<template>
  <div>
    <div class="has-background-white rounded-lg">
      <div class="columns">
        <div class="column is-one-quarter">
          <div class="pa-md">
            <!-- Filters Loaded -->
            <div class="mb-lg" v-if="filtersLoaded">
              <!-- Order By -->
              <div class="mb-lg">
                <p class="has-text-weight-bold mb-sm">Sort</p>
                <b-select v-model="orderBy" size="is-medium" expanded @input="sort">
                  <option value="-id">Newest</option>
                  <option value="id">Oldest</option>
                </b-select>
              </div>
              <product-filters :marketplace="marketplace" :applied-filters="filters" @filter="filter" />
            </div>
            <div v-else>
              <div class="py-xxl">
                <loading message="Loading filters..." />
              </div>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="pa-md rounded-lg">
            <div class="columns is-mobile is-multiline">
              <div class="column is-half-mobile is-one-third-tablet" v-for="product in results" :key="product.id">
                <product-preview :marketplace="marketplace" :product="product" />
              </div>
            </div>
              <b-loading :is-full-page="false" :active="loading" ></b-loading>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Filterable from '@/mixins/Filterable'
import ProductFilters from './ProductFilters'
import ProductPreview from './ProductPreview'
export default {
  components: {
    ProductFilters,
    ProductPreview
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/marketplaces/' + this.$route.params.id + '/products/'
    },
    placeholders () {
      return 5
    }
  },
  data () {
    return {
      key: 'profile-products',
      orderBy: '-id'
    }
  },
  methods: {

  },
  mounted () {
    this.load()
  },
  mixins: [Filterable],
  props: ['marketplace']
}
</script>
