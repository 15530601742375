<template>
  <span v-if="typeof value === 'number'">
    {{ new Intl.NumberFormat(getLocale, getOptions).format(value) }}
  </span>
  <span v-else>--</span>
</template>

<script>
export default {
  computed: {
    getLocale () {
      let locale = this.locale
      if (this.currency && this.currency.unit) {
        const { unit } = this.currency
        if (unit.standard_unit === 'USD') {
          locale = 'en-US'
        } else if (unit.standard_unit === 'CAD') {
          locale = 'en-CA'
        } else if (unit.standard_unit === 'GBP') {
          locale = 'en-GB'
        } else if (unit.standard_unit === 'EUR') {
          locale = 'de-DE'
        }
      }
      return locale
    },
    getOptions () {
      if (this.currency && this.currency.unit) {
        return {
          ...this.options,
          currency: this.currency.unit.standard_unit,
          style: this.optionStyle,
          currencyDisplay: this.optionCurrencyDisplay,
          signDisplay: this.optionSignDisplay
        }
      } else {
        return this.options
      }
    }
  },
  props: {
    currency: {
      type: Object
    },
    locale: {
      type: String,
      default: 'en-US'
    },
    value: {
      type: Number
    },
    options: {
      type: Object,
      default () {
        return {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
          style: 'currency',
          currency: 'USD',
          currencyDisplay: 'code',
          signDisplay: 'never'
        }
      }
    },
    // How to display the currency in currency formatting, the default is "code"
    optionCurrencyDisplay: {
      type: String,
      enum: ['code', 'symbol', 'narrowSymbol', 'name'],
      default: 'code'
    },
    // The formatting style to use , the default is "currency"
    optionStyle: {
      type: String,
      enum: ['decimal', 'currency', 'percent', 'unit'],
      default: 'currency'
    },
    // When to display the sign for the number; defaults to "never"
    optionSignDisplay: {
      type: String,
      enum: ['never', 'negative', 'exceptZero', 'auto', 'always'],
      default: 'never'
    }
  }
}
</script>
