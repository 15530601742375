<template>
  <form @submit.prevent="save">
    <div class="columns">
      <div class="column is-one-fifth">
        <p class="has-text-weight-bold is-size-6 mb-sm">Location</p>
        <p class="has-text-grey is-size-7">Set which inventory location to use when syncing inventory.</p>
      </div>
      <div class="column">
        <div class="has-background-white rounded-lg">
          <div class="pa-lg">
            <!-- Intro Text (Merchant) -->
            <div class="is-size-7 mb-md">
              <p class="mb-lg">
                When {{ app.platform }} updates inventory in Shopify, we have to assign
                the inventory to a location that is configured in your Shopify account.
              </p>
              <p class="">
                {{ app.platform }} recommends setting up a new, dedicated dropship location in
                order to keep your owned inventory separate from your dropship inventory. For more
                information on how to do that in Shopify, check the
                <a href="https://help.shopify.com/en/manual/locations/setting-up-your-locations#create-and-modify-locations"
                  class="has-text-weight-bold"
                  target="_blank"
                >Shopify Help Center</a>.
              </p>
            </div>
            <!-- Credential is set and configured -->
            <div v-if="locationIsSpecified" class="has-background-white-ter pa-md rounded-lg">
              <div class="columns is-vcentered is-mobile">
                <div class="column is-narrow">
                  <b-icon v-if="locationIsSpecified" icon="check" custom-class="has-text-success" />
                  <b-icon v-else icon="close" custom-class="has-text-warning" />
                </div>
                <div class="column">
                  <p class="has-text-weight-bold">Inventory is syncing with location {{ credential.values[0].value }}.</p>
                  <p class="has-text-grey-light is-size-8">
                    <span v-if="locationIsSpecified">This integration is currently enabled.</span>
                    <span v-else>This integration is currently disabled.</span>
                  </p>
                </div>
                <div class="column is-narrow">
                  <p class="has-text-weight-bold">
                    <span v-if="locationIsSpecified" class="has-text-success">Enabled</span>
                    <span v-else class="has-text-warning">Disabled</span>
                  </p>
                </div>
              </div>
            </div>
            <!-- Control -->
            <div class="pt-lg">
              <p class="has-text-weight-bold mb-sm">Select Shopify Location</p>
              <div class="columns is-mobile is-vcentered">
                <div class="column">
                  <div class="field">
                    <SelectLocation :account="account" v-model="location"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="border-t">
            <div class="columns is-vcentered is-marginless">
              <div class="column">
                <div class="px-sm has-text-grey-light">
                  <i v-if="location !== null">Click 'Update' to save your changes</i>
                </div>
              </div>
              <div class="column is-narrow">
                <div class="px-sm">
                  <b-button
                    native-type="submit"
                    type="is-primary rounded-lg"
                    :disabled="saving || location === null"
                    :loading="saving"
                  >Save Location</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import SelectLocation from './SelectLocation'
export default {
  components: {
    SelectLocation
  },
  computed: {
    ...mapGetters(['app', 'context']),
    endpoint () {
      return this.context.route + '/platform-accounts/' + this.account.id + '/'
    },
    locationIsSpecified () {
      return (this.credential !== null && this.credential.values[0].value)
    },
    json () {
      if (this.credential === null) return null
      const json = {
        credentials: [{
          credential: {
            code: 'location_id'
          },
          value: this.location
        }]
      }
      if (this.credential !== null) {
        json.credentials[0].value = this.credential.id
      }
      return json
    }
  },
  data () {
    return {
      credential: null,
      location: null,
      saving: false,
      errors: {}
    }
  },
  methods: {
    save () {
      this.saving = true
      this.$http.patch(this.endpoint, this.json).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Location saved!' })
        this.$emit('update:account')
        this.$emit('complete')
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    this.account.credentials.forEach(credential => {
      Object.keys(credential).forEach(key => {
        if (key === 'location_id' && credential[key].values[0].value) {
          this.credential = credential[key]
        }
      })
    })
  },
  props: ['account']
}
</script>
