<template>
  <form @submit.prevent="save">
    <!-- Intro -->
    <div class="mb-lg">
      <p class="has-text-centered is-size-4 mb-md has-text-black">Select Products to Import</p>
      <div class="content">
        <p>
          {{ app.platform }} can import all of your products or we can limit the import
          to a specific Shopify collection.
        </p>
        <p>
          If you can dropship all or most of your products, we recommend importing
          all products and then removing products you can't dropship later.
          Otherwise, we recommend
          <a :href="shopifyAdminUrl" target="_blank" class="has-text-info">creating a collection</a>
          in Shopify specifically for the {{ app.platform }} Commerce Network and only importing the products
          that you add to that collection. If you'd like to set up that collection,
          go do that now and come back once the collection is setup.
        </p>
      </div>
    </div>
    <!-- Fields -->
    <div class="mb-xxl">
      <p class="has-text-weight-bold mb-md">Which products do you want to import?</p>
      <div class="mb-xl">
        <div class="field">
          <b-radio v-model="importFilter" native-value="published">Import all products that are currently published to my online store.</b-radio>
        </div>
        <div class="field">
          <b-radio v-model="importFilter" native-value="collection">
            Only import products from a Shopify collection.
          </b-radio>
        </div>
      </div>
      <!-- Collection ID Input -->
      <div v-if="importFilter === 'collection'" class="mb-lg">
        <div class="columns is-vcentered">
          <div class="column is-half-desktop">
            <p class="has-text-weight-bold mb-sm">Select a Shopify Collection</p>
            <select-collection :account="account" v-model="collection" />
          </div>
          <div v-if="collection !== null" class="column">
            <div class="has-background-white-ter pa-md rounded-lg">
              <div class="columns is-mobile is-vcentered">
                <div class="column">
                  <p class="">{{ collection.title }}</p>
                  <p class="has-text-grey">Shopify ID: {{ collection.id }}</p>
                </div>
                <div class="column is-narrow">
                  <a href="#" @click.prevent="collection = null">
                    <b-icon icon="close" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Buttons -->
    <div class="mb-xl">
      <div class="columns is-centered">
        <div class="column is-three-quarters-tablet is-two-thirds-widescreen">
          <div class="columns">
            <div class="column">
              <b-button type="is-light rounded-lg" size="is-large" @click="back" expanded>
                <b-icon icon="arrow-left"></b-icon>
              </b-button>
            </div>
            <div class="column">
              <b-button
                native-type="submit"
                type="is-primary rounded-lg"
                size="is-large"
                expanded
                :loading="saving"
                :disabled="!validated || saving"
              >Next</b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import SelectCollection from './SelectCollection'
export default {
  components: {
    SelectCollection
  },
  data () {
    return {
      saving: false,
      importFilter: null,
      collection: null
    }
  },
  computed: {
    ...mapGetters(['app', 'context']),
    shopifyAdminUrl () {
      if (this.account.credentials.length === 0) return null
      let store = null
      this.account.credentials.forEach(credential => {
        Object.keys(credential).forEach(key => {
          if (key === 'shopify_shop_name') {
            store = credential[key].values[0].value
          }
        })
      })
      return (store !== null) ? 'https://' + store + '.myshopify.com/admin/collections' : null
    },
    validated () {
      if (this.importFilter === 'published') return true
      return (
        this.importFilter === 'collection' &&
        this.collection !== null
      )
    }
  },
  methods: {
    back () {
      this.$emit('change:step', 'Introduction')
    },
    save () {
      if (this.importFilter === 'collection' && this.collection !== null) {
        this.$emit('change:setting', { key: 'importFilter', value: 'collection' })
        this.$emit('change:setting', { key: 'collection', value: this.collection })
      } else {
        this.$emit('change:setting', { key: 'importFilter', value: 'published' })
      }
      this.$emit('change:step', 'DropshipCost')
    }
  },
  props: ['account', 'settings']
}
</script>
