<template>
  <div class="has-background-white pa-lg rounded-lg mb-lg">
    <!-- Intro -->
    <div class="mb-lg">
      <p class="is-size-5 has-text-weight-bold mb-sm">Import Error</p>
      <b-message type="is-warning">
        The results of your feed import are below. However, no items could be
        added to this proposal, most likely because these items have
        already been added to other active proposals (and items can only
        exist on 1 proposal at a time). We recommend you delete this proposal
        and start again.
      </b-message>
    </div>
    <!-- Results -->
    <div v-if="buildFeed !== null" class="mb-xl">
      <p class="has-text-weight-bold border-b pb-sm">Import Results</p>
      <table class="table is-fullwidth is-narrow is-size-7">
        <tbody>
          <tr>
            <th width="20%">Import ID</th>
            <td>{{ buildFeed.id }}</td>
          </tr>
          <tr>
            <th>Filename</th>
            <td>
              {{ buildFeed.original_filename }}
              <span class="has-text-grey-light">(File ID: {{ tempfileId }})</span>
            </td>
          </tr>
          <tr>
            <th>Status</th>
            <td class="has-text-weight-bold">
              <StatusPresenter :feed="buildFeed" />
              <p v-if="!loading && buildFeed !== null && buildFeed.status === 'queued' || buildFeed.status === 'processing'" >
                <a href="#" class="has-text-weight-normal has-text-info" @click.prevent="load"> Refresh</a>
              </p>
            </td>
          </tr>
          <tr>
            <th>Number of Rows</th>
            <td>{{ buildFeed.num_items }}</td>
          </tr>
          <tr>
            <th>Number of Rows Processed</th>
            <td>{{ buildFeed.num_items }}</td>
          </tr>
          <tr>
            <th>Number of Items Created</th>
            <td>{{ buildFeed.num_created }}</td>
          </tr>
          <tr>
            <th>Number of Items Updated</th>
            <td>{{ buildFeed.num_updated }}</td>
          </tr>
          <tr>
            <th>Number of Errors</th>
            <td>{{ buildFeed.num_errors }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Actions -->
    <!-- Schedule Build -->
    <div class="pt-md mb-lg">
      <div class="columns">
        <div class="column is-one-quarter">
          <b-button
            type="is-primary"
            size="is-medium"
            :disabled="!ready || saving"
            :loading="saving"
            expanded
            @click="save"
          >Build Proposal</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StatusPresenter from '@/components/Models/FeedQueue/Presenters/Status'

export default {
  components: {
    StatusPresenter
  },
  computed: {
    loading () {
      return this.loadingFeeds
    },
    tempfileId () {
      if (this.feeds.length === 0) return null
      const feed = this.feeds.find(feed => {
        return feed.info.proposal_feed_purpose === 'build'
      })
      return ('proposal_tempfile_id' in feed.info)
        ? feed.info.proposal_tempfile_id
        : null
    },
    buildFeed () {
      if (this.feeds.length === 0) return null
      return this.feeds.find(feed => {
        return feed.info.proposal_feed_purpose === 'build'
      })
    }
  },
  props: ['proposal', 'feeds', 'loadingFeeds']
}
</script>
