<template>
  <div class="section">
    <div class="container">
      <!-- Page Header -->
      <div class="columns is-vcentered">
        <div class="column is-one-third">
          <breadcrumbs :history="breadcrumbs.history" :current="breadcrumbs.current" />
        </div>
        <div class="column is-one-third">
          <h1 class="has-text-centered is-size-4"></h1>
        </div>
      </div>
      <!-- Filters -->
      <div class="has-background-white rounded-lg pa-md mb-xxs">
        <div class="columns">
          <div class="column">
            <ItemFilters @filter="filter" :applied-filters="filters" />
          </div>
          <div class="column is-narrow">
            <b-field >
              <b-select v-model="orderBy" expanded @input="sort" size="is-medium">
                <option value="-id">Most Recent</option>
                <option value="id">Oldest</option>
              </b-select>
            </b-field>
          </div>
        </div>
      </div>
      <!-- Toolbar -->
      <div class="has-background-white rounded-lg px-sm mb-xxs">
        <div class="columns is-vcentered is-mobile is-marginless is-paddingless">
          <!-- Dropdown -->
          <div class="column">
            <div class="has-text-right is-size-7">
              <!-- Counts -->
              <p v-if="loading">Loading...</p>
              <p v-else>{{ total }} {{ (total === 1) ? 'record' : 'records' }} found.</p>
            </div>
          </div>
        </div>
      </div>
      <!-- Table -->
      <div>
        <b-table
          :data="results"
          :mobile-cards="false"
          :loading="loading"
          hoverable
          class="is-size-7">
          <template>
              <!-- Item Name -->
              <b-table-column
                label="Item"
                header-class="nowrap"
                cell-class="nowrap"
                v-slot="props">
                <p>{{ props.row.name }}</p>
              </b-table-column>
              <!-- Supplier -->
              <b-table-column
                v-if="context.isMerchant()"
                label="Supplier"
                cell-class="nowrap"
                width="194"
                v-slot="props">
                {{ props.row.brand.name }}
              </b-table-column>
              <!-- UPC -->
              <b-table-column
                label="UPC"
                header-class="nowrap"
                cell-class="nowrap"
                width="128"
                v-slot="props">
                <span v-if="props.row.upc">{{ props.row.upc }}</span>
                <span v-else class="has-text-grey-light is-italic">None</span>
              </b-table-column>
              <!-- Product SKU -->
              <b-table-column
                label="Product SKU"
                header-class="nowrap"
                cell-class="nowrap"
                width="128"
                v-slot="props">
                <span v-if="props.row.parent_identifier">{{ props.row.parent_identifier }}</span>
                <span v-else class="has-text-grey-light is-italic">None</span>
              </b-table-column>
              <!-- Availability -->
              <b-table-column
                label="Availability"
                header-class="nowrap is-hidden-mobile"
                cell-class="nowrap is-hidden-mobile"
                centered
                width="200"
                v-slot="props">
                <div>
                  <div v-if="props.row.inventory.discontinued_updated_at">
                    <p class="has-text-weight-bold has-text-centered">
                      <span class="has-text-danger">Discontinued</span>
                    </p>
                    <p class="has-text-grey is-size-8">{{ $dates.utc(props.row.discontinued_updated_at).local().format('MMM D, YYYY') }}</p>
                  </div>
                  <div v-else-if="props.row.inventory.inventory === 0 && props.row.inventory.estimated_availability_date === null">
                    <p class="has-text-weight-bold has-text-centered">
                      <span class="has-text-danger">0</span>
                    </p>
                    <p class="has-text-grey is-size-8">Unknown Return</p>
                  </div>
                  <div v-else-if="props.row.inventory.inventory === 0 && props.row.inventory.estimated_availability_date !== null">
                    <p class="has-text-weight-bold has-text-centered">
                      <span class="has-text-danger">0</span>
                    </p>
                    <p class="has-text-grey is-size-8">Returns {{ $dates(props.row.inventory.estimated_availability_date).format('MMM D, YYYY') }}</p>
                  </div>
                  <div v-else-if="props.row.inventory.inventory > 0">
                    <p class="has-text-weight-bold">
                      <span v-if="props.row.inventory.inventory === 9999" class="has-text-grey is-italic">Not Managed</span>
                      <span v-else class="has-text-weight-bold has-text-info">
                        {{ props.row.inventory.inventory }}
                      </span>
                    </p>
                  </div>
                </div>
              </b-table-column>
            </template>
          <template #empty>
            <div class="py-xxl has-text-grey has-text-centered">
              {{ (loading) ? "Loading data..." : 'No records found!' }}
            </div>
          </template>
        </b-table>
      </div>
      <div class="mt-md">
        <b-pagination
          :total="total"
          :current.sync="currentPage"
          :rounded="true"
          :per-page="perPage"
          @change="paginate">
        </b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Filterable from '@/mixins/Filterable'
import ItemFilters from '../components/List/Filters'

export default {
  components: {
    ItemFilters
  },
  computed: {
    ...mapGetters(['api', 'context']),
    endpoint () {
      return `${this.context.route}/variants/?retailer_category_id=${this.$route.params.id}`
    },
    breadcrumbs () {
      const historyArray = (this.context.isSupplier())
        ? [{ label: 'Settings', name: 'settings' }, { label: 'Connections', name: 'connection' }, { label: 'Categories', name: 'categories.connection' }]
        : [{ label: 'Settings', name: 'settings' }, { label: 'Categories', name: 'categories' }]
      return {
        history: historyArray,
        current: this.$route.query.name
      }
    }
  },
  data () {
    return {
      key: 'items',
      loading: false
    }
  },
  mixins: [Filterable],
  mounted () {
    this.load()
  }
}
</script>
