<template>
  <div>
    <!-- Has Actions -->
    <div v-if="hasActions">
      <div class="columns">
        <!-- Actions -->
        <div class="column is-one-quarter" v-for="(action, index) in primaryActions" :key="index">
          <b-button v-if="action.type === 'primary'" type="is-primary" outlined expanded size="is-medium" @click="$emit(action.event)">{{ action.label }}</b-button>
          <b-button v-else type="is-light" expanded size="is-medium" @click="$emit(action.event)">{{ action.label }}</b-button>
        </div>
      </div>
    </div>
    <!-- Has No Actions -->
    <div v-else>
      <div class="columns">
        <div class="column is-one-quarter"><b-button :disabled="true" outlined expanded size="is-medium" >No Actions</b-button></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['context']),
    hasActions () {
      return (this.primaryActions.length > 0 || this.additionalActions.length > 0)
    },
    primaryActions () {
      const actions = []
      switch (this.proposal.status) {
        case 'draft':
          actions.push({ label: 'Submit', event: 'action:propose', type: 'primary' })
          actions.push({ label: 'Archive', event: 'action:archive', type: 'secondary' })
          if (this.proposal.proposal_type === 'new_product') {
            actions.push({ label: 'Update Cost Attributes', event: 'action:costUpdate', type: 'secondary' })
          }
          break
        case 'revised':
          actions.push({ label: 'Resend', event: 'action:propose', type: 'primary' })
          actions.push({ label: 'Archive', event: 'action:archive', type: 'secondary' })
          if (this.proposal.proposal_type === 'new_product') {
            actions.push({ label: 'Update Cost Attributes', event: 'action:costUpdate', type: 'secondary' })
          }
          break
        case 'proposed':
          actions.push({ label: 'Archive', event: 'action:archive', type: 'secondary' })
          break
      }
      return actions
    },
    additionalActions () {
      const actions = []

      return actions
    }
  },
  props: {
    proposal: {
      type: Object,
      required: true
    }
  }
}
</script>
