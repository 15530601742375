import dates from '@/assets/js/dates'

export const datatypes = {
  label: 'Datatype',
  key: 'data_type',
  icon: 'pulse',
  tooltip: 'Limit results based on feed type',
  options: [{
    value: 'products',
    label: 'Products'
  }, {
    value: 'inventory',
    label: 'Inventory'
  }]
}

export const createdAt = {
  label: 'Created At',
  key: 'created_at',
  icon: 'calendar-outline',
  tooltip: 'Filter items based on when they were created.',
  options: dates
}
