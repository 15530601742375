<template>
  <div class="section">
    <div class="container">
      <!-- Page Header -->
      <div class="mb-md">
        <div class="columns is-vcentered is-centered">
          <div class="column is-one-third">
            <h1 class="has-text-centered is-size-4">User Notifications</h1>
          </div>
        </div>
      </div>
      <div>
        <!-- Transactions Notifications -->
        <div class="mb-lg">
          <MerchandisingNotifications />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MerchandisingNotifications from '../components/MerchandisingNotifications'

export default {
  components: {
    MerchandisingNotifications
  },
  computed: {
    ...mapGetters(['context'])
  },
  data () {
    return {
      loading: false
    }
  }
}
</script>
