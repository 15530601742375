<template>
  <div>
    <div class="pt-xxl">
      <loading message="Checking connections..." />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['auth'])
  },
  mounted () {
    if (this.auth.productMode === 'transactions_and_products') {
      this.$router.push({ name: 'orders.dashboard' })
    } else {
      this.$router.push({ name: 'catalog.index' })
    }
  }
}
</script>
