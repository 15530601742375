<template>
<div>
  <div class="section">
    <div class="container">
      <!-- Page Header -->
      <div class="mb-lg">
        <div class="columns is-vcentered">
          <div class="column">
            <h1 class="has-text-centered is-size-4">Onboarding</h1>
          </div>
        </div>
      </div>
      <!-- Connection Tiles -->
      <div v-if="!loading && connections.length > 0">
        <div class="columns is-centered">
          <div v-for="connection in connections" :key="connection.id" class="column is-half-tablet is-two-fifths-widescreen">
            <div v-if="context.isSupplier()" class="has-background-white rounded-lg pa-md">
              <!-- Branding -->
              <div class="has-background-light rounded-lg pa-lg mb-lg">
                <div class="has-text-centered">
                  <p v-if="connection.retailer.logo_url"><img :src="connection.retailer.logo_url | cloudinary('360', '120', 'pad', 'png')" width="180" height="60" /></p>
                  <p v-else class="has-text-weight-bold">{{ connection.retailer.name }}</p>
                </div>
              </div>
              <div class="columns is-vcentered is-mobile">
                <div class="column">
                  <div class="is-size-7">
                    <!-- Submitted / Pending Approval -->
                    <div v-if="connection.onboarded_at">
                      <p class="has-text-success has-text-weight-bold">Waiting on Merchant</p>
                      <p class="has-text-grey-light">Submitted {{ $dates.utc(connection.onboarded_at).format('MMM DD, YYYY') }}</p>
                    </div>
                    <!-- In Progress -->
                    <div v-else>
                      <p class="has-text-info has-text-weight-bold">In Progress</p>
                      <p class="has-text-grey-light">Started {{ $dates.utc(connection.created_at).format('MMM DD, YYYY') }}</p>
                    </div>
                  </div>
                </div>
                <div class="column is-narrow">
                  <router-link :to="{ name: 'onboarding.tasks', params: {connection: connection.id }}" class="button is-primary is-outlined rounded-lg">Continue</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- No Connections in Onboarding -->
      <div v-else-if="!loading && connections.length === 0">
        <div class="has-background-white rounded-lg pa-xxl">
          <div class="has-text-centered pa-xxl">
            <p class="mb-md">You don't currently have any connections to onboard!</p>
            <p>Return to <router-link :to="{ name: 'home' }" class="has-text-info">Dashboard</router-link></p>
          </div>
        </div>
      </div>
      <!-- Loading -->
      <div v-else>
        <div class="columns is-vcentered is-centered is-multiline">
          <div v-for="index in 1" :key="index" class="column is-half-tablet is-two-fifths-widescreen">
            <div class="has-background-white rounded-lg pa-md">
              <!-- Branding -->
              <div class="has-background-light rounded-lg pa-lg mb-lg">
                <p class="has-text-weight-bold has-text-centered has-text-grey-light">Loading...</p>
              </div>
              <div class="columns is-vcentered">
                <div class="column is-two-thirds">
                  <b-skeleton />
                  <b-skeleton />
                </div>
                <div class="column">
                  <b-skeleton />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/connections/'
    },
    query () {
      return {
        params: {
          status: 'onboarding'
        }
      }
    }
  },
  data () {
    return {
      loading: true,
      connections: []
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint, this.query).then(response => {
        this.connections = response.data.results
      }).finally(() => {
        this.loading = false
      })
    }
  },
  mixins: [],
  mounted () {
    this.load()
  }
}
</script>
