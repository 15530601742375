<template>
  <form @submit.prevent="updateSku">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Update Item SKU</p>
      </header>
      <section class="modal-card-body">
        <div v-if="!loading && item !== null">
          <!-- Variant Details -->
          <div>
            <p class="mb-sm"><strong>Item Details</strong></p>
            <table class="table is-fullwidth is-striped is-size-7 is-narrow border mb-xl">
              <tbody>
                <tr>
                  <th>ID</th>
                  <td class="has-text-right">{{ item.id }}</td>
                </tr>
                <tr>
                  <th>Current Item SKU</th>
                  <td class="has-text-right">{{ item.brand_identifier }}</td>
                </tr>
                <tr>
                  <th>UPC</th>
                  <td class="has-text-right">{{ item.upc }}</td>
                </tr>
                <tr>
                  <th>Supplier Item Name</th>
                  <td class="has-text-right">{{ item.name }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- SKU -->
          <div>
            <b-field label="New SKU">
              <b-input
                type="text"
                name="identifier"
                v-model="identifier"
                placeholder="Enter new SKU here..."
                size="is-medium"
              ></b-input>
            </b-field>
          </div>
        </div>
        <div v-else>
          <loading message="Loading current item details..." />
        </div>
      </section>
      <footer class="modal-card-foot space-between">
        <b-button @click="$parent.close()">Close</b-button>
        <b-button
          native-type="submit"
          type="is-primary"
          :disabled="saving || !ready"
          :loading="saving"
        >Update SKU</b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  data () {
    return {
      loading: false,
      identifier: null,
      saving: false,
      errors: {}
    }
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/variants/' + this.item.id + '/'
    },
    json () {
      return {
        identifier: this.identifier
      }
    },
    ready () {
      return (this.identifier)
    }
  },
  methods: {
    updateSku () {
      this.saving = true
      this.$http.patch(this.endpoint, this.json).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Item SKU updated!' })
        this.$emit('variant:updated')
        this.$parent.close()
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {

  },
  props: ['item']
}
</script>
