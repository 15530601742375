<template>
<div>
  <div>
    <div>
      <!-- Has Tasks -->
      <div v-if="tasks.length > 0">
        <div v-for="task in tasks" :key="task.id">
          <Task :task="task" class="has-background-white rounded-lg px-md py-sm mb-xxs" />
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import Task from './Task'

export default {
  components: {
    Task
  },
  props: ['tasks']
}
</script>
