<template>
  <div>
    <b-table
      :data="connection.connection_adjustments"
      :mobile-cards="false"
      hoverable
      class="rounded-lg is-size-7">
      <template>
        <!-- Description -->
        <b-table-column
          label="Description"
          cell-class="nowrap"
          width="192"
          v-slot="props">
          {{ props.row.description }}
        </b-table-column>
        <!-- Type -->
        <b-table-column
          label="Type"
          cell-class="nowrap"
          width="164"
          v-slot="props">
          {{ $utils.upperFirst(props.row.adjustment_type) }}
        </b-table-column>
        <!-- Amount -->
        <b-table-column
          label="Amount"
          cell-class="nowrap"
          v-slot="props">
          <span v-if="props.row.adjustment_amount_type === 'percent'">{{ $numbers(props.row.adjustment_amount).format({ mantissa: 2 }) }}% per order</span>
          <span v-else>{{ $numbers(props.row.adjustment_amount).formatCurrency({ mantissa: 2 }) }} per order</span>
        </b-table-column>
      </template>
      <template #empty>
        <div class="py-xxl has-text-grey has-text-centered">No contacts found!</div>
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['context'])
  },
  props: ['connection']
}
</script>
