<template>
<div>
  <!-- Page Header -->
  <div class="mb-sm">
    <div class="columns is-centered">
      <div class="column is-one-third">
        <h1 class="has-text-centered is-size-4">Products</h1>
      </div>
    </div>
  </div>
  <!-- Content -->
  <div>
    <div v-if="context.isSupplier()">
      <Supplier :connections="connections" :platformAccounts="platformAccounts" />
    </div>
    <div v-if="context.isMerchant()">
      <Merchant :connections="connections" :platformAccounts="platformAccounts" />
    </div>
  </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Supplier from '../components/Dashboard/Supplier'
import Merchant from '../components/Dashboard/Merchant'

export default {
  components: {
    Supplier,
    Merchant
  },
  computed: {
    ...mapGetters(['context'])
  },
  props: {
    connections: {
      type: Array,
      required: true
    },
    platformAccounts: {
      type: Array,
      required: true
    }
  }
}
</script>
