<template>
  <form @submit.prevent="update" novalidate autocomplete="off">
    <div class="columns">
      <div class="column is-one-fifth">
        <p class="has-text-weight-bold is-size-6 mb-sm">Merchandising Notifications</p>
        <p class="has-text-grey is-size-7">
          Subscribe to push notifications to receive real-time alerts related to onboarding events.
        </p>
      </div>
      <div class="column">
        <!-- Existing notifications are loaded -->
        <div v-if="!loading">
          <!-- Merchant Notifications -->
          <div>
            <div class="has-background-white rounded-lg">
              <!-- Loaded -->
              <div class="px-lg py-md">
                <div class="mb-sm">
                  <p class="has-text-weight-bold">Proposal Notifications</p>
                  <p class="is-size-7 has-text-grey">Subscribe to all event notifications related to merchandising proposals.</p>
                </div>
              </div>
              <div class="border-t">
                <div class="columns is-vcentered is-marginless">
                  <div class="column is-narrow">
                    <div class="px-sm">
                    <b-field>
                      <b-switch
                        v-model="enabled"
                        true-value="Enabled"
                        false-value="Not Enabled"
                        type="is-success"
                        @input="save">
                        {{ enabled }}
                      </b-switch>
                    </b-field>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Existing notifications are loaded -->
        <div v-else>
          <div v-for="index in 1" :key="index">
            <div class="has-background-white pa-md rounded-lg mb-sm">
              <div class="mb-md">
                <b-skeleton width="25%" :animated="true"></b-skeleton>
                <b-skeleton width="75%" :animated="true"></b-skeleton>
              </div>
              <b-skeleton width="50%" height="30" :animated="true"></b-skeleton>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {

  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/push-notifications/'
    },
    query () {
      return {
        params: {
          has_recipient_email: 0
        }
      }
    },
    body () {
      const json = []
      this.availableNotifications.forEach(notification => {
        const existing = this.$utils.find(this.subscribedNotifications, subscribed => {
          return subscribed.action.name === notification
        })
        json.push({
          id: (existing) ? existing.id : null,
          status: (this.enabled === 'Enabled') ? 'enabled' : 'disabled',
          delivery_method: 'email',
          action: {
            name: notification
          }
        })
      })
      return json
    }
  },
  data () {
    return {
      loading: true,
      notifications: [],
      saving: false,
      errors: {},
      enabled: 'Not Enabled',
      subscribedNotifications: [],
      availableNotifications: [
        'Proposal Proposed',
        'Proposal Revised',
        'Collaborator Added',
        'Owner Reassigned',
        'Proposal Pricing Approved'
      ]
    }
  },
  methods: {
    load () {
      const endpoint = this.context.route + '/push-notifications/'
      this.$http.get(endpoint, this.query).then(response => {
        this.subscribedNotifications = []
        this.notifications = response.data.results
        response.data.results.forEach(notification => {
          if (this.availableNotifications.includes(notification.action.name)) {
            this.subscribedNotifications.push(notification)
          }
        })
        this.subscribedNotifications.forEach(subscribed => {
          if (subscribed.status === 'enabled') {
            this.enabled = 'Enabled'
          }
        })
      }).finally(() => {
        this.loading = false
      })
    },
    save () {
      this.saving = true
      this.$http.post(this.endpoint, this.body).then(() => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Notifications saved!' })
        this.load()
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    this.load()
  }
}
</script>
