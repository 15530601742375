<template>
<div class="section">
  <div class="container">
    <div class="mb-sm">
      <div class="columns is-centered">
        <div class="column is-one-third">
          <h1 class="has-text-centered is-size-4">Subscription Failed</h1>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return `${this.context.route}/subscriptions/checkout-failed/`
    },
    json () {
      return {
        checkout_session_id: this.$route.query.session_id,
        nonce: this.$route.query.nonce
      }
    }
  },
  data () {
    return {
      loading: true
    }
  },
  methods: {
    save () {
      this.$http.post(this.endpoint, this.json).then(response => {
        this.$store.dispatch('setContext', { type: 'vendor', partner: response.data }).then(() => {
          this.$router.push({ name: 'settings.subscriptions' })
        })
      }).catch(error => {
        console.error(error)
        this.$router.push({ name: 'settings.subscriptions' })
      })
    }
  },
  mounted () {
    this.save()
  }
}
</script>
