<template>
  <div>
    <!-- Actions Toolbar -->
    <div class="has-background-white rounded-lg px-md py-sm mb-xxs">
      <div class="columns is-vcentered is-mobile">
        <div class="column is-narrow">
          <b-dropdown :disabled="loading || selected.length === 0">
            <button class="button" slot="trigger" slot-scope="{ active }" :disabled="selected.length === 0">
              <span>Actions</span>
              <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
            </button>
            <b-dropdown-item><a href="#" @click.prevent="copyRmaNumbers">Copy RMA Numbers</a></b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="column">
          <div class="has-text-right is-size-7">
            <!-- Counts -->
            <p v-if="loading">Loading...</p>
            <p v-else>
              {{ order.rmas.length }}
              {{ (order.rmas.length === 1) ? 'return' : 'returns' }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- Reults -->
    <div>
      <b-table
        :data="order.rmas"
        :checked-rows.sync="selected"
        :mobile-cards="false"
        :loading="loading"
        hoverable
        checkable
        checkbox-position="left"
        class="is-size-7">
        <template>
          <!-- ID -->
          <b-table-column
            label="ID"
            header-class="nowrap"
            cell-class="nowrap"
            width="128"
            v-slot="props">
            <a href="#"
              class="has-text-weight-bold has-text-info"
              @click.prevent="inspectRma = props.row"
            >{{ props.row.id }}</a>
          </b-table-column>
          <!-- RMA # -->
          <b-table-column
            label="RMA #"
            header-class="nowrap"
            cell-class="nowrap"
            v-slot="props">
            <p v-if="props.row.rma_number">{{ props.row.rma_number }}</p>
            <p v-else class="has-text-grey-light is-italic">None Provided</p>
            <p></p>
          </b-table-column>
          <!-- Submitted -->
          <b-table-column
            v-if="context.isMerchant()"
            label="Label"
            cell-class="nowrap"
            centered
            width="48"
            v-slot="props">
            <a href="#" class="has-text-info" @click.prevent="printLabelRma = props.row">Get</a>
          </b-table-column>
          <!-- Submitted -->
          <b-table-column
            label="Registered"
            header-class="nowrap"
            cell-class="nowrap"
            centered
            width="128"
            v-slot="props">
            <span v-if="props.row.rmad_at === null">--</span>
            <span v-else>{{ $dates.utc(props.row.rmad_at).local().format('MMM D, YYYY') }}</span>
          </b-table-column>
          <!-- Merchant Status -->
          <b-table-column
            label="Merchant Status"
            header-class="nowrap"
            cell-class="nowrap"
            centered
            width="128"
            v-slot="props">
            <MerchantStatus :rma="props.row" />
          </b-table-column>
          <!-- Status -->
          <b-table-column
            label="Supplier Status"
            header-class="nowrap"
            cell-class="nowrap"
            centered
            width="128"
            v-slot="props">
            <Status :rma="props.row" />
          </b-table-column>
        </template>
        <template #empty>
          <div class="py-lg has-text-grey has-text-centered">
            <p>No returns have been registered yet.</p>
          </div>
        </template>
      </b-table>
    </div>
    <!-- Inspect Modal -->
    <b-modal
      v-if="(inspectRma !== null)"
      has-modal-card
      :active="(inspectRma !== null)"
      :can-cancel="['escape', 'x']"
      @close="closeModals"
      @rma:rejected="rmaRejected"
      @rma:approved="rmaApproved"
      ><RmaModal :selectedRma="inspectRma" />
    </b-modal>
    <!-- RMA Label Modal -->
    <b-modal
      v-if="(printLabelRma !== null)"
      has-modal-card
      :active="(printLabelRma !== null)"
      :can-cancel="['escape', 'x']"
      @close="closeModals"
      ><ReturnLabelsModal :selectedRma="printLabelRma" :connectionId="order.connection_id" :supplier="order.brand" />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import RmaModal from '@/components/Modals/Rma/RmaModal'
import ReturnLabelsModal from '@/components/Modals/Rma/ReturnLabelsModal'
import Status from '@/components/Presenters/Rma/Status'
import MerchantStatus from '@/components/Presenters/Rma/MerchantStatus'

export default {
  components: {
    RmaModal,
    ReturnLabelsModal,
    Status,
    MerchantStatus
  },
  data () {
    return {
      loading: false,
      selected: [],
      inspectRma: null,
      printLabelRma: null
    }
  },
  computed: {
    ...mapGetters(['context']),
    selectedRmaNumbers () {
      if (this.selected.length === 0) return null
      const rmaNumbers = []
      this.selected.forEach(rma => {
        if (rma.rma_number) {
          rmaNumbers.push(rma.rma_number)
        }
      })
      if (rmaNumbers.length === 0) return null
      if (rmaNumbers.length === 1) return rmaNumbers.join('')
      return rmaNumbers.join(', ')
    }
  },
  methods: {
    copyRmaNumbers () {
      if (this.selectedRmaNumbers !== null) {
        this.$copyText(this.selectedRmaNumbers).then(() => {
          this.$buefy.toast.open({ type: 'is-success', message: 'RMA numbers copied!' })
        })
      } else {
        this.$buefy.toast.open({ type: 'is-danger', message: 'No RMA numbers copied!' })
      }
    },
    closeModals () {
      this.inspectRma = null
      this.printLabelRma = null
    },
    rmaRejected () {
      this.$emit('rma:rejected')
    },
    rmaApproved () {
      this.$emit('rma:approved')
    }
  },
  mounted () {

  },
  props: ['order']
}
</script>
