<template>
<div>
  <div class="pa-md">
    <!-- Task is loaded -->
    <div v-if="!isLoading && task !== null && brand !== null">
      <p class="has-text-centered has-text-weight-bold mb-lg is-size-5">Schedule a Demo Call (optional)</p>
      <div class="columns is-centered">
        <div class="column is-half">
          <div class="box">
            <transition name="fade" mode="out-in">
              <component :is="step"
                :connection="connection"
                :task="task"
                :brand="brand"
                @navigate="navigate"
                @completed="$emit('completed')"
                @metadata:saved="load(true)"
              ></component>
            </transition>
          </div>
          <!-- Return & Reset Links -->
          <div class="columns is-centered">
            <router-link :to="{ name: 'onboarding.tasks', params: { connection: this.$route.params.connection }}" class="is-size-7 column ml-sm" :class="!isCompleted && 'has-text-centered'">
              Back to Tasks
            </router-link>
            <a v-if="isCompleted" href="#" class="column is-size-7 has-text-right mr-sm" @click="showResetTaskModal=true">
              Reset Task
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- Loading Skeleton -->
    <div v-else>
      <div class="mb-md">
        <div class="columns is-centered">
          <div class="column is-one-quarter">
            <b-skeleton />
          </div>
        </div>
      </div>
      <div class="columns is-centered">
        <div class="column is-half">
          <div class="box">
            <loading message="Loading..." />
          </div>
        </div>
      </div>
    </div>
    <!-- Reset Task Modal -->
    <b-modal
      v-if="showResetTaskModal && task !== null"
      :has-modal-card="true"
      :active.sync="showResetTaskModal"
      :can-cancel="['x', 'esc']">
      <ResetTaskModal :taskId="task.id" @task:reset="onTaskReset" />
    </b-modal>
  </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Intro from '../components/Welcome/Intro'
import Experience from '../components/Welcome/Experience'
import Integration from '../components/Welcome/Integration'
import Call from '../components/Welcome/Call'
import ResetTaskModal from '@/components/Models/Task/Modals/ResetTaskModal'

export default {
  components: {
    Intro,
    Experience,
    Integration,
    Call,
    ResetTaskModal
  },
  computed: {
    ...mapGetters(['app', 'context']),
    isLoading () {
      return this.loadingTask && this.loadingBrand
    },
    isCompleted () {
      return this.task && this.task.completed_at !== null
    }
  },
  data () {
    return {
      loadingTask: true,
      loadingBrand: true,
      brand: null,
      step: 'Call',
      task: null,
      showResetTaskModal: false
    }
  },
  methods: {
    navigate (step) {
      this.step = step
      window.scrollTo(0, 0)
    },
    loadTask () {
      this.loadingTask = true
      const endpoint = this.context.route + '/assigned-tasks/' + this.$route.params.task + '/'
      this.$http.get(endpoint).then(response => {
        this.task = response.data
        this.loadingTask = false
      })
    },
    load () {
      this.loadingBrand = true
      const endpoint = this.context.route + '/'
      this.$http.get(endpoint).then(response => {
        this.brand = response.data
        this.loadingBrand = false
      })
    },
    onTaskReset () {
      this.$buefy.toast.open({ type: 'is-info', message: 'Task reset successfully.' })
      this.$router.push({ name: 'onboarding.tasks', params: { connection: this.$route.params.connection } })
    }
  },
  mounted () {
    this.loadTask()
    this.load()
  },
  props: ['connection']
}
</script>
