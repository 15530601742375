import dates from '@/assets/js/dates'

export const connection = {
  label: 'Connection',
  key: 'connection_id',
  icon: 'lan',
  tooltip: 'This is a tooltip'
}

export const acknowledged = {
  label: 'Acknowledged?',
  key: 'acknowledged',
  icon: 'check',
  tooltip: 'Limit results based on acknowledgement.',
  options: [{
    value: 1,
    label: 'Yes'
  }, {
    value: 0,
    label: 'No'
  }]
}

export const updatedAt = {
  label: 'Updated At',
  key: 'updated_at',
  icon: 'calendar',
  tooltip: 'This is a tooltip',
  options: dates
}
