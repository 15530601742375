<template>
  <div>
    <!-- Actions Toolbar -->
    <div class="has-background-white rounded-lg px-md py-sm mb-xxs">
      <div class="columns is-vcentered is-mobile">
        <div class="column is-narrow">
          <b-dropdown :disabled="loading || selected.length === 0">
            <button class="button" slot="trigger" slot-scope="{ active }" :disabled="selected.length === 0">
              <span>Actions</span>
              <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
            </button>
            <!-- All Actions -->
            <b-dropdown-item><a href="#" @click.prevent="copyTrackingNumbers">Copy Tracking Numbers</a></b-dropdown-item>
            <template v-if="context.isSupplier()">
              <hr class="dropdown-divider">
              <b-dropdown-item @click="showDeleteShipmentsModal = true">Delete Tracking Numbers</b-dropdown-item>
            </template>
          </b-dropdown>
        </div>
        <div class="column">
          <div class="has-text-right is-size-7">
            <!-- Counts -->
            <p v-if="loading">Loading...</p>
            <p v-else>
              {{ order.shipments.length }}
              {{ (order.shipments.length === 1) ? 'shipment' : 'shipments' }}
              <a href="#" @click.prevent="reload" class="has-text-info ml-sm">Refresh</a>
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- Reults -->
    <div>
      <b-table
        :data="order.shipments"
        :checked-rows.sync="selected"
        :mobile-cards="false"
        :loading="loading || orderRefreshing"
        hoverable
        checkable
        checkbox-position="left"
        class="is-size-7">
        <template>
          <!-- Tracking # -->
          <b-table-column
            label="Tracking Number"
            header-class="nowrap"
            cell-class="nowrap"
            v-slot="props">
            <!-- supplier can always view shipment details -->
            <span v-if="context.isSupplier()">
              <a href="#"
                class="has-text-weight-bold has-text-info"
                @click.prevent="inspectShipment(props.row)">
                {{  getTrackingContent(props.row) }}
              </a>
            </span>
            <!-- merchant can view only closed shipment details  -->
            <span v-else-if="context.isMerchant() && props.row.status === 'closed'">
              <a href="#"
                class="has-text-weight-bold has-text-info"
                @click.prevent="inspectShipment(props.row)">
                {{  getTrackingContent(props.row) }}
              </a>
            </span>
            <span v-else>
              {{  getTrackingContent(props.row) }}
            </span>
          </b-table-column>
          <!-- Method -->
          <b-table-column
            label="Service"
            header-class="nowrap"
            cell-class="nowrap"
            centered
            width="128"
            v-slot="props">
            <ShippingService :shippingMethod="props.row.shipping_method" :shippingProviderIdentifier="props.row.shipping_provider_identifier" />
          </b-table-column>
          <!-- Registered -->
          <b-table-column
            label="Registered"
            header-class="nowrap"
            cell-class="nowrap"
            centered
            width="96"
            v-slot="props">
            <span v-if="props.row.shipped_at">{{ $dates.utc(props.row.shipped_at).local().format('MMM D') }}</span>
            <span v-else class="has-text-grey is-italic">N/A</span>
          </b-table-column>
          <!-- Merchant Status -->
          <b-table-column
            label="Merchant Status"
            header-class="nowrap"
            cell-class="nowrap"
            centered
            width="128"
            v-slot="props">
            <MerchantStatus :shipment="props.row" />
          </b-table-column>
          <!-- Carrier Status -->
          <b-table-column
            label="Carrier Status"
            header-class="nowrap"
            cell-class="nowrap"
            centered
            width="128"
            v-slot="props">
            <CarrierStatus :shipment="props.row" />
          </b-table-column>
          <!-- Edit Tracking Number -->
          <b-table-column
            label=""
            centered
            width="96"
            v-slot="props">
            <b-dropdown position="is-bottom-left">
              <button class="button is-primary is-outlined is-small" slot="trigger">
                <b-icon icon="dots-vertical"></b-icon>
              </button>
              <template v-if="context.isSupplier()">
                <b-dropdown-item @click="updateTracking(props.row)">Edit Tracking Number</b-dropdown-item>
              </template>
              <template v-else>
                <b-dropdown-item has-link>
                  <a href="#" @click.prevent class="has-text-grey-light">No Actions</a>
                </b-dropdown-item>
              </template>
            </b-dropdown>
          </b-table-column>
        </template>
        <template #empty>
          <div class="py-lg has-text-grey has-text-centered">
            <p>No shipments have been registered yet.</p>
          </div>
        </template>
      </b-table>
    </div>
    <!-- Inspect Modal -->
    <b-modal
      v-if="(focusShipment !== null)"
      has-modal-card
      :active.sync="showInspectShipmentModal"
      :can-cancel="['escape', 'x']"
      @close="closeModals"
      ><ShipmentModal :shipment="focusShipment" />
    </b-modal>
    <!-- Edit Shipments Tracking-->
    <b-modal
      v-if="(focusShipment !== null)"
      has-modal-card
      scroll="keep"
      :active.sync="showEditShipmentsTrackingModal"
      :can-cancel="false"
      @close="closeModals"
      ><EditShipmentsTrackingModal :shipment="focusShipment" @fulfillment:updated="$emit('fulfillment:updated')" />
    </b-modal>
    <!-- Delete Shipments -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showDeleteShipmentsModal"
      :can-cancel="false"
      @close="selected = []"
      ><DeleteShipmentsModal :shipments="selected" @reload="reload" />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CarrierStatus from '@/components/Presenters/Shipment/CarrierStatus'
import MerchantStatus from '@/components/Presenters/Shipment/MerchantStatus'
import ShippingService from '@/components/Presenters/Shipment/ShippingService'
import ShipmentModal from '@/components/Modals/Shipment/ShipmentModal'
import DeleteShipmentsModal from '@/components/Modals/Shipment/DeleteShipmentsModal'
import EditShipmentsTrackingModal from '@/components/Modals/Shipment/EditShipmentsTrackingModal'

export default {
  components: {
    CarrierStatus,
    DeleteShipmentsModal,
    EditShipmentsTrackingModal,
    MerchantStatus,
    ShippingService,
    ShipmentModal
  },
  data () {
    return {
      loading: false,
      selected: [],
      focusShipment: null,
      showDeleteShipmentsModal: false,
      showInspectShipmentModal: false,
      showEditShipmentsTrackingModal: false
    }
  },
  computed: {
    ...mapGetters(['context']),
    selectedTrackingNumbers () {
      if (this.selected.length === 0) return null
      const trackingNumbers = []
      this.selected.forEach(shipment => {
        if (shipment.tracking_number !== null && shipment.tracking_number !== '') {
          trackingNumbers.push(shipment.tracking_number)
        }
      })
      if (trackingNumbers.length === 0) return null
      if (trackingNumbers.length === 1) return trackingNumbers.join('')
      return trackingNumbers.join(', ')
    }
  },
  methods: {
    inspectShipment (shipment) {
      this.focusShipment = shipment
      this.showInspectShipmentModal = true
    },
    updateTracking (shipment) {
      this.focusShipment = shipment
      this.showEditShipmentsTrackingModal = true
    },
    closeModals () {
      this.focusShipment = null
      this.showInspectShipmentModal = false
      this.showEditShipmentsTrackingModal = false
    },
    copyTrackingNumbers () {
      if (this.selectedTrackingNumbers !== null) {
        this.$copyText(this.selectedTrackingNumbers).then(() => {
          this.$buefy.toast.open({ type: 'is-success', message: 'Tracking numbers copied!' })
        })
      } else {
        this.$buefy.toast.open({ type: 'is-danger', message: 'No tracking numbers copied!' })
      }
    },
    reload () {
      this.selected = []
      this.$emit('fulfillment:updated')
    },
    getTrackingContent (row) {
      let label = 'Not Set'
      if (row.tracking_number) {
        label = row.tracking_number
      } else if (row.error_logs.length) {
        label = 'Has Error!'
      }
      return label
    }
  },
  mounted () {

  },
  props: ['order', 'orderRefreshing']
}
</script>
