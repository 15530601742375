<template>
  <form @submit.prevent="save">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Integration Options</p>
      </header>
      <section class="modal-card-body">
        <div class="mb-lg border-b has-border-lg pb-lg">
          <p class="has-text-weight-bold mb-sm">Learn more about...</p>
          <b-select v-model="detail" expanded>
            <option value="edi">EDI (Managed)</option>
            <option value="console">Supplier Portal</option>
            <option value="shipstation">ShipStation</option>
            <option value="shopify">Shopify</option>
            <option value="bigcommerce">BigCommerce</option>
            <option value="woocommerce">WooCommerce</option>
            <option value="api">API</option>
          </b-select>
        </div>
        <!-- Details -->
        <div class="mb-lg is-size-7">
          <div v-if="detail === 'edi'">
            <p class="has-text-weight-bold mb-sm">EDI (Managed)</p>
            <div class="is-size-7">
              <p>
                fabric trades EDI documents over SFTP through our proprietary, in-house EDI system.
                <strong>There are no additional setup or monthly fees to use EDI.</strong>
                To administer the best possible customer experience, {{ app.platform }} requires
                exchange &amp; testing of the following documents.
              </p>
              <div class="mb-md">
                <div class="columns">
                  <div class="column is-narrow">
                    <div class="content">
                      <ul>
                        <li>846 Inventory Advice</li>
                        <li>850 Purchase Order</li>
                        <li>856 Advance Ship Notice</li>
                      </ul>
                    </div>
                  </div>
                  <div class="column">
                    <div class="content">
                      <ul>
                        <li>860 PO Change Request</li>
                        <li>810 Invoice</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else-if="detail === 'console'">
            <p class="has-text-weight-bold mb-sm">Supplier Portal</p>
            <p>
              All mandatory functions can be performed via the Supplier Portal. There are easy
              workflows for uploading inventory and processing orders.
            </p>
          </div>
          <div v-else-if="detail === 'shipstation'">
            <p class="has-text-weight-bold mb-sm">ShipStation</p>
            <p class="mb-md">
              {{ app.platform }} can be configured to automatically push purchase orders into and pull
              tracking numbers from your ShipStation account - we just need a set of ShipStation API Keys.
            </p>
            <p>
              While ShipStation is excellent for exchanging fulfillment data, it is often not the source
              of inventory data. It also doesn't handle exception flows well (e.g. cancellations), so some
              manual intervention via the Supplier Portal is often required.
            </p>
          </div>
        </div>
        <!-- How it Works -->
        <div v-if="detail !== null" class="is-size-7">
          <div class="mb-md">
            <p class="has-text-weight-bold">How It Works</p>
          </div>
          <div>
            <table class="table is-narrow is-bordered is-fullwidth is-size-7 is-striped">
              <tbody>
                <tr>
                  <th>Inventory</th>
                  <td class="">{{ options[detail].inventory }}</td>
                </tr>
                <tr>
                  <th>Orders</th>
                  <td class="">{{ options[detail].orders }}</td>
                </tr>
                <tr>
                  <th>Fulfilments</th>
                  <td class="">{{ options[detail].shipments }}</td>
                </tr>
                <tr>
                  <th>Cancels &amp; Backorders</th>
                  <td class="">{{ options[detail].changes }}</td>
                </tr>
                <tr>
                  <th>Invoices</th>
                  <td class="">{{ options[detail].invoices }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot space-between">
        <button class="button" type="button" @click="$parent.close()">Close</button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['app'])
  },
  data () {
    return {
      detail: null,
      options: {
        edi: {
          inventory: 'Upload 846 IAs to your EDI outbox.',
          orders: '850 POs will be uploaded to your EDI inbox in real time.',
          shipments: 'Upload 856 ASNs to your EDI outbox.',
          changes: 'Upload 860 Change Orders to your EDI outbox.',
          invoices: 'Upload 810 Invoices to your EDI outbox.'
        },
        console: {
          inventory: 'Upload inventory as needed through the supplier portal using a CSV/Excel template.',
          orders: 'Access and export orders via the portal (and receive real-time notifications when new orders are received).',
          shipments: 'Print shipping labels or register tracking numbers for each order (or upload files for bulk operations)',
          changes: 'Update invidual orders to reflect cancellations or provide estimated shipment dates.',
          invoices: 'Register invoices individually (or upload files for bulk operations). You may also choose to use our auto-invoice feature.'
        },
        shipstation: {
          inventory: 'ShipStation does not provide inventory webhooks, so you will need to upload inventory as needed through the supplier portal using a CSV/Excel template.',
          orders: 'New orders will be automatically sent to your ShipStation account (and you may specify a specific Store ID)',
          shipments: 'We will listen for shipment notifications from ShipStation and automatically register tracking #s',
          changes: 'ShipStation does not provide webhooks for order change events, so you will need to update individual orders in the supplier portal to reflect cancellations or provide estimated shipment dates for backorders. ',
          invoices: 'ShipStation does not provide invoices, so you will need to register invoices manually via the supplier portal (or choose to use our auto-invoice feature).'
        },
        shopify: {
          inventory: 'Shopify can provide inventory updates automatically.',
          orders: 'New orders will be automatically sent to your Shopify account.',
          shipments: 'We will listen for shipment notifications from Shopify and automatically register tracking #s',
          changes: 'We will listen for full order cancels only; partial cancels must be registered through the supplier portal.',
          invoices: 'Shopify does not provide invoices, so you will need to register invoices manually via the supplier portal (or choose to use our auto-invoice feature).'
        },
        bigcommerce: {
          inventory: 'BigCommerce can provide inventory updates automatically.',
          orders: 'New orders will be automatically sent to your BigCommerce store.',
          shipments: 'We can listen for shipment notifications from BigCommerce and automatically register tracking #s.',
          changes: 'We do not listen to order changes from BigCommerce; full & partial cancels must be registered through the supplier portal.',
          invoices: 'BigCommerce does not provide invoices, so you will need to register invoices manually via the supplier portal (or choose to use our auto-invoice feature).'
        },
        woocommerce: {
          inventory: 'WooCommerce can provide inventory updates automatically.',
          orders: 'New orders will be automatically sent to your WooCommerce store.',
          shipments: 'We can listen for shipment notifications from WooCommerce and automatically register tracking #s provided you use the official WooCommerce Shipping plugin.',
          changes: 'We do not listen to order changes from WooCommerce; full & partial cancels must be registered through the supplier portal.',
          invoices: 'WooCommerce does not provide invoices, so you will need to register invoices manually via the supplier portal (or choose to use our auto-invoice feature).'
        },
        api: {
          inventory: 'Update inventory via an Inventory API PATCH request.',
          orders: 'Pull new orders using an Orders API GET request (or listen to our Order/Create webhook)',
          shipments: 'Push shipment notifications via a Shipments API POST request.',
          changes: 'Push order change notifications via an Orders PATCH request.',
          invoices: 'Push invoice notifications via an Invoices API POST request.'
        }
      }
    }
  },
  mounted () {
    this.detail = this.option
  },
  props: {
    option: {
      type: String
    }
  }
}
</script>
