<template>
  <div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Approve Proposal?</p>
      </header>
      <section class="modal-card-body">
        <div>
          <p>
            <label class="label mb-md">
              Do you also want to publish the products on this proposal to an external platform?
            </label>
          </p>
          <b-field>
            <b-radio v-model="showSelectPlatform" :native-value="true">
              Yes
            </b-radio>
          </b-field>
          <b-field>
            <b-radio v-model="showSelectPlatform" :native-value="false">
              No, just mark this proposal approved
            </b-radio>
          </b-field>
        </div>
        <div v-if="showSelectPlatform" class="mt-lg">
          <PlatformAccountSelect v-model="account" />
          <div v-if="account" class="columns is-vcentered">
            <!-- Template Select -->
            <div class="column">
              <b-field label="Template">
                <b-select v-model="template" placeholder="Select Template" :loading="loadingTemplates"
                  :disabled="loadingTemplates || !account" expanded>
                  <option v-for="template in templates" :value="template.id" :key="template.id">
                    <span v-if="template.name"> {{ template.name }}</span>
                    <span v-else> ID: {{ template.id }}</span>
                  </option>
                </b-select>
              </b-field>
            </div>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot space-between">
        <b-button @click="$parent.close()">Close</b-button>
        <b-button type="is-primary" :disabled="!isReady || saving" :loading="saving" @click="save">Approve</b-button>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PlatformAccountSelect from '@/components/PlatformAccountSelect'

export default {
  components: {
    PlatformAccountSelect
  },
  computed: {
    ...mapGetters(['auth', 'context']),
    endpoint () {
      return this.context.route + '/proposals/' + this.proposal.id + '/'
    },
    isReady () {
      let ready = true
      if (this.showSelectPlatform) {
        ready = this.account && this.template
      }
      return ready
    }
  },
  data () {
    return {
      saving: false,
      errors: {},
      template: null,
      templates: [],
      loadingTemplates: false,
      account: null,
      showSelectPlatform: false
    }
  },
  methods: {
    async save () {
      this.saving = true
      if ('require_pricing_approval_on_proposals' in this.proposal.connection.options) {
        await this.approveProducts()
      } else {
        await this.approveProducts()
        await this.approvePricing()
        await this.completeProposal()
      }
      this.$buefy.toast.open({ type: 'is-success', message: 'Proposal approved!' })
      this.$emit('proposal:approved')
      this.saving = false
      this.$parent.close()
    },
    approveProducts () {
      return this.$http.patch(this.endpoint, {
        status: 'approved',
        export_platform_template_id: this.template ? this.template : null,
        export_platform_account_id: this.account ? this.account.id : null
      })
    },
    approvePricing () {
      return this.$http.patch(this.endpoint, {
        status: 'pricing_approved',
        sign_off: this.auth.user.fullName
      })
    },
    completeProposal () {
      return this.$http.patch(this.endpoint, { status: 'completed' })
    },
    getTemplates () {
      if (this.account !== null) {
        this.loadingTemplates = true
        const endpoint = `${this.context.route}/${this.account.platform.code}/${this.account.id}/templates/`
        this.$http.get(endpoint).then(response => {
          this.templates = response.data.results
        }).finally(() => {
          this.loadingTemplates = false
        })
      }
    }
  },
  mounted () {
  },
  watch: {
    account () {
      this.getTemplates()
    }
  },
  props: {
    proposal: {
      type: Object,
      required: true
    }
  }
}
</script>
