<template>
  <div>
    <div class="pa-sm">
      <!-- Filter Header -->
      <div class="columns is-mobile is-marginless">
        <div class="column is-narrow">
          <b-icon :icon="filter.icon"></b-icon>
        </div>
        <div class="column">
          <a href="#" @click.prevent="expanded = !expanded" ><p class="has-text-weight-bold mb-sm">{{ filter.label }}</p></a>
        </div>
        <div class="column is-narrow">
          <b-button type="is-primary" size="is-small" outlined @click="expanded = !expanded">{{ (expanded) ? 'Collapse' : 'Expand' }}</b-button>
        </div>
      </div>
      <!-- Selected Options (Tags) -->
      <div v-if="!expanded" class="pb-md px-md">
        <b-field grouped group-multiline>
          <!-- No filters enabled -->
          <div v-if="timestamps === null">
            <p class="has-text-grey-light mb-sm is-size-7">No option applied for this filter (click 'Expand' to select).</p>
          </div>
          <div v-else>
            <b-tag type="is-primary" attached closable @close="clear">{{ tagLabel }}</b-tag>
          </div>
        </b-field>
      </div>
      <!-- Available -->
      <div v-else class="pb-md px-md">
        <b-field label="Preset">
          <b-select v-model="preset" placeholder="Select Range" expanded  @input="announce">
            <option v-for="option in filter.options" :value="option.value" :key="option.value">{{ option.label }}</option>
            <option value="custom" key="custom">Custom</option>
          </b-select>
        </b-field>
        <div class="columns">
          <div class="column">
            <b-field label="Start Date">
              <b-input type="date" v-model="startDate" @input="customPreset" :disabled="preset !== 'custom'" :max="maxDate"></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="End Date">
              <b-input type="date" v-model="endDate" @input="customPreset" :disabled="preset !== 'custom'" :min="minDate"></b-input>
            </b-field>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    tagLabel () {
      if (this.preset === null) return null

      const start = this.$dates(this.startDate)
      const end = this.$dates(this.endDate)
      // both valid
      if (start.isValid() && end.isValid()) {
        if (start.isSame(end, 'day')) {
          return 'On ' + start.format('MMM D, YYYY')
        }
        return 'From ' + start.format('MMM D, YYYY') + ' to ' + end.format('MMM D, YYYY')
      }
      // if start, but no end...
      if (start.isValid() && !end.isValid()) {
        return 'From ' + this.$dates(this.startDate).format('MMM D, YYYY')
      }
      // if end, but no start...
      if (this.$dates(this.endDate).isValid() && !this.$dates(this.startDate).isValid()) {
        return 'Prior to ' + this.$dates(this.endDate).format('MMM D, YYYY')
      }
      return null
    },
    timestamps () {
      // if a preset is not set, return null
      if (this.preset === null) return null
      // if a preset is selected and it matches one of the named ranges, use those timestamps
      const timestamps = []
      if (this.preset !== null && this.preset in this.filter.options) {
        timestamps.push(this.filter.options[this.preset].start.unix())
        timestamps.push(this.filter.options[this.preset].end.unix())
        return timestamps
      }
      // for all other cases (i.e. 'custom') use the inputs to determine the timestamps
      const start = (this.startDate !== null && this.$dates(this.startDate).isValid())
        ? this.$dates(this.startDate).unix()
        : null
      const end = (this.endDate !== null && this.$dates(this.endDate).isValid())
        ? this.$dates(this.endDate).hour(23).minute(59).second(59).unix()
        : null
      return [start, end]
    },
    maxDate () {
      return (this.endDate !== null && this.$dates(this.endDate).isValid()) ? this.endDate : null
    },
    minDate () {
      return (this.startDate !== null && this.$dates(this.startDate).isValid()) ? this.startDate : null
    }
  },
  data () {
    return {
      // radio input
      expanded: false,
      preset: null,
      startDate: null,
      endDate: null
    }
  },
  methods: {
    announce () {
      this.$emit('input', this.timestamps)
    },
    clear () {
      this.preset = null
      this.startDate = null
      this.endDate = null
      this.announce()
    },
    customPreset () {
      this.preset = 'custom'
      this.$emit('input', this.timestamps)
    }
  },
  created () {
    if (typeof this.value !== 'undefined' && Array.isArray(this.value) && this.value.length > 0) {
      this.preset = 'custom'
      if (this.value[0]) {
        this.startDate = this.$dates.utc(new Date(this.value[0] * 1000)).local().format('YYYY-MM-DD')
      }
      if (this.value[1]) {
        this.endDate = this.$dates.utc(new Date(this.value[1] * 1000)).local().format('YYYY-MM-DD')
      }
    }
  },
  props: ['filter', 'value'],
  watch: {
    preset: function (value) {
      if (value !== 'custom' && value !== null) {
        const range = this.filter.options[this.preset]
        this.startDate = range.start.format('YYYY-MM-DD')
        this.endDate = range.end.format('YYYY-MM-DD')
      }
    }
  }
}
</script>
