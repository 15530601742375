<template>
  <InspectionModal
    :endpoint="endpoint"
    :loadDetail="loadDetail"
    :seed="selectedSubscription">
    <template v-slot:default="props">
      <div>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Subscription</p>
          </header>
          <section class="modal-card-body">
            <!-- Loaded -->
            <div v-if="!props.loading && props.record !== null" class="">
              <!-- Details -->
              <div class="mb-lg">
                <p class="has-text-weight-bold mb-sm">Payments</p>
                <p v-if="props.record.payments.length === 0">No payments found for this subscription.</p>
                <table v-else class="table is-fullwidth is-striped is-narrow is-size-7">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Date</th>
                      <th>Amount</th>
                      <th>Status</th>
                      <th>Period Start</th>
                      <th>Period End</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="payment in props.record.payments" :key="payment.id">
                      <td>{{payment.id}}</td>
                      <td>{{$dates.utc(payment.created_at).local().format('MMM D, YYYY')}}</td>
                      <td>{{$numbers(payment.amount).formatCurrency({ mantissa: 2 })}}</td>
                      <td><span :class="(payment.paid) ? 'is-success': 'is-danger'">{{(payment.paid) ? 'success' : 'failed'}}</span></td>
                      <td>{{$dates.utc(payment.period_start).local().format('MMM D, YYYY')}}</td>
                      <td>{{$dates.utc(payment.period_end).local().format('MMM D, YYYY')}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-- Loading -->
            <div v-else>
              <loading message="Loading payments..." />
            </div>
          </section>
          <footer class="modal-card-foot">
            <button class="button" type="button" @click="$parent.close()">Close</button>
          </footer>
        </div>
      </div>
    </template>
  </InspectionModal>
</template>

<script>
import InspectionModal from '../InspectionModal'
import { mapGetters } from 'vuex'

export default {
  components: {
    InspectionModal
  },
  data () {
    return {
      loadDetail: false
    }
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/subscriptions/' + this.selectedSubscription.id + '/'
    }
  },
  methods: {

  },
  mounted () {

  },
  props: ['selectedSubscription']
}
</script>
