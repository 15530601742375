<template>
  <div>
    <!-- Items -->
    <ProposalVariants :proposal="proposal" :connection="connection" @proposal:updated="reloadProposal" />
  </div>
</template>

<script>
import ProposalVariants from '../components/Detail/ProposalVariants'

export default {
  components: {
    ProposalVariants
  },
  methods: {
    reloadProposal () {
      this.$emit('proposal:updated')
    }
  },
  props: {
    proposal: {
      type: Object,
      required: true
    },
    connection: {
      type: Object,
      required: true
    }
  }
}
</script>
