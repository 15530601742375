<template>
  <div>
    <div v-for="property in formatted" :key="property.key">
      <span class="has-text-weight-bold">{{ $utils.startCase(property.key) }}: </span>
      <span v-html="nl2br(property.value, false)"></span>
    </div>
  </div>
</template>

<script>
import { nl2br } from '@/helpers/nl2br.js'

export default {
  computed: {
    formatted () {
      const json = JSON.parse(this.value)
      const map = Object.keys(json).map((dataKey, index) => {
        return {
          key: dataKey,
          value: json[dataKey]
        }
      })
      return map
    }
  },
  methods: {
    nl2br: nl2br
  },
  mounted () {},
  props: ['value']
}
</script>
