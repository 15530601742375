<template>
  <div>
    <div class="section">
      <div class="container">
        <!-- Page Header -->
        <div class="columns is-vcentered">
          <div class="column is-one-third">
            <breadcrumbs :history="[{ label: 'Catalog', name: context.isSupplier() ? 'catalog' : 'catalog.index' }]" current="Inventory" :hideBack="true" />
          </div>
          <div class="column is-one-third">
            <h1 class="has-text-centered is-size-4">Inventory</h1>
          </div>
        </div>
        <!-- Filters -->
        <div class="has-background-white rounded-lg pa-md mb-xxs">
          <div class="columns">
            <div class="column">
              <InventoryFilters @filter="filter" :applied-filters="filters" />
            </div>
            <div class="column is-narrow">
              <b-field >
                <b-select v-model="orderBy" expanded @input="sort" size="is-medium">
                  <option value="-id">Most Recently Added</option>
                  <option value="id">Oldest</option>
                </b-select>
              </b-field>
            </div>
            <div class="column is-narrow">
              <b-button size="is-medium" class="is-fullwidth" icon-left="download" @click="showExportModal = !showExportModal">Export</b-button>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <!-- Toolbar -->
            <div class="has-background-white rounded-lg px-sm mb-xxs">
              <div class="columns is-vcentered is-mobile is-marginless is-paddingless">
                <!-- Dropdown -->
                <div class="column is-narrow">
                  <b-dropdown :disabled="loading || selected.length === 0">
                    <button class="button" slot="trigger" slot-scope="{ active }">
                      <span>Actions</span>
                      <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
                    </button>
                    <b-dropdown-item has-link><a href="#" @click.prevent class="has-text-grey-light">No Actions</a></b-dropdown-item>
                  </b-dropdown>
                </div>
                <div class="column">
                  <div class="has-text-right is-size-7">
                    <!-- Counts -->
                    <p v-if="loading">Loading...</p>
                    <p v-else>{{ total }} items found.</p>
                  </div>
                </div>
              </div>
            </div>
            <!-- Results -->
            <div>
              <div>
                <b-table
                  :data="results"
                  :checked-rows.sync="selected"
                  :mobile-cards="false"
                  :loading="loading"
                  checkable
                  hoverable
                  class="is-size-7">
                  <template>
                    <!-- Supplier SKU -->
                    <b-table-column
                      v-if="context.isSupplier()"
                      label="SKU"
                      header-class="nowrap"
                      cell-class="nowrap"
                      width="164"
                      v-slot="props">
                      <a href="#"
                        class="has-text-weight-bold has-text-info"
                        @click.prevent="inspectVariant(props.row)">
                        <span>{{ props.row.variant.brand_identifier }}</span>
                      </a>
                    </b-table-column>
                    <!-- Retailer SKU -->
                    <b-table-column
                      v-if="context.isMerchant()"
                      label="Retailer SKU"
                      header-class="nowrap"
                      cell-class="nowrap"
                      width="128"
                      v-slot="props">
                      <a href="#"
                        class="has-text-weight-bold has-text-info"
                        @click.prevent="inspectVariant(props.row)">
                        <span v-if="props.row.variant.retailer_identifiers.length > 0">
                          {{ props.row.variant.retailer_identifiers[0].identifier}}
                        </span>
                        <span v-else class="has-text-grey-light is-italic">Not Set</span>
                      </a>
                    </b-table-column>
                    <!-- Supplier SKU -->
                    <b-table-column
                      label="UPC"
                      header-class="nowrap"
                      cell-class="nowrap"
                      width="164"
                      v-slot="props">
                      <span v-if="props.row.variant.upc">{{ props.row.variant.upc }}</span>
                      <span v-else class="has-text-grey-light is-italic">Not Set</span>
                    </b-table-column>
                    <!-- Supplier -->
                    <b-table-column
                      label="Item"
                      header-class="nowrap is-hidden-mobile"
                      cell-class="nowrap is-hidden-mobile"
                      v-slot="props">
                      <p>{{ $utils.truncate(props.row.variant.name, { length: 64 }) }}</p>
                      <p class="has-text-grey is-size-8">{{ props.row.variant.brand.name }}</p>
                    </b-table-column>
                    <!-- Availability -->
                    <b-table-column
                      label="Availability"
                      header-class="nowrap is-hidden-mobile"
                      cell-class="nowrap is-hidden-mobile"
                      centered
                      width="200"
                      v-slot="props">
                      <div v-if="props.row.discontinued && props.row.discontinued_updated_at">
                        <p class="has-text-weight-bold has-text-centered has-text-danger">Discontinued</p>
                        <p class="has-text-grey is-size-8">{{ $dates.utc(props.row.discontinued_updated_at).local().format('MMM D, YYYY') }}</p>
                      </div>
                      <div v-else-if="props.row.inventory === 0 && props.row.estimated_availability_date === null">
                        <p class="has-text-weight-bold has-text-centered has-text-danger">0</p>
                        <p class="has-text-grey is-size-8">Unknown Return</p>
                      </div>
                      <div v-else-if="props.row.inventory === 0 && props.row.estimated_availability_date !== null">
                        <p class="has-text-weight-bold has-text-centered has-text-danger">0</p>
                        <p class="has-text-grey is-size-8">Returns {{ $dates.utc(props.row.estimated_availability_date).local().format('MMM D, YYYY') }}</p>
                      </div>
                      <div v-else-if="props.row.inventory > 0">
                        <p class="has-text-weight-bold">
                          <span v-if="props.row.inventory === 9999" class="has-text-grey is-italic">Not Managed</span>
                          <span v-else>{{ props.row.inventory }}</span>
                        </p>
                        <p class="has-text-grey is-size-8">Available Now</p>
                      </div>
                    </b-table-column>
                  </template>
                  <template #empty>
                    <div class="py-xxl has-text-grey has-text-centered">
                      {{ (loading) ? "Loading data..." : 'No items found!' }}
                    </div>
                  </template>
                </b-table>
              </div>
              <div class="mt-md">
                <b-pagination
                  :total="total"
                  :current.sync="currentPage"
                  :rounded="true"
                  :per-page="perPage"
                  @change="paginate">
                </b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Inventory Inspection Modal -->
    <b-modal
      :active="selectedVariant !== null"
      has-modal-card
      :can-cancel="['escape', 'x']"
      @close="selectedVariant = null" >
      <InventoryModal :selectedVariant="selectedVariant" />
    </b-modal>

    <!-- Modal: Export  -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showExportModal"
      :can-cancel="['x']"
      ><div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Export Inventory</p>
        </header>
        <section class="modal-card-body">
          <p class="pa-xl has-text-grey has-text-centered">
            Click 'Export' below to have an export delivered to your
            email. Please note that the export will only include
            the items that match your applied filters. Please double check
            your filters before exporting.
          </p>
        </section>
        <footer class="modal-card-foot space-between">
          <button class="button" @click="showExportModal = false">Close</button>
          <b-button type="is-primary" @click="scheduleExport" :loading="exporting" :disabled="exporting">Export</b-button>
        </footer>
      </div>
    </b-modal>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CdnTransform from '@/mixins/CdnTransform.js'
import Exportable from '@/mixins/Exportable'
import Filterable from '@/mixins/Filterable'
import InventoryFilters from '../components/Inventory/InventoryFilters'
import InventoryModal from '@/components/Modals/Variant/InventoryModal'

export default {
  components: {
    InventoryFilters,
    InventoryModal
  },
  computed: {
    ...mapGetters(['context']),
    placeholders () {
      return Math.min(15, this.perPage)
    },
    endpoint () {
      return this.context.route + '/inventory/'
    },
    exportEndpoint () {
      return this.endpoint + 'export-email/'
    }
  },
  data () {
    return {
      key: 'inventory',
      alwaysAttach: {
        short: 1,
        ignore_deleted: 1
      },
      alwaysExport: {
        ignore_deleted: 1
      },
      selected: [],
      selectedVariant: null
    }
  },
  methods: {
    inspectVariant (variant) {
      this.selectedVariant = variant
    }
  },
  mixins: [CdnTransform, Filterable, Exportable],
  mounted () {
    this.load()
  },
  props: []
}
</script>
