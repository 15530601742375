<template>
  <div>
    <!-- Content -->
    <div class="">
      <div class="mb-lg">
        <p class="mb-md has-text-weight-bold has-text-centered">Question #1: Experience</p>
        <p>First, please tell us about your experience level working with other
          marketplaces or direct ship programs (e.g. Amazon, Wayfair, etc).</p>
      </div>
      <b-field>
        <b-radio v-model="experience" native-value="Advanced">
          We currently work with other marketplaces or direct ship programs.
        </b-radio>
      </b-field>
      <b-field>
        <b-radio v-model="experience" native-value="New">
          This will be our first experience working with a marketplace or direct ship program.
        </b-radio>
      </b-field>
    </div>
    <!-- Navigate -->
    <div class="py-lg">
      <div class="columns is-centered">
        <div class="column">
          <b-button
            type="is-light rounded-lg"
            size="is-medium"
            @click="goBack"
            expanded
            ><b-icon icon="arrow-left"></b-icon>
          </b-button>
        </div>
        <div class="column">
          <b-button
            type="is-primary rounded-lg"
            size="is-medium"
            expanded
            :disabled="!ready || saving"
            :loading="saving"
            @click="goForward"
          >Continue</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['app', 'context']),
    body () {
      return {
        metadata: [{
          name: 'ob_marketplace_experience',
          display_name: 'Marketplace Experience',
          type: 'string',
          value: this.experience
        }]
      }
    },
    ready () {
      return (this.experience)
    }
  },
  data () {
    return {
      saving: false,
      experience: null
    }
  },
  methods: {
    goBack () {
      this.$emit('navigate', 'Intro')
    },
    goForward () {
      this.saving = true
      const endpoint = this.context.route + '/'
      this.$http.patch(endpoint, this.body).then(() => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Thank you!' })
        this.$emit('metadata:saved')
        if (this.connection.level === 'products_only') {
          this.$emit('navigate', 'Call')
        } else {
          this.$emit('navigate', 'Integration')
        }
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    if ('ob_marketplace_experience' in this.brand.metadata) {
      this.experience = this.brand.metadata.ob_marketplace_experience.values[0].value
    }
  },
  props: {
    connection: {
      type: Object,
      required: true
    },
    task: {
      type: Object,
      required: true
    },
    brand: {
      type: Object,
      required: true
    }
  }
}
</script>
