<template>
  <div>
    <div v-for="notification in merchantNotifications" :key="notification.action" class="mb-md">
      <PushNotification
        :notifications="pushNotifications"
        :action="notification.action"
        :title="notification.title"
        :text="notification.text" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PushNotification from '@/components/Models/Notifications/PushNotification'

export default {
  components: {
    PushNotification
  },
  computed: {
    ...mapGetters(['context']),
    merchantNotifications () {
      const notifications = []
      this.availableNotifications.forEach(notification => {
        if (notification.context.includes('retailer')) {
          notifications.push(notification)
        }
      })
      return notifications
    }
  },
  data () {
    return {
      availableNotifications: [{
        action: 'Connection Invite Accepted',
        title: 'Supplier Invite Accepted',
        text: 'Notifications when a new supplier partner accepts an invitation to join your program.',
        context: ['retailer']
      }, {
        action: 'Brand Completed Onboarding',
        title: 'Supplier Completed Onboarding',
        text: 'Notifications when a new supplier partner completes onboarding.',
        context: ['retailer']
      }, {
        action: 'Connection Memo Saved',
        title: 'Connection Notifications',
        text: 'Receive real-time alerts related to your connections & connection notes.',
        context: ['retailer']
      }]
    }
  },
  methods: {

  },
  mounted () {

  },
  props: {
    pushNotifications: {
      type: Array,
      required: true
    }
  }
}
</script>
