<template>
  <div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Add to Open Issue</p>
      </header>
      <section class="modal-card-body">
        <b-select
          v-model="selectedIssue"
          :placeholder="'Select Issue'"
          expanded
          :loading="loading"
          :disabled="loading"
          class="mb-md">
          <option
            v-for="issue in issues"
            :value="issue"
            :key="issue.id"
          >{{ issue.title }}</option>
        </b-select>
        <div v-if="selectedIssue!== null">
          <p class="is-size-6 has-text-weight-bold">Issue Summary</p>
          <p class="is-size-7 has-text-grey-light">{{selectedIssue.message}}</p>
        </div>
        <p class="has-text-grey-light">{{this.variants.length}} item will be added when this issue is created.</p>
      </section>
      <footer class="modal-card-foot space-between" >
        <b-button @click="$parent.close()">Close</b-button>
        <b-button
          type="is-primary"
          :disabled="saving || !isReady"
          :loading="saving"
          @click="save">Add to Issue</b-button>
      </footer>
    </div>
  </div>
  </template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['context']),
    json () {
      const json = {
        proposal_variants: []
      }
      this.variants.forEach(variant => {
        json.proposal_variants.push({ id: variant.proposal_variant.id })
      })
      return json
    },
    isReady () {
      return (
        this.selectedIssue !== null && this.selectedIssue
      )
    }
  },
  data () {
    return {
      selectedIssue: null,
      saving: false,
      errors: {},
      loading: true,
      issues: []
    }
  },
  methods: {
    load () {
      const endpoint = this.context.route + '/proposals/' + this.proposal.id + '/issues/'
      this.$http.get(endpoint).then(response => {
        // show only open issues
        this.issues = response.data.results.filter(el => !el.is_archived && !el.is_closed && !el.is_resolved)
        this.loading = false
      })
    },
    save () {
      this.saving = true
      const endpoint = this.context.route + '/proposals/' + this.proposal.id + '/issues/' + this.selectedIssue.id + '/'
      this.$http.patch(endpoint, this.json).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Issue created successfully!' })
        this.$emit('issue:created')
        this.$parent.close()
      }).catch((error) => {
        this.errors = error.response.data
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    this.load()
  },
  props: {
    proposal: {
      type: Object,
      required: true
    },
    variants: {
      type: Array,
      required: true
    }
  }
}
</script>
