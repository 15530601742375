<template>
  <div>
    <form @submit.prevent="save">
      <div class="columns">
        <div class="column">
          <div class="has-background-white rounded-lg">
            <!-- Loaded -->
            <div v-if="!loading && template !== null" class="pa-lg pb-lg">
              <!-- Template Name -->
              <div class="columns">
                <div :class="['column', { 'is-half': templateType === 'general' }]">
                  <b-field label="Template Name" :type="('name' in errors) ? 'is-danger' : ''"
                    :message="('name' in errors) ? errors['name'][0] : ''">
                    <b-input name="name" v-model="name" @input="isDirty = true"></b-input>
                  </b-field>
                </div>
                <div v-if="templateType === 'general'" class="column is-half">
                  <b-field label="Attribute Value Transformer (optional)">
                    <b-select type="text" v-model="attributeValueTransformer" expanded @input="isDirty = 1">
                      <option v-for="item in transformerOptions" :key="item.id" :value="item.id">
                        {{ item.name }}
                      </option>
                    </b-select>
                  </b-field>
                </div>
              </div>
              <!-- Data Type & Direction -->
              <div class="columns">
                <div class="column is-half">
                  <b-field label="Data Type">
                    <b-select type="text" v-model="dataType" expanded :disabled="true" @input="isDirty = 1">
                      <option value="products">Products</option>
                      <option value="inventory">Inventory</option>
                    </b-select>
                  </b-field>
                </div>
                <div class="column is-half">
                  <b-field label="Direction">
                    <b-select type="text" v-model="direction" expanded :disabled="true" @input="isDirty = 1">
                      <option value="import">Import</option>
                      <option value="export">Export</option>
                    </b-select>
                  </b-field>
                </div>
              </div>
            </div>
            <div class="border-t">
              <div class="columns is-vcentered is-marginless">
                <div class="column is-hidden-mobile">
                  <div class="px-sm">
                    <b-button v-if="templateType === 'general'" type="is-light rounded-lg" @click="cloneTemplate">Clone
                      Template</b-button>
                  </div>
                  <div v-if="templateType !== 'general'" class="px-sm has-text-grey-light">
                    <i v-if="isDirty">Click 'Update Template' to save your changes</i>
                  </div>
                </div>
                <div class="column is-narrow">
                  <div class="px-sm">
                    <b-button v-if="templateType === 'general'" type="is-light rounded-lg"
                      @click="downloadSample">Download Sample</b-button>
                    <b-button v-if="templateType === 'fabric'" type="is-light rounded-lg"
                      @click="showConfirmModal = true">Delete Template</b-button>
                  </div>
                </div>
                <div class="column is-narrow">
                  <div class="px-sm">
                    <b-button native-type="submit" type="is-primary rounded-lg" :disabled="!ready || saving || !isDirty"
                      :loading="saving">Update Template</b-button>
                  </div>
                </div>
              </div>
              <div v-if="templateType === 'general'" class="ml-lg has-text-grey-light">
                <i v-if="isDirty">Click 'Update Template' to save your changes</i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <!-- Clone Template Modal -->
    <b-modal has-modal-card :active.sync="showCloneTemplateModal" :can-cancel="['escape', 'x']">
      <CloneTemplateModal :endpoint="this.cloneEndpoint" />
    </b-modal>
    <!-- Delete Template Modal -->
    <b-modal has-modal-card scroll="keep" :active.sync="showConfirmModal" :can-cancel="['x']">
      <ConfirmationModal :label="`Delete Template: ${name}`"
        :reason="'Are you sure you want to delete this template? This action cannot be reversed.'"
        :confirmButtonText="'Delete'" :confirmButtonType="'is-danger'" :saving="deleting"
        @modal:confirm="deleteTemplate" @modal:close="showConfirmModal = false" />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import CloneTemplateModal from './CloneTemplateModal'
import ConfirmationModal from '@/components/Modals/ConfirmationModal'
export default {
  components: {
    CloneTemplateModal,
    ConfirmationModal
  },
  computed: {
    ...mapGetters(['api', 'context']),
    ready () {
      return this.isDirty && this.name && this.dataType && this.direction
    },
    endpoint () {
      let endpoint = this.context.route + '/templates/' + this.template.id + '/'
      if (this.templateType === 'general') {
        return endpoint
      } else {
        endpoint = this.context.route + `/${this.templateType}/${this.$route.params.id}/templates/${this.$route.params.templateId}/`
        return endpoint
      }
    },
    cloneEndpoint () {
      return `${this.context.route}/templates/${this.template.id}/clone/`
    },
    baseTransformerEndpoint () {
      return `${this.context.route}/templates/${this.template.id}/base-transformers/`
    },
    transformerEndpoint () {
      return `${this.context.route}/transformers/`
    }
  },
  data () {
    return {
      isDirty: false,
      saving: false,
      name: null,
      dataType: null,
      direction: null,
      errors: {},
      showCloneTemplateModal: false,
      deleting: false,
      showConfirmModal: false,
      attributeValueTransformer: null,
      transformerOptions: []
    }
  },
  created () {
    this.fetchTransformerOptions()
  },
  methods: {
    async save () {
      this.saving = true
      try {
        if (this.templateType === 'general' && this.attributeValueTransformer !== null) {
          await this.applyTransformer()
        }
        await this.updateTemplate()
      } catch (error) {
        this.$buefy.toast.open({ type: 'is-danger', message: 'An unexpected error occurred!' })
      } finally {
        this.saving = false
        this.isDirty = false
      }
    },
    updateTemplate () {
      const body = {
        name: this.name,
        data_type: this.dataType,
        direction: this.direction
      }
      this.$http.patch(this.endpoint, body).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Template Updated!' })
      })
    },
    applyTransformer () {
      const transformerBody = {
        transformer: {
          id: this.attributeValueTransformer
        }
      }
      this.$http.post(this.baseTransformerEndpoint, transformerBody).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Transformer added!' })
      })
    },
    downloadSample () {
      const link = document.createElement('a')
      link.target = '_blank'
      // Download sample location
      if (this.template.sample_location) {
        link.href = this.template.sample_location
        document.body.appendChild(link)
        link.click()
      } else {
        // Download template mapping, if sample location is not provided
        this.downloadTemplateMapping(link)
      }
    },
    cloneTemplate () {
      this.showCloneTemplateModal = true
    },
    downloadTemplateMapping (link) {
      const endpoint = this.api.baseRoute + '/' + this.context.route + '/templates/' + this.template.id + '/mappings/?pagination=0&format=xlsx'
      axios({
        url: endpoint,
        method: 'GET',
        responseType: 'blob' // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        link.href = url
        link.setAttribute('download', this.template.name + '.xlsx')
        document.body.appendChild(link)
        link.click()
        setTimeout(() => window.URL.revokeObjectURL(url), 0)
      })
    },
    deleteTemplate () {
      this.deleting = true
      this.$http.delete(this.endpoint).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Template deleted!' })
        this.$router.push({ name: 'fabric.templates' })
        this.deleting = false
      })
    },
    fetchTransformerOptions () {
      this.$http.get(this.transformerEndpoint).then(response => {
        response.data.results.forEach(data => {
          this.transformerOptions.push({ id: data.id, name: data.name })
        })
      })
    }
  },
  mounted () {
    this.name = this.template.name
    this.dataType = this.template.data_type
    this.direction = this.template.direction
  },
  props: {
    template: {
      type: Object,
      required: true
    },
    loading: {
      type: String
    },
    templateType: {
      type: String,
      enum: ['general', 'bigcommerce', 'shopify', 'fabric'],
      default: 'general'
    }
  }
}
</script>
