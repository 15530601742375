export default {
  pending: {
    label: 'Pending',
    tooltip: 'Returns that are pending supplier approval.',
    filters () {
      return {
        preset: 'pending',
        search: {},
        facets: {
          status: [{
            label: 'Pending',
            value: 'pending'
          }]
        },
        timestamps: {}
      }
    }
  }
}
