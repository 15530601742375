<template>
<div class="columns">
  <div class="column is-one-fifth">
    <p class="has-text-weight-bold is-size-6 mb-sm">Services</p>
    <p class="has-text-grey is-size-7">
      Subscribe to our services.
    </p>
  </div>
  <div class="column is-three-fifths">
    <div v-if="loading">
      <div class="has-background-white rounded-xl">
        <div class="pa-lg pb-lb">
          <div>
            <div class="pa-md" v-for="index in 3" :key="index">
              <div class="placeholder placeholder-title"></div>
              <div class="placeholder placeholder-sentence" style="width:50%"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div v-for="service in subscriptionServices" :key="service.id" class="has-background-white rounded-xl">
        <div v-if="!hasActiveSubscription || service.subscription !== null" class="pa-lg pb-lb mb-lg">
          <div class="mb-xl">
            <div class="columns is-vcentered">
              <div class="column has-text-centered">
                <label class="label is-size-4">
                  {{ service.name }}
                  <b-tag v-if="service.subscription !== null" :type="statusType(service.subscription.status)" size="is-small">
                  <span class="is-capitalized">{{service.subscription.status}}</span></b-tag>
                </label>
                <table class="table is-striped is-bordered" style="margin-left:auto;margin-right:auto;">
                  <thead>
                    <tr>
                      <th class="has-text-centered">Sales per month</th>
                      <th class="has-text-centered">Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="has-text-centered">$0 - $10,000</td>
                      <td class="has-text-right">$59.99</td>
                    </tr>
                    <tr>
                      <td class="has-text-centered">$10,001 - $50,000</td>
                      <td class="has-text-right">$249.99</td>
                    </tr>
                    <tr>
                      <td class="has-text-centered">$50,001 - $100,000</td>
                      <td class="has-text-right">$399.99</td>
                    </tr>
                    <tr>
                      <td class="has-text-centered">$100,0001 - $500,000</td>
                      <td class="has-text-right">$1,499.99</td>
                    </tr>
                    <tr>
                      <td class="has-text-centered">$500,001 - &#8734;</td>
                      <td class="has-text-right">$1,999.99</td>
                    </tr>
                  </tbody>
                </table>
                <p v-if="service.subscription !== null && service.subscription.status !== 'pending'">
                  <a v-if="service.subscription !== null" href="#" @click.prevent="inspectUsage = service.subscription">
                    <span class="mb-md is-size-6 is-italic">
                      Next invoice on
                      {{ $dates.utc(service.subscription.expires_at).local().format('MMM D, YYYY')}}
                      for {{ $numbers(nextInvoiceAmount(service.subscription)).formatCurrency({mantissa: 2, thousandSeparated: true})}}
                    </span>
                  </a>
                  <br>
                  <a v-if="service.subscription !== null"
                    href="#"
                    class="is-size-7 has-text-info"
                    @click.prevent="inspectSubscription = service.subscription"
                  >View payments history.</a>
                </p>
                <br>
                <b-button v-if="service.subscription !== null" type="is-primary" @click="manage(service)" :disabled="loading">Manage</b-button>
                <b-button v-else type="is-success" @click="subscribe(service)" :disabled="loading || subscribing">Subscribe</b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <b-modal
    v-if="(inspectSubscription !== null)"
    has-modal-card
    :active="(inspectSubscription !== null)"
    :can-cancel="['escape', 'x']"
    @close="closeModals"
    ><SubscriptionModal :selectedSubscription="inspectSubscription" />
  </b-modal>
  <b-modal
    v-if="(inspectUsage !== null)"
    has-modal-card
    :active="(inspectUsage !== null)"
    :can-cancel="['escape', 'x']"
    @close="closeModals"
    ><UsageModal :selectedSubscription="inspectUsage" />
  </b-modal>
</div>
</template>
<script>
import { mapGetters } from 'vuex'
import SubscriptionModal from '@/components/Modals/Subscription/SubscriptionModal'
import UsageModal from '@/components/Modals/Subscription/UsageModal'

export default {
  components: {
    SubscriptionModal,
    UsageModal
  },
  computed: {
    ...mapGetters(['context', 'services']),
    endpoint () {
      return `${this.context.route}/services/`
    },
    subscriptionEndpont () {
      return `${this.context.route}/subscriptions/`
    },
    hasActiveSubscription () {
      return this.subscriptionServices.find(service => service.subscription !== null && service.subscription.status === 'active') !== undefined
    }
  },
  data () {
    return {
      loading: true,
      subscribing: false,
      subscriptionServices: [],
      inspectSubscription: null,
      inspectUsage: null,
      priceBreaks: {
        10000: 59.99,
        50000: 249.99,
        100000: 399.99,
        500000: 1499.99,
        Infinity: 1999.99
      }
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint).then(response => {
        this.subscriptionServices = response.data.results
        const statuses = ['active', 'paused', 'expired', 'canceled']
        this.subscriptionServices.forEach(service => {
          service.subscription = this.subscriptions.find(subscription => subscription.service.id === service.id && statuses.includes(subscription.status)) || null
        })
      })
      this.loading = false
    },
    subscribe (service) {
      this.subscribing = true
      this.$http.post(`${this.endpoint}${service.id}/subscribe/`).then(response => {
        const stripe = window.Stripe(this.services.stripe.key)
        // Call Stripe.js method to redirect to the new Checkout page
        stripe.redirectToCheckout({
          sessionId: response.data.checkout_session_id
        })
      })
    },
    manage (service) {
      this.$http.post(`${this.subscriptionEndpont}${service.subscription.id}/customer-portal/`).then(response => {
        window.location.href = response.data.url
      })
    },
    closeModals () {
      this.inspectSubscription = null
      this.inspectUsage = null
    },
    statusType (status) {
      switch (status) {
        case 'active':
          return 'is-success is-light'
        case 'pending':
          return 'is-light'
        case 'paused':
          return 'is-dark'
        default:
          return 'is-danger is-light'
      }
    },
    periodUsage (subscription) {
      return subscription.period_usage.reduce((acumulator, currentValue) => acumulator + currentValue.quantity, 0)
    },
    nextInvoiceAmount (subscription) {
      const breaks = Object.keys(this.priceBreaks)
      const usage = this.periodUsage(subscription)
      for (let i = 0; i < breaks.length; i++) {
        if (usage >= breaks[i]) {
          continue
        } else {
          return this.priceBreaks[breaks[i]]
        }
      }
      return 0
    }
  },
  mounted () {
    this.load()
  },
  props: {
    subscriptions: {
      type: Array,
      required: true
    }
  }
}
</script>
