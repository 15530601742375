import numbro from 'numbro'
import enGB from 'numbro/dist/languages/en-GB.min.js'

numbro.registerLanguage(enGB)

export default {
  install: function (Vue) {
    Object.defineProperty(Vue.prototype, '$numbers', { value: numbro })
  }
}
