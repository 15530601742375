<template>
  <tr>
    <th style="width:200px">{{ header }}</th>
    <td>
      <b-field>
        <b-autocomplete
          v-model="search"
          placeholder="Select Attribute..."
          :keep-first="true"
          :open-on-focus="true"
          :data="filteredAttributes"
          field="name"
          @select="setAttribute"
          :clearable="true"
          expanded>
        </b-autocomplete>
      </b-field>
    </td>
    <td>
      <b-field>
        <b-select v-model="target" expanded @input="announce">
          <option value="product">Product</option>
          <option value="variant">Item</option>
        </b-select>
      </b-field>
    </td>
    <td>
      <b-field>
        <b-input v-model="priority" expanded @input="announce" />
      </b-field>
    </td>
    <td v-show="direction === 'import'">
      <b-field>
        <b-checkbox v-model="isRequired" @input="announce">Required?</b-checkbox>
      </b-field>
    </td>
  </tr>
</template>

<script>
export default {
  computed: {
    filteredAttributes () {
      if (!this.search) return this.attributes
      return this.attributes.filter(option => {
        return (option.name.toString().toLowerCase().indexOf(this.search.toLowerCase()) >= 0)
      })
    }
  },
  data () {
    return {
      search: null,
      selected: null,
      target: 'variant',
      isRequired: false,
      priority: (parseInt(this.index) + 1) * 10
    }
  },
  methods: {
    setAttribute (attribute = null) {
      this.selected = attribute
      this.announce()
    },
    announce () {
      if (this.selected && this.target && this.priority) {
        this.$emit('announce', {
          header: this.header,
          target: this.target,
          priority: this.priority,
          isRequired: (this.isRequired) ? 1 : 0,
          attribute: this.selected
        })
      }
    }
  },
  mounted () {
    let search = this.header
    const common = [
      { lookup: 'SKU', mapTo: 'SKU' },
      { lookup: 'Name', mapTo: 'Name' },
      { lookup: 'Title', mapTo: 'Name' },
      { lookup: 'Item SKU', mapTo: 'SKU' },
      { lookup: 'Vendor SKU', mapTo: 'SKU' },
      { lookup: 'Item Number', mapTo: 'SKU' },
      { lookup: 'Product SKU', mapTo: 'Parent Identifier' },
      { lookup: 'Product Number', mapTo: 'Parent Identifier' },
      { lookup: 'Style', mapTo: 'Style' },
      { lookup: 'Style Number', mapTo: 'Parent Identifier' },
      { lookup: 'Style #', mapTo: 'Parent Identifier' },
      { lookup: 'Product Name', mapTo: 'Parent Name' },
      { lookup: 'Style Name', mapTo: 'Parent Name' },
      { lookup: 'Retailer SKU', mapTo: 'Retailer SKU []' },
      { lookup: 'UPC', mapTo: 'UPC' },
      { lookup: 'GTIN', mapTo: 'UPC' },
      { lookup: 'Category', mapTo: 'Category' },
      { lookup: 'Inventory', mapTo: 'Warehouse Inventory []' }
    ]
    const mapped = common.find(attribute => {
      return attribute.lookup.toLowerCase().indexOf(this.header.toLowerCase()) >= 0
    })
    if (mapped) {
      search = mapped.mapTo
    }
    // search attributes list for a match to prepopulate the selection
    const match = this.attributes.find(attribute => {
      return attribute.name.toLowerCase().indexOf(search.toLowerCase()) >= 0
    })
    if (match) {
      this.search = match.name
      this.selected = match
      this.announce(match)
    }
  },
  props: {
    header: {
      type: String,
      required: true
    },
    attributes: {
      type: Array,
      required: true
    },
    index: {
      type: [String, Number],
      required: true
    },
    direction: {
      type: String,
      required: true
    }
  }
}
</script>
