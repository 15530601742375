<template>
  <div>
    <!-- Intro -->
    <div>
      <p class="mb-md">
        {{ app.platform }} supports multiple methods to trade inventory, order,
        shipment, &amp; invoice data. For low to medium order volumes, we
        recommend using the {{ app.platform }} Supplier Portal (that includes import/export
        tools to process orders in bulk).
        For higher volumes, using EDI or the {{ app.platform }} API are likely more efficient.
      </p>
      <p class="mb-md">
        Which of the following options best describes how you would prefer to integrate to {{ app.productName }}?
      </p>
    </div>
    <!-- Options -->
    <div class="mb-lg">
      <div v-if="!loading">
        <p class="has-text-weight-bold has-text-centered mb-sm">
          Integration Options
        </p>
        <!-- EDI (Managed) -->
        <div class="has-background-light rounded-lg pa-md px-lg mb-xs">
          <div class="columns is-vcentered">
            <div class="column">
              <div class="pt-sm">
                <b-field><b-radio v-model="integrationOptionCode" native-value="self-serve-edi">EDI: Managed Onboarding</b-radio></b-field>
              </div>
            </div>
            <div class="column is-narrow">
              <a href="#" @click.prevent="focus('edi')" class="has-text-info is-size-7">Learn More</a>
            </div>
          </div>
        </div>
        <!-- EDI (self-serve) -->
        <!-- <div class="has-background-light rounded-lg pa-md px-lg mb-xs">
          <div class="columns is-vcentered">
            <div class="column">
              <div class="pt-sm">
                <b-field><b-radio v-model="integrationOptionCode" native-value="self-serve-edi">EDI: Managed Onboarding</b-radio></b-field>
              </div>
            </div>
            <div class="column is-narrow">
              <a href="#" @click.prevent="focus('edi')" class="has-text-info is-size-7">Learn More</a>
            </div>
          </div>
        </div> -->
        <!-- Portal -->
        <div class="has-background-light rounded-lg pa-md px-lg mb-xs">
          <div class="columns is-vcentered">
            <div class="column">
              <div class="pt-sm">
                <b-field><b-radio v-model="integrationOptionCode" native-value="console">{{ app.platform }} Supplier Portal</b-radio></b-field>
              </div>
            </div>
            <div class="column is-narrow">
              <a href="#" @click.prevent="focus('console')" class="has-text-info is-size-7">Learn More</a>
            </div>
          </div>
        </div>
        <!-- ShipStation -->
        <div class="has-background-light rounded-lg pa-md px-lg mb-xs">
          <div class="columns is-vcentered">
            <div class="column">
              <div class="pt-sm">
                <b-field><b-radio v-model="integrationOptionCode" native-value="shipstation">ShipStation</b-radio></b-field>
              </div>
            </div>
            <div class="column is-narrow">
              <a href="#" @click.prevent="focus('shipstation')" class="has-text-info is-size-7">Learn More</a>
            </div>
          </div>
        </div>
        <!-- Shopify -->
        <div class="has-background-light rounded-lg pa-md px-lg mb-xs">
          <div class="columns is-vcentered">
            <div class="column">
              <div class="pt-sm">
                <b-field><b-radio v-model="integrationOptionCode" native-value="shopify">Shopify</b-radio></b-field>
              </div>
            </div>
            <div class="column is-narrow">
              <a href="#" @click.prevent="focus('shopify')" class="has-text-info is-size-7">Learn More</a>
            </div>
          </div>
        </div>
        <!-- BigCommerce -->
        <div class="has-background-light rounded-lg pa-md px-lg mb-xs">
          <div class="columns is-vcentered">
            <div class="column">
              <div class="pt-sm">
                <b-field><b-radio v-model="integrationOptionCode" native-value="bigcommerce">BigCommerce</b-radio></b-field>
              </div>
            </div>
            <div class="column is-narrow">
              <a href="#" @click.prevent="focus('bigcommerce')" class="has-text-info is-size-7">Learn More</a>
            </div>
          </div>
        </div>
        <!-- WooCommerce -->
        <div class="has-background-light rounded-lg pa-md px-lg mb-xs">
          <div class="columns is-vcentered">
            <div class="column">
              <div class="pt-sm">
                <b-field><b-radio v-model="integrationOptionCode" native-value="woocommerce">WooCommerce</b-radio></b-field>
              </div>
            </div>
            <div class="column is-narrow">
              <a href="#" @click.prevent="focus('woocommerce')" class="has-text-info is-size-7">Learn More</a>
            </div>
          </div>
        </div>
        <!-- API -->
        <div class="has-background-light rounded-lg pa-md px-lg mb-xs">
          <div class="columns is-vcentered">
            <div class="column">
              <div class="pt-sm">
                <b-field><b-radio v-model="integrationOptionCode" native-value="api">API</b-radio></b-field>
              </div>
            </div>
            <div class="column is-narrow">
              <a href="#" @click.prevent="focus('api')" class="has-text-info is-size-7">Learn More</a>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <loading />
      </div>
    </div>
    <!-- Navigate -->
    <div class="py-lg">
      <div class="columns is-centered">
        <div class="column">
          <b-button
            type="is-light rounded-lg"
            size="is-medium"
            @click="goBack"
            expanded
            ><b-icon icon="arrow-left"></b-icon>
          </b-button>
        </div>
        <div class="column">
          <b-button
            type="is-primary rounded-lg"
            size="is-medium"
            expanded
            :disabled="!ready || saving"
            :loading="saving"
            @click="goForward"
          >Complete Task</b-button>
        </div>
      </div>
    </div>
    <!-- More Info Modal -->
    <b-modal
      :has-modal-card="true"
      :active.sync="showDetailsModal"
      :can-cancel="['x']"
      @close="focusOption = null"
    ><OptionsModal :option="focusOption" />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import OptionsModal from './OptionsModal'

export default {
  components: {
    OptionsModal
  },
  computed: {
    ...mapGetters(['app', 'context']),
    ready () {
      return (this.integrationOptionCode)
    },
    conditionalTask () {
      let task = null
      if (this.integrationOptionCode) {
        task = this.$utils.find(this.tasks, ['code', this.integrationOptionCode])
      }
      return (typeof task !== 'undefined') ? task : null
    },
    loading () {
      return (this.loadingTasks)
    }
  },
  data () {
    return {
      loadingTasks: true,
      preference: null,
      tasks: [],
      integrationOptionCode: null,
      focusOption: null,
      showDetailsModal: false,
      saving: false
    }
  },
  methods: {
    goBack () {
      this.$router.push({ name: 'onboarding.tasks', params: { connection: this.connection.id } })
    },
    async goForward () {
      this.saving = true
      await this.savePreference()
      await this.addConditionalTask()
      await this.addProductImportTask()
      await this.completeTask()
      this.saving = false
    },
    savePreference () {
      const endpoint = this.context.route + '/connections/' + this.connection.id + '/'
      const json = {
        integration_type: this.integrationOptionCode === 'self-serve-edi' ? 'edi' : this.integrationOptionCode
      }
      return this.$http.patch(endpoint, json).then(() => {
        this.$emit('connection:updated')
      })
    },
    addConditionalTask () {
      if (this.conditionalTask) {
        const endpoint = this.context.route + '/assigned-tasks/'
        const json = {
          onboarding_task: { id: this.conditionalTask.id }
        }
        return this.$http.post(endpoint, json)
      }
    },
    addProductImportTask () {
      let task = this.$utils.find(this.tasks, ['code', 'approve-import-request'])
      if ('merchandise_via_proposals' in this.connection.options) {
        task = this.$utils.find(this.tasks, ['code', 'import-template'])
      }
      if (typeof task !== 'undefined') {
        const endpoint = this.context.route + '/assigned-tasks/'
        const json = {
          onboarding_task: { id: task.id }
        }
        return this.$http.post(endpoint, json)
      }
    },
    completeTask () {
      const endpoint = this.context.route + '/assigned-tasks/' + this.$route.params.task + '/complete/'
      return this.$http.put(endpoint).then(() => {
        this.$router.push({ name: 'onboarding.tasks', params: { connection: this.connection.id } })
      })
    },
    loadTasks () {
      this.loadingTasks = true
      const endpoint = this.context.route + '/onboarding-tasks/?type=conditional'
      this.$http.get(endpoint).then(response => {
        this.tasks = response.data.results
        this.loadingTasks = false
      })
    },
    focus (option) {
      this.focusOption = option
      this.showDetailsModal = true
    }
  },
  mounted () {
    this.loadTasks()
    if (this.connection.integration_type) {
      this.integrationOptionCode = this.connection.integration_type
    }
  },
  props: {
    connection: {
      type: Object,
      required: true
    },
    task: {
      type: Object,
      required: true
    }
  }
}
</script>
