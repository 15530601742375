export default {
  pushOrderErrors: {
    label: 'Push Orders',
    tooltip: 'Orders that failed to push to Shopify',
    filters () {
      return {
        preset: 'pushOrders',
        search: {},
        facets: {
          module_name: [{
            value: 'module_name',
            label: 'push_order'
          }]
        },
        timestamps: {}
      }
    }
  }
}
