<template>
  <form @submit.prevent="save" novalidate autocomplete="off">
    <div class="columns">
      <div class="column">
        <div class="has-background-white rounded-lg">
          <!-- Loaded -->
          <div class="pa-lg pb-xl">
            <div class="mb-lg">
              <b-field expanded label="Currency">
                <b-select
                  v-model="selectedOption"
                  placeholder="Select Currency"
                  expanded
                  @input="isDirty = true">
                  <option v-for="option in options" :key="option.id" :value="option">{{ option.name }}</option>
                </b-select>
              </b-field>
            </div>
          </div>
          <div class="border-t">
            <div class="columns is-vcentered is-marginless">
              <div class="column is-hidden-mobile">
                <div class="px-sm has-text-grey-light">
                  <i v-if="isDirty">Click 'Update' to save your changes</i>
                </div>
              </div>
              <div class="column is-narrow">
                <div class="px-sm">
                  <b-button
                    native-type="submit"
                    type="is-primary rounded-lg"
                    :disabled="saving || !isDirty"
                  >Update</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  computed: {
    ...mapGetters(['app', 'context']),
    loading () {
      return this.saving
    },
    endpoint () {
      const path = this.context.isMerchant() ? '/retailer-preferences/' : '/brand-preferences/'
      return this.context.route + path
    },
    json () {
      const preferences = [{
        preference: { id: this.preferenceId },
        option: { id: this.selectedOption.id }
      }]
      return preferences
    }
  },
  data () {
    return {
      isDirty: false,
      errors: [],
      saving: false,
      options: [],
      selectedOption: null,
      preferenceId: null,
      currencyOptions: null
    }
  },
  methods: {
    save () {
      this.saving = true
      this.$http.post(this.endpoint, this.json).then(response => {
        this.isDirty = false
        this.$buefy.toast.open({ type: 'is-success', message: 'Preferences updated!' })
      }).catch(error => {
        if (error.response && error.response.status === 400) {
          this.errors = error.response.data
        }
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    // locate preferences
    this.currencyOptions = this.$utils.find(this.preferences, ['code', 'currency'])
    if (typeof this.currencyOptions !== 'undefined') {
      this.preferenceId = this.currencyOptions.id
      // set the options
      this.options = this.currencyOptions.options.map(option => {
        return {
          id: option.id,
          name: option.name
        }
      })
    }
  },
  props: ['preferences'],
  watch: {
    currencyOptions (options) {
      // set the current value
      if (this.context.isMerchant()) {
        if (options.retailer_preference && options.retailer_preference !== null) {
          this.selectedOption = {
            id: options.retailer_preference.option_id,
            name: options.retailer_preference.currency.name
          }
        }
      }
      if (this.context.isSupplier()) {
        if (options.brand_preference && options.brand_preference !== null) {
          this.selectedOption = {
            id: options.brand_preference.option_id,
            name: options.brand_preference.currency.name
          }
        }
      }
    }
  }
}
</script>
