<template>
  <p class="is-size-6 is-flex is-justify-content-center">
    <b-icon
      :icon="this.revenueChange > 0 ? 'menu-up' : 'menu-down'"
      :class="presenter.changeClass">
    </b-icon>
    <span :class="presenter.changeClass">
      {{ presenter.upDownText }} ${{ $numbers(presenter.revenueChange).format({thousandSeparated: true, mantissa: 2}) }}
    </span>
  </p>
</template>

<script>
export default {
  computed: {
    presenter () {
      const revenueChange = this.revenueChange
      const presenter = {
        revenueChange: revenueChange,
        upDownText: '',
        changeClass: null
      }

      if (revenueChange > 0) {
        presenter.changeClass = 'has-text-success'
        presenter.upDownText = 'Up'
      } else if (revenueChange < 0) {
        presenter.revenueChange = `${Math.abs(revenueChange)}`
        presenter.upDownText = 'Down'
        presenter.changeClass = 'has-text-danger'
      } else {
        presenter.changeClass = 'has-text-grey-lighter'
        presenter.revenueChange = '0'
      }
      return presenter
    }
  },
  props: ['revenueChange']
}
</script>
