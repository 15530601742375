<template>
  <div>
    <!-- Loaded Multiple -->
    <div v-if="!loading && shopifyLocations.length > 0">
      <import-location
        v-for="location in shopifyLocations"
        :key="location.id"
        :account="account"
        :location="location"
        @location:imported="imported"
       />
    </div>
    <!-- Loaded None -->
    <div v-else-if="!loading && shopifyLocations.length === 0">

    </div>
    <!-- Loading -->
    <div v-else class="py-lg">
      <loading message="Communicating with Shopify..." />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ImportLocation from './ImportLocation'
export default {
  components: {
    ImportLocation
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/shopify/' + this.account.id + '/locations/'
    }
  },
  data () {
    return {
      loading: false,
      shopifyLocations: []
    }
  },
  methods: {
    load () {
      this.loading = true
      this.shopifyLocations = []
      this.$http.get(this.endpoint).then(response => {
        if (response.data.locations.length > 0) {
          response.data.locations.forEach(location => {
            // discard inactive locations
            if (location.active === false) return
            // discard incomplete locations
            if (location.address1 === null) return
            if (location.city === null) return
            if (location.province_code === null) return
            if (location.zip === null) return
            if (location.country_code === null) return
            // check whether location already exists in rc
            const exists = this.$utils.find(this.locations, (o) => {
              return o.address.street1 === location.address1
            })
            location.revcascadeId = (typeof exists !== 'undefined') ? exists.id : null
            // add to array
            this.shopifyLocations.push(location)
          })
        }
      }).finally(() => {
        this.$emit('loaded')
        this.loading = false
      })
    },
    imported (payload) {
      this.$emit('location:imported', payload)
    }
  },
  mounted () {
    this.load()
  },
  props: ['account', 'locations']
}
</script>
