<template>
  <div>
    <div class="mb-lg">
      <p class="has-text-weight-bold">Primary Address</p>
      <p class="has-text-grey">Enter the billing address that would normally appear on invoices sent to your retail partners.</p>
    </div>
    <!-- Error -->
    <div v-if="('general' in errors)" class="mb-md">
      <p class="has-text-danger">{{ errors.general }}</p>
    </div>
    <!-- Fields -->
    <div class="mt-md mb-lg">
      <div v-if="loadingLocations">
        <div class="pa-sm mb-sm" v-for="index in 3" :key="index">
          <div class="placeholder placeholder-title"></div>
          <div class="placeholder placeholder-sentence" style="width:50%"></div>
        </div>
      </div>
      <div v-else>
        <AddressForm
          v-model="location"
          :addressForm="location"
          type="billing"
          defaultNickname="Business Address"
          addressNameLabel="Business Name"
          :errors="errors"
          :requireNickname="false"
          :requireAddressName="true"
          :requireEmail="false"
          :requirePhone="true"
          :defaultAddressName="context.partner.name"
          @is:ready="isReady"
        />
        <!-- <p class="is-size-7 has-text-grey-light">
          <a href="#" @click.prevent="$store.dispatch('supportRequest')" class="has-text-info">Click here</a>
          to let us know if your country is missing
        </p> -->
      </div>
    </div>
    <!-- Navigate -->
    <div class="pt-lg">
      <div class="columns">
        <div class="column">
          <b-button
            type="is-light rounded-lg"
            expanded
            @click="goBack"
            size="is-medium"
            ><b-icon icon="arrow-left"></b-icon>
          </b-button>
        </div>
        <div class="column">
          <b-button
            type="is-primary rounded-lg"
            expanded
            :disabled="!isValidated"
            :loading="loadingLocations || saving || isUpdating"
            @click="goForward"
            size="is-medium"
          >Save & Continue</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AddressForm from '@/components/Global/AddressForm'
export default {
  components: {
    AddressForm
  },
  computed: {
    ...mapGetters(['app', 'context']),
    endpoint () {
      return (this.location && this.location.id === null) ? this.context.route + '/locations/' : this.context.route + '/locations/' + this.location.id + '/'
    }
  },
  data () {
    return {
      location: null,
      savedLocationAddress: null,
      isValidated: false,
      saving: false,
      isUpdating: false,
      setupIsCompleted: false,
      errors: {},
      loadingLocations: true,
      method: 'post',
      isNotModified: false
    }
  },
  methods: {
    goBack () {
      this.$router.push({ name: 'retailer.onboarding.business-information' })
    },
    goForward () {
      if (this.setupIsCompleted && this.isNotModified) {
        this.$router.push({ name: 'retailer.onboarding.contact-persons', params: { task: this.$route.params.task } })
      } else {
        this.saving = true
        this.$http[this.method](this.endpoint, this.location).then(response => {
          this.$buefy.toast.open({ type: 'is-success', message: 'Address Updated!' })
          this.updateTask()
        }).catch(error => {
          if (error.response && error.response.status === 400) {
            this.errors = error.response.data
          }
        }).finally(() => {
          this.saving = false
        })
      }
    },
    updateTask () {
      const taskBody = (typeof this.task.info === 'object') ? { info: this.task.info } : { info: {} }
      taskBody.info.primaryBusinessAddress = true
      this.$emit('task:updated', taskBody, 'retailer.onboarding.contact-persons')
    },
    loadBillingLocation () {
      const endpoint = this.context.route + '/locations/?type=billing'
      this.loadingLocations = true
      this.$http.get(endpoint).then(response => {
        if (response.data.results.length > 0) {
          this.method = 'patch'
          this.location = response.data.results[0]
          // eslint-disable-next-line camelcase
          const { fax, federal_tax_id, id, type, name2, phone2, ...rest } = this.location.address
          this.savedLocationAddress = { ...rest }
        }
        this.loadingLocations = false
      })
    },
    isReady (val) {
      this.isNotModified = this.$utils.isEqual(this.location.address, this.savedLocationAddress)
      this.isValidated = val
    }
  },
  mounted () {
    this.loadBillingLocation()
    if (this.task && this.task.info && this.task.info.primaryBusinessAddress) {
      this.setupIsCompleted = true
      this.isNotModified = true
    }
  },
  props: {
    task: {
      type: Object,
      require: true
    },
    account: {
      type: Object,
      required: true
    },
    isUpdatingTask: {
      type: Boolean
    }
  },
  watch: {
    isUpdatingTask (value) {
      this.isUpdating = value
    }
  }
}
</script>
