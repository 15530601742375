<template>
  <form @submit.prevent="save">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">
          <span v-if="mapping">
            Delete Template Mapping: {{ getMappingTitle }}
          </span>
        </p>
      </header>
      <section class="modal-card-body">
        {{ getModalBody }}
      </section>
      <footer class="modal-card-foot space-between" >
        <b-button @click="$parent.close()">Close</b-button>
        <b-button
          native-type="submit"
          type="is-primary"
          :disabled="saving"
          :loading="saving"
          >Confirm</b-button>
      </footer>
    </div>
  </form>
  </template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['api', 'context']),
    endpoint () {
      return this.context.route + `/${this.templateType}/${this.$route.params.id}/templates/${this.$route.params.templateId}/${this.fieldsType}/${this.mapping.id}/`
    },
    getModalBody () {
      if (this.fieldsType === 'custom-fields-mappings') {
        return 'Are you sure you want to delete the custom fields mapping?'
      } else {
        return 'Are you sure you want to delete the mapping?'
      }
    },
    getMappingTitle () {
      let label = ''
      if (this.fieldsType === 'metafield-mappings') {
        label = this.mapping && this.mapping.namespace + '.' + this.mapping.key
      } else {
        label = this.mapping && (this.mapping.title || this.mapping.name)
      } return label
    }
  },
  data () {
    return {
      saving: false
    }
  },
  methods: {
    save () {
      this.saving = true
      this.$http.delete(this.endpoint).then(response => {
        this.$buefy.toast.open({ templateType: 'is-success', message: 'Mapping deleted' })
        this.$emit('mapping:saved')
        this.$parent.close()
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {

  },
  props: {
    mapping: {
      type: [Object, null]
    },
    templateType: {
      type: String,
      enum: ['bigcommerce', 'shopify'],
      default: ''
    },
    fieldsType: {
      type: String,
      enum: ['mappings', 'tag-mappings', 'metafield-mappings', 'custom-fields-mappings'],
      default: 'mappings'
    }
  }
}
</script>
