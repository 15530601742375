<template>
  <InspectionModal
    :endpoint="endpoint"
    :loadDetail="loadDetail"
    :seed="selectedEnvelope">
    <template v-slot:default="props">
      <div>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Inspect Envelope</p>
          </header>
          <section class="modal-card-body">
            <!-- Loaded -->
            <div v-if="!props.loading && props.record !== null" class="">
              <!-- Details -->
              <div class="mb-lg">
                <p class="has-text-weight-bold mb-sm">Envelope Details</p>
                <table class="table is-fullwidth is-striped is-narrow is-size-7 border">
                  <tbody>
                    <tr>
                      <th>ID</th>
                      <td class="has-text-right">{{ props.record.id }}</td>
                    </tr>
                    <tr>
                      <th>ICN</th>
                      <td class="has-text-right">{{ props.record.interchange_control_number }}</td>
                    </tr>
                    <tr>
                      <th>Functional Group</th>
                      <td class="has-text-right">{{ props.record.functional_identifier }}</td>
                    </tr>
                    <tr>
                      <th>Sender</th>
                      <td class="has-text-right">
                        <p v-if="props.record.sender"> {{ props.record.sender.qualifier }} / {{ props.record.sender.identifier }} </p>
                        <p v-else> - </p>
                      </td>
                    </tr>
                    <tr>
                      <th>Receiver</th>
                      <td class="has-text-right">
                        <p v-if="props.record.receiver"> {{ props.record.receiver.qualifier }} / {{ props.record.receiver.identifier }} </p>
                        <p v-else> - </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- Documents -->
              <div class="mb-lg">
                <p class="has-text-weight-bold mb-sm">Documents</p>
                <table class="table is-fullwidth is-striped is-size-7 is-narrow border">
                  <thead>
                    <tr>
                      <th>Document ID</th>
                      <th>Transaction Set</th>
                      <th>TS Control #</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="document in props.record.documents" :key="document.id">
                      <td>{{ document.id }}</td>
                      <td>{{ document.transaction_set }}</td>
                      <td>{{ document.transaction_set_control_number }}</td>
                      <td class="has-text-right">
                        <DocumentAction
                          :ediAccountId="ediAccountId"
                          :envelopeId="props.record.id"
                          :direction="props.record.direction"
                          :document="document"
                         />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- Raw EDI -->
              <div>
                <div class="columns">
                  <div class="column">
                    <p class="has-text-weight-bold">EDI</p>
                  </div>
                  <div class="column">
                    <p class="has-text-right"><a href="#" class="has-text-info" @click.prevent="copyEdi(props.record.edi)">Copy EDI</a></p>
                  </div>
                </div>
                <b-input
                  type="textarea"
                  readonly
                  v-model="props.record.edi"
                  expanded
                ></b-input>
              </div>
            </div>
            <!-- Loading -->
            <div v-else>
              <loading message="Loading item..." />
            </div>
          </section>
          <footer class="modal-card-foot">
            <button class="button" type="button" @click="$parent.close()">Close</button>
          </footer>
        </div>
      </div>
    </template>
  </InspectionModal>
</template>

<script>
import DocumentAction from './DocumentAction'
import InspectionModal from '../InspectionModal'
import { mapGetters } from 'vuex'

export default {
  components: {
    DocumentAction,
    InspectionModal
  },
  data () {
    return {
      loadDetail: true
    }
  },
  computed: {
    ...mapGetters(['context']),
    ediAccountId () {
      if (!this.selectedEnvelope) return null
      if (this.context.isSupplier()) {
        return (this.selectedEnvelope.receiver && 'brand' in this.selectedEnvelope.receiver)
          ? this.selectedEnvelope.receiver.id
          : this.selectedEnvelope.sender.id
      } else {
        return (this.selectedEnvelope.receiver && 'retailer' in this.selectedEnvelope.receiver)
          ? this.selectedEnvelope.receiver.id
          : this.selectedEnvelope.sender.id
      }
    },
    endpoint () {
      if (this.selectedEnvelope !== null && this.ediAccountId) {
        return this.context.route + '/edi-accounts/' + this.ediAccountId + '/envelopes/' + this.selectedEnvelope.id + '/'
      }
      return null
    }
  },
  methods: {
    copyEdi (text) {
      this.$copyText(text).then(() => {
        this.$buefy.toast.open({ type: 'is-success', message: 'EDI copied!' })
      })
    }
  },
  mounted () {

  },
  props: ['selectedEnvelope']
}
</script>
