<template>
  <div class="pa-md">
    <!-- Loading Skeleton -->
    <div v-if="loadingTask">
      <loading message="Loading..." />
    </div>
    <!-- Task is loaded -->
    <div v-else>
      <div class="columns is-centered mb-md">
        <div class="column is-three-quarters">
          <div class="columns">
            <div class="column is-one-third mt-sm">
              <breadcrumbs :history="[{ label: 'Onboarding', name: 'retailer.onboarding'}]" current="Shipping Accounts" />
            </div>
            <div class="column is-one-third has-text-weight-bold is-size-5 has-text-centered">
              Shipping Accounts
            </div>
          </div>
        </div>
      </div>
      <div class="columns is-centered">
        <div class="column is-half">
          <div class="box">
            <p class="mb-sm">Add an Optional Shipping Account</p>
            <b-message type="is-info">Shipping accounts can be added in settings at any time</b-message>
            <!-- Fields -->
            <b-field label="Shipping Account Nickname">
              <b-input
                placeholder="Name"
                v-model="nickName"
              ></b-input>
            </b-field>
            <b-field label="Carrier">
              <b-select :loading=loadingCarriers v-model="carrier" placeholder="Select a carrier" expanded>
                <option
                  v-for="carrier in carriers"
                  :value="carrier"
                  :key="carrier.id">
                  {{ carrier.name }}
                </option>
              </b-select>
            </b-field>
            <!-- Navigate -->
            <div class="py-lg">
              <div class="columns">
                <div class="column">
                  <b-button
                    type="is-light rounded-lg"
                    expanded
                    @click="goBack"
                    ><b-icon icon="arrow-left"></b-icon>
                  </b-button>
                </div>
                <div class="column">
                  <b-button
                    type="is-light rounded-lg"
                    expanded
                    @click="taskComplete"
                  >Skip & Complete</b-button>
                </div>
                <div class="column">
                  <b-button
                    type="is-primary rounded-lg"
                    expanded
                    @click="showShippingAccountModal = true"
                  >Continue</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Shipping Account Modal -->
    <b-modal
      :active.sync="showShippingAccountModal"
      :can-cancel="['escape', 'x']"
      has-modal-card
      scroll="keep"
      @close="showShippingAccountModal = false">
      <CreateShippingAccountModal @updated="save" :initialStep="'carrier'" :selectedCarrier="carrier"/>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import CreateShippingAccountModal from '../../Settings/components/ShippingAccounts/CreateShippingAccountModal'
export default {
  components: {
    CreateShippingAccountModal
  },
  computed: {
    ...mapGetters(['app', 'api', 'context']),
    endpoint () {
      return this.context.route + '/assigned-tasks/' + this.$route.params.task + '/'
    }
  },
  data () {
    return {
      nickName: null,
      carrier: null,
      carriers: [],
      loadingCarriers: true,
      showShippingAccountModal: false,
      loadingTask: true
    }
  },
  methods: {
    goBack () {
      this.$router.push({ name: 'retailer.onboarding' })
    },
    loadCarriers () {
      this.loadingCarriers = true
      const endpoint = this.api.baseUrl + '/v1/carriers/?is_common=1'
      this.$http.get(endpoint).then(response => {
        this.carriers = response.data.results
      }).finally(() => {
        this.loadingCarriers = false
      })
    },
    save () {
    },
    taskComplete () {
      const endpoint = this.endpoint + 'complete/'
      this.$http.put(endpoint).then(() => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Task Completed!' })
        this.$router.push({ name: 'retailer.onboarding' })
      }).finally(() => {
        this.saving = false
      })
    },
    loadTask (quiet) {
      if (!quiet) this.loadingTask = true
      this.$http.get(this.endpoint).then(response => {
        this.task = response.data
        this.loadingTask = false
      })
    }
  },
  mounted () {
    this.loadCarriers()
    this.loadTask()
  },
  props: []
}
</script>
