<template>
  <div>
    <div class="has-text-weight-semibold">
      Create Orders
    </div>
     <!-- Error -->
     <div v-if="('general' in errors)" class="mb-md">
      <p class="has-text-danger">{{ errors.general }}</p>
    </div>
    <div class="mt-md">
      <!-- Details -->
      <p class="is-size-7">Create and verify test orders for your sample items.</p>
      <ul class="my-md is-size-7">
        <li>1. Create up to 5 test orders. We recommend creating at least one single item and one multi item order.</li>
        <li>2. Click Confirm & Continue when your test orders have been created.</li>
        <li>3. Click to download EDI 855 Sample File and Specs.</li>
      </ul>
      <b-message v-if="!loading && isOrderCreatedSuccessfully" type="is-success">
        Order files have been uploaded on your server, please proceed with the fulfillment.
      </b-message>
      <b-dropdown :disabled="loading || loadingConnections || disableCreateOrders" :loading="saving" v-model="selectedOption">
        <button class="button rounded-lg" slot="trigger" slot-scope="{ active }">
          <span>Create Test Orders</span>
          <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
        </button>
        <b-dropdown-item value='single' @click="onCreateOrders" aria-role="listitem">Create order with single item</b-dropdown-item>
        <b-dropdown-item value='multiple' @click="onCreateOrders" aria-role="listitem">Create order with multiple items</b-dropdown-item>
      </b-dropdown>
      <b-table
        :data="tableData"
        :mobile-cards="false"
        :loading="loading"
        class="mt-md is-size-7 border rounded-md"
      >
      <template>
        <!-- PO -->
        <b-table-column
          label="PO#"
          cell-class="nowrap"
          v-slot="props">
          <p>{{props.row.purchase_order_number}}</p>
        </b-table-column>
        <!-- SKU -->
        <b-table-column
          label="SKU"
          cell-class="nowrap"
          centered
          v-slot="props">
          {{props.row.skus}}
        </b-table-column>
      </template>
        <template #empty>
          <div class="py-xxl has-text-grey has-text-centered">
            {{ (loading) ? "Loading data..." : 'No orders found!' }}
          </div>
        </template>
      </b-table>
    </div>
    <!-- Navigate -->
    <div class="py-lg">
      <div class="columns">
        <div class="column">
          <b-button
            type="is-light rounded-lg"
            expanded
            @click="goBack"
            ><b-icon icon="arrow-left"></b-icon>
          </b-button>
        </div>
        <div class="column">
          <b-button
            type="is-primary rounded-lg"
            expanded
            :disabled="loading || saving || !tableData.length"
            :loading="isUpdating"
            @click="goForward"
          >Confirm & Continue</b-button>
        </div>
      </div>
    </div>
    <!-- Confirm Create Order Modal -->
    <b-modal
      :has-modal-card="true"
      :active.sync="showConfirmCreateOrders"
      :can-cancel="['x', 'esc']">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Create Order?</p>
      </header>
      <section class="modal-card-body">
        <div class="has-text-centered py-md">
          <p>This task is completed, Are you sure you want to create a new order?</p>
          <p>This will reset the next steps.</p>
        </div>
      </section>
      <footer class="modal-card-foot space-between">
        <button class="button" type="button" @click="showConfirmCreateOrders = false">Cancel</button>
        <b-button type="is-danger" @click="createOrders">Create order</b-button>
      </footer>
    </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  computed: {
    ...mapGetters(['app', 'context']),
    endpoint () {
      return this.context.route + '/orders/'
    },
    loading () {
      return (this.saving || this.loadingOrders)
    },
    fields () {
      const base = [
        'id',
        'order_lines',
        'invoice_count',
        'status',
        'received_at',
        'acknowledged_at',
        'retailer'
      ]
      return base
    }
  },
  data () {
    return {
      loadingOrders: false,
      loadingConnections: true,
      saving: false,
      errors: {},
      variants: [],
      ediRetailerConnection: null,
      tableData: [],
      isUpdating: false,
      disableCreateOrders: false,
      showConfirmCreateOrders: false,
      selectedOption: null,
      isOrderCreatedSuccessfully: false
    }
  },
  methods: {
    goBack () {
      this.$router.push({ name: 'onboarding.tasks.self-serve-edi.inventory', params: { connection: this.$route.params.connection } })
    },
    goForward () {
      // Add info to the task to track the tasks
      const taskBody = (typeof this.task.info === 'object') ? { info: this.task.info } : { info: {} }
      taskBody.info.createOrders = true
      this.$emit('task:updated', taskBody, 'onboarding.tasks.self-serve-edi.fulfillment')
    },
    onCreateOrders () {
      if (this.task.completed_at) {
        // if task is already completed show confirm modal
        this.showConfirmCreateOrders = true
      } else {
        this.createOrders()
      }
    },
    createOrders () {
      this.showConfirmCreateOrders = false
      this.saving = true
      const endpoint = this.context.route + '/edi-accounts/create-test-order/'
      const body = {
        requested_shipping_method_id: 2,
        connection: {
          id: this.ediRetailerConnection.id
        }
      }
      if (this.selectedOption === 'single') {
        body.order_lines = [{
          quantity: 1,
          price: 25,
          variant: { id: this.variants[0].id }
        }]
      } else if (this.selectedOption === 'multiple') {
        body.order_lines = this.variants.map(el => {
          return ({
            quantity: 1,
            price: 25,
            variant: { id: el.id }
          })
        })
      }
      this.$http.post(endpoint, body).then(response => {
        const taskBody = (typeof this.task.info === 'object') ? { info: this.task.info } : { info: {} }
        if (taskBody.info.orderIds) {
          taskBody.info.orderIds.push(response.data.id)
        } else {
          taskBody.info.orderIds = [response.data.id]
        }
        this.loadOrders(taskBody.info.orderIds)
      }).catch(error => {
        this.errors.general = error.response.data.detail
      }).finally(() => {
        this.saving = false
        this.selectedOption = null
        this.isOrderCreatedSuccessfully = true
      })
    },
    loadOrders (createdIDs) {
      this.loadingOrders = true
      const query = {
        params: {
          order_by: '-id',
          skip_attributes: 1,
          fields: this.fields
        }
      }
      this.$http.get(this.endpoint, query).then(response => {
        const orderIds = createdIDs || this.orderIds
        this.disableCreateOrders = orderIds.length > 5
        const orders = response.data.results.filter(el => orderIds.includes(el.id))
        this.tableData = orders.map(el => {
          return ({
            id: el.id,
            orderLines: el.order_lines,
            purchase_order_number: el.purchase_order_number,
            skus: el.order_lines.map(item => item.variant.brand_identifier).toString()
          })
        })
        if (createdIDs) {
          const taskBody = (typeof this.task.info === 'object') ? { info: this.task.info } : { info: {} }
          if (this.task.completed_by) {
            taskBody.info.uploadFulfillment = false
            taskBody.info.uploadInvoices = false
          }
          taskBody.info.orderIds = createdIDs
          this.$emit('task:updated', taskBody)
          this.$buefy.toast.open({ type: 'is-success', message: 'Test orders created' })
        }
      }).finally(() => {
        this.loadingOrders = false
      })
    },
    loadConnections () {
      const endpoint = this.context.route + '/connections/?order_by=brand__name&page=1&limit=12'
      this.$http.get(endpoint).then(response => {
        // edi test retailer connection id
        if (response.data.count > 0) {
          this.ediRetailerConnection = response.data.results.find(el => el.retailer && !el.retailer.is_onboarded)
        }
      }).finally(() => {
        this.loadingConnections = false
      })
    }
  },
  mounted () {
    this.variants = this.task.info.variants
    this.loadConnections()
    if (this.task && this.task.info && this.task.info.orderIds) {
      this.orderIds = this.task.info.orderIds
      // disabled create orders if orders created are more than 5
      this.disableCreateOrders = this.orderIds.length > 5
      this.loadingOrders = true
      this.loadOrders()
    }
  },
  props: {
    connection: {
      type: Object,
      required: true
    },
    ediAccount: {
      type: Object
    },
    task: {
      type: Object,
      required: true
    },
    isUpdatingTask: {
      type: Boolean
    }
  },
  watch: {
    isUpdatingTask (value) {
      this.isUpdating = value
    }
  }
}
</script>
