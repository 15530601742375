<template>
  <div>
    <!-- Product Header -->
    <div class="has-background-white pa-md rounded-lg mb-lg">
      <div v-if="!loading && product">
        <div class="columns is-vcentered is-mobile">
          <!-- Product Image -->
          <div class="column is-narrow">
            <figure v-if="product.primary_media !== null && product.primary_media.media !== 'default'" class="image is-64x64 mb-xs">
              <img class="rounded-md" :src="cdnTransform(product.primary_media.url, 64, 64, 'fill', '2.0')" width="64" height="64"  />
            </figure>
            <figure v-else class="image is-64x64">
              <img class="rounded-md" src="https://res.cloudinary.com/revcascade/image/upload/v1576868498/static/placeholder-lg.png" />
            </figure>
          </div>
          <!-- Product Name-->
          <div class="column">
            <p class="has-text-weight-bold has-text-info is-size-6">{{ product.parent_name }}</p>
            <p class="is-size-7 has-text-grey-light">Product # {{ product.parent_identifier }}</p>
          </div>
          <!-- Compatibility -->
          <div class="column has-text-right">
            <p class="mb-sm"><b-button outlined @click="showRemoveProductModal = true">Remove Product</b-button></p>
            <p class="has-text-grey-light is-size-7">Last Updated {{ $dates.utc(proposal.updated_at).local().format('MMM D YYYY, h:mm A') }}</p>
          </div>
        </div>
      </div>
      <!-- Loading Skeleton -->
      <div v-else>
        <div class="columns">
          <div class="column is-narrow">
            <b-skeleton height="64" width="64" />
          </div>
          <div class="column">
            <b-skeleton height="25" />
            <b-skeleton height="10" width="50%"/>
          </div>
          <div class="column is-one-quarter"><b-skeleton /></div>
        </div>
      </div>
    </div>
    <b-tabs v-model="activeTab" :animated="false">
      <b-tab-item label="Product Attributes" headerClass="has-text-left has-text-weight-bold" >
        <!-- Product Attributes -->
        <div class="has-background-white rounded-lg pa-md  mb-xs">
          <div v-if="!loading && product">
            <!-- General Product Basic Attributes -->
            <div v-if="attributeGroups.general.length" class="mb-lg">
              <p class="has-text-weight-bold border-b has-border-lg pb-sm">Identifiers</p>
              <div class="columns">
                <div class="column">
                  <table class="table is-striped is-narrow is-fullwidth is-size-7">
                    <tbody>
                      <tr>
                        <th>{{ app.platform }} Product ID</th>
                        <td class="has-text-right">{{ product.id}}</td>
                      </tr>
                      <tr>
                        <th>Supplier</th>
                        <td class="has-text-right">{{ product.brand.name }}</td>
                      </tr>
                      <tr v-for="attribute in attributeGroups.general" :key="attribute.attribute.id">
                        <th>{{ attribute.attribute.name }}</th>
                        <td class="has-text-right">{{ attribute.value.value }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- Product Media -->
            <div v-if="media.length" class="mb-lg">
              <p class="mb-md has-text-weight-bold border-b has-border-lg pb-sm">Product Media</p>
              <div>
                <!-- Media Available -->
                <div >
                  <div class="columns is-multiline">
                    <div v-for="medium in media" :key="medium.id" class="column is-1">
                      <figure class="image is-square mb-xs">
                        <img class="rounded-md" :src="cdnTransform(medium.url, 64, 64, 'fill', '2.0')">
                      </figure>
                      <p class="is-size-8 has-text-centered has-text-grey">{{ medium.width }}x{{ medium.height }}</p>
                      <p class="is-size-8 has-text-centered">
                        <a :href="cdnTransform(medium.url, null, null, null, null) + '.jpg'" target="_blank" class="has-text-grey">
                          <u>Full Size</u>
                          <b-icon icon="open-in-new" size="is-small" />
                        </a>
                      </p>
                    </div>
                  </div>
                  <p class="is-size-8 has-text-grey-light">Photos  may be resized for consistency. Please review the native dimensions listed below each photo (WxH).</p>
                </div>
              </div>
            </div>
            <!-- Description -->
            <div v-if="attributeGroups.description.length" class="mb-lg">
              <p class="has-text-weight-bold border-b has-border-lg pb-sm">Product Description</p>
              <div class="columns">
                <div class="column">
                  <table class="table is-striped is-narrow is-fullwidth is-size-7">
                    <tbody>
                      <AttributeValueReader
                        v-for="attribute in attributeGroups.description"
                        :key="attribute.attribute.id"
                        :attribute="attribute"
                      />
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- Miscellaneous -->
            <div v-if="attributeGroups.misc.length" class="mb-lg">
              <p class="has-text-weight-bold border-b has-border-lg pb-sm">Additional Attributes</p>
              <div class="columns">
                <div class="column">
                  <table class="table is-striped is-narrow is-fullwidth is-size-7">
                    <tbody>
                      <AttributeValueReader
                        v-for="attribute in attributeGroups.misc"
                        :key="attribute.attribute.id"
                        :attribute="attribute"
                      />
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <loading message="Loading product details..." />
          </div>
        </div>
      </b-tab-item>
      <b-tab-item label="Items & Pricing" headerClass="has-text-left has-text-weight-bold">
        <!-- Product Variants -->
        <div v-if="!loading && product" class="has-background-white pa-md rounded-lg mb-xs">
          <div>
            <Variants :connection="connection" :proposal="proposal" :product="product" />
          </div>
        </div>
      </b-tab-item>
    </b-tabs>
    <!-- Remove Product Modal -->
    <b-modal
      has-modal-card
      :active.sync="(showRemoveProductModal)"
      :can-cancel="['escape', 'x']"
      ><RemoveProposalProductModal :proposal="proposal" :product="product" @product:removed="goToProducts()" />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CdnTransform from '@/mixins/CdnTransform'
import AttributeValueReader from '../components/Product/AttributeValueReader'
import Variants from '../components/Product/Variants'
import RemoveProposalProductModal from '@/components/Models/Proposal/Modals/RemoveProposalProduct'

export default {
  components: {
    AttributeValueReader,
    Variants,
    RemoveProposalProductModal
  },
  computed: {
    ...mapGetters(['app', 'context']),
    loading () {
      return Boolean(this.loadingProduct)
    },
    media () {
      const media = []
      if (this.product) {
        if (this.product.primary_media !== null && this.product.primary_media.media !== 'default') {
          media.push(this.product.primary_media)
        }
        this.product.media.forEach(image => {
          if (image.media !== 'default') {
            media.push(image)
          }
        })
      }
      return media
    },
    attributeGroups () {
      if (!this.template) return null
      const groups = {
        description: [],
        general: [],
        images: [],
        inventory: [],
        misc: [],
        retailer: [],
        shipping: [],
        identifiers: [],
        pricing: []
      }
      this.template.results.forEach(mapping => {
        if (mapping.target === 'product') {
          if (mapping.attribute.code in this.product.attributes && mapping.attribute.grouping in groups) {
            groups[mapping.attribute.grouping].push({
              attribute: mapping.attribute,
              value: this.product.attributes[mapping.attribute.code].values[0]
            })
          }
        }
      })
      return groups
    }
  },
  data () {
    return {
      loadingProduct: true,
      product: null,
      activeTab: 0,
      showRemoveProductModal: false
    }
  },
  methods: {
    loadProduct (quiet = false) {
      if (!quiet) this.loadingProduct = true
      const endpoint = this.context.route + '/proposals/' + this.proposal.id + '/products/' + this.$route.params.product + '/'
      this.$http.get(endpoint).then(response => {
        this.product = response.data
        this.loadingProduct = false
      })
    },
    goToProducts () {
      this.$router.push({ name: 'proposal.detail.products', params: { id: this.proposal.id } })
    }
  },
  mixins: [CdnTransform],
  mounted () {
    this.loadProduct()
  },
  props: {
    connection: {
      type: Object,
      required: true
    },
    proposal: {
      type: Object,
      required: true
    },
    template: {
      type: Object
    },
    proposalProduct: {
      type: Object
    }
  }
}
</script>
