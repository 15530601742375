import AcceptInvite from './views/AcceptInvite'

export default [{
  path: '/accept-invite',
  name: 'register.supplier.invite',
  component: AcceptInvite,
  meta: {
    skipAuth: true,
    skipContext: true,
    hideNavigation: true
  }
}]
