<template>
  <div>
    <div class="columns is-vcentered is-mobile">
      <div class="column is-narrow is-hidden-mobile">
        <div :class="authorClass" class="has-text-white rounded-full has-text-centered" style="width:48px; height:48px; padding-top:12px">
          {{ authorInitials }}
        </div>
      </div>
      <div class="column">
        <p class="has-text-grey is-size-7">
          On {{ $dates.utc(message.created_at).local().format('MMM D') }},
          {{ authorFullname }} wrote...
        </p>
        <p class="mb-xs">{{ message.text }}</p>
        <p class="is-size-8 has-text-grey-light" v-if="hasBeenRead">
          <em>Marked as read on {{ $dates.utc(message.acknowledged_at).local().format('MMM D, YYYY') }}</em>
        </p>
      </div>
      <div class="column is-narrow" v-if="canMarkAsRead">
        <b-button size="is-small" type="is-primary" outlined :loading="busy" :disabled="busy" @click="markAsRead">
          <span class="is-hidden-mobile">Mark as</span> Read
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  computed: {
    ...mapGetters(['context']),
    busy () {
      return (this.saving || this.refreshing)
    },
    authorInitials () {
      return this.message.created_by.first_name.charAt(0).toUpperCase() + this.message.created_by.last_name.charAt(0).toUpperCase()
    },
    authorClass () {
      return (this.message.created_by_context === 'retailer') ? 'has-background-primary' : 'has-background-success'
    },
    authorFullname () {
      return this.message.created_by.first_name + ' ' + this.message.created_by.last_name
    },
    canMarkAsRead () {
      return (this.context.type !== this.message.created_by_context && this.message.acknowledged_at === null)
    },
    hasBeenRead () {
      return (this.context.type !== this.message.created_by_context && this.message.acknowledged_at !== null)
    },
    readEndpoint () {
      return this.context.route + '/memos/' + this.message.id + '/acknowledge/'
    }
  },
  data () {
    return {
      message: this.ogMessage,
      saving: false,
      refreshing: false
    }
  },
  methods: {
    markAsRead () {
      this.saving = true
      this.$http.put(this.readEndpoint).then(response => {
        this.message.acknowledged_at = response.data.acknowledged_at
        this.saving = false
      })
    },
    refresh () {
      this.refreshing = true
      this.$http.put(this.readEndpoint).then(response => {

      })
    }
  },
  mounted () {

  },
  props: ['ogMessage', 'visibility']
}
</script>
