<template>
  <div>
    <!-- Page Header -->
    <div class="mb-sm">
      <div class="columns is-vcentered">
        <div class="column is-one-third">
          <breadcrumbs :history="[{ label: 'Catalog', name: 'catalog'}]" current="Submit" :hideBack="true" />
        </div>
        <div class="column is-one-third">
          <h1 class="has-text-centered is-size-4">Submit</h1>
        </div>
      </div>
    </div>
    <div class="columns is-centered is-multiline">
      <!-- Submit New -->
      <div class="column is-half is-one-third-desktop is-one-quarter-widescreen">
        <router-link :to="{ name: 'catalog.submit.new' }">
          <div class="has-background-white rounded-lg" style="height:250px">
            <div class="has-text-centered has-text-grey-lighter pt-xxl">
              <b-icon icon="application-import" custom-class="mdi-96px"></b-icon>
            </div>
            <div class="has-text-centered pa-md">
              <p class="has-text-weight-bold mb-sm">Submit New Products</p>
              <p class="has-text-grey-light is-size-7">Submit a proposal for <u>new</u> products to {{ partner }}.</p>
            </div>
          </div>
        </router-link>
      </div>
      <!-- Submit Updates  -->
      <div class="column is-half is-one-third-desktop is-one-quarter-widescreen">
        <router-link :to="{ name: 'catalog.submit.updates' }">
          <div class="has-background-white rounded-lg" style="height:250px">
            <div class="has-text-centered has-text-grey-lighter pt-xxl">
              <b-icon icon="tag-outline" custom-class="mdi-96px"></b-icon>
            </div>
            <div class="has-text-centered pa-md">
              <p class="has-text-weight-bold mb-sm">Submit Updated Pricing</p>
              <p class="has-text-grey-light is-size-7">Propose updated costs and/or pricing to {{ partner }}.</p>
            </div>
          </div>
        </router-link>
      </div>
      <!-- Review Updates  -->
      <div class="column is-half is-one-third-desktop is-one-quarter-widescreen">
        <router-link :to="{ name: 'proposals.dashboard' }">
          <div class="has-background-white rounded-lg" style="height:250px">
            <div class="has-text-centered has-text-grey-lighter pt-xxl">
              <b-icon icon="format-list-bulleted-square" custom-class="mdi-96px"></b-icon>
            </div>
            <div class="has-text-centered pa-md">
              <p class="has-text-weight-bold mb-sm">Review Proposals</p>
              <p class="has-text-grey-light is-size-7">Review the current status of proposals sent to {{ partner }}.</p>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['app']),
    partner () {
      if (this.connections.length === 1) {
        return this.connections[0].retailer.name
      }
      return 'a Merchant Partner'
    }
  },
  props: {
    connections: {
      type: Array,
      required: true
    },
    platformAccounts: {
      type: Array,
      required: true
    }
  }
}
</script>
