<template>
  <div class="has-background-light pa-sm px-lg rounded-lg mb-xs">
    <div class="columns is-vcentered">
      <div class="column">
        <p class="has-text-weight-bold">
          <a href="#" @click.prevent="$emit('inspect:role', role)" class="has-text-info">{{ role.role_type.name }}</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['role']
}
</script>
