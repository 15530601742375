<template>
  <div>
    <div class="section">
      <div class="container">
        <!-- Page Header -->
        <div class="mb-sm">
          <div class="columns is-centered">
            <div class="column is-one-third">
              <h1 class="has-text-centered is-size-4">
                <span v-if="context.isMerchant()">Suppliers</span>
                <span v-else>Merchants</span>
              </h1>
            </div>
          </div>
        </div>
        <!-- Content -->
        <div class="mb-lg">
          <div class="columns is-centered">
            <div class="column is-full-tablet is-two-thirds-widescreen">
              <!-- Search -->
              <div class="rounded-lg has-background-white mb-lg pa-md">
                <div class="columns">
                  <div class="column">
                    <ConnectionSearch />
                  </div>
                  <div v-if="context.isMerchant() && app.platformCode === 'fabric'" class="column is-one-quarter">
                    <b-button
                      size="is-medium"
                      outlined
                      expanded
                      @click.prevent="showInvitationModal = true">
                      Invite Supplier
                    </b-button>
                  </div>
                </div>
              </div>
              <!-- Workflow -->
              <div class="columns">
                <div class="column">
                  <div class="mb-md">
                    <p class="has-text-weight-bold has-text-centered mb-md pt-xs">Browse Suppliers</p>
                    <!-- Active -->
                    <router-link class="has-text-weight-bold" :to="{ name: 'connections.list', query: { preset: 'active'} }">
                      <div class="has-background-white px-md rounded-lg mb-xxs">
                        <div class="columns is-mobile is-vcentered is-marginless">
                          <div class="column">
                            Active
                          </div>
                          <div class="column is-one-fifth">
                            <div class="has-background-white-ter pt-xs rounded-lg has-text-weight-bold has-text-centered">
                              <span><b-icon icon="chevron-right" /></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </router-link>
                    <!-- Onboarding -->
                    <router-link class="has-text-weight-bold" :to="{ name: 'connections.list', query: { preset: 'onboarding'} }">
                      <div class="has-background-white px-md rounded-lg mb-xxs">
                        <div class="columns is-mobile is-vcentered is-marginless">
                          <div class="column">
                            Onboarding
                          </div>
                          <div class="column is-one-fifth">
                            <div class="has-background-white-ter pt-xs rounded-lg has-text-weight-bold has-text-centered">
                              <span><b-icon icon="chevron-right" /></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </router-link>
                    <!-- Suspended -->
                    <router-link class="has-text-weight-bold" :to="{ name: 'connections.list', query: { preset: 'suspended'} }">
                      <div class="has-background-white px-md rounded-lg mb-xxs">
                        <div class="columns is-mobile is-vcentered is-marginless">
                          <div class="column">
                            Suspended
                          </div>
                          <div class="column is-one-fifth">
                            <div class="has-background-white-ter pt-xs rounded-lg has-text-weight-bold has-text-centered">
                              <span><b-icon icon="chevron-right" /></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </router-link>
                    <!-- All -->
                    <router-link class="has-text-weight-bold" :to="{ name: 'connections.list', query: { preset: 'all'} }">
                      <div class="has-background-white px-md rounded-lg mb-xxs">
                        <div class="columns is-mobile is-vcentered is-marginless">
                          <div class="column">
                            View All Connections
                          </div>
                          <div class="column is-one-fifth">
                            <div class="has-background-white-ter pt-xs rounded-lg has-text-weight-bold has-text-centered">
                              <span><b-icon icon="chevron-right" /></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </router-link>
                  </div>
                  <!-- Invitations -->
                  <div v-if="context.isMerchant()" class="mb-lg">
                    <p class="has-text-weight-bold has-text-centered mb-md pt-xs">New Suppliers</p>
                    <router-link class="has-text-weight-bold" :to="{ name: 'connection.invitations' }">
                      <div class="has-background-white px-md rounded-lg mb-xxs">
                        <div class="columns is-mobile is-vcentered is-marginless">
                          <div class="column">
                            Invitations
                          </div>
                          <div class="column is-one-fifth">
                            <div class="has-background-white-ter pt-xs rounded-lg has-text-weight-bold has-text-centered">
                              <span><b-icon icon="chevron-right" /></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </router-link>
                  </div>
                </div>
                <div class="column is-narrow">
                  <div class="mb-lg">
                    <p class="has-text-weight-bold has-text-centered mb-md pt-xs">Supplier Calendar</p>
                    <div class="has-background-white px-sm rounded-lg">
                      <b-datepicker
                        inline
                        :min-date="minDate"
                        :max-date="maxDate"
                        :loading="loading"
                        :events="events"
                        @input="inspectDate"
                        indicators="bars"
                        v-model="date">
                      </b-datepicker>
                      <div class="border-t py-md">
                        <div class="columns is-centered">
                          <div class="column is-narrow">
                            <b-field>
                              <b-radio v-model="eventType" native-value="intros">Activations &amp; Demos &nbsp; &nbsp;</b-radio>
                              <b-radio v-model="eventType" native-value="launches">Launches </b-radio>
                            </b-field>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal: Invitation -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showInvitationModal"
      :can-cancel="['x']"
      ><InvitationModal @saved="redirectToInvitations" />
    </b-modal>
    <!-- Modal: Connection Launches -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showLaunchModal"
      :can-cancel="['x']"
      ><ConnectionLaunchModal :connections="connections" :eventType="eventType" />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ConnectionSearch from '../components/Index/ConnectionSearch'
import ConnectionLaunchModal from '../components/Index/ConnectionLaunchModal'
import InvitationModal from '@/components/Models/Connection/InvitationModal'

export default {
  components: {
    ConnectionSearch,
    ConnectionLaunchModal,
    InvitationModal
  },
  computed: {
    ...mapGetters(['app', 'auth', 'context']),
    minDate () {
      return this.$dates().subtract('3', 'month').toDate()
    },
    maxDate () {
      return this.$dates().add('3', 'month').toDate()
    },
    queryStartTime () {
      return this.$dates().subtract('3', 'month').unix()
    },
    queryEndTime () {
      return this.$dates().add('3', 'month').unix()
    },
    loading () {
      return (this.loadingConnections)
    }
  },
  data () {
    return {
      showInvitationModal: false,
      eventType: 'intros',
      date: this.$dates().toDate(),
      events: [],
      loadingConnections: true,
      showLaunchModal: false,
      connections: []
    }
  },
  methods: {
    redirectToInvitations () {
      this.$router.push({ name: 'connection.invitations' })
    },
    inspectDate () {
      const start = this.$dates(this.date).startOf('day').unix()
      const end = this.$dates(this.date).endOf('day').unix()
      this.loadingConnections = true
      const endpoint = this.context.route + '/connections/'
      const query = {
        params: {
          pagination: 0,
          status: 'onboarding'
        }
      }
      if (this.eventType === 'intros') {
        query.params.intro_call_date_gte = start
        query.params.intro_call_date_lte = end
      } else {
        query.params.onboarding_target_date_gte = start
        query.params.onboarding_target_date_lte = end
      }
      this.$http.get(endpoint, query).then(response => {
        if (response.data.results.length) {
          this.connections = response.data.results
          this.showLaunchModal = true
        }
        this.loadingConnections = false
      })
    },
    loadLaunchCalendar () {
      this.loadingConnections = true
      this.events = []
      const endpoint = this.context.route + '/connections/'
      const query = {
        params: {
          pagination: 0,
          status: 'onboarding'
        }
      }
      if (this.eventType === 'intros') {
        query.params.intro_call_date_gte = this.queryStartTime
        query.params.intro_call_date_lte = this.queryEndTime
      } else {
        query.params.onboarding_target_date_gte = this.queryStartTime
        query.params.onboarding_target_date_lte = this.queryEndTime
      }
      this.$http.get(endpoint, query).then(response => {
        response.data.results.forEach(connection => {
          if (this.eventType === 'intros' && connection.intro_call_date) {
            this.events.push({
              date: this.$dates.utc(connection.intro_call_date).toDate(),
              type: 'is-info'
            })
          }
          if (this.eventType === 'launches' && connection.onboarding_target_date) {
            this.events.push({
              date: this.$dates.utc(connection.onboarding_target_date).toDate(),
              type: 'is-info'
            })
          }
        })
        this.loadingConnections = false
      })
    }
  },
  mounted () {
    this.loadLaunchCalendar()
  },
  watch: {
    eventType (value) {
      this.loadLaunchCalendar()
    }
  }
}
</script>
