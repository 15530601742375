<template>
  <div>
    <div class="has-text-weight-semibold">
      Upload Inventory
    </div>
    <!-- Error -->
    <div v-if="('general' in errors)" class="mb-md">
      <p class="has-text-danger">{{ errors.general }}</p>
    </div>
    <div class="mt-md">
      <!-- Details -->
      <p class="is-size-7">FTP upload an 846 inventory file your the sample items.</p>
      <ul class="mt-md is-size-7">
        <li>1. Log in to your FTP client and upload your EDI 846 file into the outbox.</li>
        <li>2. Wait for 5-15 minutes for the file to be processed. Click refresh to update inventory count.</li>
        <li>3. Click Confirm & Continue when the inventory count is updated.</li>
        <li>4. Click to download EDI 846 Sample File and Specs</li>
      </ul>
      <!-- Note if Address doesn't exist -->
      <div class="mt-sm">
        <div v-if="loadingAddresses"><loading message="Loading fulfillment details..." /></div>
        <b-message v-if="!loadingAddresses && !locationExists" type="is-danger" has-icon>
          You do not have a fulfillment locations setup. Please add a fulfillment location in tasks to continue.
        </b-message>
      </div>
      <!-- FTP Details -->
      <div>
        <LoginDetails :ediAccount="ediAccount" :collapse="true" />
      </div>
      <!-- Refresh Inventory -->
      <div v-if="!loadingAddresses && locationExists" class="mt-md">
        <div class="columns is-vcentered is-size-7" @click="loadInventory()">
          <div class="column is-clickable">
            <b-icon icon="refresh" custom-class="mdi-rotate-180" size="is-small"></b-icon>
            <span>Refresh Inventory</span>
          </div>
        </div>
        <b-table
          :data="inventory"
          :mobile-cards="false"
          :loading="loading"
          class="is-size-7 border rounded-md"
        >
        <template>
          <!-- SKU -->
          <b-table-column
            label="SKU"
            cell-class="nowrap"
            v-slot="props">
            {{props.row.variant.identifier}}
          </b-table-column>
          <!-- Inventory -->
          <b-table-column
            label="Inventory Count"
            cell-class="nowrap"
            centered
            v-slot="props">
            {{props.row.inventory}}
          </b-table-column>
        </template>
          <template #empty>
            <div class="py-xxl has-text-grey has-text-centered">
              {{ (loading) ? "Loading data..." : 'No variants found!' }}
            </div>
          </template>
        </b-table>
      </div>
      <!-- Navigate -->
      <div class="py-lg">
        <div class="columns">
          <div class="column">
            <b-button
              type="is-light rounded-lg"
              expanded
              @click="goBack"
              ><b-icon icon="arrow-left"></b-icon>
            </b-button>
          </div>
          <div class="column">
            <b-button
              type="is-primary rounded-lg"
              expanded
              :disabled="loading || !inventoryExists || !locationExists "
              :loading="isUpdating"
              @click="goForward"
            >Confirm & Continue</b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LoginDetails from '../components/EdiAccount/LoginDetails'
export default {
  components: {
    LoginDetails
  },
  computed: {
    ...mapGetters(['app', 'context']),
    endpoint () {
      return this.context.route + '/inventory/?page=1&limit=12'
    }
  },
  data () {
    return {
      loading: false,
      isUpdating: false,
      inventoryExists: false,
      loadingAddresses: true,
      locationExists: false,
      errors: {},
      inventory: [],
      locations: []
    }
  },
  methods: {
    goBack () {
      this.$router.push({ name: 'onboarding.tasks.self-serve-edi.items', params: { connection: this.$route.params.connection } })
    },
    goForward () {
      // Add info to the task to track the tasks
      const taskBody = (typeof this.task.info === 'object') ? { info: this.task.info } : { info: {} }
      taskBody.info.uploadInventory = true
      this.$emit('task:updated', taskBody, 'onboarding.tasks.self-serve-edi.orders')
    },
    loadInventory () {
      this.loading = true
      this.$http.get(this.endpoint).then(response => {
        const variants = this.task.info.variants.map(el => el.id)
        this.inventory = response.data.results.filter(el => variants.includes(el.variant.id))
        this.inventoryExists = this.inventory.filter(el => el.inventory > 0).length === 2
      }).finally(() => {
        this.loading = false
      })
    },
    loadWareHouseLocations () {
      const endpoint = this.context.route + '/locations/?type=warehouse'
      this.$http.get(endpoint).then(response => {
        this.locations = response.data.results
        this.locationExists = this.locations.length
        // Load inventory if location exists
        if (this.locationExists) {
          this.loadInventory()
        }
      }).finally(() => {
        this.loadingAddresses = false
      })
    }
  },
  mounted () {
    this.loadWareHouseLocations()
  },
  props: {
    connection: {
      type: Object,
      required: true
    },
    ediAccount: {
      type: Object
    },
    task: {
      type: Object,
      required: true
    },
    isUpdatingTask: {
      type: Boolean
    }
  },
  watch: {
    isUpdatingTask (value) {
      this.isUpdating = value
    }
  }
}
</script>
