<template>
  <div class="has-text-centered">
    <h4 class="is-size-5 mt-lg has-text-centered">Orders</h4>
    <div class="columns mt-sm is-mobile is-multiline">
      <!-- Vendor Compliance Score -->
      <div class="column is-half-mobile is-one-quarter-tablet">
        <div class="has-background-white pa-md rounded-lg">
            <p class="heading">Orders received</p>
            <div v-if="this.loading" class="mb-sm"><b-icon icon="loading" custom-class="mdi-spin"></b-icon></div>
            <div v-else>
              <p class="is-size-4">
                {{ $numbers(scoreData.order_lines_count).format({thousandSeparated: true, mantissa: 0}) }}
              </p>
              <OrdersChange :ordersChange="ordersChangefromLastMonth"></OrdersChange>
            </div>
        </div>
      </div>
      <!-- Fulfillment score -->
      <div class="column is-half-mobile is-one-quarter-tablet">
        <div class="has-background-white pa-md rounded-lg">
          <p class="heading">Fulfillment Score</p>
          <div v-if="this.loading" class="mb-sm"><b-icon icon="loading" custom-class="mdi-spin"></b-icon></div>
          <div v-else>
            <p class="is-size-4">{{ $numbers(fulfillmentScore).format({ mantissa: fulfillmentScore > 0 ? 2 : 0 }) }}</p>
            <p class="is-size-8 mb-sm">{{ scoreData.order_lines_fulfilled_within_sla_quantity }} / {{ scoreData.order_lines_count }} orders fulfilled within SLA</p>
          </div>
        </div>
      </div>
      <!-- Cancellation score -->
      <div class="column is-half-mobile is-one-quarter-tablet">
        <div class="has-background-white pa-md rounded-lg">
          <p class="heading">Cancellation Score</p>
          <div v-if="this.loading" class="mb-sm"><b-icon icon="loading" custom-class="mdi-spin"></b-icon></div>
          <div v-else>
            <p class="is-size-4">{{ $numbers(cancellationScore).format({  mantissa: cancellationScore > 0 ? 2 : 0 }) }}</p>
            <p class="is-size-8 mb-sm">{{ scoreData.cancel_lines_quantity }} / {{ scoreData.order_lines_count }} orders cancelled</p>
          </div>
        </div>
      </div>
      <!-- Replacement score -->
      <div class="column is-half-mobile is-one-quarter-tablet">
        <div class="has-background-white pa-md rounded-lg">
          <p class="heading">Replacement Score</p>
          <div v-if="this.loading" class="mb-sm"><b-icon icon="loading" custom-class="mdi-spin"></b-icon></div>
          <div v-else>
            <p class="is-size-4">{{ $numbers(replacementScore).format({ mantissa: replacementScore > 0 ? 2 : 0 }) }}</p>
            <p class="is-size-8 mb-sm">{{ scoreData.replacement_lines_quantity }} / {{ scoreData.order_lines_count }} orders replaced</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import OrdersChange from '../../../components/Presenters/Compliance/OrdersChange.vue'

export default {
  components: {
    OrdersChange
  },
  computed: {
    ...mapGetters(['app']),
    ordersChangefromLastMonth () {
      let change = 0
      const lastMonthsOrderCount = (this.lastMonthsScoreData && this.lastMonthsScoreData.order_lines_count) || 0
      if (this.scoreData.order_lines_count) {
        change = this.scoreData.order_lines_count - lastMonthsOrderCount
      }
      return change
    },
    fulfillmentScore () {
      let score = 0
      if (this.scoreData.order_lines_count > 0) {
        score = (this.scoreData.order_lines_fulfilled_within_sla_quantity / this.scoreData.order_lines_count) * 40
      }
      return score
    },
    replacementScore () {
      let score = 0
      if (this.scoreData.order_lines_count > 0) {
        score = (1 - (this.scoreData.replacement_lines_quantity / this.scoreData.order_lines_count)) * 5
      }
      return score
    },
    cancellationScore () {
      let score = 0
      if (this.scoreData.order_lines_count > 0) {
        score = (1 - (this.scoreData.cancel_lines_quantity / this.scoreData.order_lines_count)) * 55
      }
      return score
    }
  },
  data () {
    return {
    }
  },
  methods: {
  },
  props: ['scoreData', 'lastMonthsScoreData', 'loading']
}
</script>
