import Index from './views/Index'
import Performance from './views/Performance'
import PerformanceScorecard from '@/modules/Performance/views/Scorecard'
import PartnerScorecard from '@/modules/Performance/views/PartnerScorecard'
import ProductScorecard from '@/modules/Performance/views/ProductScorecard'
import Payments from './views/Payments'

export default [{
  path: '/reports',
  name: 'reports',
  component: Index
}, {
  path: '/reports/performance',
  name: 'reports.performance',
  component: Performance,
  children: [{
    path: '/reports/performance/scorecard',
    name: 'reports.performance.scorecard',
    component: PerformanceScorecard
  }, {
    path: '/reports/performance/partner-scorecard',
    name: 'reports.performance.partner-scorecard',
    component: PartnerScorecard
  }, {
    path: '/reports/performance/product-scorecard',
    name: 'reports.performance.product-scorecard',
    component: ProductScorecard
  }]
}, {
  path: '/reports/payments',
  name: 'reports.payments',
  component: Payments
}]
