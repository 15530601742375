<template>
  <div>

      <!-- Title -->
      <h2 class="mb-md is-size-5">{{ (!loading && detail !== null) ? detail.connection.brand.name : ''}}</h2>

      <!-- Scorecard -->
      <div class="mb-lg">
        <div class="columns">
          <div class="column">
            <!-- Total Orders -->
            <div class="mb-sm has-background-white py-sm px-md rounded-lg">
              <div class="columns is-vcentered is-mobile">
                <div class="column">
                  <p>Total Orders</p>
                </div>
                <div class="column">
                  <p v-if="loading === false && detail !== null" class="has-text-right">{{ $numbers(detail.orders_count).format({thousandSeparated: true}) }}</p>
                  <p v-else class="has-text-right">
                    <b-icon icon="loading" custom-class="mdi-spin has-text-grey"></b-icon>
                  </p>
                </div>
              </div>
            </div>
            <!-- Incident Count -->
            <div class="mb-sm has-background-white py-sm px-md rounded-lg">
              <div class="columns is-vcentered is-mobile">
                <div class="column">
                  <p>Total Incidents</p>
                </div>
                <div class="column is-narrow">
                  <p v-if="loading === false && detail !== null" class="has-text-right">{{ $numbers(detail.incidents_count).format({mantissa: 0}) }}</p>
                  <p v-else class="has-text-right">
                    <b-icon icon="loading" custom-class="mdi-spin has-text-grey"></b-icon>
                  </p>
                </div>
              </div>
            </div>
            <!-- Incident Count -->
            <div class="mb-sm has-background-white py-sm px-md rounded-lg">
              <div class="columns is-vcentered is-mobile">
                <div class="column">
                  <p>Late Orders</p>
                </div>
                <div class="column is-narrow">
                  <p v-if="loading === false && detail !== null" class="has-text-right">{{ $numbers(detail.late_incidents_count).format({mantissa: 0}) }}</p>
                  <p v-else class="has-text-right">
                    <b-icon icon="loading" custom-class="mdi-spin has-text-grey"></b-icon>
                  </p>
                </div>
              </div>
            </div>
            <!-- Incident Count -->
            <div class="mb-sm has-background-white py-sm px-md rounded-lg">
              <div class="columns is-vcentered is-mobile">
                <div class="column">
                  <p>Cancellations (Inventory)</p>
                </div>
                <div class="column is-narrow">
                  <p v-if="loading === false && detail !== null" class="has-text-right">{{ $numbers(detail.no_inventory_incidents_count).format({mantissa: 0}) }}</p>
                  <p v-else class="has-text-right">
                    <b-icon icon="loading" custom-class="mdi-spin has-text-grey"></b-icon>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="column">
            <!-- Success -->
            <div class="mb-sm has-background-white py-sm px-md rounded-lg">
              <div class="columns is-vcentered is-mobile">
                <div class="column">
                  <p>Success Rate</p>
                </div>
                <div class="column">
                  <p v-if="loading === false && detail !== null" class="has-text-right">{{ detail.success_rate }}</p>
                  <p v-else class="has-text-right">
                    <b-icon icon="loading" custom-class="mdi-spin has-text-grey"></b-icon>
                  </p>
                </div>
              </div>
            </div>
            <!-- Incident Rate -->
            <div class="mb-sm has-background-white py-sm px-md rounded-lg">
              <div class="columns is-vcentered is-mobile">
                <div class="column">
                  <p>Incident Rate</p>
                </div>
                <div class="column">
                  <p v-if="loading === false && detail !== null" class="has-text-right">{{ detail.incident_rate }}</p>
                  <p v-else class="has-text-right">
                    <b-icon icon="loading" custom-class="mdi-spin has-text-grey"></b-icon>
                  </p>
                </div>
              </div>
            </div>
            <!-- Late Rate -->
            <div class="mb-sm has-background-white py-sm px-md rounded-lg">
              <div class="columns is-vcentered is-mobile">
                <div class="column">
                  <p>Late Rate</p>
                </div>
                <div class="column">
                  <p v-if="loading === false && detail !== null" class="has-text-right">{{ detail.late_incident_rate }}</p>
                  <p v-else class="has-text-right">
                    <b-icon icon="loading" custom-class="mdi-spin has-text-grey"></b-icon>
                  </p>
                </div>
              </div>
            </div>
            <!-- Cancellation Rate -->
            <div class="mb-sm has-background-white py-sm px-md rounded-lg">
              <div class="columns is-vcentered is-mobile">
                <div class="column">
                  <p>Cancellation Rate</p>
                </div>
                <div class="column">
                  <p v-if="loading === false && detail !== null" class="has-text-right">{{ detail.no_inventory_incident_rate }}</p>
                  <p v-else class="has-text-right">
                    <b-icon icon="loading" custom-class="mdi-spin has-text-grey"></b-icon>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Loading -->
      <h2 class="mb-md is-size-5">Incident Record</h2>
      <div v-if="loading">
        <div v-for="index in 5" :key="index" class="has-background-white pa-md rounded-xl mb-lg">
          <div class="placeholder placeholder-title mb-md"></div>
          <div class="placeholder placeholder-sentence mb-sm"></div>
          <div class="placeholder placeholder-sentence"></div>
        </div>
      </div>
      <!-- No Results -->
      <div v-else-if="incidents.length === 0">
        <p>No Results</p>
      </div>
      <!-- Display Results -->
      <div v-else>
        <table class="table is-fullwidth rounded-lg">
          <thead>
            <tr>
              <th class="has-text-centered" style="width:100px">Date</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="incident in incidents" :key="incident.id">
              <td class="has-text-centered">{{ $dates.utc(incident.created_at).local().format('MMM D')}}</td>
              <td>
                <div v-if="incident.type === 'order has not been fulfilled by specified date'">
                  <p>Late Order</p>
                  <div class="has-text-grey-light">
                    <p>
                      PO #{{ incident.order.purchase_order_number }}
                      was due  {{ $dates.utc(incident.order.received_at).utcOffset('-0500').format('MMM D') }}
                      <template v-if="incident.order.closed_at !== null">
                      but closed on {{ $dates.utc(incident.order.closed_at).utcOffset('-0500').format('MMM D') }}
                      (~{{ difference(incident.order.received_at, incident.order.closed_at) }} late)
                      </template>
                      <template v-else>but still hasn't closed.</template>
                    </p>
                  </div>
                </div>
                <div v-else-if="incident.type === 'order canceled for insufficient inventory'">
                  <p>Cancellation</p>
                  <div class="has-text-grey-light">
                    <p>
                      PO #{{ incident.order.purchase_order_number }}
                      was partially canceled by Cancel #{{ incident.entities[0].cancel_id }}
                    </p>
                  </div>
                </div>
                <div v-else-if="incident.type === 'order has not been acknowledged by specified date'">
                  <p>Not Acknowledged</p>
                  <div class="has-text-grey-light">
                    <p>
                      PO #{{ incident.order.purchase_order_number }}
                      was not acknowledged within the SLA.
                    </p>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['context']),
    loading () {
      return (this.loadingIncidents || this.loadingSummary)
    },
    startTimestamp () {
      return this.daterange.start
    },
    queryString () {
      const qs = {
        params: {
          connection_id: this.$route.params.id,
          created_at_gte: this.daterange.start,
          created_at_lte: this.daterange.end,
          incident_type: 'order canceled for insufficient inventory,order has not been fulfilled by specified date'
        }
      }
      return qs
    }
  },
  data () {
    return {
      loadingIncidents: false,
      loadingSummary: false,
      detail: null,
      incidents: []
    }
  },
  methods: {
    difference (receivedAt, closedAt) {
      const x = this.$dates.utc(receivedAt).utcOffset('-0500')
      const y = this.$dates.utc(closedAt).utcOffset('-0500')

      return this.$dates.duration(x.diff(y)).humanize()
    },
    loadComplianceDetail () {
      this.loadingSummary = true
      this.$http.get(this.context.route + '/compliance-summary/', this.queryString).then(response => {
        this.detail = response.data
        this.loadingSummary = false
      })
    },
    loadIncidents () {
      this.loadingIncidents = true
      this.$http.get(this.context.route + '/incidents/', this.queryString).then(response => {
        this.incidents = response.data.results
        this.loadingIncidents = false
      })
    }
  },
  mounted () {
    this.loadComplianceDetail()
    this.loadIncidents()
  },
  props: ['daterange'],
  watch: {
    '$route.params.id': function (id) {
      if (typeof id !== 'undefined') {
        this.loadComplianceDetail()
        this.loadingIncidents()
      } else {
        this.detail = null
      }
    },
    startTimestamp: function (value) {
      this.loadComplianceDetail()
      this.loadIncidents()
    }
  }
}
</script>
