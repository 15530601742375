<template>
  <div>
    <div v-if="step === 'child'">
      <ChildAccount
        @cancel="close"
        @child:created="setChildAccount"
      />
    </div>
    <div v-else-if="step === 'carrier'">
      <CarrierAccount
        :carrier="carrier"
        @account:created="setCarrierAccount"
        @cancel="close"
      />
    </div>
    <div v-else-if="step === 'shipping'">
      <ShippingAccount
        :nickname="nickname"
        :carrier="carrier"
        :apiKeys="apiKeys"
        :carrierAccount="carrierAccount"
        @shipping:created="setShippingAccount"
        @cancel="close"
      />
    </div>
  </div>
</template>

<script>
import ChildAccount from './ChildAccount'
import CarrierAccount from './CarrierAccount'
import ShippingAccount from './ShippingAccount'

export default {
  components: {
    ChildAccount,
    CarrierAccount,
    ShippingAccount
  },
  computed: {

  },
  data () {
    return {
      step: this.initialStep,
      nickname: null,
      carrier: this.selectedCarrier,
      apiKeys: null,
      carrierAccount: null,
      shippingAccount: null
    }
  },
  methods: {
    close () {
      this.$parent.close()
    },
    setChildAccount (childAccount) {
      this.carrier = childAccount.carrier
      this.nickname = childAccount.nickname
      this.apiKeys = childAccount.apiKeys
      this.step = 'carrier'
    },
    setCarrierAccount (carrierAccount) {
      this.carrierAccount = carrierAccount
      this.step = 'shipping'
    },
    setShippingAccount (shippingAccount) {
      this.$buefy.toast.open({ type: 'is-success', message: 'Shipping account added!' })
      this.$parent.close()
      this.$emit('updated')
    }
  },
  props: {
    initialStep: {
      type: String,
      default: 'child'
    },
    selectedCarrier: {
      type: Object,
      default: null
    }
  }
}
</script>
