<template>
<div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Submit Products to Merchant</p>
    </header>
    <section class="modal-card-body">
      <!-- Select Connection -->
      <div class="mb-lg">
        <FieldLabel label="Select Merchant Partner" class="mb-sm" />
        <b-field
          :type="('connection' in errors) ? 'is-danger' : ''"
          :message="('connection' in errors) ? errors['connection'][0] : ''">
          <ConnectionSelect v-model="connectionShort" size="is-medium" />
        </b-field>
      </div>
      <!-- Select Department -->
      <div v-if="connectionShort" class="mb-lg">
        <div v-if="!loadingConnection && connection !== null" class="mt-lg">
          <!-- Department -->
          <div class="mb-lg">
            <FieldLabel :label="connection.retailer.name + ' Department'" class="mb-sm" />
            <b-field
              :type="('department' in errors) ? 'is-danger' : ''"
              :message="('department' in errors) ? errors['department'][0] : ''">
              <DepartmentSelect v-model="department" :connectionId="(connection) ? connection.id : null" size="is-medium" />
            </b-field>
          </div>
          <!-- Proposal Type -->
          <div class="mb-lg">
            <FieldLabel label="Proposal Purpose" class="mb-md" />
            <b-field>
              <b-radio v-model="proposalType" native-value="new_product">I want to submit new products for approval.</b-radio>
            </b-field>
            <b-field>
              <b-radio v-model="proposalType" native-value="cost_update">I want to propose changes to my item costs or prices.</b-radio>
            </b-field>
          </div>
        </div>
        <div v-else>
          <loading message="Loading connection details..." />
        </div>
      </div>
    </section>
    <footer class="modal-card-foot space-between" >
      <b-button @click="$parent.close()">Close</b-button>
      <b-button
        type="is-primary"
        :disabled="!ready || saving"
        :loading="saving"
        @click="queueFile">Build Proposal</b-button>
    </footer>
  </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import ConnectionSelect from '@/components/Models/Connection/ConnectionSelect'
import DepartmentSelect from '@/components/Models/ProposalDepartment/DepartmentSelect'

export default {
  components: {
    ConnectionSelect,
    DepartmentSelect
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/proposals/'
    },
    ready () {
      return (this.connection && this.department && this.proposalType)
    },
    proposalBody () {
      if (!this.ready) return {}
      return {
        connection: {
          id: this.connection.id
        },
        name: this.department.name + ' - ' + this.context.partner.name + ' - ' + this.$dates.utc().local().format('MMM D, YYYY @ h:mm'),
        source: 'merchandising',
        proposal_type: this.proposalType,
        department: {
          id: this.department.id
        },
        recipient: {
          user_id: this.department.owner_user.id
        }
      }
    },
    feedBody () {
      return {
        template: {
          id: this.proposal.department.template.id
        },
        direction: 'import',
        data_type: 'proposals',
        source: 'console',
        behavior: 'update',
        info: {
          proposal_id: this.proposal.id,
          proposal_feed_purpose: 'build',
          rulesets: [{
            id: this.proposal.department.brand_ruleset.id
          }, {
            id: this.proposal.department.retailer_ruleset.id
          }]
        },
        file: {
          tempfile: {
            id: this.feed.info.tempfile
          }
        }
      }
    }
  },
  data () {
    return {
      loadingConnection: false,
      connectionShort: null,
      connection: null,
      department: null,
      proposal: null,
      proposalType: null,
      feedQueue: null,
      tempfile: null,
      saving: false,
      errors: {}
    }
  },
  methods: {
    loadConnectionDetail () {
      this.loadingConnection = true
      const endpoint = this.context.route + '/connections/' + this.connectionShort.id + '/'
      this.$http.get(endpoint).then(response => {
        this.connection = response.data
        this.loadingConnection = false
      })
    },
    queueFile () {
      this.saving = true
      this.buttonLabel = 'Creating Proposal...'
      const proposalEndpoint = this.context.route + '/proposals/'
      this.$http.post(proposalEndpoint, this.proposalBody).then(response => {
        this.buttonLabel = 'Submitting File...'
        this.$emit('proposal:created', response.data)
        this.proposal = response.data
        const feedEndpoint = this.context.route + '/feed-queue/'
        this.$http.post(feedEndpoint + 'enqueue/', this.feedBody).then(response => {
          this.buttonLabel = 'Queueing File...'
          this.$emit('feed:queued', response.data)
          this.feedQueue = response.data
          this.$http.put(feedEndpoint + this.feedQueue.id + '/run/').then(response => {
            this.$buefy.toast.open({ type: 'is-success', message: 'Proposal created!' })
            if (this.proposalType === 'new_product') {
              this.$router.push({ name: 'catalog.submit.new', query: { step: 'Progress', proposal_id: this.proposal.id, feed_id: response.data.id } })
            } else {
              this.$router.push({ name: 'catalog.submit.updates', query: { step: 'Progress', proposal_id: this.proposal.id, feed_id: response.data.id } })
            }
          }).finally(() => {
            this.buttonLabel = 'Upload SKU List'
            this.saving = false
          })
        })
      })
    },
    updateTempfile (file, response) {
      this.tempfile = response
    }
  },
  props: {
    feed: {
      type: Object,
      required: true
    }
  },
  watch: {
    connectionShort (connection) {
      if (connection) {
        this.loadConnectionDetail()
      }
    }
  }
}
</script>
