<template>
  <div>
    <!-- Error -->
    <div v-if="('general' in errors)" class="mb-md">
      <p class="has-text-danger">{{ errors.general }}</p>
    </div>
    <div class="mb-lg">
      <FulfillmentSla :preferences="preferences" v-model="selectedOption"/>
    </div>
    <!-- Navigate -->
    <div class="py-lg">
      <div class="columns">
        <div class="column">
          <b-button
            type="is-light rounded-lg"
            expanded
            @click="goBack"
            size="is-medium"
            ><b-icon icon="arrow-left"></b-icon>
          </b-button>
        </div>
        <div class="column">
          <b-button
            type="is-primary rounded-lg"
            expanded
            :disabled="!ready"
            :loading="saving"
            @click="goForward"
            size="is-medium"
          >Continue</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FulfillmentSla from '@/components/Models/Preferences/FulfillmentSla'
export default {
  components: {
    FulfillmentSla
  },
  computed: {
    ...mapGetters(['app', 'context']),
    endpoint () {
      return this.context.route + '/retailer-preferences/'
    },
    ready () {
      return (
        this.selectedOption !== null &&
        this.selectedOption.option !== null
      )
    }
  },
  data () {
    return {
      setupIsCompleted: false,
      selectedOption: null,
      saving: false,
      errors: {}
    }
  },
  methods: {
    goBack () {
      this.$router.push({ name: 'retailer.onboarding.supplier-onboarding-defaults' })
    },
    goForward () {
      this.saving = true
      this.$http.post(this.endpoint, this.selectedOption).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Preferences updated!' })
        this.updateTask()
      }).catch(error => {
        if (error.response && error.response.status === 400) {
          this.errors = error.response.data
        }
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    },
    updateTask () {
      const taskBody = (typeof this.task.info === 'object') ? { info: this.task.info } : { info: {} }
      taskBody.info.fulfillmentSla = true
      this.$emit('task:updated', taskBody, 'retailer.onboarding.payment')
    }
  },
  mounted () {
    if (this.task && this.task.info && this.task.info.fulfillmentSla) {
      this.setupIsCompleted = true
    }
  },
  props: {
    task: {
      type: Object,
      require: true
    },
    preferences: {
      type: Array,
      required: true
    }
  }
}
</script>
