<template>
  <form @submit.prevent="save">
    <div class="columns">
      <div class="column">
        <div class="has-background-white rounded-lg">
          <!-- Loaded -->
          <div class="px-lg py-md">
            <div class="mb-sm">
              <p class="has-text-weight-bold">{{ title }}</p>
              <p class="is-size-7 has-text-grey">{{ text }}</p>
            </div>
            <div class="columns is-vcentered is-mobile">
              <div class="column is-half-tablet">
                <b-field>
                  <b-input
                    type="email"
                    placeholder="Email address or distribution list..."
                    v-model="emailAddress"
                    :disabled="enabled === 'Enabled'"
                    @input="isDirty = 1"
                  ></b-input>
                </b-field>
              </div>
            </div>
          </div>
          <div class="border-t">
            <div class="columns is-vcentered is-marginless">
              <div class="column is-narrow">
                <div class="px-sm">
                <b-field>
                  <b-switch
                    v-model="enabled"
                    true-value="Enabled"
                    false-value="Not Enabled"
                    type="is-success"
                    :disabled="!emailAddress"
                    @input="save">
                    {{ enabled }}
                  </b-switch>
                </b-field>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      const base = this.context.route + '/push-notifications/'
      return (this.notification !== null)
        ? base + this.notification.id + '/'
        : base
    },
    method () {
      return (this.notification !== null) ? 'patch' : 'post'
    },
    json () {
      return {
        action: { name: this.action },
        name: this.action,
        delivery_method: 'recipient',
        recipient_email: this.emailAddress,
        status: (this.enabled === 'Enabled') ? 'enabled' : 'disabled'
      }
    },
    ready () {
      return (this.value !== null)
    }
  },
  data () {
    return {
      isDirty: false,
      notification: null,
      enabled: 'Disabled',
      emailAddress: null,
      errors: [],
      saving: false
    }
  },
  methods: {
    save () {
      this.saving = true
      this.$http[this.method](this.endpoint, this.json).then(response => {
        this.notification = response.data
        this.isDirty = false
        this.$buefy.toast.open({ type: 'is-success', message: 'Preference updated!' })
      }).catch(error => {
        if (error.response && error.response.status === 400) {
          this.errors = error.response.data
          this.enabled = 'Not Enabled'
        }
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    const notification = this.$utils.find(this.notifications, (notification) => {
      return notification.action.name === this.action
    })
    if (typeof notification !== 'undefined') {
      this.notification = notification
      this.emailAddress = notification.recipient_email
      this.enabled = (notification.status === 'enabled') ? 'Enabled' : 'Not Enabled'
    }
  },
  props: ['notifications', 'action', 'title', 'text']
}
</script>
