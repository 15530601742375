<template>
  <div class="has-background-white rounded-lg">
    <!-- Loaded -->
    <div v-if="preferences !== null">
      <div class="mb-lg">
        <FieldLabel label="Catalog Import Method" required/>
        <div class="has-text-grey">
          <p class="mb-md">
            The catalog import method defines how suppliers will share product data with you.
            Selecting 'Import Requests' will require you to upload a spreadsheet on behalf of
            your suppliers and subsequently seek the suppliers's approval to publish the products.
            Selecting 'Proposals' will require suppliers to submit products to you for approval.
          </p>
        </div>
      </div>
      <b-field>
        <b-select
          type="text"
          v-model="method"
          size="is-medium"
          expanded
          @input="announce">
          <option v-for="option in options" :key="option.id" :value="option">{{ option.name }}</option>
        </b-select>
      </b-field>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  computed: {
    ...mapGetters(['app', 'context']),
    json () {
      const preferences = [{
        preference: { id: this.methodPreference && this.methodPreference.id },
        option: { id: this.method && this.method.id }
      }]
      return preferences
    }
  },
  data () {
    return {
      methodPreference: null,
      method: null,
      options: []
    }
  },
  methods: {
    announce () {
      this.$emit('input', this.json)
    }
  },
  mounted () {
    // locate preferences
    this.methodPreference = this.$utils.find(this.preferences, ['code', 'default_catalog_import_method'])

    if (typeof this.methodPreference !== 'undefined') {
      // set the options
      this.options = this.methodPreference.options.map(option => {
        return {
          id: option.id,
          name: option.name,
          value: option.value
        }
      })
      // set the current values
      if (this.methodPreference.retailer_preference) {
        this.method = this.$utils.find(this.options, ['value', this.methodPreference.retailer_preference.value])
      }
      this.announce()
    }
  },
  props: {
    preferences: {
      type: Array,
      required: true
    }
  }
}
</script>
