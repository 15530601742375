<template>
  <span>{{ formatted }}</span>
</template>

<script>
export default {
  computed: {
    isSame () {
      if (typeof this.price !== 'undefined' && this.price.low && this.price.high) {
        return this.price.high === this.price.low
      }
      return null
    },
    lowPriceFormatted () {
      if (typeof this.price !== 'undefined' && this.price.low) {
        return this.$numbers(this.price.low).formatCurrency({ mantissa: 2 })
      }
      return null
    },
    highPriceFormatted () {
      if (typeof this.price !== 'undefined' && this.price.high) {
        return this.$numbers(this.price.high).formatCurrency({ mantissa: 2 })
      }
      return null
    },
    rangeFormatted () {
      if (typeof this.price !== 'undefined' && this.highPriceFormatted && this.lowPriceFormatted) {
        return this.lowPriceFormatted + ' - ' + this.highPriceFormatted
      }
      return null
    },
    formatted () {
      if (typeof this.price === 'undefined') {
        return 'Not Set'
      } else if (this.isSame) {
        return this.highPriceFormatted
      } else {
        return this.rangeFormatted
      }
    }
  },
  props: {
    price: {
      required: true,
      validator: prop => typeof prop === 'object' || typeof prop === 'undefined'
    }
  }
}
</script>
