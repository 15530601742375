<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Are you sure you want to leave this page?</p>
    </header>
    <section class="modal-card-body">
      <b-message>Hey there! It looks like you're in the middle of importing products and you haven't saved all your content.</b-message>
    </section>
    <footer class="modal-card-foot space-between">
      <b-button @click="confirm">Leave</b-button>
      <b-button type="is-primary" @click="close">Stay</b-button>
    </footer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {
  },
  computed: {
    ...mapGetters(['app'])
  },
  methods: {
    close () {
      // Close the modal
      this.$store.dispatch('setShowUnsavedChangesModal', false)
    },
    confirm () {
      // Close the modal
      this.$store.dispatch('setShowUnsavedChangesModal', false)
      this.$router.push({ name: this.app.navigateToPath })
      // Reset unsaved changes & navigate to path values to default
      this.$store.dispatch('navigateToPath', null)
      this.$store.dispatch('setUnsavedChanges', false)
    }
  },
  props: []
}
</script>
