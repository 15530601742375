<template>
  <div>
    <p class="label">{{getLabel}}</p>
    <b-field message="Please note that identifier attributes are not eligible for transformations.">
      <b-autocomplete
        v-model="searchTerm"
        :data="options"
        :open-on-focus="true"
        placeholder="Search all attributes"
        icon="magnify"
        field="name"
        :size="getInputSize"
        clearable
        @typing="search"
        @select="option => onSelection(option)"
        >
        <template slot-scope="props">
          <option v-if="props.option.grouping === 'identifiers'" disabled>
            {{ props.option.name }}
          </option>
          <option v-else>{{ props.option.name }}</option>
        </template>
        <template #empty>No results found</template>
      </b-autocomplete>
    </b-field>
  </div>
</template>

<script>
export default {
  components: {

  },
  computed: {
    getLabel () {
      return this.isCreate ? 'Add new attributes by searching' : 'Update the current attribute with'
    },
    getInputSize () {
      return this.isCreate ? 'is-normal' : 'is-medium'
    }
  },
  data: () => {
    return {
      searchTerm: '',
      options: [],
      selected: null
    }
  },
  methods: {
    search () {
      if (this.searchTerm && this.searchTerm.length > 1) {
        let filterOptions = []
        filterOptions = this.attributes.filter((val) =>
          val.name.toLowerCase().includes(this.searchTerm.toLowerCase())
        )
        this.options = filterOptions
      }
    },
    onSelection (option) {
      this.selected = option
      this.$emit('update:onSelect', option)
    }
  },
  created () {
    this.options = this.attributes
    this.searchTerm = ''
  },
  props: {
    attributes: {
      type: Array,
      required: true
    },
    isCreate: {
      type: Boolean
    }
  }
}
</script>
