<template>
  <form>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Inspect Webhook</p>
      </header>
      <section class="modal-card-body">
        <!-- Details -->
        <div v-if="webhook !== null">
          <div class="mb-lg">
            <p class="has-text-weight-bold mb-sm">Webhook Details</p>
            <table class="table is-fullwidth is-striped is-narrow is-size-7 border">
              <tbody>
                <tr>
                  <th>ID</th>
                  <td class="has-text-right">{{ webhook.id }}</td>
                </tr>
                <tr>
                  <th>Received</th>
                  <td class="has-text-right">
                    <p>{{ $dates.utc(webhook.created_at).local().format('MMM D, YYYY @ h:mm A') }}</p>
                  </td>
                </tr>
                <tr>
                  <th>Status</th>
                  <td class="has-text-right has-text-weight-bold">
                    <p v-if="webhook.status === 'success'" class="has-text-success">Success</p>
                    <p v-else-if="webhook.status === 'skip'" class="has-text-warning">Skipped</p>
                    <p v-else>{{ $utils.upperFirst(webhook.status) }}</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- Errors -->
          <div v-if="webhook.errors" class="mb-lg">
            <p class="has-text-weight-bold mb-sm">Messages</p>
            <textarea class="textarea" readonly :value="webhook.errors"></textarea>
          </div>
          <!-- Payload -->
          <div>
            <p class="has-text-weight-bold mb-sm">Webhook Details</p>
            <textarea class="textarea" rows="10" readonly :value="JSON.stringify(JSON.parse(webhook.payload), null, 2)"></textarea>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button class="button" type="button" @click="$parent.close()">Close</button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  data () {
    return {

    }
  },
  computed: {
    ...mapGetters(['context'])
  },
  methods: {

  },
  mounted () {

  },
  props: ['webhook']
}
</script>
