<template>
  <form @submit.prevent="queueFile">
    <div class="modal-card">
      <section class="modal-card-body">
        <b-tabs @input="tabChanged" v-model="activeTab">
          <b-tab-item label="Upload file">
            <div style="height:420px">
              <p class="mb-lg">
                To import {{ dataType }} in bulk, please upload a file that matches the
                columns found in this <a :href="templateLink" download class="has-text-info has-text-weight-bold">import template</a>.
                The format may be a comma or tab-delimited text file, or an Excel file.
              </p>
              <CoreUpload @file:uploaded="updateTempfile" @file:reset="resetTempfile"/>
              <div v-if="tempfile !== null" class="border has-background-white-ter pa-lg py-xl mt-4">
                <!-- Ready to schedule -->
                <p v-if="scheduled === false" class="has-text-warning">
                  You may now schedule your file for processing by clicking "Schedule Import" below.
                  Although most files will begin processing immediately, please allow up to
                  10-20 minutes for your import to complete.
                </p>
                <p v-else class="has-text-success">
                  Your file has been added to the queue. Although most files
                  will begin processing immediately, please allow up to
                  10-20 minutes for your import to complete.
                </p>
              </div>
            </div>
          </b-tab-item>
          <b-tab-item label="Check Import Status" :disabled="job_id === null"  >
            <!-- Loaded -->
            <div v-if="!loading && job !== null" style="min-height:420px">
              <!-- Notification -->
              <b-message v-if="job.finish_time !== null && job.error_logs.length === 0" type="is-success">
                Your import is complete with 0 errors. Nice job!
              </b-message>
              <b-message v-else-if="job.finish_time !== null && job.error_logs.length > 0"  type="is-warning">
                Your import is complete, but we detected some errors. Please
                check the error log to review the errors. For any rows not
                listed in the error log, you may assume they imported correctly.
              </b-message>
              <b-message v-else type="is-warning">
                Your import is still processing. You might not want to close
                this window until it completes.
                Click <a href="#" @click.prevent="refresh">here</a> to refresh
                the job status.
              </b-message>
              <p class="has-text-weight-bold mb-sm">Job ID #{{ job.id }}</p>
              <div class="columns">
                <div class="column">
                  <div class="columns is-mobile is-vcentered">
                    <div class="column is-narrow">
                      <p class="has-text-weight-bold">Started</p>
                      <p class="has-text-weight-bold">Finished</p>
                    </div>
                    <div class="column">
                      <!-- Start Time -->
                      <p v-if="job.started_at === null" class="has-text-grey">Queued</p>
                      <p v-else>{{ $dates.utc(job.started_at).local().format('MMM D, YYYY @ h:mm A')}}</p>
                      <!-- End Time -->
                      <p v-if="job.finish_time === null">
                        <span> -- </span>
                        <a href="#" @click.prevent="refresh" class="has-text-info is-size-7"> Refresh </a>
                      </p>
                      <p v-else>{{ $dates.utc(job.finish_time).local().format('MMM D, YYYY @ h:mm A')}}</p>
                    </div>
                  </div>
                </div>
                <div class="column is-narrow">
                  <div class="columns is-mobile is-vcentered">
                    <div class="column is-narrow">
                      <p class="has-text-success has-text-weight-bold"># Success</p>
                      <p class="has-text-danger has-text-weight-bold"># Failed</p>
                    </div>
                    <div class="column">
                      <p>
                        <span v-if="job.total_success !== null">{{ job.total_success }}</span>
                        <span v-else>--</span>
                      </p>
                      <p>
                        <span v-if="job.total_failure !== null">{{ job.total_failure }}</span>
                        <span v-else>--</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <b-field label="Error Logs">
                <textarea class="textarea is-small" v-model="jobErrors" disabled style="height:160px"></textarea>
              </b-field>
            </div>
            <!-- Loading -->
            <div v-else style="min-height:360px">
              <loading message="Loading import status..."/>
            </div>
          </b-tab-item>
        </b-tabs>
      </section>
      <footer class="modal-card-foot space-between">
        <div>
          <button class="button" type="button" @click="$parent.close()">Close</button>
          <b-button v-if="job !== null" class="button" type="button" @click="refresh" :loading="loading || refreshing">Refresh</b-button>
        </div>
        <b-button type="is-primary" native-type="submit" :disabled="!ready" :loading="loading || refreshing">Schedule Import</b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import CoreUpload from '@/components/CoreUpload'
export default {
  components: {
    CoreUpload
  },
  computed: {
    ...mapGetters(['context', 'assets']),
    endpoint () {
      return this.context.route + '/' + this.dataType + '/upload/'
    },
    json () {
      return {
        file_id: this.tempfile.id
      }
    },
    templateLink () {
      if (this.dataType === 'shipments') {
        return this.assets.templates.import.shipments
      } else if (this.dataType === 'invoices') {
        return this.assets.templates.import.invoices
      }
      return null
    },
    ready () {
      return (!this.scheduling && !this.scheduled && this.tempfile !== null)
    },
    jobErrors () {
      const errors = []
      if (this.job !== null && this.job.error_logs.length > 0) {
        this.job.error_logs.forEach(log => {
          errors.push(log.message)
        })
      }
      return errors.join('\r')
    }
  },
  data () {
    return {
      activeTab: 0,
      scheduling: false,
      scheduled: false,
      tempfile: null,
      job_id: null,
      job: null,
      loading: false,
      refreshing: false
    }
  },
  methods: {
    queueFile () {
      this.scheduling = true
      this.$http.post(this.endpoint, this.json).then(response => {
        this.scheduling = false
        this.scheduled = true
        this.$buefy.toast.open({ type: 'is-success', message: 'Import scheduled!' })
        if (response.data.length === 1) {
          this.job_id = response.data[0].job_id
          this.activeTab = 1
          this.loadJob()
        }
      })
    },
    updateTempfile (file, response) {
      this.tempfile = response
    },
    resetTempfile () {
      this.tempfile = null
    },
    tabChanged (index) {
      if (index === 1) this.loadJob()
    },
    loadJob () {
      this.loading = true
      this.$http.get(this.context.route + '/jobs/' + this.job_id + '/').then(response => {
        this.job = response.data
        this.loading = false
      })
    },
    refresh () {
      this.refreshing = true
      this.$http.get(this.context.route + '/jobs/' + this.job_id + '/').then(response => {
        this.job = response.data
        this.refreshing = false
      })
    }
  },
  mounted () {

  },
  props: ['dataType']
}
</script>
