<template>
  <div>
    <b-tooltip v-if="showTooltip" :label="presenter.tooltip" dashed multilined position="is-left">
      <a v-if="isShowIntegrationAccountsLink" href="#" @click.prevent="$emit('show:connectionAccounts')" class="has-text-weight-bold has-text-info">{{ presenter.label }}</a>
      <span v-else>{{ presenter.label }}</span>
    </b-tooltip>
    <span v-else>{{ presenter.label }}</span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['context']),
    isShowIntegrationAccountsLink () {
      return this.presenter.isShowIntegrationAccounts && this.context.isMerchant()
    },
    presenter () {
      const presenter = { isShowIntegrationAccounts: false }
      switch (this.connection.integration_type) {
        case 'edi':
          presenter.label = 'EDI'
          presenter.tooltip = 'The connection is using EDI as the primary integration.'
          break
        case 'shipstation':
          presenter.label = 'ShipStation'
          presenter.tooltip = 'The connection is using ShipStation as the primary integration.'
          break
        case 'shopify':
          presenter.label = 'Shopify'
          presenter.tooltip = 'The connection is using Shopify as the primary integration.'
          // TODO: isShowIntegrationAccounts should come from an API, hard coding it for now
          presenter.isShowIntegrationAccounts = true
          break
        case 'bigcommerce':
          presenter.label = 'BigCommerce'
          presenter.tooltip = 'The connection is using BigCommerce as the primary integration.'
          presenter.isShowIntegrationAccounts = true
          break
        case 'woocommerce':
          presenter.label = 'WooCommerce'
          presenter.tooltip = 'The connection is using WooCommerce as the primary integration.'
          presenter.isShowIntegrationAccounts = true
          break
        case 'storefront':
          presenter.label = 'Shopify'
          presenter.tooltip = 'The connection is using Shopify as the primary integration.'
          break
        case 'console-print-labels':
          presenter.label = 'Console (Labels)'
          presenter.tooltip = 'The connection is using an integration (e.g. Shopify or ShipStation) as the primary integration.'
          break
        case 'console-register-tracking-numbers':
          presenter.label = 'Console (Register Tracking)'
          presenter.tooltip = 'The connection is using an integration (e.g. Shopify or ShipStation) as the primary integration.'
          break
        // compensate for bug in API (MKP-170)
        case 'console-register-tracking-number':
          presenter.label = 'Console (Register Tracking)'
          presenter.tooltip = 'This connection is using an integration (e.g. Shopify or ShipStation) as the primary integration.'
          break
        case 'api':
          presenter.label = 'API'
          presenter.tooltip = 'This connection is using a custom API integration.'
          break
        default:
          presenter.label = 'Console'
          presenter.tooltip = 'This connection is using the web console as the primary integration.'
      }
      return presenter
    }
  },
  props: {
    connection: {
      type: Object,
      required: true
    },
    showTooltip: {
      type: Boolean,
      default: true
    }
  }
}
</script>
