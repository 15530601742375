<template>
  <div class="has-background-white pa-lg rounded-lg mb-lg">
    <div class="columns">
      <div class="column">
        <!-- Intro -->
        <div class="mb-lg">
          <p class="is-size-4 mb-sm has-text-weight-bold">Upload Product Template</p>
          <p>
            Before you upload any products, first you need to download the appropriate
            template for the category you selected. After you fill out and complete
            the template, please upload your file below.
          </p>
        </div>
        <!-- Template -->
        <div class="mb-lg">
          <p class="has-text-weight-bold mb-sm">Step 1: Download Template</p>
          <a href="#" @click.prevent class="button is-light pa-xl">
            <b-icon icon="download" class="mr-lg" />
            <p>Download {{ proposal.department.name }} Template</p>
          </a>
        </div>
        <!-- File Upload -->
        <div class="mb-lg py-lg">
          <!-- Upload -->
          <p class="has-text-weight-bold mb-sm">Step 2: Upload Completed Template</p>
          <CoreUpload @file:uploaded="updateTempfile" @file:reset="resetTempfile"/>
        </div>
        <!-- Begin Import -->
        <div class="mb-lg">
          <div class="columns">
            <div class="column is-one-quarter">
              <b-button
                type="is-primary"
                size="is-medium"
                :disabled="!ready || saving"
                :loading="saving"
                expanded
                @click="save"
              >Begin Import</b-button>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-one-third">
        <!-- Tips to Help Pass Compatibility -->
        <div class="px-lg">
          <div class="pa-lg has-background-light rounded-lg">
            <p class="has-text-weight-bold has-text-grey is-size-5 has-text-centered mb-sm">Import Tips</p>
            <div class="content is-size-7">
              <p>
                <span class="has-text-weight-bold has-text-info">Tip #1</span>
                Templates usually have an "instructions" sheet -- follow the instructions carefully for each field listed in the template.
              </p>
              <p>
                <span class="has-text-weight-bold has-text-info">Tip #2</span>
                Use only the allowed values defined in the template for attributes like color &amp; size.
              </p>
              <p>
                <span class="has-text-weight-bold has-text-info">Tip #3</span>
                Be mindful about SKUs and GTINs/UPCs that start with a leading 0. Your spreadsheet editor may remove leading zeros.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CoreUpload from '@/components/CoreUpload'

export default {
  components: {
    CoreUpload
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/feed-queue/'
    },
    body () {
      if (this.tempfile === null) return {}
      const body = {
        template: {
          id: this.proposal.department.template.id
        },
        behavior: 'update',
        direction: 'import',
        data_type: 'products',
        source: 'console',
        info: {
          proposal_id: this.proposal.id,
          proposal_feed_purpose: 'import',
          proposal_tempfile_id: this.tempfile.id
        },
        file: {
          tempfile: {
            id: this.tempfile.id
          }
        }
      }
      // if proposal type is for new products, use 'merge' behavior
      if (this.proposal.proposal_type === 'new_product') {
        body.behavior = 'merge'
      }
      return body
    },
    ready () {
      return this.tempfile !== null
    }
  },
  data () {
    return {
      tempfile: null,
      saving: false,
      errors: {}
    }
  },
  methods: {
    updateTempfile (file, response) {
      this.tempfile = response
      this.$emit('tempfile:created', response)
    },
    resetTempfile () {
      this.tempfile = null
    },
    save () {
      this.saving = true
      this.$http.post(this.endpoint + 'enqueue/', this.body).then(response => {
        this.feedQueue = response.data
        this.$http.put(this.endpoint + this.feedQueue.id + '/run/').then(response => {
          this.$buefy.toast.open({ type: 'is-success', message: 'Import scheduled!' })
          this.$router.push({ name: 'proposal.tasks.submit', params: { id: this.proposal.id } })
        }).finally(() => {
          this.saving = false
        })
      })
    }
  },
  props: ['proposal', 'feeds']
}
</script>
