import Vue from 'vue'

Vue.filter('cloudinary', function (value, width, height, crop, format) {
  // split the assets from protocol
  const parts = value.split('https://')

  // build out transformations
  let transformations = 'f_auto'
  if (width) transformations += ',w_' + width
  if (height) transformations += ',h_' + height
  if (crop) transformations += ',c_' + crop

  // construct the final url
  const base = 'https://res.cloudinary.com/revcascade'
  return (format === 'png')
    ? base + '/' + transformations + '/' + parts[1] + '.png'
    : base + '/' + transformations + '/' + parts[1]
})
