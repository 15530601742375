import Index from './views/Index'
export default [{
  path: '/',
  name: 'home',
  component: Index,
  meta: {
    skipAuth: true,
    skipContext: true
  }
}]
