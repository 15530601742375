<template>
  <div>
    <filter-toolbar
      :hasChanges="hasChanges"
      :appliedFilters="appliedFilters"
      @search="search"
      @update="update"
      @clear="clear">
      <remote-facet-filter
        class="border-b"
        v-model="filters.facets.connection_id"
        response-type="object"
        :resourceLabel="(context.isSupplier()) ? 'retailer.name' : 'brand.name'"
        :filter="filterOptions.connection"
        :endpoint="context.route + '/connections/?short=1'"
        @input="change"
      />
      <facet-filter
        class="border-b"
        v-model="filters.facets.proposal_type"
        :filter="filterOptions.type"
        @input="change"
      />
      <template v-if="context.isMerchant()">
        <facet-filter
          class="border-b"
          v-model="filters.facets.is_department_owner"
          :filter="departmentOwnerFilter"
          @input="change"
        />
        <facet-filter
          class="border-b"
          v-model="filters.facets.is_department_manager"
          :filter="departmentManagerFilter"
          @input="change"
        />
        <facet-filter
          class="border-b"
          v-model="filters.facets.is_merchandising_contact"
          :filter="supplierOwnerFilter"
          @input="change"
        />
        <remote-facet-filter
          class="border-b"
          v-model="filters.facets.department_id"
          response-type="object"
          :resourceLabel="'name'"
          :filter="filterOptions.department"
          :endpoint="context.route + '/departments/?pagination=0'"
          @input="change"
        />
      </template>
      <facet-filter
        class="border-b"
        v-model="filters.facets.status"
        :filter="filterOptions.status"
        @input="change"
      />
      <template v-if="context.isMerchant()">
        <boolean-filter
          class="border-b"
          v-model="filters.facets.is_acknowledged"
          :filter="filterOptions.acknowledged"
          @input="change"
        />
      </template>
      <date-filter
        class="border-b"
        v-model="filters.timestamps.proposed_at"
        :filter="filterOptions.proposedAt"
        @input="change"
      />
      <date-filter
        v-model="filters.timestamps.created_at"
        :filter="filterOptions.createdAt"
        @input="change"
      />
    </filter-toolbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import * as filterOptions from '../../filters.js'
import BooleanFilter from '@/components/Filters/BooleanFilter'
import FacetFilter from '@/components/Filters/FacetFilter'
import DateFilter from '@/components/Filters/DateFilter'
import RemoteFacetFilter from '@/components/Filters/RemoteFacetFilter'
import FilterToolbar from '@/components/Filters/FilterToolbar'

export default {
  components: {
    BooleanFilter,
    DateFilter,
    FacetFilter,
    FilterToolbar,
    RemoteFacetFilter
  },
  data () {
    return {
      hasChanges: false,
      filters: null,
      // filters
      filterOptions: filterOptions
    }
  },
  computed: {
    ...mapGetters(['auth', 'context']),
    departmentOwnerFilter () {
      return {
        label: 'Department Merchandiser',
        key: 'is_department_owner',
        icon: 'account',
        options: [{
          value: this.auth.user.id,
          label: 'Is Me'
        }]
      }
    },
    departmentManagerFilter () {
      return {
        label: 'Department Manager',
        key: 'is_department_manager',
        icon: 'account',
        options: [{
          value: this.auth.user.id,
          label: 'Is Me'
        }]
      }
    },
    supplierOwnerFilter () {
      return {
        label: 'Supplier Merchandiser',
        key: 'is_merchandising_contact',
        icon: 'account',
        options: [{
          value: this.auth.user.id,
          label: 'Is Me'
        }]
      }
    }
  },
  methods: {
    change () {
      this.hasChanges = true
    },
    search (searchQuery) {
      this.filters.search = {
        parameter: 'search',
        query: searchQuery
      }
      this.$emit('filter', this.filters)
    },
    update () {
      this.hasChanges = false
      this.filters.preset = 'custom'
      this.$emit('filter', this.filters)
    },
    clear () {
      this.filters = {
        preset: 'all',
        facets: {},
        search: {},
        timestamps: {}
      }
      this.$emit('filter', this.filters)
    }
  },
  props: {
    appliedFilters: {
      type: Object,
      required: true
    }
  },
  watch: {
    appliedFilters: {
      deep: true,
      immediate: true,
      handler (filters) {
        this.filters = filters
      }
    }
  }
}
</script>
