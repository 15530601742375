<template>
  <div class="modal-card">
    <section class="modal-card-body">
      <!-- Loading -->
      <div v-if="loading">
        <loading message="Loading latest Instagram photos" />
      </div>
      <!-- Loaded -->
      <div v-if="photos.length > 0" class="columns is-multiline">
        <div v-for="photo in photos" class="column is-one-third" :key="photo.id">
          <a v-if="photo.media_type === 'IMAGE' || photo.media_type === 'CAROUSEL_ALBUM'"
            class="border has-border-lg is-block pa-xs"
            :class="(selected !== null && selected.id === photo.id) ? 'has-border-bottom' : ''"
            @click.prevent="select(photo)">
              <figure class="image is-square">
                <img :src="photo.media_url"/>
              </figure>
          </a>
          <div v-else class="pa-xs border has-border-lg">
            <figure class="image is-square">
              <img src="https://res.cloudinary.com/revcascade/image/upload/v1579126247/static/video-placeholder.png" />
            </figure>
          </div>
        </div>
      </div>
    </section>
    <footer class="modal-card-foot" style="justify-content:flex-end">
      <button class="button  is-medium" type="button" @click="$parent.close()">Close</button>
      <button class="button is-primary is-medium" :disabled="selected === null" @click.prevent="use">Use Photo</button>
    </footer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/instagram-feed/'
    }
  },
  data () {
    return {
      loading: true,
      photos: [],
      selected: null
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint).then(response => {
        this.photos = response.data.data
        this.loading = false
      })
    },
    select (photo) {
      this.selected = photo
    },
    use () {
      this.$emit('use:photo', this.selected)
      this.$parent.close()
    }
  },
  mounted () {
    this.load()
  }
}
</script>
