<template>
  <div class="mb-sm">
    <div class="columns is-mobile is-multiline">
      <!-- Closed Orders -->
      <div class="column is-half-mobile is-one-quarter-tablet">
        <div class="has-background-white pa-md rounded-lg has-text-centered">
          <p v-if="loading" class="mb-sm"><b-icon icon="loading" custom-class="mdi-spin"></b-icon></p>
          <p v-else class="is-size-4">
            {{ $numbers(results.orders_count).format({thousandSeparated: true, mantissa: 0}) }}
          </p>
          <p class="heading"># Closed Orders</p>
        </div>
      </div>
      <!-- Days to Close -->
      <div class="column is-half-mobile is-one-quarter-tablet">
        <div class="has-background-white pa-md rounded-lg has-text-centered">
          <p v-if="loading" class="mb-sm"><b-icon icon="loading" custom-class="mdi-spin"></b-icon></p>
          <p v-else class="is-size-4">
            {{ $numbers(results.avg_days_to_fulfill).format({thousandSeparated: true, mantissa: 2}) }}
          </p>
          <p class="heading">Days to Close</p>
        </div>
      </div>
      <!-- Late Orders -->
      <div class="column is-half-mobile is-one-quarter-tablet">
        <div class="has-background-white pa-md rounded-lg has-text-centered">
          <p v-if="loading" class="mb-sm"><b-icon icon="loading" custom-class="mdi-spin"></b-icon></p>
          <p v-else class="is-size-4">
            {{ $numbers(results.late_incidents_count).format({thousandSeparated: true, mantissa: 0}) }}
          </p>
          <p class="heading">Late Orders</p>
        </div>
      </div>
      <!-- Days to Close -->
      <div class="column is-half-mobile is-one-quarter-tablet">
        <div class="has-background-white pa-md rounded-lg has-text-centered">
          <p v-if="loading" class="mb-sm"><b-icon icon="loading" custom-class="mdi-spin"></b-icon></p>
          <p v-else class="is-size-4">
            {{ $numbers(results.no_inventory_incidents_count).format({thousandSeparated: true, mantissa: 0}) }}
          </p>
          <p class="heading">No Stock Cancels</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/compliance-summary/'
    },
    query () {
      const qs = {
        params: {
          exclude_invoicing_incidents: 1,
          created_at_gte: this.daterange.start,
          created_at_lte: this.daterange.end
        }
      }
      return qs
    }
  },
  data () {
    return {
      loading: true,
      results: null
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint, this.query).then(response => {
        this.results = response.data
      }).finally(() => {
        this.loading = false
      })
    }
  },
  mounted () {
    this.load()
  },
  props: ['daterange', 'timestamp'],
  watch: {
    timestamp: function (value) {
      this.load()
    }
  }
}
</script>
