<template>
  <div>
    <!-- Actions Toolbar -->
    <div class="has-background-white rounded-lg px-md py-sm mb-xxs">
      <div class="columns is-vcentered is-mobile">
        <div class="column is-narrow">
          <b-dropdown :disabled="loading || selected.length === 0">
            <button class="button" slot="trigger" slot-scope="{ active }" :disabled="selected.length === 0">
              <span>Actions</span>
              <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
            </button>
            <b-dropdown-item has-link><a href="#" @click.prevent class="has-text-grey-light">No Actions</a></b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="column">
          <div class="has-text-right is-size-7">
            <!-- Counts -->
            <p v-if="loading">Loading...</p>
            <p v-else>
              {{ lines.length }}
              {{ (lines.length === 1) ? 'line item' : 'line items' }}
            </p>
          </div>
        </div>
        <div class="column is-narrow">
          <b-dropdown :disabled="loading" position="is-bottom-left">
            <button class="button" slot="trigger" slot-scope="{ active }">
              <span>View</span>
              <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
            </button>
            <b-dropdown-item has-link><a href="#" @click.prevent="view = 'fulfillment'">Fulfillment</a></b-dropdown-item>
            <b-dropdown-item has-link><a href="#" @click.prevent="view = 'pricing'">Pricing</a></b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </div>
    <!-- Reults -->
    <div>
      <b-table
        :data="lines"
        :checked-rows.sync="selected"
        :mobile-cards="false"
        :loading="loading || orderRefreshing"
        hoverable
        checkbox-position="left"
        class="is-size-7">
        <template>
          <!-- Image -->
          <b-table-column
            label=""
            cell-class="nowrap"
            centered
            width="64"
            v-slot="props">
            <figure class="image is-48x48 mb-xs">
              <img class="rounded-lg" :src="cdnTransform(props.row.variant.media[0].url, 48, 48, 'fill')" width="48" height="48" >
            </figure>
          </b-table-column>
          <!-- Item -->
          <b-table-column
            label="Item"
            cell-class="nowrap"
            v-slot="props">
            <p class="is-size-6">
              <a href="#" @click.prevent="$emit('inspect:variant', props.row)" class="has-text-info has-text-weight-bold">
              {{ props.row.variant.name }}
              </a>
            </p>
            <p v-if="context.isMerchant() && props.row.variant.retailer_identifiers.length > 0">
              <span class="is-size-8 has-text-grey-light">Merchant SKU </span>
              <span class="has-text-weight-bold">{{ props.row.variant.retailer_identifiers[0].identifier }}</span>
            </p>
            <p v-else>
              <span class="is-size-8 has-text-grey-light">Supplier SKU </span>
              <span class="has-text-weight-bold">{{ props.row.variant.brand_identifier }}</span>
            </p>
            <p v-if="props.row.variant.upc">
              <span class="is-size-8 has-text-grey-light">UPC </span>
              <span class="has-text-weight-bold">{{ props.row.variant.upc }}</span>
            </p>
            <!-- Show Monogram -->
            <div style="max-width:500px; white-space: break-spaces !important;" v-if="props.row.metadata.length > 0">
              <div v-for="metadata in props.row.metadata" :key="metadata.id" class="has-background-info-light pa-md my-sm is-size-8">
                <Personalization v-if="metadata.name === 'Monogram'" :value="metadata.value" />
              </div>
            </div>
          </b-table-column>
          <!-- # Ordered -->
          <b-table-column
            label="# Ordered"
            header-class="nowrap"
            cell-class="nowrap"
            centered
            :visible="columns.includes('units')"
            width="96"
            v-slot="props">
            {{ props.row.quantity }}
          </b-table-column>
          <!-- # Open -->
          <b-table-column
            label="# Open"
            header-class="nowrap"
            cell-class="nowrap"
            centered
            :visible="columns.includes('open')"
            width="96"
            v-slot="props">
            {{ props.row.quantity_open }}
          </b-table-column>
          <!-- # Shipped -->
          <b-table-column
            label="# Shipped"
            header-class="nowrap"
            cell-class="nowrap"
            centered
            :visible="columns.includes('shipped')"
            width="96"
            v-slot="props">
            {{ props.row.quantity_shipped }}
          </b-table-column>
          <!-- # Canceled -->
          <b-table-column
            label="# Canceled"
            header-class="nowrap"
            cell-class="nowrap"
            centered
            :visible="columns.includes('canceled')"
            width="96"
            v-slot="props">
            {{ props.row.quantity_canceled }}
          </b-table-column>
          <!-- # Returned -->
          <b-table-column
            label="# Returned"
            header-class="nowrap"
            cell-class="nowrap"
            centered
            :visible="columns.includes('returned')"
            width="96"
            v-slot="props">
            {{ props.row.quantity_returned }}
          </b-table-column>
          <!-- Cost -->
          <b-table-column
            label="Cost"
            cell-class="nowrap"
            centered
            :visible="columns.includes('cost')"
            width="96"
            v-slot="props">
            <CurrencyLocale :value="parseFloat(props.row.net_price)" :currency="currency"/>
          </b-table-column>
          <!-- Price -->
          <b-table-column
            label="Price"
            header-class="nowrap"
            cell-class="nowrap"
            centered
            :visible="context.isMerchant() && columns.includes('price')"
            width="96"
            v-slot="props">
            <CurrencyLocale :value="parseFloat(props.row.price)" :currency="currency"/>
          </b-table-column>
          <!-- Status -->
          <b-table-column
            label="Status"
            centered
            width="96"
            v-slot="props">
            <OrderLineStatusPresenter :line="props.row" />
          </b-table-column>
          <!-- Status -->
          <b-table-column
            label=""
            centered
            width="96"
            v-slot="props">
            <b-dropdown
            position="is-bottom-left">
              <button class="button is-primary is-outlined is-small" slot="trigger">
                <b-icon icon="dots-vertical"></b-icon>
              </button>
              <template v-if="context.isSupplier()">
                <b-dropdown-item v-if="props.row.status === 'open' || props.row.status === 'partial'" has-link>
                  <a href="#" @click.prevent="$emit('backorder:line', props.row)">Mark as Backordered</a>
                </b-dropdown-item>
                <b-dropdown-item v-else-if="props.row.status === 'backordered'" has-link>
                  <a href="#" @click.prevent="$emit('backorder:line', props.row)">Mark as Ready to Ship</a>
                </b-dropdown-item>
                <b-dropdown-item v-else has-link>
                  <a href="#" @click.prevent class="has-text-grey-light">No Actions</a>
                </b-dropdown-item>
              </template>
              <template v-else>
                <b-dropdown-item has-link>
                  <a href="#" @click.prevent class="has-text-grey-light">No Actions</a>
                </b-dropdown-item>
              </template>
            </b-dropdown>
          </b-table-column>
        </template>
        <template #empty>
          <div class="py-lg has-text-grey has-text-centered">
            <p>No lines have been registered yet.</p>
          </div>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CdnTransform from '@/mixins/CdnTransform'
import OrderLineStatusPresenter from '@/components/Presenters/OrderLine/Status'
import Personalization from './Personalization'

export default {
  components: {
    OrderLineStatusPresenter,
    Personalization
  },
  data () {
    return {
      loading: false,
      selected: [],
      view: 'fulfillment'
    }
  },
  computed: {
    ...mapGetters(['context']),
    columns () {
      if (this.view === 'pricing') return ['item', 'units', 'cost', 'price']
      return ['item', 'units', 'open', 'shipped', 'canceled']
    }
  },
  methods: {
    closeModals () {
      this.inspectEnvelope = null
    }
  },
  mixins: [CdnTransform],
  mounted () {

  },
  props: ['orderId', 'orderRefreshing', 'lines', 'currency']
}
</script>
