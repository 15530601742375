<template>
  <div>
    <div class="columns">
      <div class="column">
        <b-datepicker size="is-medium" v-model="dates" @input="datesChanged" range></b-datepicker>
      </div>
      <div class="column">
        <b-select size="is-medium" v-model="selectedRange" @input="selectedRangeChanged" expanded>
          <option v-for="range in ranges" :value="range" :key="range.value" selected>{{ range.label }}</option>
        </b-select>
      </div>
    </div>
  </div>
</template>
<script>
import Ranges from '@/assets/js/performanceDates'
import daysjs from 'dayjs'
export default {
  name: 'DateRange',
  data () {
    return {
      ranges: Ranges,
      selectedRange: null,
      min: new Date(2019, 0, 1),
      max: new Date(),
      dates: []
    }
  },
  watch: {
    dates (newVal, oldVal) {
      if (newVal[0] !== oldVal[0] || newVal[1] !== oldVal[1]) {
        this.$emit('update:dates', {
          start: this.dates[0],
          end: this.dates[1]
        })
      }
    }
  },
  methods: {
    selectedRangeChanged () {
      if (this.selectedRange !== null) {
        this.dates = [
          new Date(this.selectedRange.start),
          new Date(this.selectedRange.end)
        ]
      }
    },
    datesChanged () {
      this.ranges.custom.start = daysjs(this.dates[0])
      this.ranges.custom.end = daysjs(this.dates[1])
      this.selectedRange = this.ranges.custom
    }
  },
  mounted () {
    this.selectedRange = this.ranges.this_month
    this.selectedRangeChanged()
  }
}
</script>
