<template>
  <div>
    <b-autocomplete
      class="mb-md"
      v-model="collection"
      size="is-medium"
      :data="collections"
      :loading="loading"
      :placeholder="placeholderText"
      @typing="loadCollections"
      @select="selectCollection">
      <template slot-scope="props">
        <div>
          <p class="">{{ props.option.title }}</p>
          <p class="has-text-grey">ID: {{ props.option.id }}</p>
        </div>
      </template>
    </b-autocomplete>
    <div class="mb-md">
      <b-radio v-model="type" native-value="smart" @input="collection = ''">Smart Collections</b-radio>
      <b-radio v-model="type" native-value="custom" @input="collection = ''">Manual Collections</b-radio>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import debounce from 'lodash/debounce'
export default {
  components: {

  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/shopify/' + this.account.id + '/collections/'
    },
    query () {
      return {
        params: {
          collection_type: this.type,
          search: this.collection
        }
      }
    },
    collectionTypeKey () {
      if (this.type === 'smart') return 'smart_collections'
      return 'custom_collections'
    },
    placeholderText () {
      if (this.type === 'smart') return 'Search for a smart collection...'
      return 'Search for a manual collection...'
    }
  },
  data () {
    return {
      name: null,
      type: 'smart',
      loading: false,
      collections: [],
      collection: null
    }
  },
  methods: {
    loadCollections: debounce(function (name) {
      if (name === null || name.length === 0) {
        this.collections = []
        return
      }
      this.loading = true
      this.$http.get(this.endpoint, this.query).then(response => {
        this.collections = response.data[this.collectionTypeKey]
      }).finally(() => {
        this.loading = false
      })
    }, 500),
    selectCollection (collection) {
      this.$emit('input', collection)
    }
  },
  mounted () {

  },
  props: ['account']
}
</script>
