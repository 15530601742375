<template>
  <div>
    <!-- Vendors -->
    <div class="columns is-centered">
      <div class="column is-half">
        <div class="has-background-white rounded-xl pa-lg">
          <p class="has-text-weight-bold has-text-centered mb-lg">Select Vendor</p>
          <b-autocomplete
            :data="vendors"
            placeholder="Search for suppliers..."
            field="name"
            :loading="loadingVendors"
            @typing="searchVendors"
            @select="setVendor">
            <template slot-scope="props">
              <p>{{ props.option.name }} (ID: {{ props.option.id }})</p>
            </template>
            <template slot="empty">
              <p v-if="loadingVendors">Loading...</p>
              <p v-else>No results found.</p>
            </template>
          </b-autocomplete>
        </div>
      </div>
    </div>
    <!-- Retailers -->
    <div class="columns is-centered">
      <div class="column is-half mx-auto">
        <div class="has-background-white rounded-xl pa-lg">
          <p class="has-text-weight-bold has-text-centered mb-lg">Select Retailer</p>
          <b-autocomplete
            :data="retailers"
            placeholder="Search for retailers..."
            field="name"
            :loading="loadingRetailers"
            @typing="searchRetailers"
            @select="setRetailer">
            <template slot-scope="props">
              <p>{{ props.option.name }} (ID: {{ props.option.id }})</p>
            </template>
            <template slot="empty">
              <p v-if="loadingRetailers">Loading...</p>
              <p v-else>No results found.</p>
            </template>
          </b-autocomplete>
        </div>
      </div>
    </div>

    <!-- View All -->
    <div v-if="auth.isStaff" class="py-md has-text-centered">
      <router-link :to="{ name: 'suppliers' }">View Supplier Roster (Staff Only)</router-link>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import debounce from 'lodash/debounce'
export default {
  data: function () {
    return {
      loadingVendors: false,
      loadingRetailers: false,
      vendors: [],
      vendor: null,
      retailers: [],
      retailer: null
    }
  },
  computed: {
    ...mapGetters(['auth'])
  },
  methods: {
    searchVendors: debounce(function (name) {
      this.loadingVendors = true
      const query = {
        pagination: 0,
        short: 1,
        order_by: 'name',
        search: name
      }
      this.$http.get('brands/', { params: query }).then(response => {
        this.vendors = response.data.results
        this.loadingVendors = false
      })
    }, 500),
    searchRetailers: debounce(function (name) {
      this.loadingRetailers = true
      const query = {
        short: 1,
        pagination: 0,
        order_by: 'name',
        search: name
      }
      this.$http.get('retailers/', { params: query }).then(response => {
        this.retailers = response.data.results
        this.loadingRetailers = false
      })
    }, 500),
    setVendor (vendor) {
      this.$store.dispatch('setContext', { type: 'vendor', partner: vendor })
      if ('intended' in this.$route.query) {
        this.$router.push({ path: this.$route.query.intended })
        return
      }
      this.$emit('navigate:to')
    },
    setRetailer (retailer) {
      this.$store.dispatch('setContext', { type: 'retailer', partner: retailer })
      if ('intended' in this.$route.query) {
        this.$router.push({ path: this.$route.query.intended })
        return
      }
      this.$emit('navigate:to')
    },
    customLabel ({ name, id }) {
      return name + ' (ID: ' + id + ')'
    }
  },
  mounted: function () {

  }
}
</script>
