<template>
  <div class="pa-md">
    <!-- Error -->
    <div v-if="('general' in errors)" class="mb-md">
      <p class="has-text-danger">{{ errors.general }}</p>
    </div>
    <!-- Fields -->
    <Configure v-model="fields" :task="task" :accounts="accounts"/>
    <!-- Navigate -->
    <div class="py-lg">
      <div class="columns">
        <div class="column">
          <b-button
            type="is-light rounded-lg"
            expanded
            @click="goBack"
            ><b-icon icon="arrow-left"></b-icon>
          </b-button>
        </div>
        <div class="column">
          <b-button
            type="is-primary rounded-lg"
            expanded
            :disabled="!ready"
            :loading="saving"
            @click="goForward"
          >Save & Complete</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Configure from '../components/Integrations/Configure'
export default {
  components: {
    Configure
  },
  computed: {
    ...mapGetters(['app', 'context']),
    endpoint () {
      const platformId = this.fields && this.fields.id
      const path = platformId ? this.context.route + `/platform-accounts/${platformId}/` : this.context.route + '/platform-accounts/'
      return path
    },
    method () {
      const platformId = this.fields && this.fields.id
      return platformId ? 'patch' : 'post'
    },
    ready () {
      return (
        this.fields !== null &&
        this.fields.platform &&
        this.fields.platform.code
      )
    }
  },
  data () {
    return {
      fields: null,
      saving: false,
      selectedOption: null,
      errors: {}
    }
  },
  methods: {
    goBack () {
      this.$router.push({ name: 'retailer.onboarding.integration' })
    },
    goForward () {
      this.saving = true
      const json = this.fields
      this.$http[this.method](this.endpoint, json).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Integration preference updated!' })
        this.taskComplete()
      }).catch(error => {
        if (error.response && error.response.status === 400) {
          this.errors = error.response.data
        }
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    },
    taskComplete () {
      const endpoint = this.context.route + '/assigned-tasks/' + this.$route.params.task + '/complete/'
      const taskBody = (typeof this.task.info === 'object') ? { info: this.task.info } : { info: {} }
      taskBody.info.integrationConfigure = true
      this.$http.put(endpoint, taskBody).then(() => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Task Completed!' })
        this.$router.push({ name: 'retailer.onboarding' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    if (this.task && this.task.info && this.task.info.selectedOption) {
      this.selectedOption = this.task.info.selectedOption
    }
  },
  props: {
    task: {
      type: Object,
      require: true
    },
    accounts: {
      type: Array,
      required: true
    },
    isUpdating: {
      type: Boolean
    }
  }
}
</script>
