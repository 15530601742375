<template>
  <div class="has-text-grey">
    {{ direction }} {{ dataType }} <span class="has-text-grey-light">
    via</span> Template: {{ feed.template.name }} ({{ feed.template.id }})</div>
</template>

<script>
export default {
  computed: {
    direction () {
      if ('proposal_feed_purpose' in this.feed.info) {
        return this.$utils.upperFirst(this.feed.info.proposal_feed_purpose)
      }
      return this.$utils.upperFirst(this.feed.direction)
    },
    dataType () {
      switch (this.feed.data_type) {
        case 'products':
          return 'Products'
        case 'proposals':
          return 'Proposal'
        case 'inventory':
          return 'Inventory'
        default:
          return 'Other'
      }
    }
  },
  props: ['feed']
}
</script>
