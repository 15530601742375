import Index from './views/Index'
import List from './views/List'
import Introduction from './views/Introduction'
import Create from './views/Create'
import Detail from './views/Detail'
import Tasks from './views/Tasks'
import Import from './views/Import'
import Build from './views/Build'
import BuildResults from './views/BuildResults'
import Review from './views/Review'
import Compatibility from './views/Compatibility'
import Submit from './views/Submit'
import Submitted from './views/Submitted'
import Summary from './views/Summary'
import Items from './views/Items'
import Feeds from './views/Feeds'
import Products from './views/Products'
import Product from './views/Product'
import Imports from './views/Imports'
import Issues from './views/Issues'
import IssueDetails from './views/IssueDetails'

export default [{
  path: '/catalog/proposals/dashboard',
  name: 'proposals.dashboard',
  component: Index
}, {
  path: '/catalog/proposals/introduction',
  name: 'proposals.introduction',
  component: Introduction
}, {
  path: '/catalog/proposals/create',
  name: 'proposals.create',
  component: Create
}, {
  path: '/catalog/proposals/list',
  name: 'proposals.list',
  component: List
}, {
  path: '/catalog/proposals/:id/tasks',
  component: Tasks,
  children: [{
    path: 'import',
    name: 'proposal.tasks.import',
    component: Import
  }, {
    path: 'feeds',
    name: 'proposal.tasks.feeds',
    component: Feeds
  }, {
    path: 'build',
    name: 'proposal.tasks.build',
    component: Build
  }, {
    path: 'build-results',
    name: 'proposal.tasks.build.results',
    component: BuildResults
  }, {
    path: 'products',
    name: 'proposal.tasks.products',
    component: Products
  }, {
    path: 'review',
    name: 'proposal.tasks.review',
    component: Review
  }, {
    path: 'submit',
    name: 'proposal.tasks.submit',
    component: Submit
  }, {
    path: 'submitted',
    name: 'proposal.tasks.submitted',
    component: Submitted
  }]
}, {
  path: '/catalog/proposals/:id/detail',
  component: Detail,
  children: [{
    path: 'summary',
    name: 'proposal.detail.summary',
    component: Summary
  }, {
    path: 'products',
    name: 'proposal.detail.products',
    component: Products
  }, {
    path: 'product/:product',
    name: 'proposal.detail.product',
    component: Product
  }, {
    path: 'items',
    name: 'proposal.detail.items',
    component: Items
  }, {
    path: 'compatibility',
    name: 'proposal.detail.compatibility',
    component: Compatibility
  }, {
    path: 'imports',
    name: 'proposal.detail.imports',
    component: Imports
  }, {
    path: 'issues',
    name: 'proposal.detail.issues',
    component: Issues
  }, {
    path: 'issues/:issueId',
    name: 'proposal.detail.issues.notes',
    component: IssueDetails
  }]
}]
