import Index from './views/Index'
import Access from './views/Access'
import Dashboard from './views/Dashboard'
import Onboarding from './views/Onboarding'
import Product from './views/Product'
import Products from './views/Products'
import Preview from './views/Preview'
import Pricing from './views/Pricing'
import Profile from './views/Profile'
import Signup from './views/Signup'
import Variant from './views/Variant'

export default [{
  path: '/network',
  component: Index,
  children: [{
    path: '',
    name: 'network.dashboard',
    component: Dashboard
  }, {
    path: 'onboarding',
    name: 'network.onboarding',
    component: Onboarding
  }, {
    path: 'products',
    name: 'network.products',
    component: Products
  }, {
    path: 'access',
    name: 'network.access',
    component: Access
  }, {
    path: 'pricing',
    name: 'network.pricing',
    component: Pricing
  }, {
    path: 'products/:id/variant/:variant',
    name: 'network.variant',
    component: Variant
  }, {
    path: 'products/:id',
    name: 'network.product',
    component: Product
  }, {
    path: 'profile',
    name: 'network.profile',
    component: Profile
  }, {
    path: 'preview',
    name: 'network.preview',
    component: Preview
  }]
}, {
  path: '/network/signup',
  name: 'network.signup',
  component: Signup
}]
//   path: '/network/pricing',
//   name: 'network.pricing',
//   component: Pricing
// }, {

// }, {
//   path: '/network/products',
//   name: 'network.products',
//   component: Products
// }, , {
