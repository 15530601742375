import { render, staticRenderFns } from "./AttributeDetails.vue?vue&type=template&id=d6ab9524"
import script from "./AttributeDetails.vue?vue&type=script&lang=js"
export * from "./AttributeDetails.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports