<template>
  <div>
    <!-- Content -->
    <div class="">
      <!-- No EDI Account -->
      <div v-if="!ediAccount" class="mb-lg">
        <p class="has-text-weight-bold mb-md">Create EDI Account</p>
        <p class="mb-lg">
          To create your {{ app.productName }} EDI account, please provide your
          EDI Qualifier and EDI Identifier.
        </p>
        <!-- Error -->
        <div v-if="('general' in errors)" class="mb-md">
          <p class="has-text-danger">{{ errors.general }}</p>
        </div>
        <!-- Inputs -->
        <div class="columns">
          <div class="column is-one-third-desktop">
            <b-field label="EDI Qualifier" >
              <b-input
                size="is-medium"
                v-model="qualifier"
                :maxlength="2"
                @input="(val) => (qualifier = qualifier.toUpperCase())"
              ></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="EDI ID" >
              <b-input v-model="identifier" size="is-medium"></b-input>
            </b-field>
          </div>
        </div>
      </div>
    </div>
    <!-- Navigate -->
    <div class="py-lg">
      <div class="columns is-centered">
        <div class="column">
          <b-button
            type="is-light rounded-lg"
            size="is-medium"
            @click="goBack"
            expanded
            ><b-icon icon="arrow-left"></b-icon>
          </b-button>
        </div>
        <div class="column">
          <b-button
            type="is-primary rounded-lg"
            size="is-medium"
            expanded
            :disabled="!ready || saving"
            :loading="saving"
            @click="goForward"
          >Create EDI Account</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {

  },
  computed: {
    ...mapGetters(['app', 'context']),
    body () {
      const json = {
        qualifier: this.qualifier,
        identifier: this.identifier,
        connection: { id: this.connection.id }
      }
      return json
    },
    ready () {
      if (this.ediAccount) {
        return true
      }
      return (this.qualifier && this.identifier)
    }
  },
  data () {
    return {
      qualifier: '',
      identifier: null,
      saving: false,
      errors: {}
    }
  },
  methods: {
    goBack () {
      this.$emit('navigate', 'Intro')
    },
    goForward () {
      this.saving = true
      const endpoint = this.context.route + '/edi-accounts/onboarding/'
      this.$http.post(endpoint, this.body).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Account created!' })
        this.$emit('edi:created', response.data)
        this.$emit('navigate', 'Account')
      }).catch(error => {
        this.errors.general = error.response.data.detail
      }).finally(() => {
        this.saving = false
      })
    }
  },
  props: {
    connection: {
      type: Object,
      required: true
    },
    task: {
      type: Object,
      required: true
    },
    ediAccount: {
      type: Object,
      default: null
    }
  }
}
</script>
