import dates from '@/assets/js/dates'

export const connection = {
  label: 'Connection',
  key: 'connection_id',
  icon: 'lan',
  tooltip: 'This is a tooltip'
}

export const acknowledged = {
  label: 'Acknowledged?',
  key: 'acknowledged',
  icon: 'check',
  tooltip: 'This is a tooltip',
  options: [{
    value: 1,
    label: 'Yes'
  }, {
    value: 0,
    label: 'No'
  }]
}

export const isPaid = {
  label: 'Marked as Paid?',
  key: 'is_paid',
  icon: 'currency-usd',
  tooltip: 'This is a tooltip',
  options: [{
    value: 1,
    label: 'Yes'
  }, {
    value: 0,
    label: 'No'
  }]
}

export const invoicedAt = {
  label: 'Invoice Submitted',
  key: 'invoiced_at',
  icon: 'calendar',
  tooltip: 'This is a tooltip',
  options: dates
}

export const dueAt = {
  label: 'Invoice Due',
  key: 'terms_date_due',
  icon: 'calendar',
  tooltip: 'This is a tooltip',
  options: dates
}

export const acknowledgedAt = {
  label: 'Acknowledged',
  key: 'acknowledged_at',
  icon: 'calendar',
  tooltip: 'This is a tooltip',
  options: dates
}

export const paidAt = {
  label: 'Marked as Paid Date',
  key: 'paid_at',
  icon: 'calendar',
  tooltip: 'This is a tooltip',
  options: dates
}
