<template>
  <form @submit.prevent="update" novalidate autocomplete="off">
    <div class="columns">
      <div class="column is-one-fifth">
        <p class="has-text-weight-bold is-size-6 mb-sm">Onboarding Notifications</p>
        <p class="has-text-grey is-size-7">
          Subscribe to push notifications to receive real-time alerts related to onboarding events.
        </p>
      </div>
      <div class="column">
        <!-- Existing notifications are loaded -->
        <div v-if="!isLoading">
          <!-- Merchant Notifications -->
          <div v-if="context.isMerchant()">
            <div v-for="notification in merchantNotifications" :key="notification.action" class="mb-md">
              <PushNotification
                :notifications="pushNotifications"
                :action="notification.action"
                :title="notification.title"
                :text="notification.text" />
            </div>
          </div>
        </div>
        <!-- Existing notifications are loaded -->
        <div v-else>
          <div v-for="index in 5" :key="index">
            <div class="has-background-white pa-md rounded-lg mb-sm">
              <div class="mb-md">
                <b-skeleton width="25%" :animated="true"></b-skeleton>
                <b-skeleton width="75%" :animated="true"></b-skeleton>
              </div>
              <b-skeleton width="50%" height="30" :animated="true"></b-skeleton>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import PushNotification from './PushNotification'

export default {
  components: {
    PushNotification
  },
  computed: {
    ...mapGetters(['context']),
    merchantNotifications () {
      const notifications = []
      this.availableNotifications.forEach(notification => {
        if (notification.context.includes('retailer')) {
          notifications.push(notification)
        }
      })
      return notifications
    }
  },
  data () {
    return {
      errors: {},
      updating: false,
      availableNotifications: [{
        action: 'Connection Invite Accepted',
        title: 'Supplier Invite Accepted',
        text: 'This notification is sent when a new supplier partner accepts an invitation to join your program.',
        context: ['retailer']
      }, {
        action: 'Brand Completed Onboarding',
        title: 'Supplier Completed Onboarding',
        text: 'This notification is sent when a new supplier partner completes onboarding.',
        context: ['retailer']
      }, {
        action: 'Connection Memo Saved',
        title: 'Connection Notifications',
        text: 'Subscribe to push notifications to receive real-time alerts related to your connections & connection notes.',
        context: ['retailer']
      }]
    }
  },
  methods: {

  },
  mounted () {

  },
  props: {
    pushNotifications: {
      type: Array,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: true
    }
  }
}
</script>
