<template>
  <div class="mt-md">
    <div class="pa-md rounded-lg border">
      <div class="columns is-vcentered">
        <div class="is-clickable" @click.prevent="toggle()">
          <b-icon :icon="showDetails? 'chevron-down' : 'chevron-right'" custom-size="mdi-18px"></b-icon>
          FTP Login Details
        </div>
        <div class="column is-narrow is-clickable" v-clipboard:copy="json" v-clipboard:success="copied">
          <b-icon size="is-small" icon="content-copy" />
        </div>
      </div>
      <p v-if="showSubHeading" class="is-size-8 has-text-grey ml-md">Save these login details for your records.</p>
      <div v-if="showDetails" class="mt-sm ml-md">
        <div class="mb-md">
          <p class="has-text-grey">EDI Qualifier / ID</p>
          <div class="columns">
            <div class="column">
              {{ ediAccount.qualifier }} / {{ ediAccount.identifier }}
            </div>
            <div class="column is-narrow is-clickable" v-clipboard:copy="`${ediAccount.qualifier}/${ediAccount.identifier}`" v-clipboard:success="copied">
              <b-icon size="is-small" icon="content-copy" />
            </div>
          </div>
        </div>
        <div class="mb-md">
          <p class="has-text-grey">SFTP Host</p>
          <div class="columns">
            <div class="column">
              ftp.revcascade.com (port 22)
            </div>
            <div class="column is-narrow is-clickable" v-clipboard:copy="'ftp.revcascade.com (port 22)'" v-clipboard:success="copied">
              <b-icon size="is-small" icon="content-copy" />
            </div>
          </div>
        </div>
        <div class="mb-md">
          <p class="has-text-grey">SFTP Username</p>
          <div class="columns">
            <div class="column">
              {{ ediAccount.ftp_account.username }}
            </div>
            <div class="column is-narrow is-clickable" v-clipboard:copy="ediAccount.ftp_account.username" v-clipboard:success="copied">
              <b-icon size="is-small" icon="content-copy" />
            </div>
          </div>
        </div>
        <div class="">
          <p class="has-text-grey">SFTP Password</p>
          <div class="columns">
            <div class="column">
              {{ (showPassword) ? ediAccount.ftp_account.password : obfuscatedPassword }}
            </div>
            <div class="column is-narrow is-clickable">
              <span @click.prevent="showPassword = !showPassword">
                <b-icon size="is-small" :icon="showPassword? 'eye-off-outline' : 'eye-outline'" class="mr-sm"/>
              </span>
              <span v-clipboard:copy="ediAccount.ftp_account.password" v-clipboard:success="copied">
                <b-icon size="is-small" icon="content-copy" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  computed: {
    ...mapGetters(['app', 'context']),
    obfuscatedPassword () {
      return new Array(this.ediAccount.ftp_account.password.length + 1).join('*')
    },
    json () {
      return JSON.stringify({
        qualifier: this.ediAccount.qualifier,
        identifier: this.ediAccount.identifier,
        host: 'ftp.revcascade.com (port 22)',
        username: this.ediAccount.ftp_account.username,
        password: this.ediAccount.ftp_account.password
      })
    }
  },
  data () {
    return {
      showPassword: false,
      showDetails: false
    }
  },
  methods: {
    copied () {
      this.$buefy.toast.open({ type: 'is-success', message: 'Copied!' })
    },
    toggle () {
      this.showDetails = !this.showDetails
    }
  },
  mounted () {
    this.showDetails = !this.collapse
  },
  props: {
    ediAccount: {
      type: Object,
      required: true
    },
    collapse: {
      type: Boolean,
      default: false
    },
    showSubHeading: {
      type: Boolean,
      default: false
    }
  }
}
</script>
