<template>
  <div>
    <div class="columns is-vcentered is-variable is-2">
      <!-- Order Is Open -->
      <template v-if="orderIsOpen">
        <!-- Order Is Freight -->
        <template v-if="orderIsFreight">
          <!-- Order Is Acknowledged -->
          <template v-if="orderIsAcknowledged">
            <div v-if="orderHasUnshippedItems" class="column is-one-quarter">
              <b-button type="is-light" size="is-medium" expanded  @click.prevent="$router.push({ name: 'order.fulfill', params: { id: $route.params.id, method: 'existing' }})">Add Freight Tracking</b-button>
            </div>
          </template>
          <!-- Order Is NOT Acknowledged -->
          <template v-else>
            <div class="column is-narrow">
              <b-button type="is-light" size="is-medium" expanded  @click.prevent="$emit('action:freight')">
                <span class="px-sm">Accept Freight Pickup</span>
              </b-button>
            </div>
          </template>
        </template>
        <!-- Order Is Small Parcel (Default) -->
        <template v-else>
          <template v-if="orderHasUnshippedItems">
            <div class="column is-one-quarter">
              <b-dropdown expanded>
                <button class="button is-primary is-outlined is-medium is-fullwidth" slot="trigger" expanded>
                  <span>Fulfill Items</span>
                  <b-icon icon="menu-down"></b-icon>
                </button>
                <b-dropdown-item has-link>
                  <router-link :to="{ name: 'order.fulfill', params: { id: this.$route.params.id, method: 'create' } }">Print Shipping Labels</router-link>
                </b-dropdown-item>
                <b-dropdown-item has-link>
                  <router-link :to="{ name: 'order.fulfill', params: { id: this.$route.params.id, method: 'existing' } }">Register Tracking #s</router-link>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </template>
        <!-- Order Can be Closed -->
        <template v-if="orderIsInvoiced">
          <div class="column is-one-quarter">
            <b-button type="is-light" size="is-medium" expanded  @click.prevent="$emit('action:close')">
              Mark Order Complete
            </b-button>
          </div>
        </template>
        <!-- Order Can be Invoiced -->
        <template v-if="orderRequiresInvoice">
          <div class="column is-one-quarter">
            <b-button type="is-light" size="is-medium" expanded  @click.prevent="$emit('action:invoice')">
              Invoice Order
            </b-button>
          </div>
        </template>
        <!-- Order has an 850 -->
        <template v-if="orderHasEdi">
          <div class="column is-one-quarter">
            <RetransmitPo :envelope="poEnvelope" />
          </div>
        </template>
        <!-- Order Can be Canceled -->
        <template v-if="orderHasUnshippedItems">
          <div class="column is-one-quarter">
            <b-button type="is-light" size="is-medium" expanded  @click.prevent="$emit('action:cancel')">
              Cancel Items
            </b-button>
          </div>
        </template>
        <!-- Order Has Packing Slip -->
        <template v-if="packingSlip">
          <div class="column is-one-quarter">
            <a :href="packingSlip.file" class="button is-light is-medium is-fullwidth" target="_blank">Print Packing Slip</a>
          </div>
        </template>
        <!-- Order Missing Packing Slip -->
        <template v-else>
          <div class="column is-one-quarter">
            <b-button
              type="is-light"
              size="is-medium"
              expanded
              @click.prevent="regeneratePackingSlip"
              :disabled="generatingPackingSlip"
              :loading="generatingPackingSlip">
              Add Packing Slip
            </b-button>
          </div>
        </template>
      </template>
      <!-- Order Is Canceled -->
      <template v-if="orderIsCanceled">
        <div class="column is-one-quarter">
          <b-button type="is-light" size="is-medium" expanded  @click.prevent :disabled="true">
            No Actions
          </b-button>
        </div>
      </template>
      <!-- Order Is Closed -->
      <template v-if="orderIsClosed">
        <div class="column is-one-quarter">
          <b-button type="is-light" size="is-medium" expanded  @click="$emit('action:return')">Start a Return</b-button>
        </div>
      </template>
      <template v-else>
        <div class="column is-one-quarter">
          <b-button type="is-light" size="is-medium" expanded  @click.prevent :disabled="true">
            No Actions
          </b-button>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import RetransmitPo from './RetransmitPo'

export default {
  components: {
    RetransmitPo
  },
  computed: {
    ...mapGetters(['context']),
    /** Returns whether or not an order has been touched at all **/
    orderIsFresh () {
      let fulfilled = 0
      this.order.order_lines.forEach(line => {
        fulfilled += line.quantity_shipped + line.quantity_canceled
      })
      return (fulfilled === 0)
    },
    orderIsOpen () {
      return (this.order.status === 'open' || this.order.status === 'partial')
    },
    orderIsFreight () {
      if (this.order.shipping_method !== null) {
        return this.order.shipping_method.generic_shipping_method.type === 'ltl'
      }
      if (this.order.requested_shipping_method !== null) {
        return this.order.requested_shipping_method.type === 'ltl'
      }
      return false
    },
    orderIsFulfilled () {
      let outstanding = 0
      this.order.order_lines.forEach(line => {
        outstanding += line.quantity_open
      })
      return (outstanding <= 0)
    },
    orderRequiresInvoice () {
      let requiresInvoice = false
      this.order.order_lines.forEach(line => {
        if (line.quantity_shipped > 0 && line.quantity_shipped > line.quantity_invoiced) {
          requiresInvoice = true
        }
      })
      return requiresInvoice
    },
    orderHasUnshippedItems () {
      let outstanding = 0
      this.order.order_lines.forEach(line => {
        outstanding += line.quantity_open
      })
      return (outstanding > 0)
    },
    orderIsInvoiced () {
      // assume the order is invoiced, and loop through the lines attepmting
      // to prove that is isn't fully invoiced.
      let fullyInvoiced = true
      this.order.order_lines.forEach(line => {
        // if any lines are "open" (i.e. not shipped or canceled), the
        // order cannot be considered invoiced.
        if (line.quantity_open > 0) {
          fullyInvoiced = false
        }
        // if any of the lines
        if (line.quantity_shipped > 0 && line.quantity_shipped !== line.quantity_invoiced) {
          fullyInvoiced = false
        }
      })
      return fullyInvoiced
    },
    orderIsAcknowledged () {
      return this.order.acknowledged_at !== null
    },
    orderHasEdi () {
      return this.order.envelopes.length > 0
    },
    orderIsCanceled () {
      return this.order.status === 'canceled'
    },
    orderIsClosed () {
      return this.order.status === 'closed' && this.options.vendor_can_initiate_return != null
    },
    poEnvelope () {
      const po = this.$utils.findLast(this.order.envelopes, envelope => {
        return envelope.functional_identifier === 'PO'
      })
      return (typeof po !== 'undefined') ? po : null
    },
    packingSlip () {
      const slip = this.$utils.find(this.order.attachments, ['filename', 'packing_slip.pdf'])
      return (typeof slip !== 'undefined') ? slip : null
    }
  },
  data () {
    return {
      generatingPackingSlip: false,
      options: {}
    }
  },
  methods: {
    regeneratePackingSlip () {
      this.generatingPackingSlip = true
      const endpoint = this.context.route + '/orders/' + this.order.id + '/regenerate-packing-slip/'
      this.$http.put(endpoint).then(() => {
        setTimeout(() => {
          this.generatingPackingSlip = false
          this.$buefy.toast.open({ type: 'is-success', message: 'Packing Slip Added!' })
          this.$emit('order:updated')
        }, 5000)
      })
    },
    getConnectionOptions () {
      const endpoint = this.context.route + '/connections/' + this.order.connection_id + '/'
      this.$http.get(endpoint).then((response) => {
        this.options = response.data.options
      })
    }
  },
  mounted () {
    this.getConnectionOptions()
  },
  props: ['order']
}
</script>
