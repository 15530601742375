<template>
  <form @submit.prevent="update" novalidate autocomplete="off">
    <div class="columns">
      <div class="column is-one-fifth">
        <p class="has-text-weight-bold is-size-6 mb-sm">Security Info</p>
        <p class="has-text-grey is-size-7">Review details from the last time this user signed in to {{ app.platform }}.</p>
      </div>
      <div class="column">
        <div class="has-background-white rounded-xl pa-lg">
          <!-- Loaded -->
          <div v-if="!loading && user !== null">
            <div class="columns">
              <div class="column is-narrow">
                <p class="has-text-weight-bold">Last Login At</p>
              </div>
              <div class="column">
                <p v-if="user.last_login_at !== null">
                  {{ $dates.utc(user.last_login_at).local().format('MMM D, YYYY @ h:m A') }}
                  <span class="has-text-grey is-size-7">({{ $dates.utc(user.last_login_at).local().fromNow() }})</span>
                </p>
                <p v-else class="has-text-grey"><i>Never signed in</i></p>
              </div>
            </div>
            <div class="columns">
              <div class="column is-narrow">
                <p class="has-text-weight-bold">Last Login IP</p>
              </div>
              <div class="column">
                <p v-if="dnsLookupUrl === null" class="has-text-grey"><i>Never signed in</i></p>
                <p v-else>
                  <a :href="dnsLookupUrl" class="has-text-info has-text-weight-bold" target="_blank">{{ user.last_login_ip }}</a>
                </p>
              </div>
            </div>
          </div>
          <!-- Loading -->
          <div v-else>
            <loading message="Loading user..." />
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['app']),
    dnsLookupUrl () {
      return (this.user.last_login_ip !== null)
        ? 'https://dns.google.com/query?name=' + this.user.last_login_ip
        : null
    }
  },
  props: {
    loading: {
      required: true,
      type: Boolean
    },
    user: {
      required: true,
      validator: prop => typeof prop === 'object' || prop === null
    }
  }
}
</script>
