<template>
  <div>
    <!-- Page Header -->
    <div class="mb-sm">
      <div class="columns is-vcentered">
        <div class="column is-one-third">
          <breadcrumbs :history="[{ label: 'Catalog', name: 'catalog'}]" current="Browse" :hideBack="true" />
        </div>
        <div class="column is-one-third">
          <h1 class="has-text-centered is-size-4">Browse</h1>
        </div>
      </div>
    </div>
    <div class="columns is-centered is-multiline">
      <!-- Browse Items -->
      <div class="column is-half is-one-third-desktop is-one-quarter-widescreen">
        <router-link :to="{ name: 'catalog.products' }">
          <div class="has-background-white rounded-lg" style="height:250px">
            <div class="has-text-centered has-text-grey-lighter pt-xxl">
              <b-icon icon="format-list-bulleted-square" custom-class="mdi-96px"></b-icon>
            </div>
            <div class="has-text-centered pa-md">
              <p class="has-text-weight-bold mb-sm">Browse Products</p>
              <p class="has-text-grey-light is-size-7">View your items grouped together by parent product.</p>
            </div>
          </div>
        </router-link>
      </div>
      <!-- Items -->
      <div class="column is-half is-one-third-desktop is-one-quarter-widescreen">
        <router-link :to="{ name: 'catalog.items' }">
          <div class="has-background-white rounded-lg" style="height:250px">
            <div class="has-text-centered has-text-grey-lighter pt-xxl">
              <b-icon icon="format-align-justify" custom-class="mdi-96px"></b-icon>
            </div>
            <div class="has-text-centered pa-md">
              <p class="has-text-weight-bold mb-sm">Browse Inventory</p>
              <p class="has-text-grey-light is-size-7">Review individual item attributes &amp; inventory positions.</p>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['app'])
  },
  props: {
    connections: {
      type: Array,
      required: true
    },
    platformAccounts: {
      type: Array,
      required: true
    }
  }
}
</script>
