<template>
  <div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title" v-if="loading">Loading messages...</p>
        <p class="modal-card-title" v-else-if="connection !== null">Messages</p>
      </header>
      <section class="modal-card-body">
        <div v-if="!loading && memos.length" class="px-lg">
          <div v-for="(memo, index) in memos" :key="memo.id" :class="(index === 0) ? '' : 'border-t'" class="py-md">
            <Memo :memo="memo"/>
          </div>
        </div>
        <div v-else-if="memos.length === 0">
          <div class="has-text-centered py-md has-text-grey">No messages at this time!</div>
        </div>
        <div v-else>
          <loading message="Loading..." />
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Memo from '../Memo/Memo'

export default {
  components: {
    Memo
  },
  data () {
    return {
      loading: false,
      memos: []
    }
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/connections/' + this.connection.id + '/'
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint).then(response => {
        this.memos = response.data.memos
        this.loading = false
      })
    }
  },
  mounted () {
    // Need to load after integrating with acknowledge
    // this.load()
    if (this.connection && this.connection.memos) {
      this.memos = this.connection.memos
    }
  },
  props: {
    connection: {
      default: null
    }
  }
}
</script>
