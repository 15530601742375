<template>
   <div class="columns">
      <div class="column">
        <!-- Info -->
        <div class="mb-lg">
          <div class="pb-sm has-border-b has-border-lg">
            <p class="has-text-primary has-text-weight-bold ">Payment Info</p>
          </div>
          <table class="table is-fullwidth is-striped is-narrow is-size-7">
            <tbody>
              <tr>
                <th>{{context.isMerchant() ? 'Connection' : 'Retailer' }} Name</th>
                <td class="has-text-right">
                  {{context.isMerchant() ? payment.brand.name : payment.retailer.name}}
                </td>
              </tr>
              <tr>
                <th>Number of Invoices</th>
                <td class="has-text-right">{{ payment.num_invoices }}</td>
              </tr>
              <tr>
                <th>Number of Credits</th>
                <td class="has-text-right">{{ payment.num_credits }}</td>
              </tr>
              <tr>
                <th>Number of Adjustments</th>
                <td class="has-text-right">{{ payment.adjustments.length }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- Info -->
        <div class="mb-lg">
          <div class="pb-sm has-border-b has-border-lg">
            <p class="has-text-primary has-text-weight-bold ">Payment Summary</p>
          </div>
          <table class="table is-fullwidth is-striped is-narrow is-size-7">
            <tbody>
              <tr>
                <th>Total Invoices Amount</th>
                <td class="has-text-right">
                  <span v-if="payment.total_invoices_amount">{{ $numbers(payment.total_invoices_amount).formatCurrency({ mantissa: 2 }) }}</span>
                  <span v-else>-</span>
                </td>
              </tr>
              <tr>
                <th>Total Credits Amount</th>
                <td class="has-text-right">
                  {{ $numbers(payment.total_credits_amount).formatCurrency({ mantissa: 2 }) }}
                </td>
              </tr>
              <tr>
                <th>Total Adjustments Amount</th>
                <td class="has-text-right">
                  <span v-if="payment.total_adjustments_amount">{{ $numbers(payment.total_adjustments_amount).formatCurrency({ mantissa: 2 }) }}</span>
                  <span v-else>{{ $numbers(0).formatCurrency({ mantissa: 2 }) }}</span>
                </td>
              </tr>
              <tr>
                <th>Total Payment Amount</th>
                <td class="has-text-right has-text-weight-bold">
                  <span>{{ $numbers(total).formatCurrency({ mantissa: 2 }) }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="column">
        <!-- Details -->
        <div class="mb-lg">
          <div class="pb-sm has-border-b has-border-lg">
            <div class="columns">
              <div class="column">
                <p class="has-text-primary has-text-weight-bold ">Payment Detail</p>
              </div>
              <div v-if="context.isMerchant() && isDisabled" class="column is-narrow">
                <a href="#" @click.prevent="$emit('edit:payment')" class="has-text-weight-bold has-text-info">Edit</a>
              </div>
            </div>
          </div>
          <table class="table is-fullwidth is-striped is-narrow is-size-7">
            <tbody>
              <tr>
                <th>Check Number</th>
                <td class="has-text-right">
                  <span v-if="payment.check_number">{{payment.check_number}} </span>
                  <span v-else> - </span>
                </td>
              </tr>
              <tr>
                <th>Check Amount</th>
                <td class="has-text-right">
                  {{$numbers(payment.check_amount).formatCurrency({ mantissa: 2 })}}
                </td>
              </tr>
              <tr>
                <th>Check Issue Date</th>
                <td class="has-text-right">
                  <span v-if="payment.check_issue_date">{{ $dates.utc(payment.check_issue_date).local().format('MMM D, YYYY') }} </span>
                  <span v-else> - </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  computed: {
    ...mapGetters(['app', 'auth', 'context']),
    isDisabled () {
      return this.payment.num_invoices > 0 && this.payment.status === 'draft'
    },
    total () {
      let total = 0
      total += (this.payment.total_invoices_amount) ? parseFloat(this.payment.total_invoices_amount) : 0
      total -= (this.payment.total_credits_amount) ? parseFloat(this.payment.total_credits_amount) : 0
      total += (this.payment.total_adjustments_amount) ? parseFloat(this.payment.total_adjustments_amount) : 0
      return total
    }
  },
  data: () => {
    return {}
  },
  methods: {

  },
  mounted () {

  },
  props: {
    payment: {
      type: Object,
      required: true
    }
  }
}
</script>
