<template>
  <div>
    <div class="columns">
      <div class="column is-one-fifth">
        <p class="has-text-weight-bold is-size-6 mb-sm">Order Lines</p>
        <p class="has-text-grey">
          Add variants, quantity and price information.
        </p>
      </div>
      <div class="column">
        <div class="has-background-white rounded-lg pa-md mb-xxs">
          <div class="columns is-vcentered">
            <div class="column is-half">
              <ConnectionSelect
                v-model="connection"
                :value="connection"
                placeholder="Select a supplier"
              />
            </div>
            <div class="column">
              <b-tooltip
                :active="!isConnectionReady"
                multilined
                label="Select supplier to add new lines."
                class="is-pulled-right"
                position="is-bottom"
              >
                <b-button
                  type="is-primary"
                  @click="showLinesModal = true"
                  :loading="loadingDetails"
                  :disabled="!isConnectionReady"
                  >Add new line</b-button
                >
              </b-tooltip>
            </div>
          </div>
        </div>
        <div class="has-background-white rounded-lg pa-md">
          <b-table
            :data="lineItems"
            :mobile-cards="false"
            hoverable
            class="is-size-7"
          >
            <template>
              <!-- Order line number -->
              <b-table-column
                label="Line No"
                cell-class="nowrap"
                v-slot="props"
                width="100"
              >
                <p>{{ props.row.orderLineNo }}</p>
              </b-table-column>
              <!-- Item -->
              <b-table-column label="Item" cell-class="nowrap" v-slot="props">
                <a href="#" @click.prevent="selected(props.row)">
                  {{ $utils.truncate(props.row.variant.name, { length: 80 }) }}
                  <br />
                  <small>
                    Id: <b>{{ props.row.variant.id }}</b>
                  </small>
                </a>
              </b-table-column>
              <!-- Quantity -->
              <b-table-column
                label="Quantity"
                cell-class="nowrap"
                v-slot="props"
                width="100"
              >
                <p>{{ props.row.quantity }}</p>
              </b-table-column>
              <!-- Price -->
              <b-table-column
                label="Price"
                cell-class="nowrap"
                v-slot="props"
                width="100"
              >
                <p>
                  {{
                    $numbers(props.row.price).formatCurrency({ mantissa: 2 })
                  }}
                </p>
              </b-table-column>
              <!-- Net Price -->
              <b-table-column
                label="Net Price"
                cell-class="nowrap"
                v-slot="props"
                width="100"
              >
                <p>
                  {{
                    props.row.netPrice
                      ? $numbers(props.row.netPrice).formatCurrency({
                          mantissa: 2,
                        })
                      : '-'
                  }}
                </p>
              </b-table-column>
            </template>
            <template #empty>
              <div class="py-lg has-text-grey has-text-centered">
                <p>No lines have been registered yet.</p>
              </div>
            </template>
          </b-table>
        </div>
      </div>
    </div>
    <!-- Order lines Modal -->
    <b-modal
      has-modal-card
      :active.sync="showLinesModal"
      :can-cancel="['escape', 'x']"
      @close="reset"
    >
      <LineItemModal
        :isEdit="isEdit"
        :netPriceIsRequired="netPriceIsRequired"
        :orderLineItem="selectedOption"
        :orderLineItems="lineItems"
        :connectionId="connectionId"
        @update:list="updateList"
      />
    </b-modal>
    <!-- Connection update alert modal -->
    <b-modal
      has-modal-card
      :active.sync="showConnectionAlert"
      :can-cancel="['escape', 'x']"
    >
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">
            Are you sure you want to change the supplier?
          </p>
        </header>
        <section class="modal-card-body">
          One purchase order can have variants from only one supplier.
          Changing the supplier will remove the existing items from the order, do you want to continue?
        </section>
        <footer class="modal-card-foot space-between">
          <b-button @click="confirmNo">No</b-button>
          <b-button @click="confirmYes">Yes</b-button>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ConnectionSelect from '@/components/Models/Connection/ConnectionSelect'
import LineItemModal from './LineItemModal.vue'
export default {
  components: {
    ConnectionSelect,
    LineItemModal
  },
  computed: {
    ...mapGetters(['context'])
  },
  data: () => {
    return {
      isConnectionReady: false,
      loadingDetails: false,
      connection: null,
      prevConnection: null,
      netPriceIsRequired: false,
      connectionId: null,
      lineItems: [],
      showLinesModal: false,
      isEdit: false,
      selectedOption: null,
      showConnectionAlert: false
    }
  },
  methods: {
    updateList (json) {
      this.lineItems = json
    },
    selected (row) {
      this.selectedOption = row
      this.showLinesModal = true
      this.isEdit = true
    },
    reset () {
      this.showLinesModal = false
      this.isEdit = false
      this.selectedOption = null
    },
    confirmNo () {
      // set connection to previous value
      this.connection = this.prevConnection
    },
    confirmYes () {
      // update the connection and reset line items
      this.showConnectionAlert = false
      this.lineItems = []
      this.loadConnectionDetail()
    },
    loadConnectionDetail () {
      this.loadingDetails = true
      const endpoint = this.context.route + `/connections/${this.connection.id}/`
      this.$http.get(endpoint).then((response) => {
        this.connectionId = response.data.brand.id
        if (response.data.connection_fees) {
          this.netPriceIsRequired =
            response.data.connection_fees.net_price_method ===
            'specified_in_order'
        } else {
          this.netPriceIsRequired = false
        }
        this.loadingDetails = false
        this.isConnectionReady = true
      })
    }
  },
  mounted () {},
  props: [],
  watch: {
    connection (newValue, prevValue) {
      if (
        prevValue !== null &&
        this.connectionId !== null &&
        this.lineItems.length
      ) {
        this.showConnectionAlert = !this.showConnectionAlert
        this.prevConnection = prevValue
      } else {
        this.loadConnectionDetail()
      }
    },
    lineItems: {
      deep: true,
      immediate: true,
      handler (value) {
        this.$emit('input', { orderLines: value })
      }
    }
  }
}
</script>
