<template>
  <form @submit.prevent="invite" novalidate autocomplete="off" class="pb-lg">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Invite User</p>
      </header>
      <section class="modal-card-body">
        <b-message v-if="errors.detail" type="is-danger">{{ errors.detail }}</b-message>
        <b-message type="is-info">
          Complete the form below to invite a new user to join your team on {{ app.platform }}.
          After you click 'Invite', the person you invited will receive an email with
          instructions to activate their user account.
        </b-message>
        <!-- First & Last Name -->
        <div class="columns">
          <div class="column">
            <b-field
              label="First Name"
              :type="('first_name' in errors) ? 'is-danger' : ''"
              :message="('first_name' in errors) ? errors['first_name'][0] : ''"
              expanded>
              <b-input
                autocomplete="off"
                type="text"
                name="firstName"
                v-model="firstName"
              ></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field
              label="Last Name"
              :type="('last_name' in errors) ? 'is-danger' : ''"
              :message="('last_name' in errors) ? errors['last_name'][0] : ''"
              expanded>
              <b-input
                autocomplete="off"
                type="text"
                name="lastName"
                v-model="lastName"
              ></b-input>
            </b-field>
          </div>
        </div>
        <!-- Email -->
        <div>
          <b-field
            label="Email Address"
            :type="('email' in errors) ? 'is-danger' : ''"
            :message="('email' in errors) ? errors['email'][0] : ''"
            expanded>
            <b-input
              autocomplete="off"
              type="email"
              native-type="email"
              name="email"
              v-model="email"
            ></b-input>
          </b-field>
        </div>
      </section>
      <footer class="modal-card-foot" style="justify-content:flex-end">
        <button class="button" type="button" @click="$parent.close()">Close</button>
        <b-button native-type="submit" type="is-primary" :disabled="saving || !ready">Invite</b-button>
      </footer>
    </div>
  </form>

</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  computed: {
    ...mapGetters(['app', 'context']),
    endpoint () {
      if (this.app.platformCode === 'fabric') {
        return this.context.route + '/users/invite/?source=fabric'
      } else if (this.app.platformCode === 'revcascade') {
        return this.context.route + '/users/invite/?source=platform'
      } else {
        return this.context.route + '/users/invite/'
      }
    },
    json () {
      return {
        first_name: this.firstName,
        last_name: this.lastName,
        email: this.email
      }
    },
    ready () {
      return (this.firstName && this.lastName && this.email)
    }
  },
  data () {
    return {
      firstName: null,
      lastName: null,
      email: null,
      title: null,
      type: 'standard',
      saving: false,
      errors: {}
    }
  },
  methods: {
    invite () {
      this.saving = true
      this.$http.post(this.endpoint, this.json).then(response => {
        this.saving = false
        this.$emit('invited:user', response.data)
        this.$buefy.toast.open({ type: 'is-success', message: 'User invited!' })
        this.$parent.close()
      }).catch(error => {
        this.saving = false
        if (error.response && error.response.status === 400) {
          this.errors = error.response.data
        }
      })
    }
  },
  mounted () {

  }
}
</script>
