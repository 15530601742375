export default {
  computed: {
    status () {
      const presenter = {
        label: 'Queued',
        tooltip: 'This import has been scheduled and will begin momentarily.',
        indicatorClass: 'has-text-black'
      }
      if (this.feed.status === 'processing') {
        presenter.label = 'Processing'
        presenter.tooltip = 'This import is processing now. Please check back in a few minutes.'
      } else if (this.feed.status === 'failed') {
        presenter.labled = 'Failed'
        presenter.tooltip = 'This import failed. You will likely need to make changes to your file. Please review your import file and try again.'
        presenter.indicatorClass = 'has-text-danger'
      } else if (this.feed.status === 'success') {
        presenter.label = 'Success'
        presenter.tooltip = 'This file was imported, but please consult the results to ensure every row processed successfully.'
        presenter.indicatorClass = 'has-text-success'
      }
      return presenter
    }
  }
}
