<template>
    <div>
      <div class="section">
        <div class="container">
          <!-- Page Header -->
          <div class="columns is-vcentered">
            <div class="column is-one-third">
              <breadcrumbs :history="[{ label: 'Integrations', name: 'settings.integrations'}]" current="Webhooks" :hideBack="true" />
            </div>
            <div class="column is-one-third">
              <h1 class="has-text-centered is-size-4">WooCommerce Webhook History</h1>
            </div>
          </div>
          <!-- Filters -->
          <div class="has-background-white rounded-lg pa-md mb-xxs">
            <div class="columns">
              <div class="column">
                <WebhookFilters @filter="filter" :applied-filters="filters" />
              </div>
              <div class="column is-narrow">
                <b-field >
                  <b-select v-model="orderBy" expanded @input="sort" size="is-medium">
                    <option value="-id">Most Recent</option>
                    <option value="id">Oldest</option>
                  </b-select>
                </b-field>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <!-- Toolbar -->
              <div class="has-background-white rounded-lg px-sm mb-xxs">
                <div class="columns is-vcentered is-mobile is-marginless is-paddingless">
                  <!-- Dropdown -->
                  <div class="column is-narrow">
                    <b-dropdown :disabled="loading || selected.length === 0">
                      <button class="button" slot="trigger" slot-scope="{ active }">
                        <span>Actions</span>
                        <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
                      </button>
                      <b-dropdown-item has-link><a href="#" @click.prevent>No Actions</a></b-dropdown-item>
                    </b-dropdown>
                  </div>
                  <div class="column">
                    <div class="has-text-right is-size-7">
                      <!-- Counts -->
                      <p v-if="loading">Loading...</p>
                      <p v-else>{{ total }} {{ (total === 1) ? 'record' : 'records' }} found.</p>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Table Content -->
              <div>
                <div>
                  <b-table
                    :data="results"
                    :checked-rows.sync="selected"
                    :mobile-cards="false"
                    :loading="loading"
                    checkable
                    hoverable
                    class="is-size-7">
                    <template>
                      <!-- RC ID -->
                      <b-table-column
                        label="ID"
                        cell-class="nowrap"
                        width="96"
                        v-slot="props">
                        <a href="#"
                          class="has-text-weight-bold has-text-info"
                          @click.prevent="inspectWebhook(props.row)"
                        >{{ props.row.id }}</a>
                      </b-table-column>
                      <!-- Topic -->
                      <b-table-column
                        label="Topic"
                        cell-class="nowrap"
                        width="164"
                        v-slot="props">
                        <p>{{ props.row.topic }}</p>
                      </b-table-column>
                      <!-- Topic -->
                      <b-table-column
                        label="Received"
                        cell-class="nowrap"
                        v-slot="props">
                        <p>{{ $dates.utc(props.row.created_at).local().format('MMM D, YYYY @ h:mm A') }}</p>
                      </b-table-column>
                      <!-- Has Errors -->
                      <b-table-column
                        label="Has Message?"
                        header-class="nowrap"
                        cell-class="nowrap"
                        centered
                        width="64"
                        v-slot="props">
                        <p v-if="props.row.errors"><b-icon icon="check" /></p>
                        <p v-else></p>
                      </b-table-column>
                      <!-- Status -->
                      <b-table-column
                        label="Status"
                        cell-class="nowrap has-text-weight-bold"
                        centered
                        width="128"
                        v-slot="props">
                        <p v-if="props.row.status === 'success'" class="has-text-success">Success</p>
                        <p v-else-if="props.row.status === 'failure'" class="has-text-danger">Failed</p>
                        <p v-if="props.row.status === 'skip'" class="has-text-warning">Skipped</p>
                      </b-table-column>
                    </template>
                    <template #empty>
                      <div class="py-xxl has-text-grey has-text-centered">
                        {{ (loading) ? "Loading data..." : 'No records found!' }}
                      </div>
                    </template>
                  </b-table>
                </div>
                <div class="mt-md">
                  <b-pagination
                    :total="total"
                    :current.sync="currentPage"
                    :rounded="true"
                    :per-page="perPage"
                    @change="paginate">
                  </b-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Inspection Modal -->
      <b-modal
        :active="detail !== null"
        has-modal-card
        :can-cancel="['escape', 'x']"
        @close="detail = null" >
        <WebhookModal :webhook="detail" />
      </b-modal>
    </div>
  </template>

<script>
import { mapGetters } from 'vuex'
import Filterable from '@/mixins/Filterable'
import WebhookFilters from '../components/WoocommerceWebhooks/WebhookFilters'
import WebhookModal from '../components/WoocommerceWebhooks/WebhookModal'

export default {
  components: {
    WebhookFilters,
    WebhookModal
  },
  computed: {
    ...mapGetters(['context']),
    placeholders () {
      return Math.min(15, this.perPage)
    },
    endpoint () {
      return this.context.route + '/woocommerce/' + this.$route.params.id + '/webhook-history/'
    }
  },
  data () {
    return {
      key: 'woocommerce.webhooks',
      selected: [],
      detail: null
    }
  },
  methods: {
    inspectWebhook (webhook) {
      this.detail = webhook
    }
  },
  mixins: [Filterable],
  mounted () {
    this.load()
  },
  props: []
}
</script>
