<template>
  <div>
    <table class="table is-fullwidth is-striped is-narrow is-size-8 border">
      <tbody>
        <tr>
          <th>Feed ID</th>
          <td class="has-text-right">{{ feed.id }}</td>
          <th>Feed Type</th>
          <td class="has-text-right">{{ $utils.upperFirst(feed.direction) }} {{ $utils.upperFirst(feed.data_type) }}</td>
        </tr>
        <tr>
          <th>Filename</th>
          <td class="has-text-right">{{ $utils.truncate(feed.original_filename, { length: 32 }) }}</td>
          <th>Template</th>
          <td class="has-text-right">{{ feed.template.name }}</td>
        </tr>
        <tr>
          <th>Original File Link</th>
          <td class="has-text-right">
            <DownloadFileAs label="Download" :cloudFileName="feed.cloud_filename" :originalFileName="feed.original_filename"/>
          </td>
          <th>Status</th>
          <td class="has-text-weight-bold has-text-right">
            <span v-if="getJobMetadataFromKey(feed.jobs, 'status')" :class="statusPresenter.class">{{statusPresenter.label}}</span>
            <span v-else>Queued</span>
          </td>
        </tr>
        <tr>
          <th>Started At</th>
          <td class="has-text-right">
            <span v-if="feed.processed_at">{{ $dates.utc(feed.processed_at).local().format('MMM D, YYYY @ h:mm A') }}</span>
            <span v-else class="has-text-grey is-italic">Not Started</span>
          </td>
          <th>Completed At</th>
          <td class="has-text-right">
            <span v-if="feed.completed_at">{{ $dates.utc(feed.completed_at).local().format('MMM D, YYYY @ h:mm A') }}</span>
            <span v-else class="has-text-grey is-italic">Not Completed</span>
          </td>
        </tr>
        <tr>
          <th>Wait time</th>
          <td class="has-text-right"><Runtime :start="feed.created_at" :end="feed.processed_at" /></td>
          <th>Processing Time</th>
          <td class="has-text-right"><Runtime :start="feed.processed_at" :end="feed.completed_at" /></td>
        </tr>
        <tr>
          <th># of Rows</th>
          <td class="has-text-right">{{ feed.num_items }}</td>
          <th># of Items Created</th>
          <td class="has-text-right">{{ feed.num_created }}</td>
        </tr>
        <tr>
          <th># of Items Created with Error</th>
          <td class="has-text-right">{{ itemsWithErrors }}</td>
          <th># of Items Failed to Create</th>
          <td class="has-text-right">{{ failedToCreate }}</td>
        </tr>
        <tr>
          <th># of Items Updated</th>
          <td class="has-text-right">{{ feed.num_updated }}</td>
          <th># of Warnings</th>
          <td class="has-text-right">{{ feed.num_warnings}}</td>
        </tr>
        <tr>
          <th>Items with Errors</th>
          <td v-if="getJobMetadataFromKey(feed.jobs, 'has_error_files')" class="has-text-right">
            <DownloadFileAs label="Download"
              :cloudFileName="getJobMetadataFromKey(feed.jobs, 'product_failed_rows', true)"
              :originalFileName="`${feed.id}-error-items`" />
          </td>
          <td v-else class="has-text-right">-</td><th>Error Details</th>
          <td v-if="getJobMetadataFromKey(feed.jobs, 'has_error_files')" class="has-text-right">
            <DownloadFileAs label="Download"
              :cloudFileName="getJobMetadataFromKey(feed.jobs, 'product_failed_rows_errors', true)"
              :originalFileName="`${feed.id}-error-details`" />
          </td>
          <td v-else class="has-text-right">-</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Runtime from './Runtime'

export default {
  components: {
    Runtime
  },
  computed: {
    failedToCreate () {
      let value = '-'
      if (this.feed.processed_at !== null) {
        const jobs = this.$utils.find(this.feed.jobs[0].metadata, ['name', 'failed_to_create'])
        value = jobs && jobs.value
      }
      return value
    },
    itemsWithErrors () {
      let value = '-'
      if (this.feed.processed_at !== null) {
        const jobs = this.$utils.find(this.feed.jobs[0].metadata, ['name', 'create_with_error'])
        value = jobs && jobs.value
      }
      return value
    },
    statusPresenter () {
      const status = this.feed.processed_at !== null && this.$utils.find(this.feed.jobs[0].metadata, ['name', 'status'])
      switch (status.value) {
        case 'successful':
          return { class: 'has-text-success', label: 'Success' }
        default:
          return { class: 'has-text-warning', label: status.value }
      }
    }
  },
  props: {
    feed: {
      type: Object,
      required: true
    }
  },
  methods: {
    getJobMetadataFromKey (row, key, onlyValue) {
      let job = null
      if (row.length && row[0].metadata.length) {
        job = row[0].metadata.find(el => el.name === key)
      }
      if (job && job.value && onlyValue) {
        return job.value
      } else {
        return job !== null && job !== undefined
      }
    }
  }
}
</script>
