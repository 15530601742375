<template>
  <div>
    <!-- Costs -->
    <div class="mb-lg">
      <p class="is-size-3 has-text-black">$0 / month</p>
      <p class="mb-lg">0% Transaction Fee</p>
    </div>
    <!-- Plan -->
    <div class="mb-lg">
      <p class="is-size-4 mb-sm has-text-black">One plan, no surprises!</p>
      <p class="px-xxl">
        To celebrate the lauch of the {{ app.platform }} Commerce Network, we're
        offering a completely free plan for our launch suppliers!
      </p>
    </div>
    <!-- Features -->
    <div class="mb-xxl">
      <p class="has-text-weight-bold mb-xs">Features</p>
      <div class="">
        <div class="columns is-mobile is-vcentered">
          <div class="column">
            <table class="table is-narrow is-vcentered is-fullwidth is-size-6 is-striped rounded-lg">
              <tr v-for="(feature, index) in plan.features" :key="index">
                <td>{{ feature }}</td>
                <td><b-icon icon="check" custom-class="has-text-success has-text-weight-bold" /></td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      plan: {
        code: 'vendor-unlimited',
        name: 'Supplier Launch Partner Plan',
        isBeta: true,
        description: 'To celebrate the lauch of the Commerce Network, we are offering a completely free plan for suppliers!',
        features: [
          'Unlimited products, partners, & orders.',
          '0% transaction fee; no monthly or recurring subscription fees.',
          'MAP protection (we reject any orders sold for less than your MAPs).',
          'Merchant reimburses preset shipping expenses.',
          '30-Day return-to-vendor policy.',
          'Net 15 payment terms'
        ]
      }
    }
  },
  computed: {
    ...mapGetters(['app', 'context'])
  }
}
</script>
