<template>
  <div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Return Reason</p>
      </header>
      <section class="modal-card-body">
        <b-field>
          <b-input
            type="textarea"
            v-model="reason"
            custom-class="is-tall-textarea"
            :readonly="true"
          ></b-input>
        </b-field>
      </section>
      <footer class="modal-card-foot is-justify-content-right">
        <b-button @click="$parent.close()">Close</b-button>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['auth', 'context'])
  },
  data () {
    return {
      saving: false,
      reason: this.proposal.return_reason_detail
    }
  },
  methods: {},
  props: {
    proposal: {
      type: Object,
      required: true
    }
  }
}
</script>
