<template>
  <div class="section">
      <div class="container">
        <!-- Page Header -->
        <div class="columns is-vcentered">
          <div class="column is-one-third">
            <breadcrumbs :history="breadcrumbs.history" :current="breadcrumbs.current" />
          </div>
          <div class="column is-one-third">
            <h1 class="has-text-centered is-size-4">Payment Detail</h1>
          </div>
        </div>
        <!-- Connection Detail -->
        <div>
          <div class="has-background-white rounded-lg pa-lg mb-lg">
            <div class="columns is-vcentered is-mobile">
              <div class="column">
                <div class="is-size-5 has-text-weight-bold">
                  <p v-if="!loading">{{ context.isMerchant() ? payment.brand.name : payment.retailer.name }}</p>
                  <p v-else>Loading...</p>
                </div>
                <p class="has-text-grey-light is-size-7">Payment ID: {{ (loading) ? 'Loading...' : payment.id }}</p>
              </div>
              <div class="column is-narrow has-text-right">
                <p class="is-size-5 has-text-weight-bold">
                  <span v-if="loading" class="has-text-grey-light">Loading...</span>
                  <span v-else :class="isPaid ? 'has-text-success' : 'has-text-warning'">{{ $utils.upperFirst(payment.status) }}</span>
                </p>
              </div>
            </div>
          </div>
          <!-- child Routes -->
          <div v-if="!loading && payment !== null">
            <div class="columns">
              <div class="column is-narrow">
                <b-menu>
                  <b-menu-list label="Payment Menu">
                    <b-menu-item
                      label="Summary"
                      tag="router-link"
                      :to="{ name: 'payment.detail', params: { id: payment.id } }"
                      exact
                      @reload="load"
                    ></b-menu-item>
                    <b-menu-item
                      label="Invoices"
                      tag="router-link"
                      :to="{ name: 'payment.detail.invoices', params: { id: payment.id } }"
                      exact
                    ></b-menu-item>
                    <b-menu-item
                      label="Credits"
                      tag="router-link"
                      :to="{ name: 'payment.detail.credits', params: { id: payment.id } }"
                      exact
                    ></b-menu-item>
                    <b-menu-item
                      label="Adjustments"
                      tag="router-link"
                      :to="{ name: 'payment.detail.adjustments', params: { id: payment.id } }"
                      :class="context.isMerchant() && !isPaid && 'border-b pb-md mb-md'"
                      exact
                    ></b-menu-item>
                    <b-menu-item
                      v-if="context.isMerchant() && !isPaid"
                      label="Add Invoices"
                      tag="router-link"
                      :to="{ name: 'payment.detail.invoices.list', params: { id: payment.id } }"
                      exact
                    ></b-menu-item>
                    <b-menu-item
                      v-if="context.isMerchant() && !isPaid"
                      label="Add Credits"
                      tag="router-link"
                      :to="{ name: 'payment.detail.credits.list', params: { id: payment.id } }"
                      exact
                    ></b-menu-item>
                  </b-menu-list>
                </b-menu>
              </div>
               <div class="column">
                <router-view :payment="payment" :loading="loading" @updated="load(true)" />
              </div>
            </div>
          </div>
          <div v-else>
            <loading />
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {
  },
  computed: {
    ...mapGetters(['app', 'auth', 'context']),
    breadcrumbs () {
      return {
        current: (this.loading) ? 'Loading...' : 'Payment #' + this.$route.params.id,
        history: [
          { label: 'Payments', name: 'payments' },
          { label: 'List', name: 'payments.list' }
        ]
      }
    },
    endpoint () {
      return this.context.route + '/payments/' + this.$route.params.id + '/'
    }
  },
  data: () => {
    return {
      loading: true,
      payment: null,
      isPaid: false
    }
  },
  methods: {
    load () {
      this.$http.get(this.endpoint).then(response => {
        this.loading = false
        this.payment = response.data
        this.isPaid = response.data.status === 'paid'
      })
    }
  },
  mounted () {
    this.load()
  },
  props: []
}
</script>
