<template>
  <div>
    <!-- Toolbar -->
    <div v-if="context.isMerchant()" class="has-background-white rounded-lg py-md px-lg mb-xxs">
      <div class="columns is-vcentered">
        <div class="column">
          <div class="px-sm has-text-grey-light">
            <em class="is-size-7">Click 'Edit' to change the approved carrier accounts &amp; methods for this connection.</em>
          </div>
        </div>
        <div class="column is-narrow">
          <div class="px-sm">
            <b-button
              type="is-primary"
              size="is-medium"
              outlined
              expanded
              @click.prevent="showUpdateShippingMethodsModal = true"
            >Edit Carrier Accounts</b-button>
          </div>
        </div>
      </div>
    </div>
    <!-- Carrier Account List -->
    <div class="has-background-white pa-md rounded-lg">
      <b-table
        :data="connection.shipping_accounts"
        :mobile-cards="false"
        :loading="loading"
        hoverable
        class="rounded-lg is-size-7">
        <template>
          <b-table-column
            label="Carrier"
            cell-class="nowrap"
            width="128"
            v-slot="props">
            {{ props.row.carrier.name }}
          </b-table-column>
          <b-table-column
            label="Account Name"
            cell-class="nowrap"
            width="200"
            v-slot="props">
            {{ props.row.nickname }}
          </b-table-column>
          <b-table-column
            label="Owner"
            cell-class="nowrap"
            v-slot="props">
            {{ props.row.owner.name }}
          </b-table-column>
          <b-table-column
            label="Service Levels"
            cell-class="nowrap"
            v-slot="props">
            <p v-for="(method) in props.row.shipping_methods" :key="method.id">{{ method.name }}</p>
          </b-table-column>
        </template>
        <template #empty>
          <div class="py-xxl has-text-grey has-text-centered">
            {{ (loading) ? "Loading data..." : "No carrier accounts found!" }}
          </div>
        </template>
      </b-table>
    </div>
    <!-- Update Shipping Methods -->
    <b-modal
      v-if="showUpdateShippingMethodsModal"
      has-modal-card
      :active.sync="showUpdateShippingMethodsModal"
      :can-cancel="['escape', 'x']"
    ><UpdateShippingMethodsModal :connection="connection" @saved:methods="$emit('updated')"></UpdateShippingMethodsModal>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import UpdateShippingMethodsModal from '@/components/Models/Connection/Modals/UpdateShippingMethodsModal'

export default {
  components: {
    UpdateShippingMethodsModal
  },
  computed: {
    ...mapGetters(['context']),
    shippingMethods () {
      const shippingMethods = []
      for (const shippingAccount of this.connection.shipping_accounts) {
        for (const shippingMethod of shippingAccount.shipping_methods) {
          const sm = shippingMethod
          sm.shipping_account = {
            id: shippingAccount.id,
            nickname: shippingAccount.nickname,
            owner: shippingAccount.owner,
            carrier: shippingAccount.carrier
          }
          shippingMethods.push(sm)
        }
      }
      return shippingMethods
    }
  },
  data () {
    return {
      showUpdateShippingMethodsModal: false
    }
  },
  props: ['connection', 'loading']
}
</script>
