<template>
<div>
  <div class="section">
    <div class="container">
      <div class="columns">
        <!-- Left -->
        <div class="column is-one-third">
          <div class="box rounded-lg">

            <div v-for="message in messages" :key="message.id">
              <div class="has-background-light rounded-lg pa-md">
                <a href="#" @click.prevent="inspectMessage(message)">
                  <Memo :memo="message" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <!-- Right -->
        <div class="column">
          <div class="has-background-white pa-md rounded-lg">
            <!-- Message Selected -->
            <div v-if="!loading && message !== null">
              <!-- Order Detail -->
              <div>
                <table class="table is-fullwidth is-striped is-size-7 is-narrow">
                  <tbody>
                    <tr>
                      <th>Customer Order #</th>
                      <td class="has-text-right">{{ message.order.purchase_order_number }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div v-else>
              <div class="has-text-centered pa-lg">
                Select a message to view the order and additional order details.
              </div>
            </div>
          </div>
          <!-- Message Selected -->
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Memo from '@/components/Models/Memo/Memo'

export default {
  components: {
    Memo
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/orders/memos/'
    }
  },
  data () {
    return {
      loading: true,
      messages: [],
      message: null
    }
  },
  methods: {
    load () {
      this.$http.get(this.endpoint).then(response => {
        this.messages = response.data.results
        this.loading = false
      })
    },
    inspectMessage (message) {
      this.message = message
    }
  },
  mounted () {
    this.load()
  }
}
</script>
