<template>
  <div>
    <div class="section">
      <div class="container">
        <!-- Page Header -->
        <div class="mb-md">
          <div class="columns is-vcentered">
            <div class="column is-one-third">
              <breadcrumbs :history="breadcrumbs.history" :current="breadcrumbs.current" />
            </div>
            <div class="column is-one-third">
              <h1 class="has-text-centered is-size-4">Submit Assortment</h1>
            </div>
          </div>
        </div>
        <!-- Content -->
        <div class="has-background-white rounded-lg px-xl py-lg">
          <!-- Non-Field / General Errors -->
          <div v-if="'non_field_errors' in errors" class="mb-lg">
            <b-message type="is-danger">{{ errors.non_field_errors[0] }}</b-message>
          </div>
          <!-- Intro -->
          <div class="mb-lg">
            <p class="is-size-4 has-text-weight-bold">Submit New Assortment</p>
            <p>
              Please complete the form below so that we can determine which
              tasks you must complete in order to submit new assortment.
            </p>
          </div>
          <!-- Form -->
          <form @submit.prevent="save">
            <!-- Form Inputs -->
            <div class="mb-lg">
              <div class="columns">
                <div class="column is-half">
                  <!-- Connection -->
                  <div class="mb-lg">
                    <FieldLabel label="Select Merchant Partner" class="mb-sm" />
                    <b-field
                      :type="('name1' in errors) ? 'is-danger' : ''"
                      :message="('name1' in errors) ? errors['name1'][0] : ''">
                      <ConnectionSelect v-model="connection" size="is-medium" />
                    </b-field>
                  </div>
                  <!-- Proposal Type -->
                  <div class="mb-lg">
                    <FieldLabel label="Proposal Purpose" class="mb-md" />
                    <b-field>
                      <b-radio v-model="type" native-value="new_product">I want to submit new products for approval. <i>(New products)</i></b-radio>
                    </b-field>
                    <b-field>
                      <b-radio v-model="type" native-value="cost_update">I want to propose changes to my item costs. <i>(Cost Update)</i></b-radio>
                    </b-field>
                  </div>
                  <!-- Department -->
                  <div class="mb-lg">
                    <FieldLabel label="Category" class="mb-md" />
                    <b-field
                      :type="('department' in errors) ? 'is-danger' : ''"
                      :message="('department' in errors) ? errors['department'][0] : ''">
                      <DepartmentSelect v-model="department" :connectionId="(connection) ? connection.id : null" size="is-medium" />
                    </b-field>
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-lg">
              <div class="columns">
                <div class="column is-one-quarter">
                  <b-button
                    native-type="submit"
                    type="is-primary"
                    size="is-medium"
                    :disabled="!ready || saving"
                    :loading="saving"
                    expanded
                  >Save &amp; Continue</b-button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ConnectionSelect from '@/components/Models/Connection/ConnectionSelect'
import DepartmentSelect from '@/components/Models/ProposalDepartment/DepartmentSelect'

export default {
  components: {
    ConnectionSelect,
    DepartmentSelect
  },
  computed: {
    ...mapGetters(['api', 'context']),
    breadcrumbs () {
      return [{ label: '', name: '', params: { code: this.context.code } }]
    },
    endpoint () {
      return this.context.route + '/proposals/'
    },
    ready () {
      return this.connection && this.department
    },
    body () {
      if (!this.ready) return {}
      return {
        connection: {
          id: this.connection.id
        },
        name: this.department.name + ' - ' + this.context.partner.name + ' - ' + this.$dates.utc().local().format('MMM D, YYYY @ h:mm'),
        source: 'merchandising',
        proposal_type: this.type,
        department: {
          id: this.department.id
        }
      }
    },
    proposalsIsEnabled () {
      return (
        this.connection && 'can_merchandise_via_onboarding' in this.connection.options
      )
    }
  },
  data () {
    return {
      saving: false,
      errors: {},
      connection: null,
      department: null,
      type: null
    }
  },
  methods: {
    save () {
      this.saving = true
      this.$http.post(this.endpoint, this.body).then(response => {
        this.$router.push({ name: 'proposal.tasks.submit', params: { id: response.data.id } })
        this.$buefy.toast.open({ type: 'is-success', message: 'Proposal started!' })
      }).catch(error => {
        if (error.response && error.response.status === 400) {
          this.errors = error.response.data
        }
      }).finally(() => {
        this.saving = false
      })
    }
  }
}
</script>
