<template>
  <form @submit.prevent="save">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">
          <span v-if="mode === 'set'">Mark Order Line as Backordered</span>
          <span v-else>Mark as Ready to Ship</span>
        </p>
      </header>
      <section class="modal-card-body">
        <!-- Messaging -->
        <div class="mb-md">
          <b-message v-if="mode === 'set'" type="is-warning">
            <strong>Please Note</strong>: when you mark this item as backordered,
            your action will be processed immediately and communications may be sent
            to the merchant to inform them of the backorder.
          </b-message>
          <b-message v-else type="is-info">
            <strong>Please Note</strong>: when you remove the backorder date,
            your action will be processed immediately and communications may be sent
            to the retailer to inform them the order is ready to ship.
          </b-message>
        </div>
        <!-- Item Details -->
        <div class="mb-md">
          <p class="has-text-weight-bold mb-xs">Item</p>
          <div class="has-background-light rounded-lg pa-md">
            <div class="columns">
              <div class="column">
                <p>{{ line.variant.name }}</p>
                <p v-if="context.isMerchant() && line.variant.retailer_identifiers.length > 0">
                  <span class="is-size-8">Merchant SKU </span>
                  <span class="has-text-black">{{ line.variant.retailer_identifiers[0].identifier }}</span>
                </p>
                <p v-else>
                  <span class="is-size-8">Supplier SKU </span>
                  <span class="has-text-black">{{ line.variant.brand_identifier }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- Estimated Ship Date (only when mode = 'set') -->
        <div v-if="mode === 'set'" class="mb-md">
          <p class="has-text-weight-bold mb-xs">Ship Date</p>
          <p class="has-text-grey is-size-7 mb-md">
            Since you are marking this order line as backordered due to no stock being available,
            please enter a date when you expect the items to ship.
          </p>
          <b-field expanded>
            <b-input type="date" v-model="estimatedShipDate" :min="minDate"></b-input>
          </b-field>
        </div>
      </section>
      <footer class="modal-card-foot space-between">
        <button class="button" type="button" @click="$parent.close()">Close</button>
        <b-button
          type="is-primary"
          native-type="submit"
          :loading="saving"
          :disabled="!ready || saving"
        >Update Order Line</b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      saving: false,
      estimatedShipDate: null
    }
  },
  computed: {
    ...mapGetters(['context']),
    minDate () {
      return this.$dates().format('YYYY-MM-DD')
    },
    endpoint () {
      return this.context.route + '/orders/' + this.orderId + '/'
    },
    mode () {
      return (this.line.backordered_until) ? 'reset' : 'set'
    },
    json () {
      const json = {}
      if (this.mode === 'set') {
        json.order_lines = [{
          id: this.line.id,
          backordered_until: this.$dates(this.estimatedShipDate).utc().format()
        }]
      } else {
        json.order_lines = [{
          id: this.line.id,
          backordered_until: null
        }]
      }
      return json
    },
    ready () {
      if (this.mode === 'set') {
        return (this.estimatedShipDate !== null)
      }
      return true
    }
  },
  methods: {
    save () {
      this.saving = true
      this.$http.patch(this.endpoint, this.json).then(response => {
        this.$parent.close()
        this.$emit('line:backordered')
        this.$buefy.toast.open({ type: 'is-success', message: 'Order Updated' })
      }).catch(error => {
        let errorMessage = 'Your attempt to backorder this line item failed. Please try again.'
        if (error.response && error.response.data.detail) {
          errorMessage = error.response.data.detail
        }
        this.$buefy.toast.open({ type: 'is-danger', message: errorMessage })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {

  },
  props: ['orderId', 'line']
}
</script>
