<template>
<div>
  <div v-if="isSignedIn">
    <!-- Don't let staff users perform this action -->
    <div v-if="auth.isStaff">
      <b-message type="is-warning">
        Staff Users are not permitted to accept connection
        invites on behalf of suppliers.
      </b-message>
    </div>
    <!-- Non-Staff Users (with at least 1 supplier account) -->
    <div v-else-if="auth.suppliers.length > 0" class="pt-lg">
      <div v-for="supplier in auth.suppliers" :key="supplier.id">
        <div class="has-background-light rounded-lg pa-md mb-md">
          <div class="columns is-vcentered">
            <div class="column">
              <p class="has-text-weight-bold">{{ supplier.name }}</p>
              <p class="has-text-grey is-size-7">ID: {{ supplier.id }}</p>
            </div>
            <div class="column is-narrow">
              <b-button
                type="is-primary rounded-lg"
                @click="linkAccount(supplier)"
                :disabled="saving"
                :loading="saving"
              >Accept Invite</b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Non-Staff Users (with 0 supplier accounts) -->
    <div v-else>
      <div class="has-text-centered has-background-light rounded-lg pa-md">
        <p class="is-size-5 has-text-weight-bold mb-sm">Hi {{ auth.user.firstName }}!</p>
        <p class="mb-md">
          Thanks for signing in, but you do not have a supplier
          account. It's likely you are instead linked to a merchant account.
        </p>
        <p>
          Please file a
          <a href="#" @click.prevent="$store.dispatch('supportRequest')" class="has-text-info">support request</a>
          to make sure your access is configured correctly.
        </p>
      </div>
    </div>
    <!-- Sign Out Link -->
    <div class="pt-lg has-text-centered">
      <a href="#" @click.prevent="$store.dispatch('logout', false)">Sign Out</a>
    </div>
  </div>
  <div v-else>
    <p class="is-size-7 has-text-grey mb-md">
      If you already have a {{ app.platform }} supplier account, sign
      in below to link this invite to your existing account!
    </p>
    <form @submit.prevent="signIn">
      <div class="mb-md">
        <p class="mb-sm has-text-weight-bold">Email</p>
        <b-input v-model="username" type="email" size="is-medium" />
      </div>
      <div class="mb-xl">
        <p class="mb-sm has-text-weight-bold">Password</p>
        <b-input v-model="password" type="password" size="is-medium" password-reveal />
      </div>
      <div class="mb-xl">
        <b-button
          native-type="submit"
          type="is-primary"
          size="is-medium"
          :loading="processing"
          :disabled="processing"
          expanded>
          Sign In
        </b-button>
      </div>
    </form>
  </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['app', 'api', 'auth', 'context']),
    isSignedIn () {
      return this.auth && this.auth.user !== null
    },
    ready () {
      return this.username && this.password
    },
    credentials () {
      const form = new FormData()
      form.set('username', this.username)
      form.set('password', this.password)
      form.set('grant_type', 'password')
      form.set('client_id', this.api.clientId)
      return form
    }
  },
  data () {
    return {
      processing: false,
      saving: false,
      username: null,
      password: null
    }
  },
  methods: {
    signIn () {
      this.processing = true
      const endpoint = this.api.baseUrl + '/auth/token/'
      this.$http.post(endpoint, this.credentials).then(response => {
        this.$store.dispatch('authenticate', response.data).then(() => {})
      }).catch(() => {
        this.$buefy.toast.open({ duration: 3000, type: 'is-danger', message: 'Invalid username or password.' })
      }).finally(() => {
        this.processing = false
      })
    },
    linkAccount (supplier) {
      this.saving = true
      const endpoint = this.api.baseUrl + '/v1/brands/' + supplier.id + '/connection-invites/accept/'
      const body = {
        code: this.code
      }
      this.$http.post(endpoint, body).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Account Created!' })
        this.$router.push({ name: 'onboarding' })
      }).catch(() => {
        this.$buefy.toast.open({ type: 'is-danger', message: 'An unexpected error occurred. Please contact support.' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {

  },
  props: ['code', 'invite']
}
</script>
