<template>
  <div>
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showConfirmModal"
      :can-cancel="['x', 'escape']"
      :on-cancel="$parent.close"
      >
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Confirm</p>
        </header>
        <section class="modal-card-body">
          <div class="has-background-white rounded-lg">
            {{bodyText}}
            <p v-if="bodySubText"> {{bodySubText}} </p>
          </div>
        </section>
        <footer class="modal-card-foot" style="justify-content:space-between">
          <b-button type="is-primary" @click="$parent.close()">Cancel</b-button>
          <b-button type="is-primary" @click="$parent.confirm()" :loading="loading" :disabled="loading">Yes</b-button>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  components: {

  },
  computed: {

  },
  data: () => {
    return {
      showConfirmModal: false
    }
  },
  methods: {

  },
  mounted () {
    this.showConfirmModal = this.showModal
  },
  watch: {
  },
  props: {
    showModal: {
      type: Boolean,
      required: true,
      default: false
    },
    loading: {
      type: Boolean,
      required: true,
      default: false
    },
    bodyText: {
      type: String,
      default: 'Are you sure you want to delete it?'
    },
    bodySubText: {
      type: String,
      default: ''
    }
  }
}
</script>
