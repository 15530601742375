<template>
  <div>
    <div class="columns">
      <div class="column is-one-fifth">
        <p class="has-text-weight-bold is-size-6 mb-sm">Store Details</p>
        <p class="has-text-grey is-size-7">Information about the Shopify store connected with your Marketplace account.</p>
      </div>
      <div class="column">
        <div class="has-background-white rounded-lg">
          <div class="pa-lg">
            <div class="mb-md">
              <p class="has-text-weight-bold">Store Name</p>
              <p>{{ store.name }}</p>
            </div>
            <div class="mb-md">
              <p class="has-text-weight-bold">Store URL</p>
              <p>{{ store.url }}</p>
            </div>
            <div>
              <p class="has-text-weight-bold">Platform Account ID</p>
              <p>{{ platformAccountId }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    platformAccountId () {
      let platformAccountId = '-'
      if (this.account && this.account.id) {
        platformAccountId = this.account.id
      }
      return platformAccountId
    },
    store () {
      let store = { name: '-', url: '-' }
      const credentials = this.account.credentials.filter(el => el.shopify_shop_name)
      if (credentials && credentials.length) {
        const shopNameValue = credentials[0].shopify_shop_name.values &&
                    credentials[0].shopify_shop_name.values.length &&
                    credentials[0].shopify_shop_name.values[0].value
        if (shopNameValue) {
          store = { name: shopNameValue, url: shopNameValue + '.myshopify.com' }
        }
      }
      return store
    }
  },
  props: ['account']
}
</script>
