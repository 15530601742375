<template>
  <div>
    <div class="columns">
      <div class="column is-one-quarter">
        <b-button
          type="is-light"
          size="is-medium"
          expanded
          @click="$emit('update:inventory')">
          Update Inventory
        </b-button>
      </div>
      <div class="column is-one-quarter">
        <b-button
          type="is-light"
          size="is-medium"
          expanded
          @click="$emit('update:sku')">
          Update SKU
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
}
</script>
