<template>
<div>
  <div class="section">
    <div class="container">
      <!-- Page Header -->
      <div class="mb-md">
        <div class="columns is-vcentered">
          <div class="column is-one-third">
            <breadcrumbs :history="[{label: 'Months', name: 'finance.months'}]" :current="startDate.format('MMMM YYYY')" />
          </div>
          <div class="column is-one-third">
            <h1 class="has-text-centered is-size-4">{{ startDate.format('MMMM YYYY') }}</h1>
          </div>
        </div>
      </div>
      <!-- Content -->
      <div class="mb-md">
        <div class="mb-lg" v-if="auth.isStaff && context.isMerchant()">
          <div v-if="!loading">
            <router-view :stats="stats" :year="year" :month="month" :day="day" :startDate="startDate" :endDate="endDate" />
          </div>
          <div v-else>
            <loading :message="loadingMessage" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['auth', 'context']),
    year () {
      return parseInt(this.$route.params.year)
    },
    month () {
      return parseInt(this.$route.params.month)
    },
    day () {
      return (this.$route.params.day) ? parseInt(this.$route.params.day) : null
    },
    startDate () {
      return (this.$route.params.day === '15')
        ? this.$dates(new Date(this.year, this.month - 1, 15))
        : this.$dates(new Date(this.year, this.month - 1, 1))
    },
    endDate () {
      return (this.$route.params.day === '1')
        ? this.$dates(new Date(this.year, this.month - 1, 1)).date(14)
        : this.$dates(new Date(this.year, this.month - 1, 1)).endOf('month')
    }
  },
  data () {
    return {
      loading: true,
      loadingMessage: 'Loading Invoices...',
      supplierSummary: {},
      stats: []
    }
  },
  methods: {
    load () {
      this.loading = true
      const endpoint = this.context.route + '/invoices/summary/'
      const query = {
        params: {
          terms_date_due_gte: this.startDate.unix(),
          terms_date_due_lte: this.endDate.unix(),
          status: 'closed'
        }
      }
      this.$http.get(endpoint, query).then(response => {
        this.loadingMessage = 'Loading Credits...'
        response.data.forEach(supplier => {
          this.supplierSummary[supplier.brand.id] = {
            id: supplier.connection_id,
            name: supplier.brand.name,
            invoiceCount: parseInt(supplier.invoice_count),
            invoiceSubtotal: parseFloat(supplier.subtotal),
            invoiceCharges: parseFloat(supplier.charges),
            invoiceAllowances: parseFloat(supplier.allowances),
            allowancePercentage: parseFloat(supplier.allowances) / parseFloat(supplier.subtotal),
            creditCount: 0,
            creditSubtotal: 0,
            creditAdjustments: 0
          }
        })
        const endpoint = this.context.route + '/credits/summary/'
        const query = {
          params: {
            credited_at_gte: this.startDate.unix(),
            credited_at_lte: this.endDate.unix()
          }
        }
        this.$http.get(endpoint, query).then(response => {
          this.loadingMessage = 'Summarizing...'
          response.data.forEach(supplier => {
            if (supplier.brand.id in this.supplierSummary) {
              this.supplierSummary[supplier.brand.id].creditCount = parseInt(supplier.credit_memo_count)
              this.supplierSummary[supplier.brand.id].creditSubtotal = parseFloat(supplier.subtotal)
              this.supplierSummary[supplier.brand.id].creditAdjustments = parseFloat(supplier.adjustments)
            } else {
              this.supplierSummary[supplier.brand.id] = {
                id: supplier.connection_id,
                name: supplier.brand.name,
                invoiceCount: 0,
                invoiceSubtotal: 0,
                invoiceCharges: 0,
                invoiceAllowances: 0,
                creditCount: parseInt(supplier.credit_memo_count),
                creditSubtotal: parseFloat(supplier.subtotal),
                creditAdjustments: parseFloat(supplier.adjustments)
              }
            }
          })
          // return to array format for easier table presentation
          for (const id in this.supplierSummary) {
            this.stats.push(this.supplierSummary[id])
          }
          this.loading = false
        })
      })
    }
  },
  mounted () {
    this.load()
  }
}
</script>
