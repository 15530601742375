<template>
  <div>
    <!-- Items -->
    <ProposalVariants :proposal="proposal" />
  </div>
</template>

<script>
import ProposalVariants from '../components/Detail/ProposalVariants'

export default {
  components: {
    ProposalVariants
  },
  props: ['proposal']
}
</script>
