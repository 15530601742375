<template>
  <form>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title"></p>
      </header>
      <section class="modal-card-body">
        hi
      </section>
      <footer class="modal-card-foot">
        <button class="button" type="button" @click="$parent.close()">Close</button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  data () {
    return {

    }
  },
  computed: {
    ...mapGetters(['context'])
  },
  methods: {

  },
  mounted () {

  },
  props: []
}
</script>
