<template>
  <div>
    <div class="columns">
      <div class="column is-one-fifth">
        <p class="has-text-weight-bold is-size-6 mb-sm">Full Order Cancellations</p>
        <p class="has-text-grey is-size-7">Configure how your Shopify store syncs cancellations with {{ app.platform }}.</p>
      </div>
      <div class="column">
        <div class="has-background-white rounded-lg">
          <div class="pa-lg">
            <!-- Intro Text (Merchant) -->
            <div class="is-size-7 mb-md">
              <p class="">
                When you cancel full orders in Shopify, we can automatically cancel orders in {{ app.productName }}. Please note
                that Shopify only publishes this event when there is a FULL cancellation.
              </p>
            </div>
            <!-- Order Cancellation Status -->
            <div v-if="!loading" class="has-background-white-ter pa-md rounded-lg">
              <div class="columns is-vcentered is-mobile">
                <div class="column is-narrow">
                  <b-icon v-if="cancelOrderIsEnabled" icon="check" custom-class="has-text-success" />
                  <b-icon v-else icon="close" custom-class="has-text-warning" />
                </div>
                <div class="column">
                  <p class="has-text-weight-bold">Full Order Cancellations</p>
                  <p class="has-text-grey-light is-size-8">
                    <span v-if="cancelOrderIsEnabled">
                      This integration is currently enabled via webhook id {{ webhook.id }}
                      (Shopify ID: {{ webhook.platform_identifier }})
                    </span>
                    <span v-else>This integration is currently disabled.</span>
                  </p>
                </div>
                <div class="column is-narrow">
                  <p class="has-text-weight-bold">
                    <span v-if="cancelOrderIsEnabled" class="has-text-success">Enabled</span>
                    <span v-else class="has-text-warning">Disabled</span>
                  </p>
                </div>
              </div>
            </div>
            <div v-else class="has-background-white-ter rounded-lg">
              <p class="has-text-grey-light is-size-8 pa-md">Loading...</p>
            </div>
          </div>
          <div class="border-t">
            <div class="columns is-vcentered is-marginless">
              <div class="column">
                <div class="px-sm has-text-grey-light">
                  <i v-if="webhook !== null">Click "Disable Integration" to unsubscribe from the current webhook.</i>
                  <i v-else>Click "Enable Integration" to begin syncing new orders.</i>
                </div>
              </div>
              <div class="column is-narrow">
                <div class="px-sm">
                  <!-- Remove Webhook -->
                  <b-button
                    v-if="cancelOrderIsEnabled"
                    type="is-danger rounded-lg"
                    outlined
                    :disabled="loading || saving"
                    :loading="saving"
                    @click="disable"
                  >Disable Integration</b-button>
                  <!-- Subscribe to new Webhook -->
                  <b-button
                    v-if="!cancelOrderIsEnabled"
                    type="is-primary rounded-lg"
                    :disabled="loading || saving || topic === null"
                    @click="enable"
                    :loading="saving"
                  >Enable Integration</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['app', 'context']),
    endpoint () {
      return this.context.route + '/shopify/' + this.account.id + '/webhooks/'
    },
    cancelOrderIsEnabled () {
      if (this.webhook) {
        return (this.webhook.topic === 'orders/cancelled')
      }
      return false
    },
    json () {
      return {
        webhook_topic: this.topic
      }
    }
  },
  data () {
    return {
      loading: true,
      saving: false,
      errors: {},
      webhook: null,
      topic: 'orders/cancelled'
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint).then(response => {
        // check for the cancelled order webhook...
        const create = this.$utils.find(response.data.results, ['topic', this.topic])
        if (create) {
          this.webhook = create
        }
      }).finally(() => {
        this.loading = false
      })
    },
    enable () {
      const endpoint = this.context.route + '/shopify/' + this.account.id + '/register-webhook/'
      this.saving = true
      this.$http.put(endpoint, this.json).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Sync enabled!' })
      }).finally(() => {
        this.saving = false
        this.load()
      })
    },
    disable () {
      this.saving = true
      this.$http.delete(this.endpoint + this.webhook.id + '/').then(() => {
        this.webhook = null
        this.$buefy.toast.open({ type: 'is-success', message: 'Sync disabled!' })
      }).finally(() => {
        this.saving = false
        this.load()
      })
    }
  },
  mounted () {
    this.load()
  },
  props: ['account']
}
</script>
