<template>
  <div class="columns is-variable is-1 is-mobile is-vcentered">
    <div class="column is-narrow">
      <a href="#"
        @click.prevent="usePreset('new')"
        class="button rounded-lg is-size-7"
        :class="(preset === 'new') ? 'is-primary' : 'is-white'"
      >New</a>
    </div>
    <div class="column is-narrow is-hidden-mobile">
      &rang;
    </div>
    <div class="column is-narrow">
      <a href="#"
        @click.prevent="usePreset('open')"
        class="button rounded-lg is-size-7"
        :class="(preset === 'open') ? 'is-primary' : 'is-white'"
      >Open</a>
    </div>
    <div class="column is-narrow is-hidden-mobile">
      &rang;
    </div>
    <div class="column is-narrow is-hidden-mobile">
      <a href="#"
        @click.prevent="usePreset('completed')"
        class="button rounded-lg is-size-7"
        :class="(preset === 'completed') ? 'is-primary' : 'is-white'"
      >Completed</a>
    </div>
    <div class="column">
      <div class="has-text-right">
        <b-dropdown position="is-bottom-left">
          <button class="button"  slot="trigger" slot-scope="{ active }">
            <span>More Presets</span>
            <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
          </button>
          <b-dropdown-item><a href="#" @click.prevent="usePreset('critical')">Critically Late Orders</a></b-dropdown-item>
          <b-dropdown-item><a href="#" @click.prevent="usePreset('late')">Late Orders</a></b-dropdown-item>
          <b-dropdown-item><a href="#" @click.prevent="usePreset('current')">Current Orders</a></b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import presets from '../../presets/orders'
export default {
  data () {
    return {
      preset: null,
      presets: presets
    }
  },
  methods: {
    usePreset (preset) {
      this.$emit('preset:updated', preset)
      this.$emit('filter', this.presets[preset].filters())
      if ('preset' in this.$route.query && this.$route.query.preset !== preset) {
        this.$router.replace({ name: 'orders.list', query: { preset: preset } })
      }
    },
    sync () {
      this.preset = this.appliedFilters.preset
    }
  },
  mounted () {

  },
  props: {
    appliedFilters: {
      type: Object,
      required: true
    }
  },
  watch: {
    appliedFilters: {
      deep: true,
      immediate: true,
      handler: 'sync'
    }
  }
}
</script>
