<template>
  <div>
    <!-- Page Header -->
    <div class="mb-sm">
      <div class="columns is-vcentered">
        <div class="column is-one-third">
          <breadcrumbs :history="[{ label: 'Catalog', name: 'catalog'}]" current="Import" :hideBack="true" />
        </div>
        <div class="column is-one-third">
          <h1 class="has-text-centered is-size-4">Import</h1>
        </div>
      </div>
    </div>
    <div>
      <div class="columns is-centered is-multiline">
        <!-- Browse Items -->
        <div v-if="platformAccounts.length > 0" class="column is-half is-one-third-desktop is-one-quarter-widescreen">
          <router-link :to="{ name: 'catalog.import.shopify' }">
            <div class="has-background-white rounded-lg" style="height:250px">
              <div class="has-text-centered has-text-grey-lighter pt-xxl">
                <b-icon icon="lan-connect" custom-class="mdi-96px"></b-icon>
              </div>
              <div class="has-text-centered pa-md">
                <p class="has-text-weight-bold mb-sm">Import from Shopify</p>
                <p class="has-text-grey-light is-size-7">Import products directly from a collection within your Shopify shop.</p>
              </div>
            </div>
          </router-link >
        </div>
        <!-- Import from Template -->
        <div class="column is-half is-one-third-desktop is-one-quarter-widescreen">
          <router-link :to="{ name: 'catalog.import.products' }">
            <div class="has-background-white rounded-lg" style="height:250px">
              <div class="has-text-centered has-text-grey-lighter pt-xxl">
                <b-icon icon="playlist-check" custom-class="mdi-96px"></b-icon>
              </div>
              <div class="has-text-centered pa-md">
                <p class="has-text-weight-bold mb-sm">Import Products</p>
                <p class="has-text-grey-light is-size-7">Import new products from a CSV or spreadsheet.</p>
              </div>
            </div>
          </router-link>
        </div>
        <!-- Import Inventory from Template -->
        <div v-if="context.isSupplier()" class="column is-half is-one-third-desktop is-one-quarter-widescreen">
          <router-link :to="{ name: 'catalog.import.inventory' }">
            <div class="has-background-white rounded-lg" style="height:250px">
              <div class="has-text-centered has-text-grey-lighter pt-xxl">
                <b-icon icon="playlist-plus" custom-class="mdi-96px"></b-icon>
              </div>
              <div class="has-text-centered pa-md">
                <p class="has-text-weight-bold mb-sm">Import Inventory</p>
                <p class="has-text-grey-light is-size-7">Import current inventory from a CSV or spreadsheet.</p>
              </div>
            </div>
          </router-link>
        </div>
        <!-- Retailer Imports -->
        <!-- <div class="column is-half is-one-third-desktop is-one-quarter-widescreen">
          <router-link :to="{ name: 'catalog.import.requests' }">
            <div class="has-background-white rounded-lg" style="height:250px">
              <div class="has-text-centered has-text-grey-lighter pt-xxl">
                <b-icon icon="ballot-outline" custom-class="mdi-96px"></b-icon>
              </div>
              <div class="has-text-centered pa-md">
                <p class="has-text-weight-bold is-size-5">Retailer Imports</p>
                <p class="has-text-grey-light">Review and approve product imports submiited by {{ partner }}</p>
              </div>
            </div>
          </router-link>
        </div> -->
        <!-- Import History -->
        <div class="column is-half is-one-third-desktop is-one-quarter-widescreen">
          <router-link :to="{ name: 'catalog.import.history' }">
            <div class="has-background-white rounded-lg" style="height:250px">
              <div class="has-text-centered has-text-grey-lighter pt-xxl">
                <b-icon icon="history" custom-class="mdi-96px"></b-icon>
              </div>
              <div class="has-text-centered pa-md">
                <p class="has-text-weight-bold mb-sm">History</p>
                <p class="has-text-grey-light is-size-7">Check import history for visibility to import status and results.</p>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['app', 'context']),
    partner () {
      if (this.connections.length === 1) {
        return this.connections[0].retailer.name
      }
      return 'a Merchant Partner'
    }
  },
  props: {
    connections: {
      type: Array,
      required: true
    },
    platformAccounts: {
      type: Array,
      required: true
    }
  }
}
</script>
