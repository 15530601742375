<template>
  <div>
    <!-- Content -->
    <div class="">
      <div class="mb-lg">
        <p class="has-text-weight-bold mb-md">Import Status</p>
        <div class="columns">
          <div class="column">
            <p>
              Your import has been queued and we will begin importing your products.
              You can wait for the import to finish or you can complete this task
              and continue onboarding.
            </p>
          </div>
          <!-- <div class="column is-one-quarter">
            <div class="has-background-light rounded-lg pa-md">
              <div class="is-size-7 has-text-centered">
                <p v-if="!loadingProducts" class="has-text-weight-bold">{{ numProducts }}</p>
                <p v-else>--</p>
                <p class="is-size-7">Products</p>
              </div>
            </div>
          </div> -->
        </div>
      </div>
      <!-- Status -->
      <div v-if="!loading && jobDetail !== null" class="mb-xl">
        <!-- Progress Bar -->
        <div class="has-background-light rounded-lg pa-md">
          <Progress :job="jobDetail" size="is-medium" :emitRefresh="true" @refresh="refresh" />
        </div>
        <!-- Results -->
        <div class="mb-lg">
          <table class="table is-fullwidth is-size-7 is-narrow">
            <tr>
              <th>Status</th>
              <td class="has-text-right">
                <p><Status :job="jobDetail" /></p>
              </td>
            </tr>
            <tr>
              <th>Started</th>
              <td class="has-text-right">{{ $dates.utc(jobDetail.created_at).local().format("MMM D, YYYY @ h:mm A") }}</td>
            </tr>
            <tr>
              <th>Finished</th>
              <td class="has-text-right">
                <span v-if="jobDetail.finish_time">{{ $dates.utc(jobDetail.finish_time).local().format("MMM D, YYYY @ h:mm A") }}</span>
              </td>
            </tr>
            <tr>
              <th>Runtime</th>
              <td class="has-text-right">
                <p><Runtime :job="jobDetail" /></p>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <!-- Loading -->
      <div v-else>
        <loading message="Loading import details..." />
      </div>
    </div>
    <!-- Navigate -->
    <div class="py-lg">
      <div class="columns is-centered">
        <div class="column">
          <b-button
            type="is-light rounded-lg"
            size="is-medium"
            @click="goBack"
            expanded
            ><b-icon icon="arrow-left"></b-icon>
          </b-button>
        </div>
        <div class="column">
          <b-button
            type="is-primary rounded-lg"
            size="is-medium"
            expanded
            :disabled="!ready || saving"
            :loading="saving"
            @click="goForward"
          >View Products</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Status from '@/components/Models/Job/Presenters/Status'
import Runtime from '@/components/Models/Job/Presenters/Runtime'
import Progress from '@/components/Models/Job/Presenters/Progress'

export default {
  components: {
    Status,
    Runtime,
    Progress
  },
  computed: {
    ...mapGetters(['app', 'context']),
    ready () {
      return true
    },
    query () {
      const qs = {}
      if (this.settings.dropshipCostPercent) {
        qs.dropship_cost_percent = this.settings.dropshipCostPercent
      }
      if (this.settings.collection) {
        qs.collection_id = this.settings.collection.id
      }
      return (!this.$utils.isEmpty(qs))
        ? { params: qs }
        : null
    },
    loading () {
      return (this.loadingJob)
    }
  },
  data () {
    return {
      loadingJob: true,
      loadingProducts: true,
      jobDetail: null,
      numProducts: '--',
      saving: false,
      errors: {}
    }
  },
  methods: {
    goBack () {
      this.$router.replace({ name: 'catalog.import.shopify' })
      this.$emit('navigate', 'ScheduleImport')
    },
    goForward () {
      this.$router.push({ name: 'catalog.products' })
    },
    loadJob (silent = false) {
      if (!silent) this.loadingJob = true
      const endpoint = this.context.route + '/jobs/' + this.job.id + '/'
      this.$http.get(endpoint).then(response => {
        this.jobDetail = response.data
        this.loadingJob = false
      })
    },
    loadProducts (silent = false) {
      if (!silent) this.loadingProducts = true
      const endpoint = this.context.route + '/products/'
      const query = {
        params: {
          fields: 'id',
          short: 1,
          limit: 1
        }
      }
      this.$http.get(endpoint, query).then(response => {
        this.numProducts = response.data.count
        this.loadingProducts = false
      })
    },
    refresh () {
      this.loadJob(true)
      // this.loadProducts()
    }
  },
  mounted () {
    this.loadJob()
    this.loadProducts()
  },
  props: {
    account: {
      type: Object,
      required: true
    },
    settings: {
      type: Object,
      required: true
    },
    job: {
      type: Object,
      required: true
    }
  }
}
</script>
