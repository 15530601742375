<template>
  <div>
    <div class="section">
      <div class="container">
        <!-- Page Header -->
        <div class="mt-sm mb-xl">
          <breadcrumbs
            :history="[{ label: 'Network', name: 'network.dashboard'}, { label: 'Catalog', name: 'network.products'}]"
            :current="'Product #' + $route.params.id "
            :hideBack="true"
          />
        </div>

        <div v-if="product !== null">
          <!-- General Info -->
          <div class="border-b pb-lg mb-lg">
            <product-info :product="product" @update:product="refresh" />
          </div>
          <!-- Description -->
          <div class="border-b pb-lg mb-lg">
            <product-description :product="product" />
          </div>
          <!-- Variants -->
          <div class="border-b pb-lg mb-lg">
            <product-variants :product="product" :profile="profile" />
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ProductDescription from '../components/ProductDescription'
import ProductInfo from '../components/ProductInfo'
import ProductVariants from '../components/ProductVariants'
export default {
  components: {
    ProductDescription,
    ProductInfo,
    ProductVariants
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/products/' + this.$route.params.id + '/'
    }
  },
  data () {
    return {
      loading: true,
      product: null,
      variants: []
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint).then(response => {
        this.product = response.data
      }).finally(() => {
        this.loading = false
      })
    },
    refresh (product) {
      this.product = product
    }
  },
  mounted () {
    this.load()
  },
  props: ['profile']
}
</script>
