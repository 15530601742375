import List from './views/List'
import TransformerDetails from './views/TransformerDetails'
import AttributeDetails from './views/AttributeDetails'

export default [{
  path: '/settings/transformers',
  name: 'transformers',
  component: List
}, {
  path: '/settings/transformers/:id',
  name: 'transformer',
  component: TransformerDetails
}, {
  path: '/settings/transformers/:transformer/attribute/:id',
  name: 'attribute.map',
  component: AttributeDetails
}]
