<template>
  <div>
    <div class="section">
      <div class="container">

        <!-- Hero -->
        <!-- style="background: url('https://res.cloudinary.com/revcascade/image/upload/w_1200,h_800,c_pad,b_white,o_30/corporate-website/packing-box.jpg'); background-size:cover; border-bottom:3px solid #35C78D" -->
        <div class="has-background-white pa-lg rounded-lg">
          <div class="columns is-centered is-marginless">
            <div class="column is-four-fifths-tablet is-two-thirds is-paddingless">
              <div class="has-text-centered  has-text-grey-darker has-padding-top-xl has-padding-bottom-xl">
                <p class="is-uppercase has-text-weight-bold has-text-info mb-md">Introducing</p>
                <h1 class="is-size-3 is-size-5-mobile mb-lg">The {{ app.platform }} Commerce Network</h1>
                <p class="is-size-5 is-size-6-mobile has-text-grey mb-xl">
                  The {{ app.platform }} Commerce Network connects brands, makers, & suppliers directly to
                  leading online merchants & publishers, and turns dropshipping into a massive revenue stream.
                </p>
                <p class="mb-lg">
                  <button
                    class="button is-primary is-large has-padding-left-xl has-padding-right-xl rounded-lg"
                    type="button"
                    @click.prevent="showTermsModal = true"
                    >Activate Now!</button>
                </p>
              </div>
            </div>
          </div>
        </div>

        <!-- Interlude -->
        <div class="py-xxl">
          <div class="columns is-centered">
            <div class="column is-three-quarters">
              <p class="is-size-5 is-size-6-mobile has-text-weight-bold has-text-centered">
                Your existing connection to {{ app.platform }} gives you access to
                even more eCommerce partners that generate over $100 million in
                dropship sales and reach 200+ million consumers.
              </p>
            </div>
          </div>
        </div>

        <!-- How It Works -->
        <div class="columns is-vcentered">
          <div class="column">
            <div class="box rounded-lg">
              <p class="is-uppercase has-text-weight-bold has-text-info mb-lg has-text-centered is-size-5">How it works</p>
              <!-- Agree to Terms -->
              <div class="mb-xl">
                <div class="columns is-mobile is-vcentered">
                  <div class="column is-narrow">
                    <p><b-icon icon="file-document-edit-outline" size="is-large" /></p>
                  </div>
                  <div class="column">
                    <h4 class="has-text-weight-bold is-size-5 mb-sm">Create Profile</h4>
                    <p>
                      Customize your brand profile and share only the products & collections
                      that you're ready to dropship.
                    </p>
                  </div>
                </div>
              </div>
              <!-- Publish Products -->
              <div class="mb-xl">
                <div class="columns is-mobile is-vcentered">
                  <div class="column is-narrow">
                    <p><b-icon icon="map-search-outline" size="is-large" /></p>
                  </div>
                  <div class="column">
                    <h4 class="has-text-weight-bold is-size-5 mb-sm">Approve Merchants</h4>
                    <p>
                       Respond to merchant requests to sell your products.
                       With cost & MAP protection, you stay in complete control of your partners and your pricing.
                    </p>
                  </div>
                </div>
              </div>
              <!-- Publish Products -->
              <div class="mb-xl">
                <div class="columns is-mobile is-vcentered">
                  <div class="column is-narrow">
                    <p><b-icon icon="package-variant-closed" size="is-large" /></p>
                  </div>
                  <div class="column">
                    <h4 class="has-text-weight-bold is-size-5 mb-sm">Ship & Get Paid</h4>
                    <p>
                      Fulfill orders using EDI, API, or the
                      {{ app.platform }} portal. Recieve rolling payments
                      every 2 weeks for all shipped orders (net returns).
                    </p>
                  </div>
                </div>
              </div>
              <!-- Generate Sales (Merchants Only) -->
              <div v-if="context.type === 'retaidler'" class="mb-md">
                <div class="columns is-vcentered">
                  <div class="column is-narrow">
                    <p><b-icon icon="cash-multiple" size="is-large" /></p>
                  </div>
                  <div class="column">
                    <h4 class="has-text-weight-bold mb-sm">Generate Sales</h4>
                    <p>
                      The Network accepts any type of product feed. D2C brands using
                      popular storefronts (e.g. Shopify) can integrate in 30 seconds.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="column">
            <div class="has-background-grey-lighter pa-4xl rounded-lg has-text-white has-text-centered">
              Video
            </div>
          </div>
        </div>

        <!-- Faq's -->
        <div class="py-lg">
          <div class="columns is-centered">
            <div class="column is-three-quarters">
              <div class="">
                <h1 class="has-text-centered is-uppercase has-text-info is-size-5 has-text-weight-bold mb-md">FAQs</h1>
                <supplier-faqs v-if="context.type === 'vendor'" />
              </div>
            </div>
          </div>
        </div>

        <!-- Interlude -->
        <div class="py-xxl">
          <div class="columns is-centered">
            <div class="column is-three-quarters">
              <div class="has-text-centered">
                <p class="is-size-5 is-size-6-mobile has-text-weight-bold has-text-centered mb-md">
                  We're ready when you are!
                </p>
                <p class="mb-lg"><button type="button" name="button" @click.prevent="showApplyModal = true" class="button is-primary is-large has-padding-left-xl has-padding-right-xl rounded-lg">Activate</button></p>
                <p class="is-size-5 is-size-6-mobile has-text-weight-bold has-text-centered mb-md">
                  ...or if you have any questions, don't hesistate to contact {{ app.supportEmail }}.
                </p>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <!-- Modal with Terms -->
    <b-modal :active.sync="showTermsModal" has-modal-card>
      <div class="modal-card" style="max-width: 960px">
        <section class="modal-card-body is-paddingless">
          <iframe width="100%" height="400" style="height:400px !important; display:block" src="https://drive.google.com/file/d/10guU2ViIXrczyOSyskC4Lj5vSxFy_htrNaRLP724sGw/preview"></iframe>
        </section>
        <footer class="modal-card-foot space-between">
          <a class="button" href="https://drive.google.com/file/d/10guU2ViIXrczyOSyskC4Lj5vSxFy_htrNaRLP724sGw/preview" target="_blank">Open Fullscreen</a>
          <b-button type="is-primary" @click.prevent="save" :disabled="saving" :loading="saving">Agree to Terms</b-button>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SupplierFaqs from '../components/Onboarding/SupplierFaqs'
export default {
  components: {
    SupplierFaqs
  },
  computed: {
    ...mapGetters(['app', 'assets', 'context']),
    endpoint () {
      return this.context.route + '/marketplaces/'
    },
    json () {
      const terms = (this.context.type === 'vendor')
        ? { document_id: this.assets.terms.vendor }
        : { document_id: this.assets.terms.retailer }
      return {
        visibility: 'invisible',
        status: 'pending',
        terms_and_conditions: terms
      }
    }
  },
  data () {
    return {
      showTermsModal: false,
      saving: false
    }
  },
  methods: {
    save () {
      this.saving = true
      this.$http.post(this.endpoint, this.json).then(() => {
        this.$router.push({ name: 'network.onboarding' })
      }).finally(() => {
        this.saving = false
      })
    }
  }
}
</script>
