import dates from '@/assets/js/dates'

export const connection = {
  label: 'Connection',
  key: 'connection_id',
  icon: 'hubspot',
  tooltip: 'Limit results to a set of selected partners.'
}

export const canceledBy = {
  label: 'Canceled By',
  key: 'canceled_by',
  icon: 'pulse',
  tooltip: 'Limit results based on who requested the cancel.',
  options: [{
    value: 'brand',
    label: 'Supplier'
  }, {
    value: 'retailer',
    label: 'Retailer'
  }]
}

export const acknowledged = {
  label: 'Acknowledged',
  key: 'acknowledged',
  icon: 'pulse',
  tooltip: 'Limit results based on acknowledgement.',
  options: [{
    value: 1,
    label: 'Yes'
  }, {
    value: 0,
    label: 'No'
  }]
}

export const reason = {
  label: 'Reason',
  key: 'reason_id',
  icon: 'pulse',
  tooltip: 'Limit results based on the cancel reason.',
  options: [{
    value: 1,
    label: 'Retailer Requested Cancellation'
  }, {
    value: 2,
    label: 'Not Enough Inventory'
  }, {
    value: 3,
    label: 'Can\'t Meet Fulfillment Deadline'
  }, {
    value: 4,
    label: 'Product Listing and/or Pricing Error'
  }, {
    value: 5,
    label: 'Unfulfillable Address'
  }, {
    value: 6,
    label: 'Suspected Fraud'
  }, {
    value: 8,
    label: 'Price Discrepancy'
  }, {
    value: 9,
    label: 'Product Discontinued'
  }]
}

export const createdAt = {
  label: 'Created At',
  key: 'created_at',
  icon: 'pulse',
  tooltip: 'Limit results based on when the cancel was registered.',
  options: dates
}
