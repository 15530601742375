<template>
  <div>
    <div class="section">
      <div class="container">
        <!-- Page Header -->
        <div class="columns is-vcentered">
          <div class="column is-one-third">
            <breadcrumbs :history="[{ label: 'Settings', name: 'settings'}]" current="Commission Profiles" :hideBack="true" />
          </div>
          <div class="column is-one-third">
            <h1 class="has-text-centered is-size-4">Commission Profiles</h1>
          </div>
        </div>
        <!-- Filters -->
        <div class="has-background-white rounded-lg pa-md mb-xxs">
          <div class="columns">
            <div class="column">
              <CommissionFilters @filter="filter" :applied-filters="filters" />
            </div>
            <div class="column is-narrow">
              <b-field >
                <b-select v-model="orderBy" expanded @input="sort" size="is-medium">
                  <option value="-id">Most Recent</option>
                  <option value="id">Oldest</option>
                </b-select>
              </b-field>
            </div>
            <div class="column is-narrow">
              <router-link :to="{ name: 'commission-profiles.create' }" class="button is-primary is-medium is-outlined">Add New Profile</router-link>
            </div>
          </div>
        </div>
        <!-- Toolbar -->
        <div class="has-background-white rounded-lg px-sm mb-xxs">
          <div class="columns is-vcentered is-mobile is-marginless is-paddingless">
            <!-- Dropdown -->
            <div class="column">
              <div class="has-text-right is-size-7">
                <!-- Counts -->
                <p v-if="loading">Loading...</p>
                <p v-else>{{ total }} {{ (total === 1) ? 'record' : 'records' }} found.</p>
              </div>
            </div>
          </div>
        </div>
        <!-- Table -->
        <div>
          <b-table
            :data="results"
            :checked-rows.sync="selected"
            :mobile-cards="false"
            :loading="loading"
            hoverable
            class="is-size-7">
            <template>
              <!-- Profile Name -->
              <b-table-column
                label="Profile Name"
                width="256"
                v-slot="props">
                <router-link class="has-text-weight-bold has-text-info" :to="{ name: 'commission-profiles.edit', params: { id: props.row.id }}">
                  {{ props.row.name }}
                </router-link>
              </b-table-column>
              <!-- Suppliers -->
              <b-table-column
                label="Suppliers"
                cell-class="nowrap"
                v-slot="props">
                <p v-if="!props.row.connections.length"> - </p>
                <p v-else-if="props.row.connections.length >= 4">
                  {{ getSuppliers(props.row) }}
                  <a href="#" class="has-text-info" @click.prevent="showAllConnections=true"> + {{ moreSupplierCount }} more</a>
                </p>
                <p v-else>{{ getSuppliers(props.row) }}</p>
              </b-table-column>
              <!-- Method -->
              <b-table-column
                label="Method"
                width="256"
                cell-class="nowrap"
                v-slot="props">
                <p>{{ $utils.upperFirst(props.row.method) }}</p>
              </b-table-column>
            </template>
            <template #empty>
              <div class="py-xxl has-text-grey has-text-centered">
                {{ (loading) ? "Loading data..." : 'You currently have no subscription plans.' }}
              </div>
            </template>
          </b-table>
        </div>
        <div class="mt-md">
          <b-pagination
            :total="total"
            :current.sync="currentPage"
            :rounded="true"
            :per-page="perPage"
            @change="paginate">
          </b-pagination>
        </div>
      </div>
    </div>
    <!-- View suppliers modal -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showAllConnections"
      :can-cancel="['x']"
      ><SuppliersInfoModal :suppliers="suppliersList"/>
    </b-modal>
  </div>
  </template>

<script>
import { mapGetters } from 'vuex'
import Filterable from '@/mixins/Filterable'
import SuppliersInfoModal from '../components/List/SuppliersInfoModal'
import CommissionFilters from '../components/List/Filters'

export default {
  components: {
    CommissionFilters,
    SuppliersInfoModal
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/commission-profiles/'
    }
  },
  data () {
    return {
      key: 'commission-profiles',
      alwaysAttach: {
      },
      selected: [],
      suppliersList: [],
      showAllConnections: false,
      moreSupplierCount: 0
    }
  },
  methods: {
    getSuppliers (row) {
      const values = []
      if (row.connections.length) {
        row.connections.map(el => values.push(el.brand.name))
      }
      if (values.length >= 4) {
        this.suppliersList = row.connections
        this.moreSupplierCount = row.connections.length - 4
      }
      return values.slice(0, 4).toString()
    }
  },
  mixins: [Filterable],
  mounted () {
    this.load()
  }
}
</script>
