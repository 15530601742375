<template>
  <div>
    <div class="mb-xl">
      <div class="mb-xl pb-sm">
        <p class="is-size-4 mb-md has-text-centered ">Orders Integration</p>
        <p class="is-size-6">
          {{ app.platform }} can also push orders directly into your Shopify backend, and automatically
          pull fulfillments and tracking numbers. This is an optional integration, and you can always skip this step
          and enable this integration later.
        </p>
      </div>
    </div>
    <form @submit.prevent="save">
      <div class="mb-xl pb-lg">
        <p class="has-text-weight-bold mb-sm is-size-6">Enable Orders Integration?</p>
        <div class="has-text-grey mb-lg">
          <p>
            Do you want {{ app.platform }} to automatically push new
            orders into (and pull fulfilments from) your Shopify account, so that you
            may fulfill orders directly from Shopify?
          </p>
        </div>
        <div class="columns is-mobile is-vcentered">
          <div class="column is-narrow">
            <div class="field">
              <b-switch
                v-model="syncOrders"
                type="is-success"
                true-value="Yes"
                false-value="No"
                >{{ syncOrders }}</b-switch>
            </div>
            <p v-if="ordersWebhook !== null" class="has-text-grey-light">Note: this integration is currently enabled.</p>
          </div>
          <div class="column">
            <p v-if="syncOrders === 'No'" class="has-text-grey-light">Click the switch to activate</p>
          </div>
        </div>
      </div>
      <div class="mb-xl">
        <div class="columns is-centered">
          <div class="column is-two-thirds tablet is-half-desktop">
            <b-button native-type="submit" type="is-primary is-fullwidth rounded-lg" size="is-large" :disabled="loading || saving" :loading="saving">Save & Continue</b-button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['app', 'context']),
    endpoints () {
      return {
        webhooks: this.context.route + '/shopify/' + this.account.id + '/webhooks/',
        register: this.context.route + '/shopify/' + this.account.id + '/register-webhook/'
      }
    },
    ordersWebhook () {
      if (this.webhooks.length === 0) return null
      const hook = this.$utils.find(this.webhooks, ['topic', 'orders/paid'])
      return (typeof hook !== 'undefined') ? hook : null
    },
    webhookJson () {
      return {
        webhook_topic: 'orders/paid'
      }
    }
  },
  data () {
    return {
      loading: true,
      webhooks: [],
      syncOrders: 'Yes',
      saving: false,
      error: null
    }
  },
  methods: {
    load () {
      this.loading = true
      return this.$http.get(this.endpoints.webhooks).then(response => {
        this.webhooks = response.data.results
        this.loading = false
      })
    },
    save () {
      this.saving = true
      if (this.syncOrders === 'Yes' && this.ordersWebhook === null) {
        this.$http.put(this.endpoints.register, this.webhookJson).then(response => {
          this.load()
          this.saving = false
          this.$router.push({ name: 'shopify.onboard.complete' })
        })
      } else if (this.syncOrders === 'No' && this.ordersWebhook !== null) {
        const endpoint = this.endpoints.webhooks + this.ordersWebhook.id + '/'
        this.$http.delete(endpoint).then(() => {
          this.load()
          this.saving = false
          this.$router.push({ name: 'shopify.onboard.complete' })
        })
      } else {
        this.saving = false
        this.$router.push({ name: 'shopify.onboard.complete' })
      }
    }
  },
  mounted () {
    this.$emit('update:step', 4)
    this.load()
  },
  props: ['account']
}
</script>
