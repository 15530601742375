<template>
  <div>
    <p class="is-size-5 has-text-danger">Failing Attributes</p>
    <p>The following rules are failing because one or more items is either missing a value or contains an invalid value.</p>
    <p>Click or tap or a rule to see the items that are triggering the errors.</p>
    <b-table
      :data="attributes"
      :striped="true"
      :hoverable="true"
      :mobile-cards="false"
      class="is-size-7">
      <template>
        <!-- Name -->
        <b-table-column
          label="Rule"
          cell-class="nowrap"
          v-slot="props">
          <p><a href="#" @click.prevent="inspectRule(props.row)" class="has-text-weight-bold has-text-info">{{ props.row.rule.name }}</a></p>
        </b-table-column>
        <!-- Empty -->
        <b-table-column
          label="Missing Value"
          header-class="nowrap"
          cell-class="nowrap"
          v-slot="props"
          width="200"
          centered>
          <p>
            {{ props.row.empty }}
            {{ (props.row.empty === 1) ? 'Item' : 'Items' }}
          </p>
        </b-table-column>
        <!-- Invalid -->
        <b-table-column
          label="Invalid Value"
          header-class="nowrap"
          cell-class="nowrap"
          v-slot="props"
          width="200"
          centered>
          <p>
            {{ props.row.failed - props.row.empty }}
            {{ (props.row.failed - props.row.empty === 1) ? 'Item' : 'Items' }}
          </p>
        </b-table-column>
      </template>
      <template #empty>
        <div class="has-text-grey has-text-centered">
          {{ (loading) ? "Loading data..." : 'No requests found!' }}
        </div>
      </template>
    </b-table>
    <!-- Inspect Variants -->
    <b-modal
      has-modal-card
      :active.sync="showVariantsModal"
      :can-cancel="['escape', 'x']"
      @close="rule = null"
      ><VariantsModal :proposal="proposal" :rule="rule" />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import VariantsModal from './VariantsModal'

export default {
  components: {
    VariantsModal
  },
  computed: {
    ...mapGetters(['context'])
  },
  data () {
    return {
      loading: true,
      attributes: [],
      showVariantsModal: false,
      rule: null
    }
  },
  methods: {
    loadAttributeSummary () {
      this.loading = true
      const endpoint = this.context.route + '/proposals/' + this.proposal.id + '/rulesets-attributes-summary/'
      const query = {
        params: {
          ruleset_id: this.ruleset.id
        }
      }
      this.$http.get(endpoint, query).then(response => {
        response.data.forEach(rule => {
          if (rule.failed > 0) {
            this.attributes.push(rule)
          }
        })
        this.loading = false
      })
    },
    inspectRule (rule) {
      this.rule = rule
      this.showVariantsModal = true
    }
  },
  mounted () {
    this.loadAttributeSummary()
  },
  props: ['proposal', 'ruleset']
}
</script>
