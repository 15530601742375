<template>
  <div>
    <div v-if="!loading && eligibleLocations.length > 0">
      <div class="columns is-vcentered">
        <div class="column">
          <b-select
            v-model="location"
            placeholder="Select a Location"
            size="is-medium"
            expanded>
            <option
              v-for="location in eligibleLocations"
              :value="location"
              :key="location.id">
              {{ location.nickname || location.address.name1 }} (ID: {{ location.id }})
            </option>
          </b-select>
        </div>
        <div class="column is-narrow">
          <a href="#" @click.prevent="load">Refresh</a>
        </div>
      </div>
    </div>
    <div v-else-if="!loading && eligibleLocations.length === 0">
      <p>No Locations Found!</p>
    </div>
    <!-- Loading -->
    <div v-else>
      <div class="columns is-vcentered">
        <div class="column">
          <b-select
            placeholder="Fetching inventory Locations..."
            size="is-medium"
            expanded
            disabled
          ></b-select>
        </div>
        <div class="column is-narrow">
          <a href="#" @click.prevent class="has-text-grey-lighter">Refresh</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/locations/'
    },
    eligibleLocations () {
      if (!this.locations || this.locations.length === 0) return []
      const eligible = []
      this.locations.forEach(location => {
        // discard inactive locations
        if (location.address.active === false) return
        // discard incomplete locations
        if (location.address.address1 === null) return
        if (location.address.city === null) return
        if (location.address.province_code === null) return
        if (location.address.zip === null) return
        if (location.address.country_code === null) return
        eligible.push(location)
      })
      return eligible
    }
  },
  data () {
    return {
      loading: false,
      locations: [],
      location: null
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint).then(response => {
        this.locations = response.data.results
        if (this.locationId && this.locations && this.locations.length > 0) {
          // select the location with locationId
          this.location = this.locations.find(location => location.id === this.locationId)
        }
      }).catch((error) => {
        if (error.response.status === 401) {
          this.$buefy.toast.open({ type: 'is-danger', message: 'Invalid API key or access token to fetch locations.' })
        }
      }).finally(() => {
        this.loading = false
      })
    }
  },
  mounted () {
    this.load()
  },
  props: {
    account: Object,
    locationId: {
      default: 0,
      type: Number
    }
  },
  watch: {
    location (value) {
      if (value !== null) {
        this.$emit('input', value)
      }
    }
  }
}
</script>
