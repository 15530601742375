<template>
  <div>
    <div class="columns">
      <div class="column is-one-fifth">
        <p class="has-text-weight-bold is-size-6 mb-sm">Sync Variants by Vendor</p>
        <p class="has-text-grey is-size-7">Attempt to sync variants between Shopify and {{ app.platform }} using a vendor name.</p>
      </div>
      <div class="column">
        <div class="has-background-white rounded-lg">
          <div class="pa-lg">
            <!-- Intro Text (Merchant) -->
            <div class="is-size-7 mb-md">
              <p>
                This utility searches your Shopify store for all items matching
                the supplied vendor name created within the last 30 days
                and attempts to sync them with variants in {{ app.platform }}.
                Please enter the vendor name EXACTLY as it appears in Shopify.
              </p>
            </div>
            <!-- Form -->
            <form @submit.prevent="syncVariants" class="mb-lg">
              <p class="has-text-weight-bold mb-md">Shopify Vendor Name</p>
              <div class="columns">
                <div class="column">
                  <b-field>
                    <b-input type="text" v-model="vendorName" size="is-medium" ></b-input>
                  </b-field>
                </div>
                <div class="column is-narrow">
                  <b-button
                    type="is-primary"
                    native-type="submit"
                    size="is-medium"
                    :loading="processing"
                    :disabled="processing || !valid"
                  >Run Sync</b-button>
                </div>
              </div>
            </form>
            <!-- Job Result -->
            <div class="is-size-7">
              <div v-if="processing">
                <p class="has-text-grey-light is-italic">Processing request...</p>
              </div>
              <div v-else-if="errorMessage !== null">
                <p class="has-text-grey-light is-italic">{{ errorMessage }}</p>
              </div>
              <div v-else-if="!processing && job !== null">
                <span class="has-text-weight-bold has-text-success">Retry Scheduled!</span>
                <!-- Your input resulted in Job <a href="#" class="has-text-info" @click.prevent="inspectJob = job">#{{ job.id }}</a>.
                Please refer to the job status for more information. -->
              </div>
              <div v-else>
                <p class="has-text-grey-light is-italic">Click the 'Run Sync' to kickoff the sync process.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Inspect Job -->
    <b-modal
      v-if="(inspectJob !== null)"
      has-modal-card
      :active="(inspectJob !== null)"
      :can-cancel="['escape', 'x']"
      @close="inspectJob = null"
      ><JobModal :selectedJob="inspectJob" />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import JobModal from '@/components/Modals/Job/JobModal'
export default {
  components: {
    JobModal
  },
  computed: {
    ...mapGetters(['app', 'context']),
    endpoint () {
      return this.context.route + '/shopify/' + this.account.id + '/sync-products/'
    },
    valid () {
      return (this.vendorName !== null && this.vendorName.length >= 2)
    },
    json () {
      if (!this.vendorName) return null
      const json = {
        use_celery: true,
        vendor: this.vendorName
        // created_at_min: this.$dates().subtract(30, 'day').format('YYYY-MM-DD')
      }
      return json
    }
  },
  data () {
    return {
      vendorName: null,
      processing: false,
      job: null,
      inspectJob: null,
      errorMessage: null
    }
  },
  methods: {
    syncVariants () {
      this.processing = true
      this.job = null
      this.$http.put(this.endpoint, this.json).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Sync scheduled!' })
        this.errorMessage = null
        this.job = response.data
      }).catch(() => {
        this.$buefy.toast.open({ type: 'is-danger', message: 'Sync failed!' })
      }).finally(() => {
        this.processing = false
      })
    }
  },
  props: ['account']
}
</script>
