<template>
  <div class="section">
    <div class="container">
      <!-- Page Header -->
      <div class="mb-md">
        <div class="columns is-vcentered">
          <div class="column is-one-third">
            <breadcrumbs :history="[{ label: 'Settings', name: 'settings'}]" current="Inventory Settings" />
          </div>
          <div class="column is-one-third">
            <h1 class="has-text-centered is-size-4">Inventory Settings</h1>
          </div>
        </div>
      </div>
      <div>
        <div class="border-b pb-lg mb-lg">
          <InventoryPolicy />
        </div>
        <div class="pb-lg mb-lg">
          <InventoryLocations />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InventoryLocations from '../components/InventoryLocations'
import InventoryPolicy from '../components/InventoryPolicy'
export default {
  components: {
    InventoryLocations,
    InventoryPolicy
  }
}
</script>
