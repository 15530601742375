<template>
  <form @submit.prevent="save" autocomplete="off">
    <!-- Intro -->
    <div class="mb-lg">
      <p class="is-size-4 mb-md has-text-black has-text-centered">Inventory Location</p>
      <div class="columns is-centered">
        <div class="column is-three-quarters">
          <p class="mb-lg">
            In order to update inventory, Shopify requires that we associate
            each product with a location from your Shopify account.
          </p>
          <p class="">
            {{ app.platform }} recommends setting up a new, dedicated dropship location in
            order to keep your owned inventory separate from your dropship inventory. For more
            information on how to do that in Shopify, check the
            <a href="https://help.shopify.com/en/manual/locations/setting-up-your-locations#create-and-modify-locations"
              class="has-text-weight-bold"
              target="_blank"
            >Shopify Help Center</a>.
          </p>
        </div>
      </div>
    </div>
    <!-- Locations -->
    <div class="mb-xxl">
      <div class="columns is-centered">
        <div class="column is-three-quarters">
          <select-location :account="account" v-model="location" />
        </div>
      </div>
    </div>
    <!-- Buttons -->
    <div class="mb-xl">
      <div class="columns is-centered">
        <div class="column is-three-quarters-tablet is-two-thirds-widescreen">
          <div class="columns">
            <div class="column">
              <b-button
                type="is-light rounded-lg"
                size="is-large"
                 @click="back"
                 expanded
               ><b-icon icon="arrow-left"></b-icon>
              </b-button>
            </div>
            <div class="column">
              <b-button
                native-type="submit"
                type="is-primary rounded-lg"
                size="is-large"
                expanded
                :loading="saving"
                :disabled="!validated || saving"
              >Save & Continue</b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import SelectLocation from '../SelectLocation'
export default {
  components: {
    SelectLocation
  },
  data () {
    return {
      loading: true,
      saving: false,
      errors: {},
      location: null
    }
  },
  computed: {
    ...mapGetters(['app', 'context']),
    endpoint () {
      return this.context.route + '/platform-accounts/' + this.account.id + '/'
    },
    validated () {
      return this.location !== null
    },
    json () {
      if (this.location === null) return null
      const json = {
        credentials: [{
          credential: {
            code: 'location_id'
          },
          value: this.location.id
        }]
      }
      if (this.locationCredential !== null) {
        json.credentials[0].id = this.locationCredential.id
      }
      return json
    },
    locationCredential () {
      if (this.account.credentials.length === 0) return null
      let found = null
      this.account.credentials.forEach(credential => {
        Object.keys(credential).forEach(key => {
          if (key === 'location_id') {
            found = credential[key].values[0]
          }
        })
      })
      return found
    }
  },
  methods: {
    back () {
      this.$emit('change:step', 'inventory')
    },
    save () {
      this.saving = true
      this.$http.patch(this.endpoint, this.json).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Location saved!' })
        this.$emit('update:account')
        this.$emit('complete')
      }).finally(() => {
        this.saving = false
      })
    }
  },
  props: ['account']
}
</script>
