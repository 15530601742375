<template>
  <div class="py-md">
    <div>
      <span class="has-text-weight-semibold">Create a Ruleset</span>
      <p class="has-text-grey-light">Create one or more rulesets in settings. When done, return to this page to begin the next step. </p>
    </div>
    <!-- Fields -->
    <div class="mt-md">
      <!-- Path to settings template -->
      <router-link :to="{ name:'rulesets' }" class="has-text-info is-underlined">
        Settings / Rulesets
      </router-link>
    </div>
    <!-- Navigate -->
    <div class="py-lg">
      <div class="columns">
        <div class="column">
          <b-button
            type="is-light rounded-lg"
            expanded
            @click="goBack"
            ><b-icon icon="arrow-left"></b-icon>
          </b-button>
        </div>
        <div class="column">
          <b-button
            type="is-primary rounded-lg"
            expanded
            :loading="isUpdating"
            @click="goForward"
          >Save & Continue</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {
  },
  computed: {
    ...mapGetters(['app', 'context'])
  },
  data () {
    return {
      isUpdating: false,
      setupIsCompleted: false
    }
  },
  methods: {
    goBack () {
      this.$router.push({ name: 'retailer.onboarding.catalog-management' })
    },
    goForward () {
      this.updateTask()
    },
    updateTask () {
      const taskBody = (typeof this.task.info === 'object') ? { info: this.task.info } : { info: {} }
      taskBody.info.rulesets = true
      this.$emit('task:updated', taskBody, 'retailer.onboarding.proposal-departments')
    }
  },
  mounted () {
    if (this.task && this.task.info && this.task.info.rulesets) {
      this.setupIsCompleted = true
    }
  },
  props: {
    task: {
      type: Object,
      require: true
    },
    isUpdatingTask: {
      type: Boolean
    }
  },
  watch: {
    isUpdatingTask (value) {
      this.isUpdating = value
    }
  }
}
</script>
