<template>
  <div>
    <!-- Content -->
    <div class="">
      <div class="mb-lg">
        <p class="mb-md has-text-weight-bold has-text-centered">Question #2: Integration Plan</p>
        <p>
          {{ app.productName }} supports a number of different methods to integrate and
          trade inventory, orders, &amp; fulfillment data. Given the options
          below, which option do you think your organization will use or are you most interested
          in learning more about?
        </p>
      </div>
      <b-field>
        <b-radio v-model="integration" native-value="EDI">EDI (850, 856, 810, 855, 860, &amp; 846)</b-radio>
      </b-field>
      <b-field>
        <b-radio v-model="integration" native-value="ShipStation">ShipStation</b-radio>
      </b-field>
      <b-field>
        <b-radio v-model="integration" native-value="Shopify">Shopify</b-radio>
      </b-field>
      <b-field>
        <b-radio v-model="integration" native-value="WooCommerce">WooCommerce</b-radio>
      </b-field>
      <b-field>
        <b-radio v-model="integration" native-value="BigCommerce">BigCommerce</b-radio>
      </b-field>
      <b-field>
        <b-radio v-model="integration" native-value="Supplier Portal">Supplier Portal</b-radio>
      </b-field>
      <b-field>
        <b-radio v-model="integration" native-value="API">API</b-radio>
      </b-field>
      <b-field>
        <b-radio v-model="integration" native-value="Do Not Know">We don't know yet or we want to learn more</b-radio>
      </b-field>
      <b-field>
        <b-radio v-model="integration" native-value="Other">Other</b-radio>
      </b-field>
      <div v-if="integration === 'Other'">
        <b-field label="Please describe...">
          <b-input
            type="text"
            v-model="other"
            size="is-medium"
          ></b-input>
        </b-field>
      </div>
    </div>
    <!-- Navigate -->
    <div class="py-lg">
      <div class="columns is-centered">
        <div class="column">
          <b-button
            type="is-light rounded-lg"
            size="is-medium"
            @click="goBack"
            expanded
            ><b-icon icon="arrow-left"></b-icon>
          </b-button>
        </div>
        <div class="column">
          <b-button
            type="is-primary rounded-lg"
            size="is-medium"
            expanded
            :disabled="!ready || saving"
            :loading="saving"
            @click="goForward"
          >Continue</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['app', 'context']),
    body () {
      const body = {
        metadata: [{
          name: 'ob_integration_plan',
          display_name: 'Integration Plan',
          type: 'string',
          value: this.integration
        }]
      }
      if (this.integration === 'Other' && this.other) {
        body.metadata[0].value = this.integration + ': ' + this.other.substring(0, 24)
      }
      return body
    },
    ready () {
      if (this.integration && this.integration !== 'Other') {
        return true
      }
      if (this.integration === 'Other' && this.other) {
        return true
      }
      return false
    }
  },
  data () {
    return {
      saving: false,
      integration: null,
      other: null
    }
  },
  methods: {
    goBack () {
      this.$emit('navigate', 'Experience')
    },
    goForward () {
      this.saving = true
      const endpoint = this.context.route + '/'
      this.$http.patch(endpoint, this.body).then(() => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Thank you!' })
        this.$emit('metadata:saved')
        this.$emit('navigate', 'Call')
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    if ('ob_integration_plan' in this.brand.metadata) {
      const value = this.brand.metadata.ob_integration_plan.values[0].value
      if (value.includes('Other: ')) {
        this.integration = 'Other'
        this.other = value.split('Other: ')[1]
      } else {
        this.integration = this.brand.metadata.ob_integration_plan.values[0].value
      }
    }
  },
  props: {
    connection: {
      type: Object,
      required: true
    },
    task: {
      type: Object,
      required: true
    },
    brand: {
      type: Object,
      required: true
    }
  }
}
</script>
