<template>
  <div class="section">
    <div class="container">
      <!-- Page Header -->
      <div class="columns is-vcentered">
        <div class="column is-one-third">
          <breadcrumbs :history="breadcrumbs.history" :current="breadcrumbs.current"  />
        </div>
        <div class="column is-one-third">
          <h1 class="has-text-centered is-size-4">Edit Ruleset</h1>
        </div>
        <div class="column is-one-third">
          <div class="is-pulled-right px-md">
            <b-button
              type="is-primary rounded-lg"
              :loading="loading"
              @click.prevent="showConfirmModal=true"
            >Delete
            </b-button>
          </div>
        </div>
      </div>
      <!-- Content -->
      <!-- Ruleset Info -->
      <div class="pb-lg border-b mb-lg">
        <div class="columns">
          <div class="column is-one-fifth">
            <p class="has-text-weight-bold is-size-6 mb-sm">Ruleset Settings</p>
            <p class="has-text-grey is-size-7">
              Configure basic settings for this ruleset.
            </p>
          </div>
          <div class="column">
            <RulesetSettings :loading="loading" :ruleset="ruleset"/>
          </div>
        </div>
      </div>
      <!-- Step 2: Rule groups List -->
      <div class="columns">
        <div class="column is-one-fifth">
          <p class="has-text-weight-bold is-size-6 mb-sm">Rule Groups</p>
          <p class="has-text-grey is-size-7">
            Rule groups collect similar attributes into logical groups
            for better organization.
          </p>
        </div>
        <div class="column">
          <RuleGroups :loading="loading" :ruleset="ruleset"/>
        </div>
      </div>
    </div>
    <!-- Delete confirmation Modal -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showConfirmModal"
      :can-cancel="['x']"
      ><ConfirmationModal
        :label="'Confirmation'"
        :reason="`Are you sure? Do you really want to delete ruleset? This will also delete all the respective rule groups and rules in this ruleset.`"
        @modal:confirm="deleteRuleset"
        @modal:close="showConfirmModal = false"
        />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ConfirmationModal from '@/components/Modals/ConfirmationModal'
import RulesetSettings from '../components/Details/RulesetSettings'
import RuleGroups from '../components/Details/RuleGroups'
export default {
  components: {
    ConfirmationModal,
    RulesetSettings,
    RuleGroups
  },
  computed: {
    ...mapGetters(['app', 'api', 'context']),
    breadcrumbs () {
      return {
        history: [
          { label: 'Settings', name: 'settings' },
          { label: 'Product Rulesets', name: 'rulesets' }
        ],
        current: 'Edit'
      }
    },
    endpoint () {
      return this.context.route + '/compatibility/rulesets/' + this.$route.params.id + '/'
    }
  },
  data () {
    return {
      saving: false,
      ruleset: null,
      loading: true,
      showConfirmModal: false
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint).then(({ data }) => {
        this.ruleset = data
      }).finally(() => {
        this.loading = false
      })
    },
    deleteRuleset () {
      this.saving = true
      this.$http.delete(this.endpoint).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Ruleset deleted successfully' })
        this.$router.push({ name: 'rulesets' })
      }).catch(error => {
        let errorMessage = 'Please try again.'
        if (error.response && error.response.status === 400) {
          errorMessage = error.response.data.detail
        }
        this.$buefy.toast.open({ type: 'is-danger', message: errorMessage })
      }).finally(() => {
        this.saving = false
        this.showConfirmModal = false
      })
    }
  },
  mounted () {
    this.load()
  }
}
</script>
