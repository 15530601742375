<template>
  <form @submit.prevent="save">
    <!-- Intro -->
    <div class="">
      <p class="has-text-centered is-size-4 mb-lg has-text-black">Product Catalog Setup</p>
      <div class="px-lg pb-lg">
        <div class="columns">
          <div class="column is-three-fifths">
            <p class="mb-lg">
              As a supplier on {{ app.platform }}, you will build a brand profile
              page that displays your products to prospective
              merchant partners. If a merchant wants to sell one (or more) of
              your poducts, they must ask your permission first. If you approve
              the merchant request,
              they can sell the product, but only at or above the price you control!
            </p>
            <p class="mb-lg">
              To populate your profile, {{ app.platform }} can import your
              products directly from your Shopify store. We just need to know
              which products you want us to import and how to set the initial
              costs and pricing of your products.
            </p>
            <p class="mb-sm">
              Please click 'Next' to continue.
            </p>
          </div>
          <div class="column">
            <div class="has-text-centered">
              <figure class="image">
                <img class="border" src="https://res.cloudinary.com/revcascade/image/upload/v1588199089/static/profile-sample-supplier.png" alt="">
              </figure>
              <p class="has-text-grey py-sm is-size-7">Profile Outline</p>
            </div>
          </div>
        </div>

      </div>
    </div>
    <!-- Buttons -->
    <div class="mb-xl">
      <div class="columns is-centered">
        <div class="column is-three-quarters-tablet is-two-thirds-widescreen">
          <div class="columns">
            <div class="column">
              <b-button type="is-light rounded-lg" size="is-large" @click="back" expanded>
                <b-icon icon="arrow-left"></b-icon>
              </b-button>
            </div>
            <div class="column">
              <b-button
                native-type="submit"
                type="is-primary rounded-lg"
                size="is-large"
                expanded
                :loading="saving"
                :disabled="!validated || saving"
              >Next</b-button>
            </div>
          </div>
        </div>
      </div>
      <p v-if="'detail' in errors" class="has-text-danger">{{ error.detail }}</p>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      saving: false,
      errors: {}
    }
  },
  computed: {
    ...mapGetters(['app']),
    validated () {
      return true
    }
  },
  methods: {
    save () {
      this.$emit('change:step', 'Import')
    },
    back () {
      this.$router.push({ name: 'shopify.onboard.inventory' })
    }
  },
  props: ['account']
}
</script>
