<template>
  <div class="section">
    <div class="container">
      <!-- Page Header -->
      <div class="mb-md">
        <div class="columns is-vcentered">
          <div class="column is-one-third">
            <breadcrumbs :history="[{ label: 'Settings', name: 'settings'}]" current="EDI Settings" />
          </div>
          <div class="column is-one-third">
            <h1 class="has-text-centered is-size-4">EDI Settings</h1>
          </div>
        </div>
      </div>
      <div>
        <div class="pb-lg mb-lg">
          <EdiAccount />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EdiAccount from '../components/Edi/EdiAccount'
export default {
  components: {
    EdiAccount
  }
}
</script>
