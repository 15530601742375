<template>
  <div>
    <div v-for="notification in merchantNotifications" :key="notification.action" class="mb-md">
      <PushNotification
        :notifications="pushNotifications"
        :action="notification.action"
        :title="notification.title"
        :text="notification.text" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PushNotification from '@/components/Models/Notifications/PushNotification'

export default {
  components: {
    PushNotification
  },
  computed: {
    ...mapGetters(['context']),
    merchantNotifications () {
      const notifications = []
      this.availableNotifications.forEach(notification => {
        if (notification.context.includes('retailer')) {
          notifications.push(notification)
        }
      })
      return notifications
    }
  },
  data () {
    return {
      availableNotifications: [{
        action: 'Order Canceled',
        title: 'Order Canceled',
        text: 'If you want to be notified when a cancellation request is received, the platform will send an email notification to the email address or distribution list specified below:',
        context: ['retailer']
      }, {
        action: 'Order Backorder Date Updated',
        title: 'Order Backordered',
        text: 'If you want to be notified when a supplier marks an order as backordered',
        context: ['retailer']
      }, {
        action: 'Order Memo Created',
        title: 'Message Received',
        text: 'If you want to be notified when a new message is received on one of your orders, the platform will send an email notification to the email address or distribution list specified below:',
        context: ['retailer']
      }, {
        action: 'Order Returned',
        title: 'Return Received',
        text: 'If you want to be notified when a new RMA is created on one of your orders, the platform will send an email notification to the email address or distribution list specified below:',
        context: ['retailer']
      }, {
        action: 'RMA Completed',
        title: 'Return Approved/Rejected',
        text: 'If you want to be notified when one of your RMAs is approved or rejected, the platform will send an email notification to the email address or distribution list specified below:',
        context: ['retailer']
      }]
    }
  },
  methods: {

  },
  mounted () {

  },
  props: {
    pushNotifications: {
      type: Array,
      required: true
    }
  }
}
</script>
