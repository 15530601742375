import Request from '../Request'
export default class AcknowledgeCancel extends Request {
  constructor (config) {
    super()
    this.method = 'put'
    this.context = config.context
    this.route = config.route
    this.cancel = config.cancel
    this.validate()
  }

  id () {
    return this.cancel.id
  }

  label () {
    return this.cancel.id
  }

  validate () {
    if (this.cancel.acknowledged_at !== null) {
      this.isValid = false
      this.status = 'Skip'
      this.errorMessage = 'Cancellation has already been acknowledged.'
    } else if (this.context.isMerchant() && this.cancel.canceled_by === 'retailer') {
      this.isValid = false
      this.status = 'Skip'
      this.errorMessage = 'Cancellation was created by your organization.'
    } else if (this.context.isSupplier() && this.cancel.canceled_by === 'brand') {
      this.isValid = false
      this.status = 'Skip'
      this.errorMessage = 'Cancellation was created by your organization.'
    } else {
      this.isValid = true
    }
  }

  json () {
    return null
  }

  endpoint () {
    return this.route + '/cancels/' + this.id() + '/acknowledge/'
  }

  errorHandler (errors) {
    return errors[0]
  }
}
