<template>
  <div>
    <div class="section">
      <div class="container">
        <!-- Page Header -->
        <div class="mb-lg">
          <div class="columns is-vcentered">
            <div class="column is-one-third">
              <breadcrumbs :history="[{ label: 'Connections', name: 'connections'}]" current="Applications" />
            </div>
            <div class="column is-one-third">
              <h1 class="has-text-centered is-size-4">Applications</h1>
            </div>
          </div>
        </div>
        <!-- Filters -->
        <div class="has-background-white rounded-lg pa-md mb-xxs">
          <div class="columns">
            <div class="column">
              <InvitationFilters @filter="filter" :applied-filters="filters" />
            </div>
            <div class="column is-narrow">
              <b-select v-model="orderBy" expanded @input="sort" size="is-medium">
                <option value="id">Oldest</option>
                <option value="-id">Most Recent</option>
              </b-select>
            </div>
          </div>
        </div>
        <!-- Content -->
        <div>
          <div>
            <b-table
              :data="results"
              :mobile-cards="false"
              :loading="loading"
              hoverable
              class="rounded-lg is-size-7">
              <template>
                <!-- ID -->
                <b-table-column
                  label="ID"
                  cell-class="nowrap"
                  width="64"
                  v-slot="props">
                  {{ props.row.id }}
                </b-table-column>
                <!-- Company -->
                <b-table-column
                  label="Company"
                  cell-class="nowrap"
                  width="200"
                  v-slot="props">
                  {{ props.row.company_name }}
                </b-table-column>
                <!-- Company Website -->
                <b-table-column
                  label="Website"
                  cell-class="nowrap"
                  width="200"
                  v-slot="props">
                  <a :href="props.row.company_website" target="_blank" class="has-text-info has-text-weight-bold">Link</a>
                </b-table-column>
                <!-- Contact -->
                <b-table-column
                  label="Contact"
                  cell-class="nowrap"
                  v-slot="props">
                  {{ props.row.first_name }} {{ props.row.last_name }}
                </b-table-column>
                <!-- Sent -->
                <b-table-column
                  label="Created"
                  cell-class="nowrap"
                  width="128"
                  centered
                  v-slot="props">
                  {{ $dates.utc(props.row.created_at).local().format('MMM D, YYYY') }}
                </b-table-column>
                <!-- Status -->
                <b-table-column
                  label="Status"
                  cell-class="nowrap"
                  width="128"
                  centered
                  v-slot="props">
                  <p class="has-text-weight-bold">
                    <span v-if="props.row.status === 'accepted'" class="has-text-success">{{ $utils.upperFirst(props.row.status) }}</span>
                    <span v-else class="has-text-grey">{{ $utils.upperFirst(props.row.status) }}</span>
                  </p>
                </b-table-column>
                <!-- Actions -->
                <b-table-column
                  label=""
                  cell-class="nowrap"
                  width="128"
                  centered
                  v-slot="props">
                  <b-button v-if="props.row.status === 'New'" size="is-small" class="mr-md">Approve</b-button>
                  <b-button v-if="props.row.status === 'New'" size="is-small">Decline</b-button>
                </b-table-column>
              </template>
              <template #empty>
                <div class="py-xxl has-text-grey has-text-centered">
                  {{ (loading) ? "Loading data..." : 'No invitations found!' }}
                </div>
              </template>
            </b-table>
          </div>
          <div class="mt-md">
            <b-pagination
              :total="total"
              :current.sync="currentPage"
              :rounded="true"
              :per-page="perPage"
              @change="paginate">
            </b-pagination>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal: Invitation -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showInvitationModal"
      :can-cancel="['x']"
      ><InvitationModal @saved="load"/>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Filterable from '@/mixins/Filterable'
import InvitationFilters from '../components/List/InvitationFilters'
import InvitationModal from '@/components/Models/Connection/InvitationModal'

export default {
  components: {
    InvitationFilters,
    InvitationModal
  },
  data () {
    return {
      key: 'applications',
      orderBy: '-id',
      showInvitationModal: false
    }
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/connection-invites/'
    }
  },
  methods: {
    fake () {
      this.results = [{
        id: 1000,
        company_name: 'Demo Vendor Application',
        company_website: 'https://example.org',
        first_name: 'Kyle',
        last_name: 'Stainer',
        created_at: '2021-11-29 12:10:00',
        status: 'New'
      }]
    }
  },
  mixins: [Filterable],
  mounted () {
    // this.load()
    this.fake()
    this.loading = false
  }
}
</script>
