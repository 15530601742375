<template>
  <div>
    <form>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Export Shopify Template</p>
        </header>
        <section class="modal-card-body">
          <div class="mb-lg">
            <b-message type="is-info">{{ app.platform }} can export product data into
              the Shopify Import Template. Please configure your export below.
              If certain data fields are blank, you may need to work with
              your supplier or complete the missing entries before attempting
              to import into Shopify.
            </b-message>
          </div>
          <!-- Use Brand SKY -->
          <div class="mb-lg">
            <p class="has-text-weight-bold mb-sm">What value should we export into the 'Variant SKU' column?</p>
            <div>
              <b-radio v-model="useSupplierSku" native-value="1">Use the supplier's SKU</b-radio>
            </div>
            <div >
              <b-radio v-model="useSupplierSku" native-value="0">Leave the Variant SKU column blank</b-radio>
            </div>
          </div>
          <!-- Use Brand Category -->
          <div class="mb-lg">
            <p class="has-text-weight-bold mb-sm">What value should we export into the 'Product Type' column?</p>
            <div>
              <b-radio v-model="useSupplierCategory" native-value="1">Use the supplier's category into the 'Product Type' column.</b-radio>
            </div>
            <div >
              <b-radio v-model="useSupplierCategory" native-value="0">Leave the 'Product Type' column blank</b-radio>
            </div>
          </div>
          <!-- Options -->
          <div class="mb-lg">
            <!-- Option 1 -->
            <div class="mb-md">
              <p class="has-text-weight-bold mb-sm">Which attribute should be mapped to Option 1?</p>
              <b-select placeholder="Select an attribute or leave blank" v-model="option1" expanded>
                <option
                  v-for="attribute in attributes"
                  :value="attribute.code"
                  :key="attribute.code">
                  {{ attribute.name }}
                </option>
              </b-select>
            </div>
            <!-- Option 2 -->
            <div class="mb-md">
              <p class="has-text-weight-bold mb-sm">Which attribute should be mapped to Option 2?</p>
              <b-select
                placeholder="Select an attribute or leave blank"
                v-model="option2"
                :disabled="option1 === null"
                expanded>
                <option
                  v-for="attribute in attributes"
                  :value="attribute.code"
                  :key="attribute.code">
                  {{ attribute.name }}
                </option>
              </b-select>
            </div>
            <!-- Option 2 -->
            <div class="mb-md">
              <p class="has-text-weight-bold mb-sm">Which attribute should be mapped to Option 3?</p>
              <b-select
                placeholder="Select an attribute or leave blank"
                v-model="option3"
                :disabled="option1 === null || option2 === null"
                expanded>
                <option
                  v-for="attribute in attributes"
                  :value="attribute.code"
                  :key="attribute.code">
                  {{ attribute.name }}
                </option>
              </b-select>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot space-between">
          <b-button @click="$parent.close()">Close</b-button>
          <b-button
            type="is-primary"
            @click="scheduleExport()"
            :disabled="exporting"
            :loading="exporting"
          >Schedule Export</b-button>
        </footer>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  data () {
    return {
      exporting: false,
      useSupplierSku: 0,
      useSupplierCategory: 0,
      attributes: [{
        code: 'color',
        name: 'Color'
      }, {
        code: 'size',
        name: 'Size'
      }, {
        code: 'style',
        name: 'Style'
      }],
      option1: null,
      option2: null,
      option3: null
    }
  },
  computed: {
    ...mapGetters(['app', 'api', 'context']),
    shopifyQuery () {
      const params = {
        use_brand_sku: (parseInt(this.useSupplierSku)),
        use_brand_category: (parseInt(this.useSupplierCategory))
      }
      // build options string
      let options = ''
      if (this.option1) options += this.option1
      if (this.option2) options += ',' + this.option2
      if (this.option3) options += ',' + this.option3
      if (options) {
        params.options = options
      }
      return {
        params: {
          ...this.filterQuery.params,
          ...params
        }
      }
    },
    endpoint () {
      return this.context.route + '/products/shopify-export/'
    }
    // for autocomplete
    // filteredAttributes() {
    //   return this.attributes.filter(option => {
    //     return (option.name.toString().toLowerCase().indexOf(this.option1.toLowerCase()) >= 0)
    //   })
    // }
  },
  methods: {
    scheduleExport () {
      this.exporting = true
      this.$http.get(this.endpoint, this.shopifyQuery).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Export scheduled. Please allow 5-10 minutes for delivery!' })
        this.reset()
      }).finally(() => {
        this.exporting = false
      })
    },
    reset () {
      this.useSupplierSku = 0
      this.useSupplierCategory = 0
      this.option1 = null
      this.option2 = null
      this.option3 = null
      this.$parent.close()
    }
  },
  mounted () {

  },
  props: ['filterQuery']
}
</script>
