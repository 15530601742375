<template>
  <form @submit.prevent="save">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Add Shipping Account</p>
      </header>
      <section class="modal-card-body">
        <div>
          <!-- Shipping Methods -->
          <div>
            <label class="label">Allowed Shipping Methods</label>
            <div v-if="carrier !== null">
              <b-table
                :data="allowedMethods"
                :checked-rows.sync="selected"
                :mobile-cards="false"
                :loading="loadingCarriers"
                hoverable
                checkable
                narrowed
                checkbox-position="left"
                class="is-size-7">
                <!-- Shipping Method Name -->
                <b-table-column
                  label="Name"
                  header-class="nowrap"
                  cell-class="nowrap"
                  v-slot="props">
                  {{ props.row.name }}
                </b-table-column>
                <!-- Generic Shipping Method -->
                <b-table-column
                  label=""
                  header-class="nowrap"
                  cell-class="nowrap has-text-grey-light"
                  numeric
                  width="64"
                  v-slot="props">
                  <span class="is-italic">{{ props.row.generic_shipping_method.name }}</span>
                </b-table-column>
                <template #empty>
                  <div class="py-xxl has-text-grey has-text-centered">
                    {{ (loadingCarriers) ? "Loading data..." : 'No shipping methods found!' }}
                  </div>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot space-between">
        <button class="button" type="button" @click="$parent.close()">Close</button>
        <b-button
          type="is-primary"
          native-type="submit"
          :loading="saving"
          :disabled="saving || !ready">
          Save Shipping Account
        </b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  computed: {
    ...mapGetters(['api', 'context']),
    endpoint () {
      return this.context.route + '/shippingaccounts/'
    },
    json () {
      const json = {
        nickname: this.nickname,
        carrier_id: this.carrier.id,
        api_token: this.apiKeys.easypost_production_api_key,
        identifier: this.carrierAccount.account.id,
        is_first_party_bill: true,
        // bill_third_party_account: this.carrierAccount.credentials.accountNumber,
        // bill_third_party_country: this.carrierAccount.credentials.billingCountry,
        // bill_third_party_postal_code: this.carrierAccount.credentials.billingPostalCode,
        shipping_account_shipping_methods: []
      }
      this.selected.forEach(method => {
        json.shipping_account_shipping_methods.push({
          shipping_method: { id: method.id }
        })
      })
      return json
    },
    ready () {
      return this.selected.length > 0
    },
    allowedMethods () {
      if (this.shippingMethods.length === 0) return []
      return this.shippingMethods.filter((method) => {
        return method.generic_shipping_method.name === 'Ground' ||
          method.generic_shipping_method.name === 'Expedited'
      })
    }
  },
  data () {
    return {
      saving: false,
      errors: {},
      loadingCarriers: true,
      shippingMethods: [],
      selected: []
    }
  },
  methods: {
    save () {
      this.saving = true
      this.$http.post(this.endpoint, this.json).then(response => {
        this.$emit('shipping:created')
      }).finally(() => {
        this.saving = false
      })
    },
    loadCarriers () {
      this.loadingCarriers = true
      const endpoint = this.api.baseUrl + '/' + this.api.version + '/carriers/'
      const query = {
        params: {
          is_common: 1,
          pagination: 0,
          search: this.carrier.code
        }
      }
      this.$http.get(endpoint, query).then(response => {
        if (response.data.count === 1) {
          this.shippingMethods = response.data.results[0].shipping_methods
        }
      }).finally(() => {
        this.loadingCarriers = false
      })
    }
  },
  mounted () {
    setTimeout(() => {
      this.loadCarriers()
    }, 500)
  },
  props: ['nickname', 'carrier', 'apiKeys', 'carrierAccount']
}
</script>
