<template>
  <div class="is-size-6 is-flex is-justify-content-center">
    <b-icon
      :icon="this.ordersChange > 0 ? 'menu-up' : 'menu-down'"
      :class="presenter.changeClass">
    </b-icon>
    <span :class="presenter.changeClass">
      {{ presenter.upDownText }}
      {{ $numbers(presenter.ordersChange).format({thousandSeparated: true, mantissa: 0}) }}
    </span>
  </div>
</template>

<script>
export default {
  computed: {
    presenter () {
      const ordersChange = this.ordersChange
      const presenter = {
        ordersChange: ordersChange,
        upDownText: '',
        changeClass: null
      }

      if (ordersChange > 0) {
        presenter.changeClass = 'has-text-success'
        presenter.upDownText = 'Up'
      } else if (ordersChange < 0) {
        presenter.changeClass = 'has-text-danger'
        presenter.upDownText = 'Down'
        presenter.ordersChange = `${Math.abs(ordersChange)}`
      } else {
        presenter.changeClass = 'has-text-grey-lighter'
        presenter.ordersChange = '0'
      }
      return presenter
    }
  },
  props: ['ordersChange']
}
</script>
