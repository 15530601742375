<template>
  <div>
    <div class="columns is-multiline">
      <div class="column is-half">
        <b-field label="Account Holder Name">
          <b-input
            type="text"
            v-model="accountHolderName"
            size="is-medium"
            @input="announce"
          ></b-input>
        </b-field>
      </div>
      <div class="column is-half">
        <b-field label="Street Address">
          <b-input
            type="text"
            v-model="addressStreet"
            size="is-medium"
            @input="announce"
          ></b-input>
        </b-field>
      </div>
      <div class="column is-half">
        <b-field label="City">
          <b-input
            type="text"
            v-model="addressCity"
            size="is-medium"
            @input="announce"
          ></b-input>
        </b-field>
      </div>
      <div class="column is-half">
        <b-field label="State">
          <b-input
            type="text"
            v-model="addressState"
            size="is-medium"
            @input="announce"
          ></b-input>
        </b-field>
      </div>
      <div class="column is-half">
        <b-field label="Zip Code">
          <b-input
            type="text"
            v-model="addressZip"
            size="is-medium"
            @input="announce"
          ></b-input>
        </b-field>
      </div>
      <div class="column is-half">
        <b-field label="Phone">
          <b-input
            type="text"
            v-model="phone"
            size="is-medium"
            @input="announce"
          ></b-input>
        </b-field>
      </div>
      <div class="column is-half">
        <b-field label="Email">
          <b-input
            type="text"
            v-model="email"
            size="is-medium"
            @input="announce"
          ></b-input>
        </b-field>
      </div>
      <div class="column is-half">
        <b-field label="Shipper Identifier (Optional)">
          <b-input
            type="text"
            v-model="shipperIdentifier"
            size="is-medium"
            @input="announce"
          ></b-input>
        </b-field>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    ready () {
      return (
        this.accountHolderName &&
        this.addressStreet &&
        this.addressCity &&
        this.addressState &&
        this.addressZip &&
        this.phone &&
        this.email
      )
    },
    json () {
      return {
        carrier: {
          code: 'USPS'
        },
        credentials: {
          company_name: this.accountHolderName,
          address_street: this.addressStreet,
          address_city: this.addressCity,
          address_state: this.addressState,
          address_zip: this.addressZip,
          phone: this.phone,
          email: this.email,
          shipper_id: this.shipperIdentifier
        },
        test_credentials: {}
      }
    }
  },
  data () {
    return {
      accountHolderName: null,
      addressStreet: null,
      addressCity: null,
      addressState: null,
      addressZip: 'US',
      phone: null,
      email: null,
      shipperIdentifier: null
    }
  },
  methods: {
    announce () {
      if (this.ready) {
        this.$emit('input', this.json)
      }
    }
  }
}
</script>
