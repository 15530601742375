import Index from './views/Index'
import Months from './views/Months'
import Detail from './views/Detail'
import Summary from './views/Summary'
import Supplier from './views/Supplier'

export default [{
  path: '/finance',
  name: 'finance',
  component: Index
}, {
  path: '/finance/months',
  name: 'finance.months',
  component: Months
}, {
  path: '/finance/:year/:month/:day?',
  name: 'finance.detail',
  component: Detail,
  children: [{
    path: 'summary',
    name: 'finance.detail.summary',
    component: Summary
  }, {
    path: 'supplier/:id',
    name: 'finance.detail.supplier',
    component: Supplier
  }]
}]
