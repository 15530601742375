<template>
  <div>
    <div class="columns">
      <div class="column is-one-fifth">
        <p class="has-text-weight-bold is-size-6 mb-sm">Store Details</p>
        <p class="has-text-grey is-size-7">Information about the Big Commerce store connected with your Marketplace account.</p>
      </div>
      <div class="column">
        <div class="has-background-white rounded-lg">
          <div class="pa-lg">
            <div class="mb-md">
              <p class="has-text-weight-bold">Store URL</p>
              <p>{{ url }}</p>
            </div>
            <div>
              <p class="has-text-weight-bold">Platform Account ID</p>
              <p>{{ platformAccountId }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    platformAccountId () {
      let platformAccountId = '-'
      if (this.account && this.account.id) {
        platformAccountId = this.account.id
      }
      return platformAccountId
    },
    url () {
      let url = '-'
      const credentials = this.account.credentials.filter(el => el.bigcommerce_shop_hash)
      if (credentials && credentials.length) {
        const hash = credentials[0].bigcommerce_shop_hash.values &&
                    credentials[0].bigcommerce_shop_hash.values.length &&
                    credentials[0].bigcommerce_shop_hash.values[0].value
        if (hash) {
          url = `https://store-${hash}.mybigcommerce.com`
        }
      }
      return url
    }
  },
  props: ['account']
}
</script>
