<template>
  <div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Inspect Item</p>
      </header>
      <section class="modal-card-body">
        <!-- Loaded -->
        <div v-if="!loading && variant !== null">
          <!-- Ruleset Selector -->
          <div class="border-b pb-md mb-md">
            <b-select v-model="ruleset" expanded>
              <option v-for="ruleset in variant.ruleset_variants" :value="ruleset" :key="ruleset.id">
                {{ ruleset.ruleset.name }} ({{ $utils.upperFirst(ruleset.status) }})
              </option>
            </b-select>
          </div>
          <!-- Ruleset Display -->
          <div v-if="ruleset !== null">
            <!-- IDs -->
            <div v-if="auth.isStaff" class="mb-lg">
              <p class="has-text-weight-bold border-b has-border-lg mb-xs">Proposal Item Attributes</p>
              <table class="table is-fullwidth is-narrow is-striped is-size-7">
                <tbody>
                  <tr>
                    <th>Variant ID</th>
                    <td class="has-text-right">{{ variant.id }}</td>
                    <td class="has-text-centered" style="width:16px">
                      <b-icon icon="check" size="is-small" style="width:16px" custom-class="has-text-success" />
                    </td>
                  </tr>
                  <tr>
                    <th>Proposal Variant ID</th>
                    <td class="has-text-right">{{ variant.proposal_variant.id }}</td>
                    <td class="has-text-centered" style="width:16px">
                      <b-icon icon="check" size="is-small" style="width:16px" custom-class="has-text-success" />
                    </td>
                  </tr>
                  <tr v-if="variant.active_permit">
                    <th>Active Permit ID</th>
                    <td class="has-text-right">
                      {{ variant.active_permit.id }}
                      <span v-if="variant.permits.length > 2">({{ variant.permits.length }} permits)</span>
                    </td>
                    <td class="has-text-centered" style="width:16px">
                      <b-icon icon="check" size="is-small" style="width:16px" custom-class="has-text-success" />
                    </td>
                  </tr>
                  <tr v-if="proposalVariantCost !== null">
                    <th>Proposal Variant Retailer Cost</th>
                    <td class="has-text-right">
                      <CurrencyLocale :value="parseFloat(proposalVariantCost.value)" :currency="proposalVariantCost.currency"/>
                    </td>
                    <td class="has-text-centered" style="width:16px">
                      <b-icon icon="check" size="is-small" style="width:16px" custom-class="has-text-success" />
                    </td>
                  </tr>
                  <tr v-if="proposalVariantPrice !== null">
                    <th>Proposal Variant Retailer Price</th>
                    <td class="has-text-right">
                      <CurrencyLocale :value="parseFloat(proposalVariantPrice.value)" :currency="proposalVariantPrice.currency"/>
                    </td>
                    <td class="has-text-centered" style="width:16px">
                      <b-icon icon="check" size="is-small" style="width:16px" custom-class="has-text-success" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- Group Loop -->
            <div v-for="group in ruleset.ruleset.rule_groups" :key="group.id" class="mb-md">
              <p class="has-text-weight-bold border-b has-border-lg mb-xs">{{ group.name }}</p>
              <!-- Attribute Loop -->
              <table class="table is-fullwidth is-narrow is-striped is-size-7">
                <tbody>
                  <RuleReader
                    v-for="rule in group.rules"
                    :key="rule.id"
                    :rule="rule"
                    :attribute="(rule.attribute.code in variant.attributes) ? variant.attributes[rule.attribute.code] : null"
                    :result="(rule.attribute.code in rulesetResults) ? rulesetResults[rule.attribute.code] : null"
                  />
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!-- Loading -->
        <div v-else>
          <loading message="Loading item..." />
        </div>
      </section>
      <footer class="modal-card-foot">
        <button class="button" type="button" @click="$parent.close()">Close</button>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import RuleReader from './RuleReader'

export default {
  components: {
    RuleReader
  },
  data () {
    return {
      loading: true,
      variant: null,
      ruleset: null
    }
  },
  computed: {
    ...mapGetters(['auth', 'context']),
    endpoint () {
      return this.context.route + '/proposals/' + this.proposal.id + '/variants/' + this.selectedVariant.id + '/'
    },
    query () {
      return {
        params: {
          editor: 1
        }
      }
    },
    rulesets () {
      if (this.variant === null) return []
      return this.variant.ruleset_variants
    },
    rulesetResults () {
      if (this.ruleset === null) return {}
      const results = {}
      this.ruleset.results.forEach(result => {
        results[result.rule.attribute.code] = result
      })
      return results
    },
    proposalVariantCost () {
      let cost = null
      if (this.variant.proposal_variant.attributes && this.variant.proposal_variant.attributes.retailer_cost) {
        let retailerCost = this.variant.proposal_variant.attributes.retailer_cost.values[0]
        if (this.variant.proposal_variant.attributes.retailer_cost.values.length > 1) {
          const values = this.variant.proposal_variant.attributes.retailer_cost.values
          // fetch the last created value
          retailerCost = values.reduce((a, b) => a.created_at > b.created_at ? a : b)
        }
        cost = {
          value: retailerCost.value,
          currency: retailerCost
        }
      }
      return cost
    },
    proposalVariantPrice () {
      let price = null
      if (this.variant.proposal_variant.attributes && this.variant.proposal_variant.attributes.retailer_price) {
        price = {
          value: this.variant.proposal_variant.attributes.retailer_price.values[0].value,
          currency: this.variant.proposal_variant.attributes.retailer_price.values[0]
        }
      }
      return price
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint, this.query).then(response => {
        this.variant = response.data
        if (response.data.ruleset_variants.length > 0) {
          this.ruleset = response.data.ruleset_variants[0]
        }
        this.loading = false
      })
    }
  },
  mounted () {
    this.load()
  },
  props: ['proposal', 'selectedVariant']
}
</script>
