<template>
  <div>
    <!-- Shop Config Loaded -->
    <div v-if="!loading && shop !== null">
      <transition name="fade" mode="out-in">
        <component :is="step" :shop="shop" @change:step="goto" @complete="complete" ></component>
      </transition>
    </div>
    <!-- Shop Config Loading -->
    <div v-else>
      <loading message="Communicating with Shopify..." />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TermsOfService from '../../components/TermsOfService'
import Billing from '../../components/Account/Billing'
import Partner from '../../components/Account/Partner'
import User from '../../components/Account/User'

export default {
  components: {
    Billing,
    TermsOfService,
    Partner,
    User
  },
  data () {
    return {
      loading: true,
      shop: null,
      step: 'partner',
      errors: {}
    }
  },
  computed: {
    ...mapGetters(['api', 'assets', 'auth', 'context']),
    endpoint () {
      return this.context.route + '/shopify/' + this.account.id + '/shop-config/'
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint).then(response => {
        this.shop = response.data.shop
      }).finally(() => {
        this.loading = false
      })
    },
    goto (step) {
      this.step = step
    },
    complete () {
      this.$router.push({ name: 'shopify.onboard.plans' })
    }
  },
  mounted () {
    this.$emit('update:step', 0)
    this.load()
  },
  props: ['account']
}
</script>
