<template>
  <div>
    <div class="section">
      <div class="container">
        <!-- Page Header -->
        <div class="columns is-vcentered">
          <div class="column is-one-third">
            <breadcrumbs :history="[{ label: 'Integrations', name: 'settings.integrations'}]" current="Utilities" :hideBack="true" />
          </div>
          <div class="column is-one-third">
            <h1 class="has-text-centered is-size-4">BigCommerce Utilities</h1>
          </div>
        </div>
        <!-- Content -->
        <div>
          <!-- Sync Variants By Date -->
          <div class="mb-xl pb-xl">
            <SyncVariantsByDate :account="account" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SyncVariantsByDate from '../components/BigCommerceUtilities/SyncVariantsByDate'

export default {
  components: {
    SyncVariantsByDate
  },
  data () {
    return {
      loading: true,
      account: null
    }
  },
  computed: {
    ...mapGetters(['auth', 'context']),
    endpoint () {
      return this.context.route + '/platform-accounts/' + this.$route.params.id + '/'
    }
  },
  methods: {
    load (refresh = false) {
      this.loading = (!refresh)
      this.$http.get(this.endpoint).then(response => {
        this.account = response.data
      }).finally(() => {
        this.loading = false
      })
    }
  },
  mounted () {
    this.load()
  },
  props: []
}
</script>
