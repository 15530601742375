<template>
  <div>
    <b-tooltip v-if="showTooltip" :label="presenter.tooltip" dashed multilined position="is-left">
      {{ presenter.label }}
    </b-tooltip>
    <span v-else>{{ presenter.label }}</span>
  </div>
</template>

<script>
export default {
  computed: {
    revsharePct () {
      if (this.connection.connection_fees.net_price_method === 'revshare') {
        return parseFloat(this.connection.connection_fees.revshare_fraction) * 100
      }
      return null
    },
    commissionProfileName () {
      if (this.connection.connection_fees.net_price_method === null && this.connection.commission_profile) {
        return this.connection.commission_profile.name
      }
      return null
    },
    presenter () {
      const presenter = {}
      const commissionProfile = this.connection.commission_profile
      switch (this.connection.connection_fees.net_price_method) {
        case 'permit_cost':
          presenter.label = 'Permit Cost'
          presenter.tooltip = 'Item cost is determined by the most recently accepted proposal.'
          break
        case 'revshare':
          presenter.label = 'Commission: ' + this.revsharePct + '%'
          presenter.tooltip = 'Item cost is determined by a commission applied to the retail price.'
          break
        case null && commissionProfile:
          presenter.label = 'Commission: ' + this.commissionProfileName
          presenter.tooltip = 'Item cost is determined by a commission applied to the retail price.'
          break
        default:
          presenter.label = 'Specified on Order'
          presenter.tooltip = 'The retailer is responsible for tracking and passing item costs for each purchase order.'
      }
      return presenter
    }
  },
  props: {
    connection: {
      type: Object,
      required: true
    },
    showTooltip: {
      type: Boolean,
      default: true
    }
  }
}
</script>
