<template>
  <div>
    <div class="columns">
      <div class="column is-one-fifth">
        <p class="has-text-weight-bold is-size-6 mb-sm">Custom Fulfillment Location</p>
        <p class="has-text-grey is-size-7">Configure the location for your order fulfillments.</p>
      </div>
      <div class="column">
        <div class="has-background-white rounded-lg">
          <div class="pa-lg">
            <!-- Intro Text (Vendor) -->
            <div class="is-size-7 mb-md">
              <p class="">
                By default, orders are fulfilled from your default location.
                If you have multiple locations, you can select a different
                location to fulfill orders from.
              </p>
            </div>
            <div>
              <p class="has-text-weight-bold mb-sm">Select Fulfillment Location</p>
              <SelectLocation :account="account" v-model="fulfillmentLocation" />
              <div>
                <p v-if="credential !== null" class="has-text-grey-light is-size-8">
                  Orders are being fulfilled from location_id: {{ parseInt(credential.value) }}.
                </p>
                <p v-else class="has-text-grey-light is-size-8">
                  Orders are being fulfilled from your default location.
                </p>
              </div>
            </div>
          </div>
          <div class="border-t">
            <div class="columns is-vcentered is-marginless">
              <div class="column">
                <div class="px-sm has-text-grey-light">
                  <i v-if="credential !== null">Click "Disable" to fulfill orders from your default location.</i>
                  <i v-else>Click "Set Location" to select the desired fulfillment location for your orders.</i>
                </div>
              </div>
              <div class="column is-narrow">
                <div>
                  <!-- Remove Webhook -->
                  <b-button type="is-danger rounded-lg" outlined :disabled="saving || credential === null"
                    :loading="saving" @click="disableSetting">Disable</b-button>
                  &nbsp;&nbsp;&nbsp;
                  <b-button type="is-primary rounded-lg"
                    :disabled="saving || fulfillmentLocation === null || (credential !== null && fulfillmentLocation.id === parseInt(credential.value))"
                    :loading="saving" @click="save">Set Location</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SelectLocation from './SelectLocation'

export default {
  components: {
    SelectLocation
  },
  computed: {
    ...mapGetters(['app', 'context']),
    endpoint () {
      return this.context.route + '/platform-accounts/' + this.account.id + '/'
    },
    credential () {
      if (this.account.credentials.length === 0) return null
      let found = null
      this.account.credentials.forEach(credential => {
        Object.keys(credential).forEach(key => {
          if (key === 'fulfill_orders_from_location_id') {
            found = credential[key].values[0]
          }
        })
      })
      return found
    },
    json () {
      const json = {
        credentials: [{
          credential: {
            code: 'fulfill_orders_from_location_id'
          },
          value: this.fulfillmentLocation !== null ? this.fulfillmentLocation.id : '0',
          delete: this.fulfillmentLocation === null ? 1 : 0
        }]
      }
      if (this.credential !== null) {
        json.credentials[0].id = this.credential.id
      }
      return json
    }
  },
  data () {
    return {
      fulfillmentLocation: null,
      saving: false,
      errors: {}
    }
  },
  methods: {
    load () {
    },
    disableSetting () {
      this.fulfillmentLocation = null
      this.save()
    },
    save () {
      this.saving = true
      this.$http.patch(this.endpoint, this.json).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Fulfillment location saved!' })
        this.$emit('update:account')
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    this.load()
  },
  props: ['account']
}
</script>
