<template>
  <div>
    <p>{{ location.name1 }}</p>
    <p v-if="location.name2 !== null">{{ location.name2 }}</p>
    <p>{{ location.street1 }}</p>
    <p v-if="location.street2 !== null">{{ location.street2 }}</p>
    <p>{{ location.city }}, {{ location.province }} {{ location.postal_code }}</p>
    <p v-if="location.phone1 !== null">{{ location.phone1 }}</p>
    <p v-if="location.email !== null">{{ location.email }}</p>
  </div>
</template>

<script>
export default {
  props: ['location']
}
</script>
