<template>
<div>
  <div class="section">
    <div class="container">
      <!-- Page Header -->
      <div class="mb-lg">
        <div class="columns is-vcentered">
          <div class="column">
            <h1 class="has-text-centered is-size-4">Onboarding Tasks</h1>
          </div>
        </div>
      </div>
      <!-- Connection Tiles -->
      <div v-if="!loading && platformTasks.length > 0 && !context.partner.isOnboarded">
        <div class="columns is-centered">
          <div class="column is-half">
            <TaskList :tasks="platformTasks" />
          </div>
        </div>
         <!-- Complete -->
        <div class="py-xl">
          <div class="columns is-centered">
            <div class="column is-one-third">
              <b-button
                type="is-primary rounded-lg"
                size="is-medium"
                @click="complete"
                expanded
                :loading="saving"
                :disabled="!ready || saving || context.partner.isOnboarded === true"
              >Complete Onboarding</b-button>
            </div>
          </div>
        </div>
      </div>
      <!-- No Connections in Onboarding -->
      <div v-else-if="!loading && platformTasks.length === 0">
        <div class="has-background-white rounded-lg pa-xxl">
          <div class="has-text-centered pa-xxl">
            <p class="mb-md">You don't currently have any tasks to onboard!</p>
            <p>Return to the <router-link :to="{ name: 'orders.dashboard' }" class="has-text-info">Orders Dashboard</router-link></p>
          </div>
        </div>
      </div>
      <!-- Loading -->
      <div v-else>
        <div class="columns is-centered is-vcentered">
          <div class="column is-half">
            <div v-for="index in 5" :key="index">
              <div class="has-background-white rounded-lg px-md py-md mb-xxs">
                <div class="columns is-vcentered">
                  <div class="column is-narrow">
                    <div class="pa-sm">
                      <b-icon icon="check" class="has-text-white-ter" />
                    </div>
                  </div>
                  <div class="column">
                    <b-skeleton width="50%" height="25" />
                    <b-skeleton width="75%" height="15" class="m-0" />
                  </div>
                  <div class="column is-one-fifth">
                    <b-skeleton height="35" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import TaskList from '../components/Tasks/List'
export default {
  components: {
    TaskList
  },
  computed: {
    ...mapGetters(['context']),
    ready () {
      let ready = true
      this.platformTasks.forEach(task => {
        if (task.completed_at === null) {
          ready = false
        }
      })
      return ready
    }
  },
  data () {
    return {
      loading: true,
      platformTasks: [],
      saving: false
    }
  },
  methods: {
    loadPlatformTasks () {
      const endpoint = this.context.route + '/assigned-tasks/'
      this.loading = true
      this.$http.get(endpoint).then(response => {
        this.platformTasks = []
        response.data.results.forEach(task => {
          this.platformTasks.push(task)
        })
        this.loading = false
      })
    },
    complete () {
      this.saving = true
      this.$http.patch(this.context.route + '/', { is_onboarded: true }).then(response => {
        this.saving = false
        this.$store.dispatch('setContext', { type: 'retailer', partner: response.data })
        this.$buefy.toast.open({ type: 'is-success', message: 'Onboarding completed!' })
        this.$router.push({ name: 'orders.dashboard' })
      })
    }
  },
  mounted () {
    this.loadPlatformTasks()
  },
  props: []
}
</script>
