import Request from '../Request'
export default class AcknowledgeShipment extends Request {
  constructor (config) {
    super()
    this.method = 'delete'
    this.route = config.route
    this.shipment = config.shipment
    this.validate()
  }

  id () {
    return this.shipment.id
  }

  label () {
    return this.shipment.tracking_number
  }

  validate () {
    if (this.shipment.acknowledged_at !== null) {
      this.isValid = false
      this.status = 'Skip'
      this.errorMessage = 'Tracking number has already been acknowledged by merchant!'
    } else {
      this.isValid = true
    }
  }

  json () {
    return null
  }

  endpoint () {
    return this.route + '/shipments/' + this.id() + '/'
  }

  errorHandler (errors) {
    return errors[0]
  }
}
