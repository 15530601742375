<template>
  <div>
    <div class="section">
      <div class="container">
        <!-- Page Header -->
        <div class="columns is-vcentered">
          <div class="column is-one-third">
            <breadcrumbs :history="[{ label: 'Catalog', name: 'catalog.index'}]" current="Import Inventory" :hideBack="true" />
          </div>
          <div class="column is-one-third">
            <h1 class="has-text-centered is-size-4">Import Inventory</h1>
          </div>
        </div>
        <!-- Content -->
        <div>
          <!-- Step 1: Select Template -->
          <div class="has-background-white pa-lg mb-xs rounded-lg">
            <div class="mb-md">
              <h1 class="has-text-primary has-text-weight-bold">Step 1: Select Template</h1>
              <p class="has-text-grey">Select the template that you used for this file.</p>
            </div>
            <div class="columns is-vcentered">
              <div class="column is-half-desktop">
                <b-select
                  v-model="template"
                  placeholder="Select a template..."
                  :disabled="loading || scheduling"
                  :loading="loading"
                  expanded>
                  <option v-for="template in templates" :value="template" :key="template.id">{{ template.name }}</option>
                </b-select>
              </div>
              <!-- <div v-if="template !== null" class="column is-narrow">
                <a href="#" @click.prevent class="has-text-info">Download Sample Template</a>
              </div> -->
            </div>
          </div>
          <!-- Step 2: Select File -->
          <div v-if="template !== null" class="has-background-white pa-lg mb-xs rounded-lg">
            <div class="mb-md">
              <h1 class="has-text-primary has-text-weight-bold">Step 2: Select a File</h1>
              <p class="has-text-grey">Select the file you want to upload to {{ app.platform }}.</p>
            </div>
            <div class="columns">
              <div class="column is-half-desktop">
                <!-- Upload -->
                <CoreUpload @file:uploaded="updateTempfile" @file:reset="resetTempfile"/>
              </div>
            </div>
          </div>
          <!-- Step 3: Schedule Import -->
          <div v-if="tempfile !== null" class="has-background-white pa-lg mb-lg rounded-xl">
            <div class="mb-lg">
              <h1 class="has-text-primary has-text-weight-bold">Step 3: Schedule Import</h1>
              <p class="has-text-grey">When ready, click the button below to schedule your import.</p>
            </div>
            <div>
              <b-button
                type="is-primary"
                :disabled="!ready || feed !== null"
                :loading="scheduling"
                @click="queueFile"
              >Schedule Import</b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CoreUpload from '@/components/CoreUpload'
export default {
  components: {
    CoreUpload
  },
  computed: {
    ...mapGetters(['app', 'context']),
    endpoints () {
      return {
        template: this.context.route + '/templates/',
        queue: this.context.route + '/feed-queue/'
      }
    },
    templatesQuery () {
      return {
        params: {
          direction: 'import',
          data_type: 'inventory',
          limit: 100
        }
      }
    },
    json () {
      return {
        template: {
          id: this.template.id
        },
        direction: 'import',
        data_type: 'inventory',
        source: 'console',
        behavior: 'update',
        info: {},
        file: {
          tempfile: {
            id: this.tempfile.id
          }
        }
      }
    },
    loading () {
      if (this.loadingTemplates) return true
      return false
    },
    ready () {
      return (!this.loadingTemplates && !this.uploading && !this.scheduling && this.template !== null && this.tempfile !== null)
    }
  },
  data () {
    return {
      loadingTemplates: false,
      templates: [],
      template: null,
      tempfile: null,
      scheduling: false,
      feed: null
    }
  },
  methods: {
    loadTemplates () {
      this.loadingTemplates = true
      this.$http.get(this.endpoints.template, this.templatesQuery).then(response => {
        this.templates = response.data.results
        this.loadingTemplates = false
      })
    },
    queueFile () {
      this.scheduling = true
      this.$http.post(this.endpoints.queue + 'enqueue/', this.json).then(response => {
        this.$http.put(this.endpoints.queue + response.data.id + '/run/').then(() => {
          this.scheduling = false
          this.$buefy.toast.open({ type: 'is-success', message: 'File scheduled!' })
        })
      })
    },
    updateTempfile (file, response) {
      this.tempfile = response
    },
    resetTempfile () {
      this.tempfile = null
    }
  },
  mounted () {
    this.loadTemplates()
  }
}
</script>
