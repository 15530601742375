<template>
  <div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Create Rule groups</p>
      </header>
      <section class="modal-card-body">
        <div v-if="errors && 'non_field_errors' in errors" class="mb-lg">
          <b-message type="is-danger">{{ errors.non_field_errors[0] }}</b-message>
        </div>
        <RulesetForm v-model="json" :showPosition="true" @is:ready="isReady"/>
      </section>
      <footer class="modal-card-foot space-between">
        <b-button @click="$parent.close()">Close</b-button>
        <b-button
          :disabled="saving || !isDirty"
          type="is-primary"
          @click="save"
        >Save</b-button>
      </footer>
    </div>
  </div>
  </template>

<script>
import { mapGetters } from 'vuex'
import RulesetForm from '@/components/Models/Ruleset/Form'
export default {
  components: {
    RulesetForm
  },
  computed: {
    ...mapGetters(['api', 'context']),
    endpoint () {
      return this.api.baseUrl + '/v1/rule-groups/'
    }
  },
  data () {
    return {
      saving: false,
      json: null,
      isDirty: false,
      errors: {}
    }
  },
  methods: {
    save () {
      this.saving = true
      this.errors = {}
      const data = {
        ...this.json,
        ruleset: {
          id: this.$route.params.id
        }
      }
      this.$http.post(this.endpoint, data).then(response => {
        this.$emit('reload:ruleGroups')
        this.$buefy.toast.open({ type: 'is-success', message: 'Rule Group created successfully' })
        this.$parent.close()
      }).catch(error => {
        if (error.response && error.response.status === 400) {
          this.errors = error.response.data
        } else {
          this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
        }
      }).finally(() => {
        this.saving = false
      })
    },
    isReady (val) {
      this.isDirty = val
    }
  },
  mounted () {
  },
  props: {
  }
}
</script>
