<template>
  <div>
    <!-- Already Signed -->
    <div v-if="!loading && document !== null && isAcknowledged === true" class="">
      <div class="columns is-mobile is-vcentered is-variable is-2">
        <div class="column is-narrow">
          <b-icon icon="check" custom-class="has-text-success" />
        </div>
        <div class="column">
          <p>
            <a href="#" @click.prevent="showTermsModal = true"><u>Terms of Service</u></a>
            acknowledged by {{ acknowledgedBy }} on {{ acknowledgedOn }}.
          </p>
        </div>
      </div>
    </div>
    <!-- Need to Sign -->
    <div v-else-if="!loading && document !== null && isAcknowledged === false">
      <b-checkbox v-model="agreed" @input="update">I agree to the {{ app.platform }} <a href="#" @click.prevent="showTermsModal = true"><u>Terms of Service</u></a></b-checkbox>
    </div>
    <!-- Loading -->
    <div v-else class="has-text-grey-light">
      <div class="columns is-mobile is-vcentered">
        <div class="column is-narrow">
          <b-icon icon="loading" custom-class="mdi-spin" />
        </div>
        <div class="column">
          <i>Loading Terms of Service...</i>
        </div>
      </div>
    </div>
    <!-- Modal with Terms -->
    <b-modal v-if="!loading && document !== null" :active.sync="showTermsModal" has-modal-card>
      <div class="modal-card" style="max-width: 960px">
        <section class="modal-card-body is-paddingless">
          <iframe width="100%" height="400" style="height:400px !important; display:block" src="https://drive.google.com/file/d/10guU2ViIXrczyOSyskC4Lj5vSxFy_htrNaRLP724sGw/preview"></iframe>
        </section>
        <footer class="modal-card-foot space-between">
          <p class="has-text-grey"> Terms of Service v{{ document.version }}</p>
          <a class="button is-primary" href="https://drive.google.com/file/d/1m2pUhEgsOaUxV61PXbDOV7IwhBgCOgvH/preview" target="_blank">Open Fullscreen</a>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  computed: {
    ...mapGetters(['app', 'assets', 'context']),
    endpoint () {
      return this.context.route + '/terms-and-conditions/' + this.assets.terms[this.context.type] + '/'
    },
    isAcknowledged () {
      return this.document !== null && this.document.acknowledgements.length > 0
    },
    acknowledgedBy () {
      if (this.document !== null && this.document.acknowledgements.length > 0) {
        return this.document.acknowledgements[0].user.first_name + ' ' + this.document.acknowledgements[0].user.last_name
      }
      return null
    },
    acknowledgedOn () {
      if (this.document !== null && this.document.acknowledgements.length > 0) {
        return this.$dates.utc(this.document.acknowledgements[0].acknowledged_at).local().format('MMM D, YYYY')
      }
      return null
    }
  },
  data () {
    return {
      loading: true,
      document: null,
      agreed: false,
      showTermsModal: false
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint).then(response => {
        this.loading = false
        this.document = response.data
        if (this.document.acknowledgements.length > 0) {
          this.$emit('input', true)
        }
      })
    },
    update () {
      this.$emit('input', this.agreed)
    }
  },
  mounted () {
    this.load()
  },
  props: ['value']
}
</script>
