<template>
  <div>
    <!-- Content -->
    <div class="">
      <p class="has-text-weight-bold mb-md">Activate Cancellation Webhook</p>
      <p class="mb-lg">
        When you cancel full orders in Shopify, we can automatically cancel orders in {{ app.productName }}. Please note
        that Shopify only publishes this event when there is a FULL cancellation -- partial cancellations
        still have to be registered via the supplier portal.
      </p>
      <div class="mb-lg">
        <p class="has-text-weight-bold mb-md">Orders Cancelled</p>
        <div class="columns is-mobile is-vcentered">
          <div class="column is-narrow">
            <div class="field">
              <b-switch
                v-model="sync"
                type="is-success"
                size="is-medium"
                true-value="Yes"
                false-value="No"
                :disabled="loading"
              >{{ sync }}</b-switch>
            </div>
          </div>
        </div>
        <div class="has-text-grey-light">
          <p v-if="loading">Checking current status...</p>
          <p v-else-if="webhook !== null">Note: This integration is currently enabled.</p>
          <p v-else>This integration is currently disabled.</p>
        </div>
      </div>
    </div>
    <!-- Navigate -->
    <div class="py-lg">
      <div class="columns is-centered">
        <div class="column">
          <b-button
            type="is-light rounded-lg"
            size="is-medium"
            @click="goBack"
            expanded
            ><b-icon icon="arrow-left"></b-icon>
          </b-button>
        </div>
        <div class="column">
          <b-button
            type="is-primary rounded-lg"
            size="is-medium"
            expanded
            @click="goForward"
            :disabled="saving || !ready"
            :loading="saving"
          >Save &amp; Continue</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['app', 'context']),
    ready () {
      return (this.account || this.storeName)
    },
    endpoint () {
      return this.context.route + '/shopify/' + this.account.id + '/webhooks/'
    },
    json () {
      return {
        webhook_topic: this.topic
      }
    }
  },
  data () {
    return {
      loading: true,
      webhook: null,
      saving: false,
      errors: {},
      sync: 'No',
      topic: 'orders/cancelled'
    }
  },
  methods: {
    enable () {
      const endpoint = this.context.route + '/shopify/' + this.account.id + '/register-webhook/'
      this.saving = true
      this.$http.put(endpoint, this.json).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Sync enabled!' })
        this.$emit('navigate', 'CarrierMapping')
      }).finally(() => {
        this.saving = false
      })
    },
    disable () {
      this.saving = true
      this.$http.delete(this.endpoint + this.webhook.id + '/').then(() => {
        this.webhook = null
        this.$buefy.toast.open({ type: 'is-success', message: 'Sync disabled!' })
        this.$emit('navigate', 'CarrierMapping')
      }).finally(() => {
        this.saving = false
      })
    },
    goBack () {
      this.$emit('navigate', 'Fulfillments')
    },
    goForward () {
      if (this.sync === 'Yes' && this.webhook === null) {
        // enable order cancellation integration
        this.enable()
      } else if (this.sync === 'No' && this.webhook !== null) {
        // disable order cancellation integration
        this.disable()
      } else {
        // don't do anything
        this.$buefy.toast.open({ type: 'is-success', message: 'Settings saved!' })
        this.$emit('navigate', 'CarrierMapping')
      }
    },
    loadWebhooks () {
      this.loading = true
      const endpoint = this.context.route + '/shopify/' + this.account.id + '/webhooks/'
      this.$http.get(endpoint).then(response => {
        // check for the cancelled order webhook...
        const order = this.$utils.find(response.data.results, ['topic', this.topic])
        if (order) {
          this.webhook = order
          this.sync = 'Yes'
        }
      }).finally(() => {
        this.loading = false
      })
    }
  },
  mounted () {
    this.loadWebhooks()
  },
  props: {
    connection: {
      type: Object,
      required: true
    },
    task: {
      type: Object,
      required: true
    },
    account: {
      type: Object
    }
  }
}
</script>
