<template>
  <div>
    <!-- Toolbar -->
  <div class="has-background-white rounded-lg py-md px-lg mb-xxs">
    <div class="columns is-vcentered">
      <div class="column">
        <div v-if="!isPaid" class="px-sm has-text-grey-light">
          <em class="is-size-7">Click {{list.length ? 'Edit' : 'Add' }} to change the adjustment amount &amp; description for this payment.</em>
        </div>
        <div v-else class="has-text-primary has-text-weight-bold">Adjustments</div>
      </div>
      <div class="column is-narrow">
        <div class="px-sm">
          <b-button
            :disabled="isPaid"
            type="is-primary"
            outlined
            expanded
            @click.prevent="setCreateAdjustmentModal(true)"
          >Add Adjustment</b-button>
        </div>
      </div>
    </div>
  </div>
  <div class="mb-lg">
    <div class="has-background-white rounded-lg pa-lg">
      <div v-if="list.length > 0">
        <table class="table is-fullwidth is-striped is-narrow is-size-7 is-vcentered">
          <thead>
            <tr>
              <th >Description</th>
              <th width="25%" class="has-text-right">Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(adjustment) in list" :key="adjustment.id">
              <th>{{ adjustment.description }}</th>
              <td width="25%" class="has-text-right">
                <div>{{ $numbers(adjustment.amount).formatCurrency({ mantissa: 2 }) }}</div>
                <div><a href="#" v-if="context.isMerchant() && !isPaid" @click.prevent="editAdjustment(adjustment)" class="has-text-info">Edit</a></div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- No Metadata -->
      <div v-else class="has-text-centered py-xxl">
        <p class="has-text-grey-light mb-lg">
          There are no custom adjustments associated with this payment.
        </p>
        <p v-if="context.isMerchant() && !isPaid"><b-button outlined @click="setCreateAdjustmentModal(true)">Add Adjustment</b-button></p>
      </div>
    </div>
  </div>
  <!-- New adjustment Modal -->
  <b-modal
    v-if="showAdjustmentModal"
    has-modal-card
    :active="showAdjustmentModal"
    :can-cancel="['escape', 'x']"
    @close="closeModal"
    ><EditAdjustmentModal
      :saving="saving"
      @update:adjustment="updateAdjustment"
      @delete:adjustment="deleteAdjustment"
      :adjustment="adjustment"
      :payment="payment"
    />
  </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import EditAdjustmentModal from '../components/Adjustments/EditAdjustmentsModal'
export default {
  components: {
    EditAdjustmentModal
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/payments/' + this.$route.params.id + '/'
    },
    isPaid () {
      return this.payment.status === 'paid'
    }
  },
  data: () => {
    return {
      showAdjustmentModal: false,
      list: [],
      adjustment: null,
      saving: false,
      isEdit: false
    }
  },
  methods: {
    setCreateAdjustmentModal (value) {
      this.showAdjustmentModal = value
    },
    editAdjustment (value) {
      this.adjustment = value
      this.isEdit = true
      this.showAdjustmentModal = true
    },
    closeModal () {
      this.showAdjustmentModal = false
      this.adjustment = null
      this.isEdit = false
    },
    updateAdjustment (adjustment) {
      this.saving = true
      const json = {
        status: 'draft',
        brand_id: this.payment.brand.id,
        adjustments: [{
          amount: adjustment.amount,
          description: adjustment.description
        }]
      }
      if (this.isEdit && this.adjustment && this.adjustment.id) {
        json.adjustments[0].id = this.adjustment.id
      }
      this.$http.patch(this.endpoint, json).then(response => {
        this.list = response.data.adjustments
        this.$emit('updated')
      }).finally(() => {
        this.saving = false
        this.closeModal()
      })
    },
    deleteAdjustment (adjustmentId) {
      this.saving = true
      const endpoint = this.endpoint + 'remove-adjustments/'
      const json = {
        adjustment_ids: [adjustmentId]
      }
      this.$http.put(endpoint, json).then(response => {
        this.$emit('updated')
      }).finally(() => {
        this.saving = false
        this.closeModal()
      })
    }
  },
  mounted () {
    if (this.payment.adjustments && this.payment.adjustments.length) {
      this.list = this.payment.adjustments
    }
  },
  watch: {
    payment: {
      deep: true,
      immediate: true,
      handler () {
        this.list = this.payment.adjustments
      }
    }
  },
  props: ['payment']
}
</script>
