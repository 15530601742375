<template>
  <div>
    <b-select
      v-model="selectedOption"
      placeholder="Select Currency"
      expanded
      :loading="loading"
      :disabled="loading"
      :size="sizeClass"
      @input="announce">
      <option v-for="option in options" :key="option.id" :value="option">{{ option.name }}</option>
    </b-select>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  data () {
    return {
      loading: true,
      selectedOption: null,
      currencyOptions: null,
      options: [],
      preferenceId: null
    }
  },
  computed: {
    ...mapGetters(['context']),
    sizeClass () {
      return (this.size) ? this.size : null
    }
  },
  methods: {
    load () {
      const endpoint = this.context.route + '/preferences/'
      const query = {
        params: {
          grouping: 'locale'
        }
      }
      this.$http.get(endpoint, query).then(response => {
        const preferences = response.data.results
        // locale preferences
        this.currencyOptions = this.$utils.find(preferences, ['code', 'currency'])
        if (typeof this.currencyOptions !== 'undefined') {
          // set the preference Id
          this.preferenceId = this.currencyOptions.id
          // set the options
          this.options = this.currencyOptions.options.map(option => {
            return {
              id: option.id,
              name: option.name
            }
          })
        }
      }).finally(() => {
        this.loading = false
      })
    },
    announce () {
      const json = {
        preferenceId: this.preferenceId,
        selectedOption: this.selectedOption
      }
      this.$emit('input', json)
    }
  },
  mounted () {
    if (this.value !== null) {
      this.selectedOption = this.value
    }
    this.load()
  },
  watch: {
    currencyOptions (options) {
      // set the current value
      if (this.context.isMerchant()) {
        if (options.retailer_preference && options.retailer_preference !== null) {
          this.selectedOption = {
            id: options.retailer_preference.option_id,
            name: options.retailer_preference.currency.name
          }
        }
      }
      if (this.context.isSupplier()) {
        if (options.brand_preference && options.brand_preference !== null) {
          this.selectedOption = {
            id: options.brand_preference.option_id,
            name: options.brand_preference.currency.name
          }
        }
      }
      this.announce()
    }
  },
  props: ['value', 'size']
}
</script>
