<template>
  <div>
    <div class=" mb-md">
      <p class="is-uppercase has-text-weight-bold is-size-7 mb-xs">Product Media</p>
      <p class="is-size-7 has-text-grey">Photos below may be resized for consistency. Please review the native dimensions listed below each photo (WxH).</p>
    </div>
    <div class="columns is-centered">

      <div class="column ">
        <div class="px-md">

          <!-- Media -->
          <div class=" mb-md">

            <div class="columns is-multiline is-mobile">
              <div class="column is-narrow" v-for="medium in product.media" :key="medium.id">
                <figure class="image is-128x128 mb-xs">
                  <img class="rounded-lg" :src="cdnTransform(medium.url, 128, 128, 'fill')" width="128" height="128" >
                </figure>
                <p class="is-size-8 has-text-centered has-text-grey">{{ medium.width }}x{{ medium.height }}</p>
                <p class="is-size-8 has-text-centered">
                  <a :href="cdnTransform(medium.url, null, null, null)" target="_blank" class="has-text-grey">
                    <u>Full Size</u>
                    <b-icon icon="open-in-new" size="is-small" />
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CdnTransform from '@/mixins/CdnTransform'

export default {
  components: {

  },
  data () {
    return {

    }
  },
  computed: {
    ...mapGetters(['context'])
  },
  methods: {

  },
  mixins: [CdnTransform],
  mounted () {

  },
  props: ['product']
}
</script>
