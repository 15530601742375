<template>
  <div>
    <div class="section">
      <div class="container">
        <!-- Page Header -->
        <div class="mb-md">
          <div class="columns is-vcentered">
            <div class="column is-one-third">
              <breadcrumbs :history="breadcrumbs.history" :current="breadcrumbs.current" />
            </div>
            <div class="column is-one-third">
              <h1 class="has-text-centered is-size-4">Proposal Detail</h1>
            </div>
          </div>
        </div>
        <!-- Loaded -->
        <div v-if="!loading && this.proposal !== null">
          <!-- Header -->
          <div class="has-background-white px-xl py-md rounded-lg mb-lg">
            <ProposalHeader :proposal="proposal" :loading="loading" />
          </div>
          <!-- Child Route Container -->
          <div class="columns">
            <div class="column is-narrow">
              <b-menu class="pr-md">
                <b-menu-list label="Proposal Menu">
                  <b-menu-item
                    icon="clipboard-list-outline"
                    label="Summary"
                    tag="router-link"
                    :to="{ name: 'proposal.detail.summary', params: { id: proposal.id } }"
                    exact
                  ></b-menu-item>
                  <b-menu-item
                    icon="format-list-text"
                    label="Products"
                    tag="router-link"
                    :to="{ name: 'proposal.detail.products', params: { id: proposal.id } }"
                    exact
                  ></b-menu-item>
                  <b-menu-item
                    icon="format-align-justify"
                    label="Pricing"
                    tag="router-link"
                    :to="{ name: 'proposal.detail.items', params: { id: proposal.id } }"
                    exact
                  ></b-menu-item>
                  <b-menu-item
                    icon="format-list-checks"
                    label="Compatibility"
                    tag="router-link"
                    :to="{ name: 'proposal.detail.compatibility', params: { id: proposal.id } }"
                    exact
                  ></b-menu-item>
                  <b-menu-item
                    icon="format-list-checks"
                    tag="router-link"
                    :to="{ name: 'proposal.detail.issues', params: { id: proposal.id } }"
                    exact>
                    <template #label>
                      Issues
                      <b-tag v-if="proposal.issues_summary.open > 0" rounded type="is-warning">{{proposal.issues_summary.open}}</b-tag>
                    </template>
                  </b-menu-item>
                  <b-menu-item
                    v-if="context.isSupplier()"
                    icon="file-table-box-multiple-outline"
                    label="Imports"
                    tag="router-link"
                    :to="{ name: 'proposal.detail.imports', params: { id: proposal.id } }"
                    exact
                  ></b-menu-item>
                </b-menu-list>
              </b-menu>
            </div>
            <div class="column">
              <router-view
                :proposal="proposal"
                :connection="connection"
                :template="template"
                @proposal:updated="loadProposal(true)"
              ></router-view>
            </div>
          </div>
        </div>
        <!-- Loading Skeleton... -->
        <div v-else>
          <div class="has-background-white pa-lg rounded-lg mb-xs">
            <b-skeleton width="60%" height="25" />
            <b-skeleton width="50%" />
          </div>
          <div class="has-background-white px-lg py-xxl rounded-lg mb-lg">
            <div class="columns">
              <div class="column">
                <b-skeleton />
                <b-skeleton />
                <b-skeleton />
              </div>
              <div class="column">
                <b-skeleton />
                <b-skeleton />
                <b-skeleton />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ProposalHeader from '../components/Detail/ProposalHeader'

export default {
  components: {
    ProposalHeader
  },
  data () {
    return {
      loadingProposal: true,
      loadingConnection: true,
      loadingTemplate: true,
      refreshing: false,
      proposal: null,
      connection: null,
      template: null
    }
  },
  computed: {
    ...mapGetters(['auth', 'context']),
    breadcrumbs () {
      return {
        current: (this.loading) ? 'Loading...' : '#' + this.$route.params.id,
        history: [
          { label: 'Proposals', name: 'proposals.dashboard' },
          { label: 'List', name: 'proposals.list' }
        ]
      }
    },
    loading () {
      return (this.loadingProposal || this.loadingConnection || this.loadingTemplate)
    }
  },
  methods: {
    loadProposal (quiet) {
      if (!quiet) this.loadingProposal = true
      const endpoint = this.context.route + '/proposals/' + this.$route.params.id + '/'
      this.$http.get(endpoint).then(response => {
        this.proposal = response.data
        // load connection details
        this.loadConnection(quiet)
        // if the proposal is assigned to a department, load the template...
        if (this.proposal.department) {
          this.loadTemplate()
        } else {
          this.loadingTemplate = false
        }
        this.loadingProposal = false
      })
    },
    loadConnection (quiet) {
      if (!quiet) this.loadingConnection = true
      if (!this.proposal) return null
      const endpoint = this.context.route + '/connections/' + this.proposal.connection.id + '/'
      this.$http.get(endpoint).then(response => {
        this.connection = response.data
        this.loadingConnection = false
      })
    },
    loadTemplate (quiet) {
      if (!quiet) this.loadingTemplate = true
      if (!this.proposal) return null
      const endpoint = this.context.route + '/templates/' + this.proposal.department.template.id + '/mappings/?pagination=0'
      this.$http.get(endpoint).then(response => {
        this.template = response.data
        this.loadingTemplate = false
      })
    }
  },
  mounted () {
    this.loadProposal()
  }
}
</script>
