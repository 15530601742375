<template>
  <div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Reset Task?</p>
      </header>
      <section class="modal-card-body">
        <p class="py-xl has-text-centered">
          Are you sure you want to reset this task?
        </p>
      </section>
      <footer class="modal-card-foot space-between" >
        <b-button @click="$parent.close()">Cancel</b-button>
        <b-button
        type="is-danger"
        @click="resetTask"
        :loading="saving"
        :disabled="saving"
        >Reset Task</b-button>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/assigned-tasks/' + this.taskId + '/reset/'
    }
  },
  data () {
    return {
      saving: false
    }
  },
  methods: {
    resetTask () {
      this.saving = true
      // reset the task info
      const taskBody = { info: {} }
      this.$http.put(this.endpoint, taskBody).then(response => {
        this.$parent.close()
        this.$emit('task:reset')
      }).finally(() => {
        this.saving = false
      })
    }
  },
  props: ['taskId']
}
</script>
