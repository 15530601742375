<template>
<div>
  <div v-if="!loadingFeed && feedDetail !== null">
    <!-- Intro -->
    <div class="mb-lg">
      <p class="has-text-weight-bold has-text-info has-text-centered mb-md">Step 2 of 3: Review Import</p>
      <p class="has-text-grey">
        Please review the results of your import and make sure they meet
        your expections. Please remember that SKUs must already be
        <router-link class="has-text-info is-underlined" :to="{ name: 'catalog.import' }">imported</router-link>
        and cannot be on another active proposal -- otherwise, the item will be skipped.
      </p>
    </div>
    <div class="mb-lg">
      <div class="has-background-light rounded-lg pa-md">
        <Progress :feed="feedDetail" size="is-large" :emitRefresh="true" @refresh="loadFeed(true)" />
      </div>
    </div>
    <!-- Proposal Details -->
    <div v-if="!loadingProposal && proposalDetail !== null && feedDetail.status === 'success'">
      <!-- Item Counters -->
      <div class="mb-lg">
        <!-- Items -->
        <div class="mb-sm">
          <div class="columns">
            <div class="column is-half">
              <div class="has-background-light rounded-lg pa-md has-text-centered">
                <p class="is-size-4 has-text-weight-bold">
                  <span v-if="activeProposalItems > 0" class="has-text-success">{{ activeProposalItems }}</span>
                  <span v-if="activeProposalItems <= 0" class="has-text-grey-light">0</span>
                </p>
                <p class="has-text-grey is-size-7"># Items Added to Proposal</p>
              </div>
            </div>
            <div class="column is-half">
              <div class="has-background-light rounded-lg pa-md has-text-centered">
                <p class="is-size-4 has-text-weight-bold">
                  <span v-if="proposalDetail.item_rejected_count > 0" class="has-text-warning">{{ proposalDetail.item_rejected_count }}</span>
                  <span v-if="proposalDetail.item_rejected_count === 0" class="has-text-success">0</span>
                </p>
                <p class="has-text-grey is-size-7"># Items Skipped from Proposal</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Warning -->
      <div class="mt-lg" v-if="!activeProposalItems">
        <div>
          <b-message class="is-size-7" type="is-warning">
            Items are skipped from your cost proposal if they have not
            been previously approved by {{ proposalDetail.connection.retailer.name }}
            or are already on another proposal awaiting approval from
            {{ proposalDetail.connection.retailer.name }}. Unfortunately, all of the items you submitted
            met one or more of these conditions. Please archive this proposal.
          </b-message>
        </div>
      </div>
      <!-- Feed Details -->
      <div class="mb-lg">
        <div v-if="showDetails">
          <table class="table is-fullwidth is-striped is-narrow is-size-8 border">
            <tbody>
              <tr>
                <th>ID</th>
                <td class="has-text-right">{{ feedDetail.id }}</td>
                <th>Feed Type</th>
                <td class="has-text-right">{{ $utils.upperFirst(feedDetail.direction) }} {{ $utils.upperFirst(feedDetail.data_type) }}</td>
              </tr>
              <tr>
                <th>Filename</th>
                <td class="has-text-right">{{ $utils.truncate(feedDetail.original_filename, { length: 32 }) }}</td>
                <th>Template</th>
                <td class="has-text-right">{{ feedDetail.template.name }}</td>
              </tr>
              <tr>
                <th>File Link</th>
                <td class="has-text-right">
                  <DownloadFileAs label="Download" :cloudFileName="feedDetail.cloud_filename" :originalFileName="feedDetail.original_filename"/>
                </td>
                <th>Status</th>
                <td class="has-text-weight-bold has-text-right">
                  <span v-if="feedDetail.status === 'success'" class="has-text-success">Success</span>
                  <span v-else-if="feedDetail.status === 'failure'" class="has-text-danger">Failed</span>
                  <span v-else-if="feedDetail.status === 'processing'" class="has-text-danger">Processing</span>
                  <span v-else>Queued</span>
                </td>
              </tr>
              <tr>
                <th>Started At</th>
                <td class="has-text-right">
                  <span v-if="feedDetail.processed_at">{{ $dates.utc(feedDetail.processed_at).local().format('MMM D, YYYY @ h:mm A') }}</span>
                  <span v-else class="has-text-grey is-italic">Not Started</span>
                </td>
                <th>Completed At</th>
                <td class="has-text-right">
                  <span v-if="feedDetail.completed_at">{{ $dates.utc(feedDetail.completed_at).local().format('MMM D, YYYY @ h:mm A') }}</span>
                  <span v-else class="has-text-grey is-italic">Not Completed</span>
                </td>
              </tr>
              <tr>
                <th>Wait time</th>
                <td class="has-text-right"><Runtime :start="feedDetail.created_at" :end="feedDetail.processed_at" /></td>
                <th>Processing Time</th>
                <td class="has-text-right"><Runtime :start="feedDetail.processed_at" :end="feedDetail.completed_at" /></td>
              </tr>
              <tr>
                <th># of Rows</th>
                <td class="has-text-right">{{ feedDetail.num_items }}</td>
                <th># of Items Created</th>
                <td class="has-text-right">{{ feedDetail.num_created }}</td>
              </tr>
              <tr>
                <th># of Items Updated</th>
                <td class="has-text-right">{{ feedDetail.num_updated }}</td>
                <th># of Warnings</th>
                <td class="has-text-right">{{ feedDetail.num_warnings }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- Navigate -->
      <div class="mt-xl">
        <div class="columns is-centered">
          <div class="column">
            <b-button
              type="is-light rounded-lg"
              size="is-medium"
              @click="goBack"
              expanded
              ><b-icon icon="arrow-left"></b-icon>
            </b-button>
          </div>
          <div v-if="activeProposalItems > 0" class="column">
            <b-button
              type="is-primary rounded-lg"
              size="is-medium"
              expanded
              :disabled="saving"
              :loading="saving"
              @click="goForward"
            >Review Compatibility</b-button>
          </div>
          <div v-else-if="activeProposalItems <= 0 && proposalDetail.item_rejected_count > 0" class="column">
            <b-button
              type="is-primary rounded-lg"
              size="is-medium"
              expanded
              :disabled="saving"
              :loading="saving"
              @click="archiveProposal"
            >Archive Proposal</b-button>
          </div>
          <div v-else class="column">
            <b-button
              type="is-primary rounded-lg"
              size="is-medium"
              expanded
              :disabled="true"
            >Review Compatibility</b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <loading message="Waiting on import details..." />
  </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Progress from '@/components/Models/FeedQueue/Presenters/Progress'
import Runtime from '@/components/Models/FeedQueue/Presenters/Runtime'

export default {
  components: {
    Progress,
    Runtime
  },
  computed: {
    ...mapGetters(['app', 'context']),
    activeProposalItems () {
      if (this.proposalDetail) {
        return this.proposalDetail.item_count - this.proposalDetail.item_rejected_count
      }
      return 0
    }
  },
  data () {
    return {
      loadingFeed: true,
      loadingProposal: true,
      loadingCompatibility: true,
      feedDetail: null,
      proposalDetail: null,
      rulesets: [],
      showDetails: false,
      saving: false,
      errors: {}
    }
  },
  methods: {
    goBack () {
      this.$emit('navigate', 'Build')
    },
    goForward () {
      this.$emit('navigate', 'Compatibility')
    },
    toggleDetails () {
      this.showDetails = !this.showDetails
    },
    loadProposal (silent = false) {
      if (!silent) this.loadingProposal = true
      const endpoint = this.context.route + '/proposals/' + this.proposal.id + '/'
      this.$http.get(endpoint).then(response => {
        this.proposalDetail = response.data
        this.loadingProposal = false
      })
    },
    loadFeed (silent = false) {
      if (!silent) this.loadingFeed = true
      const endpoint = this.context.route + '/feed-queue/' + this.feed.id + '/'
      this.$http.get(endpoint).then(response => {
        this.feedDetail = response.data
        if (this.feedDetail.status === 'success' || this.feedDetail.status === 'failure') {
          this.loadProposal(true)
        }
        this.loadingFeed = false
      })
    },
    submitProposal () {
      this.saving = true
      const endpoint = this.context.route + '/proposals/' + this.proposal.id + '/'
      const body = {
        status: 'proposed'
      }
      this.$http.patch(endpoint, body).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Proposal submitted!' })
        this.$router.push({ name: 'catalog' })
      })
    },
    archiveProposal () {
      this.saving = true
      const endpoint = this.context.route + '/proposals/' + this.proposal.id + '/'
      const body = {
        status: 'archived'
      }
      this.$http.patch(endpoint, body).then(response => {
        this.$buefy.toast.open({ type: 'is-info', message: 'Proposal archived!' })
        this.$router.push({ name: 'catalog' })
      })
    }
  },
  mounted () {
    this.loadFeed()
  },
  props: {
    feed: {
      type: Object,
      required: true
    },
    proposal: {
      type: Object,
      required: true
    }
  }
}
</script>
