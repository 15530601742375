<template>
  <div>
    <div class="has-background-white rounded-lg pa-lg">
      <AttributeSearch :attributes="attributes" :isCreate="true" @update:onSelect="onSelect"/>
      <div class="mt-md">
        <b-table
          class="is-size-7"
          narrowed
          :data="attributesList"
          :loading="loading"
          :mobile-cards="false">
          <template>
            <!-- Attribute -->
            <b-table-column
              label="Attribute"
              cell-class="nowrap"
              v-slot="props"
              width="200">
              <div>
                <router-link class="has-text-info has-text-weight-bold"
                  :to="{
                    name: 'attribute.map',
                    params: {
                      id: props.row.id,
                      transformer: transformerId
                    }
                  }">
                  {{props.row.attribute.name}}
                </router-link>
              </div>
            </b-table-column>
            <!-- No.Of Transformations -->
            <b-table-column
              label="Transformations"
              cell-class="nowrap"
              v-slot="props"
              centered
              width="100">
              <p> {{ props.row.transformations.length }} </p>
            </b-table-column>
            <!-- Updated At -->
            <b-table-column
              label="Updated At"
              cell-class="nowrap"
              v-slot="props"
              centered
              width="100">
              <p>
                {{ $dates.utc(props.row.updated_at).local().format('MMM D, YYYY @ h:mm A')}}
              </p>
              <p></p>
            </b-table-column>
          </template>
          <template #empty>
            <div class="has-text-grey has-text-centered">
              {{ (loading) ? "Loading data..." : 'Attributes are not yet added to this transformer!' }}
            </div>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AttributeSearch from '@/components/Models/Transformers/AttributeSearch'
export default {
  components: {
    AttributeSearch
  },
  computed: {
    ...mapGetters(['app', 'api', 'context']),
    endpoint () {
      return this.context.route + `/transformers/${this.transformerId}/maps/`
    }
  },
  data: () => {
    return {
      attributesList: [],
      loading: false,
      attribute: null,
      selected: null,
      errors: {}
    }
  },
  methods: {
    onSelect (option) {
      this.selected = option
      if (option && option.code) {
        this.createMapping()
      }
    },
    // Get list of transformer attributes
    loadTransformerAttributes () {
      this.loading = true
      const query = {
        params: { pagination: 0 }
      }
      this.$http.get(this.endpoint, query).then(response => {
        this.attributesList = response.data.results
        this.loading = false
      })
    },
    // Create a new attribute
    createMapping () {
      this.loading = true
      const json = {
        attribute: {
          code: this.selected.code
        }
      }
      this.$http.post(this.endpoint, json).then(response => {
        this.errors = {}
        this.$buefy.toast.open({ type: 'is-success', message: 'Attribute added!' })
        this.loadTransformerAttributes()
      }).catch(error => {
        this.$buefy.toast.open({ type: 'is-danger', message: 'Error adding attribute!' })
        this.errors = error.response.data
      }).finally(() => {
        this.selected = null
      })
    }
  },
  created () {
    if (this.transformerId) {
      this.loadTransformerAttributes()
    }
  },
  mounted () {
  },
  props: {
    attributes: {
      type: Array,
      required: true
    },
    transformerId: {
      type: Number,
      required: true
    }
  }
}
</script>
