<template>
  <p class="mb-xs">
    <span class="has-text-weight-bold">{{ label }} </span>
    <span v-if="required" class="has-text-danger">*</span>
  </p>
</template>

<script>
export default {
  props: {
    label: {
      type: String
    },
    required: {
      type: Boolean,
      default: true
    }
  }
}
</script>
