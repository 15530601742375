<template>
  <div class="section">
    <div class="container">
      <!-- Page Header -->
      <div class="columns is-vcentered">
        <div class="column is-one-third">
          <breadcrumbs :history="[{ label: 'Settings', name: 'settings'}]" current="Transformers" :hideBack="true" />
        </div>
        <div class="column is-one-third">
          <h1 class="has-text-centered is-size-4">Attribute Value Transformers</h1>
        </div>
      </div>
      <!-- Filters -->
      <div class="has-background-white rounded-lg pa-md mb-xxs">
        <div class="columns">
          <div class="column">
            <ListFilters @filter="filter" :applied-filters="filters" />
          </div>
          <div class="column is-narrow">
            <b-field >
              <b-select v-model="orderBy" expanded @input="sort" size="is-medium">
                <option value="-id">Most Recent</option>
                <option value="id">Oldest</option>
              </b-select>
            </b-field>
          </div>
          <div class="column is-narrow">
            <b-button type="is-primary" outlined size="is-medium" @click="showCreateModal = true">
              <span>Create Transformer</span>
            </b-button>
          </div>
        </div>
      </div>
      <!-- Toolbar -->
      <div class="has-background-white rounded-lg px-sm mb-xxs">
        <div class="columns is-vcentered is-mobile is-marginless is-paddingless">
          <!-- Dropdown -->
          <div class="column">
            <div class="has-text-right is-size-7">
              <!-- Counts -->
              <p v-if="loading">Loading...</p>
              <p v-else>{{ total }} {{ (total === 1) ? 'record' : 'records' }} found.</p>
            </div>
          </div>
        </div>
      </div>
      <!-- Table -->
      <div>
        <b-table
          :data="transformersList"
          :mobile-cards="false"
          :loading="loading"
          hoverable
          class="is-size-7">
          <template>
            <!-- ID -->
            <b-table-column
              label="ID"
              width="64"
              v-slot="props">
              <p class="has-text-grey-light">{{ props.row.id }}</p>
            </b-table-column>
            <!-- Name -->
            <b-table-column
              label="Transformer Name"
              cell-class="nowrap"
              v-slot="props">
              <div>
                <router-link class="has-text-weight-bold has-text-info" :to="{ name: 'transformer', params: { id: props.row.id }}">
                  {{ props.row.name }}
                </router-link>
              </div>
              <div class="has-text-grey-light">
                <p v-if="props.row.retailer">{{ props.row.retailer.name }}</p>
                <p v-else-if="props.row.brand">{{ props.row.brand.name }}</p>
                <p v-else>{{ props.row.brand.name }}</p>
              </div>
            </b-table-column>
            <!-- Mappings -->
            <b-table-column
              label="Mappings"
              width="128"
              centered
              v-slot="props">
              <p>{{ props.row.maps.length }}</p>
            </b-table-column>
          </template>
          <template #empty>
            <div class="py-xxl has-text-grey has-text-centered">
              {{ (loading) ? "Loading data..." : 'No records found!' }}
            </div>
          </template>
        </b-table>
      </div>
      <div class="mt-md">
        <b-pagination
          :total="total"
          :current.sync="currentPage"
          :rounded="true"
          :per-page="perPage"
          @change="paginate">
        </b-pagination>
      </div>
    </div>
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showCreateModal"
      :can-cancel="['x']"
      >
      <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Create Transformer</p>
      </header>
      <section class="modal-card-body">
        <div class="has-background-white rounded-lg">
          <b-field
            label="Transformer Name"
            expanded>
            <b-input
              type="text"
              size="is-medium"
              v-model="name"
            />
          </b-field>
        </div>
      </section>
      <footer class="modal-card-foot" style="justify-content:space-between">
        <b-button type="is-primary" @click="showCreateModal = false">Cancel</b-button>
        <b-button type="is-primary" @click="create" :loading="saving" :disabled="saving">Save</b-button>
      </footer>
    </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Filterable from '@/mixins/Filterable'
import ListFilters from '../components/List/Filter'

export default {
  components: {
    ListFilters
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/transformers/'
    },
    transformersList () {
      let lists = []
      if (!this.loading && this.results.length > 0) {
        lists = this.results
      }
      return lists
    },
    json () {
      return {
        name: this.name
      }
    }
  },
  data () {
    return {
      key: 'transformers',
      alwaysAttach: {
      },
      name: null,
      saving: false,
      showCreateModal: false
    }
  },
  methods: {
    create () {
      this.saving = true
      this.$http.post(this.endpoint, this.json).then(response => {
        this.errors = {}
        this.$buefy.toast.open({ type: 'is-success', message: 'Transformer created!' })
        this.showCreateModal = false
        this.load()
      }).catch(error => {
        this.$buefy.toast.open({ type: 'is-danger', message: 'Error creating transformer!' })
        this.errors = error.response.data
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mixins: [Filterable],
  mounted () {
    this.load()
  }
}
</script>
