<template>
  <div>
    <span>{{ presenter.label }}</span>
  </div>
</template>

<script>
export default {
  computed: {
    presenter () {
      const presenter = {}
      const days = this.connection.connection_slas.brand_fulfillment_hours / 24
      switch (days) {
        case 1:
          presenter.label = '1 Business Day'
          break
        default:
          presenter.label = days + ' Business Days'
      }
      return presenter
    }
  },
  props: {
    connection: {
      type: Object,
      required: true
    },
    showTooltip: {
      type: Boolean,
      default: true
    }
  }
}
</script>
