<template>
  <div>
    <div class="has-text-weight-semibold">
      Complete Setup
    </div>
    <div class="mt-md">
      <!-- Details -->
      <p class="is-size-7">All EDI setup steps were successful. Complete setup and begin receiving orders from {{retailerName}}?</p>
    </div>
    <!-- Navigate -->
    <div class="py-lg">
        <div class="columns">
          <div class="column">
            <b-button
              type="is-light rounded-lg"
              expanded
              @click="goBack"
              ><b-icon icon="arrow-left"></b-icon>
            </b-button>
          </div>
          <div class="column">
            <b-button
              type="is-primary rounded-lg"
              expanded
              :disabled="saving"
              :loading="saving"
              @click="complete"
            >Complete</b-button>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  computed: {
    ...mapGetters(['app', 'context']),
    retailerName () {
      return this.connection.retailer.name
    }
  },
  data () {
    return {
      saving: false
    }
  },
  methods: {
    goBack () {
      this.$router.push({ name: 'onboarding.tasks.self-serve-edi.invoice', params: { connection: this.$route.params.connection } })
    },
    complete () {
      this.saving = true
      const endpoint = this.context.route + '/assigned-tasks/' + this.$route.params.task + '/complete/'
      const taskBody = (typeof this.task.info === 'object') ? { info: this.task.info } : { info: {} }
      taskBody.info.completeSetup = true
      this.$http.put(endpoint, taskBody).then(() => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Task Completed!' })
        this.$router.push({ name: 'onboarding.tasks', params: { connection: this.connection.id } })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
  },
  props: {
    connection: {
      type: Object,
      required: true
    },
    ediAccount: {
      type: Object
    },
    task: {
      type: Object,
      required: true
    }
  }
}
</script>
