<template>
  <div class="has-background-white rounded-lg">
    <!-- Loaded -->
    <div v-if="preferences !== null">
      <div class="mb-lg">
        <FieldLabel label="Payment Terms" required/>
        <div class="has-text-grey">
          <p>
            The Payment Terms reflect how long you have in credit days before the amount is due to your supplier.
            For example, if set to Net 30, you should remit payment the supplier within 30 days of invoice receipt.
          </p>
        </div>
      </div>
      <b-field>
        <b-select
          type="text"
          v-model="value"
          size="is-medium"
          expanded
          @input="announce">
          <option v-for="option in options" :key="option.id" :value="option">{{ option.name }}</option>
        </b-select>
      </b-field>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  computed: {
    ...mapGetters(['app', 'context']),
    json () {
      return {
        preference: { id: this.preference && this.preference.id },
        option: { id: this.value && this.value.id }
      }
    },
    ready () {
      return (this.value !== null)
    }
  },
  data () {
    return {
      preference: null,
      options: [],
      value: null
    }
  },
  methods: {
    announce () {
      this.$emit('input', this.json)
    }
  },
  mounted () {
    this.preference = this.$utils.find(this.preferences, ['code', 'default_payment_terms'])
    if (typeof this.preference !== 'undefined') {
      // set the options
      this.options = this.preference.options.map(option => {
        return {
          id: option.id,
          name: option.name,
          value: option.value
        }
      })
      if (this.preference.retailer_preference) {
        this.value = this.$utils.find(this.options, ['value', this.preference.retailer_preference.value])
      }
      this.announce()
    }
  },
  props: {
    preferences: {
      type: Array,
      required: true
    }
  }
}
</script>
