export const networkStatus = {
  label: 'Network Status',
  key: 'rcn_status',
  icon: 'pulse',
  tooltip: 'This is a tooltip',
  options: [{
    value: 'pending',
    label: 'Pending'
  }, {
    value: 'published',
    label: 'Published'
  }, {
    value: 'not_published',
    label: 'Not Published'
  }, {
    value: 'failed',
    label: 'Needs Data'
  }]
}

export const category = {
  label: 'Supplier Category',
  key: 'brand_category',
  icon: 'shape-outline',
  tooltip: 'This is a tooltip'
}
