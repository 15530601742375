<template>
  <div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Inventory Details</p>
      </header>
      <section class="modal-card-body">
        <!-- Seeded Variant -->
        <div v-if="selectedVariant !== null" class="pb-md">
          <Identifiers
            :variant="selectedVariant.variant"
            :showImage="false"
            nameInteraction="none"
            imageInteraction="none"
           />
        </div>
        <!-- Loaded Variant -->
        <div>
          <b-tabs
            v-model="activeTab"
            type="is-boxed"
            :animated="false">
            <b-tab-item>
              <template slot="header">
                <b-icon icon="information-outline"></b-icon>
                <span>Current</span>
              </template>
              <div v-if="!loading && inventory !== null">
                <InventoryInfo :inventory="inventory" />
              </div>
              <div v-else>
                <loading message="Loading inventory details..." />
              </div>
            </b-tab-item>
            <b-tab-item>
              <template slot="header">
                <b-icon icon="history"></b-icon>
                <span>History</span>
              </template>
              <div v-if="!loading && inventory !== null">
                <InventoryHistory v-if="activeTab === 1" :itemId="inventory.variant.id" />
              </div>
              <div v-else>
                <loading message="Loading inventory details..." />
              </div>
            </b-tab-item>
          </b-tabs>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button class="button" type="button" @click="$parent.close()">Close</button>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Identifiers from '@/components/Models/Variant/Identifiers'
import InventoryInfo from './InventoryInfo'
import InventoryHistory from './InventoryHistory'

export default {
  components: {
    Identifiers,
    InventoryInfo,
    InventoryHistory
  },
  data () {
    return {
      activeTab: 0,
      loading: true,
      inventory: null
    }
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      if (this.selectedVariant !== null) {
        return this.context.route + '/inventory/' + this.selectedVariant.variant.id + '/'
      }
      return null
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint).then(response => {
        this.inventory = response.data
      }).finally(() => {
        this.loading = false
      })
    }
  },
  mounted () {
    this.load()
  },
  props: ['selectedVariant']
}
</script>
