<template>
  <form @submit.prevent="save">
    <div class="columns">
      <div class="column">
        <div class="has-background-white rounded-xl">
          <!-- Loaded -->
          <div class="pa-lg pb-xl">
            <div class="mb-lg">
              <p class="has-text-weight-bold mb-xs">Retailer SKU Assignment</p>
              <div class="has-text-grey is-size-7">
                <p class="mb-md">
                  Enable Retailer SKU assignment if you would like the {{ app.productName }} platform
                  to generate unique SKUs for items as soon as they are approved by a member of
                  the {{ context.partner.name }} team.
                </p>
              </div>
            </div>
            <div class="columns">
              <div class="column is-half">
                <b-field>
                  <b-select
                    type="text"
                    v-model="value"
                    size="is-medium"
                    expanded
                    @input="isDirty = 1">
                    <option value="1">Enabled</option>
                    <option value="0">Disabled</option>
                  </b-select>
                </b-field>
              </div>
            </div>
          </div>
          <div class="border-t">
            <div class="columns is-vcentered is-marginless">
              <div class="column is-hidden-mobile">
                <div class="px-sm has-text-grey-light">
                  <i v-if="isDirty">Click 'Save Preference' to save your changes</i>
                </div>
              </div>
              <div class="column is-narrow">
                <div class="px-sm">
                  <b-button
                    native-type="submit"
                    type="is-primary rounded-lg"
                    :disabled="!ready || saving || !isDirty"
                  >Save Preference</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  computed: {
    ...mapGetters(['app', 'context']),
    endpoint () {
      return this.context.route + '/retailer-preferences/'
    },
    json () {
      return {
        preference: { id: this.preference.id },
        value: this.value
      }
    },
    ready () {
      return (this.value !== null)
    }
  },
  data () {
    return {
      isDirty: false,
      preference: null,
      value: 0,
      errors: [],
      saving: false,
      showSerialKey: false
    }
  },
  methods: {
    save () {
      this.saving = true
      this.$http.post(this.endpoint, this.json).then(response => {
        this.isDirty = false
        this.$emit('preferences:updated')
        this.$buefy.toast.open({ type: 'is-success', message: 'Preference updated!' })
      }).catch(error => {
        if (error.response && error.response.status === 400) {
          this.errors = error.response.data
        }
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    const preference = this.$utils.find(this.preferences, ['code', 'automatically_generate_retailer_skus'])
    if (typeof preference !== 'undefined') {
      this.preference = preference
      if (this.preference.retailer_preference) {
        this.value = this.preference.retailer_preference.value
      }
    }
  },
  props: ['preferences'],
  watch: {
    value (value) {
      if (value === 1) {
        this.showSerialKey = true
      }
    }
  }
}
</script>
