<template>
  <div>
    <!-- Content -->
    <div class="">
      <p class="mb-md">
        The {{ app.productName }} BigCommerce app can be installed to automatically push purchase
        orders into your BigCommerce store and pull tracking numbers once you ship. To install the app,
        please enter your credentials below:
      </p>
      <!-- Account Exists -->
      <div v-if="account !== null">
        <BigCommercePresenter :account="account" />
      </div>
      <!-- Account Doesn't Exist -->
      <div v-else class="mb-lg">
        <b-field label="Store Identifer">
          <b-input v-model="hash" size="is-medium"></b-input>
        </b-field>
        <b-field label="Store Token">
          <b-input v-model="token" size="is-medium"></b-input>
        </b-field>
      </div>
    </div>
    <!-- Navigate -->
    <div class="py-lg">
      <div class="columns is-centered">
        <div class="column">
          <b-button type="is-light rounded-lg" size="is-medium" @click="goBack" expanded>
            <b-icon icon="arrow-left"></b-icon>
          </b-button>
        </div>
        <div class="column">
          <b-button type="is-primary rounded-lg" size="is-medium" expanded @click="goForward"
            :disabled="saving || !ready" :loading="saving">Continue</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BigCommercePresenter from '@/components/Models/PlatformAccount/Presenters/BigCommerce'

export default {
  components: {
    BigCommercePresenter
  },
  computed: {
    ...mapGetters(['app', 'context']),
    ready () {
      return (this.account || this.hash || this.token)
    }
  },
  data () {
    return {
      hash: null,
      token: null,
      saving: false
    }
  },
  methods: {
    goBack () {
      this.$router.push({ name: 'onboarding.tasks', params: { connection: this.connection.id } })
    },
    goForward () {
      // if platform account exists, don't do anything
      if (this.account !== null) {
        this.$emit('navigate', 'Confirmation')
        return
      }
      this.saving = true
      const endpoint = this.context.route + '/platform-accounts/'
      const json = {
        platform: { code: 'bigcommerce' },
        credentials: [{
          credential: { code: 'bigcommerce_shop_hash' },
          value: this.hash
        }, {
          credential: { code: 'bigcommerce_token' },
          value: this.token
        }]
      }
      this.$http.post(endpoint, json).then(response => {
        this.$buefy.toast.open({ type: 'is-info', message: 'Platform Added!' })
        this.$emit('platform:saved')
        this.$emit('navigate', 'Confirmation')
      }).catch(() => {
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  props: {
    connection: {
      type: Object,
      required: true
    },
    task: {
      type: Object,
      required: true
    },
    account: {
      type: Object
    }
  }
}
</script>
