<template>
  <span>{{ amount }} {{ unit }}</span>
</template>

<script>
export default {
  computed: {
    amount () {
      if (this.value.value) {
        return this.$numbers(this.value.value).format({ mantissa: 2, thousandSeparated: true })
      }
      return null
    },
    symbol () {
      if (this.value.unit !== null) {
        return this.value.unit.symbol
      }
      return '$'
    },
    unit () {
      if (this.value.unit !== null) {
        return this.value.unit.standard_unit
      }
      return 'USD'
    }
  },
  props: ['value']
}
</script>
