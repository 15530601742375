<template>
  <div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Inspect Job</p>
      </header>
      <section class="modal-card-body">
        <!-- Loaded -->
        <div v-if="!loading && job">
          <!-- Error Logs -->
          <div v-if="job.error_logs.length > 0" class="mb-lg">
            <p class="has-text-weight-bold mb-sm">Errors ({{ job.error_logs.length }})</p>
            <table class="table is-fullwidth is-striped is-size-7 is-narrow border">
              <thead>
                <tr>
                  <th>Message</th>
                  <th class="has-text-centered" style="widht:96px">Count</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="error in job.error_logs" :key="error.id">
                  <td>{{ error.message }}</td>
                  <td class="has-text-centered">{{ error.count }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- Details -->
          <div>
            <p class="has-text-weight-bold mb-sm">Job Details</p>
            <table class="table is-fullwidth is-striped is-narrow is-size-7 border">
              <tbody>
                <tr>
                  <th style="width:164px">ID</th>
                  <td>{{ job.id }}</td>
                </tr>
                <tr>
                  <th>Status</th>
                  <td><Status :job="job" /></td>
                </tr>
                <tr>
                  <th>Job</th>
                  <td><ModuleName :module="job.module_name" /></td>
                </tr>
                <tr>
                  <th>Module</th>
                  <td>{{ job.module_name }}</td>
                </tr>
                <tr>
                  <th>Started At</th>
                  <td>
                    <span v-if="job.start_time">{{ $dates.utc(job.start_time).local().format('MMM D, YYYY @ h:mm A') }}</span>
                    <span v-else class="has-text-grey">--</span>
                  </td>
                </tr>
                <tr>
                  <th>Finished At</th>
                  <td>
                    <span v-if="job.finish_time">{{ $dates.utc(job.finish_time).local().format('MMM D, YYYY @ h:mm A') }}</span>
                    <span v-else class="has-text-grey">--</span>
                  </td>
                </tr>
                <tr>
                  <th>Runtime</th>
                  <td><Runtime :job="job" /></td>
                </tr>
                <tr v-if="job.total_success ">
                  <th>Success</th>
                  <td>{{ job.total_success }}</td>
                </tr>
                <tr v-if="job.total_failure ">
                  <th>Errors</th>
                  <td>{{ job.total_failure }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- Loading -->
        <div v-else>
          <loading message="Loading..." />
        </div>
      </section>
      <footer class="modal-card-foot space-between">
        <b-button @click="$parent.close()">Close</b-button>
        <b-button @click="loadJob">Refresh</b-button>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ModuleName from '@/components/Models/Job/Presenters/ModuleName'
import Runtime from '@/components/Models/Job/Presenters/Runtime'
import Status from '@/components/Models/Job/Presenters/Status'

export default {
  components: {
    ModuleName,
    Runtime,
    Status
  },
  computed: {
    ...mapGetters(['context']),
    loading () {
      return Boolean(this.loadingJob)
    }
  },
  data () {
    return {
      loadingJob: true,
      job: null,
      showDetails: false
    }
  },
  methods: {
    loadJob () {
      this.loadingJob = true
      const endpoint = this.context.route + '/jobs/' + this.seed.id + '/'
      this.$http.get(endpoint).then(response => {
        this.job = response.data
        this.loadingJob = false
      })
    }
  },
  mounted () {
    if (this.seed) {
      this.loadJob()
    }
  },
  props: {
    seed: {
      type: [Object, null]
    }
  },
  watch: {
    seed (value) {
      if (value) {
        this.loadJob()
      }
    }
  }
}
</script>
