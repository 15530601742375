<template>
  <span>{{ nameValue }}</span>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['context']),
    hasMerchantName () {
      return (
        this.context.isMerchant() &&
        this.variant.retailer_identifiers.length > 0 &&
        this.variant.retailer_identifiers[0].name
      )
    },
    nameValue () {
      return (this.hasMerchantName) ? this.variant.retailer_identifiers[0].name : this.variant.name
    }
  },
  props: ['variant']
}
</script>
