<template>
  <div>
    <div class="mt-md mb-md" v-if="getJobMetaFields.product_failed_rows || getJobMetaFields.product_failed_rows_errors">
      <b-message class="is-size-7" type="is-warning">
        <span class="is-size-5">We detected some issues with the file you uploaded…</span>
        <p>We detected that your import contained some errors. To assist you with troubleshooting, we can provide you with...</p>
        <p>1. <DownloadFileAs label="Download" :cloudFileName="getJobMetaFields.product_failed_rows_errors"
          :originalFileName="`${feedDetail.id}-failed-rows-errors`"/> the list of SKUs along with ALL of the error messages.</p>
        <p>2. <DownloadFileAs label="Download" :cloudFileName="getJobMetaFields.product_failed_rows"
          :originalFileName="`${feedDetail.id}-failed-rows`"/> a template that contains only the SKUs that had an error so that you may fix the SKUs and try again.</p>
      </b-message>
    </div>
    <table class="table is-fullwidth is-striped is-narrow is-size-8 border">
      <tbody>
        <tr>
          <th>File</th>
          <td class="has-text-info">{{ feedDetail.original_filename }} <span class="has-text-grey">(Import ID: {{ feedDetail.id }})</span></td>
        </tr>
        <tr>
          <th>Template</th>
          <td>{{ feedDetail.template.name }} <span class="has-text-grey">({{ getFeedType }})</span></td>
        </tr>
        <tr>
          <th>Status</th>
          <td>{{ getJobMetaFields.status }}</td>
        </tr>
        <tr>
          <th>Started</th>
          <td>
            <span v-if="feedDetail.processed_at">{{ $dates.utc(feedDetail.processed_at).local().format('MMM D, YYYY @ h:mm A') }}</span>
            <span v-else class="has-text-grey is-italic">Not Started</span>
          </td>
        </tr>
        <tr>
          <th>Completed</th>
          <td>
            <span v-if="feedDetail.completed_at">{{ $dates.utc(feedDetail.completed_at).local().format('MMM D, YYYY @ h:mm A') }}
              (<Runtime :start="feedDetail.processed_at" :end="feedDetail.completed_at" />)
            </span>
            <span v-else class="has-text-grey is-italic">Not Completed</span>
          </td>
        </tr>
        <tr>
          <th>Rows</th>
          <td>{{ feedDetail.num_items }}</td>
        </tr>
        <tr>
          <th>Items created</th>
          <td>
            {{ feedDetail.num_created }}
          </td>
        </tr>
        <tr>
          <th>Items updated</th>
          <td>{{ feedDetail.num_updated }}</td>
        </tr>
        <tr>
          <th>Items created/updated with errors</th>
          <td>{{ getJobMetaFields.create_with_error }}</td>
        </tr>
        <tr>
          <th>Failed to create</th>
          <td>{{ getJobMetaFields.failed_to_create }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Runtime from '@/components/Models/FeedQueue/Presenters/Runtime'

export default {
  components: {
    Runtime
  },
  computed: {
    getFeedType () {
      return `${this.$utils.upperFirst(this.feedDetail.direction)} ${this.$utils.upperFirst(this.feedDetail.data_type)}`
    },
    getJobMetaFields () {
      const fields = {
        status: 'Queued',
        create_with_error: 0,
        failed_to_create: 0,
        product_failed_rows: null,
        product_failed_rows_errors: null
      }
      const feedDetailsJobs = this.feedDetail.jobs
      if (feedDetailsJobs.length && feedDetailsJobs[0]?.metadata.length) {
        const keysToProcess = ['status', 'create_with_error', 'failed_to_create', 'product_failed_rows', 'product_failed_rows_errors']
        feedDetailsJobs[0].metadata.forEach(item => {
          if (keysToProcess.includes(item.name)) {
            fields[item.name] = item.value
          }
        })
      }
      return fields
    }
  },
  props: {
    feedDetail: {
      type: Object,
      required: true
    }
  }
}
</script>
