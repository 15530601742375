import dates from '@/assets/js/dates'

export const connection = {
  label: 'Connection',
  key: 'connection_id',
  icon: 'pulse',
  tooltip: 'This is a tooltip'
}

export const acknowledged = {
  label: 'Acknowledged?',
  key: 'acknowledged',
  icon: 'pulse',
  tooltip: 'This is a tooltip',
  options: [{
    value: 1,
    label: 'Yes'
  }, {
    value: 0,
    label: 'No'
  }]
}

export const shippedAt = {
  label: 'Created At',
  key: 'shipped_at',
  icon: 'pulse',
  tooltip: 'This is a tooltip',
  options: dates
}
