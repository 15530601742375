<template>
  <div class="columns">
    <div class="column is-one-fifth">
      <p class="has-text-weight-bold is-size-6 mb-sm">
        Requested Shipping Method
      </p>
      <p class="has-text-grey">Select a requested shipping method.</p>
    </div>
    <div class="column">
      <div class="has-background-white rounded-lg pa-md pb-lg">
        <GenericShippingMethod
          v-model="shippingMethod"
          methodType="['Ground', 'Expedited']"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import GenericShippingMethod from '@/components/Models/ShippingMethod/GenericShippingMethod'
export default {
  components: {
    GenericShippingMethod
  },
  computed: {
    ...mapGetters(['app', 'api', 'context'])
  },
  data: () => {
    return {
      shippingMethod: null
    }
  },
  methods: {},
  mounted () {},
  props: [],
  watch: {
    shippingMethod (value) {
      this.$emit('input', { shippingMethod: value })
    }
  }
}
</script>
