import dates from '@/assets/js/dates'

export const functionalIdentifiers = {
  label: 'Functional Identifier',
  key: 'functional_identifiers',
  icon: 'file-document-box-multiple-outline',
  tooltip: 'The functional identifier or document type.',
  options: [{
    value: 'po',
    label: 'Purchase Order (850)'
  }, {
    value: 'ib',
    label: 'Inventory Advice (846)'
  }, {
    value: 'fa',
    label: 'Functional Acknowledgement (997)'
  }, {
    value: 'sh',
    label: 'Advance Ship Notice (856)'
  }, {
    value: 'in',
    label: 'Invoice (810)'
  }]
}

export const directions = {
  label: 'Direction',
  key: 'direction',
  icon: 'arrow-collapse',
  tooltip: 'The direction of the document',
  options: [{
    value: 'incoming',
    label: 'Received from Supplier'
  }, {
    value: 'outgoing',
    label: 'Sent to Supplier'
  }]
}
export const transmittedAt = {
  label: 'Transmitted At',
  key: 'transmitted_at',
  icon: 'calendar-outline',
  tooltip: 'Filter envelopes based the date and time they were transmitted or received.',
  options: dates
}
export const createdAt = {
  label: 'Created At',
  key: 'created_at',
  icon: 'calendar-outline',
  tooltip: 'Filter envelopes based the date and time they were created',
  options: dates
}
