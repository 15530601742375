<template>
<div>
  <div class="section">
    <div class="container">
      <!-- Connection Loaded -->
      <div>
        <div v-if="!loading && connection !== null" class="mb-lg">
          <router-view :connection="connection" @connection:updated="load(true)"></router-view>
        </div>
        <!-- Loading -->
        <div v-else>
          <loading message="Loading onboarding details..." />
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  computed: {
    ...mapGetters(['context']),
    breadcrumbs () {
      return {
        current: null,
        history: [
          { label: 'Onboarding', name: 'onboarding' },
          { label: 'Tasks', name: 'onboarding.tasks', params: { connection: this.$route.params.connection } }
        ]
      }
    },
    endpoint () {
      return this.context.route + '/connections/' + this.$route.params.connection + '/'
    }
  },
  data () {
    return {
      loading: true,
      connection: null
    }
  },
  methods: {
    load (silent = false) {
      if (!silent) this.loading = true
      this.$http.get(this.endpoint).then(response => {
        this.connection = response.data
        this.loading = false
      })
    }
  },
  mounted () {
    this.load()
  }
}
</script>
