<template>
  <div>
    <!-- Page Header -->
    <div class="pt-lg has-text-centered">
      <h1 class="is-size-4">Commerce Network</h1>
    </div>
    <!-- Action Items -->
    <div class="section">
      <div class="container">
        <div class="columns is-centered">
          <div class="column ">
            <div class="columns">
              <div class="column">
                <div class="rounded-lg has-background-white pa-lg mb-lg">
                  <profile-status :marketplace="marketplace" />
                </div>
                <div class="rounded-lg has-background-white pa-lg mb-lg">
                  <h4 class="has-text-weight-bold">Still Onboarding?</h4>
                  <p class="is-size-7 has-text-grey mb-md">Click below to resume onboarding.</p>
                  <p><router-link class="button is-primary is-outlined is-fullwidth" :to="{ name: 'network.onboarding'}">Resume Onboarding</router-link></p>
                </div>
              </div>
              <!-- Action Items -->
              <div class="column is-three-quarters">
                <div class="rounded-lg has-background-white px-xl mb-lg">
                  <!-- Product Request Component -->
                  <product-request-summary />
                  <!-- Catalog / Publisher (Vendors Only) -->
                  <router-link v-if="context.type === 'vendor'" :to="{ name: 'network.products' }">
                    <div class="has-border-b py-lg">
                      <div class="columns is-vcentered is-mobile">
                        <div class="column">
                          <div class="">
                            <h4 class="has-text-weight-bold mb-xs">My Products</h4>
                            <p class="has-text-grey">
                              Manage your catalog and publish products for merchants to discover.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </router-link>
                  <!-- Pricing Update (Vendors Only) -->
                  <router-link :to="{ name: 'network.connections' }">
                    <div class="has-border-b py-lg">
                      <div class="columns is-vcentered is-mobile">
                        <div class="column">
                          <div class="">
                            <h4 class="has-text-weight-bold mb-xs">My Connections</h4>
                            <p class="has-text-grey">
                              Manage your network and invite new partners to join {{ app.platform }}.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </router-link>
                  <!-- Discovery (Retailers Only) -->
                  <router-link v-if="context.type !== 'vendor'" :to="{ name: 'discover.dashboard' }">
                    <div class="has-border-b py-lg">
                      <div class="columns is-vcentered is-mobile">
                        <div class="column">
                          <div class="">
                            <h4 class="has-text-weight-bold mb-xs">Discover New Products</h4>
                            <p class="has-text-grey">
                              Browse your connections' catalogs for new products.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </router-link>
                  <!-- Profile Preview -->
                  <profile-preview :marketplace="marketplace" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Tour -->
    <b-modal :active.sync="showGuide" has-modal-card>
      <div class="modal-card">
        <section class="modal-card-body">
          <!-- How It Works -->
          <div class="has-text-centered mb-lg">
            <div class="mb-md">
              <p class="has-text-weight-bold is-size-5">Welcome to the {{ app.platform }} Commerce Network!</p>
              <p class="has-text-grey">Click on a step below to learn more about how to get started!</p>
            </div>
            <div class="columns">
              <div class="column">
                <a href="#"
                  class="rounded-lg is-block pa-md"
                  :class="(guideStep === 'publish') ? 'active-button' : 'inactive-button'"
                  @click.prevent="guideStep = 'publish'"
                  >Publish <br> Products</a>
              </div>
              <div class="column">
                <a href="#"
                  class="rounded-lg is-block pa-md"
                  :class="(guideStep === 'invite') ? 'active-button' : 'inactive-button'"
                  @click.prevent="guideStep = 'invite'"
                  >Invite <br> Merchants</a>
              </div>
              <div class="column">
                <a href="#"
                  class="rounded-lg is-block pa-md"
                  :class="(guideStep === 'fulfill') ? 'active-button' : 'inactive-button'"
                  @click.prevent="guideStep = 'fulfill'"
                  >Fulfill <br> Orders</a>
              </div>
              <div class="column">
                <a href="#"
                  class="rounded-lg is-block pa-md"
                  :class="(guideStep === 'payments') ? 'active-button' : 'inactive-button'"
                  @click.prevent="guideStep = 'payments'"
                  >Collect <br> Payments</a>
              </div>
            </div>
          </div>
          <!-- Details -->
          <div class="has-background-white-ter pa-md rounded-lg">
            <transition name="fade" mode="out-in">
              <!-- Publish -->
              <div v-if="guideStep === 'publish'" key="publish">
                <div class="py-sm">
                  <p class="has-text-centered has-text-weight-bold mb-sm">Publish Products</p>
                  <p>
                    Since you're brand new to the network, your products & profile
                    are <span class="has-text-danger"><b>NOT VISIBLE</b></span> to merchants.
                    To publish your profile, you'll need to:
                  </p>
                </div>
                <div class="py-sm">
                  <div class="mb-sm">
                    <div class="columns is-vcentered">
                      <div class="column is-narrow">
                        <b-icon icon="chevron-right" />
                      </div>
                      <div class="column">
                        <p class="has-text-weight-bold">Setup Your Brand Profile</p>
                        <p class="is-size-7">Customize your profile that promote your brand values.</p>
                      </div>
                    </div>
                  </div>
                  <div class="mb-sm">
                    <div class="columns is-vcentered">
                      <div class="column is-narrow">
                        <b-icon icon="chevron-right" />
                      </div>
                      <div class="column">
                        <p class="has-text-weight-bold">Update Product Costs & MAPs</p>
                        <p class="is-size-7">Set your all-in costs (e.g. wholesale + shipping) and minimum selling prices.</p>
                      </div>
                    </div>
                  </div>
                  <div class="mb-sm">
                    <div class="columns is-vcentered">
                      <div class="column is-narrow">
                        <b-icon icon="chevron-right" />
                      </div>
                      <div class="column">
                        <p class="has-text-weight-bold">Publish Products</p>
                        <p class="is-size-7">
                          Select and publish only the products that you want potential partners to see.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="py-sm">
                  <p>
                    Each of these tasks can be completed on the page as soon
                    as you close this guide. Once each step is complete, you
                    may request approval and you will be live on the
                    {{ app.platform }} Commerce Network!
                  </p>
                </div>
                <div class="py-md">
                  <div class="columns is-centered">
                    <div class="column is-one-third">
                      <b-button type=" rounded-lg is-fullwidth" @click.prevent="showGuide = false">Close</b-button>
                    </div>
                    <div class="column is-one-third">
                      <b-button type="rounded-lg is-fullwidth" @click.prevent :disabled="true">Previous</b-button>
                    </div>
                    <div class="column is-one-third">
                      <b-button type="is-primary rounded-lg is-fullwidth" @click.prevent="guideStep = 'invite'">Next</b-button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Approve -->
              <div v-else-if="guideStep === 'invite'" key="approve">
                <div class="py-sm">
                  <p class="has-text-centered has-text-weight-bold">Invite Merchants</p>
                  <div class="py-sm">
                    <p>
                      Once your profile is published, you can send your
                      profile link out to prospective merchants. If a merchant
                      joins {{ app.platform }} to sell your products, you'll both
                      earn commission-free transactions!
                    </p>
                  </div>
                  <div class="py-sm">
                    <div class="has-background-white rounded-lg has-text-centered pa-md">
                      <p class="has-text-grey">Your Link:</p>
                      <p class="is-size-5"></p>
                    </div>
                  </div>
                  <div class="py-sm">
                    <p>
                      In addition, your profile will be published for merchants
                      that are already part of the {{ app.platform }} network. As merchants
                      discover your profile, you may receive requests to sell
                      your products on their websites at the prices you set & approve.
                    </p>
                  </div>
                  <div class="py-sm">
                    <p>
                      Always remember that in the {{ app.platform }} Commerce Network,
                      you are always in control of your products and your pricing!
                    </p>
                  </div>
                  <div class="py-md">
                    <div class="columns is-centered">
                      <div class="column is-one-third">
                        <b-button type=" rounded-lg is-fullwidth" @click.prevent="showGuide = false">Close</b-button>
                      </div>
                      <div class="column is-one-third">
                        <b-button type="rounded-lg is-fullwidth" @click.prevent="guideStep = 'publish'">Previous</b-button>
                      </div>
                      <div class="column is-one-third">
                        <b-button type="is-primary rounded-lg is-fullwidth" @click.prevent="guideStep = 'fulfill'">Next</b-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Fulfill -->
              <div v-else-if="guideStep === 'fulfill'" key="fulfill">
                <div class="py-sm">
                  <p class="has-text-centered has-text-weight-bold mb-sm">Fulfill Orders</p>
                  <p>
                    When merchants sell your products, you will receive
                    purchase order notifications from {{ app.platform }}. In most cases,
                    you will have 2 business days to process and ship orders. Orders
                    should be shipped in plain cartons with no additional
                    marketing materials.
                  </p>
                </div>
                <div class="py-sm">
                  <p class="has-text-centered has-text-weight-bold mb-sm">Cancellation Policy</p>
                  <p>
                    If you can't fulfill all or part of a purchase order, you
                    are allowed to cancel line items for no fee.
                    Please note that you must either fill or cancel the entire line.
                    For example, if a customer orders 4 units, you must ship 4 units or 0 units (no
                    partial shipments please). Excessive cancellation rates
                    will reduce your published compliance score and you
                    may be asked to leave the platform.
                  </p>
                </div>
                <div class="py-sm">
                  <p class="has-text-centered has-text-weight-bold mb-sm">Returns Policy</p>
                  <p>
                    As a supplier, you are expected to allow returns of your items sold
                    through the {{ app.platform }} Commerce Network. However, you will NOT be
                    responsible for return shipping costs and will have the opportunity
                    to inspect and approve each return before a credit is issued to the Merchant.
                  </p>
                </div>
                <div class="py-sm">
                  <p class="has-text-centered has-text-weight-bold mb-sm">Replacement Policy</p>
                  <p>
                    If an incorrect item is shipped to the customer or if an item arrives
                    broken or damaged, you will be asked to ship a replacement unit as soon
                    as possible to resolve the customer complaint. {{ app.platform }} will then
                    assist in determining the appropriate corrective action (financial or otherwise).
                  </p>
                </div>
                <div class="py-md">
                  <div class="columns is-centered">
                    <div class="column is-one-third">
                      <b-button type=" rounded-lg is-fullwidth" @click.prevent="showGuide = false">Close</b-button>
                    </div>
                    <div class="column is-one-third">
                      <b-button type="rounded-lg is-fullwidth" @click.prevent="guideStep = 'invite'">Previous</b-button>
                    </div>
                    <div class="column is-one-third">
                      <b-button type="is-primary rounded-lg is-fullwidth" @click.prevent="guideStep = 'payments'">Next</b-button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Payments -->
              <div v-else-if="guideStep === 'payments'" key="payments">
                <div class="py-sm">
                  <p class="has-text-centered has-text-weight-bold mb-sm">Payments</p>
                  <p>Description</p>
                </div>
              </div>
            </transition>
          </div>
        </section>
      </div>
    </b-modal>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ProductRequestSummary from '../components/ProductRequestSummary'
import ProfilePreview from '../components/ProfilePreview'
import ProfileStatus from '../components/ProfileStatus'

export default {
  components: {
    ProductRequestSummary,
    ProfilePreview,
    ProfileStatus
  },
  computed: {
    ...mapGetters(['app', 'context']),
    endpoint () {
      return this.context.route + '/marketplaces/?mine=1'
    }
  },
  data () {
    return {
      showGuide: false,
      guideStep: 'publish',
      loading: true,
      marketplace: null
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint).then(response => {
        if (response.data.results.length > 0) {
          this.marketplace = response.data.results[0]
        }
      }).finally(() => {
        this.loading = false
      })
    }
  },
  mounted () {
    if (this.profile.onboarded_at === null) {
      this.$router.push({ name: 'network.onboarding' })
    } else {
      this.load()
    }
  },
  props: ['profile']
}
</script>

<style scoped>
  .active-button {
    background-color: #2E3E51 !important;
    color: #EFF1F9;
    font-size: 0.875rem;
  }
  .inactive-button {
    background-color: #EFF1F9 !important;
    color: #2E3E51;
    font-size: 0.875rem;
  }
</style>
