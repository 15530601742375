<template>
  <span class="has-text-weight-bold" :class="presenter.indicatorClass">{{ presenter.label }}</span>
</template>

<script>
export default {
  computed: {
    presenter () {
      const presenter = {
        label: 'Not Finalized',
        tooltip: 'This invoice has been registered, but because the order is not closed, is subject to change.',
        indicatorClass: 'has-text-grey'
      }
      // if the invoice is closed, but not acknowledged
      if (this.invoice.status === 'closed' && this.invoice.acknowledged_at === null) {
        presenter.label = 'Sent'
        presenter.tooltip = 'Invoice sent, but the merchant has not yet acknowledged receipt.'
        presenter.indicatorClass = 'has-text-warning'
      }
      // if the invoice is closed, but acknowledged
      if (this.invoice.status === 'closed' && this.invoice.acknowledged_at !== null) {
        presenter.label = 'Received'
        presenter.tooltip = 'The merchant has received this invoice.'
        presenter.indicatorClass = 'has-text-success'
      }
      return presenter
    }
  },
  props: ['invoice']
}
</script>
