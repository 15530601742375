<template>
  <div class="has-background-white rounded-lg">
    <!-- Loading -->
    <div v-if="loading">
      <loading message="Loading Transformer..." />
    </div>
    <!-- Loaded -->
    <div v-else>
      <div class="pa-lg">
        <!-- Transformer Name -->
        <b-field
          label="Transformer Name"
          expanded>
          <b-input
            type="text"
            size="is-medium"
            @input="isDirty = 1"
            v-model="name"
          ></b-input>
        </b-field>
      </div>
      <div class="border-t" />
      <div class="columns is-vcentered is-marginless">
        <div class="column is-hidden-mobile">
          <div class="px-sm has-text-grey-light">
            <i v-if="isDirty">Click 'Update' to save your changes</i>
          </div>
        </div>
        <div class="column is-narrow ">
          <div class="px-sm">
            <b-button
              type="is-primary rounded-lg"
              class="is-pulled-right"
              :disabled="saving || !isDirty"
              :loading="saving"
              @click="update"
            >Update
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {
  },
  computed: {
    ...mapGetters(['app', 'api', 'context']),
    endpoint () {
      return this.context.route + `/transformers/${this.$route.params.id}/`
    },
    json () {
      return {
        name: this.name
      }
    }
  },
  data: () => {
    return {
      name: null,
      isDirty: false,
      saving: false
    }
  },
  methods: {
    // Update transformer
    update () {
      this.saving = true
      this.$http.patch(this.endpoint, this.json).then(response => {
        this.errors = {}
        this.$buefy.toast.open({ type: 'is-success', message: 'Transformer updated!' })
      }).catch(error => {
        this.$buefy.toast.open({ type: 'is-danger', message: 'Error updating transformer!' })
        this.errors = error.response.data
      }).finally(() => {
        this.saving = false
        this.isDirty = false
      })
    }
  },
  mounted () {
    this.name = this.transformerName
  },
  watch: {
    transformerName () {
      this.name = this.transformerName
    }
  },
  props: ['loading', 'transformerName']
}
</script>
