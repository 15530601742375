<template>
  <div>
    <div class="columns">
      <div class="column is-one-fifth">
        <p class="has-text-weight-bold is-size-6 mb-sm">Push Order</p>
        <p class="has-text-grey is-size-7">Attempt to push an order to {{ account.platform.name }}.</p>
      </div>
      <div class="column">
        <div class="has-background-white rounded-lg">
          <div class="pa-lg">
            <!-- Intro Text (Merchant) -->
            <div class="is-size-7 mb-lg">
              <p class="mb-md">
                If an order didn't import into {{ account.platform.name }}, its likely there
                was a problem creating an order with the correct SKUs. If you
                have doubled checked the SKUs, you
                can attempt to push the order again by supplying the
                {{ app.productName }} order ID.
              </p>
            </div>
            <!-- Form -->
            <form @submit.prevent="pushOrder" class="mb-lg">
              <p class="has-text-weight-bold mb-sm">{{ app.platform.name }} Purchase Order Number (PO #)</p>
              <div class="mb-lg">
                <div class="columns">
                  <div class="column">
                    <b-field>
                      <b-input
                        autocomplete="off"
                        type="number"
                        size="is-medium"
                        v-model="purchaseOrderNumber"
                      ></b-input>
                    </b-field>
                  </div>
                  <div class="column is-narrow">
                    <b-button
                      type="is-primary"
                      native-type="submit"
                      size="is-medium"
                      :loading="searching || processing"
                      :disabled="searching || processing || !valid"
                    >Push Order</b-button>
                  </div>
                </div>
              </div>
            </form>
            <!-- Job Result -->
            <div class="is-size-7">
              <div v-if="searching">
                <p class="has-text-grey-light is-italic">Searching for order...</p>
              </div>
              <div v-else-if="processing">
                <p class="has-text-grey-light is-italic">Scheduling retry...</p>
              </div>
              <div v-else-if="errorMessage !== null">
                <p class="has-text-danger is-italic">{{ errorMessage }}</p>
              </div>
              <div v-else-if="job !== null">
                <span class="has-text-weight-bold has-text-success">Retry Scheduled!</span>
                Your input resulted in scheduled job <a href="#" class="has-text-info" @click.prevent="inspectJob = job">#{{ job.id }}</a>.
                Please allow 5-10 minutes this task to complete. Please refer to the job status for more information.
              </div>
              <div v-else>
                <p class="has-text-grey-light is-italic">Enter a {{ app.productName}} Purchase Order Number above and click 'Retry Order'.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Inspect Job -->
    <b-modal
      v-if="(inspectJob !== null)"
      has-modal-card
      :active="(inspectJob !== null)"
      :can-cancel="['escape', 'x']"
      @close="inspectJob = null"
      ><JobModal :selectedJob="inspectJob" />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import JobModal from '@/components/Modals/Job/JobModal'
export default {
  components: {
    JobModal
  },
  computed: {
    ...mapGetters(['app', 'context']),
    endpoint () {
      return this.context.route + '/shipstation/' + this.account.id
    },
    queryString () {
      if (!this.purchaseOrderNumber) return null
      return {
        params: {
          purchase_order_number: this.purchaseOrderNumber,
          short: 1
        }
      }
    },
    json () {
      return {
        order_id: parseInt(this.orderId),
        force_order_push: (this.forcePush) ? 1 : 0
      }
    },
    valid () {
      return (parseInt(this.purchaseOrderNumber) > 0)
    }
  },
  data () {
    return {
      purchaseOrderNumber: null,
      orderId: null,
      forcePush: true,
      searching: false,
      processing: false,
      job: null,
      inspectJob: null,
      errorMessage: null
    }
  },
  methods: {
    reset () {
      this.searching = false
      this.processing = false
      this.orderId = null
      this.errorMessage = null
      this.job = null
    },
    pushOrder () {
      // look for order first...
      this.reset()
      this.searching = true
      this.$http.get(this.context.route + '/orders/', this.queryString).then(response => {
        // if we have exactly 1 result, attempt to retry the order using the  Order ID
        if (response.data.results.length === 1) {
          this.orderId = response.data.results[0].id
          this.searching = false
          this.processing = true
          this.$http.put(this.endpoint + '/push-order/', this.json).then(response => {
            this.$buefy.toast.open({ type: 'is-success', message: 'Order scheduled!' })
            this.orderId = null
            this.purchaseOrderNumber = null
            this.job = response.data
          }).catch(error => {
            this.$buefy.toast.open({ type: 'is-danger', message: 'Order push failed!' })
            if (error.response.status === 400 && error.response.data === 'Invalid Request') {
              this.errorMessage = 'An error occurred while attempting to import your order. It is likely that the order Number you supplied is not correct. Please double check your Shopify Order Number and try again.'
            }
          }).finally(() => {
            this.processing = false
          })
        // no order could be found
        } else {
          this.errorMessage = 'Purchase order number not found! Please check your purchase order number and try again.'
        }
      }).finally(() => {
        this.searching = false
      })
    }
  },
  mounted () {

  },
  props: {
    account: {
      type: Object,
      required: true
    }
  }
}
</script>
