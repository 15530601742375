<template>
  <form @submit.prevent="searchOrders">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Advanced Order Search</p>
      </header>
      <section class="modal-card-body">
        <!-- Info -->
        <div class="mb-md">
          <b-message type="is-info is-size-7">
            Please enter your search criteria below. Please note that <b>exact</b> filters
            will return results much faster than <b>contains</b> filters.
          </b-message>
        </div>
        <!-- Parameter -->
        <div class="mb-md">
          <FieldLabel label="Identifier" />
          <b-select placeholder="Identifer" v-model="parameter" expanded>
            <option value="purchase_order_number_sw">PO Number (exact)</option>
            <option value="purchase_order_number">PO Number (contains)</option>
            <option value="customer_order_number_sw">Customer Order Number (exact)</option>
            <option value="customer_order_number">Customer Order Number (contains)</option>
            <option value="tracking_number">Tracking Number</option>
            <option value="invoice_number">Invoice Number</option>
          </b-select>
        </div>
        <!-- Identifier -->
        <div class="mb-md">
          <FieldLabel :label="identifierLabel" />
          <b-input type="text" v-model="identifer" />
        </div>
        <!-- Connection -->
        <div v-if="requiresConnection" class="mb-md">
          <FieldLabel label="Supplier (optional)" :required="false" />
          <b-autocomplete
            v-model="searchConnection"
            placeholder="Search for suppliers..."
            max-height="100px"
            :loading="loadingConnections"
            :disabled="loadingConnections"
            :keep-first="false"
            :open-on-focus="true"
            :data="filteredConnections"
            field="brand.name"
            :clearable="true"
            @select="option => connection = option">
          </b-autocomplete>
        </div>
        <!-- Status -->
        <div class="mb-md">
          <FieldLabel label="Order Status (optional)" :required="false" />
          <b-select placeholder="Select Status" v-model="status" expanded @input="$refs.myAutocomplete.focus()">
            <option value="any">Any</option>
            <option value="open,partial">Open</option>
            <option value="closed">Closed</option>
          </b-select>
        </div>
        <!-- Results -->
        <div v-if="performedSearch" class="mb-lg">
          <p class="mb-sm has-text-weight-bold">Search Results</p>
          <div v-if="!loadingOrders && results.length > 0">
            <div v-for="order in results" :key="order.id">
              <div class="has-background-light py-sm px-md mb-sm">
                <a href="#" @click="viewOrder(order)" class="has-text-info has-text-weight-bold">
                  Order #
                  <span v-if="parameter === 'customer_order_number' || parameter === 'customer_order_number_sw'">{{ order.customer_order_number }}</span>
                  <span v-else>{{ order.purchase_order_number }}</span>
                </a>
              </div>
            </div>
          </div>
          <div v-else-if="!loadingOrders && results.length === 0 && performedSearch">
            <p class="has-text-warning">No results found. Please check your filters and try again.</p>
          </div>
          <div v-else>
            <b-skeleton height="25" />
          </div>
        </div>
      </section>
      <footer class="modal-card-foot space-between">
        <div>
          <button class="button" type="button" @click="$parent.close()">Close</button>
          <b-button @click="reset">Reset</b-button>
        </div>
        <b-button
          native-type="submit"
          :disabled="!ready || loadingOrders"
          :loading="loadingOrders"
          type="is-primary"
        >Search</b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {

  },
  computed: {
    ...mapGetters(['context']),
    identifierLabel () {
      switch (this.parameter) {
        case 'purchase_order_number':
        case 'purchase_order_number_sw':
          return 'Purchase Order Number'
        case 'customer_order_number':
        case 'customer_order_number_sw':
          return 'Customer Order Number'
        case 'invoice_number':
          return 'Invoice Number'
        case 'tracking_number':
          return 'Tracking Number'
        default:
          return 'Identifier'
      }
    },
    loading () {
      return (this.loadingConnections || this.loadingOrders)
    },
    endpoint () {
      switch (this.parameter) {
        case 'tracking_number':
          return this.context.route + '/shipments/'
        case 'invoice_number':
          return this.context.route + '/invoices/'
        default:
          return this.context.route + '/orders/'
      }
    },
    filteredConnections () {
      return this.connections.filter((option) => {
        return option.brand.name.toString().toLowerCase().indexOf(this.searchConnection.toLowerCase()) >= 0
      })
    },
    requiresConnection () {
      return (
        this.parameter === 'customer_order_number' ||
        this.parameter === 'purchase_order_number' ||
        this.parameter === 'tracking_number' ||
        this.parameter === 'invoice_number'
      )
    },
    query () {
      const params = {
        fields: null,
        order_by: '-id'
      }
      // attach status (if set)
      if (this.status === 'open,partial' || this.status === 'closed') {
        params.status = this.status
      }
      // attach connection (if set)
      if (this.connection) {
        params.connection_id = this.connection.id
      }
      // attach additional search param based on what user has selected
      switch (this.parameter) {
        case 'purchase_order_number_sw':
          params.purchase_order_number_sw = this.identifer
          params.fields = 'id,purchase_order_number,customer_order_number'
          break
        case 'customer_order_number_sw':
          params.customer_order_number_sw = this.identifer
          params.fields = 'id,purchase_order_number,customer_order_number'
          break
        case 'customer_order_number':
          params.customer_order_number = this.identifer
          params.fields = 'id,purchase_order_number,customer_order_number'
          break
        case 'tracking_number':
          params.search = this.identifer
          params.fields = 'id,order_id,purchase_order_number,customer_order_number,tracking_number'
          break
        case 'invoice_number':
          params.search = this.identifer
          params.fields = 'id,order_id,purchase_order_number,customer_order_number,invoice_number'
          break
        default:
          params.purchase_order_number = this.identifer
          params.fields = 'id,purchase_order_number,customer_order_number'
      }
      return params
    },
    ready () {
      return this.identifer
    }
  },
  data () {
    return {
      loadingConnections: true,
      loadingOrders: false,
      connections: [],
      searchConnection: '',
      connection: null,
      parameter: 'purchase_order_number_sw',
      identifer: null,
      status: 'any',
      performedSearch: false,
      results: []
    }
  },
  methods: {
    reset () {
      this.performedSearch = false
      this.loadingOrders = false
      this.searchConnection = ''
      this.connection = null
      this.parameter = 'purchase_order_number_sw'
      this.identifer = null
      this.status = 'any'
      this.results = []
    },
    loadConnections () {
      this.loadingConnections = true
      const endpoint = this.context.route + '/connections/'
      const query = {
        params: { mini: 1, pagination: 0, order_by: 'brand__name' }
      }
      this.$http.get(endpoint, query).then(response => {
        this.connections = response.data.results
        this.loadingConnections = false
      })
    },
    searchOrders () {
      this.loadingOrders = true
      this.performedSearch = true
      this.$http.get(this.endpoint, { params: this.query }).then(response => {
        this.results = response.data.results
        this.loadingOrders = false
      })
    },
    viewOrder (order) {
      if (this.parameter === 'tracking_number' || this.parameter === 'invoice_number') {
        this.$router.push({ name: 'order.summary', params: { id: order.order_id } })
        return
      }
      this.$router.push({ name: 'order.summary', params: { id: order.id } })
    }
  },
  mounted () {
    this.loadConnections()
  }
}
</script>
