<template>
  <div>
    <div>
      <span class="has-text-weight-semibold">Currency</span>
      <p class="has-text-grey-light">These settings will be used to apply locale-specific configurations (e.g. cost & price on products, etc).</p>
    </div>
    <!-- Error -->
    <div v-if="('general' in errors)" class="mb-md">
      <p class="has-text-danger">{{ errors.general }}</p>
    </div>
    <!-- Fields -->
    <div class="mt-md">
      <b-field label="Select Currency" custom-class="required">
        <CurrencyOptionsSelect v-model="storeCurrency"/>
      </b-field>
      <!-- <p class="is-size-7 has-text-grey-light">
        <a href="#" @click.prevent="$store.dispatch('supportRequest')" class="has-text-info">Click here</a>
        to let us know if your currency is missing
      </p> -->
    </div>
    <!-- Navigate -->
    <div class="pt-3xl">
      <div class="columns">
        <div class="column">
          <b-button
            type="is-light rounded-lg"
            expanded
            @click="goBack"
            size="is-medium"
            ><b-icon icon="arrow-left"></b-icon>
          </b-button>
        </div>
        <div class="column">
          <b-button
            type="is-primary rounded-lg"
            expanded
            :disabled="!ready"
            :loading="saving"
            @click="save"
            size="is-medium"
          >Save & Complete</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CurrencyOptionsSelect from '@/components/Models/Currency/CurrencyOptionsSelect'
export default {
  components: {
    CurrencyOptionsSelect
  },
  computed: {
    ...mapGetters(['app', 'context']),
    endpoint () {
      return this.context.route + '/retailer-preferences/'
    },
    json () {
      const preferences = [{
        preference: { id: this.storeCurrency.preferenceId },
        option: { id: this.storeCurrency.selectedOption.id }
      }]
      return preferences
    },
    ready () {
      return this.storeCurrency !== null
    }
  },
  data () {
    return {
      storeCurrency: null,
      saving: false,
      setupIsCompleted: false,
      errors: {}
    }
  },
  methods: {
    goBack () {
      this.$router.push({ name: 'retailer.onboarding.branding-logo' })
    },
    save () {
      if (this.setupIsCompleted) {
        this.$router.push({ name: 'retailer.onboarding' })
      } else {
        this.saving = true
        this.$http.post(this.endpoint, this.json).then(response => {
          this.taskComplete()
        }).catch(error => {
          if (error.response && error.response.status === 400) {
            this.errors = error.response.data
          }
          this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
        }).finally(() => {
          this.saving = false
        })
      }
    },
    taskComplete () {
      const endpoint = this.context.route + '/assigned-tasks/' + this.$route.params.task + '/complete/'
      const taskBody = (typeof this.task.info === 'object') ? { info: this.task.info } : { info: {} }
      taskBody.info.storeCurrency = true
      this.$http.put(endpoint, taskBody).then(() => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Task Completed!' })
        this.$router.push({ name: 'retailer.onboarding' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    if (this.task && this.task.info && this.task.info.storeCurrency) {
      this.setupIsCompleted = true
    }
  },
  props: {
    task: {
      type: Object,
      require: true
    },
    account: {
      type: Object,
      required: true
    }
  }
}
</script>
