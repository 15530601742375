<template>
  <InspectionModal
    :endpoint="endpoint"
    :loadDetail="loadDetail"
    :seed="selectedException">
    <template v-slot:default="props">
      <div>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Inspect Exception</p>
          </header>
          <section class="modal-card-body">
            <!-- Loaded -->
            <div v-if="!props.loading && props.record !== null" class="">
              <!-- Details -->
              <div class="mb-lg">
                <p class="has-text-weight-bold mb-sm">Exception Details</p>
                <table class="table is-fullwidth is-striped is-narrow is-size-7 border">
                  <tbody>
                    <tr>
                      <th style="width:164px">ID</th>
                      <td>{{ props.record.id }}</td>
                    </tr>
                    <tr>
                      <th>Module</th>
                      <td>
                        <p>{{ props.record.type.module }}</p>
                      </td>
                    </tr>
                    <tr>
                      <th>Is Resolved?</th>
                      <td>
                        <span v-if="props.record.resolved_at !== null">Yes</span>
                        <span v-else>No</span>
                      </td>
                    </tr>
                    <tr>
                      <th>Created At</th>
                      <td>
                        {{ $dates.utc(props.record.created_at).local().format('MMM D, YYYY @ h:mm A')}}
                      </td>
                    </tr>
                    <tr>
                      <th>Resolved At</th>
                      <td>
                        <span v-if="props.record.resolved_at !== null">{{ $dates.utc(props.record.resolved_at).local().format('MMM D, YYYY @ h:mm A')}}</span>
                        <span v-else class="has-text-grey-light is-italic">Not Resolved</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- Message -->
              <div>
                <p class="has-text-weight-bold mb-sm">Exception Message</p>
                <b-input
                  type="textarea"
                  readonly
                  size="is-small"
                  v-model="props.record.type.message"
                  expanded
                ></b-input>
              </div>
            </div>
            <!-- Loading -->
            <div v-else>
              <loading message="Loading item..." />
            </div>
          </section>
          <footer class="modal-card-foot">
            <button class="button" type="button" @click="$parent.close()">Close</button>
          </footer>
        </div>
      </div>
    </template>
  </InspectionModal>
</template>

<script>
import InspectionModal from '../InspectionModal'
import { mapGetters } from 'vuex'

export default {
  components: {
    InspectionModal
  },
  data () {
    return {
      loadDetail: true
    }
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/exceptions/' + this.selectedException.id + '/'
    }
  },
  methods: {

  },
  mounted () {

  },
  props: ['selectedException']
}
</script>
