<template>
  <div>
    <div class="has-background-white pa-md pb-xl rounded-lg">
      <div class="columns is-mobile">
        <div class="column is-one-third"></div>
        <div class="column is-one-third">
          <p class="has-text-weight-bold has-text-centered is-size-7">PRODUCT SKUs</p>
        </div>
        <div class="column is-one-third has-text-right">
          <b-field class="is-pulled-right">
            <b-radio-button v-model="view" size="is-small" native-value="pricing">Pricing</b-radio-button>
            <b-radio-button v-model="view" size="is-small" native-value="attributes">Attributes</b-radio-button>
            <b-radio-button v-model="view" size="is-small" native-value="images">Images</b-radio-button>
          </b-field>
        </div>
      </div>

      <div>
        <b-table
          :data="presentVariants"
          :mobile-cards="false"
          :loading="loading || variants.length === 0"
          class="is-size-7 is-tall">
          <template>
            <!-- Image -->
            <b-table-column
              label=""
              cell-class="nowrap"
              :visible="columns.includes('sku')"
              width="64"
              centered
              v-slot="props">
              <figure class="image is-48x48">
                <img :src="cdnTransform(props.row.media[0].url, 48, 48, 'fill')" class="rounded-lg mr-xs is-block" />
              </figure>
            </b-table-column>
            <!-- SKU -->
            <b-table-column
              label="SKU"
              header-class="nowrap"
              cell-class="nowrap"
              :visible="columns.includes('sku')"
              v-slot="props">
              <router-link :to="{ name: 'network.variant', params: { id: $route.params.id, variant: props.row.id } }">{{ props.row.sku }}</router-link>
            </b-table-column>
            <!-- Images -->
            <b-table-column
              label="Images"
              :visible="columns.includes('images')"
              style="white-space:nowrap"
              v-slot="props">
              <figure class="image is-48x48 is-pulled-left mr-xs" v-for="medium in props.row.media" :key="medium.id">
                <img class="rounded-lg" :src="cdnTransform(medium.url, 48, 48, 'fill')"  />
              </figure>
            </b-table-column>
            <b-table-column
              label="Color"
              header-class="nowrap"
              cell-class="nowrap"
              :visible="columns.includes('color')"
              v-slot="props">
              <p v-if="props.row.color === null" class="has-text-grey is-size-7">N/A
              <p v-else>{{ props.row.color }}</p>
            </b-table-column>
            <b-table-column
              field="size"
              label="Size"
              sortable
              :visible="columns.includes('size')"
              v-slot="props">
              <p v-if="props.row.size === null" class="has-text-grey is-size-7">N/A
              <p v-else>{{ props.row.size }}</p>
            </b-table-column>
            <b-table-column
              field="cost"
              label="Shipped Cost"
              centered
              header-class="nowrap"
              cell-class="nowrap"
              :visible="columns.includes('cost')"
              width="128"
              v-slot="props">
              <b-icon icon="alert-outline" v-if="props.row.cost === null" />
              <p v-else>{{ $numbers(props.row.cost).formatCurrency({ mantissa: 2 }) }}</p>
            </b-table-column>
            <b-table-column
              label="MAP"
              head-class="nowrap"
              cell-class="nowrap"
              centered
              :visible="columns.includes('map')"
              width="100"
              v-slot="props">
              <b-icon icon="alert-outline" v-if="props.row.map === null" />
              <p v-else>{{ $numbers(props.row.map).formatCurrency({ mantissa: 2 }) }}</p>
            </b-table-column>
            <b-table-column
              label="Inventory"
              centered
              :visible="columns.includes('inventory')"
              width="100"
              v-slot="props">
              <b-icon icon="alert-outline" v-if="props.row.inventory === null" />
              <p v-else>{{ props.row.inventory }}</p>
            </b-table-column>
          </template>
        </b-table>
      </div>

    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CdnTransform from '@/mixins/CdnTransform'

export default {
  components: {
    // VariantDetail
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/marketplaces/' + this.marketplace + '/products/' + this.product.id + '/variants/'
    },
    presentVariants () {
      if (this.variants.length === 0) return []
      const variants = []
      this.variants.forEach(variant => {
        const mapped = {
          id: variant.id,
          sku: variant.brand_identifier,
          name: variant.name,
          color: variant.color,
          size: variant.size,
          cost: null,
          shipping: null,
          map: null,
          msrp: null,
          media: variant.media,
          inventory: variant.inventory.inventory
        }
        // handle pricing (which may or may not be present)
        if ('dropship_cost' in variant.attributes) {
          mapped.cost = this.$numbers(variant.attributes.dropship_cost.values[0].value).format({ mantissa: 2 })
        }
        if ('map' in variant.attributes) {
          mapped.map = this.$numbers(variant.attributes.map.values[0].value).format({ mantissa: 2 })
        }
        variants.push(mapped)
      })
      return variants
    },
    columns () {
      if (this.view === 'pricing') return ['sku', 'cost', 'shipping', 'map', 'msrp', 'inventory', 'status']
      if (this.view === 'attributes') return ['sku', 'color', 'size', 'status']
      if (this.view === 'images') return ['sku', 'images', 'status']
      return ['sku', 'status']
    }
  },
  data () {
    return {
      loading: true,
      view: 'pricing',
      variants: [],
      selected: []
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint).then(response => {
        this.variants = response.data.results
      }).finally(() => {
        this.loading = false
      })
    },
    editVariants (field) {
      this.showVariantEditModal = true
      this.editField = field
    }
  },
  mounted () {
    this.load()
  },
  mixins: [CdnTransform],
  props: ['marketplace', 'product']
}
</script>
