<template>
  <div>
    <!-- Content -->
    <div class="">
      <p class="mb-md">
        Communication is critical to delivering a positive experience
        for <strong>{{ connection.retailer.name }}</strong> customers.
        For this task, we need you to select (or invite!) the team members for key roles
        so that we (and {{ connection.retailer.name }}) knows who to contact
        with relevant questions and inquiries.
      </p>
      <p class="mb-md">
        We'll start with the <strong>Primary Business Contact</strong>.
      </p>
    </div>
    <!-- Navigate -->
    <div class="py-lg">
      <div class="columns is-centered">
        <div class="column">
          <b-button
            type="is-light rounded-lg"
            size="is-medium"
            @click="goBack"
            expanded
            ><b-icon icon="arrow-left"></b-icon>
          </b-button>
        </div>
        <div class="column">
          <b-button
            type="is-primary rounded-lg"
            size="is-medium"
            expanded
            @click="goForward"
          >Continue</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['app'])
  },
  data () {
    return {
      back: ''
    }
  },
  methods: {
    goBack () {
      this.$router.push({ name: 'onboarding.tasks', params: { connection: this.connection.id } })
    },
    goForward () {
      this.$emit('navigate', 'PrimaryContact')
    }
  },
  props: {
    connection: {
      type: Object,
      required: true
    },
    task: {
      type: Object,
      required: true
    }
  }
}
</script>
