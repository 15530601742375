<template>
  <div>
    <!-- Content -->
    <div class="mb-lg">
      <div v-if="!loading">
        <p class="mb-md">
          {{ connection.retailer.name }} will soon submit a request
          to upload a set products on your behalf. Once submitted, this "import request"
          requires your approval.
        </p>
        <p class="mb-md">
          When {{ connection.retailer.name }} submits the import request, you will receive an
          email with instructions (and a link) to view the products and approve the request. Please
          review the video below to see how the process works.
        </p>
        <!-- Video -->
        <div class="my-xl" style="position: relative; padding-bottom: 55.3125%; height: 0;">
          <iframe src="https://www.loom.com/embed/c96b8db3c00648fc902c0c028d197e23?sid=f0c37871-b2d0-431e-b91e-0e6b7bff6911"
            frameborder="0"
            webkitallowfullscreen
            mozallowfullscreen
            allowfullscreen
            style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"
          ></iframe>
        </div>
        <!-- Next Steps -->
        <div class="mt-lg">
          <p class="has-text-weight-bold mb-sm">Next Steps</p>
          <div v-if="requests.length">
            {{ connection.retailer.name }} has already submitted some import requests for you to review.
            You can review them now or continue onboarding.
          </div>
          <div v-else>
            {{ connection.retailer.name }} hasn't uploaded any import requests yet, so you don't have to take any action at this time. You may click
            'Complete Task' below to continue onboarding (but be on the lookout for an email notification soon!)
          </div>
        </div>
      </div>
      <!-- Loading -->
      <div v-else>
        <p class="has-text-centered has-text-grey-light py-md">Loading...</p>
      </div>
    </div>
    <!-- Navigate -->
    <div class="py-lg">
      <div class="columns is-centered">
        <div class="column">
          <b-button
            type="is-light rounded-lg"
            size="is-medium"
            @click="goBack"
            expanded
            ><b-icon icon="arrow-left"></b-icon>
          </b-button>
        </div>
        <div class="column">
          <b-button
            type="is-primary rounded-lg"
            size="is-medium"
            expanded
            :disabled="!ready || saving"
            :loading="saving"
            @click="goForward"
          >Complete Task</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {

  },
  computed: {
    ...mapGetters(['app', 'context']),
    loading () {
      return this.loadingImportRequests
    },
    ready () {
      if (this.loadingImportRequests) return false
      if (this.requests.length > 0) {
        this.requests.forEach(request => {
          if (request.status === 'pending') {
            return false
          }
        })
      }
      return true
    }
  },
  data () {
    return {
      loadingImportRequests: true,
      requests: [],
      saving: false
    }
  },
  methods: {
    loadImportRequests () {
      this.loadingImportRequests = true
      const endpoint = this.context.route + '/feed-requests/'
      const query = {
        params: {
          orderBy: '-id',
          status: 'approved,pending',
          limit: 5
        }
      }
      this.$http.get(endpoint, query).then(response => {
        this.requests = response.data.results
        this.loadingImportRequests = false
      })
    },
    goBack () {
      this.$router.push({ name: 'onboarding.tasks', params: { connection: this.$route.params.connection } })
    },
    goForward () {
      this.saving = true
      const endpoint = this.context.route + '/assigned-tasks/' + this.task.id + '/complete/'
      this.$http.put(endpoint).then(() => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Task Completed!' })
        this.$router.push({ name: 'onboarding.tasks', params: { connection: this.connection.id } })
      }).catch(() => {
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please try again!' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    this.loadImportRequests()
  },
  props: {
    connection: {
      type: Object,
      required: true
    },
    task: {
      type: Object,
      required: true
    }
  }
}
</script>
