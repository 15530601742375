<template>
  <form @submit.prevent>
    <div class="modal-card">
      <header class="modal-card-head">
        List of Suppliers
      </header>
      <section class="modal-card-body">
        <!-- Results -->
        <b-table
        :data="suppliers"
        :mobile-cards="false"
        hoverable
        class="is-size-7">
        <template>
          <!-- brand id -->
          <b-table-column
            label="Supplier Id"
            v-slot="props">
            {{props.row.brand.id}}
          </b-table-column>
          <!-- brand name -->
          <b-table-column
            label="Supplier Name"
            v-slot="props">
            {{props.row.brand.name}}
          </b-table-column>
        </template>
      </b-table>
      </section>
      <footer class="modal-card-foot">
        <b-button @click="$parent.close()">Close</b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {
  },
  computed: {
    ...mapGetters(['app', 'context'])
  },
  data () {
    return {
    }
  },
  methods: {

  },
  mounted () {
  },
  props: {
    suppliers: {
      type: Array,
      required: true
    }
  }
}
</script>
