<template>
  <div>
    <!-- Progress -->
    <div v-if="context.isMerchant()" class="has-background-white rounded-lg py-md px-xxl mb-xxs">
      <p class="has-text-primary has-text-weight-bold has-text-centered mb-sm">Onboarding Progress</p>
      <div class="mb-md">
        <b-progress :value="progressPercent" show-value format="percent" size="is-large" >
          {{ progressMessage }}
        </b-progress>
      </div>
    </div>
    <div v-if="!loading">
      <!-- Tasklist -->
      <div class="has-background-white rounded-lg px-md mb-xxs">
        <b-table
          :data="tasks"
          :mobile-cards="false"
          :loading="loading"
          hoverable
          class="is-size-7 is-vcentered">
          <template>
            <!-- Status -->
            <b-table-column
              cell-class="nowrap"
              width="48"
              centered
              v-slot="props">
              <b-icon v-if="props.row.completed_at" icon="check" custom-class="has-text-success" />
              <b-icon v-else icon="check" custom-class="has-text-grey-lighter" />
            </b-table-column>
            <!-- Task -->
            <b-table-column
              label="Task"
              cell-class="nowrap"
              width="192"
              v-slot="props">
              <p>{{ props.row.onboarding_task.name }}</p>
              <p class="has-text-grey-light">{{ props.row.onboarding_task.description }}</p>
            </b-table-column>
            <!-- Status -->
            <b-table-column
              label="Completed"
              cell-class="nowrap"
              width="96"
              numeric
              v-slot="props">
              <div v-if="props.row.completed_at !== null">
                <p>{{ $dates.utc(props.row.completed_at).local().format('MMM D, YYYY') }}</p>
                <p class="has-text-grey-light">{{ props.row.completed_by.first_name }} {{ props.row.completed_by.last_name }}</p>
              </div>
            </b-table-column>
          </template>
          <template #empty>
            <div class="py-lg has-text-grey has-text-centered">No tasks found!</div>
          </template>
        </b-table>
      </div>
    </div>
    <div v-else>
      <div class="has-background-white rounded-lg px-md mb-xxs">
        <loading message="Loading..." />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/connections/' + this.$route.params.id + '/'
    },
    tasksDone () {
      if (this.loading || this.tasks.length === 0) return 0
      let done = 0
      this.tasks.forEach(task => {
        if (task.completed_at !== null) done++
      })
      return done
    },
    progressMessage () {
      if (this.loading) return 'Loading...'
      return this.tasksDone + ' of ' + this.tasks.length + ' tasks completed.'
    },
    progressPercent () {
      if (this.loading || this.tasks.length === 0) return 0
      return Math.round((this.tasksDone / this.tasks.length) * 100, 0)
    }
  },
  data () {
    return {
      tasks: [],
      loading: true
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint + 'assigned-tasks/').then(response => {
        this.tasks = response.data.results
        this.loading = false
      })
    }
  },
  mounted () {
    this.load()
  },
  props: ['connection']
}
</script>
