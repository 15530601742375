<template>
  <div>
    <form @submit.prevent="save" novalidate>
      <!-- Header -->
      <div class="has-text-centered mb-lg">
        <p class="has-text-weight-bold">Update Password</p>
        <p class="has-text-grey is-size-7">You are required to update the password on your {{ app.platform }} account.
        </p>
      </div>
      <!-- Validation Message -->
      <div class="has-text-centered has-text-grey-light mb-lg">
        <i v-if="error !== null" v-text="error" class="has-text-danger"></i>
        <i v-else v-text="validationMessage.body" :class="validationMessage.class"></i>
      </div>
      <!-- Input Fields -->
      <div class="mb-lg">
        <div class="mb-lg">
          <b-field label="New Password" expanded>
            <b-input autocomplete="new-password" type="password" name="newPassword" v-model="newPassword"
              password-reveal></b-input>
          </b-field>
          <div class="po-password-strength-wrapper">
            <password-meter :password="newPassword" @score="setScore" />
          </div>
        </div>
        <div class="mb-lg">
          <b-field label="Confirm Password">
            <b-input v-model="confirmPassword" native-type="submit" size="is-medium" type="password"
              name="confirmPassword" password-reveal></b-input>
          </b-field>
        </div>
        <div>
          <input type="hidden" name="username" :value="auth.user.email" />
          <b-button native-type="submit" type="is-primary rounded-lg" size="is-medium" :disabled="saving || !ready"
            expanded>
            Update
          </b-button>
        </div>
      </div>
      <!-- Tips -->
      <div class="has-background-light mb-md pa-md rounded-xl content is-size-7">
        <p class="has-text-weight-bold">Tips for a strong password</p>
        <ul>
          <li class="mb-lg">
            Longer passwords are more secure. Symbols and numbers help,
            but the number of characters matters more.
          </li>
          <li class="mb-lg">
            Use the meter to gauge your password strength. You must
            score at least in the yellow to update your password (but
            green is highly recommended).
          </li>
        </ul>
      </div>
      <!-- Logout Link -->
      <p class="has-text-centered">
        <a class="is-size-7" href="#" @click.prevent="$store.dispatch('logout')">Sign Out</a>
      </p>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import passwordMeter from 'vue-simple-password-meter'

export default {
  components: {
    passwordMeter
  },
  computed: {
    ...mapGetters(['app', 'api', 'auth', 'context']),
    endpoint () {
      return this.api.baseRoute + '/users/update-password/'
    },
    json () {
      return {
        password: this.newPassword
      }
    },
    ready () {
      return (
        this.newPassword &&
        this.newPassword === this.confirmPassword &&
        this.passwordScore >= 2
      )
    },
    validationMessage () {
      const message = {
        class: 'has-text-warning',
        body: null
      }
      if (!this.newPassword) {
        message.body = 'Please enter a new password.'
      } else if (this.newPassword !== this.confirmPassword) {
        message.body = 'Please make sure to confirm your new password.'
      } else if (this.newPassword === this.oldPassword) {
        message.body = 'Please make sure your new password is different than your old password.'
      } else if (this.passwordScore < 2) {
        message.body = 'Please strengthen your password until you see a green score.'
      } else {
        message.class = 'has-text-success'
        message.body = 'You are ready to submit your new password!'
      }
      return message
    }
  },
  data () {
    return {
      saving: false,
      oldPassword: null,
      newPassword: null,
      confirmPassword: null,
      passwordScore: 0,
      error: null
    }
  },
  methods: {
    setScore ({ score }) {
      this.passwordScore = score
    },
    save () {
      this.saving = true
      this.$http.patch(this.endpoint, this.json).then(response => {
        this.$store.dispatch('changedPassword').then(() => {
          this.$buefy.toast.open({ type: 'is-success', message: 'Profile updated!' })
          if (this.context.isUnresolved()) {
            this.$router.push({ name: 'context' })
          } else if (this.auth.user && this.auth.user.preferences && this.auth.user.preferences.default_landing_page) {
            this.$router.push({ name: this.auth.user.preferences.default_landing_page.value })
          } else {
            this.$router.push({ name: 'home' })
          }
        })
      }).catch(error => {
        if (error.response && error.response.status === 400) {
          this.error = error.response.data.detail
        }
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {

  }
}
</script>
