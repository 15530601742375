<template>
  <div class="has-background-white rounded-lg">
    <!-- Loaded -->
    <div v-if="preferences !== null">
      <div class="mb-lg">
        <FieldLabel label="Fulfillment SLA" required/>
        <div class="has-text-grey">
          <p>
            The Fulfillment SLA governs how long suppliers have to ship orders before they are considered late.
          </p>
        </div>
      </div>
      <b-field>
        <b-select
          type="text"
          v-model="value"
          size="is-medium"
          expanded
          @input="announce">
          <option v-for="option in options" :key="option.id" :value="option">{{ option.name }}</option>
        </b-select>
      </b-field>
      <div v-if="this.value">
        <table class="table is-fullwidth is-narrow is-size-7">
          <tr>
            <th>Order Placed</th>
            <th>Must Ship By</th>
          </tr>
          <tr>
            <td>Monday (before 1 PM ET), Friday (after 1PM ET), Saturday, Sunday</td>
            <td>
              <span v-if="value.value === '24'">Tuesday</span>
              <span v-else-if="value.value === '48'">Wednesday</span>
              <span v-else-if="value.value === '72'">Thursday</span>
              <span v-else></span>
            </td>
          </tr>
          <tr>
            <td>Monday (after 1 PM ET)</td>
            <td>
              <span v-if="value.value === '24'">Wednesday</span>
              <span v-else-if="value.value === '48'">Thursday</span>
              <span v-else-if="value.value === '72'">Friday</span>
              <span v-else></span>
            </td>
          </tr>
          <tr>
            <td>Wednesday (after 1 PM ET), Thursday (before 1PM ET)</td>
            <td>
              <span v-if="value.value === '24'">Friday</span>
              <span v-else-if="value.value === '48'">Monday</span>
              <span v-else-if="value.value === '72'">Tuesday</span>
              <span v-else></span>
            </td>
          </tr>
          <tr>
            <td>Thursday (after 1 PM ET), Friday (before 1PM ET)</td>
            <td>
              <span v-if="value.value === '24'">Monday</span>
              <span v-else-if="value.value === '48'">Tuesday</span>
              <span v-else-if="value.value === '72'">Wednesday</span>
              <span v-else></span>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  computed: {
    ...mapGetters(['app', 'context']),
    json () {
      return {
        preference: { id: this.preference && this.preference.id },
        option: { id: this.value && this.value.id }
      }
    }
  },
  data () {
    return {
      preference: null,
      options: [],
      value: null
    }
  },
  methods: {
    announce () {
      this.$emit('input', this.json)
    }
  },
  mounted () {
    this.preference = this.$utils.find(this.preferences, ['code', 'default_fulfillment_sla'])
    if (typeof this.preference !== 'undefined') {
      // set the options
      this.options = this.preference.options.map(option => {
        return {
          id: option.id,
          name: option.name,
          value: option.value
        }
      })
      if (this.preference.retailer_preference) {
        this.value = this.$utils.find(this.options, ['value', this.preference.retailer_preference.value])
      }
      this.announce()
    }
  },
  props: {
    preferences: {
      type: Array,
      required: true
    }
  }
}
</script>
