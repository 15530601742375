<template>
  <div>
    <p class="has-text-weight-bold mb-sm">Carrier Service</p>
    <!-- Select Service -->
    <div>
      <b-field
        :type="('weight' in errors) ? 'is-danger' : ''"
        :message="('weight' in errors) ? errors['weight'][0] : ''">
        <b-autocomplete
          v-model="name"
          placeholder="Search carrier service..."
          field="name"
          :data=filteredShippingMethods
          :clearable="true"
          :loading="loading"
          expanded
          size="is-medium"
          @select="announce">
          <template slot-scope="props">
            <div class="media">
              <div class="media-content">
                <p class="has-text-weight-bold has-text-primary">{{ props.option.name }}</p>
                <p class="is-size-7">
                  <span class="has-text-grey">{{ props.option.carrier.name }}</span>
                </p>
              </div>
            </div>
          </template>
        </b-autocomplete>
      </b-field>
      <div>
        <p v-if="method !== null" class="has-text-grey is-size-7">
          From {{ method.carrier.name }}
        </p>
        <p v-else class="has-text-grey-light is-size-7">
          Select a method above...
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  computed: {
    ...mapGetters(['api', 'context']),
    endpoint () {
      return this.api.baseUrl + '/' + this.api.version + '/shippingmethods/?pagination=0'
    },
    filteredShippingMethods () {
      if (this.name === null) {
        return []
      }
      const lowerCaseName = this.name.toLowerCase()
      return this.methods.filter((method) => {
        return method.name.toLowerCase().indexOf(lowerCaseName) >= 0 || method.carrier.name.toLowerCase().indexOf(lowerCaseName) >= 0
      })
    }
  },
  data () {
    return {
      loading: true,
      methods: [],
      method: null,
      name: null,
      errors: []
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint).then(response => {
        this.methods = response.data.results.sort((a, b) => {
          if (a.name > b.name) {
            return 1
          } else if (a.name < b.name) {
            return -1
          }
          return 0
        })
      }).finally(() => {
        this.loading = false
      })
    },
    announce (option) {
      this.method = option
      this.$emit('input', this.method)
    },
    clear () {
      this.method = null
      this.$emit('input', null)
    }
  },
  mounted () {
    this.load()
  },
  props: ['order', 'requestedService']
}
</script>
