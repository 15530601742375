<template>
<div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Remove Invoice</p>
    </header>
    <section class="modal-card-body">
      <p v-if="selectedInvoices.length === 1">
        Are you sure you want to remove the <b> Invoice #{{selectedInvoices[0].invoice_number}}</b> from the payment #{{payment.id}}?
      </p>
      <p v-else>
        Are you sure you want to remove the <b>{{selectedInvoices.length}}</b> selected invoices from the payment #{{payment.id}}?
      </p>
    </section>
    <footer class="modal-card-foot space-between">
      <b-button @click="$parent.close()">Close</b-button>
      <b-button
        :disabled="saving"
        type="is-primary"
        @click="save"
      >Save</b-button>
    </footer>
  </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['context'])
  },
  data () {
    return {
      saving: false
    }
  },
  methods: {
    save () {
      this.saving = true
      const json = {
        invoice_ids: this.selectedInvoices.map(el => {
          return el.id
        })
      }
      const endpoint = this.context.route + '/payments/' + this.$route.params.id + '/remove-invoices/'
      this.$http.put(endpoint, json).then(response => {
        if (response.data.success_invoices_removed.length) {
          this.$buefy.toast.open({ type: 'is-success', message: 'Invoices removed successfully' })
        } else if (response.data.failure_invoices_removed.length) {
          this.$buefy.toast.open({ type: 'is-danger', message: 'There was a problem removing invoices to the payment. Please try again or contact support.' })
        }
        this.$emit('updated')
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
  },
  props: ['payment', 'selectedInvoices']
}
</script>
