<template>
  <div>
    <div class="columns">
      <div class="column is-narrow">
        <b-select placeholder="Title" v-model="parameter" expanded size="is-medium" @input="$refs.myAutocomplete.focus()">
          <option value="id">ID</option>
        </b-select>
      </div>
      <div class="column">
        <b-field>
          <b-autocomplete
            ref="proposalAutocomplete"
            :placeholder="placeholderText"
            field="id"
            v-model="value"
            :loading="loading"
            :data="results"
            :clear-on-select="true"
            :open-on-focus="true"
            size="is-medium"
            @typing="search"
            @select="viewProposal"
            expanded>
            <template slot-scope="props">
              <div class="media">
                <div class="media-content">
                  <p class="has-text-weight-bold has-text-primary">Proposal #{{ props.option.id }}</p>
                  <p class="has-text-grey-dark"><StatusPresenter :proposal="props.option" /></p>
                  <p v-if="props.option.brand" class="has-text-grey-dark">{{ props.option.brand.name }}</p>
                </div>
              </div>
            </template>
            <template #empty>No results found</template>
          </b-autocomplete>
        </b-field>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import { mapGetters } from 'vuex'
import StatusPresenter from '@/components/Models/Proposal/Presenters/Status'

export default {
  components: {
    StatusPresenter
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      switch (this.parameter) {
        case 'tracking_number':
          return this.context.route + '/shipments/'
        case 'invoice_number':
          return this.context.route + '/invoices/'
        default:
          return this.context.route + '/orders/'
      }
    },
    query () {
      const params = {
        status: 'proposed,revised,approved,pricing_approved,completed',
        order_by: '-id'
      }
      // attach additional search param based on what user has selected
      switch (this.parameter) {
        case 'name':
          params.name = this.value
          break
        default:
          params.id = this.value
      }
      return params
    },
    placeholderText () {
      return (this.parameter === 'id') ? 'Search by proposal ID...' : 'Search by proposal name...'
    },
    ready () {
      return this.value
    }
  },
  data () {
    return {
      loading: false,
      parameter: 'id',
      value: null,
      results: [],
      selected: null
    }
  },
  methods: {
    search: debounce(function (name) {
      this.searchProposals()
    }, 1000),
    searchProposals () {
      this.loading = true
      const endpoint = this.context.route + '/proposals/'
      this.$http.get(endpoint, { params: this.query }).then(response => {
        this.results = response.data.results
        this.loading = false
      })
    },
    viewProposal (option) {
      this.$router.push({ name: 'proposal.detail.summary', params: { id: option.id } })
    }
  },
  watch: {
    parameter (value) {
      if (this.value !== null) {
        this.searchProposals()
      }
    }
  }
}
</script>
