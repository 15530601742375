<template>
  <div>
    <div v-for="question in faqs" :key="question.id" >
      <faq :question="question" />
    </div>
  </div>
</template>

<script>
import Faq from './Faq'
export default {
  components: {
    Faq
  },
  data () {
    return {
      faqs: [{
        id: 'costs',
        question: 'How do I price my products to merchants? Should I just use my wholesale cost?',
        answer: `<p>
          No - do not use your wholesale cost. During onboarding, we'll ask you to set a new
          cost: <b><i>dropship cost</i></b>. This cost should be your all-in cost to ship an
          item to a consumer. It should be higher than your standard wholesale
          cost and include any order processing fees. It should also include some amount to cover most,
          if not all, of your shipping expenses. We recommend setting
          your dropship cost around 60-70% of your MAP, if possible.
        </p>
        <p>
        Together with your minimum advertised price (or "MAP")
        merchants will have enough data to determine their approximate margins.
        If they can offer competive pricing with enough room for modest advertsing,
        merchants will line up to sell your products!</p>
        `
      }, {
        id: 'approve',
        question: 'How do I get paid?',
        answer: `
        <p>
          We collect payments for shipped orders from Merchants approximately
          7 business days after the customer's credit card is charged for the sale. After the funds
          clear, we initiate payment to you! Funds should arrive in your
          bank account in the next 5-7 business days; the whole process takes only
          about 2 weeks!
        </p>
        <p>
          Payments are made on a rolling basis for any orders you that you ship
          across the entire network. Any returns that you approve are debited
          against future payments.
        </p>
        `
      }, {
        id: 'shipping',
        question: 'Who pays for shipping?',
        answer: `In the Commerce Network, suppliers cover the initial
        cost of shipping. <b>However</b>, suppliers should expect to be paid/reimbursed
        enough to cover most, if not all, of the shipping expenses. Make sure
        to add enough padding to your costs to account for shipping.`
      }, {
        id: 'returns',
        question: 'What is the return policy?',
        answer: `
        <p>
          Customers can ask Merchants to return items for up to 30 days from
          the date of purchase. If a merchant issues an RMA, you will receive a notification to expect
          a return shipment. As the supplier, you must approve the return before any
          funds will be credited back to the merchant.
        </p>
        <p>
          The cost of the return shipment must be paid by the Merchant.
        </p>`
      }]
    }
  }
}
</script>
