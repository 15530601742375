<template>
  <div>
    <div class="has-text-weight-semibold">
      Upload Fulfillment
    </div>
    <!-- Error -->
    <div v-if="('general' in errors)" class="mb-md">
      <p class="has-text-danger">{{ errors.general }}</p>
    </div>
    <div class="mt-md">
      <!-- Details -->
      <p class="is-size-7">FTP upload an 856ASN fulfillment file for your sample item orders.</p>
      <ul class="mt-md is-size-7">
        <li>1. Log in to your FTP client and upload the 856ASN fulfillment file into your outbox.</li>
        <li>2. Wait for 5-15 minutes for the file to be processed. Click refresh to update fulfillment status.</li>
        <li>3. Click Confirm & Continue when all the orders statuses are fulfilled.</li>
        <li>4. Click to download EDI 856ASN Sample File and Specs.</li>
      </ul>
      <p class="mt-md is-size-7 has-text-grey">
        NOTE: Please check your EDI 997 if the status of fulfillment hasn’t been updated even after waiting for 15 minutes.
      </p>
      <!-- FTP Details -->
      <div>
        <LoginDetails :ediAccount="ediAccount" :collapse="true" />
      </div>
      <div class="mt-md">
        <div class="columns is-vcentered is-size-7" @click="loadOrders()">
          <div class="column is-clickable">
            <b-icon icon="refresh" custom-class="mdi-rotate-180" size="is-small"></b-icon>
            <span>Refresh Orders</span>
          </div>
        </div>
        <b-table
          :data="orders"
          :mobile-cards="false"
          :loading="loading"
          class="is-size-7 border rounded-md"
        >
        <template>
          <!-- PO# -->
          <b-table-column
            label="PO#"
            cell-class="nowrap"
            v-slot="props">
            {{ props.row.purchase_order_number }}
          </b-table-column>
          <!-- Fulfillment Status -->
          <b-table-column
            label="Fulfilled"
            cell-class="nowrap"
            centered
            v-slot="props">
            <a href="#" class="has-text-weight-bold has-text-info" @click.prevent="showOrderLines(props.row)">
              <NumberFulfilled :lines="props.row.order_lines" />
            </a>
          </b-table-column>
        </template>
          <template #empty>
            <div class="py-xxl has-text-grey has-text-centered">
              {{ (loading) ? "Loading data..." : 'No orders found!' }}
            </div>
          </template>
        </b-table>
      </div>
      <!-- Navigate -->
      <div class="py-lg">
        <div class="columns">
          <div class="column">
            <b-button
              type="is-light rounded-lg"
              expanded
              @click="goBack"
              ><b-icon icon="arrow-left"></b-icon>
            </b-button>
          </div>
          <div class="column">
            <b-button
              type="is-primary rounded-lg"
              expanded
              :loading="isUpdating"
              @click="goForward"
            >Confirm & Continue</b-button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal: Order Lines -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showOrderLinesModal"
      :can-cancel="['x']"
      width="1200px"
      @close="inspectOrder = null"
      ><OrderLinesModal :order="inspectOrder" />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import NumberFulfilled from '@/components/Presenters/Order/NumberFulfilled'
import OrderLinesModal from '@/components/Modals/Order/OrderLinesModal'
import LoginDetails from '../components/EdiAccount/LoginDetails'
export default {
  components: {
    NumberFulfilled,
    OrderLinesModal,
    LoginDetails
  },
  computed: {
    ...mapGetters(['app', 'context']),
    endpoint () {
      return this.context.route + '/orders/'
    },
    fields () {
      const base = [
        'id',
        'purchase_order_number',
        'fulfill_by',
        'fulfilled_at',
        'order_lines',
        'invoice_count',
        'status',
        'received_at',
        'acknowledged_at',
        'retailer'
      ]
      return base
    }
  },
  data () {
    return {
      loading: true,
      errors: {},
      orders: [],
      showOrderLinesModal: false,
      inspectOrder: null,
      isFulfilled: false,
      isUpdating: false
    }
  },
  methods: {
    goBack () {
      this.$router.push({ name: 'onboarding.tasks.self-serve-edi.orders', params: { connection: this.$route.params.connection } })
    },
    goForward () {
      // Add info to the task to track the tasks
      const taskBody = (typeof this.task.info === 'object') ? { info: this.task.info } : { info: {} }
      taskBody.info.uploadFulfillment = true
      this.$router.push({ name: 'onboarding.tasks.self-serve-edi.invoice', params: { connection: this.$route.params.connection } })
    },
    loadOrders () {
      this.loading = true
      const query = {
        params: {
          order_by: '-id',
          skip_attributes: 1,
          fields: this.fields
        }
      }
      this.$http.get(this.endpoint, query).then(response => {
        this.orders = response.data.results.filter(el => this.orderIds.includes(el.id))
        this.isFulfilled = this.orders.filter(el => el.fulfilled_at !== null).length === this.orders.length
      }).finally(() => {
        this.loading = false
      })
    },
    showOrderLines (order) {
      this.inspectOrder = order
      this.showOrderLinesModal = true
    }
  },
  mounted () {
    this.orderIds = this.task.info.orderIds
    this.loadOrders()
  },
  props: {
    connection: {
      type: Object,
      required: true
    },
    ediAccount: {
      type: Object
    },
    task: {
      type: Object,
      required: true
    },
    isUpdatingTask: {
      type: Boolean
    }
  },
  watch: {
    isUpdatingTask (value) {
      this.isUpdating = value
    }
  }
}
</script>
