import Request from '../Request'
export default class MarkAsPaid extends Request {
  constructor (config) {
    super()
    this.method = 'put'
    this.route = config.route
    this.invoice = config.invoice
    this.validate()
  }

  id () {
    return this.invoice.id
  }

  label () {
    return this.invoice.invoice_number
  }

  validate () {
    if (this.invoice.paid_at !== null) {
      this.isValid = false
      this.status = 'Skip'
      this.errorMessage = 'Invoice has already been marked as paid.'
    } else {
      this.isValid = true
    }
  }

  json () {
    return null
  }

  endpoint () {
    return this.route + '/invoices/' + this.id() + '/mark-paid/'
  }

  errorHandler (errors) {
    return errors[0]
  }
}
