<template>
  <InspectionModal
    :endpoint="endpoint"
    :loadDetail="loadDetail"
    :seed="selectedAccessRequest">
    <template v-slot:default="props">
      <form>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Access Request</p>
          </header>
          <section class="modal-card-body">
            <!-- Loaded -->
            <div v-if="!props.loading && props.record !== null" class="">

              <b-button type="is-primary" @click="approve" :loading="saving" :disabled="saving">Approve</b-button>

            </div>
            <!-- Loading -->
            <div v-else>
              <loading message="Loading item..." />
            </div>
          </section>
          <footer class="modal-card-foot">
            <button class="button" type="button" @click="$parent.close()">Close</button>
          </footer>
        </div>
      </form>
    </template>
  </InspectionModal>
</template>

<script>
import InspectionModal from '../InspectionModal'
import { mapGetters } from 'vuex'

export default {
  components: {
    InspectionModal
  },
  data () {
    return {
      loadDetail: false,
      saving: false
    }
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      if (this.selectedAccessRequest !== null) {
        return this.context.route + '/product-access/' + this.selectedAccessRequest.id + '/'
      }
      return null
    }
  },
  methods: {
    approve () {
      this.saving = true
      this.$http.put(this.endpoint + 'approve/').then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Request Approved' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {

  },
  props: ['selectedAccessRequest']
}
</script>
