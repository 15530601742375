export const status = {
  label: 'Status',
  key: 'status',
  icon: 'pulse',
  tooltip: 'Status of the user.',
  options: [{
    value: 'active',
    label: 'Active'
  }, {
    value: 'invited',
    label: 'Invited'
  }]
}
