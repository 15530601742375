<template>
  <div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Add Integration</p>
      </header>
      <section class="modal-card-body">
        <!-- Category Selector -->
        <!-- <div class="mb-lg">
          <b-field label="Category">
            <b-select v-model="category" expanded>
              <option value="All">All</option>
              <option value="Orders">Orders &amp; Fulfillments</option>
              <option value="Products">Product Information</option>
              <option value="Inventory">Inventory</option>
              <option value="Returns">Returns & Post-Transaction Support</option>
            </b-select>
          </b-field>
        </div> -->
        <div v-if="!loading && availablePlatforms" class="columns is-vcentered is-multiline">
          <div v-for="platform in availablePlatforms" :key="platform.code" class="column is-half">
            <div class="has-text-centered pa-md border">
              <p class="mb-lg"><b-image :src="platform.logo_url" :alt="platform.name" ratio="3x1" /></p>
              <p>
                <b-button
                  type="is-light"
                  expanded
                  @click="selectPlatform(platform)"
                >Add</b-button>
              </p>
            </div>
          </div>
        </div>
        <div v-else>
          <loading message="Loading..." />
        </div>
      </section>
      <footer class="modal-card-foot space-between">
        <button class="button" type="button" @click="$parent.close()">Cancel</button>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Filterable from '@/mixins/Filterable'

export default {
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.api.route + '/'
    }
  },
  data () {
    return {
      loading: true,
      // category: 'All',
      availablePlatforms: null
    }
  },
  methods: {
    selectPlatform (platform) {
      this.$emit('platform:selected', platform)
    },
    filterAvailablePlatforms () {
      const existingAccounts = this.existingAccounts.map(platform => platform.platform.code)
      this.availablePlatforms = this.availablePlatforms.filter(platform => !existingAccounts.includes(platform.code))
    },
    load () {
      this.loading = true
      const endpoint = this.context.route + '/platforms/'
      this.$http.get(endpoint).then(response => {
        this.availablePlatforms = response.data.results.filter(el => el.status === 'active')
        if (this.existingAccounts) {
          this.filterAvailablePlatforms()
        }
      }).finally(() => {
        this.loading = false
      })
    }
  },
  mixins: [Filterable],
  mounted () {
    this.load()
  },
  props: ['existingAccounts']
}
</script>
