<template>
  <div class="login">
    <!-- Sign In -->
    <form @submit.prevent="attempt" novalidate>
      <p class="has-text-centered is-size-4 mb-lg">{{ message }}</p>
      <div class="mb-xxl">
        <!-- Email -->
        <div class="mb-lg">
          <p class="mb-sm">Email</p>
          <b-input
            v-model="email"
            type="email"
            size="is-medium"
          ></b-input>
        </div>
        <!-- Password -->
        <div class="mb-lg">
          <p class="mb-sm">Password</p>
          <b-input
            v-model="password"
            type="password"
            size="is-medium"
            password-reveal
          ></b-input>
        </div>
      </div>
      <div class="mb-xl">
        <b-button
          type="is-primary rounded-lg"
          size="is-medium"
          native-type="submit"
          :disabled="!ready || processing"
          :loading="processing"
          expanded
        >Sign In</b-button>
      </div>
      <div class="has-text-centered">
        <router-link class="has-text-grey" :to="{ name: 'auth.forgot' }">Forgot Password?</router-link>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['api', 'auth', 'context']),
    endpoint () {
      return this.api.baseUrl + '/auth/token/'
    },
    credentials () {
      const form = new FormData()
      form.set('username', this.email)
      form.set('password', this.password)
      form.set('grant_type', 'password')
      form.set('client_id', this.api.clientId)
      return form
    },
    ready () {
      return (
        this.email !== null &&
        this.email !== '' &&
        this.password !== null &&
        this.password !== ''
      )
    }
  },
  data: function () {
    return {
      processing: false,
      email: null,
      password: null,
      message: 'Sign in to your account'
    }
  },
  methods: {
    attempt () {
      this.processing = true
      this.$http.post(this.endpoint, this.credentials).then(response => {
        this.$store.dispatch('authenticate', response.data).then(() => {
          this.processing = false
          // if user requires a forced password change
          if (this.auth.user.requirePasswordChange) {
            this.$router.push({ name: 'auth.change' })
          // if context is not resolved, redirect to context selection...
          } else if (this.context.isUnresolved()) {
            this.$router.push({ name: 'context' })
          // otherwise, try and redirect to intended path
          } else if (this.auth.intended !== null) {
            if (this.$route.path === this.auth.intended) {
              this.$router.go(0)
            } else {
              this.$router.push({ path: this.auth.intended })
            }
          } else if (this.auth.user && this.auth.user.preferences && this.auth.user.preferences.default_landing_page) {
            this.$router.push({ name: this.auth.user.preferences.default_landing_page.value })
          } else {
            this.$router.push({ name: 'home' })
          }
        })
      }).catch(() => {
        this.processing = false
        this.$buefy.toast.open({ duration: 3000, type: 'is-danger', message: 'Invalid username or password.' })
      })
    }
  }
}
</script>
