<template>
    <div>
      <!-- Content -->
      <div class="">
        <p class="mb-md">
          The {{ app.productName }} WooCommerce app can be installed to automatically push purchase
          orders into your WooCommerce store and pull tracking numbers once you ship. To install the app,
          please enter your Store URL below:
        </p>
        <!-- Account Exists -->
        <div v-if="account !== null">
          <WooCommercePresenter :account="account" />
        </div>
        <!-- Account Doesn't Exist -->
        <div v-else class="mb-lg">
          <b-field label="WooCommerce Store URL">
            <b-input v-model="storeURL" @input="isDirty = true" expanded size="is-medium" placeholder="WooCommerce Store URL"></b-input>
          </b-field>
        </div>
      </div>
      <!-- Navigate -->
      <div class="py-lg">
        <div class="columns is-centered">
          <div class="column">
            <b-button
              type="is-light rounded-lg"
              size="is-medium"
              @click="goBack"
              expanded
              ><b-icon icon="arrow-left"></b-icon>
            </b-button>
          </div>
          <div class="column">
            <b-button
              type="is-primary rounded-lg"
              size="is-medium"
              expanded
              @click="goForward"
              :disabled="saving || !ready"
              :loading="saving"
            >Continue</b-button>
          </div>
        </div>
      </div>
    </div>
  </template>

<script>
import { mapGetters } from 'vuex'
import WooCommercePresenter from '@/components/Models/PlatformAccount/Presenters/WooCommerce'

export default {
  components: {
    WooCommercePresenter
  },
  computed: {
    ...mapGetters(['app', 'context']),
    ready () {
      return (this.account || this.storeURL)
    }
  },
  data () {
    return {
      storeURL: null,
      saving: false
    }
  },
  methods: {
    goBack () {
      this.$router.push({ name: 'onboarding.tasks', params: { connection: this.connection.id } })
    },
    goForward () {
      // if platform account exists, don't do anything
      if (this.account !== null) {
        this.$emit('navigate', 'Install')
        return
      }
      this.saving = true
      const endpoint = this.context.route + '/platform-accounts/'
      const json = {
        platform: { code: 'woocommerce' },
        credentials: [{
          credential: { code: 'woocommerce_shop_uri' },
          value: this.storeURL.replace(/\/+$/, '')
        }]
      }
      this.$http.post(endpoint, json).then(response => {
        this.$buefy.toast.open({ type: 'is-info', message: 'Platform Added!' })
        this.$emit('platform:saved')
        this.$emit('navigate', 'Install')
      }).catch(() => {
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  props: {
    connection: {
      type: Object,
      required: true
    },
    task: {
      type: Object,
      required: true
    },
    account: {
      type: Object
    }
  }
}
</script>
