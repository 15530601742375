<template>
  <div>
    <!-- Cover Photos -->
    <div style="height:315px">
      <p class="coverphoto"><img class="rounded-lg"  :src="coverPhoto.cdnUrl" /></p>
      <p class="profilephoto"><img class="rounded-lg"  :src="profilePhoto.cdnUrl"  /></p>
    </div>
    <!-- Text -->
    <div class="columns">
      <!-- Name & Verticals -->
      <div class="column">
        <div class="mb-md pl-xl pt-md">
          <p class="is-size-4 has-text-weight-bold">{{ name }}</p>
          <p class="has-text-grey">{{ verticals }}</p>
        </div>
      </div>
      <!-- Mission -->
      <div class="column" v-if="missionStatement !== null">
        <div class="rounded-lg has-background-white pa-md">
          <p class="has-text-grey is-size-7">{{ missionStatement }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MarketplacePresenters from '@/mixins/presenters/Marketplace'
export default {
  components: {

  },
  computed: {
    ...mapGetters(['context']),
    background () {
      return 'background-image: url("' + this.coverPhoto.cdnUrl + '")'
    }
  },
  data () {
    return {

    }
  },
  methods: {

  },
  mounted () {

  },
  mixins: [MarketplacePresenters],
  props: ['marketplace']
}
</script>

<style scoped>
  .coverphoto img {
    border-radius: .5rem;
    border:5px solid white;
    width:100%;
    height:300px;
    object-fit: cover;
    object-position:0 0;
  }
  .profilephoto {
    border-radius: .5rem;
    width:200px;
    height:200px;
    margin-top:-190px;
    margin-left:32px;
  }
  .profilephoto img {
    border:5px solid white;
  }
</style>
