<template>
  <div>
    <!-- Most recent activity event -->
    <div v-if="latest !== null" class="columns is-mobile is-marginless">
      <div class="column is-narrow">
        <span class="has-text-weight-bold">{{ $dates.utc(latest.created_at).local().format('MMM D') }}</span>
      </div>
      <div class="column">
        <span class="has-text-grey">{{ latest.activity_event.name }}</span>
      </div>
    </div>
    <!-- Remaining Events -->
    <div v-if="activity !== null && activity.length > 0">
      <div v-if="showAll">
        <div v-for="event in activity" class="columns is-mobile is-marginless" :key="event.id">
          <div class="column is-narrow">
            <span class="has-text-weight-bold">{{ $dates.utc(event.created_at).local().format('MMM D') }}</span>
          </div>
          <div class="column">
            <span class="has-text-grey">{{ event.activity_event.name }}</span>
          </div>
        </div>
      </div>
      <div v-else>
        <p class="mb-md"><b-icon icon="dots-vertical" /></p>
        <p class="mb-md"><button class="button is-outlined" @click.prevent="showAll = !showAll" v-text="(showAll) ? 'Collapse Activity' : 'Show All Activity'"></button></p>
        <p class="mb-md"><b-icon icon="dots-vertical" /></p>
      </div>
    </div>
    <!-- Oldest event is order being created, so it's always present -->
    <div class="columns is-mobile is-marginless">
      <div class="column is-narrow">
        <span class="has-text-weight-bold">{{ oldest.date }}</span>
      </div>
      <div class="column">
        <span class="has-text-grey">Order was received from {{ order.retailer.name }} at {{ oldest.time }}.</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  computed: {
    ...mapGetters(['context']),
    collapsible () {
      if (this.activity === null || this.activity.length < 3) return false
      return true
    },
    oldest () {
      return {
        retailer: this.order.retailer.name,
        date: this.$dates.utc(this.order.received_at).local().format('MMM D'),
        time: this.$dates.utc(this.order.received_at).local().format('h:mm A')
      }
    },
    endpoint () {
      return this.context.route + '/orders/' + this.order.id + '/activity-feed/?order_by=-id'
    }
  },
  data () {
    return {
      latest: null,
      activity: null,
      collapsed: false,
      showAll: false
    }
  },
  methods: {
    loadActivity () {
      this.loading = true
      this.$http.get(this.endpoint).then(response => {
        if (response.data.length > 0) {
          this.latest = response.data.shift()
        }
        this.activity = response.data
        this.loading = false
      })
    }
  },
  mounted () {
    this.loadActivity()
  },
  props: ['order']
}
</script>
