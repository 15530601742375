<template>
  <div>
    <p class="has-text-weight-bold mb-sm">Carrier Service</p>
    <!-- Select Service -->
    <div>
      <b-field>
        <b-select
          v-model="method"
          placeholder="Select service..."
          expanded
          :disabled="methods.length === 0"
          size="is-medium"
          @input="announce">
          <option v-for="method in methods" :key="method.id" :value="method">{{ method.name }}</option>
        </b-select>
      </b-field>
      <div>
        <p v-if="method !== null" class="has-text-grey is-size-7">
          {{ method.generic_shipping_method.name }}
        </p>
        <p v-else class="has-text-grey-light is-size-7">
          Select a method above...
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  computed: {
    ...mapGetters(['context'])
  },
  data () {
    return {
      errors: [],
      methods: [],
      method: null
    }
  },
  methods: {
    announce () {
      this.$emit('input', this.method)
    }
  },
  mounted () {

  },
  props: ['account', 'requestedService'],
  watch: {
    account (account) {
      if (account !== null) {
        this.methods = account.shipping_methods
        this.method = account.shipping_methods.find(method => {
          return method.generic_shipping_method.name === this.requestedService.name
        }) || null
        if (this.method === null && this.methods.length > 0) {
          this.method = this.methods[0]
        }
      } else {
        this.methods = []
      }
      this.$emit('input', this.method)
    }
  }
}
</script>
