<template>
  <span>{{ $numbers(margin).format({ output: 'percent', mantissa: 1 }) }}</span>
</template>

<script>
export default {
  components: {

  },
  data () {
    return {

    }
  },
  computed: {
    cost () {
      if ('retailer_cost' in this.pricing) {
        return parseFloat(this.getValue('retailer_cost'))
      }
      return null
    },
    price () {
      if ('retailer_price' in this.pricing) {
        return parseFloat(this.getValue('retailer_price'))
      }
      return null
    },
    margin () {
      if (this.cost && this.price) {
        return (this.price - this.cost) / this.price
      }
      return null
    }
  },
  methods: {
    getValue (key) {
      let value = this.pricing[key].values[0].value
      if (this.pricing[key].values.length > 1) {
        const values = this.pricing[key].values
        // fetch the last created value
        value = values.reduce((a, b) => a.created_at > b.created_at ? a : b).value
      }
      return value
    }
  },
  mounted () {

  },
  props: ['pricing']
}
</script>
