<template>
  <div>
    <!-- Content -->
    <div class="">
      <div class="mb-lg">
        <p class="mb-md">
          {{ app.platform }} trades EDI documents over SFTP through our proprietary, in-house EDI system.
          <strong class="has-text-success">There are no additional setup or monthly fees to use EDI.</strong>
        </p>
        <p class="mb-md">
          To administer the best possible customer experience,
          {{ app.platform }} can exchange the following documents:
        </p>
        <ul class="pl-md mb-md">
          <li class="has-text-weight-bold">846 Inventory Advice</li>
          <li class="has-text-weight-bold">850 Purchase Order</li>
          <li class="has-text-weight-bold">856 Advance Ship Notice</li>
          <li class="has-text-weight-bold">810 Invoice</li>
          <li>860 PO Change Request</li>
          <li>855 PO Acknowledgement</li>
        </ul>
        <p class="mb-md">
          The documents in <strong>bold</strong> are required or highly recommended.
          Any documents that are not implemented will require
          your team to operate inside the {{ app.platform }} Supplier portal to perform
          the equivalent action.
        </p>
        <p>The link to our EDI specifications is below:</p>
      </div>
      <!-- EDI Specs Link -->
      <div class="has-background-light pa-lg rounded-lg mb-xl">
        <a :href="specificationsUrl" target="_blank">
          <div class="columns is-vcentered is-mobile">
            <div class="column is-narrow">
              <b-icon icon="download" size="is-medium" custom-class="has-text-grey"></b-icon>
            </div>
            <div class="column">
              <p class="has-text-weight-bold has-text-info">EDI Specifications</p>
              <p class="has-text-grey">
                Download the EDI specifications file
              </p>
            </div>
          </div>
        </a>
      </div>
      <div class="mb-lg">
        Please download and review our specifications before continuing.
      </div>
    </div>
    <!-- Navigate -->
    <div class="py-lg">
      <div class="columns is-centered">
        <div class="column">
          <b-button
            type="is-light rounded-lg"
            size="is-medium"
            @click="goBack"
            expanded
            ><b-icon icon="arrow-left"></b-icon>
          </b-button>
        </div>
        <div class="column">
          <b-button
            type="is-primary rounded-lg"
            size="is-medium"
            expanded
            :disabled="!ready || saving"
            :loading="saving"
            @click="goForward"
          >Continue</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['app', 'context']),
    ready () {
      return true
    }
  },
  data () {
    return {
      specificationsUrl: 'https://res.cloudinary.com/revcascade/image/upload/v1667864086/static/resources/fabricMarketplaceEDISpecifications_Oct2021.pdf',
      qualifier: null,
      identifier: null,
      saving: false,
      errors: {}
    }
  },
  methods: {
    goBack () {
      this.$router.push({ name: 'onboarding.tasks', params: { connection: this.connection.id } })
    },
    goForward () {
      if (this.ediAccount) {
        this.$emit('navigate', 'Account')
      } else {
        this.$emit('navigate', 'Provision')
      }
    }
  },
  props: {
    connection: {
      type: Object,
      required: true
    },
    task: {
      type: Object,
      required: true
    },
    ediAccount: {
      type: Object,
      default: null
    }
  }
}
</script>
