<template>
  <div class="mb-md">
    <b-field label="Commission Profile">
      <b-select
        v-model="profile"
        :placeholder="getPlaceholder"
        expanded
        :loading="loading"
        :disabled="loading || disabled"
        :size="sizeClass"
        @input="announce">
        <option
          v-for="profile in profiles"
          :value="profile"
          :key="profile.id"
        >{{ profile.name }}</option>
        <option v-if="!profiles.length" disabled>No results found</option>
      </b-select>
    </b-field>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  data () {
    return {
      loading: false,
      profile: null,
      profiles: []
    }
  },
  computed: {
    ...mapGetters(['api', 'context']),
    endpoint () {
      return this.context.route + '/commission-profiles/'
    },
    query () {
      return {
        params: {
          pagination: 0,
          short: 1,
          order_by: 'id'
        }
      }
    },
    sizeClass () {
      return (this.size) ? this.size : null
    },
    getPlaceholder () {
      return this.placeholder ? this.placeholder : 'Select Profile'
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint, this.query).then(response => {
        this.profiles = response.data.results
        // set profile based on id
        const id = this.value !== null && this.value.id
        if (id) {
          this.profile = this.profiles.find(el => el.id === parseInt(id))
        }
        this.loading = false
      })
    },
    announce () {
      this.$emit('input', this.profile)
    }
  },
  mounted () {
    this.load()
  },
  props: ['value', 'size', 'placeholder', 'disabled']
}
</script>
