<template>
  <div>
    <FilterToolbar
      :hasChanges="hasChanges"
      :appliedFilters="appliedFilters"
      @search="search"
      @update="update"
      @clear="clear">
      <RemoteFacetFilter
        class="border-b"
        v-model="filters.facets.connection_id"
        response-type="object"
        :resourceLabel="(context.isSupplier()) ? 'retailer.name' : 'brand.name'"
        :filter="filterOptions.connection"
        :endpoint="context.route + '/connections/?short=1'"
        @input="change"
      />
      <DateFilter
        class="border-b"
        v-model="filters.timestamps.created_at"
        :filter="filterOptions.createdAt"
        @input="change"
      />
      <DateFilter
        class="border-b"
        v-model="filters.timestamps.recorded_at"
        :filter="filterOptions.recordedAt"
        @input="change"
      />
      <FacetFilter
        v-if="context.isMerchant()"
        v-model="filters.facets.status"
        :filter="filterOptions.status"
        @input="change"
      />
    </FilterToolbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import * as filterOptions from '../../filters.js'
import FilterToolbar from '@/components/Filters/FilterToolbar'
import DateFilter from '@/components/Filters/DateFilter'
import FacetFilter from '@/components/Filters/FacetFilter'
import RemoteFacetFilter from '@/components/Filters/RemoteFacetFilter'

export default {
  components: {
    DateFilter,
    FilterToolbar,
    FacetFilter,
    RemoteFacetFilter
  },
  data () {
    return {
      hasChanges: false,
      filters: null,
      // filters
      filterOptions: filterOptions
    }
  },
  computed: {
    ...mapGetters(['context'])
  },
  methods: {
    change () {
      this.hasChanges = true
    },
    search (searchQuery) {
      this.filters.search = {
        parameter: 'search',
        query: searchQuery
      }
      this.$emit('filter', this.filters)
    },
    update () {
      this.hasChanges = false
      this.filters.preset = 'custom'
      this.$emit('filter', this.filters)
    },
    clear () {
      this.filters = {
        preset: 'all',
        facets: {},
        search: {},
        timestamps: {}
      }
      this.$emit('filter', this.filters)
    }
  },
  mounted () {

  },
  props: {
    appliedFilters: {
      type: Object,
      required: true
    }
  },
  watch: {
    appliedFilters: {
      deep: true,
      immediate: true,
      handler (filters) {
        this.filters = filters
      }
    }
  }
}
</script>
