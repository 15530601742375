<template>
  <InspectionModal
    :endpoint="endpoint"
    :loadDetail="loadDetail"
    :seed="selectedProposal">
    <template v-slot:default="props">
      <div>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Inspect Proposal</p>
          </header>
          <section class="modal-card-body">
            <!-- Loaded -->
            <div v-if="!props.loading && props.record !== null" class="">
              <!-- Details -->
              <div class="mb-lg">
                <p class="has-text-weight-bold mb-sm">Proposal Details</p>
                <table class="table is-fullwidth is-striped is-narrow is-size-7 border">
                  <tbody>
                    <tr>
                      <th style="width:164px">ID</th>
                      <td>{{ props.record.id }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-- Loading -->
            <div v-else>
              <loading message="Loading proposal..." />
            </div>
          </section>
          <footer class="modal-card-foot">
            <button class="button" type="button" @click="$parent.close()">Close</button>
          </footer>
        </div>
      </div>
    </template>
  </InspectionModal>
</template>

<script>
import InspectionModal from '@/components/Modals/InspectionModal'
import { mapGetters } from 'vuex'

export default {
  components: {
    InspectionModal
  },
  data () {
    return {
      loadDetail: true
    }
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/proposals/' + this.selectedProposal.id + '/'
    }
  },
  methods: {

  },
  mounted () {

  },
  props: ['selectedProposal']
}
</script>
