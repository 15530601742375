<template>
  <div>
    <!-- Content -->
    <div class="mb-lg">
      <div class="mb-lg">
        <p class="has-text-weight-bold mb-md">Shopify Installation Complete!</p>
        <p>
          Your Shopify integration has been installed and configured. If you
          need to make any changes, you can return to this task or access the
          <router-link class="has-text-info" :to="{ name: 'settings.integrations.shopify', params: { id: this.account.id }}">Shopify settings</router-link>
          page.
        </p>
      </div>
      <!-- <div>
        <p class="has-text-weight-bold mb-md">Do you want to import products from Shopify?</p>
        <b-field><b-radio v-model="importSetting" native-value="shopify">Yes, I want to import a collection of products from Shopify</b-radio></b-field>
        <b-field><b-radio v-model="importSetting" native-value="template">No, I prefer to uplaod a template file.</b-radio></b-field>
      </div> -->
    </div>
    <!-- Navigate -->
    <div class="py-lg">
      <div class="columns is-centered">
        <div class="column">
          <b-button
            type="is-light rounded-lg"
            size="is-medium"
            @click="goBack"
            expanded
            ><b-icon icon="arrow-left"></b-icon>
          </b-button>
        </div>
        <div class="column">
          <b-button
            type="is-primary rounded-lg"
            size="is-medium"
            expanded
            @click="goForward"
            :disabled="loading || !ready || saving"
            :loading="saving"
          >Complete Task</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {

  },
  computed: {
    ...mapGetters(['app', 'context']),
    ready () {
      return true
    },
    loading () {
      return (this.loadingTasks)
    }
  },
  data () {
    return {
      loadingTasks: true,
      tasks: [],
      importSetting: null,
      saving: false,
      errors: {}
    }
  },
  methods: {
    goBack () {
      this.$emit('navigate', 'CarrierMapping')
    },
    async goForward () {
      this.saving = true
      // if (this.importSetting === 'shopify') {
      //   await this.addShopifyImportTask()
      // } else {
      //   await this.addProductImportTask()
      // }
      // await this.addProductImportTask()
      await this.completeTask()
      this.saving = false
    },
    addShopifyImportTask () {
      const task = this.$utils.find(this.tasks, ['code', 'import-shopify'])
      if (task && this.importSetting === 'shopify') {
        const endpoint = this.context.route + '/assigned-tasks/'
        const json = {
          onboarding_task: { id: task.id }
        }
        return this.$http.post(endpoint, json)
      }
    },
    addProductImportTask () {
      const task = this.$utils.find(this.tasks, ['code', 'import-template'])
      if (task && this.importSetting === 'template') {
        const endpoint = this.context.route + '/assigned-tasks/'
        const json = {
          onboarding_task: { id: task.id }
        }
        return this.$http.post(endpoint, json)
      }
    },
    completeTask () {
      const endpoint = this.context.route + '/assigned-tasks/' + this.$route.params.task + '/complete/'
      return this.$http.put(endpoint).then(() => {
        this.$router.push({ name: 'onboarding.tasks', params: { connection: this.connection.id } })
      })
    },
    loadTasks () {
      this.loadingTasks = true
      const endpoint = this.context.route + '/onboarding-tasks/?type=conditional'
      this.$http.get(endpoint).then(response => {
        this.tasks = response.data.results
        this.loadingTasks = false
      })
    }
  },
  mounted () {
    this.loadTasks()
  },
  props: {
    connection: {
      type: Object,
      required: true
    },
    task: {
      type: Object,
      required: true
    },
    account: {
      type: Object,
      required: true
    }
  }
}
</script>
