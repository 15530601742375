<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">API Secret</p>
    </header>
    <section class="modal-card-body">
      <div class="has-text-centered">
        <!-- Intro Warning -->
        <div class="mb-lg">
          <p class="has-text-danger has-text-weight-bold is-size-5">Please Note</p>
          <p>
            You will only to be able to see your API secret once! <br/> If you
            lose it, you will have to generate a new set of credentials.
          </p>
        </div>
        <div class="mb-lg">
          <b-button
            size="is-medium"
            type="is-primary rounded-lg"
            @click="getSecret"
            :loading="loading"
            :disabled="loading || secret !== null"
          >Show API Secret</b-button>
        </div>
        <div v-if="!loading && secret !== null" class="mb-lg is-size-5">
          <p class="has-text-weight-bold mb-sm">API Secret</p>
          <div>
            <b-input type="textarea" :value="secret" :readonly="true"></b-input>
            <p><a href="#" @click.prevent="copySecret" class="has-text-info has-text-weight-bold is-size-6">copy</a></p>
          </div>
        </div>
        <div class="has-text-grey-light has-text-centered">
          <p>Please file a <a href="#" @click.prevent="$store.dispatch('supportRequest')" class="has-text-info">support request</a> if you need any assistance.</p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['app', 'context']),
    endpoint () {
      return this.context.route + '/users/' + this.client.id + '/retrieve-secret/'
    },
    body () {
      return {
        client_id: this.client.client_id
      }
    }
  },
  data () {
    return {
      loading: false,
      secret: null
    }
  },
  methods: {
    getSecret () {
      this.loading = true
      this.$http.put(this.endpoint, this.body).then(response => {
        this.secret = response.data.client_secret
        this.$emit('secret:retrieved')
      }).catch(() => {
        this.$buefy.toast.open({ type: 'is-danger', message: 'An error occured retrieving your secret.' })
      }).finally(() => {
        this.loading = false
      })
    },
    copySecret () {
      this.$copyText(this.secret).then(() => {
        this.$buefy.toast.open({ type: 'is-success', message: 'API secret copied!' })
      })
    }
  },
  props: ['client']
}
</script>
