<template>
  <div>
    <!-- Actions Toolbar -->
      <div class="has-background-white rounded-lg px-md py-sm mb-xxs">
        <div class="columns is-vcentered is-mobile">
          <div class="column is-narrow">
            <b-dropdown :disabled="loading || selected.length === 0">
              <button class="button" slot="trigger" slot-scope="{ active }" :disabled="selected.length === 0">
                <span>Actions</span>
                <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
              </button>
              <template v-if="context.isSupplier()">
                <b-dropdown-item has-link><a href="#" @click.prevent class="has-text-grey-light">No Actions</a></b-dropdown-item>
              </template>
              <template v-if="context.isMerchant()">
                <b-dropdown-item has-link><a href="#" @click.prevent="showConfirmModal = true">Add Credits</a></b-dropdown-item>
              </template>
            </b-dropdown>
          </div>
          <div class="column is-narrow">
            <div class="is-size-7">
              <!-- Counts -->
              <p v-if="loading">Loading...</p>
              <p v-else>{{ total ? total : 0 }} credits found.</p>
            </div>
          </div>
        </div>
      </div>
      <!-- Results -->
      <div>
        <div class="mb-md">
          <b-table
            :data="results"
            :checked-rows.sync="selected"
            :mobile-cards="false"
            :loading="loading"
            hoverable
            checkable
            checkbox-position="left"
            class="is-size-7">
            <template>
              <b-table-column
                label="ID"
                cell-class="nowrap"
                width="128"
                v-slot="props">
                {{ props.row.id }}
              </b-table-column>
              <!-- Credit Memo # -->
              <b-table-column
                label="Credit Memo #"
                header-class="nowrap"
                cell-class="nowrap"
                width="196"
                v-slot="props">
                <p v-if="props.row.credit_number">{{ props.row.credit_number }}</p>
                <p v-else class="has-text-grey-light is-italic">None Provided</p>
              </b-table-column>
              <!-- PO # -->
              <b-table-column
                label="PO #"
                cell-class="nowrap"
                v-slot="props">
                {{ props.row.order.purchase_order_number }}
              </b-table-column>
              <!-- Registered -->
              <b-table-column
                label="Registered"
                header-class="nowrap"
                cell-class="nowrap"
                centered
                width="128"
                v-slot="props">
                <span v-if="props.row.rmad_at === null">--</span>
                <span v-else>{{ $dates.utc(props.row.credited_at).local().format('MMM D, YYYY') }}</span>
              </b-table-column>
            </template>
            <template #empty>
              <div class="py-xxl has-text-grey has-text-centered">
                {{ (loading) ? "Loading data..." : 'No credits found!' }}
              </div>
            </template>
          </b-table>
        </div>
        <b-pagination
          :total="total"
          :current.sync="currentPage"
          :rounded="true"
          :per-page="perPage"
          @change="paginate">
        </b-pagination>
      </div>
      <!-- Add Credit -->
      <b-modal
        v-if="showConfirmModal"
        has-modal-card
        :active="showConfirmModal"
        :can-cancel="['escape', 'x']"
        @close="closeModals"
        ><AddCreditsModal
          :selectedCredits="selected"
          :payment="payment"
          @reload:payment="reloadPayment"
        />
      </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Filterable from '@/mixins/Filterable'
import AddCreditsModal from '../components/Credits/AddCreditsModal'
export default {
  components: {
    AddCreditsModal
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/credits/'
    },
    alwaysAttach () {
      return {
        skip_attributes: 1,
        brand_id: this.payment.brand.id,
        has_payment: 0
      }
    }
  },
  data: () => {
    return {
      key: 'paymentCredits',
      showConfirmModal: false
    }
  },
  methods: {
    closeModals () {
      this.showConfirmModal = false
    },
    reloadPayment () {
      this.$emit('updated')
    }
  },
  mounted () {
    this.load()
  },
  props: ['payment'],
  mixins: [Filterable]
}
</script>
