import dates from '@/assets/js/dates'

export const isResolved = {
  label: 'Is Resolved?',
  key: 'resolved',
  icon: 'pulse',
  tooltip: 'Filter exceptions based on status.',
  options: [{
    value: 1,
    label: 'Yes'
  }, {
    value: 0,
    label: 'No'
  }]
}
export const createdAt = {
  label: 'Created At',
  key: 'created_at',
  icon: 'calendar-outline',
  tooltip: 'Filter exceptions based the date and time they were created',
  options: dates
}
