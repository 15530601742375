<template>
  <div>
    <div class="columns is-vcentered is-variable is-2">
      <!-- Order Is Open -->
      <template v-if="orderIsOpen">
        <!-- Cancel Items -->
        <div class="column is-one-quarter">
          <b-button type="is-light" size="is-medium" expanded  @click="$emit('action:cancel')">Cancel Items</b-button>
        </div>
        <!-- Update Address -->
        <div class="column is-one-quarter">
          <b-button type="is-light" size="is-medium" expanded  @click="$emit('action:shipto')">Change Ship To</b-button>
        </div>
        <!-- Order Is a New Backorder -->
        <div v-if="orderIsNewBackorder" class="column is-one-quarter">
          <b-button type="is-light" size="is-medium" expanded  @click="$emit('action:acknowledgeBackorder')">Acknowledge Backorder</b-button>
        </div>
      </template>
      <!-- Order Is Closed -->
      <template v-else-if="orderIsClosed">
        <div class="column is-one-quarter">
          <b-button type="is-light" size="is-medium" expanded  @click="$emit('action:return')">Start a Return</b-button>
        </div>
      </template>
      <!-- All Other States -->
      <template v-else>
        <div class="column is-one-quarter">
          <b-button type="is-light" size="is-medium" expanded  @click.prevent :disabled="true">
            No Actions
          </b-button>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  data () {
    return {

    }
  },
  computed: {
    ...mapGetters(['auth', 'context']),
    orderIsNew () {
      return ((this.order.status === 'open' || this.order.status === 'partial') && this.order.acknowledged_at === null)
    },
    orderIsOpen () {
      return (this.order.status === 'open' || this.order.status === 'partial')
    },
    orderIsClosed () {
      return (this.order.status === 'closed')
    },
    orderIsCanceled () {
      return this.order.status === 'canceled'
    },
    orderIsNewBackorder () {
      return this.order.backordered_until !== null && this.order.backorder_acknowledged_at === null
    }
  },
  methods: {

  },
  mounted () {

  },
  props: ['order']
}
</script>
