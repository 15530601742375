<template>
  <div class="section">
    <div class="container">
      <!-- Page Header -->
      <div class="mb-md">
        <div class="columns is-vcentered">
          <div class="column is-one-third">
            <breadcrumbs :history="[{ label: 'Settings', name: 'settings'}]" current="Branding" />
          </div>
          <div class="column is-one-third">
            <h1 class="has-text-centered is-size-4">Branding</h1>
          </div>
        </div>
      </div>
      <!-- Cover Photo -->
      <div class="pb-lg mb-lg">
        <LogoUpload :loading="loading" :logoUrl="(!loading && partner !== null) ? partner.logo_url : null" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LogoUpload from '../components/Branding/LogoUpload'

export default {
  components: {
    LogoUpload
  },
  data () {
    return {
      loading: false,
      partner: null
    }
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/'
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint).then(response => {
        this.partner = response.data
        this.loading = false
      })
    }
  },
  mounted () {
    this.load()
  },
  props: []
}
</script>
