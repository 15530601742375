<template>
  <div>
    <div class="columns">
      <div class="column is-one-fifth">
        <p class="has-text-weight-bold is-size-6 mb-sm">Retry Fulfillments</p>
        <p class="has-text-grey is-size-7">Attempt to retry an order fulfillment import.</p>
      </div>
      <div class="column">
        <div class="has-background-white rounded-lg">
          <div class="pa-lg">
            <!-- Intro Text (Merchant) -->
            <div class="is-size-7 mb-lg">
              <p class="mb-md">
                If an order fulfillment didn't import into {{ app.productName }}, its likely there
                was a problem processing the {{ account.platform.name }} webhook. Enter a {{ account.platform.name }} order number
                and we'll attempt to fetch and import an existing fulfillments.
              </p>
            </div>
            <!-- Form -->
            <form @submit.prevent="retryOrder" class="mb-lg">
              <p class="has-text-weight-bold mb-sm">ShipStation Order Number</p>
              <div class="columns">
                <div class="column">
                  <b-field>
                    <b-input
                      autocomplete="off"
                      type="text"
                      size="is-medium"
                      v-model="shipstationOrderNumber"
                    ></b-input>
                  </b-field>
                </div>
                <div class="column is-narrow">
                  <b-button
                    type="is-primary"
                    native-type="submit"
                    size="is-medium"
                    :loading="processing"
                    :disabled="processing || !valid"
                  >Retry Fulfillment Import</b-button>
                </div>
              </div>
            </form>
            <!-- Job Result -->
            <div class="is-size-7">
              <div v-if="processing">
                <p class="has-text-grey-light is-italic">Scheduling retry...</p>
              </div>
              <div v-else-if="errorMessage !== null">
                <p class="has-text-danger is-italic">{{ errorMessage }}</p>
              </div>
              <div v-else-if="job !== null">
                <span class="has-text-weight-bold has-text-success">Retry Scheduled!</span>
                Your input resulted in scheduled job <a href="#" class="has-text-info" @click.prevent="inspectJob = job">#{{ job.id }}</a>.
                Please allow 5-10 minutes this task to complete. Please refer to the job status for more information.
              </div>
              <div v-else>
                <p class="has-text-grey-light is-italic">Enter a ShipStation Order Number above and click 'Retry Fulfillment Import'.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Inspect Job -->
    <b-modal
      v-if="(inspectJob !== null)"
      has-modal-card
      :active="(inspectJob !== null)"
      :can-cancel="['escape', 'x']"
      @close="inspectJob = null"
      ><JobModal :selectedJob="inspectJob" />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import JobModal from '@/components/Modals/Job/JobModal'
export default {
  components: {
    JobModal
  },
  computed: {
    ...mapGetters(['app', 'context']),
    endpoint () {
      return this.context.route + '/shipstation/' + this.account.id
    },
    json () {
      return {
        shipstation_order_number: this.shipstationOrderNumber
      }
    },
    valid () {
      return Boolean(this.shipstationOrderNumber)
    }
  },
  data () {
    return {
      shipstationOrderNumber: null,
      processing: false,
      job: null,
      inspectJob: null,
      errorMessage: null
    }
  },
  methods: {
    reset () {
      this.processing = false
      this.errorMessage = null
      this.job = null
    },
    retryOrder () {
      // look for order first...
      this.reset()
      this.processing = true
      this.$http.put(this.endpoint + '/retry-shipment/', this.json).then(response => {
        this.job = response.data
        this.$buefy.toast.open({ type: 'is-success', message: 'Order fulfillment import scheduled.' })
        this.shipstationOrderNumber = null
      }).catch(error => {
        this.$buefy.toast.open({ type: 'is-danger', message: 'Order fulfillment import failed!' })
        if (error.response.status === 400 && error.response.data === 'Invalid Request') {
          this.errorMessage = 'An error occurred while attempting to import your order. It is likely that the order Number you supplied is not correct. Please double check your ShipStation Order Number and try again.'
        }
      }).finally(() => {
        this.processing = false
      })
    }
  },
  props: {
    account: {
      type: Object,
      required: true
    }
  }
}
</script>
