<template>
  <div>
    <template v-if="is_valid">
      <div class="mb-sm">
        <p>
          <span :class="(size > 165) ? 'has-text-danger' : '' ">{{ size.toFixed(0) + dimensions.width_unit.symbol }}</span>,
          {{ dimensions.weight }} {{ dimensions.weight_unit.standard_unit }}
        </p>
        <p class="is-size-8 has-text-grey">
            {{ dimensions.width }}{{ dimensions.width_unit.symbol }}
            x {{ dimensions.height }}{{ dimensions.height_unit.symbol }}
            x {{ dimensions.length }}{{ dimensions.height_unit.symbol }}
        </p>
      </div>
   </template>
   <template v-else>
     <p>Invalid Carton</p>
   </template>
  </div>
</template>

<script>
export default {
  computed: {
    size: function () {
      if (this.is_valid && this.has_same_units) {
        // convert dimensions to integers and sort them, high to low
        const dimensions = [parseFloat(this.dimensions.height), parseFloat(this.dimensions.width), parseFloat(this.dimensions.length)]
        const sorted = this.$utils.orderBy(dimensions, [o => {
          return o
        }], ['desc'])

        // return the calculated girth
        return (sorted[0] + (2 * (sorted[1] + sorted[2])))
      }
      return '--'
    },
    valid_width: function () {
      return !!((this.dimensions.width && this.dimensions.width_unit))
    },
    valid_height: function () {
      return !!((this.dimensions.height && this.dimensions.height_unit))
    },
    valid_length: function () {
      return !!((this.dimensions.length && this.dimensions.length_unit))
    },
    valid_weight: function () {
      return !!((this.dimensions.weight && this.dimensions.weight_unit))
    },
    is_valid: function () {
      return !!((this.valid_width && this.valid_height && this.valid_length && this.valid_weight))
    },
    has_same_units () {
      return !!((this.dimensions.height_unit.id === this.dimensions.width_unit.id && this.dimensions.width_unit.id === this.dimensions.length_unit.id))
    }
  },
  data () {
    return {}
  },
  methods: {
  },
  props: {
    dimensions: {
      type: Object,
      required: true
    }
  }
}
</script>
