<template>
  <div>
    <div class="columns is-mobile is-multiline">
      <!-- Open -->
      <div class="column is-half">
        <div class="has-background-white rounded-lg px-md py-sm">
          <router-link :to="{ name: 'orders.list', query: { preset: 'open'} }">
            <div class="has-text-centered">
              <p class="is-size-5 has-text-weight-bold" style="margin-bottom:-5px">
                <span v-if="loading"><b-icon icon="loading" custom-class="mdi-spin" size="is-small" /></span>
                <span v-else>{{ totalOpen }}</span>
              </p>
              <p class="is-size-7 has-text-grey">Open Orders</p>
            </div>
          </router-link>
        </div>
      </div>
      <!-- Current -->
      <div class="column is-half">
        <div class="has-background-white rounded-lg px-md py-sm">
          <router-link :to="{ name: 'orders.list', query: { preset: 'current'} }">
            <div class="has-text-centered">
              <p class="is-size-5 has-text-weight-bold" style="margin-bottom:-5px">
                <span v-if="loading"><b-icon icon="loading" custom-class="mdi-spin" size="is-small" /></span>
                <span v-else class="has-text-success">{{ totalCurrent }}</span>
              </p>
              <p class="is-size-7 has-text-grey">Current Orders</p>
            </div>
          </router-link>
        </div>
      </div>
      <!-- Late Fulfillment -->
      <div class="column is-half">
        <div class="has-background-white rounded-lg px-md py-sm">
          <router-link :to="{ name: 'orders.list', query: { preset: 'notShipped'} }">
            <div class="has-text-centered">
              <p class="is-size-5 has-text-weight-bold" style="margin-bottom:-5px">
                <span v-if="loading"><b-icon icon="loading" custom-class="mdi-spin" size="is-small" /></span>
                <span v-else-if="totalLateFulfillment > 0" class="has-text-danger">{{ totalLateFulfillment }}</span>
                <span v-else class="has-text-success">0</span>
              </p>
              <p class="is-size-7 has-text-grey">Past Fulfillment SLA</p>
            </div>
          </router-link>
        </div>
      </div>
      <!-- Late Invoice -->
      <div class="column is-half">
        <div class="has-background-white rounded-lg px-md py-sm">
          <router-link :to="{ name: 'orders.list', query: { preset: 'notInvoiced'} }">
            <div class="has-text-centered">
              <p class="is-size-5 has-text-weight-bold" style="margin-bottom:-5px">
                <span v-if="loading"><b-icon icon="loading" custom-class="mdi-spin" size="is-small" /></span>
                <span v-else-if="totalLateInvoicing > 0" class="has-text-danger">{{ totalLateInvoicing }}</span>
                <span v-else class="has-text-success">0</span>
              </p>
              <p class="is-size-7 has-text-grey">Late Invoices</p>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {
  },
  computed: {
    ...mapGetters(['context']),
    loading () {
      return (this.loadingAging || this.loadingOrders)
    }
  },
  data () {
    return {
      loadingAging: true,
      loadingOrders: true,
      vendors: [],
      showVendorBreakout: false,
      totalOpen: 0,
      totalCurrent: 0,
      totalLateFulfillment: 0,
      totalLateInvoicing: 0
    }
  },
  methods: {
    loadAging () {
      this.loadingAging = true
      const endpoint = this.context.route + '/orders-aging-summary/'
      this.$http.get(endpoint).then(response => {
        // sort vendors
        this.vendors = this.$utils.sortBy(response.data, ['name'])
        // loop through and summarize
        this.vendors.forEach(vendor => {
          this.totalLateFulfillment += vendor.late_orders_awaiting_fulfillment
          this.totalLateInvoicing += vendor.late_orders_awaiting_invoicing
          this.totalCurrent += (vendor.current_orders_awaiting_fulfillment + vendor.current_orders_awaiting_invoicing)
        })
        this.loadingAging = false
      })
    },
    loadOrders () {
      this.loadingOrders = true
      const endpoint = this.context.route + '/orders/?status=open,partial&fields=id&limit=1'
      this.$http.get(endpoint).then(response => {
        this.totalOpen = response.data.count
        this.loadingOrders = false
      })
    }
  },
  mounted () {
    this.loadAging()
    this.loadOrders()
  },
  props: []
}
</script>
