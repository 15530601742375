<template>
  <div>
    <!-- Filter & Search Input Controls -->
    <form @submit.prevent="search">
      <b-field>
        <div class="mb-md">
          <b-input
            :appliedFilters="appliedFilters"
            icon="magnify"
            type="search"
            size="is-medium"
            placeholder="Search..."
            expanded
            v-model="searchQuery"
           />
         </div>
        <p class="control">
          <button type="submit" class="button is-primary is-medium">
            <b-icon icon="keyboard-return" custom-class="mdi-flip-h"></b-icon>
          </button>
        </p>
      </b-field>
      <!-- Filters Slot -->
      <slot></slot>
    </form>
  </div>
</template>

<script>
export default {
  data () {
    return {
      searchQuery: null
    }
  },
  computed: {
    instructions () {
      return (this.hasChanges)
        ? 'Click \'Update\' to apply new filters.'
        : 'Explore the filters below to target specific results.'
    }
  },
  methods: {
    search () {
      this.$emit('search', this.searchQuery)
    },
    update () {
      this.$emit('update')
      this.showFiltersModal = false
      this.$buefy.toast.open({ type: 'is-success', message: 'Filters updated!' })
    },
    clear () {
      this.$emit('clear')
      this.searchQuery = null
      this.$buefy.toast.open({ type: 'is-success', message: 'Filters cleared.' })
    }
  },
  mounted () {
    if ('query' in this.appliedFilters.search && this.appliedFilters.search.query !== '') {
      this.searchQuery = this.appliedFilters.search.query
    }
  },
  props: ['hasChanges', 'appliedFilters']
}
</script>
