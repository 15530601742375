<template>
  <span>{{ difference }}</span>
</template>

<script>
export default {
  computed: {
    startDatetime () {
      return this.$dates.utc(this.start)
    },
    endDatetime () {
      return this.$dates.utc(this.end)
    },
    difference () {
      if (this.startDatetime.isValid() && this.endDatetime.isValid()) {
        return this.$dates.duration(this.startDatetime.diff(this.endDatetime)).humanize()
      }
      return '--'
    }
  },
  props: ['start', 'end']
}
</script>
