<template>
  <div>
    <div class="section">
      <div class="container">

        <!-- Breadcrumbs -->
        <div>
          <div class="columns is-vcentered">
            <div class="column is-one-third">
              <breadcrumbs :history="[{ label: 'Discover', name: 'discover.dashboard'}]" :current="breadcrumb" :hideBack="true" />
            </div>
          </div>
        </div>

        <!-- Loaded (No Profile / Opt-in) -->
        <div v-if="loading">
          <loading message="Loading..." />
        </div>
        <!-- Loaded (With Profile) -->
        <div v-else-if="!loading && marketplace !== null">
          <!-- Profile Header -->
          <div class="py-lg">
            <profile-header :marketplace="marketplace" />
          </div>
          <!-- Profile Products -->
          <profile-products :marketplace="marketplace" />
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ProfileHeader from '../components/Profile/ProfileHeader'
import ProfileProducts from '../components/Profile/ProfileProducts'
export default {
  components: {
    ProfileHeader,
    ProfileProducts
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/marketplaces/' + this.$route.params.id + '/'
    },
    breadcrumb () {
      return (this.loading || this.marketplace === null) ? 'Loading...' : this.marketplace.brand.name
    }
  },
  data () {
    return {
      loading: true,
      marketplace: null
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint).then(response => {
        this.marketplace = response.data
      }).finally(() => {
        this.loading = false
      })
    }
  },
  mounted () {
    this.load()
  },
  props: ['profile']
}
</script>
