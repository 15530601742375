<template>
  <span :class="presenter.textClass">{{ presenter.label }}%</span>
</template>

<script>
export default {
  computed: {
    presenter () {
      const successful = this.orders - this.cancels
      const rate = this.$utils.round(successful / this.orders * 100)
      const presenter = {
        label: (rate < 0) ? 0 : rate,
        textClass: null
      }
      if (rate > 95) {
        presenter.textClass = 'has-text-success'
      } else if (rate > 80) {
        presenter.textClass = 'has-text-warning'
      } else {
        presenter.textClass = 'has-text-danger'
      }
      return presenter
    }
  },
  props: ['orders', 'cancels']
}
</script>
