var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"has-background-white rounded-lg"},[_c('div',{staticClass:"columns is-vcentered"},[_c('div',{staticClass:"column px-lg"},[_c('b-button',{staticClass:"is-pulled-right px-md",attrs:{"type":"is-primary rounded-lg","loading":_vm.loading},on:{"click":function($event){_vm.showCreateModal=true}}},[_vm._v("Create ")])],1)]),_c('div',{staticClass:"border-t"}),_c('div',[_c('b-table',{staticClass:"is-size-7",attrs:{"data":_vm.results,"mobile-cards":false,"loading":_vm.loading,"hoverable":""},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"py-xxl has-text-grey has-text-centered"},[_vm._v(" "+_vm._s((_vm.loading) ? "Loading data..." : 'Rule groups are not yet added to this ruleset!')+" ")])]},proxy:true}])},[[_c('b-table-column',{attrs:{"label":"ID","cell-class":"has-text-grey-light","width":"64","centered":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.id)+" ")]}}])}),_c('b-table-column',{attrs:{"label":"Name","header-class":"nowrap","cell-class":"nowrap"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('div',[_c('router-link',{staticClass:"has-text-info has-text-weight-bold",attrs:{"to":{
                  name: 'ruleset.rulegroup',
                  params: {
                    id: props.row.id,
                    ruleset: _vm.ruleSetId
                  }
                }}},[_vm._v(" "+_vm._s(props.row.name)+" ")])],1)]}}])}),_c('b-table-column',{attrs:{"label":"Position","centered":"","width":"128"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.position)+" ")]}}])}),_c('b-table-column',{attrs:{"label":"Updated At","width":"128","centered":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(_vm.$dates.utc(props.row.updated_at).local().format('MMM DD, YYYY'))+" ")]}}])})]],2)],1)]),_c('div',{staticClass:"mt-md"},[_c('b-pagination',{attrs:{"total":_vm.total,"current":_vm.currentPage,"rounded":true,"per-page":_vm.perPage},on:{"update:current":function($event){_vm.currentPage=$event},"change":_vm.paginate}})],1),(_vm.showCreateModal)?_c('b-modal',{attrs:{"has-modal-card":"","active":_vm.showCreateModal,"can-cancel":['escape', 'x']},on:{"close":function($event){_vm.showCreateModal=false}}},[_c('CreateRuleGroup',{on:{"reload:ruleGroups":_vm.reload}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }