<template>
  <div>
    <PerformanceSummary :start-date="startDate" :end-date="endDate" :dimension="dimension" :metric="metric"></PerformanceSummary>
    <div class="container">
      <div class="columns">
        <div class="column pa-sm ma-md rounded-lg has-background-white is-size-6">
          <DimensionSummary label="Top Suppliers" dimension="brand" :start-date="startDate" :end-date="endDate"></DimensionSummary>
        </div>
        <div class="column pa-sm ma-md rounded-lg has-background-white is-size-6">
          <DimensionSummary label="Top Products" dimension="variant" :start-date="startDate" :end-date="endDate"></DimensionSummary>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import PerformanceSummary from '@/modules/Performance/views/Summary.vue'
import DimensionSummary from '@/modules/Performance/views/DimensionSummary.vue'
export default {
  data () {
    return {}
  },
  computed: {
    ...mapGetters(['context']),
    summaryLabel () {
      switch (this.context.type) {
        case 'vendor':
          return 'Top Retailers'
        case 'retailer':
          return 'Top Suppliers'
        default:
          return ''
      }
    },
    summaryDimension () {
      switch (this.context.type) {
        case 'vendor':
          return 'retailer'
        case 'retailer':
          return 'brand'
        default:
          return ''
      }
    }
  },
  props: {
    startDate: Date,
    endDate: Date,
    dimension: String,
    metric: String
  },
  components: { PerformanceSummary, DimensionSummary }
}
</script>
