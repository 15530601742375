<template>
  <div>
    <!-- Toolbar -->
    <div class="has-background-white rounded-lg py-md px-lg mb-xxs">
      <div class="columns is-vcentered">
        <div class="column">
          <InvoiceFilters :applied-filters="filters" @filter="filter"/>
        </div>
      </div>
    </div>
    <!-- Actions Toolbar -->
      <div class="has-background-white rounded-lg px-md py-sm mb-xxs">
        <div class="columns is-vcentered is-mobile">
          <div class="column is-narrow">
            <b-dropdown :disabled="loading || selected.length === 0">
              <button class="button" slot="trigger" slot-scope="{ active }" :disabled="selected.length === 0">
                <span>Actions</span>
                <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
              </button>
              <template v-if="context.isSupplier()">
                <b-dropdown-item has-link><a href="#" @click.prevent class="has-text-grey-light">No Actions</a></b-dropdown-item>
              </template>
              <template v-if="context.isMerchant()">
                <b-dropdown-item has-link><a href="#" @click.prevent="showConfirmModal = true">Add Invoices</a></b-dropdown-item>
              </template>
            </b-dropdown>
          </div>
          <div class="column is-narrow is-size-7" v-if="total > 0 && showAddMatchingInvoices && !loading">
            <a href="#" class="has-text-info" @click.prevent="showConfirmModal = true"> Add {{total}} matching invoices to payment. </a>
          </div>
          <div class="column">
            <div class="is-size-7 has-text-right">
              <!-- Counts -->
              <p v-if="loading">Loading...</p>
              <p v-else>{{ total ? total : 0 }} invoices found.</p>
            </div>
          </div>
        </div>
      </div>
      <!-- Results -->
      <div>
        <div class="mb-md">
          <b-table
            :data="results"
            :checked-rows.sync="selected"
            :mobile-cards="false"
            :loading="loading"
            hoverable
            checkable
            checkbox-position="left"
            class="is-size-7">
            <template>
              <!-- Invoice # -->
              <b-table-column
                label="Invoice #"
                header-class="nowrap"
                cell-class="nowrap"
                v-slot="props">
                <a href="#"
                  class="has-text-weight-bold has-text-info"
                  @click.prevent="inspectInvoice = props.row"
                >{{ props.row.invoice_number }}</a>
              </b-table-column>
              <!-- Submitted -->
              <b-table-column
                label="Submitted"
                header-class="nowrap"
                cell-class="nowrap"
                centered
                width="128"
                v-slot="props">
                <span v-if="props.row.created_at === null">--</span>
                <span v-else>{{ $dates.utc(props.row.invoiced_at).local().format('MMM D, YYYY') }}</span>
              </b-table-column>
              <!-- Due -->
              <b-table-column
                v-if="context.isMerchant()"
                label="Due"
                header-class="nowrap"
                cell-class="nowrap"
                centered
                width="128"
                v-slot="props">
                <span v-if="props.row.terms_date_due === null">--</span>
                <span v-else>{{ $dates.utc(props.row.terms_date_due).local().format('MMM D, YYYY') }}</span>
              </b-table-column>
              <!-- Subtotal -->
              <b-table-column
                label="Subtotal"
                header-class="nowrap"
                cell-class="nowrap"
                centered
                width="96"
                v-slot="props">
                {{ $numbers(props.row.subtotal).formatCurrency({ mantissa: 2 }) }}
              </b-table-column>
              <!-- Adjustments -->
              <b-table-column
                label="Adjustments"
                header-class="nowrap"
                cell-class="nowrap"
                centered
                width="96"
                v-slot="props">
                {{ $numbers(props.row.due_amount).subtract(props.row.subtotal).formatCurrency({ mantissa: 2 }) }}
              </b-table-column>
              <!-- Due Amount -->
              <b-table-column
                label="Total"
                header-class="nowrap"
                cell-class="nowrap"
                centered
                width="96"
                v-slot="props">
                {{ $numbers(props.row.due_amount).formatCurrency({ mantissa: 2 }) }}
              </b-table-column>
            </template>
            <template #empty>
              <div class="py-xxl has-text-grey has-text-centered">
                {{ (loading) ? "Loading data..." : 'No invoices found!' }}
              </div>
            </template>
          </b-table>
        </div>
        <b-pagination
          :total="total"
          :current.sync="currentPage"
          :rounded="true"
          :per-page="perPage"
          @change="paginate">
        </b-pagination>
      </div>
      <!-- Inspect Invoice -->
      <b-modal
        v-if="(inspectInvoice !== null)"
        has-modal-card
        :active="(inspectInvoice !== null)"
        :can-cancel="['escape', 'x']"
        @close="closeModals"
        ><InvoiceModal :invoice="inspectInvoice" />
      </b-modal>
      <!-- Add Invoice -->
      <b-modal
        v-if="showConfirmModal"
        has-modal-card
        :active="showConfirmModal"
        :can-cancel="['escape', 'x']"
        @close="closeModals"
        ><AddInvoicesModal
          :selectedInvoices="selected"
          :payment="payment"
          :isAddMatchingInvoices="showAddMatchingInvoices"
          :total="total"
          :applied-filters="filters"
          @filter="filter"
          @reload:payment="reloadPayment"
        />
      </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Filterable from '@/mixins/Filterable'
import InvoiceModal from '@/components/Modals/Invoice/InvoiceModal'
import AddInvoicesModal from '../components/Invoices/AddInvoicesModal'
import InvoiceFilters from '../components/Invoices/InvoiceFilters'
export default {
  components: {
    InvoiceFilters,
    InvoiceModal,
    AddInvoicesModal
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/invoices/'
    },
    alwaysAttach () {
      return {
        skip_attributes: 1,
        brand_id: this.payment.brand.id,
        has_payment: 0
      }
    },
    showAddMatchingInvoices () {
      return (
        this.filters &&
        this.filters.preset === 'custom' &&
        Object.keys(this.filters.timestamps).length > 0 &&
        this.selected.length === 0
      )
    }
  },
  data: () => {
    return {
      key: 'paymentInvoices',
      showConfirmModal: false,
      inspectInvoice: null
    }
  },
  methods: {
    closeModals () {
      this.inspectInvoice = null
      this.showConfirmModal = false
    },
    reloadPayment () {
      this.$emit('updated')
    }
  },
  mounted () {
    this.load()
  },
  props: ['payment'],
  mixins: [Filterable]
}
</script>
