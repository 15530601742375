<template>
  <div>
    <div class="mt-md">
      <Transactions :pushNotifications="pushNotifications" />
    </div>
    <!-- Navigate -->
    <div class="py-lg">
      <div class="columns">
        <div class="column">
          <b-button
            type="is-light rounded-lg"
            expanded
            @click="goBack"
            size="is-medium"
            ><b-icon icon="arrow-left"></b-icon>
          </b-button>
        </div>
        <div class="column">
          <b-button
            type="is-primary rounded-lg"
            expanded
            :loading="isUpdating"
            @click="goForward"
            size="is-medium"
          >Continue</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Transactions from '@/components/Models/Notifications/Transactions'
export default {
  components: {
    Transactions
  },
  computed: {
    ...mapGetters(['app', 'context'])
  },
  data () {
    return {
      setupIsCompleted: false
    }
  },
  methods: {
    goBack () {
      this.$router.push({ name: 'retailer.onboarding.notifications' })
    },
    goForward () {
      this.updateTask()
    },
    updateTask () {
      const taskBody = (typeof this.task.info === 'object') ? { info: this.task.info } : { info: {} }
      taskBody.info.transactions = true
      this.$emit('task:updated', taskBody, 'retailer.onboarding.digest')
    }
  },
  mounted () {
    if (this.task && this.task.info && this.task.info.transactions) {
      this.setupIsCompleted = true
    }
  },
  props: {
    task: {
      type: Object,
      require: true
    },
    pushNotifications: {
      type: Array,
      required: true
    },
    isUpdating: {
      type: Boolean
    }
  }
}
</script>
