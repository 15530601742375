<template>
  <form @submit.prevent="update" novalidate autocomplete="off">
    <div class="columns">
      <div class="column is-one-fifth">
        <p class="has-text-weight-bold is-size-6 mb-sm">Business Address</p>
        <p class="has-text-grey is-size-7">Update your primary business address to be used for any correspondence.</p>
      </div>
      <div class="column">
        <div class="has-background-white rounded-xl">
          <div class="pa-lg pb-xl">
            <!-- Loading -->
            <div v-if="loading">
              <div class="pa-md mb-md" v-for="index in 3" :key="index">
                <div class="placeholder placeholder-title"></div>
                <div class="placeholder placeholder-sentence" style="width:50%"></div>
              </div>
            </div>
            <div v-else>
              <AddressForm
                type="billing"
                v-model="form"
                :addressForm="form"
                :defaultAddressName="context.partner.name"
                :errors="errors"
                :requireAddressName="false"
                :requireEmail="false"
                :requirePhone="true"
                @is:ready="isReady"
              />
            </div>
          </div>
          <div class="border-t">
              <div class="columns is-vcentered is-marginless">
                <div class="column">
                  <div class="px-sm has-text-grey-light">
                    <i v-if="isValidated">Click 'Update' to save your changes</i>
                  </div>
                </div>
                <div class="column is-narrow">
                  <div class="px-sm">
                    <b-button native-type="submit" type="is-primary rounded-lg" :disabled="updating || !isValidated">Update</b-button>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import AddressForm from '@/components/Global/AddressForm'
export default {
  components: {
    AddressForm
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return (this.id === null) ? this.context.route + '/locations/' : this.context.route + '/locations/' + this.id + '/'
    },
    query () {
      return {
        params: {
          type: 'billing'
        }
      }
    },
    json () {
      return {
        nickname: 'Billing Address',
        type: this.form.type,
        address: this.form.address
      }
    }
  },
  data () {
    return {
      mode: 'post',
      loading: true,
      id: null,
      errors: {},
      updating: false,
      isValidated: false,
      form: null
    }
  },
  methods: {
    isReady (val) {
      this.isValidated = val
    },
    load () {
      this.loading = true
      this.$http.get(this.endpoint, this.query).then(response => {
        if (response.data.results.length > 0) {
          this.mode = 'patch'
          const location = response.data.results[0]
          // map properties
          this.id = location.id
          this.form = location
        }
        this.loading = false
      })
    },
    update () {
      this.updating = true
      this.errors = {}
      this.$http[this.mode](this.endpoint, this.json).then(response => {
        this.name = response.data.name
        this.website = response.data.website
        this.updating = false
        this.$buefy.toast.open({ type: 'is-success', message: 'Settings updated!' })
      }).catch(error => {
        this.updating = false
        if (error.response && error.response.status === 400) {
          this.errors = error.response.data
        }
      })
    }
  },
  mounted () {
    this.load()
  },
  props: ['partner']
}
</script>
