<template>
  <div>
    <form @submit.prevent="update" novalidate autocomplete="off">
      <div class="columns">
        <div class="column is-one-fifth">
          <p class="has-text-weight-bold is-size-6 mb-sm">Role</p>
          <p class="has-text-grey is-size-7">Configure the access level appropriate for this user.</p>
        </div>
        <div class="column">
          <div class="has-background-white rounded-xl">
            <!-- Loaded -->
            <div v-if="!loading && user !== null" class="pa-lg">
              <div v-for="role in contextRoles" :key="role.id">
                <Role :role="role" @inspect:role="inspectRole" />
              </div>
            </div>
            <!-- Loading -->
            <div v-else>
              <loading message="Loading user..." />
            </div>
            <div class="border-t">
              <div class="columns is-vcentered is-marginless">
                <div class="column is-narrow">
                  <div class="px-sm">
                    <b-button
                      type="is-primary rounded-lg"
                      outlined
                      :disabled="loading"
                      @click="showSaveRoleModal = true"
                    >Add Role</b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <!-- Create or Update Modal -->
    <b-modal
      :has-modal-card="true"
      :active.sync="showSaveRoleModal"
      :can-cancel="['x']"
      @close="role = null"
    ><SaveRoleModal :user="user" :role="role" @role:saved="refreshUser" />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Role from './Role'
import SaveRoleModal from '@/components/Models/Role/Modals/SaveRole'

export default {
  components: {
    Role,
    SaveRoleModal
  },
  computed: {
    ...mapGetters(['app', 'context']),
    endpoint () {
      return this.context.route + '/users/' + this.$route.params.id + '/'
    },
    contextRoles () {
      if (this.user === null) return []

      const context = (this.context.type === 'retailer') ? 'retailer' : 'brand'
      const roles = this.$utils.filter(this.user.roles, ['content_type', context])
      return roles
    },
    json () {
      return {}
    }
  },
  data () {
    return {
      role: null,
      showSaveRoleModal: false
    }
  },
  methods: {
    update () {
      this.updating = true
      this.$http.patch(this.endpoint, this.json).then(response => {
        this.updating = false
        this.isDirty = false
        this.$buefy.toast.open({ type: 'is-success', message: 'User updated!' })
      })
    },
    inspectRole (value) {
      this.role = value
      this.showSaveRoleModal = true
    },
    refreshUser () {
      this.$emit('user:saved')
    }
  },
  props: {
    loading: {
      required: true,
      type: Boolean
    },
    user: {
      required: true,
      validator: prop => typeof prop === 'object' || prop === null
    }
  }
}
</script>
