<template>
  <div>
    <!-- Workflow -->
      <div v-if="context.isMerchant() && payment.status === 'draft'" class="has-background-white rounded-lg py-md px-lg mb-xxs">
        <!-- Non-Field / General Errors -->
        <div v-if="'non_field_errors' in errors" class="mb-lg">
          <b-message type="is-danger">{{ errors.non_field_errors[0] }}</b-message>
        </div>
        <div v-if="payment !== null && payment.status === 'draft'">
          <div class="columns">
            <!-- Actions -->
            <div class="column is-one-quarter">
              <b-button type="is-primary" outlined expanded size="is-medium" @click="submit" :disabled="!isPaymentReady" :loading="saving">Mark as Paid</b-button>
              <!-- <b-tooltip label="This is only a record of payment information. This feature doesn’t move the money."
                position="is-bottom" multilined>
              </b-tooltip> -->
            </div>
          </div>
        </div>
        <div v-else>
          <b-skeleton height="20" />
        </div>
      </div>
    <div class="mb-lg">
      <div class="has-background-white rounded-lg pa-lg">
        <PaymentInfo :payment="payment" @edit:payment="showEditPaymentModal = true"/>
      </div>
    </div>
    <b-modal
      has-modal-card
      :active.sync="showEditPaymentModal"
      :can-cancel="['escape', 'x']"
      ><PaymentDetailsModal :payment="payment" @reload="onReload"/>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PaymentInfo from '../components/Summary/PaymentInfo'
import PaymentDetailsModal from '../components/Summary/PaymentDetailsModal'
export default {
  components: {
    PaymentInfo,
    PaymentDetailsModal
  },
  computed: {
    ...mapGetters(['app', 'auth', 'context']),
    endpoint () {
      return this.context.route + '/payments/' + this.$route.params.id + '/'
    },
    isPaymentReady () {
      return (
        this.payment.num_invoices > 0 &&
        this.payment.check_issue_date !== null &&
        this.payment.check_number !== null &&
        this.payment.check_amount !== '0.00'
      )
    }
  },
  data: () => {
    return {
      saving: false,
      showEditPaymentModal: false,
      errors: {}
    }
  },
  methods: {
    submit () {
      this.errors = {}
      this.saving = true
      const json = {
        ...this.payment,
        status: 'paid'
      }
      this.$http.patch(this.endpoint, json).then(response => {
        this.$emit('updated')
        this.$buefy.toast.open({ type: 'is-success', message: 'Payment submitted successfully!' })
      }).catch((error) => {
        if (error.response && error.response.status === 400) {
          this.errors = error.response.data
        }
      }).finally(() => {
        this.saving = false
      })
    },
    onReload () {
      this.errors = {}
      this.showEditPaymentModal = false
      this.$emit('updated')
    }
  },
  mounted () {

  },
  props: ['payment']
}
</script>
