<template>
  <div>
    <div class="has-text-centered mb-lg">
      <div class="columns is-mobile is-multiline">
        <!-- # Connections -->
        <div class="column is-half-mobile is-one-quarter-tablet">
          <div class="has-background-white pa-md rounded-lg">
            <p v-if="loading" class="mb-sm"><b-icon icon="loading" custom-class="mdi-spin"></b-icon></p>
            <p v-else class="is-size-4">
              {{ $numbers(connections).format({thousandSeparated: true, mantissa: 0}) }}
            </p>
            <p class="heading"># Suppliers with Orders</p>
          </div>
        </div>
        <!-- Orders -->
        <div class="column is-half-mobile is-one-quarter-tablet">
          <div class="has-background-white pa-md rounded-lg">
            <p v-if="loading" class="mb-sm"><b-icon icon="loading" custom-class="mdi-spin"></b-icon></p>
            <p v-else class="is-size-4">
              {{ $numbers(orders_count).format({thousandSeparated: true, mantissa: 0}) }}
            </p>
            <p class="heading"># Orders</p>
          </div>
        </div>
        <!-- GMV -->
        <div class="column is-half-mobile is-one-quarter-tablet">
          <div class="has-background-white pa-md rounded-lg">
            <p v-if="loading" class="mb-sm"><b-icon icon="loading" custom-class="mdi-spin"></b-icon></p>
            <p v-else class="is-size-4">
              {{ $numbers(estimated_gmv).formatCurrency({thousandSeparated: true, mantissa: 2})}}
            </p>
            <p class="heading">Est. GMV ($)</p>
          </div>
        </div>
        <!-- AOV -->
        <div class="column is-half-mobile is-one-quarter-tablet">
          <div class="has-background-white pa-md rounded-lg">
            <p v-if="loading" class="mb-sm"><b-icon icon="loading" custom-class="mdi-spin"></b-icon></p>
            <p v-else class="is-size-4">
              {{ $numbers(estimated_aov).formatCurrency({thousandSeparated: true, mantissa: 2})}}
            </p>
            <p class="heading">AOV ($)</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PerformanceSummary',
  data () {
    return {
      isFullPage: false
    }
  },
  props: {
    orders_count: {
      type: Number,
      default: 0
    },
    estimated_gmv: {
      type: Number,
      default: 0
    },
    estimated_aov: {
      type: Number,
      default: 0
    },
    connections: {
      type: Number,
      default: 0
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
}
</script>
