export const status = {
  label: 'Request Status',
  key: 'status',
  icon: 'pulse',
  tooltip: 'The status of the request.',
  options: [{
    value: 'pending',
    label: 'Pending'
  }, {
    value: 'appproved',
    label: 'Approved'
  }, {
    value: 'declined',
    label: 'Declined'
  }]
}

export const connection = {
  label: 'Connection',
  key: 'connection_id',
  icon: 'pulse',
  tooltip: 'This is a tooltip'
}
