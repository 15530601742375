<template>
  <div>
    <!-- Cover Photo -->
    <div class="mb-lg">
      <p class="has-text-grey mb-md">Tip: You can zoom and pan the image below to crop or adjust your logo!</p>
      <croppa
        v-model="croppa"
        :width="360"
        :height="120"
        canvas-color="transparent"
        remove-button-color="black"
        placeholder="Click (or tap) to select a photo."
        initial-size="natural"
        :initial-image="(this.existingLogo !== null) ? this.existingLogo : null"
        :placeholder-font-size="16"
        :auto-sizing="true"
        :quality="1"
        :prevent-white-space="true"
        :accept="'image/*'"
        :file-size-limit="5242880"
        :show-remove-button="false"
        @init="croppaReady = true"
        @file-size-exceed="sizeError"
        @file-type-mismatch="typeError"
        @file-choose="uploadFile"
        @new-image="validate"
        @image-remove="reset"
        @move="validate"
        @zoom="validate"
      ></croppa>
    </div>
    <!-- Instructions -->
    <div class="columns">
      <!-- Device Button -->
      <div class="column is-half">
        <b-button
          type="is-light rounded-lg"
          size="is-medium"
          icon-left="desktop-mac"
          @click="croppa.chooseFile()"
          expanded
          :loading="updating"
          :disabled="updating"
          >
          Upload From Device
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CdnTransform from '@/mixins/CdnTransform'
export default {
  components: {
  },
  computed: {
    ...mapGetters(['app', 'api', 'context']),
    existingLogo () {
      return (this.logo !== null) ? this.cdnTransform(this.logoUrl, 360, 120, 'fill') : null
    },
    quality () {
      if (this.croppaReady) {
        return (this.croppa.realWidth >= 360) ? 1 : parseFloat((360 / this.croppa.realWidth).toFixed(2))
      }
      return 2
    },
    endpoint () {
      return this.context.route + '/'
    },
    method () {
      return (this.existingLogo !== null) ? 'patch' : 'post'
    },
    json () {
      if (this.tempfile === null) return null
      return {
        logo_photo: {
          tempfile: {
            id: this.tempfile.id
          }
        }
      }
    }
  },
  data () {
    return {
      croppaReady: false,
      croppa: {},
      inputError: null,
      readyToUpload: false,
      tempfile: null,
      updating: false
    }
  },
  methods: {
    sizeError () {
      this.readyToUpload = false
      this.inputError = 'The filesize of your logo is too large. Please try again with a smaller logo.'
    },
    typeError () {
      this.readyToUpload = false
      this.inputError = 'Please upload an image file only.'
    },
    validate () {
      if (this.croppa.naturalWidth < 360 || this.croppa.naturalHeight < 120) {
        this.readyToUpload = false
        this.inputError = 'Please upload a larger logo (at least 360x120 pixels)'
        return
      }
      this.isDirty = true
      this.readyToUpload = true
      this.inputError = null
    },
    reset () {
      this.isDirty = false
      this.readyToUpload = false
      this.inputError = null
      this.errors = []
    },
    uploadFile () {
      this.updating = true
      this.croppa.generateBlob(blob => {
        const route = this.api.baseUrl + '/v1/tempfiles/upload/'
        const formdata = new FormData()
        formdata.append('file', blob, 'logo.png')
        this.$http.post(route, formdata).then(response => {
          this.tempfile = response.data
          this.$http.patch(this.endpoint, this.json).then(response => {
            this.reset()
            this.$buefy.toast.open({ type: 'is-success', message: 'Logo updated!' })
          }).finally(() => {
            this.updating = false
          })
        })
      }, 'image/png')
    }
  },
  mounted () {

  },
  mixins: [CdnTransform],
  props: ['logoUrl']
}
</script>
