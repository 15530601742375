<template>
  <div>
    <filter-toolbar
      :hasChanges="hasChanges"
      :appliedFilters="appliedFilters"
      @search="search"
      @update="update"
      @clear="clear">
      <remote-facet-filter
        class="border-b"
        v-model="filters.facets.connection_id"
        response-type="object"
        :resourceLabel="(context.isSupplier()) ? 'retailer.name' : 'brand.name'"
        :filter="filterOptions.connection"
        :endpoint="context.route + '/connections/?short=1'"
        @input="change"
      />
      <date-filter
        class="border-b"
        v-model="filters.timestamps.updated_at"
        :filter="filterOptions.updatedAt"
        @input="change"
      />
      <boolean-filter
        class="border-b"
        v-model="filters.facets.is_on_shopify"
        :filter="filterOptions.isOnShopify"
        @input="change"
      />
      <boolean-filter
        class="border-b"
        v-model="filters.facets.is_on_bigcommerce"
        :filter="filterOptions.isOnBigCommerce"
        @input="change"
      />
      <remote-facet-filter
        class="border-b"
        v-model="filters.facets.is_merchandising_contact"
        response-type="object"
        :resourceLabel="'email'"
        :filter="filterOptions.isMerchandisingContact"
        :endpoint="context.route + usersPath"
        @input="change"
      />
      <remote-facet-filter
        class="border-b"
        v-model="filters.facets.is_department_owner"
        response-type="object"
        :resourceLabel="'email'"
        :filter="filterOptions.isDepartmentOwner"
        :endpoint="context.route + usersPath"
        @input="change"
      />
      <remote-facet-filter
        v-model="filters.facets.is_department_manager"
        response-type="object"
        :resourceLabel="'email'"
        :filter="filterOptions.isDepartmentManager"
        :endpoint="context.route + usersPath"
        @input="change"
      />
    </filter-toolbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import * as filterOptions from '../../filters/products.js'
import BooleanFilter from '@/components/Filters/BooleanFilter'
import DateFilter from '@/components/Filters/DateFilter'
import RemoteFacetFilter from '@/components/Filters/RemoteFacetFilter'
import FilterToolbar from '@/components/Filters/FilterToolbar'

export default {
  components: {
    BooleanFilter,
    DateFilter,
    FilterToolbar,
    RemoteFacetFilter
  },
  data () {
    return {
      hasChanges: false,
      filters: null,
      usersPath: '/users/?short=1&type=standard',
      filterOptions: filterOptions
    }
  },
  computed: {
    ...mapGetters(['context'])
  },
  methods: {
    change () {
      this.hasChanges = true
    },
    search (searchQuery) {
      this.filters.search = {
        parameter: 'search',
        query: searchQuery
      }
      this.$emit('filter', this.filters)
    },
    update () {
      this.hasChanges = false
      this.filters.preset = 'custom'
      this.$emit('filter', this.filters)
    },
    clear () {
      this.filters = {
        preset: 'all',
        facets: {},
        search: {},
        timestamps: {}
      }
      this.$emit('filter', this.filters)
    }
  },
  props: {
    appliedFilters: {
      type: Object,
      required: true
    }
  },
  watch: {
    appliedFilters: {
      deep: true,
      immediate: true,
      handler (filters) {
        this.filters = filters
      }
    }
  }
}
</script>
