<template>
  <div>
    <!-- Intro -->
    <div class="">
      <div class="mb-lg has-text-centered">
        <p class="has-text-weight-bold is-size-5">Learn about {{ app.platform }}</p>
        <p class="has-text-grey-light">Question #3 of 3</p>
      </div>
      <div>
        <p class="has-text-weight-bold has-text-centered mb-lg">
          <a :href="app.websiteUrl" target="_blank" class="has-text-info">{{ app.platform }}</a> is the leading modular and headless platform for modern e-commerce experiences.
        </p>
        <div class="mb-xl">
          <p class="has-text-weight-bold mb-md">Would you like to learn more about {{ app.platform }}?</p>
          <b-field><b-radio v-model="learnMore" native-value="Yes">Yes!</b-radio></b-field>
          <b-field><b-radio v-model="learnMore" native-value="No">Not at this time</b-radio></b-field>
        </div>
      </div>
    </div>
    <!-- Navigate -->
    <div class="py-lg">
      <div class="columns is-centered">
        <div class="column">
          <b-button
            type="is-light rounded-lg"
            size="is-medium"
            @click="goBack"
            expanded
            ><b-icon icon="arrow-left"></b-icon>
          </b-button>
        </div>
        <div class="column">
          <b-button
            type="is-primary rounded-lg"
            size="is-medium"
            expanded
            :disabled="!ready || saving"
            :loading="saving"
            @click="goForward"
          >Continue</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {

  },
  computed: {
    ...mapGetters(['app', 'context']),
    body () {
      const body = {
        metadata: [{
          name: 'ob_feedback_learn',
          display_name: 'Onboarding Feedback: Learn More',
          type: 'string',
          value: this.learnMore
        }]
      }
      return body
    },
    ready () {
      return (this.learnMore)
    }
  },
  data () {
    return {
      saving: false,
      learnMore: null
    }
  },
  methods: {
    goBack () {
      this.$emit('navigate', 'Platform')
    },
    goForward () {
      this.saving = true
      const endpoint = this.context.route + '/'
      this.$http.patch(endpoint, this.body).then(() => {
        this.$http.put(endpoint + 'assigned-tasks/' + this.task.id + '/complete/').then(() => {
          this.showSkipCallModal = false
          this.$buefy.toast.open({ type: 'is-success', message: 'Task Completed!' })
          this.$router.push({ name: 'onboarding.tasks', params: { connection: this.connection.id } })
        }).finally(() => {
          this.saving = false
        })
      })
    }
  },
  mounted () {
    if ('ob_feedback_learn' in this.brand.metadata) {
      this.learnMore = this.brand.metadata.ob_feedback_learn.values[0].value
    }
  },
  props: {
    connection: {
      type: Object,
      required: true
    },
    task: {
      type: Object,
      required: true
    },
    brand: {
      type: Object,
      required: true
    }
  }
}
</script>
