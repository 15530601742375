<template>
  <b-button
    :type="type"
    :size="size"
    :loading="loading"
    :disabled="loading"
    @click="resend">
    Resend
  </b-button>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/connection-invites/' + this.invitation.id + '/resend/'
    }
  },
  data () {
    return {
      loading: false
    }
  },
  methods: {
    resend () {
      this.loading = true
      this.$http.post(this.endpoint).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Resent invitation.' })
      }).finally(() => {
        this.loading = false
      })
    }
  },
  props: {
    invitation: {
      type: Object
    },
    type: {
      type: String,
      default: null
    },
    size: {
      type: String,
      default: null
    }
  }
}
</script>
