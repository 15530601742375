<template>
  <div>
    <!-- Intro -->
    <div>
      <div>
        <div class="py-xxl has-text-white has-text-centered">
          <h1 class="is-size-4">Reporting</h1>
        </div>
      </div>
      <div style="height:1px"></div>
    </div>
    <!-- Content -->
    <div class="section">
      <div class="container">
        <div class="has-background-white pa-xxl rounded-xl">
          Coming Soon.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  computed: {
    ...mapGetters([])
  },
  data () {
    return {

    }
  },
  methods: {

  },
  mounted () {

  }
}
</script>
