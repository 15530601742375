import dates from '@/assets/js/dates'

export const connection = {
  label: 'Connection',
  key: 'connection_id',
  icon: 'lan',
  tooltip: 'This is a tooltip'
}

export const type = {
  label: 'Proposal Type',
  key: 'proposal_type',
  icon: 'shape-outline',
  tooltip: 'Limit results based on the proposal type.',
  options: [{
    value: 'new_product',
    label: 'New Products'
  }, {
    value: 'cost_update',
    label: 'Cost Updates'
  }, {
    value: 'image_update',
    label: 'Image Updates'
  }, {
    value: 'attribute_update',
    label: 'Attribute Updates'
  }]
}

export const department = {
  label: 'Department',
  key: 'department_id',
  icon: 'shape-outline',
  tooltip: 'This is a tooltip'
}

export const status = {
  label: 'Status',
  key: 'status',
  icon: 'pulse',
  tooltip: 'Limit results based on the proposal status',
  options: [{
    value: 'draft',
    label: 'Draft'
  }, {
    value: 'proposed',
    label: 'Proposed'
  }, {
    value: 'revised',
    label: 'Revised'
  }, {
    value: 'completed',
    label: 'Completed'
  }, {
    value: 'declined',
    label: 'Declined'
  }]
}

export const acknowledged = {
  label: 'Acknowledged?',
  key: 'acknowledged',
  icon: 'check',
  tooltip: 'This is a tooltip',
  options: [{
    value: 1,
    label: 'Yes'
  }, {
    value: 0,
    label: 'No'
  }]
}

export const proposedAt = {
  label: 'Proposed On',
  key: 'proposed_on',
  icon: 'calendar-outline',
  tooltip: 'Filter proposals based the date and time they were proposed',
  options: dates
}

export const createdAt = {
  label: 'Created On',
  key: 'created_at',
  icon: 'calendar-outline',
  tooltip: 'Filter proposals based the date and time they were created',
  options: dates
}
