<template>
  <div>
    <p class="has-text-weight-bold mb-sm">Return To</p>
    <!-- Select Service -->
    <div>
      <b-field>
        <b-select
          v-model="returnTo"
          placeholder="Select Location..."
          :loading="loading"
          expanded
          size="is-medium"
          @input="announce">
          <option v-for="location in locations" :key="location.id" :value="location">{{ location.brand.name }} ({{ location.nickname }})</option>
        </b-select>
      </b-field>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/connection-locations/'
    },
    query () {
      return {
        params: {
          type: 'warehouse',
          search_locations: this.connection.brand.name
        }
      }
    }
  },
  data () {
    return {
      loading: true,
      errors: [],
      locations: [],
      returnTo: null
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint, this.query).then(response => {
        this.locations = response.data.results
        if (response.data.results.length === 1) {
          this.returnTo = response.data.results[0]
          this.$emit('input', this.returnTo)
        }
        this.loading = false
      })
    },
    announce () {
      this.$emit('input', this.returnTo)
    }
  },
  mounted () {
    this.load()
  },
  props: ['connection']
}
</script>
