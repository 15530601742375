<template>
  <span :class="presenter.textClass" class="has-text-weight-bold">
    {{ presenter.label }}
  </span>
</template>

<script>
export default {
  components: {

  },
  data () {
    return {

    }
  },
  computed: {
    presenter () {
      // set defaults first
      const presenter = {
        label: null,
        textClass: 'has-text-danger',
        message: null
      }
      // Open Issues - not closed or resolved
      if (!this.issue.is_closed && !this.issue.is_resolved) {
        presenter.label = 'Open'
        presenter.textClass = 'has-text-danger'
        return presenter
      }
      // Resolved Issues - not closed but are resolved by supplier
      if (!this.issue.is_closed && this.issue.is_resolved) {
        presenter.label = 'Resolved'
        presenter.textClass = 'has-text-warning'
        return presenter
      }
      // Closed Issues - closed by merchant & not archived
      if (this.issue.is_closed && !this.issue.is_archived) {
        presenter.label = 'Closed'
        presenter.textClass = 'has-text-success'
        return presenter
      } else if (this.issue.is_archived) {
        presenter.textClass = 'has-text-grey-light'
        presenter.label = 'Archived'
      }
      return presenter
    }
  },
  methods: {

  },
  mounted () {

  },
  props: {
    issue: {
      type: Object,
      required: true
    }
  }
}
</script>
