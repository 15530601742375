<template>
  <div>
    <div class="columns is-vcentered">
      <div class="column">
        <p>{{ line.variant.name }}</p>
        <p class="is-size-7 has-text-grey">{{ line.variant.brand_identifier }}</p>
      </div>
      <div class="column is-two-fifths">
        <b-field>
          <b-numberinput
            v-model="quantity"
            controlsPosition="compact"
            min="0"
            :max="line.quantity_open"
            @input="update"
            expanded
          />
          <p class="control">
            <span class="button is-static">of {{ line.quantity_open }}</span>
          </p>
        </b-field>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  computed: {
    ...mapGetters(['context'])
  },
  data () {
    return {
      quantity: 0
    }
  },
  methods: {
    update () {
      this.$emit('update:line', {
        lineId: this.line.id,
        variantId: this.line.variant.id,
        brandIdentifier: this.line.brand_identifier,
        quantity: this.quantity
      })
    }
  },
  mounted () {

  },
  props: ['line', 'cancelAll', 'isDirty'],
  watch: {
    cancelAll (value) {
      if (value === true && this.quantity !== this.line.quantity_open) {
        this.quantity = this.line.quantity_open
        this.update()
      }
    },
    isDirty (value) {
      if (value === false) {
        this.quantity = 0
      }
    }
  }
}
</script>
