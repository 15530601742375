<template>
  <div class="section">
    <div class="container">
      <!-- Breadcrumbs -->
      <div>
        <breadcrumbs :history="[{ label: 'Catalog', name: 'catalog'}]" current="Import" :hideBack="true" />
      </div>
      <!-- Header -->
      <div class="mb-xl has-text-centered">
        <h1 class="is-size-4 has-text-grey-dark">Import From File</h1>
      </div>
      <!-- Step 1: Select Template -->
      <div class="has-background-white pa-lg mb-lg rounded-xl">
        <div class="mb-md">
          <h1 class="has-text-primary is-size-4">Step 1: Select Template</h1>
          <p class="has-text-grey">Select the template that you used for this file.</p>
        </div>
        <div class="columns">
          <div class="column is-half-desktop">
            <b-select v-model="template" placeholder="Select a template..." :disabled="loading" :loading="loading" expanded>
              <option v-for="template in templates" :value="template" :key="template.id">{{ template.name }}</option>
            </b-select>
          </div>
        </div>
      </div>
      <!-- Step 2: Select File -->
      <div v-if="template !== null" class="has-background-white pa-lg mb-lg rounded-xl">
        <div class="mb-md">
          <h1 class="has-text-primary is-size-4">Step 2: Select File</h1>
          <p class="has-text-grey">Select the file you want to upload to {{ app.platform }}.</p>
        </div>
        <div class="columns">
          <div class="column is-half-desktop">
            <!-- Upload -->
            <CoreUpload @file:uploaded="updateTempfile" @file:reset="resetTempfile"/>
          </div>
        </div>
      </div>
      <!-- Step 3: Schedule Import -->
      <div v-if="tempfile !== null" class="has-background-white pa-lg mb-lg rounded-xl">
        <div class="mb-lg">
          <h1 class="has-text-primary is-size-4">Step 3: Schedule Import</h1>
          <p class="has-text-grey">When ready, click the button below to schedule your import.</p>
        </div>
        <div>
          <b-button type="is-primary" :disabled="!ready" @click="queueFile" :loading="scheduling">Schedule Import</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CoreUpload from '@/components/CoreUpload'
export default {
  components: {
    CoreUpload
  },
  computed: {
    ...mapGetters(['app', 'context']),
    endpoints () {
      return {
        template: this.context.route + '/templates/',
        queue: this.context.route + '/feed-queue/enqueue/'
      }
    },
    templatesQuery () {
      return {
        params: {
          direction: 'import',
          data_type: 'products',
          limit: 100
        }
      }
    },
    json () {
      return {
        template: {
          id: this.template.id
        },
        direction: 'import',
        data_type: 'products',
        source: 'monitor',
        behavior: 'update',
        info: {},
        file: {
          tempfile: {
            id: this.tempfile.id
          }
        }
      }
    },
    loading () {
      if (this.loadingTemplates) return true
      return false
    },
    ready () {
      return (!this.loadingTemplates && !this.uploading && !this.scheduling && this.template !== null && this.tempfile !== null)
    }
  },
  data () {
    return {
      loadingTemplates: false,
      templates: [],
      template: null,
      tempfile: null,
      scheduling: false
    }
  },
  methods: {
    loadTemplates () {
      this.loadingTemplates = true
      this.$http.get(this.endpoints.template, this.templatesQuery).then(response => {
        this.templates = response.data.results
        this.loadingTemplates = false
      })
    },
    queueFile () {
      this.scheduling = true
      this.$http.post(this.endpoints.queue, this.json).then(response => {
        this.scheduling = false
        this.$buefy.toast.open({ type: 'is-success', message: 'File scheduled!' })
      })
    },
    updateTempfile (file, response) {
      this.tempfile = response
    },
    resetTempfile () {
      this.tempfile = null
    }
  },
  mounted () {
    this.loadTemplates()
  }
}
</script>
