<template>
  <div>
    <p class="has-text-centered">Select {{ app.platform }} Account</p>
    <div v-for="(supplier) in supplierAccounts" :key="supplier.id" class="mb-sm py-md">
      <div class="has-background-light pa-md rounded-lg mb-xs">
        <div class="columns is-vcentered">
          <div class="column">
            <p class="has-text-weight-bold is-size-7">{{ supplier.name }}</p>
            <p class="has-text-grey-light is-size-8">ID: {{ supplier.id }}</p>
          </div>
          <div class="column is-narrow">
            <b-button
              type="is-primary"
              size="is-small"
              outlined
              @click.prevent="() => submit(supplier)"
              :loading="saving"
              :disabled="saving"
            >Connect</b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  computed: {
    ...mapGetters(['app', 'api', 'context', 'auth']),
    query () {
      return {
        params: {
          status: 'onboarding'
        }
      }
    }
  },
  data () {
    return {
      supplierAccounts: [],
      saving: false,
      shopifyTask: null,
      connection: null,
      shopifyAccessToken: null
    }
  },
  methods: {
    async submit (supplier) {
      this.saving = true
      try {
        // fetch connections
        const { data: { results } } = await this.fetchConnections(supplier)
        this.connection = results[0]
        // fetch assigned tasks
        const { data } = await this.fetchPlatformAssignedTasks(supplier)
        this.shopifyTask = data.results.find(el => el.onboarding_task.code === 'shopify')
        await this.createPlatformAccount(supplier)
        this.saving = false
      } catch (error) {
        this.$buefy.toast.open({ type: 'is-danger', message: 'An unexpected error occurred!' })
        this.saving = false
      }
    },
    fetchConnections (supplier) {
      const endpoint = this.api.baseUrl + `/v1/brands/${supplier.id}/connections/`
      return this.$http.get(endpoint, this.query)
    },
    fetchPlatformAssignedTasks (supplier) {
      const endpoint = this.api.baseUrl + `/v1/brands/${supplier.id}/assigned-tasks/`
      return this.$http.get(endpoint)
    },
    createPlatformAccount (supplier) {
      const endpoint = this.api.baseUrl + `/v1/brands/${supplier.id}/platform-accounts/`
      const json = {
        platform: { code: 'shopify' },
        credentials: [{
          credential: { code: 'shopify_shop_name' },
          value: this.shopifyStoreName
        }, {
          credential: { code: 'shopify_token' },
          value: this.shopifyAccessToken
        }, {
          credential: { code: 'shopify_private_app' },
          value: 0
        }]
      }
      let redirectPath = '/'
      if (this.connection && this.shopifyTask) {
        redirectPath = `/onboarding/${this.connection.id}/tasks/${this.shopifyTask.id}/shopify`
      }
      this.$http.get(endpoint, { params: { platform: 'shopify' } }).then(response => {
        if (response.data.results.length === 0) {
          this.$http.post(endpoint, json).then(response => {
            this.$buefy.toast.open({ type: 'is-info', message: 'Platform Added!' })
          }).finally(() => {
            this.$router.push(redirectPath)
          })
        } else {
          this.$router.push(redirectPath)
        }
      })
    }
  },
  mounted () {
    if (this.auth.suppliers) {
      this.supplierAccounts = this.auth.suppliers
    }
    if (this.$route.query && this.$route.query.shop && this.$route.query.sat) {
      this.shopifyStoreName = this.$route.query.shop
      this.shopifyAccessToken = this.$route.query.sat
      this.$router.replace(this.$route.path)
    }
  },
  props: []
}
</script>
