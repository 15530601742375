<template>
  <div>
    <div class="has-background-white pa-xl rounded-lg mb-lg">

      <!-- Intro -->
      <div class="pa-xl has-text-centered">
        <h4 class="mb-lg is-size-5 has-text-weight-bold">Submission Complete!</h4>
        <div class="mb-xxl">
          <p>You have submitted your proposal to <strong>{{ proposal.connection.retailer.name }}</strong>.
            A representative from {{ proposal.connection.retailer.name }} will now review your
            submission as soon as possible. There is a possibility that your proposal
            will need amendments. You will be notified if any additional data
            or changes are required.
          </p>
        </div>
        <div class="mb-lg">
          <p class="text-muted pa-xl"><b-icon icon="check" custom-class="mdi-192px has-text-success">check</b-icon></p>
        </div>
        <div class="columns is-centered">
          <div class="column is-one-third">
            <router-link :to="{ name: 'proposals.dashboard' }" class="button is-primary is-fullwidth is-medium">Return to Proposals Summary</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['proposal']
}
</script>
