<template>
  <div>
    <!-- Intro -->
    <div class="has-background-black py-xl">
      <div class="section">
        <div class="container">
          <div class="columns is-centered">
            <div class="column is-three-fifths">
              <div class="has-text-white has-text-centered mb-lg">
                <Logo color="white" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Confirming Install -->
    <div class="section">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-three-fifths">
            <div class="has-background-white rounded-xl pa-xl">
              <p class="is-size-4 has-text-primary mb-md has-text-centered">{{ progressMessage }}</p>
              <transition name="fade" mode="out-in">
                <div class="pt-xxl pb-md">
                  <loading message="" />
                </div>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Logo from '@/components/Logos/Logo'

export default {
  components: {
    Logo
  },
  computed: {
    ...mapGetters(['app', 'api', 'context']),
    busy () {
      return this.checking || this.provisioning
    },
    signatureQuery () {
      // clone query string, but delete the hmac param
      const query = this.$utils.clone(this.$route.query)
      delete query.hmac
      // sort params lexographically (i.e. alphabetically) & return query string
      const sorted = this.$utils.sortBy(Object.entries(query), [function (o) { return o[0] }])
      const qs = []
      for (const [key, value] of sorted) {
        qs.push(key + '=' + value)
      }
      return qs.join('&')
    }
  },
  data () {
    return {
      checking: true,
      provisioning: false,
      progressMessage: 'Checking with Shopify...',
      store: null,
      contextType: null,
      // error tracking for user feedback
      hasSignatureError: false,
      hasRequestError: false,
      errorMessage: null
    }
  },
  methods: {
    check () {
      this.checking = true
      const endpoint = this.api.baseUrl + '/shopify/lookup-platform-account/'
      const json = {
        signature: this.$route.query.hmac,
        query: this.signatureQuery
      }
      this.$http.post(endpoint, json).then(response => {
        // the 'check' endpoint returns a platform account object (if one is found)
        // or it returns an empty object. if the object is empty, we know
        // we need to create a platform account for the currently authenticated user
        if (Object.keys(response.data.platform_account).length === 0) {
          // this.progressMessage = 'Linking accounts...'
          // if (this.context.isResolved()) {
          //   this.$http.post(this.context.route + '/shopify/link-account/', {
          //     signature: this.$route.query.hmac,
          //     query: this.signatureQuery
          //   }).then(response => {
          //     this.progressMessage = 'App Installed!'
          //   })
          // } else {
          //
          // }
        // if the object is not empty, it's likely the user came from the
        // shopify app link, so we can figure out the context by examing the
        // the platform account, then authenticate the user and send them
        // on their way
        } else {
          this.progressMessage = 'Welcome back! Signing you in...'
          this.store = response.data.platform_account
          if ('retailer' in this.store) this.contextType = 'retailer'
          if ('brand' in this.store) this.contextType = 'brand'
          this.signIn()
        }
        this.checking = false
      }).catch(() => {
        this.hasSignatureError = true
        this.checking = false
      })
    },
    provision () {
      this.provisioning = true
      const endpoint = this.api.baseUrl + '/shopify/confirm-install/'
      const json = {
        signature: this.$route.query.hmac,
        query: this.signatureQuery,
        context: this.contextType
      }
      this.$http.post(endpoint, json).then(response => {
        this.$store.dispatch('authenticate', response.data.revcascade_access_token).then(() => {
          this.provisioning = false
          this.$router.push({ name: 'orders.dashboard' })
        })
      }).catch(error => {
        if (error.response && error.response.status === 403) {
          this.hasSignatureError = true
        } else if (error.response && error.response.status === 400) {
          this.hasRequestError = true
          this.errorMessage = error.response.data.detail
        }
        this.provisioning = false
      })
    },
    signIn () {
      this.provisioning = true
      const endpoint = this.api.baseUrl + '/shopify/confirm-install/'
      const json = {
        signature: this.$route.query.hmac,
        query: this.signatureQuery,
        context: this.contextType
      }
      this.$http.post(endpoint, json).then(response => {
        this.$store.dispatch('authenticate', response.data.revcascade_access_token).then(() => {
          this.provisioning = false
          if (this.context.isUnresolved()) {
            this.$router.push({ name: 'context' })
          } else {
            this.$router.push({ name: 'orders.dashboard' })
          }
        })
      }).catch(error => {
        if (error.response && error.response.status === 403) {
          this.hasSignatureError = true
        } else if (error.response && error.response.status === 400) {
          this.hasRequestError = true
          this.errorMessage = error.response.data.detail
        }
        this.provisioning = false
      })
    }
  },
  mounted () {
    setTimeout(() => {
      this.check()
    }, 1000)
  }
}
</script>
