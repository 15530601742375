<template>
  <div class="section">
    <div class="container">
      <!-- Page Header -->
      <div class="columns is-vcentered">
        <div class="column is-one-third">
          <breadcrumbs :history="breadcrumbs" current="Import" :hideBack="true" />
        </div>
        <div class="column is-one-third">
          <h1 class="has-text-centered is-size-4">Imports</h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  data () {
    return {
      loading: true,
      results: []
    }
  },
  computed: {
    ...mapGetters(['context']),
    breadcrumbs () {
      return [
        { label: 'Network', name: 'network.dashboard' },
        { label: 'Products', name: 'network.products' },
        { label: 'Import', name: 'network.products' }
      ]
    },
    endpoint () {
      return this.context.route + '/jobs/?module_name=pull_products'
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint).then(response => {
        this.results = response.data.results
        this.loading = false
      })
    }
  },
  mounted () {
    this.load()
  },
  props: []
}
</script>
