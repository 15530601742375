<template>
  <div class="section">
    <div class="container">
      <!-- Page Header -->
      <div class="mb-sm">
        <div class="columns is-vcentered">
          <div class="column">
            <h4 class="is-size-4 has-text-centered-mobile">Performance Reporting</h4>
          </div>
          <div class="column is-half">
            <date-range @update:dates="updateDates"></date-range>
           </div>
        </div>
      </div>
      <!-- Router View -->
       <transition name="fade" mode="out-in">
        <keep-alive>
          <router-view
            :startDate="startDate"
            :endDate="endDate"
            :dimension="dimension"
            :metric="metric"
          ></router-view>
        </keep-alive>
      </transition>
    </div>
  </div>
</template>

<script>
import DateRange from '@/modules/Performance/views/DateRange.vue'
export default {
  components: {
    DateRange
  },
  data () {
    return {
      startDate: null,
      endDate: null,
      dimension: 'date',
      metric: 'estimated_gmv'
    }
  },
  methods: {
    updateDates (payload) {
      this.startDate = payload.start
      this.endDate = payload.end
    }
  }
}
</script>
