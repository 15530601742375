<template>
  <div>
    <filter-panel
      :hasChanges="hasChanges"
      :appliedFilters="appliedFilters"
      @search="search"
      @update="update"
      @clear="clear">
      <AsyncFilter
        v-model="filters.facets.brand_category"
        :source="endpoints.categories"
        :filter="filterOptions.categories"
        @input="change"
      />
    </filter-panel>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import * as filters from '../../filters.js'
import AsyncFilter from '@/components/FilterPanel/AsyncFilter'
import FilterPanel from '@/components/FilterPanel/FilterPanel'

export default {
  components: {
    AsyncFilter,
    FilterPanel
  },
  computed: {
    ...mapGetters(['context']),
    endpoints () {
      return {
        categories: this.context.route + '/marketplaces/' + this.marketplace.id + '/brand-categories/'
      }
    }
  },
  data () {
    return {
      hasChanges: false,
      filters: null,
      filterOptions: filters
    }
  },
  methods: {
    change () {
      this.hasChanges = true
      this.update()
    },
    search (searchQuery) {
      this.filters.search = {
        parameter: 'search',
        query: searchQuery
      }
      this.$emit('filter', this.filters)
    },
    update () {
      this.hasChanges = false
      this.filters.preset = 'custom'
      this.$emit('filter', this.filters)
    },
    clear () {
      this.filters = {
        preset: 'all',
        facets: {},
        search: {},
        timestamps: {}
      }
      this.$emit('filter', this.filters)
    }
  },
  props: {
    appliedFilters: {
      type: Object,
      required: true
    },
    marketplace: {
      type: Object,
      required: true
    }
  },
  watch: {
    appliedFilters: {
      deep: true,
      immediate: true,
      handler (filters) {
        this.filters = filters
      }
    }
  }
}
</script>
