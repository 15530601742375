import Index from './views/Index'
import List from './views/List'
import Detail from './views/Detail'
import Locations from './views/Locations'
import LocationsList from './views/LocationsList'
import Invitations from './views/Invitations'
import Applications from './views/Applications'
import Summary from './views/Summary'
import Shipping from './views/Shipping'
import Onboarding from './views/Onboarding'
import Credentials from './views/Credentials'
import Resources from './views/Resources.vue'

export default [{
  path: '/connections',
  name: 'connections',
  component: Index
}, {
  path: '/connections/list',
  name: 'connections.list',
  component: List
}, {
  path: '/connections/locations',
  name: 'connection.locations',
  component: LocationsList
}, {
  path: '/connections/invitations',
  name: 'connection.invitations',
  component: Invitations
}, {
  path: '/connections/applications',
  name: 'connection.applications',
  component: Applications
}, {
  path: '/connections/:id',
  component: Detail,
  children: [{
    path: 'locations',
    name: 'connection.detail.locations',
    component: Locations
  }, {
    path: 'metadata',
    name: 'connection.detail.credentials',
    component: Credentials
  }, {
    path: 'shipping',
    name: 'connection.detail.shipping',
    component: Shipping
  }, {
    path: 'onboarding',
    name: 'connection.detail.onboarding',
    component: Onboarding
  }, {
    path: '',
    name: 'connection.detail',
    component: Summary
  }, {
    path: 'resources',
    name: 'connection.detail.resources',
    component: Resources
  }]
}]
