<template>
  <div>
    <!-- History -->
    <div v-if="!loading && positions.length > 0">
      <div class="is-size-7">
        <table class="table is-fullwidth is-vcentered is-narrow">
          <thead>
            <tr>
              <th>Date</th>
              <th>Event</th>
              <th class="has-text-centered">Inventory</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="position in positions" :key="position.id">
              <td>
                <p>{{ $dates.utc(position.updated_at).local().format('MMM DD, YYYY') }}</p>
                <p class="is-size-8 has-text-grey-light">{{ $dates.utc(position.updated_at).local().format('h:mm A') }}</p>
              </td>
              <td>{{ position.event }}</td>
              <td class="has-text-centered">{{ position.inventory }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- None Found -->
    <div v-else-if="!loading && positions.length === 0" class="has-text-centered pa-xl has-text-grey-light">
      No inventory updates found.
    </div>
    <!-- Loading -->
    <div v-else>
      <loading message="Loading inventory history..." />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      loading: true,
      positions: []
    }
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/inventory/' + this.itemId + '/history/'
    },
    queryString () {
      return {
        params: {
          limit: 30
        }
      }
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint, this.queryString).then(response => {
        this.positions = response.data.results
      }).finally(() => {
        this.loading = false
      })
    }
  },
  mounted () {
    this.load()
  },
  props: ['itemId']
}
</script>
