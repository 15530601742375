<template>
  <div>
    <div class="section">
      <div class="container">
        <!-- Page Header -->
        <div class="columns is-vcentered">
          <div class="column is-one-third">
            <breadcrumbs :history="breadcrumbs.history" :current="breadcrumbs.current"  />
          </div>
          <div class="column is-one-third">
            <h1 class="has-text-centered is-size-4">Edit Transformer</h1>
          </div>
          <div class="column is-one-third">
            <div class="is-pulled-right px-md">
              <b-button
                type="is-primary rounded-lg"
                :loading="!attributesReady"
                @click="onDelete"
              >Delete
              </b-button>
            </div>
          </div>
        </div>
        <!-- Content -->
        <!-- Transformer Info -->
        <div class="pb-lg border-b mb-lg">
          <div class="columns">
            <div class="column is-one-fifth">
              <p class="has-text-weight-bold is-size-6 mb-sm">Transformer Settings</p>
              <p class="has-text-grey is-size-7">
                Configure basic settings for this transformer.
              </p>
            </div>
            <div class="column">
              <TransformerSettings :loading="loadingTransformer" :transformerName="transformer.name"/>
            </div>
          </div>
        </div>
        <!-- Step 2: Attribute List -->
        <div class="columns">
          <div class="column is-one-fifth">
            <p class="has-text-weight-bold is-size-6 mb-sm">Transformation Attributes</p>
            <p class="has-text-grey is-size-7">
              Add attributes to the transformer.
            </p>
          </div>
          <div class="column">
            <div v-if="attributesReady">
              <TransformationAttributes :attributes="attributes" :transformerId="transformer.id"/>
            </div>
            <div v-else>
              <div class="has-background-white rounded-lg pa-lg">
                <loading message="Loading attributes..." />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showConfirmModal">
      <ConfirmModal
        :showModal="showConfirmModal"
        :loading="isDeleting"
        :bodyText="`Are you sure you want to delete transformer: ${transformer.name}?`"
        :bodySubText="'This will also delete all the respective attributes and transformations in this transformer.'"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ConfirmModal from '@/components/Models/Transformers/Modals/ConfirmModal'
import TransformerSettings from '../components/TransformerDetails/TransformerSettings'
import TransformationAttributes from '../components/TransformerDetails/TransformationAttributes'
export default {
  components: {
    ConfirmModal,
    TransformerSettings,
    TransformationAttributes
  },
  computed: {
    ...mapGetters(['app', 'api', 'context']),
    breadcrumbs () {
      return {
        history: [
          { label: 'Settings', name: 'settings' },
          { label: 'Transformers', name: 'transformers' }
        ],
        current: 'Edit'
      }
    },
    endpoint () {
      return this.api.baseRoute + '/attributes/'
    },
    attributesReady () {
      return (!this.loadingAttributes && !this.loadingTransformer && this.attributes.length)
    }
  },
  data: () => {
    return {
      loadingAttributes: false,
      loadingTransformer: false,
      isDeleting: false,
      showConfirmModal: false,
      attributes: [],
      transformer: {},
      errors: {}
    }
  },
  methods: {
    loadAttributes () {
      this.loadingAttributes = true
      const query = {
        params: { pagination: 0 }
      }
      this.$http.get(this.endpoint, query).then(response => {
        this.attributes = response.data.results
        this.loadingAttributes = false
      })
    },
    loadTransformer () {
      this.loadingTransformer = true
      const endpoint = this.context.route + `/transformers/${this.$route.params.id}/`
      this.$http.get(endpoint).then(response => {
        this.transformer = response.data
      }).finally(() => {
        this.loadingTransformer = false
      })
    },
    confirm () {
      this.isDeleting = true
      const endpoint = this.context.route + `/transformers/${this.$route.params.id}/`
      this.$http.delete(endpoint).then(response => {
        this.errors = {}
        this.$buefy.toast.open({ type: 'is-success', message: 'Transformer deleted!' })
        this.$router.push({ name: 'transformers' })
        this.close()
      }).catch(error => {
        this.$buefy.toast.open({ type: 'is-danger', message: 'Error deleting transformer!' })
        this.errors = error.response.data
      }).finally(() => {
        this.isDeleting = false
      })
    },
    onDelete () {
      this.showConfirmModal = true
    },
    close () {
      this.showConfirmModal = false
    }
  },
  mounted () {
    this.loadAttributes()
    this.loadTransformer()
  },
  props: []
}
</script>
