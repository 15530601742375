<template>
  <div class="py-sm">
    <div class="columns">
      <div class="column is-narrow">
        <b-select placeholder="Title" v-model="parameter" expanded size="is-medium">
          <option value="search_sku">Item SKU</option>
          <option value="search">Item Name</option>
        </b-select>
      </div>
      <div class="column">
        <b-field>
          <b-autocomplete
             :placeholder="placeholderText"
             field="id"
             v-model="value"
             :loading="loading"
             :data="results"
             :clear-on-select="true"
             @typing="search"
             @select="viewItem"
             size="is-medium"
             expanded>
             <template slot-scope="props">
               <div class="media">
                 <div class="media-content">
                   <p class="has-text-weight-bold">{{ props.option.name }}</p>
                   <p class="has-text-grey">{{ props.option.brand.name }}</p>
                   <div v-if="context.type === 'retailer' && 'retailer_identifiers' in props.option && props.option.retailer_identifiers.length > 0">
                     <p class="has-text-grey">
                       <span class="is-size-8">Retailer SKU </span>
                       <span class="has-text-black">{{ props.option.retailer_identifiers[0].identifier }}</span>
                      </p>
                   </div>
                   <p class="has-text-grey">
                     <span class="is-size-8">Vendor SKU </span>
                     <span class="has-text-black">{{ props.option.brand_identifier }}</span>
                   </p>
                 </div>
               </div>
             </template>
          </b-autocomplete>
        </b-field>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/variants/'
    },
    query () {
      // always attach short'
      const params = {
        short: 1,
        order_by: '-id'
      }
      // attach additional search param based on what user has selected
      if (this.parameter === 'search_sku') {
        params.search_sku = this.value
      } else if (this.parameter === 'search') {
        params.search = this.value
      }
      return params
    },
    placeholderText () {
      return 'Search for items...'
    }
  },
  data () {
    return {
      loading: false,
      parameter: 'search_sku',
      value: null,
      results: [],
      selected: null
    }
  },
  methods: {
    search: debounce(function (name) {
      this.searchItems()
    }, 500),
    searchItems () {
      this.loading = true
      this.$http.get(this.endpoint, { params: this.query }).then(response => {
        this.results = response.data.results
        this.loading = false
      })
    },
    viewItem (option) {
      this.$router.push({ name: 'catalog.item', params: { productId: option.product_id, variantId: option.id } })
    }
  },
  mounted () {

  },
  watch: {
    parameter (value) {
      if (this.value !== null && this.value !== '') {
        this.searchItems()
      }
    }
  }
}
</script>
