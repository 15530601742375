var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"has-background-white rounded-lg pa-lg"},[_c('AttributeSearch',{attrs:{"attributes":_vm.attributes,"isCreate":true},on:{"update:onSelect":_vm.onSelect}}),_c('div',{staticClass:"mt-md"},[_c('b-table',{staticClass:"is-size-7",attrs:{"narrowed":"","data":_vm.attributesList,"loading":_vm.loading,"mobile-cards":false},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"has-text-grey has-text-centered"},[_vm._v(" "+_vm._s((_vm.loading) ? "Loading data..." : 'Attributes are not yet added to this transformer!')+" ")])]},proxy:true}])},[[_c('b-table-column',{attrs:{"label":"Attribute","cell-class":"nowrap","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('div',[_c('router-link',{staticClass:"has-text-info has-text-weight-bold",attrs:{"to":{
                  name: 'attribute.map',
                  params: {
                    id: props.row.id,
                    transformer: _vm.transformerId
                  }
                }}},[_vm._v(" "+_vm._s(props.row.attribute.name)+" ")])],1)]}}])}),_c('b-table-column',{attrs:{"label":"Transformations","cell-class":"nowrap","centered":"","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('p',[_vm._v(" "+_vm._s(props.row.transformations.length)+" ")])]}}])}),_c('b-table-column',{attrs:{"label":"Updated At","cell-class":"nowrap","centered":"","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('p',[_vm._v(" "+_vm._s(_vm.$dates.utc(props.row.updated_at).local().format('MMM D, YYYY @ h:mm A'))+" ")]),_c('p')]}}])})]],2)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }