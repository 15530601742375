0<template>
  <form @submit.prevent="update" novalidate autocomplete="off">
    <div class="columns">
      <div class="column is-one-fifth">
        <p class="has-text-weight-bold is-size-6 mb-sm">Product Description</p>
        <p class="has-text-grey">
          Add a rich description to your product.
        </p>
      </div>
      <div class="column">
        <div class="has-background-white rounded-xl">
          <!-- Loaded -->
          <div class="pa-lg pb-xl">
            <editor-menu-bar :editor="editor" v-slot="{ commands, isActive }">
              <div class="buttons">
                <b-button :class="{ 'is-active': isActive.bullet_list() }" @click.prevent="commands.bullet_list">
                  <b-icon icon="format-list-bulleted"  />
                </b-button>
                <b-button :class="{ 'is-active': isActive.ordered_list() }" @click.prevent="commands.ordered_list">
                  <b-icon icon="format-list-numbered"  />
                </b-button>
              </div>
            </editor-menu-bar>
            <editor-content :editor="editor" class="border pa-md content" style="height:250px" />
          </div>
          <div class="border-t">
            <div class="columns is-vcentered is-marginless">
              <div class="column">
                <div class="px-sm has-text-grey-light">
                  <i v-if="isDirty">Click 'Update' to save your changes</i>
                </div>
              </div>
              <div class="column is-narrow">
                <div class="px-sm">
                  <b-button native-type="submit" type="is-primary rounded-lg" :disabled="updating || !isDirty">Update</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
import { OrderedList, BulletList, ListItem, HardBreak } from 'tiptap-extensions'
import stripTags from 'locutus/php/strings/strip_tags'

export default {
  components: {
    EditorContent,
    EditorMenuBar
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/products/' + this.$route.params.id + '/'
    },
    json () {
      return {
        description: this.descriptionHtml
      }
    }
  },
  data () {
    return {
      isDirty: false,
      descriptionHtml: null,
      editor: null,
      errors: [],
      updating: false
    }
  },
  methods: {
    update () {
      this.updating = true
      this.$http.patch(this.endpoint, this.json).then(response => {
        this.isDirty = false
        this.$emit('updated:product', response.data)
        this.$buefy.toast.open({ type: 'is-success', message: 'Product description updated!' })
      }).catch(error => {
        if (error.response && error.response.status === 400) {
          this.errors = error.response.data
        }
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.updating = false
      })
    }
  },
  mounted () {
    this.descriptionHtml = stripTags(this.product.description, '<p><ul><ol>')
    this.editor = new Editor({
      extensions: [
        new ListItem(),
        new OrderedList(),
        new BulletList(),
        new HardBreak()
      ],
      onUpdate: ({ getHTML }) => {
        this.isDirty = true
        this.descriptionHtml = getHTML()
      }
    })
    this.editor.setContent(this.descriptionHtml)
  },
  beforeDestroy () {
    this.editor.destroy()
  },
  props: ['product']
}
</script>
<style>
.ProseMirror:focus {
  outline: none
}
</style>
