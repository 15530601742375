<template>
  <form @submit.prevent="submit" novalidate autocomplete="off">
    <div class="columns">
      <div class="column is-one-fifth">
        <p class="has-text-weight-bold is-size-6 mb-sm">Account Settings</p>
        <p class="has-text-grey is-size-7">Basic account information that identifies this user across the {{
    app.platform }} platform.</p>
      </div>
      <div class="column">
        <div class="has-background-white rounded-xl">
          <div class="pa-lg pb-xl">
            <b-field label="Account Name" expanded>
              <b-input autocomplete="off" type="text" name="accountName" v-model="accountName"
                @input="isDirty = 1"></b-input>
            </b-field>
            <div class="mb-md">
              <div class="columns">
                <div class="column is-half">
                  <b-field label="First Name" expanded>
                    <b-input autocomplete="off" type="text" name="firstName" v-model="firstName"
                      @input="isDirty = 1"></b-input>
                  </b-field>
                </div>
                <div class="column is-half">
                  <b-field label="Last Name" expanded>
                    <b-input autocomplete="off" type="text" name="lastName" v-model="lastName"
                      @input="isDirty = 1"></b-input>
                  </b-field>
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <div class="mb-lg">
                    <b-field label="Email" expanded>
                      <b-input autocomplete="off" type="email" name="email" v-model="email"
                        @input="isDirty = 1"></b-input>
                    </b-field>
                    <b-field label="New Password" expanded>
                      <b-input autocomplete="new-password" type="password" name="newPassword" v-model="newPassword"
                        password-reveal></b-input>
                    </b-field>
                    <div class="po-password-strength-wrapper">
                      <password-meter :password="newPassword" @score="setScore" />
                    </div>
                    <b-field label="Confirm Password" expanded>
                      <b-input autocomplete="new-password" v-model="confirmPassword" type="password"
                        name="confirmPassword" @input="isDirty = 1" password-reveal></b-input>
                    </b-field>
                  </div>
                  <div>
                    <p class="has-text-weight-bold mb-md">Can suppliers create permits?</p>
                    <b-field>
                      <b-radio v-model="brandCanCreatePermits" native-value="Yes">Yes</b-radio>
                    </b-field>
                    <b-field>
                      <b-radio v-model="brandCanCreatePermits" native-value="No">No</b-radio>
                    </b-field>
                  </div>
                  <div>
                    <p class="has-text-weight-bold my-md">Select Onboarding Tasks:</p>
                    <div v-if="loadingTasks">
                      <loading />
                    </div>
                    <div v-else v-for="task in onboardingTasks" :key="task.name" class="field">
                      <b-checkbox v-model="selectedValues" :native-value="task.id">{{ task.name }}</b-checkbox>
                    </div>
                  </div>
                </div>
                <div class="column">
                  <div class="has-background-white-ter mt-md pa-md rounded-xl content">
                    <p class="has-text-weight-bold">Tips for a strong password</p>
                    <ul>
                      <li class="mb-md">
                        Longer passwords are better. Symbols and numbers help,
                        but the number of characters matters more.
                      </li>
                      <li class="mb-md">
                        Use the meter to gauge your password strength. You must
                        score at least in the yellow to set your password (but
                        green is highly recommended).
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="border-t">
            <div class="columns is-vcentered is-marginless">
              <div class="column">
                <div class="px-sm has-text-grey-light">
                  <!-- <i v-if="error !== null" v-text="error" class="has-text-danger"></i> -->
                  <i v-text="validationMessage.body" :class="validationMessage.class"></i>
                </div>
              </div>
              <div class="column is-narrow">
                <div class="px-sm">
                  <b-button native-type="submit" type="is-primary rounded-lg" :disabled="saving || !ready"
                    :loading="saving">Submit</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import passwordMeter from 'vue-simple-password-meter'

export default {
  components: {
    passwordMeter
  },
  computed: {
    ...mapGetters(['app', 'api', 'auth', 'context']),
    accountEndpoint () {
      return this.api.baseUrl + '/v1/users/'
    },
    accountRequest () {
      const code = this.$utils.kebabCase(this.accountName)
      return {
        account: {
          name: this.accountName,
          code: code
        },
        user: {
          first_name: this.firstName,
          last_name: this.lastName,
          email: this.email,
          password: this.newPassword
        }
      }
    },
    retailerEndpoint () {
      return this.api.baseUrl + '/v1/retailers/'
    },
    validationMessage () {
      const message = {
        class: 'has-text-grey',
        body: ''
      }
      if (this.isDirty) {
        if (this.newPassword === null || this.newPassword === '') {
          message.body = 'Please enter a new password.'
        } else if (this.newPassword !== this.confirmPassword) {
          message.body = 'Please make sure to confirm your new password.'
        } else if (this.passwordScore < 2) {
          message.body = 'Please strengthen your password until you see a green score.'
        }
      }
      return message
    },
    ready () {
      return (!this.saving &&
        this.isDirty &&
        this.accountName &&
        this.firstName &&
        this.lastName &&
        this.email &&
        this.newPassword !== null &&
        this.newPassword !== '' &&
        this.newPassword === this.confirmPassword &&
        this.passwordScore >= 2
      )
    }
  },
  data: () => {
    return {
      isDirty: false,
      accountName: null,
      firstName: null,
      lastName: null,
      email: null,
      newPassword: null,
      confirmPassword: null,
      saving: false,
      passwordScore: 0,
      brandCanCreatePermits: 'No',
      error: null,
      account: null,
      userId: null,
      retailerId: null,
      selectedValues: [],
      onboardingTasks: [],
      loadingTasks: true
    }
  },
  mounted () {
    const endpoint = this.api.baseUrl + '/v1/onboarding-tasks/?status=active&type=initial&content_type=retailer'
    this.$http.get(endpoint).then(response => {
      response.data.results.forEach(task => {
        this.onboardingTasks.push({ name: task.name, id: task.id })
      })
      this.loadingTasks = false
    })
  },
  methods: {
    setScore ({ score }) {
      this.isDirty = true
      this.passwordScore = score
    },
    async createAccount () {
      return this.$http.post(this.accountEndpoint, this.accountRequest)
    },
    async createRetailer () {
      const code = this.$utils.kebabCase(this.accountName)
      const body = {
        name: this.accountName,
        code: code,
        account_id: this.account.id,
        connections_manager: {
          id: this.userId
        },
        merchandising_manager: {
          id: this.userId
        },
        transaction_manager: {
          id: this.userId
        },
        brand_permit_creation_allowed: (this.brandCanCreatePermits === 'Yes') ? 1 : 0
      }
      return this.$http.post(this.retailerEndpoint, body)
    },
    async fetchRoleTypes () {
      const endpoint = this.api.baseUrl + `/v1/retailers/${this.retailerId}/role-types/`
      return this.$http.get(endpoint)
    },
    async assignRole (roleId) {
      const endpoint = this.api.baseUrl + `/v1/retailers/${this.retailerId}/users/${this.userId}/roles/`
      const body = {
        role_type: {
          id: roleId
        }
      }
      return this.$http.post(endpoint, body)
    },
    async assignOnboardingTasks () {
      const endpoint = this.api.baseUrl + `/v1/retailers/${this.retailerId}/assigned-tasks/`
      const body = []
      this.selectedValues.forEach(value => {
        body.push({ onboarding_task: { id: value } })
      })
      return this.$http.post(endpoint, body)
    },
    async submit () {
      this.saving = true
      try {
        // create account
        const accountResponse = await this.createAccount()
        const { account, user } = accountResponse.data
        this.account = account
        this.userId = user.id
        // create retailer
        const retailerResponse = await this.createRetailer()
        this.retailerId = retailerResponse.data.id
        // fetch retailer roles
        const roles = await this.fetchRoleTypes()
        const retailerAdmin = roles.data.results.find(el => el.code === 'retailer_admin')
        // add retailer role to the user
        const assignRoleResponse = await this.assignRole(retailerAdmin.id)
        // assign tasks to retailer
        const assignTasksResponse = await this.assignOnboardingTasks()

        if (assignRoleResponse.status === 201 && assignTasksResponse.status === 200) {
          this.saving = false
          this.isDirty = false
          this.$buefy.toast.open({ type: 'is-success', message: 'Account created!' })
          this.$router.push({ name: 'context' })
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          const message = 'Please check that the account and user your provided does not already exist.'
          this.$buefy.toast.open({ type: 'is-danger', message: message, duration: 5000 })
        }
        this.saving = false
        this.isDirty = false
      }
    }
  }
}
</script>
