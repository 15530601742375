<template>
  <span :class="presenter.textClass">{{ presenter.label }}</span>
</template>

<script>
export default {
  computed: {
    presenter () {
      const days = Math.ceil(this.timestat)
      const presenter = {
        label: days,
        days: days,
        textClass: null
      }
      if (days === 0) {
        presenter.label = 'Same Day'
        presenter.textClass = 'has-text-success'
      }
      if (days >= 5) {
        presenter.textClass = 'has-text-warning'
      }
      return presenter
    }
  },
  props: ['timestat']
}
</script>
