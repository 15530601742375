<template>
<form>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Remove Task</p>
    </header>
    <section class="modal-card-body">
      <div class="has-text-centered py-md">
        <p>Are you sure you want to remove this task?</p>
        <p>It can always be re-added later if needed.</p>
      </div>
    </section>
    <footer class="modal-card-foot space-between">
      <button class="button" type="button" @click="$parent.close()">Cancel</button>
      <b-button
        type="is-danger"
        @click="removeTask"
        :loading="saving"
        :disabled="saving"
      >Remove Task</b-button>
    </footer>
  </div>
</form>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/assigned-tasks/' + this.taskId + '/'
    }
  },
  data () {
    return {
      saving: false
    }
  },
  methods: {
    removeTask () {
      this.saving = true
      this.$http.delete(this.endpoint).then(() => {
        this.$parent.close()
        this.$emit('task:removed')
      }).finally(() => {
        this.saving = false
      })
    }
  },
  props: ['taskId']
}
</script>
