import Request from '../Request'
export default class ArchiveProposal extends Request {
  constructor (config) {
    super()
    this.method = 'patch'
    this.context = config.context
    this.route = config.route
    this.proposal = config.proposal
    this.validate()
  }

  id () {
    return this.proposal.id
  }

  label () {
    return '#' + this.proposal.id
  }

  validate () {
    if (this.proposal.status !== 'draft') {
      this.isValid = false
      this.status = 'Skip'
      this.errorMessage = 'Only draft proposals can be archived in bulk.'
    } else {
      this.isValid = true
    }
  }

  json () {
    return {
      status: 'archived'
    }
  }

  endpoint () {
    return this.route + '/proposals/' + this.id() + '/'
  }

  errorHandler (errors) {
    return errors[0]
  }
}
