<template>
  <div class="section">
    <div class="container">
      <!-- Page Header -->
      <div class="mb-lg">
        <div class="columns is-vcentered">
          <div class="column is-one-third">
            <breadcrumbs :history="[{ label: 'Orders', name: 'orders.dashboard'}]" current="Tracking #s" />
          </div>
          <div class="column is-one-third">
            <h1 class="has-text-centered is-size-4">Tracking #s</h1>
          </div>
        </div>
      </div>
      <!-- Filters Toolbar -->
      <div class="has-background-white rounded-lg pa-md mb-xxs">
        <div class="columns">
          <div class="column">
            <shipment-filters :applied-filters="filters" @filter="filter" />
          </div>
          <div class="column is-narrow">
            <b-select v-model="orderBy" size="is-medium" expanded @input="sort">
              <option value="-id">Most Recent</option>
              <option value="id">Oldest</option>
            </b-select>
          </div>
          <div class="column is-narrow">
            <b-button size="is-medium" class="is-fullwidth" icon-left="download" @click="showExportModal = !showExportModal">Export</b-button>
          </div>
        </div>
      </div>
      <!-- Actions Toolbar -->
      <div class="has-background-white rounded-lg px-md py-sm mb-xxs">
        <div class="columns is-vcentered is-mobile">
          <div class="column is-narrow">
            <b-dropdown :disabled="loading || selected.length === 0">
              <button class="button" slot="trigger" slot-scope="{ active }" :disabled="selected.length === 0">
                <span>Actions</span>
                <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
              </button>
              <template v-if="context.isSupplier()">
                <b-dropdown-item has-link><a href="#" @click.prevent class="has-text-grey-light">No Actions</a></b-dropdown-item>
              </template>
              <template v-if="context.isMerchant()">
                <b-dropdown-item has-link><a href="#" @click.prevent="showAcknowledgeShipmentsModal = true">Acknowledge Shipments</a></b-dropdown-item>
                <b-dropdown-item><a href="#" @click.prevent="copyTrackingNumbers">Copy Tracking Numbers</a></b-dropdown-item>
              </template>
            </b-dropdown>
          </div>
          <div class="column">
            <div class="has-text-right is-size-7">
              <!-- Counts -->
              <p v-if="loading">Loading...</p>
              <p v-else>{{ total }} shipments found.</p>
            </div>
          </div>
        </div>
      </div>
      <!-- Results -->
      <div>
        <div class="mb-md">
          <b-table
            :data="results"
            :checked-rows.sync="selected"
            :mobile-cards="false"
            :loading="loading"
            hoverable
            checkable
            checkbox-position="left"
            class="is-size-7">
            <template>
              <!-- Brand -->
              <b-table-column
                v-if="context.isMerchant()"
                label="Supplier"
                cell-class="nowrap"
                width="164"
                v-slot="props">
                {{ props.row.brand.name }}
              </b-table-column>
              <!-- Shipping Method -->
              <b-table-column
                label="Service"
                header-class="nowrap"
                cell-class="nowrap"
                width="164"
                v-slot="props">
                <ShippingService :shippingMethod="props.row.shipping_method" :shippingProviderIdentifier="null" />
              </b-table-column>
              <!-- Tracking # -->
              <b-table-column
                label="Tracking #"
                header-class="nowrap"
                cell-class="nowrap"
                width="164"
                v-slot="props">
                <a href="#"
                  class="has-text-weight-bold has-text-info"
                  @click.prevent="inspectShipment = props.row"
                >{{ props.row.tracking_number }}</a>
              </b-table-column>
              <!-- PO # -->
              <b-table-column
                label="PO #"
                header-class="nowrap"
                cell-class="nowrap"
                v-slot="props">
                <a href="#"
                  class="has-text-weight-bold has-text-info"
                  @click.prevent="showOrder(props.row)"
                >{{ props.row.purchase_order_number }}</a>
              </b-table-column>
              <!-- Merchant -->
              <b-table-column
                v-if="context.isSupplier()"
                label="Merchant"
                header-class="nowrap"
                cell-class="nowrap"
                v-slot="props">
                {{ props.row.retailer.name }}
              </b-table-column>
              <!-- Registered -->
              <b-table-column
                label="Registered"
                header-class="nowrap"
                cell-class="nowrap"
                centered
                width="128"
                v-slot="props">
                <span v-if="props.row.created_at !== null">{{ $dates.utc(props.row.created_at).local().format('MMM D, YYYY') }}</span>
                <span v-else>--</span>
              </b-table-column>
              <!-- Acknowledged -->
              <b-table-column
                label="Acknowledged"
                header-class="nowrap"
                cell-class="nowrap"
                centered
                width="128"
                v-slot="props">
                <span v-if="props.row.acknowledged_at === null">--</span>
                <span v-else>{{ $dates.utc(props.row.acknowledged_at).local().format('MMM D, YYYY') }}</span>
              </b-table-column>
              <!-- Tracking Link -->
              <b-table-column
                label=""
                header-class="nowrap"
                cell-class="nowrap"
                width="64"
                v-slot="props">
                <a v-if="props.row.tracking_url" class="has-text-info" :href="props.row.tracking_url" target="_blank">Track</a>
                <span v-else class="has-text-grey-light">Track</span>
              </b-table-column>
            </template>
            <template #empty>
              <div class="py-xxl has-text-grey has-text-centered">
                {{ (loading) ? "Loading data..." : 'No shipments found!' }}
              </div>
            </template>
          </b-table>
        </div>
        <b-pagination
          :total="total"
          :current.sync="currentPage"
          :rounded="true"
          :per-page="perPage"
          @change="paginate">
        </b-pagination>
      </div>

      <!-- Inspect Shipment -->
      <b-modal
        v-if="(inspectShipment !== null)"
        has-modal-card
        :active="(inspectShipment !== null)"
        :can-cancel="['escape', 'x']"
        @close="closeModals"
        ><ShipmentModal :shipment="inspectShipment" />
      </b-modal>

      <!-- Inspect Order -->
      <b-modal
        has-modal-card
        scroll="keep"
        :active.sync="showOrderModal"
        :can-cancel="['x']"
        @close="closeModals"
        ><OrderModal :selectedOrder="inspectOrder" />
      </b-modal>

      <!-- Bulk Accept -->
      <b-modal
        v-if="context.isMerchant()"
        has-modal-card
        scroll="keep"
        :active.sync="showAcknowledgeShipmentsModal"
        :can-cancel="['x']"
        ><acknowledge-shipments-modal :shipments="selected" @reload="reload"></acknowledge-shipments-modal>
      </b-modal>

      <!-- Modal: Export  -->
      <b-modal
        has-modal-card
        scroll="keep"
        :active.sync="showExportModal"
        :can-cancel="['x']"
        ><div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Export Tracking #s</p>
          </header>
          <section class="modal-card-body">
            <p class="pa-xl has-text-grey has-text-centered">
              Click 'Export' below to have a tracking number export delivered to your
              email. Please note that the export will only include
              the items that match your applied filters. Please double check
              your filters before exporting.
            </p>
          </section>
          <footer class="modal-card-foot" style="justify-content:flex-end">
            <b-button type="is-primary" @click="scheduleExport" :loading="exporting" :disabled="exporting">Export</b-button>
          </footer>
        </div>
      </b-modal>

    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import presets from '../presets/shipments'
import Filterable from '@/mixins/Filterable'
import Exportable from '@/mixins/Exportable'
import ShipmentModal from '@/components/Modals/Shipment/ShipmentModal'
import ShipmentFilters from '../components/Shipments/ShipmentFilters'
import OrderModal from '@/components/Modals/Order/OrderModal'
import AcknowledgeShipmentsModal from '../components/Shipments/AcknowledgeShipmentsModal'
import ShippingService from '@/components/Presenters/Shipment/ShippingService'

export default {
  components: {
    AcknowledgeShipmentsModal,
    ShipmentFilters,
    ShipmentModal,
    OrderModal,
    ShippingService
  },
  data () {
    return {
      key: 'shipments',
      presets: presets,
      // modals
      showOrderModal: false,
      showShipmentModal: false,
      showAcknowledgeShipmentsModal: false,
      inspectShipment: null,
      inspectOrder: null
    }
  },
  computed: {
    ...mapGetters(['context']),
    view () {
      return (this.context.isSupplier()) ? 'Suppliers' : 'Merchants'
    },
    endpoint () {
      return this.context.route + '/shipments/'
    },
    exportEndpoint () {
      return this.endpoint + 'email-export/'
    },
    selectedTrackingNumbers () {
      if (this.selected.length === 0) return null
      const trackingNumbers = []
      this.selected.forEach(shipment => {
        if (shipment.tracking_number !== null && shipment.tracking_number !== '') {
          trackingNumbers.push(shipment.tracking_number)
        }
      })
      if (trackingNumbers.length === 0) return null
      if (trackingNumbers.length === 1) return trackingNumbers.join('')
      return trackingNumbers.join(', ')
    },
    fields () {
      const base = [
        'id',
        'order_id',
        'shipping_method',
        'carrier',
        'tracking_number',
        'purchase_order_number',
        'brand',
        'retailer',
        'created_at',
        'shipped_at',
        'acknowledged_at',
        'tracking_url'
      ].join(',')
      return base
    },
    alwaysAttach () {
      return {
        fields: this.fields,
        short: 1
      }
    }
  },
  methods: {
    closeModals () {
      this.inspectShipment = null
      this.inspectOrder = null
    },
    showOrder (shipment) {
      this.inspectOrder = {
        id: shipment.order_id,
        purchase_order_number: shipment.purchase_order_number
      }
      this.showOrderModal = true
    },
    copyTrackingNumbers () {
      if (this.selectedTrackingNumbers !== null) {
        this.$copyText(this.selectedTrackingNumbers).then(() => {
          this.$buefy.toast.open({ type: 'is-success', message: 'Tracking numbers copied!' })
        })
      } else {
        this.$buefy.toast.open({ type: 'is-danger', message: 'No tracking numbers copied!' })
      }
    },
    reload () {
      this.selected = []
      this.load()
    }
  },
  mounted () {
    this.load()
  },
  mixins: [Filterable, Exportable]
}
</script>
