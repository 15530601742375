<template>
  <div>
    <div class="section">
      <div class="container">
        <!-- Page Header -->
        <div class="columns is-vcentered">
          <div class="column is-one-third">
            <breadcrumbs :history="[{ label: 'Products', name: 'catalog'}]" current="New Proposal" :hideBack="true" />
          </div>
          <div class="column is-one-third">
            <h1 class="has-text-centered is-size-4">Submit New Proposal</h1>
          </div>
        </div>
        <!-- Wizard -->
        <div>
          <div class="columns is-centered">
            <div class="column is-two-thirds-desktop is-half-widescreen">
              <div class="box">
                <transition name="fade" mode="out-in">
                  <component :is="step"
                    :feed="feed"
                    :feedDetails="feedDetails"
                    :proposal="proposal"
                    @proposal:created="trackProposal"
                    @feed:queued="trackFeed"
                    @feedDetails:queued="trackFeedDetails"
                    @navigate="navigate"
                  ></component>
                </transition>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Intro from '../components/SubmitNew/Intro'
import Build from '../components/SubmitNew/Build'
import Progress from '../components/SubmitNew/Progress'
import Compatibility from '../components/SubmitNew/Compatibility'

export default {
  components: {
    Intro,
    Build,
    Progress,
    Compatibility
  },
  computed: {
    ...mapGetters(['app', 'context'])
  },
  data () {
    return {
      step: null,
      proposal: null,
      feed: null,
      feedDetails: null
    }
  },
  methods: {
    navigate (step) {
      this.step = step
      window.scrollTo(0, 0)
    },
    trackProposal (proposal) {
      this.proposal = proposal
    },
    trackFeed (feed) {
      this.feed = feed
    },
    trackFeedDetails (feedDetails) {
      this.feedDetails = feedDetails
    }
  },
  mounted () {
    if (this.$route.query.proposal_id && this.$route.query.feed_id) {
      this.proposal = {
        id: this.$route.query.proposal_id
      }
      this.feed = {
        id: this.$route.query.feed_id
      }
    }
    const steps = ['Intro', 'Build', 'Progress', 'Compatibility']
    if ('step' in this.$route.query && steps.includes(this.$route.query.step)) {
      this.step = this.$route.query.step
    } else {
      this.step = 'Intro'
    }
  }
}
</script>
