<template>
<div>
  <div class="pa-md">
    <!-- Task is loaded -->
    <div v-if="!loading && task !== null">
      <p class="has-text-centered has-text-weight-bold mb-lg is-size-5">
        {{ connection.retailer.name }}
        Terms &amp; Conditions
      </p>
      <div class="columns is-centered">
        <div class="column is-half-widescreen">
          <div class="box">
            <div v-if="terms">
              <!-- Intro -->
              <div class="mb-lg">
                <p class="mb-sm">
                  {{ connection.retailer.name }} requires you to review and accept
                  their Terms &amp; Conditions agreement before you can complete
                  onboarding. Please review the following document.
                </p>
                <p>
                  <span class="has-text-danger has-text-weight-bold">Please note:</span>
                  Any questions should be directed to {{ connection.retailer.name }}.
                  {{ app.platform }} cannot answer any questions about this document.
                </p>
              </div>
              <!-- iFrame in the document -->
              <div class="mb-lg border">
                <iframe width="100%" height="400" style="height:400px !important; display:block" :src="terms.content"></iframe>
              </div>
              <!-- Link -->
              <div class="mb-sm has-text-centered">
                <p><a :href="terms.content" target="_blank" class="has-text-info has-text-weight-bold">Open document in a new window</a></p>
              </div>
              <!-- Acknowledgment Warning -->
              <p v-if="terms.acknowledgements.length" class="has-text-warning has-text-centered">
                This document was acknowledged by {{ terms.acknowledgements[0].name }}
                on {{ $dates.utc(terms.acknowledgements[0].acknowledged_at).local().format('MMM D, YYYY @ h:mm A') }}.
              </p>
              <!-- Navigate -->
              <div class="py-lg">
                <div class="columns is-centered">
                  <div class="column">
                    <b-button
                      type="is-light rounded-lg"
                      size="is-medium"
                      @click="goBack"
                      expanded
                      ><b-icon icon="arrow-left"></b-icon>
                    </b-button>
                  </div>
                  <div class="column">
                    <b-button
                      type="is-primary rounded-lg"
                      size="is-medium"
                      expanded
                      @click="goForward"
                      :disabled="saving"
                      :loading="saving"
                    >{{ (isAcknowledged) ? 'Return to Tasks' : 'Accept' }}</b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Return & Reset Links -->
          <div class="columns is-centered">
            <router-link :to="{ name: 'onboarding.tasks', params: { connection: this.$route.params.connection }}" class="column is-size-7 ml-sm" :class="!isCompleted && 'has-text-centered'">
              Back to Tasks
            </router-link>
            <a v-if="isCompleted" href="#" class="column is-size-7 has-text-right mr-sm" @click="showResetTaskModal=true">
              Reset Task
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- Loading Skeleton -->
    <div v-else>
      <div class="mb-md">
        <div class="columns is-centered">
          <div class="column is-one-quarter">
            <b-skeleton />
          </div>
        </div>
      </div>
      <div class="columns is-centered">
        <div class="column is-half">
          <div class="box">
            <loading message="Loading..." />
          </div>
          <!-- Return Link -->
          <div class="has-text-centered">
            <router-link :to="{ name: 'onboarding.tasks', params: { connection: this.$route.params.connection }}" class="is-size-7">
              Back to Tasks
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Reset Task Modal -->
  <b-modal
    v-if="!loading && task !== null"
    :has-modal-card="true"
    :active.sync="showResetTaskModal"
    :can-cancel="['x', 'esc']">
    <ResetTaskModal :taskId="task.id" @task:reset="onTaskReset" />
  </b-modal>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import ResetTaskModal from '@/components/Models/Task/Modals/ResetTaskModal'

export default {
  components: {
    ResetTaskModal
  },
  computed: {
    ...mapGetters(['app', 'context']),
    loading () {
      return Boolean(this.loadingTask || this.loadingDocuments)
    },
    isAcknowledged () {
      return Boolean(this.terms && this.terms.acknowledgements.length)
    },
    isCompleted () {
      return this.task && this.task.completed_at !== null
    }
  },
  data () {
    return {
      loadingTask: true,
      loadingDocuments: true,
      task: null,
      terms: null,
      showTermsModal: false,
      showResetTaskModal: false,
      saving: false
    }
  },
  methods: {
    loadTask () {
      this.loadingTask = true
      const endpoint = this.context.route + '/assigned-tasks/' + this.$route.params.task + '/'
      this.$http.get(endpoint).then(response => {
        this.task = response.data
        this.loadingTask = false
      })
    },
    loadDocuments () {
      this.loadingDocuments = true
      const endpoint = this.context.route + '/terms-and-conditions/'
      this.$http.get(endpoint).then(response => {
        response.data.results.forEach(document => {
          if (document.author && document.author.id === this.connection.retailer.id && document.type === 'full_agreement') {
            this.terms = document
          }
        })
        this.loadingDocuments = false
      })
    },
    goBack () {
      this.$router.push({ name: 'onboarding.tasks', params: { connection: this.connection.id } })
    },
    acknowledgeTerms () {
      const endpoint = this.context.route + '/terms-and-conditions/' + this.terms.id + '/acknowledge/'
      return this.$http.put(endpoint)
    },
    async goForward () {
      this.saving = true
      if (this.terms && this.terms.acknowledgements.length === 0) {
        await this.acknowledgeTerms()
      }
      const endpoint = this.context.route + '/assigned-tasks/' + this.task.id + '/complete/'
      return this.$http.put(endpoint).then(() => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Task Completed!' })
        this.$router.push({ name: 'onboarding.tasks', params: { connection: this.connection.id } })
      })
    },
    onTaskReset () {
      this.$buefy.toast.open({ type: 'is-info', message: 'Task reset successfully.' })
      this.$router.push({ name: 'onboarding.tasks', params: { connection: this.$route.params.connection } })
    }
  },
  mounted () {
    this.loadTask()
    this.loadDocuments()
  },
  props: ['connection']
}
</script>
