<template>
  <span class="has-text-weight-bold" :class="presenter.indicatorClass">{{ presenter.label }}</span>
</template>

<script>
export default {
  computed: {
    presenter () {
      const presenter = {
        label: 'Not Finalized',
        tooltip: 'This shipment has been registered, but because the order is not closed, is subject to change.',
        indicatorClass: 'has-text-grey'
      }
      // if the shipment is closed, but not acknowledged
      if (this.shipment.status === 'closed' && this.shipment.acknowledged_at === null) {
        presenter.label = 'Sent'
        presenter.tooltip = 'Shipment sent, but Merchant has not yet acknowledged receipt.'
        presenter.indicatorClass = 'has-text-warning'
      }
      // if the shipment is closed, but acknowledged
      if (this.shipment.status === 'closed' && this.shipment.acknowledged_at !== null) {
        presenter.label = 'Received'
        presenter.tooltip = 'The retailer has received this shipment.'
        presenter.indicatorClass = 'has-text-success'
      }
      return presenter
    }
  },
  props: ['shipment']
}
</script>
