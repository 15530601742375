<template>
  <div class="mb-md">
    <b-field label="Attribute">
      <b-select
        v-model="attribute"
        :placeholder="getPlaceholder"
        expanded
        :loading="loading"
        :disabled="loading"
        :size="sizeClass"
        @input="announce">
        <option
          v-for="attribute in attributes"
          :value="attribute"
          :key="attribute.id"
        >{{ attribute.name }}</option>
      </b-select>
    </b-field>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  data () {
    return {
      loading: false,
      attribute: null,
      attributes: []
    }
  },
  computed: {
    ...mapGetters(['api', 'context']),
    endpoint () {
      return this.api.baseRoute + '/attributes/'
    },
    query () {
      return {
        params: {
          pagination: 0,
          short: 1,
          order_by: 'id'
        }
      }
    },
    sizeClass () {
      return (this.size) ? this.size : null
    },
    getPlaceholder () {
      return this.placeholder ? this.placeholder : 'Select Attribute'
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint, this.query).then(response => {
        this.attributes = response.data.results
        this.loading = false
      })
    },
    announce () {
      this.$emit('input', this.attribute)
    }
  },
  mounted () {
    if (this.value !== null) {
      this.attribute = this.value
    }
    this.load()
  },
  watch: {
    value (newValue) {
      this.attribute = newValue
    }
  },
  props: ['value', 'size', 'placeholder']
}
</script>
