import Request from './Request'
export default class CancelOrderRequest extends Request {
  constructor (config) {
    super()
    this.context = config.context
    this.method = 'post'
    this.order = config.order
    this.validate()
  }

  id () {
    return this.order.id
  }

  label () {
    return this.order.purchase_order_number
  }

  endpoint () {
    return this.context.route + '/cancels/'
  }

  validate () {
    if (this.orderHasFulfillments()) {
      this.invalidate('Order is already in progress.')
    } else if (this.orderIsAccepted()) {
      this.invalidate('Order has already been accepted by supplier.')
    } else {
      this.isValid = true
    }
  }

  orderHasFulfillments () {
    let fulfilled = 0
    this.order.order_lines.forEach(line => {
      fulfilled += line.quantity_shipped + line.quantity_canceled
    })
    return (fulfilled > 0)
  }

  orderIsAccepted () {
    return this.context.isMerchant() && this.order.acknowledged_at !== null
  }

  json () {
    const json = {
      order_id: this.order.id,
      reason_id: (this.attributes.reason) ? parseInt(this.attributes.reason) : null,
      cancel_lines: []
    }
    this.order.order_lines.forEach(line => {
      const cancelLine = {
        quantity: line.quantity_open,
        variant: {
          id: line.variant.id
        }
      }
      if (line.brand_identifier) {
        cancelLine.brand_identifier = line.brand_identifier
      }
      json.cancel_lines.push(cancelLine)
    })
    return json
  }

  errorHandler (errors) {
    return errors.detail
  }
}
