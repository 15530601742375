<template>
  <div>
    <div class="section">
      <div class="container">
        <!-- Page Header -->
        <div class="mb-md">
          <div class="columns is-vcentered">
            <div class="column is-one-third">
              <breadcrumbs :history="[{ label: 'Settings', name: 'settings'}]" current="User Management" />
            </div>
            <div class="column is-one-third">
              <h1 class="has-text-centered is-size-4">User Management</h1>
            </div>
          </div>
        </div>
        <!-- Filters -->
        <div class="has-background-white rounded-lg pa-md mb-xxs">
          <div class="columns">
            <div class="column">
              <user-filters @filter="filter" :applied-filters="filters" />
            </div>
            <div class="column is-narrow">
              <b-select v-model="orderBy" size="is-medium" expanded @input="sort">
                <option value="-id">Newest</option>
                <option value="id">Oldest</option>
                <option value="last_name">Last Name (A-Z)</option>
                <option value="-last_name">Last Name (Z-A)</option>
              </b-select>
            </div>
            <div class="column is-narrow">
              <b-button type="is-primary" size="is-medium" @click="showInviteModal = true">Add New User</b-button>
            </div>
          </div>
        </div>
        <!-- Results -->
        <div>
          <!-- Table -->
          <div style="overflow-x:auto; overflow-y:hidden">
            <b-table
              :data="results"
              :checked-rows.sync="selected"
              :mobile-cards="false"
              :loading="loading"
              hoverable
              checkbox-position="left"
              class="mb-lg">
              <b-table-column
                label="Name"
                width="255"
                v-slot="props">
                <router-link class="has-text-weight-bold has-text-info" :to="{ name: 'user', params: { id: props.row.id } }">
                  {{ props.row.last_name }}, {{ props.row.first_name }}
                </router-link>
              </b-table-column>
              <b-table-column
                label="Email"
                v-slot="props">
                {{ props.row.email }}
              </b-table-column>
              <b-table-column
                label="Status"
                centered
                v-slot="props">
                <p v-if="props.row.status === 'locked'" class="has-text-grey has-text-weight-bold">Inactive</p>
                <p v-else-if="props.row.status === 'active'" class="has-text-success has-text-weight-bold">Active</p>
                <p v-else-if="props.row.status === 'invited'" class="has-text-info has-text-weight-bold">Invited</p>
              </b-table-column>
                <!-- Link to Detail -->
              <b-table-column
                label=""
                centered
                class="is-hidden-mobile"
                width="64"
                v-slot="props">
                <p class="has-text-centered">
                  <router-link :to="{ name: 'user', params: { id: props.row.id } }">
                    <b-icon icon="chevron-right" />
                  </router-link>
                </p>
              </b-table-column>
              <template #empty>
                <div class="has-text-grey has-text-centered">
                  {{ (loading) ? "Loading data..." : 'No team members found!' }}
                </div>
              </template>
            </b-table>
          </div>
          <!-- Pagination Controls -->
          <div class="mt-md">
            <b-pagination
              :total="total"
              :current.sync="currentPage"
              :rounded="true"
              :per-page="perPage"
              @change="paginate">
            </b-pagination>
          </div>
        </div>

        <!-- Create Modal -->
        <b-modal
          :has-modal-card="true"
          :active.sync="showInviteModal"
          :can-cancel="['x']"
        ><invite-user-modal @invited:user="load" />
        </b-modal>

      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Filterable from '@/mixins/Filterable'
import InviteUserModal from '@/components/Models/User/Modals/InviteUserModal'
import UserFilters from '../components/List/UserFilters'

export default {
  components: {
    InviteUserModal,
    UserFilters
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/users/'
    }
  },
  data () {
    return {
      key: 'users',
      orderBy: 'last_name',
      alwaysAttach: {
        short: 1,
        type: 'standard'
      },
      selected: [],
      // modal controls
      focusUser: null,
      showInviteModal: false
    }
  },
  methods: {
    inspectUser (payload) {
      this.focusUser = payload
    },
    resetUser () {
      this.focusUser = null
    },
    resetModals () {
      this.focusOrder = null
    },
    getDetails (row) {
      this.$router.push({ name: 'user', params: { id: row.id } })
    }
  },
  mixins: [Filterable],
  mounted () {
    this.load()
  }
}
</script>
