<template>
  <div>
  <div class="has-background-white rounded-lg py-md px-lg mb-xxs">
    <div class="columns is-vcentered">
      <div class="column is-narrow">
        <b-dropdown :disabled="loading || selected.length === 0">
          <button class="button" slot="trigger" slot-scope="{ active }" :disabled="selected.length === 0 && isPaid">
            <span>Actions</span>
            <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
          </button>
          <template v-if="context.isSupplier()">
            <b-dropdown-item has-link><a href="#" @click.prevent class="has-text-grey-light">No Actions</a></b-dropdown-item>
          </template>
          <template v-if="context.isMerchant()">
            <b-dropdown-item has-link><a href="#" @click.prevent="showRemoveInvoiceModal = true">Remove Invoices</a></b-dropdown-item>
          </template>
        </b-dropdown>
      </div>
      <div class="column">
        <div class="is-pulled-right">
          <b-button
            :disabled="isPaid"
            type="is-primary"
            outlined
            expanded
            @click.prevent="navigateToInvoices()"
          >Add Invoices</b-button>
        </div>
      </div>
    </div>
  </div>
  <div class="mb-lg">
    <div class="has-background-white rounded-lg pa-lg">
        <b-table
          :data="results"
          :checked-rows.sync="selected"
          :mobile-cards="false"
          :loading="loading"
          hoverable
          :checkable="!isPaid"
          checkbox-position="left"
          class="is-size-7">
          <template>
            <!-- Invoice # -->
            <b-table-column
              label="Invoice #"
              header-class="nowrap"
              cell-class="nowrap"
              width="128"
              v-slot="props">
              <a href="#"
                class="has-text-weight-bold has-text-info"
                @click.prevent="showInvoice(props.row)"
              >{{ props.row.invoice_number }}</a>
            </b-table-column>
            <!-- Submitted -->
            <b-table-column
              label="Submitted"
              header-class="nowrap"
              cell-class="nowrap"
              centered
              width="128"
              v-slot="props">
              <span v-if="props.row.created_at === null">--</span>
              <span v-else>{{ $dates.utc(props.row.created_at).local().format('MMM D, YYYY') }}</span>
            </b-table-column>
            <!-- Subtotal -->
            <b-table-column
              label="Subtotal"
              header-class="nowrap"
              cell-class="nowrap"
              centered
              width="128"
              v-slot="props">
              {{ $numbers(props.row.subtotal).formatCurrency({ mantissa: 2 }) }}
            </b-table-column>
            <!-- Adjustments -->
            <b-table-column
              label="Adjustments"
              header-class="nowrap"
              cell-class="nowrap"
              centered
              width="128"
              v-slot="props">
              {{ $numbers(props.row.due_amount).subtract(props.row.subtotal).formatCurrency({ mantissa: 2 }) }}
            </b-table-column>
            <!-- Due Amount -->
            <b-table-column
              label="Total"
              header-class="nowrap"
              cell-class="nowrap"
              centered
              width="128"
              v-slot="props">
              {{ $numbers(props.row.due_amount).formatCurrency({ mantissa: 2 }) }}
            </b-table-column>
          </template>
          <template #empty>
            <div v-if="!loading" class="has-text-centered py-xxl">
              <p class="has-text-grey-light mb-lg">
                There are no invoices associated with this payment. Click on add invoice to view list of invoices.
              </p>
              <p v-if="context.isMerchant()"><b-button outlined @click="navigateToInvoices()">Add Invoices</b-button></p>
            </div>
          </template>
        </b-table>
    </div>
  </div>
  <!-- Inspect Invoice -->
  <b-modal
    v-if="showInvoiceModal"
    has-modal-card
    :active="showInvoiceModal"
    :can-cancel="['escape', 'x']"
    @close="closeModal"
    ><InvoiceModal :invoice="inspectInvoice" />
  </b-modal>
  <!-- Remove Invoice -->
  <b-modal
    v-if="showRemoveInvoiceModal"
    has-modal-card
    :active="showRemoveInvoiceModal"
    :can-cancel="['escape', 'x']"
    @close="closeModal"
    ><RemoveInvoiceModal :selectedInvoices="selected" :payment="this.payment" @updated="onRemove"/>
  </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Filterable from '@/mixins/Filterable'
import InvoiceModal from '@/components/Modals/Invoice/InvoiceModal'
import RemoveInvoiceModal from '../components/Invoices/RemoveInvoiceModal'
export default {
  components: {
    InvoiceModal,
    RemoveInvoiceModal
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/payments/' + this.$route.params.id + '/invoices/'
    },
    isPaid () {
      return this.payment.status === 'paid'
    }
  },
  data: () => {
    return {
      showInvoiceModal: false,
      showRemoveInvoiceModal: false,
      inspectInvoice: null
    }
  },
  methods: {
    navigateToInvoices () {
      this.$router.push({ name: 'payment.detail.invoices.list', params: { id: this.payment.id } })
    },
    closeModal () {
      this.showInvoiceModal = false
      this.showRemoveInvoiceModal = false
      this.inspectInvoice = null
    },
    showInvoice (invoice) {
      this.inspectInvoice = invoice
      this.showInvoiceModal = true
    },
    removeInvoice (invoice) {
      this.inspectInvoice = invoice
      this.showRemoveInvoiceModal = true
    },
    onRemove () {
      this.closeModal()
      this.load()
      this.$emit('updated')
    }
  },
  mounted () {
    this.load()
  },
  props: ['payment'],
  mixins: [Filterable]
}
</script>
