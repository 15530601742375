<template>
  <div>
    <router-link :to="{ name: 'network.profile' }">
      <div class="rounded-xl has-background-white py-lg">
        <div class="mb-lg">
          <div class="columns is-vcentered is-mobile">
            <div class="column">
              <div class="">
                <h4 class="has-text-weight-bold mb-xs" id="network-tour-1">My Brand Profile</h4>
                <p class="has-text-grey">
                  {{ (context.partner.isRcn) ? 'Change the appearance and branding of your shop profile.' : 'Join the Commerce Network.' }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- Marketplace Loaded -->
        <div v-if="!loading && marketplace !== null">
          <p><img class="rounded-lg" :src="coverPhoto.cdnUrl" /></p>
          <p>
            <img :src="profilePhoto.cdnUrl"
              class="rounded-lg"
              style="margin-top:-72px; margin-left:32px; z-index:100; border:4px solid white"
              width="128"
            />
          </p>
        </div>
        <!-- Loading -->
        <div v-else>
          <loading size="is-large" message="Loading profile..." />
        </div>

      </div>
    </router-link>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MarketplacePresenters from '@/mixins/presenters/Marketplace'
import CdnTransform from '@/mixins/CdnTransform'

export default {
  components: {

  },
  computed: {
    ...mapGetters(['context']),
    loading () {
      return this.marketplace === null
    }
  },
  data () {
    return {

    }
  },
  methods: {

  },
  mixins: [CdnTransform, MarketplacePresenters],
  mounted () {

  },
  props: ['marketplace']
}
</script>
