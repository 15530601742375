<template>
  <div>
    <PerformanceSummary :start-date="startDate" :end-date="endDate" :dimension="dynamicDimension" :metric="metric"></PerformanceSummary>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import PerformanceSummary from '@/modules/Performance/views/Summary.vue'
export default {
  data () {
    return {}
  },
  props: {
    startDate: Date,
    endDate: Date,
    dimension: String,
    metric: String
  },
  computed: {
    ...mapGetters(['context']),
    dynamicDimension () {
      if (this.context.isSupplier()) {
        return 'retailer'
      } else if (this.context.isMerchant()) {
        return 'brand'
      }
      return 'date'
    }
  },
  components: { PerformanceSummary }
}
</script>
