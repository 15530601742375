<template>
  <form @submit.prevent="save">
    <div class="has-background-white-ter mb-xxs rounded-lg pa-md">
      <div class="columns is-mobile is-vcentered">
        <div class="column">
          <div class="is-size-7">
            <p>{{ location.address1 }}</p>
            <p>
              {{ location.city }}, {{ location.province_code }}
              {{ location.zip }} {{ location.country_code }}
            </p>
          </div>
        </div>
        <div class="column is-narrow">
          <b-button
            v-if="revcascadeId === null"
            native-type="submit"
            type="is-primary rounded-lg"
            :loading="saving"
            :disabled="saving"
          >Import</b-button>
          <b-button
            v-else
            type="is-primary rounded-lg"
            :disabled="true"
          >Imported</b-button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  data () {
    return {
      saving: false,
      revcascadeId: null,
      errors: {}
    }
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/locations/'
    },
    json () {
      return {
        nickname: this.location.name,
        address: {
          name1: this.account.brand.name,
          street1: this.location.address1,
          street2: this.location.address2,
          city: this.location.city,
          province: this.location.province_code,
          postal_code: this.location.zip,
          country: this.location.country_code
        }
      }
    }
  },
  methods: {
    save () {
      this.saving = true
      this.$http.post(this.endpoint, this.json).then(response => {
        this.revcascadeId = response.data.id
        this.$emit('location:imported', response.data)
        this.$buefy.toast.open({ type: 'is-success', message: 'Location imported!' })
      }).catch(error => {
        this.errors = error.response.data
        this.$buefy.toast.open({ type: 'is-danger', message: 'Import failed: ' + error.response.data.detail })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    if (this.location.revcascadeId !== null) {
      this.revcascadeId = this.location.revcascadeId
    }
  },
  props: ['account', 'location']
}
</script>
