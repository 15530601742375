<template>
  <div>
    <div class="section">
      <div class="container">
        <div class="mt-xl">
          <!-- Invite Found & Loaded -->
          <div v-if="!loading && invite !== null">
            <div class="columns is-centered">
              <div class="column">
                <!-- Retailer Logo -->
                <div>
                  <div class="mb-xl">
                    <div v-if="invite.retailer.logo_url">
                      <img :src="invite.retailer.logo_url | cloudinary(360, 120, 'pad', 'png')" width="180" />
                    </div>
                    <div v-else>
                      <Logo color="black" />
                    </div>
                  </div>
                  <!-- Intro text -->
                  <div>
                    <div class="mb-xl is-size-5">
                      <p class="mb-md">Hi {{ invite.first_name }},</p>
                      <!-- Products Only -->
                      <p v-if="invite.level === 'products_only'">
                        <span class="has-text-weight-bold">{{ invite.retailer.name }} </span> has selected
                        <span class="has-text-weight-bold">{{ invite.company_name }} </span>
                        to onboard & offer products their customers with technology from
                        <a class="has-text-info has-text-weight-bold" :href="app.websiteUrl" target="_blank">{{ app.productName }}.</a>
                      </p>
                      <!-- Transactions & Products -->
                      <p v-else>
                        <span class="has-text-weight-bold">{{ invite.retailer.name }} </span> has selected
                        <span class="has-text-weight-bold">{{ invite.company_name }} </span>
                        to be an exclusive partner to source and sell your products directly to
                        their customers with technology from
                        <a class="has-text-info has-text-weight-bold" :href="app.websiteUrl" target="_blank">{{ app.productName }}.</a>
                      </p>
                    </div>
                    <div class="mb-lg">
                      <p class="has-text-weight-bold is-size-5 mb-md">As a brand on {{ app.productName }}, you're entitled to: </p>
                      <table class="table has-background-light is-narrow is-vcentered is-fullwidth">
                        <tr v-for="(feature, index) in features" :key="index">
                          <td><b-icon icon="check" custom-class="has-text-success has-text-weight-bold" /></td>
                          <td>{{ feature }}</td>
                        </tr>
                      </table>
                    </div>
                    <div class="is-size-5">
                      <p class="mb-lg">
                        After you create your account, <span class="has-text-weight-bold">{{ invite.retailer.name }} </span>
                        and {{ app.platform }} will work in tandem to ensure that your products are available to
                        sell as quickly as possible.
                      </p>
                      <p>
                        If you have any questions, please don't hesitate to contact us at
                        <a :href="'mailto:' + app.supportEmail" class="has-text-info has-text-weight-bold">{{ app.supportEmail }} </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="column is-two-fifths">
                <div class="has-background-white pa-lg rounded-lg">
                  <div class="has-text-centered mb-md">
                    <p class="has-text-weight-bold is-size-5">Sign Up</p>
                  </div>
                  <!-- Toggle -->
                  <div class="mb-md">
                    <div>
                      <b-radio v-model="view" native-value="create" :disabled="isSignedIn">I am new to {{ app.productName }}</b-radio>
                    </div>
                    <div>
                      <b-radio v-model="view" native-value="link">I already have a {{ app.productName }} brand account</b-radio>
                    </div>
                  </div>
                  <!-- Create Account -->
                  <div v-if="view === 'create'">
                    <CreateAccount :code="inviteCode" :invite="invite" @showTerms="showTermsModal = true" />
                  </div>
                  <!-- Sign In & Select -->
                  <div v-if="view === 'link'">
                    <LinkAccount :code="inviteCode" :invite="invite" :isSignedIn="isSignedIn" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Invite Not Found -->
          <div v-else-if="!loading && invite === null">
            <div class="has-text-centered mb-lg">
              <Logo color="black" />
            </div>
            <div class="columns is-centered">
              <div class="column is-half">
                <div class="has-background-white rounded-lg pa-xl mb-lg has-text-centered">
                  <p class="has-text-weight-bold is-size-5 has-text-danger mb-lg">Whoops!</p>
                  <div class="mb-lg">
                    <p>
                      This invitation code could not be found or has already
                      been accepted.
                    </p>
                    <p>
                      Please try to <router-link class="has-text-info" :to="{ name: 'auth.login' }">sign in</router-link>
                      or <router-link class="has-text-info" :to="{ name: 'auth.forgot' }">reset your password</router-link>.
                    </p>
                  </div>
                  <p>For additional assistance, please contact</p>
                  <p class="has-text-weight-bold is-size-5">{{ app.supportEmail }}</p>
                </div>
              </div>
            </div>
          </div>
          <!-- Loading -->
          <div v-else>
            <div class="has-text-centered">
              <Logo color="black" />
              <loading message="Verifying invitation code..." />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal with Terms -->
    <b-modal v-if="!loading && invite !== null" :active.sync="showTermsModal" has-modal-card>
      <div class="modal-card" style="max-width: 960px">
        <section class="modal-card-body is-paddingless">
          <iframe width="100%" height="400" style="height:400px !important; display:block" src="https://drive.google.com/viewerng/viewer?embedded=true&url=https://res.cloudinary.com/revcascade/image/upload/v1645229568/static/contracts/VendorAgreement_20220114.pdf"></iframe>
        </section>
        <footer class="modal-card-foot space-between">
          <p class="has-text-grey">Terms of Service v2.0.0</p>
          <a class="button is-primary" href="https://res.cloudinary.com/revcascade/image/upload/v1645229568/static/contracts/VendorAgreement_20220114.pdf" target="_blank">Open Fullscreen</a>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Logo from '@/components/Logos/Logo'
import CreateAccount from '../components/CreateAccount'
import LinkAccount from '../components/LinkAccount'

import { mapGetters } from 'vuex'
export default {
  components: {
    CreateAccount,
    LinkAccount,
    Logo
  },
  computed: {
    ...mapGetters(['app', 'api', 'auth', 'context']),
    endpoint () {
      return this.api.baseRoute + '/connection-invites/'
    },
    query () {
      return {
        params: {
          code: this.$route.query.code
        }
      }
    },
    inviteCode () {
      return (this.$route.query.code) ? this.$route.query.code : null
    },
    isSignedIn () {
      return this.auth && this.auth.user !== null
    },
    features () {
      if (!this.invite) return []
      if (this.invite.level === 'products_only') {
        return [
          'Import and manage unlimited products.',
          'Submit unlimited product proposals to ' + this.invite.retailer.name,
          'No setup or integration fees!',
          'No monthly or recurring subscription fees!'
        ]
      }
      return [
        'Unlimited products & orders.',
        'No setup or integration fees (even for EDI!)',
        '0% transaction fee; no monthly or recurring subscription fees.',
        'Fulfill orders via EDI, API, Shopify, or Shipstation (or just use the web portal)'
      ]
    }
  },
  data () {
    return {
      view: 'create',
      invite: null,
      loading: true,
      showTermsModal: false
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint, this.query).then(response => {
        if (response.data.count === 1) {
          this.invite = response.data.results[0]
        }
      }).finally(() => {
        this.loading = false
      })
    }
  },
  mounted () {
    if (this.isSignedIn) {
      this.view = 'link'
    }
    setTimeout(() => {
      this.load()
    }, 1000)
  }
}
</script>
