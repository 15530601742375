export const status = {
  label: 'Status',
  key: 'status',
  icon: 'pulse',
  tooltip: 'Filter invites by status',
  options: [{
    value: 'accepted',
    label: 'Accepted'
  }, {
    value: 'pending',
    label: 'Pending'
  }]
}
