<template>
  <div class="has-border-b py-lg">
    <div class="columns is-vcentered">
      <div class="column">
        <div class="">
          <p class="has-text-weight-bold mb-xs"><router-link :to="{ name: 'network.access' }">Product Requests</router-link></p>
          <p class="has-text-grey">
            Review merchant requests to sell your products.
          </p>
        </div>
      </div>
      <div class="column is-one-fifth">
        <div class="rounded-lg has-background-white-ter py-sm is-size-5 has-text-danger has-text-centered">
          <span v-if="loading"><b-icon icon="loading" custom-class="mdi-spin" /></span>
          <span v-else>{{ summary }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/product-access/?status=pending'
    }
  },
  data () {
    return {
      loading: true,
      summary: null
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint).then(response => {
        this.summary = response.data.count
      }).finally(() => {
        this.loading = false
      })
    }
  },
  mounted () {
    this.load()
  },
  props: []
}
</script>
