<template>
  <div>
    <!-- Content -->
    <div class="mb-lg">
      <p class="mb-md">
        You may also specify a ShipStation Store ID if you want
        orders to land in a designated ShipStation store. Otherwise, your
        orders will land in your default ShipStation store.
      </p>
      <b-field label="ShipStation Store ID (optional)" >
        <b-input v-model="storeId" @input="isDirty = true" size="is-medium"></b-input>
      </b-field>
    </div>
    <!-- Navigate -->
    <div class="py-lg">
      <div class="columns is-centered">
        <div class="column">
          <b-button
            type="is-light rounded-lg"
            size="is-medium"
            @click="goBack"
            expanded
            ><b-icon icon="arrow-left"></b-icon>
          </b-button>
        </div>
        <div class="column">
          <b-button
            type="is-primary rounded-lg"
            size="is-medium"
            expanded
            @click="goForward"
          >Continue</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['app', 'context'])
  },
  data () {
    return {
      storeId: null,
      saving: false,
      errors: {},
      savedStoreId: null,
      isDirty: false,
      valueId: null
    }
  },
  methods: {
    goBack () {
      this.$emit('navigate', 'Intro')
    },
    goForward () {
      // if platform account & store id exists & not edited, don't do anything
      if (this.account !== null && this.savedStoreId === this.storeId) {
        this.$emit('navigate', 'Tags')
      } else {
        this.saving = true
        const endpoint = this.context.route + '/platform-accounts/' + this.account.id + '/'
        const json = {
          credentials: [{
            credential: { code: 'shipstation_store_id' },
            value: this.storeId
          }]
        }
        if (this.savedStoreId && this.valueId) {
          json.credentials[0].id = this.valueId
        }
        this.$http.patch(endpoint, json).then(response => {
          this.$buefy.toast.open({ type: 'is-info', message: 'Platform Updated!' })
          this.$emit('platform:updated')
          this.$emit('navigate', 'Tags')
        }).catch(() => {
          this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
        }).finally(() => {
          this.saving = false
        })
      }
    }
  },
  mounted () {
    const storeIdObject = this.account && this.account.credentials.filter(el => el.shipstation_store_id)
    const storeValue = storeIdObject && storeIdObject.length ? storeIdObject[0].shipstation_store_id.values[0].value : null
    const valueId = storeIdObject && storeIdObject.length ? storeIdObject[0].shipstation_store_id.values[0].id : null
    this.savedStoreId = storeValue
    this.valueId = valueId
    this.storeId = storeValue
  },
  props: {
    connection: {
      type: Object,
      required: true
    },
    task: {
      type: Object,
      required: true
    },
    account: {
      type: Object
    }
  }
}
</script>
