<template>
  <form @submit.prevent="save">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">
            Enter new name for template
        </p>
      </header>
      <section class="modal-card-body">
        <b-input
            size="is-medium"
            expanded
            v-model="newTemplateName"
           />
      </section>
      <footer class="modal-card-foot space-between" >
        <b-button @click="$parent.close()">Close</b-button>
        <b-button
          native-type="submit"
          type="is-primary"
          :disabled="saving"
          :loading="saving"
          >Confirm</b-button>
      </footer>
    </div>
  </form>
  </template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['api', 'context']),
    json () {
      return { name: this.newTemplateName }
    }
  },
  data () {
    return {
      saving: false,
      newTemplateName: null
    }
  },
  methods: {
    save () {
      this.saving = true
      this.$http.post(this.endpoint, this.json).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Template was cloned' })
        this.$emit('mapping:saved')
        this.$parent.close()
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {

  },
  props: ['endpoint']
}
</script>
