<template>
  <div>
    <!-- Actions Toolbar -->
    <div class="has-background-white rounded-lg px-md py-sm mb-xxs">
      <div class="columns is-vcentered is-mobile">
        <div class="column is-narrow">
          <b-dropdown :disabled="loading || selected.length === 0">
            <button class="button" slot="trigger" slot-scope="{ active }" :disabled="selected.length === 0">
              <span>Actions</span>
              <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
            </button>
            <b-dropdown-item><a href="#" @click.prevent="copyInvoiceNumbers">Copy Invoice Numbers</a></b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="column">
          <div class="has-text-right is-size-7">
            <!-- Counts -->
            <p v-if="loading">Loading...</p>
            <p v-else>
              {{ order.invoices.length }}
              {{ (order.invoices.length === 1) ? 'invoice' : 'invoices' }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- Reults -->
    <div>
      <b-table
        :data="order.invoices"
        :checked-rows.sync="selected"
        :mobile-cards="false"
        :loading="loading"
        hoverable
        checkable
        checkbox-position="left"
        class="is-size-7">
        <template>
          <!-- RC ID -->
          <b-table-column
            label="ID"
            header-class="nowrap"
            cell-class="nowrap"
            width="128"
            v-slot="props">
            <a href="#"
              class="has-text-weight-bold has-text-info"
              @click.prevent="inspectInvoice = props.row"
            >{{ props.row.id }}</a>
          </b-table-column>
          <!-- Invoice # -->
          <b-table-column
            label="Invoice Number"
            header-class="nowrap"
            cell-class="nowrap"
            v-slot="props">
            {{ props.row.invoice_number }}
          </b-table-column>
          <!-- Submitted -->
          <b-table-column
            label="Registered"
            header-class="nowrap"
            cell-class="nowrap"
            centered
            width="128"
            v-slot="props">
            <span v-if="props.row.invoiced_at === null">--</span>
            <span v-else>{{ $dates.utc(props.row.invoiced_at).local().format('MMM D, YYYY') }}</span>
          </b-table-column>
          <!-- Merchant Status -->
          <b-table-column
            label="Merchant Status"
            header-class="nowrap"
            cell-class="nowrap"
            centered
            width="128"
            v-slot="props">
            <MerchantStatus :invoice="props.row" />
          </b-table-column>
          <!-- Due Amount -->
          <b-table-column
            label="Total"
            header-class="nowrap"
            cell-class="nowrap"
            centered
            width="128"
            v-slot="props">
            <CurrencyLocale :value="parseFloat(props.row.due_amount)" :currency="props.row.currency"/>
          </b-table-column>
        </template>
        <template #empty>
          <div class="py-lg has-text-grey has-text-centered">
            <p>No invoices have been registered yet.</p>
          </div>
        </template>
      </b-table>
    </div>
    <!-- Inspect Modal -->
    <b-modal
      v-if="(inspectInvoice !== null)"
      has-modal-card
      :active="(inspectInvoice !== null)"
      :can-cancel="['escape', 'x']"
      @close="closeModals"
      ><InvoiceModal :invoice="inspectInvoice" />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import InvoiceModal from '@/components/Modals/Invoice/InvoiceModal'
import MerchantStatus from '@/components/Presenters/Invoice/MerchantStatus'

export default {
  components: {
    InvoiceModal,
    MerchantStatus
  },
  data () {
    return {
      loading: false,
      selected: [],
      inspectInvoice: null
    }
  },
  computed: {
    ...mapGetters(['context']),
    selectedInvoiceNumbers () {
      if (this.selected.length === 0) return null
      const invoiceNumbers = []
      this.selected.forEach(invoice => {
        if (invoice.invoice_number !== null && invoice.invoice_number !== '') {
          invoiceNumbers.push(invoice.invoice_number)
        }
      })
      if (invoiceNumbers.length === 0) return null
      if (invoiceNumbers.length === 1) return invoiceNumbers.join('')
      return invoiceNumbers.join(', ')
    }
  },
  methods: {
    copyInvoiceNumbers () {
      if (this.selectedInvoiceNumbers !== null) {
        this.$copyText(this.selectedInvoiceNumbers).then(() => {
          this.$buefy.toast.open({ type: 'is-success', message: 'Invoice numbers copied!' })
        })
      } else {
        this.$buefy.toast.open({ type: 'is-danger', message: 'No invoice numbers copied!' })
      }
    },
    closeModals () {
      this.inspectInvoice = null
    }
  },
  mounted () {

  },
  props: ['order']
}
</script>
