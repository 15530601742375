<template>
  <div>
    <div class="columns">
      <div class="column is-one-fifth">
        <p class="has-text-weight-bold is-size-6 mb-sm">Orders</p>
        <p class="has-text-grey is-size-7">Configure how your Shopify store syncs orders with {{ app.platform }}.</p>
      </div>
      <div class="column">
        <div class="has-background-white rounded-lg">
          <div class="pa-lg">
            <!-- Intro Text (Merchant) -->
            <div class="is-size-7 mb-md">
              <p class="mb-lg">
                {{ app.platform }} can automatically <b><u>send</u></b> purchase orders
                from your merchant partners directly to your
                Shopify store. When you fulfill items, tracking numbers
                will be automatically returned to your merchant partner.
              </p>
              <p>
                Please note that if you enable this integration, orders from
                <b><u>ALL</u></b> of your merchant connections on {{ app.platform }} will begin to flow
                through this integration.
              </p>
            </div>
            <!-- Credential Status -->
            <div class="has-background-white-ter pa-md rounded-lg">
              <div class="columns is-vcentered is-mobile">
                <div class="column is-narrow">
                  <b-icon v-if="credentialIsEnabled" icon="check" custom-class="has-text-success" />
                  <b-icon v-else icon="close" custom-class="has-text-warning" />
                </div>
                <div class="column">
                  <p class="has-text-weight-bold">Push Orders to Shopify</p>
                  <p class="has-text-grey-light is-size-8">
                    <span v-if="credentialIsEnabled">This integration is currently enabled.</span>
                    <span v-else>This integration is currently disabled.</span>
                  </p>
                </div>
                <div class="column is-narrow">
                  <p class="has-text-weight-bold">
                    <span v-if="credentialIsEnabled" class="has-text-success">Enabled</span>
                    <span v-else class="has-text-warning">Disabled</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="border-t">
            <div class="columns is-vcentered is-marginless">
              <div class="column">
                <div class="px-sm has-text-grey-light">
                  <i v-if="webhook !== null">Click "Disable Integration" to stop receiving orders.</i>
                  <i v-else>Click "Enable Integration" to begin receiving new orders.</i>
                </div>
              </div>
              <div class="column is-narrow">
                <div class="px-sm">
                  <!-- Disable Credental -->
                  <b-button
                    v-if="credentialIsEnabled"
                    type="is-danger rounded-lg"
                    outlined
                    :disabled="saving || savingNote"
                    :loading="saving"
                    @click="save"
                  >Disable Integration</b-button>
                  <!-- Subscribe to new Webhook -->
                  <b-button
                    v-if="!credentialIsEnabled"
                    type="is-primary rounded-lg"
                    :disabled="saving || savingNote"
                    :loading="saving"
                    @click="save"
                  >Enable Integration</b-button>
                </div>
              </div>
            </div>
          </div>
          <div class="border-t pa-lg">
            <div class="is-size-7">
              <b-field>
                <b-checkbox v-model="customizeNotes">
                  {{ customizedNoteLabel }}
                </b-checkbox>
              </b-field>
              <div v-if="customizeNotes">
                <div class="columns">
                  <div class="column">
                <b-field label="Maximum Character limit"
                  :type="{ 'is-danger': isNotValidValue }"
                  :message="isNotValidValue? 'Please enter a valid number greater than 0.' : ''">
                  <b-input
                    type="text"
                    size="is-medium"
                    v-model="noteLength"
                  ></b-input>
                </b-field>
                <p class="mb-lg"> Please select the sections you will like to <span class="has-text-success">INCLUDE</span> to your notes</p>
                    <b-field>
                      <b-checkbox v-model="notesNotification">
                      Notification
                      </b-checkbox>
                    </b-field>
                    <b-field>
                      <b-checkbox v-model="notesPackagingSlip">
                      Packaging Slip
                      </b-checkbox>
                    </b-field>
                    <b-field>
                      <b-checkbox v-model="notesGiftMessage">
                      Gift Message
                      </b-checkbox>
                    </b-field>
                    <b-field>
                      <b-checkbox v-model="notesPODetails" @input="togglePODetails">
                      Purchase Order Details
                      </b-checkbox>
                    </b-field>
                    <div v-if="notesPODetails" class="is-vcentered is-marginless">
                      <div :style="'margin-left: 40px;'">
                        <b-field>
                          <b-checkbox v-model="notesPONumber" @input="changePOSubFields">
                          PO Number
                          </b-checkbox>
                        </b-field>
                        <b-field>
                          <b-checkbox v-model="notesMOID" @input="changePOSubFields">
                          fabric Marketplace Order ID
                          </b-checkbox>
                        </b-field>
                        <b-field>
                          <b-checkbox v-model="notesShippingMethod" @input="changePOSubFields">
                          Shipping Method
                          </b-checkbox>
                        </b-field>
                        <b-field>
                          <b-checkbox v-model="notesSignatureRequired" @input="changePOSubFields">
                          Signature Required
                          </b-checkbox>
                        </b-field>
                        <b-field>
                          <b-checkbox v-model="fulfillBy" @input="changePOSubFields">
                          Fulfilled By
                          </b-checkbox>
                        </b-field>
                      </div>
                    </div>
                    <b-field>
                      <b-checkbox v-model="notesPersonalization">
                      Personalization
                      </b-checkbox>
                    </b-field>
                    <b-field>
                      <b-checkbox v-model="notesContactUs">
                      Contact Us
                      </b-checkbox>
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field label="Sample Note">
                      <b-input type="textarea" v-model="sampleMessage" rows="20" readonly></b-input>
                    </b-field>
                  </div>
                </div>
                <div class="columns">
                  <div class="column is-narrow">
                    <div class="px-sm">
                      <b-button
                        type="is-primary rounded-lg"
                        :disabled="savingNote"
                        @click="() => toggleEditResetNotesModal(true)"
                      >Reset</b-button>
                    </div>
                  </div>
                  <div class="column"></div>
                  <div class="column is-narrow">
                    <div class="px-sm">
                      <b-button
                        type="is-primary rounded-lg"
                        :disabled="savingNote || isNotValidValue"
                        :loading="savingNote"
                        @click="saveNotePreferences"
                      >Save</b-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Reset Order Notes Modal -->
    <b-modal
      v-if="showEditResetNotes"
      has-modal-card
      scroll="keep"
      :active="showEditResetNotes"
      :can-cancel="['x']"
      @cancel="()=>toggleEditResetNotesModal(false)"
      ><ConfirmationModal
        :label="`Reset custom notes`"
        :reason="'Are you sure you want to reset your Shopify order notes to its default settings?'"
        :confirmButtonText="'Reset'"
        :confirmButtonType="'is-danger'"
        :saving="resetting"
        @modal:confirm="onReset"
        @modal:close="()=>toggleEditResetNotesModal(false)"
        />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ConfirmationModal from '@/components/Modals/ConfirmationModal'
export default {
  components: {
    ConfirmationModal
  },
  computed: {
    ...mapGetters(['app', 'context']),
    endpoint () {
      return this.context.route + '/platform-accounts/' + this.account.id + '/'
    },
    credential () {
      if (this.account.credentials.length === 0) return null
      let found = null
      this.account.credentials.forEach(credential => {
        Object.keys(credential).forEach(key => {
          if (key === 'push_order_on_order_create') {
            found = credential[key].values[0]
          }
        })
      })
      return found
    },
    maxNoteCredentialValue () {
      if (this.account.credentials.length === 0) return null
      let maxNoteCredential = null
      this.account.credentials.forEach(credential => {
        Object.keys(credential).forEach(key => {
          if (key === 'max_note_value') {
            maxNoteCredential = credential[key].values[0]
          }
        })
      })
      return maxNoteCredential
    },
    credentialIsEnabled () {
      if (this.credential) {
        return (this.credential.value === '1')
      }
      return false
    },
    isNotValidValue () {
      if (!this.noteLength || isNaN(this.noteLength) || parseInt(this.noteLength) <= 0) {
        return true
      } else {
        return false
      }
    },
    sampleMessage () {
      const messageParts = []
      if (this.notesNotification) messageParts.push('This is a Purchase Order from {retailer name} forwarded via fabric Marketplace')
      if (this.notesPODetails) {
        messageParts.push('Purchase Order Details: ')
        if (this.notesPONumber) messageParts.push('- PO #: 54534')
        if (this.notesMOID) messageParts.push('- fabric Marketplace Order ID:3 4234')
        if (this.notesShippingMethod) messageParts.push('- Shipping Method: UPS 2 days')
        if (this.notesSignatureRequired) messageParts.push('- Signature Required: No')
        if (this.fulfillBy) messageParts.push('- Fulfilled By: 01-02-2024')
      }
      if (this.notesPackagingSlip) messageParts.push('The Packing slip for the order can be found here {URL}')
      if (this.notesGiftMessage) messageParts.push('Please note this order has a gift message (which may already be printed on the packing slip): You guys are the best')
      if (this.notesPersonalization) messageParts.push('Please note that some items on this order require personalization: {Gift Note: “You guys are the best”, “Requires Wrapping”: “Yes}')
      if (this.notesContactUs) messageParts.push('If you have any questions about this order, please contact markeptlacesupport@fabric.inc')

      let fullMessage = messageParts.join('\n')

      if (!this.noteLength) {
        return fullMessage
      }

      if (fullMessage.length > this.noteLength) {
        fullMessage = fullMessage.substring(0, this.noteLength) + '...'
      }

      return fullMessage
    },
    resetNotesJson () {
      const notesExclusions = []
      const json = {
        credentials: [{
          credential: {
            code: 'max_note_value'
          },
          value: 0,
          delete: 1
        }]
      }
      json.notes_exclusions = notesExclusions
      if (this.maxNoteCredentialValue !== null && this.maxNoteCredentialValue.id !== null) {
        json.credentials[0].id = this.maxNoteCredentialValue.id
      }
      return json
    },
    saveNotesJson () {
      const json = {}
      const exclusionsMapping = {
        notesNotification: { field_name: 'notification', sub_field_name: null },
        notesPackagingSlip: { field_name: 'packing_slip', sub_field_name: null },
        notesGiftMessage: { field_name: 'gift_message', sub_field_name: null },
        notesPersonalization: { field_name: 'personalization', sub_field_name: null },
        notesContactUs: { field_name: 'contact_us', sub_field_name: null },
        notesPONumber: { field_name: 'purchase_order_details', sub_field_name: 'po_number' },
        notesMOID: { field_name: 'purchase_order_details', sub_field_name: 'fabric_marketplace_order_id' },
        notesShippingMethod: { field_name: 'purchase_order_details', sub_field_name: 'shipping_method' },
        notesSignatureRequired: { field_name: 'purchase_order_details', sub_field_name: 'signature_required' },
        fulfillBy: { field_name: 'purchase_order_details', sub_field_name: 'fulfill_by' }
      }
      const notesExclusions = []
      for (const [checkboxVar, mapping] of Object.entries(exclusionsMapping)) {
        if (!this[checkboxVar]) {
          const exclusionObj = {
            field_name: mapping.field_name,
            sub_field_name: mapping.sub_field_name
          }
          notesExclusions.push(exclusionObj)
        }
      }
      json.notes_exclusions = notesExclusions
      const credentials = []
      if (this.noteLength !== null && this.noteLength.length) {
        const maxNoteCredential = {
          credential: {
            code: 'max_note_value'
          },
          value: this.noteLength
        }
        credentials.push(maxNoteCredential)
        json.credentials = credentials
      }
      if (this.maxNoteCredentialValue !== null && this.maxNoteCredentialValue.id !== null) {
        json.credentials[0].id = this.maxNoteCredentialValue.id
      }
      return json
    },
    customizedNoteLabel () {
      let label = 'I want to customize my notes for orders'
      if ((this.account && this.account.notes_exclusions && this.account.notes_exclusions.length > 0) || this.maxNoteCredentialValue) {
        label = 'I want to update my customization for order notes'
      }
      return label
    },
    json () {
      const json = {
        credentials: [{
          credential: {
            code: 'push_order_on_order_create'
          },
          value: (this.credentialIsEnabled) ? 0 : 1
        }]
      }
      if (this.credential !== null) {
        json.credentials[0].id = this.credential.id
      }
      return json
    }
  },
  data () {
    return {
      saving: false,
      savingNote: false,
      resetting: false,
      webhook: null,
      customizeNotes: false,
      notesNotification: true,
      notesPackagingSlip: true,
      notesGiftMessage: true,
      notesPODetails: true,
      notesPersonalization: true,
      notesContactUs: true,
      notesPONumber: true,
      notesMOID: true,
      notesShippingMethod: true,
      notesSignatureRequired: true,
      fulfillBy: true,
      noteLength: 600,
      showEditResetNotes: false
    }
  },
  methods: {
    save () {
      this.saving = true
      this.$http.patch(this.endpoint, this.json).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Setting updated!' })
        this.$emit('update:account')
      }).finally(() => {
        this.saving = false
      })
    },
    saveNotePreferences () {
      this.savingNote = true
      this.$http.patch(this.endpoint, this.saveNotesJson).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Setting updated!' })
        this.$emit('update:account')
      }).finally(() => {
        this.savingNote = false
      })
    },
    onReset () {
      this.resetting = true
      this.$http.patch(this.endpoint, this.resetNotesJson).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Setting updated!' })
        this.$emit('update:account')
      }).finally(() => {
        this.resetting = false
        this.resetCheckboxesToDefault()
        this.toggleEditResetNotesModal(false)
      })
    },
    toggleEditResetNotesModal (value) {
      this.showEditResetNotes = value
    },
    resetCheckboxesToDefault () {
      this.customizeNotes = false
      this.notesNotification = true
      this.notesPackagingSlip = true
      this.notesGiftMessage = true
      this.notesPODetails = true
      this.notesPersonalization = true
      this.notesContactUs = true
      this.notesPONumber = true
      this.notesMOID = true
      this.notesShippingMethod = true
      this.notesSignatureRequired = true
      this.fulfillBy = true
      this.noteLength = 600
    },
    setCheckboxesBasedOnExclusions () {
      this.account.notes_exclusions.forEach(exclusion => {
        switch (exclusion.field_name) {
          case 'notification':
            this.notesNotification = false
            break
          case 'packing_slip':
            this.notesPackagingSlip = false
            break
          case 'gift_message':
            this.notesGiftMessage = false
            break
          case 'personalization':
            this.notesPersonalization = false
            break
          case 'contact_us':
            this.notesContactUs = false
            break
        }
        if (exclusion.field_name === 'purchase_order_details' && exclusion.sub_field_name) {
          switch (exclusion.sub_field_name) {
            case 'po_number':
              this.notesPONumber = false
              break
            case 'fabric_marketplace_order_id':
              this.notesMOID = false
              break
            case 'shipping_method':
              this.notesShippingMethod = false
              break
            case 'signature_required':
              this.notesSignatureRequired = false
              break
            case 'fulfillBy':
              this.fulfillBy = false
              break
          }
        }
      })
      this.changePOSubFields()
    },
    togglePODetails (value) {
      this.notesPONumber = value
      this.notesMOID = value
      this.notesShippingMethod = value
      this.notesSignatureRequired = value
      this.fulfillBy = value
    },
    changePOSubFields () {
      if (!this.notesPONumber && !this.notesMOID && !this.notesShippingMethod && !this.notesSignatureRequired && !this.fulfillBy) {
        this.notesPODetails = false
      }
    }
  },
  mounted () {
    if (this.account && this.account.notes_exclusions.length > 0) {
      this.setCheckboxesBasedOnExclusions(this.account.notes_exclusions)
    } else {
      this.resetCheckboxesToDefault()
    }
    if (this.maxNoteCredentialValue) {
      this.noteLength = this.maxNoteCredentialValue.value
    }
  },
  props: ['account']
}
</script>
