<template>
<div>
  <div class="section">
    <div class="container">
      <!-- Page Header -->
      <div class="columns is-vcentered">
        <div class="column is-one-third">
          <breadcrumbs :history="[{ label: 'Settings', name: 'settings'}]" current="Terms & Conditions" :hideBack="true" />
        </div>
        <div class="column is-one-third">
          <h1 class="has-text-centered is-size-4">Supplier Terms &amp; Conditions</h1>
        </div>
      </div>
      <!-- List -->
      <div>
        <div class="has-background-white rounded-lg px-md py-sm mb-xxs">
          <div class="columns flex-end">
            <div class="column is-narrow">
              <b-button
                size="is-medium"
                class="is-fullwidth"
                icon-left="download"
                :disabled="results.length === 0"
                @click="showExportModal = !showExportModal"
              >Export</b-button>
            </div>
          </div>
        </div>
        <!-- Results Found -->
        <div v-if="!loading && results.length > 0">
          <b-table
            :data="results"
            :mobile-cards="false"
            :loading="loading"
            class="mb-lg rounded-lg is-size-7">
            <template>
              <b-table-column
                label="Supplier"
                v-slot="props">
                <p>
                  <router-link class="has-text-info has-text-weight-bold" :to="{ name: 'connection.detail', params: { id: props.row.connection.id } }">
                    <span>{{ props.row.connection.brand.name }}</span>
                  </router-link>
                </p>
                <p class="has-text-grey-light">
                  {{ app.platform }} Connection ID: {{ props.row.connection.id }}
                </p>
              </b-table-column>
              <b-table-column
                label="Supplier #"
                width="128"
                centered
                v-slot="props">
                <p>{{ props.row.connection.retailer_identifier }}</p>
              </b-table-column>
              <b-table-column
                label="Accepted"
                width="128"
                centered
                v-slot="props">
                <p v-if="props.row.tnc_accepted === 'Yes'" class="has-text-success has-text-weight-bold">Yes</p>
                <p v-else class="has-text-warning has-text-weight-bold">No</p>
              </b-table-column>
              <b-table-column
                label="Signatory"
                width="128"
                numeric
                v-slot="props">
                <div v-if="props.row.tnc_accepted === 'Yes'">
                  <p>{{ props.row.user_name }}</p>
                  <p>{{ props.row.user_email }}</p>
                </div>
                <div v-else>
                  <p class="has-text-grey-light">--</p>
                  <p class="has-text-grey-light">--</p>
                </div>
              </b-table-column>
            </template>
          </b-table>
        </div>
        <!-- None Found  -->
        <div v-else-if="!loading && results.length === 0">
          <div class="has-background-white has-text-grey-light has-text-centered py-3xl rounded-xl">
            <p><b-icon icon="magnify-close" size="is-large"></b-icon></p>
            <p>No documents found.</p>
            <p>Please check your filters and try again.</p>
          </div>
        </div>
        <!-- Loading -->
        <div v-else>
          <div class="has-background-white pa-lg mb-xs rounded-lg" v-for="index in 2" :key="index">
            <div class="placeholder placeholder-sentence"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal: Export  -->
  <b-modal
    has-modal-card
    scroll="keep"
    :active.sync="showExportModal"
    :can-cancel="['x']"
    ><div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Export</p>
      </header>
      <section class="modal-card-body">
        <p class="pa-xl has-text-grey has-text-centered">
          Click 'Export' below to have an export delivered to your
          email. Please note that the orders export will only include
          the items that match your applied filters. Please double check
          your filters before exporting.
        </p>
      </section>
      <footer class="modal-card-foot space-between">
        <b-button @click="showExportModal = false">Close</b-button>
        <b-button type="is-primary" @click="scheduleExport" :loading="exporting" :disabled="exporting">Export</b-button>
      </footer>
    </div>
  </b-modal>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  computed: {
    ...mapGetters(['app', 'context']),
    endpoint () {
      return this.context.route + '/connections/t-and-c-status/'
    },
    iframeUrl () {
      return this.document.content
    },
    isAcknowledged () {
      return (this.document && this.document.acknowledgements.length > 0)
    }
  },
  data () {
    return {
      loading: true,
      results: [],
      showExportModal: false,
      showDocumentModal: false,
      showIframe: false,
      document: null,
      exporting: false,
      saving: false,
      errors: {}
    }
  },
  methods: {
    load () {
      this.loading = true
      const query = {
        params: {
          orderBy: 'connecttion.brand.name'
        }
      }
      this.$http.get(this.endpoint, query).then(response => {
        this.results = response.data
        this.loading = false
      })
    },
    scheduleExport () {
      this.exporting = true
      const endpoint = this.context.route + '/connections/t-and-c-status-email-export/'
      this.$http.get(endpoint).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Export scheduled. Please allow 5-10 mintues for delivery.' })
        this.showExportModal = false
      }).finally(() => {
        this.exporting = false
      })
    },
    openDocumentModal (document) {
      this.document = document
      this.showDocumentModal = true
      this.showIframe = true
    },
    openDocument (document) {
      window.open(this.document.content, '_blank')
    },
    closeDocumentModal () {
      this.showDocumentModal = false
      this.document = null
      this.showIframe = false
    }
  },
  mounted () {
    this.load()
  },
  props: []
}
</script>
