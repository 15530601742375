<template>
  <InspectionModal
    :endpoint="endpoint"
    :loadDetail="loadDetail"
    :seed="selectedSupplier">
    <template v-slot:default="props">
      <div>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Inspect Supplier</p>
          </header>
          <section class="modal-card-body">
            <!-- Loaded -->
            <div v-if="!props.loading && props.record !== null" class="">
              <!-- Details -->
              <div class="mb-lg">
                <p class="has-text-weight-bold mb-sm">Supplier Details</p>
                <table class="table is-fullwidth is-striped is-narrow is-size-7 border">
                  <tbody>
                    <tr>
                      <th style="width:164px">ID</th>
                      <td>{{ props.record.id }}</td>
                    </tr>
                    <tr>
                      <th style="width:164px">Name</th>
                      <td>{{ props.record.name }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- Metadata -->
              <div class="mb-lg">
                <p class="has-text-weight-bold mb-sm">Supplier Metadata</p>
                <table class="table is-fullwidth is-striped is-narrow is-size-7 border">
                  <tbody>
                    <tr v-for="metadata in props.record.metadata" :key="metadata.id">
                      <th>{{ metadata.display_name }}</th>
                      <td>{{ metadata.values[0].value }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- Connections -->
              <div class="mb-lg">
                <p class="has-text-weight-bold mb-sm">Connections</p>
                <div v-if="!loadingConnections && connections.length > 0">
                  <table class="table is-fullwidth is-striped is-narrow is-size-7 border">
                    <tbody>
                      <tr v-for="connection in connections" :key="connection.id">
                        <td>{{ connection.retailer.name }}</td>
                        <td class="has-text-right">{{ $dates.utc(connection.created_at).local().format('MMM D, YYYY') }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div v-else-if="!loadingConnections && connections.length === 0">
                  <p class="has-text-centered has-text-grey-ligh py-md">No connections yet.</p>
                </div>
                <div v-else>
                  <p class="has-text-centered has-text-grey-ligh py-md">Loading connections...</p>
                </div>
              </div>
            </div>
            <!-- Loading -->
            <div v-else>
              <loading message="Loading supplier..." />
            </div>
          </section>
          <footer class="modal-card-foot">
            <button class="button" type="button" @click="$parent.close()">Close</button>
          </footer>
        </div>
      </div>
    </template>
  </InspectionModal>
</template>

<script>
import InspectionModal from '../InspectionModal'
import { mapGetters } from 'vuex'

export default {
  components: {
    InspectionModal
  },
  data () {
    return {
      loadDetail: true,
      loadingConnections: false,
      connections: []
    }
  },
  computed: {
    ...mapGetters(['api']),
    endpoint () {
      return this.api.baseUrl + '/v1/brands/' + this.selectedSupplier.id + '/'
    }
  },
  methods: {
    loadConnections () {
      this.loadingConnections = true
      this.$http.get(this.endpoint + 'connections/?short=1pagination=0&order_by=-id').then(response => {
        this.connections = response.data.results
        this.loadingConnections = false
      })
    }
  },
  mounted () {
    this.loadConnections()
  },
  props: ['selectedSupplier']
}
</script>
