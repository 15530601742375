<template>
  <div>
    <filter-toolbar
      :hasChanges="hasChanges"
      :appliedFilters="appliedFilters"
      @search="search"
      @update="update"
      @clear="clear">
      <facet-filter
        class="border-b"
        v-model="filters.facets.status"
        :filter="filterOptions.status"
        @input="change"
      />
      <facet-filter
        v-model="filters.facets.integration_type"
        :filter="filterOptions.integrations"
        @input="change"
      />
    </filter-toolbar>
  </div>
</template>

<script>
import * as filterOptions from '../../filters/connections.js'
import FilterToolbar from '@/components/Filters/FilterToolbar'
import FacetFilter from '@/components/Filters/FacetFilter'

export default {
  components: {
    FacetFilter,
    FilterToolbar
  },
  data () {
    return {
      hasChanges: false,
      filters: {
        preset: 'all',
        facets: {},
        search: {},
        timestamps: {}
      },
      // filters
      filterOptions: filterOptions
    }
  },
  computed: {

  },
  methods: {
    change () {
      this.hasChanges = true
    },
    search (searchQuery) {
      this.filters.search = {
        parameter: 'search',
        query: searchQuery
      }
      this.$emit('filter', this.filters)
    },
    update () {
      this.hasChanges = false
      this.filters.preset = 'custom'
      this.$emit('filter', this.filters)
    },
    clear () {
      this.filters = {
        preset: 'all',
        facets: {},
        search: {},
        timestamps: {}
      }
      this.$emit('filter', this.filters)
    }
  },
  mounted () {

  },
  props: {
    appliedFilters: {
      type: Object,
      required: true
    }
  },
  watch: {
    appliedFilters: {
      deep: true,
      immediate: true,
      handler (filters) {
        this.filters = filters
      }
    }
  }
}
</script>
