<template>
  <div class="section">
    <div class="container">
      <!-- Page Header -->
      <div class="mb-lg ">
        <div class="columns is-mobile is-vcentered">
          <div class="column">
            <h1 class="is-size-4">Set Costs & Pricing</h1>
          </div>
          <div class="column">
            <p class="has-text-right"><router-link :to="{ name: 'network.dashboard' }">&lt; Back to Network</router-link></p>
          </div>
        </div>
      </div>
      <div class="mb-lg pb-lg has-border-b">
        <div class="columns">
          <div class="column is-one-fifth">
            <p class="has-text-weight-bold is-size-6 mb-sm">Step 1: Download Template</p>
            <p class="has-text-grey">Download a pricing template to import your pricing into {{ app.platform }}.</p>
          </div>
          <div class="column">
            <div class="has-background-white rounded-xl pa-xl">
              <p class="mb-lg">
                The easiest way to update <u>ALL</u> of your costs & MAPs across
                the {{ app.platform }} Commerce Network is to upload a simple pricing template.
                The template requires 3 columns. Click on a column to learn more.
              </p>
              <div class="mb-xl">
                <!-- Item SKU -->
                <div class="has-background-white-ter pa-md rounded-lg mb-sm">
                  <b-collapse :open="false">
                    <a href="#" @click.prevent slot="trigger">
                      <div class="columns is-vcentered is-mobile">
                        <div class="column">
                          <span class="has-text-weight-bold">Item SKU</span>
                        </div>
                        <div class="column is-narrow">
                          <p>Learn More</p>
                        </div>
                      </div>
                    </a>
                    <div class="pt-sm">
                      Your unique identifier for the the item.
                    </div>
                  </b-collapse>
                </div>
                <!-- Dropship Cost -->
                <div class="has-background-white-ter pa-md rounded-lg mb-sm">
                  <b-collapse :open="false">
                    <a href="#" @click.prevent slot="trigger">
                      <div class="columns is-vcentered is-mobile">
                        <div class="column">
                          <span class="has-text-weight-bold">Dropship Cost</span>
                        </div>
                        <div class="column is-narrow">
                          <a href="#" >Learn More</a>
                        </div>
                      </div>
                    </a>
                    <div class="pt-sm">
                      In the Commerce Network, suppliers pay upfront
                      for shipping labels, but are later reimbursed for the shipping
                      & handling fee set per item. The "Dropship Cost" represents
                      your "all-in" cost to sell <u>AND</u> ship this item anywhere
                      via domestic ground shipping. This cost Should also include any
                      order processing fees (sometimes added by 3PLs).
                    </div>
                  </b-collapse>
                </div>
                <!-- MAP -->
                <div class="has-background-white-ter pa-md rounded-lg mb-sm">
                  <b-collapse :open="false">
                    <a href="#" @click.prevent slot="trigger">
                      <div class="columns is-vcentered is-mobile">
                        <div class="column">
                          <span class="has-text-weight-bold">MAP</span>
                        </div>
                        <div class="column is-narrow">
                          <a href="#" >Learn More</a>
                        </div>
                      </div>
                    </a>
                    <div class="pt-sm">
                      The minimum advertised price (or "MAP") is the lowest price a
                      merchant can advertise the product for sale. {{ app.platform }}
                      rejects any orders from merchants that are sold below
                      your MAP.
                    </div>
                  </b-collapse>
                </div>
                <!-- Final Instructions -->
                <div class="py-lg">
                  <p class="mb-md">
                    If this is your first time or you want to review or change
                    all of your skus, you might want to export your entire SKU
                    & pricing list first, make your changes, and then upload
                    the file.
                  </p>
                  <p class="has-text-danger">
                    Please note: setting or updating costs and prices only
                    affects new relationships. To publish changes to existing
                    relationships, use the Cost Update tool.
                  </p>
                </div>
                <div class="mb-sm">
                  <div class="columns is-centered">
                    <div class="column is-half-tablet is-one-third-desktop">
                      <form @submit.prevent="exportVariants">
                        <b-button native-type="submit" type="is-primary rounded-lg" size="is-medium" :disabled="exporting" :loading="exporting" expanded>Email My Existing Data</b-button>
                      </form>
                    </div>
                    <div class="column is-half-tablet is-one-third-desktop">
                      <a :href="assets.templates.import.pricing.sample" class="button is-medium is-fullwidth is-primary is-outlined rounded-lg" download>Download Blank Template</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Step 2 -->
      <div class="mb-lg pb-lg">
        <div class="columns">
          <div class="column is-one-fifth">
            <p class="has-text-weight-bold is-size-6 mb-sm">Step 2: Upload File</p>
            <p class="has-text-grey">Select and upload your pricing updated pricing file.</p>
          </div>
          <div class="column">
            <div class="has-background-white rounded-xl">
              <div class="pa-xl">
                <!-- Upload -->
                <CoreUpload @file:uploaded="updateTempfile" @file:reset="resetTempfile"/>
              </div>
              <div class="has-border-t">
                <div class="columns is-vcentered is-marginless">
                  <div class="column">
                    <div class="px-sm has-text-grey-light">
                      <i v-if="tempfile !== null">Click 'Import File' to upload your file for processing.</i>
                    </div>
                  </div>
                  <div class="column is-narrow">
                    <div class="px-sm">
                      <b-button native-type="submit" type="is-primary" :disabled="scheduling || tempfile === null">Import File</b-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CoreUpload from '@/components/CoreUpload'

export default {
  components: {
    CoreUpload
  },
  computed: {
    ...mapGetters(['app', 'assets', 'context']),
    endpoints () {
      return {
        queue: this.context.route + '/feed-queue/enqueue/'
      }
    }
  },
  data () {
    return {
      tempfile: null,
      scheduling: false,
      exporting: false
    }
  },
  methods: {
    queueFile () {
      this.scheduling = true
      this.$http.post(this.endpoints.queue, this.json).then(response => {
        this.scheduling = false
        this.$buefy.toast.open({ type: 'is-success', message: 'File scheduled!' })
      })
    },
    updateTempfile (file, response) {
      this.tempfile = response
    },
    resetTempfile () {
      this.tempfile = null
    },
    exportVariants () {
      const endpoint = this.context.route + '/variants/export/'
      const json = {
        params: {
          template_id: this.assets.templates.export.pricing.id
        }
      }
      this.exporting = true
      this.$http.get(endpoint, json).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Export scheduled!' })
      }).finally(() => {
        this.exporting = false
      })
    }
  }
}
</script>
