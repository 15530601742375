<template>
  <form @submit.prevent>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Create Ruleset</p>
      </header>
      <section class="modal-card-body">
        <div class="pb-md">
          <p>To create a custom ruleset, please complete the steps below.</p>
          <RulesetForm v-model="json" @is:ready="isReady"/>
        </div>
        <!-- Results -->
        <div>
        </div>
      </section>
      <footer class="modal-card-foot space-between">
        <b-button @click.prevent="$parent.close()">Close</b-button>
        <b-button
          :disabled="saving || !isDirty"
          :loading="saving"
          @click.prevent="createRuleset"
          >
          Create
        </b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import RulesetForm from '@/components/Models/Ruleset/Form'
export default {
  components: {
    RulesetForm
  },
  computed: {
    ...mapGetters(['app', 'api', 'context']),
    endpoint () {
      return this.context.route + '/compatibility/rulesets/'
    }
  },
  data () {
    return {
      json: null,
      saving: false,
      errors: {},
      isDirty: false
    }
  },
  methods: {
    createRuleset () {
      this.saving = true
      this.$http.post(this.endpoint, this.json).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Ruleset created successfully' })
        this.$emit('reload')
      }).catch(error => {
        if (error.response && error.response.status === 400) {
          this.errors = error.response.data
        }
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    },
    isReady (val) {
      this.isDirty = val
    }
  },
  mounted () {
  }
}
</script>
