<template>
  <form @submit.prevent="save">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Cancel Order</p>
      </header>
      <section class="modal-card-body">
        <b-message type="is-danger">
          <strong>Please Note</strong>: when you click submit, your cancelation
          request will be processed immediately and communications may be sent
          to the retailer to inform them of the cancelation.
          <strong>This action cannot be reversed!</strong>
        </b-message>
        <!-- Reason -->
        <div class="mb-md">
          <p class="has-text-weight-bold mb-xs">Cancel Reason</p>
          <b-field
            expanded>
            <b-select v-model="reason" placeholder="Please select a reason..."  expanded>
              <template v-if="context.isSupplier()">
                <option value="2">Not Enough Inventory</option>
                <option value="1">Retailer Requested Cancellation</option>
                <option value="5">Unfulfillable Address</option>
                <option value="10">Incorrect Product Information</option>
                <option value="8">Price Discrepancy</option>
                <option value="9">Product Discontinued</option>
                <option value="6">Suspected Fraud</option>
              </template>
              <template v-if="context.isMerchant()">
                <option value="1">Customer Requested Cancellation</option>
                <option value="6">Suspected Fraud</option>
              </template>
            </b-select>
          </b-field>
        </div>
        <!-- Reason -->
        <div v-if="reason === '2'" class="mb-md">
          <p class="has-text-weight-bold mb-xs">Estimated Return Date</p>
          <p class="has-text-grey is-size-7 mb-md">
            Since you are cancelling this order due to no stock being available,
            please enter a date when you expect the items to return to stock.
            If the items have been discontinued, please update the status
            of the item in the Catalog manager.
          </p>
          <b-field expanded>
            <b-input type="date" v-model="returnDate"></b-input>
          </b-field>
        </div>
        <div class="has-background-white px-lg py-md mb-xxs">
          <div class="columns is-mobile is-vcentered">
            <div class="column">
              <p class="has-text-weight-bold">Line Items</p>
            </div>
            <div class="column">
              <p class="has-text-right"><a href="#" @click.prevent="updateCancelAll">Cancel All</a></p>
            </div>
          </div>
          <CancelLine
            v-for="line in order.order_lines"
            :line="line"
            :key="line.id"
            :cancelAll="cancelAll[line.id]"
            :isDirty="isDirty"
            @update:line="updateLine"
          >
          </CancelLine>
      </div>
      </section>
      <footer class="modal-card-foot space-between">
        <button class="button" type="button" @click="$parent.close()">Close</button>
        <b-button
          type="is-primary"
          native-type="submit"
          :loading="saving"
          :disabled="!ready || saving"
        >Cancel Items</b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import CancelLine from './CancelLine'
export default {
  components: {
    CancelLine
  },
  data () {
    return {
      reason: null,
      saving: false,
      returnDate: null,
      cancelAll: {},
      isDirty: false,
      lines: {}
    }
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/cancels/'
    },
    elligible () {
      return true
    },
    json () {
      const json = {
        order_id: this.order.id,
        reason_id: (this.reason) ? parseInt(this.reason) : null,
        cancel_lines: []
      }
      for (const id in this.lines) {
        const cancelLine = {
          variant: { id: this.lines[id].variant.id },
          quantity: this.lines[id].quantity
        }
        if (this.lines[id].brand_identifier) {
          cancelLine.brand_identifier = this.lines[id].brand_identifier
        }
        if (this.reason === '2' && this.returnDate) {
          cancelLine.estimated_availability_date = this.returnDate
        }
        json.cancel_lines.push(cancelLine)
      }
      return json
    },
    ready () {
      if (this.reason === '2' && this.returnDate === null) return false
      return this.elligible && this.reason !== null && Object.keys(this.lines).length > 0
    }
  },
  methods: {
    save () {
      this.saving = true
      this.$http.post(this.endpoint, this.json).then(response => {
        let isFullyCanceled = true
        for (let index = 0; index < this.order.order_lines.length; index++) {
          if (this.order.order_lines[index].quantity_shipped > 0) {
            isFullyCanceled = false
            break
          }
          if (this.order.order_lines[index].quantity_open > 0) {
            const cancelLine = response.data.cancel_lines.find(cancelLine => cancelLine.variant.id === this.order.order_lines[index].variant.id)
            if (cancelLine === undefined || cancelLine.quantity < this.order.order_lines[index].quantity_open) {
              isFullyCanceled = false
              break
            }
          }
        }
        this.$parent.close()
        this.$emit('order:canceled')
        this.$buefy.toast.open({ type: 'is-success', message: isFullyCanceled ? 'Order Canceled.' : 'Cancel Created.' })
      }).catch(error => {
        let errorMessage = 'Your attempt to cancel this order failed. Please refresh and try again.'
        if (error.response && error.response.data.detail) {
          errorMessage = error.response.data.detail
        }
        this.$buefy.toast.open({ type: 'is-danger', message: errorMessage })
      }).finally(() => {
        this.saving = false
      })
    },
    updateLine (payload) {
      if (this.cancelAll[payload.lineId]) {
        this.$set(this.cancelAll, payload.lineId, false)
      }
      this.isDirty = true
      if (payload.quantity > 0) {
        const line = {
          variant: {
            id: payload.variantId
          },
          brand_identifier: payload.brandIdentifier,
          quantity: payload.quantity
        }
        this.$set(this.lines, payload.lineId, line)
      } else {
        this.$delete(this.lines, payload.lineId)
      }
    },
    updateCancelAll () {
      Object.keys(this.cancelAll).forEach(key => {
        if (!this.cancelAll[key]) {
          this.cancelAll[key] = true
        }
      })
    }
  },
  mounted () {
    this.order.order_lines.forEach(line => {
      this.$set(this.cancelAll, line.id, false)
    })
  },
  props: ['order'],
  watch: {
    reason (value) {
      if (value !== '1') this.returnDate = null
    }
  }
}
</script>
