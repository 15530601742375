<template>
  <div class="columns">
    <div class="column">
      <div v-if="!loading">
        <div v-if="accountTags.length > 0">
          <b-field label="Select tags">
            <b-taginput
              v-model="selectedTags"
              :data="filteredTags"
              autocomplete
              :allow-new="false"
              :open-on-focus="true"
              field="name"
              icon="label"
              type="is-info"
              placeholder="Select tags"
              @typing="getFilteredTags"
              @input="selectedTagsUpdated">
            </b-taginput>
          </b-field>
        </div>
        <div v-else>
          <div class="has-background-white has-text-grey-light has-text-centered py-xs rounded-xl">
            <p><b-icon icon="magnify-close" size="is-large"></b-icon></p>
            <p>Your Shipstation account does not have any tags.</p>
          </div>
        </div>
      </div>
      <div v-else class="has-background-white-ter rounded-lg">
        <p class="has-text-grey-light is-size-8 pa-md">Loading...</p>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/shipstation/' + this.account.id + '/account-tags/'
    }
  },
  data () {
    return {
      loading: true,
      accountTags: [],
      filteredTags: [],
      selectedTags: []
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint).then(response => {
        this.accountTags = response.data
        this.filteredTags = response.data
        this.selectedTags = this.accountTags.filter(tag => this.currentTagIds.indexOf(tag.tagId) >= 0)
      }).finally(() => {
        this.loading = false
      })
    },
    getFilteredTags (text) {
      this.filteredTags = this.accountTags.filter((option) => {
        return option.name.toLowerCase().indexOf(text.toLowerCase()) >= 0
      })
    },
    selectedTagsUpdated (value) {
      this.$emit('update:tags', value)
    }
  },
  mounted () {
    this.load()
  },
  props: {
    account: {
      type: Object
    },
    currentTagIds: {
      type: Array
    }
  }
}
</script>
