<template>
<div class="pa-md">
  <p class="has-text-centered has-text-weight-bold mb-lg is-size-5">Welcome to {{ app.productName }}!</p>
  <div class="columns is-centered">
    <div class="column is-two-fifths">
      <div class="box">
        <div class="has-text-grey">
          <p class="mb-md has-text-weight-bold has-text-centered has-text-success is-size-5">Thank you!</p>
          <!-- Call Date -->
          <div v-if="startTime" class="has-text-centered mb-lg">
            <p>We're looking forward to our call currently set for:</p>
            <p class="has-text-weight-bold is-size-4">{{ startTime }}</p>
          </div>
          <!-- Content -->
          <div class="content has-text-centered">
            <p class="">
              If you need to change the date or time of the call, please file a
              <a href="#" @click.prevent="$store.dispatch('supportRequest')" class="has-text-info">support request</a>.
            </p>
            <p class="">
              If you would like to learn more ahead of the
              call, please visit our
              <a :href="app.supportUrl" target="_blank" class="has-text-info has-text-weight-bold">Knowledge Base!</a>
              You may close this window now (or feel free to explore the Supplier Portal!)</p>
            <p>Have a great day!</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['app', 'context']),
    startTime () {
      if (this.$route.query.event_start_time) {
        const start = this.$dates.utc(this.$route.query.event_start_time)
        if (start.isValid()) {
          return start.local().format('MMM D, YYYY @ h:mm A')
        }
      }
      return null
    }
  },
  data () {
    return {
      loading: true,
      brand: null,
      step: 'Intro'
    }
  },
  methods: {
    navigate (step) {
      this.step = step
    },
    // get all connections of status onboarding
    loadConnections () {
      const endpoint = this.context.route + '/connections/?status=onboarding'
      this.$http.get(endpoint).then(response => {
        if (this.$route.query.event_start_time && response.data.results.length) {
          const connections = response.data.results
          // loop through each connection and post the event_start_time date as intro_call_date
          connections.map(el => this.addIntroCallDate(el.id))
        }
      })
    },
    addIntroCallDate (connectionId) {
      const endpoint = this.context.route + `/connections/${connectionId}/`
      const data = {
        intro_call_date: this.$dates(this.$route.query.event_start_time).format('YYYY-MM-DDTHH:mm:ssZ[Z]')
      }
      this.$http.patch(endpoint, data).then(response => {
      })
    },
    load (silent = false) {
      if (!silent) this.loading = true
      const endpoint = this.context.route + '/'
      this.$http.get(endpoint).then(response => {
        this.brand = response.data
        this.loading = false
      })
    }
  },
  mounted () {
    this.load()
    this.loadConnections()
  },
  props: ['connection', 'task']
}
</script>
