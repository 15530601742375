<template>
<form @submit.prevent="submitRequest">
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Product Onboarding Support Request</p>
    </header>
    <section class="modal-card-body">
      <!-- Message -->
      <div class="mb-lg">
        <b-field
          label="Message"
          :type="('message' in errors) ? 'is-danger' : ''"
          :message="('message' in errors) ? errors['message'][0] : ''">
          <b-input
            type="textarea"
            v-model="message"
            size="is-medium"
          ></b-input>
        </b-field>
      </div>
      <!-- File Upload -->
      <div class="mt-lg">
        <div class="mb-lg">
          <p class="has-text-primary has-text-weight-bold">Do you have a different  source product data file?</p>
          <p class="has-text-grey-light is-size-7 mb-md">
            If you have a source product file that is different from the template
            you're trying to upload, please upload it here. Our data specialists are happy
            to assist with data mapping and attribute review!
          </p>
          <CoreUpload @file:uploaded="updateTempfile" @file:reset="resetTempfile"/>
        </div>
      </div>
    </section>
    <footer class="modal-card-foot space-between">
      <button class="button" type="button" @click="$parent.close()">Close</button>
      <b-button
        type="is-primary"
        native-type="submit"
        :loading="saving"
        :disabled="!ready || saving">
        Submit Request
      </b-button>
    </footer>
  </div>
</form>
</template>

<script>
import { mapGetters } from 'vuex'
import CoreUpload from '@/components/CoreUpload'

export default {
  components: {
    CoreUpload
  },
  computed: {
    ...mapGetters(['app', 'auth', 'context']),
    ready () {
      return (this.message)
    },
    postScript () {
      const context = this.context.label + ': ' + this.context.partner.name + ' (' + this.context.partner.id + ')'
      const user = 'User: ' + this.auth.user.fullName
      const route = 'Route: ' + this.app.url + this.$route.fullPath
      const proposal = 'Proposal: ' + this.proposal.id
      return '\n\n' + proposal + '\n' + context + '\n' + user + '\n' + route
    },
    json () {
      const json = {
        group_id: 6313351708433,
        subject: 'Data Onboarding: ' + this.context.partner.name + ' needs help with proposal #' + this.proposal.id,
        priority: 'High',
        body: this.message + this.postScript,
        attachments: []
      }
      if (this.tempfile) {
        json.attachments.push({ tempfile_id: this.tempfile.id })
      }
      return json
    }
  },
  data () {
    return {
      message: null,
      tempfile: null,
      saving: false,
      errors: {}
    }
  },
  methods: {
    submitRequest () {
      this.saving = true
      const endpoint = this.context.route + '/zendesk/create-ticket/'
      this.$http.post(endpoint, this.json).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Request submitted!' })
        this.$parent.close()
      }).catch(() => {
        this.$buefy.toast.open({ type: 'is-danger', message: 'There was a problem submitting your request. Please check your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    },
    updateTempfile (file, response) {
      this.tempfile = response
    },
    resetTempfile () {
      this.tempfile = null
    }
  },
  props: {
    proposal: {
      type: Object,
      required: true
    }
  }
}
</script>
