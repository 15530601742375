<template>
  <InspectionModal :endpoint="endpoint" :loadDetail="loadDetail" :seed="selectedFeed">
    <template v-slot:default="props">
      <form>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Inspect Feed</p>
          </header>
          <section class="modal-card-body">
            <!-- Loaded -->
            <div v-if="!props.loading && props.record !== null" class="">
              <!-- Feed Details -->
              <div class="mb-lg">
                <div class="columns">
                  <p class="has-text-weight-bold column pb-0">Feed Details</p>
                  <div v-if="props.record.status === 'queued'" class="is-narrow column">
                    <b-button
                      type="is-primary"
                      size="is-small"
                      :loading="runningFeed"
                      :disabled="runningFeed"
                      @click="enqueue"
                    >Process Feed</b-button>
                  </div>
                </div>
                <ImportSummary :feedDetail="props.record"/>
              </div>
            </div>
            <!-- Loading -->
            <div v-else>
              <loading message="Loading..." />
            </div>
          </section>
          <footer class="modal-card-foot">
            <button class="button" type="button" @click="$parent.close()">Close</button>
          </footer>
        </div>
      </form>
    </template>
  </InspectionModal>
</template>

<script>
import InspectionModal from '../InspectionModal'
import { mapGetters } from 'vuex'
import ImportSummary from '@/components/Models/FeedQueue/Presenters/ImportSummary'

export default {
  components: {
    InspectionModal,
    ImportSummary
  },
  data () {
    return {
      loadDetail: true,
      runningFeed: false
    }
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      if (this.selectedFeed !== null) {
        const path = this.context.route + '/feed-queue/' + this.selectedFeed.id + '/'
        return path
      }
      return null
    }
  },
  methods: {
    enqueue () {
      this.runningFeed = true
      const endpoint = this.endpoint + 'run/'
      this.$http.put(endpoint).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Feed queued!' })
        this.$parent.close()
      }).finally(() => {
        this.runningFeed = false
      })
    },
    getJobMetadataFromKey (row, key, onlyValue) {
      let job = null
      if (row.length && row[0].metadata.length) {
        job = row[0].metadata.find(el => el.name === key)
      }
      if (job && job.value && onlyValue) {
        return job.value
      } else {
        return job !== null && job !== undefined
      }
    }
  },
  props: ['selectedFeed']
}
</script>
