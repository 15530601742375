<template>
  <div>
    <div v-if="!loading && carriers.length > 0">
      <div class="columns is-vcentered">
        <div class="column">
          <b-select
            v-model="carrier"
            placeholder="Select a Carrier"
            size="is-medium"
            expanded>
            <option
              v-for="carrier in carriers"
              :value="carrier"
              :key="carrier"
            >
              {{ carrier }}
            </option>
          </b-select>
        </div>
        <div class="column is-narrow">
          <a href="#" @click.prevent="load">Refresh</a>
        </div>
      </div>
    </div>
    <div v-else-if="!loading && carriers.length === 0">
      <p>No Carriers Found!</p>
    </div>
    <!-- Loading -->
    <div v-else>
      <div class="columns is-vcentered">
        <div class="column">
          <b-select
            placeholder="Communicating with Shopify..."
            size="is-medium"
            expanded
            disabled
          ></b-select>
        </div>
        <div class="column is-narrow">
          <a href="#" @click.prevent class="has-text-grey-lighter">Refresh</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/shopify/' + this.account.id + '/carriers/'
    }
  },
  data () {
    return {
      loading: false,
      carriers: [],
      carrier: null
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint).then(response => {
        this.carriers = response.data.carriers
      }).catch((error) => {
        if (error.response.status === 401) {
          this.$buefy.toast.open({ type: 'is-danger', message: 'Invalid API key or access token to fetch carriers.' })
        }
      }).finally(() => {
        this.loading = false
      })
    }
  },
  mounted () {
    this.carrier = this.carrierName
    this.load()
  },
  props: {
    account: Object,
    carrierName: String
  },
  watch: {
    carrier (value) {
      if (value !== null) {
        this.$emit('input', value)
      }
    }
  }
}
</script>
