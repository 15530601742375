<template>
  <div class="section">
    <div class="container">
      <!-- Page Header -->
      <div class="mb-md">
        <div class="columns is-vcentered">
          <div class="column is-one-third">
            <breadcrumbs :history="[{ label: 'Settings', name: 'settings'}]" current="Shipping Accounts" />
          </div>
          <div class="column is-one-third">
            <h1 class="has-text-centered is-size-4">Shipping Accounts</h1>
          </div>
        </div>
      </div>
      <!-- Filters Toolbar -->
      <div class="has-background-white rounded-lg pa-md mb-xxs">
        <div class="columns">
          <div class="column">
            <b-select v-model="orderBy" size="is-medium" expanded @input="sort">
              <option value="-id">Most Recent</option>
              <option value="id">Oldest</option>
            </b-select>
          </div>
          <div class="column is-narrow">
            <b-button
              size="is-medium"
              @click="showCreateShippingAccountModal = true">Add Shipping Account</b-button>
          </div>
        </div>
      </div>
      <!-- Results -->
      <div>
        <div class="mb-md">
          <b-table
            :data="results"
            :checked-rows.sync="selected"
            :mobile-cards="false"
            :loading="loading"
            hoverable
            checkable
            checkbox-position="left"
            class="is-size-7">
            <template>
              <!-- RC ID -->
              <b-table-column
                label="ID"
                header-class="nowrap"
                cell-class="nowrap"
                width="64"
                v-slot="props">
                {{ props.row.id }}
              </b-table-column>
              <!-- Nickname -->
              <b-table-column
                label="Nickname"
                header-class="nowrap"
                cell-class="nowrap"
                width="164"
                v-slot="props">
                <a href="#" class="has-text-weight-bold has-text-info" @click="edit(props.row)">
                  {{ props.row.nickname }}
                </a>
              </b-table-column>
              <!-- Carrier -->
              <b-table-column
                label="Carrier"
                header-class="nowrap"
                cell-class="nowrap"
                width="128"
                v-slot="props">
                {{ props.row.carrier.name }}
              </b-table-column>
              <!-- Account # -->
              <b-table-column
                label="Account #"
                header-class="nowrap"
                cell-class="nowrap"
                v-slot="props">
                {{ props.row.bill_third_party_account }}
              </b-table-column>
              <!-- Shipping Methods -->
              <b-table-column
                label="# Methods"
                header-class="nowrap"
                cell-class="nowrap"
                width="196"
                centered
                v-slot="props">
                {{ props.row.shipping_methods.length }}
              </b-table-column>
            </template>
            <template #empty>
              <div class="py-xxl has-text-grey has-text-centered">
                {{ (loading) ? "Loading data..." : 'No shipping accounts found!' }}
              </div>
            </template>
          </b-table>
        </div>
        <b-pagination
          :total="total"
          :current.sync="currentPage"
          :rounded="true"
          :per-page="perPage"
          @change="paginate">
        </b-pagination>
      </div>
      <!-- Create Shipping Account Wizard Modal -->
      <b-modal
        :active.sync="showCreateShippingAccountModal"
        :can-cancel="['escape', 'x']"
        has-modal-card
        scroll="keep"
        @close="account = null">
        <CreateShippingAccountModal @updated="load" />
      </b-modal>
      <!-- Edit Shipping Account Modal -->
      <b-modal
        :active.sync="showEditShippingAccountModal"
        :can-cancel="['escape', 'x']"
        has-modal-card
        scroll="keep"
        @close="account = null">
        <EditShippingAccountModal :account="account" @updated="load" />
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CreateShippingAccountModal from '../components/ShippingAccounts/CreateShippingAccountModal'
import EditShippingAccountModal from '../components/ShippingAccounts/EditShippingAccountModal'
import Filterable from '@/mixins/Filterable'

export default {
  components: {
    CreateShippingAccountModal,
    EditShippingAccountModal
  },
  data () {
    return {
      key: 'shipping-accounts',
      loading: true,
      accounts: [],
      account: null,
      showCreateShippingAccountModal: false,
      showEditShippingAccountModal: false
    }
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/shippingaccounts/'
    }
  },
  methods: {
    edit (account) {
      this.account = account
      this.showEditShippingAccountModal = true
    }
  },
  mixins: [Filterable],
  mounted () {
    this.load()
  },
  props: []
}
</script>
