<template>
  <div>
    No Actions
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  data () {
    return {

    }
  },
  computed: {
    ...mapGetters(['context'])
  },
  methods: {

  },
  mounted () {

  },
  props: []
}
</script>
