<template>
  <form @submit.prevent="update" novalidate autocomplete="off">
    <div class="columns">
      <div class="column is-one-fifth">
        <p class="has-text-weight-bold is-size-6 mb-sm">Product Identification</p>
        <p class="has-text-grey">Update general information about your product.</p>
      </div>
      <div class="column">
        <div class="has-background-white rounded-xl">
          <!-- Loaded -->
          <div class="pa-lg pb-xl">
            <b-field label="Product SKU">
              <p>{{ parent_identifier }}</p>
            </b-field>
            <b-field
              label="Name"
              :type="('parent_name' in errors) ? 'is-danger' : ''"
              :message="('parent_name' in errors) ? errors['parent_name'][0] : ''"
              expanded>
              <b-input
                autocomplete="off"
                type="text"
                name="name"
                v-model="parent_name"
                @input="isDirty = true"
              ></b-input>
            </b-field>
            <b-field
              label="Category"
              :type="('brand_category' in errors) ? 'is-danger' : ''"
              :message="('brand_category' in errors) ? errors['brand_category'][0] : ''"
              expanded>
              <b-input
                autocomplete="off"
                type="text"
                name="brand_category"
                v-model="brand_category"
                @input="isDirty = true"
              ></b-input>
            </b-field>
          </div>
          <div class="border-t">
            <div class="columns is-vcentered is-marginless">
              <div class="column">
                <div class="px-sm has-text-grey-light">
                  <i v-if="isDirty">Click 'Update' to save your changes</i>
                </div>
              </div>
              <div class="column is-narrow">
                <div class="px-sm">
                  <b-button native-type="submit" type="is-primary rounded-lg" :disabled="updating || !isDirty">Update</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/products/' + this.$route.params.id + '/'
    },
    json () {
      return {
        name: this.parent_name,
        brand_category: this.brand_category
      }
    }
  },
  data () {
    return {
      isDirty: false,
      parent_name: null,
      parent_identifier: null,
      brand_category: null,
      errors: [],
      updating: false
    }
  },
  methods: {
    update () {
      this.updating = true
      this.$http.patch(this.endpoint, this.json).then(response => {
        this.isDirty = false
        this.$emit('updated:product', response.data)
        this.$buefy.toast.open({ type: 'is-success', message: 'Product updated!' })
      }).catch(error => {
        if (error.response && error.response.status === 400) {
          this.errors = error.response.data
        }
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.updating = false
      })
    }
  },
  created () {
    this.parent_name = this.product.parent_name
    this.parent_identifier = this.product.parent_identifier
    this.brand_category = this.product.brand_category
  },
  props: ['product']
}
</script>
