<template>
  <div>
    <!-- Content -->
    <div class="">
      <div>
        <AddressForm
          v-model="location"
          :addressForm="location"
          type="billing"
          defaultNickname="Business Address"
          addressNameLabel="Legal Business Name"
          :errors="errors"
          :requireNickname="false"
          :requireAddressName="true"
          :requireEmail="false"
          :requirePhone="false"
          :defaultAddressName="context.partner.name"
          @is:ready="isReady"
        />
      </div>
    </div>
    <!-- Navigate -->
    <div class="py-lg">
      <div class="columns is-centered">
        <div class="column">
          <b-button
            type="is-light rounded-lg"
            size="is-medium"
            @click="goBack"
            expanded
            ><b-icon icon="arrow-left"></b-icon>
          </b-button>
        </div>
        <div class="column">
          <b-button
            type="is-primary rounded-lg"
            size="is-medium"
            expanded
            :disabled="!isValidated || saving"
            :loading="saving"
            @click="goForward"
          >Continue</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AddressForm from '@/components/Global/AddressForm'

export default {
  components: {
    AddressForm
  },
  computed: {
    ...mapGetters(['app', 'context'])
  },
  data () {
    return {
      location: null,
      saving: false,
      errors: {},
      isValidated: false,
      tasks: []
    }
  },
  methods: {
    goBack () {
      this.$router.push({ name: 'onboarding.tasks', params: { connection: this.connection.id } })
    },
    async goForward () {
      const method = (this.location.id) ? 'patch' : 'post'
      const endpoint = (this.location.id)
        ? this.context.route + '/locations/' + this.location.id + '/'
        : this.context.route + '/locations/'
      this.saving = true
      if (this.connection.level === 'products_only') {
        await this.addTask('terms-and-conditions')
      }
      if (this.connection.level === 'transactions_and_products') {
        await this.addTask('integration')
        await this.addTask('notifications')
      }
      this.$http[method](endpoint, this.location).then(response => {
        this.completeTask()
      }).catch(error => {
        if (error.response && error.response.status === 400) {
          this.errors = error.response.data
        }
        this.saving = false
      })
    },
    addTask (taskCode) {
      const task = this.$utils.find(this.tasks, ['code', taskCode])
      if (typeof task !== 'undefined') {
        const endpoint = this.context.route + '/assigned-tasks/'
        const json = { onboarding_task: { id: task.id } }
        return this.$http.post(endpoint, json)
      }
    },
    completeTask () {
      const endpoint = this.context.route + '/assigned-tasks/' + this.$route.params.task + '/complete/'
      return this.$http.put(endpoint).then(() => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Task Completed!' })
        this.$router.push({ name: 'onboarding.tasks', params: { connection: this.connection.id } })
      })
    },
    isReady (val) {
      this.isValidated = val
    },
    loadTasks () {
      const endpoint = this.context.route + '/onboarding-tasks/?type=conditional'
      this.$http.get(endpoint).then(response => {
        this.tasks = response.data.results
      })
    }
  },
  mounted () {
    this.loadTasks()
  },
  props: {
    connection: {
      type: Object,
      required: true
    },
    task: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: true
    },
    existingLocation: {
      type: Object,
      default: null
    }
  },
  watch: {
    existingLocation: {
      handler (location) {
        if (location) {
          this.location = this.existingLocation
        }
      },
      // force eager callback execution
      immediate: true
    }
  }
}
</script>
