<template>
  <div>
    <div class="mb-lg">
      <p class="has-text-weight-bold">Account Profile</p>
      <p class="has-text-grey">Verify and complete all account information.</p>
    </div>
    <!-- Error -->
    <div v-if="('general' in errors)" class="mb-md">
      <p class="has-text-danger">{{ errors.general }}</p>
    </div>
    <!-- Fields -->
    <div class="mt-md mb-xl">
      <b-field label="Retailer Name" custom-class="required">
        <b-input
          placeholder="Store Name"
          v-model="name"
          size="is-medium"
          @input="announce('name')"
        ></b-input>
      </b-field>
      <b-field label="Wesbite URL" custom-class="required"
        :type="{ 'is-danger': errors.website }"
        :message="{ 'Invalid URL': errors.website }">
        <b-input
          placeholder="Enter your the URL for you website."
          v-model="website"
          size="is-medium"
          @input="announce('website')"
        ></b-input>
      </b-field>
    </div>
    <!-- Navigate -->
    <div class="pt-lg">
      <div class="columns">
        <div class="column">
          <b-button
            type="is-light rounded-lg"
            expanded
            @click="goBack"
            size="is-medium"
            ><b-icon icon="arrow-left"></b-icon>
          </b-button>
        </div>
        <div class="column">
          <b-button
            type="is-primary rounded-lg"
            :disabled="!isReady || saving"
            :loading="saving || isUpdating"
            expanded
            size="is-medium"
            @click="goForward"
          >Save & Continue</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { isValidUrl } from '@/helpers/isValidUrl'
export default {
  components: {

  },
  computed: {
    ...mapGetters(['context']),
    isReady () {
      return (this.name && this.name !== null && this.website && this.website !== null)
    },
    endpoint () {
      return this.context.route + '/'
    },
    json () {
      return {
        name: this.name,
        website: this.parsedWebsite
      }
    }
  },
  data () {
    return {
      name: this.account.name,
      website: this.account.website,
      parsedWebsite: this.account.website,
      saving: false,
      isUpdating: false,
      setupIsCompleted: false,
      isNotModified: false,
      errors: {}
    }
  },
  methods: {
    goBack () {
      this.$router.push({ name: 'retailer.onboarding' })
    },
    goForward () {
      if (this.setupIsCompleted && this.isNotModified) {
        this.$router.push({ name: 'retailer.onboarding.business-address', params: { task: this.$route.params.task } })
      } else {
        this.saving = true
        this.$http.patch(this.endpoint, this.json).then(response => {
          this.name = response.data.name
          this.website = response.data.website
          this.updateTask()
        }).catch(error => {
          if (error.response && error.response.status === 400) {
            this.errors = error.response.data
          }
          this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
        }).finally(() => {
          this.saving = false
        })
      }
    },
    updateTask () {
      const taskBody = (typeof this.task.info === 'object') ? { info: this.task.info } : { info: {} }
      taskBody.info.accountInformation = true
      this.$emit('task:updated', taskBody, 'retailer.onboarding.business-address', true)
    },
    announce (key) {
      this.isNotModified = false
      if (key === 'website') {
        this.parsedWebsite = this.website.toLowerCase().startsWith('https://') ? this.website : `https://${this.website}`
        this.errors[key] = !isValidUrl(this.parsedWebsite)
      }
    }
  },
  mounted () {
    if (this.task && this.task.info && this.task.info.accountInformation) {
      this.setupIsCompleted = true
      this.isNotModified = true
    }
  },
  props: {
    task: {
      type: Object,
      require: true
    },
    account: {
      type: Object,
      required: true
    },
    isUpdatingTask: {
      type: Boolean
    }
  },
  watch: {
    isUpdatingTask (value) {
      this.isUpdating = value
    }
  }
}
</script>
