import dayjs from 'dayjs'
const quarterOfYear = require('dayjs/plugin/quarterOfYear')
dayjs.extend(quarterOfYear)

export default {
  today: {
    label: 'Today',
    value: 'today',
    start: dayjs().hour(0).minute(0).second(0),
    end: dayjs().hour(23).minute(59).second(59)
  },
  yesterday: {
    label: 'Yesterday',
    value: 'yesterday',
    start: dayjs().subtract(1, 'day').hour(0).minute(0).second(0),
    end: dayjs().subtract(1, 'day').hour(23).minute(59).second(59)
  },
  this_week: {
    label: 'This Week',
    value: 'this_week',
    start: dayjs().startOf('week').hour(0).minute(0).second(0),
    end: dayjs().endOf('week').hour(23).minute(59).second(59)
  },
  last_week: {
    label: 'Last Week',
    value: 'last_week',
    start: dayjs().subtract(1, 'week').startOf('week').hour(0).minute(0).second(0),
    end: dayjs().subtract(1, 'week').endOf('week').hour(23).minute(59).second(59)
  },
  this_month: {
    label: 'This Month',
    value: 'this_month',
    start: dayjs().startOf('month').hour(0).minute(0).second(0),
    end: dayjs().endOf('month').hour(23).minute(59).second(59)
  },
  last_month: {
    label: 'Last Month',
    value: 'last_month',
    start: dayjs().subtract(1, 'month').startOf('month').hour(0).minute(0).second(0),
    end: dayjs().subtract(1, 'month').endOf('month').hour(23).minute(59).second(59)
  },
  this_quarter: {
    label: 'This Quarter',
    value: 'this_quarter',
    start: dayjs().startOf('quarter').hour(0).minute(0).second(0),
    end: dayjs().endOf('quarter').hour(23).minute(59).second(59)
  },
  last_quarter: {
    label: 'Last Quarter',
    value: 'last_quarter',
    start: dayjs().subtract(1, 'quarter').startOf('quarter').hour(0).minute(0).second(0),
    end: dayjs().subtract(1, 'quarter').endOf('quarter').hour(23).minute(59).second(59)
  },
  this_year: {
    label: 'This Year',
    value: 'this_year',
    start: dayjs().startOf('year').hour(0).minute(0).second(0),
    end: dayjs().endOf('year').hour(23).minute(59).second(59)
  },
  last_year: {
    label: 'Last Year',
    value: 'last_year',
    start: dayjs().subtract(1, 'year').startOf('year').hour(0).minute(0).second(0),
    end: dayjs().subtract(1, 'year').endOf('year').hour(23).minute(59).second(59)
  },
  custom: {
    label: 'Custom',
    value: 'custom',
    start: dayjs().startOf('month').hour(0).minute(0).second(0),
    end: dayjs().endOf('month').hour(23).minute(59).second(59)
  }
}
