export default {
  methods: {
    cdnTransform (assetUrl, width, height, crop, resolution) {
      // if the asset is null for whatever reason, make sure to render a placeholder
      if (assetUrl === null || typeof assetUrl === 'undefined') {
        return 'https://res.cloudinary.com/revcascade/image/upload/v1576868498/static/placeholder-lg.png'
      }
      // split the assets from protocol
      const parts = assetUrl.split('https://')

      // build out transformations
      let transformations = 'f_auto'
      if (width !== null) transformations += ',w_' + width
      if (height !== null) transformations += ',h_' + height
      if (crop !== null) transformations += ',c_' + crop
      if (resolution) transformations += ',dpr_' + resolution

      // construct the final url
      const base = 'https://res.cloudinary.com/revcascade'
      if (transformations !== null) {
        return base + '/' + transformations + '/' + parts[1]
      }

      // return formatted url (and always use jpg)
      return base + '/' + parts[1]
    }
  }
}
