<template>
  <form @submit.prevent>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Discontinue Variants</p>
      </header>
      <section class="modal-card-body">
        <b-message type="is-info" class="is-size-7">
          Are you sure you want to discontinue the selected {{ products.length }} variants?
        </b-message>
        <p v-if="processing" class="mb-lg">Progress: <b>{{processedCount}}/{{products.length}}</b> variants are discontinued</p>
        <p class="is-size-7 has-text-grey">Note: You cannot close the modal while processing.</p>
        <p v-if="skipped > 0" class="has-text-warning">{{ `${skipped} ${skippedMessage}` }}</p>
        <p v-if="success > 0" class="has-text-success">{{ `${success} successfully discontinued` }}</p>
      </section>
      <footer class="modal-card-foot space-between">
        <b-button @click="$parent.close()" :disabled="isCloseDisabled">Close</b-button>
        <b-button
          v-if="skipped !== products.length || !completed && processedCount > 0"
          type="is-primary rounded-lg"
          @click="acceptAll"
          :loading="busy"
        >Confirm</b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import MarkAsDiscontinued from '@/helpers/Requests/Items/MarkAsDiscontinued'
export default {
  components: {
  },
  data () {
    return {
      processing: false,
      requests: [],
      todo: 0,
      skipped: 0,
      success: 0,
      completed: false,
      skippedMessage: '',
      processedCount: 0
    }
  },
  computed: {
    ...mapGetters(['context']),
    busy () {
      return this.todo !== this.skipped + this.processedCount
    },
    isCloseDisabled () {
      return this.processing
    }
  },
  methods: {
    acceptAll () {
      this.reset()
      this.processing = true
      this.products.forEach((product, index) => {
        this.todo++
        const request = new MarkAsDiscontinued({
          route: this.context.route,
          variant: product
        })
        if (request.isValid) {
          this.success++
        } else {
          this.skipped++
          this.skippedMessage = request.errorMessage
        }
        request.prepare()
        setTimeout(() => {
          request.run().then(response => {
            this.processedCount++
            if (this.todo === this.products.length) {
              this.completed = true
              this.$parent.close()
              this.$emit('reload')
            }
          }).catch(() => {})
        }, index * 1000)
      })
      this.processing = false
    },
    reset () {
      this.todo = this.skipped = this.success = 0
      this.skippedMessage = ''
      this.processing = this.completed = false
    }
  },
  mounted () {

  },
  props: {
    products: {
      type: Array
    },
    reload: {
      type: Boolean
    }
  }
}
</script>
