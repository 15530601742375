<template>
  <div>
    <!-- Content -->
    <div class="">
      <!-- Has EDI Account -->
      <div v-if="!loading && ediAccount !== null">
        <p class="has-text-weight-bold mb-md">EDI Account Credentials</p>
        <div class="mb-md">
          Your EDI account &amp; SFTP credentials are below:
        </div>
        <div class="has-background-light pa-md rounded-lg mb-xl">
          <table class="table is-fullwidth is-narrow is-size-7 is-transparent no-border">
            <tr>
              <th width="25%">EDI Qualifier / ID</th>
              <td>{{ ediAccount.qualifier }} / {{ ediAccount.identifier }}</td>
            </tr>
            <tr>
              <th>SFTP Host</th>
              <td>ftp.revcascade.com (port 22)</td>
            </tr>
            <tr>
              <th>SFTP Username</th>
              <td>{{ ediAccount.ftp_account.username }}</td>
            </tr>
            <tr>
              <th>SFTP Password</th>
              <td>
                <p>{{ (showPassword) ? ediAccount.ftp_account.password : obfuscatedPassword }}</p>
                <p class="is-size-7">
                  <a href="#" class="has-text-info" @click.prevent v-clipboard:copy="ediAccount.ftp_account.password" v-clipboard:success="copied">Copy</a> &bull;
                  <a href="#" class="has-text-info" @click.prevent="showPassword = !showPassword">{{ (showPassword) ? 'Hide' : 'Show'}}</a>
                </p>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div v-else>
        <loading message="Loading accounts..." />
      </div>
    </div>
    <!-- Navigate -->
    <div class="py-lg">
      <div class="columns is-centered">
        <div class="column">
          <b-button
            type="is-light rounded-lg"
            size="is-medium"
            @click="goBack"
            expanded
            ><b-icon icon="arrow-left"></b-icon>
          </b-button>
        </div>
        <div class="column">
          <b-button
            type="is-primary rounded-lg"
            size="is-medium"
            expanded
            :disabled="!ready || saving"
            :loading="saving"
            @click="goForward"
          >Complete Task</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {

  },
  computed: {
    ...mapGetters(['app', 'context']),
    ready () {
      return (this.ediAccount)
    },
    obfuscatedPassword () {
      return new Array(this.ediAccount.ftp_account.password.length + 1).join('*')
    }
  },
  data () {
    return {
      showPassword: false,
      saving: false,
      errors: {}
    }
  },
  methods: {
    goBack () {
      this.$emit('navigate', 'Intro')
    },
    goForward () {
      this.saving = true
      const endpoint = this.context.route + '/assigned-tasks/' + this.task.id + '/complete/'
      this.$http.put(endpoint).then(() => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Task Completed!' })
        this.$router.push({ name: 'onboarding.tasks', params: { connection: this.connection.id } })
      }).finally(() => {
        this.saving = false
      })
    },
    copied () {
      this.$buefy.toast.open({ type: 'is-success', message: 'Copied!' })
    }
  },
  props: {
    connection: {
      type: Object,
      required: true
    },
    task: {
      type: Object,
      required: true
    },
    ediAccount: {
      type: Object,
      default: null
    },
    loading: {
      type: Boolean,
      default: true
    }
  }
}
</script>
