<template>
  <form @submit.prevent="save">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Edit Tracking Number</p>
      </header>
      <section class="modal-card-body">
        <b-message class="is-size-7">
          <p><span class="has-text-weight-bold has-text-danger">Please Note </span></p>
          <p>
            When you update a tracking number, a notification will be sent to {{ shipment.retailer.name }}
            so that they may take appropriate action to notifiy the customer.
          </p>
        </b-message>
        <div class="columns">
          <div class="column">
            <b-field label="Carrier">
              <b-input :value="shipment.carrier.name" size="is-medium" readonly disabled />
            </b-field>
          </div>
          <div class="column">
            <b-field label="Current Tracking Number">
              <b-input :value="shipment.tracking_number" size="is-medium" readonly disabled />
            </b-field>
          </div>
        </div>
        <b-field label="New Tracking Number">
          <b-input v-model="trackingNumber" size="is-medium" />
        </b-field>
      </section>
      <footer class="modal-card-foot space-between">
        <b-button @click="$parent.close()">Cancel</b-button>
        <b-button
          native-type="submit"
          type="is-primary"
          :loading="saving"
          :disabled="!ready || saving">
          Update Tracking
        </b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['context']),
    ready () {
      return (this.trackingNumber && this.trackingNumber !== this.shipment.tracking_number)
    }
  },
  data () {
    return {
      trackingNumber: null,
      saving: false,
      errors: {}
    }
  },
  methods: {
    save () {
      this.saving = true
      const endpoint = this.context.route + '/shipments/' + this.shipment.id + '/update-tracking-number/'
      const json = {
        tracking_number: this.trackingNumber
      }
      this.$http.put(endpoint, json).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Tracking number updated!' })
        this.$emit('fulfillment:updated')
        this.$parent.close()
      }).catch(() => {
        this.$buefy.toast.open({ type: 'is-danger', message: 'There was a problem updating the tracking number. Please check your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  props: {
    shipment: {
      type: Object,
      required: true
    }
  }
}
</script>
