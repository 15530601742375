<template>
<form @submit.prevent="save">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">{{isAddLocation ? 'Add' : 'Update'}} Location</p>
      </header>
      <!--Section contains the form input/select fields-->
      <section class="modal-card-body">
        <div>
          <div class="pb-lg" style="width:87%;">
            <b-field label="Mapping Name">
              <b-input size="is-medium expanded" v-model="mappingName"></b-input>
            </b-field>
          </div>
          <p class="has-text-weight-bold mb-sm">Select Shopify Location</p>
          <div class="columns is-mobile is-vcentered">
            <div class="column">
              <div class="">
                <SelectShopifyLocation v-model="shopifyLocation" :locationId="locationMapping && locationMapping.shopify_location_id" :account="account" />
              </div>
            </div>
          </div>
        </div>
        <div class="pt-lg">
          <p class="has-text-weight-bold mb-sm">Select {{ app.productName }} Location</p>
          <div class="columns is-mobile is-vcentered">
            <div class="column">
              <SelectMarketplaceLocation v-model="marketplaceLocation" :account="account" :locationId="locationMapping && locationMapping.location.id"/>
            </div>
          </div>
        </div>
        <div class="pt-lg">
          <p class=" mb-sm">Can't find a {{ app.productName }} location? <span class="has-text-weight-light">Click <a href="#" class="has-text-info has-text-weight-bold" @click.prevent="$emit('location:create')">here</a> to create one.</span></p>
        </div>
      </section>
      <!--Footer contains all the ctas-->
      <footer class="modal-card-foot" style="justify-content:space-between" >
        <div>
          <button class="button" type="button" @click="$parent.close()" :disabled="saving">Close</button>
          <b-button
          :type="confirmDelete ? 'is-danger' : ''"
          :disabled="!mappingName || !marketplaceLocation || !shopifyLocation || saving"
          v-if="!isAddLocation"
          @click.prevent="onDelete"
          >{{confirmDelete ? 'Confirm?' : 'Delete'}}</b-button>
        </div>
        <!-- If it is add location flow show Add button -->
        <b-button
          native-type="submit"
          type="is-primary"
          v-if="isAddLocation"
          :disabled="!mappingName || !marketplaceLocation || !shopifyLocation || saving"
        >Save</b-button>
        <!-- Else check if it is delete flow, if not show update button-->
        <b-button
          @click.prevent="update"
          type="is-primary"
          v-else-if="!confirmDelete"
          :disabled="!mappingName || !marketplaceLocation || !shopifyLocation || saving"
        >Update</b-button>
      </footer>
    </div>
  </form>
</template>
<script>
import { mapGetters } from 'vuex'
import SelectShopifyLocation from './SelectLocation'
import SelectMarketplaceLocation from '../SelectInventoryLocations'

export default {
  components: {
    SelectShopifyLocation,
    SelectMarketplaceLocation
  },
  computed: {
    ...mapGetters(['app', 'context']),
    endpoint () {
      return this.context.route + '/shopify/' + this.account.id + '/location-mappings/'
    },
    isAddLocation () {
      return !this.locationMapping
    }
  },
  methods: {
    save () {
      this.saving = true
      const payload = {
        shopify_location_id: this.shopifyLocation.id,
        location_id: this.marketplaceLocation.id,
        nickname: this.mappingName
      }
      this.$http.post(this.endpoint, payload).then(response => {
        this.onSuccess('Locations mapped successfully!')
      }).catch((error) => {
        const msg = error.response.data.detail || 'Unable to save the location mapping'
        this.$buefy.toast.open({ type: 'is-danger', message: msg })
      }).finally(() => { this.saving = false })
    },
    onDelete () {
      if (this.confirmDelete) {
        this.remove()
      } else {
        this.confirmDelete = true
        this.deleteBtnTimerID = setTimeout(() => {
          this.confirmDelete = false
        }, 3000)
      }
    },
    remove () {
      this.saving = true
      clearTimeout(this.deleteBtnTimerID)
      this.$http.delete(this.endpoint + this.locationMapping.id + '/').then(response => {
        this.onSuccess('Location mapping deleted successfully!')
      }).catch((error) => {
        const msg = error.response.data.detail || 'Unable to delete the location mapping'
        this.$buefy.toast.open({ type: 'is-danger', message: msg })
      }).finally(() => {
        this.saving = false
        this.confirmDelete = false
      })
    },
    update () {
      this.saving = true
      this.confirmDelete = false
      const payload = {
        shopify_location_id: this.shopifyLocation.id,
        location_id: this.marketplaceLocation.id,
        nickname: this.mappingName
      }
      this.$http.put(this.endpoint + this.locationMapping.id + '/', payload).then(response => {
        this.onSuccess('Location mapping updated successfully!')
      }).catch((error) => {
        const msg = error.response.data.detail || 'Unable to update the location mapping'
        this.$buefy.toast.open({ type: 'is-danger', message: msg })
      }).finally(() => { this.saving = false })
    },
    onSuccess (msg) {
      this.$buefy.toast.open({ type: 'is-success', message: msg })
      this.$emit('locations:updated')
      this.$parent.close()
    }
  },
  data () {
    return {
      saving: false,
      shopifyLocation: null,
      marketplaceLocation: null,
      mappingName: this.locationMapping ? this.locationMapping.nickname : '',
      confirmDelete: false,
      deleteBtnTimerID: 0
    }
  },
  props: {
    account: Object,
    locationMapping: {
      type: Object,
      default: null
    }
  }

}
</script>
