<template>
<div>
  <!-- Task -->
  <div class="has-background-white pa-xl rounded-lg">
    <div class="mb-xl">
      <div class="columns space-between">
        <div class="column is-three-fifths">
          <!-- Intro -->
          <div class="mb-xxl">
            <p class="has-text-weight-bold is-size-4 mb-sm">Submit Assortment</p>
            <p class="has-text-grey mb-md">
              {{ app.productName }} uses a "proposal" system to help suppliers (i.e. You!)
              &amp; {{ connection.retailer.name }} agree on merchandising assortment and costs.
              Proposals are small sets of items from your catalog that
              usually fit within a single department that you "propose"
              to your partner. A merchandiser from {{ connection.retailer.name }} will review your proposal and decide
              whether to approve or suggest changes to your proposal.
              If the merchandiser approves your proposal, your items will be listed for sale!
            </p>
            <p class="has-text-grey mb-md">
              It's called a "proposal" because {{ connection.retailer.name }} won't always accept ALL of
              the items that are contained in the proposal. It is possible that
              some items just aren't a great fit for a variety of reasons.
              So sometimes, your partner may remove certain items from your
              proposal before approving the remaining items.
            </p>
            <p class="has-text-grey mb-md">
              To create your first proposal, visit the
              <router-link :to="{ name: 'proposals.create' }" class="has-text-weight-bold has-text-info">Proposals Dashboard</router-link>
              and follow the instructions on the screen.
            </p>
          </div>
          <!-- Complete Task -->
          <div class="mb-xl">
            <div class="columns">
              <div class="column is-half">
                <b-button
                  type="is-primary"
                  size="is-medium"
                  :disabled="!ready || saving"
                  :loading="saving"
                  @click="completeTask"
                  expanded
                >Complete Task</b-button>
              </div>
            </div>
            <p class="my-lg">
              <span v-if="!loading && completedProposals === 0" class="has-text-warning">
                The number of completed proposals must be greater than 0
                before you can complete this task.
              </span>
            </p>
          </div>
        </div>
        <div class="column is-one-third">
          <!-- Proposal Counts -->
          <div class="mb-xxl">
            <div class="border has-text-centered mb-sm">
              <div class="border-b has-background-light">
                <p class="has-text-weight-bold py-sm">Completed Proposals</p>
              </div>
              <div>
                <p class="is-size-4 py-md">
                  <span v-if="!loading && completedProposals > 0" class="has-text-success">{{ completedProposals }}</span>
                  <span v-else-if="!loading && completedProposals === 0">0</span>
                  <span v-else class="has-text-grey-light">Loading...</span>
                </p>
              </div>
            </div>
            <div class="has-text-centered has-text-weight-bold mb-lg">
              <router-link :to="{ name: 'proposals.dashboard' }" class="has-text-info">Proposals Dashboard</router-link>
            </div>
          </div>
          <!-- Resources -->
          <div class="has-background-light pa-lg rounded-lg">
            <p class="has-text-weight-bold is-size-5 has-text-centered mb-md">Proposal Resources</p>
            <div class="mb-md">
              <p class="is-size-5 mb-sm">
                <a href="https://help.shipstation.com/hc/en-us/articles/360025856212-ShipStation-API" target="_blank" class="has-text-info">Proposal Guide</a>
              </p>
              <p class="has-text-grey">
                This guide demonstrates how to submit a proposal to {{ connection.retailer.name }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['app', 'context']),
    breadcrumbs () {
      const id = this.$route.params.connection
      return {
        current: 'Assortment',
        history: [
          { label: 'Onboarding', name: 'onboarding' },
          { label: 'Tasks', name: 'onboarding.tasks', params: { id: id } }
        ]
      }
    },
    endpoint () {
      return this.context.route + '/'
    },
    loading () {
      return this.loadingTask || this.loadingCount
    },
    ready () {
      return this.loading && this.completedProposals > 0
    }
  },
  data () {
    return {
      loadingTask: true,
      loadingCount: true,
      task: null,
      completedProposals: 0,
      saving: false,
      errors: {}
    }
  },
  methods: {
    loadTask () {
      this.loadingTask = true
      const endpoint = this.context.route + '/connections/' + this.$route.params.connection + '/assigned-tasks/' + this.$route.params.task + '/'
      this.$http.get(endpoint).then(response => {
        this.task = response.data
        this.loadingTask = false
      })
    },
    loadCount () {
      this.loadingCount = true
      const endpoint = this.context.route + '/proposals/summary/'
      const query = {
        params: {
          connection_id: this.$route.params.connection
        }
      }
      this.$http.get(endpoint, query).then(response => {
        this.statuses = response.data
        let count = 0
        this.statuses.forEach(status => {
          if (status.status === 'completed') {
            count++
          }
        })
        this.completedProposals = count
        this.loadingCount = false
      })
    },
    completeTask () {
      this.saving = true
      const endpoint = this.context.route + '/assigned-tasks/' + this.$route.params.task + '/complete/'
      this.$http.put(endpoint).then(() => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Task Completed!' })
        this.$router.push({ name: 'onboarding.tasks', params: { connection: this.$route.params.connection } })
      }).catch(error => {
        if (error.response && error.response.status === 400) {
          this.$router.push({
            name: 'onboarding.tasks',
            params: { connection: this.$route.params.connection }
          })
        }
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    this.loadTask()
    this.loadCount()
  },
  props: ['connection']
}
</script>
