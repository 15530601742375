export default {
  active: {
    label: 'Active',
    tooltip: 'Connections that are active',
    filters () {
      return {
        preset: 'active',
        search: {},
        facets: {
          status: [{
            label: 'Active',
            value: 'active'
          }]
        },
        timestamps: {}
      }
    }
  },
  onboarding: {
    label: 'Onboarding',
    tooltip: 'Connections that are onboarding',
    filters () {
      return {
        preset: 'onboarding',
        search: {},
        facets: {
          status: [{
            label: 'Onboarding',
            value: 'onboarding'
          }]
        },
        timestamps: {}
      }
    }
  },
  suspended: {
    label: 'Suspended',
    tooltip: 'Connections that are suspended',
    filters () {
      return {
        preset: 'suspended',
        search: {},
        facets: {
          status: [{
            label: 'Suspended',
            value: 'suspended'
          }]
        },
        timestamps: {}
      }
    }
  },
  all: {
    label: 'All',
    tooltip: 'All connections',
    filters () {
      return {
        preset: 'all',
        search: {},
        facets: {},
        timestamps: {}
      }
    }
  }
}
