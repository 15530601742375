<template>
  <form @submit.prevent="save">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Add Shipping Account</p>
      </header>
      <section class="modal-card-body">
        <div>
          <!-- UPS Form -->
          <div v-if="carrier.code === 'UPSN'">
            <UpsSetupForm v-model="carrierCredentials" />
          </div>
          <!-- FedEx -->
          <div v-else-if="carrier.code === 'FDEG'">
            <FedexSetupForm v-model="carrierCredentials"/>
          </div>
          <!-- USPS -->
          <div v-else-if="carrier.code === 'USPS'">
            <UspsSetupForm v-model="carrierCredentials"/>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot space-between">
        <button class="button" type="button" @click="$parent.close()">Close</button>
        <b-button
          type="is-primary"
          native-type="submit"
          :loading="saving"
          :disabled="saving || !ready">
          Save &amp; Continue
        </b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import FedexSetupForm from './FedexSetupForm'
import UpsSetupForm from './UpsSetupForm'
import UspsSetupForm from './UspsSetupForm'

export default {
  components: {
    FedexSetupForm,
    UpsSetupForm,
    UspsSetupForm
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/shippingaccounts/easypost-carrier-account/'
    },
    json () {
      return (this.carrierCredentials !== null) ? this.carrierCredentials : null
    },
    ready () {
      return (this.carrier !== null && this.carrierCredentials !== null)
    }
  },
  data () {
    return {
      saving: false,
      errors: {},
      carrierCredentials: null
    }
  },
  methods: {
    save () {
      // this.$emit('account:created', {
      //   credentials: null,
      //   account: null
      // })
      this.saving = true
      this.$http.post(this.endpoint, this.json).then(response => {
        this.$emit('account:created', {
          credentials: {
            accountNumber: this.carrierCredentials.credentials.account_number,
            billingCountry: this.carrierCredentials.credentials.billing_country,
            billingPostalCode: this.carrierCredentials.credentials.billing_postal_code
          },
          account: response.data
        })
      }).catch(error => {
        if (error.response && error.response.status === 400) {
          this.$buefy.toast.open({ type: 'is-danger', message: 'Error saving carrier.' })
        }
      }).finally(() => {
        this.saving = false
      })
    }
  },
  props: ['carrier']
}
</script>
