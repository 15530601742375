<template>
<div>
  <div class="section">
    <div class="container">
      <!-- Page Header -->
      <div class="mb-md">
        <div class="columns is-vcentered">
          <div class="column is-one-third">
            <breadcrumbs :history="breadcrumbs.history" :current="breadcrumbs.current" />
          </div>
          <div class="column is-one-third">
            <h1 class="has-text-centered is-size-4">Submit Assortment</h1>
          </div>
        </div>
      </div>
      <!-- Proposal Header -->
      <div class="has-background-white px-xl py-md rounded-lg mb-lg">
        <ProposalHeader :proposal="proposal" :loading="loading" />
      </div>
      <!-- Proposal Tasks -->
      <div>
        <div v-if="proposal !== null">
          <router-view
            :proposal="proposal"
            :feeds="feeds"
            :loadingProposal="loadingProposal"
            :loadingFeeds="loadingFeeds"
            @refresh="refresh"
            @proposal:updated="loadProposal()"
            @feeds:updated="loadFeeds()"
          ></router-view>
        </div>
        <!-- Proposal Is Loading -->
        <div v-else>
          <loading message="Loading proposal..." />
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import ProposalHeader from '../components/Detail/ProposalHeader'

export default {
  components: {
    ProposalHeader
  },
  computed: {
    ...mapGetters(['context']),
    breadcrumbs () {
      const crumbs = {
        current: 'Loading...',
        history: [
          { label: 'Catalog', name: 'catalog' },
          { label: 'Proposals', name: 'proposals.dashboard' }
        ]
      }
      if (this.proposal) {
        crumbs.current = null
        crumbs.history.push({ label: 'Submit', name: 'proposal.tasks.submit', params: { id: this.proposal.id } })
      }
      return crumbs
    },
    loading () {
      return (this.loadingProposal || this.loadingFeeds)
    }
  },
  data () {
    return {
      loadingProposal: true,
      loadingFeeds: true,
      proposal: null,
      feeds: [],

      tempfile: null,
      loadingImportFeed: false,
      loadingBuildFeed: false,
      buildFeed: null
    }
  },
  methods: {
    loadProposal (refresh = false) {
      if (!refresh) this.loadingProposal = true
      const endpoint = this.context.route + '/proposals/' + this.$route.params.id + '/'
      this.$http.get(endpoint).then(response => {
        this.proposal = response.data
        this.proposal.active_items = this.proposal.item_count - this.proposal.item_rejected_count
        this.loadingProposal = false
      })
    },
    loadFeeds (refresh = false) {
      if (!refresh) this.loadingFeeds = true
      const endpoint = this.context.route + '/proposals/' + this.$route.params.id + '/feeds/?order_by=id'
      this.$http.get(endpoint).then(response => {
        this.feeds = response.data.results
        this.loadingFeeds = false
      })
    },
    refresh () {
      this.loadProposal(true)
      this.loadFeeds(true)
    }
  },
  mounted () {
    this.loadProposal()
    this.loadFeeds()
  }
}
</script>
