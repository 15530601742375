import Index from './views/Index'
import Detail from './views/Detail'
import Tasks from './views/Tasks'
import Welcome from './views/Welcome'
import CallScheduled from './views/CallScheduled'
import Billing from './views/Billing'
import Locations from './views/Locations'
import Contacts from './views/Contacts'
import Integration from './views/Integration'
import EdiAccount from './views/EdiAccount'
import Assortment from './views/Assortment'
import Shopify from './views/Shopify'
import WooCommerce from './views/WooCommerce'
import BigCommerce from './views/BigCommerce'
import Shipstation from './views/Shipstation'
import Notifications from './views/Notifications'
import Complete from './views/Complete'
import ImportTemplate from './views/ImportTemplate'
import ApproveImportRequest from './views/ApproveImportRequest'
import ImportShopify from './views/ImportShopify'
import Feedback from './views/Feedback'
import TermsAndConditions from './views/TermsAndConditions'
import ManageEdiAccount from './views/ManageEdiAccount'
import EdiAccountCreate from './views/EdiAccountCreate'
import EdiItems from './views/EdiItems'
import EdiInventory from './views/EdiInventory'
import EdiOrders from './views/EdiOrders'
import EdiFulfillment from './views/EdiFulfillment'
import EdiInvoice from './views/EdiInvoice'
import EdiCompleteSetup from './views/EdiCompleteSetup'

export default [{
  path: '/onboarding',
  name: 'onboarding',
  component: Index
}, {
  path: '/onboarding/scheduled',
  name: 'onboarding.scheduled',
  component: CallScheduled
}, {
  path: '/onboarding/:connection',
  component: Detail,
  children: [{
    path: 'tasks',
    name: 'onboarding.tasks',
    component: Tasks
  }, {
    path: 'tasks/:task/welcome',
    name: 'onboarding.tasks.welcome',
    component: Welcome
  }, {
    path: 'tasks/:task/billing',
    name: 'onboarding.tasks.billing',
    component: Billing
  }, {
    path: 'tasks/:task/locations',
    name: 'onboarding.tasks.locations',
    component: Locations
  }, {
    path: 'tasks/:task/contacts',
    name: 'onboarding.tasks.contacts',
    component: Contacts
  }, {
    path: 'tasks/:task/integration',
    name: 'onboarding.tasks.integration',
    component: Integration
  }, {
    path: 'tasks/:task/notifications',
    name: 'onboarding.tasks.notifications',
    component: Notifications
  }, {
    path: 'tasks/:task/assortment',
    name: 'onboarding.tasks.assortment',
    component: Assortment
  }, {
    path: 'tasks/:task/shipstation',
    name: 'onboarding.tasks.shipstation',
    component: Shipstation
  }, {
    path: 'tasks/:task/shopify',
    name: 'onboarding.tasks.shopify',
    component: Shopify
  }, {
    path: 'tasks/:task/terms-and-conditions',
    name: 'onboarding.tasks.terms-and-conditions',
    component: TermsAndConditions
  }, {
    path: 'tasks/:task/woocommerce',
    name: 'onboarding.tasks.woocommerce',
    component: WooCommerce
  }, {
    path: 'tasks/:task/bigcommerce',
    name: 'onboarding.tasks.bigcommerce',
    component: BigCommerce
  }, {
    path: 'tasks/:task/import-template',
    name: 'onboarding.tasks.import-template',
    component: ImportTemplate
  }, {
    path: 'tasks/:task/approve-import-request',
    name: 'onboarding.tasks.approve-import-request',
    component: ApproveImportRequest
  }, {
    path: 'tasks/:task/import-shopify',
    name: 'onboarding.tasks.import-shopify',
    component: ImportShopify
  }, {
    path: 'tasks/:task/feedback',
    name: 'onboarding.tasks.feedback',
    component: Feedback
  },
  {
    path: '/onboarding/:connection/tasks/:task/manage-edi-account',
    name: 'onboarding.tasks.edi',
    component: ManageEdiAccount
  },
  {
    path: '/onboarding/:connection/tasks/:task/edi-account',
    component: EdiAccount,
    children: [{
      path: 'create',
      name: 'onboarding.tasks.self-serve-edi',
      component: EdiAccountCreate
    }, {
      path: 'items',
      name: 'onboarding.tasks.self-serve-edi.items',
      component: EdiItems
    }, {
      path: 'inventory',
      name: 'onboarding.tasks.self-serve-edi.inventory',
      component: EdiInventory
    }, {
      path: 'orders',
      name: 'onboarding.tasks.self-serve-edi.orders',
      component: EdiOrders
    }, {
      path: 'fulfillment',
      name: 'onboarding.tasks.self-serve-edi.fulfillment',
      component: EdiFulfillment
    }, {
      path: 'invoice',
      name: 'onboarding.tasks.self-serve-edi.invoice',
      component: EdiInvoice
    }, {
      path: 'complete',
      name: 'onboarding.tasks.self-serve-edi.complete',
      component: EdiCompleteSetup
    }]
  }]
}, {
  path: '/onboarding/:connection/complete',
  name: 'onboarding.tasks.complete',
  component: Complete
}]
