<template>
  <form @submit.prevent="save" autocomplete="off">
    <!-- Intro -->
    <div class="has-text-centered">
      <p class="is-size-4 mb-sm has-text-black">Partner Setup</p>
      <p class="has-text-centered pb-lg">First, let's confirm a few settings about your business.</p>
    </div>
    <!-- Fields -->
    <div class="pb-lg mb-lg">
      <div class="columns is-centered">
        <div class="column is-three-quarters-tablet is-two-thirds-widescreen">
          <b-field
            label="Business Name"
            :type="('name' in errors) ? 'is-danger' : ''"
            :message="('name' in errors) ? errors['name'][0] : ''"
            expanded>
            <b-input
              size="is-medium"
              autocomplete="off"
              type="text"
              v-model="name"
            ></b-input>
          </b-field>
          <b-field
            label="Website"
            :type="('website' in errors) ? 'is-danger' : ''"
            :message="('website' in errors) ? errors['website'][0] : ''"
            expanded>
            <b-input
              size="is-medium"
              autocomplete="off"
              type="text"
              v-model="website"
            ></b-input>
          </b-field>
        </div>
      </div>
    </div>
    <!-- Buttons -->
    <div class="mb-xl">
      <div class="columns is-centered">
        <div class="column is-three-quarters-tablet is-two-thirds-widescreen">
          <div class="columns">
            <div class="column">
              <b-button type="is-light rounded-lg" size="is-large" expanded :disabled="true">
                <b-icon icon="arrow-left"></b-icon>
              </b-button>
            </div>
            <div class="column">
              <b-button
                native-type="submit"
                type="is-primary rounded-lg"
                size="is-large"
                expanded
                :loading="saving"
                :disabled="!validated || saving"
              >Save & Continue</b-button>
            </div>
          </div>
        </div>
      </div>
      <p v-if="'detail' in errors" class="has-text-danger">{{ error.detail }}</p>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  data () {
    return {
      saving: false,
      errors: {},
      name: null,
      website: null
    }
  },
  computed: {
    ...mapGetters(['api', 'auth', 'context']),
    endpoint () {
      return this.context.route + '/'
    },
    json () {
      const json = {
        name: this.name,
        website: this.website,
        connections_manager: {
          id: this.auth.user.id
        },
        merchandising_manager: {
          id: this.auth.user.id
        }
      }
      if (this.context.type === 'vendor') {
        json.shipping_manager = { id: this.auth.user.id }
      } else {
        json.transactions_manager = { id: this.auth.user.id }
      }
      return json
    },
    validated () {
      return true
    }
  },
  methods: {
    save () {
      this.saving = true
      this.$http.patch(this.endpoint, this.json).then(response => {
        this.$store.dispatch('updateContextInfo', {
          name: response.data.name,
          website: response.data.website
        })
        this.$emit('change:step', 'user')
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    this.name = this.context.partner.name
    this.website = (this.context.partner.website !== null)
      ? this.context.partner.website
      : 'https://' + this.shop.domain
  },
  props: ['shop']
}
</script>
