<template>
<form @submit.prevent="save">
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Update Shipping Account</p>
    </header>
    <section class="modal-card-body">
      <div>
        <!-- Account Name -->
        <b-field label="Shipping Account Nickname">
          <b-input
            type="text"
            v-model="nickname"
            size="is-medium"
          ></b-input>
        </b-field>
        <!-- Enabled Methods -->
        <div class="mb-lg">
          <p class="has-text-weight-bold mb-sm">Enabled Methods</p>
          <div v-if="account && account.shipping_methods.length >0">
            <div v-for="method in account.shipping_methods" :key="method.id">
              <EnabledShippingMethod :accountId="account.id" :method="method" @method:removed="load" />
            </div>
          </div>
          <div v-else>
            <p class="has-background-light pa-md has-text-centered rounded-lg has-text-grey is-size-7">
              Enable new methods by selecting from the available shipping methods below
            </p>
          </div>
        </div>
        <!-- Available Methods -->
        <div class="mb-lg">
          <p class="has-text-weight-bold mb-sm">Available Methods</p>
          <b-table
            class="is-size-7"
            checkable
            :checked-rows.sync="selected"
            :data="availableMethods">
            <!-- Name -->
            <b-table-column
              label="Item"
              cell-class="nowrap"
              v-slot="props">
              <p>{{ props.row.name }}</p>
            </b-table-column>
            <template #empty>
              <div v-if="!loading">
                <p>No shipping methods found for this carrier.</p>
              </div>
              <div v-else>
                <loading />
              </div>
            </template>
          </b-table>
        </div>
      </div>
    </section>
    <footer class="modal-card-foot space-between">
      <button class="button" type="button" @click="$parent.close()">Close</button>
      <b-button
        type="is-primary"
        native-type="submit"
        :loading="saving"
        :disabled="saving || !ready">
        Save Shipping Account
      </b-button>
    </footer>
  </div>
</form>
</template>

<script>
import { mapGetters } from 'vuex'
import EnabledShippingMethod from './EnabledShippingMethod'

export default {
  components: {
    EnabledShippingMethod
  },
  computed: {
    ...mapGetters(['api', 'context']),
    endpoint () {
      return this.context.route + '/shippingaccounts/' + this.account.id + '/'
    },
    json () {
      const json = {
        nickname: this.nickname,
        shipping_account_shipping_methods: []
      }
      this.selected.forEach(method => {
        json.shipping_account_shipping_methods.push({
          shipping_method: {
            id: method.id
          }
        })
      })
      return json
    },
    ready () {
      return this.nickname // this.selected.length > 0
    },
    allowedMethods () {
      if (this.shippingMethods.length === 0) return []
      return this.shippingMethods.filter((method) => {
        return method.generic_shipping_method.name === 'Ground' ||
          method.generic_shipping_method.name === 'Expedited'
      })
    }
  },
  data () {
    return {
      loading: true,
      nickname: this.account.nickname,
      availableMethods: [],
      selected: [],
      saving: false,
      errors: {}
    }
  },
  methods: {
    load () {
      this.loading = true
      const endpoint = this.api.baseUrl + '/' + this.api.version + '/carriers/'
      const query = {
        params: {
          pagination: 0,
          search: this.account.carrier.code
        }
      }
      this.$http.get(endpoint, query).then(response => {
        if (response.data.count === 1) {
          this.shippingMethods = response.data.results[0].shipping_methods
          this.shippingMethods.forEach(method => {
            const selected = this.$utils.find(this.account.shipping_methods, ['id', method.id])
            if (typeof selected === 'undefined') {
              this.availableMethods.push(method)
            }
          })
        }
      }).finally(() => {
        this.loading = false
      })
    },
    save () {
      this.saving = true
      this.$http.patch(this.endpoint, this.json).then(response => {
        this.$emit('updated')
        this.$parent.close()
        this.$buefy.toast.open({ type: 'is-success', message: 'Shipping Account Updated!' })
      }).catch(error => {
        if (error.response && error.response.status === 400) {
          this.errors = error.response.data
        }
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mixins: [],
  mounted () {
    this.load()
  },
  props: ['account']
}
</script>
