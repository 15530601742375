<template>
  <div>
    <!-- Respective api based integration fields -->
    <div v-if="option && option === 'api'">
      <b-field label="Add API Integration Details" custom-class="required"></b-field>
      <b-field label="Client Name" custom-class="required">
        <b-input
          placeholder="Client Name"
          @input="announce"
          v-model="ClientName"
        ></b-input>
      </b-field>
    </div>
    <!-- Respective shopify fields -->
    <div v-if="option && option === 'shopify'">
      <b-field label="Add Shopify Integration Details" custom-class="required"></b-field>
      <b-field label="Shopify Store URL" custom-class="required">
        <p class="control">
          <span class="button is-static is-medium">https://</span>
        </p>
        <b-input v-model="shopifyStoreName" @input="announce" expanded placeholder="Shopify Store Name" size="is-medium"></b-input>
        <p class="control">
          <span class="button is-static is-medium">.myshopify.com</span>
        </p>
      </b-field>
      <b-field label="Shopify API Key" custom-class="required">
        <b-input
          placeholder="Shopify API Key"
          @input="announce"
          v-model="shopifyApiKey"
          size="is-medium"
        ></b-input>
      </b-field>
    </div>
    <!-- Respective BigCommerce integration fields -->
    <div v-if="option && option === 'bigcommerce'">
      <b-field label="Add BigCommerce Integration Details" custom-class="required"></b-field>
      <b-field label="Store Identifier" custom-class="required">
        <b-input
          placeholder="Store Identifier"
          @input="announce"
          v-model="StoreId"
          size="is-medium"
        ></b-input>
      </b-field>
      <b-field label="Store Token" custom-class="required">
        <b-input
          placeholder="Store Token"
          @input="announce"
          v-model="StoreToken"
          size="is-medium"
        ></b-input>
      </b-field>
    </div>
    <!-- Respective fabric platform fields -->
    <div v-if="option && option === 'fabric'">
      <b-field label="Add fabric Integration Details" custom-class="required"></b-field>
      <b-field label="fabric Account" custom-class="required">
        <b-input
          placeholder="Account"
          @input="announce"
          v-model="fabricAccount"
          size="is-medium"
        ></b-input>
      </b-field>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  computed: {
    ...mapGetters(['app', 'context']),
    json () {
      const json = {}
      if (this.option === 'fabric') {
        json.id = this.platformId
        json.platform = { code: 'fabric' }
        json.credentials = [{
          credential: { code: 'fabric_account' },
          value: this.fabricAccount
        }]
      } else if (this.option === 'shopify') {
        json.id = this.platformId
        json.platform = { code: 'shopify' }
        json.credentials = [{
          credential: { code: 'shopify_shop_name' },
          value: this.shopifyStoreName
        }, {
          credential: { code: 'shopify_token' },
          value: this.shopifyApiKey
        }, {
          credential: { code: 'shopify_private_app' },
          value: 1
        }]
      } else if (this.option === 'bigcommerce') {
        json.platform = { code: 'bigcommerce' }
        json.id = this.platformId
        json.credentials = [{
          credential: { code: 'bigcommerce_shop_hash' },
          value: this.StoreId
        }, {
          credential: { code: 'bigcommerce_token' },
          value: this.StoreToken
        }]
      }
      return json
    }
  },
  data () {
    return {
      option: null,
      fabricAccount: null,
      shopifyStoreName: null,
      shopifyApiKey: null,
      ClientName: null,
      StoreToken: null,
      StoreId: null,
      platformId: null
    }
  },
  methods: {
    announce () {
      this.$emit('input', this.json)
    }
  },
  mounted () {
    if (this.task.info && this.task.info.selectedOption) {
      this.option = this.task.info.selectedOption
    }
    if (this.accounts && this.accounts.length) {
      const optionFields = this.accounts.find(el => el.platform.code === this.option)
      if (this.option === 'fabric') {
        this.platformId = optionFields.id
        optionFields.credentials.forEach(credential => {
          Object.keys(credential).forEach(key => {
            if (key === 'fabric_account' && credential[key].values[0].value) {
              this.fabricAccount = credential[key].values[0].value
            }
          })
        })
      }
      if (this.option === 'shopify') {
        this.platformId = optionFields.id
        optionFields.credentials.forEach(credential => {
          Object.keys(credential).forEach(key => {
            if (key === 'shopify_shop_name' && credential[key].values[0].value) {
              this.shopifyStoreName = credential[key].values[0].value
            }
            if (key === 'shopify_token' && credential[key].values[0].value) {
              this.shopifyApiKey = credential[key].values[0].value
            }
          })
        })
      }
      if (this.option === 'bigcommerce') {
        this.platformId = optionFields.id
        optionFields.credentials.forEach(credential => {
          Object.keys(credential).forEach(key => {
            if (key === 'bigcommerce_shop_hash' && credential[key].values[0].value) {
              this.StoreId = credential[key].values[0].value
            }
            if (key === 'bigcommerce_token' && credential[key].values[0].value) {
              this.StoreToken = credential[key].values[0].value
            }
          })
        })
      }
      this.announce()
    }
  },
  props: {
    task: {
      type: Object,
      require: true
    },
    accounts: {
      type: Array,
      require: true
    }
  }
}
</script>
