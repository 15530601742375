<template>
  <p :class="presenter.textClass">{{ presenter.service }}</p>
</template>

<script>
export default {
  computed: {
    presenter () {
      const presenter = {
        textClass: null
      }
      // if we have a shipping provider identifier, show only the carrier name (which should be present)
      if (this.shippingProviderIdentifier !== null) {
        presenter.service = this.shippingMethod.carrier.name
      // otherwise, check for the presencne of a shipping method name
      } else if (this.shippingMethod.name) {
        presenter.service = this.shippingMethod.name
      // if shipping method is not set, check for a carrier
      } else if ('carrier' in this.shippingMethod && this.shippingMethod.carrier.name) {
        presenter.service = this.shippingMethod.carrier.name
      // finally, just give up
      } else {
        presenter.service = '- Not Specified -'
        presenter.textClass = 'has-text-grey-light is-italic'
      }
      return presenter
    }
  },
  props: ['shippingMethod', 'shippingProviderIdentifier']
}
</script>
