import Index from './views/Index'
import List from './views/List'
import Detail from './views/Detail'
import ScoreList from './views/ScoreList'
import ScoreDetail from './views/ScoreDetail'

export default [{
  path: '/reports/compliance',
  name: 'reports.compliance',
  component: Index,
  children: [{
    path: '/reports/compliance/scorecard',
    name: 'reports.compliance.scorecard',
    component: List
  }, {
    path: '/reports/compliance/scorecard/:id',
    name: 'reports.compliance.detail',
    component: Detail
  }]
}, {
  path: '/reports/compliance/score',
  name: 'reports.compliance.score',
  component: ScoreList
}, {
  path: '/reports/compliance/score/:id/:connection_id/:report_id',
  name: 'reports.compliance.score.detail',
  component: ScoreDetail
}]
