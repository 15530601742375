<template>
  <form @submit.prevent="save">
    <div class="columns">
      <div class="column">
        <div class="has-background-white rounded-xl">
          <!-- Loaded -->
          <div class="pa-lg pb-xl">
            <div class="mb-lg">
              <p class="has-text-weight-bold mb-xs">Default Customer Service &amp; Returns Allowance</p>
              <div class="has-text-grey is-size-7">
                <p class="mb-md">
                  Setting a customer service &amp; returns allowance on a connection
                  automatically applies an adjustment to all new incoming supplier invoices, decreasing the
                  amount {{ context.partner.name }} owes to the supplier.
                </p>
                <p>
                  This allowance is typically applied to offset the costs of returns (and other
                  costs associated with servicing a customer).
                  To set a default returns allowance for any new connections automatically, enter a value between
                  0 and 10%.
                </p>
              </div>
            </div>
            <div class="columns">
              <div class="column is-half">
                <p class="has-text-weight-bold mb-md">Default Allowance</p>
                <b-field>
                  <b-numberinput
                    type="text"
                    size="is-medium"
                    v-model="value"
                    step="0.25"
                    min="0"
                    max="10"
                    @input="isDirty = 1"
                  ></b-numberinput>
                </b-field>
              </div>
            </div>
          </div>
          <div class="border-t">
            <div class="columns is-vcentered is-marginless">
              <div class="column is-hidden-mobile">
                <div class="px-sm has-text-grey-light">
                  <i v-if="isDirty">Click 'Save Preference' to save your changes</i>
                </div>
              </div>
              <div class="column is-narrow">
                <div class="px-sm">
                  <b-button
                    native-type="submit"
                    type="is-primary rounded-lg"
                    :disabled="!ready || saving || !isDirty"
                  >Save Preference</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/retailer-preferences/'
    },
    json () {
      return {
        preference: { id: this.preference.id },
        value: this.value
      }
    },
    ready () {
      return (
        this.value !== null &&
        parseFloat(this.value) >= 0 &&
        parseFloat(this.value) <= 10
      )
    }
  },
  data () {
    return {
      isDirty: false,
      preference: null,
      value: null,
      errors: [],
      saving: false
    }
  },
  methods: {
    save () {
      this.saving = true
      this.$http.post(this.endpoint, this.json).then(response => {
        this.isDirty = false
        this.$buefy.toast.open({ type: 'is-success', message: 'Preference updated!' })
      }).catch(error => {
        if (error.response && error.response.status === 400) {
          this.errors = error.response.data
        }
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    const preference = this.$utils.find(this.preferences, ['code', 'default_returns_allowance'])
    if (typeof preference !== 'undefined') {
      this.preference = preference
      if (this.preference.retailer_preference) {
        this.value = parseFloat(preference.retailer_preference.value)
      }
    }
  },
  props: ['preferences', 'retailerPreferences']
}
</script>
