<template>
  <div>
    <form @submit.prevent="save">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Update Connection Fees</p>
        </header>
        <section class="modal-card-body">
          <!-- General Errors -->
          <div v-if="error" class="mb-md">
            <b-message type="is-danger">{{ error }}</b-message>
          </div>
          <!-- Alert -->
          <div v-if="costMethod === 'revshare'" class="mb-lg">
            <b-message v-if="costMethod === 'revshare'" type="is-info">When you use the <strong>commission</strong>
              cost method, we apply the commission rate to the retail price to determine the appropriate cost price.
              A commission rate of {{ commissionPercent }}% means {{ 100 - commissionPercent }}% of the retail
              price will be reserved for the supplier.
            </b-message>
          </div>
          <!-- Cost Method -->
          <div class="mb-md">
            <div class="columns">
              <div class="column">
                <b-field label="Cost Method">
                  <b-select v-model="costMethod" expanded size="is-medium">
                    <option value="specified_in_order">Specified in Order</option>
                    <option value="permit_cost">Permit Cost</option>
                    <option value="revshare">Commission</option>
                    <option value="commission_profile">Commission Profile</option>
                  </b-select>
                </b-field>
              </div>
              <div v-if="costMethod === 'revshare'" class="column">
                <b-field label="Commission Rate"
                  :message="('commission' in errors) ? errors['commission'][0] : 'Enter value between 1-100'">
                  <b-numberinput
                    v-model="commissionPercent"
                    size="is-medium"
                    min=1
                    step="0.50"
                  ></b-numberinput>
                </b-field>
              </div>
              <div v-if="costMethod === 'commission_profile'" class="column">
                <CommissionProfileSelect v-model="commissionProfile" size="is-medium"/>
              </div>
            </div>
          </div>
          <!-- Payment Terms -->
          <b-field label="Payment Terms">
            <b-select v-model="paymentTerms" expanded size="is-medium">
              <option value="net7">Net 7</option>
              <option value="net15">Net 15</option>
              <option value="net30">Net 30</option>
              <option value="net45">Net 45</option>
              <option value="net60">Net 60</option>
            </b-select>
          </b-field>
        </section>
        <footer class="modal-card-foot space-between">
          <b-button @click="$parent.close()">Close</b-button>
          <b-button
            type="is-primary"
            native-type="submit"
            :loading="saving"
            :disabled="!ready || saving">
            Save Settings
          </b-button>
        </footer>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CommissionProfileSelect from '../../CommissionProfiles/ProfileSelect'
export default {
  components: {
    CommissionProfileSelect
  },
  data () {
    return {
      costMethod: null,
      commissionPercent: null,
      paymentTerms: null,
      saving: false,
      error: null,
      errors: {},
      commissionProfile: null
    }
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/connections/' + this.connection.id + '/fees/' + this.connection.connection_fees.id + '/'
    },
    body () {
      const body = {
        net_price_method: this.costMethod,
        payment_terms: this.paymentTerms
      }
      // handle the revshare pct for revshare connectinos only
      if (this.costMethod === 'revshare') {
        body.revshare_fraction = this.commissionPercent / 100
      } else if (this.costMethod === 'commission_profile') {
        body.net_price_method = null
      } else {
        body.revshare_fraction = null
      }
      return body
    },
    ready () {
      if (!this.costMethod) return false
      if (!this.paymentTerms) return false
      if (this.costMethod === 'revshare' && !this.commissionPercent) return false
      if (this.costMethod === 'commission_profile' && !this.commissionProfile) return false
      return true
    }
  },
  methods: {
    async save () {
      this.saving = true
      try {
        await this.saveConnectionFees()
        await this.saveConnection()
        this.$emit('saved:connection')
        this.$buefy.toast.open({ type: 'is-success', message: 'Settings updated!' })
        this.$parent.close()
      } catch (error) {
        this.$buefy.toast.open({ type: 'is-danger', message: 'An unexpected error occurred!' })
      }
      this.saving = false
    },
    saveConnectionFees () {
      this.$http.patch(this.endpoint, this.body).then(response => {
      }).catch(error => {
        if (error.response && 'non_field_errors' in error.response.data) {
          this.error = error.response.data.non_field_errors[0]
        }
      })
    },
    saveConnection () {
      const endpoint = this.context.route + '/connections/' + this.connection.id + '/'
      const body = {
        commission_profile: null
      }
      if (this.costMethod === 'commission_profile') {
        body.commission_profile = {
          id: this.commissionProfile.id
        }
      }
      this.$http.patch(endpoint, body)
    }
  },
  mounted () {
    if (this.connection.connection_fees.net_price_method) {
      this.costMethod = this.connection.connection_fees.net_price_method
    } else if (this.connection.commission_profile) {
      this.costMethod = 'commission_profile'
      this.commissionProfile = this.connection.commission_profile
    }
    if (this.costMethod === 'revshare') {
      this.commissionPercent = parseFloat(this.connection.connection_fees.revshare_fraction) * 100
    }
    this.paymentTerms = this.connection.connection_fees.payment_terms
  },
  props: ['connection']
}
</script>
