<template>
  <div class="has-background-light pa-sm px-md rounded-lg mb-xs">
    <div class="columns">
      <div class="column">
        <p>{{ method.name }}</p>
      </div>
      <div class="column is-narrow">
        <span v-if="saving" class="has-text-grey-light">Removing...</span>
        <span v-else-if="wasRemoved" class="has-text-grey-light">Removed</span>
        <a v-else href="#" @click.prevent="removeMethod">Remove</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/shippingaccounts/' + this.accountId + '/'
    },
    body () {
      return {
        shipping_account_shipping_methods: [{
          shipping_method: {
            id: this.method.id,
            delete: 1
          }
        }]
      }
    }
  },
  data () {
    return {
      saving: false,
      wasRemoved: false
    }
  },
  methods: {
    removeMethod () {
      this.saving = true
      this.$http.patch(this.endpoint, this.body).then(response => {
        this.wasRemoved = true
        this.$emit('method:removed')
      }).finally(() => {
        this.saving = false
      })
    }
  },
  props: ['accountId', 'method']
}
</script>
