export default {
  paid: {
    label: 'Completed',
    tooltip: 'Payments that are completed',
    filters () {
      return {
        preset: 'completed',
        search: {},
        facets: {
          status: [{
            label: 'Completed',
            value: 'paid'
          }]
        },
        timestamps: {}
      }
    }
  },
  draft: {
    label: 'Draft',
    tooltip: 'Payments that are in progress',
    filters () {
      return {
        preset: 'draft',
        search: {},
        facets: {
          status: [{
            label: 'Draft',
            value: 'draft'
          }]
        },
        timestamps: {}
      }
    }
  },
  all: {
    label: 'All',
    tooltip: 'All payments',
    filters () {
      return {
        preset: 'all',
        search: {},
        facets: {},
        timestamps: {}
      }
    }
  }
}
