<template>
  <div>
    <div v-if="!loading && marketplace !== null">
      <!-- Status -->
      <div class="mb-lg has-text-centered">
        <h4 class="has-text-weight-bold mb-xs">Profile Status</h4>
        <p :class="status.indicatorClass" class="has-text-weight-bold mb-xs">{{ status.label }}</p>
      </div>
      <!-- Visibility -->
      <div class="mb-lg has-text-centered">
        <h4 class="has-text-weight-bold mb-xs">Profile Visibility</h4>
        <p :class="visibility.indicatorClass" class="has-text-weight-bold mb-xs">{{ visibility.label }}</p>
        <p class="is-size-7 has-text-grey">{{ visibility.tooltip }}</p>
      </div>
      <!-- Actions (Inactive Marketplace)-->
      <div v-if="marketplace.status !== 'active'">
        <b-button
          class="mb-sm"
          type="is-primary"
          :disabled="processing || requested"
          :loading="processing"
          @click.prevent="requestApproval"
          expanded
        >Request Approval</b-button>
        <!-- <router-link class="button is-primary is-block mb-md is-fullwidth is-outlined" :to="{ name: 'network.preview', params: { id: marketplace.id } }">Preview Profile</router-link> -->
        <router-link class="button is-primary is-block mb-md is-fullwidth is-outlined" :to="{ name: 'network.profile' }">Update Profile</router-link>
      </div>
    </div>
    <div v-else-if="!loading && marketplace === null">

    </div>
    <div v-else>
      <loading message="Loading..." size="is-large"/>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MarketplacePresenters from '@/mixins/presenters/Marketplace'

export default {
  data () {
    return {
      processing: false,
      requested: false
    }
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      if (this.marketplace !== null) {
        return this.context.route + '/marketplaces/' + this.marketplace.id + '/request-approval/'
      }
      return null
    },
    loading () {
      return this.marketplace === null
    }
  },
  methods: {
    requestApproval () {
      this.processing = true
      this.$http.get(this.endpoint).then(() => {
        this.requested = true
      }).finally(() => {
        this.processing = false
      })
    }
  },
  mixins: [MarketplacePresenters],
  props: ['marketplace']
}
</script>
