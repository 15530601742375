<template>
  <form @submit.prevent="connect">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Connect to Shopify</p>
      </header>
      <section class="modal-card-body">
        <div class="has-text-centered">
          <p class="mb-md"><img src="https://res.cloudinary.com/revcascade/image/upload/v1579302346/static/integrations/shopify.png" width="180" height="60" /></p>
          <p class="mb-lg">
            {{ app.platform }} has connected to Shopify to allow you to import your products, sync inventory, and more. To
            get started, enter your store name below.
          </p>
          <div class="mb-xl">
            <b-field>
              <b-input v-model="shop" @input="isDirty = true" expanded></b-input>
              <p class="control">
                <span class="button is-static">.myshopify.com</span>
              </p>
            </b-field>
          </div>
          <div v-if="showPermissions">
            <p class="mb-sm has-text-weight-bold">Permissions & Scopes</p>
            <p class="mb-md has-text-grey">
              {{ app.platform }} requires the following data permissions to
              provide our services to Shopify customers. If you have any
              questions, please file a <a href="#" @click.prevent="$store.dispatch('supportRequest')" class="has-text-info">support request</a>.
            </p>
            <table class="table is-fullwidth is-size-7 mb-lg">
              <thead>
                <tr>
                  <th>Component</th>
                  <th>Scopes</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Products</td>
                  <td>
                    <p class="has-text-weight-bold">read_products, write_products</p>
                    <p class>
                      Required to access product & collection information.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>Inventory</td>
                  <td>
                    <p class="has-text-weight-bold">read_inventory, write_inventory, read_locations</p>
                    <p class>
                      Required to access inventory levels and locations.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>Orders</td>
                  <td>
                    <p class="has-text-weight-bold">read_orders, write_orders</p>
                    <p class>
                      Required to create orders and track status
                      of existing orders.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>Fulfillments</td>
                  <td>
                    <p class="has-text-weight-bold">read_fulfillments, write_fulfillments</p>
                    <p class>
                      Required to access order shipments & fulment services.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>Shipping</td>
                  <td>
                    <p class="has-text-weight-bold">read_shipping, write_shipping</p>
                    <p class>
                      Required to access fulfillment services & carrier info.
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- Toggle -->
          <a href="#"
            @click.prevent="showPermissions = !showPermissions"
            v-text="(showPermissions) ? 'Hide permissions' : 'Show required permissions'" />
        </div>
      </section>
      <footer class="modal-card-foot" style="justify-content:space-between">
        <button class="button" type="button" @click="$parent.close()">Close</button>
        <input type="submit" class="button is-primary" value="Connect" :disabled="!ready">
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  computed: {
    ...mapGetters(['app', 'context', 'services']),
    ready () {
      return !this.isDirty
    },
    endpoint () {
      return true
    },
    nonce () {
      return Math.random().toString(36).slice(2)
    },
    redirectUrl () {
      return this.app.url + this.$route.path
    },
    installUrl () {
      if (this.shop === null) return null
      return 'https://' + this.shop + '.myshopify.com/admin/oauth/authorize?' +
        '&client_id=' + this.services.shopify.clientId +
        '&scope=' + this.services.shopify.scopes +
        '&redirect_uri=' + this.redirectUrl +
        '&state=' + this.nonce
    }
  },
  data () {
    return {
      isDirty: false,
      showPermissions: false,
      shop: 'revcascade-demo-store'
    }
  },
  methods: {
    connect () {
      sessionStorage.setItem('shopifyState', this.nonce)
      window.location.href = this.installUrl
    }
  },
  mounted () {

  },
  props: []
}
</script>
