<template>
  <div class="has-background-white pa-md rounded-lg mb-md">
    <div class="columns is-vcentered is-mobile">
      <div class="column">
        <p v-if="label !== null">
          <span class="is-uppercase has-text-weight-bold pr-sm">{{ label }}</span>
          <router-link
            v-if="showLinkToDetail"
            class="has-text-info is-size-8"
            :to="{name: `reports.performance.${this.dimension === 'variant' ? 'product' : 'partner'}-scorecard`}">
            View Detail
          </router-link>
        </p>
      </div>
      <div class="column is-narrow">
        <download-csv :data="tableData" name="data.csv" :labels="fileLabels">
          <b-button size="is-small" icon-left="download">Export</b-button>
        </download-csv>
      </div>
    </div>
    <b-table
      class="is-size-7"
      :data="tableData"
      :columns="tableOptions.tableColumns"
      :default-sort="sortField"
      :default-sort-direction="sortDirection"
      :bordered="tableOptions.bordered"
      :narrowed="tableOptions.narrowed"
      :striped="tableOptions.striped"
      :hoverable="tableOptions.hoverable"
      :paginated="paginated"
      :per-page="perPage"
      :pagination-position="tableOptions.paginationPosition"
      :sort-icon-size="tableOptions.sortIconSize"
      :loading="loading">
      <template #empty>
        <section class="section">
          <div class="content has-background-white has-text-centered">
            <p>No orders found.</p>
          </div>
        </section>
      </template>
    </b-table>
  </div>
</template>
<script>
export default {
  data () {
    return {
      tableOptions: {
        tableColumns: [
          {
            field: 'label',
            label: 'date',
            sortable: true,
            customSort: this.dimension === 'date' ? this.sortByDate : undefined
          },
          {
            field: 'retailer_identifier',
            label: 'Merchant SKU',
            sortable: true,
            visible: this.dimension === 'variant'
          },
          {
            field: 'connections',
            label: '# Suppliers with Orders',
            numeric: true,
            sortable: true,
            visible: !this.hideConnections,
            customSort: this.sortByConnections
          },
          {
            field: 'orders_count',
            label: '# Orders',
            numeric: true,
            sortable: true,
            customSort: this.sortByOrders
          },
          {
            field: 'wholesale_cost',
            label: 'Cost',
            numeric: true,
            sortable: true,
            customSort: this.sortByCost
          },
          {
            field: 'estimated_gmv',
            label: 'Est. GMV',
            numeric: true,
            sortable: true,
            customSort: this.sortByGmv
          },
          {
            field: 'estimated_aov',
            label: 'Est. AOV',
            numeric: true,
            sortable: true,
            customSort: this.sortByAov
          }
        ],
        bordered: false,
        narrowed: true,
        striped: true,
        hoverable: true,
        paginationPosition: 'bottom',
        sortIconSize: 'small'
      },
      dimensionLabel: '',
      fileLabels: {
        label: this.dimensionLabel,
        retailer_identifier: 'Merchant SKU',
        connections: 'Connections',
        orders_count: '# Sales',
        wholesale_cost: 'Cost',
        estimated_gmv: 'Estimated GMV',
        estimated_aov: 'Estimated AOV'
      }
    }
  },
  props: {
    reportData: {
      type: Object,
      default () {
        return {}
      }
    },
    loading: {
      type: Boolean,
      default: false
    },
    dimension: {
      type: String,
      default: 'date'
    },
    label: {
      type: String
    },
    paginated: {
      type: Boolean,
      default: true
    },
    perPage: {
      type: Number,
      default: 10
    },
    hideConnections: {
      type: Boolean,
      default: false
    },
    showLinkToDetail: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    sortField: function () {
      switch (this.dimension) {
        case 'date':
        case 'order_status':
          return 'label'
        case 'brand':
          return 'wholesale_cost'
        default:
          return 'estimated_gmv'
      }
    },
    sortDirection: function () {
      return (this.dimension === 'date' || this.dimension === 'order_status') ? 'asc' : 'desc'
    },
    tableData: function () {
      if (this.reportData[this.dimension] !== undefined) {
        return Object.keys(this.reportData[this.dimension]).map((item) => {
          let label = 'label'
          switch (this.dimension) {
            case 'brand':
            case 'retailer':
              label = 'name'
              break
            case 'variant':
              label = 'identifier'
              break
          }
          return {
            label: this.dimension === 'date'
              ? this.$dates(this.reportData[this.dimension][item][label]).format('MMM D, YYYY')
              : this.reportData[this.dimension][item][label],
            retailer_identifier: this.reportData[this.dimension][item].retailer_identifier,
            connections: this.$numbers(this.reportData[this.dimension][item].connections).format({
              thousandSeparated: true,
              mantissa: 0
            }),
            estimated_gmv: this.$numbers(this.reportData[this.dimension][item].estimated_gmv).formatCurrency({
              thousandSeparated: true,
              mantissa: 2
            }),
            estimated_aov: this.$numbers(this.reportData[this.dimension][item].estimated_aov).formatCurrency({
              thousandSeparated: true,
              mantissa: 2
            }),
            orders_count: this.$numbers(this.reportData[this.dimension][item].orders_count).format({
              thousandSeparated: true,
              mantissa: 0
            }),
            wholesale_cost: this.$numbers(this.reportData[this.dimension][item].wholesale_cost).formatCurrency({
              thousandSeparated: true,
              mantissa: 2
            }),
            key: item
          }
        })
      }
      return []
    }
  },
  watch: {
    dimension () {
      this.setDimensionLabel()
    }
  },
  methods: {
    setDimensionLabel () {
      this.dimensionLabel = this.dimension
        .split('_')
        .map(word => word[0].toUpperCase() + word.substring(1))
        .join(' ')
      this.fileLabels.label = this.dimensionLabel
      this.$set(
        this.tableOptions.tableColumns[0],
        'label',
        this.dimensionLabel
      )
    },
    sortByDate (a, b, isAsc) {
      if (isAsc) {
        return b.key > a.key ? -1 : 0
      } else {
        return b.key < a.key ? -1 : 0
      }
    },
    sortByConnections (a, b, isAsc) {
      if (isAsc) {
        return parseInt(b.connections) > parseInt(a.connections) ? -1 : 0
      } else {
        return parseInt(b.connections) < parseInt(a.connections) ? -1 : 0
      }
    },
    sortByOrders (a, b, isAsc) {
      if (isAsc) {
        return parseInt(b.orders_count) > parseInt(a.orders_count) ? -1 : 0
      } else {
        return parseInt(b.orders_count) < parseInt(a.orders_count) ? -1 : 0
      }
    },
    sortByCost (a, b, isAsc) {
      const numA = parseFloat(a.wholesale_cost.replace('$', '').replace(',', ''))
      const numB = parseFloat(b.wholesale_cost.replace('$', '').replace(',', ''))
      if (isAsc) {
        return numB > numA ? -1 : 0
      } else {
        return numB < numA ? -1 : 0
      }
    },
    sortByGmv (a, b, isAsc) {
      const numA = parseFloat(a.estimated_gmv.replace('$', '').replace(',', ''))
      const numB = parseFloat(b.estimated_gmv.replace('$', '').replace(',', ''))
      if (isAsc) {
        return numB > numA ? -1 : 0
      } else {
        return numB < numA ? -1 : 0
      }
    },
    sortByAov (a, b, isAsc) {
      const numA = parseFloat(a.estimated_aov.replace('$', '').replace(',', ''))
      const numB = parseFloat(b.estimated_aov.replace('$', '').replace(',', ''))
      if (isAsc) {
        return numB > numA ? -1 : 0
      } else {
        return numB < numA ? -1 : 0
      }
    }
  },
  mounted () {
    this.setDimensionLabel()
  }
}
</script>
