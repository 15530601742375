import dates from '@/assets/js/dates'

export const status = {
  label: 'Status',
  key: 'status',
  icon: 'pulse',
  tooltip: 'Limit results based on webhook status.',
  options: [{
    value: 'success',
    label: 'Successful'
  }, {
    value: 'failure',
    label: 'Failed'
  }, {
    value: 'skip',
    label: 'Skipped'
  }]
}

export const topic = {
  label: 'Topic',
  key: 'topic',
  icon: 'select-group',
  tooltip: 'Limit results based on webhook topic.',
  options: [{
    value: 'order.updated',
    label: 'Fullfillment Webhook'
  }, {
    value: 'product.updated',
    label: 'Inventory Sync Webhook'
  }]
}

export const createdAt = {
  label: 'Created At',
  key: 'created_at',
  icon: 'calendar-outline',
  tooltip: 'Limit results based on when the return was registered.',
  options: dates
}
