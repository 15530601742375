import dates from 'dayjs'

export default {
  recent: {
    label: 'Recent',
    tooltip: 'Invoices registered within the last 60 days',
    filters () {
      const limit = dates().subtract(60, 'day').unix()
      return {
        preset: 'recent',
        search: {},
        facets: {},
        timestamps: {
          created_at: [limit, null]
        }
      }
    }
  }
}
