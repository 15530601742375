<template>
  <div>
    <div class="columns">
      <div v-if=!this.isOnboarding class="column is-one-fifth">
        <p class="has-text-weight-bold is-size-6 mb-sm">Carrier Mapping</p>
        <p class="has-text-grey is-size-7">Configure the mapping of your carriers from Shopify with available carriers in Marketplace.</p>
      </div>
      <div class="column">
        <div class="has-background-white rounded-lg">
          <div class="pa-lg">
            <div v-if="!loading && carrierMappings.length > 0">
              <b-table
                :data="carrierMappings"
                :hoverable="true"
                :narrowed="true"
                :loading="loading"
                :mobile-cards="false"
                class="is-size-7">
                <b-table-column
                  label="ID"
                  cell-class="nowrap"
                  centered
                  width="32"
                  v-slot="props">
                  <p class="has-text-grey-light">{{ props.row.id }}</p>
                </b-table-column>
                <b-table-column
                  label="Name"
                  header-class="nowrap"
                  cell-class="nowrap"
                  v-slot="props">
                  <a class="has-text-weight-bold has-text-info" @click.prevent="showMappingModal(props.row)">{{`${props.row.value} => ${props.row.carrier.name}` }}</a>
                </b-table-column>
                <b-table-column
                  label="Shopify Carrier"
                  header-class="nowrap"
                  cell-class="nowrap"
                  v-slot="props">
                  <p>{{ props.row.value }}</p>
                </b-table-column>
                <b-table-column
                  :label="app.platform + ' Carrier'"
                  header-class="nowrap"
                  cell-class="nowrap"
                  v-slot="props">
                  <p>{{ props.row.carrier.name }}</p>
                </b-table-column>
              </b-table>
            </div>
            <div v-else-if="!loading && carrierMappings.length === 0" class="has-background-white-ter  pa-md rounded-lg">
              <p class="has-text-grey-light">No carrier mappings found</p>
            </div>
            <div v-else class="has-background-white-ter rounded-lg">
              <p class="has-text-grey-light is-size-8 pa-md">Loading...</p>
            </div>
          </div>
          <div :class="[!isOnboarding && 'border-t']" >
            <div class="columns is-vcentered is-marginless">
              <div class="column">
                <div class="px-sm has-text-grey-light">
                  <p v-if="this.isOnboarding">{{ addCarrierText }}</p>
                  <i v-else>{{ addCarrierText }}</i>
                </div>
              </div>
              <div class="column is-narrow">
                <div class="px-sm">
                  <b-button
                  type="is-primary rounded-lg"
                  :disabled="loading"
                  @click="onAddCarrierClick"
                  >Add Carrier</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Create/Edit Carrier Mapping Modal -->
    <b-modal
      :active.sync="showCarrierMappingModal"
      has-modal-card>
      <CarrierMappingModal
        :carrierMapping="carrierMapping"
        :account="account"
        :carrierMappings="carrierMappings"
        @carriers:updated="fetchCarrierMappings"
      />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CarrierMappingModal from './CarrierMappingModal'

export default {
  components: {
    CarrierMappingModal
  },
  computed: {
    ...mapGetters(['app', 'context']),
    addCarrierText () {
      return `Click 'Add Carrier' to map a Shopify Carrier to a ${this.app.productName} Carrier.`
    }
  },
  data () {
    return {
      showCarrierMappingModal: false,
      showMarketplaceCarrierModal: false,
      carrierMappings: [],
      carrierMapping: null,
      loading: false
    }
  },
  methods: {
    fetchCarrierMappings () {
      this.loading = true
      const endpoint = this.context.route + '/platform-accounts/' + this.account.id + '/carrier-mappings/'
      this.$http.get(endpoint).then(response => {
        this.carrierMappings = response.data.results
      }).finally(() => { this.loading = false })
    },
    showMappingModal (row) {
      this.carrierMapping = row
      this.showCarrierMappingModal = true
    },
    onAddCarrierClick () {
      this.carrierMapping = null
      this.showCarrierMappingModal = true
    }
  },
  mounted () {
    this.fetchCarrierMappings()
  },
  props: {
    account: Object,
    isOnboarding: {
      type: Boolean,
      default: false
    }
  }
}
</script>
