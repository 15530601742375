<template>
<div>
  <div class="mb-sm">
    <!-- Intro -->
    <div class="mb-xxl">
      <div class="content">
        <p class="has-text-weight-bold">Introduction</p>
        <p>
          {{ app.productName }} uses <b>proposals</b> to help you
          &amp; your merchant partners agree on assortment, costs, &amp; pricing. After you
          submit a proposal, a merchandiser or buyer
          from your partner will review and decide whether to approve your products.
        </p>
        <p>
          Submitting a proposal takes just a few steps, so let's get started.
        </p>
      </div>
    </div>
    <!-- Navigate -->
    <div class="pt-lg">
      <div class="columns is-centered">
        <div class="column">
          <b-button
            type="is-light rounded-lg"
            size="is-medium"
            @click="goBack"
            expanded
            ><b-icon icon="arrow-left"></b-icon>
          </b-button>
        </div>
        <div class="column">
          <b-button
            type="is-primary rounded-lg"
            size="is-medium"
            expanded
            @click="goForward"
          >Get Started</b-button>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['app'])
  },
  methods: {
    goBack () {
      this.$router.push({ name: 'catalog' })
    },
    goForward () {
      this.$emit('navigate', 'Build')
    }
  }
}
</script>
