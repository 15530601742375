<template>
  <div>
    <div v-if="!loading && elligibleLocations.length > 0">
      <div class="columns is-vcentered">
        <div class="column">
          <b-select
            v-model="location"
            placeholder="Select a Location"
            size="is-medium"
            expanded>
            <option
              v-for="location in elligibleLocations"
              :value="location"
              :key="location.id">
              {{ location.name }}
            </option>
          </b-select>
        </div>
        <div class="column is-narrow">
          <a href="#" @click.prevent="load">Refresh</a>
        </div>
      </div>

    </div>
    <div v-else-if="!loading && elligibleLocations.length === 0">

    </div>
    <!-- Loading -->
    <div v-else>
      <div class="columns is-vcentered">
        <div class="column">
          <b-select
            placeholder="Communicating with Shopify..."
            size="is-medium"
            expanded
            disabled
          ></b-select>
        </div>
        <div class="column is-narrow">
          <a href="#" @click.prevent class="has-text-grey-lighter">Refresh</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/shopify/' + this.account.id + '/locations/'
    },
    elligibleLocations () {
      if (this.locations.length === 0) return []
      const elligible = []
      this.locations.forEach(location => {
        // discard inactive locations
        if (location.active === false) return
        // discard incomplete locations
        if (location.address1 === null) return
        if (location.city === null) return
        if (location.province_code === null) return
        if (location.zip === null) return
        if (location.country_code === null) return
        elligible.push(location)
      })
      return elligible
    }
  },
  data () {
    return {
      loading: false,
      locations: [],
      location: null
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint).then(response => {
        this.locations = response.data.locations
      }).finally(() => {
        this.loading = false
      })
    }
  },
  mounted () {
    this.load()
  },
  props: ['account'],
  watch: {
    location (value) {
      if (value !== null) {
        this.$emit('input', value)
      }
    }
  }
}
</script>
