import dates from '@/assets/js/dates'

export const createdAt = {
  label: 'Payment Created At',
  key: 'created_at',
  icon: 'calendar',
  tooltip: 'Limit results based on when the payment was created.',
  options: dates
}

export const recordedAt = {
  label: 'Payment Recorded At',
  key: 'recorded_at',
  icon: 'calendar',
  tooltip: 'Limit results based on when the check was recorded.',
  options: dates
}

export const status = {
  label: 'Payment Status',
  key: 'status',
  icon: 'pulse',
  tooltip: 'The status of the payment.',
  options: [{
    value: 'draft',
    label: 'Draft'
  }, {
    value: 'paid',
    label: 'Paid'
  }]
}

export const connection = {
  label: 'Connection',
  key: 'connection_id',
  icon: 'hubspot',
  tooltip: 'This is a tooltip'
}
