<template>
  <form @submit.prevent="save">
    <div class="columns">
      <div class="column">
        <div class="has-background-white rounded-lg">
          <!-- Loaded -->
          <div v-if="preferences !== null" class="pa-lg pb-xl">
            <div class="mb-lg">
              <p class="has-text-weight-bold mb-xs">Default Fulfillment SLA</p>
              <div class="has-text-grey is-size-7">
                <p>
                  The Fulfillment SLA governs how long suppliers have to ship orders before they are considered late.
                </p>
              </div>
            </div>
            <div class="mb-lg">
              <div class="columns">
                <div class="column is-half">
                  <b-field>
                    <b-select
                      type="text"
                      v-model="value"
                      size="is-medium"
                      expanded
                      @input="isDirty = 1">
                      <option v-for="option in options" :key="option.id" :value="option">{{ option.name }}</option>
                    </b-select>
                  </b-field>
                </div>
              </div>
            </div>
            <div v-if="this.value">
              <table class="table is-fullwidth is-narrow is-size-7">
                <tr>
                  <th>Order Placed</th>
                  <th>Must Ship By</th>
                </tr>
                <tr>
                  <td>Monday (before 1 PM ET), Friday (after 1PM ET), Saturday, Sunday</td>
                  <td>
                    <span v-if="value.value === '24'">Tuesday</span>
                    <span v-else-if="value.value === '48'">Wednesday</span>
                    <span v-else-if="value.value === '72'">Thursday</span>
                    <span v-else></span>
                  </td>
                </tr>
                <tr>
                  <td>Monday (after 1 PM ET)</td>
                  <td>
                    <span v-if="value.value === '24'">Wednesday</span>
                    <span v-else-if="value.value === '48'">Thursday</span>
                    <span v-else-if="value.value === '72'">Friday</span>
                    <span v-else></span>
                  </td>
                </tr>
                <tr>
                  <td>Wednesday (after 1 PM ET), Thursday (before 1PM ET)</td>
                  <td>
                    <span v-if="value.value === '24'">Friday</span>
                    <span v-else-if="value.value === '48'">Monday</span>
                    <span v-else-if="value.value === '72'">Tuesday</span>
                    <span v-else></span>
                  </td>
                </tr>
                <tr>
                  <td>Thursday (after 1 PM ET), Friday (before 1PM ET)</td>
                  <td>
                    <span v-if="value.value === '24'">Monday</span>
                    <span v-else-if="value.value === '48'">Tuesday</span>
                    <span v-else-if="value.value === '72'">Wednesday</span>
                    <span v-else></span>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div class="border-t">
            <div class="columns is-vcentered is-marginless">
              <div class="column is-hidden-mobile">
                <div class="px-sm has-text-grey-light">
                  <i v-if="isDirty">Click 'Save Preference' to save your changes</i>
                </div>
              </div>
              <div class="column is-narrow">
                <div class="px-sm">
                  <b-button
                    native-type="submit"
                    type="is-primary rounded-lg"
                    :disabled="!ready || saving || !isDirty"
                  >Save Preference</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  computed: {
    ...mapGetters(['app', 'context']),
    endpoint () {
      return this.context.route + '/retailer-preferences/'
    },
    json () {
      return {
        preference: { id: this.preference.id },
        option: { id: this.value.id }
      }
    },
    ready () {
      return (this.value !== null)
    }
  },
  data () {
    return {
      isDirty: false,
      preference: null,
      options: [],
      value: null,
      errors: [],
      saving: false
    }
  },
  methods: {
    save () {
      this.saving = true
      this.$http.post(this.endpoint, this.json).then(response => {
        this.isDirty = false
        this.$buefy.toast.open({ type: 'is-success', message: 'Preference updated!' })
      }).catch(error => {
        if (error.response && error.response.status === 400) {
          this.errors = error.response.data
        }
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    this.preference = this.$utils.find(this.preferences, ['code', 'default_fulfillment_sla'])
    if (typeof this.preference !== 'undefined') {
      // set the options
      this.options = this.preference.options.map(option => {
        return {
          id: option.id,
          name: option.name,
          value: option.value
        }
      })
      if (this.preference.retailer_preference) {
        this.value = this.$utils.find(this.options, ['value', this.preference.retailer_preference.value])
      }
    }
  },
  props: ['preferences', 'retailerPreferences']
}
</script>
