<template>
<div>
  <div>
    <p class="mb-md">
      {{ connection.retailer.name }} requires you to upload your products in groups called "proposals".
      Proposals help {{ connection.retailer.name }} keep your products organized so they can review them efficiently.
    </p>
    <p class="mb-md">
      When you submit a proposal to {{ connection.retailer.name }}, a merchandising representative from
      {{ connection.retailer.name }} will review your products and provide any feedback. If all is well,
      {{ connection.retailer.name }} will approve your proposal and begin the process of setting your
      products live on their website. Please
      review the video below to learn more about submitting proposals.
    </p>
    <!-- Video -->
    <div class="my-xl" style="position: relative; padding-bottom: 55.3125%; height: 0;">
      <iframe src="https://www.loom.com/embed/582d5acff25442ceb6cdb19df14734ac?sid=4719aace-90af-4a8e-94ba-177b7e4c3843"
        frameborder="0"
        webkitallowfullscreen
        mozallowfullscreen
        allowfullscreen
        style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"
      ></iframe>
    </div>
    <!-- Next Steps -->
    <div class="mt-lg">
      <p class="has-text-weight-bold mb-sm">Next Steps</p>
      <div>
        You may upload a proposal whenever you are ready. For now, you may click
        'Complete Task' below to continue onboarding.
      </div>
    </div>
    <!-- Navigate -->
    <div class="pt-lg">
      <div class="columns is-centered">
        <div class="column">
          <b-button
            type="is-light rounded-lg"
            size="is-medium"
            @click="goBack"
            expanded
            ><b-icon icon="arrow-left" /></b-button>
        </div>
        <div class="column">
          <b-button
            type="is-primary rounded-lg"
            size="is-medium"
            expanded
            :disabled="saving"
            @click="goForward">Complete Task</b-button>
        </div>
      </div>
    </div>
  </div>
  <!-- Reset Progress Modal -->
  <b-modal
    has-modal-card
    scroll="keep"
    :active.sync="showResetProgressModal"
    :can-cancel="['x']"
    ><ResetProgressModal :task="task" :proposal="proposal" @task:reset="$emit('task:reset')" />
  </b-modal>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import ResetProgressModal from './ResetProgressModal'

export default {
  components: {
    ResetProgressModal
  },
  computed: {
    ...mapGetters(['api', 'app', 'context'])
  },
  data () {
    return {
      saving: false,
      errors: {}
    }
  },
  methods: {
    goForward () {
      this.saving = true
      const endpoint = this.context.route + '/assigned-tasks/' + this.task.id + '/complete/'
      this.$http.put(endpoint).then(() => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Task Completed!' })
        this.$router.push({ name: 'onboarding.tasks', params: { connection: this.connection.id } })
      }).catch(() => {
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please try again!' })
      }).finally(() => {
        this.saving = false
      })
    },
    goBack () {
      this.$router.push({ name: 'onboarding.tasks', params: { connection: this.$route.params.connection } })
    }
  },
  props: {
    connection: {
      type: Object,
      required: true
    },
    task: {
      type: Object,
      required: true
    }
  }
}
</script>
