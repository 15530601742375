<template>
  <div class="section">
    <div class="container">

      <!-- Page Header -->
      <div class="columns is-mobile is-vcentered">
        <div class="column">
          <h4 class="is-size-4 mb-lg">User Settings</h4>
        </div>
      </div>

      <!-- General Info -->
      <div class="border-b pb-lg mb-lg">
        <basic-info />
      </div>

      <!-- Security Info -->
      <div class="pb-lg mb-lg">
        <reset-password />
      </div>

    </div>
  </div>
</template>

<script>
import BasicInfo from '../components/BasicInfo'
import ResetPassword from '../components/ResetPassword'
import { mapGetters } from 'vuex'

export default {
  components: {
    BasicInfo,
    ResetPassword
  },
  computed: {
    ...mapGetters([])
  },
  data () {
    return {

    }
  },
  methods: {

  },
  mounted () {

  }
}
</script>
