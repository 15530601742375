<template>
  <div>
    <transition name="fade" mode="out-in">
      <component :is="step"
        :account="account"
        :import-job-id="importJobId"
        :settings="settings"
        @change:step="goto"
        @track:job="trackImportJob"
        @complete="complete"
        @change:setting="changeSetting"
        @clear:settings="clearSettings"
      ></component>
    </transition>
  </div>
</template>

<script>
import Introduction from '../../components/Products/Introduction'
import Import from '../../components/Products/Import'
import DropshipCost from '../../components/Products/DropshipCost'
import ScheduleImport from '../../components/Products/ScheduleImport'

export default {
  components: {
    Introduction,
    Import,
    DropshipCost,
    ScheduleImport
  },
  data () {
    return {
      loading: true,
      step: 'introduction',
      importJobId: null,
      settings: {},
      errors: {}
    }
  },
  methods: {
    goto (step) {
      document.querySelector('#app').scrollIntoView({ behavior: 'smooth' })
      this.step = step
    },
    trackImportJob (jobId) {
      this.importJobId = jobId
    },
    complete () {
      this.$router.push({ name: 'shopify.onboard.orders' })
    },
    changeSetting (setting) {
      this.$set(this.settings, setting.key, setting.value)
    },
    clearSettings () {
      this.settings = {}
    }
  },
  mounted () {
    this.$emit('update:step', 3)
  },
  props: ['account']
}
</script>
