<template>
<div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">
        {{ credential !== null ? 'Edit' : 'Add' }} Connection Attribute
      </p>
    </header>
    <section class="modal-card-body">
      <b-message type="is-danger" v-if="isError">
        Attribute Value already exist
      </b-message>
      <b-field v-if="credential === null"
               label="Attribute">
        <b-select
          v-model="selectedAttribute"
          placeholder="Select Attribute"
          expanded>
          <optgroup label="Platform">
            <option :value="availableAttribute.name">
              {{ availableAttribute.name }}
            </option>
          </optgroup>
          <optgroup label="Custom">
            <option v-for="attribute in connectionCredentials"
              :key="attribute.code"
              :value="attribute.name">
              {{ attribute.name }}
            </option>
            <option value="New Custom Attribute">
              Add a new custom attribute...
            </option>
          </optgroup>
        </b-select>
      </b-field>
      <div>
      <!-- Attribute Name -->
      <b-field v-if="credential !== null ||
               selectedAttribute === 'New Custom Attribute'"
               label="Attribute">
        <b-input v-model="attributeName"
          :value="attributeName">
        </b-input>
      </b-field>
      <!-- Attribute Value -->
      <b-field
        :type="('value' in errors) ? 'is-danger' : ''"
        label="Attribute Value"
        :message="('value' in errors) ? errors['value'][0] : ''">
        <b-input type="textarea"
          v-model="value"
          maxlength="2048"
          expanded/>
        </b-field>
      </div>
    </section>
    <footer class="modal-card-foot space-between">
      <b-button @click="$parent.close()">
        Close
      </b-button>
      <b-button
        type="is-primary"
        :disabled="saving || !ready"
        :loading="saving"
        @click="save">
        Save
      </b-button>
    </footer>
  </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return (this.credential)
        ? this.context.route + '/connections/' + this.connection.id + '/credentials/' + this.credential.id + '/'
        : this.context.route + '/connections/' + this.connection.id + '/credentials/'
    },
    method () {
      return (this.credential) ? 'patch' : 'post'
    },
    body () {
      return {
        name: this.attributeName,
        code: this.code,
        value: this.value,
        delete: 0
      }
    },
    ready () {
      // For edit
      if (this.credential !== null) {
        return this.credential.name !== this.attributeName ||
        this.credential.value !== this.value
      }
      // For Add
      return (this.selectedAttribute && this.value) ||
      (this.attributeName && this.value)
    }
  },
  data () {
    return {
      loading: false,
      attributeName: '',
      code: '',
      value: '',
      connectionCredentials: [],
      saving: false,
      errors: {},
      isError: false,
      existingAttributes: [],
      availableAttribute: {
        name: 'Packing Slip Text',
        code: 'packing_slip_bottom_text'
      },
      selectedAttribute: null
    }
  },
  methods: {
    createCode () {
      this.code = (this.attributeName === this.availableAttribute.name)
        ? this.availableAttribute.code
        : this.attributeName.toLowerCase().replace(/ /g, '_')
    },
    validateAttribute () {
      if (this.existingAttributes?.filter(({ name, value }) =>
        name.toLowerCase() === this.attributeName.toLowerCase() &&
        value.toLowerCase() === this.value.toLowerCase()).length > 0) {
        this.isError = true
        return true
      }
    },
    save () {
      this.isError = false
      if (this.selectedAttribute !== null && this.selectedAttribute !== 'New Custom Attribute') {
        this.attributeName = this.selectedAttribute
      }
      if (this.validateAttribute()) {
        return
      }
      this.createCode()
      this.saving = true
      this.$http[this.method](this.endpoint, this.body).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Attribute saved.' })
        this.$emit('saved:credential')
        this.$parent.close()
      }).catch(error => {
        this.errors = error.response.data
      }).finally(() => {
        this.saving = false
      })
    },
    getConnectionCredentials () {
      const endpoint = `${this.context.route}/connection-credentials/`
      this.$http.get(endpoint).then(response => {
        this.connectionCredentials = response.data.results.filter(({ name }) =>
          name !== this.availableAttribute.name)
      }).catch(error => {
        this.$buefy.toast.open({ type: 'is-danger', message: error })
      })
    }
  },
  mounted () {
    this.getConnectionCredentials()
    this.existingAttributes = this.connection.connection_credentials
    if (this.credential) {
      this.attributeName = this.credential.name
      this.code = this.credential.code
      this.value = this.credential.value
    }
  },
  props: {
    connection: {
      type: Object,
      required: true
    },
    credential: {
      type: Object,
      default: null
    }
  }
}
</script>
