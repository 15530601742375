<template>
<div>
  <div class="has-background-white pa-xl rounded-lg mb-lg">
    <div class="columns is-centered">
      <div class="column is-two-thirds">
        <div class="border-b pb-md">
          <div class="columns">
            <div class="column"></div>
            <div class="column">
              <p class="has-text-centered"><b>Workflow Steps</b></p>
            </div>
            <div class="column">
              <p class="has-text-right"><a href="#" @click.prevent="$emit('refresh')">Refresh</a></p>
            </div>
          </div>
        </div>

        <!-- Upload Template -->
        <div class="border-b py-lg">
          <div class="columns is-vcentered space-between is-mobile">
            <div class="column">
              <p class="has-text-weight-bold is-size-5">Import Products</p>
              <p class="has-text-grey-light">Import product data attributes and images.</p>
            </div>
            <div class="column is-narrow">
              <!-- Feeds loaded & import feed exists -->
              <div v-if="!loadingFeeds && importFeed !== null" class="has-text-right">
                <div>
                  <p v-if="importFeed.status === 'success'" class="has-text-weight-bold has-text-success">Done</p>
                  <p v-else-if="importFeed.status === 'processing'" class="has-text-weight-bold has-text-info">Processing</p>
                  <p v-else-if="importFeed.status === 'failure'" class="has-text-weight-bold has-text-danger">Review Required</p>
                  <p v-else class="has-text-weight-bold has-text-warning">Pending</p>
                </div>
                <p class="is-size-7">
                  {{ $dates.utc(importFeed.processed_at).local().format('MMM D') }} &bull;
                  <router-link :to="{ name: 'proposal.tasks.feeds', params: { id: proposal.id } }" class="has-text-info">Review</router-link>
                </p>
              </div>
              <!-- Feeds loaded, but no import feed exists -->
              <div v-else-if="!loadingFeeds && importFeed === null">
                <router-link
                  :to="{ name: 'proposal.tasks.import', params: { id: proposal.id } }"
                  class="button is-primary is-medium"
                >Go</router-link>
              </div>
              <!-- Loading State -->
              <div v-else>
                <b-button type="is-primary" size="is-medium" :disabled="true" :loading="true">Go</b-button>
              </div>
            </div>
          </div>
        </div>
        <!-- Build Proposal -->
        <div class="border-b py-lg">
          <div class="columns is-vcentered space-between is-mobile">
            <div class="column">
              <p class="has-text-weight-bold is-size-5">Build Proposal</p>
              <p class="has-text-grey-light">Build your proposal based on an import.</p>
            </div>
            <div class="column is-narrow">
              <!-- Build Feed Submitted -->
              <div v-if="!loadingFeeds && buildFeed !== null">
                <!-- Build Feed Successful -->
                <div v-if="buildFeed.status === 'success' && proposal.active_items > 0" class="has-text-right">
                  <p class="has-text-weight-bold has-text-success">Done</p>
                  <p class="is-size-7">
                    <Date :value="buildFeed.completed_at" :showYear="false" /> &bull;
                    <router-link :to="{ name: 'proposal.tasks.feeds', params: { id: proposal.id } }" class="has-text-info">Review</router-link>
                  </p>
                </div>
                <!-- Build Feed Failed -->
                <div v-else-if="buildFeed.status === 'success' && proposal.active_items === 0" class="has-text-right">
                  <p class="has-text-weight-bold has-text-warning">Requires Review</p>
                  <p class="is-size-7">
                    <Date :value="buildFeed.completed_at" :showYear="false" /> &bull;
                    <router-link :to="{ name: 'proposal.tasks.build.results', params: { id: proposal.id } }" class="has-text-info">Review</router-link>
                  </p>
                </div>
                <!-- Build Feed Processing -->
                <div v-else class="has-text-right">
                  <p class="has-text-weight-bold has-text-grey">Processing</p>
                  <p class="is-size-7">
                    <Date :value="buildFeed.processed_at" :showYear="false" /> &bull;
                    <router-link :to="{ name: 'proposal.tasks.feeds', params: { id: proposal.id } }" class="has-text-info">Review</router-link>
                  </p>
                </div>
              </div>
              <!-- Ready to Build -->
              <div v-else-if="!loadingFeeds && importFeed !== null && importFeed.status === 'success'">
                <router-link class="button is-primary is-medium"
                  :to="{ name: 'proposal.tasks.build', params: { id: proposal.id } }"
                >Go</router-link>
              </div>
              <!-- Not Ready to Build -->
              <div v-else-if="loadingFeeds">
                <b-button type="is-primary" size="is-medium" :disabled="true" :loading="true">Go</b-button>
              </div>
              <!-- Loading -->
              <div v-else>
                <b-button type="is-primary" size="is-medium" :disabled="true">Go</b-button>
              </div>
            </div>
          </div>
        </div>
        <!-- Review Compatibility -->
        <div class="border-b py-lg">
          <div class="columns is-vcentered space-between is-mobile">
            <div class="column">
              <p class="has-text-weight-bold is-size-5">Review Attribute Compatibility</p>
              <p class="has-text-grey-light">Review attribute compatibility scores.</p>
            </div>
            <div class="column is-narrow">
              <!-- Done -->
              <div v-if="!loadingFeeds && buildFeed !== null && proposal.active_items > 0 && proposal.item_failed_count === 0" class="has-text-right">
                <p class="has-text-weight-bold has-text-success">Passed</p>
                <p class="is-size-7">
                  {{ $dates.utc(proposal.created_at).local().format('MMM D') }} &bull;
                  <router-link :to="{ name: 'proposal.tasks.compatibility', params: { id: proposal.id } }" class="has-text-info">Review</router-link>
                </p>
              </div>
              <!-- Requires Review -->
              <div v-else-if="!loadingFeeds && proposal.item_failed_count > 0" class="has-text-right">
                <p class="has-text-weight-bold has-text-warning has-text-right">Requires Review</p>
                <p class="is-size-7">
                  {{ $dates.utc(proposal.created_at).local().format('MMM D') }} &bull;
                  <router-link :to="{ name: 'proposal.tasks.compatibility', params: { id: proposal.id } }" class="has-text-info">Review</router-link>
                </p>
              </div>
              <!-- Not Ready -->
              <div v-else-if="!loadingFeeds">
                <b-button type="is-primary" size="is-medium" :disabled="true">Go</b-button>
              </div>
              <!-- Loading -->
              <div v-else>
                <b-button type="is-primary" size="is-medium" :disabled="true" :loading="true">Go</b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Submit -->
    <div class="py-xl">
      <p class="has-text-grey-light has-text-centered mb-lg">
        You must complete all of the above tasks before you can submit this proposal.
      </p>
      <div class="columns is-centered">
        <div class="column is-one-quarter">
          <router-link
            v-if="canViewProducts"
            class="button is-light is-medium is-fullwidth"
            :to="{ name: 'proposal.detail.items', params: { id: this.proposal.id } }"
          >Review Products</router-link>
          <b-button
             v-else
             type="is-light"
             size="is-medium"
             expanded
             :disabled="!canViewProducts"
          >Review Products</b-button>
        </div>
        <div class="column is-one-quarter">
          <b-button
            @click="submitProposal"
            type="is-primary"
            size="is-medium"
            :loading="saving"
            :disabled="!ready || saving"
            expanded
          >Submit Proposal</b-button>
        </div>
      </div>
    </div>
    <!-- Archive -->
    <div class="py-xl has-text-centered">
      <a href="#" @click.prevent="showArchiveModal = true" class="has-text-danger">Archive Proposal</a>
    </div>
  </div>
  <!-- Archive Modal -->
  <b-modal
    has-modal-card
    :active.sync="showArchiveModal"
    :can-cancel="['escape', 'x']"
    ><ArchiveModal :proposal="proposal" />
  </b-modal>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import ArchiveModal from '@/components/Models/Proposal/Modals/Archive'

export default {
  components: {
    ArchiveModal
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/proposals/' + this.$route.params.id + '/'
    },
    ready () {
      return (
        !this.loadingFeeds &&
        this.proposal.active_items > 0 &&
        this.proposal.item_failed_count === 0
      )
    },
    importFeed () {
      if (this.feeds.length === 0) return null
      const feed = this.feeds.find(feed => {
        return feed.info.proposal_feed_purpose === 'import'
      })
      return (typeof feed !== 'undefined') ? feed : null
    },
    buildFeed () {
      if (this.feeds.length === 0) return null
      const feed = this.feeds.find(feed => {
        return feed.info.proposal_feed_purpose === 'build'
      })
      return (typeof feed !== 'undefined') ? feed : null
    },
    hasZeroItems () {
      return (
        this.buildFeed !== null &&
        (parseInt(this.proposal.item_count) - parseInt(this.proposal.item_rejected_count) === 0)
      )
    },
    canViewProducts () {
      return this.buildFeed !== null && this.buildFeed.status === 'success' && this.proposal.active_items > 0
    }
  },
  data () {
    return {
      saving: false,
      showArchiveModal: false
    }
  },
  methods: {
    submitProposal () {
      this.saving = true
      const body = {
        status: 'proposed'
      }
      this.$http.patch(this.endpoint, body).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Proposal Submitted!' })
        this.$router.push({ name: 'proposal.tasks.submitted' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    if (this.proposal.status === 'draft') {
      this.$emit('refresh')
    }
  },
  props: ['proposal', 'feeds', 'loadingFeeds']
}
</script>
