<template>
  <span class="has-text-weight-bold" :class="presenter.class">{{ presenter.label }}</span>
</template>

<script>
export default {
  computed: {
    presenter () {
      switch (this.type) {
        case 'new_product':
          return { class: 'has-text-success', label: 'New Products' }
        case 'image_update':
          return { class: 'has-text-info', label: 'Image Update' }
        case 'attribute_update':
          return { class: 'has-text-grey-dark', label: 'Attribute Update' }
        default:
          return { class: 'has-text-warning', label: 'Cost Update' }
      }
    }
  },
  props: ['type']
}
</script>
