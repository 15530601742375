<template>
  <div class="section">
    <div class="container">
      <!-- Page Header -->
      <div class="mb-sm">
        <div class="columns is-mobile is-vcentered">
          <div class="column">
            <h4 class="is-size-4">Compliance Reporting</h4>
          </div>
          <div class="column is-one-quarter">
            <div class="field">
              <b-select size="is-medium" v-model="selectedMonth" expanded>
                <option v-for="month in months" :value="month" :key="month.start" selected>
                  {{ month.label }}
                </option>
              </b-select>
            </div>
           </div>
        </div>
      </div>
      <!-- Router View -->
      <transition name="fade" mode="out-in">
        <keep-alive>
          <router-view :daterange="dates"></router-view>
        </keep-alive>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    dates () {
      const range = {}
      if (this.selectedMonth !== null) {
        const matchedMonth = this.$utils.find(this.months, o => {
          return o.label === this.selectedMonth.label
        })
        range.start = matchedMonth.start
        range.end = matchedMonth.end
      }
      return range
    }

  },
  data () {
    return {
      selectedMonth: null,
      months: []
    }
  },
  methods: {
  },
  created () {
    // configure right now
    const now = this.$dates.utc()
    const months = []
    let i
    // add last 12 months
    for (i = 0; i < 12; i++) {
      const past = now.clone().startOf('month').subtract(i, 'month')
      months.push({
        label: past.format('MMM YYYY'),
        start: past.startOf('month').unix(),
        end: past.endOf('month').unix()
      })
    }
    this.months = months
    this.selectedMonth = this.months[0]
  }
}
</script>
