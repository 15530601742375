<template>
  <InspectionModal
    :endpoint="endpoint"
    :loadDetail="loadDetail"
    :seed="selectedJob">
    <template v-slot:default="props">
      <form>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Inspect Job</p>
          </header>
          <section class="modal-card-body">
            <!-- Loaded -->
            <div v-if="!props.loading && props.record !== null" class="">
              <p class="has-text-weight-bold mb-sm">Job Details</p>
              <table class="table is-fullwidth is-striped is-narrow is-size-7 border">
                <tbody>
                  <tr>
                    <th style="width:164px">ID</th>
                    <td>{{ props.record.id }}</td>
                  </tr>
                  <tr>
                    <th>Job</th>
                    <td><ModuleName :module="props.record.module_name" /></td>
                  </tr>
                  <tr>
                    <th>Module</th>
                    <td>{{ props.record.module_name }}</td>
                  </tr>
                  <tr>
                    <th>Started At</th>
                    <td>
                      <span v-if="props.record.start_time">{{ $dates.utc(props.record.start_time).local().format('MMM D, YYYY @ h:mm A') }}</span>
                      <span v-else class="has-text-grey">--</span>
                    </td>
                  </tr>
                  <tr>
                    <th>Finished At</th>
                    <td>
                      <span v-if="props.record.finish_time">{{ $dates.utc(props.record.finish_time).local().format('MMM D, YYYY @ h:mm A') }}</span>
                      <span v-else class="has-text-grey">--</span>
                    </td>
                  </tr>
                  <tr>
                    <th>Runtime</th>
                    <td><Runtime :start="props.record.start_time" :finish="props.record.finish_time" /></td>
                  </tr>
                  <tr>
                    <th>Success</th>
                    <td>{{ props.record.total_success }}</td>
                  </tr>
                  <tr>
                    <th>Errors</th>
                    <td>{{ props.record.total_failure }}</td>
                  </tr>
                </tbody>
              </table>
              <!-- Error Logs -->
              <div v-if="props.record.error_logs.length > 0">
                <p class="has-text-weight-bold mb-sm">Errors ({{ props.record.error_logs.length }})</p>
                <table class="table is-fullwidth is-striped is-size-7 is-narrow border">
                  <thead>
                    <tr>
                      <th>Message</th>
                      <th class="has-text-centered" style="widht:96px">Count</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="error in props.record.error_logs" :key="error.id">
                      <td>{{ error.message }}</td>
                      <td class="has-text-centered">{{ error.count }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-- Loading -->
            <div v-else>
              <loading message="Loading..." />
            </div>
          </section>
          <footer class="modal-card-foot">
            <button class="button" type="button" @click="$parent.close()">Close</button>
          </footer>
        </div>
      </form>
    </template>
  </InspectionModal>
</template>

<script>
import InspectionModal from '../InspectionModal'
import { mapGetters } from 'vuex'
import ModuleName from '@/components/Presenters/Job/ModuleName'
import Runtime from '@/components/Presenters/Job/Runtime'

export default {
  components: {
    InspectionModal,
    ModuleName,
    Runtime
  },
  data () {
    return {
      loadDetail: true
    }
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      if (this.selectedJob !== null) {
        return this.context.route + '/jobs/' + this.selectedJob.id + '/'
      }
      return null
    }
  },
  methods: {

  },
  mounted () {

  },
  props: ['selectedJob']
}
</script>
