<template>
  <div>
    <form @submit.prevent="save">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title"></p>
        </header>
        <section class="modal-card-body">
          <div v-for="variant in items" :key="variant.id">
            <div class="columns is-vcentered">
              <div class="column is-one-third">
                <div class="pa-sm has-background-white-ter">
                  {{ variant.sku }}
                </div>
              </div>
              <div class="column">
                <b-input v-model="variant[field]" name="name" placeholder="Value" expanded></b-input>
              </div>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot space-between">
          <button class="button" type="button" @click="$parent.close()">Close</button>
          <b-button type="is-primary" native-type="submit" :loading="saving" :disabled="saving">Save</b-button>

        </footer>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/variants/'
    },
    json () {
      const json = []
      this.items.forEach((variant, i) => {
        if (variant[this.field] === null || variant[this.field] === '') {
          return
        }
        const obj = {
          id: variant.id,
          attributes: [{
            attribute: {
              code: this.map[this.field]
            },
            value: variant[this.field]
          }]
        }
        json.push(obj)
      })
      return json
    }
  },
  data () {
    return {
      saving: false,
      items: null,
      map: {
        sku: 'brand_identifier',
        cost: 'dropship_cost',
        shipping: 'shipping_and_handling',
        map: 'map',
        msrp: 'msrp',
        color: 'color',
        size: 'size'
      }
    }
  },
  methods: {
    save () {
      this.saving = true
      this.$http.patch(this.endpoint, this.json).then(response => {
        this.$emit('variants:updated')
        this.$parent.close()
      }).finally(() => {
        this.saving = false
      })
    }
  },
  created () {
    this.items = this.$utils.cloneDeep(this.variants)
  },
  mounted () {

  },
  props: ['field', 'variants']
}
</script>
