<template>
  <div>
    <!-- Content -->
    <div class="">
      <p class="has-text-weight-bold mb-sm">Primary Business Contact</p>
      <p class="has-text-grey mb-md">
        The Primary Business contact is the key contact for all partner business inquiries
        and may receive critical email communications from {{ app.platform }}
        regarding product updates and new business inquiries.
      </p>
      <b-field
        class="py-sm"
        :type="('connections_manager' in errors) ? 'is-danger' : ''"
        :message="('connections_manager' in errors) ? errors['connections_manager'][0] : ''">
        <b-select
          v-model="businessContact"
          placeholder="Select User"
          expanded>
          <option v-for="user in users" :key="user.id" :value="user.id">
            {{ user.first_name }} {{ user.last_name }}
          </option>
        </b-select>
      </b-field>
      <p>Need to invite a <a href="#" @click.prevent="$emit('invite:user')" class="has-text-info">new teammate?</a></p>
    </div>
    <!-- Navigate -->
    <div class="py-lg">
      <div class="columns is-centered">
        <div class="column">
          <b-button
            type="is-light rounded-lg"
            size="is-medium"
            @click="goBack"
            expanded
            ><b-icon icon="arrow-left"></b-icon>
          </b-button>
        </div>
        <div class="column">
          <b-button
            type="is-primary rounded-lg"
            size="is-medium"
            expanded
            @click="goForward"
            :disabled="saving"
            :loading="saving"
          >Save &amp; Continue</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['app', 'context'])
  },
  data () {
    return {
      businessContact: null,
      saving: false,
      errors: {}
    }
  },
  methods: {
    goBack () {
      this.$emit('navigate', 'Intro')
    },
    goForward () {
      this.saving = true
      const endpoint = this.context.route + '/'
      const json = {
        connections_manager: { id: this.businessContact }
      }
      this.$http.patch(endpoint, json).then(response => {
        this.saving = false
        this.$buefy.toast.open({ type: 'is-success', message: 'Contact Updated!' })
        this.$emit('contact:updated')
        this.$emit('navigate', 'MerchandisingContact')
      }).catch(error => {
        if (error.response && error.response.status === 400) {
          this.errors = error.response.data
        }
      })
    }
  },
  mounted () {
    this.businessContact = (this.supplier.connections_manager) ? this.supplier.connections_manager.id : null
  },
  props: {
    connection: {
      type: Object,
      required: true
    },
    task: {
      type: Object,
      required: true
    },
    supplier: {
      type: Object,
      required: true
    },
    users: {
      type: Array,
      required: true
    }
  }
}
</script>
