<template>
  <div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Publish Proposal</p>
      </header>
      <section class="modal-card-body">
        <!-- Platform Account Loaded -->
        <div v-if="!loading && platformAccount !== null">
          <b-field label="Export Template">
            <b-select
              v-model="exportTemplate"
              size="is-medium"
              placeholder="Select an export template..."
              expanded>
              <option v-for="template in exportTemplates" :key="template.id" :value="template">{{ template.name }}</option>
            </b-select>
          </b-field>
        </div>
        <!-- No Platform Account Found -->
        <div v-else-if="!loading && platformAccount === null">
          <p class="has-text-centered has-text-grey-light">
            Sorry, but you do not have any elligible integrations!
          </p>
        </div>
        <!-- Loading -->
        <div v-else>
          <loading message="Loading platform accounts..." />
        </div>
      </section>
      <footer class="modal-card-foot space-between" >
        <b-button @click="$parent.close()">Close</b-button>
        <b-button
          type="is-primary"
          :disabled="!ready || saving"
          :loading="saving"
          @click="save">Publish</b-button>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/' + this.platformAccount.platform.code + '/' + this.platformAccount.id + '/push-products/'
    },
    ready () {
      return this.platformAccount !== null && this.exportTemplate !== null
    },
    body () {
      const json = {
        proposal_id: this.proposal.id
      }
      if (this.exportTemplate) {
        if (this.platformAccount.platform.code === 'shopify') {
          json.shopify_template_id = this.exportTemplate.id
        } else if (this.platformAccount.platform.code === 'bigcommerce') {
          json.bigcommerce_template_id = this.exportTemplate.id
        }
      }
      return json
    },
    loading () {
      return this.loadingPlatformAccounts || this.loadingExportTemplates
    }
  },
  data () {
    return {
      loadingPlatformAccounts: true,
      loadingExportTemplates: false,
      platformAccount: null,
      exportTemplate: null,
      exportTemplates: [],
      saving: false
    }
  },
  methods: {
    load () {
      const endpoint = this.context.route + '/platform-accounts/?platform=shopify,bigcommerce'
      this.loadingPlatformAccounts = true
      this.$http.get(endpoint).then(response => {
        if (response.data.count > 0) {
          this.platformAccount = response.data.results[0]
          this.loadExportTemplates()
        }
      }).finally(() => {
        this.loadingPlatformAccounts = false
      })
    },
    loadExportTemplates () {
      this.loadingExportTemplates = true
      const endpoint = this.context.route + '/' + this.platformAccount.platform.code + '/' + this.platformAccount.id + '/templates/'
      const query = {
        params: {
          direction: 'export'
        }
      }
      this.$http.get(endpoint, query).then(response => {
        response.data.results.forEach(result => {
          if (result.code !== '') {
            this.exportTemplates.push(result)
          }
        })
        this.loadingExportTemplates = false
      })
    },
    save () {
      this.saving = true
      this.$http.post(this.endpoint, this.body).then(response => {
        this.$emit('proposal:published')
        this.$buefy.toast.open({ type: 'is-success', message: 'Publish attempt scheduled!' })
        this.$parent.close()
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    this.load()
  },
  props: ['proposal']
}
</script>
