<template>
  <div>
    <!-- Title Bar -->
    <div class="has-background-white rounded-lg px-lg py-md mb-xxs">
      <div class="columns is-vcentered is-mobile">
        <div class="column">
          <div class="has-text-weight-bold is-size-7">
            <p v-if="context.isMerchant()">ITEM SKUs & OFFERS</p>
            <p v-else>ITEM SKUs</p>
          </div>
        </div>
        <div class="column is-one-third has-text-right">
          <b-field class="is-pulled-right">
            <b-radio-button v-model="view" size="is-small" native-value="inventory">Inventory</b-radio-button>
            <!-- <b-radio-button v-model="view" size="is-small" native-value="attributes">Attributes</b-radio-button>
            <b-radio-button v-model="view" size="is-small" native-value="images">Images</b-radio-button> -->
          </b-field>
        </div>
      </div>
    </div>
    <!-- Variant List -->
    <div class="has-background-white rounded-lg">
      <div>
        <b-table
          :data="variants"
          :mobile-cards="false"
          :loading="loading || variants.length === 0"
          striped
          class="is-tall is-size-7">
          <template>
            <!-- Identifiers -->
            <b-table-column
              label="Item"
              cell-class="nowrap"
              v-slot="props">
              <Identifiers
                :variant="props.row"
                :showImage="true"
                nameInteraction="none"
                imageInteraction="none"
                @inspect="inspectVariant"
                @navigate="navigateToDetail"
              ></Identifiers>
            </b-table-column>
            <b-table-column
              label="Last Updated"
              :visible="columns.includes('inventory')"
              centered
              width="128"
              v-slot="props">
              <p v-if="props.row.inventory.inventory_updated_at !== null">
                {{ $dates.utc(props.row.inventory.inventory_updated_at).local().format('MMM D, YYYY') }}
                <br/>
                <span class="has-text-grey is-size-8">
                  {{ $dates.utc(props.row.inventory.inventory_updated_at).local().format('h:mm A') }}
                </span>
              </p>
              <p v-else><i class="has-text-grey-light">Never</i></p>
            </b-table-column>
            <!-- Availability -->
            <b-table-column
              label="Availability"
              :visible="columns.includes('inventory')"
              cell-class="nowrap"
              centered
              width="200"
              v-slot="props">
              <div v-if="props.row.inventory.discontinued_updated_at">
                <p class="has-text-weight-bold has-text-centered has-text-danger">Discontinued</p>
                <p class="has-text-grey is-size-8">$dates.utc(props.row.discontinued_updated_at).local().format('MMM D, YYYY')</p>
              </div>
              <div v-else-if="props.row.inventory.inventory === 0 && props.row.inventory.estimated_availability_date === null">
                <p class="has-text-weight-bold has-text-centered has-text-danger">0</p>
                <p class="has-text-grey is-size-8">Unknown Return</p>
              </div>
              <div v-else-if="props.row.inventory.inventory === 0 && props.row.inventory.estimated_availability_date !== null">
                <p class="has-text-weight-bold has-text-centered has-text-danger">0</p>
                <p class="has-text-grey is-size-8">Returns {{ $dates.utc(props.row.inventory.estimated_availability_date).local().format('MMM D, YYYY') }}</p>
              </div>
              <div v-else-if="props.row.inventory.inventory > 0">
                <p class="has-text-weight-bold">
                  <span v-if="props.row.inventory.inventory === 9999" class="has-text-grey is-italic">Not Managed</span>
                  <span v-else>{{ props.row.inventory.inventory }}</span>
                </p>
                <p class="has-text-grey is-size-8">Available Now</p>
              </div>
            </b-table-column>
          </template>
          <template #empty>
            <div class="py-xxl has-text-grey has-text-centered">
              {{ (loading) ? "Loading data..." : 'No variants found!' }}
            </div>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CdnTransform from '@/mixins/CdnTransform'
import Identifiers from '@/components/Models/Variant/Identifiers'
export default {
  components: {
    Identifiers
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/products/' + this.product.id + '/variants/'
    },
    columns () {
      if (this.view === 'inventory') return ['sku', 'inventory']
      return ['sku']
    }
  },
  data () {
    return {
      loading: true,
      view: 'inventory',
      variants: [],
      selected: [],
      detail: null
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint).then(response => {
        this.variants = response.data.results
      }).finally(() => {
        this.loading = false
      })
    },
    editVariants (field) {
      this.showVariantEditModal = true
      this.editField = field
    },
    inspectVariant (variant) {
      this.detail = variant
    },
    navigateToDetail (variant) {
      this.$router.push({ name: 'catalog.item', params: { id: variant.product_id, item: variant.id } })
    }
  },
  mounted () {
    this.load()
  },
  mixins: [CdnTransform],
  props: ['product']
}
</script>
