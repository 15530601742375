<template>
  <div class="section">
    <div class="container">
      <!-- Page Header -->
      <div class="mb-md">
        <div class="columns is-vcentered">
          <div class="column is-one-third">
            <breadcrumbs :history="[{ label: 'Settings', name: 'settings'}]" current="Notifications" />
          </div>
          <div class="column is-one-third">
            <h1 class="has-text-centered is-size-4">Notifications</h1>
          </div>
        </div>
      </div>
      <div>
        <!-- Reporting Notifications -->
        <!-- Hiding until BE is in place -->
        <!-- <div class="border-b pb-lg mb-lg">
          <ReportingNotifications />
        </div> -->
        <!-- Onboarding Push Notifications -->
        <div v-if="context.isMerchant()" class="border-b pb-lg mb-lg">
          <OnboardingNotifications :pushNotifications="notifications" :isLoading="loading"/>
        </div>
        <!-- Transactions Notifications -->
        <div class="border-b pb-lg mb-lg">
          <TransactionsNotifications :pushNotifications="notifications" :isLoading="loading"/>
        </div>
        <!-- Digest Notifications -->
        <div>
          <DigestNotifications />
        </div>
        <!-- Shopify Notifications -->
        <div v-if="context.isMerchant() && hasShopify" class="pt-lg mt-lg border-t">
          <ShopifyNotifications :pushNotifications="notifications" :isLoading="loading"/>
        </div>
        <!-- Shopify Push Order Error Notification -->
        <div v-if="context.isSupplier() && hasShopify" class="pt-lg mt-lg border-t">
          <PushErrorNotifications :pushNotifications="notifications" :isLoading="loading"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
// import ReportingNotifications from '../components/Notifications/ReportingNotifications.vue'
import OnboardingNotifications from '../components/Notifications/OnboardingNotifications'
import TransactionsNotifications from '../components/Notifications/PushNotifications'
import DigestNotifications from '../components/Notifications/DigestNotifications'
import ShopifyNotifications from '../components/Notifications/ShopifyNotifications'
import PushErrorNotifications from '../components/Notifications/PushErrorNotifications'

export default {
  components: {
    // ReportingNotifications,
    OnboardingNotifications,
    TransactionsNotifications,
    DigestNotifications,
    ShopifyNotifications,
    PushErrorNotifications
  },
  computed: {
    ...mapGetters(['context']),
    loading () {
      return (this.loadingNotifications || this.loadingPlatformAccounts)
    },
    notificationsEndpoint () {
      return this.context.route + '/push-notifications/'
    },
    platformAccountsEndpoint () {
      return this.context.route + '/platform-accounts/'
    },
    hasShopify () {
      let hasShopify = false
      if (this.platformAccounts.length) {
        this.platformAccounts.forEach(account => {
          if (account.platform.code === 'shopify') hasShopify = true
        })
      }
      return hasShopify
    },
    query () {
      return {
        has_recipient_email: 1
      }
    }
  },
  data () {
    return {
      loadingNotifications: true,
      loadingPlatformAccounts: true,
      notifications: [],
      platformAccounts: []
    }
  },
  methods: {
    load () {
      this.$http.get(this.notificationsEndpoint, { params: this.query }).then(response => {
        this.notifications = response.data.results
      }).finally(() => {
        this.loadingNotifications = false
      })
    },
    loadPlatformAccounts () {
      this.$http.get(this.platformAccountsEndpoint).then(response => {
        this.platformAccounts = response.data.results
      }).finally(() => {
        this.loadingPlatformAccounts = false
      })
    }
  },
  mounted () {
    this.load()
    this.loadPlatformAccounts()
  }
}
</script>
