<template>
  <div>
    <div class="section">
      <div class="container">
        <!-- Page Header -->
        <div class="columns is-vcentered">
          <div class="column is-one-third">
            <breadcrumbs :history="[{ label: 'Catalog', name: 'catalog'}]" current="Inventory by Vendor" :hideBack="true" />
          </div>
          <div class="column is-one-third">
            <h1 class="has-text-centered is-size-4">Inventory by Vendor</h1>
          </div>
        </div>
        <!-- Datatable -->
        <div class="columns">
          <div class="column">
            <!-- Toolbar -->
            <div class="has-background-white rounded-lg px-sm mb-xxs">
              <div class="columns is-vcentered is-mobile is-marginless is-paddingless">
                <div class="column">
                  <div class="has-text-right is-size-7">
                    <!-- Counts -->
                    <p v-if="loading">Loading...</p>
                    <p v-else>{{ results  .length }} suppliers</p>
                  </div>
                </div>
              </div>
            </div>
            <!-- Results -->
            <div>
              <div>
                <b-table
                  :data="results"
                  :mobile-cards="false"
                  :loading="loading"
                  hoverable
                  class="is-size-7">
                  <template>
                    <!-- Supplier -->
                    <b-table-column
                      label="Supplier"
                      header-class="nowrap"
                      cell-class="nowrap"
                      v-slot="props">
                      {{ props.row.name }}
                    </b-table-column>
                    <!-- Number of Items -->
                    <b-table-column
                      label="# Items"
                      header-class="nowrap"
                      cell-class="nowrap"
                      width="96"
                      centered
                      v-slot="props">
                      {{ props.row.item_count }}
                    </b-table-column>
                    <!-- In Stock -->
                    <b-table-column
                      label="# In Stock"
                      header-class="nowrap"
                      cell-class="nowrap"
                      width="96"
                      centered
                      v-slot="props">
                      {{ props.row.in_stock }}
                    </b-table-column>
                    <!-- Out of Stock -->
                    <b-table-column
                      label="# Out of Stock"
                      header-class="nowrap"
                      cell-class="nowrap"
                      width="96"
                      centered
                      v-slot="props">
                      {{ props.row.out_of_stock }}
                    </b-table-column>
                    <!-- Last Submission -->
                    <b-table-column
                      label="Last Submission"
                      header-class="nowrap"
                      cell-class="nowrap"
                      width="96"
                      numeric
                      v-slot="props">
                      <div v-if="props.row.max_inventory_last_submitted_at">
                        <p>{{ $dates.utc(props.row.max_inventory_last_submitted_at).local().format('MMM DD, YYYY') }}</p>
                        <p class="is-size-8 has-text-grey-light">{{ $dates.utc(props.row.max_inventory_last_submitted_at).local().format('h:mm A') }}</p>
                      </div>
                      <p v-else class="has-text-grey-light is-italic">Never</p>
                    </b-table-column>
                  </template>
                  <template #empty>
                    <div class="py-xxl has-text-grey has-text-centered">
                      {{ (loading) ? "Loading data..." : 'No suppliers found!' }}
                    </div>
                  </template>
                </b-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/inventory-summary/'
    }
  },
  data () {
    return {
      loading: true,
      results: []
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint).then(response => {
        this.results = this.$utils.sortBy(response.data, [function (o) { return o.name }])
      }).finally(() => {
        this.loading = false
      })
    }
  },
  mixins: [],
  mounted () {
    this.load()
  },
  props: []
}
</script>
