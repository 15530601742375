<template>
  <div class="section">
    <div class="container">
      <div class="has-text-centered mb-lg">
        <p class="is-size-4 mb-sm">Select Account</p>
        <p class="has-text-grey">
          Your user account has access to mulitiple {{ app.platform }} accounts.
          Please select the account you wish to view.
        </p>
      </div>
      <div class="py-lg">
        <ContextInternal v-if="auth.isStaff" @navigate:to="navigateToLandingPage"/>
        <ContextExternal v-else @navigate:to="navigateToLandingPage"/>
        <p class="has-text-centered mt-lg"><a href="" @click.prevent="logout">Logout</a></p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ContextInternal from './ContextInternal'
import ContextExternal from './ContextExternal'
export default {
  components: {
    ContextInternal,
    ContextExternal
  },
  computed: {
    ...mapGetters(['app', 'auth'])
  },
  methods: {
    logout () {
      this.$store.dispatch('logout')
    },
    navigateToLandingPage () {
      const { user } = this.auth
      if (user && user.preferences && user.preferences.default_landing_page) {
        this.$router.push({ name: user.preferences.default_landing_page.value })
      } else {
        this.$router.push({ name: 'home' })
      }
    }
  }
}
</script>
