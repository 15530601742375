<template>
  <div>
    <div class="columns is-centered">
      <div class="column is-half">
        <!-- Search -->
        <div class="rounded-lg has-background-white mb-lg pa-md">
          <ProposalSearch />
        </div>
        <!-- Open Proposals -->
        <div class="mb-lg">
          <p class="has-text-weight-bold has-text-centered mb-md">Open</p>
          <!-- Proposed -->
          <PresetLink
            :loading="loading"
            :count="('proposed' in statuses) ? statuses.proposed.count : 0"
            :alert="true"
            label="Requires Approval"
            preset="proposed"
            description="Proposals that are awaiting your approval."
          />
          <!-- Revised -->
          <PresetLink
            :loading="loading"
            :count="('revised' in statuses) ? statuses.revised.count : 0"
            :alert="false"
            label="Waiting for Supplier"
            preset="revised"
            description="Proposals that have been returned to the supplier for revisions."
          />
          <!-- Approved -->
          <PresetLink
            :loading="loading"
            :count="('approved' in statuses) ? statuses.approved.count : 0"
            :alert="false"
            label="Needs Pricing Approval"
            preset="approved"
            description="Proposals that need additional pricing approval (optional)."
          />
          <!-- Pricing Approved -->
          <PresetLink
            :loading="loading"
            :count="('pricing_approved' in statuses) ? statuses.pricing_approved.count : 0"
            :alert="false"
            label="Pricing Approved"
            preset="pricing_approved"
            description="Proposals that are pricing-approved and preparing to be published."
          />
          <!-- ReadyToPublish (completed, but not acknowledged) -->
          <ReadyToPublish />
        </div>
        <!-- Completed Proposals -->
        <div>
          <p class="has-text-weight-bold has-text-centered mb-md">Closed</p>
          <Completed />
          <!-- Pricing Approved -->
          <PresetLink
            :loading="loading"
            :count="('declined' in statuses) ? statuses.declined.count : 0"
            :alert="false"
            label="Declined"
            preset="declined"
            description="Proposals that have been declined."
          />
          <!-- View All Closed -->
          <div class="has-text-centered mt-md">
            <router-link class="has-text-info" :to="{ name: 'proposals.list', query: { preset: 'closed' } }">
              View All Closed
            </router-link>
          </div>
        </div>
      </div>
      <!-- Right Column (New & Completed Proposals) -->

    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PresetLink from './PresetLink'
import ReadyToPublish from './ReadyToPublish'
import Completed from './Completed'
import ProposalSearch from './ProposalSearch'

export default {
  components: {
    PresetLink,
    ReadyToPublish,
    Completed,
    ProposalSearch
  },
  computed: {
    ...mapGetters(['context']),
    totalApproved () {
      let count = 0
      if ('approved' in this.statuses) {
        count += this.statuses.approved.count
      }
      if ('pricing_approved' in this.statuses) {
        count += this.statuses.pricing_approved.count
      }
      return count
    },
    totalDeclined () {
      return ('declined' in this.statuses) ? this.statuses.declined.count : 0
    }
  },
  props: {
    loading: {
      type: Boolean,
      required: true
    },
    statuses: {
      type: Object,
      required: true
    }
  }
}
</script>
