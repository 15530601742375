<template>
  <div class="mb-lg">
    <!-- Has Shipments -->
    <div v-if="shipments.length > 0">
      <!-- Shipping Labels -->
      <div v-if="shippingLabels.length > 0">
        <p class="has-text-centered has-text-weight-bold mb-md">Shipping Labels ({{ shippingLabels.length }})</p>
        <div class="mb-xl">
          <div v-for="shipment in shippingLabels" :key="shipment.id">
            <ShippingLabelShipment :shipment="shipment" />
          </div>
        </div>
        <b-button
          type="is-primary"
          size="is-medium"
          @click="printLabels"
          outlined
          expanded>
          Print All Labels
        </b-button>
      </div>
      <!-- Tracking #s -->
      <div v-if="trackingNumbers.length > 0">
        <p class="has-text-centered has-text-weight-bold mb-md">Tracking #s ({{ trackingNumbers.length }})</p>
        <!-- Actions Toolbar -->
        <div class="has-background-white rounded-lg py-sm mb-xxs">
          <div class="columns is-vcentered is-mobile">
            <div class="column is-narrow">
              <b-dropdown :disabled="orderRefreshing || selected.length === 0">
                <button class="button" slot="trigger" slot-scope="{ active }" :disabled="selected.length === 0">
                  <span>Actions</span>
                  <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
                </button>
                <!-- All Actions -->
                <b-dropdown-item has-link><a href="#" @click.prevent="copyTrackingNumbers">Copy Tracking Numbers</a></b-dropdown-item>
                <!-- Supplier Actions -->
                <template v-if="context.isSupplier()">
                  <b-dropdown-item has-link><a href="#" @click.prevent="showDeleteShipmentsModal = true">Delete Tracking Numbers</a></b-dropdown-item>
                </template>
              </b-dropdown>
            </div>
            <div class="column">
              <div class="has-text-right is-size-7">
                <!-- Counts -->
                <p v-if="orderRefreshing">Loading...</p>
                <p v-else>
                  {{ shipments.length }}
                  {{ (shipments.length === 1) ? 'shipment' : 'shipments' }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- Results -->
        <div style="overflow-x:auto; overflow-y:hidden">
          <b-table
            :data="shipments"
            :checked-rows.sync="selected"
            :mobile-cards="false"
            :loading="orderRefreshing"
            hoverable
            checkable
            checkbox-position="left"
            class="is-size-7">
            <template>
              <!-- Tracking # -->
              <b-table-column
                label="Tracking Number"
                header-class="nowrap"
                cell-class="nowrap"
                v-slot="props">
                <p class="has-text-weight-bold has-text-info">
                  {{ props.row.tracking_number }}
                </p>
                <p class="has-text-grey is-size-7">
                  {{ props.row.shipping_method.name }}
                </p>
              </b-table-column>
            </template>
            <template #empty>
              <div class="py-lg has-text-grey has-text-centered">
                <p>No shipments have been registered yet.</p>
              </div>
            </template>
          </b-table>
        </div>
      </div>
    </div>
    <!-- No Shipmens Yet -->
    <div v-else class="has-text-centered has-text-grey-light">
      <p class="has-text-centered has-text-weight-bold mb-md">Labels & Tracking #s</p>
      <p class="mb-sm"><b-icon icon="package-variant" size="is-large" custom-class="has-text-grey-light" /></p>
      <p>You haven't registered any fulfillments for this purchase order yet.</p>
    </div>
    <!-- Delete Shipments -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showDeleteShipmentsModal"
      :can-cancel="false"
      @close="selected = []"
      ><DeleteShipmentsModal :shipments="selected" @reload="reload" />
    </b-modal>
    <!-- Print Shipments -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showPrintLabelsModal"
      :can-cancel="false"
      @close="selected = []"
      ><PrintLabelsModal :orders="[{ id: orderId }]" :shipments="shippingLabels" @reload="reload" />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DeleteShipmentsModal from '@/components/Modals/Shipment/DeleteShipmentsModal'
import PrintLabelsModal from '@/components/Modals/Shipment/PrintLabelsModal'
import ShippingLabelShipment from '@/components/Models/Shipment/ShippingLabelShipment'

export default {
  components: {
    DeleteShipmentsModal,
    PrintLabelsModal,
    ShippingLabelShipment
  },
  data () {
    return {
      selected: [],
      showPrintLabelsModal: false,
      showDeleteShipmentsModal: false
    }
  },
  computed: {
    ...mapGetters(['context']),
    shippingLabels () {
      return this.shipments.filter(shipment => {
        return shipment.valid_shipping_label === true
      })
    },
    trackingNumbers () {
      return this.shipments.filter(shipment => {
        return shipment.valid_shipping_label === false
      })
    },
    selectedTrackingNumbers () {
      if (this.selected.length === 0) return null
      const trackingNumbers = []
      this.selected.forEach(shipment => {
        if (shipment.tracking_number !== null && shipment.tracking_number !== '') {
          trackingNumbers.push(shipment.tracking_number)
        }
      })
      if (trackingNumbers.length === 0) return null
      if (trackingNumbers.length === 1) return trackingNumbers.join('')
      return trackingNumbers.join(', ')
    }
  },
  methods: {
    copyTrackingNumbers () {
      if (this.selectedTrackingNumbers !== null) {
        this.$copyText(this.selectedTrackingNumbers).then(() => {
          this.$buefy.toast.open({ type: 'is-success', message: 'Tracking numbers copied!' })
        })
      } else {
        this.$buefy.toast.open({ type: 'is-danger', message: 'No tracking numbers copied!' })
      }
    },
    reload () {
      this.selected = []
      this.$emit('fulfillment:updated')
    },
    printLabels () {
      this.showPrintLabelsModal = true
    }
  },
  mounted () {

  },
  props: ['orderRefreshing', 'orderId', 'shipments']
}
</script>
