<template>
  <div>
    <div class="has-text-weight-semibold">
      Create Sample Items
    </div>
    <!-- Error -->
    <div v-if="('general' in errors)" class="mb-md">
      <p class="has-text-danger">{{ errors.general }}</p>
    </div>
    <div>
      <!-- Fields -->
      <div class="mt-md">
        <p>Item 1</p>
        <div class="columns">
          <b-field label="SKU" class="column">
            <b-input v-model="sku1" :disabled="itemsCreated"></b-input>
          </b-field>
          <b-field label="Item Name" class="column">
            <b-input v-model="name1" :disabled="itemsCreated"></b-input>
          </b-field>
        </div>
        <p>Item 2</p>
        <div class="columns">
          <b-field label="SKU" class="column">
            <b-input v-model="sku2" :disabled="itemsCreated"></b-input>
          </b-field>
          <b-field label="Item Name" class="column">
            <b-input v-model="name2" :disabled="itemsCreated"></b-input>
          </b-field>
        </div>
      </div>
      <!-- Navigate -->
      <div class="py-lg">
        <div class="columns">
          <div class="column">
            <b-button
              type="is-light rounded-lg"
              expanded
              @click="goBack"
              ><b-icon icon="arrow-left"></b-icon>
            </b-button>
          </div>
          <div class="column">
            <b-button
              type="is-primary rounded-lg"
              expanded
              :disabled="!ready"
              :loading="saving || isUpdating"
              @click="goForward"
            >{{buttonText}}</b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  computed: {
    ...mapGetters(['app', 'context']),
    endpoint () {
      return this.context.route + '/variants/'
    },
    ready () {
      return (
        this.sku1 !== null && this.sku1 !== '' &&
        this.sku2 !== null && this.sku2 !== '' &&
        this.name1 !== null && this.name1 !== '' &&
        this.name2 !== null && this.name2 !== ''
      )
    },
    json () {
      const body = [
        {
          identifier: this.sku1,
          name: this.name1
        },
        {
          identifier: this.sku2,
          name: this.name2
        }
      ]
      return body
    },
    buttonText () {
      if (this.itemsCreated) {
        return 'Save & Continue'
      } else {
        return 'Create & Continue'
      }
    }
  },
  data () {
    return {
      saving: false,
      isUpdating: false,
      errors: {},
      sku1: null,
      name1: null,
      sku2: null,
      name2: null,
      itemsCreated: false
    }
  },
  methods: {
    goBack () {
      this.$router.push({ name: 'onboarding.tasks.self-serve-edi', params: { connection: this.$route.params.connection } })
    },
    goForward () {
      if (this.itemsCreated) {
        this.$router.push({ name: 'onboarding.tasks.self-serve-edi.inventory', params: { connection: this.$route.params.connection, task: this.$route.params.task } })
      } else {
        // create items first and then update the task info
        this.saving = true
        this.$http.post(this.endpoint, this.json).then(response => {
          this.$buefy.toast.open({ type: 'is-success', message: 'Sample items created' })
          // Add info to the task to track the tasks
          const taskBody = (typeof this.task.info === 'object') ? { info: this.task.info } : { info: {} }
          taskBody.info.createSampleItems = true
          taskBody.info.variants = response.data.map(el => {
            return {
              id: el.id,
              name: el.name,
              sku: el.brand_identifier
            }
          })
          this.$emit('task:updated', taskBody, 'onboarding.tasks.self-serve-edi.inventory')
        }).catch(error => {
          this.errors.general = JSON.stringify(error.response.data)
        }).finally(() => {
          this.saving = false
        })
      }
    },
    parseItems (items) {
      this.sku1 = items[0].sku
      this.sku2 = items[1].sku
      this.name1 = items[0].name
      this.name2 = items[1].name
    }
  },
  mounted () {
    if (this.task && this.task.info && this.task.info.createSampleItems) {
      this.itemsCreated = true
      this.parseItems(this.task.info.variants)
    }
  },
  props: {
    connection: {
      type: Object,
      required: true
    },
    ediAccount: {
      type: Object
    },
    task: {
      type: Object,
      required: true
    },
    isUpdatingTask: {
      type: Boolean
    }
  },
  watch: {
    isUpdatingTask (value) {
      this.isUpdating = value
    }
  }
}
</script>
