<template>
  <div>
    <!-- Toolbar -->
  <div class="has-background-white rounded-lg py-md px-lg mb-xxs">
    <div class="columns is-vcentered">
      <div class="column">
        <div v-if="!isPaid" class="px-sm has-text-grey-light">
          <em class="is-size-7">Click 'Add Credit' to view list of available credits and modify your selection.</em>
        </div>
      </div>
      <div class="column is-narrow">
        <div class="px-sm">
          <b-button
            :disabled="isPaid"
            type="is-primary"
            outlined
            expanded
            @click.prevent="navigateToCredits()"
          >Add Credits</b-button>
        </div>
      </div>
    </div>
  </div>
  <div class="mb-lg">
    <div class="has-background-white rounded-lg pa-lg">
        <b-table
          :data="results"
          :checked-rows.sync="selected"
          :mobile-cards="false"
          :loading="loading"
          hoverable
          :checkable="!isPaid"
          checkbox-position="left"
          class="is-size-7">
          <template>
            <b-table-column
              label="ID"
              cell-class="nowrap"
              width="128"
              v-slot="props">
                <a href="#"
              class="has-text-weight-bold has-text-info"
              @click.prevent="inspectCredit = props.row"
              >{{ props.row.id }} </a>
            </b-table-column>
            <!-- Credit Memo # -->
            <b-table-column
              label="Credit Memo #"
              header-class="nowrap"
              cell-class="nowrap"
              width="196"
              v-slot="props">
              <p v-if="props.row.credit_number">{{ props.row.credit_number }}</p>
              <p v-else class="has-text-grey-light is-italic">None Provided</p>
            </b-table-column>
            <!-- PO # -->
            <b-table-column
              label="PO #"
              cell-class="nowrap"
              v-slot="props">
              {{ props.row.order.purchase_order_number }}
            </b-table-column>
            <!-- Registered -->
            <b-table-column
              label="Registered"
              header-class="nowrap"
              cell-class="nowrap"
              centered
              width="128"
              v-slot="props">
              <span v-if="props.row.rmad_at === null">--</span>
              <span v-else>{{ $dates.utc(props.row.credited_at).local().format('MMM D, YYYY') }}</span>
            </b-table-column>
          </template>
          <template #empty>
            <div v-if="!loading && !isPaid" class="has-text-centered py-xxl">
              <p class="has-text-grey-light mb-lg">
                There are no credits associated with this payment. Click on add credit to view list of credits.
              </p>
              <p v-if="context.isMerchant()"><b-button outlined @click="navigateToCredits()">Add Credits</b-button></p>
            </div>
            <div v-else class="has-text-grey-light mb-lg has-text-centered py-xxl"> No Credits added to this payment.</div>
          </template>
        </b-table>
    </div>
  </div>
  <!-- Inspect Credit -->
  <b-modal
    v-if="(inspectCredit !== null)"
    has-modal-card
    :active="(inspectCredit !== null)"
    :can-cancel="['escape', 'x']"
    @close="closeModal"
    ><CreditModal :selectedCredit="inspectCredit" />
  </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Filterable from '@/mixins/Filterable'
import CreditModal from '@/components/Modals/Credit/CreditModal'
export default {
  components: {
    CreditModal
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/payments/' + this.$route.params.id + '/credits/'
    },
    isPaid () {
      return this.payment.status === 'paid'
    }
  },
  data: () => {
    return {
      inspectCredit: null
    }
  },
  methods: {
    navigateToCredits () {
      this.$router.push({ name: 'payment.detail.credits.list', params: { id: this.payment.id } })
    },
    closeModal () {
      this.inspectCredit = null
    }
  },
  mounted () {
    this.load()
  },
  props: ['payment'],
  mixins: [Filterable]
}
</script>
