<template>
  <router-link :to="{ name: 'returns.list', query: { preset: 'pending'} }">
    <div class="has-background-white px-md rounded-lg mb-xxs">
      <div class="columns is-mobile is-vcentered is-marginless">
        <div class="column"><p class="has-text-weight-bold">Pending Returns </p></div>
        <div class="column is-one-fifth">
          <div class="has-background-white-ter pa-xs rounded-lg has-text-weight-bold has-text-centered">
            <span v-if="loading"><b-icon icon="loading" custom-class="mdi-spin" size="is-small" /></span>
            <span v-else :class="countClass">{{ count }}</span>
          </div>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  computed: {
    ...mapGetters(['context']),
    countClass () {
      return (!this.loading && this.count > 0) ? 'has-text-danger' : 'has-text-success'
    }
  },
  data () {
    return {
      loading: true,
      count: 0
    }
  },
  methods: {
    loadRmas () {
      this.loading = true
      this.$http.get(this.context.route + '/rmas/?status=pending').then(response => {
        this.count = response.data.count
        this.loading = false
      })
    },
    routeToOpenRmas () {

    }
  },
  mounted () {
    this.loadRmas()
  },
  props: []
}
</script>
