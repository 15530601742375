<template>
  <div class="has-background-white py-sm px-md rounded-lg mb-xs">
    <div class="columns is-mobile is-paddingless is-marginless">
      <div class="column is-vcentered is-narrow">
        <a href="#" @click.prevent="expanded = !expanded"><b-icon icon="plus" /></a>
      </div>
      <div class="column">
        <a href="#" @click.prevent="expanded = !expanded"><p class="has-text-weight-bold pt-xxs">{{ question.question }}</p></a>
        <div class="mt-md content" v-if="expanded" v-html="question.answer"></div>
      </div>
      <div class="column is-narrow is-hidden-mobile">
        <a href="#" @click.prevent="expanded = !expanded"><b-icon icon="chevron-down" custom-class="mdi-rotate-180" /></a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  computed: {
    ...mapGetters(['context'])
  },
  data () {
    return {
      expanded: false
    }
  },
  methods: {

  },
  mounted () {

  },
  props: ['question']
}
</script>
