<template>
  <form @submit.prevent="generateReturnLabel">
    <!-- Toolbar -->
    <div class="border-b">
      <div class="columns is-vcentered is-paddingless is-marginless">
        <div class="column">
          Create Return Label
        </div>
        <div class="column is-narrow">
          <div class="has-text-right">
            <b-button type="is-light" @click.prevent="$emit('labels:reload')">Go Back</b-button>
          </div>
        </div>
      </div>
    </div>
    <!-- Label Form -->
    <div class="pa-md">

      <!-- Shipping Account -->
      <div class="mb-lg">
        <ShippingAccount v-model="shippingAccount" :connection='connection' />
      </div>
      <!-- Shipping Method -->
      <div class="mb-lg">
        <ShippingMethod v-model="shippingMethod" :account="shippingAccount" :requestedService="{ name: 'Ground' }" />
      </div>
      <!-- Return To -->
      <div class="mb-lg">
        <ReturnsCenter v-model="returnWarehouse" :connection="{ id: connection, brand: supplier }" />
      </div>
      <!-- Carton -->
      <div class="mb-lg">
        <div class="columns">
          <div class="column">
            <p class="mb-sm has-text-weight-bold">Carton Dimensions (in approximate inches)</p>
            <div class="columns is-vcentered is-mobile">
              <div class="column">
                <b-field
                  label=""
                  size="is-medium"
                  :type="('width' in errors) ? 'is-danger' : ''"
                  :message="('width' in errors) ? errors['width'][0] : ''"
                  expanded>
                    <b-numberinput v-model="shipWidth" placeholder="Width" :controls="false" size="is-medium"></b-numberinput>
                </b-field>
              </div>
              <div class="column">
                <b-field
                  label=""
                  :type="('height' in errors) ? 'is-danger' : ''"
                  :message="('height' in errors) ? errors['height'][0] : ''"
                  expanded>
                    <b-numberinput v-model="shipHeight" placeholder="Height" :controls="false" size="is-medium"></b-numberinput>
                </b-field>
              </div>
              <div class="column">
                <b-field
                  label=""
                  :type="('length' in errors) ? 'is-danger' : ''"
                  :message="('length' in errors) ? errors['length'][0] : ''"
                  expanded>
                    <b-numberinput v-model="shipLength" placeholder="Length" :controls="false" size="is-medium"></b-numberinput>
                </b-field>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Weight -->
      <div class="mb-lg">
        <p class="mb-sm has-text-weight-bold">Carton Weight (in approximate pounds)</p>
        <div class="columns">
          <div class="column is-one-quarter">
            <b-field
              label=""
              :type="('weight' in errors) ? 'is-danger' : ''"
              :message="('weight' in errors) ? errors['weight'][0] : ''"
              expanded>
                <b-numberinput v-model="shipWeight" placeholder="Weight" :controls="false" size="is-medium"></b-numberinput>
            </b-field>
          </div>
        </div>
      </div>
      <!-- Submit -->
      <div class="mb-lg">
        <b-button
          type="is-primary"
          size="is-medium"
          native-type="submit"
          :loading="processing"
          :disabled="!isValid || processing"
        >Generate Label</b-button>
      </div>
    </div>

  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import ShippingAccount from '@/components/Models/ShippingAccount/ShippingAccount'
import ShippingMethod from '@/components/Models/ShippingMethod/ShippingMethod'
import ReturnsCenter from '@/components/Models/Location/ReturnsCenter'

export default {
  components: {
    ShippingAccount,
    ShippingMethod,
    ReturnsCenter
  },
  data () {
    return {
      processing: false,
      shippingAccount: null,
      shippingMethod: null,
      returnWarehouse: null,
      shipWeight: null,
      shipWidth: null,
      shipLength: null,
      shipHeight: null,
      errors: {}
    }
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/rmas/' + this.rma.id + '/'
    },
    isValid () {
      if (!this.shippingAccount) return false
      if (!this.shippingMethod) return false
      if (!this.returnWarehouse) return false
      if (!this.shipWidth || !this.shipLength || !this.shipHeight) return false
      if (!this.shipWeight) return false
      return true
    },
    returnLabelJson () {
      if (!this.isValid) return {}
      return {
        shipping_account: {
          id: this.shippingAccount.id
        },
        shipping_method: {
          id: this.shippingMethod.id
        },
        ship_to: {
          name1: this.returnWarehouse.address.name1,
          street1: this.returnWarehouse.address.street1,
          city: this.returnWarehouse.address.city,
          province: this.returnWarehouse.address.province,
          postal_code: this.returnWarehouse.address.postal_code,
          country: this.returnWarehouse.address.country,
          phone1: this.returnWarehouse.address.phone1
        },
        ship_weight: this.shipWeight,
        ship_width: this.shipWidth,
        ship_length: this.shipLength,
        ship_height: this.shipHeight,
        label_format: 'PDF'
      }
    }
  },
  methods: {
    generateReturnLabel () {
      this.processing = true
      this.$http.post(this.endpoint + 'returns/', this.returnLabelJson).then(response => {
        this.reset()
        this.$emit('labels:reload')
      }).finally(() => {
        this.processing = false
      })
    },
    reset () {
      this.shippingAccount = null
      this.shippingMethod = null
      this.returnWarehouse = null
      this.shipWeight = null
      this.shipWidth = null
      this.shipLength = null
      this.shipHeight = null
    }
  },
  mounted () {

  },
  props: ['rma', 'connection', 'supplier']
}
</script>
