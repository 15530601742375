<template>
  <div>
    <div class="columns is-multiline">
      <!-- Identifiers -->
      <div class="column is-half">
        <p class="has-text-primary has-text-weight-bold pb-sm has-border-b has-border-lg">Item Info</p>
        <table class="table is-fullwidth is-striped is-size-7 is-narrow">
          <tbody>
            <tr>
              <th>Item ID</th>
              <td class="has-text-right">{{ item.id }}</td>
            </tr>
            <tr>
              <th>Supplier</th>
              <td class="has-text-right">{{ item.brand.name }}</td>
            </tr>
            <tr>
              <th>Supplier Product SKU</th>
              <td class="has-text-right">
                <span v-if="item.parent_identifier !== null">{{ item.parent_identifier }}</span>
                <span v-else class="has-text-grey-light is-italic">- Not Set -</span>
              </td>
            </tr>
            <tr>
              <th>Supplier Product Name</th>
              <td class="has-text-right">
                <span v-if="item.parent_name !== null">{{ $utils.truncate(item.parent_name, { length: 64 }) }}</span>
                <span v-else class="has-text-grey-light is-italic">- Not Set -</span>
              </td>
            </tr>
            <tr>
              <th>Supplier SKU</th>
              <td class="has-text-right">{{ item.brand_identifier }}</td>
            </tr>
            <tr>
              <th>Supplier Item Name</th>
              <td v-if="context.isMerchant() && item.retailer_identifiers.length > 0" class="has-text-right">{{ $utils.truncate(item.retailer_identifiers[0].name, { length: 64 }) }}</td>
              <td v-else class="has-text-right">{{ $utils.truncate(item.name, { length: 64 }) }}</td>
            </tr>
            <tr>
              <th>UPC</th>
              <td class="has-text-right">
                <span v-if="item.upc !== null">{{ item.upc }}</span>
                <span v-else class="has-text-grey-light is-italic">- Not Set -</span>
              </td>
            </tr>
            <tr v-if="context.isMerchant()">
              <th>Category</th>
              <td class="has-text-right">
                <span v-if="item.retailer_categories.length">
                  {{ categories }}
                </span>
                <span v-else>-</span>
              </td>
            </tr>
            <tr v-if="context.isSupplier()">
              <th>Categories</th>
              <td class="has-text-right">
                <span v-if="item.retailer_categories.length">
                  <a href="#" class="has-text-info" @click.prevent="showCategories=true">View retailer categories({{ item.retailer_categories.length }})</a>
                </span>
                <span v-else>-</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- Inventory -->
      <div class="column is-half">
        <p class="has-text-primary has-text-weight-bold pb-sm has-border-b has-border-lg">Inventory</p>
        <table class="table is-fullwidth is-striped is-size-7 is-narrow">
          <tbody>
            <tr>
              <th>Policy</th>
              <td class="has-text-right">
                {{ $utils.upperFirst(item.inventory_policy) }}
              </td>
            </tr>
            <tr>
              <th>Availability</th>
              <td class="has-text-right has-text-weight-bold">
                <span v-if="item.inventory.discontinued" class="has-text-danger">Discontinued</span>
                <span v-else-if="item.inventory.inventory === 0" class="has-text-warning">Out of Stock</span>
                <span v-else class="has-text-success">In Stock</span>
              </td>
            </tr>
            <tr>
              <th>On Hand</th>
              <td class="has-text-right">
                <span v-if="item.inventory.inventory === 9999" class="has-text-danger">&infin;</span>
                <span v-else>{{ item.inventory.inventory }}</span>
              </td>
            </tr>
            <tr v-if="item.inventory.inventory === 0">
              <th>Estimated Return</th>
              <td class="has-text-right">
                <span v-if="item.inventory.estimated_availability_date !== null">
                  {{ $dates(item.inventory.estimated_availability_date).format('MMM D, YYYY') }}
                </span>
                <span v-else class="has-text-grey-light is-italic">Unknown</span>
              </td>
            </tr>
            <tr>
              <th>Last Update</th>
              <td class="has-text-right">
                <span v-if="item.inventory.inventory_updated_at !== null">
                  {{ $dates.utc(item.inventory.inventory_updated_at).local().format('MMM D, YYYY @ h:mm A') }}
                </span>
                <span v-else class="has-text-light is-italic">Never</span>
              </td>
            </tr>
            <tr>
              <th>Last Submission</th>
              <td class="has-text-right">
                <span v-if="item.inventory.inventory_last_submitted_at !== null">
                  {{ $dates.utc(item.inventory.inventory_last_submitted_at).local().format('MMM D, YYYY @ h:mm A') }}
                </span>
                <span v-else class="has-text-grey-light is-italic">Never</span>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-if="hasShopifyAccount">
          <div class="pb-sm has-border-b has-border-lg">
            <div class="columns">
              <div class="column">
                <p class="has-text-primary has-text-weight-bold">External Platform Identifiers</p>
              </div>
              <div class="column is-narrow">
                <a href="#" @click.prevent="$emit('sync:item')" class="has-text-weight-bold has-text-info">Edit</a>
              </div>
            </div>
          </div>
          <table class="table is-fullwidth is-striped is-size-7 is-narrow">
            <tbody>
              <tr>
                <th>Shopify</th>
                <td class="has-text-right">
                  <span v-if="item.platform_account_identifiers.length > 0">{{ item.platform_account_identifiers[0].identifier }}</span>
                  <span v-else class="has-text-grey-light is-italic">- Using SKU -</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- Category modal -->
    <b-modal
      v-if="context.isSupplier() && item !== null"
      has-modal-card
      :active.sync="showCategories"
      :can-cancel="['escape', 'x']"
      ><RetailerCategoryModal :categories="item.retailer_categories" />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import RetailerCategoryModal from './RetailerCategoryModal'
export default {
  components: {
    RetailerCategoryModal
  },
  computed: {
    ...mapGetters(['context']),
    categories () {
      const values = []
      if (this.item && this.item.retailer_categories.length) {
        this.item.retailer_categories.map(el => values.push(el.category))
      }
      const categories = values.length ? values.join('/') : '-'
      return categories
    }
  },
  data () {
    return {
      showCategories: false
    }
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    hasShopifyAccount: {
      type: Boolean,
      required: true,
      default: false
    }
  }
}
</script>
