<template>
  <div>
    <!-- Connection Details -->
    <div class="has-background-white rounded-lg pa-lg mb-lg">

      <!-- Loaded -->
      <div v-if="!loadingConnection && connection !== null">
        <div class="columns">
          <div class="column">
            <div>
              <p class="has-text-primary has-text-weight-bold pb-sm has-border-b has-border-lg">Vendor Details</p>
              <table class="table is-fullwidth is-striped is-narrow is-size-7">
                <tbody>
                  <tr v-if="context.type === 'vendor'">
                    <th>Retailer</th>
                    <td class="has-text-right">{{ connection.retailer.name }}</td>
                  </tr>
                  <tr v-else>
                    <th>Vendor</th>
                    <td class="has-text-right">{{ connection.brand.name }}</td>
                  </tr>
                  <tr>
                    <th>Vendor Identifier</th>
                    <td class="has-text-right">
                      <p>{{ connection.retailer_identifier }}</p>
                    </td>
                  </tr>
                  <tr>
                    <th>ID</th>
                    <td class="has-text-right">{{ connection.id }}</td>
                  </tr>
                  <!-- <tr>
                    <th>Integration Type</th>
                    <td class="has-text-right">{{ integration.label }}</td>
                  </tr> -->
                  <tr>
                    <th>Established</th>
                    <td class="has-text-right">{{ $dates.utc(connection.created_at).local().format('MMM D, YYYY')}}</td>
                  </tr>
                  <!-- <tr v-if="canCustomizePackingSlipCopy">
                    <th>Custom Packing Slip Copy</th>
                    <td class="has-text-right">
                      <a class="has-text-info has-text-weight-bold" href="#" @click.prevent="showPackingSlipCopyModal = true">View</a>
                    </td>
                  </tr> -->
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!-- Loading -->
      <div v-else>
        <loading message="Loading connection details..." />
      </div>
    </div>

    <!-- Summary Boxes -->
    <div class="mb-md">
      <div class="columns">
        <div class="column">
          <div class="has-background-white pa-md rounded-lg has-text-centered">
            <p class="is-size-5 has-text-weight-bold">{{ vendorStats.invoiceCount }}</p>
            <p class="is-size-7"># of Invoices</p>
          </div>
        </div>
        <div class="column">
          <div class="has-background-white pa-md rounded-lg has-text-centered">
            <p class="is-size-5 has-text-weight-bold"><Currency :value="vendorStats.invoiceSubtotal" /></p>
            <p class="is-size-7">Invoice Total</p>
          </div>
        </div>
        <div class="column">
          <div class="has-background-white pa-md rounded-lg has-text-centered">
            <p class="is-size-5 has-text-weight-bold">{{ vendorStats.creditCount }}</p>
            <p class="is-size-7"># of Credits</p>
          </div>
        </div>
        <div class="column">
          <div class="has-background-white pa-md rounded-lg has-text-centered">
            <p class="is-size-5 has-text-weight-bold"><Currency :value="vendorStats.creditSubtotal" /></p>
            <p class="is-size-7">Credit Total</p>
          </div>
        </div>
        <div class="column">
          <div class="has-background-white pa-md rounded-lg has-text-centered">
            <p class="is-size-5 has-text-weight-bold has-text-success"><Currency :value="vendorStats.invoiceSubtotal - vendorStats.creditSubtotal" /></p>
            <p class="is-size-7">Due Amount</p>
          </div>
        </div>
      </div>
    </div>

    <!-- Details -->
    <div class="mb-lg">
      <div class="columns">
        <!-- Invoices -->
        <div class="column">
          <div class="rounded-lg has-background-white px-md py-sm mb-xs">
            <div class="columns is-vcentered">
              <div class="column">
                <p class="has-text-grey-light is-size-7">{{ invoices.length }} {{ (invoices.length === 1) ? 'Invoice' : 'Invoices' }}</p>
              </div>
              <div class="column is-narrow">
                <b-select size="is-small" v-model="sort">
                  <option value="-id">Most Recent</option>
                  <option value="id">Oldest</option>
                  <option>Largest Adjustments</option>
                </b-select>
              </div>
              <div class="column is-narrow">
                <b-button size="is-small">Export</b-button>
              </div>
            </div>
          </div>
          <div class="rounded-lg has-background-white pa-md" style="overflow-x:none">
            <b-table
              class="is-size-7"
              :data="invoices"
              :loading="loadingInvoices"
              sticky-header>
              <b-table-column
                label="Invoice #"
                v-slot="props">
                <a href="#" @click.prevent class="has-text-info has-text-weight-bold">{{ props.row.invoice_number }}</a>
              </b-table-column>
              <b-table-column
                label="Received"
                v-slot="props">
                <p><Date :value="props.row.invoiced_at" :isTimestamp="true" /></p>
              </b-table-column>
              <b-table-column
                label="Acknowledged"
                v-slot="props">
                <p><Date :value="props.row.acknowledged_at" :isTimestamp="true" /></p>
              </b-table-column>
              <b-table-column
                label="Due"
                v-slot="props">
                <p><Date :value="props.row.terms_date_due" :isTimestamp="false" /></p>
              </b-table-column>
              <b-table-column
                label="Subtotal"
                v-slot="props"
                numeric>
                <p><Currency :value="props.row.subtotal" /></p>
              </b-table-column>
              <b-table-column
                label="Due"
                numeric
                v-slot="props">
                <p><Currency :value="props.row.due_amount" /></p>
              </b-table-column>
            </b-table>
          </div>
        </div>
        <!-- Credits -->
        <div class="column">
          <div class="rounded-lg has-background-white px-md py-sm mb-xs">
            <div class="columns is-vcentered">
              <div class="column">
                <p class="has-text-grey-light is-size-7">{{ credits.length }} {{ (credits.length === 1) ? 'Credit' : 'Credits' }}</p>
              </div>
              <div class="column is-narrow">
                <b-select size="is-small" v-model="sort">
                  <option value="-id">Most Recent</option>
                  <option value="id">Oldest</option>
                  <option>Largest Adjustments</option>
                </b-select>
              </div>
              <div class="column is-narrow">
                <b-button size="is-small">Export</b-button>
              </div>
            </div>
          </div>
          <div class="rounded-lg has-background-white pa-md">
            <b-table
              class="is-size-7"
              :data="credits"
              :loading="loadingInvoices">
              <b-table-column
                label="Credit #"
                v-slot="props">
                <a href="#" @click.prevent class="has-text-info has-text-weight-bold">{{ props.row.id }}</a>
              </b-table-column>
              <template #empty>
                <div class="is-size-7 has-text-centered has-text-grey-light">
                  <p v-if="!loadingCredits" class="py-xxl">No credits found for this period</p>
                  <p v-else>Loading...</p>
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  computed: {
    ...mapGetters(['context']),
    loading () {
      return (this.loadingConnection || this.loadingInvoices || this.loadingCredits)
    },
    vendorStats () {
      return this.$utils.find(this.stats, (vendor) => {
        return vendor.id === this.$route.params.id
      })
    }
  },
  data () {
    return {
      loadingConnection: true,
      loadingInvoices: true,
      loadingCredits: true,
      connection: null,
      invoices: [],
      credits: [],
      sort: '-id'
    }
  },
  methods: {
    loadConnection () {
      this.loadingConnection = true
      const endpoint = this.context.route + '/connections/' + this.$route.params.id + '/'
      this.$http.get(endpoint).then(response => {
        this.connection = response.data
        this.loadingConnection = false
      })
    },
    loadInvoices () {
      this.loadingInvoices = true
      const endpoint = this.context.route + '/invoices/'
      const query = {
        params: {
          fields: ['id'],
          limit: 12,
          connection_id: this.$route.params.id,
          terms_date_due_gte: this.startDate.unix(),
          terms_date_due_lte: this.endDate.unix(),
          status: 'closed'
        }
      }
      this.$http.get(endpoint, query).then(response => {
        this.invoices = response.data.results
        this.loadingInvoices = false
      })
    },
    loadCredits () {
      this.loadingCredits = true
      const endpoint = this.context.route + '/credits/'
      const query = {
        params: {
          fields: ['id'],
          connection_id: this.$route.params.id,
          credited_at_gte: this.startDate.unix(),
          credited_at_lte: this.endDate.unix(),
          status: 'closed'
        }
      }
      this.$http.get(endpoint, query).then(response => {
        this.credits = response.data.results
        this.loadingCredits = false
      })
    }
  },
  mounted () {
    this.loadConnection()
    this.loadInvoices()
    this.loadCredits()
  },
  props: ['stats', 'year', 'month', 'day', 'startDate', 'endDate']
}
</script>
