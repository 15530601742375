<template>
  <div>
    <div class="columns">
      <div class="column is-one-fifth">
        <p class="has-text-weight-bold is-size-6 mb-sm">Store Details</p>
        <p class="has-text-grey is-size-7">Information about {{ app.platform }} connected with your Marketplace account.
        </p>
      </div>
      <div class="column">
        <div class="has-background-white rounded-lg">
          <div class="pa-lg">
            <div class="mb-md">
              <p class="has-text-weight-bold">{{ app.platform }} Account ID</p>
              <p>{{ accountId }}</p>
            </div>
            <div>
              <p class="has-text-weight-bold">Platform Account ID</p>
              <p>{{ platformAccountId }}</p>
            </div>
            <div>
              <b-field label="Location Number">
                <b-input v-model="inputValue"></b-input>
              </b-field>
              <b-button type="is-primary rounded-sm"
                :disabled="saving" @click="update" :loading="saving">Update</b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['app', 'context']),
    endpoint () {
      return this.context.route + '/platform-accounts/' + this.$route.params.id + '/'
    },
    platformAccountId () {
      let platformAccountId = '-'
      if (this.account && this.account.id) {
        platformAccountId = this.account.id
      }
      return platformAccountId
    },
    accountId () {
      let accountId = '-'
      const credentials = this.account.credentials.filter(el => el.fabric_account)
      if (credentials && credentials.length) {
        accountId = credentials[0].fabric_account.values &&
                      credentials[0].fabric_account.values.length &&
                      credentials[0].fabric_account.values[0].value
      }
      return accountId
    }
  },
  data () {
    return {
      saving: false,
      errors: {},
      inputValue: null
    }
  },
  mounted () {
    this.fetchInitialValue()
  },
  methods: {
    update () {
      const credentials = this.account.credentials.filter(el => el.location_num)
      const locationNum = credentials[0].location_num
      const json = { credentials: [{ id: locationNum.values[0].id, credential: { code: locationNum.code }, value: this.inputValue }] }

      this.saving = true
      this.$http.patch(this.endpoint, json).then(() => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Sync enabled!' })
      }).catch(() => {
        const errorMessage = 'Could not update Location Number!'
        this.$buefy.toast.open({ type: 'is-danger', message: errorMessage })
      }).finally(() => {
        this.saving = false
      })
    },
    fetchInitialValue () {
      let locationNum = '-'
      const credentials = this.account.credentials.filter(el => el.location_num)
      if (credentials && credentials.length) {
        locationNum = credentials[0].location_num.values &&
                      credentials[0].location_num.values.length &&
                      credentials[0].location_num.values[0].value
      }

      this.inputValue = locationNum
    }
  },
  props: ['account']
}
</script>
