<template>
  <form @submit.prevent="save">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Accept Freight Delivery</p>
      </header>
      <section class="modal-card-body">
        <b-message type="is-info">
          When you accept this order, all order information will be passed to
          the freight provider and your shipments will be automatically created.
          You will receive additional instructions from the freight provider.
          No additional data is required in {{ app.platform }} for this order.
        </b-message>
        <!-- Freight Service -->
        <div class="mb-lg">
          <p class="has-text-weight-bold is-size-6 mb-sm">Select Pickup Date</p>
          <b-datepicker
            v-model="pickUpDate"
            size="is-medium"
            :disabled="saving"
            :minDate="minSelectableDate"
            placeholder="Click to select..."
            icon="calendar-today"
            trap-focus>
          </b-datepicker>
        </div>
        <!-- Ship From -->
        <div class="mb-lg">
          <ShipFrom v-model="shipFrom" />
        </div>
      </section>
      <footer class="modal-card-foot space-between">
        <div class="buttons">
          <button class="button" type="button" @click="$parent.close()">Close</button>
        </div>
        <div>
          <b-button
            type="is-primary"
            native-type="submit"
            :loading="saving"
            :disabled="!ready || saving">
            Accept Freight Order
          </b-button>
        </div>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import ShipFrom from '../Fulfill/ShipFrom'

export default {
  components: {
    ShipFrom
  },
  data () {
    return {
      saving: false,
      shipFrom: null,
      pickUpDate: this.$dates().toDate()
    }
  },
  computed: {
    ...mapGetters(['app', 'context']),
    endpoint () {
      return this.context.route + `/orders/${this.order.id}/acknowledge/`
    },
    ready () {
      return this.shipFrom !== null && this.pickUpDate !== null
    },
    json () {
      const json = {
        pickup_on: this.$dates(this.pickUpDate).format('YYYY-MM-DD'),
        ship_from: { ...this.shipFrom.address }
      }
      return json
    },
    minSelectableDate () {
      return this.$dates().subtract(30, 'day').toDate()
    }
  },
  methods: {
    save () {
      this.saving = true
      this.$http.put(this.endpoint, this.json).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Freight shipment accepted!' })
        this.$emit('fulfillment:updated')
        this.$parent.close()
      }).catch(error => {
        console.log(error.response.data)
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {

  },
  props: ['order']
}
</script>
