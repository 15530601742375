<template>
  <div>
    <!-- Content -->
    <div class="">
      <p class="has-text-weight-bold mb-md">Map Shopify carriers to {{app.productName}} carriers</p>
      <p class="mb-lg">
        Mapping your Shopify carriers enables {{app.productName}} to read the fulfilment details from your orders
        and seamlessly pass the fulfillment details to your merchant partner.
      </p>
      <div class="mb-lg">
        <CarrierMapping :account="account" :isOnboarding="true" />
      </div>
    </div>
    <!-- Navigate -->
    <div class="py-lg">
      <div class="columns is-centered">
        <div class="column">
          <b-button
            type="is-light rounded-lg"
            size="is-medium"
            @click="goBack"
            expanded
            ><b-icon icon="arrow-left"></b-icon>
          </b-button>
        </div>
        <div class="column">
          <b-button
            type="is-primary rounded-lg"
            size="is-medium"
            expanded
            @click="goForward"
            :disabled="saving"
            :loading="saving"
          >Save &amp; Continue</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CarrierMapping from '../../../Settings/components/Shopify/CarrierMapping.vue'

export default {
  components: {
    CarrierMapping
  },
  computed: {
    ...mapGetters(['app', 'context'])
  },
  data () {
    return {
      saving: false,
      errors: {}
    }
  },
  methods: {
    goBack () {
      this.$emit('navigate', 'Orders')
    },
    goForward () {
      this.$buefy.toast.open({ type: 'is-success', message: 'Setting updated!' })
      this.$emit('navigate', 'Confirmation')
    }
  },
  props: {
    account: {
      type: Object,
      required: true
    }
  }
}
</script>
