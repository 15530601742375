<template>
  <div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Rename</p>
      </header>
      <section class="modal-card-body">
      <div class="mb-lg">
        <!-- Category Name -->
        <b-field
          label="Category Name"
          expanded>
          <b-input
            type="text"
            size="is-medium"
            @input="isDirty = 1"
            v-model="name"
          ></b-input>
        </b-field>
      </div>
      </section>
      <footer class="modal-card-foot space-between" >
        <b-button @click="$parent.close()">Close</b-button>
        <b-button
          type="is-primary rounded-lg"
          class="is-pulled-right"
          :disabled="saving || !isDirty"
          :loading="saving"
          @click="$emit('update:category', { name, id })"
        >Update
        </b-button>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {
  },
  computed: {
    ...mapGetters(['context'])
  },
  data () {
    return {
      name: null,
      id: null,
      isDirty: false,
      saving: false
    }
  },
  methods: {
  },
  mounted () {
    this.name = this.category.category
    this.id = this.category.id
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    category: {
      type: Object,
      required: true
    }
  }
}
</script>
