<template>
  <div>
    <!-- Shop Config Loaded -->
    <div v-if="!loading && shop !== null">
      <transition name="fade" mode="out-in">
        <component :is="step" :shop="shop" :account="account" @change:step="goto" @complete="complete" @update:account="updateAccount" ></component>
      </transition>
    </div>
    <!-- Shop Config Loading -->
    <div v-else>
      <loading message="Communicating with Shopify..." />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Inventory from '../../components/Integration/Inventory'
import Location from '../../components/Integration/Location'
import Orders from '../../components/Integration/Orders'

export default {
  components: {
    Inventory,
    Location,
    Orders
  },
  data () {
    return {
      loading: true,
      shop: null,
      step: 'orders',
      errors: {}
    }
  },
  computed: {
    ...mapGetters(['api', 'assets', 'auth', 'context']),
    endpoint () {
      return this.context.route + '/shopify/' + this.account.id + '/shop-config/'
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint).then(response => {
        this.shop = response.data.shop
      }).finally(() => {
        this.loading = false
      })
    },
    goto (step) {
      this.step = step
    },
    complete () {
      this.$router.push({ name: 'shopify.onboard.complete' })
    },
    updateAccount () {
      this.$emit('update:account')
    }
  },
  mounted () {
    this.$emit('update:step', 2)
    this.load()
  },
  props: ['account']
}
</script>
