<template>
  <div class="has-background-white pa-md pb-lg rounded-lg">
    <div class="has-text-centered mb-md">
      <p class="is-uppercase has-text-weight-bold is-size-7 mb-xs">Product Media</p>
      <p class="is-size-7 has-text-grey">Note: Photos below may be resized for consistency. Please review the native dimensions listed below each photo (WxH).</p>
    </div>
    <div class="columns is-multiline is-mobile is-centered">
      <div class="column is-narrow" v-for="medium in media" :key="medium.id">
        <figure class="image is-128x128 mb-xs">
          <img class="rounded-lg" :src="cdnTransform(medium.url, 128, 128, 'fill')" width="128" height="128" >
        </figure>
        <p class="is-size-8 has-text-centered has-text-grey">{{ medium.width }}x{{ medium.height }}</p>
        <p class="is-size-8 has-text-centered">
          <a :href="cdnTransform(medium.url, null, null, null)" target="_blank" class="has-text-grey">
            <u>Full Size</u>
            <b-icon icon="open-in-new" size="is-small" />
          </a>
        </p>
      </div>
    </div>
    <!-- <div v-if="false">
      <carousel :per-page="media.length">
        <slide v-for="medium in media" :key="medium.id">
          <div class="has-text-centered px-md">
            <img :src="cdnTransform(medium.url, 128, 128, 'fill')" height="128" width="128" >
            <p class="is-size-8 has-text-grey">{{ medium.width }}x{{ medium.height }}</p>
          </div>
        </slide>
      </carousel>
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CdnTransform from '@/mixins/CdnTransform'

export default {
  components: {

  },
  computed: {
    ...mapGetters(['context'])
  },
  data () {
    return {

    }
  },
  methods: {

  },
  mounted () {

  },
  mixins: [CdnTransform],
  props: ['product', 'media']
}
</script>
