<template>
  <div>
    <div class="columns">
      <div class="column is-narrow">
        <b-select placeholder="Title" v-model="parameter" expanded size="is-medium" @input="$refs.myAutocomplete.focus()">
          <option value="purchase_order_number">PO #</option>
          <option value="customer_order_number"><span class="is-hidden-mobile">Customer Order #</span></option>
          <option value="retailer_order_num">Retailer Order #</option>
          <option value="tracking_number">Tracking #</option>
          <option value="invoice_number">Invoice #</option>
        </b-select>
      </div>
      <div class="column">
        <b-field>
          <b-autocomplete
            ref="myAutocomplete"
            :placeholder="placeholderText"
            field="id"
            v-model="value"
            :loading="loading"
            :data="results"
            :clear-on-select="true"
            :open-on-focus="true"
            size="is-medium"
            @typing="search"
            @select="viewOrder"
            expanded>
            <template slot-scope="props">
              <div class="media">
                <div class="media-content">
                  <p class="has-text-weight-bold has-text-primary">PO #{{ props.option.purchase_order_number }}</p>
                  <p v-if="props.option.customer_order_number" class="has-text-grey-dark">Customer Order #{{ props.option.customer_order_number }}</p>
                  <p v-if="parameter === 'tracking_number'" class="has-text-weight-bold has-text-info">Tracking #{{ props.option.tracking_number }}</p>
                  <p v-if="parameter === 'invoice_number'" class="has-text-weight-bold has-text-info">Invoice #{{ props.option.invoice_number }}</p>
                </div>
              </div>
            </template>
            <template #empty>No results found</template>
          </b-autocomplete>
        </b-field>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      switch (this.parameter) {
        case 'tracking_number':
          return this.context.route + '/shipments/'
        case 'invoice_number':
          return this.context.route + '/invoices/'
        default:
          return this.context.route + '/orders/'
      }
    },
    query () {
      // always attach short'
      const params = {
        fields: null,
        order_by: '-id'
      }
      // attach additional search param based on what user has selected
      switch (this.parameter) {
        case 'customer_order_number':
          params.customer_order_number_sw = this.value
          params.fields = 'id,purchase_order_number,customer_order_number'
          break
        case 'retailer_order_num':
          params.retailer_order_number_sw = this.value
          params.fields = 'id,purchase_order_number,customer_order_number'
          break
        case 'tracking_number':
          params.search = this.value
          params.fields = 'id,order_id,purchase_order_number,customer_order_number,tracking_number'
          break
        case 'invoice_number':
          params.search = this.value
          params.fields = 'id,order_id,purchase_order_number,customer_order_number,invoice_number'
          break
        default:
          params.purchase_order_number_sw = this.value
          params.fields = 'id,purchase_order_number,customer_order_number'
      }
      return params
    },
    placeholderText () {
      if (this.parameter === 'tracking_number') return 'Search by tracking number...'
      if (this.parameter === 'invoice_number') return 'Search by invoice number...'
      return 'Search for orders by exact match...'
    },
    ready () {
      return this.value
    }
  },
  data () {
    return {
      loading: false,
      parameter: 'purchase_order_number',
      value: null,
      results: [],
      selected: null
    }
  },
  methods: {
    search: debounce(function (name) {
      this.searchOrders()
    }, 1000),
    searchOrders () {
      if (this.ready) {
        this.loading = true
        this.$http.get(this.endpoint, { params: this.query }).then(response => {
          this.results = response.data.results
          this.loading = false
        })
      }
    },
    viewOrder (option) {
      if (this.parameter === 'tracking_number' || this.parameter === 'invoice_number') {
        this.$router.push({ name: 'order.summary', params: { id: option.order_id } })
        return
      }
      this.$router.push({ name: 'order.summary', params: { id: option.id } })
    }
  },
  watch: {
    parameter (value) {
      if (this.value !== null) {
        this.searchOrders()
      }
    }
  }
}
</script>
