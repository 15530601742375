<template>
  <div>
    Connect to Instagram.
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  computed: {
    ...mapGetters([])
  },
  data () {
    return {

    }
  },
  methods: {

  },
  mounted () {
  }
}
</script>
