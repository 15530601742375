import List from './views/List'
import Detail from './views/Detail'

export default [{
  path: '/settings/departments',
  name: 'departments',
  component: List
}, {
  path: '/settings/departments/:id',
  name: 'department',
  component: Detail
}]
