export default {
  data () {
    return {
      /**
       * Signal that the exporting action is processing
       * @type {Boolean}
       */
      exporting: false,
      /**
       * Signal to optionally show a modal before exporting
       * @type {Boolean}
       */
      showExportModal: false
    }
  },
  computed: {
    /**
     * Given the filter set and pagination configs, return a query string
     * object that can be passed as query params to the export endpoint (making
     * sure to exclude pagination filters )
     * @returns {Object}
     */
    exportQuery () {
      const query = {
        params: {}
      }
      // search params first
      if (
        'search' in this.filters &&
        'parameter' in this.filters.search &&
        'query' in this.filters.search &&
        this.filters.search.query !== null &&
        this.filters.search.query !== ''
      ) {
        query.params[this.filters.search.parameter] = this.filters.search.query
      }
      // facets next...
      if ('facets' in this.filters) {
        for (const facetKey in this.filters.facets) {
          const facet = this.filters.facets[facetKey]
          if (facet.length > 0) {
            const values = []
            facet.forEach(option => {
              values.push(option.value)
            })
            query.params[facetKey] = values.join(',')
          }
        }
      }
      if (this.selected && this.selected.length > 0) {
        query.params.id = this.selected.map(({ id }) => (id)).join(',')
      }
      // timestamps...
      if ('timestamps' in this.filters) {
        for (const tsKey in this.filters.timestamps) {
          const timestamp = this.filters.timestamps[tsKey]
          if (timestamp) {
            if (typeof timestamp[0] !== 'undefined' && timestamp[0] !== null) {
              query.params[tsKey + '_gte'] = timestamp[0]
            }
            if (typeof timestamp[1] !== 'undefined' && timestamp[1] !== null) {
              query.params[tsKey + '_lte'] = timestamp[1]
            }
          }
        }
      }
      // ...then order by...
      if (this.orderBy) {
        query.params.order_by = this.orderBy
      }
      // ...then the 'always on' params...
      if (typeof this.alwaysExport === 'object') {
        Object.assign(query.params, this.alwaysExport)
      }
      return query
    }
  },
  methods: {
    scheduleExport () {
      this.exporting = true
      this.$http.get(this.exportEndpoint, this.exportQuery).then(response => {
        this.exporting = false
        this.$buefy.toast.open({ type: 'is-success', message: 'Export scheduled! Please allow 5-10 mins for delivery.', duration: 5000 })
        this.$emit('export:scheduled')
        this.showExportModal = false
      })
    }
  }
}
