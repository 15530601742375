<template>
  <div class="section">
    <div class="container">
      <!-- Page Header -->
      <div class="columns is-vcentered">
        <div class="column is-one-third">
          <breadcrumbs :history="[{ label: 'Settings', name: 'settings'}]" current="Connections" :hideBack="true" />
        </div>
      </div>
      <div>
      <!-- connections list -->
        <div>
          <div v-if="!loading" class="columns is-multiline">
            <div v-for="{id, retailer, updated_at} in results" class="column is-one-third" :key="id">
              <div class="card">
                <div class="card-content">
                  <p>{{ retailer.name }}</p>
                  <div class="">
                    <p>{{ $dates.utc(updated_at).local().format('MMM DD, YYYY') }}</p>
                  </div>
                  <div class="is-flex is-justify-content-flex-end">
                    <router-link class="button" :to="{ name: 'categories.connection', params: { connectionId: id }}">
                    View
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
            <div v-else class="py-xxl has-text-grey has-text-centered">
              {{ (loading) ? "Loading data..." : 'No records found!' }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {
  },
  computed: {
    ...mapGetters(['api', 'context'])
  },
  data () {
    return {
      loading: false,
      results: []
    }
  },
  methods: {
    loadConnections () {
      this.loading = true
      const endpoint = `${this.context.route}/connections/`
      this.$http.get(endpoint).then(({ data: { results } }) => {
        this.results = results
      }).finally(() => {
        this.loading = false
      })
    }
  },
  mounted () {
    this.loadConnections()
  }
}
</script>
