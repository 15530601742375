<template>
  <div>
    <b-select
      v-model="departmentOwner"
      placeholder="Select User..."
      expanded
      :loading="loading"
      :disabled="loading"
      :size="size"
      @input="announce">
      <option v-for="user in users" :key="user.id" :value="user.id">
        {{ user.first_name }} {{ user.last_name }}
      </option>
    </b-select>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      loading: false,
      departmentOwner: null,
      users: []
    }
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/users/'
    },
    query () {
      return {
        params: {
          mini: 1,
          pagination: 0,
          order_by: 'first_name',
          type: 'standard',
          status: 'active,invited'
        }
      }
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint, this.query).then(response => {
        this.users = response.data.results
        this.loading = false
      })
    },
    announce () {
      this.$emit('input', this.departmentOwner)
    }
  },
  mounted () {
    this.load()
  },
  props: {
    size: {
      type: String,
      default: null
    }
  }
}
</script>
