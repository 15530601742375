<template>
  <div>
    <form @submit.prevent="upload">
      <div class="columns">
        <div class="column is-one-fifth">
          <p class="has-text-weight-bold is-size-6 mb-sm">Logo</p>
          <div class="has-text-grey is-size-7">
            <p class="mb-md">
              Your organization's logo is often displayed to your partners
              throughout the {{ app.platform }} platform (but most notably included
              on your packing slip)
            </p>
            <p>
              The ideal logo is a 360x120 pixel PNG with a transparent background.
            </p>
          </div>
        </div>
        <div class="column">
          <div class="has-background-white rounded-xl">
            <!-- Loading platform account or loading marketplace -->
            <div v-if="loading" class="pa-xl">
              <loading message="Loading..."></loading>
            </div>
            <!-- Loaded -->
            <div v-else>
              <div class="pa-xl">
                <!-- Cover Photo -->
                <div class="mb-lg">
                  <p class="has-text-grey mb-md">Tip: You can zoom and pan the image below to crop or adjust your logo!</p>
                  <croppa
                    v-model="croppa"
                    canvas-color="transparent"
                    remove-button-color="black"
                    placeholder="Click (or tap) to select a photo."
                    initial-size="natural"
                    :initial-image="(this.existingLogo !== null) ? this.existingLogo : null"
                    :placeholder-font-size="16"
                    :auto-sizing="true"
                    :quality="1"
                    :prevent-white-space="true"
                    :accept="'image/*'"
                    :file-size-limit="5242880"
                    :show-remove-button="false"
                    @init="croppaReady = true"
                    @file-size-exceed="sizeError"
                    @file-type-mismatch="typeError"
                    @new-image="validate"
                    @image-remove="reset"
                    @move="validate"
                    @zoom="validate"
                  ></croppa>
                </div>
                <!-- Instructions -->
                <div>
                  <div class="columns">
                    <!-- Device Button -->
                    <div class="column is-one-third-desktop">
                      <b-button
                        type="is-light rounded-lg"
                        size="is-medium"
                        icon-left="desktop-mac"
                        @click="croppa.chooseFile()"
                        expanded>
                        Upload From Device
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="border-t">
                <div class="columns is-vcentered is-marginless">
                  <div class="column">
                    <div class="px-sm has-text-grey-light">
                      <i v-if="inputError !== null" class="has-text-danger">{{ inputError }}</i>
                      <i v-if="isDirty" class="has-text-danger">Click 'Save' to upload your logo.</i>
                    </div>
                  </div>
                  <div class="column is-narrow">
                    <div class="px-sm">
                      <b-button native-type="submit" type="is-primary rounded-lg" :loading="updating" :disabled="updating || !isDirty">Save</b-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CdnTransform from '@/mixins/CdnTransform'

export default {
  components: {

  },
  computed: {
    ...mapGetters(['app', 'api', 'context']),
    endpoint () {
      return this.context.route + '/'
    },
    method () {
      return (this.existingLogo !== null) ? 'patch' : 'post'
    },
    existingLogo () {
      return (this.loading === false && this.logo !== null) ? this.cdnTransform(this.logoUrl, 360, 120, 'fill') : null
    },
    json () {
      if (this.tempfile === null) return null
      return {
        logo_photo: {
          tempfile: {
            id: this.tempfile.id
          }
        }
      }
    },
    quality () {
      if (this.croppaReady) {
        return (this.croppa.realWidth >= 360) ? 1 : parseFloat((360 / this.croppa.realWidth).toFixed(2))
      }
      return 2
    }
  },
  data () {
    return {
      isDirty: false,
      updating: false,
      showTooltip: false,
      croppaReady: false,
      croppa: {},
      inputError: null,
      readyToUpload: false,
      processing: false,
      tempfile: null,
      errors: [],
      story: null
    }
  },
  methods: {
    sizeError () {
      this.readyToUpload = false
      this.inputError = 'The filesize of your logo is too large. Please try again with a smaller logo.'
    },
    typeError () {
      this.readyToUpload = false
      this.inputError = 'Please upload an image file only.'
    },
    validate () {
      if (this.croppa.naturalWidth < 360 || this.croppa.naturalHeight < 120) {
        this.readyToUpload = false
        this.inputError = 'Please upload a larger logo (at least 360x120 pixels)'
        return
      }
      this.isDirty = true
      this.readyToUpload = true
      this.inputError = null
    },
    reset () {
      this.isDirty = false
      this.readyToUpload = false
      this.inputError = null
      this.errors = []
    },
    usePhoto (payload) {
      this.croppa.initialImage = payload.media_url
      this.croppa.refresh()
    },
    upload () {
      this.updating = true
      this.croppa.generateBlob(blob => {
        const route = this.api.baseUrl + '/v1/tempfiles/upload/'
        const formdata = new FormData()
        formdata.append('file', blob, 'logo.png')
        this.$http.post(route, formdata).then(response => {
          this.tempfile = response.data
          this.$http.patch(this.endpoint, this.json).then(response => {
            this.reset()
            this.$buefy.toast.open({ type: 'is-success', message: 'Logo updated!' })
          }).finally(() => {
            this.updating = false
          })
        })
      }, 'image/png')
    }
  },
  mixins: [CdnTransform],
  props: ['loading', 'logoUrl']
}
</script>

<style scoped>
.croppa-container {
  width: 360px;
  height: 120px;
  background-color: #EEF1F9;
}
</style>
