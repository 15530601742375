<template>
  <div>
    <!-- Vendors -->
    <div class="mb-lg">
      <div class="columns is-centered">
        <div class="column is-half">
          <div class="has-background-white rounded-xl pa-lg">
            <div class="mb-xl">
              <p class="has-text-weight-bold has-text-centered mb-sm">Select Supplier</p>
              <b-autocomplete
                :data="vendors"
                placeholder="Search for suppliers..."
                field="name"
                :loading="loadingVendors"
                @typing="searchVendors"
                @select="setVendor">
                <template slot-scope="props">
                  <p>{{ props.option.name }} (ID: {{ props.option.id }})</p>
                </template>
                <template slot="empty">
                  <p v-if="loadingVendors">Loading...</p>
                  <p v-else>No results found.</p>
                </template>
              </b-autocomplete>
            </div>
            <!-- Recent Vendors -->
            <div class="mt-md" v-if="recentVendors && recentVendors.length">
              <p class="is-size-7 has-text-weight-bold mb-md has-text-centered has-text-grey">Recent Suppliers</p>
              <div class="columns is-multiline is-variable is-1">
                <div class="column is-one-third is-paddingless" v-for="vendor in recentVendors" :key="vendor.id">
                  <div class="ma-xs">
                    <b-button size="is-small" @click="setVendor(vendor)" expanded >{{ $utils.truncate(vendor.name, { length: 24 }) }}</b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Retailers -->
    <div class="columns is-centered">
      <div class="column is-half mx-auto">
        <div class="has-background-white rounded-xl pa-lg">
          <div class="mb-xl">
            <p class="has-text-weight-bold has-text-centered mb-sm">Select Retailer</p>
            <b-autocomplete
              :data="retailers"
              placeholder="Search for retailers..."
              field="name"
              :loading="loadingRetailers"
              @typing="searchRetailers"
              @select="setRetailer">
              <template slot-scope="props">
                <p>{{ props.option.name }} (ID: {{ props.option.id }})</p>
              </template>
              <template slot="empty">
                <p v-if="loadingRetailers">Loading...</p>
                <p v-else>No results found.</p>
              </template>
            </b-autocomplete>
          </div>
          <!-- Recent Retailers -->
          <div class="mt-md" v-if="recentRetailers && recentRetailers.length">
            <p class="is-size-7 has-text-weight-bold mb-md has-text-centered has-text-grey">Recent Retailers</p>
            <div class="columns is-multiline is-variable is-1">
              <div class="column is-one-third is-paddingless" v-for="retailer in recentRetailers" :key="retailer.id">
                <div class="ma-xs">
                  <b-button size="is-small" @click="setRetailer(retailer)" expanded>{{ $utils.truncate(retailer.name, { length: 24 }) }}</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- View All -->
    <div v-if="auth.isStaff" class="py-md has-text-centered">
      <router-link :to="{ name: 'suppliers' }">View Supplier Roster (Staff Only)</router-link>
    </div>

    <!-- Create Account -->
    <div v-if="auth.isStaff" class="py-md has-text-centered">
      <router-link :to="{ name: 'account' }">Create Account (Staff Only)</router-link>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import debounce from 'lodash/debounce'
export default {
  data: function () {
    return {
      loadingVendors: false,
      loadingRetailers: false,
      vendors: [],
      vendor: null,
      retailers: [],
      retailer: null,
      recentVendors: null,
      recentRetailers: null
    }
  },
  computed: {
    ...mapGetters(['auth'])
  },
  methods: {
    searchVendors: debounce(function (name) {
      this.loadingVendors = true
      const query = {
        pagination: 0,
        short: 1,
        order_by: 'name',
        search: name
      }
      this.$http.get('brands/', { params: query }).then(response => {
        this.vendors = response.data.results
        this.loadingVendors = false
      })
    }, 500),
    searchRetailers: debounce(function (name) {
      this.loadingRetailers = true
      const query = {
        short: 1,
        pagination: 0,
        order_by: 'name',
        search: name
      }
      this.$http.get('retailers/', { params: query }).then(response => {
        this.retailers = response.data.results
        this.loadingRetailers = false
      })
    }, 500),
    setVendor (vendor) {
      this.setStorage('recentVendors', vendor)
      this.$store.dispatch('setContext', { type: 'vendor', partner: vendor })
      if ('intended' in this.$route.query) {
        this.$router.push({ path: this.$route.query.intended })
        return
      }
      this.$emit('navigate:to')
    },
    setRetailer (retailer) {
      this.setStorage('recentRetailers', retailer)
      this.$store.dispatch('setContext', { type: 'retailer', partner: retailer })
      if ('intended' in this.$route.query) {
        this.$router.push({ path: this.$route.query.intended })
        return
      }
      this.$emit('navigate:to')
    },
    setStorage (key, value) {
      const valueArray = this[key]
      if (valueArray.length <= 6) {
        valueArray.unshift(value)
      } else {
        valueArray.pop()
        valueArray.unshift(value)
      }
      const result = [...new Map(valueArray.map(item => [item.id, item])).values()]
      localStorage.setItem(key, JSON.stringify(result))
    },
    customLabel ({ name, id }) {
      return name + ' (ID: ' + id + ')'
    }
  },
  mounted: function () {
    this.recentVendors = localStorage.getItem('recentVendors') ? JSON.parse(localStorage.getItem('recentVendors')) : []
    this.recentRetailers = localStorage.getItem('recentRetailers') ? JSON.parse(localStorage.getItem('recentRetailers')) : []
  }
}
</script>
