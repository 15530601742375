<template>
  <div class="columns">
    <div class="column">
      <div class="pr-lg border-r">
        <p class="is-size-4">Connect Shopify to {{ app.platform }}</p>
        <p class="mt-md">
          To install the fabric Dropship Shopify App, you must have
          a {{ app.platform }} account that you receive when you are
          invited to join {{ app.platform }} by a retailer that uses the
          fabric Dropship Platform.
        </p>
        <p class="mt-md">
          If you have a {{ app.platform }} account, please sign in and choose
          the account you would like to connect to Shopify.
        </p>
        <p class="mt-md">
          If you do not have an {{ app.platform }} account and are looking for
          to connect with retailers in the fabric Dropship network, drop us an email at
          <a :href="'mailto:' + app.supportEmail" class="has-text-info has-text-weight-bold">source@fabric.inc</a>
          We're happy to discuss your business and see if we can find a match!
        </p>
        <p class="mt-md">
          If you need any other assitance, please contact
          <a :href="'mailto:' + app.supportEmail" class="has-text-info has-text-weight-bold">{{ app.supportEmail }}</a>
        </p>
      </div>
    </div>
    <div class="column is-two-fifths">
      <div>
        <div v-if="auth && auth.user !== null">
          <div v-if="!auth.isStaff" >
            <SupplierAccounts />
          </div>
          <div v-else>
            <p class="has-text-grey-light has-text-centered pt-3xl">
              This action is not available to staff users.
            </p>
          </div>
          <div class="pt-3xl has-text-centered">
            <p class="has-text-grey-light">Not {{  auth.user.firstName }}?</p>
            <a class="is-size-7" href="#" @click.prevent="$store.dispatch('logout', false)">Sign Out</a>
          </div>
        </div>
        <div v-else>
          <LoginForm />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LoginForm from './LoginForm.vue'
import SupplierAccounts from './SupplierAccounts'

export default {
  components: {
    LoginForm,
    SupplierAccounts
  },
  computed: {
    ...mapGetters(['app', 'context', 'auth'])
  }
}
</script>
