<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Export Offers</p>
    </header>
    <section class="modal-card-body">
      <p class="pa-xl has-text-grey has-text-centered">
        Click 'Export' below to have an offers export delivered to your
        email. Please note that the export will only include
        the items that match your applied filters. Please double check
        your filters before exporting.
      </p>
    </section>
    <footer class="modal-card-foot space-between">
      <b-button @click="$parent.close()">Close</b-button>
      <b-button type="is-primary" @click="scheduleExport">Export</b-button>
    </footer>
  </div>
</template>

<script>
export default {
  methods: {
    scheduleExport () {
      this.$emit('offers:export')
      this.$parent.close()
    }
  }
}
</script>
