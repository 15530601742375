<template>
  <b-button
    type="is-light"
    size="is-medium"
    expanded
    :disabled="saving"
    :loading="saving"
    @click="transmit"
  >Retransmit 850</b-button>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      saving: false
    }
  },
  computed: {
    ...mapGetters(['context']),
    ediAccountId () {
      return this.envelope.receiver.id
    },
    endpoint () {
      if (this.envelope !== null && this.ediAccountId) {
        return this.context.route + '/edi-accounts/' + this.ediAccountId + '/envelopes/' + this.envelope.id + '/transmit/'
      }
      return null
    }
  },
  methods: {
    transmit () {
      this.saving = true
      this.$http.put(this.endpoint).then(() => {
        this.$buefy.toast.open({ type: 'is-success', message: '850 transmission successful!' })
      }).catch(() => {
        this.$buefy.toast.open({ type: 'is-danger', message: 'Transmission failed!' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  props: ['envelope']
}
</script>
