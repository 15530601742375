<template>
  <div>
    <!-- Content -->
    <div class="">
      <p class="mb-lg">
        {{ app.platform }} can be configured to automatically push purchase
        orders to and pull tracking numbers from your ShipStation account
        - we just need a set of ShipStation
        API Keys. Please provide your API keys below. If you need help
        retrieving API keys, please consult
        <a href="https://help.shipstation.com/hc/en-us/articles/360025856212-ShipStation-API" target="_blank" class="has-text-info">ShipStation's guide</a>.
      </p>
      <!-- Account Exists -->
      <div v-if="account !== null">
        <ShipStationPresenter :account="account" />
      </div>
      <!-- Account Doesn't Exist -->
      <div v-else>
        <b-field label="ShipStation API Key" >
          <b-input v-model="apiKey" size="is-medium"></b-input>
        </b-field>
        <b-field label="ShipStation API Secret" >
          <b-input v-model="apiSecret" size="is-medium"></b-input>
        </b-field>
      </div>
    </div>
    <!-- Navigate -->
    <div class="py-lg">
      <div class="columns is-centered">
        <div class="column">
          <b-button
            type="is-light rounded-lg"
            size="is-medium"
            @click="goBack"
            expanded
            ><b-icon icon="arrow-left"></b-icon>
          </b-button>
        </div>
        <div class="column">
          <b-button
            type="is-primary rounded-lg"
            size="is-medium"
            expanded
            @click="goForward"
            :disabled="saving"
            :loading="saving"
          >Continue</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ShipStationPresenter from '@/components/Models/PlatformAccount/Presenters/Shipstation'

export default {
  components: {
    ShipStationPresenter
  },
  computed: {
    ...mapGetters(['app', 'context'])
  },
  data () {
    return {
      apiKey: null,
      apiSecret: null,
      saving: false,
      errors: {}
    }
  },
  methods: {
    goBack () {
      this.$router.push({ name: 'onboarding.tasks', params: { connection: this.connection.id } })
    },
    goForward () {
      // if platform account exists, don't do anything
      if (this.account !== null) {
        this.$emit('navigate', 'StoreSetup')
        return
      }
      this.saving = true
      const endpoint = this.context.route + '/platform-accounts/'
      const json = {
        platform: { code: 'shipstation' },
        credentials: [{
          credential: { code: 'push_order_on_order_create' },
          value: 0
        }, {
          credential: { code: 'shipstation_api_key' },
          value: this.apiKey
        }, {
          credential: { code: 'shipstation_api_secret' },
          value: this.apiSecret
        }]
      }
      this.$http.post(endpoint, json).then(response => {
        this.$buefy.toast.open({ type: 'is-info', message: 'Platform Added!' })
        this.$emit('platform:added')
        this.$emit('navigate', 'StoreSetup')
      }).catch(() => {
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  props: {
    connection: {
      type: Object,
      required: true
    },
    task: {
      type: Object,
      required: true
    },
    account: {
      type: Object
    }
  }
}
</script>
