export default {
  newBrand: {
    label: 'New (from Supplier)',
    tooltip: 'Cancels that have not been acknowledged by the retailer.',
    filters () {
      return {
        preset: 'newBrand',
        search: {},
        facets: {
          is_acknowledged: [{
            label: 'No',
            value: '0'
          }],
          canceled_by: [{
            label: 'Supplier',
            value: 'brand'
          }]
        },
        timestamps: {}
      }
    }
  },
  newRetailer: {
    label: 'New (from Retailer)',
    tooltip: 'Cancels that have not been acknowledged by the supplier.',
    filters () {
      return {
        preset: 'newRetailer',
        search: {},
        facets: {
          is_acknowledged: [{
            label: 'No',
            value: '0'
          }],
          canceled_by: [{
            label: 'Retailer',
            value: 'retailer'
          }]
        },
        timestamps: {}
      }
    }
  }
}
