<template>
  <div>
    <p class="has-text-weight-bold mb-sm">Carrier Account</p>
    <!-- Select Service -->
    <div>
      <b-field>
        <b-select
          v-model="account"
          placeholder="Select Account..."
          :loading="loading"
          expanded
          size="is-medium"
          @input="announce">
          <option v-for="account in accounts" :key="account.id" :value="account">{{ account.nickname }}</option>
        </b-select>
      </b-field>
      <div>
        <p v-if="account !== null" class="has-text-grey is-size-7">
          {{ account.carrier.name }} account provided by {{ account.owner.name }}
        </p>
        <p v-else class="has-text-grey-light is-size-7">
          Select an account above...
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/connections/' + this.connectionId + '/'
    }
  },
  data () {
    return {
      loading: true,
      errors: [],
      accounts: [],
      account: null
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint).then(response => {
        this.accounts = response.data.shipping_accounts
        if (response.data.shipping_accounts.length === 1) {
          this.account = response.data.shipping_accounts[0]
          this.$emit('input', this.account)
        }
        this.loading = false
      })
    },
    announce () {
      this.$emit('input', this.account)
    }
  },
  mounted () {
    this.load()
  },
  props: ['connectionId']
}
</script>
