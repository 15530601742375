<template>
  <div>
  <form>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Request History</p>
      </header>
      <section class="modal-card-body">
        <table class="table is-fullwidth">
          <thead>
            <tr>
              <th>Requested By</th>
              <th>Requested On</th>
              <th class="has-text-right">Status</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="request in outstanding" :key="request.id">
              <td>
                {{ request.requested_by.first_name }}
                {{ request.requested_by.last_name }}
              </td>
              <td>{{ $dates.utc(request.created_at).local().format('MMM D, YYYY') }}</td>
              <td class="has-text-right">{{ $utils.upperFirst(request.status) }}</td>
            </tr>
          </tbody>
        </table>
      </section>
      <footer class="modal-card-foot">
        <button class="button" type="button" @click="$parent.close()">Close</button>
      </footer>
    </div>
  </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  computed: {
    ...mapGetters(['context'])
  },
  data () {
    return {

    }
  },
  methods: {

  },
  mounted () {

  },
  props: ['outstanding']
}
</script>
