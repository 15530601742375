<template>
  <span>{{ label }}</span>
</template>

<script>
export default {
  computed: {
    label () {
      if (this.module === 'process_shipment_file_upload') return 'Import Shipments'
      if (this.module === 'process_invoice_file_upload') return 'Import Invoices'
      if (this.module === 'ingest_855') return 'EDI: Import Acknowledgement (855)'
      if (this.module === 'ingest_810') return 'EDI: Import Invoice (810)'
      if (this.module === 'ingest_856') return 'EDI: Import ASN (856)'
      if (this.module === 'ingest_846') return 'EDI: Inventory (846)'
      if (this.module === 'import_feed') return 'Import Products'
      if (this.module === 'pull_products') return 'Shopify: Import Products'
      if (this.module === 'push_inventory') return 'Push Inventory'
      if (this.module === 'push_order') return 'Push Order'
      if (this.module === 'products/update') return 'Shopify: Product Updated'
      if (this.module === 'orders/paid') return 'Shopify: Order Import Attempt'
      return this.module
    }
  },
  props: ['module']
}
</script>
