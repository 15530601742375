<template>
  <div class="modal-card">
    <section class="modal-card-body">
      <!-- Logo -->
      <div class="has-text-centered my-md">
        <Logo color="black" />
      </div>
      <!-- Single Connection -->
      <div v-if="connections.length === 1">
        <div class="has-text-centered">
          <p class="is-size-5 mb-sm">You have a new connection!</p>
          <p class="mb-md">{{ connections[0].brand.name }} has a pending connection that is in onboarding. Would you like to resume onboarding with {{ connections[0].retailer.name }}?</p>
          <p class="mb-lg">
            <b-button
              type="is-primary"
              size="is-medium"
              outlined
              @click="continueOnboarding(connections[0])">
              Continue Onboarding
            </b-button>
          </p>
          <p class="mb-lg"><a href="#" @click.prevent="$parent.close()">Skip for now</a></p>
        </div>
      </div>
      <!-- Multiple Connections -->
      <div v-else-if="connections.length > 1">
        <div class="has-text-centered">
          <p class="is-size-5">You have a new connections!</p>
          <p class="mb-md">Select a connection below to resume onboarding...</p>
        </div>
        <div v-for="(connection, index) in connections" :key="connection.id" :class="(index === 0) ? '' : 'border-t'" class="mb-sm py-md">
          <div class="columns is-vcentered">
            <div class="column">
              <p class="has-text-weight-bold">{{ connection.retailer.name }}</p>
              <p class="has-text-grey-light is-size-7">Connection # {{ connection.id }}</p>
            </div>
            <div class="column is-narrow">
              <b-button
                type="is-primary"
                outlined
                @click="continueOnboarding(connection)">
                Continue Onboarding
              </b-button>
            </div>
          </div>
        </div>
      </div>
      <!-- No Connections -->
      <div v-else>
        <p class="py-xl has-text-grey-light has-text-centered">All connections have completed onboarding. You may close this window.</p>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Logo from '@/components/Logos/Logo'

export default {
  components: {
    Logo
  },
  computed: {
    ...mapGetters(['app'])
  },
  methods: {
    continueOnboarding (connection) {
      if (this.$route.name !== 'onboarding.tasks') {
        this.$router.push({ name: 'onboarding.tasks', params: { connection: connection.id } })
      }
      this.$parent.close()
    }
  },
  props: ['connections']
}
</script>
