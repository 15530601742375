<template>
  <div id="app">

    <!-- Navigation -->
    <Navigation v-if="showNavigation" @toggle:onboarding="toggleOnboarding" />

    <!-- Content -->
    <div v-if="showContent">
      <router-view />
      <Credits />
    </div>
    <div v-else>
      <div class="pt-xxl">
        <loading message="Preparing app..." />
      </div>
    </div>

    <!-- Global Modal: Server Error  -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active="app.serverError"
      :can-cancel="['escape', 'x']"
      @close="$store.dispatch('clearServerError')">
      <ServerErrorModal />
    </b-modal>
    <!-- Global Modal: Support Request Modal -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active="app.supportRequest"
      :can-cancel="['escape', 'x']"
      @close="$store.dispatch('clearSupportRequest')">
      ><SupportRequestModal />
    </b-modal>
    <!-- Global Modal: Onboarding Onboarding Modal -->
    <b-modal
      v-if="context.isSupplier()"
      has-modal-card
      scroll="keep"
      :active.sync="showOnboardingModal"
      ><OnboardingModal :connections="connections" />
    </b-modal>
    <!-- Global Modal: Unsaved Changes Modal -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="app.showUnsavedChangesModal">
      <UnsavedChangesModal />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Credits from './modules/App/Credits'
import Navigation from './modules/App/Navigation'
import OnboardingModal from './modules/App/OnboardingModal'
import ServerErrorModal from './modules/App/ServerErrorModal'
import SupportRequestModal from './modules/App/SupportRequestModal'
import UnsavedChangesModal from './modules/App/UnsavedChangesModal'

export default {
  components: {
    Credits,
    Navigation,
    OnboardingModal,
    ServerErrorModal,
    SupportRequestModal,
    UnsavedChangesModal
  },
  computed: {
    ...mapGetters(['app', 'auth', 'context', 'services']),
    showNavigation () {
      return Boolean(
        this.app.initialized &&
        this.$route.meta.hideNavigation !== true &&
        this.context.isResolved() &&
        this.auth.ready
      )
    },
    showContent () {
      return Boolean(
        this.app.initialized &&
        this.auth.ready
      )
    }
  },
  data () {
    return {
      announcements: [],
      connections: [],
      showAnnouncementsModal: false,
      showOnboardingModal: false
    }
  },
  methods: {
    toggleOnboarding (connections) {
      this.connections = connections
      this.showOnboardingModal = true
    }
  },
  mounted () {
    // initialize fullstory
    this.$fullstory.setVars('page', {
      pageName: this.services.fullstory.pageName
    })
    // initialize app
    this.$store.dispatch('initialize').then(() => {
      this.$fullstory.identify(this.auth.user.id, {
        displayName: this.auth.user.first_name + this.auth.user.last_name,
        email: this.auth.user.email
      })
    })
  }
}
</script>
