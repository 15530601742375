<template>
  <div class="modal-card">
    <section class="modal-card-body">
      <div class="pa-lg">
        <div class="has-text-centered mb-lg">
          <!-- Fabric Logo -->
          <p v-if="app.platformCode === 'fabric'">
            <FabricLogo color="black" />
          </p>
          <!-- RC Logo -->
          <p v-else>
            <RevCascadeLogo />
          </p>
          <p class="has-text-grey">Server Error</p>
        </div>
        <div v-if="app.errorStatusCode === 404" class="has-text-centered">
          <p class="is-size-4 mb-lg has-text-danger">404 Not Found</p>
          <p class="has-text-grey">The requested data could not be found. If you
            need immediate assistance, please file a support request.
          </p>
        </div>
        <div v-else-if="app.errorStatusCode === 403" class="has-text-centered">
          <p class="is-size-4 mb-lg has-text-danger">403 Unauthorized</p>
          <p class="has-text-grey">You don't have permission to perform
            the requested action. If you think you're receiving this message in
            error, please file a support request.
          </p>
        </div>
        <div v-else-if="app.errorStatusCode === 504" class="has-text-centered">
          <p class="is-size-4 mb-lg has-text-danger">504 Timeout</p>
          <p class="has-text-grey">Your request took longer than 30 seconds, so our servers have timed out. If
            possible, try to limit your data requests using filters. If you
            need a large data set, or think you're receiving this message in
            error, please file a support request.
          </p>
        </div>
        <div v-else class="has-text-centered">
          <p class="is-size-4 mb-lg has-text-danger">500 Server Error</p>
          <p class="has-text-grey">
            Our system was unable to process your request. This is likely
            not your fault. Our team has been notified. If you
            need immediate assistance, please file a support request.
          </p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FabricLogo from '@/components/Logos/Fabric'
import RevCascadeLogo from '@/components/Logos/RevCascade'
export default {
  components: {
    FabricLogo,
    RevCascadeLogo
  },
  computed: {
    ...mapGetters(['app'])
  }
}
</script>
