<template>
  <span :class="presenter.textClass" class="has-text-weight-bold">
    {{ presenter.label }}
  </span>
</template>

<script>
export default {
  components: {

  },
  data () {
    return {

    }
  },
  computed: {
    presenter () {
      // set defaults first
      const presenter = {
        label: null,
        textClass: 'has-text-black',
        message: null
      }
      // backordered
      if (this.backorderDate !== null) {
        presenter.label = 'Backordered'
        presenter.textClass = 'has-text-warning'
        return presenter
      }
      // open orders are now those that are 'open' or 'partial'
      if (this.status === 'open' || this.status === 'partial') {
        presenter.label = 'Open'
        // if the order is open or partial, color the text according to how
        // late the order is (where > 5 is really bad)
        const due = this.$dates.utc().diff(this.$dates(this.dueDate), 'day')
        if (due > 4) {
          presenter.textClass = 'has-text-danger'
          presenter.message = 'Very Late'
        } else if (due >= 1) {
          presenter.textClass = 'has-text-warning'
          presenter.message = 'Late'
        } else {
          presenter.textClass = 'has-text-success'
          presenter.message = 'On Time'
        }
      } else if (this.status === 'closed') {
        presenter.label = 'Closed'
      } else if (this.status === 'canceled') {
        presenter.textClass = 'has-text-info'
        presenter.label = 'Canceled'
      }
      return presenter
    }
  },
  methods: {

  },
  mounted () {

  },
  props: {
    status: {
      type: String
    },
    dueDate: {
      type: String
    },
    backorderDate: {
      type: String,
      default: null
    }
  }
}
</script>
