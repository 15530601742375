<template>
  <form @submit.prevent="update" novalidate autocomplete="off">
    <div class="columns">
      <div class="column is-one-fifth">
        <p class="has-text-weight-bold is-size-6 mb-sm">Connect Instagram</p>
        <p class="has-text-grey is-size-7">
          Connect your Instagram account to access assets from Instagram.
        </p>
      </div>
      <div class="column">
        <div>
          <!-- Connecting Instagram -->
          <div v-if="connecting" class="has-background-white rounded-xl pa-sm">
            <loading message="Connecting..."></loading>
          </div>
          <!-- Connection Error -->
          <div v-else-if="error !== null" class="has-background-white rounded-xl py-xxl px-sm">
            <p class="has-text-centered">{{ error }}</p>
          </div>
          <!-- Loading platform account -->
          <div v-else-if="loading" class="has-background-white rounded-xl pa-sm">
            <loading message="Loading..."></loading>
          </div>
          <!-- Loaded (No Platform) -->
          <div v-else-if="!loading && account === null" class="has-background-white rounded-xl pa-xl">
            <div class="">
              <div class="has-text-centered">
                <p class="mb-sm">
                  <img src="https://res.cloudinary.com/revcascade/image/upload/h_48/static/instagram.png" />
                </p>
                <p><a :href="installUrl" class="button is-primary rounded-lg">Connect Instagram</a></p>
              </div>
            </div>
          </div>
          <!-- Loaded (with Platform) -->
          <div v-else-if="!loading && account !== null" class="has-background-white rounded-xl pa-xl">
            <div class="">
              <div class="has-text-centered">
                <p class="mb-sm">
                  <img src="https://res.cloudinary.com/revcascade/image/upload/h_48/static/instagram.png" />
                </p>
                <p>Connected!</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {

  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/instagram/'
    },
    json () {
      if (!('code' in this.$route.query)) return null
      return {
        code: this.$route.query.code.replace('#_', '')
      }
    }
  },
  data () {
    return {
      connecting: false,
      isDirty: false,
      error: null
    }
  },
  methods: {
    connect () {
      this.connecting = true
      this.$http.post(this.endpoint, this.json).then(response => {
        this.connecting = false
        this.$emit('instagram:connected')
        this.$router.push(this.$route.path)
      }).catch(() => {
        this.connecting = false
        this.error = 'There was an error connecting your instagram account. Please refresh and try again.'
      })
    }
  },
  mounted () {
    if ('code' in this.$route.query) {
      this.connect()
    }
  },
  props: ['account', 'installUrl', 'loading', 'marketplace']
}
</script>
