<template>
  <div>
    <!-- Content -->
    <div>
      <p class="is-size-5 has-text-centered mb-sm">Connection Notes</p>
      <div class="has-background-white rounded-lg">
        <!-- Has Memos -->
        <div v-if="!loading && memos.length > 0">
          <div class="px-lg">
            <div v-for="(memo, index) in memos" :key="memo.id" :class="(index === 0) ? '' : 'border-t'" class="py-md">
              <Memo :memo="memo"  @edit:memo="editMemo"/>
            </div>
          </div>
          <div v-if="context.type === 'retailer'" class="border-t px-lg py-md">
            <div class="columns is-mobile is-vcentered">
              <div class="column">
                <p class="has-text-grey-light is-size-7">Connection notes are private and can be viewed by visibility set.</p>
              </div>
              <div class="column is-narrow">
                <div class="has-text-right">
                  <b-button outlined @click="showConnectionMemoModal = true">Add A Note</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- No Memos Yet for retailers-->
        <div v-else-if="!loading && memos.length === 0 && context.type === 'retailer'">
          <div class="pa-lg has-text-centered has-text-grey-light">
            <p class="">You haven't added an private connection notes yet.</p>
            <p class="mb-md">To add a private note only visible to your team, click the button below</p>
            <p class="">
              <b-button outlined @click="showConnectionMemoModal = true">Add A Memo</b-button>
            </p>
          </div>
        </div>
        <!-- No Memos Yet for suppliers-->
        <div v-else-if="!loading && memos.length === 0 && context.type === 'vendor'">
          <p class="pa-lg has-text-centered has-text-grey-light">You have no connection notes yet.</p>
        </div>
        <!-- Loading or Refreshing -->
        <div v-else>
          <div v-for="index in 3" :key="index" :class="(index === 1) ? '' : 'border-t'" class="pa-md">
            <div class="columns">
              <div class="column">
                <b-skeleton />
                <b-skeleton width="50%" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Edit Memo Modal -->
    <b-modal
      v-if="connection !== null"
      has-modal-card
      scroll="keep"
      :active.sync="showConnectionMemoModal"
      :can-cancel="['x']"
      @close="memo=null"
      ><ConnectionMemoModal :connection="connection" :memo="memo" @saved:memo="refreshMemos"/>
    </b-modal>
  </div>
</template>

<script>
import ConnectionMemoModal from '@/components/Models/Connection/ConnectionMemoModal'
import Memo from '@/components/Models/Memo/Memo'
import { mapGetters } from 'vuex'

export default {
  components: {
    ConnectionMemoModal,
    Memo
  },
  computed: {
    ...mapGetters(['context'])
  },
  data () {
    return {
      showConnectionMemoModal: false,
      loading: false,
      memos: [],
      memo: null
    }
  },
  methods: {
    refreshMemos () {
      this.loading = true
      this.memos = []
      const endpoint = this.context.route + '/connections/' + this.connection.id + '/'
      this.$http.get(endpoint).then(response => {
        this.memos = response.data.memos
        this.loading = false
      })
    },
    editMemo (memo) {
      this.memo = memo
      this.showConnectionMemoModal = true
    }
  },
  mounted () {
    this.memos = this.connection.memos
  },
  props: ['connection']
}
</script>
