<template>
  <div>
    <div class="columns">
      <div class="column is-one-fifth">
        <p class="has-text-weight-bold is-size-6 mb-sm">Locations</p>
        <p class="has-text-grey is-size-7">Select which inventory locations to use when syncing inventory.</p>
      </div>
      <div class="column">
        <div class="has-background-white rounded-lg">
          <div class="pa-lg">

            <div v-if="!loading && locationMappings.length > 0">
              <b-table
                :data="locationMappings"
                :hoverable="true"
                :narrowed="true"
                :loading="loading"
                :mobile-cards="false"
                class="is-size-7 mb-lg">
                <b-table-column
                  label="ID"
                  cell-class="nowrap"
                  v-slot="props">
                  <p class="has-text-weight-bold">{{ props.row.id }}</p>
                </b-table-column>
                <b-table-column
                  label="Name"
                  cell-class="nowrap"
                  v-slot="props">
                  <a class="has-text-weight-bold has-text-info" @click.prevent="showMappingModal(props.row)">{{ props.row.nickname }}</a>
                </b-table-column>
                <b-table-column
                  label="Shopify Location ID"
                  cell-class="nowrap"
                  v-slot="props">
                  <p>{{props.row.shopify_location_id }}</p>
                </b-table-column>
                <b-table-column
                  :label="app.productName + ' Location ID'"
                  cell-class="nowrap"
                  v-slot="props">
                  <p>{{props.row.location.id }}</p>
                </b-table-column>
              </b-table>
              <b-field>
              <b-switch type="is-success"
                v-model="syncCredentialValue"
                :true-value="1"
                :false-value="0"
                @input="toggleLocationSyncCredential"
              >Sync inventory from specific locations only</b-switch>
            </b-field>
            </div>
            <div v-else-if="!loading && locationMappings.length === 0" class="has-background-white-ter  pa-md rounded-lg">
              <p class="has-text-grey-light">No location mappings found</p>
            </div>
            <div v-else class="has-background-white-ter rounded-lg">
              <p class="has-text-grey-light is-size-8 pa-md">Loading...</p>
            </div>
          </div>
          <div class="border-t">
            <div class="columns is-vcentered is-marginless">
              <div class="column">
                <div class="px-sm has-text-grey-light">
                  <i>Click 'Add Location' to map a Shopify Location to {{ app.productName }} Location</i>
                </div>
              </div>
              <div class="column is-narrow">
                <div class="px-sm">
                  <b-button
                  type="is-primary rounded-lg"
                  :disabled="loading"
                  @click="onAddLocationClick"
                  >Add Location</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Create/Edit Location Mapping Modal -->
    <b-modal
      :active.sync="showLocationMappingModal"
      has-modal-card>
      <LocationMappingModal
        :locationMapping="locationMapping"
        :account="account"
        @locations:updated="fetchLocationMappings"
        @location:create="showMarketplaceLocationModal = true"/>
    </b-modal>

    <!-- Create Marketplace Location  Modal -->
    <b-modal
      :active.sync="showMarketplaceLocationModal"
      has-modal-card>
      <!--Pass 'null' location prop to ensure that add location flow is used-->
      <InventoryLocationModal :location="null"/>
    </b-modal>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LocationMappingModal from './LocationMappingModal'
import InventoryLocationModal from '../InventoryLocationModal'

export default {
  components: {
    LocationMappingModal,
    InventoryLocationModal
  },
  computed: {
    ...mapGetters(['app', 'context']),
    syncCredential () {
      let syncCredential = null
      this.account.credentials.forEach(credential => {
        Object.keys(credential).forEach(key => {
          if (key === 'sync_select_locations') {
            syncCredential = credential[key]
          }
        })
      })
      return syncCredential
    }
  },
  data () {
    return {
      showLocationMappingModal: false,
      showMarketplaceLocationModal: false,
      locationMappings: [],
      locationMapping: null,
      loading: false,
      syncCredentialValue: '0',
      saving: false,
      errors: {}
    }
  },
  methods: {
    fetchLocationMappings () {
      this.loading = true
      const endpoint = this.context.route + '/shopify/' + this.account.id + '/location-mappings/'
      this.$http.get(endpoint).then(response => {
        this.locationMappings = response.data.results
      }).finally(() => { this.loading = false })
    },
    showMappingModal (row) {
      this.locationMapping = row
      this.showLocationMappingModal = true
    },
    onAddLocationClick () {
      this.locationMapping = null
      this.showLocationMappingModal = true
    },
    toggleLocationSyncCredential () {
      this.saving = true
      const endpoint = this.context.route + '/platform-accounts/' + this.account.id + '/'
      const json = {
        credentials: [{
          credential: { code: 'sync_select_locations' },
          value: (parseInt(this.syncCredentialValue) === 1) ? '1' : '0'
        }]
      }
      if (this.syncCredential !== null) {
        json.credentials[0].id = this.syncCredential.values[0].id
      }
      this.$http.patch(endpoint, json).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Location sync activated.' })
        this.$emit('update:account')
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    this.fetchLocationMappings()
    if (this.syncCredential) {
      this.syncCredentialValue = this.syncCredential.values[0].value
    }
  },
  props: {
    account: {
      type: Object,
      required: true
    }
  },
  watch: {
    showMarketplaceLocationModal (value) {
      this.showLocationMappingModal = !value
    }
  }
}
</script>
