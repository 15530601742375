<template>
<div>
  <div class="pa-md">
    <!-- Task is loaded -->
    <div v-if="!loading && task !== null">
      <p class="has-text-centered has-text-weight-bold mb-lg is-size-5">Activate Notifications</p>
      <div class="columns is-centered">
        <div class="column is-half">
          <div class="box">
            <transition name="fade" mode="out-in">
              <component :is="step"
                :connection="connection"
                :task="task"
                :pushNotifications="pushNotifications"
                :notifications="notifications"
                @push-notifications:updated="loadPushNotifications(true)"
                @notifications:updated="loadNotifications(true)"
                @navigate="navigate"
              ></component>
            </transition>
          </div>
          <!-- Return & Reset Links -->
          <div class="columns is-centered">
            <router-link :to="{ name: 'onboarding.tasks', params: { connection: this.$route.params.connection }}" class="is-size-7 column ml-sm" :class="!isCompleted && 'has-text-centered'">
              Back to Tasks
            </router-link>
            <a href="#" v-if="isCompleted" class="column is-size-7 has-text-right mr-sm" @click="showResetTaskModal=true">
              Reset Task
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- Loading Skeleton -->
    <div v-else>
      <div class="mb-md">
        <div class="columns is-centered">
          <div class="column is-one-quarter">
            <b-skeleton />
          </div>
        </div>
      </div>
      <div class="columns is-centered">
        <div class="column is-half">
          <div class="box">
            <loading message="Loading..." />
          </div>
          <!-- Return Link -->
          <div class="has-text-centered">
            <router-link :to="{ name: 'onboarding.tasks', params: { connection: this.$route.params.connection }}" class="is-size-7">
              Back to Tasks
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Reset Task Modal -->
  <b-modal
    v-if="!loading && task !== null"
    :has-modal-card="true"
    :active.sync="showResetTaskModal"
    :can-cancel="['x', 'esc']">
    <ResetTaskModal :taskId="task.id" @task:reset="onTaskReset" />
  </b-modal>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import ResetTaskModal from '@/components/Models/Task/Modals/ResetTaskModal'
import Intro from '../components/Notifications/Intro'
import Connection from '../components/Notifications/Connection'
import Order from '../components/Notifications/Order'
import Change from '../components/Notifications/Change'
import OrderDigest from '../components/Notifications/OrderDigest'
import Confirmation from '../components/Notifications/Confirmation'

export default {
  components: {
    Intro,
    Connection,
    Order,
    Change,
    OrderDigest,
    Confirmation,
    ResetTaskModal
  },
  computed: {
    ...mapGetters(['app', 'context']),
    loading () {
      return (this.loadingTask || this.loadingPushNotifications || this.loadingNotifications)
    },
    isCompleted () {
      return this.task && this.task.completed_at !== null
    }
  },
  data () {
    return {
      loadingTask: true,
      loadingPushNotifications: true,
      loadingNotifications: true,
      task: null,
      step: 'Intro',
      pushNotifications: [],
      notifications: [],
      showResetTaskModal: false
    }
  },
  methods: {
    loadTask () {
      this.loadingTask = true
      const endpoint = this.context.route + '/assigned-tasks/' + this.$route.params.task + '/'
      this.$http.get(endpoint).then(response => {
        this.loadPushNotifications()
        this.loadNotifications()
        this.task = response.data
        this.loadingTask = false
      })
    },
    loadPushNotifications (silent = false) {
      if (!silent) this.loadingPushNotifications = true
      const endpoint = this.context.route + '/push-notifications/'
      this.$http.get(endpoint).then(response => {
        this.pushNotifications = response.data.results
        this.loadingPushNotifications = false
      })
    },
    loadNotifications (silent = false) {
      if (!silent) this.loadingNotifications = true
      const endpoint = this.context.route + '/notifications/'
      this.$http.get(endpoint).then(response => {
        this.notifications = response.data.results
        this.loadingNotifications = false
      })
    },
    navigate (step) {
      this.step = step
    },
    onTaskReset () {
      this.$buefy.toast.open({ type: 'is-info', message: 'Task reset successfully.' })
      this.$router.push({ name: 'onboarding.tasks', params: { connection: this.$route.params.connection } })
    }
  },
  mounted () {
    this.loadTask()
  },
  props: ['connection']
}
</script>
