<template>
  <div>
    <div class="section">
      <div class="container">

        <!-- Profile Header -->
        <div class="mb-lg">
          <profile-header :marketplace="profile" />
        </div>

        <div class="columns">
          <div class="column is-one-quarter">
            <div class="has-background-white pa-md">
              Filters
            </div>
          </div>
          <div class="column">
            <div class="has-background-white pa-md rounded-xl">
              <!-- Products Loaded -->
              <div v-if="products.length > 0">
                <div class="columns">
                  <div class="column" v-for="product in products" :key="product.id">
                    <preview-product :marketplace="profile" :product="product" />
                  </div>
                </div>
              </div>
              <!-- Loading -->
              <div v-else>
                <loading message="Loading products" />
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ProfileHeader from '../components/ProfileHeader'
import PreviewProduct from '../components/PreviewProduct'

export default {
  components: {
    ProfileHeader,
    PreviewProduct
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/marketplaces/?mine=1'
    }
  },
  data () {
    return {
      loading: true,
      marketplace: null,
      loadingProducts: true,
      search: null,
      categories: [],
      products: []
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint).then(response => {
        if (response.data.results.length > 0) {
          this.marketplace = response.data.results[0]
        }
      }).finally(() => {
        this.loading = false
      })
    },
    loadProducts () {
      this.loadingProducts = true
      const endpoint = this.context.route + '/marketplaces/' + this.profile.id + '/products/?rcn_status=published'
      this.$http.get(endpoint).then(response => {
        this.products = response.data.results
      }).finally(() => {
        this.loadingProducts = false
      })
    }
  },
  mounted () {
    this.loadProducts()
  },
  props: ['profile']
}
</script>
