<template>
  <div class="login">
    <!-- Sign In -->
    <form @submit.prevent="attempt" novalidate>
      <p class="is-size-4 mb-lg">{{ message }}</p>
      <div class="mb-xxl">
        <!-- Email -->
        <div class="mb-lg">
          <b-field label="Email">
            <b-input
              v-model="email"
              type="email"
            ></b-input>
          </b-field>
        </div>
        <!-- Password -->
        <div class="mb-lg">
          <b-field label="Password">
            <b-input
              v-model="password"
              type="password"
              password-reveal
            ></b-input>
          </b-field>
        </div>
      </div>
      <div class="mb-xl">
        <b-button
          type="is-primary rounded-lg"
          native-type="submit"
          :disabled="!ready || processing"
          :loading="processing"
          expanded
        >Sign In</b-button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['api', 'auth', 'context']),
    endpoint () {
      return this.api.baseUrl + '/auth/token/'
    },
    credentials () {
      const form = new FormData()
      form.set('username', this.email)
      form.set('password', this.password)
      form.set('grant_type', 'password')
      form.set('client_id', this.api.clientId)
      return form
    },
    ready () {
      return (
        this.email !== null &&
        this.email !== '' &&
        this.password !== null &&
        this.password !== ''
      )
    }
  },
  data: function () {
    return {
      processing: false,
      email: null,
      password: null,
      message: 'Sign in to fabric'
    }
  },
  methods: {
    attempt () {
      this.processing = true
      this.$http.post(this.endpoint, this.credentials).then(response => {
        this.$store.dispatch('authenticate', response.data, true).then(() => {
          this.processing = false
        })
      }).catch(() => {
        this.processing = false
        this.$buefy.toast.open({ duration: 3000, type: 'is-danger', message: 'Invalid username or password.' })
      })
    }
  }
}
</script>
