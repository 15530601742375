<template>
  <form @submit.prevent="update" novalidate>
    <div class="columns">
      <div class="column is-one-fifth">
        <p class="has-text-weight-bold is-size-6 mb-sm">Security Credentials</p>
        <p class="has-text-grey is-size-7">Update the password to your {{ app.platform }} account.</p>
      </div>
      <div class="column">
        <div class="has-background-white rounded-xl">
          <div class="pa-lg pb-xl">
            <div class="columns">
              <div class="column">
                <b-field label="Email" expanded>
                  <b-input type="text" v-model="email" disabled autocomplete="username"></b-input>
                </b-field>
                <b-field label="Old Password" expanded>
                  <b-input autocomplete="current-password" type="password" name="oldPassword" v-model="oldPassword"
                    @input="isDirty = 1" password-reveal></b-input>
                </b-field>
                <b-field label="New Password" expanded>
                  <b-input autocomplete="new-password" type="password" name="newPassword" v-model="newPassword"
                    password-reveal></b-input>
                </b-field>
                <div class="po-password-strength-wrapper">
                  <password-meter :password="newPassword" @score="setScore" />
                </div>
                <b-field label="Confirm Password" expanded>
                  <b-input autocomplete="new-password" v-model="confirmPassword" type="password" name="confirmPassword"
                    @input="isDirty = 1" password-reveal></b-input>
                </b-field>
              </div>
              <div class="column">
                <div class="has-background-white-ter mt-md pa-md rounded-xl content">
                  <p class="has-text-weight-bold">Tips for a strong password</p>
                  <ul>
                    <li class="mb-lg">
                      Longer passwords are better. Symbols and numbers help,
                      but the number of characters matters more.
                    </li>
                    <li class="mb-lg">
                      Use the meter to gauge your password strength. You must
                      score at least in the yellow to update your password (but
                      green is highly recommended).
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="border-t">
            <div class="columns is-vcentered is-marginless">
              <div class="column">
                <div class="px-sm has-text-grey-light">
                  <i v-if="error !== null" v-text="error" class="has-text-danger"></i>
                  <i v-else v-text="validationMessage.body" :class="validationMessage.class"></i>
                </div>
              </div>
              <div class="column is-narrow">
                <div class="px-sm">
                  <b-button native-type="submit" type="is-primary rounded-lg"
                    :disabled="updating || !ready">Update</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import passwordMeter from 'vue-simple-password-meter'

export default {
  components: {
    passwordMeter
  },
  computed: {
    ...mapGetters(['app', 'api', 'auth', 'context']),
    endpoint () {
      return this.api.baseUrl + '/v1/users/update-password/'
    },
    json () {
      return {
        username: this.auth.user.email,
        old_password: this.oldPassword,
        password: this.newPassword
      }
    },
    ready () {
      return (!this.updating &&
        this.isDirty &&
        this.oldPassword !== null &&
        this.oldPassword !== '' &&
        this.newPassword !== null &&
        this.newPassword !== '' &&
        this.newPassword !== this.oldPassword &&
        this.newPassword === this.confirmPassword &&
        this.passwordScore >= 2
      )
    },
    validationMessage () {
      const message = {
        class: 'has-text-grey',
        body: ''
      }
      if (this.isDirty) {
        if (this.oldPassword === null || this.oldPassword === '') {
          message.body = 'Please enter your old password.'
        } else if (this.newPassword === null || this.newPassword === '') {
          message.body = 'Please enter a new password.'
        } else if (this.newPassword !== this.confirmPassword) {
          message.body = 'Please make sure to confirm your new password.'
        } else if (this.newPassword === this.oldPassword) {
          message.body = 'Please make sure your new password is different than your old password.'
        } else if (this.passwordScore < 2) {
          message.body = 'Please strengthen your password until you see a green score.'
        }
      }
      return message
    }
  },
  data () {
    return {
      loading: true,
      isDirty: false,
      password: null,
      oldPassword: null,
      newPassword: null,
      confirmPassword: null,
      passwordScore: 0,
      lastName: null,
      email: null,
      error: null,
      updating: false
    }
  },
  methods: {
    setScore ({ score }) {
      this.isDirty = true
      this.passwordScore = score
    },
    update () {
      this.updating = true
      this.$http.patch(this.endpoint, this.json).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Password updated.' })
        this.reset()
        this.updating = false
      }).catch(error => {
        this.updating = false
        if (error.response && error.response.status === 400) {
          if (error.response.data.length > 0) {
            this.error = error.response.data[0]
          } else {
            this.error = 'An unknown error occurred. Please contact support!'
          }
        }
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      })
    },
    reset () {
      this.newPassword = ''
      this.oldPassword = ''
      this.confirmPassword = ''
      this.error = null
    }
  },
  mounted () {
    this.firstName = this.auth.user.firstName
    this.lastName = this.auth.user.lastName
    this.email = this.auth.user.email
  },
  props: []
}
</script>
