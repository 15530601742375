<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Add Tags</p>
    </header>
    <section class="modal-card-body" style="height: 250px;">
      <b-field label="Add tags">
        <b-taginput
          v-model="tags"
          :data="filteredTags"
          ellipsis
          icon="label"
          field="name"
          autocomplete
          :allow-new="true"
          placeholder="Add a tag"
          @typing="filterTags"
          aria-close-label="Delete this tag">
        </b-taginput>
      </b-field>
    </section>
    <footer class="modal-card-foot is-flex is-justify-content-space-between">
      <b-button @click="$parent.close()">Close</b-button>
      <b-button @click="bulkAdd()" :disabled="tags.length <= 0" type="is-primary">Add</b-button>
    </footer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import debounce from 'lodash/debounce'

export default {
  components: {
  },
  data () {
    return {
      orderIds: [],
      tags: [],
      filteredTags: [],
      isAdding: false
    }
  },
  computed: {
    ...mapGetters(['context'])
  },
  watch: {},
  methods: {
    validateTags () {
      this.errors = []
      const commonTags = this.orders.map(({ tags }) => (tags))
        .reduce((x, y) => x.filter((obj) =>
          y.some((yTag) => obj.tag === yTag.tag)
        )).map(({ tag }) => (tag))
      this.tags.forEach((tag) => {
        if (commonTags.includes(tag)) {
          this.tags = this.tags.filter((t) => t !== tag)
        }
      })
    },
    bulkAdd () {
      this.validateTags()
      this.isAdding = true
      const endpoint = `${this.context.route}/orders/bulk-add-tags/`
      const body = {
        ids: this.orderIds,
        object_tags: this.tags
      }
      this.$http.post(endpoint, body).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: `${this.orderIds.length} orders updated` })
      }).finally(() => {
        this.isAdding = false
        this.$emit('bulkUpdated', true)
        this.$parent.close()
      })
    },
    filterTags: debounce(function (e) {
      this.loading = true
      const endpoint = `${this.context.route}/tags/?name_search=${e}`
      this.$http.get(endpoint).then(({ data: { results } }) => {
        this.filteredTags = results.map(({ name }) => (name))
      }).finally(() => {
        this.loading = false
      })
    }, 500)
  },
  mounted () {
    this.orderIds = this.orders.map(({ id }) => (id))
  },
  props: {
    orders: {
      default: null
    }
  }
}
</script>
