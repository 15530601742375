<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Order #{{ selectedOrder.purchase_order_number }}</p>
    </header>
    <section class="modal-card-body">
      <div v-if="!loading && order !== null">
        <div class="columns">
          <div class="column">
            <table class="table is-fullwidth is-striped is-size- is-narrow border">
              <tbody>
                <tr>
                  <th>Order ID</th>
                  <td>{{ order.id }}</td>
                </tr>
                <tr>
                  <th>Purchase Order #</th>
                  <td>{{ order.purchase_order_number }}</td>
                </tr>
                <tr>
                  <th>Retailer Order #</th>
                  <td>{{ order.retailer_order_number }}</td>
                </tr>
                <tr>
                  <th>Supplier</th>
                  <td>{{ order.brand.name }}</td>
                </tr>
                <tr>
                  <th># Shipments</th>
                  <td>{{ order.shipment_count }}</td>
                </tr>
                <tr>
                  <th># Invoices</th>
                  <td>{{ order.invoice_count }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div v-else>
        <loading message="Loading order details..."/>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/orders/' + this.selectedOrder.id + '/'
    }
  },
  data () {
    return {
      loading: true,
      order: null
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint).then(response => {
        this.order = response.data
      }).finally(() => {
        this.loading = false
      })
    }
  },
  mounted () {
    this.load()
  },
  props: ['selectedOrder']
}
</script>
