<template>
  <div>
    <b-button
      type="is-primary"
      size="is-small"
      @click="retry"
      outlined
      :disabled="retrying"
      :loading="retrying"
    >Retry</b-button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  data () {
    return {
      retrying: false
    }
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/webhooks-history/' + this.webhook.id + '/rerun-webhook/'
    }
  },
  methods: {
    retry () {
      this.retrying = true
      this.$http.put(this.endpoint).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Webhook payload retried!' })
      }).finally(() => {
        this.retrying = false
      })
    }
  },
  mounted () {

  },
  props: ['webhook']
}
</script>
