<template>
  <div>
    <!-- Have Label -->
    <div v-if="!loading && label !== null && label.return_label !== null">
      <div class="border-b">
        <div class="columns is-vcentered is-paddingless is-marginless">
          <div class="column">
            Return Label
          </div>
          <div class="column is-narrow">
            <div class="has-text-right">
              <b-button type="is-light" @click.prevent="$emit('labels:reload')">Go Back</b-button>
            </div>
          </div>
        </div>
      </div>
      <object type="application/pdf" typemustmatch="true" style="width:100%; height:500px" :data="'data:application/pdf;base64,' + label.return_label "></object>
    </div>
    <!-- Have Return, But No Label -->
    <div v-else-if="!loading && label !== null && lable.return_label === null">
      <b-message type="is-info">This return label has not generated yet. Try refreshing in a few seconds.</b-message>
    </div>
    <!-- Loading -->
    <div v-else>
      <loading message="Loading label..." />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  data () {
    return {
      loading: true,
      label: null
    }
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/rmas/' + this.rma.id + '/returns/' + this.rmaReturn.id + '/return_label/'
    }
  },
  methods: {
    getLabel () {
      this.loading = true
      this.$http.get(this.endpoint).then(response => {
        this.label = response.data
      }).finally(() => {
        this.loading = false
      })
    }
  },
  mounted () {
    this.getLabel()
  },
  props: ['rma', 'rmaReturn']
}
</script>
