<template>
  <div>
    <b-select
      v-model="connection"
      :placeholder="getPlaceholder"
      expanded
      :loading="loading"
      :disabled="loading"
      :size="sizeClass"
      @input="announce">
      <option
        v-for="connection in connections"
        :value="connection"
        :key="connection.id"
      >{{ (context.isSupplier()) ? connection.retailer.name : connection.brand.name }}</option>
    </b-select>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  data () {
    return {
      loading: false,
      connection: null,
      connections: []
    }
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/connections/'
    },
    query () {
      return {
        params: {
          pagination: 0,
          short: 1,
          order_by: 'brand__name',
          status: 'active,onboarding'
        }
      }
    },
    sizeClass () {
      return (this.size) ? this.size : null
    },
    getPlaceholder () {
      return this.placeholder ? this.placeholder : 'Select Connection'
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint, this.query).then(response => {
        response.data.results.forEach(connection => {
          if (connection.retailer.is_onboarded) {
            this.connections.push(connection)
          }
        })
        if (this.connections.length === 1) {
          this.connection = this.connections[0]
          this.announce()
        }
        this.loading = false
      })
    },
    announce () {
      this.$emit('input', this.connection)
    }
  },
  mounted () {
    if (this.value !== null) {
      this.connection = this.value
    }
    this.load()
  },
  watch: {
    value (newValue) {
      this.connection = newValue
    }
  },
  props: ['value', 'size', 'placeholder']
}
</script>
