<template>
  <div>
    <div v-if="tempFile === null">
      <p v-if="showHeading" class="has-text-primary has-text-weight-bold mb-sm">Select File</p>
      <vue-dropzone
        ref="myVueDropzone"
        id="dropzone"
        :options="dropzoneOptions"
        v-on:vdropzone-success="success"
        v-on:vdropzone-error="error"
      />
    </div>
    <div v-if="tempFile && tempFile.name" class="columns mt-4 is-vcentered">
      <div class="column has-text-weight-bold">
        <p class="has-text-success">File Selected:</p>
        <p>{{ tempFile.name }}</p>
      </div>
      <div class="column is-narrow ">
        <a href="#" class="has-text-info" @click.prevent="clearFile" v-text="'Remove'"></a>
      </div>
    </div>
    <div v-if="errorMessage && errorMessage !== null" class="has-text-danger has-text-weight-bold mt-4">
      {{ errorMessage }}
    </div>
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showConfirmModal"
      :can-cancel="false">
      <ConfirmationModal
        label='Remove the file'
        reason='Changing the sample file will reset and require you to provide the mapping again. Are you sure you want to continue?'
        messageType='is-warning'
        @modal:close="closeModal"
        @modal:confirm="confirmModal"
      />
    </b-modal>
  </div>
</template>
<script>

import vue2Dropzone from 'vue2-dropzone'
import { mapGetters } from 'vuex'
import ConfirmationModal from './Modals/ConfirmationModal.vue'
export default {
  components: {
    vueDropzone: vue2Dropzone,
    ConfirmationModal
  },
  computed: {
    ...mapGetters(['api', 'auth']),
    dropzoneOptions () {
      return {
        method: 'post',
        url: this.api.baseUrl + '/v1/tempfiles/upload/',
        acceptedFiles: this.acceptedFileTypes,
        uploadMultiple: false,
        maxFiles: 1,
        createImageThumbnails: false,
        headers: {
          Authorization: 'Bearer ' + this.auth.token
        },
        dictDefaultMessage: 'Drag file here to upload (or click to select)'
      }
    }
  },
  data: function () {
    return {
      tempFile: null,
      errorMessage: null,
      acceptedFileTypes: this.fileType,
      showHeading: false,
      showConfirmModal: false,
      isConfirmRequired: false
    }
  },
  methods: {
    success: function (file, response) {
      this.tempFile = file
      this.errorMessage = null
      this.$refs.myVueDropzone.removeFile(file)
      this.$emit('file:uploaded', file, response)
    },
    error: function (file, message, xhr) {
      if (message) {
        this.errorMessage = `You can't upload files of the type ${file.type}.`
      }
      if (xhr) {
        this.$emit('file:error', xhr.responseText, xhr.status)
      }
      this.tempFile = null
      this.$refs.myVueDropzone.removeAllFiles()
    },
    clearFile () {
      if (this.isConfirmRequired) {
        this.showConfirmModal = true
      } else {
        this.tempFile = null
        this.$emit('file:reset')
      }
    },
    closeModal () {
      this.showConfirmModal = false
    },
    confirmModal () {
      this.tempFile = null
      this.$emit('file:reset')
      this.showConfirmModal = false
    }
  },
  mounted () {
    if (this.showLabel) {
      this.showHeading = this.showLabel
    }
    if (this.askToConfirm) {
      this.isConfirmRequired = this.askToConfirm
    }
  },
  props: {
    fileType: {
      type: String,
      default: '.xls,.xlsx,.csv,.txt'
    },
    showLabel: {
      type: Boolean,
      default: false
    },
    askToConfirm: {
      type: Boolean,
      default: false
    }
  }
}
</script>
