import Index from './views/Index'
import Webhooks from './views/Webhooks'
import Branding from './views/Branding'
import Edi from './views/Edi'
import Ftp from './views/Ftp'
import General from './views/General'
import Integrations from './views/Integrations'
import Inventory from './views/Inventory'
import Legal from './views/Legal'
import Notifications from './views/Notifications'
import Payments from './views/Payments'
import Products from './views/Products'
import Onboarding from './views/Onboarding'
import ShippingAccounts from './views/ShippingAccounts'
import Shopify from './views/Shopify'
import TermsAndConditions from './views/TermsAndConditions'
import Woocommerce from './views/Woocommerce'
import WoocommerceWebhooks from './views/WoocommerceWebhooks'
import BigCommerce from './views/BigCommerce'
import BigCommerceUtilities from './views/BigCommerceUtilities'
import BigCommerceWebhooks from './views/BigCommerceWebhooks'
import ShopifyUtilities from './views/ShopifyUtilities'
import ShopifyWebhooks from './views/ShopifyWebhooks'
import Shipstation from './views/Shipstation'
import ShipstationUtilities from './views/ShipstationUtilities'
import ShipstationWebhooks from './views/ShipstationWebhooks'
import Returnly from './views/Returnly'
import ReturnlyWebhooks from './views/ReturnlyWebhooks'
import Subscriptions from './views/Subscriptions'
import SubscriptionSuccess from './views/SubscriptionSuccess'
import SubscriptionFailed from './views/SubscriptionFailed'
import Transaction from './views/Transaction'
import Tracking from './views/Tracking'
import Fabric from './views/Fabric'
import FabricWebhooks from './views/FabricWebhooks'

export default [{
  path: '/settings',
  name: 'settings',
  component: Index
}, {
  path: '/settings/general',
  name: 'settings.general',
  component: General
}, {
  path: '/settings/onboarding',
  name: 'settings.onboarding',
  component: Onboarding
}, {
  path: '/settings/transaction',
  name: 'settings.transaction',
  component: Transaction
}, {
  path: '/settings/products',
  name: 'settings.products',
  component: Products
}, {
  path: '/settings/branding',
  name: 'settings.branding',
  component: Branding
}, {
  path: '/settings/integrations',
  name: 'settings.integrations',
  component: Integrations
}, {
  path: '/settings/integrations/shopify/:id',
  name: 'settings.integrations.shopify',
  component: Shopify
}, {
  path: '/settings/integrations/woocommerce/:id',
  name: 'settings.integrations.woocommerce',
  component: Woocommerce
}, {
  path: '/settings/integrations/woocommerce/:id/webhook-history',
  name: 'settings.integrations.woocommerce.webhooks',
  component: WoocommerceWebhooks
}, {
  path: '/settings/integrations/shopify/:id/webhook-history',
  name: 'settings.integrations.shopify.webhooks',
  component: ShopifyWebhooks
}, {
  path: '/settings/integrations/shopify/:id/utilities',
  name: 'settings.integrations.shopify.utilities',
  component: ShopifyUtilities
}, {
  path: '/settings/legal',
  name: 'settings.legal',
  component: Legal
}, {
  path: '/settings/inventory',
  name: 'settings.inventory',
  component: Inventory
}, {
  path: '/settings/payments',
  name: 'settings.payments',
  component: Payments
}, {
  path: '/settings/notifications',
  name: 'settings.notifications',
  component: Notifications
}, {
  path: '/settings/webhooks',
  name: 'settings.webhooks',
  component: Webhooks
}, {
  path: '/settings/edi',
  name: 'settings.edi',
  component: Edi
}, {
  path: '/settings/ftp',
  name: 'settings.ftp',
  component: Ftp
}, {
  path: '/settings/shipping-accounts',
  name: 'settings.shipping',
  component: ShippingAccounts
}, {
  path: '/settings/supplier-terms-and-conditions',
  name: 'settings.terms',
  component: TermsAndConditions
}, {
  path: '/settings/integrations/shipstation/:id',
  name: 'settings.integrations.shipstation',
  component: Shipstation
}, {
  path: '/settings/integrations/shipstation/:id/utilities',
  name: 'settings.integrations.shipstation.utilities',
  component: ShipstationUtilities
}, {
  path: '/settings/integrations/shipstation/:id/webhook-history',
  name: 'settings.integrations.shipstation.webhooks',
  component: ShipstationWebhooks
}, {
  path: '/settings/subscriptions',
  name: 'settings.subscriptions',
  component: Subscriptions
}, {
  path: '/settings/subscriptions/success',
  name: 'settings.subscriptions.success',
  component: SubscriptionSuccess
}, {
  path: '/settings/subscriptions/failed',
  name: 'settings.subscriptions.failed',
  component: SubscriptionFailed
}, {
  path: '/settings/integrations/returnly/:id',
  name: 'settings.integrations.returnly',
  component: Returnly
}, {
  path: '/settings/integrations/returnly/:id/webhook-history',
  name: 'settings.integrations.returnly.webhooks',
  component: ReturnlyWebhooks
}, {
  path: '/settings/integrations/bigcommerce/:id',
  name: 'settings.integrations.bigcommerce',
  component: BigCommerce
}, {
  path: '/settings/integrations/bigcommerce/:id/utilities',
  name: 'settings.integrations.bigcommerce.utilities',
  component: BigCommerceUtilities
}, {
  path: '/settings/integrations/bigcommerce/:id/webhook-history',
  name: 'settings.integrations.bigcommerce.webhooks',
  component: BigCommerceWebhooks
}, {
  path: '/settings/tracking',
  name: 'settings.tracking',
  component: Tracking
}, {
  path: '/settings/integrations/fabric/:id',
  name: 'settings.integrations.fabric',
  component: Fabric
}, {
  path: '/settings/integrations/fabric/:id/webhook-history',
  name: 'settings.integrations.fabric.webhooks',
  component: FabricWebhooks
}]
