<template>
<div>
  <!-- Toolbar -->
  <div v-if="context.isMerchant()" class="has-background-white rounded-lg py-md px-lg mb-xxs">
    <div class="columns is-vcentered">
      <div class="column">
        <div class="px-sm has-text-grey-light">
          <em v-if="connection.connection_credentials.length > 0" class="is-size-7">Click 'Edit' to change the approved carrier accounts &amp; methods for this connection.</em>
        </div>
      </div>
      <div class="column is-narrow">
        <div class="px-sm">
          <b-button
            type="is-primary"
            outlined
            expanded
            @click.prevent="showEditCredentialModal = true"
          >Add Attribute</b-button>
        </div>
      </div>
    </div>
  </div>
  <!-- Connection Credentials -->
  <div class="mb-lg">
    <div class="has-background-white rounded-lg pa-lg">
      <div v-if="connection.connection_credentials.length > 0">
        <div class="pb-sm has-border-b has-border-lg">
          <div class="columns">
            <div class="column">
              <p class="has-text-primary has-text-weight-bold">Connection Attributes</p>
            </div>
            <div class="column">

            </div>
          </div>
        </div>
        <table class="table is-fullwidth is-striped is-narrow is-size-7 is-vcentered">
          <tbody>
            <tr v-for="attribute in connection.connection_credentials" :key="attribute.id">
              <th width="25%">{{ attribute.name }}</th>
              <td class="has-text-right">
                <div>{{ attribute.value }}</div>
                <div class="is-flex is-justify-content-flex-end">
                  <div class="mr-sm"><a href="#" v-if="context.isMerchant()" @click.prevent="editAttribute(attribute)" class="has-text-info">Edit</a></div>
                  <div><a href="#" v-if="context.isMerchant()" @click.prevent="showDeleteConfirm(attribute)" class="has-text-info">Delete</a></div>
                </div>

              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- No Metadata -->
      <div v-else class="has-text-centered py-xxl">
        <p class="has-text-grey-light mb-lg">
          There are no custom attributes associated with this connection.
        </p>
        <!-- <p v-if="context.isMerchant()"><b-button outlined @click="showEditMetadataModal = true">Add Attribute</b-button></p> -->
      </div>
    </div>
  </div>
  <!-- Create/Update Metadatas -->
  <b-modal
    v-if="context.isMerchant()"
    has-modal-card
    :active.sync="showEditCredentialModal"
    :can-cancel="['escape', 'x']"
    @close="attribute = null"
  ><EditCredentialModal :connection="connection" :credential="attribute" @saved:credential="$emit('updated')"></EditCredentialModal>
  </b-modal>

   <!-- delete Connection Attribute confirmation modal -->
  <div v-if="showConfirmModal">
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showConfirmModal"
      :can-cancel="['x']"
      >
      <ConfirmationModal
          :label="'Are you sure?'"
          :reason="`Are you sure you want to delete Connection Attribute: ${deleteAttribute.name} ${deleteAttribute.value}?`"
          @modal:confirm="confirm"
          @modal:close="closeModal"
          />
      </b-modal>
  </div>

</div>
</template>

<script>
import { mapGetters } from 'vuex'
import EditCredentialModal from '../components/Credentials/EditCredentialModal'
import ConfirmationModal from '@/components/Modals/ConfirmationModal'
export default {
  components: {
    EditCredentialModal,
    ConfirmationModal
  },
  computed: {
    ...mapGetters(['context'])
  },
  data () {
    return {
      showEditCredentialModal: false,
      attribute: null,
      showConfirmModal: false,
      deleteAttribute: {},
      deleting: false
    }
  },
  methods: {
    confirm () {
      this.deleting = true
      const body = {
        name: this.deleteAttribute.name,
        code: this.deleteAttribute.code,
        value: this.deleteAttribute.value,
        delete: 1
      }
      this.$http.delete(`${this.context.route}/connections/${this.connection.id}/credentials/${this.deleteAttribute.id}/`, body).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Attribute deleted.' })
      }).catch(error => {
        this.$buefy.toast.open({ type: 'is-error', message: error })
      }).finally(() => {
        this.deleting = false
        this.deleteAttribute = {}
        this.showConfirmModal = false
        this.$emit('updated')
      })
    },
    editAttribute (attribute) {
      this.attribute = attribute
      this.showEditCredentialModal = true
    },
    showDeleteConfirm (attribute) {
      this.showConfirmModal = true
      this.deleteAttribute = attribute
    },
    closeModal () {
      this.showConfirmModal = false
    }
  },
  props: ['connection']
}
</script>
