<template>
<div class="section">
  <div class="container">
    <!-- Page Header -->
    <div class="columns is-vcentered">
      <div class="column is-one-third">
        <breadcrumbs :history="[{ label: 'Catalog', name: context.isSupplier() ? 'catalog' : 'catalog.index' }]" current="Import Products" :hideBack="true" />
      </div>
      <div class="column is-one-third">
        <h1 class="has-text-centered is-size-4">Import Products</h1>
      </div>
    </div>
    <!-- Wizard -->
    <div>
      <div class="columns is-centered">
        <div class="column is-two-thirds-desktop is-half-widescreen">
          <div class="box">
            <transition name="fade" mode="out-in">
              <component :is="step"
                :feed="feed"
                @feed:queued="trackFeed"
                @navigate="navigate"
              ></component>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Intro from '../components/ImportProducts/Intro'
import Progress from '../components/ImportProducts/Progress'

export default {
  components: {
    Intro,
    Progress
  },
  computed: {
    ...mapGetters(['app', 'context'])
  },
  data () {
    return {
      step: 'Intro',
      feed: null
    }
  },
  methods: {
    navigate (step) {
      this.step = step
      window.scrollTo(0, 0)
    },
    trackFeed (feed) {
      this.feed = feed
    }
  },
  mounted () {
    if (this.$route.query.feed_id) {
      this.feed = {
        id: this.$route.query.feed_id
      }
      this.step = 'Progress'
    }
  }
}
</script>
