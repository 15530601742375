<template>
  <form @submit.prevent="save">
    <!-- Intro -->
    <div class="">
      <p class="has-text-centered is-size-4 mb-md has-text-black">Begin Import</p>
      <p class="pb-lg">
        We're now ready to import your products! Please review the settings
        below and click "Begin Import" when ready. Your import will start immediately
        and you do not need to wait for the import to finish. You should see products
        appear in your {{ app.platform }} catalog within 5-15 minutes.
      </p>
    </div>
    <!-- Settings -->
    <div class="mb-lg">
      <div class="columns is-centered">
        <div class="column is-two-thirds">
          <div class="has-background-white-ter pa-md rounded-lg mb-lg">
            <p class="has-text-weight-bold has-text-centered mb-sm">Import Settings</p>
            <div class="mb-sm">
              <p v-if="settings.importFilter === 'published'">
                <b-icon icon="check" custom-class="has-text-success" />
                Import all products published to the "Online Store" sales channel.
              </p>
              <p v-else-if="settings.importFilter === 'collection'">
                <b-icon icon="check" custom-class="has-text-success" />
                Import a collection only ({{ settings.collection.title }})
              </p>
            </div>
            <div class="mb-sm">
              <p>
                <b-icon icon="check" custom-class="has-text-success" />
                MAPs will be set to the price configured in Shopify.
              </p>
            </div>
            <div class="mb-sm">
              <p v-if="settings.dropshipCostPercent">
                <b-icon icon="check" custom-class="has-text-success" />
                Dropship costs will be set at {{ settings.dropshipCostPercent }}% of retail.
              </p>
              <p v-else>
                <b-icon icon="check" custom-class="has-text-success" />
                Do not set dropship costs at this time.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Buttons -->
    <div class="mb-xl">
      <div class="columns is-centered">
        <div class="column is-three-quarters-tablet is-two-thirds-widescreen">
          <div class="columns">
            <div class="column">
              <b-button type="is-light rounded-lg" size="is-large" @click="back" expanded>
                <b-icon icon="arrow-left"></b-icon>
              </b-button>
            </div>
            <div class="column">
              <b-button
                native-type="submit"
                type="is-primary rounded-lg"
                size="is-large"
                expanded
                :loading="saving"
                :disabled="!validated || saving"
              >Begin Import</b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  data () {
    return {
      saving: false
    }
  },
  computed: {
    ...mapGetters(['app', 'context']),
    endpoint () {
      return this.context.route + '/platform-accounts/' + this.account.id + '/pull-products/'
    },
    query () {
      const qs = {}
      if (this.settings.dropshipCostPercent) {
        qs.dropship_cost_percent = this.settings.dropshipCostPercent
      }
      if (this.settings.collection) {
        qs.collection_id = this.settings.collection.id
      }
      return (!this.$utils.isEmpty(qs))
        ? { params: qs }
        : null
    },
    validated () {
      return true
    }
  },
  methods: {
    back () {
      this.$emit('change:step', 'DropshipCost')
    },
    save () {
      this.saving = true
      this.$http.get(this.endpoint, this.query).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Import scheduled!' })
        this.$router.push({ name: 'shopify.onboard.orders' })
        this.saving = false
      })
    }
  },
  mounted () {

  },
  props: ['account', 'settings']
}
</script>
