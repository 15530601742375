import dates from '@/assets/js/dates'
export const status = {
  label: 'Order Status',
  key: 'status',
  icon: 'pulse',
  tooltip: 'The status of the order.',
  options: [{
    value: 'open,partial',
    label: 'Open'
  }, {
    value: 'closed',
    label: 'Completed'
  }, {
    value: 'canceled',
    label: 'Canceled'
  }]
}

export const connection = {
  label: 'Connection',
  key: 'connection_id',
  icon: 'hubspot',
  tooltip: 'This is a tooltip'
}

export const tags = {
  label: 'Tag',
  key: 'tags',
  icon: 'tag',
  tooltip: 'This is a tooltip'
}

export const item = {
  label: 'Supplier SKU',
  key: 'variant_identifier',
  icon: 'tag-outline',
  tooltip: 'This is a tooltip'
}

export const retailerSku = {
  label: 'Retailer SKU',
  key: 'retailer_identifier',
  icon: 'tag-outline',
  tooltip: 'This is a tooltip'
}

export const fulfilled = {
  label: 'Requires Fulfillment?',
  key: 'not_fully_shipped',
  icon: 'format-list-checkbox',
  tooltip: 'Return only orders that require one or more fulfillments.',
  options: [{
    value: 1,
    label: 'Yes'
  }, {
    value: 0,
    label: 'No'
  }]
}

export const invoiced = {
  label: 'Requires Invoice?',
  key: 'not_fully_invoiced',
  icon: 'format-list-checkbox',
  tooltip: 'Return only orders that require one or more invoices.',
  options: [{
    value: 1,
    label: 'Yes'
  }, {
    value: 0,
    label: 'No'
  }]
}

export const unreadMemos = {
  label: 'Has Unread Messages?',
  key: 'has_unread_memos',
  icon: 'comment-alert-outline',
  tooltip: 'Return only orders that have unread messages.',
  options: [{
    value: 1,
    label: 'Yes'
  }, {
    value: 0,
    label: 'No'
  }]
}

export const acknowledged = {
  label: 'Acknowledged?',
  key: 'acknowledged',
  icon: 'check',
  tooltip: 'This is a tooltip',
  options: [{
    value: 1,
    label: 'Yes'
  }, {
    value: 0,
    label: 'No'
  }]
}

export const backordered = {
  label: 'Backordered?',
  key: 'is_backordered',
  icon: 'calendar-clock',
  tooltip: 'Filter orders that have 1 or more lines that are backordered',
  options: [{
    value: 1,
    label: 'Yes'
  }, {
    value: 0,
    label: 'No'
  }]
}

export const backorderAcknowledged = {
  label: 'Backordered Acknowledged?',
  key: 'backorder_is_acknowledged',
  icon: 'check',
  tooltip: 'Filter backorders that have been acknowledged.',
  options: [{
    value: 1,
    label: 'Yes'
  }, {
    value: 0,
    label: 'No'
  }]
}

export const backorderedUntil = {
  label: 'Backordered Until',
  key: 'backordered_until',
  icon: 'clock-outline',
  tooltip: 'This is a tooltip',
  options: dates
}

export const orderedAt = {
  label: 'Order Received',
  key: 'created_at',
  icon: 'clock-outline',
  tooltip: 'This is a tooltip',
  options: dates
}

export const closedAt = {
  label: 'Order Closed',
  key: 'closed_at',
  icon: 'clock-outline',
  tooltip: 'This is a tooltip',
  options: dates
}

export const canceledAt = {
  label: 'Order Canceled',
  key: 'canceled_at',
  icon: 'clock-outline',
  tooltip: 'This is a tooltip',
  options: dates
}

export const fulfillBy = {
  label: 'Due Date',
  key: 'fulfill_by',
  icon: 'alarm',
  tooltip: 'This is a tooltip',
  options: dates
}
