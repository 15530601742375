<template>
  <b-tooltip position="is-bottom" multilined :active="!loading && !transformers.length" style="width: 100%">
    <b-select
      v-model="transformer"
      :placeholder="getPlaceholder"
      expanded
      :loading="loading"
      :disabled="loading || !transformers.length"
      :size="sizeClass"
      @input="announce">
      <option
        v-for="transformer in transformers"
        :value="transformer"
        :key="transformer.id"
      >{{ transformer.name }}</option>
    </b-select>
    <template v-slot:content>
      No transformer available to apply.
    </template>
  </b-tooltip>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  data () {
    return {
      loading: false,
      transformer: null,
      transformers: [],
      isRequired: false
    }
  },
  computed: {
    ...mapGetters(['context']),
    getPlaceholder () {
      return this.isRequired ? 'Select Transformer' : 'Select Transformer (optional)'
    },
    endpoint () {
      return this.context.route + '/transformers/'
    },
    query () {
      return {
        params: {
          pagination: 0
        }
      }
    },
    sizeClass () {
      return (this.size) ? this.size : null
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint, this.query).then(response => {
        this.transformers = response.data.results
        this.loading = false
      })
    },
    announce () {
      this.$emit('input', this.transformer)
    }
  },
  mounted () {
    if (this.value !== null) {
      this.transformer = this.value
    }
    if (this.isMandatory) {
      this.isRequired = this.isMandatory
    }
    this.load()
  },
  props: ['value', 'size', 'isMandatory']
}
</script>
