<template>
  <form @submit.prevent="save">
    <div class="columns">
      <div class="column">
        <div class="has-background-white rounded-lg">
          <!-- Loaded -->
          <div v-if="preferences !== null" class="pa-lg pb-xl">
            <div class="mb-lg">
              <p class="has-text-weight-bold mb-xs">Default Catalog Import Method</p>
              <div class="has-text-grey is-size-7">
                <p class="mb-md">
                  The catalog import method defines how suppliers will share product data with you.
                  Selecting 'Import Requests' will require you to upload a spreadsheet on behalf of
                  your suppliers and subsequently seek the suppliers's approval to publish the products.
                  Selecting 'Proposals' will require suppliers to submit products to you for approval.
                </p>
              </div>
            </div>
            <div class="columns">
              <div class="column is-half">
                <b-field label="Import Method">
                  <b-select
                    type="text"
                    v-model="method"
                    size="is-medium"
                    expanded
                    @input="isDirty = 1">
                    <option v-for="option in options" :key="option.id" :value="option">{{ option.name }}</option>
                  </b-select>
                </b-field>
              </div>
            </div>
          </div>
          <div class="border-t">
            <div class="columns is-vcentered is-marginless">
              <div class="column is-hidden-mobile">
                <div class="px-sm has-text-grey-light">
                  <i v-if="isDirty">Click 'Save Preference' to save your changes</i>
                </div>
              </div>
              <div class="column is-narrow">
                <div class="px-sm">
                  <b-button
                    native-type="submit"
                    type="is-primary rounded-lg"
                    :disabled="!ready || saving || !isDirty"
                  >Save Preference</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  computed: {
    ...mapGetters(['app', 'context']),
    endpoint () {
      return this.context.route + '/retailer-preferences/'
    },
    json () {
      const preferences = [{
        preference: { id: this.methodPreference.id },
        option: { id: this.method.id }
      }]
      return preferences
    },
    ready () {
      return (this.method !== null)
    }
  },
  data () {
    return {
      isDirty: false,
      methodPreference: null,
      method: null,
      errors: [],
      saving: false,
      options: []
    }
  },
  methods: {
    save () {
      this.saving = true
      this.$http.post(this.endpoint, this.json).then(response => {
        this.isDirty = false
        this.$buefy.toast.open({ type: 'is-success', message: 'Preferences updated!' })
      }).catch(error => {
        if (error.response && error.response.status === 400) {
          this.errors = error.response.data
        }
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    // locate preferences
    this.methodPreference = this.$utils.find(this.preferences, ['code', 'default_catalog_import_method'])

    if (typeof this.methodPreference !== 'undefined') {
      // set the options
      this.options = this.methodPreference.options.map(option => {
        return {
          id: option.id,
          name: option.name,
          value: option.value
        }
      })
      // set the current values
      if (this.methodPreference.retailer_preference) {
        this.method = this.$utils.find(this.options, ['value', this.methodPreference.retailer_preference.value])
      }
    }
  },
  props: ['preferences']
}
</script>
