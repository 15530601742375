<template>
  <div class="has-text-centered">
    <h4 class="is-size-5 mt-lg has-text-centered">Summary</h4>
        <div :class="isColumns">
          <div :class="isTwoColumns">
            <div class="has-background-white pa-md rounded-lg">
              <h2 class="is-size-5 has-text-centered">Compliance Score</h2>
              <div v-if="!loading" >
                <LineChart
                  :chartData="graphData"
                  name="score"
                  :month="thisMonthsIndex"
                  :height="250"
                  notePosition="right"/>
              </div>
              <div v-else>
                <loading />
              </div>
            </div>
          </div>
          <div v-if="isMerchant" class="column is-half">
            <div class="has-background-white pa-md rounded-lg">
              <h2 class="is-size-5 has-text-centered">Rank</h2>
              <div v-if="!loading" >
                <LineChart
                  :chartData="graphData"
                  name="rank"
                  :month="thisMonthsIndex"
                  :height="250"
                  notePosition="right"/>
              </div>
              <div v-else>
                <loading />
              </div>
            </div>
          </div>
        </div>
  </div>
</template>

<script>
import LineChart from '../../../components/Graphs/LineChart.vue'

export default {
  components: {
    LineChart
  },
  computed: {
    isColumns () {
      return this.isMerchant ? 'mt-sm mb-md columns mt-sm is-multiline' : 'mt-sm mb-md mt-sm is-multiline'
    },
    isTwoColumns () {
      return this.isMerchant ? 'column is-half' : ''
    },
    graphData () {
      const graphData = {
        count: this.connectionsCount,
        results: [
          {
            score: 0,
            rank: 0
          },
          {
            score: 0,
            rank: 0
          },
          {
            score: 0,
            rank: 0
          },
          {
            score: 0,
            rank: 0
          },
          {
            score: 0,
            rank: 0
          },
          {
            score: 0,
            rank: 0
          }
        ]
      }
      for (let i = 0; i < this.scoreHistoryData.length; i++) {
        if (this.scoreHistoryData[i]) {
          graphData.results[5 - i].score = this.scoreHistoryData[i].results[0].score
          if (this.isMerchant) {
            graphData.results[5 - i].rank = this.scoreHistoryData[i].results[0].rank
          }
        }
      }
      return graphData
    }
  },
  props: ['scoreHistoryData', 'thisMonthsIndex', 'connectionsCount', 'loading', 'isMerchant']
}
</script>
