<template>
  <div>
    <form @submit.prevent="save">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Update Connection SLAs</p>
        </header>
        <section class="modal-card-body">
          <!-- General Errors -->
          <div v-if="error" class="mb-md">
            <b-message type="is-danger">{{ error }}</b-message>
          </div>
          <!-- SLA Terms -->
          <b-field label="Fulfillment SLA (Business Days)">
            <b-numberinput
              v-model="fulfillmentSla"
              size="is-medium"
              :controls="false"
              :min="1"
              expanded
              :disabled="saving"  />
          </b-field>
        </section>
        <footer class="modal-card-foot space-between">
          <b-button @click="$parent.close()">Close</b-button>
          <b-button
            type="is-primary"
            native-type="submit"
            :loading="saving"
            :disabled="saving">
            Save Settings
          </b-button>
        </footer>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  data () {
    return {
      fulfillmentSla: '48',
      saving: false,
      error: null,
      errors: {}
    }
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/connections/' + this.connection.id + '/slas/' + this.connection.connection_slas.id + '/'
    },
    json () {
      return {
        brand_fulfillment_hours: this.fulfillmentSla * 24
      }
    }
  },
  methods: {
    save () {
      this.saving = true
      this.$http.patch(this.endpoint, this.json).then(response => {
        this.$emit('saved:connection')
        this.$buefy.toast.open({ type: 'is-success', message: 'Settings updated!' })
        this.$parent.close()
      }).catch(error => {
        if (error.response && 'non_field_errors' in error.response.data) {
          this.error = error.response.data.non_field_errors[0]
        }
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    this.fulfillmentSla = this.connection.connection_slas.brand_fulfillment_hours / 24
  },
  props: ['connection']
}
</script>
