import dates from '@/assets/js/dates'

export const createdAt = {
  label: 'Created At',
  key: 'created_at',
  icon: 'clock-outline',
  tooltip: 'Limit results based on when the transformer was created.',
  options: dates
}

export const updatedAt = {
  label: 'Updated At',
  key: 'updated_at',
  icon: 'calendar-outline',
  tooltip: 'Filter proposals based the date and time they were last updated',
  options: dates
}
