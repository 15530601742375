<template>
  <div>
    <div class="section">
      <div class="container">
        <!-- Page Header -->
        <div class="columns is-vcentered">
          <div class="column is-one-third">
            <breadcrumbs :history="[{ label: 'Catalog', name: 'catalog.index'}]" current="Import Product Data" :hideBack="true" />
          </div>
          <div class="column is-one-third">
            <h1 class="has-text-centered is-size-4">Import Product Data</h1>
          </div>
        </div>
        <!-- Content -->
        <div>
          <!-- Step 1: Select Template -->
          <div class="has-background-white pa-lg mb-xs rounded-lg">
            <div class="mb-md">
              <h1 class="has-text-primary has-text-weight-bold">Step 1: Select Template</h1>
              <p class="has-text-grey">Select the template that you used for this file.</p>
            </div>
            <div class="columns is-multiline">
              <div v-if="context.isMerchant()" class="column">
                <b-field label="Connection">
                  <ConnectionSelect v-model="connection" />
                </b-field>
              </div>
              <div class="column is-half">
                <b-field label="Template">
                  <b-select
                    v-model="template"
                    placeholder="Select a template..."
                    :disabled="loading || scheduling"
                    :loading="loading"
                    expanded>
                    <option v-for="template in templates" :value="template" :key="template.id">{{ template.name }}</option>
                  </b-select>
                </b-field>
              </div>
              <div class="column is-half">
                <b-field label="Import Behavior">
                  <b-select
                    v-model="behavior"
                    placeholder="Select behavior..."
                    expanded>
                    <option disabled value="merge">Merge (Create new products & update existing)</option>
                    <option value="update">Update (Update existing products)</option>
                  </b-select>
                </b-field>
              </div>
              <!-- Apply Transformers -->
              <div class="column is-half">
                <b-field label="Transformer">
                  <TransformerSelect v-model="transformer"/>
                </b-field>
              </div>
            </div>
          </div>
          <!-- Step 2: Select File -->
          <div v-if="readyForFile" class="has-background-white pa-lg mb-xs rounded-lg">
            <div class="mb-md">
              <h1 class="has-text-primary has-text-weight-bold">Step 2: Select a File</h1>
              <p class="has-text-grey">Select the file you want to upload to {{ app.platform }}.</p>
            </div>
            <div class="columns">
              <div class="column is-half-desktop">
                <!-- Upload -->
                <CoreUpload @file:uploaded="updateTempfile" @file:reset="resetTempfile"/>
              </div>
            </div>
          </div>
          <!-- Step 3: Begin Import -->
          <div v-if="tempFile !== null" class="has-background-white pa-lg mb-lg rounded-xl">
            <div class="mb-lg">
              <h1 class="has-text-primary has-text-weight-bold">Step 3: Schedule Import</h1>
              <p class="has-text-grey">When ready, click the button below to schedule your import.</p>
            </div>
            <div>
              <b-button
                type="is-primary"
                :disabled="!ready || feed !== null"
                :loading="scheduling"
                @click="queueFile"
              >Begin Import</b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CoreUpload from '@/components/CoreUpload'
import ConnectionSelect from '@/components/Models/Connection/ConnectionSelect'
import TransformerSelect from '@/components/Models/Transformers/TransformerSelect'

export default {
  components: {
    ConnectionSelect,
    CoreUpload,
    TransformerSelect
  },
  computed: {
    ...mapGetters(['app', 'context']),
    endpoints () {
      return {
        template: this.context.route + '/templates/',
        queue: this.context.route + '/feed-queue/'
      }
    },
    templatesQuery () {
      return {
        params: {
          direction: 'import',
          data_type: 'products',
          limit: 100
        }
      }
    },
    json () {
      if (!this.template || !this.behavior) return null
      const json = {
        template: {
          id: this.template.id
        },
        direction: 'import',
        data_type: 'products',
        source: 'console',
        behavior: this.behavior,
        info: {},
        file: {
          tempfile: {
            id: this.tempFile.id
          }
        }
      }
      if (this.connection !== null) {
        json.info = {
          brand_id: this.connection.brand.id
        }
      }
      if (this.transformer && this.transformer !== null) {
        json.info.transformer_ids = [this.transformer.id]
      }
      return json
    },
    loading () {
      if (this.loadingTemplates) return true
      return false
    },
    readyForFile () {
      if (this.context.isMerchant()) {
        return (
          this.template !== null &&
          this.connection !== null
        )
      } else {
        return (
          this.template !== null
        )
      }
    },
    ready () {
      const ready = (
        !this.loadingTemplates &&
        !this.uploading &&
        !this.scheduling &&
        this.template !== null &&
        this.tempFile !== null
      )
      return ready
    }
  },
  data () {
    return {
      loadingTemplates: false,
      templates: [],
      template: null,
      tempFile: null,
      behavior: 'merge',
      connection: null,
      scheduling: false,
      feed: null,
      transformer: null
    }
  },
  methods: {
    loadTemplates () {
      this.loadingTemplates = true
      this.$http.get(this.endpoints.template, this.templatesQuery).then(response => {
        this.templates = response.data.results
        this.loadingTemplates = false
      })
    },
    queueFile () {
      this.scheduling = true
      this.$http.post(this.endpoints.queue + 'enqueue/', this.json).then(response => {
        this.$http.put(this.endpoints.queue + response.data.id + '/run/').then(() => {
          this.scheduling = false
          this.$buefy.toast.open({ type: 'is-success', message: 'File scheduled!' })
        })
      })
    },
    updateTempfile (file, response) {
      this.tempFile = response
    },
    resetTempfile () {
      this.tempFile = null
    }
  },
  mounted () {
    this.loadTemplates()
  }
}
</script>
