<template>
  <div>
    <div class="section">
      <div class="container">
        <!-- Page Header -->
        <div class="columns is-vcentered">
          <div class="column is-one-third">
            <breadcrumbs :history="[{ label: 'Catalog', name: context.isSupplier() ? 'catalog' : 'catalog.index' }]" current="Offers" :hideBack="true" />
          </div>
          <div class="column is-one-third">
            <h1 class="has-text-centered is-size-4">Offers</h1>
          </div>
        </div>
        <!-- Filters -->
        <div class="has-background-white rounded-lg pa-md mb-xxs">
          <div class="columns">
            <div class="column">
              <OfferFilters @filter="filter" :applied-filters="filters" />
            </div>
            <div class="column is-narrow">
              <b-field >
                <b-select v-model="orderBy" expanded @input="sort" size="is-medium">
                  <option value="-id">Most Recent</option>
                  <option value="id">Oldest</option>
                </b-select>
              </b-field>
            </div>
            <div v-if="context.isSupplier()" class="column is-narrow">
              <b-dropdown position="is-bottom-left">
                <button class="button is-medium" slot="trigger">
                  <b-icon icon="dots-vertical"></b-icon>
                  <span>More</span>
                </button>
                <b-dropdown-item @click="showExportModal = true">
                  Export Offers
                </b-dropdown-item>
                <b-dropdown-item has-link>
                  <router-link :to="{ name: 'catalog.import.permits' }">Upload Offers</router-link>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <!-- Toolbar -->
            <div class="has-background-white rounded-lg px-sm mb-xxs">
              <div class="columns is-vcentered is-mobile is-marginless is-paddingless">
                <!-- Dropdown -->
                <div class="column is-narrow">
                  <b-dropdown :disabled="loading || selected.length === 0">
                    <button class="button" slot="trigger" slot-scope="{ active }">
                      <span>Actions</span>
                      <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
                    </button>
                    <b-dropdown-item has-link><a href="#" @click.prevent>No Actions</a></b-dropdown-item>
                  </b-dropdown>
                </div>
                <div class="column">
                  <div class="has-text-right is-size-7">
                    <!-- Counts -->
                    <p v-if="loading">Loading...</p>
                    <p v-else>{{ total }} {{ (total === 1) ? 'offer' : 'offers' }} found.</p>
                  </div>
                </div>
              </div>
            </div>
            <!-- Loading -->
            <div v-if="loading">
              <div class="has-background-white rounded-lg pa-md mb-xxs" v-for="index in placeholders" :key="index">
                <div class="placeholder placeholder-title" style="width:66%"></div>
                <div class="placeholder placeholder-sentence" style="width:50%"></div>
              </div>
            </div>
            <!-- Loaded (None)  -->
            <div v-else-if="!loading && results.length === 0">
              <div class="has-background-white rounded-xl has-text-grey-light has-text-centered py-3xl">
                <p><b-icon icon="magnify-close" size="is-large"></b-icon></p>
                <p>No offers found.</p>
              </div>
            </div>
            <!-- Loaded -->
            <div v-else>
              <div>
                <b-table
                  :data="results"
                  :checked-rows.sync="selected"
                  :mobile-cards="false"
                  :loading="loading || results.length === 0"
                  checkable
                  hoverable
                  class="is-size-7">
                  <template>
                    <!-- SKU -->
                    <b-table-column
                      label="SKU"
                      cell-class="nowrap"
                      v-slot="props">
                      <Identifiers
                        :variant="props.row.variant"
                        :showImage="false"
                        :showMerhantSku="true"
                        imageInteraction="none"
                        nameInteraction="inspect"
                        @inspect="inspectPermit(props.row)"
                      ></Identifiers>
                    </b-table-column>
                    <!-- Supplier -->
                    <b-table-column
                      v-if="context.isMerchant()"
                      label="Supplier"
                      header-class="nowrap is-hidden-mobile"
                      cell-class="nowrap is-hidden-mobile"
                      width="200"
                      v-slot="props">
                      <p>{{ props.row.variant.brand.name }}</p>
                    </b-table-column>
                    <!-- Retailer -->
                    <b-table-column
                      v-if="context.isSupplier()"
                      label="Merchant"
                      header-class="nowrap is-hidden-mobile"
                      cell-class="nowrap is-hidden-mobile"
                      width="200"
                      v-slot="props">
                      <p>{{ props.row.retailer.name }}</p>
                    </b-table-column>
                    <!-- Cost -->
                    <b-table-column
                      label="Cost"
                      header-class="nowrap is-hidden-mobile"
                      cell-class="nowrap is-hidden-mobile"
                      centered
                      width="110"
                      v-slot="props">
                      <p v-if="props.row.retailer_cost !== null">{{ $numbers(props.row.retailer_cost).formatCurrency({ mantissa: 2 }) }}</p>
                      <p v-else class="has-text-grey-light">External</p>
                    </b-table-column>
                    <!-- Price -->
                    <!-- <b-table-column
                      label="Retail"
                      header-class="nowrap is-hidden-mobile"
                      cell-class="nowrap is-hidden-mobile"
                      centered
                      width="110"
                      v-slot="props">
                      <p v-if="props.row.retailer_price !== null">{{ $numbers(props.row.retailer_price).formatCurrency({ mantissa: 2 }) }}</p>
                      <p v-else class="has-text-grey-light">External</p>
                    </b-table-column> -->
                    <!-- Start Date -->
                    <b-table-column
                      label="Starts"
                      header-class="nowrap is-hidden-mobile"
                      cell-class="nowrap is-hidden-mobile"
                      centered
                      width="110"
                      v-slot="props">
                      <p v-if="props.row.start_at !== null">{{ $dates.utc(props.row.start_at).local().format('MMM D, YYYY') }}</p>
                      <p v-else class="has-text-grey-light">Now</p>
                    </b-table-column>
                    <!-- End Date -->
                    <b-table-column
                      label="Ends"
                      header-class="nowrap is-hidden-mobile"
                      cell-class="nowrap is-hidden-mobile"
                      centered
                      width="110"
                      v-slot="props">
                      <p v-if="props.row.end_at !== null">{{ $dates.utc(props.row.end_at).local().format('MMM D, YYYY') }}</p>
                      <p v-else class="has-text-grey-light">Never</p>
                    </b-table-column>
                    <!-- Ack -->
                    <b-table-column
                      label="Ack?"
                      header-class="nowrap is-hidden-mobile"
                      cell-class="nowrap is-hidden-mobile"
                      centered
                      width="64"
                      v-slot="props">
                      <p v-if="props.row.acknowledged_at !== null">Yes</p>
                      <p v-else class="has-text-grey-light">No</p>
                    </b-table-column>
                  </template>
                </b-table>
              </div>
              <div class="mt-md">
                <b-pagination
                  :total="total"
                  :current.sync="currentPage"
                  :rounded="true"
                  :per-page="perPage"
                  @change="paginate">
                </b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Export Permits Modal -->
    <b-modal
      has-modal-card
      :active.sync="showExportModal"
      :can-cancel="['escape', 'x']">
      <ExportPermitsModal @offers:export="scheduleExport" />
    </b-modal>
    <!-- Permit Inspection Modal -->
    <b-modal
      has-modal-card
      :active="detail !== null"
      :can-cancel="['escape', 'x']"
      @close="detail = null" >
      <PermitModal :selected="detail" />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CdnTransform from '@/mixins/CdnTransform.js'
import Filterable from '@/mixins/Filterable'
import Exportable from '@/mixins/Exportable'
import OfferFilters from '../components/Offers/OfferFilters'
import Identifiers from '@/components/Models/Variant/Identifiers'
import PermitModal from '@/components/Modals/Permit/PermitModal'
import ExportPermitsModal from '@/components/Models/Permit/Modals/ExportPermits'

export default {
  components: {
    Identifiers,
    OfferFilters,
    ExportPermitsModal,
    PermitModal
  },
  computed: {
    ...mapGetters(['context']),
    placeholders () {
      return Math.min(15, this.perPage)
    },
    endpoint () {
      return this.context.route + '/permits/'
    },
    exportEndpoint () {
      return this.endpoint + 'email-export/'
    }
  },
  data () {
    return {
      key: 'permits',
      selected: [],
      detail: null,
      showExportModal: false
    }
  },
  methods: {
    inspectPermit (permit) {
      this.detail = permit
    }
  },
  mixins: [CdnTransform, Filterable, Exportable],
  mounted () {
    this.load()
  },
  props: []
}
</script>
