<template>
  <div>
    <!-- Content -->
    <div class="mb-lg">
      <div class="content">
        <p>
          {{ app.productName }} can import products directly from your from Shopify shop.
        </p>
        <p>
          We recommend
          <a :href="shopifyAdminUrl" target="_blank" class="has-text-info">creating a collection</a>
          in Shopify and only importing the products
          that you add to that collection. If you'd like to set up that collection,
          go do that now and come back once the collection is ready. Otherwise, you can just import
          all currently published products.
        </p>
      </div>
      <!-- Fields -->
      <div class="mb-xxl">
        <p class="has-text-weight-bold mb-md">Which products do you want to import?</p>
        <div class="mb-xl">
          <div class="field">
            <b-radio v-model="importFilter" native-value="collection">
              Import products from a Shopify collection (recommended).
            </b-radio>
          </div>
          <div class="field">
            <b-radio v-model="importFilter" native-value="published">
              Import all products that are currently published to my online store.
            </b-radio>
          </div>
        </div>
        <!-- Collection ID Input -->
        <div v-if="importFilter === 'collection'" class="mb-lg">
          <div class="">
            <div class="">
              <p class="has-text-weight-bold mb-sm">Select a Shopify Collection</p>
              <SelectCollection :account="account" v-model="collection" />
            </div>
            <div v-if="collection !== null">
              <div class="has-background-white-ter pa-md rounded-lg">
                <div class="columns is-mobile is-vcentered">
                  <div class="column">
                    <p class="">{{ collection.title }}</p>
                    <p class="has-text-grey">Shopify ID: {{ collection.id }}</p>
                  </div>
                  <div class="column is-narrow">
                    <a href="#" @click.prevent="collection = null">
                      <b-icon icon="close" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <p v-if="collection === null" class="mb-lg is-size-7">
                <span class="has-text-danger has-text-weight-bold">Please Note:</span>
                This search will perform a live search against collections in your
                shop. Shopify requires the <i><u>exact
                and complete</u></i> name of the collection be entered in order to return a result.
              </p>
            </div>
          </div>
        </div>
        <b-field
          label="Transformer"
          expanded>
          <TransformerSelect v-model="transformer" />
    </b-field>
      </div>
    </div>
    <!-- Navigate -->
    <div class="py-lg">
      <div class="columns is-centered">
        <div class="column">
          <b-button
            type="is-light rounded-lg"
            size="is-medium"
            @click="goBack"
            expanded
            ><b-icon icon="arrow-left"></b-icon>
          </b-button>
        </div>
        <div class="column">
          <b-button
            type="is-primary rounded-lg"
            size="is-medium"
            expanded
            :disabled="!ready"
            @click="goForward"
          >Continue</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SelectCollection from './SelectCollection'
import TransformerSelect from '@/components/Models/Transformers/TransformerSelect'

export default {
  components: {
    SelectCollection,
    TransformerSelect
  },
  computed: {
    ...mapGetters(['app']),
    shopifyAdminUrl () {
      if (this.account.credentials.length === 0) return null
      let store = null
      this.account.credentials.forEach(credential => {
        Object.keys(credential).forEach(key => {
          if (key === 'shopify_shop_name') {
            store = credential[key].values[0].value
          }
        })
      })
      return (store !== null) ? 'https://' + store + '.myshopify.com/admin/collections' : null
    },
    ready () {
      if (this.importFilter === 'published') return true
      return (
        this.importFilter === 'collection' &&
        this.collection !== null
      )
    }
  },
  data () {
    return {
      importFilter: null,
      collection: null,
      transformer: null
    }
  },
  methods: {
    goBack () {
      this.$router.push({ name: 'catalog.import' })
    },
    goForward () {
      if (this.importFilter === 'collection' && this.collection !== null) {
        this.$emit('change:setting', { key: 'importFilter', value: 'collection' })
        this.$emit('change:setting', { key: 'collection', value: this.collection })
      } else {
        this.$emit('change:setting', { key: 'importFilter', value: 'published' })
      }
      if (this.transformer !== null) {
        this.$emit('change:setting', { key: 'transformer_id', value: this.transformer.id })
      }
      this.$emit('navigate', 'ScheduleImport')
    }
  },
  props: {
    account: {
      type: Object,
      required: true
    }
  }
}
</script>
