<template>
  <div>
    <div class="section">
      <div class="container">
        <!-- Page Header -->
        <div class="columns is-vcentered">
          <div class="column is-one-third">
            <breadcrumbs :history="[{ label: 'Integrations', name: 'settings.integrations'}]" current="Utilities" :hideBack="true" />
          </div>
          <div class="column is-one-third">
            <h1 class="has-text-centered is-size-4">ShipStation Utilities</h1>
          </div>
        </div>
        <!-- Content -->
        <div>
          <div v-if="loading">
            <loading />
          </div>
          <div v-else>
            <!-- Push Orders (Suppliers) -->
            <div v-if="context.isSupplier()" class="border-b mb-xl pb-xl">
              <PushOrder :account="account" />
            </div>
            <!-- Retry Fulfillments (Suppliers) -->
            <div v-if="context.isSupplier()" class="border-b mb-xl pb-xl">
              <RetryFulfillment :account="account" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PushOrder from '../components/ShipstationUtilities/PushOrder'
import RetryFulfillment from '../components/ShipstationUtilities/RetryFulfillment'

export default {
  components: {
    PushOrder,
    RetryFulfillment
  },
  data () {
    return {
      loading: true,
      account: null
    }
  },
  computed: {
    ...mapGetters(['auth', 'context']),
    endpoint () {
      return this.context.route + '/platform-accounts/' + this.$route.params.id + '/'
    }
  },
  methods: {
    load (refresh = false) {
      this.loading = (!refresh)
      this.$http.get(this.endpoint).then(response => {
        this.account = response.data
      }).finally(() => {
        this.loading = false
      })
    }
  },
  mounted () {
    this.load()
  },
  props: []
}
</script>
