<template>
  <div>
    <div class="columns">
      <div class="column is-one-fifth">
        <p class="has-text-weight-bold is-size-6 mb-sm">Product Variants</p>
        <p class="has-text-grey">
          Manage product options such as colors and sizes.
        </p>
      </div>
      <div class="column">
        <div class="">
          <div>
            <!-- Toolbar -->
            <div class="has-background-white rounded-lg mb-xxs pa-md">
              <div class="columns">
                <div class="column">
                  <b-dropdown aria-role="list" :disabled="selected.length === 0">
                    <button class="button" slot="trigger" slot-scope="{ active }">
                      <span>Edit Attribute</span>
                      <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
                    </button>
                    <b-dropdown-item @click="editVariants('cost')">Dropship Cost</b-dropdown-item>
                    <b-dropdown-item @click="editVariants('map')">MAP</b-dropdown-item>
                    <hr class="dropdown-divider">
                    <b-dropdown-item @click="editVariants('color')">Color</b-dropdown-item>
                    <b-dropdown-item @click="editVariants('size')">Size</b-dropdown-item>
                  </b-dropdown>
                </div>
                <div class="column is-narrow">
                  <b-field>
                    <b-radio-button v-model="view" native-value="pricing">Pricing</b-radio-button>
                    <b-radio-button v-model="view" native-value="attributes">Attributes</b-radio-button>
                    <b-radio-button v-model="view" native-value="images">Images</b-radio-button>
                  </b-field>
                </div>
              </div>
            </div>
            <b-table
              :data="presentVariants"
              :checked-rows.sync="selected"
              :mobile-cards="false"
              :loading="loading || variants.length === 0"
              checkable
              checkbox-position="left"
              class="is-size-7 is-tall">
              <template>
                <!-- Image -->
                <b-table-column
                  field="image"
                  label=""
                  cell-class="is-tall"
                  :visible="columns.includes('sku')"
                  width="64"
                  centered
                  v-slot="props">
                  <figure class="image is-48x48">
                    <img :src="cdnTransform(props.row.media[0].url, 48, 48, 'fill')" class="rounded-lg mr-xs is-block" />
                  </figure>
                </b-table-column>
                <!-- Name & SKU -->
                <b-table-column
                  field="sku"
                  label="SKU"
                  cell-class="is-tall"
                  :visible="columns.includes('sku')"
                  v-slot="props">
                  <router-link class="has-text-weight-bold" :to="{ name: 'network.variant', params: { id: $route.params.id, variant: props.row.id } }">
                    {{ props.row.name }}
                  </router-link>
                  <p class="has-text-grey is-size-7">{{ props.row.sku }}</p>
                </b-table-column>
                <!-- Images -->
                <b-table-column
                  label="Images"
                  :visible="columns.includes('images')"
                  style="white-space:nowrap"
                  v-slot="props">
                  <figure class="image is-48x48 is-pulled-left mr-xs" v-for="medium in props.row.media" :key="medium.id">
                    <img class="rounded-lg" :src="cdnTransform(medium.url, 48, 48, 'fill')"  />
                  </figure>
                </b-table-column>
                <!-- Color -->
                <b-table-column
                  label="Color"
                  :visible="columns.includes('color')"
                  v-slot="props">
                  <p v-if="props.row.color === null" class="has-text-grey is-size-7">N/A
                  <p v-else>{{ props.row.color }}</p>
                </b-table-column>
                <!-- Size -->
                <b-table-column
                  label="Size"
                  :visible="columns.includes('size')"
                  v-slot="props">
                  <p v-if="props.row.size === null" class="has-text-grey is-size-7">N/A
                  <p v-else>{{ props.row.size }}</p>
                </b-table-column>
                <!-- Cost -->
                <b-table-column
                  label="Dropship Cost"
                  centered
                  :visible="columns.includes('cost')"
                  width="128"
                  v-slot="props">
                  <b-icon icon="alert" type="is-danger" v-if="props.row.cost === null" />
                  <p v-else>{{ $numbers(props.row.cost).formatCurrency({ mantissa: 2 }) }}</p>
                </b-table-column>
                <b-table-column
                  label="MAP"
                  centered
                  :visible="columns.includes('map')"
                  width="128"
                  v-slot="props">
                  <b-icon icon="alert-outline" v-if="props.row.map === null" />
                  <p v-else>{{ $numbers(props.row.map).formatCurrency({ mantissa: 2 }) }}</p>
                </b-table-column>
                <b-table-column
                  label="Inventory"
                  centered
                  :visible="columns.includes('inventory')"
                  width="100"
                  v-slot="props">
                  <b-icon icon="alert-outline" v-if="props.row.inventory === null" />
                  <p v-else>{{ props.row.inventory }}</p>
                </b-table-column>
                <b-table-column
                  label="Status"
                  centered
                  :visible="columns.includes('status')" width="100"
                  v-slot="props">
                  <b-icon icon="check" type="is-success" v-if="props.row.status === 'passed'" />
                  <b-icon icon="alert" type="is-danger" v-else-if="props.row.status === 'failed'" />
                  <b-icon icon="clock-outline" v-else />
                </b-table-column>
              </template>
              <template #empty>
                <div class="py-lg has-text-grey has-text-centered">
                  {{ (loading) ? "Loading data..." : 'No items found!' }}
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>

    <!-- Edit Variant Modal -->
    <b-modal :active.sync="showVariantEditModal"
      has-modal-card
      trap-focus>
      <variant-edit-modal :variants="selected" :field="editField" @variants:updated="load"></variant-edit-modal>
    </b-modal>

  </div >
</template>

<script>
import { mapGetters } from 'vuex'
import CdnTransform from '@/mixins/CdnTransform'
import VariantEditModal from '../components/VariantEditModal'
export default {
  components: {
    VariantEditModal
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/marketplaces/' + this.profile.id + '/products/' + this.product.id + '/variants/?classification=1'
    },
    presentVariants () {
      if (this.variants.length === 0) return []
      const variants = []
      this.variants.forEach(variant => {
        const mapped = {
          id: variant.id,
          sku: variant.brand_identifier,
          name: variant.name,
          color: variant.color,
          size: variant.size,
          cost: null,
          map: null,
          media: variant.media,
          inventory: variant.inventory.inventory
        }
        // handle pricing (which may or may not be present)
        if ('dropship_cost' in variant.attributes) {
          mapped.cost = this.$numbers(variant.attributes.dropship_cost.values[0].value).format({ mantissa: 2 })
        }
        if ('map' in variant.attributes) {
          mapped.map = this.$numbers(variant.attributes.map.values[0].value).format({ mantissa: 2 })
        }
        // ruleset
        if ('ruleset_variants' in variant && variant.ruleset_variants.length > 0) {
          variant.ruleset_variants.forEach(rulesetVariant => {
            if (rulesetVariant.ruleset.name === 'RCN Ruleset') {
              mapped.status = rulesetVariant.status
            }
          })
        }
        variants.push(mapped)
      })
      return variants
    },
    columns () {
      if (this.view === 'pricing') return ['sku', 'cost', 'map', 'msrp', 'inventory', 'status']
      if (this.view === 'attributes') return ['sku', 'color', 'size', 'status']
      if (this.view === 'images') return ['sku', 'images', 'status']
      return ['sku', 'status']
    }
  },
  data () {
    return {
      loading: true,
      view: 'pricing',
      variants: [],
      selected: [],
      selectAll: false,
      searchSku: null,
      showVariantEditModal: false,
      editField: null
    }
  },
  methods: {
    load () {
      this.selected = []
      this.loading = true
      this.$http.get(this.endpoint).then(response => {
        this.variants = response.data.results
      }).finally(() => {
        this.loading = false
      })
    },
    editVariants (field) {
      this.showVariantEditModal = true
      this.editField = field
    }
  },
  mixins: [CdnTransform],
  mounted () {
    this.load()
  },
  props: ['product', 'profile'],
  watch: {

  }
}
</script>
