<template>
  <span>{{ presenter }}</span>
</template>

<script>
export default {
  computed: {
    presenter () {
      if (this.template) {
        return this.$utils.startCase(this.template.replace('_', ' ').replace('.html', ''))
      }
      return 'Default Packing Slip'
    }
  },
  props: ['template']
}
</script>
