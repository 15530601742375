import Index from './views/Index'
import Onboard from './views/Onboard'
import Account from './views/Onboarding/Account'
import Plans from './views/Onboarding/Plans'
import Integration from './views/Onboarding/Integration'
import Products from './views/Onboarding/Products'
import Inventory from './views/Onboarding/Inventory'
import Orders from './views/Onboarding/Orders'
import Complete from './views/Onboarding/Complete'
import ConfirmInstall from './views/ConfirmInstall'
import Install from './views/Install'
import ShopifyLink from './views/ShopifyLink'

export default [{
  path: '/shopify',
  name: 'shopify.identity',
  component: Index,
  meta: {
    skipAuth: true,
    skipContext: true,
    hideNavigation: true
  }
}, {
  path: '/shopify/install',
  name: 'shopify.install',
  component: Install,
  meta: {
    skipAuth: true,
    skipContext: true,
    hideNavigation: true
  }
}, {
  path: '/shopify/confirm',
  name: 'shopify.confirm',
  component: ConfirmInstall,
  meta: {
    skipAuth: true,
    skipContext: true,
    hideNavigation: true
  }
}, {
  path: '/shopify/onboard',
  name: 'shopify.onboard',
  component: Onboard,
  meta: {
    hideNavigation: true
  },
  children: [{
    path: 'account',
    name: 'shopify.onboard.account',
    meta: {
      hideNavigation: true
    },
    component: Account
  }, {
    path: 'plans',
    name: 'shopify.onboard.plans',
    meta: {
      hideNavigation: true
    },
    component: Plans
  }, {
    path: 'integration',
    name: 'shopify.onboard.integration',
    meta: {
      hideNavigation: true
    },
    component: Integration
  }, {
    path: 'products',
    name: 'shopify.onboard.products',
    meta: {
      hideNavigation: true
    },
    component: Products
  }, {
    path: 'inventory',
    name: 'shopify.onboard.inventory',
    meta: {
      hideNavigation: true
    },
    component: Inventory
  }, {
    path: 'orders',
    name: 'shopify.onboard.orders',
    meta: {
      hideNavigation: true
    },
    component: Orders
  }, {
    path: 'complete',
    name: 'shopify.onboard.complete',
    meta: {
      hideNavigation: true
    },
    component: Complete
  }]
}, {
  path: '/shopify/link',
  name: 'shopify.link',
  component: ShopifyLink,
  meta: {
    skipAuth: true,
    skipContext: true,
    hideNavigation: true
  }
}]
