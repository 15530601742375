<template>
  <form @submit.prevent="save">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">
          <span v-if="mapping">
            Edit Template Mapping: {{ getMappingTitle }}
          </span>
          <span v-else>Add Template Mapping</span>
        </p>
      </header>
      <section class="modal-card-body pb-xl">
        <div v-if="!loading">
          <b-field :label="`${app.productName} Attribute`">
            <b-autocomplete
              v-model="search"
              placeholder="Select Attribute..."
              :keep-first="true"
              :open-on-focus="true"
              :data="filteredAttributes"
              field="name"
              @select="setAttribute"
              :clearable="true"
              expanded>
            </b-autocomplete>
          </b-field>
          <b-field
            :label="getAttributeLabel"
            v-if="fieldsType!='metafield-mappings'">
            <b-input v-model="title" expanded />
          </b-field>
          <b-field
            label="Metafield Namespace"
            v-if="fieldsType==='metafield-mappings'">
            <b-input v-model="metafieldNamespace" expanded />
          </b-field>
          <b-field
            label="Metafield Key"
            v-if="fieldsType==='metafield-mappings'">
            <b-input v-model="metafieldKey" expanded />
          </b-field>
          <b-field
            label="Metafield Type"
            v-if="fieldsType==='metafield-mappings'">
            <b-select
              v-model="metafieldType"
              placeholder="Select Type"
              expanded>
              <option
                v-for="metafieldType in metafieldTypes"
                :value="metafieldType"
                :key="metafieldType">
                {{ $utils.startCase(metafieldType) }}
              </option>
            </b-select>
          </b-field>
          <b-field
            label="Description"
            v-if="fieldsType === 'mappings' || 'tag-mappings' || 'metafield-mappings'">
            <b-input v-model="description" expanded />
          </b-field>
          <b-field label="Target">
            <b-select
              v-model="target"
              placeholder="Select Target"
              expanded>
              <option
                v-for="target in targets"
                :value="target"
                :key="target">
                {{ target }}
              </option>
            </b-select>
          </b-field>
        </div>
        <!-- Loading -->
        <div v-else>
          <loading message="Loading Attributes..." />
        </div>
      </section>
      <footer class="modal-card-foot space-between" >
        <b-button @click="$parent.close()">Close</b-button>
        <b-button
          native-type="submit"
          type="is-primary"
          :disabled="saving || !ready"
          :loading="saving"
          >Save</b-button>
      </footer>
    </div>
  </form>
  </template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['api', 'app', 'context']),
    filteredAttributes () {
      if (!this.search) return this.attributes
      return this.attributes.filter(option => {
        return (option.name.toString().toLowerCase().indexOf(this.search.toLowerCase()) >= 0)
      })
    },
    ready () {
      if (this.fieldsType === 'metafield-mappings') {
        return (this.attribute && this.metafieldKey && this.metafieldNamespace && this.metafieldType)
      }
      return (this.attribute && this.title)
    },
    getAttributeLabel () {
      return this.attributeLabel ? this.attributeLabel : `${this.templateType.charAt(0).toUpperCase() + this.templateType.slice(1)} Attribute`
    },
    getMappingTitle () {
      let label = ''
      if (this.fieldsType === 'metafield-mappings') {
        label = this.mapping && this.mapping.namespace + '.' + this.mapping.key
      } else {
        label = this.mapping && (this.mapping.title || this.mapping.name)
      } return label
    },
    endpoint () {
      const base = this.context.route + `/${this.templateType}/${this.$route.params.id}/templates/${this.$route.params.templateId}/${this.fieldsType}/`
      return (this.mapping)
        ? base + this.mapping.id + '/'
        : base
    },
    method () {
      return (this.mapping) ? 'patch' : 'post'
    },
    body () {
      const json = {
        attribute: { code: this.attribute.code }
      }
      if (this.fieldsType !== 'custom-field-mappings') {
        json.description = this.description
        json.title = this.title
      }
      if (this.fieldsType === 'metafield-mappings') {
        json.namespace = this.metafieldNamespace
        json.key = this.metafieldKey
        json.type = this.metafieldType
      }
      if (this.fieldsType === 'custom-fields-mappings') {
        json.name = this.title
      }
      if (this.templateType === 'fabric') {
        json.target = this.target.toLowerCase()
      }
      return json
    }
  },
  data () {
    return {
      loading: true,
      attributes: [],
      search: null,
      title: null,
      attribute: null,
      description: null,
      metafieldNamespace: null,
      metafieldKey: null,
      metafieldType: null,
      target: null,
      metafieldTypes: [
        'single_line_text_field',
        'money',
        'dimension',
        'url',
        'boolean',
        'multi_line_text_field',
        'date_time'
      ],
      targets: [
        'Variant',
        'Product'
      ],
      saving: false,
      errors: {}
    }
  },
  methods: {
    loadAttributes () {
      this.loading = true
      const endpoint = this.api.baseRoute + '/attributes/'
      const query = {
        params: { common: 1, pagination: 0 }
      }
      this.$http.get(endpoint, query).then(response => {
        this.attributes = response.data.results
        this.loading = false
      })
    },
    setAttribute (attribute = null) {
      this.attribute = attribute
    },
    save () {
      this.saving = true
      this.$http[this.method](this.endpoint, this.body).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Mapping saved' })
        this.$emit('mapping:saved')
        this.$parent.close()
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    this.loadAttributes()
    if (this.mapping) {
      this.search = this.mapping.attribute.name
      this.title = this.mapping.title || this.mapping.name
      this.attribute = this.mapping.attribute
      this.description = this.mapping.description
      this.metafieldNamespace = this.mapping.namespace
      this.metafieldKey = this.mapping.key
      this.metafieldType = this.mapping.type
      this.target = this.$utils.upperFirst(this.mapping.target)
    }
  },
  props: {
    mapping: {
      type: [Object, null]
    },
    templateType: {
      type: String,
      enum: ['bigcommerce', 'shopify', 'fabric'],
      default: ''
    },
    fieldsType: {
      type: String,
      enum: ['mappings', 'tag-mappings', 'metafield-mappings', 'custom-fields-mappings'],
      default: 'mappings'
    },
    attributeLabel: {
      type: String
    }
  }
}
</script>
