<template>
  <div>
    <div class="section">
      <div class="container">
        <!-- Page Header -->
        <div class="columns is-vcentered">
          <div class="column is-one-third">
            <breadcrumbs :history="[{ label: 'Products', name: (context.isSupplier()) ? 'catalog' : 'catalog.index'}]" current="Import Requests" :hideBack="true" />
          </div>
          <div class="column is-one-third">
            <h1 class="has-text-centered is-size-4">Import Requests</h1>
          </div>
        </div>
        <!-- Filters -->
        <div class="has-background-white rounded-lg pa-md mb-xxs">
          <div class="columns">
            <div class="column">

            </div>
            <div class="column is-narrow">
              <b-field >
                <b-select v-model="orderBy" expanded @input="sort" size="is-medium">
                  <option value="-id">Most Recent</option>
                  <option value="id">Oldest</option>
                </b-select>
              </b-field>
            </div>
            <div v-if="context.isMerchant()" class="column is-narrow">
              <b-button tag="router-link" :to="{ name: 'catalog.import.request.create'}" size="is-medium" class="is-fullwidth" icon-left="upload" >Upload</b-button>
            </div>
          </div>
        </div>
        <!-- Results -->
        <div>
          <div>
            <b-table
              :data="results"
              :mobile-cards="false"
              :loading="loading"
              hoverable
              class="is-size-7">
              <template>
                <!-- ID -->
                <b-table-column
                  label="ID"
                  centered
                  width="110"
                  v-slot="props">
                  <router-link
                    :to="{ name: 'catalog.import.request', params: { id: props.row.id } }"
                    class="has-text-weight-bold has-text-info"
                  >{{ props.row.id }}</router-link>
                </b-table-column>
                <!-- Merchant (Suppliers Only) -->
                <b-table-column
                  v-if="context.isSupplier()"
                  label="Merchant"
                  v-slot="props">
                  {{ props.row.retailer.name }}
                </b-table-column>
                <!-- Supplier (Merchants Only) -->
                <b-table-column
                  v-if="context.isMerchant()"
                  label="Supplier"
                  v-slot="props">
                  {{ props.row.brand.name }}
                </b-table-column>
                <!-- Template -->
                <b-table-column
                  label="Template"
                  v-slot="props">
                  {{ props.row.template.name }}
                </b-table-column>
                <!-- Status -->
                <b-table-column
                  label="Status"
                  cell-class="has-text-weight-bold"
                  centered
                  width="200"
                  v-slot="props">
                  <span v-if="props.row.status === 'approved'" class="has-text-success">Approved</span>
                  <span v-else-if="props.row.status === 'rejected'" class="has-text-grey-light">Declined</span>
                  <span v-else>Pending</span>
                </b-table-column>
              </template>
              <template #empty>
                <div class="py-xxl has-text-grey has-text-centered">
                  {{ (loading) ? "Loading data..." : 'No requests found!' }}
                </div>
              </template>
            </b-table>
          </div>
          <div class="mt-md">
            <b-pagination
              :total="total"
              :current.sync="currentPage"
              :rounded="true"
              :per-page="perPage"
              @change="paginate">
            </b-pagination>
          </div>
        </div>
      </div>
    </div>

    <!-- Feed Request Modal -->
    <b-modal
      :active="detail !== null"
      has-modal-card
      :can-cancel="['escape', 'x']"
      @close="detail = null" >
      <FeedRequestModal :selected="detail" />
    </b-modal>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CdnTransform from '@/mixins/CdnTransform.js'
import Filterable from '@/mixins/Filterable'
import FeedRequestModal from '@/components/Modals/FeedRequest/FeedRequestModal'

export default {
  components: {
    FeedRequestModal
  },
  computed: {
    ...mapGetters(['context']),
    placeholders () {
      return Math.min(15, this.perPage)
    },
    endpoint () {
      return this.context.route + '/feed-requests/'
    }
  },
  data () {
    return {
      key: 'permits',
      selected: [],
      detail: null
    }
  },
  methods: {
    inspectPermit (permit) {
      this.detail = permit
    }
  },
  mixins: [CdnTransform, Filterable],
  mounted () {
    this.load()
  },
  props: []
}
</script>
