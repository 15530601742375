<template>
  <form @submit.prevent="save">
    <div class="columns">
      <div class="column">
        <div class="has-background-white rounded-xl">
          <!-- Loaded -->
          <div class="pa-lg pb-xl">
            <div class="mb-lg">
              <p class="has-text-weight-bold mb-xs">Custom Packing Slip Text</p>
              <div class="has-text-grey is-size-7">
                <p class="mb-md">
                  The default packing slip will include your contact email and phone number.
                  You can specify custom text copy below,
                  and this will be added at the bottom of the default packing slip.
                </p>
              </div>
            </div>
            <div>
              <p class="has-text-weight-bold mb-md"></p>
              <b-field label="Default Custom Packing Slip Text">
                <b-input type="textarea" v-model="value" @input="isDirty = 1"></b-input>
              </b-field>
            </div>
          </div>
          <div class="border-t">
            <div class="columns is-vcentered is-marginless">
              <div class="column is-hidden-mobile">
                <div class="px-sm has-text-grey-light">
                  <i v-if="isDirty">Click 'Save Preference' to save your changes</i>
                </div>
              </div>
              <div class="column is-narrow">
                <div class="px-sm">
                  <b-button
                    native-type="submit"
                    type="is-primary rounded-lg"
                    :disabled="!ready || saving || !isDirty"
                  >Save Preference</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import { br2nl } from '@/helpers/br2nl.js'
import nl2br from 'locutus/php/strings/nl2br'

export default {
  components: {

  },
  computed: {
    ...mapGetters(['app', 'context']),
    endpoint () {
      return this.context.route + '/retailer-preferences/'
    },
    json () {
      return {
        preference: { id: this.preference.id },
        value: nl2br(this.value, false)
      }
    },
    ready () {
      return (
        this.value !== null
      )
    }
  },
  data () {
    return {
      isDirty: false,
      preference: null,
      value: null,
      errors: [],
      saving: false
    }
  },
  methods: {
    save () {
      this.saving = true
      this.$http.post(this.endpoint, this.json).then(response => {
        this.isDirty = false
        this.$buefy.toast.open({ type: 'is-success', message: 'Preference updated!' })
      }).catch(error => {
        if (error.response && error.response.status === 400) {
          this.errors = error.response.data
        }
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    const preference = this.$utils.find(this.preferences, ['code', 'default_packing_slip_bottom_text'])
    if (typeof preference !== 'undefined') {
      this.preference = preference
      if (this.preference.retailer_preference) {
        this.value = br2nl(preference.retailer_preference.value, true)
      }
    }
  },
  props: ['preferences', 'retailerPreferences']
}
</script>
