<template>
  <form @submit.prevent>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Cancel Orders</p>
      </header>
      <section class="modal-card-body">
        <div class="pb-md border-b">
          <b-message type="is-danger">
            <strong>Please Note</strong>: when you click submit, your cancelation
            request will be processed immediately and communications may be sent
            to the retailer to inform them of the cancelation.
            <strong>This action cannot be reversed!</strong>
          </b-message>
          <!-- Reason -->
          <div class="has-background-white-ter pa-md rounded-lg">
            <div class="mb-md">
              <b-field
                label="Reason"
                expanded>
                <b-select v-model="reason" placeholder="Please select a reason..."  expanded>
                  <option value="1">Retailer Requested Cancellation</option>
                  <option value="2">Not Enough Inventory</option>
                  <option value="3">Can't Meet Fulfillment Deadline</option>
                  <option value="4">Product Listing and/or Pricing Error</option>
                  <option value="5">Unfulfillable Address</option>
                  <option value="6">Suspected Fraud</option>
                  <option value="8">Price Discrepancy</option>
                  <option value="9">Product Discontinued</option>
                </b-select>
              </b-field>
            </div>
            <div class="columns is-vcentered">
              <div class="column">
                <p>Are you sure you want to cancel these orders?</p>
              </div>
              <div class="column is-narrow">
                <b-button
                  type="is-primary rounded-lg"
                  @click="acceptAll"
                  :loading="busy"
                  :disabled="busy || valid === 0 || !reason"
                >Cancel All Orders</b-button>
              </div>
            </div>
          </div>
        </div>
        <!-- Results -->
        <div>
          <b-table
            :data="requests"
            :mobile-cards="false"
            hoverable
            class="is-size-7">
            <template>
              <!-- Label -->
              <b-table-column
                label="PO #"
                header-class="nowrap"
                cell-class="nowrap"
                width="128"
                v-slot="props">
                {{ props.row.label() }}
              </b-table-column>
              <!-- Status -->
              <b-table-column
                label="Status"
                header-class="nowrap"
                cell-class="nowrap"
                v-slot="props">
                <p v-if="props.row.status === 'Complete'" class="has-text-success">Complete</p>
                <p v-else-if="props.row.status === 'Skip'" class="has-text-warning">
                  Skip: {{ props.row.errorMessage }}
                </p>
                <p v-else-if="props.row.status === 'Error'" class="has-text-danger">
                  Error: {{ props.row.errorMessage }}
                </p>
                <p v-else>{{ props.row.status }}</p>
              </b-table-column>
              <!-- Progress -->
              <b-table-column
                label=""
                header-class="nowrap"
                cell-class="nowrap"
                width="96"
                v-slot="props">
                <b-progress v-if="props.row.status === 'Pending'" show-value></b-progress>
              </b-table-column>
            </template>
          </b-table>
        </div>
      </section>
      <footer class="modal-card-foot space-between">
        <button class="button" type="button" @click="$parent.close()">Close</button>
        <button class="button" type="button" @click="closeAndReload()">Close & Reload</button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import CancelOrderRequest from '@/helpers/Requests/CancelOrderRequest'
export default {
  components: {

  },
  data () {
    return {
      processing: false,
      requests: [],
      todo: 0,
      skipped: 0,
      success: 0,
      failed: 0,
      reason: null
    }
  },
  computed: {
    ...mapGetters(['context']),
    busy () {
      return this.todo !== this.skipped + this.success + this.failed
    },
    valid () {
      let valid = 0
      this.requests.forEach(request => {
        if (request.isValid) {
          valid++
        }
      })
      return valid
    }
  },
  methods: {
    acceptAll () {
      this.reset()
      this.processing = true
      this.requests.forEach((request, index) => {
        this.todo++
        request.prepare({ reason: this.reason })
        setTimeout(() => {
          request.run().then(response => {
            this.success++
          }).catch(error => {
            if (error) {
              this.failed++
            }
          })
        }, index * 1000)
      })
    },
    reset () {
      this.todo = this.skipped = this.success = this.failed = 0
      this.processing = false
    },
    closeAndReload () {
      this.$emit('reload')
      this.$parent.close()
    }
  },
  mounted () {
    this.orders.forEach(order => {
      this.requests.push(new CancelOrderRequest({
        context: this.context,
        order: order
      }))
    })
  },
  props: ['orders']
}
</script>
