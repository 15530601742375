<template>
  <form @submit.prevent="save">
    <div class="columns">
      <div class="column">
        <div class="has-background-white rounded-lg">
          <!-- Loaded -->
          <div v-if="preferences !== null" class="pa-lg pb-xl">
            <div class="mb-lg">
              <p class="has-text-weight-bold mb-xs">Default Connection Level</p>
              <div class="has-text-grey is-size-7">
                <p>
                  If you plan to onboard suppliers with transactional capabilities
                  (i.e. products, inventory, orders, fulfillments, etc.), select
                  <strong>Transactions + Products</strong>. If you're only using
                  {{ app.productName }} for product onboarding, select <strong>Products Only.</strong>
                </p>
              </div>
            </div>
            <div class="mb-lg">
              <div class="columns">
                <div class="column is-half">
                  <b-field>
                    <b-select
                      type="text"
                      v-model="value"
                      size="is-medium"
                      expanded
                      @input="isDirty = 1">
                      <option v-for="option in options" :key="option.id" :value="option">{{ option.name }}</option>
                    </b-select>
                  </b-field>
                </div>
              </div>
            </div>
          </div>
          <div class="border-t">
            <div class="columns is-vcentered is-marginless">
              <div class="column is-hidden-mobile">
                <div class="px-sm has-text-grey-light">
                  <i v-if="isDirty">Click 'Save Preference' to save your changes</i>
                </div>
              </div>
              <div class="column is-narrow">
                <div class="px-sm">
                  <b-button
                    native-type="submit"
                    type="is-primary rounded-lg"
                    :disabled="!ready || saving || !isDirty"
                  >Save Preference</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  computed: {
    ...mapGetters(['app', 'context']),
    endpoint () {
      return this.context.route + '/retailer-preferences/'
    },
    json () {
      return {
        preference: { id: this.preference.id },
        option: { id: this.value.id }
      }
    },
    ready () {
      return (this.value !== null)
    }
  },
  data () {
    return {
      isDirty: false,
      preference: null,
      options: [],
      value: null,
      errors: [],
      saving: false
    }
  },
  methods: {
    save () {
      this.saving = true
      this.$http.post(this.endpoint, this.json).then(response => {
        this.isDirty = false
        this.$buefy.toast.open({ type: 'is-success', message: 'Preference updated!' })
      }).catch(error => {
        if (error.response && error.response.status === 400) {
          this.errors = error.response.data
        }
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    this.preference = this.$utils.find(this.preferences, ['code', 'default_connection_level'])
    if (typeof this.preference !== 'undefined') {
      // set the options
      this.options = this.preference.options.map(option => {
        return {
          id: option.id,
          name: option.name,
          value: option.value
        }
      })
      if (this.preference.retailer_preference) {
        this.value = this.$utils.find(this.options, ['value', this.preference.retailer_preference.value])
      } else {
        this.value = this.$utils.find(this.options, ['value', 'transactions_and_products'])
      }
    }
  },
  props: ['preferences']
}
</script>
