<template>
  <div>
    <div class="columns">
      <div class="column is-one-fifth">
        <p class="has-text-weight-bold is-size-6 mb-sm">Skip Fraud Order</p>
        <p class="has-text-grey is-size-7">Use this setting to configure order skipping based on Shopify Fraud recommendation.</p>
      </div>
      <div class="column">
        <div class="has-background-white rounded-lg">
          <div class="pa-lg">
            <b-field>
              <b-radio v-model="value" :native-value="'none'" @input="isDirty=1">
                Disabled - all orders will be imported to {{ app.platform }} regardless of Shopify's fraud recommendation
              </b-radio>
            </b-field>
            <b-field>
              <b-radio v-model="value" :native-value="'medium'" @input="isDirty=1">
                Enabled: Medium - orders that are flagged with high or medium fraud risk will NOT be automatically imported to {{ app.platform }}
              </b-radio>
            </b-field>
            <b-field>
              <b-radio v-model="value" :native-value="'high'" @input="isDirty=1">
                Enabled: High - orders that are flagged with high fraud risk will not be automatically imported to {{ app.platform }}
              </b-radio>
            </b-field>
          </div>
          <div class="border-t">
            <div class="columns is-vcentered is-marginless">
              <div class="column is-hidden-mobile">
                <div class="px-sm has-text-grey-light">
                  <i v-if="isDirty">Click 'Save Preference' to save your changes</i>
                </div>
              </div>
              <div class="column is-narrow">
                <div class="px-sm">
                  <b-button
                    native-type="submit"
                    type="is-primary rounded-lg"
                    :disabled="saving || !isDirty || value === null"
                    @click="save"
                  >Save Preference</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['app', 'context']),
    endpoint () {
      return this.context.route + '/platform-accounts/' + this.account.id + '/'
    },
    credential () {
      if (this.account.credentials.length === 0) return null
      let found = null
      this.account.credentials.forEach(credential => {
        Object.keys(credential).forEach(key => {
          if (key === 'order_risk_skipping_policy') {
            found = credential[key].values[0]
          }
        })
      })
      return found
    },
    json () {
      const json = {
        credentials: [{
          credential: {
            code: 'order_risk_skipping_policy'
          },
          value: this.value
        }]
      }
      if (this.credential !== null) {
        json.credentials[0].id = this.credential.id
      }
      return json
    }
  },
  data () {
    return {
      saving: false,
      errors: {},
      isDirty: false,
      value: null
    }
  },
  methods: {
    save () {
      this.saving = true
      this.$http.patch(this.endpoint, this.json).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Preference saved!' })
        this.$emit('update:account')
      }).finally(() => {
        this.saving = false
        this.isDirty = false
      })
    }
  },
  mounted () {
    this.account.credentials.forEach(credential => {
      Object.keys(credential).forEach(key => {
        if (key === 'order_risk_skipping_policy' && credential[key].values[0].value) {
          this.value = credential[key].values[0].value
        }
      })
    })
  },
  props: ['account']
}
</script>
