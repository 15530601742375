<template>
<div class="modal-card">
  <section class="modal-card-body">
    <p class="has-text-weight-bold is-size-4 mb-md">Supplier Events</p>
    <div v-if="connections.length">
      <div v-for="(connection) in connections" :key="connection.id" class="has-background-light pa-md rounded-lg mb-lg">
        <div class="columns is-vcentered is-mobile">
          <div class="column is-narrow">
            <div class="px-sm">
              <span class="has-text-info is-size-1">&bull;</span>
            </div>
          </div>
          <div class="column">
            <p class="is-uppercase has-text-warning has-text-weight-bold is-size-8">
              <span v-if="eventType === 'launches'">Supplier Launch</span>
              <span v-if="eventType === 'intros'">Supplier Activation Call &amp; Demo</span>
            </p>
            <p class="is-size-5 has-text-weight-bold">
              {{ connection.brand.name }}
              <span v-if="eventType === 'intros' && connection.intro_call_date" class="is-size-7">
                - {{ $dates(connection.intro_call_date).format('MMM D, YYYY') }}
              </span>
            </p>
            <p>
              <router-link class="has-text-info" :to="{ name: 'connection.detail', params: {id: connection.id }}">Settings</router-link>
              &bull;
              <router-link class="has-text-info" :to="{ name: 'connection.detail.onboarding', params: {id: connection.id }}">Onboarding Progress</router-link>
            </p>
          </div>
          <div class="column is-narrow">
            <div class="has-text-right">
              <p class="has-text-grey-light">Target Launch</p>
              <p class="has-text-weight-bold">{{ $dates(connection.onboarding_target_date).format('MMM D, YYYY') }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <p class="has-text-grey-light">
        No suppliers found
      </p>
    </div>
  </section>
  <footer class="modal-card-foot">
    <button class="button" type="button" @click="$parent.close()">Close</button>
  </footer>
</div>
</template>

<script>
export default {
  props: {
    connections: {
      type: Array,
      required: true
    },
    eventType: {
      type: String,
      required: true
    }
  }
}
</script>
