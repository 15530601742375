<template>
  <div>
    <form @submit.prevent="save">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Update Settings</p>
        </header>
        <section class="modal-card-body">
          <!-- General Errors -->
          <div v-if="error" class="mb-md">
            <b-message type="is-danger">{{ error }}</b-message>
          </div>
          <!-- Supplier # -->
          <b-field label="Supplier #">
            <b-input
              autocomplete="off"
              type="text"
              v-model="retailerIdentifier"
              size="is-medium"
            ></b-input>
          </b-field>
          <!-- Connection Level -->
          <b-field label="Connection Level">
            <b-select v-model="connectionLevel" expanded size="is-medium">
              <option value="transactions_and_products">Transactions &amp; Products</option>
              <option value="products_only">Products Only</option>
            </b-select>
          </b-field>
          <!-- Integration -->
          <b-field label="Integration">
            <b-select v-model="integrationType" expanded size="is-medium">
              <option value="edi">EDI</option>
              <option value="api">API</option>
              <option value="shopify">Shopify</option>
              <option value="shipstation">ShipStation</option>
              <option value="console">Console</option>
              <option value="console-print-labels">Console (Print Labels)</option>
              <option value="console-register-tracking-numbers">Console (Register Tracking #s)</option>
            </b-select>
          </b-field>
          <!-- Default Catalog Import Method -->
          <b-field label="Catalog Import Method">
            <b-select v-model="importType" expanded size="is-medium">
              <option value="import_request">Import Request</option>
              <option value="proposals">Proposals</option>
            </b-select>
          </b-field>
          <!-- Onboarding Target Date -->
          <b-field v-if="connection.status === 'onboarding'" label="Target Launch Date">
            <b-input
              type="date"
              :min="minLaunchDate"
              v-model="onboardingTargetDate"
              size="is-medium"
            ></b-input>
          </b-field>
          <!-- Activation Call Date -->
          <b-field v-if="connection.status === 'onboarding'" label="Activation Call">
            <b-input
              type="date"
              :min="minLaunchDate"
              v-model="introCallDate"
              size="is-medium"
            ></b-input>
          </b-field>
        </section>
        <footer class="modal-card-foot space-between">
          <b-button @click="$parent.close()">Close</b-button>
          <b-button
            type="is-primary"
            native-type="submit"
            :loading="saving"
            :disabled="saving">
            Save Settings
          </b-button>
        </footer>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  data () {
    return {
      retailerIdentifier: null,
      connectionLevel: null,
      integrationType: null,
      importType: null,
      costMethod: null,
      commissionPercent: null,
      paymentTerms: null,
      paymentModel: null,
      fulfillmentSla: '2',
      onboardingTargetDate: null,
      introCallDate: null,
      saving: false,
      error: null,
      errors: {}
    }
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/connections/' + this.connection.id + '/'
    },
    json () {
      const body = {
        integration_type: this.integrationType,
        retailer_identifier: this.retailerIdentifier,
        level: this.connectionLevel
      }
      // either one import type can be active
      if (this.importType === 'proposals') {
        body.connection_options = {
          merchandise_via_proposals: true,
          merchandise_via_import_request: false
        }
      }
      if (this.importType === 'import_request') {
        body.connection_options = {
          merchandise_via_import_request: true,
          merchandise_via_proposals: false
        }
      }
      if (this.connection.status === 'onboarding' && this.onboardingTargetDate) {
        const date = this.$dates(this.onboardingTargetDate)
        if (date.isValid()) {
          body.onboarding_target_date = date.format('YYYY-MM-DD') + 'T10:00'
        }
      }
      if (this.connection.status === 'onboarding' && this.introCallDate) {
        const date = this.$dates(this.introCallDate)
        if (date.isValid()) {
          body.intro_call_date = date.format('YYYY-MM-DD') + 'T10:00'
        }
      }
      return body
    },
    minLaunchDate () {
      return this.$dates().format('YYYY-MM-DD')
    }
  },
  methods: {
    save () {
      this.saving = true
      this.$http.patch(this.endpoint, this.json).then(response => {
        this.$emit('saved:connection')
        this.$buefy.toast.open({ type: 'is-success', message: 'Settings updated!' })
        this.$parent.close()
      }).catch(error => {
        if (error.response && 'non_field_errors' in error.response.data) {
          this.error = error.response.data.non_field_errors[0]
        }
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    if (this.connection.onboarding_target_date) {
      this.onboardingTargetDate = this.$dates(this.connection.onboarding_target_date).format('YYYY-MM-DD')
    }
    if (this.connection.intro_call_date) {
      this.introCallDate = this.$dates(this.connection.intro_call_date).format('YYYY-MM-DD')
    }
    if (this.connection.options) {
      const isProposal = this.$utils.find(this.connection.options, ['code', 'merchandise_via_proposals'])
      const isImportRequest = this.$utils.find(this.connection.options, ['code', 'merchandise_via_import_request'])
      this.importType = isProposal ? 'proposals' : isImportRequest ? 'import_request' : null
    }
    this.connectionLevel = this.connection.level
    this.retailerIdentifier = this.connection.retailer_identifier
    // compensate for bug in API (MKP-17)
    if (this.connection.integration_type === 'console-register-tracking-number') {
      this.integrationType = 'console-register-tracking-numbers'
    } else if (this.connection.integration_type) {
      this.integrationType = this.connection.integration_type
    } else {
      this.integrationType = 'console'
    }
  },
  props: ['connection']
}
</script>
