<template>
  <form @submit.prevent="syncItem">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Configure Shopify Variant</p>
      </header>
      <section class="modal-card-body">
        <div v-if="!loading && item !== null">
          <!-- Variant Details -->
          <div>
            <p class="mb-sm"><strong>Item Details</strong></p>
            <table class="table is-fullwidth is-striped is-size-7 is-narrow border mb-xl">
              <tbody>
                <tr>
                  <th>{{ app.platform }} Item ID</th>
                  <td class="has-text-right">{{ item.id }}</td>
                </tr>
                <tr>
                  <th>{{ app.platform }} SKU</th>
                  <td class="has-text-right">{{ item.brand_identifier }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-if="suggestedIdentifiers.length > 0">
            <p class="mb-sm"><strong>Suggested Products from Shopify</strong></p>
            <table class="table is-fullwidth is-striped is-size-7 is-narrow border is-vcentered mb-xl">
              <thead>
                <tr>
                  <th></th>
                  <th>Name</th>
                  <th>Variant ID</th>
                  <th>SKU</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in suggestedIdentifiers" :key="item.legacyResourceId">
                  <td class="has-text-centered pl-lg">
                    <b-radio v-model="selectedItem" :native-value="item" @input="announceSelectedItem"></b-radio>
                  </td>
                  <td>
                    <span v-if="item.product">{{ item.product.title }}</span>
                    <p class="is-size-8 has-text-grey">{{ item.title }}</p>
                  </td>
                  <td>{{ item.legacyResourceId }}</td>
                  <td>{{ item.sku }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- New Identifier -->
          <div>
            <b-field v-if="suggestedIdentifiers.length > 0">
              <b-checkbox v-model="isNewIdentifier" @input="announceCheckbox">Don't see your item in the list?</b-checkbox>
            </b-field>
            <b-field v-if="isNewIdentifier" label="Preferred Shopify Variant ID">
              <b-input
                type="text"
                name="identifier"
                v-model="identifier"
                placeholder="Enter new Identifier here..."
                size="is-medium"
              ></b-input>
            </b-field>
          </div>
        </div>
        <div v-else>
          <loading message="Loading current item details..." />
        </div>
      </section>
      <footer class="modal-card-foot space-between">
        <b-button @click="$parent.close()">Close</b-button>
          <b-button
            native-type="submit"
            type="is-primary"
            :disabled="saving || !ready"
            :loading="saving"
          >Save Variant Mapping</b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  data () {
    return {
      loading: true,
      identifier: null,
      saving: false,
      errors: {},
      suggestedIdentifiers: [],
      selectedItem: null,
      isNewIdentifier: false,
      productId: null
    }
  },
  computed: {
    ...mapGetters(['context', 'app']),
    endpoint () {
      return this.context.route + '/variants/' + this.item.id + '/platform-account-identifiers/'
    },
    json () {
      return {
        platform_account_id: this.shopifyAccount.id,
        identifier: this.identifier,
        product_id: this.productId
      }
    },
    ready () {
      return (this.identifier)
    }
  },
  methods: {
    syncItem () {
      this.saving = true
      this.$http.put(this.endpoint, this.json).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Item SKU synced!' })
        this.$emit('item:updated')
        this.$parent.close()
      }).finally(() => {
        this.saving = false
      })
    },
    shopifyVariantLookup () {
      const variantLookupEndpoint = this.context.route + '/shopify/' + this.shopifyAccount.id + '/variants/' + this.item.id + '/variant-lookup/'
      this.$http.get(variantLookupEndpoint).then(response => {
        const results = response.data
        const uniqueResults = [...new Map(results.map(variant => [JSON.stringify(variant), variant])).values()]
        this.suggestedIdentifiers = uniqueResults
        if (results.length === 0) {
          this.isNewIdentifier = true
        }
      }).finally(() => {
        this.loading = false
      })
    },
    announceSelectedItem () {
      if (this.selectedItem) {
        this.identifier = this.selectedItem.legacyResourceId
        // id format - gid://shopify/Product/8686832550198
        const productId = this.selectedItem.product.id
        const parts = productId.split('/')
        const lastValue = parts[parts.length - 1]
        this.productId = lastValue
        this.isNewIdentifier = false
      }
    },
    announceCheckbox () {
      this.selectedItem = null
      this.identifier = null
    }
  },
  mounted () {
    this.shopifyVariantLookup()
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    shopifyAccount: {
      type: Object,
      required: true,
      default: null
    }
  }
}
</script>
