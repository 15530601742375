<template>
  <form>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Custom Packing Slip Text</p>
      </header>
      <section class="modal-card-body">
        <b-message type="is-info" v-if="context.isMerchant()">
          {{ infoMessage }}
          <router-link :to="{ name: 'connection.detail.credentials' }">attributes page.</router-link>
        </b-message>
        <b-field>
          <b-input
            type="textarea"
            :loading="loading"
            v-model="value"
            :readonly="true"
          ></b-input>
        </b-field>
      </section>
      <footer class="modal-card-foot is-justify-content-right">
        <b-button @click="$parent.close()">Close</b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import { br2nl } from '@/helpers/br2nl.js'

export default {
  components: {

  },
  data () {
    return {
      loading: false,
      value: null
    }
  },
  computed: {
    ...mapGetters(['context']),
    readOnly () {
      return this.context.isSupplier()
    },
    endpoint () {
      return this.context.route + '/preferences/?grouping=onboarding'
    },
    infoMessage () {
      let message = `This text is powered by ${this.context.partner.name}'s default preferences, if you wish to edit copy for this connection,
      please visit the `
      if (this.copy) {
        message = 'This text is powered by connection attributes, if you wish to remove or edit please visit the '
      }
      return message
    }
  },
  methods: {
    loadRetailerPreferences () {
      this.loading = true
      this.$http.get(this.endpoint).then(({ data }) => {
        const defaultPreference = this.$utils.find(data.results, ['code', 'default_packing_slip_bottom_text'])
        if (typeof defaultPreference !== 'undefined') {
          if (defaultPreference.retailer_preference) {
            this.value = br2nl(defaultPreference.retailer_preference.value, true)
          }
        }
      }).finally(() => {
        this.loading = false
      })
    }
  },
  mounted () {
    if (this.copy) {
      this.value = this.copy ? br2nl(this.copy, true) : null
    } else if (this.context.isMerchant()) {
      this.loadRetailerPreferences()
    }
  },
  props: ['copy']
}
</script>
