<template>
  <span>{{ numShipped }} of {{ numOrdered }} {{ (numOrdered === 1) ? 'Item' : 'Items' }}</span>
</template>
<script>
export default {
  computed: {
    numOrdered () {
      let items = 0
      this.lines.forEach(line => {
        items += (line.quantity - line.quantity_canceled)
      })
      return items
    },
    numShipped () {
      let items = 0
      this.lines.forEach(line => {
        items += line.quantity_shipped
      })
      return items
    }
  },
  props: {
    lines: {
      type: Array,
      required: true
    }
  }
}
</script>
