<template>
<div>
  <div class="section">
    <div class="container">
      <!-- Page Header -->
      <div class="columns is-vcentered">
        <div class="column is-one-third">
          <breadcrumbs :history="[{ label: 'Settings', name: 'settings'}]" current="Templates" :hideBack="true" />
        </div>
        <div class="column is-one-third">
          <h1 class="has-text-centered is-size-4">Templates</h1>
        </div>
      </div>
      <!-- Info banner -->
      <!-- For the time being, we are disabling the info banner because this task has not been added to the retailer onboarding process. -->
      <!-- <div v-if="context.isMerchant() && !context.partner.isOnboarded">
        <InfoBanner bannerInfo="custom" page="templates"/>
      </div> -->
      <!-- Filters -->
      <div class="has-background-white rounded-lg pa-md mb-xxs">
        <div class="columns">
          <div class="column">
            <TemplateFilters @filter="filter" :applied-filters="filters" />
          </div>
          <div class="column is-narrow">
            <b-field >
              <b-select v-model="orderBy" expanded @input="sort" size="is-medium">
                <option value="-id">Most Recent</option>
                <option value="id">Oldest</option>
              </b-select>
            </b-field>
          </div>
          <div class="column is-narrow">
            <router-link :to="{ name: 'template.create' }" class="button is-primary is-medium is-outlined">Create Template</router-link>
          </div>
          <!-- Hiding feature for now -->
          <div v-if="context.isMerchant()" class="column is-narrow">
            <b-dropdown expanded :disabled="loadingPlatforms">
              <b-button expanded slot="trigger" size="is-medium">
                <b-icon icon="dots-vertical"></b-icon>
                <span>Manage</span>
              </b-button>
              <b-dropdown-item v-for="(action, index) in moreActions" :key="index">
                <router-link :to="{ name: action.pathName, params: { id: action.id }}">{{ action.label }}</router-link>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </div>
      <!-- Toolbar -->
      <div class="has-background-white rounded-lg px-sm mb-xxs">
        <div class="columns is-vcentered is-mobile is-marginless is-paddingless">
          <!-- Dropdown -->
          <div class="column">
            <div class="has-text-right is-size-7">
              <!-- Counts -->
              <p v-if="loading">Loading...</p>
              <p v-else>{{ editableTemplates.length }} {{ (editableTemplates.length === 1) ? 'record' : 'records' }} found.</p>
            </div>
          </div>
        </div>
      </div>
      <!-- Table -->
      <div>
        <b-table
          :data="editableTemplates"
          :checked-rows.sync="selected"
          :mobile-cards="false"
          :loading="loading"
          hoverable
          class="is-size-7">
          <template>
            <!-- ID -->
            <b-table-column
              label="ID"
              width="64"
              v-slot="props">
              <p class="has-text-grey-light">{{ props.row.id }}</p>
            </b-table-column>
            <!-- Template -->
            <b-table-column
              label="Template Name"
              cell-class="nowrap"
              v-slot="props">
              <div>
                <router-link class="has-text-weight-bold has-text-info" :to="{ name: 'template', params: { id: props.row.id }}">
                  {{ props.row.name }}
                </router-link>
              </div>
              <div class="has-text-grey-light">
                <p v-if="props.row.retailer">{{ props.row.retailer.name }}</p>
                <p v-else-if="props.row.brand">{{ props.row.brand.name }}</p>
                <p v-else>{{ props.row.brand.name }}</p>
              </div>
            </b-table-column>
            <!-- Datatype -->
            <b-table-column
              label="Data Type"
              width="128"
              centered
              v-slot="props">
              <p>{{ $utils.upperFirst(props.row.data_type) }}</p>
            </b-table-column>
            <!-- Direction -->
            <b-table-column
              label="Direction"
              width="128"
              centered
              v-slot="props">
              <p>{{ $utils.upperFirst(props.row.direction) }}</p>
            </b-table-column>
            <!-- Column Mappings -->
            <b-table-column
              label="Mappings"
              width="128"
              centered
              v-slot="props">
              <p>{{ props.row.mapping_field_count }}</p>
            </b-table-column>
          </template>
          <template #empty>
            <div class="py-xxl has-text-grey has-text-centered">
              {{ (loading) ? "Loading data..." : 'No records found!' }}
            </div>
          </template>
        </b-table>
      </div>
      <div class="mt-md">
        <b-pagination
          :total="editableTemplates.length"
          :current.sync="currentPage"
          :rounded="true"
          :per-page="perPage"
          @change="paginate">
        </b-pagination>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Filterable from '@/mixins/Filterable'
// import InfoBanner from '@/components/Global/InfoBanner'
import TemplateFilters from '../components/Templates/TemplateFilters'

export default {
  components: {
    TemplateFilters
    // InfoBanner
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/templates/'
    },
    editableTemplates () {
      const templates = []
      if (!this.loading && this.results.length > 0) {
        this.results.forEach(template => {
          if (this.context.isMerchant() && template.retailer && template.retailer.id === this.context.partner.id) {
            templates.push(template)
          }
          if (this.context.isSupplier() && template.brand && template.brand.id === this.context.partner.id) {
            templates.push(template)
          }
        })
      }
      return templates
    },
    moreActions () {
      const moreActions = []
      this.platforms.map(el => moreActions.push({
        label: `${el.platform.name} Templates`,
        pathName: `${el.platform.code}.templates`,
        id: el.id
      }))
      return moreActions
    }
  },
  data () {
    return {
      key: 'templates',
      alwaysAttach: {
        exclude_ownerless: 1
      },
      selected: [],
      platforms: [],
      loadingPlatforms: false
    }
  },
  methods: {
    loadPlatforms () {
      this.loadingPlatforms = true
      const endpoint = this.context.route + '/platform-accounts/'
      this.$http.get(endpoint).then(response => {
        this.platforms = response.data.results
        this.loadingPlatforms = false
      })
    }
  },
  mixins: [Filterable],
  mounted () {
    this.load()
    if (this.context.isMerchant()) {
      this.loadPlatforms()
    }
  }
}
</script>
