<template>
  <div>
    <div class="section">
      <div class="container">
        <div class="has-text-centered">
          <p class="mb-xs">
            <Logo color="black" />
          </p>
          <p class="is-size-4 mb-lg">Shopify {{ (context.type === 'vendor') ? 'Supplier' : 'Merchant' }} Onboarding</p>
        </div>
        <div class="columns is-centered">
          <div class="column is-four-fifths- is-two-thirds-desktop">
            <b-steps v-model="currentStep" :has-navigation="false">
              <b-step-item label="Account" icon="account" :clickable="false"></b-step-item>
              <b-step-item label="Plan" icon="cards-variant" :clickable="false"></b-step-item>
              <!-- Supplier Specific -->
              <b-step-item v-if="context.type === 'vendor'" label="Inventory" icon="format-list-numbered" :clickable="false"></b-step-item>
              <b-step-item v-if="context.type === 'vendor'" label="Products" icon="tag" :clickable="false"></b-step-item>
              <b-step-item v-if="context.type === 'vendor'" label="Orders" icon="settings" :clickable="false"></b-step-item>
              <!-- Merchant Specific -->
              <b-step-item v-if="context.type !== 'vendor'" label="Integration" icon="settings" :clickable="false"></b-step-item>
            </b-steps>
          </div>
        </div>
        <div class="columns is-centered">
          <div class="column is-two-thirds-desktop">
            <div class="has-background-white pa-xl rounded-lg mb-xxl">
              <!-- Platform Account Loaded -->
              <div v-if="!loading && account !== null">
                <transition name="fade" mode="out-in">
                  <router-view :account="account" @update:step="updateStep" @update:account="updateAccount" />
                </transition>
              </div>
              <!-- Platform Account Not Found -->
              <div v-else-if="!loading && account === null">
                Hmm. Couldn't find shopify account.
              </div>
              <div v-else>
                <loading message="Loading account..." />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Logo from '@/components/Logos/Logo'

export default {
  components: {
    Logo
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/platform-accounts/?platform=shopify'
    }
  },
  data () {
    return {
      loading: true,
      account: null,
      currentStep: 0
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint).then(response => {
        if (response.data.results.length === 1) {
          this.account = response.data.results[0]
        }
        this.loading = false
      })
    },
    refresh () {
      this.$http.get(this.endpoint).then(response => {
        if (response.data.results.length === 1) {
          this.account = Object.assign({}, this.account, response.data.results[0])
        }
      })
    },
    updateStep (index) {
      this.currentStep = index
    },
    updateAccount () {
      this.refresh()
    }
  },
  mounted () {
    this.load()
  },
  props: []
}
</script>
