<template>
  <div class="has-background-white rounded-lg">
    <!-- Loading -->
    <div v-if="loading">
      <loading message="Loading Rule Group..." />
    </div>
    <!-- Loaded -->
    <div v-else>
      <div class="pa-lg">
        <div v-if="errors && 'non_field_errors' in errors" class="mb-lg">
          <b-message type="is-danger">{{ errors.non_field_errors[0] }}</b-message>
        </div>
        <RulesetForm v-model="json" :formFields="ruleGroup" :showPosition="true" @is:ready="isReady"/>
      </div>
      <div class="border-t" />
      <div class="columns is-vcentered is-marginless">
        <div class="column is-hidden-mobile">
          <div class="px-sm has-text-grey-light">
            <i v-if="isDirty">Click 'Update' to save your changes</i>
          </div>
        </div>
        <div class="column is-narrow ">
          <div class="px-sm">
            <b-button
              type="is-primary rounded-lg"
              class="is-pulled-right"
              :disabled="saving || !isDirty"
              :loading="saving"
              @click="update"
            >Update
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import RulesetForm from '@/components/Models/Ruleset/Form'
export default {
  components: {
    RulesetForm
  },
  computed: {
    ...mapGetters(['app', 'api', 'context']),
    endpoint () {
      return this.api.baseUrl + `/v1/rule-groups/${this.$route.params.id}/`
    },
    body () {
      const data = {
        name: this.json.name,
        position: this.json.position
      }
      if (this.json.description !== null) {
        data.description = this.json.description
      }
      return data
    }
  },
  data: () => {
    return {
      isDirty: false,
      saving: false,
      json: null,
      errors: {}
    }
  },
  methods: {
    update () {
      this.saving = true
      this.errors = {}
      this.$http.put(this.endpoint, this.body).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Rule Group updated successfully' })
      }).catch(error => {
        if (error.response && error.response.status === 400) {
          this.errors = error.response.data
        } else {
          this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
        }
      }).finally(() => {
        this.saving = false
      })
    },
    isReady (val) {
      this.isDirty = val
    }
  },
  mounted () {
    this.json = this.ruleGroup
  },
  props: {
    loading: {
      type: Boolean,
      required: true
    },
    ruleGroup: {
      type: Object
    }
  }
}
</script>
