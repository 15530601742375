<template>
  <div>
    <div class="columns">
      <div class="column is-one-fifth">
        <p class="has-text-weight-bold is-size-6 mb-sm">Retry Order</p>
        <p class="has-text-grey is-size-7">Attempt to retry an order import.</p>
      </div>
      <div class="column">
        <div class="has-background-white rounded-lg">
          <div class="pa-lg">
            <!-- Intro Text (Merchant) -->
            <div class="is-size-7 mb-lg">
              <p class="mb-md">
                If an order didn't import into {{ app.platform }}, its likely there
                was a problem routing the order to the correct supplier. If you
                have doubled checked the SKUs and the Shopify mappings, you
                can attempt to import the order again by supplying the
                Shopify Order Number.
              </p>
            </div>
            <!-- Form -->
            <form @submit.prevent="retryOrder" class="mb-lg">
              <p class="has-text-weight-bold mb-sm">Shopify Order Number</p>
              <div class="mb-lg">
                <div class="columns">
                  <div class="column">
                    <b-field>
                      <b-input
                        autocomplete="off"
                        type="number"
                        size="is-medium"
                        v-model="shopifyOrderNumber"
                      ></b-input>
                    </b-field>
                  </div>
                  <div class="column is-narrow">
                    <b-button
                      type="is-primary"
                      native-type="submit"
                      size="is-medium"
                      :loading="searching || processing"
                      :disabled="searching || processing || !valid"
                    >Retry Order</b-button>
                  </div>
                </div>
              </div>
              <!-- Override Fraud -->
              <div>
                <b-checkbox v-model="ignoreFraud" :native-value="true">
                  Ignore Shopify's fraud risk recommendation
                </b-checkbox>
              </div>
            </form>
            <!-- Job Result -->
            <div class="is-size-7">
              <div v-if="searching">
                <p class="has-text-grey-light is-italic">Searching for order...</p>
              </div>
              <div v-else-if="processing">
                <p class="has-text-grey-light is-italic">Scheduling retry...</p>
              </div>
              <div v-else-if="errorMessage !== null">
                <p class="has-text-danger is-italic">{{ errorMessage }}</p>
              </div>
              <div v-else-if="job !== null">
                <span class="has-text-weight-bold has-text-success">Retry Scheduled!</span>
                Your input resulted in scheduled job <a href="#" class="has-text-info" @click.prevent="inspectJob = job">#{{ job.id }}</a>.
                Please allow 5-10 minutes this task to complete. Please refer to the job status for more information.
              </div>
              <div v-else>
                <p class="has-text-grey-light is-italic">Enter a Shopify Order Number above and click 'Retry Order'.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Inspect Job -->
    <b-modal
      v-if="(inspectJob !== null)"
      has-modal-card
      :active="(inspectJob !== null)"
      :can-cancel="['escape', 'x']"
      @close="inspectJob = null"
      ><JobModal :selectedJob="inspectJob" />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import JobModal from '@/components/Modals/Job/JobModal'
export default {
  components: {
    JobModal
  },
  computed: {
    ...mapGetters(['app', 'context']),
    endpoint () {
      return this.context.route + '/shopify/' + this.account.id
    },
    queryString () {
      if (!this.shopifyOrderNumber) return null
      return {
        params: {
          order_number: this.shopifyOrderNumber
        }
      }
    },
    json () {
      return {
        shopify_order_id: parseInt(this.shopifyOrderId),
        ignore_order_risk: (this.ignoreFraud) ? 1 : 0
      }
    },
    valid () {
      return (parseInt(this.shopifyOrderNumber) > 0)
    }
  },
  data () {
    return {
      shopifyOrderNumber: null,
      shopifyOrderId: null,
      ignoreFraud: true,
      searching: false,
      processing: false,
      job: null,
      inspectJob: null,
      errorMessage: null
    }
  },
  methods: {
    reset () {
      this.searching = false
      this.processing = false
      this.shopifyOrderId = null
      this.errorMessage = null
      this.job = null
    },
    retryOrder () {
      // look for order first...
      this.reset()
      this.searching = true
      this.$http.get(this.endpoint + '/orders/', this.queryString).then(response => {
        // if we have exactly 1 result, attempt to retry the order using the Shopify Order ID
        if (response.data.length === 1) {
          this.shopifyOrderId = response.data[0].id
          this.searching = false
          this.processing = true
          this.$http.put(this.endpoint + '/retry-order/', this.json).then(response => {
            this.$buefy.toast.open({ type: 'is-success', message: 'Order import scheduled!' })
            this.shopifyOrderId = null
            this.shopifyOrderNumber = null
            this.job = response.data
          }).catch(error => {
            this.$buefy.toast.open({ type: 'is-danger', message: 'Order Import failed!' })
            if (error.response.status === 400 && error.response.data === 'Invalid Request') {
              this.errorMessage = 'An error occurred while attempting to import your order. It is likely that the order Number you supplied is not correct. Please double check your Shopify Order Number and try again.'
            }
          }).finally(() => {
            this.processing = false
          })
        // no order could be found
        } else {
          this.errorMessage = 'Shopify order number not found! Please check your order number and try again.'
        }
      }).finally(() => {
        this.searching = false
      })
    }
  },
  mounted () {

  },
  props: ['account']
}
</script>
