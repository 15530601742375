<template>
  <div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Add Issue</p>
      </header>
      <section class="modal-card-body">
        <b-field label="Issue Title"
          :type="('title' in errors) ? 'is-danger' : ''"
          :message="('title' in errors) ? errors['title'][0] : ''"
          expanded>
          <b-input
            autocomplete="off"
            type="text"
            name="title"
            v-model="title"
            @input="isDirty = true"/>
        </b-field>
        <!-- Description Value -->
        <b-field label="Issue">
          <b-input type="textarea"
            v-model="message"
            maxlength="2048"
            expanded
            @input="isDirty = true"/>
        </b-field>
        <p class="has-text-grey-light">{{this.variants.length}} item will be added when this issue is created.</p>
      </section>
      <footer class="modal-card-foot space-between" >
        <b-button @click="$parent.close()">Close</b-button>
        <b-button
          type="is-primary"
          :disabled="saving || !isReady"
          :loading="saving"
          @click="save">Add Issue</b-button>
      </footer>
    </div>
  </div>
  </template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['context']),
    json () {
      const json = {
        title: this.title,
        message: this.message,
        proposal_variants: []
      }
      this.variants.forEach(variant => {
        json.proposal_variants.push({ id: variant.proposal_variant.id })
      })
      return json
    },
    isReady () {
      return (
        this.title !== null &&
        this.title.length &&
        this.message !== null &&
        this.message.length &&
        this.isDirty
      )
    }
  },
  data () {
    return {
      title: null,
      message: null,
      saving: false,
      errors: {},
      isDirty: false
    }
  },
  methods: {
    save () {
      this.saving = true
      const endpoint = this.context.route + '/proposals/' + this.proposal.id + '/issues/'
      this.$http.post(endpoint, this.json).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Issue created successfully!' })
        this.$emit('issue:created')
        this.$parent.close()
      }).catch((error) => {
        this.errors = error.response.data
      }).finally(() => {
        this.saving = false
      })
    }
  },
  props: {
    proposal: {
      type: Object,
      required: true
    },
    variants: {
      type: Array,
      required: true
    }
  }
}
</script>
