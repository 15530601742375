<template>
  <div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Print Pick Tickets</p>
      </header>
      <section class="modal-card-body">
        <!-- General Status Message -->
        <b-message :type="status.messageType">{{ status.message }}</b-message>
        <object
          v-if="!loading && file !== null"
          :data="file.file"
          type="application/pdf"
          typemustmatch="true"
          style="width:100%; height:500px"
        ></object>
      </section>
      <footer class="modal-card-foot space-between">
        <button class="button" type="button" @click="$parent.close()">Close</button>
        <a v-if="!loading && file !== null"
          class="button is-primary"
          :href="file.file"
          target="_blank">
          Open In New Tab
        </a>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  data () {
    return {
      loading: true,
      file: null,
      numTickets: null,
      missingOrders: [],
      errorMessage: null
    }
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/orders/picklists/'
    },
    json () {
      const orders = []
      this.orders.forEach(order => {
        orders.push({
          order_id: order.id
        })
      })
      return orders
    },
    missingOrdersMessage () {
      const orders = []
      this.missingOrders.forEach(order => {
        orders.push(order.order_id)
      })
      if (orders.length > 0) {
        const orderString = orders.join(', ')
        return (orders.length === 1)
          ? '1 order did not have a pick ticket and was excluded (Order ID: ' + orderString + ')'
          : orders.length + ' orders did not have pick tickets and were excluded (IDs: ' + orderString + ')'
      }
      return null
    },
    status () {
      const status = {
        message: 'Loading pick tickets...',
        messageType: 'is-info'
      }
      // if (this.errorMessage !== null) {
      //   status.message = this.errorMessage
      //   status.messageType = 'is-danger'
      // // happy path
      if (!this.loading && this.file !== null && this.numTickets > 0) {
        status.message = (this.numTickets === 1)
          ? 'Pick tickets are ready to print'
          : this.numTickets + ' pick tickets are ready to print'
      }
      return status
    }
  },
  methods: {
    fetchPickTickets () {
      this.loading = true
      this.$http.post(this.endpoint, this.json).then(response => {
        // if server returns a file...
        if (response.data.file_data) {
          this.file = response.data.file_data
        // if server does not return a file, it's most likely because there are no
        // shipments at all on the requested orders
        } else {
          this.errorMessage = 'Orders are missing pick tickets.'
        }
        this.numTickets = response.data.total_pick_lists
        this.missingOrders = response.data.orders_missing
      }).catch(error => {
        if (error.response && error.response.status === 400) {
          this.errorMessage = error.response.data[0]
        }
      }).finally(() => {
        this.loading = false
      })
    }
  },
  mounted () {
    this.fetchPickTickets()
  },
  props: {
    orders: {
      type: Array,
      required: true
    }
  }
}
</script>
