<template>
  <div>
    <!-- Actions -->
    <div class="has-background-white rounded-lg py-md px-lg">
      <SupplierToolbar
        v-if="context.isSupplier()"
        :order="order"
        @action:invoice="showEditInvoiceModal = true"
        @action:freight="showFreightModal = true"
        @action:cancel="showCancelModal = true"
        @action:close="showCloseOrderModal = true"
        @order:updated="$emit('order:updated')"
        @action:return="showEditRmaModal = true">
      </SupplierToolbar>
      <MerchantToolbar
        v-else :order="order"
        @action:cancel="showCancelModal = true"
        @action:return="showEditRmaModal = true"
        @action:shipto="showChangeShipToModal = true"
        @action:acknowledgeBackorder="showAcknowledgeBackorderModal = true">
      </MerchantToolbar>
    </div>

    <!-- Push Order Status -->
    <div class="mt-lg mb-md" v-if="orderPushStatus.shouldPush">
      <b-message v-if="orderPushStatus.didPush" type="is-success">
        <p v-if="context.isSupplier()">This order was pushed to {{ platformAccount.platform.name }}.</p>
        <p v-else>This order was pushed to {{ order.brand.name }}'s {{ connectionPlatformAccount.platform.name }} account.</p>
      </b-message>
      <b-message v-else type="is-warning">
        <div class="columns is-mobile is-vcentered">
          <div class="column">
            <p v-if="context.isSupplier()">
              This order was not pushed to {{ platformAccount.platform.name }}.
              <span v-if="pushOrderJob">
                View results of retry <a href="#" @click.prevent="inspectJob" class="has-text-info" style="text-decoration:none">#{{ pushOrderJob.id }}</a>
              </span>
            </p>
            <p v-else>This order was not pushed to {{ order.brand.name }}'s {{ connectionPlatformAccount.platform.name }} account!</p>
            <span v-if="orderPushStatus.error">
             {{ orderPushStatus.error }}
            </span>
          </div>
          <div v-if="context.isSupplier()" class="column is-narrow">
            <div class="buttons">
              <b-button
                outlined
                type="is-warning"
                size="is-small"
                :loading="pushingOrder"
                :disabled="pushingOrder"
                @click="pushOrder"
              >Retry Push Order</b-button>
              <b-button
                type="is-warning"
                size="is-small"
                outlined
                :loading="pushingOrder"
                :disabled="pushingOrder"
                @click="$emit('order:refresh')"
              >Refresh Order</b-button>
            </div>
          </div>
        </div>
      </b-message>
    </div>
    <b-message class="mt-lg" v-if="shipmentPlatformAccountTransactions.length > 0"  type="is-warning">
      <div class="columns is-mobile is-vcentered">
        <div class="column">
          <p v-if="context.isMerchant()">
              This order’s shipment details could not be pushed from {{ order.brand.name }}'s Shopify account to {{ order.retailer.name }}'s Shopify account as it failed to register.
              Exception from module Shopify: {{ shipmentPlatformAccountTransactions }}
          </p>
        </div>
      </div>
    </b-message>

    <!-- Identifiers & Dates -->
    <div class="mt-lg mb-md">
      <OrderInfo
        :order="order"
        :platformAccount="platformAccount"
        :connectionPlatformAccount="connectionPlatformAccount"
        @updateTag="updateTag"
      />
    </div>

    <!-- Order Lines -->
    <div class="mb-md">
      <p class="is-size-5 has-text-centered mb-sm">Order Lines</p>
      <OrderLines
        :orderRefreshing="orderRefreshing"
        :orderId="order.id"
        :lines="order.order_lines"
        :currency="order.currency"
        @inspect:variant="inspectVariant"
        @backorder:line="backorderLine"
      />
    </div>

    <!-- EDI Envelopes -->
    <div v-if="order.envelopes.length > 0" class="mb-md">
      <p class="is-size-5 has-text-centered mb-sm">EDI Envelopes</p>
      <EdiEnvelopes :orderId="order.id" :envelopes="order.envelopes" />
    </div>

    <!-- Cancels -->
    <div v-if="order.cancels.length > 0" class="mb-md">
      <p class="is-size-5 has-text-centered mb-sm">Cancels</p>
      <cancels
        :orderId="order.id"
        :cancels="order.cancels"
        @cancel:acknowledged="$emit('order:updated')"/>
    </div>

    <!-- Fulfillment -->
    <div class="mb-md">
      <p class="is-size-5 has-text-centered mb-sm">Tracking Numbers</p>
      <shipments
        :order="order"
        :orderRefreshing="orderRefreshing"
        @fulfillment:updated="$emit('order:updated')"/>
    </div>

    <!-- Invoices -->
    <div class="mb-md">
      <p class="is-size-5 has-text-centered mb-sm">Invoices</p>
      <invoices :order="order" />
    </div>

    <!-- RMAs -->
    <div v-if="order.rmas.length > 0" class="mb-md">
      <p class="is-size-5 has-text-centered mb-sm">Returns</p>
      <rmas
        :order="order"
        @rma:rejected="$emit('order:updated')"
        @rma:approved="$emit('order:updated')"/>
    </div>

    <!-- Credits -->
    <div v-if="order.credits.length > 0" class="mb-md">
      <p class="is-size-5 has-text-centered mb-sm">Credits</p>
      <credits :order="order" />
    </div>

    <!-- Memos -->
    <div class="mb-md">
      <div class="mb-md has-text-centered">
        <p class="is-size-5">Messages</p>
        <p class="has-text-grey is-size-7">Messages that can be seen by all parties.</p>
      </div>
      <div class="has-background-white rounded-lg pa-lg">
        <order-messages :order="order" />
      </div>
    </div>

    <!-- Order Activity -->
    <div class="py-sm">
      <div class="mb-md has-text-centered">
        <p class="is-size-5">Order Activity</p>
      </div>
      <div class="has-background-white rounded-lg pa-md">
        <order-activity :order="order" />
      </div>
    </div>

    <!-- Modal: Cancel Full Order -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showCancelModal"
      :can-cancel="['x', 'esc']"
      ><CancelOrderModal :order="order" @order:canceled="$emit('order:updated')" />
    </b-modal>
    <!-- Modal: LTL/Freight Modal -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showFreightModal"
      :can-cancel="['x', 'esc']"
      ><FreightModal :order="order" @fulfillment:updated="$emit('order:updated')" />
    </b-modal>
    <!-- Modal: Create Invoice -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showEditInvoiceModal"
      :can-cancel="['x', 'esc']"
      ><EditInvoiceModal :order="order" @invoice:registered="$emit('order:updated')"  />
    </b-modal>
    <!-- Modal: Change Address -->
    <b-modal
      v-if="context.isMerchant()"
      has-modal-card
      scroll="keep"
      :active.sync="showChangeShipToModal"
      :can-cancel="['x', 'esc']"
      ><ChangeShipToModal :order="order" @shipto:updated="$emit('order:updated')" />
    </b-modal>
    <!-- Modal: Acknowledge Backorder -->
    <b-modal
      v-if="context.isMerchant()"
      has-modal-card
      scroll="keep"
      :active.sync="showAcknowledgeBackorderModal"
      :can-cancel="['x', 'esc']"
      ><AcknowledgeBackorderModal :order="order" @order:updated="$emit('order:updated')" />
    </b-modal>
    <!-- Modal: Create Return -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showEditRmaModal"
      :can-cancel="['x', 'esc']"
      ><EditRmaModal :order="order" @rma:created="$emit('order:updated')" />
    </b-modal>
    <!-- Modal: Close Order -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showCloseOrderModal"
      :can-cancel="['x', 'esc']"
      ><CloseOrderModal :order="order" @order:updated="$emit('order:updated')" />
    </b-modal>
    <!-- Modal: Inspect Job Modal -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showInspectJobModal"
      :can-cancel="['x', 'esc']"
      ><InspectJobModal :seed="pushOrderJob" />
    </b-modal>
    <!-- Modal: Mark an Order Line as Backordered -->
    <b-modal
      has-modal-card
      scroll="keep"
      v-if="focusOrderLine !== null"
      :active.sync="showBackorderModal"
      :can-cancel="['x', 'esc']"
      @close="resetBackorderLine"
      ><BackorderLineModal :orderId="order.id" :line="focusOrderLine" @line:backordered="$emit('order:updated')" />
    </b-modal>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MerchantToolbar from '../components/Summary/MerchantToolbar'
import SupplierToolbar from '../components/Summary/SupplierToolbar'
import EdiEnvelopes from '../components/Summary/EdiEnvelopes'
import Invoices from '../components/Summary/Invoices'
import OrderActivity from '../components/Summary/OrderActivity'
import OrderInfo from '../components/Summary/OrderInfo'
import OrderLines from '../components/Summary/OrderLines'
import OrderMessages from '../components/Messages'
import Cancels from '../components/Summary/Cancels'
import Rmas from '../components/Summary/Rmas'
import Credits from '../components/Summary/Credits'
import Shipments from '../components/Summary/Shipments'
import BackorderLineModal from '@/components/Models/OrderLine/BackorderLineModal'
import AcknowledgeBackorderModal from '@/components/Models/Order/Modals/AcknowledgeBackorderModal'
import EditRmaModal from '@/components/Modals/Rma/EditRmaModal'
import EditInvoiceModal from '@/components/Modals/Invoice/EditInvoiceModal'
import CancelOrderModal from '../components/Summary/CancelOrderModal'
import CloseOrderModal from '@/components/Modals/Order/CloseOrderModal'
import FreightModal from '../components/Summary/FreightModal'
import InspectJobModal from '@/components/Models/Job/Modals/Inspect'
import ChangeShipToModal from '@/components/Modals/Order/ChangeShipToModal'

export default {
  components: {
    MerchantToolbar,
    SupplierToolbar,
    // order details
    OrderInfo,
    OrderLines,
    Cancels,
    Shipments,
    Invoices,
    EdiEnvelopes,
    Rmas,
    Credits,
    OrderMessages,
    OrderActivity,
    // modals
    AcknowledgeBackorderModal,
    BackorderLineModal,
    CancelOrderModal,
    ChangeShipToModal,
    CloseOrderModal,
    EditInvoiceModal,
    EditRmaModal,
    FreightModal,
    InspectJobModal
  },
  computed: {
    ...mapGetters(['context']),
    orderPushStatus () {
      let pushOrderCredential = null
      let didPush = false
      let error = null
      const account = (this.context.isSupplier()) ? this.platformAccount : this.connectionPlatformAccount
      if (account && 'credentials' in account) {
        account.credentials.forEach(credential => {
          if ('push_order_on_order_create' in credential) {
            pushOrderCredential = credential.push_order_on_order_create.values[0].value
          }
        })
      }
      if (this.order.platform_account_transactions) {
        error = this.order.platform_account_transactions.errors.replace('ore', 'or').replace('[\'', '').replace('\']', '').replace('shopify', 'Shopify')
        if (this.order.platform_account_transactions.status === 'success') {
          didPush = true
        } else if (this.order.brand_identifier) {
          didPush = true
        }
      }
      return {
        shouldPush: (account && pushOrderCredential === '1'),
        didPush: didPush,
        error: error
      }
    }

  },
  data () {
    return {
      pushingOrder: false,
      pushOrderJob: null,
      focusOrderLine: null,
      showEditRmaModal: false,
      showEditInvoiceModal: false,
      showCancelModal: false,
      showCloseOrderModal: false,
      showFreightModal: false,
      showChangeShipToModal: false,
      showBackorderModal: false,
      showInspectJobModal: false,
      showAcknowledgeBackorderModal: false,
      tags: this.order.tags,
      shipmentPlatformAccountTransactions: []
    }
  },
  created () {
    this.shipmentError()
  },
  methods: {
    inspectVariant (variant) {
      this.$emit('inspect:variant', variant)
    },
    inspectJob () {
      this.showInspectJobModal = true
    },
    backorderLine (line) {
      this.focusOrderLine = line
      this.showBackorderModal = true
    },
    resetBackorderLine () {
      this.showBackorderModal = false
      setTimeout(() => {
        this.focusOrderLine = null
      }, 250)
    },
    pushOrder () {
      this.pushingOrder = true
      const endpoint = this.context.route + '/platform-accounts/' + this.platformAccount.id + '/push_order/'
      const body = {
        order_id: this.order.id
      }
      this.$http.post(endpoint, body).then(response => {
        setTimeout(() => {
          this.pushOrderJob = response.data
          this.pushingOrder = false
          this.$buefy.toast.open({ type: 'is-info', message: 'Retrying order push...' })
          this.$emit('order:updated')
        }, 5000)
      })
    },
    updateTag (data) {
      this.tags = data
    },
    shipmentError () {
      this.order.shipments.forEach(shipment => {
        const endpoint = `${this.context.route}/platform-accounts/${this.platformAccount.id}/transactions/?shipment_id=${shipment.id}`
        this.$http.get(endpoint).then(response => {
          setTimeout(() => {
            const results = response.data.results
            if (results.length > 0 && results[0].status === 'failure') {
              this.shipmentPlatformAccountTransactions.push(results[0].errors)
            }
          }, 5000)
        })
      })
    }
  },
  props: {
    order: {
      type: Object,
      required: true
    },
    orderRefreshing: {
      type: Boolean,
      requried: true
    },
    loading: {
      type: Boolean,
      requried: true
    },
    platformAccount: {
      type: [Object, null]
    },
    connectionPlatformAccount: {
      type: [Object, null]
    }
  }
}
</script>
