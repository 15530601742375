<template>
  <div>
    <div class="section">
      <div class="container">
        <!-- Page Header -->
        <div class="mt-sm mb-xl">
          <breadcrumbs
            :history="breadcrumbs"
            :current="'Item #' + $route.params.variant "
            :hideBack="true"
          />

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  computed: {
    ...mapGetters(['context']),
    breadcrumbs () {
      return [{
        label: 'Network',
        name: 'network.dashboard'
      }, {
        label: 'Catalog',
        name: 'network.products'
      }, {
        label: 'Product',
        name: 'network.product',
        params: { id: this.$route.params.id }
      }]
    },
    endpoint () {
      return this.context.route + '/variants/' + this.$route.params.variant + '/'
    }
  },
  data () {
    return {

    }
  },
  methods: {

  },
  mounted () {

  },
  props: []
}
</script>
