<template>
  <div>
    <b-select
      v-model="roleType"
      placeholder="Select a Role..."
      :loading="loading"
      :disabled="loading"
      @input="announce"
      expanded>
      <option
        v-for="roleType in roleTypes"
        :key="roleType.id"
        :value="roleType"
        >{{ roleType.name }}</option>
    </b-select>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['app', 'context']),
    endpoint () {
      return this.context.route + '/role-types/'
    }
  },
  data () {
    return {
      loading: true,
      roleTypes: [],
      roleType: null
    }
  },
  methods: {
    load () {
      this.$http.get(this.endpoint).then(response => {
        const allowed = [
          'Retailer Admin',
          'Retailer Operations',
          'Retailer User',
          'Brand Admin',
          'Brand User',
          'Shopify User'
        ]
        response.data.results.forEach(roleType => {
          if (allowed.includes(roleType.name)) {
            this.roleTypes.push(roleType)
          }
        })
        this.loading = false
        if (this.value !== null) {
          this.roleType = this.value
        }
      })
    },
    announce () {
      this.$emit('input', this.roleType)
    }
  },
  mounted () {
    if (this.value !== null) {
      this.roleType = this.value
    }
    this.load()
  },
  props: ['value']
}
</script>
