<template>
  <div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Resolve Issue?</p>
      </header>
      <section class="modal-card-body">
        <p class="mb-md">
          Are you sure you're ready to resolve this issue?
        </p>
        <!-- Add a note -->
        <b-field label="Add a Note? (optional)">
          <b-input type="textarea"
            v-model="text"
            maxlength="2048"
            expanded
            @input="isDirty = true"/>
        </b-field>
      </section>
      <footer class="modal-card-foot space-between" >
        <b-button @click="$parent.close()">Cancel</b-button>
        <b-button
          type="is-primary"
          :disabled="saving"
          :loading="saving"
          @click="save">Resolve Issue</b-button>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['auth', 'context']),
    endpoint () {
      return this.context.route + '/proposals/' + this.$route.params.id + '/issues/' + this.$route.params.issueId + '/resolve/'
    },
    json () {
      const json = {
        text: this.text
      }
      return json
    },
    postEndpoint () {
      return this.context.route + '/proposals/' + this.$route.params.id + '/issues/' + this.$route.params.issueId + '/memos/'
    }
  },
  data () {
    return {
      saving: false,
      text: null,
      errors: {}
    }
  },
  methods: {
    async save () {
      this.saving = true
      if (this.text !== null && this.text) {
        this.addNote()
      } else {
        this.resolveIssue()
      }
    },
    addNote () {
      this.$http.post(this.postEndpoint, this.json).then(response => {
        this.text = null
        this.resolveIssue()
      })
    },
    resolveIssue () {
      this.$http.put(this.endpoint).then(response => {
        this.$buefy.toast.open({ type: 'is-info', message: 'Issue Resolved!' })
        this.$emit('issue:resolved')
        this.$parent.close()
      }).finally(() => {
        this.saving = false
      })
    }
  },
  props: {
  }
}
</script>
