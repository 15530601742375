<template>
  <div>
    <div class="section">
      <div class="container">
        <!-- Page Header -->
        <div class="mb-md">
          <div class="columns is-vcentered">
            <div class="column is-one-third">
              <breadcrumbs :history="breadcrumbs.history" :current="breadcrumbs.current" />
            </div>
            <div class="column is-one-third">
              <h1 class="has-text-centered is-size-4">Submit Assortment</h1>
            </div>
          </div>
        </div>
        <!-- Content -->
        <div class="has-background-white rounded-lg px-xl py-lg">
          <div class="columns">
            <div class="column is-three-quarters">
              <!-- Intro -->
              <div class="mb-lg">
                <p class="is-size-4 has-text-weight-bold">Submit New Assortment: How it Works</p>
                <p>
                  Before you submit products to, please review the following steps to
                  familiarize yourself with the process and workflow.
                </p>
              </div>
              <!-- Steps -->
              <div class="mb-lg">
                <div class="columns is-centered">
                  <div class="column">
                    <div class="rounded-lg has-background-light px-xxl py-lg">
                      <ul>
                        <li class="mb-sm"><b>Step 1 &nbsp;</b> Select the department for the items you are submitting.</li>
                        <li class="mb-sm"><b>Step 2 &nbsp;</b> Upload product attributes &amp; images for the items you are submitting using an approved template.</li>
                        <li class="mb-sm"><b>Step 3 &nbsp;</b> Complete (or skip) optional uploads (e.g. multi-carton shipping dimensions).</li>
                        <li class="mb-sm"><b>Step 4 &nbsp;</b> Review product attribute compatibility and readiness scores.</li>
                        <li class=""><b>Step 5 &nbsp; </b> Submit proposal</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Workflow -->
              <div class="mb-xxl">
                <p class="mb-md">A merchandising representative from your partner will review your proposal, and they may choose to:</p>
                <div class="content">
                  <ul class="content mb-lg">
                    <li>Accept your proposal as-is.</li>
                    <li>Mark items that need to have certain attributes fixed.</li>
                    <li>Remove certain items from your proposal, but accept all others.</li>
                    <li>Decline your entire proposal.</li>
                  </ul>
                </div>
                <p class="mb-lg">
                  To expedite the process, please review all instructions carefully.
                  You will receive a notification once
                  has made a decision regarding your submission. If you want to learn more,
                  please consult our Proposals FAQ or file a
                  <a href="#" @click.prevent="$store.dispatch('supportRequest')" class="has-text-info">support request</a>.
                </p>
              </div>
              <!-- Get Started -->
              <div class="mb-lg">
                <div class="columns">
                  <div class="column is-one-third">
                    <router-link :to="{ name: 'proposals.create' }" class="button is-primary is-fullwidth is-medium">Get Started</router-link>
                  </div>
                </div>
              </div>
            </div>
            <!-- Resources -->
            <div class="column is-one-quarter"></div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['app', 'context']),
    breadcrumbs () {
      return {
        current: 'Submit',
        history: [
          { label: 'Catalog', name: 'catalog' },
          { label: 'Proposals', name: 'proposals.dashboard' }
        ]
      }
    }
  }
}
</script>
