<template>
  <div class="">
    <!-- Initial -->
    <div class="columns is-vcentered is-mobile">
      <div class="column">
        <div class="pl-sm">
          <p class="has-text-weight-bold">
            <a href="#" @click.prevent="expand">
              <span class="is-hidden-mobile">{{ title }}</span>
              <span class="is-hidden-tablet is-size-7">{{ $utils.truncate(title, {length: 48}) }}</span>
            </a>
          </p>
          <div class="is-size-7">
            <p v-if="preview.completed_at === null">Queued at {{ $dates.utc(preview.created_at).local().format('MMM D, YYYY') }}</p>
            <p v-else>Completed on {{ $dates.utc(preview.completed_at).local().format('MMM D, YYYY @ h:mm A') }}</p>
            <p class=" has-text-grey">{{ preview.template.name }}</p>
          </div>
        </div>
      </div>
      <div class="column is-narrow">
        <p :class="status.indicatorClass">
          <b-tooltip :label="status.tooltip" position="is-left" dashed multilined>{{ status.label }}</b-tooltip>
        </p>
      </div>
    </div>
    <!-- Detail Loading -->
    <div v-if="loading">
      <div class="has-background-white-ter rounded-xl">
        <loading message="Loading detail..."></loading>
      </div>
    </div>
    <!-- Detail loaded, so show expanded state -->
    <div v-else-if="!loading && feed !== null && expanded">
      <div class="has-background-white-ter pa-lg rounded-xl">
        <div class="columns">
          <div class="column">
            <p class="has-text-centered has-text-weight-bold has-text-grey mb-md">{{ $utils.upperFirst(feed.direction) }} Info</p>
            <table class="table is-fullwidth is-size-7 rounded-lg">
              <tbody>
                <tr>
                  <th>ID</th>
                  <td class="has-text-right">{{ feed.id }}</td>
                </tr>
                <tr>
                  <th>Direction</th>
                  <td class="has-text-right">{{ $utils.upperFirst(feed.direction) }}</td>
                </tr>
                <tr>
                  <th>Behavior</th>
                  <td class="has-text-right">{{ $utils.upperFirst(feed.behavior) }}</td>
                </tr>
                <tr>
                  <th>Data Type</th>
                  <td class="has-text-right">{{ $utils.upperFirst(feed.data_type) }}</td>
                </tr>
                <tr>
                  <th>Filename</th>
                  <td class="has-text-right">
                    {{ $utils.truncate(feed.original_filename, {length: 48}) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="column">
            <p class="has-text-centered has-text-weight-bold has-text-grey mb-md">
              Results
              <a href="#" v-if="feed.completed_at === null" @click.prevent="refresh" class="has-text-weight-normal is-size-7">(Refresh)</a>
            </p>
            <table class="table is-fullwidth is-size-7 rounded-lg">
              <tbody>
                <tr>
                  <th>Status</th>
                  <td class="has-text-right">
                    <p>{{ $utils.upperFirst(feed.status) }}</p>
                    <p v-if="feed.status === 'queued'">
                      <span v-if="running" class="has-text-grey">Running...</span>
                      <a v-else href="#" @click.prevent="run" >(Run Now)</a>
                    </p>
                  </td>
                </tr>
                <tr>
                  <th># Rows</th>
                  <td class="has-text-right">{{ feed.num_items }}</td>
                </tr>
                <tr>
                  <th># Items Created</th>
                  <td class="has-text-right">{{ feed.num_created }}</td>
                </tr>
                <tr>
                  <th># Items Updated</th>
                  <td class="has-text-right">{{ feed.num_updated }}</td>
                </tr>
                <tr>
                  <th># Errors</th>
                  <td class="has-text-right">{{ feed.num_errors }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FeedQueuePresenter from '@/mixins/presenters/FeedQueue.js'
export default {
  components: {

  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/feed-queue/' + this.preview.id + '/'
    },
    title () {
      return this.$utils.upperFirst(this.preview.direction) + ' ' + this.$utils.upperFirst(this.preview.data_type) + ': ' + this.preview.original_filename
    }
  },
  data () {
    return {
      expanded: false,
      loading: false,
      loaded: false,
      feed: this.preview,
      refreshing: false,
      running: false
    }
  },
  methods: {
    expand () {
      // if the detail view is already expaned, collapse it this time
      if (this.expanded) {
        this.expanded = false
        return
      // if we've already loaded the detail, just expand again
      } else if (this.loaded && this.feed !== null) {
        this.expanded = true
        return
      }
      // otherwise, load the detail data from the api
      this.loading = true
      this.$http.get(this.endpoint).then(response => {
        this.feed = response.data
        this.expanded = true
        this.loaded = true
        this.loading = false
      })
    },
    refresh () {
      this.refreshing = true
      this.$http.get(this.endpoint).then(response => {
        this.feed = response.data
        this.refreshing = false
      })
    },
    run () {
      this.running = true
      this.$http.put(this.endpoint + 'run/').then(response => {
        this.feed = response.data
        this.running = false
        this.refresh()
      })
    }
  },
  mounted () {

  },
  mixins: [FeedQueuePresenter],
  props: ['preview']
}
</script>
