<template>
  <div>
    <!-- Intro -->
    <div class="has-background-black py-xl">
      <div class="section">
        <div class="container">
          <div class="columns is-centered">
            <div class="column is-three-fifths">
              <div class="has-text-white has-text-centered mb-lg">
                <Logo color="white" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Loading State -->
    <div class="section">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-three-fifths">
            <div class="has-background-white rounded-xl pa-xl">
              <p class="is-size-4 has-text-primary mb-md has-text-centered">{{ progressMessage }}</p>
              <transition name="fade" mode="out-in">
                <div class="pt-xxl pb-md">
                  <loading message="" />
                </div>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Logo from '@/components/Logos/Logo'

export default {
  components: {
    Logo
  },
  computed: {
    ...mapGetters(['api', 'app']),
    installUrl () {
      return this.api.baseUrl + '/shopify/request-permission/?shop=' + this.$route.query.shop
    }
  },
  data () {
    return {
      progressMessage: 'Checking with Shopify...'
    }
  },
  mounted () {
    setTimeout(() => {
      window.location.href = this.installUrl
    }, 2000)
  }
}
</script>
