<template>
  <div>
    <!-- Intro -->
    <div class="">
      <div class="mb-lg has-text-centered">
        <p class="has-text-weight-bold is-size-5">Platform &amp; Technology</p>
        <p class="has-text-grey-light">Question #2 of 3</p>
      </div>
      <div>
        <p class="has-text-weight-bold has-text-centered mb-lg">
          How would you rate the ease of the use of the {{ app.productName }}
          platform and it's ability to integrate with your business?
        </p>
        <div class="columns is-centered is-marginless is-mobile">
          <div class="column is-narrow">
            <b-rate :max="5" v-model="score" size="is-large"></b-rate>
          </div>
        </div>
        <div class="has-text-centered mb-xl">
          <p class="has-text-grey-light mb-xl">Click on a star to register your rating</p>
          <p class="has-text-weight-bold">Score Meaning</p>
          <div v-if="score !== null">
            <div v-if="score >= 4">
              <p class="has-text-success has-text-weight-bold is-size-4">Excellent</p>
              <p>We are happy with the platform &amp; its capabilities.</p>
            </div>
            <div v-else-if="score >= 2">
              <p class="has-text-warning has-text-weight-bold is-size-4">Average</p>
              <p>The platfrom will work for us, but doesn't exactly fit our needs.</p>
            </div>
            <div v-else-if="score >= 1">
              <p class="has-text-danger has-text-weight-bold is-size-4">Needs Improvement</p>
              <p>We think the platform needs improvement.</p>
            </div>
          </div>
          <div v-else>
            <p class="has-text-grey-light">Please select a rating</p>
          </div>
        </div>
      </div>
    </div>
    <!-- Navigate -->
    <div class="py-lg">
      <div class="columns is-centered">
        <div class="column">
          <b-button
            type="is-light rounded-lg"
            size="is-medium"
            @click="goBack"
            expanded
            ><b-icon icon="arrow-left"></b-icon>
          </b-button>
        </div>
        <div class="column">
          <b-button
            type="is-primary rounded-lg"
            size="is-medium"
            expanded
            :disabled="!ready || saving"
            :loading="saving"
            @click="goForward"
          >Continue</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {

  },
  computed: {
    ...mapGetters(['app', 'context']),
    body () {
      const body = {
        metadata: [{
          name: 'ob_feedback_platform',
          display_name: 'Onboarding Feedback: Platform',
          type: 'string',
          value: this.score
        }]
      }
      return body
    },
    ready () {
      return (this.score)
    }
  },
  data () {
    return {
      saving: false,
      score: null
    }
  },
  methods: {
    goBack () {
      this.$emit('navigate', 'Support')
    },
    goForward () {
      this.saving = true
      const endpoint = this.context.route + '/'
      this.$http.patch(endpoint, this.body).then(() => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Thank you!' })
        this.$emit('metadata:saved')
        this.$emit('navigate', 'Learn')
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    if ('ob_feedback_platform' in this.brand.metadata) {
      this.score = parseInt(this.brand.metadata.ob_feedback_support.values[0].value)
    }
  },
  props: {
    connection: {
      type: Object,
      required: true
    },
    task: {
      type: Object,
      required: true
    },
    brand: {
      type: Object,
      required: true
    }
  }
}
</script>
