<template>
  <div>
    <div class="columns is-vcentered is-mobile">
      <div class="column is-narrow is-hidden-mobile">
        <div :class="authorClass" class="has-text-white rounded-full has-text-centered" style="width:48px; height:48px; padding-top:12px">
          {{ authorInitials }}
        </div>
      </div>
      <div class="column">
        <p class="has-text-grey is-size-7">
          On {{ $dates.utc(message.created_at).local().format('MMM D') }},
          {{ authorFullName }} wrote...
        </p>
        <p class="mb-xs">{{ message.text }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  computed: {
    ...mapGetters(['context']),
    authorInitials () {
      return this.message.created_by.first_name.charAt(0).toUpperCase() + this.message.created_by.last_name.charAt(0).toUpperCase()
    },
    authorClass () {
      return (this.message.created_by_context === 'retailer') ? 'has-background-primary' : 'has-background-success'
    },
    authorFullName () {
      return this.message.created_by.first_name + ' ' + this.message.created_by.last_name
    }
  },
  data () {
    return {
      message: this.ogMessage
    }
  },
  methods: {
  },
  mounted () {

  },
  props: {
    ogMessage: {
      type: Object,
      required: true
    }
  }
}
</script>
