<template>
  <div>
    <!-- Production Credentials -->
    <div>
      <p class="has-text-weight-bold mb-sm has-text-info border-b pb-sm">Production Credentials</p>
      <div class="columns is-multiline">
        <div class="column is-half">
          <b-field label="Production Account Number">
            <b-input
              type="text"
              v-model="accountNumber"
              size="is-medium"
              @input="announce"
            ></b-input>
          </b-field>
        </div>
        <div class="column is-half">
          <b-field label="Production Meter Number">
            <b-input
              type="text"
              v-model="meterNumber"
              size="is-medium"
              @input="announce"
            ></b-input>
          </b-field>
        </div>
        <div class="column is-half">
          <b-field label="Production Authentication Key">
            <b-input
              type="text"
              v-model="key"
              size="is-medium"
              @input="announce"
            ></b-input>
          </b-field>
        </div>
        <div class="column is-half">
          <b-field label="Production Password">
            <b-input
              type="password"
              v-model="password"
              size="is-medium"
              password-reveal
              @input="announce"
            ></b-input>
          </b-field>
        </div>
        <div class="column is-half">
          <b-field label="Billing Country">
            <b-input
              type="text"
              v-model="billingCountry"
              size="is-medium"
              :maxlength="2"
              @input="announce"
            ></b-input>
          </b-field>
        </div>
        <div class="column is-half">
          <b-field label="Billing Postal Code">
            <b-input
              type="text"
              v-model="billingPostalCode"
              size="is-medium"
              @input="announce"
            ></b-input>
          </b-field>
        </div>
      </div>
    </div>
    <!-- Test Credentials -->
    <div>
      <p class="has-text-weight-bold mb-sm has-text-info border-b pb-sm">Test Credentials</p>
      <div class="columns is-multiline">
        <div class="column is-half">
          <b-field label="Test Account Number">
            <b-input
              type="text"
              v-model="testAccountNumber"
              size="is-medium"
              @input="announce"
            ></b-input>
          </b-field>
        </div>
        <div class="column is-half">
          <b-field label="Test Meter Number">
            <b-input
              type="text"
              v-model="testMeterNumber"
              size="is-medium"
              @input="announce"
            ></b-input>
          </b-field>
        </div>
        <div class="column is-half">
          <b-field label="Test Authentication Key">
            <b-input
              type="text"
              v-model="testKey"
              size="is-medium"
              @input="announce"
            ></b-input>
          </b-field>
        </div>
        <div class="column is-half">
          <b-field label="Test Password">
            <b-input
              type="password"
              v-model="testPassword"
              size="is-medium"
              password-reveal
              @input="announce"
            ></b-input>
          </b-field>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    valid () {
      return (
        this.accountNumber &&
        this.meterNumber &&
        this.key &&
        this.password &&
        this.billingCountry &&
        this.billingPostalCode &&
        this.testAccountNumber &&
        this.testMeterNumber &&
        this.testKey &&
        this.testPassword
      )
    },
    json () {
      return {
        carrier: {
          code: 'FDEG'
        },
        credentials: {
          account_number: this.accountNumber,
          key: this.key,
          meter_number: this.meterNumber,
          password: this.password,
          billing_country: this.billingCountry,
          billing_postal_code: this.billingPostalCode
        },
        test_credentials: {
          account_number: this.testAccountNumber,
          key: this.testKey,
          meter_number: this.testMeterNumber,
          password: this.testPassword
        }
      }
    }
  },
  data () {
    return {
      accountNumber: null,
      key: null,
      meterNumber: null,
      password: null,
      billingCountry: 'US',
      billingPostalCode: null,
      testAccountNumber: null,
      testMeterNumber: null,
      testKey: null,
      testPassword: null
    }
  },
  methods: {
    announce () {
      if (this.valid) {
        this.$emit('input', this.json)
      }
    }
  }
}
</script>
