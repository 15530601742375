<template>
  <form @submit.prevent="save">
    <!-- Intro -->
    <div class="">
      <p class="has-text-centered is-size-4 mb-md has-text-black">Dropship Pricing</p>
      <p class="pb-lg">
        To help inform prospective Merchants that are considering selling
        your products, we ask that you set two prices for each product you import into {{ app.platform }}:
        <strong>MAP</strong> and <strong>Dropship Cost</strong>.
      </p>
      <!-- Map -->
      <div class="mb-xl">
        <p class="has-text-weight-bold mb-sm is-size-5">Minimum Advertised Price (or MAP)</p>
        <p class="mb-md is-z">
          Minimum advertised price is the lowest price a
          merchant can advertise your product for sale. {{ app.platform }} will protect
          your pricing by rejecting any order that is sold below your MAP.
        </p>
        <b-message type="is-info">
          By default,
          {{ app.platform }} will import the value set in Shopify's "price" field as your
          MAP price in {{ app.platform }}.
        </b-message>
      </div>
      <!-- Dropship Cost -->
      <div class="mb-xl">
        <p class="has-text-weight-bold mb-sm is-size-5">Dropship Cost</p>
        <p class="mb-md">The "dropship cost" is your all-in cost paid to you by the merchant once
          you ship an order to a customer. It should be higher than your standard wholesale
          cost and include any order processing fees.
        </p>
        <b-message type="is-info">
          By default, {{ app.platform }} will set your
          initial dropship costs using a percentage of your retail price.
        </b-message>
      </div>
    </div>
    <!-- Fields -->
    <div class="mb-xxl">
      <p class="has-text-weight-bold mb-md">
        What percentange of your retail price should we use to calculate your inital dropship cost?
      </p>
      <!-- Old Password -->
      <div class="mb-xl">
        <div class="columns is-vcentered is-mobile">
          <div class="column is-one-third">
            <b-field>
              <b-numberinput
                class="mb-lg"
                size="is-large"
                min="50"
                max="100"
                v-model="dropshipCostPercent"
              ></b-numberinput>
            </b-field>
          </div>
          <div class="column">
            <span class="has-text-grey">Enter a number between 50-100.</span>
          </div>
        </div>
      </div>
      <div class="has-background-white-ter pa-lg rounded-lg">
        <span class="has-text-centered has-text-weight-bold mb-sm">Example:</span>
        If your variant/item has the price set to {{ exampleMap }} in Shopify,
        then your initial MAP in {{ app.platform }} would be set to {{ exampleMap }}
        and your intial dropship cost would be set to {{ dropshipCostPercent }}%
        of {{ exampleMap }}, or <b>{{ exampleCost }}</b>.
        Of course, you can always customize or optimize your costs & MAPs <b>per relationship</b> later.
      </div>
    </div>
    <!-- Buttons -->
    <div class="mb-xl">
      <div class="columns is-centered">
        <div class="column is-three-quarters-tablet is-two-thirds-widescreen">
          <div class="columns">
            <div class="column">
              <b-button type="is-light rounded-lg" size="is-large" @click="back" expanded>
                <b-icon icon="arrow-left"></b-icon>
              </b-button>
            </div>
            <div class="column">
              <b-button
                native-type="submit"
                type="is-primary rounded-lg"
                size="is-large"
                expanded
                :loading="saving"
                :disabled="!validated || saving"
              >Next</b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      examplePrice: 85,
      dropshipCostPercent: 70,
      saving: false
    }
  },
  computed: {
    ...mapGetters(['app']),
    validated () {
      return (
        this.dropshipCostPercent &&
        this.dropshipCostPercent >= 50 &&
        this.dropshipCostPercent <= 100
      )
    },
    exampleMap () {
      return this.$numbers(this.examplePrice).formatCurrency({ mantissa: 2 })
    },
    exampleCost () {
      return this.$numbers(this.examplePrice * this.dropshipCostPercent / 100).formatCurrency({ mantissa: 2 })
    }
  },
  methods: {
    save () {
      this.$emit('change:setting', { key: 'dropshipCostPercent', value: this.dropshipCostPercent })
      this.$emit('change:step', 'ScheduleImport')
    },
    back () {
      this.$emit('change:step', 'Import')
    }
  },
  props: ['account', 'settings']
}
</script>
