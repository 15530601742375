<template>
  <b-tooltip
    position="is-bottom"
    :active="!loading && !returnReasons.length"
    style="width: 100%"
  >
    <b-select
      v-model="returnReason"
      :placeholder="getPlaceholder"
      expanded
      :loading="loading"
      :disabled="loading || !returnReasons.length"
      :size="sizeClass"
      @input="announce"
    >
      <option
        v-for="returnReason in returnReasons"
        :value="returnReason"
        :key="returnReason.id"
      >
        {{ returnReason.return_reason }}
      </option>
    </b-select>
    <template v-slot:content> No Return Reason available to apply. </template>
  </b-tooltip>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {},
  data () {
    return {
      loading: true,
      returnReason: null,
      returnReasons: [],
      isRequired: false
    }
  },
  computed: {
    ...mapGetters(['api', 'context']),
    getPlaceholder () {
      return this.isRequired
        ? 'Please select a reason...'
        : 'Please select a reason (optional)'
    },
    endpoint () {
      const endpoint = this.api.baseUrl + '/v1/proposal-return-reasons/'
      return endpoint
    },
    query () {
      return {
        params: {
          pagination: 0
        }
      }
    },
    sizeClass () {
      return this.size ? this.size : null
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint, this.query).then((response) => {
        this.returnReasons = response.data.results
        this.loading = false
      })
    },
    announce () {
      this.$emit('input', this.returnReason)
    }
  },
  mounted () {
    if (this.value !== null) {
      this.returnReason = this.value
    }
    if (this.isMandatory) {
      this.isRequired = this.isMandatory
    }
    this.load()
  },
  props: ['value', 'size', 'isMandatory']
}
</script>
