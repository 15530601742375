<template>
  <form @submit.prevent="update" novalidate autocomplete="off">
    <div class="columns">
      <div class="column is-one-fifth">
        <p class="has-text-weight-bold is-size-6 mb-sm">General Settings</p>
        <p class="has-text-grey is-size-7">Basic profile information that identifies this user across the {{ app.platform }} platform.</p>
      </div>
      <div class="column">
        <div class="has-background-white rounded-xl">
          <!-- Loaded -->
          <div v-if="!loading && user !== null" class="pa-lg pb-xl">
            <div class="mb-md">
              <div class="columns">
                <div class="column is-half">
                  <b-field
                    label="First Name"
                    :type="('first_name' in errors) ? 'is-danger' : ''"
                    :message="('first_name' in errors) ? errors['first_name'][0] : ''">
                    <b-input
                      autocomplete="off"
                      type="text"
                      name="firstName"
                      v-model="firstName"
                      @input="isDirty = 1"
                    ></b-input>
                  </b-field>
                </div>
                <div class="column is-half">
                  <b-field
                    label="Last Name"
                    :type="('last_name' in errors) ? 'is-danger' : ''"
                    :message="('last_name' in errors) ? errors['last_name'][0] : ''"
                    expanded>
                    <b-input
                      autocomplete="off"
                      type="text"
                      name="lastName"
                      v-model="lastName"
                      @input="isDirty = 1"
                    ></b-input>
                  </b-field>
                </div>
              </div>
            </div>
            <b-field
              label="Title"
              :type="('title' in errors) ? 'is-danger' : ''"
              :message="('title' in errors) ? errors['title'][0] : ''">
              <b-input
                autocomplete="off"
                type="text"
                name="title"
                v-model="title"
                @input="isDirty = 1"
              ></b-input>
            </b-field>
            <b-field
              label="Status"
              :type="('status' in errors) ? 'is-danger' : ''"
              :message="('status' in errors) ? errors['status'][0] : ''">
              <b-select
                v-model="status"
                @input="isDirty = 1"
                expanded>
                <option value="active">Active</option>
                <option value="locked">Suspended</option>
                <option value="invited" disabled>Invited</option>
              </b-select>
            </b-field>
          </div>
          <!-- Loading -->
          <div v-else>
            <loading message="Loading user..." />
          </div>
          <div class="border-t">
            <div class="columns is-vcentered is-marginless">
              <div class="column">
                <div class="px-sm has-text-grey-light">
                  <i v-if="isDirty">Click 'Update' to save your changes</i>
                </div>
              </div>
              <div class="column is-narrow">
                <div class="px-sm">
                  <b-button
                    native-type="submit"
                    type="is-primary rounded-lg"
                    :loading="updating"
                    :disabled="updating || !isDirty"
                  >Update</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['app', 'context']),
    endpoint () {
      return this.context.route + '/users/' + this.$route.params.id + '/'
    },
    json () {
      return {
        first_name: this.firstName,
        last_name: this.lastName,
        title: this.title,
        status: this.status
      }
    }
  },
  data () {
    return {
      isDirty: false,
      firstName: null,
      lastName: null,
      title: null,
      status: null,
      errors: {},
      updating: false
    }
  },
  methods: {
    update () {
      this.updating = true
      this.$http.patch(this.endpoint, this.json).then(response => {
        this.updating = false
        this.isDirty = false
        this.$buefy.toast.open({ type: 'is-success', message: 'User updated!' })
      })
    }
  },
  props: {
    loading: {
      required: true,
      type: Boolean
    },
    user: {
      required: true,
      validator: prop => typeof prop === 'object' || prop === null
    }
  },
  watch: {
    loading: {
      immediate: true,
      handler (value) {
        if (value === false) {
          this.firstName = this.user.first_name
          this.lastName = this.user.last_name
          this.title = this.user.title
          this.status = this.user.status
        }
      }
    }
  }
}
</script>
