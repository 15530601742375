import axios from 'axios'
export default class Request {
  /**
   * Array of properties required by the request to form a request body
   * @type {object}
   */
  attributes = {}

  /**
   * Error string (usually converted from API error response)
   * @type {string}
   */
  errorMessage = null

  /**
   * API response status code after request fires
   * @type {number}
   */
  statusCode = null

  /**
   * HTTP request type (e.g. PUT, POST, GET, DELETE) in lowercase
   * @type {string}
   */
  method = 'get'

  /**
   * Presentable status (i.e. Ready => Pending => Complete || Error)
   * @type {string}
   */
  status = 'Ready'

  /**
   * Boolean to track whether or not request data has bugun processing
   * @type {boolean}
   */
  processing = false

  /**
   * Boolean to track whether or not request data has been validated
   * @type {boolean}
   */
  isValid = false

  /**
   * Error string describing why client-side validations failed
   * @type {string}
   */
  validationMessage = null

  /**
   * Invalidates a request and sets the reason
   *
   * @param {object}  // usually Vue data
   * @returns null
   */
  invalidate (message = null) {
    this.isValid = false
    this.status = 'Skip'
    this.errorMessage = message
  }

  /**
   * Configures or resets state to prepare a request to be run; calls function
   * that should be defined on child class to prepare the request payload
   *
   * @param {object}  // usually Vue data
   * @returns null
   */
  prepare (data = null) {
    this.validate()
    if (this.isValid) {
      this.status = 'Pending'
      this.statusCode = null
      this.errorMessage = null
      if (data !== null) {
        Object.assign(this.attributes, data)
      }
    }
  }

  /**
   * Run the defined request through axios; adjust state depending on response
   * and return promise resolution
   *
   * @returns Promise
   */
  run () {
    // if (typeof this.preflight === 'function') {
    //   await this.preflight()
    // } else {
    // }
    return new Promise((resolve, reject) => {
      if (this.isValid === false) {
        reject(new Error('Invalid request.'))
        return
      }
      this.processing = true
      axios[this.method](this.endpoint(), this.json()).then(response => {
        this.status = 'Complete'
        this.statusCode = response.status
        resolve(response)
      }).catch(error => {
        this.status = 'Error'
        this.statusCode = error.response.status
        this.errorMessage = this.errorHandler(error.response.data)
        reject(error)
      }).finally(() => {
        this.processing = false
      })
    })
  }
}
