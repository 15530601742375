<template>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">{{isEdit ? 'Update' : 'Add' }} Order Line Items</p>
      </header>
      <section class="modal-card-body">
        <b-field label="Order Line Number"
          :type="!isUnique ? 'is-danger' : ''"
          :message="!isUnique ? 'Order line number already exists' : ''"
          >
          <b-input
            type="number"
            placeholder="must be a unique number"
            autocomplete="off"
            class="is-align-content-start"
            v-model="orderLineNo"
            :controls="false"
            name="orderLineNo"
            step="1"
            @input="isDirty = 1"
          />
        </b-field>
        <VariantSearch v-model="variant" :selectedVariant="orderLineItem" :isEdit="isEdit" :connectionId="connectionId" @change="updateVariant"/>
        <div class="columns">
          <b-field label="Quantity" class="column">
            <b-input
              type="number"
              autocomplete="off"
              class="is-align-content-start"
              v-model="quantity"
              :controls="false"
              name="quantity"
              step="1"
              min="1"
              @input="isDirty = 1"
            />
          </b-field>
          <b-field label="Price" class="column">
            <b-input
              type="number"
              style="text-align:start"
              autocomplete="off"
              class="is-align-content-start"
              v-model="price"
              :controls="false"
              name="price"
              step="0.01"
              @input="isDirty = 1"
            />
          </b-field>
          <b-field label="Item Cost" class="column">
            <b-input
              type="number"
              autocomplete="off"
              class="is-align-content-start"
              v-model="netPrice"
              :controls="false"
              name="netPrice"
              :disabled="!netPriceIsRequired"
              step="0.01"
              @input="isDirty = 1"
            />
          </b-field>
      </div>
      </section>
      <footer class="modal-card-foot space-between">
        <b-button @click="$parent.close()">Cancel</b-button>
        <div class="space-between">
          <b-button
            v-if="isEdit"
            type="is-primary"
            @click="remove"
            >Remove</b-button>
          <b-button
            v-if="isEdit"
            :disabled="!ready"
            @click="update"
            type="is-primary"
          >Update</b-button>
          <b-button
            v-if="!isEdit"
            type="is-primary"
            :disabled="!ready"
            @click="add"
          >Add</b-button>
        </div>
      </footer>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import VariantSearch from './VariantSearch.vue'
export default {
  components: {
    VariantSearch
  },
  data () {
    return {
      isDirty: false,
      orderLineNo: '1',
      quantity: null,
      price: null,
      netPrice: null,
      uniqueId: null,
      variant: null,
      lineItems: []
    }
  },
  computed: {
    ...mapGetters(['context']),
    json () {
      const json = {
        lineItem: {
          orderLineNo: this.orderLineNo,
          quantity: this.quantity,
          price: this.price,
          netPrice: this.netPrice,
          variant: this.variant
        }
      }
      return json
    },
    ready () {
      if (this.netPriceIsRequired && !this.netPrice) return false
      return (
        this.isDirty &&
        this.orderLineNo !== null &&
        this.quantity !== null &&
        this.price !== null &&
        this.variant !== null
      )
    },
    isUnique () {
      let value = true
      if (this.orderLineNo && this.isDirty) {
        if (this.isEdit && this.orderLineItem && this.orderLineNo === this.orderLineItem.orderLineNo) {
          value = true
        } else {
          this.lineItems.forEach(el => {
            if (el.orderLineNo === this.orderLineNo) {
              value = false
            }
          })
        }
      }
      return value
    }
  },
  methods: {
    add () {
      if (this.ready) {
        const data = this.lineItems
        data.push({
          ...this.json.lineItem,
          randomUniqueId: Math.floor((Math.random() * 100) + 1)
        })
        this.lineItems = data
        this.$emit('update:list', this.lineItems)
        this.resetIsDirty()
        this.$parent.close()
      }
    },
    update () {
      if (this.ready && this.isDirty) {
        const filteredLines = this.lineItems.filter(item => item.randomUniqueId !== this.uniqueId)
        filteredLines.push({
          ...this.json.lineItem,
          randomUniqueId: Math.floor((Math.random() * 100) + 1)
        })
        this.lineItems = filteredLines
        this.$emit('update:list', this.lineItems)
        this.resetIsDirty()
        this.$parent.close()
      }
    },
    remove () {
      const filteredLines = this.lineItems.filter(item => item.randomUniqueId !== this.uniqueId)
      this.$emit('update:list', filteredLines)
      this.$parent.close()
    },
    updateVariant () {
      this.isDirty = 1
    },
    resetIsDirty () {
      this.isDirty = false
    }
  },
  mounted () {
    if (!this.isEdit && this.orderLineItems.length > 0) {
      let nextLineNumDefaultVal = Number(this.orderLineItems[this.orderLineItems.length - 1].orderLineNo) + 1
      // if the nextLineNumDefaultVal is already exist, increment to the next number
      while (this.orderLineItems.filter(({ orderLineNo }) => Number(orderLineNo) === nextLineNumDefaultVal).length > 0) {
        nextLineNumDefaultVal++
      }
      this.orderLineNo = nextLineNumDefaultVal.toString()
    }
    if (this.isEdit && this.orderLineItem !== null) {
      this.orderLineNo = this.orderLineItem.orderLineNo
      this.quantity = this.orderLineItem.quantity
      this.price = this.orderLineItem.price
      this.netPrice = this.orderLineItem.netPrice
      this.uniqueId = this.orderLineItem.randomUniqueId
      this.variant = this.orderLineItem.variant
    }
    if (this.orderLineItems !== null) {
      this.lineItems = this.orderLineItems
    } else {
      this.lineItems = []
    }
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false
    },
    netPriceIsRequired: {
      type: Boolean,
      default: false
    },
    orderLineItem: {
      type: Object,
      default: null
    },
    orderLineItems: {
      type: Array,
      default: null
    },
    connectionId: {
      type: Number,
      default: null
    }
  }
}
</script>
