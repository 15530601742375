<template>
  <div>
    <div class="pa-sm">
      <!-- Filter Header -->
      <div class="columns is-mobile is-marginless">
        <div class="column is-narrow">
          <b-icon :icon="filter.icon"></b-icon>
        </div>
        <div class="column">
          <a href="#" @click.prevent="expanded = !expanded"><p class="has-text-weight-bold mb-sm">{{ filter.label }}</p></a>
        </div>
        <div class="column is-narrow">
          <b-button type="is-primary" size="is-small" outlined @click="expanded = !expanded">{{ (expanded) ? 'Collapse' : 'Expand' }}</b-button>
        </div>
      </div>
      <!-- Selected Options (Tags) -->
      <div v-if="!expanded" class="pb-md px-md">
        <b-field grouped group-multiline>
          <!-- No filters enabled -->
          <div v-if="selected.length === 0">
            <p class="has-text-grey-light mb-sm is-size-7">No options applied for this filter (click 'Expand' to select).</p>
          </div>
          <div v-else v-for="option in selected" class="control" :key="option.value">
            <b-tag type="is-primary" attached closable @close="remove(option)">{{ option.label }}</b-tag>
          </div>
        </b-field>
      </div>
      <!-- Available -->
      <div v-else class="pb-md px-md">
        <!-- Available Options -->
        <b-select expanded @input="add" :placeholder="placeholder">
          <option v-for="result in results" :key="result.id" :value="result">{{ result.label }}</option>
        </b-select>
        <!-- Selected Options Input -->
        <div v-if="selected.length > 0" class="mt-md">
          <div v-for="result in selected" :key="result.value" class="field">
            <b-checkbox v-model="selected" :native-value="result" @input="announce">{{ result.label }}</b-checkbox>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  computed: {
    ...mapGetters(['context']),
    placeholder () {
      return 'Select a vertical...'
    },
    query () {
      return {
        search: this.searchQuery,
        short: 1,
        page: 1,
        limit: 25
      }
    }
  },
  data () {
    return {
      expanded: false,
      loading: false,
      searchQuery: null,
      results: [],
      selected: []
    }
  },
  methods: {
    load () {
      this.loading = true
      this.results = []
      this.$http.get(this.endpoint, { params: this.query }).then(response => {
        if (this.responseType === 'array') {
          response.data.results.forEach(result => {
            this.results.push({ id: null, label: result, value: result })
          })
        } else {
          response.data.results.forEach(result => {
            const label = this.$utils.get(result, this.resourceLabel)
            this.results.push({ id: result.id, label: label, value: result.id })
          })
        }
      }).finally(() => {
        this.loading = false
      })
    },
    add (result) {
      if (result !== null) {
        if (this.$utils.find(this.selected, { value: result.value }) === undefined) {
          this.selected.push({
            value: result.value,
            label: result.label
          })
        }
      }
      this.announce()
    },
    remove (result) {
      const remaining = this.$utils.filter(this.selected, o => {
        return o.value !== result.value
      })
      this.selected = remaining
      this.announce()
    },
    announce () {
      this.$emit('input', this.selected)
    }
  },
  created () {
    if (typeof this.value !== 'undefined' && Array.isArray(this.value) && this.value.length > 0) {
      this.selected = this.value
    }
  },
  mounted () {
    this.load()
  },
  props: ['filter', 'endpoint', 'value', 'responseType', 'resourceLabel']
}
</script>
