<template>
  <div class="section">
    <div class="container">
      <div class="has-background-white pa-xl has-text-centered rounded-xl">
        Payment Reporting
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  computed: {
    ...mapGetters(['context'])
  },
  data () {
    return {

    }
  },
  methods: {

  },
  mounted () {

  },
  props: []
}
</script>
