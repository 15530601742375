import dates from '@/assets/js/dates'

export const connection = {
  label: 'Connection',
  key: 'connection_id',
  icon: 'pulse',
  tooltip: 'Limit results to a set of selected partners.'
}

export const acknowledged = {
  label: 'Acknowledged?',
  key: 'acknowledged',
  icon: 'pulse',
  tooltip: 'Limit results based on acknowledgement.',
  options: [{
    value: 1,
    label: 'Yes'
  }, {
    value: 0,
    label: 'No'
  }]
}

export const createdAt = {
  label: 'Created At',
  key: 'created_at',
  icon: 'pulse',
  tooltip: 'Limit results based on when the return was registered.',
  options: dates
}
