<template>
  <div class="section">
    <div class="container">
      <!-- Page Header -->
      <div class="mb-md">
        <div class="columns is-vcentered">
          <div class="column is-one-third">
            <breadcrumbs :history="[{ label: 'Network', name: 'network.dashboard'}]" current="Product Requests" :hideBack="true" />
          </div>
          <div class="column is-one-third">
            <h1 class="has-text-centered is-size-4">Product Requests</h1>
          </div>
        </div>
      </div>
      <!-- Filters -->
      <div class="has-background-white rounded-lg pa-md mb-xxs">
        <div class="columns">
          <div class="column">
            <access-filters @filter="filter" :applied-filters="filters" :profile="profile" />
          </div>
          <div class="column is-narrow">
            <b-field >
              <b-select v-model="orderBy" expanded @input="sort" size="is-medium">
                <option value="-id">Most Recent</option>
                <option value="id">Oldest</option>
              </b-select>
            </b-field>
          </div>
        </div>
      </div>
      <!-- Toolbar -->
      <div class="has-background-white rounded-lg px-md py-sm mb-xxs">
        <div class="columns is-vcentered is-mobile">
          <div class="column is-narrow">
            <b-dropdown :disabled="loading || selected.length === 0">
              <button class="button" slot="trigger" slot-scope="{ active }" :disabled="selected.length === 0">
                <span>Actions</span>
                <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
              </button>
              <template v-if="context.isSupplier()">
                <b-dropdown-item has-link><a href="#" @click.prevent class="has-text-grey-light">No Actions</a></b-dropdown-item>
              </template>
              <template v-if="context.isMerchant()">
                <b-dropdown-item has-link><a href="#" @click.prevent class="has-text-grey-light">No Actions</a></b-dropdown-item>
              </template>
            </b-dropdown>
          </div>
          <div class="column">
            <div class="has-text-right is-size-7">
              <!-- Counts -->
              <p v-if="loading">Loading...</p>
              <p v-else>
                {{ total }}
                {{ (total === 1) ? 'request' : 'requests' }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- Results -->
      <div>
        <div class="mb-md">
          <b-table
            :data="results"
            :checked-rows.sync="selected"
            :mobile-cards="false"
            :loading="loading"
            hoverable
            checkable
            checkbox-position="left"
            class="is-size-7">
            <template>
              <!-- Image -->
              <b-table-column
                label=""
                cell-class="nowrap"
                width="64"
                centered
                v-slot="props">
                <figure class="image is-48x48" v-if="props.row.product.primary_media !== null">
                  <img :src="cdnTransform(props.row.product.primary_media.url, 48, 48, 'fill')" class="rounded-lg" />
                </figure>
                <figure class="image is-48x48" v-else>
                  <img :src="cdnTransform(null, 48, 48, 'fill')" class="rounded-lg" />
                </figure>
              </b-table-column>
              <!-- Name -->
              <b-table-column
                field="name"
                label="Product"
                cell-class="nowrap"
                v-slot="props">
                <p class="has-text-weight-bold">
                  <a href="#" @click.prevent="inspectAccessRequest = props.row" class="has-text-info">
                    <span class="is-hidden-mobile">{{ props.row.product.parent_name }}</span>
                    <span class="is-hidden-tablet is-size-7">{{ $utils.truncate(props.row.product.parent_name, {length: 48}) }}</span>
                  </a>
                </p>
                <p class="is-size-8">
                  <span class="is-size-8 has-text-grey">SKU</span>
                  <span class="is-size-7"> {{ props.row.product.parent_identifier }}</span>
                </p>
              </b-table-column>
              <!-- Sender -->
              <b-table-column
                label="Requested By"
                cell-class="nowrap"
                width="128"
                centered
                v-slot="props">
                {{ props.row.sender.name }}
              </b-table-column>
              <!-- Status -->
              <b-table-column
                label="Status"
                cell-class="nowrap"
                width="128"
                centered
                v-slot="props">
                <b-tag v-if="props.row.status === 'approved'" type="is-success">Approved</b-tag>
                <b-tag v-else>Pending</b-tag>
              </b-table-column>
            </template>
            <template #empty>
              <div class="py-xxl has-text-grey has-text-centered">
                {{ (loading) ? "Loading data..." : 'No requests found!' }}
              </div>
            </template>
          </b-table>
        </div>
        <b-pagination
          :total="total"
          :current.sync="currentPage"
          :rounded="true"
          :per-page="perPage"
          @change="paginate">
        </b-pagination>
      </div>

      <!-- Inspection Modal -->
      <b-modal
        v-if="(inspectAccessRequest !== null)"
        has-modal-card
        :active="(inspectAccessRequest !== null)"
        :can-cancel="['escape', 'x']"
        @close="inspectAccessRequest = null"
        ><AccessRequestModal :selectedAccessRequest="inspectAccessRequest" />
      </b-modal>

    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CdnTransform from '@/mixins/CdnTransform'
import Filterable from '@/mixins/Filterable'
import AccessFilters from '../components/Access/AccessFilters'
import AccessRequestModal from '@/components/Modals/AccessRequest/AccessRequestModal'

export default {
  components: {
    AccessFilters,
    AccessRequestModal
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/product-access/'
    }
  },
  data () {
    return {
      key: 'product-access',
      // modals
      inspectAccessRequest: null
    }
  },
  mounted () {
    this.load()
  },
  mixins: [CdnTransform, Filterable],
  props: ['profile']
}
</script>
