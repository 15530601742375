<template>
  <div>
    <div class="section">
      <div class="container">
        <!-- Page Header -->
        <div class="mb-md">
          <div class="columns is-vcentered">
            <div class="column is-one-third">
              <breadcrumbs :history="breadcrumbs.history" :current="breadcrumbs.current"  />
            </div>
            <div class="column is-one-third">
              <h1 class="has-text-centered is-size-4">Create Commission Profile</h1>
            </div>
            <div class="column is-one-third">
              <div class="is-pulled-right px-md">
                <b-button
                  class="mr-xl"
                  type="rounded-lg"
                  :disabled="saving"
                  @click.prevent="showConfirmModal = true">
                  Cancel
                </b-button>
                <b-button
                  type="is-primary rounded-lg"
                  :loading="saving"
                  :disabled="!isValid"
                  @click.prevent="createProfile">
                  Save
                </b-button>
              </div>
            </div>
          </div>
        </div>
        <!-- Error Display -->
        <b-message v-if="!saving && 'non_field_errors' in errors" type="is-danger">
          {{ errors.non_field_errors[0] }}
        </b-message>
        <!-- Profile Info -->
        <div class="pb-lg mb-lg">
          <div class="columns">
            <div class="column is-one-fifth">
              <p class="has-text-weight-bold is-size-6 mb-sm">Profile Details</p>
              <p class="has-text-grey is-size-7">
                Configure basic settings for this commission profile.
              </p>
            </div>
            <div class="column">
              <ProfileInfo v-model="profile"/>
            </div>
          </div>
        </div>
        <div v-if="profile && profile.method === 'category'" class="columns">
          <div class="column is-one-fifth">
          <p class="has-text-weight-bold is-size-6 mb-sm">Categories and commission values</p>
          <p class="has-text-grey is-size-7">
            Details about categories and commission values.
          </p>
        </div>
        <div class="column">
          <CategoryInfo v-model="categoryDetails" :categoryDetails="categoryDetails" :profileData="profile"/>
        </div>
        </div>
      </div>
    </div>
    <!-- show confirm modal -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showConfirmModal"
      :can-cancel="['x']"
      ><ConfirmationModal
        :label="'Cancel Commission Profile'"
        :reason="'Are you sure you want to cancel? Any changes made will be discarded.'"
        :confirmButtonText="'Yes, Cancel'"
        @modal:confirm="cancelProfile"
        @modal:close="closeModal"
        />
    </b-modal>
  </div>
  </template>

<script>
import { mapGetters } from 'vuex'
import ConfirmationModal from '@/components/Modals/ConfirmationModal'
import ProfileInfo from '../components/Create/ProfileInfo'
import CategoryInfo from '../components/Create/CategoryInfo'

export default {
  components: {
    ConfirmationModal,
    ProfileInfo,
    CategoryInfo
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/commission-profiles/'
    },
    json () {
      const json = {
        name: this.profile.name,
        method: this.profile.method,
        description: this.profile.description,
        values: this.profile.values
      }
      if (this.profile.method === 'category') {
        const categoryValues = []
        this.categoryDetails.map(el => {
          if (el.retailer_category.category === 'All categories') {
            categoryValues.push({
              start_at: new Date().toISOString(),
              value: el.value / 100
            })
          } else {
            categoryValues.push({
              ...el,
              value: el.value / 100
            })
          }
          return categoryValues
        })
        json.values = categoryValues
      }
      return json
    },
    breadcrumbs () {
      return {
        history: [
          { label: 'Settings', name: 'settings' },
          { label: 'Commission Profiles', name: 'commission-profiles' }
        ],
        current: 'Create'
      }
    }
  },
  data () {
    return {
      profile: null,
      categoryDetails: [],
      showConfirmModal: false,
      saving: false,
      errors: {},
      isValid: false
    }
  },
  methods: {
    cancelProfile () {
      this.$router.push({ name: 'commission-profiles' })
    },
    createProfile () {
      this.saving = true
      this.$http.post(this.endpoint, this.json).then(response => {
        this.errors = {}
        this.$buefy.toast.open({ type: 'is-success', message: 'Commission profile created!' })
        this.$router.push({ name: 'commission-profiles' })
      }).catch(error => {
        this.errors = (error.response.data) ? error.response.data : { detail: 'An unexpected error occurred!' }
      }).finally(() => {
        this.saving = false
      })
    },
    closeModal () {
      this.showConfirmModal = false
    },
    checkValidity () {
      this.isValid = this.json.name && this.json.method && this.json.values.every(item => item.start_at && item.value)
    }
  },
  mounted () {
  },
  watch: {
    profile () {
      this.checkValidity()
    },
    categoryDetails () {
      this.checkValidity()
    }
  }
}
</script>
