<template>
  <div>
    <div class="pa-md">
      <!-- Loading Skeleton -->
      <div v-if="loading">
        <loading message="Loading..." />
      </div>
      <!-- Task is loaded -->
      <div v-else>
        <p class="has-text-centered has-text-weight-bold mb-lg is-size-5">EDI Setup</p>
        <div class="columns is-centered">
          <div class="column is-three-quarters">
            <div class="columns">
              <div class="column is-one-third">
                <div class="has-background-white rounded-lg px-3">
                  <div class="columns">
                    <div class="column has-text-weight-semibold">Setup Steps</div>
                    <div class="column is-vcentered is-narrow is-size-7" @click="showResetTaskModal=true"><b-icon size="is-small" icon="restart"></b-icon>Reset</div>
                  </div>
                  <div v-for="step in setupSteps" :key="step.id">
                    <router-link :is="step.isCompleted ? 'router-link': 'span'" :to="{ name: step.path }"
                      :style="!step.isCompleted && step.path !== currentPathName && 'opacity: 0.5; pointer-events: none'"
                    >
                      <div class="columns mt-3 py-sm has-border-b is-vcentered" :class="step.path === currentPathName && 'has-background-grey-lighter has-text-black'">
                        <div class="column">{{step.label}}</div>
                        <div class="column is-narrow">
                          <b-icon v-if="step.isCompleted" icon="check" type="is-success"></b-icon>
                          <b-icon icon="chevron-right"></b-icon>
                        </div>
                      </div>
                    </router-link>
                  </div>
                  <div class="py-sm is-size-7 has-text-grey-light mt-sm">
                    This onboarding task was added based on your preferences. If you no longer need this task, you can remove it by
                    <a class="has-text-info" href="#" @click.prevent="showDeleteTaskModal = true">clicking here</a>
                  </div>
                </div>
                <div class="is-size-7 has-text-grey-light has-text-centered mt-sm">
                  <router-link :to="{ name:'onboarding.tasks', params: { connection: this.$route.params.connection } }">
                    Back to Tasks
                  </router-link>
                </div>
              </div>
              <div class="column has-background-white rounded-lg ml-md">
                <router-view
                  :ediAccount="ediAccount"
                  :connection="connection"
                  :task="task"
                  :isUpdatingTask="isUpdatingTask"
                  @load:ediAccount="loadEdiAccounts(true)"
                  @task:updated="(body, path)=>updateTaskInfo(body, path)"
                ></router-view>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Delete Task Modal -->
    <b-modal
      v-if="!loading && task !== null"
      :has-modal-card="true"
      :active.sync="showDeleteTaskModal"
      :can-cancel="['x', 'esc']">
      <RemoveTaskModal :taskId="task.id" @task:removed="returnToTasks" />
    </b-modal>
    <!-- Reset Task Modal -->
    <b-modal
      v-if="!loading && task !== null"
      :has-modal-card="true"
      :active.sync="showResetTaskModal"
      :can-cancel="['x', 'esc']">
      <ResetTaskModal :taskId="task.id" @task:reset="onTaskReset" />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import RemoveTaskModal from '@/components/Models/Task/Modals/RemoveTaskModal'
import ResetTaskModal from '@/components/Models/Task/Modals/ResetTaskModal'
export default {
  components: {
    RemoveTaskModal,
    ResetTaskModal
  },
  computed: {
    ...mapGetters(['app', 'context']),
    loading () {
      return (this.loadingEdiAccounts || this.loadingTask)
    },
    currentPathName () {
      return this.$route.name
    },
    endpoint () {
      return this.context.route + '/assigned-tasks/' + this.$route.params.task + '/'
    }
  },
  data () {
    return {
      loadingEdiAccounts: true,
      loadingTask: true,
      isUpdatingTask: false,
      task: null,
      ediAccount: null,
      showDeleteTaskModal: false,
      showResetTaskModal: false,
      setupSteps: [
        {
          id: 'createAccount',
          label: '1. Create EDI Account',
          path: 'onboarding.tasks.self-serve-edi',
          isCompleted: false
        },
        {
          id: 'createSampleItems',
          label: '2. Create Sample Items',
          path: 'onboarding.tasks.self-serve-edi.items',
          isCompleted: false
        },
        {
          id: 'uploadInventory',
          label: '3. Upload Inventory',
          path: 'onboarding.tasks.self-serve-edi.inventory',
          isCompleted: false
        },
        {
          id: 'createOrders',
          label: '4. Create Orders',
          path: 'onboarding.tasks.self-serve-edi.orders',
          isCompleted: false
        },
        {
          id: 'uploadFulfillment',
          label: '5. Upload Fulfillment',
          path: 'onboarding.tasks.self-serve-edi.fulfillment',
          isCompleted: false
        },
        {
          id: 'uploadInvoices',
          label: '6. Upload Invoices',
          path: 'onboarding.tasks.self-serve-edi.invoice',
          isCompleted: false
        },
        {
          id: 'completeSetup',
          label: '7. Complete Setup',
          path: 'onboarding.tasks.self-serve-edi.complete',
          isCompleted: false
        }
      ]
    }
  },
  methods: {
    updateTaskInfo (taskBody, nextPath) {
      this.isUpdatingTask = true
      this.$http.patch(this.endpoint, taskBody).then(response => {
        this.task = response.data
        this.setupSteps.map(el => {
          if (el.id in this.task.info) {
            el.isCompleted = true
          }
          return el
        })
      }).finally(() => {
        this.isUpdatingTask = false
        if (nextPath) {
          this.$router.push({ name: nextPath, params: { connection: this.$route.params.connection, task: this.$route.params.task } })
        }
      })
    },
    loadTask (quiet) {
      if (!quiet) this.loadingTask = true
      this.$http.get(this.endpoint).then(response => {
        this.task = response.data
        this.setupSteps.map(el => {
          if (el.id in this.task.info) {
            el.isCompleted = true
          } else if (this.task.completed_at) {
            el.isCompleted = true
          }
          return el
        })
        this.loadingTask = false
      })
    },
    loadEdiAccounts (silent = false) {
      if (!silent) this.loadingEdiAccounts = true
      this.$http.get(this.context.route + '/edi-accounts/').then(response => {
        if (response.data.results.length > 0) {
          this.ediAccount = response.data.results[0]
          this.$http.get(this.context.route + '/ftp-accounts/').then(response => {
            if (response.data.results.length > 0) {
              this.$set(this.ediAccount, 'ftp_account', response.data.results[0])
            }
            this.loadingEdiAccounts = false
          })
        } else {
          this.loadingEdiAccounts = false
        }
      })
    },
    returnToTasks () {
      this.$buefy.toast.open({ type: 'is-info', message: 'Task removed.' })
      this.$router.push({ name: 'onboarding.tasks', params: { connection: this.$route.params.connection } })
    },
    onTaskReset () {
      this.$buefy.toast.open({ type: 'is-info', message: 'Task reset successfully.' })
      this.$router.push({ name: 'onboarding.tasks', params: { connection: this.$route.params.connection } })
    }
  },
  mounted () {
    this.loadTask()
    this.loadEdiAccounts()
  },
  props: ['connection']
}
</script>
