import Index from './views/Templates'
import Detail from './views/Template'
import Create from './views/CreateTemplate'
import BigCommerceTemplates from './views/BigCommerceTemplates'
import BigCommerceTemplateDetails from './views/BigCommerceDetails'
import ShopifyTemplates from './views/ShopifyTemplates'
import ShopifyTemplateDetails from './views/ShopifyDetails'
import FabricTemplates from './views/FabricTemplates'
import FabricTemplateDetails from './views/FabricDetails'

export default [{
  path: '/settings/templates',
  name: 'templates',
  component: Index
}, {
  path: '/settings/templates/create',
  name: 'template.create',
  component: Create
}, {
  path: '/settings/templates/:id',
  name: 'template',
  component: Detail
}, {
  path: '/settings/templates/bigcommerce/:id',
  name: 'bigcommerce.templates',
  component: BigCommerceTemplates
}, {
  path: '/settings/templates/bigcommerce/:id/templates/:templateId',
  name: 'bigcommerce.template',
  component: BigCommerceTemplateDetails
}, {
  path: '/settings/templates/shopify/:id',
  name: 'shopify.templates',
  component: ShopifyTemplates
}, {
  path: '/settings/templates/shopify/:id/templates/:templateId',
  name: 'shopify.template',
  component: ShopifyTemplateDetails
}, {
  path: '/settings/templates/fabric/:id',
  name: 'fabric.templates',
  component: FabricTemplates
}, {
  path: '/settings/templates/fabric/:id/create',
  name: 'fabric.template.create',
  component: Create
}, {
  path: '/settings/templates/fabric/:id/templates/:templateId',
  name: 'fabric.template',
  component: FabricTemplateDetails
}]
