<template>
  <div class="section">
    <div class="container">
      <!-- Page Header -->
      <div class="py-sm mb-md">
        <div class="columns is-vcentered">
          <div class="column">
            <breadcrumbs :history="breadcrumbs.history" :current="breadcrumbs.current" />
          </div>
        </div>
      </div>
      <!-- Loaded -->
      <div v-if="!loading && user !== null">
        <!-- General Info -->
        <div class="border-b pb-lg mb-lg">
          <BasicInfo :loading="loading" :user="user" />
        </div>
        <!-- Roles & Permissions -->
        <div class="border-b pb-lg mb-lg">
          <Roles :loading="loading" :user="user" @user:saved="load(true)" />
        </div>
        <!-- Reset Info -->
        <div class="border-b pb-lg mb-lg">
          <ResetPassword :loading="loading" :user="user" />
        </div>
        <!-- Security Info -->
        <div class="pb-lg mb-lg">
          <SecurityInfo :loading="loading" :user="user" />
        </div>
      </div>
      <!-- Loading -->
      <div v-else>
        <loading message="Loading details..." />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BasicInfo from '../components/Detail/BasicInfo'
import ResetPassword from '../components/Detail/ResetPassword'
import Roles from '../components/Detail/Roles'
import SecurityInfo from '../components/Detail/SecurityInfo'

export default {
  components: {
    BasicInfo,
    ResetPassword,
    Roles,
    SecurityInfo
  },
  computed: {
    ...mapGetters(['context']),
    breadcrumbs () {
      return {
        current: '#' + this.$route.params.id,
        history: [
          { label: 'Settings', name: 'settings' },
          { label: 'Team Members', name: 'users' }
        ]
      }
    },
    endpoint () {
      return this.context.route + '/users/' + this.$route.params.id + '/'
    }
  },
  data () {
    return {
      loading: true,
      user: null
    }
  },
  methods: {
    load (refresh = false) {
      if (!refresh) this.loading = true
      this.$http.get(this.endpoint).then(response => {
        this.user = response.data
        this.loading = false
      })
    }
  },
  mounted () {
    this.load()
  }
}
</script>
