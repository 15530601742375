<template>
  <div>
    <div>
      <div class="mb-md">
        <b-table
          :data="results"
          :mobile-cards="false"
          :loading="loading"
          hoverable
          class="is-size-7">
          <template>
            <!-- Name -->
            <b-table-column
              label="Title"
              cell-class="nowrap"
              v-slot="props">
              <router-link class="has-text-info has-text-weight-bold" :to="{ name: 'proposal.detail.issues.notes', params: { id: proposal.id, issueId: props.row.id } }">
                {{$utils.truncate(props.row.title, { length: 32 })}}
              </router-link>
            </b-table-column>
            <!-- Message -->
            <b-table-column
              label="Message"
              cell-class="nowrap"
              width="128"
              centered
              v-slot="props">
              {{$utils.truncate(props.row.message, { length: 64 })}}
            </b-table-column>
            <!-- Variants -->
            <b-table-column
              label="Variants"
              cell-class="nowrap"
              width="128"
              centered
              v-slot="props">
              {{props.row.elements.length}}
            </b-table-column>
            <!-- Status -->
            <b-table-column
              cell-class="nowrap"
              centered
              label="Status"
              width="128"
              v-slot="props">
              <IssueStatus :issue="props.row" />
            </b-table-column>
          </template>
          <template #empty>
            <div class="py-xxl has-text-grey has-text-centered">
              {{ (loading) ? "Loading data..." : 'No issues found!' }}
            </div>
          </template>
        </b-table>
      </div>
      <b-pagination
        :total="total"
        :current.sync="currentPage"
        :rounded="true"
        :per-page="perPage"
        @change="paginate">
      </b-pagination>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Filterable from '@/mixins/Filterable'
import IssueStatus from '../components/IssueDetails/IssueStatus'
export default {
  components: {
    IssueStatus
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/proposals/' + this.$route.params.id + '/issues/'
    }
  },
  data: () => {
    return {
      key: 'proposal.issues'
    }
  },
  methods: {

  },
  mounted () {
    this.load()
  },
  mixins: [Filterable],
  props: {
    proposal: {
      type: Object,
      required: true
    }
  }
}
</script>
