<template>
  <div class="section">
    <div class="container">

      <!-- Breadcrumbs -->
      <div class="mb-md">
        <breadcrumbs :history="[{ label: 'Catalog', name: 'catalog'}, { label: 'Import', name: 'import'}]"
          current="Import History"
          :hideBack="true" />
      </div>

      <!-- Loading State -->
      <div v-if="loading">
        <div class="has-background-white rounded-xl pa-md mb-md" v-for="index in placeholders" :key="index">
          <div class="placeholder placeholder-title" style="width:66%"></div>
          <div class="placeholder placeholder-sentence" style="width:50%"></div>
        </div>
      </div>
      <!-- None Found  -->
      <div v-else-if="!loading && feeds.length === 0">
        <div class="has-background-white has-text-grey-light has-text-centered py-3xl rounded-xl">
          <p><b-icon icon="magnify-close" size="is-large"></b-icon></p>
          <p>No history found.</p>
        </div>
      </div>
      <!-- Content -->
      <div v-else>
        <feed-expander class="rounded-xl has-background-white pa-md mb-md" v-for="feed in feeds" :key="feed.id" :preview="feed" />
        <b-pagination
          :total="total"
          :current.sync="currentPage"
          :rounded="true"
          :per-page="perPage"
          @change="updatePage">
        </b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FeedExpander from '../components/FeedExpander'
export default {
  components: {
    FeedExpander
  },
  computed: {
    ...mapGetters(['context']),
    placeholders () {
      return Math.min(5, this.perPage)
    },
    endpoint () {
      return this.context.route + '/feed-queue/'
    },
    query () {
      const query = {
        params: {}
      }
      // ...then order by...
      query.params.order_by = this.orderBy
      // ...then pagination...
      query.params.page = this.currentPage
      query.params.limit = this.perPage
      // ...then the 'always on' params...
      query.params.direction = 'import'
      // ...finally return query string object
      return query
    }
  },
  data () {
    return {
      loading: false,
      feeds: [],
      // current filters & pagination
      filters: {
        search: {},
        facets: {},
        timestamps: {}
      },
      orderBy: '-id',
      currentPage: 1,
      total: 0,
      perPage: 50,
      // defaults pagination settings
      defaults: {
        currentPage: 1,
        perPage: 50,
        orderBy: '-id'
      }
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint, this.query).then(response => {
        this.feeds = response.data.results
        this.loading = false
      })
    },
    updatePage (value) {
      this.currentPage = value
      this.load()
    }
  },
  mounted () {
    this.load()
  }
}
</script>
