<template>
  <div>
    <PerformanceTable
      :label="label"
      :report-data="responseData"
      :dimension="dimension"
      :loading="isLoading"
      :hide-connections="true"
      :paginated="true"
      :show-link-to-detail="true"
      :per-page="10"
      ></PerformanceTable>
  </div>
</template>
<script>
import PerformanceTable from '@/modules/Performance/views/PerformanceTable.vue'
import { mapGetters } from 'vuex'
import debounce from 'lodash/debounce'
export default {
  data () {
    return {
      isLoading: false,
      responseData: {}
    }
  },
  components: { PerformanceTable },
  props: {
    label: {
      type: String
    },
    dimension: {
      type: String,
      default: 'date'
    },
    startDate: {
      type: Date
    },
    endDate: {
      type: Date
    }
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return '/summary/orders/'
    },
    query () {
      const filters = {
        start_date: this.$dates(this.startDate).format('YYYY-MM-DD'),
        end_date: this.$dates(this.endDate).format('YYYY-MM-DD'),
        dimension: this.dimension,
        limit: this.dimension === 'date' ? this.$dates(this.endDate).diff(this.$dates(this.startDate), 'day') : 250
      }
      if (this.context.type === 'vendor') {
        filters.brands = this.context.partner.id
      } else if (this.context.type === 'retailer') {
        filters.retailers = this.context.partner.id
      }
      return filters
    }
  },
  methods: {
    load () {
      this.isLoading = true
      this.$http.get(this.endpoint, { params: this.query }).then(response => {
        this.responseData = response.data
        this.isLoading = false
      })
    }
  },
  watch: {
    startDate (newVal, oldVal) {
      if (newVal !== null) this.load()
    },
    endDate (newVal, oldVal) {
      if (newVal !== null) this.load()
    },
    dimension (newVal, oldVal) {
      if (newVal !== null && newVal !== oldVal) this.load()
    }
  },
  created () {
    this.load = debounce(this.load, 500)
  }
}
</script>
