<template>
  <div class="columns is-mobile is-vcentered">
    <div class="column">
      <p class="is-size-7">{{ location.nickname }}</p>
    </div>
    <div class="column is-one-third-mobile is-half-tablet">
      <b-field>
        <b-input
          autocomplete="off"
          type="number"
          v-model="onHand"
          @input="announce"
        ></b-input>
      </b-field>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      onHand: null
    }
  },
  computed: {
    ...mapGetters(['context'])
  },
  methods: {
    announce () {
      const onHand = (parseInt(this.onHand) > 0) ? parseInt(this.onHand) : 0
      this.$emit('inventory:updated', {
        locationId: this.location.id,
        onHand: onHand
      })
    }
  },
  mounted () {
    this.onHand = this.location.inventory
    this.announce()
  },
  props: ['location']
}
</script>
