<template>
  <div>
    <div class="has-text-weight-semibold">
      Create EDI Account
    </div>
    <!-- Error -->
    <div v-if="('general' in errors)" class="mb-md">
      <p class="has-text-danger">{{ errors.general }}</p>
    </div>
    <!-- Fields -->
    <div class="mt-md">
      <b-field class="mb-0" label="Qualifier ID" >
        <b-input
          size="is-medium"
          v-model="qualifier"
          :disabled="account !== null"
          :maxlength="2"
          @input="(val) => (qualifier = qualifier.toUpperCase())"
        ></b-input>
      </b-field>
      <b-field label="Sender ID" >
        <b-input
          v-model="identifier"
          size="is-medium"
          :disabled="account !== null"
        ></b-input>
      </b-field>
      <b-button
        type="rounded-lg"
        :disabled="!this.ready || this.saving || this.account"
        :loading="saving"
        @click="createAccount"
      >Create Account
      </b-button>
    </div>
    <!-- FTP Details -->
    <div v-if="account && account !== null">
      <LoginDetails :ediAccount="account" showSubHeading />
    </div>
    <!-- Navigate -->
    <div class="py-lg">
      <div class="columns">
        <div class="column">
          <b-button
            type="is-light rounded-lg"
            expanded
            @click="goBack"
            ><b-icon icon="arrow-left"></b-icon>
          </b-button>
        </div>
        <div class="column">
          <b-button
            type="is-primary rounded-lg"
            expanded
            :disabled="this.account === null"
            :loading="isUpdating"
            @click="goForward"
          >Save & Continue</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LoginDetails from '../components/EdiAccount/LoginDetails'
export default {
  components: {
    LoginDetails
  },
  computed: {
    ...mapGetters(['app', 'context']),
    endpoint () {
      return this.context.route + '/edi-accounts/onboarding/'
    },
    body () {
      const json = {
        qualifier: this.qualifier,
        identifier: this.identifier,
        connection: { id: this.connection.id }
      }
      return json
    },
    ready () {
      if (this.ediAccount) {
        return true
      }
      return (this.qualifier && this.identifier)
    }
  },
  data () {
    return {
      qualifier: null,
      identifier: null,
      account: null,
      saving: false,
      isUpdating: false,
      errors: {}
    }
  },
  methods: {
    goBack () {
      this.$router.push({ name: 'onboarding.tasks', params: { connection: this.$route.params.connection } })
    },
    goForward () {
      // if create account already exists in task info then navigate to next step
      if (this.task && this.task.info && this.task.info.createAccount) {
        this.$router.push({ name: 'onboarding.tasks.self-serve-edi.items', params: { connection: this.$route.params.connection, task: this.$route.params.task } })
      } else {
        // Add info to the task to track the tasks
        const taskBody = (typeof this.task.info === 'object') ? { info: this.task.info } : { info: {} }
        taskBody.info.createAccount = true
        this.$emit('task:updated', taskBody, 'onboarding.tasks.self-serve-edi.items')
      }
    },
    createAccount () {
      this.saving = true
      this.$http.post(this.endpoint, this.body).then(response => {
        this.account = {
          ftp_account: response.data.ftp_account,
          identifier: response.data.identifier,
          qualifier: response.data.qualifier
        }
        this.$emit('load:ediAccount')
        this.$buefy.toast.open({ type: 'is-success', message: 'EDI Account and FTP Login created' })
      }).catch(error => {
        this.errors.general = error.response.data.detail
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    if (this.ediAccount) {
      this.account = this.ediAccount
      this.qualifier = this.ediAccount.qualifier
      this.identifier = this.ediAccount.identifier
    }
  },
  props: {
    connection: {
      type: Object,
      required: true
    },
    ediAccount: {
      type: Object
    },
    task: {
      type: Object,
      required: true
    },
    isUpdatingTask: {
      type: Boolean
    }
  },
  watch: {
    isUpdatingTask (value) {
      this.isUpdating = value
    }
  }
}
</script>
