<template>
  <div>
    <div class="columns">
      <div v-if="showImage && imageUrl !== null" class="column is-narrow">
        <figure class="image is-48x48">
          <img :src="imageUrl" class="rounded-lg" />
        </figure>
      </div>
      <div class="column">
        <p class="is-size-7">{{ name }}</p>
        <p>
          <span class="is-size-8 has-text-grey">SKU </span>
          <span class="is-size-7 has-text-weight-bold">{{ supplierSku }}</span>
        </p>
        <p v-if="merchantSku !== null">
          <span class="is-size-8 has-text-grey">Merchant SKU </span>
          <span class="is-size-7 has-text-weight-bold">{{ merchantSku }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CdnTransform from '@/mixins/CdnTransform'

export default {
  computed: {
    ...mapGetters(['context']),
    name () {
      return this.variant.name
    },
    supplierSku () {
      return this.variant.brand_identifier
    },
    merchantSku () {
      return (this.variant.retailer_identifiers.length > 0)
        ? this.variant.retailer_identifiers[0].identifier
        : null
    },
    imageUrl () {
      if (this.showImage === false) return null
      if (this.variant.media.length === 0) return null
      return this.cdnTransform(this.variant.media[0].url, 48, 48, 'fill')
    }
  },
  mixins: [CdnTransform],
  props: {
    variant: {
      type: Object,
      required: true
    },
    showImage: {
      type: Boolean,
      default: true
    }
  }
}
</script>
