<template>
  <form @submit.prevent="save" class="pb-lg">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ (role) ? 'Update' : 'Add' }} Role</p>
      </header>
      <section class="modal-card-body">
        <!-- Reason -->
        <b-field
          label="Role"
          :type="('role_type_id' in errors) ? 'is-danger' : ''"
          :message="('role_type_id' in errors) ? errors['reason_id'][0] : ''">
          <RoleTypeSelect v-model="roleType" />
        </b-field>
      </section>
      <footer class="modal-card-foot space-between">
        <b-button @click="$parent.close()">Close</b-button>
        <b-button native-type="submit" type="is-primary" :disabled="saving || !ready">Save Role</b-button>
      </footer>
    </div>
  </form>

</template>

<script>
import { mapGetters } from 'vuex'
import RoleTypeSelect from '@/components/Models/RoleType/RoleTypeSelect'

export default {
  components: {
    RoleTypeSelect
  },
  computed: {
    ...mapGetters(['app', 'context']),
    endpoint () {
      return (this.role)
        ? this.context.route + '/users/' + this.user.id + '/roles/' + this.role.id + '/'
        : this.context.route + '/users/' + this.user.id + '/roles/'
    },
    method () {
      return (this.role) ? 'patch' : 'post'
    },
    body () {
      if (this.roleType === null) return {}
      return {
        role_type: {
          id: this.roleType.id
        }
      }
    },
    ready () {
      return (this.roleType)
    }
  },
  data () {
    return {
      roleType: null,
      saving: false,
      errors: {}
    }
  },
  methods: {
    save () {
      this.saving = true
      this.$http[this.method](this.endpoint, this.body).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Role saved!' })
        this.$parent.close()
      }).finally(() => {
        this.saving = false
      })
    }
  },
  props: ['role', 'user'],
  watch: {
    role: function (newVal, oldVal) { // watch it

    }
  }
}
</script>
