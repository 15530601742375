<template>
  <div class="has-background-white rounded-lg">
    <!-- Loaded -->
    <div v-if="preferences !== null">
      <div class="mb-lg">
        <FieldLabel label="Cost Method" required />
        <div class="has-text-grey">
          <p class="mb-md">
            All incoming items on a purchase order must have an associated cost to {{ context.partner.name }}.
            Choose 'Specified on Order' if the costs of items will be maintained by {{ context.partner.name }}.
            If {{ app.platform }} is maintaining costs, choose 'Specified by Platform'. If {{ app.platform }} should
            calculate the cost using a percentage of retail, choose 'Commission' (and specify a default commission rate).
          </p>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <b-field label="Cost Method" custom-class="required">
            <b-select
              type="text"
              v-model="method"
              size="is-medium"
              expanded
              @input="announce">
              <option v-for="option in options" :key="option.id" :value="option">{{ option.name }}</option>
            </b-select>
          </b-field>
        </div>
        <div v-if="method && method.value === 'revshare'" class="column">
          <b-field label="Your Commission (%)" custom-class="required">
            <b-input
              type="number"
              v-model="commission"
              size="is-medium"
              expanded
              :max="99"
              :min="1"
              @input="announce" />
          </b-field>
        </div>
      </div>
      <!-- Example -->
      <div v-if="method && method.value === 'revshare'" class="mt-md">
        <b-message type="is-info">
          Example: if you sell a <Currency value="100" /> item, you will owe the supplier <Currency :value="100 - commission" />.
        </b-message>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  computed: {
    ...mapGetters(['app', 'context']),
    endpoint () {
      return this.context.route + '/retailer-preferences/'
    },
    json () {
      const preferences = [{
        preference: { id: this.methodPreference.id },
        option: { id: this.method.id }
      }]
      if (this.method && this.method.value === 'revshare') {
        preferences.push({
          preference: { id: this.commissionPreference.id },
          value: this.commission
        })
      }
      if (this.method && this.method.value !== 'revshare') {
        preferences.push({
          preference: { id: this.commissionPreference.id },
          value: 0
        })
      }
      return preferences
    }
  },
  data () {
    return {
      methodPreference: null,
      method: null,
      commissionPreference: null,
      commission: null,
      options: []
    }
  },
  methods: {
    announce () {
      this.$emit('input', this.json)
    }
  },
  mounted () {
    // locate preferences
    this.methodPreference = this.$utils.find(this.preferences, ['code', 'default_net_price_method'])
    this.commissionPreference = this.$utils.find(this.preferences, ['code', 'default_commission_rate'])

    if (typeof this.methodPreference !== 'undefined') {
      // set the options - skip commission profile for retailer onboarding
      this.methodPreference.options.forEach(option => {
        if (option.code !== 'commission_profile') {
          this.options.push({
            id: option.id,
            name: option.name,
            value: option.value
          })
        }
      })
      // set the current values
      if (this.methodPreference.retailer_preference) {
        this.method = this.$utils.find(this.options, ['value', this.methodPreference.retailer_preference.value])
        // if method is commission, also configure the commission rate preference
        if (
          typeof this.commissionPreference !== 'undefined' &&
          this.method.value === 'revshare' &&
          this.commissionPreference.retailer_preference
        ) {
          this.commission = this.commissionPreference.retailer_preference.value
        }
      }
      this.announce()
    }
  },
  props: {
    preferences: {
      type: Array,
      required: true
    }
  }
}
</script>
