<template>
  <InspectionModal
    :endpoint="endpoint + '?short=1'"
    :loadDetail="loadDetail"
    :seed="selectedCancel">
    <template v-slot:default="props">
      <form>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Inspect Cancel</p>
          </header>
          <section class="modal-card-body">
            <!-- Loaded -->
            <div v-if="!props.loading && props.record !== null" class="">
              <p class="has-text-weight-bold mb-sm">Cancel Details</p>
              <table class="table is-fullwidth is-striped is-size-7 is-narrow border">
                <tbody>
                  <tr>
                    <th>ID</th>
                    <td>{{ props.record.id }}</td>
                  </tr>
                  <tr>
                    <th>PO #</th>
                    <td>{{ props.record.purchase_order_number }}</td>
                  </tr>
                  <tr>
                    <th>Reason</th>
                    <td>{{ props.record.reason }}</td>
                  </tr>
                  <tr>
                    <th>Cancel Received</th>
                    <td>{{ $dates.utc(props.record.canceled_at).local().format('MMM D, YYYY') }}</td>
                  </tr>
                  <tr>
                    <th>Acknowledged</th>
                    <td>
                      <span v-if="props.record.acknowledged_at">{{ $dates.utc(props.record.acknowledged_at).local().format('MMM D, YYYY') }}</span>
                      <span v-else class="has-text-grey-light">Not Acknowledged</span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <!-- Lines -->
              <div>
                <p class="has-text-weight-bold mb-sm">Canceled Line Items</p>
                <table class="table is-fullwidth is-striped is-size-7 is-narrow border is-vcentered">
                  <thead>
                    <tr>
                      <th>Item</th>
                      <th class="has-text-centered" style="width:128px">Qty</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="line in props.record.cancel_lines" :key="line.id">
                      <td>
                        <Identifiers :variant="line.variant" :showImage="false" nameInteraction="none" />
                      </td>
                      <td class="has-text-centered">{{ line.quantity }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-- Loading -->
            <div v-else>
              <loading message="Loading item..." />
            </div>
          </section>
          <footer class="modal-card-foot" style="justify-content:space-between">
            <b-button @click="$parent.close()">Close</b-button>
            <b-button type="is-primary" :disabled="!canAcknowledge" @click="acknowledge">Acknowledge Cancel</b-button>
          </footer>
        </div>
      </form>
    </template>
  </InspectionModal>
</template>

<script>
import Identifiers from '@/components/Models/Variant/Identifiers'
import InspectionModal from '../InspectionModal'
import { mapGetters } from 'vuex'

export default {
  components: {
    Identifiers,
    InspectionModal
  },
  data () {
    return {
      loadDetail: false
    }
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      if (this.selectedCancel !== null) {
        return this.context.route + '/cancels/' + this.selectedCancel.id + '/'
      }
      return null
    },
    canAcknowledge () {
      return this.selectedCancel !== null && this.selectedCancel.acknowledged_at === null && this.context.type !== this.selectedCancel.canceled_by
    }
  },
  methods: {
    acknowledge () {
      this.saving = true
      this.$http.put(`${this.endpoint}acknowledge/`).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Cancel acknowledged.' })
        this.$emit('cancel:acknowledged')
      }).catch(error => {
        let errorMessage = 'An unexpected error occured!'
        if (error.response && error.response.data.detail) {
          errorMessage = error.response.data.detail
        }
        this.$buefy.toast.open({ type: 'is-danger', message: errorMessage })
      }).finally(() => {
        this.saving = false
        this.$parent.close()
      })
    }
  },
  mounted () {

  },
  props: ['selectedCancel']
}
</script>
