<template>
  <div>
    <div class="columns">
      <div class="column is-one-fifth">
        <p class="has-text-weight-bold is-size-6 mb-sm">Sync Variants by Date</p>
        <p class="has-text-grey is-size-7">Attempt to sync variants between Shopify and {{ app.platform }} using a date range.</p>
      </div>
      <div class="column">
        <div class="has-background-white rounded-lg">
          <div class="pa-lg">
            <!-- Intro Text (Merchant) -->
            <div class="is-size-7 mb-lg">
              <p class="mb-md">
                This utility searches your Shopify store for all items created
                within specified date range and attempts to sync them with
                variants in {{ app.platform }}.
                We will check
                if the Shopify variant's SKU field matches the Merchant SKU, the UPC
                or the Supplier SKU (in that order).
              </p>
            </div>
            <!-- Form -->
            <form @submit.prevent="syncVariants" class="mb-lg">
              <!-- Require Dates Selector -->
              <div class="mb-lg">
                <p class="has-text-weight-bold is-size-6 mb-md">Select Date Range</p>
                <div class="field">
                  <b-radio v-model="requireDates" native-value="yes">Look for products created between two given dates</b-radio>
                </div>
                <div class="field" v-if="auth.isStaff">
                  <b-radio v-model="requireDates" native-value="no">Run sync against all products (can be very, very slow)</b-radio>
                </div>
              </div>
              <!-- Min/Max Range Fields -->
              <div>
                <div class="columns">
                  <div class="column is-half">
                    <div class="mb-lg">
                      <p class="has-text-weight-bold is-size-6 mb-sm">Select Minimum Date</p>
                      <b-datepicker
                        v-model="minDate"
                        size="is-medium"
                        placeholder="Click to select..."
                        :disabled="processing || requireDates === 'no'"
                        :minDate="minSelectableDate"
                        :maxDate="maxSelectableDate"
                        icon="calendar-today"
                        trap-focus>
                      </b-datepicker>
                    </div>
                    <div class="mb-lg">
                      <p class="has-text-weight-bold is-size-6 mb-sm">Select Maximum Date</p>
                      <b-datepicker
                        v-model="maxDate"
                        size="is-medium"
                        :disabled="processing || requireDates === 'no'"
                        :minDate="minSelectableDate"
                        :maxDate="maxSelectableDate"
                        placeholder="Click to select..."
                        icon="calendar-today"
                        trap-focus>
                      </b-datepicker>
                    </div>
                  </div>
                </div>
              </div>
              <div class="columns">
                <div class="column is-narrow">
                  <b-button
                    type="is-primary"
                    native-type="submit"
                    size="is-medium"
                    :loading="processing"
                    :disabled="processing || !valid"
                  >Run Sync</b-button>
                </div>
              </div>
            </form>
            <!-- Job Result -->
            <div class="is-size-7">
              <div v-if="processing">
                <p class="has-text-grey-light is-italic">Processing request...</p>
              </div>
              <div v-else-if="errorMessage !== null">
                <p class="has-text-grey-light is-italic">{{ errorMessage }}</p>
              </div>
              <div v-else-if="!processing && job !== null">
                <span class="has-text-weight-bold has-text-success">Retry Scheduled!</span>
                Your input resulted in Job <a href="#" class="has-text-info" @click.prevent="inspectJob = job">#{{ job.id }}</a>.
                Please refer to the job status for more information.
              </div>
              <div v-else>
                <p class="has-text-grey-light is-italic">Click the 'Run Sync' to kickoff the sync process.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Inspect Job -->
    <b-modal
      v-if="(inspectJob !== null)"
      has-modal-card
      :active="(inspectJob !== null)"
      :can-cancel="['escape', 'x']"
      @close="inspectJob = null"
      ><JobModal :selectedJob="inspectJob" />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import JobModal from '@/components/Modals/Job/JobModal'
export default {
  components: {
    JobModal
  },
  computed: {
    ...mapGetters(['app', 'auth', 'context']),
    endpoint () {
      return this.context.route + '/shopify/' + this.account.id + '/sync-products/'
    },
    valid () {
      if (this.account === null) return false
      if (this.requireDates === 'yes') {
        if (!this.minDate) return false
        if (!this.maxDate) return false
        const min = this.$dates(this.minDate)
        const max = this.$dates(this.maxDate)
        if (min.isValid() && max.isValid()) {
          const diff = max.diff(min, 'day')
          return (diff < 8 && min.isSameOrBefore(max))
        } else {
          return false
        }
      }
      return true
    },
    json () {
      const json = {
        use_celery: true
      }
      if (this.requireDates && this.valid) {
        json.created_at_min = this.$dates(this.minDate).format('YYYY-MM-DD')
        json.created_at_max = this.$dates(this.maxDate).format('YYYY-MM-DD')
      }
      return json
    },
    maxSelectableDate () {
      if (this.minDate !== null) {
        const min = this.$dates(this.minDate)
        if (min.isValid()) {
          return min.add(7, 'day').toDate()
        }
      }
      return this.$dates().add(1, 'day').toDate()
    },
    minSelectableDate () {
      return this.$dates().subtract(30, 'day').toDate()
    }
  },
  data () {
    return {
      requireDates: 'yes',
      minDate: null,
      maxDate: null,
      processing: false,
      job: null,
      inspectJob: null,
      errorMessage: null
    }
  },
  methods: {
    syncVariants () {
      this.processing = true
      this.job = null
      this.$http.put(this.endpoint, this.json).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Sync scheduled!' })
        this.errorMessage = null
        this.job = response.data
      }).catch(() => {
        this.$buefy.toast.open({ type: 'is-danger', message: 'Sync failed!' })
      }).finally(() => {
        this.processing = false
      })
    }
  },
  mounted () {

  },
  props: ['account']
}
</script>
