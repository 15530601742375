<template>
  <div>
    <p class="mb-sm has-text-info"><b>Inventory Details</b></p>
    <table class="table is-fullwidth is-striped is-size-7 is-narrow border">
      <tbody>
        <tr>
          <th>Item ID</th>
          <td class="has-text-right">
            <router-link class="has-text-info has-text-weight-bold" :to="{ name: 'catalog.item', params: { variantId: inventory.variant.id }}">{{ inventory.variant.id }}</router-link>
          </td>
        </tr>
        <tr>
          <th>Item SKU</th>
          <td class="has-text-right">
            {{ inventory.variant.brand_identifier }}
          </td>
        </tr>
        <tr>
          <th>UPC</th>
          <td class="has-text-right">
            <span v-if="inventory.variant.upc">{{ inventory.variant.upc }}</span>
            <span v-else class="has-text-grey is-italic">Not set</span>
          </td>
        </tr>
        <tr>
          <th>Policy</th>
          <td class="has-text-right">
            {{ $utils.upperFirst(inventory.inventory_policy) }}
          </td>
        </tr>
        <tr>
          <th>Availability</th>
          <td class="has-text-right has-text-weight-bold">
            <span v-if="inventory.discontinued" class="has-text-danger">Discontinued</span>
            <span v-else-if="inventory.inventory === 0" class="has-text-warning">Out of Stock</span>
            <span v-else class="has-text-success">In Stock</span>
          </td>
        </tr>
        <tr>
          <th>On Hand</th>
          <td class="has-text-right">
            <span v-if="inventory.inventory === 9999" class="has-text-danger">&infin;</span>
            <span v-else>{{ inventory.inventory }}</span>
          </td>
        </tr>
        <tr v-if="inventory.inventory === 0">
          <th>Estimated Return</th>
          <td class="has-text-right">
            <span v-if="inventory.estimated_availability_date !== null">
              {{ $dates(inventory.estimated_availability_date).format('MMM D, YYYY') }}
            </span>
            <span v-else class="has-text-grey-light is-italic">Unknown</span>
          </td>
        </tr>
        <tr>
          <th>Last Update</th>
          <td class="has-text-right">
            <span v-if="inventory.inventory_updated_at !== null">
              {{ $dates.utc(inventory.inventory_updated_at).local().format('MMM D, YYYY @ h:mm A') }}
            </span>
            <span v-else class="has-text-light is-italic">Never</span>
          </td>
        </tr>
        <tr>
          <th>Last Submission</th>
          <td class="has-text-right">
            <span v-if="inventory.inventory_last_submitted_at !== null">
              {{ $dates.utc(inventory.inventory_last_submitted_at).local().format('MMM D, YYYY @ h:mm A') }}
            </span>
            <span v-else class="has-text-grey-light is-italic">Never</span>
          </td>
        </tr>
      </tbody>
    </table>
    <!-- Location Breakout (Suppliers Only) -->
    <div v-if="context.isSupplier()">
      <p class="mb-sm has-text-info"><b>Inventory by Location</b></p>
      <table class="table is-fullwidth is-striped is-size-7 is-narrow border">
        <tbody>
          <tr v-for="location in inventory.locations" :key="location.id">
            <th>{{ location.nickname }}</th>
            <td class="has-text-right">
              {{ location.inventory }} units
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  data () {
    return {

    }
  },
  computed: {
    ...mapGetters(['context'])
  },
  methods: {

  },
  mounted () {

  },
  props: ['inventory']
}
</script>
