<template>
  <form @submit.prevent="generateReturnLabel">
    <div class="modal-card">
      <header class="modal-card-head">
        <p v-if="!loading && rma !== null" class="modal-card-title">
          <span v-if="rma.rma_number">RMA #{{ rma.rma_number }}</span>
          <span v-else>RMA #{{ rma.id }} (RC ID)</span>
        </p>
        <p v-else class="modal-card-title">Loading...</p>
      </header>
      <section class="modal-card-body is-paddingless">
        <!-- Pane 1: A Return Label can be retrieved -->
        <div v-if="!loading && rma !== null">
          <!-- Exising Labels -->
          <component
            :is="component"
            :rma="rma"
            :rmaReturn="rmaReturn"
            :connection="connectionObject"
            :supplier="supplier"
            @labels:reload="reloadLabels"
            @label:create="createLabel"
            @label:get="getLabel"
          ></component>
        </div>
        <!-- Loading -->
        <div v-else>
          <loading message="Loading RMA..." />
        </div>
      </section>
      <footer class="modal-card-foot space-between">
        <b-button @click="$parent.close()">Close</b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import ExistingReturnLabels from './ExistingReturnLabels'
import GenerateReturnLabel from './GenerateReturnLabel'
import DisplayReturnLabel from './DisplayReturnLabel'

export default {
  components: {
    ExistingReturnLabels,
    GenerateReturnLabel,
    DisplayReturnLabel
  },
  data () {
    return {
      component: 'ExistingReturnLabels',
      loading: true,
      connection: null,
      rma: null,
      rmaLabels: [],
      rmaReturn: null
    }
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/rmas/' + this.selectedRma.id + '/'
    },
    connectionObject () {
      if (this.connection !== null) return { id: this.connection.id }
      if (this.connectionId !== null) return { id: this.connectionId }
      return null
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint).then(response => {
        this.rma = response.data
        if (!this.connectionId) {
          this.$http.get(this.context.route + '/orders/' + this.rma.order_id + '/?skip_attributes=1').then(response => {
            this.connection = { id: response.data.connection_id }
            this.loading = false
          })
        } else {
          this.loading = false
        }
      })
    },
    createLabel () {
      this.component = 'GenerateReturnLabel'
    },
    getLabel (returnLabel) {
      this.rmaReturn = returnLabel
      this.component = 'DisplayReturnLabel'
    },
    reloadLabels () {
      this.rmaReturn = null
      this.component = 'ExistingReturnLabels'
    }
  },
  mounted () {
    this.load()
  },
  props: ['selectedRma', 'connectionId', 'supplier']
}
</script>
