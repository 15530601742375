<template>
<div>
  <div class="section">
    <div class="container">
      <!-- Page Header -->
      <div class="columns is-vcentered">
        <div class="column is-one-third">
          <breadcrumbs :history="[{ label: 'Settings', name: 'settings'}]" current="Terms of Service" :hideBack="true" />
        </div>
        <div class="column is-one-third">
          <h1 class="has-text-centered is-size-4">Terms of Service</h1>
        </div>
      </div>
      <!-- List -->
      <div>
        <!-- Results Found -->
        <div v-if="!loading && results.length > 0">
          <b-table
            :data="results"
            :mobile-cards="false"
            :loading="loading"
            class="mb-lg rounded-lg is-size-7">
            <template>
              <b-table-column
                label="ID"
                width="64"
                centered
                v-slot="props">
                <p class="has-text-grey-light">{{ props.row.id }}</p>
              </b-table-column>
              <b-table-column
                label="Name"
                v-slot="props">
                <p><a href="#" @click.prevent="openDocumentModal(props.row)" class="has-text-info has-text-weight-bold">{{ props.row.name }}</a></p>
                <!-- </router-link> -->
              </b-table-column>
              <b-table-column
                label="Version"
                width="100"
                centered
                v-slot="props">
                <p class="has-text-grey">v{{ props.row.version }}</p>
              </b-table-column>
              <b-table-column
                label="Acknowledged"
                v-slot="props">
                <p v-if="props.row.acknowledgements.length > 0">
                  {{ props.row.acknowledgements[0].name }}
                  on
                  {{ $dates.utc(props.row.acknowledgements[0].created_by).local().format('MMM D, YYYY') }}
                </p>
                <p v-else class="has-text-grey">
                  <em>Not Acknowledged</em>
                </p>
              </b-table-column>
              <b-table-column
                width="64"
                numeric
                v-slot="props">
                <a :href="props.row.content" target="_blank"><b-icon icon="open-in-new"></b-icon></a>
              </b-table-column>
            </template>
          </b-table>
        </div>
        <!-- None Found  -->
        <div v-else-if="!loading && results.length === 0">
          <div class="has-background-white has-text-grey-light has-text-centered py-3xl rounded-xl">
            <p><b-icon icon="magnify-close" size="is-large"></b-icon></p>
            <p>No documents found.</p>
            <p>Please check your filters and try again.</p>
          </div>
        </div>
        <!-- Loading -->
        <div v-else>
          <div class="has-background-white pa-lg mb-xs rounded-lg" v-for="index in 2" :key="index">
            <div class="placeholder placeholder-sentence"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal with Terms -->
  <b-modal
    :width="1200"
    :active.sync="showDocumentModal"
    @close="closeDocumentModal"
    has-modal-card>
    <div v-if="document !== null" class="modal-card">
      <section class="modal-card-body is-paddingless">
        <div v-if="showIframe">
          <iframe width="100%" height="400" style="height:400px !important; display:block" :src="iframeUrl"></iframe>
        </div>
        <div v-else>
          <loading message="Loading..." />
        </div>
      </section>
      <footer class="modal-card-foot space-between">
        <b-button @click="openDocument">Open Fullscreen</b-button>
        <b-button
          type="is-primary"
          @click="acknowledgeDocument"
          :disabled="saving || isAcknowledged"
          :loading="saving"
        >{{ (isAcknowledged) ? 'Document Acknowledged' : 'Acknowledge Document' }}</b-button>
      </footer>
    </div>
  </b-modal>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/terms-and-conditions/'
    },
    iframeUrl () {
      return this.document.content
    },
    isAcknowledged () {
      return (this.document && this.document.acknowledgements.length > 0)
    }
  },
  data () {
    return {
      loading: true,
      results: [],
      showDocumentModal: false,
      showIframe: false,
      document: null,
      saving: false,
      errors: {}
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint).then(response => {
        this.results = response.data.results
        this.loading = false
      })
    },
    acknowledgeDocument () {
      this.saving = true
      const endpoint = this.endpoint + this.document.id + '/acknowledge/'
      this.$http.put(endpoint).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Document acknowledged!' })
        this.closeDocumentModal()
        this.load()
      }).finally(() => {
        this.saving = false
      })
    },
    openDocumentModal (document) {
      this.document = document
      this.showDocumentModal = true
      this.showIframe = true
    },
    openDocument (document) {
      window.open(this.document.content, '_blank')
    },
    closeDocumentModal () {
      this.showDocumentModal = false
      this.document = null
      this.showIframe = false
    }
  },
  mounted () {
    this.load()
  },
  props: []
}
</script>
