<template>
  <form @submit.prevent>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Publish Products</p>
      </header>
      <section class="modal-card-body">
        <b-message type="is-info">
          Please select the platform that you want to receive this product.
          You should see changes reflected in your platform within 5-10 minutes.
        </b-message>
        <div>
          <div class="columns is-vcentered">
            <!-- Platform Account Select -->
            <div class="column">
              <PlatformAccountSelect v-model="platformAccount" />
            </div>
          </div>
          <div class="columns is-vcentered">
            <!-- Template Select -->
            <div class="column">
              <b-field label="Template">
                <b-select
                  v-model="template"
                  placeholder="Select Template"
                  :loading="loadingTemplates"
                  :disabled="loadingTemplates || !platformAccount"
                  expanded>
                  <option v-for="template in templates" :value="template.id" :key="template.name">
                    <span v-if="template.name"> {{ template.name }}</span>
                    <span v-else> ID: {{ template.id }}</span>
                  </option>
                </b-select>
              </b-field>
            </div>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot space-between">
        <button class="button" type="button" @click="$parent.close()">Close</button>
        <b-button
          type="is-primary"
          @click="publishAll"
          :disabled="!platformAccount || saving"
          :loading="saving"
        >Publish Products</b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import PlatformAccountSelect from '@/components/PlatformAccountSelect'

export default {
  components: {
    PlatformAccountSelect
  },
  computed: {
    ...mapGetters(['context']),
    ready () {
      return (this.platformAccount)
    },
    endpoint () {
      if (this.platformAccount !== null) {
        switch (this.platformAccount.platform.code) {
          case 'shopify':
            return this.context.route + '/platform-accounts/' + this.platformAccount.id + '/push-products/'
          case 'fabric':
            return this.context.route + '/fabric/' + this.platformAccount.id + '/pim/push-bulk-products/'
          default:
            return null
        }
      }
      return null
    },
    json () {
      const body = {}
      const productIds = []
      if (this.account !== null) {
        this.products.forEach(product => {
          productIds.push(product.id)
        })
        body.products = productIds
      }
      if (this.template !== null) {
        body[`${this.platformAccount.platform.code}_template_id`] = this.template
      }
      return body
    }
  },
  methods: {
    publishAll () {
      this.saving = true
      this.$http.post(this.endpoint, this.json).then(response => {
        this.$parent.close()
        this.$buefy.toast.open({ type: 'is-success', message: 'Products published!' })
      }).finally(() => {
        this.saving = false
      })
    },
    getTemplates () {
      if (this.platformAccount !== null) {
        this.loadingTemplates = true
        const endpoint = `${this.context.route}/${this.platformAccount.platform.code}/${this.platformAccount.id}/templates/`
        this.$http.get(endpoint).then(response => {
          this.templates = response.data.results
        }).finally(() => {
          this.loadingTemplates = false
        })
      }
    }
  },
  data () {
    return {
      platformAccount: null,
      template: null,
      templates: [],
      saving: false,
      loadingTemplates: false
    }
  },
  watch: {
    platformAccount () {
      this.getTemplates()
    }
  },
  props: {
    products: {
      type: Array,
      required: true
    }
  }
}
</script>
