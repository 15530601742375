<template>
  <div>
    <div class="columns is-vcentered is-variable is-2">
      <!-- Publish Product (e.g. to Shopify) -->
      <template>
        <div class="column is-one-quarter">
          <b-button type="is-light" size="is-medium" expanded  @click="$emit('action:publish')">Publish Product</b-button>
        </div>
        <div class="column is-one-quarter">
          <b-button type="is-light" size="is-medium" expanded  @click="$emit('action:applyTransformer')">Apply Transformer</b-button>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  data () {
    return {

    }
  },
  computed: {
    ...mapGetters(['context'])
  },
  methods: {

  },
  mounted () {

  },
  props: []
}
</script>
