<template>
  <div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Change Owner</p>
      </header>
      <section class="modal-card-body">
        <b-field label="Select Teammate">
          <b-select
            v-model="user"
            size="is-medium"
            :loading="loading"
            placeholder="Select new owner..."
            expanded>
            <option v-for="user in users" :key="user.id" :value="user.id">{{ user.first_name + ' ' + user.last_name }}</option>
          </b-select>
        </b-field>
        <!-- Error Display -->
        <b-message v-if="'non_field_errors' in errors" type="is-danger">
            {{ errors.non_field_errors[0] }}
          </b-message>
      </section>
      <footer class="modal-card-foot space-between" >
        <b-button @click="$parent.close()">Close</b-button>
        <b-button
          type="is-primary"
          :disabled="saving || !ready"
          :loading="saving"
          @click="save">Change Owner</b-button>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['auth', 'context']),
    loading () {
      return (this.loadingUsers)
    },
    json () {
      if (this.context.isSupplier() && 'brand' in this.proposal.sender) {
        return { sender: { user_id: this.user } }
      }
      if (this.context.isSupplier() && 'brand' in this.proposal.recipient) {
        return { recipient: { user_id: this.user } }
      }
      if (this.context.isMerchant() && 'retailer' in this.proposal.sender) {
        return { sender: { user_id: this.user } }
      }
      if (this.context.isMerchant() && 'retailer' in this.proposal.recipient) {
        return { recipient: { user_id: this.user } }
      }
      return null
    },
    ready () {
      return (this.user)
    }
  },
  data () {
    return {
      loadingUsers: true,
      users: [],
      user: null,
      saving: false,
      errors: {}
    }
  },
  methods: {
    save () {
      this.saving = true
      const endpoint = this.context.route + '/proposals/' + this.proposal.id + '/'
      this.$http.patch(endpoint, this.json).then(response => {
        this.$buefy.toast.open({ type: 'is-info', message: 'Proposal Archived!' })
        this.$emit('proposal:reassigned')
        this.$parent.close()
      }).catch(error => {
        this.errors = (error.response.data) ? error.response.data : { detail: 'An unexpected error occurred!' }
      }).finally(() => {
        this.saving = false
      })
    },
    loadUsers () {
      this.loadingUsers = true
      const endpoint = this.context.route + '/users/'
      const query = {
        params: {
          pagination: 0,
          short: 1,
          type: 'standard',
          order_by: 'last_name',
          status: 'active,invited'
        }
      }
      this.$http.get(endpoint, query).then(response => {
        response.data.results.forEach(user => {
          if (user.email !== this.auth.user.email) {
            this.users.push(user)
          }
        })
        this.loadingUsers = false
      })
    }
  },
  mounted () {
    this.loadUsers()
  },
  props: {
    proposal: {
      type: Object,
      required: true
    }
  }
}
</script>
