<template>
  <span>{{ presenter }}</span>
</template>

<script>
export default {
  computed: {
    presenter () {
      switch (this.feed.data_type) {
        case 'proposals':
          return 'Proposal'
        default:
          return this.$utils.upperFirst(this.feed.data_type)
      }
    }
  },
  props: {
    feed: {
      type: Object,
      required: true
    }
  }
}
</script>
