<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Bulk Remove Tags</p>
    </header>
    <section class="modal-card-body" style="height: 200px;">
      <b-message type="is-info" v-if="commonTags.length <= 0">No common tags available to be removed from the selected orders</b-message>
      <b-dropdown v-else
        type="is-light"
        :disabled="isDeleting"
        :scrollable="true"
        :max-height="300"
        multiple
        aria-role="list">
        <template #trigger>
          <b-button
            label="Select Tags"
            icon-right="menu-down" />
        </template>
        <b-dropdown-item
          v-for="({ ids, tag }, index) in commonTags"
          :key="index"
          :value="tag"
          aria-role="listitem">
          <div>
            <b-checkbox
              type="is-info"
              :native-value="ids"
              v-model="checkboxGroup">
              {{ tag }}
            </b-checkbox>
          </div>
        </b-dropdown-item>
      </b-dropdown>
    </section>
    <footer class="modal-card-foot is-flex is-justify-content-space-between">
      <b-button @click="$parent.close()">Close</b-button>
      <b-button @click="bulkDelete()" :disabled="checkboxGroup.length <= 0" type="is-primary">Remove</b-button>
    </footer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {
  },
  data () {
    return {
      loading: false,
      commonTags: [],
      checkboxGroup: [],
      isDeleting: false
    }
  },
  computed: {
    ...mapGetters(['context'])
  },
  watch: {},
  methods: {
    bulkDelete () {
      this.isDeleting = true
      const endpoint = `${this.context.route}/orders/bulk-delete-tags/`
      const body = {
        object_tag_item_ids: this.checkboxGroup.flat()
      }
      this.$http.post(endpoint, body).then(() => {
        this.$buefy.toast.open({ type: 'is-success', message: 'order tags deleted' })
      }).finally(() => {
        this.isDeleting = false
        this.$emit('bulkUpdated', true)
        this.$parent.close()
      })
    }
  },
  mounted () {
    this.commonTags = this.orders.map(({ tags }) => (tags))
      .reduce((x, y) => x.filter((obj) =>
        y.some((yTag) => {
          if (yTag.tag === obj.tag) {
            obj.ids = [obj.id, yTag.id]
          }
          return obj.tag === yTag.tag
        })
      )).map(({ tag, ids }) => {
        return { ids, tag, isChecked: false }
      })
  },
  props: {
    orders: {
      default: null
    }
  }
}
</script>
