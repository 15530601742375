<template>
  <div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title" v-if="loading">Loading Comments...</p>
        <p class="modal-card-title" v-else-if="order !== null">PO #{{ order.purchase_order_number }}</p>
      </header>
      <section class="modal-card-body">
        <div v-if="!loading && order !== null">
          <messages :order="order" />
        </div>
        <div v-else>
          <loading message="Loading..." />
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Messages from './Messages'

export default {
  components: {
    Messages
  },
  data () {
    return {
      loading: false,
      order: null
    }
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/orders/' + this.selectedOrder.id + '/'
    },
    query () {
      return {
        params: {
          fields: 'id,memos',
          skip_attributes: 1
        }
      }
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint, this.query).then(response => {
        this.order = response.data
        this.loading = false
      })
    }
  },
  mounted () {
    if (this.selectedOrder !== null) {
      this.load()
    }
  },
  props: {
    selectedOrder: {
      default: null
    }
  }
}
</script>
