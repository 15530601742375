<template>
  <div>
    <div class="mb-md">
      <p class="is-size-7 has-text-grey">
        If you are new to {{ app.platform }}, start here! Please confirm
        the information below (or make any corrections) and create for
        your free account!
      </p>
    </div>
    <div v-if="error" class="mb-md">
      <b-message type="is-danger">{{ error }}</b-message>
    </div>
    <form @submit.prevent="save">
      <div class="mb-sm">
        <p class="mb-sm has-text-weight-bold">Company</p>
        <b-input v-model="companyName" type="text" size="is-medium" />
      </div>
      <div class="mb-sm">
        <div class="columns">
          <div class="column">
            <p class="mb-sm has-text-weight-bold">First Name</p>
            <b-input v-model="firstName" type="text" size="is-medium" />
          </div>
          <div class="column">
            <p class="mb-sm has-text-weight-bold">Last Name</p>
            <b-input v-model="lastName" type="text" size="is-medium" />
          </div>
        </div>
      </div>
      <div class="mb-xl">
        <p class="mb-sm has-text-weight-bold">Email</p>
        <b-input v-model="email" type="email" size="is-medium" :readonly="true" :disabled="true" />
      </div>
      <div class="mb-lg">
        <b-checkbox v-model="acknowledgement">I agree to the
          <a href="#" class="has-text-info" @click.prevent="$emit('showTerms')">{{ app.productName }} Terms &amp;
            Conditions</a>.
        </b-checkbox>
      </div>
      <div class="mb-lg">
        <div class="columns">
          <div class="column">
            <p class="mb-sm has-text-weight-bold">Set Password</p>
            <b-field>
              <b-input autocomplete="new-password" type="password" name="newPassword" v-model="newPassword"
                password-reveal></b-input>
            </b-field>
            <div class="po-password-strength-wrapper">
              <password-meter :password="newPassword" @score="setScore" />
            </div>
          </div>
          <div class="column">
            <p class="mb-sm has-text-weight-bold">Confirm Password</p>
            <b-input v-model="confirmPassword" type="password" size="is-medium" password-reveal />
          </div>
        </div>
        <p class="has-text-centered">
          <i v-text="validationMessage.body" :class="validationMessage.class"></i>
        </p>
      </div>
      <div>
        <b-button native-type="submit" type="is-primary" size="is-medium" :loading="saving" :disabled="saving || !ready"
          expanded>
          Create New Account
        </b-button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import passwordMeter from 'vue-simple-password-meter'

export default {
  components: {
    passwordMeter
  },
  computed: {
    ...mapGetters(['app', 'api', 'auth', 'context']),
    endpoint () {
      return this.api.baseRoute + '/connection-invites/accept/'
    },
    json () {
      return {
        code: this.code,
        company: this.companyName,
        first_name: this.firstName,
        last_name: this.lastName,
        email: this.invite.email,
        password: this.newPassword
      }
    },
    ready () {
      return (
        this.acknowledgement &&
        this.code &&
        this.companyName &&
        this.firstName &&
        this.lastName &&
        this.email &&
        this.newPassword &&
        this.newPassword === this.confirmPassword &&
        this.passwordScore >= 2
      )
    },
    validationMessage () {
      const message = {
        class: 'has-text-warning',
        body: null
      }
      if (!this.newPassword) {
        message.class = 'has-text-grey-light'
        message.body = 'Please enter a password to secure your account.'
      } else if (this.newPassword !== this.confirmPassword) {
        message.body = 'Please make sure to confirm your new password.'
      } else if (this.passwordScore < 2) {
        message.body = 'Please strengthen your password until you see a green score.'
      } else {
        message.class = 'has-text-success'
        message.body = 'Your password meets our requirements!'
      }
      return message
    }
  },
  data () {
    return {
      saving: false,
      companyName: this.invite.company_name,
      firstName: this.invite.first_name,
      lastName: this.invite.last_name,
      email: this.invite.email,
      acknowledgement: false,
      newPassword: null,
      confirmPassword: null,
      passwordScore: 0,
      error: null,
      errors: {}
    }
  },
  methods: {
    save () {
      this.saving = true
      this.$http.post(this.endpoint, this.json).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Account Created!' })
        this.$router.push({ name: 'auth.login' })
      }).catch(error => {
        if (error.response && error.response.status === 400) {
          // clean up error messages...
          if ('detail' in error.response.data) {
            if (error.response.data.detail === 'email: user with this email already exists.') {
              this.error = 'A user with this email already exists. You might try signing in instead.'
            } else {
              this.error = error.response.data.detail
            }
          } else {
            this.error = 'An unexpected error occured. Please review your input and try again.'
          }
        }
      }).finally(() => {
        this.saving = false
      })
    },
    setScore ({ score }) {
      this.passwordScore = score
    }
  },
  props: ['code', 'invite']
}
</script>
