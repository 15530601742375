<template>
  <div class="py-md">
    <div>
      <span class="has-text-weight-semibold">Create a Proposal Department</span>
      <p class="has-text-grey-light">Create one or more proposal departments in settings. When done, return to this page to ensure it is marked as complete.</p>
    </div>
    <!-- Fields -->
    <div class="mt-md">
      <!-- Path to settings template -->
      <router-link :to="{ name:'departments' }" class="has-text-info is-underlined">
        Settings / Proposal Departments
      </router-link>
    </div>
    <!-- Navigate -->
    <div class="py-lg">
      <div class="columns">
        <div class="column">
          <b-button
            type="is-light rounded-lg"
            expanded
            @click="goBack"
            ><b-icon icon="arrow-left"></b-icon>
          </b-button>
        </div>
        <div class="column">
          <b-button
            type="is-primary rounded-lg"
            expanded
            :loading="isUpdating"
            @click="goForward"
          >Complete</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {
  },
  computed: {
    ...mapGetters(['app', 'context'])
  },
  data () {
    return {
      isUpdating: false,
      setupIsCompleted: false
    }
  },
  methods: {
    goBack () {
      this.$router.push({ name: 'retailer.onboarding.rulesets' })
    },
    goForward () {
      this.taskComplete()
    },
    taskComplete () {
      const endpoint = this.context.route + '/assigned-tasks/' + this.$route.params.task + '/complete/'
      const taskBody = (typeof this.task.info === 'object') ? { info: this.task.info } : { info: {} }
      taskBody.info.proposalDepartment = true
      this.$http.put(endpoint, taskBody).then(() => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Task Completed!' })
        this.$router.push({ name: 'retailer.onboarding' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    if (this.task && this.task.info && this.task.info.proposalDepartment) {
      this.setupIsCompleted = true
    }
  },
  props: {
    task: {
      type: Object,
      require: true
    },
    isUpdatingTask: {
      type: Boolean
    }
  },
  watch: {
    isUpdatingTask (value) {
      this.isUpdating = value
    }
  }
}
</script>
