<template>
  <form @submit.prevent="update" novalidate autocomplete="off">
    <div class="columns">
      <div class="column is-one-fifth">
        <p class="has-text-weight-bold is-size-6 mb-sm">General Settings</p>
        <p class="has-text-grey is-size-7">Basic profile information that identifies this user across the {{ app.platform }} platform.</p>
      </div>
      <div class="column">
        <div class="has-background-white rounded-xl">
          <div class="pa-lg pb-xl">
            <div class="mb-md">
              <div class="columns">
                <div class="column is-half">
                  <b-field
                    label="First Name"
                    :type="('first_name' in errors) ? 'is-danger' : ''"
                    :message="('first_name' in errors) ? errors['first_name'][0] : ''"
                    expanded>
                      <b-input
                        autocomplete="off"
                        type="text"
                        name="firstName"
                        v-model="firstName"
                        @input="isDirty = 1"
                      ></b-input>
                  </b-field>
                </div>
                <div class="column is-half">
                  <b-field
                    label="Last Name"
                    :type="('last_name' in errors) ? 'is-danger' : ''"
                    :message="('last_name' in errors) ? errors['last_name'][0] : ''"
                    expanded>
                      <b-input
                        autocomplete="off"
                        type="text"
                        name="lastName"
                        v-model="lastName"
                        @input="isDirty = 1"
                      ></b-input>
                  </b-field>
                </div>
              </div>
            </div>
            <b-field
              label="Title"
              :type="('title' in errors) ? 'is-danger' : ''"
              :message="('title' in errors) ? errors['title'][0] : ''"
              expanded>
                <b-input
                  autocomplete="off"
                  type="text"
                  name="title"
                  v-model="title"
                  @input="isDirty = 1"
                ></b-input>
            </b-field>
          </div>
          <div class="border-t">
            <div class="columns is-vcentered is-marginless">
              <div class="column">
                <div class="px-sm has-text-grey-light">
                  <i v-if="isDirty">Click 'Update' to save your changes</i>
                </div>
              </div>
              <div class="column is-narrow">
                <div class="px-sm">
                  <b-button native-type="submit" type="is-primary rounded-lg" :disabled="updating || !isDirty">Update</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  computed: {
    ...mapGetters(['app', 'api', 'auth', 'context']),
    endpoint () {
      return this.api.baseUrl + '/v1/users/me/'
    },
    json () {
      return {
        first_name: this.firstName,
        last_name: this.lastName,
        title: this.title
      }
    }
  },
  data () {
    return {
      isDirty: false,
      firstName: null,
      lastName: null,
      title: null,
      errors: [],
      updating: false
    }
  },
  methods: {
    update () {
      this.updating = true
      this.$http.patch(this.endpoint, this.json).then(response => {
        this.$store.dispatch('refreshUser', response.data)
        this.updating = false
        this.isDirty = false
        this.$buefy.toast.open({ type: 'is-success', message: 'Profile updated!' })
      })
    }
  },
  mounted () {
    this.firstName = this.auth.user.firstName
    this.lastName = this.auth.user.lastName
    this.title = this.auth.user.title
  },
  props: []
}
</script>
