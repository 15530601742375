<template>
  <form @submit.prevent>
    <div class="modal-card">
      <header class="modal-card-head">
        Select Categories
      </header>
      <section class="modal-card-body">
        <div class="columns is-vcentered">
          <!-- Search -->
          <div class="column">
            <FilterToolbar :allowFilters="false" @search="search" :appliedFilters="filters"/>
          </div>
          <!-- Counts -->
          <div class="column is-narrow">
            <div class="has-text-right is-size-7">
                <p v-if="loading">Loading...</p>
                <p v-else>{{ total }} {{ (total === 1) ? 'record' : 'records' }} found.</p>
              </div>
          </div>
        </div>
        <!-- Results -->
        <b-table
        :data="results"
        :checked-rows.sync="checkedRows"
        checkable
        :is-row-checkable="row => isCheckable(row)"
        :header-checkable="false"
        :mobile-cards="false"
        hoverable
        class="is-size-7">
        <template>
          <!-- Category -->
          <b-table-column
            label="Category"
            v-slot="props">
            {{props.row.category}}
          </b-table-column>
          <!-- No.Of Items -->
          <b-table-column
            label="Number of Items"
            v-slot="props">
            {{props.row.item_count}}
          </b-table-column>
        </template>
        <template #empty>
          <div class="py-xxl has-text-grey has-text-centered">
            {{ (loading) ? "Loading data..." : 'No categories found!' }}
          </div>
        </template>
      </b-table>
      <div class="mt-md">
        <b-pagination
          :total="total"
          :current.sync="currentPage"
          :rounded="true"
          :per-page="perPage"
          @change="paginate">
        </b-pagination>
      </div>
      </section>
      <footer class="modal-card-foot space-between">
        <b-button @click="close">Close</b-button>
        <b-button type="is-primary" @click="confirm">Next</b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import Filterable from '@/mixins/Filterable'
import FilterToolbar from '@/components/Filters/FilterToolbar'
export default {
  components: {
    FilterToolbar
  },
  computed: {
    ...mapGetters(['app', 'context']),
    endpoint () {
      return this.context.route + '/categories/'
    }
  },
  data () {
    return {
      checkedRows: []
    }
  },
  methods: {
    isCheckable (row) {
      let check = true
      const findRow = this.selectedCategories.find(el => el.id === row.id)
      if (findRow && findRow.id) {
        check = false
      }
      return check
    },
    close () {
      this.$emit('modal:close')
    },
    confirm () {
      this.$emit('modal:next', this.checkedRows)
    },
    search (searchQuery) {
      this.filters.search = {
        parameter: 'search',
        query: searchQuery
      }
      this.filter(this.filters)
    }
  },
  mixins: [Filterable],
  mounted () {
    this.load()
  },
  props: {
    selectedCategories: {
      type: Array
    }
  },
  watch: {
    loading: {
      deep: true,
      immediate: true,
      handler (value) {
        if (!value) {
          // to set the checked rows
          const rows = []
          if (this.results && this.results.length) {
            this.results.forEach(res => {
              if (this.selectedCategories.some(cat => cat.id === res.id)) {
                rows.push(res)
              }
            })
          }
          this.checkedRows = rows
        }
      }
    }
  }
}
</script>
