<template>
  <div>
    <div class="columns">
      <div class="column is-one-fifth">
        <p class="has-text-weight-bold is-size-6 mb-sm">Sync Inventory</p>
        <p class="has-text-grey is-size-7">Sync inventory between Shopify and {{ app.productName }}</p>
      </div>
      <div class="column">
        <div class="has-background-white pa-lg rounded-lg">
          <div class="columns is-vcentered">
            <div class="column is-three-quarters">
              <p class="is-size-7">
                This utility loops through all of your items in {{ app.productName }} (using the SKU or UPC)
                and imports the current inventory from Shopify. If you are only syncing specific locations with
                {{ app.productName }}, only inventory from allowed locations will be imported.
              </p>
            </div>
            <div class="column">
              <form @submit.prevent="syncInventory" class="column">
                <b-button
                  type="is-primary"
                  native-type="submit"
                  size="is-medium"
                  expanded
                  :loading="processing"
                  :disabled="processing">
                  Sync Inventory
                </b-button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {},
  computed: {
    ...mapGetters(['app', 'context']),
    endpoint () {
      return `${this.context.route}/shopify/${this.account.id}/sync-inventory/`
    }
  },
  data () {
    return {
      processing: false
    }
  },
  methods: {
    syncInventory () {
      this.processing = true
      this.$http.put(this.endpoint).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Sync Inventory Started!' })
      }).catch(() => {
        this.$buefy.toast.open({ type: 'is-danger', message: 'Sync Inventory failed!' })
      }).finally(() => {
        this.processing = false
      })
    }
  },
  mounted () {},
  props: ['account']
}
</script>
