<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Return Proposal</p>
    </header>
    <section class="modal-card-body">
      <b-message type="is-info">Are you sure you want to return this proposal?</b-message>
      <b-field label="Please select the reason for returning the proposal" class="mb-lg">
        <ReturnReasonSelect v-model="returnReason" :isMandatory="true"/>
      </b-field>
      <b-field v-if="returnReason && returnReason.return_reason==='Other'" label="Return Reason">
        <b-input
          type="textarea"
          v-model="message"
          expanded />
      </b-field>
    </section>
    <footer class="modal-card-foot space-between" >
      <b-button @click="$parent.close()">Close</b-button>
      <b-button
        type="is-primary"
        :disabled="saving"
        :loading="saving"
        @click="save">Return Proposal</b-button>
    </footer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ReturnReasonSelect from '../ReturnReasonSelect'

export default {
  components: {
    ReturnReasonSelect
  },
  computed: {
    ...mapGetters(['context'])
  },
  data () {
    return {
      saving: false,
      message: null,
      returnReason: null
    }
  },
  methods: {
    save () {
      this.saving = true
      const endpoint = this.context.route + '/proposals/' + this.proposal.id + '/'
      const body = {
        status: 'revised',
        return_reason: this.returnReason
      }
      if (this.message !== null) {
        body.return_reason_detail = this.message
      }
      this.$http.patch(endpoint, body).then(response => {
        this.$buefy.toast.open({ type: 'is-info', message: 'Proposal returned!' })
        this.$parent.close()
        this.$emit('proposal:revised')
      }).finally(() => {
        this.saving = false
      })
    }
  },
  props: ['proposal']
}
</script>
