<template>
  <form @submit.prevent="save">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Add Invoice</p>
      </header>
      <section class="modal-card-body">
        <!-- Must have the connection loaded to show the body content -->
        <div v-if="!loading && connection !== null">
          <!-- Error Display -->
          <b-message v-if="'non_field_errors' in errors" type="is-danger">
            {{ errors.non_field_errors[0] }}
          </b-message>
          <!-- Invoice Metadata -->
          <div class="mb-xl">
            <div class="columns">
              <div class="column is-two-fifths">
                <b-field
                  label="Invoice Number"
                  :type="('invoice_number' in errors) ? 'is-danger' : ''"
                  :message="('invoice_number' in errors) ? errors['invoice_number'][0] : ''"
                  expanded>
                    <b-input
                      autocomplete="off"
                      type="text"
                      size="is-medium"
                      v-model="invoiceNumber"
                    ></b-input>
                </b-field>
                <p class="has-text-grey is-size-7">
                  Must be unique.
                </p>
              </div>
              <div class="column">
                <BillTo v-model="billTo" />
              </div>
            </div>
          </div>
          <!-- Invoice Lines -->
          <div class="mb-xl">
            <p class="has-text-centered has-text-weight-bold has-text-primary mb-lg is-uppercase">Invoice Lines</p>
            <EditInvoiceLine
              class="has-background-white-ter rounded-lg px-lg mb-xxs"
              v-for="line in order.order_lines"
              :key="line.id"
              :line="line"
              :fillAll="fillAll"
              :isDirty="isDirty"
              :currency="order.currency"
              @update:line="updateLine"
            />
          </div>
          <!-- Adjustments -->
          <div class="mb-xl" v-if="!isInvoiceIntegrityOn" >
            <!-- Header -->
            <div class="columns">
              <div class="column"></div>
              <div class="column">
                <p class="has-text-centered has-text-weight-bold has-text-primary is-uppercase">Adjustments</p>
              </div>
              <div v-if="!isInvoiceIntegrityOn" class="column">
                <p class="is-size-7 has-text-right">
                  <a href="#" @click.prevent="showAdjustmentForm = !showAdjustmentForm" class="has-text-info is-size-7">Add Adjustments</a>
                </p>
              </div>
              <div v-else class="column"></div>
            </div>
            <!-- Applied Adjustments -->
            <div v-if="!isInvoiceIntegrityOn && adjustments.length > 0">
              <div v-for="(adjustment, index) in adjustments" :key="index">
                <EditAdjustmentLine
                  :adjustment="adjustment"
                  :subtotal="subtotal"
                  :index="index"
                  :currency="order.currency"
                  @adjustment:updated="updateAdjustment"
                  @adjustment:remove="removeAdjustment(index)"
                 />
              </div>
            </div>
            <div v-if="!isInvoiceIntegrityOn && adjustments.length <= 0" class="has-background-white-ter pa-md rounded-lg">
              <p class="has-text-centered has-text-grey is-italic">- No Adjustments -</p>
            </div>
            <!-- Add Adjustment Form -->
            <div v-if="showAdjustmentForm">
              <EditAdjustment
                class="mt-sm"
                :subtotal="subtotal"
                @close="showAdjustmentForm = false"
                @adjustment:create="addAdjustment"
              />
            </div>
          </div>
          <!-- Totals -->
          <div class="mb-lg">
            <p class="has-text-centered has-text-weight-bold has-text-primary mb-sm is-uppercase">Totals</p>
            <div class="has-background-white-ter rounded-lg pa-md mb-xxs">
              <div class="columns is-mobile is-vcentered">
                <div class="column">
                  <p class="has-text-weight-bold">Subtotal</p>
                </div>
                <div class="column is-one-fifth">
                  <p class="has-text-weight-bold has-text-right"><CurrencyLocale :value="subtotal" :currency="order.currency"/></p>
                </div>
              </div>
            </div>
            <div  v-if="!isInvoiceIntegrityOn" class="has-background-white-ter rounded-lg pa-md mb-xxs">
              <div class="columns is-mobile is-vcentered">
                <div class="column">
                  <p class="has-text-weight-bold">Adjustments</p>
                </div>
                <div class="column is-one-fifth">
                  <p class="has-text-weight-bold has-text-right">
                    <span v-if="adjustmentsTotal >= 0"><CurrencyLocale :value="adjustmentsTotal" :currency="order.currency"/></span>
                    <span v-else class="has-text-danger"><CurrencyLocale :value="adjustmentsTotal" :currency="order.currency"/></span>
                  </p>
                </div>
              </div>
            </div>
            <div class="has-background-white-ter rounded-lg pa-md mb-xxs">
              <div class="columns is-mobile is-vcentered">
                <div class="column">
                  <p class="has-text-weight-bold">Total Due</p>
                </div>
                <div class="column is-one-fifth">
                  <p class="has-text-weight-bold has-text-right"><CurrencyLocale :value="invoiceTotal" :currency="order.currency"/></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Loading Connection -->
        <div v-else class="pa-3xl">
          <loading message="Loading connection settings..." />
        </div>
      </section>
      <footer class="modal-card-foot space-between">
        <button class="button" type="button" @click="$parent.close()">Close</button>
        <b-button
          type="is-primary"
          native-type="submit"
          :loading="saving"
          :disabled="!ready || saving">
          Register Invoice
        </b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import BillTo from '@/components/Models/Location/BillTo'
import EditInvoiceLine from './EditInvoiceLine'
import EditAdjustmentLine from './EditAdjustmentLine'
import EditAdjustment from './EditAdjustment'

export default {
  components: {
    BillTo,
    EditInvoiceLine,
    EditAdjustmentLine,
    EditAdjustment
  },
  data () {
    return {
      loading: true,
      connection: null,
      billTo: null,
      invoiceNumber: null,
      fillAll: true,
      isDirty: false,
      invoiceLines: {},
      adjustments: [],
      errors: {},
      saving: false,
      showAdjustmentForm: false,
      focusAdjustment: null,
      isInvoiceIntegrityOn: false
    }
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/invoices/'
    },
    ready () {
      if (!this.order) return false
      return (
        this.billTo !== null &&
        this.invoiceNumber !== null &&
        this.invoiceNumber !== '' &&
        Object.keys(this.invoiceLines).length > 0
      )
    },
    subtotal () {
      let subtotal = 0
      for (const variantId in this.invoiceLines) {
        subtotal += this.invoiceLines[variantId].subtotal
      }
      return subtotal
    },
    adjustmentsTotal () {
      let total = 0
      this.adjustments.forEach(adjustment => {
        total += adjustment.price && parseFloat(adjustment.price)
      })
      return total
    },
    invoiceTotal () {
      return this.subtotal + this.adjustmentsTotal
    },
    json () {
      const json = {
        retailer_id: this.order.retailer.id,
        // order_id: this.order.id,
        purchase_order_number: this.order.purchase_order_number,
        invoice_number: this.invoiceNumber,
        invoice_lines: [],
        charges: [],
        allowances: []
      }
      // invoice lines
      for (const id in this.invoiceLines) {
        json.invoice_lines.push({
          variant: { id: id },
          name: this.invoiceLines[id].name,
          description: this.invoiceLines[id].description,
          quantity: this.invoiceLines[id].quantity,
          price: this.invoiceLines[id].netPrice
        })
      }
      // charges
      this.adjustments.forEach(adjustment => {
        const price = adjustment.price ? adjustment.price : 0
        const obj = {
          adjustment_code: {
            code: adjustment.code.value
          },
          description: adjustment.description,
          price: this.$numbers(Math.abs(price)).format({ mantissa: 2 })
        }
        if (adjustment.type === 'charge') {
          json.charges.push(obj)
        } else if (adjustment.type === 'allowance') {
          json.allowances.push(obj)
        }
      })
      return json
    }
  },
  methods: {

    updateLine (payload) {
      this.isDirty = true
      if (payload.quantity > 0) {
        const line = {
          variant: {
            id: payload.variantId
          },
          quantity: payload.quantity,
          name: payload.name,
          description: payload.description,
          netPrice: payload.netPrice,
          subtotal: payload.total
        }
        this.$set(this.invoiceLines, payload.variantId, line)
      } else {
        this.$delete(this.invoiceLines, payload.variantId)
      }
    },
    loadConnection () {
      const endpoint = this.context.route + '/connections/' + this.order.connection_id + '/'
      this.$http.get(endpoint).then(response => {
        this.isInvoiceIntegrityOn = Object.keys(response.data.options).includes('enforce_invoice_integrity')
        response.data.connection_adjustments.forEach(adjustment => {
          this.adjustments.push({
            source: 'Automatic',
            type: adjustment.adjustment_type,
            calculation: adjustment.adjustment_amount_type,
            code: {
              value: adjustment.adjustment_code.code,
              label: adjustment.adjustment_code.description
            },
            description: adjustment.description,
            amount: adjustment.adjustment_amount,
            price: null
          })
        })
        this.connection = response.data
        this.loading = false
      })
    },
    save () {
      this.saving = true
      this.$http.post(this.endpoint, this.json).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Invoice registered!' })
        this.$emit('invoice:registered', response.data)
        this.$parent.close()
      }).catch(error => {
        this.$buefy.toast.open({ type: 'is-danger', message: 'An error occurred! Please check your input and try again!' })
        this.errors = (error.response.data) ? error.response.data : { detail: 'An unexpected error occured!' }
      }).finally(() => {
        this.saving = false
      })
    },
    trackLines (line, value) {
      this.$set(this.invoiceLines, line.variant.id, value)
    },
    addAdjustment (adjustment) {
      this.adjustments.push(adjustment)
    },
    updateAdjustment (payload) {
      this.$set(this.adjustments, payload.index, payload)
    },
    removeAdjustment (index) {
      this.adjustments.splice(index, 1)
    }
  },
  mounted () {
    this.loadConnection()
  },
  props: ['order']
}
</script>
