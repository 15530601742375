<template>
  <div>
    <b-field label="Integration Method" custom-class="required"></b-field>
    <!-- options -->
    <div class="mb-md">
      <b-field>
        <b-radio
          v-model="option"
          @input="announce"
          native-value='api'>
          API
        </b-radio>
      </b-field>
      <b-field>
        <b-radio
          v-model="option"
          @input="announce"
          native-value='shopify'>
          Shopify
        </b-radio>
      </b-field>
      <b-field>
        <b-radio
          v-model="option"
          @input="announce"
          native-value='bigcommerce'>
          BigCommerce
        </b-radio>
      </b-field>
      <!-- <b-field>
        <b-radio
          v-model="option"
          @input="announce"
          native-value="fabric">
          fabric
        </b-radio>
      </b-field> -->
      <p v-if="option === null">Select an integration method</p>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  computed: {
    ...mapGetters(['app', 'context'])
  },
  data () {
    return {
      option: null
    }
  },
  methods: {
    announce () {
      this.$emit('input', this.option)
    }
  },
  mounted () {
    if (this.task.info && this.task.info.selectedOption) {
      this.option = this.task.info.selectedOption
    }
  },
  props: {
    task: {
      type: Object,
      require: true
    }
  }
}
</script>
