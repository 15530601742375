<template>
  <form @submit.prevent="save" autocomplete="off">
    <!-- Intro -->
    <div class="has-text-centered">
      <p class="is-size-4 mb-sm has-text-black">Inventory Sync</p>
      <div class="columns is-centered">
        <div class="column is-three-quarters">
          <p class="has-text-centered">
            {{ app.platform }} Merchants require frequent inventory updates in order
            to reduce cancellations due to stockouts. Our Shopify integration
            can sync inventory with {{ app.platform }} automatically. You may turn this integration
            off if you would prefer to update inventory manually each day.
          </p>
        </div>
      </div>
    </div>
    <!-- Fields -->
    <div class="pb-lg mb-lg">
      <!-- Loaded -->
      <div v-if="!loading" class="columns is-centered">
        <div class="column is-two-thirds">
          <div class="py-lg">
            <p class="has-text-weight-bold mb-sm">Sync inventory automatically? (Highly recommended)</p>
            <div class="columns is-mobile is-vcentered">
              <div class="column is-narrow">
                <div class="field">
                  <b-switch v-model="sync" type="is-success" true-value="Yes" false-value="No">{{ sync }}</b-switch>
                </div>
              </div>
              <div class="column">
                <p v-if="sync === 'No'" class="has-text-grey-light">Click the switch to activate</p>
              </div>
            </div>
            <p v-if="webhook !== null" class="has-text-grey-light">Note: this integration is currently enabled.</p>
          </div>
        </div>
      </div>
      <!-- Loading -->
      <div v-else class="py-lg">
        <loading message="Loading..." />
      </div>
    </div>
    <!-- Buttons -->
    <div class="mb-xl">
      <div class="columns is-centered">
        <div class="column is-three-quarters-tablet is-two-thirds-widescreen">
          <div class="columns">
            <div class="column">
              <b-button type="is-light rounded-lg" size="is-large" @click="back" expanded>
                <b-icon icon="arrow-left"></b-icon>
              </b-button>
            </div>
            <div class="column">
              <b-button
                native-type="submit"
                type="is-primary rounded-lg"
                size="is-large"
                expanded
                :loading="saving"
                :disabled="!validated || saving"
              >Save & Continue</b-button>
            </div>
          </div>
        </div>
      </div>
      <p v-if="'detail' in errors" class="has-text-danger">{{ error.detail }}</p>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['app', 'context']),
    endpoint () {
      return this.context.route + '/shopify/' + this.account.id + '/webhooks/'
    },
    validated () {
      return true
    },
    json () {
      return {
        webhook_topic: 'products/update'
      }
    },
    webhook () {
      if (this.webhooks.length === 0) return null
      const hook = this.$utils.find(this.webhooks, ['topic', 'products/update'])
      return (typeof hook !== 'undefined') ? hook : null
    }
  },
  data () {
    return {
      loading: true,
      sync: 'Yes',
      saving: false,
      errors: {},
      webhooks: []
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint).then(response => {
        this.loading = false
        this.webhooks = response.data.results
      })
    },
    back () {
      this.$emit('change:step', 'locations')
    },
    save () {
      if (this.sync === 'Yes' && this.webhook === null) {
        const endpoint = this.context.route + '/shopify/' + this.account.id + '/register-webhook/'
        this.saving = true
        this.$http.put(endpoint, this.json).then(response => {
          this.saving = false
          this.$buefy.toast.open({ type: 'is-success', message: 'Sync enabled!' })
          this.$emit('complete')
        })
      } else if (this.sync === 'No' && this.webhook !== null) {
        this.saving = true
        this.$http.delete(this.endpoint + this.webhook.id + '/').then(() => {
          this.saving = false
          this.$buefy.toast.open({ type: 'is-success', message: 'Sync disabled!' })
          this.$emit('complete')
        })
      } else {
        // didn't do anything
        this.$buefy.toast.open({ type: 'is-success', message: 'Settings saved!' })
        this.$emit('complete')
      }
    }
  },
  mounted () {
    this.load()
  },
  props: ['account']
}
</script>
