import Request from '../Request'
export default class ApplyTransformer extends Request {
  constructor (config) {
    super()
    this.method = 'put'
    this.route = config.route
    this.product = config.product
    this.transformer = config.transformer
    this.validate()
  }

  id () {
    return this.product.id
  }

  validate () {
    if (!this.product || !this.transformer) {
      this.isValid = false
      this.status = 'Skip'
      this.errorMessage = 'Something went wrong with this product or transformer'
    } else {
      this.isValid = true
    }
  }

  json () {
    return {
      transformer_ids: [this.transformer.id]
    }
  }

  endpoint () {
    return this.route + '/products/' + this.id() + '/apply-transformers/'
  }

  errorHandler (errors) {
    return errors[0]
  }
}
