<template>
  <div class="section">
    <div class="container">
      <!-- Page Header -->
      <div class="columns is-vcentered">
        <div class="column is-one-third">
          <breadcrumbs :history="[{ label: 'Settings', name: 'settings'}]" current="Product Rulesets" :hideBack="true" />
        </div>
        <div class="column is-one-third">
          <h1 class="has-text-centered is-size-4">Product Rulesets</h1>
        </div>
      </div>
      <!-- Info banner -->
      <!-- For the time being, we are disabling the info banner because this task has not been added to the retailer onboarding process. -->
      <!-- <div v-if="context.isMerchant() && !context.partner.isOnboarded">
        <InfoBanner bannerInfo="custom" page="rulesets"/>
      </div> -->
      <!-- Filters -->
      <div class="has-background-white rounded-lg pa-md mb-xxs">
        <div class="columns">
          <div class="column">
            <RulesetFilters @filter="filter" :applied-filters="filters" />
          </div>
          <div class="column is-narrow">
            <b-field >
              <b-select v-model="orderBy" expanded @input="sort" size="is-medium">
                <option value="-id">Most Recent</option>
                <option value="id">Oldest</option>
              </b-select>
            </b-field>
          </div>
          <div class="column is-narrow">
            <b-button type="is-primary" size="is-medium" outlined @click.prevent="showCreateModal=true">Create Ruleset</b-button>
          </div>
        </div>
      </div>
      <!-- Toolbar -->
      <div class="has-background-white rounded-lg px-sm mb-xxs">
        <div class="columns is-vcentered is-mobile is-marginless is-paddingless">
          <!-- Dropdown -->
          <div class="column">
            <div class="has-text-right is-size-7">
              <!-- Counts -->
              <p v-if="loading">Loading...</p>
              <p v-else>{{ total }} {{ (total === 1) ? 'record' : 'records' }} found.</p>
            </div>
          </div>
        </div>
      </div>
      <!-- Table -->
      <div>
        <b-table
          :data="results"
          :checked-rows.sync="selected"
          :mobile-cards="false"
          :loading="loading"
          hoverable
          class="is-size-7">
          <template>
            <!-- ID -->
            <b-table-column
              label="ID"
              width="64"
              centered
              v-slot="props">
              <p class="has-text-grey-light">{{ props.row.id }}</p>
            </b-table-column>
            <!-- Ruleset -->
            <b-table-column
              label="Ruleset Name"
              cell-class="nowrap"
              v-slot="props">
              <div>
                <router-link class="has-text-weight-bold has-text-info" :to="{ name: 'ruleset', params: { id: props.row.id }}">
                  {{ props.row.name }}
                </router-link>
              </div>
              <div class="has-text-grey-light">
                <p v-if="props.row.retailer">{{ props.row.retailer.name }}</p>
                <p v-else-if="props.row.brand">{{ props.row.brand.name }}</p>
                <p v-else>-</p>
              </div>
            </b-table-column>
            <!-- Rule groups -->
            <b-table-column
              label="Rule Groups"
              width="128"
              centered
              v-slot="props">
              <p>{{ props.row.rule_groups.length }}</p>
            </b-table-column>
            <!-- Last Updated -->
            <b-table-column
              label="Updated At"
              width="128"
              centered
              v-slot="props">
              <p>{{ $dates.utc(props.row.updated_at).local().format('MMM DD, YYYY') }}</p>
            </b-table-column>
          </template>
          <template #empty>
            <div class="py-xxl has-text-grey has-text-centered">
              {{ (loading) ? "Loading data..." : 'No records found!' }}
            </div>
          </template>
        </b-table>
      </div>
      <div class="mt-md">
        <b-pagination
          :total="total"
          :current.sync="currentPage"
          :rounded="true"
          :per-page="perPage"
          @change="paginate">
        </b-pagination>
      </div>
    </div>
    <!-- Create modal -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showCreateModal"
      :can-cancel="['x']"
      ><CreateModal @reload="reload"></CreateModal>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Filterable from '@/mixins/Filterable'
import RulesetFilters from '../components/List/Filters'
// import InfoBanner from '@/components/Global/InfoBanner'
import CreateModal from '@/components/Models/Ruleset/Modals/Create'

export default {
  components: {
    // InfoBanner,
    RulesetFilters,
    CreateModal
  },
  computed: {
    ...mapGetters(['api', 'context']),
    endpoint () {
      return this.context.route + '/compatibility/rulesets/'
    }
  },
  data () {
    return {
      key: 'rulesets',
      alwaysAttach: {
      },
      selected: [],
      showCreateModal: false
    }
  },
  methods: {
    reload () {
      this.showCreateModal = false
      this.load()
    }
  },
  mixins: [Filterable],
  mounted () {
    this.load()
  }
}
</script>
