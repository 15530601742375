import dates from '@/assets/js/dates'

export const connection = {
  label: 'Connection',
  key: 'connection_id',
  icon: 'lan',
  tooltip: 'Filter items by connection.'
}

export const createdAt = {
  label: 'Created At',
  key: 'created_at',
  icon: 'calendar-outline',
  tooltip: 'Filter items based on when they were created.',
  options: dates
}

export const updatedAt = {
  label: 'Updated At',
  key: 'updated_at',
  icon: 'calendar-outline',
  tooltip: 'Filter items based on when they were last updated.',
  options: dates
}

export const retailerSkuCreated = {
  label: 'Retailer SKU Assigned',
  key: 'retailer_identifier_created_at',
  icon: 'calendar-outline',
  tooltip: 'Filter items based on when a retailer sku was assigned.',
  options: dates
}

export const hasRetailerSku = {
  label: 'Has Retailer SKU set?',
  key: 'has_retailer_identifier',
  icon: 'pulse',
  tooltip: 'Limit results based on whether an item has retailer SKU set or not.',
  options: [{
    value: 1,
    label: 'Yes'
  }, {
    value: 0,
    label: 'No'
  }]
}

export const inStock = {
  label: 'In Stock?',
  key: 'in_stock',
  icon: 'pulse',
  tooltip: 'Limit results based on whether an item is in stock or not.',
  options: [{
    value: 1,
    label: 'Yes'
  }, {
    value: 0,
    label: 'No'
  }]
}

export const noStockNoEad = {
  label: 'Unknown Return',
  key: 'no_stock_no_ead',
  icon: 'pulse',
  tooltip: 'Limit results to items that are out of stock with no estimated restock date.',
  options: [{
    value: 1,
    label: 'Out of stock with no estimated restock date'
  }]
}

export const isDiscontinued = {
  label: 'Is Discontinued?',
  key: 'is_discontinued',
  icon: 'pulse',
  tooltip: 'Limit results based on whether an item is discontinued or not.',
  options: [{
    value: 1,
    label: 'Yes'
  }, {
    value: 0,
    label: 'No'
  }]
}

export const submittedAt = {
  label: 'Supplier Last Update',
  key: 'brand_inventory_updated_at',
  icon: 'calendar-outline',
  tooltip: 'This is a tooltip',
  options: dates
}

export const isOnShopify = {
  label: 'Is Synced to Shopify',
  key: 'is_on_shopify',
  icon: 'autorenew',
  tooltip: 'Filter items based on whether or not they have been synced with Shopify.',
  options: [{
    value: 1,
    label: 'Yes'
  }, {
    value: 0,
    label: 'No'
  }]
}

export const isOnBigcommerce = {
  label: 'Is Synced to BigCommerce',
  key: 'is_on_bigcommerce',
  icon: 'autorenew',
  tooltip: 'Filter items based on whether or not they have been synced with bigcommerce.',
  options: [{
    value: 1,
    label: 'Yes'
  }, {
    value: 0,
    label: 'No'
  }]
}

export const isMerchandisingContact = {
  label: 'Merchandising Contact',
  key: 'is_merchandising_contact',
  icon: 'account',
  tooltip: 'Filter items by their merchandising contact.'
}

export const isDepartmentOwner = {
  label: 'Department Merchandiser',
  key: 'is_department_owner',
  icon: 'account',
  tooltip: 'Filter items by their department merchandiser.'
}

export const isDepartmentManager = {
  label: 'Department Manager',
  key: 'is_department_manager',
  icon: 'account',
  tooltip: 'Filter items by their department manager.'
}
