<template>
  <div class="has-background-white rounded-xl">
    <!-- Loaded -->
    <div v-if="preferences !== null">
      <div class="mb-lg">
        <p class="has-text-weight-bold mb-xs">Packing Slip Template</p>
        <div class="has-text-grey">
          <p class="mb-lg">
            Including a {{ context.partner.name }} branded packing slip
            is often an important component of the customer experience. The
            default packing slip will include your logo and core order data
            including the customer order number. If you have
            worked with {{ app.platform }} Support to configure a custom
            packing slip, please provide the template name.
            </p>
        </div>
      </div>
      <b-field>
        <b-radio v-model="customTemplate" native-value="no" @input="announce">Use standard packing slip template.</b-radio>
      </b-field>
      <b-field>
        <b-radio v-model="customTemplate" native-value="yes" @input="announce">Use custom packing slip template.</b-radio>
      </b-field>
      <b-field v-if="customTemplate === 'yes'" label="Template Name">
        <b-input
          type="text"
          size="is-medium"
          v-model="value"
          @input="announce"
          placeholder="Template Name"
        ></b-input>
      </b-field>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  computed: {
    ...mapGetters(['app', 'context']),
    endpoint () {
      return this.context.route + '/retailer-preferences/'
    },
    json () {
      return {
        preference: { id: this.preference.id },
        value: this.customTemplate === 'yes' ? this.value : this.standardTemplateName
      }
    }
  },
  data () {
    return {
      customTemplate: 'no',
      preference: null,
      value: null,
      standardTemplateName: 'generic_rcn_packing_slip.html'
    }
  },
  methods: {
    announce () {
      this.$emit('input', this.json)
    }
  },
  mounted () {
    const preference = this.$utils.find(this.preferences, ['code', 'default_packing_slip_template'])
    if (typeof preference !== 'undefined') {
      this.preference = preference
      if (this.preference.retailer_preference) {
        this.value = preference.retailer_preference.value
        if (this.value !== this.standardTemplateName) {
          this.customTemplate = 'yes'
        }
      }
      this.announce()
    }
  },
  props: {
    preferences: {
      type: Array,
      required: true
    }
  }
}
</script>
