export const rejected = {
  label: 'Product Status',
  key: 'is_rejected',
  icon: 'check',
  tooltip: 'This is a tooltip',
  options: [{
    value: 0,
    label: 'Included'
  }, {
    value: 1,
    label: 'Removed'
  }]
}
