<template>
  <div>
    <router-link :to="{ name: 'discover.product', params: { id: marketplace.id, slug: marketplace.brand.code, product: product.id } }">
      <figure class="image is-square">
        <img class="rounded-lg" :src="cdnTransform(product.primary_media.url, 400, 400, 'fill')" />
      </figure>
    </router-link>
    <div class="py-md is-size-7">
      <router-link :to="{ name: 'discover.product', params: { id: marketplace.id, slug: marketplace.brand.code, product: product.id } }">
        <p class="has-text-weight-bold">{{ product.parent_name }}</p>
      </router-link>
      <p class="has-text-grey is-size-7" v-if="sellAtPrice !== null">
        <span class="is-size-8">MAP </span>
        <MapPrice class="has-text-danger has-text-weight-bold" :price="product.pricing.map_price"></MapPrice>
        <span class="mx-sm">&nbsp;</span>
        <span class="is-size-8">COST </span>
        <DropshipCost class="has-text -primary has-text-weight-bold" :price="product.pricing.dropship_cost"></DropshipCost>
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MapPrice from '@/components/Presenters/Product/MapPrice'
import DropshipCost from '@/components/Presenters/Product/DropshipCost'
import CdnTransform from '@/mixins/CdnTransform'
export default {
  components: {
    DropshipCost,
    MapPrice
  },
  computed: {
    ...mapGetters(['context'])
  },
  data () {
    return {

    }
  },
  methods: {

  },
  mixins: [CdnTransform],
  mounted () {

  },
  props: ['marketplace', 'product']
}
</script>
