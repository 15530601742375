<template>
  <div>
    <b-select
      v-model="template"
      :placeholder="placeholder"
      :loading="loading"
      :disabled="loading"
      :size="size"
      @input="announce"
      expanded>
      <option
        v-for="template in templates"
        :value="template"
        :key="template.id"
      >{{ template.name }}</option>
    </b-select>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  data () {
    return {
      loading: false,
      template: null,
      templates: []
    }
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/templates/'
    },
    queryString () {
      const params = {
        pagination: 0,
        order_by: 'name'
      }
      if (this.datatype) params.data_type = this.datatype
      if (this.direction) params.direction = this.direction
      return {
        params: params
      }
    },
    placeholder () {
      return (this.direction === 'export') ? 'Select Export Template' : 'Select Import Template'
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint, this.queryString).then(response => {
        this.templates = []
        response.data.results.forEach(template => {
          if (template.retailer !== null || template.brand !== null) {
            this.templates.push(template)
          }
          if (this.id && template.id === this.id) {
            this.template = template
            this.announce()
          }
        })
        this.loading = false
      })
    },
    announce () {
      this.$emit('input', this.template)
    }
  },
  mounted () {
    if (this.value !== null) {
      this.template = this.value
    }
    this.load()
  },
  props: ['value', 'direction', 'datatype', 'size', 'id']
}
</script>
