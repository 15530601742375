<template>
  <div class="section">
    <div class="container">
      <!-- Page Header -->
      <div class="mb-md">
        <div class="columns is-vcentered">
          <div class="column is-one-third">
            <breadcrumbs :history="[{ label: 'Settings', name: 'settings'}]" current="General Information" />
          </div>
          <div class="column is-one-third">
            <h1 class="has-text-centered is-size-4">General Information</h1>
          </div>
        </div>
      </div>
      <!-- General Info & Key Contacts-->
      <div class="border-b pb-lg mb-lg">
        <BusinessInfo :partner="partner" />
      </div>
      <!-- Billing Address -->
      <div class="border-b pb-lg mb-lg">
        <BusinessAddress :partner="partner" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BusinessAddress from '../components/BusinessAddress'
import BusinessInfo from '../components/BusinessInfo'
export default {
  components: {
    BusinessAddress,
    BusinessInfo
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/'
    }
  },
  data () {
    return {
      name: null,
      url: null,
      partner: null
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint).then(response => {
        this.partner = response.data
        this.loading = false
      })
    }
  },
  mounted () {
    this.load()
  }
}
</script>
