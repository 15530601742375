<template>
  <form @submit.prevent="save">
    <div class="columns">
      <div class="column">
        <div class="has-background-white rounded-lg">
          <!-- Loaded -->
          <div v-if="preferences !== null" class="pa-lg pb-xl">
            <div class="mb-lg">
              <p class="has-text-weight-bold mb-xs">Default Cost Determination</p>
              <div class="has-text-grey is-size-7">
                <p class="mb-md">
                  All incoming items on a purchase order must have an associated cost to {{ context.partner.name }}.
                  Choose 'Specified on Order' if the costs of items are to be maintained by {{ context.partner.name }}.
                  If {{ app.platform }} is maintaining costs, choose 'Specified by Platform'. If {{ app.platform }} should
                  calculate the cost using a percentage of retail, choose 'Commission' (and specify a default commission rate).
                </p>
              </div>
            </div>
            <div class="columns">
              <div class="column is-half">
                <b-field label="Cost Method">
                  <b-select
                    type="text"
                    v-model="method"
                    size="is-medium"
                    expanded
                    @input="isDirty = 1">
                    <option v-for="option in options" :key="option.id" :value="option">{{ option.name }}</option>
                  </b-select>
                </b-field>
              </div>
              <div v-if="method && method.value === 'revshare'" class="column is-half">
                <b-field label="Your Commission (%)">
                  <b-input
                    type="number"
                    v-model="commission"
                    size="is-medium"
                    expanded
                    :max="99"
                    :min="1"
                    @input="isDirty = 1" />
                </b-field>
              </div>
              <div v-if="method && method.value === 'commission_profile'" class="column is-half">
                <CommissionProfileSelect v-model="commissionProfile" size="is-medium" @input="isDirty = 1" />
              </div>
            </div>
            <!-- Example -->
            <div v-if="method && method.value === 'revshare'" class="mt-md">
              <b-message type="is-info">
                Example: if you sell a <Currency value="100" /> item, you will owe the supplier <Currency :value="100 - commission" />.
              </b-message>
            </div>
          </div>
          <div class="border-t">
            <div class="columns is-vcentered is-marginless">
              <div class="column is-hidden-mobile">
                <div class="px-sm has-text-grey-light">
                  <i v-if="isDirty">Click 'Save Preference' to save your changes</i>
                </div>
              </div>
              <div class="column is-narrow">
                <div class="px-sm">
                  <b-button
                    native-type="submit"
                    type="is-primary rounded-lg"
                    :disabled="!ready || saving || !isDirty"
                  >Save Preference</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import CommissionProfileSelect from '@/components/Models/CommissionProfiles/ProfileSelect'
export default {
  components: {
    CommissionProfileSelect
  },
  computed: {
    ...mapGetters(['app', 'context']),
    endpoint () {
      return this.context.route + '/retailer-preferences/'
    },
    json () {
      const preferences = [{
        preference: { id: this.methodPreference.id },
        option: { id: this.method.id }
      }]
      if (this.method && this.method.value === 'revshare') {
        preferences.push({
          preference: { id: this.commissionPreference.id },
          value: this.commission
        })
      } else if (this.method && this.method.value === 'commission_profile') {
        preferences.push({
          preference: { id: this.commissionPreference.id },
          value: this.commissionProfile.id
        })
      } else {
        preferences.push({
          preference: { id: this.commissionPreference.id },
          value: 0
        })
      }
      return preferences
    },
    ready () {
      return (this.method !== null)
    }
  },
  data () {
    return {
      isDirty: false,
      methodPreference: null,
      method: null,
      commissionPreference: null,
      commission: null,
      errors: [],
      saving: false,
      options: [],
      commissionProfile: null
    }
  },
  methods: {
    save () {
      this.saving = true
      this.$http.post(this.endpoint, this.json).then(response => {
        this.isDirty = false
        this.$buefy.toast.open({ type: 'is-success', message: 'Preferences updated!' })
      }).catch(error => {
        if (error.response && error.response.status === 400) {
          this.errors = error.response.data
        }
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    // locate preferences
    this.methodPreference = this.$utils.find(this.preferences, ['code', 'default_net_price_method'])
    this.commissionPreference = this.$utils.find(this.preferences, ['code', 'default_commission_rate'])

    if (typeof this.methodPreference !== 'undefined') {
      // set the options
      this.options = this.methodPreference.options.map(option => {
        return {
          id: option.id,
          name: option.name,
          value: option.value
        }
      })
      // set the current values
      if (this.methodPreference.retailer_preference) {
        this.method = this.$utils.find(this.options, ['value', this.methodPreference.retailer_preference.value])
        // if method is commission, also configure the commission rate preference
        if (
          typeof this.commissionPreference !== 'undefined' &&
          this.method.value &&
          this.commissionPreference.retailer_preference
        ) {
          if (this.method.value === 'revshare') {
            this.commission = this.commissionPreference.retailer_preference.value
          }
          if (this.method.value === 'commission_profile') {
            this.commissionProfile = {
              id: parseInt(this.commissionPreference.retailer_preference.value)
            }
          }
        }
      }
    }
  },
  props: ['preferences', 'retailerPreferences']
}
</script>
