import Index from './views/Index'
import ImportFile from './views/ImportFile'
import ImportShopify from './views/ImportShopify'
import History from './views/History'
import Jobs from './views/Jobs'

export default [{
  path: '/network/import',
  name: 'import',
  component: Index
}, {
  path: '/network/import/history',
  name: 'import.history',
  component: History
}, {
  path: '/network/import/jobs',
  name: 'import.jobs',
  component: Jobs
}, {
  path: '/network/import/file',
  name: 'import.file',
  component: ImportFile
}, {
  path: '/network/import/shopify',
  name: 'import.shopify',
  component: ImportShopify
}]
