<template>
<div>
  <div class="mb-sm">
    <!-- Intro -->
    <div class="mb-lg">
      <p class="has-text-weight-bold has-text-info has-text-centered">Step 1 of 3: Upload SKU List</p>
    </div>
    <!-- Select Connection -->
    <div class="mb-lg">
      <FieldLabel label="Select Merchant Partner" class="mb-sm" />
      <b-field
        :type="('connection' in errors) ? 'is-danger' : ''"
        :message="('connection' in errors) ? errors['connection'][0] : ''">
        <ConnectionSelect v-model="connectionShort" size="is-medium" />
      </b-field>
    </div>
    <div v-if="connectionShort">
      <div v-if="!loadingConnection && connection !== null" class="mt-lg">
        <!-- Department -->
        <div class="mb-lg">
          <FieldLabel label="Category" class="mb-sm" />
          <b-field
            :type="('department' in errors) ? 'is-danger' : ''"
            :message="('department' in errors) ? errors['department'][0] : ''">
            <DepartmentSelect v-model="department" :connectionId="(connection) ? connection.id : null" size="is-medium" />
          </b-field>
        </div>
        <!--- File -->
        <div class="mb-lg">
          <p class="has-text-primary has-text-weight-bold mb-sm">Upload File</p>
          <b-message class="is-size-7">
              <span class="has-text-weight-bold has-text-danger">Please Note</span>
              Your file <u>must</u> include a column with <b>SKU</b> as a header.
              Your file can contain other columns, but they will be ignored.
            </b-message>
          <CoreUpload @file:uploaded="updateTempfile" @file:reset="resetTempfile"/>
        </div>
        <!-- Email Me -->
        <!-- <div class="mb-lg">
          <b-field>
            <b-checkbox v-model="emailOnceComplete" true-value="1" false-value="0">Email me once my feed is complete.</b-checkbox>
          </b-field>
        </div> -->
      </div>
      <div v-else>
        <loading message="Loading connection details..." />
      </div>
    </div>
    <!-- Navigate -->
    <div class="pt-lg">
      <div class="columns is-centered">
        <div class="column">
          <b-button
            type="is-light rounded-lg"
            size="is-medium"
            @click="goBack"
            expanded
            ><b-icon icon="arrow-left"></b-icon>
          </b-button>
        </div>
        <div class="column">
          <b-button
            type="is-primary rounded-lg"
            size="is-medium"
            expanded
            :disabled="!ready || saving"
            @click="queueFile"
          v-text="buttonLabel"></b-button>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import ConnectionSelect from '@/components/Models/Connection/ConnectionSelect'
import DepartmentSelect from '@/components/Models/ProposalDepartment/DepartmentSelect'
import CoreUpload from '@/components/CoreUpload'

export default {
  components: {
    ConnectionSelect,
    CoreUpload,
    DepartmentSelect
  },
  computed: {
    ...mapGetters(['app', 'context']),
    ready () {
      return (this.connection && this.department && this.tempfile)
    },
    proposalBody () {
      if (!this.ready) return {}
      return {
        connection: {
          id: this.connection.id
        },
        name: this.department.name + ' - ' + this.context.partner.name + ' - ' + this.$dates.utc().local().format('MMM D, YYYY @ h:mm'),
        source: 'merchandising',
        proposal_type: 'cost_update',
        department: {
          id: this.department.id
        },
        recipient: {
          user_id: this.department.owner_user.id
        }
      }
    }
  },
  data () {
    return {
      connectionShort: null,
      loadingConnection: true,
      connection: null,
      department: null,
      tempfile: null,
      proposal: null,
      saving: false,
      buttonLabel: 'Upload SKU List',
      errors: {}
    }
  },
  methods: {
    loadConnectionDetail () {
      this.loadingConnection = true
      const endpoint = this.context.route + '/connections/' + this.connectionShort.id + '/'
      this.$http.get(endpoint).then(response => {
        this.connection = response.data
        this.loadingConnection = false
      })
    },
    goBack () {
      this.$emit('navigate', 'Intro')
    },
    queueFile () {
      this.saving = true
      this.buttonLabel = 'Creating Proposal...'
      const proposalEndpoint = this.context.route + '/proposals/'
      this.$http.post(proposalEndpoint, this.proposalBody).then(response => {
        this.buttonLabel = 'Submitting Upload...'
        this.$emit('proposal:created', response.data)
        this.proposal = response.data
        const feedEndpoint = this.context.route + '/feed-queue/'
        const feedBody = {
          template: {
            id: this.proposal.department.template.id
          },
          direction: 'import',
          data_type: 'proposals',
          source: 'console',
          behavior: 'update',
          info: {
            proposal_id: this.proposal.id,
            proposal_feed_purpose: 'build',
            rulesets: [{
              id: this.proposal.department.brand_ruleset.id
            }, {
              id: this.proposal.department.retailer_ruleset.id
            }]
          },
          file: {
            tempfile: {
              id: this.tempfile.id
            }
          }
        }
        this.$http.post(feedEndpoint + 'enqueue/', feedBody).then(response => {
          this.buttonLabel = 'Queueing File...'
          this.$emit('feed:queued', response.data)
          this.feedQueue = response.data
          this.$http.put(feedEndpoint + this.feedQueue.id + '/run/').then(response => {
            this.$buefy.toast.open({ type: 'is-success', message: 'Proposal created!' })
            this.$router.replace({ name: 'catalog.submit.updates', query: { step: 'Progress', proposal_id: this.proposal.id, feed_id: response.data.id } })
            this.$emit('navigate', 'Progress')
          }).finally(() => {
            this.buttonLabel = 'Upload Proposal'
            this.saving = false
          })
        })
      })
    },
    updateTempfile (file, response) {
      this.tempfile = response
    },
    resetTempfile () {
      this.tempfile = null
    }
  },
  watch: {
    connectionShort (connection) {
      if (connection) {
        this.loadConnectionDetail()
      }
    }
  }
}
</script>
