<template>
  <div>
    <b-select
      v-model="department"
      placeholder="Select Department..."
      expanded
      :loading="loading"
      :disabled="loading || connectionId === null"
      :size="size"
      @input="announce">
      <option
        v-for="department in departments"
        :value="department"
        :key="department.id"
      >{{ department.name }}</option>
    </b-select>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      loading: false,
      department: null,
      departments: []
    }
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/connections/' + this.connectionId + '/departments/'
    },
    query () {
      return {
        params: {
          pagination: 0
        }
      }
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint, this.query).then(response => {
        this.departments = response.data.results
        this.loading = false
      })
    },
    announce () {
      this.$emit('input', this.department)
    }
  },
  mounted () {
    this.load()
  },
  props: {
    connectionId: {
      type: Number,
      default: null
    },
    value: {
      type: Object
    },
    size: {
      type: String,
      default: null
    }
  },
  watch: {
    connectionId: {
      handler: function (id) {
        if (id) this.load()
      },
      immedate: true
    }
  }
}
</script>
