<template>
  <div>
    <form @submit.prevent="postNote">
      <div class="columns">
        <div class="column">
          <b-field>
            <b-input
              placeholder="Add a note for your trading partner..."
              size="is-medium"
              v-model="message"
            ></b-input>
          </b-field>
        </div>
        <div class="column is-one-fifth">
          <b-button
            native-type="submit"
            type="is-primary"
            size="is-medium"
            class="is-fullwidth"
            :loading="busy"
            :disabled="busy || !isValid"
          >Post</b-button>
        </div>
      </div>
    </form>
    <div class="pt-lg">
      <!-- No Messages -->
      <div v-if="!refreshing && messages.length === 0">
        <p class="has-text-centered has-text-grey py-lg">No notes have been posted.</p>
      </div>
      <!-- Has Messages -->
      <div v-else>
        <Note class="mb-sm pt-sm border-t" v-for="message in publicMessages" :og-message="message" :key="message.id" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Note from './Note'
export default {
  components: {
    Note
  },
  computed: {
    ...mapGetters(['context']),
    busy () {
      return (this.saving || this.refreshing)
    },
    isValid () {
      return (this.message !== null && this.message !== '')
    },
    postEndpoint () {
      return this.context.route + '/proposals/' + this.$route.params.id + '/issues/' + this.$route.params.issueId + '/memos/'
    },
    postBody () {
      return {
        text: this.message
      }
    },
    publicMessages () {
      return this.$utils.filter(this.messages, ['visibility', 'any'])
    },
    issueEndpoint () {
      return this.context.route + '/proposals/' + this.$route.params.id + '/issues/' + this.$route.params.issueId + '/'
    }
  },
  data () {
    return {
      message: null,
      messages: this.issue.memos,
      refreshing: false,
      saving: false
    }
  },
  methods: {
    postNote () {
      this.saving = true
      this.$http.post(this.postEndpoint, this.postBody).then(response => {
        this.saving = false
        this.message = null
        this.refreshIssue()
      })
    },
    refreshIssue () {
      this.refreshing = true
      this.$http.get(this.issueEndpoint).then(response => {
        // only grab the 'public' messages
        const messages = []
        response.data.memos.forEach(memo => {
          if (memo.visibility === 'any') {
            messages.push(memo)
          }
        })
        this.messages = messages
        this.refreshing = false
      })
    }
  },
  mounted () {

  },
  props: {
    issue: {
      type: Object,
      required: true
    }
  }
}
</script>
