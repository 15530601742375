<template>
  <form @submit.prevent="save">
    <!-- Marketplaces Loaded -->
    <div v-if="!loading" class="mb-xl">
      <div class="mb-xl">
        <p class="is-size-4 mb-md has-text-centered">Shopify Setup Complete!</p>
        <div class="has-text-centered mb-lg">
          <p class="mb-md">Thank you for partnering with {{ app.platform }}!</p>
          <p>Although your product import may still be processing, your Shopify
            onboarding is complete! There are a few additional steps required
            to activate your account. When you complete setup, you will
            be redirected to the console where you will complete the next steps.
          </p>
        </div>
        <div v-if="context.isSupplier()">
          <p class="has-text-weight-bold has-text-centered mb-md">Remaining Steps</p>
          <div class="has-background-white-ter rounded-lg mb-xl pa-xl">
            <div class="columns is-mobile is-vcentered">
              <div class="column">
                <ul class="has-text-left is-size-5">
                  <li class="mb-lg">
                    <b-icon icon="check" size="is-small" custom-class="has-text-success mr-sm"/>
                    Setup a profile so merchants can discover you and your products.
                  </li>
                  <li class="">
                    <b-icon icon="check" size="is-small" custom-class="has-text-success mr-sm"/>
                    Publish your products to the Commerce Network!
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Buttons -->
      <div class="mb-xl">
        <div class="columns is-centered">
          <div class="column is-two-thirds tablet is-half-desktop">
            <b-button native-type="submit" type="rounded-lg is-primary is-fullwidth" size="is-large" :disabled="saving" :loading="saving">Complete Setup</b-button>
          </div>
        </div>
      </div>
    </div>
    <!-- Loading -->
    <div v-else>
      <loading message="Loading..." />
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  computed: {
    ...mapGetters(['app', 'assets', 'context']),
    endpoint () {
      return this.context.route + '/marketplaces/?mine=1'
    },
    json () {
      const terms = (this.context.type === 'vendor')
        ? { document_id: this.assets.terms.vendor }
        : { document_id: this.assets.terms.retailer }
      return {
        visibility: 'invisible',
        status: 'pending',
        terms_and_conditions: terms
      }
    }
  },
  data () {
    return {
      loading: true,
      marketplace: null,
      saving: false
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint).then(response => {
        if (response.data.results.length > 0) {
          this.marketplace = response.data.results[0]
        }
        this.loading = false
      })
    },
    save () {
      if (this.marketplace === null) {
        this.saving = true
        this.$http.post(this.endpoint, this.json).then(response => {
          this.saving = false
          this.$router.push({ name: 'settings.integrations', query: { shopify: 1 } })
        })
      } else {
        this.saving = true
        setTimeout(() => {
          this.$router.push({ name: 'settings.integrations', query: { shopify: 1 } })
        }, 1500)
      }
    }
  },
  mounted () {
    this.load()
  },
  props: []
}
</script>
