<template>
  <div>
    <p class="has-text-weight-bold mb-md">{{ filter.label }}</p>
    <!-- Loaded (with options)-->
    <div v-if="!loading && options.length > 0">
      <div v-for="option in options" :key="option.value" class="field">
        <b-checkbox v-model="selected" :native-value="option" @input="announce">{{ option.label }}</b-checkbox>
      </div>
    </div>
    <!-- Loaded (no options) -->
    <div v-else-if="!loading && options.length === 0">
      <b-checkbox v-model="noValuesFound" :disabled="true" @input="announce">All</b-checkbox>
    </div>
    <!-- Loaded (no options) -->
    <div v-else>
      <p class="has-text-grey-light">Loading...</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['context']),
    query () {
      return {
        pagination: 0
      }
    }
  },
  data () {
    return {
      loading: true,
      noValuesFound: true,
      options: [],
      selected: []
    }
  },
  methods: {
    announce () {
      this.$emit('input', this.selected)
    }
  },
  created () {
    if (typeof this.value !== 'undefined' && Array.isArray(this.value) && this.value.length > 0) {
      this.selected = this.value
    }
  },
  mounted () {
    this.loading = true
    this.$http.get(this.source, this.query).then(response => {
      this.options = []
      response.data.forEach(option => {
        this.options.push({ value: option, label: option })
      })
      this.$emit('filter:loaded')
    }).finally(() => {
      this.loading = false
    })
  },
  props: ['filter', 'value', 'source']
}
</script>
