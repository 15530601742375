<template>
<div>
  <div v-if="!loadingFeed && detail !== null">
    <div class="mb-lg">
      <Progress :feed="detail" size="is-large" :emitRefresh="true" @refresh="loadFeed(true)" />
    </div>
    <!-- Feed Details -->
    <div class="mb-lg">
      <p class="has-text-weight-bold has-text-centered mb-sm">Import Results</p>
      <div v-if="showDetails">
        <table class="table is-fullwidth is-striped is-narrow is-size-8 border">
          <tbody>
            <tr>
              <th>ID</th>
              <td class="has-text-right">{{ detail.id }}</td>
              <th>Feed Type</th>
              <td class="has-text-right">{{ $utils.upperFirst(detail.direction) }} {{ $utils.upperFirst(detail.data_type) }}</td>
            </tr>
            <tr>
              <th>Filename</th>
              <td class="has-text-right">{{ $utils.truncate(detail.original_filename, { length: 32 }) }}</td>
              <th>Template</th>
              <td class="has-text-right">{{ detail.template.name }}</td>
            </tr>
            <tr>
              <th>File Link</th>
              <td class="has-text-right">
                <DownloadFileAs label="Download" :cloudFileName="detail.cloud_filename" :originalFileName="detail.original_filename"/>
              </td>
              <th>Status</th>
              <td class="has-text-weight-bold has-text-right">
                <span v-if="detail.status === 'success'" class="has-text-success">Success</span>
                <span v-else-if="detail.status === 'failure'" class="has-text-danger">Failed</span>
                <span v-else-if="detail.status === 'processing'" class="has-text-danger">Processing</span>
                <span v-else>Queued</span>
              </td>
            </tr>
            <tr>
              <th>Started At</th>
              <td class="has-text-right">
                <span v-if="detail.processed_at">{{ $dates.utc(detail.processed_at).local().format('MMM D, YYYY @ h:mm A') }}</span>
                <span v-else class="has-text-grey is-italic">Not Started</span>
              </td>
              <th>Completed At</th>
              <td class="has-text-right">
                <span v-if="detail.completed_at">{{ $dates.utc(detail.completed_at).local().format('MMM D, YYYY @ h:mm A') }}</span>
                <span v-else class="has-text-grey is-italic">Not Completed</span>
              </td>
            </tr>
            <tr>
              <th>Wait time</th>
              <td class="has-text-right"><Runtime :start="detail.created_at" :end="detail.processed_at" /></td>
              <th>Processing Time</th>
              <td class="has-text-right"><Runtime :start="detail.processed_at" :end="detail.completed_at" /></td>
            </tr>
            <tr>
              <th># of Rows</th>
              <td class="has-text-right">{{ detail.num_items }}</td>
              <th># of Items Created</th>
              <td class="has-text-right">{{ detail.num_created }}</td>
            </tr>
            <tr>
              <th># of Items Updated</th>
              <td class="has-text-right">{{ detail.num_updated }}</td>
              <th># of Warnings</th>
              <td class="has-text-right">{{ detail.num_warnings }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div v-else>
    <loading message="Loading import details..." />
  </div>
  <!-- Navigate -->
  <div class="pt-lg">
    <div class="columns is-centered">
      <div class="column">
        <b-button
          type="is-light rounded-lg"
          size="is-medium"
          @click="goBack"
          expanded
          ><b-icon icon="arrow-left"></b-icon>
        </b-button>
      </div>
      <div class="column">
        <b-button
          type="is-primary rounded-lg"
          size="is-medium"
          expanded
          @click="goForward"
        >Return to Dashboard</b-button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Progress from '@/components/Models/FeedQueue/Presenters/Progress'
import Runtime from '@/components/Models/FeedQueue/Presenters/Runtime'

export default {
  components: {
    Progress,
    Runtime
  },
  computed: {
    ...mapGetters(['app', 'context'])
  },
  data () {
    return {
      loadingFeed: true,
      detail: null,
      showDetails: true
    }
  },
  methods: {
    goBack () {
      this.$emit('navigate', 'Intro')
    },
    goForward () {
      this.$router.push({ name: 'catalog.import' })
    },
    toggleDetails () {
      this.showDetails = !this.showDetails
    },
    loadFeed (silent = false) {
      if (!silent) this.loadingFeed = true
      const endpoint = this.context.route + '/feed-queue/' + this.feed.id + '/'
      this.$http.get(endpoint).then(response => {
        this.detail = response.data
        this.loadingFeed = false
      })
    }
  },
  mounted () {
    this.loadFeed()
  },
  props: {
    feed: {
      type: Object,
      required: true
    }
  }
}
</script>
