<template>
  <div class="pa-xl has-text-centered rounded-lg">
    <p class="mb-lg">Authentication Diagnostics</p>
    <div class="mb-lg">
      <p class="">Local Access Token</p>
      <p class="has-text-grey-light">{{ localToken }}</p>
    </div>
    <div class="has-text-centered mb-lg">
      <p class="">Local Context</p>
      <p class="has-text-grey-light">{{ localContext }}</p>
    </div>
    <div class="has-text-centered">
      <router-link to="/auth/login">Return to Sign In</router-link>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    localContext () {
      const context = sessionStorage.getItem('rcc')
      return (context !== null) ? context : '- Not Set -'
    },
    localToken () {
      const token = localStorage.getItem('rct')
      return (token !== null) ? token + ' (Standard)' : '- Not Set -'
    }
  }
}
</script>
