<template>
  <div>
    <div class="columns">
      <!-- Name -->
      <div class="column">
        <b-field
          label="Name"
          expanded>
          <b-input
            type="text"
            size="is-medium"
            v-model="name"
            @input="announce"
          ></b-input>
        </b-field>
      </div>
      <!-- Priority -->
      <div v-if="showPosition" class="column">
        <b-field
          label="Position"
          expanded>
          <b-input
            type="number"
            size="is-medium"
            min="1"
            v-model="position"
            @input="announce"
          ></b-input>
        </b-field>
      </div>
    </div>
    <!-- Description -->
    <b-field label="Description">
      <b-input
        type="textarea"
        expanded
        v-model="description"
        @input="announce"
      ></b-input>
    </b-field>
    <!-- isRequired -->
    <div v-if="showIsRequired">
      <b-field
        expanded>
        <b-checkbox v-model="isRequired" @input="announce">Is Required</b-checkbox>
      </b-field>
    </div>
  </div>
</template>

<script>
export default {
  components: {

  },
  computed: {
    ready () {
      const nameExists = this.name && this.name.length > 0
      let isReady = nameExists
      if (this.position) {
        isReady = nameExists && this.position > 0
      }
      return isReady
    },
    json () {
      const json = {
        name: this.name,
        description: this.description,
        position: this.position ? this.position : null,
        is_required: this.isRequired
      }
      return json
    }
  },
  data: () => {
    return {
      name: null,
      description: null,
      position: null,
      isRequired: false
    }
  },
  methods: {
    announce () {
      if (this.ready) {
        this.$emit('input', this.json)
      }
      this.$emit('is:ready', this.ready)
    }
  },
  mounted () {
    if (this.formFields && this.formFields !== null) {
      this.name = this.formFields.name
      this.description = this.formFields.description
      this.position = this.formFields.position
      this.isRequired = this.formFields.is_required
    }
  },
  props: {
    formFields: {
      type: Object
    },
    showPosition: {
      type: Boolean,
      default: false
    },
    showIsRequired: {
      type: Boolean,
      default: false
    }
  }
}
</script>
