<template>
  <span :class="presenter.textClass" class="has-text-weight-bold">{{ presenter.label }}</span>
</template>

<script>
export default {
  computed: {
    presenter () {
      // set defaults first
      const presenter = {
        label: null,
        textClass: 'has-text-black',
        message: null
      }
      // open orders are now those that are 'open' or 'partial'
      switch (this.line.status) {
        case 'partial':
          presenter.label = 'In Progress'
          presenter.textClass = 'has-text-info'
          break
        case 'canceled':
          presenter.label = 'Canceled'
          presenter.textClass = 'has-text-warning'
          break
        case 'closed':
          presenter.label = 'In Progress'
          break
        case 'backordered':
          presenter.label = 'Backordered'
          presenter.textClass = 'has-text-warning'
          break
        default:
          presenter.label = 'Open'
      }
      return presenter
    }
  },
  props: ['line']
}
</script>
