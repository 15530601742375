<template>
  <div>
    <!-- Plan -->
    <div class="mb-md">
      <p class="is-size-3 mb-sm has-text-black">One plan, no surprises!</p>
      <div class="columns is-centered">
        <div class="column is-four-fifths-desktop">
          <p class="is-size-5">
            To celebrate the launch of the {{ app.platform }} Commerce Network, we're
            offering a single plan with no monthly fees. You only pay when
            you sell products, so our success is linked to your success!
          </p>
        </div>
      </div>
    </div>
    <!-- Costs -->
    <div class="mb-md">
      <div class="columns is-centered">
        <div class="column is-half-desktop">
          <div class="has-background-white-ter pa-md rounded-lg is-size-4">
            <div class="has-text-grey mb-sm">
              <p class="has-text-weight-bold">5% Transaction Fee</p>
              <p class="is-size-7">Based on GMV</p>
            </div>
            <p class="has-text-success has-text-weight-bold">$0 / month</p>
          </div>
        </div>
      </div>
    </div>
    <!-- Features -->
    <div class="mb-xl">
      <p class="has-text-weight-bold mb-xs">Features</p>
      <div class="">
        <div class="columns is-mobile is-vcentered">
          <div class="column">
            <table class="table is-narrow is-vcentered is-fullwidth is-size-6 is-striped rounded-lg">
              <tr v-for="(feature, index) in plan.features" :key="index">
                <td>{{ feature }}</td>
                <td><b-icon icon="check" custom-class="has-text-success has-text-weight-bold" /></td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  data () {
    return {
      plan: {
        code: 'vendor-unlimited',
        name: 'Supplier Launch Partner Plan',
        isBeta: true,
        description: 'To celebrate the lauch of the Commerce Network, we are offering a completely free plan for suppliers!',
        features: [
          'Unlimited products, partners, & orders.',
          '5% transaction fee due AFTER orders ship (collected at the end of each month.)',
          'No monthly or recurring subscription fees.',
          'Transparent supplier costs that include shipping & processing.',
          '30-Day return-to-vendor policy.',
          'Automated supplier payments.'
        ]
      }
    }
  },
  computed: {
    ...mapGetters(['app', 'context'])
  },
  methods: {

  },
  mounted () {

  },
  props: []
}
</script>
