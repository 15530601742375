<template>
  <form @submit.prevent="create" class="pb-lg">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Create API Client</p>
      </header>
      <section class="modal-card-body">
        <b-message :closable="false">
          Click or tap "Create Client" below to add an API client. After the
          client is created, you will be able to retreive the credentials.
          <span class="has-text-warning">Please note that you can only
            retreive the credentials once!</span>
        </b-message>
        <b-field
          label="Client Name"
          :type="('name' in errors) ? 'is-danger' : ''"
          :message="('name' in errors) ? errors['name'][0] : ''">
          <b-input
            autocomplete="off"
            type="text"
            name="name"
            size="is-medium"
            v-model="name"
          ></b-input>
        </b-field>
      </section>
      <footer class="modal-card-foot space-between">
        <button class="button" type="button" @click="$parent.close()">Close</button>
        <b-button
          native-type="submit"
          type="is-primary"
          :disabled="saving || !ready"
          :loading="saving"
        >Create Client</b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  computed: {
    ...mapGetters(['app', 'context']),
    endpoint () {
      return this.context.route + '/users/'
    },
    json () {
      return {
        type: 'api',
        name: this.name
      }
    },
    ready () {
      return (this.name)
    }
  },
  data () {
    return {
      name: null,
      saving: false,
      errors: {}
    }
  },
  methods: {
    create () {
      this.saving = true
      this.$http.post(this.endpoint, this.json).then(respnose => {
        this.$emit('client:created')
        this.$buefy.toast.open({ type: 'is-success', message: 'Client created!' })
        this.$parent.close()
      }).catch(error => {
        if (error.response && error.response.status === 400) {
          this.errors = error.response.data
        }
      }).finally(() => {
        this.saving = false
      })
    }
  }
}
</script>
