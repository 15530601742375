import dates from '@/assets/js/dates'

export const invoicedAt = {
  label: 'Invoice Submitted',
  key: 'invoiced_at',
  icon: 'calendar',
  tooltip: 'This is a tooltip',
  options: dates
}

export const dueAt = {
  label: 'Invoice Due',
  key: 'terms_date_due',
  icon: 'calendar',
  tooltip: 'This is a tooltip',
  options: dates
}
