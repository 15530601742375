<template>
  <tr>
    <td>
      <Identifiers :variant="line.variant" :showImage="false" nameInteraction="none" />
    </td>
    <td class="has-text-centered">
      <p>{{ line.quantity }}</p>
    </td>
    <td class="has-text-centered">
      <CurrencyLocale :value="parseFloat(line.locale_price)" :currency="currency"/>
    </td>
    <td class="has-text-centered">
      <CurrencyLocale :value="lineUnitCost" :currency="currency"/>
    </td>
  </tr>
</template>

<script>
import Identifiers from '@/components/Models/Variant/Identifiers'
import { mapGetters } from 'vuex'
export default {
  components: {
    Identifiers
  },
  computed: {
    ...mapGetters(['context']),
    lineUnitCost () {
      return parseInt(this.line.quantity) * parseFloat(this.line.locale_price)
    },
    lineRetailPrice () {
      return parseInt(this.line.quantity) * parseFloat(this.line.retail_price)
    }
  },
  props: {
    line: {
      type: Object,
      required: true
    },
    currency: {
      type: Object,
      required: true
    }
  }
}
</script>
