<template>
  <div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Categories</p>
      </header>
      <section class="modal-card-body">
        <b-table
          :data="categories"
          :mobile-cards="false"
          hoverable>
          <template>
            <!-- Merchant Id -->
            <b-table-column
              label="Merchant ID"
              cell-class="nowrap"
              width="194"
              v-slot="props">
              {{ props.row.retailer.id }}
            </b-table-column>
            <!-- Merchant Name -->
            <b-table-column
              label="Merchant Name"
              cell-class="nowrap"
              width="194"
              v-slot="props">
              {{ props.row.retailer.name }}
            </b-table-column>
            <!-- Category -->
            <b-table-column
              label="Category"
              cell-class="nowrap"
              width="194"
              v-slot="props">
              {{ props.row.category }}
            </b-table-column>
          </template>
        </b-table>
      </section>
      <footer class="modal-card-foot is-justify-content-flex-end">
        <b-button @click="$parent.close()" >Close</b-button>
      </footer>
    </div>
  </div>
  </template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['context'])
  },
  data () {
    return {
    }
  },
  props: {
    categories: {
      type: Array,
      required: true
    }
  }
}
</script>
