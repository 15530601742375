<template>
  <div>
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">Product Detail</p>
      </header>
      <section class="modal-card-body">
        <!-- Loaded -->
        <div v-if="!loadingProduct && detail !== null">
          <!-- Header Loaded -->
          <div class="border-b px-lg py-md mb-xxs">
            <div class="columns is-vcentered is-mobile">
              <div class="column is-narrow">
                <figure class="image is-48x48" v-if="detail.primary_media !== null">
                  <img :src="cdnTransform(detail.primary_media.url, 48, 48, 'fill')" class="rounded-lg" />
                </figure>
                <figure class="image is-48x48" v-else>
                  <img :src="cdnTransform(null, 48, 48, 'fill')" class="rounded-lg" />
                </figure>
              </div>
              <div class="column">
                <p class="is-size-5">{{ detail.parent_name }}</p>
                <p class="has-text-grey">
                  <span class="is-size-8">Product SKU </span>
                  <span class="">{{ detail.parent_identifier }}</span>
                </p>
              </div>
            </div>
          </div>
          <!-- To-Do: Action Toolbar (Publish product)-->
          <!-- Content -->
          <div class="mb-md">
            <div class="columns border-b ">
              <div class="column is-flex">
                <!-- Product Info -->
                <div class="px-lg py-md mb-xxs">
                  <ProductInfo :product="detail" />
                </div>
              </div>
              <div class="column is-flex">
                <!-- Product Description -->
                <div class="px-lg py-md mb-xxs is-fullwidth" style="width:100%">
                  <ProductDescription :product="detail" />
                </div>
              </div>
            </div>
          </div>
          <div class="columns border-b pb-md">
            <div class="column">
              <!-- Item Selector -->
                <p>Select an item to view attributes</p>
                <b-select v-model="selectedItem" expanded @input="loadItemAttributes">
                  <option v-for="item in detail.variants" :value="item" :key="item.id">
                    {{ item.name }} (Supplier SKU:{{item.identifier}})
                  </option>
                </b-select>
            </div>
            <div class="column">
              <!-- Ruleset Selector -->
              <p>Select a ruleset to view respective attributes</p>
              <b-select v-model="ruleset" expanded :loading="loadingItemAttributes">
                <option v-for="ruleset in itemDetail && itemDetail.ruleset_variants" :value="ruleset" :key="ruleset.id">
                  {{ ruleset.ruleset.name }} ({{ $utils.upperFirst(ruleset.status) }})
                </option>
              </b-select>
            </div>
          </div>
          <!-- Item attributes -->
          <div v-if="!loadingItemAttributes && itemDetail !== null" class="mt-md">
            <!-- IDs -->
            <div v-if="auth.isStaff" class="mb-lg">
              <p class="has-text-weight-bold border-b has-border-lg mb-xs">Proposal Item Attributes</p>
              <table class="table is-fullwidth is-narrow is-striped is-size-7">
                <tbody>
                  <tr>
                    <th>Variant ID</th>
                    <td class="has-text-right">{{ itemDetail.id }}</td>
                    <td class="has-text-centered" style="width:16px">
                      <b-icon icon="check" size="is-small" style="width:16px" custom-class="has-text-success" />
                    </td>
                  </tr>
                  <tr>
                    <th>Proposal Variant ID</th>
                    <td class="has-text-right">{{ itemDetail.proposal_variant.id }}</td>
                    <td class="has-text-centered" style="width:16px">
                      <b-icon icon="check" size="is-small" style="width:16px" custom-class="has-text-success" />
                    </td>
                  </tr>
                  <tr v-if="itemDetail.active_permit">
                    <th>Active Permit ID</th>
                    <td class="has-text-right">
                      {{ itemDetail.active_permit.id }}
                      <span v-if="itemDetail.permits.length > 2">({{ itemDetail.permits.length }} permits)</span>
                    </td>
                    <td class="has-text-centered" style="width:16px">
                      <b-icon icon="check" size="is-small" style="width:16px" custom-class="has-text-success" />
                    </td>
                  </tr>
                  <tr v-if="proposalVariantCost !== null">
                    <th>Proposal Variant Retailer Cost</th>
                    <td class="has-text-right">
                      <CurrencyLocale :value="proposalVariantCost.value" :currency="proposalVariantCost.currency"/>
                    </td>
                    <td class="has-text-centered" style="width:16px">
                      <b-icon icon="check" size="is-small" style="width:16px" custom-class="has-text-success" />
                    </td>
                  </tr>
                  <tr v-if="proposalVariantPrice !== null">
                    <th>Proposal Variant Retailer Price</th>
                    <td class="has-text-right">
                      <CurrencyLocale :value="proposalVariantPrice.value" :currency="proposalVariantPrice.currency"/>
                    </td>
                    <td class="has-text-centered" style="width:16px">
                      <b-icon icon="check" size="is-small" style="width:16px" custom-class="has-text-success" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-if="ruleset !== null">
              <div v-for="group in ruleset.ruleset.rule_groups" :key="group.id" class="mb-md">
                <p class="has-text-weight-bold border-b has-border-lg mb-xs">{{ group.name }}</p>
                <!-- Attribute Loop -->
                <table class="table is-fullwidth is-narrow is-striped is-size-7">
                  <tbody>
                    <RuleReader
                      v-for="rule in group.rules"
                      :key="rule.id"
                      :rule="rule"
                      :attribute="(rule.attribute.code in itemDetail.attributes) ? itemDetail.attributes[rule.attribute.code] : null"
                      :result="(rule.attribute.code in rulesetResults) ? rulesetResults[rule.attribute.code] : null"
                    />
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div v-else>
            <loading message="Loading item attributes..." />
          </div>
        </div>
        <!-- Loading -->
        <div v-else>
          <loading message="Loading product..." />
        </div>
      </section>
      <footer class="modal-card-foot">
        <b-button label="Close" @click="$parent.close()" />
      </footer>
  </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CdnTransform from '@/mixins/CdnTransform'
import RuleReader from '@/components/Models/Proposal/Modals/RuleReader'
import ProductInfo from '../../../Catalog/components/Product/ProductInfo'
import ProductDescription from '../../../Catalog/components/Product/ProductDescription'
export default {
  components: {
    RuleReader,
    ProductInfo,
    ProductDescription
  },
  computed: {
    ...mapGetters(['auth', 'context']),
    loading () {
      return (this.loadingProduct || this.loadingVariants)
    },
    rulesetResults () {
      if (this.ruleset === null) return {}
      const results = {}
      this.ruleset.results.forEach(result => {
        results[result.rule.attribute.code] = result
      })
      return results
    },
    proposalVariantCost () {
      let cost = null
      if (this.itemDetail.proposal_variant.attributes && this.itemDetail.proposal_variant.attributes.retailer_cost) {
        cost = {
          value: this.itemDetail.proposal_variant.attributes.retailer_cost.values[0].value,
          currency: this.itemDetail.proposal_variant.attributes.retailer_cost.values[0]
        }
      }
      return cost
    },
    proposalVariantPrice () {
      let price = null
      if (this.itemDetail.proposal_variant.attributes && this.itemDetail.proposal_variant.attributes.retailer_price) {
        price = {
          value: this.itemDetail.proposal_variant.attributes.retailer_price.values[0].value,
          currency: this.itemDetail.proposal_variant.attributes.retailer_price.values[0]
        }
      }
      return price
    }
  },
  data () {
    return {
      loadingProduct: true,
      detail: null,
      loadingVariants: false,
      loadingItemAttributes: false,
      variants: [],
      selectedItem: null,
      itemDetail: null,
      ruleset: null
    }
  },
  methods: {
    loadProposalProduct () {
      this.loadingProduct = true
      const endpoint = this.context.route + '/proposals/' + this.proposal.id + '/products/' + this.product.id + '/'
      this.$http.get(endpoint).then(response => {
        this.detail = response.data
        if (this.detail.num_variants > 0) {
          this.selectedItem = response.data.variants[0]
          this.loadItemAttributes()
        }
        this.loadingProduct = false
      })
    },
    loadItemAttributes () {
      this.loadingItemAttributes = true
      const endpoint = this.context.route + '/proposals/' + this.proposal.id + '/variants/' + this.selectedItem.id + '/'
      const query = {
        params: {
          editor: 1
        }
      }
      this.$http.get(endpoint, query).then(response => {
        this.itemDetail = response.data
        if (response.data.ruleset_variants.length > 0) {
          this.ruleset = response.data.ruleset_variants[0]
        }
        this.loadingItemAttributes = false
      })
    },
    loadProposalVariants () {
      this.loadingVariants = true
      const endpoint = this.context.route + '/proposals/' + this.proposal.id + '/proposal-variants/'
      const query = {
        params: {
          product_id: this.product.id,
          added_to_proposal: 1,
          is_rejected: 0,
          short: 1
          // ruleset_variant_summary: 1,
        }
      }
      this.$http.get(endpoint, query).then(response => {
        this.variants = response.data.results
        this.loadingVariants = false
      })
    }
  },
  mounted () {
    this.loadProposalProduct()
  },
  mixins: [CdnTransform],
  props: {
    proposal: {
      type: Object,
      required: true
    },
    product: {
      type: Object
    }
  }
}
</script>
