<template>
  <div>
    <!-- Error -->
    <div v-if="('general' in errors)" class="mb-md">
      <p class="has-text-danger">{{ errors.general }}</p>
    </div>
    <!-- Fields -->
    <div>
      <div class="mb-lg">
        <p><label class="label">Connections Manager</label></p>
        <p class="mb-sm has-text-grey">The key contact for all supplier business inquiries</p>
        <b-field
          class="mb-lg"
          custom-class="required"
          :type="('connections_manager' in errors) ? 'is-danger' : ''"
          :message="('connections_manager' in errors) ? errors['connections_manager'][0] : ''">
          <b-select
            v-model="connectionManager"
            placeholder="Select User"
            :loading="loading"
            @input="announce"
            expanded>
            <option v-for="user in users" :key="user.id" :value="user.id">
              {{ user.first_name }} {{ user.last_name }}
            </option>
          </b-select>
        </b-field>
      </div>
      <div class="mb-lg">
        <p><label class="label">Merchandising Manager</label></p>
        <p class="mb-sm has-text-grey">The key contact for all product and inventory inquiries.</p>
        <b-field
          custom-class="required"
          :type="('merchandising_manager' in errors) ? 'is-danger' : ''"
          :message="('merchandising_manager' in errors) ? errors['merchandising_manager'][0] : ''">
          <b-select
            v-model="merchandisingManager"
            placeholder="Select User"
            :loading="loading"
            @input="announce"
            expanded>
            <option v-for="user in users" :key="user.id" :value="user.id">
              {{ user.first_name }} {{ user.last_name }}
            </option>
          </b-select>
        </b-field>
      </div>
      <div class="mb-lg">
        <p><label class="label">Transactions Manager</label></p>
        <p class="mb-sm has-text-grey">The key contact for all order and fulfillment inquiries.</p>
        <b-field
          custom-class="required"
          :type="('shipping_manager' in errors) ? 'is-danger' : ''"
          :message="('shipping_manager' in errors) ? errors['shipping_manager'][0] : ''">
          <b-select
            v-model="transactionsManager"
            placeholder="Select User"
            :loading="loading"
            @input="announce"
            expanded>
            <option v-for="user in users" :key="user.id" :value="user.id">
              {{ user.first_name }} {{ user.last_name }}
            </option>
          </b-select>
        </b-field>
      </div>
      <p class="is-size-7 has-text-grey-light">Need to invite a <a href="#" @click.prevent="showInviteModal=true" class="has-text-info">new teammate?</a></p>
    </div>
    <!-- Navigate -->
    <div class="pt-lg">
      <div class="columns">
        <div class="column">
          <b-button
            type="is-light rounded-lg"
            expanded
            @click="goBack"
            size="is-medium"
            ><b-icon icon="arrow-left"></b-icon>
          </b-button>
        </div>
        <div class="column">
          <b-button
            type="is-primary rounded-lg"
            expanded
            :disabled="!ready"
            :loading="saving || isUpdating"
            @click="goForward"
            size="is-medium"
          >Save & Continue</b-button>
        </div>
      </div>
    </div>
    <!-- Invite User Modal -->
    <b-modal
      :has-modal-card="true"
      :active.sync="showInviteModal"
      :can-cancel="['x']"
    ><InviteUserModal @invited:user="loadUsers(true)" />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import InviteUserModal from '@/components/Models/User/Modals/InviteUserModal'
export default {
  components: {
    InviteUserModal
  },
  computed: {
    ...mapGetters(['app', 'context']),
    ready () {
      return (this.connectionManager && this.connectionManager !== null &&
      this.merchandisingManager && this.merchandisingManager !== null &&
      this.transactionsManager && this.transactionsManager !== null)
    },
    loading () {
      return (this.loadingUsers || this.loadingPartner)
    },
    endpoint () {
      return this.context.route + '/'
    }
  },
  data () {
    return {
      connectionManager: null,
      merchandisingManager: null,
      transactionsManager: null,
      loadingUsers: true,
      loadingPartner: false,
      showInviteModal: false,
      users: [],
      errors: {},
      saving: false,
      isUpdating: false,
      setupIsCompleted: false,
      isNotModified: false
    }
  },
  methods: {
    goBack () {
      this.$router.push({ name: 'retailer.onboarding.business-address' })
    },
    loadUsers (quiet = false) {
      if (!quiet) this.loadingUsers = true
      const endpoint = this.context.route + '/users/'
      const query = {
        params: {
          mini: 1,
          pagination: 0,
          order_by: 'first_name'
        }
      }
      this.$http.get(endpoint, query).then(response => {
        this.users = response.data.results
        this.loadingUsers = false
      })
    },
    goForward () {
      if (this.setupIsCompleted && this.isNotModified) {
        this.$router.push({ name: 'retailer.onboarding.branding-logo', params: { task: this.$route.params.task } })
      } else {
        const json = {
          connections_manager: { id: this.connectionManager },
          merchandising_manager: { id: this.merchandisingManager },
          transaction_manager: { id: this.transactionsManager }
        }
        this.saving = true
        this.$http.patch(this.endpoint, json).then(response => {
          this.$buefy.toast.open({ type: 'is-success', message: 'Contact Updated!' })
          this.updateTask()
        }).catch(error => {
          if (error.response && error.response.status === 400) {
            this.errors = error.response.data
          }
        }).finally(() => {
          this.saving = false
        })
      }
    },
    updateTask () {
      const taskBody = (typeof this.task.info === 'object') ? { info: this.task.info } : { info: {} }
      taskBody.info.contact = true
      this.$emit('task:updated', taskBody, 'retailer.onboarding.branding-logo', true)
    },
    announce () {
      this.isNotModified = false
    }
  },
  mounted () {
    this.loadUsers()
    if (this.task && this.task.info && this.task.info.contact) {
      this.setupIsCompleted = true
      this.isNotModified = true
    }
    if (this.account) {
      if (this.account.connections_manager !== null) {
        this.connectionManager = this.account.connections_manager.id
      }
      if (this.account.merchandising_manager !== null) {
        this.merchandisingManager = this.account.merchandising_manager.id
      }
      if (this.account.transaction_manager !== null) {
        this.transactionsManager = this.account.transaction_manager.id
      }
    }
  },
  props: {
    task: {
      type: Object,
      require: true
    },
    account: {
      type: Object,
      required: true
    },
    isUpdatingTask: {
      type: Boolean
    }
  },
  watch: {
    isUpdatingTask (value) {
      this.isUpdating = value
    }
  }
}
</script>
