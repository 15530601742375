<template>
  <div class="section">
    <div class="container">
      <!-- Page Header -->
      <div class="columns is-vcentered">
        <div class="column is-one-third">
          <breadcrumbs :history="breadcrumbs.history" :current="breadcrumbs.current"  />
        </div>
        <div class="column is-one-third">
          <h1 class="has-text-centered is-size-4">Edit Rule Group</h1>
        </div>
        <div class="column is-one-third">
          <div class="is-pulled-right px-md">
            <b-button
              type="is-primary rounded-lg"
              :loading="loading"
              @click.prevent="showConfirmModal=true"
            >Delete
            </b-button>
          </div>
        </div>
      </div>
      <!-- Content -->
      <!-- Rule Group Info -->
      <div class="pb-lg border-b mb-lg">
        <div class="columns">
          <div class="column is-one-fifth">
            <p class="has-text-weight-bold is-size-6 mb-sm">Rule Group Settings</p>
            <p class="has-text-grey is-size-7">
              Configure basic settings for this rule group.
            </p>
          </div>
          <div class="column">
            <RuleGroupSettings :loading="loading" :ruleGroup="ruleGroup"/>
          </div>
        </div>
      </div>
      <!-- Step 2: Rules List -->
        <div class="columns">
          <div class="column is-one-fifth">
            <p class="has-text-weight-bold is-size-6 mb-sm">Rules</p>
            <p class="has-text-grey is-size-7">
              Add &amp; configure validation rules for individual attributes.
            </p>
          </div>
          <div class="column">
            <Rules :loading="loading" :ruleGroup="ruleGroup" @update:rule="showRuleValidationModal=true"/>
          </div>
        </div>
    </div>
    <!-- Delete confirmation Modal -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showConfirmModal"
      :can-cancel="['x']"
      ><ConfirmationModal
        :label="'Confirmation'"
        :reason="`Are you sure? Do you really want to delete ruleGroup? This will also delete all the respective rules.`"
        @modal:confirm="deleteRuleset"
        @modal:close="showConfirmModal = false"
        />
    </b-modal>
    <!-- Rule validation Modal -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showRuleValidationModal"
      :can-cancel="['x']"
      ><ConfirmationModal
        :label="'Confirmation'"
        :reason="`Are you sure? Do you really want to delete ruleGroup? This will also delete all the respective rules.`"
        @modal:confirm="deleteRuleset"
        @modal:close="showRuleValidationModal = false"
        />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ConfirmationModal from '@/components/Modals/ConfirmationModal'
import RuleGroupSettings from '../components/RuleGroupDetails/RuleGroupSettings'
import Rules from '../components/RuleGroupDetails/Rules'
export default {
  components: {
    ConfirmationModal,
    RuleGroupSettings,
    Rules
  },
  computed: {
    ...mapGetters(['app', 'api', 'context']),
    breadcrumbs () {
      return {
        history: [
          { label: 'Settings', name: 'settings' },
          { label: 'Product Rulesets', name: 'rulesets' },
          { label: `#${this.$route.params.ruleset}`, name: 'ruleset', params: { id: this.$route.params.ruleset } }
        ],
        current: 'Rule group #' + this.$route.params.id
      }
    },
    endpoint () {
      return this.api.baseUrl + `/v1/rule-groups/${this.$route.params.id}/`
    }
  },
  data () {
    return {
      saving: false,
      ruleGroup: null,
      loading: true,
      showConfirmModal: false,
      showRuleValidationModal: false,
      error: {}
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint).then(({ data }) => {
        this.ruleGroup = data
      }).finally(() => {
        this.loading = false
      })
    },
    deleteRuleset () {
      this.saving = true
      this.error = {}
      this.$http.delete(this.endpoint).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Rule group deleted successfully' })
        this.$router.push({ name: 'ruleset', params: { id: this.$route.params.ruleset } })
      }).catch(error => {
        let errorMessage = 'Please try again.'
        if (error.response && error.response.status === 400) {
          errorMessage = error.response.data.detail
        }
        this.$buefy.toast.open({ type: 'is-danger', message: errorMessage })
      }).finally(() => {
        this.saving = false
        this.showConfirmModal = false
      })
    }
  },
  mounted () {
    this.load()
  }
}
</script>
