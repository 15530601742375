<template>
  <div>
    <div class="section">
      <div class="container">
        <!-- Page Header -->
        <div class="columns is-vcentered">
          <div class="column is-one-third">
            <breadcrumbs :history="breadcrumbs.history" :current="breadcrumbs.current"  />
          </div>
          <div class="column is-one-third">
            <h1 class="has-text-centered is-size-4">Template Details</h1>
          </div>
        </div>
        <!-- Template Basics -->
        <div class="pb-lg border-b mb-lg">
          <div class="columns">
            <div class="column is-one-fifth">
              <p class="has-text-weight-bold is-size-6 mb-sm">Template Settings</p>
              <p class="has-text-grey is-size-7">
                Configure basic settings for this template.
              </p>
            </div>
            <div class="column">
              <div v-if="!loading && template !== null">
                <TemplateInfo :template="template" templateType="shopify"/>
              </div>
              <div v-else>
                <div class="has-background-white rounded-lg pa-lg">
                  <loading message="Loading Template..." />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Template Mappings -->
        <div class="pb-lg border-b mb-lg">
          <div class="columns">
            <div class="column is-one-fifth">
              <p class="has-text-weight-bold is-size-6 mb-sm">Primary Attribute Mappings</p>
              <p class="has-text-grey is-size-7">
                Map {{ app.productName }} attributes to primary attributes on Shopify products & variants.
              </p>
            </div>
            <div class="column">
              <div v-if="!loading && template !== null">
                <ExportTemplateMappings :template="template" templateType="shopify"/>
              </div>
              <div v-else>
                <div class="has-background-white rounded-lg pa-lg">
                  <loading message="Loading Template..." />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Tag Mappings -->
        <div class="pb-lg border-b mb-lg">
          <div class="columns">
            <div class="column is-one-fifth">
              <p class="has-text-weight-bold is-size-6 mb-sm">Tag Mappings</p>
              <p class="has-text-grey is-size-7">
                Map {{ app.productName }} attribute values to Shopify tags.
              </p>
            </div>
            <div class="column">
              <div v-if="!loading && template !== null">
                <ExportTemplateMappings :template="template" templateType="shopify" fieldsType="tag-mappings"/>
              </div>
              <div v-else>
                <div class="has-background-white rounded-lg pa-lg">
                  <loading message="Loading Template..." />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Meta Field Mappings -->
        <div class="columns">
          <div class="column is-one-fifth">
            <p class="has-text-weight-bold is-size-6 mb-sm">Metafield Mappings</p>
            <p class="has-text-grey is-size-7">
              Map {{ app.productName }} attribute values to Shopify tags.
            </p>
          </div>
          <div class="column">
            <div v-if="!loading && template !== null">
              <ExportTemplateMappings :template="template" templateType="shopify" fieldsType="metafield-mappings"/>
            </div>
            <div v-else>
              <div class="has-background-white rounded-lg pa-lg">
                <loading message="Loading Template..." />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </template>

<script>
import { mapGetters } from 'vuex'
import TemplateInfo from '../components/Template/TemplateInfo'
import ExportTemplateMappings from '../components/Template/ExportTemplateMappings'

export default {
  components: {
    TemplateInfo,
    ExportTemplateMappings
  },
  computed: {
    ...mapGetters(['app', 'context']),
    endpoint () {
      return this.context.route + `/shopify/${this.$route.params.id}/templates/${this.$route.params.templateId}/`
    },
    breadcrumbs () {
      return {
        history: [
          { label: 'Settings', name: 'settings' },
          { label: 'Shopify Templates', name: 'shopify.templates' }
        ],
        current: (this.loading) ? 'Loading...' : '#' + this.template.id
      }
    }
  },
  data () {
    return {
      loading: true,
      template: null
    }
  },
  methods: {
    load () {
      this.$http.get(this.endpoint).then(response => {
        this.template = response.data
        this.loading = false
      })
    }
  },
  mounted () {
    this.load()
  }
}
</script>
