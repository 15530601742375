<template>
  <div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">{{cardTitle}}</p>
      </header>
      <section class="modal-card-body">
        <p class="py-xl has-text-centered">
         {{cardBody}}
        </p>
      </section>
      <footer class="modal-card-foot space-between" >
        <b-button @click="$parent.close()">Close</b-button>
        <b-button
          type="is-danger"
          :disabled="saving"
          :loading="saving"
          @click="save">Archive</b-button>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['context']),
    cardTitle () {
      if (this.type === 'proposal') {
        return 'Archive Proposal?'
      } else {
        return 'Archive Issue?'
      }
    },
    cardBody () {
      if (this.type === 'proposal') {
        return `Are you sure you want to archive this proposal?
          This proposal will be canceled and this action cannot be undone.`
      } else {
        return `Are you sure you want to archive this issue?
          This issue will be deleted and this action cannot be undone.`
      }
    }
  },
  data () {
    return {
      saving: false,
      errors: {}
    }
  },
  methods: {
    save () {
      this.saving = true
      if (this.type === 'proposal') {
        this.archiveProposal()
      } else if (this.type === 'issue') {
        this.archiveIssue()
      }
    },
    archiveProposal () {
      const endpoint = this.context.route + '/proposals/' + this.proposal.id + '/'
      const body = {
        status: 'archived'
      }
      this.$http.patch(endpoint, body).then(response => {
        this.$buefy.toast.open({ type: 'is-info', message: 'Proposal Archived!' })
        this.$emit('proposal:archived')
        this.$parent.close()
      }).finally(() => {
        this.saving = false
      })
    },
    archiveIssue () {
      const endpoint = this.context.route + '/proposals/' + this.$route.params.id + '/issues/' + this.$route.params.issueId + '/archive/'
      this.$http.put(endpoint).then(response => {
        this.$buefy.toast.open({ type: 'is-info', message: 'Issue Archived!' })
        this.$emit('issue:archived')
        this.$parent.close()
      }).finally(() => {
        this.saving = false
      })
    }
  },
  props: {
    proposal: {
      type: Object
    },
    issue: {
      type: Object
    },
    type: {
      type: String,
      enum: ['proposal', 'issue'],
      required: true,
      default: 'proposal'
    }
  }
}
</script>
