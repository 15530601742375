<template>
  <form @submit.prevent="save">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ (location === null) ? 'Add Location' : 'Update Location' }}</p>
      </header>
      <section class="modal-card-body">
        <AddressForm
          v-model="existingLocation"
          :addressForm="existingLocation"
          type="warehouse"
          addressNameLabel="Business Name"
          :errors="errors"
          :requireNickname="true"
          :requireAddressName="true"
          :requireEmail="true"
          :requirePhone="true"
          @is:ready="isReady"
        />
      </section>
      <footer class="modal-card-foot" style="justify-content:flex-end">
        <button class="button" type="button" @click="$parent.close()">Close</button>
        <b-button
          v-if="location === null"
          native-type="submit"
          type="is-primary"
          :loading="saving"
          :disabled="!isValidated || saving"
        >Add Location</b-button>
        <b-button
          v-else
          native-type="submit"
          type="is-primary"
          :loading="saving"
          :disabled="!isValidated || saving"
        >Update Location</b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import AddressForm from '@/components/Global/AddressForm'
export default {
  components: {
    AddressForm
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      let route = this.context.route + '/locations/'
      if (this.location !== null) {
        route += this.location.id + '/'
      }
      return route
    },
    method () {
      return (this.location === null) ? 'post' : 'patch'
    },
    json () {
      return {
        nickname: this.existingLocation.nickname,
        type: this.existingLocation.type,
        address: this.existingLocation.address
      }
    }
  },
  data () {
    return {
      saving: false,
      errors: {},
      existingLocation: null,
      isValidated: false
    }
  },
  methods: {
    save () {
      this.saving = true
      this.$http[this.method](this.endpoint, this.json).then(response => {
        this.saving = false
        this.$emit('updated')
        this.$buefy.toast.open({ type: 'is-success', message: 'Location saved!' })
        this.$parent.close()
      }).catch(error => {
        this.saving = false
        if (error.response && error.response.status === 400) {
          this.errors = error.response.data
        }
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      })
    },
    isReady (val) {
      this.isValidated = val
    }
  },
  mounted () {

  },
  watch: {
    location: {
      handler (location) {
        if (location) {
          this.existingLocation = this.location
        }
      },
      // force eager callback execution
      immediate: true
    }
  },
  props: ['location']
}
</script>
