<template>
  <div>
    <form @submit.prevent="save">
      <div class="columns">
        <div class="column is-one-fifth">
          <p class="has-text-weight-bold is-size-6 mb-sm">About</p>
          <div class="has-text-grey is-size-7">
            <p class="mb-md">
              Share your whole story with the {{ app.platform }} Network!
            </p>
            <p>
              Some HTML content (e.g. lists) is allowed.
            </p>
          </div>
        </div>
        <div class="column">
          <div class="has-background-white rounded-lg">
            <div class="px-lg pt-lg pb-sm">
              <!-- Loading platform account or loading marketplace -->
              <div v-if="loading" class="pa-xl">
                <loading message="Loading..."></loading>
              </div>
              <!-- Loaded -->
              <div v-else>
                <editor-menu-bar :editor="editor" v-slot="{ commands, isActive }">
                  <div class="mb-lg">
                    <div class="buttons">
                      <b-button :class="{ 'is-active': isActive.bullet_list() }" @click.prevent="commands.bullet_list">
                        <b-icon icon="format-list-bulleted"  />
                      </b-button>
                      <b-button :class="{ 'is-active': isActive.ordered_list() }" @click.prevent="commands.ordered_list">
                        <b-icon icon="format-list-numbered"  />
                      </b-button>
                    </div>
                  </div>
                </editor-menu-bar>
                <editor-content :editor="editor" class="border pa-md content mb-md" style="min-height:250px" />
              </div>
            </div>
            <div class="border-t">
              <div class="columns is-vcentered is-marginless">
                <div class="column">
                  <div class="px-sm has-text-grey-light">
                    <i v-if="isDirty" class="has-text-danger">Click 'Save' to save your changes before moving on to the next section.</i>
                  </div>
                </div>
                <div class="column is-narrow">
                  <div class="px-sm">
                    <b-button native-type="submit" type="is-primary rounded-lg" :loading="saving" :disabled="saving || !isDirty">Save</b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
import { OrderedList, BulletList, ListItem, HardBreak } from 'tiptap-extensions'

export default {
  components: {
    EditorContent,
    EditorMenuBar
  },
  computed: {
    ...mapGetters(['app', 'context']),
    endpoint () {
      if (this.marketplace === null) return null
      return this.context.route + '/marketplaces/' + this.marketplace.id + '/'
    },
    json () {
      return {
        about_us: this.descriptionHtml
      }
    }
  },
  data () {
    return {
      isDirty: false,
      descriptionHtml: null,
      editor: null,
      errors: {},
      saving: false
    }
  },
  methods: {
    save () {
      this.saving = true
      this.$http.patch(this.endpoint, this.json).then(response => {
        this.isDirty = false
        this.$emit('visibility:updated', this.visibility)
        this.$buefy.toast.open({ type: 'is-success', message: 'Profile updated!' })
      }).catch(() => {
        this.$buefy.toast.open({ type: 'is-danger', message: 'Update failed! Please check your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    this.editor = new Editor({
      extensions: [
        new ListItem(),
        new OrderedList(),
        new BulletList(),
        new HardBreak()
      ],
      onUpdate: ({ getHTML }) => {
        this.isDirty = true
        this.descriptionHtml = getHTML()
      }
    })
  },
  beforeDestroy () {
    this.editor.destroy()
  },
  props: ['account', 'loading', 'marketplace'],
  watch: {
    loading (value) {
      if (value === false && this.marketplace.about_us !== null) {
        this.editor.setContent(this.marketplace.about_us)
      }
    }
  }
}
</script>
