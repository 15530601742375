<template>
  <b-button
    :type="type"
    :outlined="buttonOutlined"
    :loading="loading"
    :disabled="disabled"
    @click="handleClick">
      {{ buttonLabel }}
  </b-button>
</template>

<script>
export default {
  data () {
    return {
      state: 'initial',
      hasBeenClicked: false,
      buttonLabel: null
    }
  },
  methods: {
    handleClick () {
      if (this.hasBeenClicked) {
        this.$emit('action:confirmed')
        this.reset()
      } else {
        this.outlinedButton = false
        this.hasBeenClicked = true
        this.buttonLabel = 'Are you sure?'
        setTimeout(() => {
          if (this.hasBeenClicked) {
            this.reset()
          }
        }, 5000)
      }
    },
    reset () {
      this.hasBeenClicked = false
      this.buttonLabel = this.initialLabel
      this.outlinedButton = this.outlined
    }
  },
  created () {
    this.buttonLabel = this.initialLabel
    this.buttonOutlined = this.outlined
  },
  props: {
    initialLabel: {
      type: String,
      required: true
    },
    confirmLabel: {
      type: String,
      default: 'Are you sure?'
    },
    type: {
      type: String,
      default: 'is-danger'
    },
    outlined: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>
