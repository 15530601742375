<template>
  <div>
    <!-- Content -->
    <div class="mb-lg">
      <p class="has-text-weight-bold mb-sm has-text-info">{{ title }}</p>
      <p class="mb-lg">
        If you want to be notified when a new order is received,
        the platform will send an email notification to the address or distribution list specified below:
      </p>
      <!-- Emails -->
      <div class="mb-lg">
        <b-field>
          <b-input
            type="email"
            size="is-medium"
            placeholder="Email address or distribution list..."
            v-model="emailAddress"
          ></b-input>
        </b-field>
        <p v-if="notification">
          <span class="has-text-grey-light">Current Status </span>
          <span v-if="enabled === 'Enabled'" class="has-text-success has-text-weight-bold">{{ enabled }}</span>
          <span v-else class="has-text-warning has-text-weight-bold">Not Enabled</span>
        </p>
      </div>
    </div>
    <!-- Navigate -->
    <div class="py-lg">
      <div class="columns is-centered">
        <div class="column">
          <b-button
            type="is-light rounded-lg"
            size="is-medium"
            @click="goBack"
            expanded
            ><b-icon icon="arrow-left"></b-icon>
          </b-button>
        </div>
        <div class="column">
          <b-button
            type="is-primary rounded-lg"
            size="is-medium"
            expanded
            :disabled="!ready || saving"
            :loading="saving"
            @click="goForward"
          >Save &amp; Continue</b-button>
        </div>
      </div>
      <div class="pt-lg has-text-centered">
        <p class="mb-md"><a href="#" @click.prevent="skip">Skip Notification</a></p>
        <p class="has-text-grey-light">
          Notifications can be changed anytime in your
          <router-link class="has-text-info" :to="{ name: 'settings.notifications' }">Supplier Settings.</router-link>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['app', 'context']),
    endpoint () {
      const base = this.context.route + '/push-notifications/'
      return (this.notification !== null)
        ? base + this.notification.id + '/'
        : base
    },
    method () {
      return (this.notification !== null) ? 'patch' : 'post'
    },
    json () {
      return {
        action: { name: this.action },
        delivery_method: 'recipient',
        recipient_email: this.emailAddress,
        status: 'enabled'
      }
    },
    ready () {
      return (this.emailAddress !== null)
    }
  },
  data () {
    return {
      notification: null,
      title: 'Order Received',
      action: 'Order Created',
      emailAddress: null,
      enabled: 'Enabled',
      saving: false,
      errors: {}
    }
  },
  methods: {
    goBack () {
      this.$emit('navigate', 'Connection')
    },
    goForward () {
      this.saving = true
      this.$http[this.method](this.endpoint, this.json).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Preference updated!' })
        this.$emit('push-notifications:updated')
        this.$emit('navigate', 'Change')
      }).catch(error => {
        if (error.response && error.response.status === 400) {
          this.errors = error.response.data
          this.enabled = 'Not Enabled'
        }
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    },
    skip () {
      this.$emit('navigate', 'Change')
    }
  },
  mounted () {
    const notification = this.$utils.find(this.pushNotifications, (notification) => {
      return notification.action.name === this.action
    })
    if (typeof notification !== 'undefined') {
      this.notification = notification
      this.emailAddress = notification.recipient_email
      this.enabled = (notification.status === 'enabled') ? 'Enabled' : 'Not Enabled'
    }
  },
  props: {
    connection: {
      type: Object,
      required: true
    },
    task: {
      type: Object,
      required: true
    },
    pushNotifications: {
      type: Array,
      required: true
    }
  }
}
</script>
