<template>
<div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Payment Details</p>
    </header>
    <section class="modal-card-body">
      <!-- Non-Field / General Errors -->
      <div v-if="'non_field_errors' in errors" class="mb-lg">
        <b-message type="is-danger">{{ errors.non_field_errors[0] }}</b-message>
      </div>
      <b-field label="Check Issue Date">
        <b-input type="date" v-model="checkDate" @input="change" size="is-medium"/>
      </b-field>
      <b-field label="Check Number">
        <b-input v-model="checkNumber" @input="change" size="is-medium"></b-input>
      </b-field>
      <div>
        <label class="label">Check Amount</label>
        <div class="columns is-vcentered">
          <div class="column">
            <b-field>
              <b-input type="number" v-model="checkAmount" @input="change" step="0.01" size="is-medium"></b-input>
            </b-field>
          </div>
          <div class="column is-narrow">
            <a href="#" @click.prevent="useTotal" class="has-text-info">
              Use  {{ $numbers(total).formatCurrency({ mantissa: 2 }) }}
            </a>
          </div>
        </div>
      </div>
    </section>
    <footer class="modal-card-foot space-between">
      <b-button @click="$parent.close()">Close</b-button>
      <b-button
        :loading="saving"
        :disabled="!isDirty"
        type="is-primary"
        @click="save"
      >Save</b-button>
    </footer>
  </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/payments/' + this.$route.params.id + '/'
    },
    total () {
      let total = 0
      total += (this.payment.total_invoices_amount) ? parseFloat(this.payment.total_invoices_amount) : 0
      total -= (this.payment.total_credits_amount) ? parseFloat(this.payment.total_credits_amount) : 0
      total += (this.payment.total_adjustments_amount) ? parseFloat(this.payment.total_adjustments_amount) : 0
      return total
    }
  },
  data () {
    return {
      saving: false,
      errors: {},
      checkNumber: null,
      checkAmount: null,
      checkDate: null,
      isDirty: false
    }
  },
  methods: {
    clearDate () {
      this.checkDate = null
    },
    change () {
      this.isDirty = true
      this.errors = {}
    },
    save () {
      this.saving = true
      const json = {
        ...this.payment,
        status: 'draft',
        check_number: this.checkNumber,
        check_issue_date: new Date(this.checkDate).toISOString(),
        check_amount: this.checkAmount
      }
      this.$http.patch(this.endpoint, json).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Payment details saved successfully!' })
        this.$emit('reload')
      }).catch((error) => {
        if (error.response && error.response.status === 400) {
          this.errors = error.response.data
        }
      }).finally(() => {
        this.saving = false
      })
    },
    useTotal () {
      this.checkAmount = this.$numbers(this.total).format({ mantissa: 2 })
    }
  },
  mounted () {
    this.checkNumber = this.payment.check_number && this.payment.check_number
    this.checkAmount = this.payment.check_amount && this.payment.check_amount
    this.checkDate = this.payment.check_issue_date && this.$dates(this.payment.check_issue_date).toDate()
  },
  props: {
    payment: {
      type: Object,
      default: null
    }
  }
}
</script>
