<template>
  <p>
    <span class="has-text-weight-bold mr-sm">{{ presenter.rank }}</span>
    <span :class="presenter.changeTextClass">{{ presenter.rankChange }}</span>
  </p>
</template>

<script>
export default {
  computed: {
    presenter () {
      const rank = this.rank
      const rankChange = this.rankChange
      const presenter = {
        rank: rank,
        rankChange: rankChange,
        changeTextClass: null
      }

      if (rankChange < 0) {
        presenter.changeTextClass = 'has-text-success'
      } else if (rankChange > 0) {
        presenter.changeTextClass = 'has-text-danger'
        presenter.rankChange = `+${this.rankChange}`
      } else {
        presenter.changeTextClass = 'has-text-grey-lighter'
        presenter.rankChange = '+0'
      }
      return presenter
    }
  },
  props: ['rank', 'rankChange']
}
</script>
