<template>
  <div>
    <div class="section">
      <div class="container">
        <!-- Page Header -->
        <div class="columns is-vcentered">
          <div class="column is-one-third">
            <breadcrumbs :history="[{ label: 'Catalog', name: 'catalog'}]" current="Import Product Request" :hideBack="true" />
          </div>
          <div class="column is-one-third">
            <h1 class="has-text-centered is-size-4">Import Product Data</h1>
          </div>
        </div>
        <!-- Content -->
        <div>
          <!-- Step 1: Select Connection -->
          <div class="mb-lg">
            <div class="columns">
              <div class="column is-one-fifth">
                <p class="has-text-weight-bold is-size-6 mb-sm">Step 1: Select Supplier</p>
                <p class="has-text-grey is-size-7">Select the partner that must approve this import request.</p>
              </div>
              <div class="column">
                <div class="has-background-white rounded-lg pa-xl">
                  <p class="mb-md">
                    As a merchant, you may request approval to upload products
                    on behalf of a connected supplier. When you submit a request,
                    the supplier will have a chance to review and
                    approve (or decline) the request.
                  </p>
                  <p class="mb-md">
                    To get started,
                    select a connection and file template below:
                  </p>
                  <div class="mb-xl">
                    <div class="columns">
                      <div class="column">
                        <b-field label="Connection">
                          <ConnectionSelect v-model="connection" />
                        </b-field>
                      </div>
                      <div class="column">
                        <b-field label="File Template">
                          <b-select
                            v-model="template"
                            placeholder="Select Template..."
                            :disabled="loading || scheduling"
                            :loading="loading"
                            expanded>
                            <option v-for="template in templates" :value="template" :key="template.id">{{ template.name }}</option>
                          </b-select>
                        </b-field>
                      </div>
                    </div>
                    <div class="columns">
                      <div class="column">
                        <b-field label="Purpose">
                          <b-select
                            v-model="behavior"
                            placeholder="Select behavior..."
                            :disabled="loading || scheduling"
                            :loading="loading"
                            expanded>
                            <option value="merge">Add new items & update existing items</option>
                            <option value="update">Update existing items only</option>
                          </b-select>
                        </b-field>
                      </div>
                      <div class="column">
                        <b-field
                          label="Reference Number"
                          expanded>
                          <b-input
                            type="text"
                            v-model="referenceNumber"
                          ></b-input>
                        </b-field>
                      </div>
                    </div>
                  </div>
                  <!-- Post Processing -->
                  <div v-if="!loading && platformAccount !== null" class="mb-md">
                    <p class="has-text-weight-bold mb-md">Post-Processing Options</p>
                    <div class="columns">
                      <div class="column is-half">
                        <b-field>
                          <b-radio v-model="postProcessing" native-value="none">
                            None
                          </b-radio>
                        </b-field>
                        <b-field>
                          <b-radio v-model="postProcessing" native-value="publish">
                            Publish products to my storefront platform
                          </b-radio>
                        </b-field>
                        <b-field v-if="postProcessing === 'publish'" label="Export Template">
                          <b-select
                            v-model="exportTemplate"
                            :loading="loading"
                            expanded>
                            <option
                              v-for="template in exportTemplates"
                              :key="template.id"
                              value="template">{{ template.name }}</option>
                          </b-select>
                        </b-field>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Step 2: Select File -->
          <div v-if="connection !== null && template !== null" class="mb-lg pt-lg has-border-t">
            <div class="columns">
              <div class="column is-one-fifth">
                <p class="has-text-weight-bold is-size-6 mb-sm">Step 2: Upload File</p>
                <p class="has-text-grey is-size-7">Select a file to upload.</p>
              </div>
              <div class="column">
                <div class="has-background-white pa-lg mb-xs rounded-lg">
                  <div class="mb-md">
                    <p>Next, select or drag the file you want to upload to {{ app.platform }}.</p>
                  </div>
                  <div class="columns">
                    <div class="column is-half-desktop">
                      <!-- Upload -->
                      <CoreUpload @file:uploaded="updateTempfile" @file:reset="resetTempfile"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Step 3: Submit Request -->
          <div v-if="tempfile !== null" class="mb-lg pt-lg has-border-t">
            <div class="columns">
              <div class="column is-one-fifth">
                <p class="has-text-weight-bold is-size-6 mb-sm">Step 3: Submit request</p>
                <p class="has-text-grey is-size-7">Submit your request to your partner for review.</p>
              </div>
              <div class="column">
                <div class="has-background-white pa-lg mb-lg rounded-xl">
                  <div class="mb-lg">
                    <p class="has-text-grey">When ready, click the button below to submit your request.</p>
                  </div>
                  <div>
                    <b-button
                      type="is-primary"
                      :disabled="!ready || feed !== null"
                      :loading="scheduling"
                      @click="queueFile"
                    >Request Approval from Supplier</b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CoreUpload from '@/components/CoreUpload'
import ConnectionSelect from '@/components/Models/Connection/ConnectionSelect'

export default {
  components: {
    ConnectionSelect,
    CoreUpload
  },
  computed: {
    ...mapGetters(['app', 'context']),
    endpoints () {
      return {
        template: this.context.route + '/templates/',
        queue: this.context.route + '/feed-requests/'
      }
    },
    templatesQuery () {
      return {
        params: {
          direction: 'import',
          data_type: 'products',
          limit: 100
        }
      }
    },
    json () {
      if (this.connection === null || this.template === null) return {}
      const json = {
        brand: {
          id: this.connection.brand.id
        },
        template: {
          id: this.template.id
        },
        reference_number: this.referenceNumber,
        direction: 'import',
        data_type: 'products',
        source: 'console',
        delimiter: 'comma',
        behavior: this.behavior,
        info: {},
        feed_file_id: this.tempfile.id,
        push_to_shopify: (this.postProcessing === 'publish') ? 1 : 0,
        shopify_template_id: null
      }
      if (this.postProcessing === 'publish') {
        json.push_to_shopify = 1
        json.shopify_template_id = this.exportTemplate.id
      }
      return json
    },
    loading () {
      return (
        this.loadingTemplates ||
        this.loadingPlatformAccounts ||
        this.loadingExportTemplates
      )
    },
    ready () {
      const ready = (
        !this.loadingTemplates &&
        !this.uploading &&
        !this.scheduling &&
        this.template !== null &&
        this.tempfile !== null
      )
      if (this.postProcessing === 'publish') {
        if (!this.exportTemplate) return false
      }
      return ready
    }
  },
  data () {
    return {
      connection: null,
      loadingTemplates: true,
      loadingPlatformAccounts: true,
      loadingExportTemplates: false,
      templates: [],
      template: null,
      tempfile: null,
      behavior: 'merge',
      referenceNumber: null,
      scheduling: false,
      feed: null,
      postProcessing: 'none',
      platformAccount: null,
      exportTemplates: [],
      exportTemplate: null
    }
  },
  methods: {
    loadTemplates () {
      this.loadingTemplates = true
      this.$http.get(this.endpoints.template, this.templatesQuery).then(response => {
        this.templates = response.data.results
        this.loadingTemplates = false
      })
    },
    loadPlatformAccounts () {
      this.loadingPlatformAccounts = true
      const endpoint = this.context.route + '/platform-accounts/'
      const query = {
        params: {
          code: 'shopify'
        }
      }
      this.$http.get(endpoint, query).then(response => {
        if (response.data.results.length === 1) {
          this.platformAccount = response.data.results[0]
          this.loadExportTemplates()
        }
        this.loadingPlatformAccounts = false
      })
    },
    loadExportTemplates () {
      this.loadingExportTemplates = true
      const endpoint = this.context.route + '/shopify/' + this.platformAccount.id + '/templates/'
      const query = {
        params: {
          direction: 'export'
        }
      }
      this.$http.get(endpoint, query).then(response => {
        this.exportTemplates = response.data.results
        this.loadingExportTemplates = false
      })
    },
    queueFile () {
      this.scheduling = true
      this.$http.post(this.endpoints.queue, this.json).then(response => {
        this.referenceNumber = null
        this.connection = null
        this.template = null
        this.tempfile = null
        this.$buefy.toast.open({ type: 'is-success', message: 'Import request created!' })
        this.$router.push({ name: 'catalog.import.requests' })
      }).finally(() => {
        this.scheduling = false
      })
    },
    updateTempfile (file, response) {
      this.tempfile = response
    },
    resetTempfile () {
      this.tempfile = null
    }
  },
  mounted () {
    this.loadTemplates()
    this.loadPlatformAccounts()
  }
}
</script>
