<template>
    <div>
      <div class="has-background-light rounded-lg pa-md">
        <p class="has-text-weight-bold">WooCommerce Integration #{{ account.id }}</p>
        <p v-if="pushOrders">
          Push Orders:
          <span v-if='pushOrders' class="has-text-success has-text-weight-bold">Enabled</span>
          <span v-else class="has-text-danger has-text-weight-bold">Disabled</span>
        </p>
        <p v-else class="has-text-grey">
          This account requires
          <router-link :to="{ name: 'settings.integrations', params: { id: account.id }}" class="has-text-info">
            additional configuration
          </router-link>
        </p>
      </div>
    </div>
  </template>

<script>
export default {
  computed: {
    pushOrders () {
      let setting = false
      this.account.credentials.forEach(credential => {
        Object.keys(credential).forEach(key => {
          if (key === 'push_order_on_order_create' && credential[key].values[0].value) {
            setting = (credential[key].values[0].value === '1')
          }
        })
      })
      return setting
    }
  },
  props: {
    account: {
      type: Object,
      required: true
    }
  }
}
</script>
