<template>
  <div>
    <form @submit.prevent="save">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Update Contacts</p>
        </header>
        <section class="modal-card-body">
          <template v-if="context.isMerchant()">
            <!-- Merchandising -->
            <div>
              <p class="mb-sm">
                <label v-if="contactType === 'merchandising'" class="label">Merchandising Contact</label>
                <label v-else-if="contactType === 'orders'" class="label">Fulfillment Contact</label>
              </p>
              <b-select
                v-model="retailerContact"
                :loading="loading"
                :disabled="loading || saving"
                placeholder="Select user..."
                size="is-medium"
                expanded>
                <option v-for="user in users" :key="user.id" :value="user">{{ user.name }}</option>
              </b-select>
            </div>
          </template>
        </section>
        <footer class="modal-card-foot space-between">
          <b-button @click="$parent.close()">Close</b-button>
          <b-button
            type="is-primary"
            native-type="submit"
            :loading="saving"
            :disabled="saving">
            Save Contacts
          </b-button>
        </footer>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  data () {
    return {
      loadingUsers: true,
      users: [],
      retailerContactId: null,
      retailerContact: null,
      saving: false,
      error: null,
      errors: {}
    }
  },
  computed: {
    ...mapGetters(['context']),
    hasTransactions () {
      return this.connection.level === 'transactions_and_products'
    },
    method () {
      return (this.retailerContactId) ? 'patch' : 'post'
    },
    endpoint () {
      return (this.retailerContactId)
        ? this.context.route + '/connections/' + this.connection.id + '/connection-contacts/' + this.retailerContactId + '/'
        : this.context.route + '/connections/' + this.connection.id + '/connection-contacts/'
    },
    loading () {
      return Boolean(this.loadingUsers)
    },
    json () {
      if (this.retailerContact) {
        return {
          contact_type: this.contactType,
          retailer: {
            id: this.context.partner.id
          },
          user: {
            id: this.retailerContact.user_id
          }
        }
      }
      return {}
    }
  },
  methods: {
    loadUsers () {
      this.loadingUsers = true
      const endpoint = this.context.route + '/users/'
      const query = {
        params: {
          mini: 1,
          pagination: 0,
          orderBy: 'first_name',
          type: 'standard',
          status: 'active,invited'
        }
      }
      this.$http.get(endpoint, query).then(response => {
        this.users = []
        response.data.results.forEach(user => {
          this.users.push({
            user_id: user.id,
            name: user.first_name + ' ' + user.last_name
          })
        })
        this.loadingUsers = false
      })
    },
    save () {
      this.saving = true
      this.$http[this.method](this.endpoint, this.json).then(response => {
        this.$emit('connection:updated')
        this.$buefy.toast.open({ type: 'is-success', message: 'Contacts updated!' })
        this.$parent.close()
      }).catch(error => {
        if (error.response && 'non_field_errors' in error.response.data) {
          this.error = error.response.data.non_field_errors[0]
        }
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    this.loadUsers()
    this.connection.connection_contacts.forEach(contact => {
      if ('retailer' in contact && contact.contact_type === this.contactType) {
        this.retailerContactId = contact.id
        this.retailerContact = {
          user_id: contact.user_id,
          name: contact.first_name + ' ' + contact.last_name
        }
      }
    })
  },
  props: {
    connection: {
      type: Object,
      required: true
    },
    contactType: {
      type: String,
      required: true
    }
  }
}
</script>
