<template>
  <form @submit.prevent="update" novalidate autocomplete="off">
    <div class="columns">
      <div class="column is-one-fifth">
        <p class="has-text-weight-bold is-size-6 mb-sm">Shopify Notifications</p>
        <p class="has-text-grey is-size-7">
          Subscribe to push notifications to receive real-time alerts related to Shopify errors.
        </p>
      </div>
      <div class="column">
        <div v-if="!isLoading">
          <!-- Supplier Notifications -->
            <div v-for="notification in supplierNotifications" :key="notification.action" class="mb-md">
              <PushNotification
                :notifications="pushNotifications"
                :action="notification.action"
                :title="notification.title"
                :text="notification.text" />
            </div>
        </div>
        <!-- while loading -->
        <div v-else>
          <div v-for="index in 2" :key="index">
            <div class="has-background-white pa-md rounded-lg mb-sm">
              <div class="mb-md">
                <b-skeleton width="25%" :animated="true"></b-skeleton>
                <b-skeleton width="75%" :animated="true"></b-skeleton>
              </div>
              <b-skeleton width="50%" height="30" :animated="true"></b-skeleton>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import PushNotification from './PushNotification'

export default {
  components: {
    PushNotification
  },
  computed: {
    ...mapGetters(['context']),
    supplierNotifications () {
      const notifications = []
      this.availableNotifications.forEach(notification => {
        if (notification.context.includes('supplier')) {
          notifications.push(notification)
        }
      })
      return notifications
    }
  },
  data () {
    return {
      availableNotifications: [{
        action: 'Push Order Error',
        title: 'Push Order Error',
        text: 'If you have any orders failed to be pushed to Shopify, the platform will send notification to the distribution list specified below.',
        context: ['supplier']
      }]
    }
  },
  methods: {

  },
  mounted () {
  },
  props: {
    pushNotifications: {
      type: Array,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: true
    }
  }
}
</script>
