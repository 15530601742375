<template>
  <div class="has-background-white rounded-xl">
    <!-- Loaded -->
    <div v-if="preferences !== null">
      <div class="mb-lg">
        <FieldLabel label="Customer Service &amp; Returns Allowance" required/>
        <div class="has-text-grey">
          <p class="mb-md">
            Setting a customer service &amp; returns allowance on a connection
            automatically applies an adjustment to all new incoming supplier invoices, decreasing the
            amount {{ context.partner.name }} owes to the supplier.
          </p>
          <p>
            This allowance is typically applied to offset the costs of returns (and other
            costs associated with servicing a customer).
            To set a default returns allowance for any new connections automatically, enter a value between
            0 and 10%.
          </p>
        </div>
      </div>
      <b-field>
        <b-numberinput
          type="text"
          size="is-medium"
          v-model="value"
          step="0.25"
          min="0"
          max="10"
          @input="announce"
        ></b-numberinput>
      </b-field>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  computed: {
    ...mapGetters(['context']),
    json () {
      return {
        preference: { id: this.preference.id },
        value: this.value
      }
    },
    ready () {
      return (
        this.value !== null &&
        parseFloat(this.value) >= 0 &&
        parseFloat(this.value) <= 10
      )
    }
  },
  data () {
    return {
      preference: null,
      value: null,
      errors: [],
      saving: false
    }
  },
  methods: {
    announce () {
      this.$emit('input', this.json)
    }
  },
  mounted () {
    const preference = this.$utils.find(this.preferences, ['code', 'default_returns_allowance'])
    if (typeof preference !== 'undefined') {
      this.preference = preference
      if (this.preference.retailer_preference) {
        this.value = parseFloat(preference.retailer_preference.value)
      }
      this.announce()
    }
  },
  props: {
    preferences: {
      type: Array,
      required: true
    }
  }
}
</script>
