import dayjs from 'dayjs'
export default {
  today: {
    label: 'Today',
    value: 'today',
    start: dayjs().hour(0).minute(0).second(0),
    end: dayjs().hour(23).minute(59).second(59)
  },
  yesterday: {
    label: 'Yesterday',
    value: 'yesterday',
    start: dayjs().subtract(1, 'day').hour(0).minute(0).second(0),
    end: dayjs().subtract(1, 'day').hour(23).minute(59).second(59)
  },
  last_7: {
    label: 'Last 7 Days',
    value: 'last_7',
    start: dayjs().subtract(7, 'day').hour(0).minute(0).second(0),
    end: dayjs().hour(23).minute(59).second(59)
  },
  last_30: {
    label: 'Last 30 Days',
    value: 'last_30',
    start: dayjs().subtract(30, 'day').hour(0).minute(0).second(0),
    end: dayjs().hour(23).minute(59).second(59)
  },
  this_month: {
    label: 'This Month',
    value: 'this_month',
    start: dayjs().startOf('month').hour(0).minute(0).second(0),
    end: dayjs().hour(23).minute(59).second(59)
  },
  last_month: {
    label: 'Last Month',
    value: 'last_month',
    start: dayjs().subtract(1, 'month').startOf('month').hour(0).minute(0).second(0),
    end: dayjs().subtract(1, 'month').endOf('month').hour(23).minute(59).second(59)
  },
  next_month: {
    label: 'Next Month',
    value: 'next_month',
    start: dayjs().add(1, 'month').startOf('month').hour(0).minute(0).second(0),
    end: dayjs().add(1, 'month').endOf('month').hour(23).minute(59).second(59)
  }
}
