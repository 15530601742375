<template>
  <div class="section">
    <div class="container">
      <!-- Page Header -->
      <div class="mb-lg">
        <div class="columns is-vcentered">
          <div class="column is-one-third">
            <breadcrumbs :history="[{ label: 'Context', name: 'context'}]" current="Create Account" />
          </div>
          <div class="column is-one-third">
            <h1 class="has-text-centered is-size-4">Create Account</h1>
          </div>
        </div>
      </div>
      <!-- Account Info -->
      <div class="border-b pb-lg mb-lg">
        <AccountInfo />
      </div>
    </div>
  </div>
</template>

<script>
import AccountInfo from '../components/AccountInfo'

export default {
  components: {
    AccountInfo
  }
}
</script>
