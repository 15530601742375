<template>
  <div>
    <!-- Content -->
    <div class="">
      <p class="has-text-weight-bold mb-md">Activate Orders Integration</p>
      <div class="mb-lg">
        <p class="mb-md">
          {{ app.platform }} can automatically send orders
          from your merchant partners directly to your ShipStation account.
          Please note that if you enable this integration, orders from
          <b><u>ALL</u></b> of your merchant connections on {{ app.platform }} will begin to flow
          through this integration.
        </p>
      </div>
      <div class="mb-lg">
        <p class="has-text-weight-bold mb-md">Push Orders to Shipstation?</p>
        <div class="columns is-mobile is-vcentered">
          <div class="column is-narrow">
            <div class="field">
              <b-switch v-model="sync" type="is-success" size="is-medium" true-value="Yes" false-value="No">{{ sync }}</b-switch>
            </div>
          </div>
        </div>
        <div class="has-text-grey-light">
          <p v-if="credentialIsEnabled">This integration is currently enabled. Disable to stop receiving orders.</p>
          <p v-if="!credentialIsEnabled">This integration is currently disabled. Enable to begin receiving new orders.</p>
        </div>
      </div>
    </div>
    <!-- Navigate -->
    <div class="py-lg">
      <div class="columns is-centered">
        <div class="column">
          <b-button
            type="is-light rounded-lg"
            size="is-medium"
            @click="goBack"
            expanded
            ><b-icon icon="arrow-left"></b-icon>
          </b-button>
        </div>
        <div class="column">
          <b-button
            type="is-primary rounded-lg"
            size="is-medium"
            expanded
            :disabled="saving || !ready"
            @click="goForward"
          >Save &amp; Continue</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['app', 'context']),
    endpoint () {
      return this.context.route + '/platform-accounts/' + this.account.id + '/'
    },
    ready () {
      return (this.account)
    },
    credential () {
      if (this.account.credentials.length === 0) return null
      let found = null
      this.account.credentials.forEach(credential => {
        Object.keys(credential).forEach(key => {
          if (key === 'push_order_on_order_create') {
            found = credential[key].values[0]
          }
        })
      })
      return found
    },
    credentialIsEnabled () {
      if (this.credential) {
        return this.credential.value === '1'
      }
      return false
    },
    json () {
      const json = {
        credentials: [{
          credential: {
            code: 'push_order_on_order_create'
          },
          value: (this.credentialIsEnabled) ? 0 : 1
        }]
      }
      if (this.credential !== null) {
        json.credentials[0].id = this.credential.id
      }
      return json
    }
  },
  data () {
    return {
      saving: false,
      errors: {},
      sync: 'Yes'
    }
  },
  methods: {
    goBack () {
      this.$emit('navigate', 'Shipments')
    },
    goForward () {
      if (this.sync === 'Yes' && !this.credentialIsEnabled) {
        // if brand wants to receive push orders, enable push orders...
        this.save()
      } else if (this.sync === 'No' && this.credentialIsEnabled) {
        // if the brand wants to turn off push orders, disable push orders...
        this.save()
      } else {
        // in all other cases, don't do anything...
        this.$buefy.toast.open({ type: 'is-success', message: 'Settings saved!' })
        this.$emit('navigate', 'Confirmation')
      }
    },
    save () {
      this.saving = true
      this.$http.patch(this.endpoint, this.json).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Integration updated!' })
        this.$emit('platform:updated')
        this.$emit('navigate', 'Confirmation')
      }).catch(() => {
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  props: {
    connection: {
      type: Object,
      required: true
    },
    task: {
      type: Object,
      required: true
    },
    account: {
      type: Object
    }
  }
}
</script>
