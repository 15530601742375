<template>
  <div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Import Feed</p>
      </header>
      <section class="modal-card-body">
        <!-- Has Feed -->
        <div v-if="feed !== null">
          <div class="mb-lg">
            <Progress :feed="feed" :emitRefresh="true" @refresh="loadFeed(true)" />
          </div>
          <div class="mb-lg">
            <Results :feed="feed" />
          </div>
        </div>
        <!-- Upload -->
        <div v-else>
          <div class="mb-lg">
            <div class="columns">
              <div class="column">
                <p class="has-text-weight-bold">Template</p>
                <p>{{ proposal.department.template.name }}</p>
              </div>
              <div class="column">
                <p class="has-text-weight-bold">Behavior</p>
                <p>Update Products</p>
              </div>
            </div>
          </div>
          <div class="mb-lg">
            <CoreUpload @file:uploaded="updateTempfile" @file:reset="resetTempfile" :showLabel='true'/>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot space-between" >
        <b-button @click="$parent.close()">Close</b-button>
        <b-button
          v-if="feed"
          type="is-primary"
          :disabled="!ready || saving"
          :loading="saving"
          @click="closeAndRefresh"
        >Close &amp; Refresh</b-button>
        <b-button
          v-else
          type="is-primary"
          :disabled="!ready || saving"
          :loading="saving"
          @click="save"
        >Import File</b-button>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CoreUpload from '@/components/CoreUpload'
import Progress from '@/components/Models/FeedQueue/Presenters/Progress'
import Results from '@/components/Models/FeedQueue/Presenters/Results'

export default {
  components: {
    CoreUpload,
    Progress,
    Results
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/feed-queue/'
    },
    body () {
      if (this.tempfile === null) return {}
      const body = {
        template: {
          id: this.proposal.department.template.id
        },
        behavior: 'update',
        direction: 'import',
        data_type: 'products',
        source: 'console',
        info: {
          proposal_id: this.proposal.id,
          proposal_feed_purpose: 'update',
          proposal_tempfile_id: this.tempfile.id
        },
        file: {
          tempfile: {
            id: this.tempfile.id
          }
        }
      }
      return body
    },
    ready () {
      return this.tempfile !== null
    }
  },
  data () {
    return {
      saving: false,
      tempfile: null,
      loadingFeed: true,
      feed: null
    }
  },
  methods: {
    updateTempfile (file, response) {
      this.tempfile = response
      this.$emit('tempfile:created', response)
    },
    resetTempfile () {
      this.tempfile = null
    },
    save () {
      this.saving = true
      this.$http.post(this.endpoint + 'enqueue/', this.body).then(response => {
        this.feed = response.data
        this.$http.put(this.endpoint + this.feed.id + '/run/').then(response => {
          this.$buefy.toast.open({ type: 'is-success', message: 'Import scheduled!' })
        }).finally(() => {
          this.saving = false
        })
      })
    },
    closeAndRefresh () {
      this.$emit('feed:imported')
      this.$parent.close()
    },
    loadFeed (silent = false) {
      if (!silent) this.loadingFeed = true
      const endpoint = this.context.route + '/feed-queue/' + this.feed.id + '/'
      this.$http.get(endpoint).then(response => {
        this.feed = response.data
        this.loadingFeed = false
      })
    }
  },
  mounted () {

  },
  props: ['proposal']
}
</script>
