<template>
  <tr>
    <td class="has-text-weight-bold" style="width:33%">{{ attribute.attribute.name }}</td>
    <td class="has-text-right">
      <!-- Show booleans as Yes/No instead of 1/0 -->
      <template v-if="type === 'boolean'">
        <p v-if="value == '1'">Yes</p>
        <p v-else-if="value == '0'">No</p>
        <p v-else><span class='has-text-grey-light'>- Not Set -</span></p>
      </template>
      <!-- String Fields -->
      <template v-else>
        <template v-if="value">
          <!-- Show currency sign for money fields -->
          <template v-if="type == 'money'">
            <p :class="{ 'has-text-danger': hasError }">{{ $numbers(value).formatCurrency({ mantissa: 2 }) }} {{ (standardUnit) ? standardUnit : "" }}</p>
          </template>
          <!-- Open media links in a new window -->
          <template v-else-if="type == 'media'">
            <p :class="{ 'has-text-danger': hasError }"><a target="_blank" class="has-text-info has-text-weight-bold" :href="value">Link</a></p>
          </template>
          <!-- Otherwise, just show the value -->
          <template v-else>
            <p>{{ value }} {{ (standardUnit) ? standardUnit : "" }}</p>
          </template>
        </template>
        <template v-else>
          <p :class="(hasError) ? 'has-text-danger' : 'has-text-grey-light'">- Not Set -</p>
        </template>
      </template>
    </td>
    <!-- <td class="has-text-centered" style="width:16px">
      <b-icon
        :icon="(hasError) ? 'close' : 'check'"
        size="is-small"
        style="width:16px"
        :custom-class="(hasError) ? 'has-text-danger' : 'has-text-success'"
      />
    </td> -->
  </tr>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  data () {
    return {

    }
  },
  computed: {
    ...mapGetters(['context']),
    hasUnits () {
      return (this.attribute.attribute.available_units.length > 0)
    },
    standardUnit () {
      return (this.attribute.attribute && this.attribute.value.unit) ? this.attribute.value.unit.standard_unit : null
    },
    type () {
      return this.attribute.attribute.type
    },
    unit () {
      return (this.attribute.attribute && this.attribute.value.unit) ? this.attribute.value.unit.id : ''
    },
    value () {
      return this.attribute.value.value
    }
  },
  methods: {

  },
  mounted () {

  },
  props: {
    attribute: {
      type: Object
    }
  }
}
</script>
