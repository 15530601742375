<template>
  <form @submit.prevent>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Edit Invoices Numbers</p>
      </header>
      <section class="modal-card-body">
        <b-table
          :data="requests"
          :mobile-cards="false"
          hoverable
          class="is-size-7">
          <template>
            <!-- Label -->
            <b-table-column
              label="Invoice #"
              header-class="nowrap"
              cell-class="nowrap"
              width="128"
              v-slot="props">
              {{ props.row.label() }}
            </b-table-column>
            <!-- Status -->
            <b-table-column
              label="New Invoice #"
              header-class="nowrap"
              cell-class="nowrap"
              v-slot="props">
              <b-input v-model="props.row.newInvoiceNumber" size="is-medium" :disabled="props.row.status === 'Complete'" />
            </b-table-column>
            <!-- Progress -->
            <b-table-column
              label=""
              header-class="nowrap"
              cell-class="nowrap"
              width="96"
              v-slot="props">
              <b-progress v-if="props.row.status === 'Pending'" show-value></b-progress>
            </b-table-column>
          </template>
        </b-table>
      </section>
      <footer class="modal-card-foot space-between">
        <button class="button" type="button" @click="$parent.close()">Close</button>
        <div class="buttons is-marginless">
        <button class="button is-primary" type="button" :disabled="processing" @click="save()">Save</button>
        <button class="button is-primary" type="button" @click="reload()">Reload</button>
      </div>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import EditInvoice from '@/helpers/Requests/Invoice/EditInvoice'

export default {
  components: {

  },
  data () {
    return {
      processing: false,
      requests: [],
      todo: 0,
      skipped: 0,
      success: 0,
      failed: 0
    }
  },
  computed: {
    ...mapGetters(['context']),
    busy () {
      return this.todo !== this.skipped + this.success + this.failed
    }
  },
  methods: {
    reset () {
      this.todo = this.skipped = this.success = this.failed = 0
      this.processing = false
    },
    save () {
      this.reset()
      this.processing = true
      this.requests.forEach((request, index) => {
        this.todo++
        request.prepare()
        setTimeout(() => {
          request.run().then(response => {
            this.success++
          }).catch(() => {
            this.failed++
          })
        }, index * 1000)
      })
      this.processing = false
    },
    reload () {
      this.$emit('reload')
      this.$parent.close()
    }
  },
  mounted () {
    this.invoices.forEach(invoice => {
      this.requests.push(new EditInvoice({
        route: this.context.route,
        invoice: invoice,
        newInvoiceNumber: null
      }))
    })
  },
  props: ['invoices']
}
</script>
