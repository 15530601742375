<template>
  <form @submit.prevent="process" autocomplete="off" novalidate>
    <p class="has-text-centered is-size-4 mb-md">Please enter a new password.</p>
    <!-- Generic Error -->
    <b-message v-if="error" type="is-danger">
      {{ error }}
    </b-message>
    <!-- Fields -->
    <div class="mb-lg">
      <div class="mb-lg">
        <b-input v-model="email" type="text" native-type="email" size="is-medium" placeholder="Email address"
          :disabled="true"></b-input>
      </div>
      <div class="mb-lg" v-if="hint !== null">
        <p class=" has-text-centered" :class="hint.textClass">{{ hint.message }}</p>
      </div>
      <div class="mb-lg">
        <b-field label="New Password" expanded>
          <b-input autocomplete="new-password" type="password" name="newPassword" v-model="newPassword"
            password-reveal></b-input>
        </b-field>
        <div class="po-password-strength-wrapper">
          <password-meter :password="newPassword" @score="setScore" />
        </div>
      </div>
      <div class="mb-lg">
        <b-field label="Confirm Password" expanded>
          <b-input autocomplete="new-password" size="is-medium" v-model="confirmPassword" type="password"
            name="confirmPassword" password-reveal></b-input>
        </b-field>
      </div>
    </div>
    <!-- Buttons -->
    <div class="mb-lg">
      <b-button type="is-primary rounded-lg" size="is-medium" native-type="submit" :disabled="!validated || saving"
        :loading="saving" expanded>Reset Password</b-button>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import passwordMeter from 'vue-simple-password-meter'

export default {
  components: {
    passwordMeter
  },
  computed: {
    ...mapGetters(['api']),
    endpoint () {
      return this.api.baseUrl + '/' + this.api.version + '/users/reset-password/?source=platform'
    },
    validated () {
      return (
        this.code !== null &&
        this.code.length === 36 &&
        this.newPassword !== null &&
        this.newPassword !== '' &&
        this.newPassword === this.confirmPassword &&
        this.passwordScore >= 2
      )
    },
    hint () {
      if (this.newPassword !== null && this.newPassword !== '' && this.passwordScore >= 2) {
        return {
          textClass: 'has-text-success',
          message: 'Your new password meets our requirements.'
        }
      } else if (this.newPassword !== null && this.newPassword !== '' && this.passwordScore < 2) {
        return {
          textClass: 'has-text-warning',
          message: 'Please strengthen your password by adding more letters, numbers, and symbols or try increasing its complexity.'
        }
      } else {
        return {
          textClass: '',
          message: 'Your new password should be at least 8 characters and can include letters, numbers, and symbols.'
        }
      }
    },
    json () {
      return {
        email: this.email,
        password: this.newPassword,
        reset_code: this.code
      }
    }
  },
  data: function () {
    return {
      saving: false,
      email: null,
      code: null,
      newPassword: null,
      confirmPassword: null,
      passwordScore: 0,
      errors: {}
    }
  },
  methods: {
    setScore ({ score }) {
      this.passwordScore = score
    },
    process () {
      this.saving = true
      this.$http.put(this.endpoint, this.json).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Password reset! Please sign in.' })
        this.$router.push({ name: 'auth.login' })
      }).catch(error => {
        if (error.response.data && error.response.data.detail) {
          this.error = error.response.data.detail
        }
      }).finally(() => {
        this.saving = false
      })
    },
    reset () {
      this.newPassword = ''
      this.confirmPassword = ''
      this.error = null
    }
  },
  mounted () {
    if (this.$route.query.email !== null) {
      this.email = this.$route.query.email
    }
    if ('code' in this.$route.query && this.$route.query.code !== null) {
      this.code = this.$route.query.code
    }
  }
}
</script>
