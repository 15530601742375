<template>
<div>
  <form @submit.prevent="save">
    <div class="columns">
      <div class="column">
        <div class="has-background-white rounded-lg px-md py-sm mb-xxs">
          <div class="columns is-vcentered">
            <div class="column">
              <p class="has-text-grey-light is-size-7">
                <span v-if="!loading">{{ mappings.length }} {{ (mappings.length === 1) ? 'Mapping' : 'Mappings' }}</span>
                <span v-else>Loading...</span>
              </p>
            </div>
            <div class="column is-narrow">
              <b-button
                type="is-primary"
                size="is-small"
                outlined
                @click="showEditMappingModal = true"
              >Add Mapping</b-button>
            </div>
          </div>
        </div>
        <div class="has-background-white rounded-lg pa-md">
          <!-- Loaded -->
          <div>
            <b-table
              class="is-size-7"
              narrowed
              :data="mappings"
              :loading="loading"
              :mobile-cards="false">
              <template>
                <!-- Title -->
                <b-table-column
                  label="Column Header"
                  cell-class="nowrap"
                  v-slot="props">
                  <p><a href="#" @click.prevent="editMapping(props.row)" class="has-text-info has-text-weight-bold">{{ props.row.title}}</a></p>
                </b-table-column>
                <!-- Attribute -->
                <b-table-column
                  label="Attribute"
                  cell-class="nowrap"
                  v-slot="props"
                  width="200">
                  <p>{{ props.row.attribute.name }}</p>
                </b-table-column>
                <!-- Target -->
                <b-table-column
                  label="Target"
                  cell-class="nowrap"
                  v-slot="props"
                  centered
                  width="128">
                  <p>{{ (props.row.target === 'product') ? 'Product' : 'Item' }}</p>
                </b-table-column>
                <!-- Priority -->
                <b-table-column
                  label="Priority"
                  cell-class="nowrap"
                  v-slot="props"
                  centered
                  width="128">
                  <p>
                    <span v-if="props.row.priority">{{ props.row.priority }}</span>
                    <span v-else class="has-text-grey-light">Not Set</span>
                  </p>
                </b-table-column>
                <!-- Required -->
                <b-table-column
                  :visible="template && template.direction === 'import'"
                  label="Required?"
                  cell-class="nowrap"
                  v-slot="props"
                  centered
                  width="128">
                  <p>
                    <span v-if="props.row.is_required" class="has-text-warning">Yes</span>
                    <span v-else class="has-text-grey-light">No</span>
                  </p>
                  <p></p>
                </b-table-column>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>
  </form>
  <!-- Inspection Modal -->
  <b-modal
    has-modal-card
    :active.sync="showEditMappingModal"
    :can-cancel="['escape', 'x']"
    @close="mapping = null"
    ><EditTemplateMappingModal :template="template" :mapping="mapping" @mapping:saved="load" />
  </b-modal>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import EditTemplateMappingModal from '@/components/Models/Template/Modals/EditTemplateMapping'

export default {
  components: {
    EditTemplateMappingModal
  },
  computed: {
    ...mapGetters(['context']),
    ready () {
      return this.isDirty
    }
  },
  data () {
    return {
      loading: false,
      mappings: [],
      mapping: null,
      showEditMappingModal: false,
      isDirty: false,
      saving: false,
      errors: {}
    }
  },
  methods: {
    load () {
      this.loading = true
      const endpoint = this.context.route + '/templates/' + this.template.id + '/mappings/'
      const query = {
        params: {
          pagination: 0
        }
      }
      this.$http.get(endpoint, query).then(response => {
        this.mappings = response.data.results
        this.loading = false
      })
    },
    editMapping (mapping) {
      this.mapping = mapping
      this.showEditMappingModal = true
    }
  },
  mounted () {
    this.load()
  },
  props: ['template']
}
</script>
