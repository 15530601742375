import List from './views/List'
import Detail from './views/Detail'
import RuleGroupDetails from './views/RuleGroupDetails'

export default [{
  path: '/settings/rulesets',
  name: 'rulesets',
  component: List
}, {
  path: '/settings/rulesets/:id',
  name: 'ruleset',
  component: Detail
}, {
  path: '/settings/rulesets/:ruleset/rulegroup/:id',
  name: 'ruleset.rulegroup',
  component: RuleGroupDetails
}]
