import List from './views/List'
import Create from './views/Create'
import Details from './views/Details'

export default [
  {
    path: '/settings/commission-profiles',
    name: 'commission-profiles',
    component: List
  }, {
    path: '/settings/commission-profiles/create',
    name: 'commission-profiles.create',
    component: Create
  }, {
    path: '/settings/commission-profiles/:id',
    name: 'commission-profiles.edit',
    component: Details
  }
]
