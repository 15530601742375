<template>
  <div class="section">
    <div class="container">
      <!-- Page Header -->
      <div class="mb-lg">
        <div class="columns is-vcentered">
          <div class="column is-one-third">
            <breadcrumbs :history="[{ label: 'Orders', name: 'orders.dashboard'}]" current="Invoices" />
          </div>
          <div class="column is-one-third">
            <h1 class="has-text-centered is-size-4">Invoices</h1>
          </div>
        </div>
      </div>
      <!-- Filters Toolbar -->
      <div class="has-background-white rounded-lg pa-md mb-xxs">
        <div class="columns">
          <div class="column">
            <invoice-filters :applied-filters="filters" @filter="filter" />
          </div>
          <div class="column is-narrow">
            <b-select v-model="orderBy" size="is-medium" expanded @input="sort">
              <option value="-id">Most Recent</option>
              <option value="id">Oldest</option>
            </b-select>
          </div>
          <div class="column is-narrow">
            <b-button size="is-medium" class="is-fullwidth" icon-left="download" @click="showExportModal = !showExportModal">Export</b-button>
          </div>
        </div>
      </div>

      <!-- Actions Toolbar -->
      <div class="has-background-white rounded-lg px-md py-sm mb-xxs">
        <div class="columns is-vcentered is-mobile">
          <div class="column is-narrow">
            <b-dropdown :disabled="loading || selected.length === 0">
              <button class="button" slot="trigger" slot-scope="{ active }" :disabled="selected.length === 0">
                <span>Actions</span>
                <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
              </button>
              <template v-if="context.isSupplier()">
                <b-dropdown-item has-link><a href="#" @click.prevent="showEditInvoiceNumber = true">Edit Invoice Number</a></b-dropdown-item>
              </template>
              <template v-if="context.isMerchant()">
                <b-dropdown-item has-link><a href="#" @click.prevent="showAcceptInvoicesModal = true">Accept Invoices</a></b-dropdown-item>
                <b-dropdown-item has-link><a href="#" @click.prevent="showMarkPaidInvoicesModal = true">Mark Invoices as Paid</a></b-dropdown-item>
              </template>
            </b-dropdown>
          </div>
          <div class="column">
            <div class="has-text-right is-size-7">
              <!-- Counts -->
              <p v-if="loading">Loading...</p>
              <p v-else>{{ total }} invoices found.</p>
            </div>
          </div>
        </div>
      </div>

      <!-- Results -->
      <div>
        <div class="mb-md">
          <b-table
            :data="results"
            :checked-rows.sync="selected"
            :mobile-cards="false"
            :loading="loading"
            hoverable
            checkable
            checkbox-position="left"
            class="is-size-7">
            <template>
              <!-- Invoice # -->
              <b-table-column
                label="Invoice #"
                header-class="nowrap"
                cell-class="nowrap"
                width="128"
                v-slot="props">
                <a href="#"
                  class="has-text-weight-bold has-text-info"
                  @click.prevent="inspectInvoice = props.row"
                >{{ props.row.invoice_number }}</a>
              </b-table-column>
              <!-- PO # -->
              <b-table-column
                label="PO #"
                header-class="nowrap"
                cell-class="nowrap"
                width="128"
                v-slot="props">
                <a href="#"
                  class="has-text-weight-bold has-text-info"
                  @click.prevent="showOrder(props.row)"
                >{{ props.row.purchase_order_number }}</a>
              </b-table-column>
              <!-- Brand -->
              <b-table-column
                v-if="context.isMerchant()"
                label="Supplier"
                header-class="nowrap"
                cell-class="nowrap"
                v-slot="props">
                 {{ props.row.brand.name }}
              </b-table-column>
              <!-- Merchant -->
              <b-table-column
                v-if="context.isSupplier()"
                label="Merchant"
                header-class="nowrap"
                cell-class="nowrap"
                v-slot="props">
                {{ props.row.retailer.name }}
              </b-table-column>
              <!-- Submitted -->
              <b-table-column
                label="Submitted"
                header-class="nowrap"
                cell-class="nowrap"
                centered
                width="100"
                v-slot="props">
                <span v-if="props.row.created_at === null">--</span>
                <span v-else>{{ $dates.utc(props.row.invoiced_at).local().format('MMM D, YYYY') }}</span>
              </b-table-column>
              <!-- Due -->
              <b-table-column
                v-if="context.isMerchant()"
                label="Due"
                header-class="nowrap"
                cell-class="nowrap"
                centered
                width="100"
                v-slot="props">
                <span v-if="props.row.terms_date_due === null">--</span>
                <span v-else>{{ $dates.utc(props.row.terms_date_due).local().format('MMM D, YYYY') }}</span>
              </b-table-column>
              <!-- Acknowledged -->
              <b-table-column
                label="Acknowledged"
                header-class="nowrap"
                cell-class="nowrap"
                centered
                width="100"
                v-slot="props">
                <span v-if="props.row.acknowledged_at === null">--</span>
                <span v-else>{{ $dates.utc(props.row.acknowledged_at).local().format('MMM D, YYYY') }}</span>
              </b-table-column>
              <!-- Subtodal -->
              <b-table-column
                label="Subtotal"
                header-class="nowrap"
                cell-class="nowrap"
                centered
                width="128"
                v-slot="props">
                <CurrencyLocale :value="parseFloat(props.row.subtotal)" :currency="props.row.currency"/>
              </b-table-column>
              <!-- Adjustments -->
              <b-table-column
                label="Adjustments"
                header-class="nowrap"
                cell-class="nowrap"
                centered
                width="100"
                v-slot="props">
                <CurrencyLocale :value="(parseFloat(props.row.due_amount) - parseFloat(props.row.subtotal))" :currency="props.row.currency"/>
              </b-table-column>
              <!-- Due Amount -->
              <b-table-column
                label="Total"
                header-class="nowrap"
                cell-class="nowrap"
                centered
                width="100"
                v-slot="props">
                <CurrencyLocale :value="parseFloat(props.row.due_amount)" :currency="props.row.currency"/>
              </b-table-column>
              <!-- Paid -->
              <b-table-column
                v-if="context.isMerchant()"
                label="Paid"
                header-class="nowrap"
                cell-class="nowrap"
                centered
                width="100"
                v-slot="props">
                <span v-if="props.row.paid_at !== null" class="has-text-success has-text-weight-bold">Yes</span>
                <span v-else class="has-text-grey-light">--</span>
              </b-table-column>
            </template>
            <template #empty>
              <div class="py-xxl has-text-grey has-text-centered">
                {{ (loading) ? "Loading data..." : 'No invoices found!' }}
              </div>
            </template>
          </b-table>
        </div>
        <b-pagination
          :total="total"
          :current.sync="currentPage"
          :rounded="true"
          :per-page="perPage"
          @change="paginate">
        </b-pagination>
      </div>

      <!-- Inspect Invoice -->
      <b-modal
        v-if="(inspectInvoice !== null)"
        has-modal-card
        :active="(inspectInvoice !== null)"
        :can-cancel="['escape', 'x']"
        @close="closeModals"
        ><InvoiceModal :invoice="inspectInvoice" />
      </b-modal>
      <!-- Inspect Order -->
      <b-modal
        v-if="(inspectOrder !== null)"
        has-modal-card
        scroll="keep"
        :active="(inspectOrder !== null)"
        :can-cancel="['x']"
        @close="closeModals"
        ><OrderModal :selectedOrder="inspectOrder" />
      </b-modal>
      <!-- Bulk: Accept -->
      <b-modal
        v-if="context.isMerchant()"
        has-modal-card
        scroll="keep"
        :active.sync="showAcceptInvoicesModal"
        :can-cancel="['x']"
        ><AcceptInvoicesModal :invoices="selected" @reload="reload" />
      </b-modal>
      <!-- Bulk: Mark as Paid -->
      <b-modal
        v-if="context.isMerchant()"
        has-modal-card
        scroll="keep"
        :active.sync="showMarkPaidInvoicesModal"
        :can-cancel="['x']"
        ><MarkInvoicesPaidModal :invoices="selected" @reload="reload" />
      </b-modal>

      <!-- Bulk: Edit Invoice Number -->
      <b-modal
        v-if="context.isSupplier()"
        has-modal-card
        scroll="keep"
        :active.sync="showEditInvoiceNumber"
        :can-cancel="['x']"
        ><EditInvoiceNumber :invoices="selected" @reload="reload" />
      </b-modal>
      <!-- Modal: Export  -->
      <b-modal
        has-modal-card
        scroll="keep"
        :active.sync="showExportModal"
        :can-cancel="['x']"
        ><div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Export Orders</p>
          </header>
          <section class="modal-card-body">
            <p class="mb-md">
              Click 'Export' below to have an invoices export delivered to your
              email. Please note that the export will only include
              the items that match your applied filters. Please double check
              your filters before exporting.
            </p>
            <!-- Export Settings -->
            <div>
              <p class="mb-md"><label class="label">Export Settings</label></p>
              <b-field>
                <b-radio v-model="exportInvoiceLines" :native-value="0">Export Invoices Only</b-radio>
              </b-field>
              <b-field>
                <b-radio v-model="exportInvoiceLines" :native-value="1">Export Invoices with Invoices Lines</b-radio>
              </b-field>
              <p v-if="exportInvoiceLines" class="border has-background-light pa-md is-size-7">
                <span class="has-text-danger has-text-weight-bold">Please Note</span>
                Your invoice line export may contain monetary columns related to the invoice (e.g. subtotals, adjustments, etc)
                as well as the invoice lines.
                If you summarize invoice-level columns, it may lead to discrepancy.
              </p>
            </div>
          </section>
          <footer class="modal-card-foot" style="justify-content:flex-end">
            <b-button type="is-primary" @click="scheduleExport" :loading="exporting" :disabled="exporting">Export</b-button>
          </footer>
        </div>
      </b-modal>

    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import presets from '../presets/invoices'
import Filterable from '@/mixins/Filterable'
import Exportable from '@/mixins/Exportable'
import InvoiceModal from '@/components/Modals/Invoice/InvoiceModal'
import InvoiceFilters from '../components/Invoices/InvoiceFilters'
import OrderModal from '@/components/Modals/Order/OrderModal'
import AcceptInvoicesModal from '../components/Invoices/AcceptInvoicesModal'
import MarkInvoicesPaidModal from '../components/Invoices/MarkInvoicesPaidModal'
import EditInvoiceNumber from '@/components/Modals/Invoice/EditInvoiceNumber'

export default {
  components: {
    AcceptInvoicesModal,
    InvoiceFilters,
    InvoiceModal,
    OrderModal,
    MarkInvoicesPaidModal,
    EditInvoiceNumber
  },
  data () {
    return {
      key: 'invoices',
      presets: presets,
      exportInvoiceLines: 0,
      // modals
      showAcceptInvoicesModal: false,
      showMarkPaidInvoicesModal: false,
      showEditInvoiceNumber: false,
      inspectInvoice: null,
      inspectOrder: null
    }
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/invoices/'
    },
    exportEndpoint () {
      return (this.exportInvoiceLines)
        ? this.endpoint + 'export-email/?invoice_fields=0'
        : this.endpoint + 'export-email/?invoice_fields=1'
    },
    fields () {
      const base = [
        'id',
        'invoice_number',
        'purchase_order_number',
        'order_id',
        'brand',
        'retailer',
        'acknowledged_at',
        'created_at',
        'subtotal',
        'due_amount',
        'invoice_lines',
        'charges',
        'allowances',
        'remit_to'
      ]
      return (this.context.isSupplier())
        ? base.concat(['retailer']).join(',')
        : base.concat(['brands']).join(',')
    },
    alwaysAttach () {
      return {
        skip_attributes: 1
      }
    }
  },
  methods: {
    closeModals () {
      this.inspectInvoice = null
      this.inspectOrder = null
    },
    showOrder (invoice) {
      this.inspectOrder = {
        id: invoice.order_id,
        purchase_order_number: invoice.purchase_order_number
      }
    },
    reload () {
      this.selected = []
      this.load()
    }
  },
  mounted () {
    this.load()
  },
  mixins: [Filterable, Exportable]
}
</script>
