<template>
<div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Add Invoices</p>
    </header>
    <section class="modal-card-body">
      <div class="py-lg has-text-centered">
        <p v-if="isAddMatchingInvoices">
          Are you sure you want to add the <b> {{total}} </b> matching invoices to the payment #{{payment.id}}?
        </p>
        <p v-else>
          Are you sure you want to add the <b>{{selectedInvoices.length}}</b> selected invoices to the payment #{{payment.id}}?
        </p>
      </div>
    </section>
    <footer class="modal-card-foot space-between">
      <b-button @click="$parent.close()">Cancel</b-button>
      <b-button
        :disabled="saving"
        :loading="saving"
        type="is-primary"
        @click="save"
      >Add Invoices</b-button>
    </footer>
  </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['context'])
  },
  data () {
    return {
      saving: false
    }
  },
  methods: {
    save () {
      this.saving = true
      if (this.isAddMatchingInvoices) {
        this.addFilteredInvoices()
      } else {
        this.addSelectedInvoices()
      }
    },
    addSelectedInvoices () {
      const json = {
        invoice_ids: this.selectedInvoices.map(el => {
          return el.id
        })
      }
      const endpoint = this.context.route + '/payments/' + this.$route.params.id + '/add-invoices/'
      this.$http.put(endpoint, json).then(response => {
        if (response.data.success_invoices.length) {
          this.$emit('reload:payment')
          this.$router.push({ name: 'payment.detail.invoices', params: { id: this.payment.id } })
        } else if (response.data.failure_invoices.length) {
          this.$buefy.toast.open({ type: 'is-danger', message: 'There was a problem adding invoices to the payment. Please try again or contact support.' })
        }
      }).finally(() => {
        this.saving = false
      })
    },
    addFilteredInvoices () {
      const query = {}
      for (const key in this.appliedFilters.timestamps) {
        const timestamp = this.appliedFilters.timestamps[key]
        if (timestamp) {
          if (typeof timestamp[0] !== 'undefined' && timestamp[0] !== null) {
            query[key + '_gte'] = timestamp[0]
          }
          if (typeof timestamp[1] !== 'undefined' && timestamp[1] !== null) {
            query[key + '_lte'] = timestamp[1]
          }
        }
      }
      const queryParams = []
      Object.keys(query).forEach(key => {
        queryParams.push(`${key}=${encodeURIComponent(query[key])}`)
      })
      const url = this.context.route + '/payments/' + this.$route.params.id + '/add-bulk-invoices/'
      const endpoint = `${url}?${queryParams.join('&')}`
      this.$http.put(endpoint).then(response => {
        this.clearFilter()
        this.$emit('reload:payment')
        this.$router.push({ name: 'payment.detail.invoices', params: { id: this.payment.id } })
      }).finally(() => {
        this.saving = false
      })
    },
    clearFilter () {
      const filters = {
        preset: 'all',
        facets: {},
        search: {},
        timestamps: {}
      }
      this.$emit('filter', filters)
    }
  },
  mounted () {
  },
  props: ['payment', 'selectedInvoices', 'appliedFilters', 'isAddMatchingInvoices', 'total']
}
</script>
