<template>
  <div class="section">
    <div class="container">

      <!-- Page Header -->
      <div class="columns is-mobile is-vcentered">
        <div class="column">
          <h4 class="is-size-4 mb-lg">{{ app.platform }} Network Profile</h4>
        </div>
        <div class="column is-narrow">
          <router-link :to="{ name: 'network.dashboard'}" exact>&lt; Back to Network</router-link>
        </div>
      </div>

      <!-- Content -->
      <div>
        <!-- Visibility -->
        <div class="border-b pb-lg mb-lg">
          <profile-visibility
            :marketplace="marketplace"
            :loading="loadingMarketplaces"
            @marketplace:updated="refreshMarketplace"
          />
        </div>
        <!-- Mission -->
        <div class="border-b pb-lg mb-lg">
          <profile-mission
            :loading="loadingAccounts || loadingMarketplaces"
            :marketplace="marketplace"
            @marketplace:updated="refreshMarketplace"
          />
        </div>
        <!-- About -->
        <div class="border-b pb-lg mb-lg">
          <profile-about
            :loading="loadingAccounts || loadingMarketplaces"
            :marketplace="marketplace"
          />
        </div>
        <!-- Connnect Instagram -->
        <div class="border-b pb-lg mb-lg">
          <connect-instagram
            :account="instagramPlatformAccount"
            :loading="loadingAccounts"
            :install-url="installUrl"
            @instagram:connected="loadAccounts"
          />
        </div>
        <!-- Cover Photo -->
        <div class="border-b pb-lg mb-lg">
          <cover-photo
            :account="instagramPlatformAccount"
            :loading="loadingAccounts || loadingMarketplaces"
            :marketplace="marketplace"
            :install-url="installUrl"
          />
        </div>
        <!-- Profile Photo -->
        <div class="pb-lg mb-lg">
          <profile-photo
            :account="instagramPlatformAccount"
            :loading="loadingAccounts || loadingMarketplaces"
            :marketplace="marketplace"
            :install-url="installUrl"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ConnectInstagram from '../components/Profile/ConnectInstagram'
import CoverPhoto from '../components/Profile/CoverPhoto'
import ProfilePhoto from '../components/Profile/ProfilePhoto'
import ProfileAbout from '../components/Profile/About'
import ProfileMission from '../components/Profile/Mission'
import ProfileVisibility from '../components/Profile/Visibility'

export default {
  components: {
    ConnectInstagram,
    CoverPhoto,
    ProfileAbout,
    ProfilePhoto,
    ProfileMission,
    ProfileVisibility
  },
  computed: {
    ...mapGetters(['app', 'context', 'services']),
    marketplacesEndpoint () {
      return this.context.route + '/marketplaces/?mine=1'
    },
    platformAccountsEndpoint () {
      return this.context.route + '/platform-accounts/?platform=instagram'
    },
    instagramPlatformAccount () {
      if (this.accounts.length === 0) return null
      return this.$utils.find(this.accounts, (o) => {
        return o.platform.code === 'instagram'
      })
    },
    installUrl () {
      return this.services.instagram.oauthUrl +
        '?client_id=' + this.services.instagram.clientId +
        '&redirect_uri=' + this.services.instagram.redirectUrl +
        '&scope=user_profile,user_media&response_type=code'
    }
  },
  data () {
    return {
      loadingMarketplaces: true,
      loadingAccounts: true,
      marketplace: null,
      accounts: [],
      visibility: 'public'
    }
  },
  methods: {
    loadMarketplace () {
      this.loadingMarketplaces = true
      this.$http.get(this.marketplacesEndpoint).then(response => {
        if (response.data.results.length === 1) {
          this.marketplace = response.data.results[0]
        }
        this.loadingMarketplaces = false
      })
    },
    loadAccounts () {
      this.loadingAccounts = true
      this.$http.get(this.platformAccountsEndpoint).then(response => {
        this.accounts = response.data.results
        this.loadingAccounts = false
      })
    },
    updateVisibility (visibility) {
      this.$set(this.marketplace, 'visibility', visibility)
    },
    refreshMarketplace () {
      this.loadMarketplace()
    }
  },
  mounted () {
    // always load marketplaces
    this.loadMarketplace()

    // if we have a code on the query string, that means we're coming back
    // in from instagram, so let the child component handle that
    if ('code' in this.$route.query === false) this.loadAccounts()
  }
}
</script>
