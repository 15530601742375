<template>
  <div class="section">
    <div class="container">
      <!-- Page Header -->
      <div class="mb-md">
        <div class="columns is-vcentered">
          <div class="column is-one-third">
            <breadcrumbs :history="[{ label: 'Settings', name: 'settings'}]" current="Subscriptions" />
          </div>
          <div class="column is-one-third">
            <h1 class="has-text-centered is-size-4">Subscriptions</h1>
          </div>
        </div>
      </div>
      <div>
        <div v-if="loading" class="has-background-white pa-lg rounded-lg mb-xs">
          <div class="placeholder placeholder-title" style="width:66%"></div>
          <div class="placeholder placeholder-sentence" style="width:50%"></div>
        </div>
        <div v-else-if="hasPermission('View Services', 'brand')" class="border-b pb-lg mb-lg">
          <Services :subscriptions="subscriptions"></Services>
        </div>
        <div class="border-b pb-lg mb-lg">

        </div>
        <div class="pb-lg mb-lg">

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Services from '../components/Subscriptions/Services'
// import BusinessNotifications from '../components/Notifications/BusinessNotifications'

export default {
  components: {
    Services
    // BusinessNotifications
  },
  computed: {
    ...mapGetters(['context', 'auth']),
    endpoint () {
      return `${this.context.route}/subscriptions/?is_failed=0&`
    }
  },
  data () {
    return {
      loading: false,
      subscriptions: []
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint).then(response => {
        this.subscriptions = response.data.results
      }).finally(() => {
        this.loading = false
      })
    },
    hasPermission (name, contentType) {
      const res = this.auth.user.permissions.find(permission => permission.name === name && permission.contentType === contentType) !== undefined
      return res
    }
  },
  mounted () {
    if (this.hasPermission('View Subscriptions', 'brand')) {
      this.load()
    }
  }
}
</script>
