<template>
  <div>
    <div class="columns">
      <div class="column is-one-fifth">
        <p class="has-text-weight-bold is-size-6 mb-sm">Tracking Number Reset</p>
        <p class="has-text-grey is-size-7">Re-push tracking numbers for orders to Shopify.</p>
      </div>
      <div class="column">
        <div class="has-background-white rounded-lg">
          <div class="pa-lg">
            <!-- Intro Text (Merchant) -->
            <div class="is-size-7 mb-md">
              <p class="">
                When provided with a list of {{ app.product }} comma-separated order IDs, tracking numbers will be updated in Shopify.
              </p>
            </div>
            <div class="columns">
              <div class="column">
                <b-field>
                  <b-input
                  type="textarea"
                  placeholder="Order IDs..."
                  v-model="orderIds"
                ></b-input>
                </b-field>
              </div>
            </div>
          </div>
          <div class="border-t">
            <div class="columns is-vcentered is-marginless">
              <div class="column is-hidden-mobile">
                <div class="px-sm has-text-grey-light">
                </div>
              </div>
              <div class="column is-narrow">
                <div class="px-sm">
                  <b-button
                    native-type="submit"
                    type="is-primary rounded-lg"
                    :disabled="saving"
                    @click="prepareAndSave"
                  >Update Tracking</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['app', 'context']),
    endpoint () {
      return this.context.route + '/shopify/' + this.account.id + '/bulk-update-tracking/'
    }
  },
  data () {
    return {
      saving: false,
      orderIds: ''
    }
  },
  methods: {
    prepareAndSave () {
      if (this.orderIds) {
        const idsArray = this.orderIds.split(',').map(Number)
        const json = {
          order_ids: idsArray
        }
        this.save(json)
      }
    },
    save (json) {
      this.saving = true
      this.$http.post(this.endpoint, json).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Tracking Numbers Updated!' })
        this.$emit('update:account')
      }).finally(() => {
        this.saving = false
      })
    }
  },
  props: ['account']
}
</script>
