<template>
  <div>
    <div class="is-size-7">
      <p class="is-uppercase has-text-weight-bold mb-sm is-size-7">Description</p>
      <div v-if="getDescription !== null" class="has-text-grey">
        <p class="is-italic mb-sm">Description provided by supplier...</p>
        <div>
          {{ getDescription }}
        </div>
      </div>
      <div class="has-text-grey is-italic" v-else>No description provided by supplier.</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  data () {
    return {

    }
  },
  computed: {
    ...mapGetters(['context']),
    getDescription () {
      let description = null
      if (this.product.description) {
        description = this.product.description
      } else if (this.product.attributes.description && this.product.attributes.description.values.length > 0) {
        description = this.product.attributes.description.values[0].value
      }
      return description
    }
  },
  methods: {

  },
  mounted () {

  },
  props: ['product']
}
</script>
