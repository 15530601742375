<template>
  <div>
    <div class="columns">
      <div class="column is-one-fifth">
        <p class="has-text-weight-bold is-size-6 mb-sm">EDI Account</p>
        <p class="has-text-grey is-size-7">Manage the details of your EDI account.</p>
      </div>
      <div class="column">
        <div class="has-background-white rounded-lg">
          <div class="pa-md">
            <!-- FTP Account -->
            <div v-if="accountFTP !== null">
              <p class="has-text-weight-bold mb-md">FTP Account</p>
              <div class="has-background-light pa-md rounded-lg mb-xl">
                <div class="columns is-mobile is-marginless">
                  <div class="column is-narrow">
                    <p class="has-text-weight-bold">Username</p>
                  </div>
                  <div class="column">
                    {{ accountFTP.username }}
                  </div>
                </div>
                <div class="columns is-mobile is-marginless">
                  <div class="column is-narrow">
                    <p class="has-text-weight-bold">Password</p>
                  </div>
                  <div class="column">
                    {{ accountFTP.password }}
                  </div>
                </div>
              </div>
            </div>
            <!-- EDI Account -->
            <div v-if="accountEDI !== null">
              <p class="has-text-weight-bold mb-md">EDI Account</p>
              <div class="has-background-light pa-md rounded-lg mb-xl">
                <div class="columns is-mobile is-marginless">
                  <div class="column is-narrow">
                    <p class="has-text-weight-bold">Identifier</p>
                  </div>
                  <div class="column">
                    {{ accountEDI.identifier }}
                  </div>
                </div>
                <div class="columns is-mobile is-marginless">
                  <div class="column is-narrow">
                    <p class="has-text-weight-bold">Qualifier</p>
                  </div>
                  <div class="column">
                    {{ accountEDI.qualifier }}
                  </div>
                </div>
                <div class="columns is-mobile is-marginless">
                  <div class="column is-narrow">
                    <p class="has-text-weight-bold">Status</p>
                  </div>
                  <div class="column">
                    {{ accountEDI.status }}
                  </div>
                </div>
              </div>
            </div>
            <!-- Not Found -->
            <div v-else-if="!loading && accountEDI === null">
              <div class="py-xxl has-text-centered">
                <p>No EDI accounts found. <br/> Click 'Add EDI Account' to provision a new EDI account.</p>
              </div>
            </div>
            <!-- Loading -->
            <div v-if="loading">
              <div class="pa-md" v-for="index in 1" :key="index">
                <div class="placeholder placeholder-title"></div>
                <div class="placeholder placeholder-sentence" style="width:50%"></div>
              </div>
            </div>
          </div>
          <!-- Add EDI Account Button-->
          <div v-if="!loading && accountEDI === null" class="border-t">
            <div class="columns is-vcentered is-marginless">
              <div class="column has-text-right">
                <div class="px-sm">
                  <b-button
                    native-type="submit"
                    type="is-primary rounded-lg"
                    :loading="loading"
                    @click="showAddModal = true">
                  Add EDI Account
                </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Add EDI Account Modal -->
    <b-modal
      has-modal-card
      :active.sync="showAddModal"
      :can-cancel="['escape', 'x']">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Create EDI</p>
        </header>
        <section class="modal-card-body">
          <div class="has-background-white rounded-lg">
            <b-message v-if="error" type="is-danger">
              {{ error }}
            </b-message>
            <b-field
              label="Qualifier"
              expanded>
              <b-input
                type="text"
                size="is-medium"
                v-model="qualifier"/>
            </b-field>
            <b-field
              label="Identifier"
              expanded>
              <b-input
                type="text"
                size="is-medium"
                v-model="identifier"/>
            </b-field>
            <b-field label="Connection">
              <ConnectionSelect v-model="connection" size="is-medium" />
            </b-field>
          </div>
        </section>
        <footer class="modal-card-foot space-between" >
          <b-button @click="resetModal">Cancel</b-button>
          <b-button type="is-primary"
                    @click="add"
                    :loading="saving"
                    :disabled="!ready">
            Save
          </b-button>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ConnectionSelect from '@/components/Models/Connection/ConnectionSelect'
export default {
  components: {
    ConnectionSelect
  },
  data () {
    return {
      loading: true,
      accountEDI: null,
      accountFTP: null,
      showEditModal: false,
      identifier: null,
      qualifier: null,
      connection: null,
      saving: false,
      showAddModal: false,
      error: null
    }
  },
  computed: {
    ...mapGetters(['context']),
    ready () {
      return Boolean(
        this.identifier !== null &&
        this.qualifier !== null &&
        this.connection !== null
      )
    }
  },
  methods: {
    resetModal () {
      this.error = null
      this.saving = null
      this.showAddModal = false
      this.identifier = null
      this.qualifier = null
      this.connection = null
    },
    async getAccountFTP () {
      const endpoint = `${this.context.route}/ftp-accounts/`
      await this.$http.get(endpoint).then(response => {
        this.accountFTP = response.data.results[0] || null
      })
    },
    async getAccountEDI () {
      const endpoint = `${this.context.route}/edi-accounts/`
      await this.$http.get(endpoint).then(response => {
        this.accountEDI = response.data.results[0] || null
      })
    },
    async load () {
      this.loading = true
      try {
        await this.getAccountFTP()
        await this.getAccountEDI()
      } catch (error) {
        this.$buefy.toast.open({ type: 'is-danger', message: 'Error loading!' })
      } finally {
        this.loading = false
      }
    },
    add () {
      this.error = null
      this.saving = true
      const endpoint = `${this.context.route}/edi-accounts/onboarding/`
      this.$http.post(endpoint, {
        identifier: this.identifier,
        qualifier: this.qualifier,
        connection: {
          id: this.connection.id
        }
      }).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'EDI account provisioned' })
        this.resetModal()
      }).catch(error => {
        if (error.response && error.response.status === 400) {
          this.error = `${error.response.data.detail} Please contact support`
        }
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    this.load()
  },
  props: []
}
</script>
