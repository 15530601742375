<template>
  <div>
    <div class="columns">
      <div class="column is-one-fifth">
        <p class="has-text-weight-bold is-size-6 mb-sm">Returns</p>
        <p class="has-text-grey is-size-7">Configure how your Returnly account syncs returns with {{ app.platform }}.</p>
      </div>
      <div class="column">
        <div class="has-background-white rounded-lg">
          <div class="pa-lg">
            <!-- Intro Text (Merchant) -->
            <div class="is-size-7 mb-md">
              <p class="">
                {{ app.platform }} can automatically pull returns from your Returnly account.
                When you get a return request on Returnly, it will be automatically synced to {{ app.platform }}.
              </p>
            </div>
            <div v-if="!loading">
              <!-- Webhook Exists -->
              <div class="has-background-white-ter pa-md rounded-lg">
                <div class="columns is-vcentered is-mobile">
                  <div class="column is-narrow">
                    <b-icon v-if="webhook !== null" icon="check" custom-class="has-text-success" />
                    <b-icon v-else icon="close" custom-class="has-text-warning" />
                  </div>
                  <div class="column">
                    <p class="has-text-weight-bold">Pull Returns from Returnly</p>
                    <p class="has-text-grey-light is-size-8">
                      <span v-if="webhook !== null">This integration is currently enabled.</span>
                      <span v-else>This integration is currently disabled.</span>
                    </p>
                  </div>
                  <div class="column is-narrow">
                    <p class="has-text-weight-bold">
                      <span v-if="webhook !== null" class="has-text-success">Enabled</span>
                      <span v-else class="has-text-warning">Disabled</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="has-background-white-ter rounded-lg">
              <p class="has-text-grey-light is-size-8 pa-md">Loading...</p>
            </div>
          </div>
          <div class="border-t">
            <div class="columns is-vcentered is-marginless">
              <div class="column">
                <div class="px-sm has-text-grey-light">
                  <em v-if="webhook !== null">Click "Disable Integration" to unsubscribe from the current webhook.</em>
                  <em v-else>Click "Enable Integration" to begin syncing new returns.</em>
                </div>
              </div>
              <div class="column is-narrow">
                <div class="px-sm">
                  <!-- Remove Webhook -->
                  <b-button
                    v-if="webhook !== null"
                    type="is-danger rounded-lg"
                    outlined
                    :disabled="loading || saving"
                    :loading="saving"
                    @click="disable"
                  >Disable Integration</b-button>
                  <!-- Subscribe to new Webhook -->
                  <b-button
                    v-if="webhook === null"
                    type="is-primary rounded-lg"
                    :disabled="loading || saving || topic === null"
                    @click="enable"
                    :loading="saving"
                  >Enable Integration</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['app', 'context']),
    endpoint () {
      return this.context.route + '/returnly/' + this.account.id + '/webhooks/'
    },
    json () {
      return {
        webhook_topic: this.topic
      }
    }
  },
  data () {
    return {
      loading: true,
      saving: false,
      errors: {},
      topic: 'return.requested',
      webhook: null
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint).then(response => {
        // check for return.requested webhook.
        const returnRequested = this.$utils.find(response.data.results, ['topic', this.topic])
        if (returnRequested) {
          this.webhook = returnRequested
        }
      }).finally(() => {
        this.loading = false
      })
    },
    enable () {
      const endpoint = this.context.route + '/returnly/' + this.account.id + '/register-webhook/'
      this.saving = true
      this.$http.put(endpoint, this.json).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Sync enabled!' })
      }).catch(error => {
        let errorMessage = 'An unexpected error occured!'
        if (error.response && error.response.data.detail) {
          errorMessage = error.response.data.detail
        }
        this.$buefy.toast.open({ type: 'is-danger', message: errorMessage })
      }).finally(() => {
        this.saving = false
        this.load()
      })
    },
    disable () {
      this.saving = true
      this.$http.delete(this.endpoint + this.webhook.id + '/').then(() => {
        this.webhook = null
        this.$buefy.toast.open({ type: 'is-success', message: 'Sync disabled!' })
      }).finally(() => {
        this.saving = false
        this.load()
      })
    }
  },
  mounted () {
    this.load()
  },
  props: ['account']
}
</script>
