<template>
  <!-- Connection Attachments List -->
  <div class="has-background-white pa-md rounded-lg">
    <b-table :data="connection.retailer.assets" :mobile-cards="false" :loading="loading" class="rounded-lg is-size-7">
      <template>
        <b-table-column label="ID" header-class="nowrap" cell-class="nowrap has-text-grey-light" v-slot="props"
          width="100">
          <p>{{ props.row.id }}</p>
        </b-table-column>
        <b-table-column label="File Name" header-class="nowrap" cell-class="nowrap" style="cursor: pointer"
          v-slot="props">
          <a :href="props.row.source_url" class="has-text-info has-text-weight-bold" target="_blank">
            <p>{{ props.row.name || props.row.filename }}</p>
          </a>
        </b-table-column>
        <b-table-column label="Actions" header-class="nowrap" cell-class="nowrap has-text-weight-bold" centered
          width="128" v-slot="props">
          <b-button type="is-primary" size="is-small" @click="downloadFile(props.row.file)">Download</b-button>
        </b-table-column>
      </template>
      <template #empty>
        <div class="py-xxl has-text-grey has-text-centered">
          {{ (loading) ? "Loading data..." : "No resources found!" }}
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['context'])
  },
  methods: {
    async downloadFile (fileUrl) {
      const response = await fetch(fileUrl)
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      const blob = await response.blob()
      const link = document.createElement('a')
      const url = URL.createObjectURL(blob)

      link.href = url
      link.download = fileUrl.split('/').pop()
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)

      URL.revokeObjectURL(url)
    }
  },
  props: ['connection', 'loading']
}
</script>
