<template>
  <div>
    <FilterToolbar
      :hasChanges="hasChanges"
      :appliedFilters="appliedFilters"
      @search="search"
      @update="update"
      @clear="clear">
    <FacetFilter
      v-model="filters.facets.method"
      :filter="filterOptions.profileMethods"
      @input="change"
    />
    </FilterToolbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import * as filterOptions from '../../filters/profiles'
import FilterToolbar from '@/components/Filters/FilterToolbar'
import FacetFilter from '@/components/Filters/FacetFilter'

export default {
  components: {
    FilterToolbar,
    FacetFilter
  },
  data () {
    return {
      hasChanges: false,
      filters: null,
      filterOptions: filterOptions
    }
  },
  computed: {
    ...mapGetters(['context'])
  },
  methods: {
    change () {
      this.hasChanges = true
    },
    search (searchQuery) {
      this.filters.search = {
        parameter: 'name',
        query: searchQuery
      }
      this.$emit('filter', this.filters)
    },
    update () {
      this.hasChanges = false
      this.filters.preset = 'custom'
      this.$emit('filter', this.filters)
    },
    clear () {
      this.filters = {
        preset: 'all',
        facets: {},
        search: {},
        timestamps: {}
      }
      this.$emit('filter', this.filters)
    }
  },
  mounted () {

  },
  props: {
    appliedFilters: {
      type: Object,
      required: true
    }
  },
  watch: {
    appliedFilters: {
      deep: true,
      immediate: true,
      handler (filters) {
        this.filters = filters
      }
    }
  }
}
</script>
