<template>
  <form @submit.prevent="save" autocomplete="off">
    <!-- Intro -->
    <div class="mb-lg">
      <p class="is-size-4 mb-md has-text-black has-text-centered">Orders Integration</p>
      <div class="columns is-centered">
        <div class="column is-three-quarters">
          <p class="mb-lg">
            {{ app.platform }} will automatically import customer orders that contain items
            from your approved suppliers and distribute purchase orders to suppliers via
            their preferred integration method (Console, EDI, API, etc).
          </p>
          <p class="mb-lg">
            When suppliers ship items, fulfillments and tracking numbers will be
            automatically synced back to the original customer order in your
            Shopify store account.
          </p>
          <p class="">
            When you click "Save & Continue", we will activate this integration
            and begin listening for new orders.
          </p>
        </div>
      </div>
    </div>
    <!-- Buttons -->
    <div class="mb-xl">
      <div class="columns is-centered">
        <div class="column is-three-quarters-tablet is-two-thirds-widescreen">
          <div class="columns">
            <div class="column">
              <b-button
                type="is-light rounded-lg"
                size="is-large"
                 @click="back"
                 expanded
               ><b-icon icon="arrow-left"></b-icon>
              </b-button>
            </div>
            <div class="column">
              <b-button
                native-type="submit"
                type="is-primary rounded-lg"
                size="is-large"
                expanded
                :loading="saving"
                :disabled="!validated || saving"
              >Save & Continue</b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  data () {
    return {
      loading: true,
      saving: false,
      errors: {}
    }
  },
  computed: {
    ...mapGetters(['app', 'context']),
    endpoint () {
      return this.context.route + '/shopify/' + this.account.id + '/webhooks/'
    },
    validated () {
      return true
    },
    json () {
      return {
        webhook_topic: 'orders/create'
      }
    },
    webhook () {
      if (this.webhooks.length === 0) return null
      const hook = this.$utils.find(this.webhooks, ['topic', 'orders/create'])
      return (typeof hook !== 'undefined') ? hook : null
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint).then(response => {
        this.loading = false
        this.webhooks = response.data.results
      })
    },
    back () {
      this.$router.go(-1)
    },
    save () {
      if (this.webhook === null) {
        const endpoint = this.context.route + '/shopify/' + this.account.id + '/register-webhook/'
        this.saving = true
        this.$http.put(endpoint, this.json).then(response => {
          this.$buefy.toast.open({ type: 'is-success', message: 'Sync enabled!' })
          this.$emit('change:step', 'inventory')
        }).catch(() => {
          this.$emit('change:step', 'inventory')
          this.$buefy.toast.open({ type: 'is-success', message: 'Sync enabled!' })
        }).finally(() => {
          this.saving = false
        })
      } else {
        this.$buefy.toast.open({ type: 'is-success', message: 'Settings saved!' })
        this.$emit('change:step', 'inventory')
      }
    }
  },
  mounted () {
    this.load()
  },
  props: ['account']
}
</script>
