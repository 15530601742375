<template>
  <span class="has-text-weight-bold" :class="presenter.indicatorClass">{{ presenter.label }}</span>
</template>

<script>
export default {
  computed: {
    presenter () {
      const presenter = {
        label: 'Pending',
        tooltip: 'This RMA has been registered, and is awaiting action from the supplier.',
        indicatorClass: 'has-text-grey'
      }
      // if the invoice is closed, but not acknowledged
      if (this.rma.status === 'approved' && this.rma.acknowledged_at === null) {
        presenter.label = 'Sent'
        presenter.tooltip = 'RMA approval sent, but the merchant has not yet acknowledged receipt.'
        presenter.indicatorClass = 'has-text-warning'
      } else if (this.rma.status === 'approved' && this.rma.acknowledged_at !== null) {
        presenter.label = 'Received'
        presenter.tooltip = 'The merchant has received this RMA approval.'
        presenter.indicatorClass = 'has-text-success'
      } else if (this.rma.status === 'rejected' && this.rma.acknowledged_at === null) {
        presenter.label = 'Sent'
        presenter.tooltip = 'RMA rejection sent, but the vendor has not yet acknowledged receipt.'
        presenter.indicatorClass = 'has-text-warning'
      } else if (this.rma.status === 'approved' && this.rma.acknowledged_at !== null) {
        presenter.label = 'Received'
        presenter.tooltip = 'The merchant has received this RMA rejection.'
        presenter.indicatorClass = 'has-text-danger'
      }
      return presenter
    }
  },
  props: ['rma']
}
</script>
