<template>
  <div class="columns is-vcentered is-mobile">
    <div class="column">
      <nav class="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <li v-for="(route, index) in history" :key="index">
            <router-link :to="{ name: route.name, params: route.params }" exact>{{ route.label }}</router-link>
          </li>
          <li v-if="current" class="is-active"><a href="#" aria-current="page">{{ $utils.truncate(current, {length: 24}) }}</a></li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  props: ['history', 'current']
}
</script>
