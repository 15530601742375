<template>
  <span class="has-text-weight-bold" :class="textClass">{{ label }}</span>
</template>

<script>
export default {
  computed: {
    label () {
      if (this.job.finish_time) return 'Complete'
      if (this.job.start_time) return 'Processing'
      return 'Queued'
    },
    textClass () {
      if (!this.job.finish_time) return 'has-text-info'
      return null
    }
  },
  props: {
    job: {
      type: Object,
      required: true
    }
  }
}
</script>
