<template>
  <div>
    <div class="section">
      <div class="container">
        <!-- Breadcrumbs -->
        <div class="mb-lg">
          <div class="columns is-vcentered">
            <div class="column is-one-third">
              <breadcrumbs :history="breadcrumbs" :current="breadcrumb" :hideBack="true" />
            </div>
          </div>
        </div>
        <!-- Product Loading -->
        <div v-if="loading" class="has-background-white rounded-lg pa-xl">
          <loading message="Loading product..." />
        </div>
        <!-- Product Loaded -->
        <div v-if="!loading && product !== null" >
          <!-- Product Info -->
          <div class="mb-xs">
            <product-info :product="product" />
          </div>
          <!-- Images -->
          <div class="mb-xs">
            <product-media :product="product" :media="product.media" />
          </div>
          <!-- Variants -->
          <product-variants :marketplace="$route.params.id" :product="product" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ProductInfo from '../components/ProductInfo'
import ProductMedia from '../components/ProductMedia'
import ProductVariants from '../components/ProductVariants'
export default {
  components: {
    ProductInfo,
    ProductMedia,
    ProductVariants
  },
  computed: {
    ...mapGetters(['context']),
    breadcrumbs () {
      const crumbs = [{
        label: 'Discover',
        name: 'discover.dashboard'
      }, {
        label: 'Catalog',
        name: 'discover.profile',
        params: {
          id: this.$route.params.id
        }
      }]
      return crumbs
    },
    breadcrumb () {
      return (this.loading || this.product === null) ? 'Loading...' : this.product.parent_name
    },
    endpoint () {
      return this.context.route + '/marketplaces/' + this.$route.params.id + '/products/' + this.$route.params.product + '/'
    }
  },
  data () {
    return {
      loading: true,
      product: null
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint).then(response => {
        this.product = response.data
      }).finally(() => {
        this.loading = false
      })
    }
  },
  mounted () {
    this.load()
  },
  props: ['profile']
}
</script>
