<template>
  <div>
    <div class="section">
      <div class="container">

        <!-- Page Header -->
        <div class="mb-md">
          <div class="columns is-vcentered">
            <div class="column is-one-third">
              <breadcrumbs :history="[{ label: 'Discover', name: 'discover.dashboard'}]" current="Connections" :hideBack="true" />
            </div>
            <div class="column is-one-third">
              <h1 class="has-text-centered is-size-4">Discover Products</h1>
            </div>
          </div>
        </div>

        <!-- Filters -->
        <div class="has-background-white rounded-lg pa-md mb-lg">
          <div class="columns">
            <div class="column">
              <connection-filters @filter="filter" :applied-filters="filters" :profile="profile" />
            </div>
            <div class="column is-narrow">
              <b-field >
                <b-select v-model="orderBy" expanded @input="sort" size="is-medium">
                  <option value="-id">Most Recently Added</option>
                  <option value="id">Oldest</option>
                  <option value="brand__name">Name (A-Z)</option>
                </b-select>
              </b-field>
            </div>
          </div>
        </div>

        <!-- Profiles -->
        <div class="">
          <!-- Loading -->
          <div v-if="loading">
            <div class="columns is-multiline">
              <div class="column is-one-quarter" v-for="index in placeholders" :key="index">
                <div class="">
                  <div class="placeholder placeholder-photo rounded-t-lg"></div>
                  <div class="has-background-white pa-md rounded-b-lg">
                    <div class="placeholder placeholder-title"></div>
                    <div class="placeholder placeholder-sentence"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Loaded (None)  -->
          <div v-else-if="!loading && results.length === 0">
            <div class="has-background-white rounded-xl has-text-grey-light has-text-centered py-3xl">
              <p><b-icon icon="magnify-close" size="is-large"></b-icon></p>
              <p>No connections found.</p>
            </div>
          </div>
          <!-- Loaded -->
          <div v-else>
            <div class="columns is-mobile is-multiline">
              <div class="column is-one-quarter-desktop is-one-third-tablet is-half-mobile" v-for="profile in results" :key="profile.id">
                <profile-preview :marketplace="profile" />
              </div>
            </div>
            <div class="mt-md">
              <b-pagination
                :total="total"
                :current.sync="currentPage"
                :rounded="true"
                :per-page="perPage"
                @change="paginate">
              </b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ConnectionFilters from '../components/Connections/ConnectionFilters'
import ProfilePreview from '../components/ProfilePreview'
import Filterable from '@/mixins/Filterable'
export default {
  components: {
    ConnectionFilters,
    ProfilePreview
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/marketplaces/'
    },
    placeholders () {
      return 12
    }
  },
  data () {
    return {
      key: 'connections',
      orderBy: 'brand__name',
      alwaysAttach: {
        is_connected: 1,
        short: 1
      }
    }
  },
  methods: {

  },
  mixins: [Filterable],
  mounted () {
    this.load()
  },
  props: ['profile']
}
</script>
