<template>
  <div>
    <form @submit.prevent="save">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">{{ (this.memo) ? 'Update Note' : 'Add Note'}}</p>
        </header>
        <section class="modal-card-body">
          <b-field label="Message">
            <b-input
              placeholder="Type your message here..."
              type="textarea"
              v-model="message"
              @input="announce"
            ></b-input>
          </b-field>
          <b-field label="Message Visibility">
            <b-select
              v-model="visibility"
              placeholder="Select Visibility"
              @input="announce"
              expanded>
              <option value="retailer">Retailer only</option>
              <option v-if="auth.isStaff" value="brand">Supplier only</option>
              <option value="any">Retailer & Supplier</option>
            </b-select>
          </b-field>
          <b-field label="Message notification">
            <b-checkbox v-model="notifyPeople" @input="announce">{{notifyLabel}}</b-checkbox>
          </b-field>
        </section>
        <footer class="modal-card-foot space-between">
          <b-button @click="$parent.close()">Close</b-button>
          <b-button
            type="is-primary"
            native-type="submit"
            :loading="saving"
            :disabled="!isReady || saving">
            Save Note
          </b-button>
        </footer>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  data () {
    return {
      message: null,
      saving: false,
      isDirty: false,
      visibility: 'any',
      notifyPeople: true,
      errors: {}
    }
  },
  computed: {
    ...mapGetters(['auth', 'context']),
    endpoint () {
      return (this.memo === null)
        ? this.context.route + '/connections/' + this.connection.id + '/memos/'
        : this.context.route + '/memos/' + this.memo.id + '/'
    },
    method () {
      return (this.memo === null) ? 'post' : 'patch'
    },
    json () {
      return {
        visibility: this.visibility,
        text: this.message,
        notify_people: this.notifyPeople
      }
    },
    notifyLabel () {
      const brand = this.connection.brand.name
      const retailer = this.context.partner.name
      let label = `Send email confirmation to ${brand} & ${retailer}`
      if (this.visibility === 'retailer') {
        label = `Send email confirmation to ${retailer}`
      } else if (this.visibility === 'brand') {
        label = `Send email confirmation to ${brand}`
      }
      return label
    },
    isReady () {
      return (
        this.message && this.isDirty
      )
    }
  },
  methods: {
    save () {
      this.saving = true
      this.$http[this.method](this.endpoint, this.json).then(repsonse => {
        this.$emit('saved:memo')
        this.$buefy.toast.open({ type: 'is-success', message: 'Note saved!' })
        this.$parent.close()
      }).finally(() => {
        this.saving = false
      })
    },
    announce () {
      this.isDirty = true
    }
  },
  mounted () {
    if (this.memo) {
      this.message = this.memo.text
      this.visibility = this.memo.visibility
    }
  },
  props: {
    connection: {
      type: Object
    },
    memo: {
      type: Object,
      default: null
    }
  }
}
</script>
