<template>
  <form @submit.prevent="save">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Complete Order?</p>
      </header>
      <section class="modal-card-body">
        <div class="has-text-centered">
          <b-message type="is-info">
            When you mark this order complete, your fulfillment data will be
            transmitted to your merchant partner. This action
            cannot be reversed.
          </b-message>
        </div>
      </section>
      <footer class="modal-card-foot space-between">
        <button class="button" type="button" @click="$parent.close()">Close</button>
        <b-button
          type="is-primary"
          native-type="submit"
          :loading="saving"
          :disabled="saving"
        >Complete Order</b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  data () {
    return {
      saving: false
    }
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/orders/' + this.order.id + '/close/'
    }
  },
  methods: {
    save () {
      this.saving = true
      this.$http.put(this.endpoint).then(() => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Order completed!' })
        this.$emit('order:updated')
      }).catch(error => {
        this.$buefy.toast.open({ type: 'is-danger', message: error.response.data[0] })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {

  },
  props: ['order']
}
</script>
