<template>
  <div>
    <p v-if="!obfuscate">{{ customer.name1 }}</p>
    <p v-if="customer.name2 !== null && !obfuscate">{{ customer.name2 }}</p>
    <p v-if="!obfuscate">{{ customer.street1 }}</p>
    <p v-if="customer.street2 !== null && !obfuscate">{{ customer.street2 }}</p>
    <p>{{ customer.city }}, {{ customer.province }} {{ customer.postal_code }}</p>
    <p v-if="customer.phone1 !== null && !obfuscate">{{ customer.phone1 }}</p>
    <p v-if="customer.email !== null && !obfuscate">{{ customer.email }}</p>
    <p><a href="#" class="has-text-info" @click.prevent="obfuscate = !obfuscate" v-text="(obfuscate) ? 'Show Customer' : 'Hide Customer'"></a></p>
  </div>
</template>

<script>
export default {
  data () {
    return {
      obfuscate: true
    }
  },
  props: ['customer']
}
</script>
