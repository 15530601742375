<template>
  <form @submit.prevent="save">
    <!-- Logistics -->
    <div class="has-background-white pa-lg rounded-lg mb-xxs">
      <div class="columns is-mobile">
        <div class="column">
          <!-- Ship From -->
          <ShipFrom v-model="shipFrom" />
        </div>
        <div class="column">
          <!-- Service -->
          <SelectService v-model="shippingMethod" :order="order" :requestedService="(order.requested_shipping_method) ? order.requested_shipping_method : order.shipping_method.generic_shipping_method" />
        </div>
      </div>
      <b-field
        class="mt-md"
        label="Tracking Number"
        :type="('trackingNumber' in errors) ? 'is-danger' : ''"
        :message="('trackingNumber' in errors) ? errors['trackingNumber'][0] : ''"
        expanded>
          <b-input
            type="text"
            v-model="trackingNumber"
            size="is-medium"
            :disabled="shippingMethod === null"
          ></b-input>
      </b-field>
    </div>
    <!-- Items -->
    <div class="has-background-white pa-lg rounded-lg mb-xxs">
      <div class="columns is-mobile is-vcentered">
        <div class="column">
          <p class="has-text-weight-bold">Line Items</p>
        </div>
        <div class="column">
          <p class="has-text-right"><a href="#" @click.prevent="updateFillAll">Fill All</a></p>
        </div>
      </div>
      <fulfill-line
        v-for="line in order.order_lines"
        :line="line"
        :key="line.id"
        :fillAll="fillAll[line.variant.id]"
        :isDirty="isDirty"
        @update:line="updateLine"/>
    </div>
    <!-- Buttons -->
    <div class="has-background-white py-md px-lg rounded-lg mb-xxs">
      <div class="columns">
        <div class="column is-narrow">
          <b-button
            type="is-primary"
            native-type="submit"
            size="is-medium"
            :disabled="!ready || saving || orderRefreshing"
            expanded
          >{{ submitButtonLabel }}</b-button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import FulfillLine from './FulfillLine'
import ShipFrom from './ShipFrom'
import SelectService from './AllServices'

export default {
  components: {
    FulfillLine,
    ShipFrom,
    SelectService
  },
  data () {
    return {
      shipFrom: null,
      shippingMethod: null,
      fillAll: {},
      isDirty: false,
      lines: {},
      trackingNumber: null,
      saving: false,
      errors: {}
    }
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/shipments/'
    },
    ready () {
      return (
        this.shipFrom &&
        this.shippingMethod &&
        this.trackingNumber &&
        Object.keys(this.lines).length > 0
      )
    },
    json () {
      if (!this.ready) return null
      const json = {
        order_id: this.order.id,
        ship_from: { location: { id: this.shipFrom.id } },
        shipping_method_id: this.shippingMethod.id,
        tracking_number: this.trackingNumber,
        shipment_lines: []
      }
      for (const id in this.lines) {
        const shipmentLine = {
          variant: {
            id: this.lines[id].variant.id
          },
          quantity: this.lines[id].quantity,
          part: 1
        }
        if (this.lines[id].brand_identifier) {
          shipmentLine.brand_identifier = this.lines[id].brand_identifier
        }
        json.shipment_lines.push(shipmentLine)
      }
      return json
    },
    submitButtonLabel () {
      if (this.orderRefreshing) return 'Order Refreshing...'
      if (this.saving) return 'Saving...'
      return 'Register Tracking Number'
    }
  },
  methods: {
    save () {
      this.saving = true
      this.$http.post(this.endpoint, this.json).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Tracking number registered!' })
        this.$emit('fulfillment:updated')
        this.reset()
      }).catch(() => {
        this.$buefy.toast.open({ type: 'is-danger', message: 'Tracking number not registered!' })
      }).finally(() => {
        this.saving = false
      })
    },
    updateLine (payload) {
      if (this.fillAll[payload.lineId]) {
        this.$set(this.fillAll, payload.lineId, false)
      }
      this.isDirty = true
      if (payload.quantity > 0) {
        const line = {
          variant: {
            id: payload.variantId
          },
          brand_identifier: payload.brandIdentifier,
          quantity: payload.quantity
        }
        this.$set(this.lines, payload.lineId, line)
      } else {
        this.$delete(this.lines, payload.lineId)
      }
    },
    updateFillAll () {
      Object.keys(this.fillAll).forEach(key => {
        if (!this.fillAll[key]) {
          this.fillAll[key] = true
        }
      })
    },
    reset () {
      this.isDirty = false
      this.tracking_number = null
      this.lines = {}
    }
  },
  mounted () {
    this.order.order_lines.forEach(line => {
      this.$set(this.fillAll, line.variant.id, false)
    })
  },
  props: ['order', 'orderRefreshing']
}
</script>
