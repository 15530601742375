<template>
  <div>
    <form @submit.prevent="save">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Invite Supplier</p>
        </header>
        <section class="modal-card-body">
          <div v-if="!loading">
            <div class="mb-lg">
              <!-- Company -->
              <div class="mb-xl">
                <p class="has-text-info has-text-weight-bold border-b pb-xs mb-md">Supplier Info</p>
                <div class="columns is-multiline">
                  <div class="column is-full">
                    <!-- Name -->
                    <b-field
                      label="Supplier Name"
                      :type="('company_name' in errors) ? 'is-danger' : ''"
                      :message="('company_name' in errors) ? errors['company_name'][0] : ''">
                      <b-input
                        type="text"
                        size="is-medium"
                        v-model="supplierName"
                      ></b-input>
                    </b-field>
                  </div>
                  <div class="column is-half">
                    <!-- Supplier Number-->
                    <b-field
                      label="Supplier Number (optional)"
                      :type="('retailer_identifier' in errors) ? 'is-danger' : ''"
                      :message="('retailer_identifier' in errors) ? errors['retailer_identifier'][0] : ''">
                      <b-input
                        type="text"
                        size="is-medium"
                        v-model="supplierNumber"
                      ></b-input>
                    </b-field>
                  </div>
                  <div class="column is-half">
                    <!-- Target Date -->
                    <b-field
                      label="Target Launch Date (optional)"
                      :type="('onboarding_target_date' in errors) ? 'is-danger' : ''"
                      :message="('onboarding_target_date' in errors) ? errors['onboarding_target_date'][0] : ''">
                      <b-input
                        size="is-medium"
                        type="date"
                        :min="$dates().format('YYYY-MM-DD')"
                        v-model="targetDate"
                      ></b-input>
                    </b-field>
                  </div>
                </div>
              </div>
              <!-- First & Last Name -->
              <p class="has-text-info has-text-weight-bold border-b pb-xs mb-md">Primary Contact</p>
              <div class="columns">
                <div class="column">
                  <b-field
                    label="First Name"
                    :type="('first_name' in errors) ? 'is-danger' : ''"
                    :message="('first_name' in errors) ? errors['first_name'][0] : ''">
                    <b-input
                      type="text"
                      size="is-medium"
                      v-model="firstName"
                    ></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field
                    label="Last Name"
                    :type="('last_name' in errors) ? 'is-danger' : ''"
                    :message="('last_name' in errors) ? errors['last_name'][0] : ''">
                    <b-input
                      type="text"
                      size="is-medium"
                      v-model="lastName"
                    ></b-input>
                  </b-field>
                </div>
              </div>
              <!-- Email Address -->
              <b-field
                label="Email Address"
                :type="('email' in errors) ? 'is-danger' : ''"
                :message="('email' in errors) ? errors['email'][0] : ''">
                <b-input
                  type="email"
                  size="is-medium"
                  v-model="emailAddress"
                ></b-input>
              </b-field>
              <!-- Connection Memos -->
              <p class="has-text-info has-text-weight-bold border-b pb-xs mb-md">Connection note</p>
              <b-field>
                <b-checkbox v-model="addMemo">
                  I want to add a connection note
                </b-checkbox>
              </b-field>
              <div v-if="addMemo">
                <b-field label="Message">
                <b-input
                  placeholder="Type your message here..."
                  type="textarea"
                  v-model="memoMessage"
                ></b-input>
              </b-field>
              <b-field label="Message Visibility">
                <b-select
                  v-model="visibility"
                  placeholder="Select Visibility"
                  expanded>
                  <option value="retailer">Retailer only</option>
                  <option v-if="auth.isStaff" value="brand">Supplier only</option>
                  <option value="any">Retailer & Supplier</option>
                </b-select>
              </b-field>
            </div>
            </div>
            <!-- Preferences -->
            <div class="mb-lg">
              <div class="mb-md">
                <div class="mb-md">
                  <p class="has-text-info has-text-weight-bold border-b pb-xs mb-sm">Advanced Settings</p>
                  <p class="">
                    The following settings are preconfigured using your
                    organization's default settings. You may override
                    the default settings, but contact your administrator
                    if you have any questions.
                  </p>
                </div>
                <b-field>
                  <b-checkbox v-model="override" :disabled="override">
                    I want to override the settings below:
                  </b-checkbox>
                </b-field>
              </div>
              <!-- Connection Level -->
              <div class="mb-sm">
                <div class="columns">
                  <div class="column">
                    <b-field label="Connection Level">
                      <b-select
                        v-model="connectionLevel"
                        size="is-medium"
                        expanded
                        :disabled="saving || !override">
                        <option value="transactions_and_products">Transactions &amp; Products</option>
                        <option value="products_only">Products Only</option>
                      </b-select>
                    </b-field>
                    <p class="is-size-7 has-text-grey-light">
                      Select <b>Transactions &amp; Products</b> if you plan to onboard this supplier
                      with full transactional capabilities (i.e. products, inventory, orders, fulfillments, e.tc).
                      If you're only using {{ app.productName }} for product onboarding, select <b>Products Only</b>.
                    </p>
                  </div>
                </div>
              </div>
              <!-- Fulfillment SLA -->
              <div v-if="connectionLevel === 'transactions_and_products'" class="mb-sm">
                <div class="columns">
                  <div class="column">
                    <b-field label="Fulfillment SLA (Business Days)">
                      <b-numberinput
                        v-model="fulfillmentSla"
                        size="is-medium"
                        :controls="false"
                        :min="1"
                        expanded
                        :disabled="saving || !override"  />
                    </b-field>
                  </div>
                </div>
              </div>
              <!-- Payment Terms -->
              <div class="mb-sm" v-if="connectionLevel === 'transactions_and_products'">
                <div class="columns">
                  <div class="column">
                    <b-field label="Payment Terms">
                      <b-select
                        v-model="paymentTerms"
                        size="is-medium"
                        expanded
                        :disabled="saving || !override">
                        <option value="net7">Net 7 Days</option>
                        <option value="net15">Net 15 Days</option>
                        <option value="net30">Net 30 Days</option>
                        <option value="net45">Net 45 Days</option>
                        <option value="net60">Net 60 Days</option>
                      </b-select>
                    </b-field>
                  </div>
                </div>
              </div>
              <!-- Default Catalog Import Methods -->
              <div class="mb-sm">
                <div class="columns">
                  <div class="column">
                    <b-field label="Catalog Import Method">
                      <b-select
                        type="text"
                        v-model="catalogImportMethod"
                        :disabled="saving || !override"
                        size="is-medium"
                        expanded
                        @input="isDirty = 1">
                        <option v-for="option in defaultCatalogImportOptions" :key="option.id" :value="option.value">{{ option.name }}</option>
                      </b-select>
                    </b-field>
                  </div>
                </div>
              </div>
              <!-- Cost Model -->
              <div class="mb-lg" v-if="connectionLevel === 'transactions_and_products'">
                <div class="columns">
                  <div class="column">
                    <b-field label="Cost Model">
                      <b-select size="is-medium" expanded  :disabled="saving || !override" v-model="netPriceMethod">
                        <option value="specified_on_order">Specified on Order</option>
                        <option value="permit_cost">Specified on Platform</option>
                        <option value="revshare">Commission</option>
                        <option value="commission_profile">Commission Profile</option>
                      </b-select>
                    </b-field>
                  </div>
                  <div v-if="netPriceMethod === 'revshare'" class="column">
                    <b-field label="Rate (to Merchant)">
                      <b-numberinput
                        type="text"
                        size="is-medium"
                        v-model="commissionRate"
                        :disabled="saving || !override"
                        :step=".25"
                        :min="0"
                        :max="99"
                      ></b-numberinput>
                    </b-field>
                  </div>
                  <div v-if="netPriceMethod === 'commission_profile'" class="column">
                    <CommissionProfileSelect v-model="commissionProfile" size="is-medium" :disabled="saving || !override"/>
                  </div>
                </div>
              </div>
              <!-- Returns Allowance -->
              <div class="mb-lg" v-if="connectionLevel === 'transactions_and_products'">
                <p class="mb-sm"><label class="label"></label></p>
                <div class="columns">
                  <div class="column is-two-thirds">
                    <b-field label="Returns & Customer Service Allowance (%)">
                      <b-numberinput
                        type="text"
                        size="is-medium"
                        v-model="returnsAllowance"
                        :disabled="saving || !override"
                        step=".25"
                      ></b-numberinput>
                    </b-field>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="">
              <loading message="Loading onboarding preferences..." />
            </div>
          </div>
        </section>
        <footer class="modal-card-foot space-between">
          <b-button @click="$parent.close()">Close</b-button>
          <b-button
            type="is-primary"
            native-type="submit"
            :loading="saving"
            :disabled="saving || !ready">
            Send Invitation
          </b-button>
        </footer>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CommissionProfileSelect from '../CommissionProfiles/ProfileSelect'
export default {
  components: {
    CommissionProfileSelect
  },
  data () {
    return {
      message: null,
      saving: false,
      errors: {},
      loading: true,
      preferences: [],
      supplierName: null,
      supplierNumber: null,
      targetDate: null,
      firstName: null,
      lastName: null,
      emailAddress: null,
      memoMessage: '',
      visibility: null,
      addMemo: false,
      override: false,
      connectionLevel: null,
      paymentTerms: null,
      fulfillmentSla: null,
      returnsAllowance: null,
      netPriceMethod: null,
      catalogImportMethod: null,
      commissionRate: 0.0,
      defaultCatalogImportOptions: [],
      commissionProfile: null
    }
  },
  computed: {
    ...mapGetters(['app', 'auth', 'context']),
    endpoint () {
      return this.context.route + '/connection-invites/'
    },
    json () {
      const json = {
        company_name: this.supplierName,
        first_name: this.firstName,
        last_name: this.lastName,
        email: this.emailAddress,
        memos: [],
        slas: {},
        adjustments: [],
        options: {}
      }
      if (this.supplierNumber) {
        json.retailer_identifer = this.supplierNumber
      }
      if (this.targetDate) {
        json.onboarding_target_date = this.targetDate + 'T10:10'
      }
      // connection level
      json.level = (this.connectionLevel === 'products_only')
        ? 'products_only'
        : 'transactions_and_products'
      // sla
      if (this.fulfillmentSla) {
        json.slas.brand_fulfillment_hours = this.fulfillmentSla * 24
      }
      if (this.addMemo && this.memoMessage) {
        // construct the memos
        json.memos = [{
          text: this.memoMessage,
          visibility: this.visibility
        }]
      }
      // construct the fees
      json.fees = [{
        context: 'retailer',
        net_price_method: 'specified_in_order',
        payment_terms: (this.paymentTerms) ? this.paymentTerms : 'net30'
      }, {
        context: 'brand',
        net_price_method: 'specified_in_order',
        payment_terms: (this.paymentTerms) ? this.paymentTerms : 'net30'
      }]
      // handle permit cost...
      if (this.netPriceMethod === 'permit_cost') {
        json.fees[0].net_price_method = 'permit_cost'
        json.fees[1].net_price_method = 'permit_cost'
      }
      // handle revshares...
      if (this.netPriceMethod === 'revshare' && this.commissionRate) {
        const commission = parseFloat(this.commissionRate / 100)
        if (commission > 0 && commission < 1) {
          json.fees[0].net_price_method = 'revshare'
          json.fees[0].revshare_fraction = commission
          json.fees[1].net_price_method = 'revshare'
          json.fees[1].revshare_fraction = 1 - commission
        }
      }
      // handle commission profile...
      if (this.netPriceMethod === 'commission_profile' && this.commissionProfile) {
        json.fees[0].net_price_method = null
        json.fees[1].net_price_method = null
        json.commission_profile = {
          id: this.commissionProfile.id
        }
      }
      // handle return allowances...
      if (this.returnsAllowance) {
        const allowance = parseFloat(this.returnsAllowance)
        if (allowance > 0 && allowance < 10) {
          json.adjustments.push({
            context: 'retailer',
            description: 'Returns Allowance',
            adjustment_type: 'allowance',
            adjustment_amount_type: 'percent',
            adjustment_amount: allowance,
            adjustment_code: 'returns_allowance'
          })
        }
      }
      // attach connection options
      if (this.catalogImportMethod) {
        if (this.catalogImportMethod === 'proposals') {
          json.options.merchandise_via_proposals = true
        } else if (this.catalogImportMethod === 'import_request') {
          json.options.merchandise_via_import_request = true
        }
      }
      return json
    },
    ready () {
      return (
        this.supplierName &&
        this.firstName &&
        this.lastName &&
        this.emailAddress
      )
    }
  },
  methods: {
    save () {
      this.saving = true
      this.$http.post(this.endpoint, this.json).then(repsonse => {
        this.$emit('saved')
        this.$buefy.toast.open({ type: 'is-success', message: 'Invitation sent!' })
        this.$parent.close()
        this.$router.push({ name: 'connection.invitations' })
      }).catch((error) => {
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please check your input and try again!' })
        this.errors = (error.response.data) ? error.response.data : { detail: 'An unexpected error occured!' }
      }).finally(() => {
        this.saving = false
      })
    },
    loadPreferences () {
      this.loading = true
      const route = this.context.route + '/preferences/?grouping=onboarding'
      this.$http.get(route).then(response => {
        this.preferences = response.data.results
        // set the connection level based on default preference
        const level = this.$utils.find(this.preferences, ['code', 'default_connection_level'])
        if (typeof level !== 'undefined' && level.retailer_preference) {
          this.connectionLevel = level.retailer_preference.value
        } else {
          this.connectionLevel = level.default_option
        }
        // set the fulfillment sla based on default preference
        const sla = this.$utils.find(this.preferences, ['code', 'default_fulfillment_sla'])
        if (typeof sla !== 'undefined' && sla.retailer_preference) {
          this.fulfillmentSla = sla.retailer_preference.value / 24
        }
        // set the payment terms based on preference
        const paymentTerms = this.$utils.find(this.preferences, ['code', 'default_payment_terms'])
        if (typeof paymentTerms !== 'undefined' && paymentTerms.retailer_preference) {
          this.paymentTerms = paymentTerms.retailer_preference.value
        }
        // set the payment terms based on preference
        const netPriceMethod = this.$utils.find(this.preferences, ['code', 'default_net_price_method'])
        if (typeof netPriceMethod !== 'undefined' && netPriceMethod.retailer_preference) {
          this.netPriceMethod = netPriceMethod.retailer_preference.value
        }
        // if the payment terms is revshare, also set the commission rate
        if (this.netPriceMethod === 'revshare') {
          const commissionRate = this.$utils.find(this.preferences, ['code', 'default_commission_rate'])
          if (typeof commissionRate !== 'undefined' && commissionRate.retailer_preference) {
            this.commissionRate = parseFloat(commissionRate.retailer_preference.value)
          }
        }
        // if the payment terms is commission profile
        if (this.netPriceMethod === 'commission_profile') {
          const commissionRate = this.$utils.find(this.preferences, ['code', 'default_commission_rate'])
          if (typeof commissionRate !== 'undefined' && commissionRate.retailer_preference) {
            this.commissionProfile = {
              id: parseInt(commissionRate.retailer_preference.value)
            }
          }
        }
        // set default returns allowance
        const returnsAllowance = this.$utils.find(this.preferences, ['code', 'default_returns_allowance'])
        if (typeof returnsAllowance !== 'undefined' && returnsAllowance.retailer_preference) {
          this.returnsAllowance = parseFloat(returnsAllowance.retailer_preference.value)
        }
        // set default catalog import methods
        const importMethods = this.$utils.find(this.preferences, ['code', 'default_catalog_import_method'])
        if (typeof importMethods !== 'undefined') {
          // set the options
          this.defaultCatalogImportOptions = importMethods.options.map(option => {
            return {
              id: option.id,
              name: option.name,
              value: option.value
            }
          })
          if (importMethods.retailer_preference) {
            this.catalogImportMethod = importMethods.retailer_preference.value
          }
        }
      }).finally(() => {
        this.loading = false
      })
    }
  },
  mounted () {
    this.loadPreferences()
  },
  props: {}
}
</script>
