<template>
  <div>
    <div class="columns is-vcentered">
      <div class="column">
        <Item :variant="line.variant" :showImage="false" />
      </div>
      <div class="column is-two-fifths">
        <b-field>
          <b-numberinput
            v-model="quantity"
            controlsPosition="compact"
            min="0"
            :max="line.quantity_shipped - line.quantity_invoiced"
            @input="update"
            expanded
          />
          <p class="control">
            <span class="button is-static">of {{ line.quantity_shipped - line.quantity_invoiced }}</span>
          </p>
        </b-field>
      </div>
      <div class="column is-narrow has-text-centered">
        <p><CurrencyLocale :value="total" :currency="currency"/></p>
        <p class="is-size-8 has-text-grey-light"><CurrencyLocale :value="cost" :currency="currency"/> / ea</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Item from '@/components/Item'

export default {
  components: {
    Item
  },
  data () {
    return {
      quantity: 0
    }
  },
  computed: {
    ...mapGetters(['context']),
    cost () {
      return parseFloat(this.line.net_price)
    },
    total () {
      return parseFloat(this.line.net_price * this.quantity)
    }
  },
  methods: {
    update () {
      this.$emit('update:line', {
        variantId: this.line.variant.id,
        name: this.line.variant.name,
        description: this.line.variant.description,
        quantity: this.quantity,
        netPrice: parseFloat(this.line.net_price),
        total: parseFloat(this.line.net_price * this.quantity)
      })
    }
  },
  mounted () {

  },
  props: ['line', 'fillAll', 'isDirty', 'currency'],
  watch: {
    fillAll: {
      immediate: true,
      handler (value) {
        if (value === true) {
          this.quantity = this.line.quantity_shipped - this.line.quantity_invoiced
          this.update()
        }
      }
    },
    isDirty: {
      immediate: false,
      handler (value) {
        if (value === false) {
          this.quantity = 0
        }
      }
    }
  }
}
</script>
