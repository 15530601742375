<template>
  <div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Shipment Detail</p>
      </header>
      <section class="modal-card-body">
        <div v-if="!loading && detail !== null">
          <div class="columns">
            <div class="column">
              <table class="table is-fullwidth is-striped is-size-8 is-narrow border">
                <tbody>
                  <tr>
                    <th>Service</th>
                    <td>
                      <ShippingService :shippingMethod="detail.shipping_method" :shippingProviderIdentifier="detail.shipping_provider_identifier" />
                    </td>
                  </tr>
                  <tr>
                    <th>Tracking Number</th>
                    <td>
                      <a v-if="detail.tracking_url !== null" :href="detail.tracking_url" target="_blank" class="has-text-weight-bold has-text-info">{{ detail.tracking_number }}</a>
                      <p v-else>{{ detail.tracking_number }}</p>
                    </td>
                  </tr>
                  <tr>
                    <th>Source</th>
                    <td>
                      <p v-if="detail.valid_shipping_label === true">{{ app.platform }}</p>
                      <p v-else class="has-text-grey">External</p>
                    </td>
                  </tr>
                  <tr>
                    <th>Dimensions</th>
                    <td>
                      <p v-if="detail.ship_girth !== null">
                        {{ detail.ship_height }}"x{{ detail.ship_length }}"x{{ detail.ship_width }}"
                        ({{ detail.ship_girth }}"),
                        {{ detail.ship_weight }} lbs
                      </p>
                      <p v-else class="has-text-grey">- Unknown -</p>
                    </td>
                  </tr>
                  <tr>
                    <th>Signature</th>
                    <td>
                      <span class="has-text-warning" v-if="detail.signature === true">Required</span>
                      <span v-else>Not Required</span>
                    </td>
                  </tr>
                  <tr>
                    <th>ID</th>
                    <td>{{ detail.id }}</td>
                  </tr>
                  <tr>
                    <th>PO #</th>
                    <td>{{ detail.purchase_order_number }}</td>
                  </tr>
                  <tr>
                    <th>Status</th>
                    <td>
                      <p :class="status.indicatorClass">
                        <b-tooltip :label="status.tooltip" dashed multilined>
                          {{ status.label }}
                        </b-tooltip>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <th>Acknowledged</th>
                    <td>
                      <span class="has-text-sucess" v-if="detail.acknowledged_at !== null">{{ $dates.utc(detail.acknowledged_at).local().format('MMM D, YYYY')}}</span>
                      <span v-else class="has-text-grey">Not Acknowledged</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="column">
              <table class="table is-fullwidth is-size-8 is-narrow border mb-lg">
                <tbody>
                  <tr>
                    <th>Origin</th>
                    <td><location :location="detail.ship_from"></location></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- Shipment Lines -->
          <div>
            <p class="has-text-weight-bold mb-sm has-text-centered">Line Items</p>
            <table class="table is-fullwidth is-striped is-size-7 is-narrow border is-vcentered">
              <thead>
                <tr>
                  <th>Item</th>
                  <th class="has-text-centered" style="width:128px">Qty</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="line in detail.shipment_lines" :key="line.id">
                  <td>
                    <Identifiers :variant="line.variant" :showImage="false" nameInteraction="none" />
                  </td>
                  <td class="has-text-centered">
                    <p v-if="line.part === 1">{{ line.quantity }}</p>
                    <div v-else>
                      <p>Partial Shipment</p>
                      <p class="is-size-8">Part #{{ line.part }}</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- Error Logs -->
          <div v-if="detail.error_logs.length > 0">
            <p class="has-text-centered  has-text-weight-bold mb-sm">Errors ({{ detail.error_logs.length }})</p>
            <table class="table is-fullwidth is-striped is-size-7 is-narrow border">
              <thead>
                <tr>
                  <th>Message</th>
                  <th class="has-text-centered" style="widht:96px">Count</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="error in detail.error_logs" :key="error.id">
                  <td>{{ error.message }}</td>
                  <td class="has-text-centered">{{ error.count }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- Packing Slip -->
          <b-modal
            v-if="showPackingSlipModal"
            has-modal-card
            :active="showPackingSlipModal"
            :can-cancel="['escape', 'x']"
            :shipment="shipment"
            ><PackingSlipModal :shipment="shipment" @close="showPackingSlipModal = false"></PackingSlipModal>
          </b-modal>
        </div>
        <div v-else>
          <loading message="Loading shipment..." />
        </div>
      </section>
      <footer class="modal-card-foot" style="justify-content:space-between">
        <button class="button" type="button" @click="$parent.close()">Close</button>
        <b-button type="is-primary" :disabled="loading || !shipment.valid_packing_slip" @click="showPackingSlipModal = true">Packing Slip</b-button>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Identifiers from '@/components/Models/Variant/Identifiers'
import ShipmentPresenters from '@/mixins/presenters/Shipment'
import Location from '@/components/Location'
import ShippingService from '@/components/Presenters/Shipment/ShippingService'
import PackingSlipModal from '@/components/Modals/Shipment/PackingSlipModal'

export default {
  components: {
    Identifiers,
    Location,
    ShippingService,
    PackingSlipModal
  },
  computed: {
    ...mapGetters(['app', 'context']),
    endpoint () {
      return this.context.route + '/shipments/' + this.shipment.id + '/?show_shipments=1'
    }
  },
  data () {
    return {
      loading: false,
      detail: null,
      showPackingSlipModal: false
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint).then(response => {
        this.detail = response.data
        this.loading = false
      })
    }
  },
  mixins: [ShipmentPresenters],
  mounted () {
    this.load()
  },
  props: ['shipment']
}
</script>
