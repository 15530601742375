<template>
  <span>{{ runtime }}</span>
</template>

<script>
export default {
  computed: {
    runtime () {
      if (!this.job.start_time || !this.job.finish_time) return '--'
      const start = this.$dates.utc(this.job.start_time)
      const finish = this.$dates.utc(this.job.finish_time)
      const msDiff = finish.diff(start)
      if (msDiff > 60000) {
        return '~ ' + this.$numbers(finish.diff(start, 'minute')).format({ mantissa: 0 }) + ' minutes'
      } else if (msDiff > 1000) {
        return '~ ' + this.$numbers(finish.diff(start, 'second')).format({ mantissa: 0 }) + ' seconds'
      }
      return this.$numbers(finish.diff(start)).format({ mantissa: 0 }) + ' ms'
    }
  },
  props: {
    job: {
      type: Object,
      required: true
    }
  }
}
</script>
