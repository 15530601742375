<template>
  <div>
    <div class="columns">
      <div class="column">
        <b-field>
          <b-autocomplete
            field="id"
            placeholder="Search for a connection by name..."
            v-model="value"
            :loading="loading"
            :data="results"
            :clear-on-select="true"
            :open-on-focus="true"
            size="is-medium"
            @typing="search"
            @select="viewConnection"
            expanded>
            <template slot-scope="props">
              <div class="media">
                <div class="media-content">
                  <p class="has-text-weight-bold has-text-primary">{{ props.option.brand.name }}</p>
                  <p class="has-text-grey-dark">{{ $utils.upperFirst(props.option.status) }}</p>
                </div>
              </div>
            </template>
          </b-autocomplete>
        </b-field>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/connections/'
    },
    query () {
      const params = {
        order_by: '-id',
        short: 1,
        search: this.value
      }
      return params
    },
    ready () {
      return !this.invalidSearchValues.includes(this.query.search)
    }
  },
  data () {
    return {
      loading: false,
      parameter: 'search',
      invalidSearchValues: [null, undefined, ''],
      value: null,
      results: [],
      selected: null
    }
  },
  methods: {
    search: debounce(function (name) {
      this.searchConnections()
    }, 1000),
    searchConnections () {
      if (this.ready) {
        this.loading = true
        this.$http.get(this.endpoint, { params: this.query }).then(response => {
          this.results = response.data.results
          this.loading = false
        })
      }
    },
    viewConnection (option) {
      this.$router.push({ name: 'connection.detail', params: { id: option.id } })
    }
  }
}
</script>
