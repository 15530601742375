<template>
  <span>{{ presenter }}</span>
</template>

<script>
export default {
  computed: {
    presenter () {
      if (!this.value) {
        return '--'
      }
      if (this.isTimestamp) {
        return (this.showYear)
          ? this.$dates.utc(this.value).local().format('MMM D, YYYY')
          : this.$dates.utc(this.value).local().format('MMM D')
      }
      return (this.showYear)
        ? this.$dates(this.value).format('MMM D, YYYY')
        : this.$dates(this.value).format('MMM D')
    }
  },
  props: {
    value: {
      type: String,
      required: true
    },
    isTimestamp: {
      type: Boolean,
      required: false,
      default: false
    },
    showYear: {
      type: Boolean,
      required: false,
      default: true
    }
  }
}
</script>
