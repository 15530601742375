<template>
  <div class="pa-md">
    <!-- Loading Skeleton -->
    <div v-if="loading">
      <loading message="Loading..." />
    </div>
    <!-- Task is loaded -->
    <div v-else>
      <div class="columns is-centered mb-md">
        <div class="column is-three-quarters">
          <div class="columns">
            <div class="column is-one-third mt-sm">
              <breadcrumbs :history="[{ label: 'Onboarding', name: 'retailer.onboarding'}]" current="Catalog Management" />
            </div>
            <div class="column is-one-third has-text-weight-bold is-size-5 has-text-centered">
              Catalog Management
            </div>
          </div>
        </div>
      </div>
      <div class="columns is-centered mt-sm">
        <div class="column is-three-quarters">
          <div class="columns">
            <div class="column is-one-third">
              <div class="has-background-white rounded-lg px-3" style="margin-top: -1.5rem;">
                <div v-for="(step, index) in setupSteps" :key="step.id">
                  <router-link :to="{ name: step.path }">
                    <div class="columns mt-3 py-sm is-vcentered" :class="[step.path === currentPathName && 'has-background-grey-lighter has-text-black', index !== 2 && 'has-border-b']">
                      <div class="column">{{step.label}}</div>
                      <div class="column is-narrow">
                        <b-icon v-if="step.isCompleted" icon="check" type="is-success"></b-icon>
                        <b-icon icon="chevron-right"></b-icon>
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>
              <div class="is-size-7 has-text-grey-light mt-md">
                <a v-if="auth.isStaff && isCompleted" href="#" class="is-pulled-left" @click="showResetTaskModal=true">
                  Reset Task
                </a>
                <router-link :to="{ name:'retailer.onboarding' }" class="is-pulled-right">
                  Back to Tasks
                </router-link>
              </div>
            </div>
            <div class="column px-lg ml-md has-background-white rounded-lg">
              <router-view
                :task="task"
                :isUpdatingTask="isUpdatingTask"
                @task:updated="(body, path)=>updateTaskInfo(body, path)"
              ></router-view>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Reset Task Modal -->
    <b-modal
      v-if="!loading && task !== null"
      :has-modal-card="true"
      :active.sync="showResetTaskModal"
      :can-cancel="['x', 'esc']">
      <ResetTaskModal :taskId="task.id" @task:reset="onTaskReset" />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ResetTaskModal from '@/components/Models/Task/Modals/ResetTaskModal'
export default {
  components: {
    ResetTaskModal
  },
  computed: {
    ...mapGetters(['app', 'auth', 'context']),
    currentPathName () {
      return this.$route.name
    },
    endpoint () {
      return this.context.route + '/assigned-tasks/' + this.$route.params.task + '/'
    },
    loading () {
      return (this.loadingTask)
    }
  },
  data () {
    return {
      loadingTask: true,
      isUpdatingTask: false,
      task: null,
      isCompleted: false,
      showResetTaskModal: false,
      setupSteps: [
        {
          id: 'templates',
          label: '1. Templates',
          path: 'retailer.onboarding.catalog-management',
          isCompleted: false
        },
        {
          id: 'rulesets',
          label: '2. Rulesets',
          path: 'retailer.onboarding.rulesets',
          isCompleted: false
        },
        {
          id: 'proposalDepartment',
          label: '3. Proposal Department',
          path: 'retailer.onboarding.proposal-departments',
          isCompleted: false
        }
      ]
    }
  },
  methods: {
    updateTaskInfo (taskBody, nextPath) {
      this.isUpdatingTask = true
      this.$http.patch(this.endpoint, taskBody).then(response => {
        this.task = response.data
        this.setupSteps.map(el => {
          if (el.id in this.task.info) {
            el.isCompleted = true
          }
          return el
        })
        this.loadTask(true)
      }).finally(() => {
        this.isUpdatingTask = false
        if (nextPath) {
          this.$router.push({ name: nextPath, params: { task: this.$route.params.task } })
        }
      })
    },
    loadTask (quiet) {
      if (!quiet) this.loadingTask = true
      this.$http.get(this.endpoint).then(response => {
        this.task = response.data
        this.setupSteps.map(el => {
          if (el.id in this.task.info) {
            el.isCompleted = true
          } else if (this.task.completed_at) {
            el.isCompleted = true
          }
          return el
        })
        this.isCompleted = this.task.completed_at !== null
        this.loadingTask = false
      })
    },
    onTaskReset () {
      this.$buefy.toast.open({ type: 'is-info', message: 'Task reset successfully.' })
      this.$router.push({ name: 'retailer.onboarding' })
    }
  },
  mounted () {
    this.loadTask()
  }
}
</script>
