<template>
  <div>
    <p class="has-text-weight-bold mb-sm">Ship From</p>
    <!-- Select Warehouse -->
    <div>
      <b-field>
        <b-select
          v-model="location"
          placeholder="Select Warehouse"
          :loading="loading"
          expanded
          size="is-medium"
          @input="announce">
          <option v-for="location in locations" :value="location" :key="location.id">
            {{ location.nickname }}
          </option>
        </b-select>
      </b-field>
      <div>
        <p v-if="location !== null" class="has-text-grey is-size-7">
          {{ location.address.street1 }}
          {{ location.address.street2 }}
          {{ location.address.city }}, {{ location.address.province }}
          {{ location.address.postal_code }}
        </p>
        <p v-else class="has-text-grey-light is-size-7">
          Select a location above...
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/locations/?type=warehouse'
    }
  },
  data () {
    return {
      loading: true,
      errors: [],
      locations: [],
      location: null
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint).then(response => {
        this.locations = response.data.results
        this.loading = false
        if (this.locations.length === 1) {
          this.location = this.locations[0]
        }
      })
    },
    announce () {
      this.$emit('input', this.location)
    }
  },
  mounted () {
    this.load()
  },
  watch: {
    location (value) {
      if (value !== null) {
        this.$emit('input', this.location)
      }
    }
  }
}
</script>
