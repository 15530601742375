<template>
  <div class="section">
    <div class="container">
      <!-- Intro -->
      <div>
        <div class="mb-lg">
          <div class="has-text-centered">
            <h1 class="is-size-4">Commerce Network Onboarding</h1>
            <p class="has-text-grey">Please review all tasks below to complete onboarding to the {{ app.platform }} Commerce Network.</p>
          </div>
        </div>
      </div>
      <!-- Tasks -->
      <div class="columns is-centered">
        <div class="column is-one-quarter-widescreen">
          <div class="has-background-white rounded-lg">
            <div class="has-gradient-primary has-text-centered pt-xxl pb-lg rounded-t-lg">
              <b-icon icon="account-box-outline" custom-class="has-text-white mdi-96px" />
            </div>
            <div class="pa-lg">
              <p class="is-size-5 has-text-weight-bold has-text-centered">Setup Brand Profile</p>
              <div class="content">
                <p class="mb-sm is-size-7 py-sm has-text-centered">
                  Share your brand with the Commerce Network by creating a profile
                  page with custom imagery, photos, & content.
                </p>
                <router-link class="button is-primary is-outlined is-medium rounded-lg is-fullwidth" :to="{ name: 'network.profile', params: {} }">Go</router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-one-quarter-widescreen">
          <div class="has-background-white rounded-lg">
            <div class="has-gradient-primary has-text-centered pt-xxl pb-lg rounded-t-lg">
              <b-icon icon="publish" custom-class="has-text-white mdi-96px" />
            </div>
            <div class="pa-lg">
              <p class="is-size-5 has-text-weight-bold has-text-centered">Publish Products</p>
              <div class="content">
                <p class="mb-sm is-size-7 py-sm has-text-centered">
                  Just in case, your products are NOT yet visibile to potential
                  partners. Please review your catalog and publish your products!
                </p>
                <router-link class="button is-primary is-outlined is-medium rounded-lg is-fullwidth" :to="{ name: 'network.products', params: {} }">Go</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Complete -->
      <div class="py-xl">
        <div class="columns is-centered">
          <div class="column is-one-third">
            <b-button
              type="is-primary rounded-lg"
              size="is-medium"
              @click="complete"
              expanded
              :loading="saving"
              :disabled="saving || profile.onboarded_at !== null"
            >Complete Onboarding</b-button>
          </div>
        </div>
        <p v-if="account !== null" class="has-text-centered pt-md">
          <router-link :to="{ name: 'shopify.onboard.account' }">Return to Shopify Onboarding</router-link>
        </p>
        <p v-else>&nbsp;</p>
      </div>
      <!-- Faqs -->
      <div class="py-lg">
        <h1 class="has-text-centered is-uppercase has-text-grey has-text-weight-bold mb-md">Supplier FAQs</h1>
        <div class="columns is-centered">
          <div class="column is-three-quarters">
            <supplier-faqs />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SupplierFaqs from './SupplierFaqs'
export default {
  components: {
    SupplierFaqs
  },
  data () {
    return {
      saving: false,
      loading: false,
      account: null
    }
  },
  computed: {
    ...mapGetters(['app', 'context']),
    endpoint () {
      return this.context.route + '/marketplaces/' + this.profile.id + '/'
    },
    json () {
      return {
        onboarded_at: this.$dates().format()
      }
    }
  },
  methods: {
    load () {
      this.loading = true
      const endpoint = this.context.route + '/platform-accounts/?platform=shopify'
      this.$http.get(endpoint).then(response => {
        if (response.data.results.length > 0) {
          this.account = response.data.results[0]
          this.loading = false
        }
      })
    },
    complete () {
      this.saving = true
      this.$http.patch(this.endpoint, this.json).then(response => {
        this.saving = false
        this.$buefy.toast.open({ type: 'is-success', message: 'Onboarding completed!' })
        this.$emit('update:profile')
      })
    }
  },
  mounted () {
    this.load()
  },
  props: ['profile']
}
</script>
