<template>
  <div class="section">
    <div class="container">
      <!-- Page Header -->
      <div class="mb-lg">
        <div class="columns is-vcentered">
          <div class="column is-one-third">
            <breadcrumbs :history="[{ label: 'Orders', name: 'orders.dashboard'}]" current="Replacements" />
          </div>
          <div class="column is-one-third">
            <h1 class="has-text-centered is-size-4">Replacement Orders</h1>
          </div>
        </div>
      </div>
      <!-- Filters Toolbar -->
      <div class="has-background-white rounded-lg pa-md mb-xxs">
        <div class="columns">
          <div class="column">
            <order-filters :applied-filters="filters" @filter="filter" />
          </div>
          <div class="column is-narrow">
            <b-select v-model="orderBy" size="is-medium" expanded @input="sort">
              <option value="-id">Most Recent (Received)</option>
              <option value="id">Oldest (Received)</option>
              <option value="-ordered_at">Most Recent (Placed)</option>
              <option value="ordered_at">Oldest (Placed)</option>
            </b-select>
          </div>
          <div class="column is-narrow">
            <b-button size="is-medium" class="is-fullwidth" icon-left="download" @click="showExportModal = !showExportModal">Export</b-button>
          </div>
        </div>
      </div>
      <!-- Actions Toolbar -->
      <div class="has-background-white rounded-lg px-md py-sm mb-xxs">
        <div class="columns is-vcentered is-mobile">
          <div class="column is-narrow">
            <b-dropdown :disabled="loading || selected.length === 0">
              <button class="button" slot="trigger" slot-scope="{ active }" :disabled="selected.length === 0">
                <span>Actions</span>
                <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
              </button>
              <template v-if="context.isSupplier()">
                <b-dropdown-item has-link><a href="#" @click.prevent="showAcceptOrdersModal = true">Accept Orders</a></b-dropdown-item>
                <b-dropdown-item has-link><a href="#" @click.prevent="showCancelOrdersModal = true">Cancel Orders</a></b-dropdown-item>
                <hr class="dropdown-divider">
                <!-- <b-dropdown-item has-link><a href="#" @click.prevent>Auto Generate Shipping Labels</a></b-dropdown-item> -->
                <b-dropdown-item has-link><a href="#" @click.prevent="showPrintLabelsModal = true">Print Shipping Labels</a></b-dropdown-item>
                <b-dropdown-item has-link><a href="#" @click.prevent="showPrintPackingSlipsModal = true">Print Packing Slips</a></b-dropdown-item>
              </template>
              <template v-if="context.isMerchant()">
                <b-dropdown-item has-link><a href="#" @click.prevent="showCancelOrdersModal = true">Cancel Orders</a></b-dropdown-item>
              </template>
            </b-dropdown>
          </div>
          <div class="column">
            <div class="has-text-right is-size-7">
              <p v-if="loading">Loading...</p>
              <p v-else>{{ total }} orders found.</p>
            </div>
          </div>
        </div>
      </div>
      <!-- Results -->
      <div>
        <div class="mb-md">
          <b-table
            :data="results"
            :checked-rows.sync="selected"
            :mobile-cards="false"
            :loading="loading"
            hoverable
            checkable
            checkbox-position="left"
            class="is-size-7">
            <template>
              <!-- PO # -->
              <b-table-column
                field="purchase_order_number"
                label="PO #"
                header-class="nowrap"
                cell-class="nowrap"
                width="128"
                v-slot="props">
                <router-link
                  class="has-text-weight-bold has-text-info"
                  :to="{ name: 'order.summary', params: { id: props.row.id} }"
                >{{ props.row.purchase_order_number }}</router-link>
              </b-table-column>
              <!-- Brand -->
              <b-table-column
                v-if="context.isMerchant()"
                label="Supplier"
                header-class="nowrap"
                cell-class="nowrap"
                v-slot="props">
                <span v-if="props.row.brand">{{ props.row.brands.name }}</span>
                <span v-else-if="props.row.brands[0]">{{ props.row.brands[0].name }}</span>
                <span v-else class="has-text-grey-light is-italic">Not Set</span>
              </b-table-column>
              <!-- Merchant -->
              <b-table-column
                v-if="context.isSupplier()"
                label="Merchant"
                header-class="nowrap"
                cell-class="nowrap"
                v-slot="props">
                {{ props.row.retailer.name }}
              </b-table-column>
              <!-- Ordered At -->
              <b-table-column
                style="white-space: nowrap"
                header-class="nowrap"
                label="Ordered"
                centered
                width="96"
                v-slot="props">
                {{ $dates.utc(props.row.received_at).local().format('MMM D') }}
              </b-table-column>
              <!-- Due Date -->
              <b-table-column
                style="white-space: nowrap"
                header-class="nowrap"
                label="Due"
                centered
                width="96"
                v-slot="props">
                {{ $dates.utc(props.row.fulfill_by).local().format('MMM D') }}
              </b-table-column>
              <!-- Progress -->
              <b-table-column
                label="Fulfilled"
                header-class="nowrap"
                cell-class="nowrap"
                centered
                width="128"
                v-slot="props">
                <a href="#" class="has-text-weight-bold has-text-info" @click.prevent="showOrderLines(props.row)">
                  <NumberFulfilled :lines="props.row.order_lines" />
                </a>
              </b-table-column>
              <!-- Service Level -->
              <b-table-column
                header-class="nowrap"
                cell-class="nowrap"
                centered
                label="Service"
                width="128"
                v-slot="props">
                <span v-if="props.row.shipping_method !== null">{{ props.row.shipping_method.generic_shipping_method.name }} </span>
                <span v-else-if="props.row.requested_shipping_method !== null">{{ props.row.requested_shipping_method.name }} </span>
                <span v-else class="has-text-grey is-italic">Not Set</span>
              </b-table-column>
              <!-- Status -->
              <b-table-column
                header-class="nowrap"
                cell-class="nowrap"
                centered
                label="Status"
                width="96"
                v-slot="props">
                <order-status :status="props.row.status" :due-date="props.row.fulfill_by" />
              </b-table-column>
              <!-- # Memos -->
              <b-table-column
                label=""
                header-class="nowrap"
                cell-class="nowrap"
                centered
                width="64"
                v-slot="props">
                <a href="#" @click.prevent="showMessages(props.row)">
                  <span v-if="props.row.unread_memos_count > 0" class="has-text-danger has-text-weight-bold">
                    <b-icon icon="comment-outline" size="is-small" />
                    {{ props.row.unread_memos_count }}
                  </span>
                  <span v-else>
                    <b-icon icon="comment-outline" size="is-small" />
                    {{ props.row.memos_count }}
                  </span>
                </a>
              </b-table-column>
            </template>
            <template #empty>
              <div class="py-xxl has-text-grey has-text-centered">
                {{ (loading) ? "Loading data..." : 'No orders found!' }}
              </div>
            </template>
          </b-table>
        </div>
        <b-pagination
          :total="total"
          :current.sync="currentPage"
          :rounded="true"
          :per-page="perPage"
          @change="paginate">
        </b-pagination>
      </div>

      <!-- Modal: Order Lines -->
      <b-modal
        has-modal-card
        scroll="keep"
        :active.sync="showOrderLinesModal"
        :can-cancel="['x']"
        @close="inspectOrder = null"
        ><OrderLinesModal :selectedOrder="inspectOrder" />
      </b-modal>

      <!-- Modal: Bulk Accept Orders -->
      <b-modal
        has-modal-card
        scroll="keep"
        :active.sync="showAcceptOrdersModal"
        :can-cancel="['x']"
        ><accept-orders-modal :orders="selected" @reload="reload"></accept-orders-modal>
      </b-modal>

      <!-- Modal: Cancel Orders -->
      <b-modal
        has-modal-card
        scroll="keep"
        :active.sync="showCancelOrdersModal"
        :can-cancel="['x']"
        @close="showCancelOrdersModal = false"
        ><cancel-orders-modal :orders="selected" @reload="reload"></cancel-orders-modal>
      </b-modal>

      <!-- Modal: Get Messages -->
      <b-modal
        has-modal-card
        scroll="keep"
        :active.sync="showMessagesModal"
        :can-cancel="['x']"
        @close="inspectOrder = null"
        ><messages-modal :selectedOrder="inspectOrder" ></messages-modal>
      </b-modal>
      <!-- Print Packing Slips -->
      <b-modal
        has-modal-card
        scroll="keep"
        :active.sync="showPrintPackingSlipsModal"
        :can-cancel="false"
        ><PrintPackingSlipsModal :orders="selected" @reload="reload" />
      </b-modal>
      <!-- Print Shipments -->
      <b-modal
        has-modal-card
        scroll="keep"
        :active.sync="showPrintLabelsModal"
        :can-cancel="false"
        ><PrintLabelsModal :orders="selected" @reload="reload" />
      </b-modal>

      <!-- Modal: Export  -->
      <b-modal
        has-modal-card
        scroll="keep"
        :active.sync="showExportModal"
        :can-cancel="['x']"
        ><div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Export Orders</p>
          </header>
          <section class="modal-card-body">
            <p class="pa-xl has-text-grey has-text-centered">
              Click 'Export' below to have an orders export delivered to your
              email. Please note that the orders export will only include
              the items that match your applied filters. Please double check
              your filters before exporting.
            </p>
          </section>
          <footer class="modal-card-foot" style="justify-content:flex-end">
            <b-button type="is-primary" @click="scheduleExport" :loading="exporting" :disabled="exporting">Export</b-button>
          </footer>
        </div>
      </b-modal>

    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import presets from '../presets/orders'
import AcceptOrdersModal from '../components/List/AcceptOrdersModal'
import CancelOrdersModal from '../components/List/CancelOrdersModal'
import Filterable from '@/mixins/Filterable'
import Exportable from '@/mixins/Exportable'
import MessagesModal from '../components/MessagesModal'
import OrderFilters from '../components/List/OrderFilters'
import OrderLinesModal from '@/components/Modals/Order/OrderLinesModal'
import PrintPackingSlipsModal from '../components/List/PrintPackingSlipsModal'
import PrintLabelsModal from '@/components/Modals/Shipment/PrintLabelsModal'
import OrderStatus from '@/components/Presenters/Order/OrderStatus'
import NumberFulfilled from '@/components/Presenters/Order/NumberFulfilled'

export default {
  components: {
    OrderFilters,
    // modals
    OrderLinesModal,
    AcceptOrdersModal,
    CancelOrdersModal,
    MessagesModal,
    PrintLabelsModal,
    PrintPackingSlipsModal,
    // presenters
    OrderStatus,
    NumberFulfilled
  },
  data () {
    return {
      key: 'replacements',
      // modals
      showAcceptOrdersModal: false,
      showCancelOrdersModal: false,
      showPrintLabelsModal: false,
      showPrintPackingSlipsModal: false,
      showOrderLinesModal: false,
      showMessagesModal: false,
      inspectOrder: null,
      // load presets
      presets: presets
    }
  },
  computed: {
    ...mapGetters(['context']),
    view () {
      return (this.context.isSupplier()) ? 'Suppliers' : 'Merchants'
    },
    endpoint () {
      return this.context.route + '/replacements/'
    },
    exportEndpoint () {
      return this.endpoint + 'email-export/'
    },
    fields () {
      const base = [
        'id',
        'purchase_order_number',
        'fulfill_by',
        'order_lines',
        'shipment_count',
        'invoice_count',
        'shipping_method',
        'requested_shipping_method',
        'status',
        'memos_count',
        'unread_memos_count',
        'order_batches',
        'received_at',
        'acknowledged_at'
      ]
      return (this.context.isSupplier())
        ? base.concat(['retailer']).join(',')
        : base.concat(['brands']).join(',')
    },
    alwaysAttach () {
      return {
        skip_attributes: 1,
        fields: this.fields
      }
    }
  },
  methods: {
    showOrderLines (order) {
      this.inspectOrder = order
      this.showOrderLinesModal = true
    },
    showMessages (order) {
      this.inspectOrder = order
      this.showMessagesModal = true
    },
    reload () {
      this.selected = []
      this.load()
    }
  },
  mounted () {
    this.load()
  },
  mixins: [Filterable, Exportable]
}
</script>
