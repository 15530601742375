<template>
  <div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Create Payment</p>
      </header>
      <section class="modal-card-body">
        <div class="mb-lg">
          <label class="label">Select Supplier</label>
          <ConnectionSelect v-model="connection" size="is-medium" />
        </div>
      </section>
      <footer class="modal-card-foot space-between">
        <b-button @click="$parent.close()">Cancel</b-button>
        <b-button type="is-primary" :disabled="!connection" :loading="saving" @click="save">Continue</b-button>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ConnectionSelect from '../../Connection/ConnectionSelect'
export default {
  components: {
    ConnectionSelect
  },
  computed: {
    ...mapGetters(['context'])
  },
  data () {
    return {
      connection: null,
      saving: false
    }
  },
  methods: {
    save () {
      this.saving = true
      const endpoint = this.context.route + '/payments/'
      const json = {
        brand_id: this.connection.brand.id,
        status: 'draft'
      }
      this.$http.post(endpoint, json).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Payment created!' })
        // Navigate to invoices list
        this.$router.push({ name: 'payment.detail.invoices.list', params: { id: response.data.id } })
      }).catch(() => {
        this.$buefy.toast.open({ type: 'is-danger', message: 'There was a problem submitting your request. Please check your input and try again.' })
      }).finally(() => {
        this.saving = false
        this.$parent.close()
      })
    }
  },
  mixins: [],
  mounted () {
  },
  props: ['']
}
</script>
