<template>
  <div>
    <div class="columns is-vcentered is-mobile">
      <div v-if="showImage && imageUrl" class="column is-narrow is-hidden-mobile">
        <!-- Image can be clicked -->
        <a v-if="imageInteraction !== 'none'"
          href="#"
          @click.prevent="handleImageInteraction">
          <figure class="image is-48x48">
            <img :src="imageUrl" class="rounded-lg" />
          </figure>
        </a>
        <!-- Image does Nothing -->
        <figure v-else class="image is-48x48">
          <img :src="imageUrl" class="rounded-lg" />
        </figure>
      </div>
      <div class="column">
        <div class="is-size-7">
          <!-- Name can be inspected in modal -->
          <a v-if="nameInteraction === 'inspect'"
            href="#"
            class="has-text-info has-text-weight-bold"
            @click.prevent="handleNameInteraction"
          >{{ (name) ? $utils.truncate(name, { length: 64 }) : 'Not Set' }}</a>
          <!-- Name should navigate to detail page -->
          <router-link
            v-else-if="nameInteraction === 'navigate'"
            :to="{ name: 'catalog.item', params: { productId: variant.product_id, variantId: variant.id } }"
            class="has-text-info has-text-weight-bold">
            {{ $utils.truncate(name, { length: 64 }) }}
          </router-link>
          <!-- Name does nothing -->
          <p v-else>{{ (name) ? name : 'Not Set' }}</p>
        </div>
        <!-- For retailers, try to show merchant sku then upc -->
        <template v-if="context.isMerchant()">
          <p v-if="merchantSku !== null" class="is-size-7">
            <span class="is-size-8 has-text-grey">Merchant SKU </span>
            <span class="has-text-weight-bold">{{ merchantSku }}</span>
          </p>
          <p v-else-if="upc !== null" class="is-size-7">
            <span class="is-size-8 has-text-grey">UPC </span>
            <span class="has-text-weight-bold">{{ upc }}</span>
          </p>
          <p v-else class="is-size-7">
            <span class="is-size-8 has-text-grey">Merchant SKU </span>
            <span class="has-text-weight-bold is-italic">Not Set</span>
          </p>
        </template>
        <!-- For brands, only show the supplier sku -->
        <template v-else>
          <p class="is-size-7">
            <span class="is-size-8 has-text-grey">Supplier SKU </span>
            <span class="is-size-7 has-text-weight-bold">{{ supplierSku }}</span>
          </p>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CdnTransform from '@/mixins/CdnTransform'
export default {
  computed: {
    ...mapGetters(['context']),
    name () {
      if (
        this.context.isMerchant() &&
        this.variant.retailer_identifiers.length > 0 &&
        this.variant.retailer_identifiers[0].name !== null
      ) {
        return this.variant.retailer_identifiers[0].name
      }
      return this.variant.name
    },
    supplierSku () {
      return this.variant.brand_identifier
    },
    merchantSku () {
      return (this.variant.retailer_identifiers.length > 0)
        ? this.variant.retailer_identifiers[0].identifier
        : null
    },
    upc () {
      return (this.variant.upc) ? this.variant.upc : null
    },
    imageUrl () {
      if (this.showImage === false) return null
      if ('media' in this.variant && this.variant.media.length > 0) {
        return this.cdnTransform(this.variant.media[0].url, 48, 48, 'fill')
      }
      return null
    }
  },
  methods: {
    handleImageInteraction () {
      if (this.imageInteraction === 'navigate') {
        this.$emit('navigate', this.product)
      } else if (this.imageInteraction === 'inspect') {
        this.$emit('inspect', this.product)
      }
    },
    handleNameInteraction () {
      if (this.nameInteraction === 'navigate') {
        this.$emit('navigate', this.variant)
      } else if (this.nameInteraction === 'inspect') {
        this.$emit('inspect', this.variant)
      }
    }
  },
  mixins: [CdnTransform],
  props: {
    variant: {
      type: Object,
      required: true
    },
    showImage: {
      type: Boolean,
      default: true
    },
    showMerhantSku: {
      type: Boolean,
      default: false
    },
    nameInteraction: {
      type: String,
      validator: (val) => ['navigate', 'inspect', 'none'].includes(val),
      default: 'navigate'
    },
    imageInteraction: {
      type: String,
      validator: (val) => ['navigate', 'inspect', 'none'].includes(val),
      default: 'inspect'
    }
  }
}
</script>
