<template>
  <div class="section">
    <div class="container">
      <!-- Loading Skeleton -->
      <div v-if="loading">
        <loading message="Loading..." />
      </div>
      <!-- Task is loaded -->
      <div v-else>
        <!-- Header -->
        <div class="mb-sm">
          <div class="columns is-vcentered">
            <div class="column is-one-third">
              <breadcrumbs :history="[{ label: 'Onboarding', name: 'retailer.onboarding'}]" current="Business Information" />
            </div>
            <div class="column is-one-third is-size-4 has-text-centered">
              Business Information
            </div>
          </div>
        </div>
        <div class="columns is-centered">
          <div class="column is-three-quarters">
            <div class="columns">
              <div class="column is-one-third">
                <div v-for="(step, index) in setupSteps"
                  :key="step.id"
                  :class="[step.path === currentPathName && 'has-background-grey-lighter', index === 0 && 'rounded-t-lg', index === 4 && 'rounded-b-lg', index !== 4 && 'has-border-b']"
                  class="has-background-white pa-md">
                  <router-link :to="{ name: step.path }" class="has-text-black">
                    <div class="columns is-vcentered is-mobile">
                      <div class="column is-narrow">
                        <b-icon v-if="step.isCompleted" icon="check" type="is-success"></b-icon>
                        <b-icon v-else icon="check" custom-class="has-text-grey-lighter"></b-icon>
                      </div>
                      <div class="column">{{step.label}}</div>
                      <div class="column is-narrow">
                        <b-icon icon="chevron-right"></b-icon>
                      </div>
                    </div>
                  </router-link>
                </div>
                <div class="is-size-7 has-text-grey-light mt-lg">
                  <div class="columns">
                    <div class="column">
                      <router-link :to="{ name:'retailer.onboarding' }" class="has-text-grey">&lt; Back to Tasks</router-link>
                    </div>
                    <div class="column">
                      <p class="has-text-right">
                        <a v-if="auth.isStaff && isCompleted" href="#" @click="showResetTaskModal=true" class="has-text-grey">Reset Task </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="pa-lg ml-md has-background-white rounded-lg">
                  <router-view
                    :task="task"
                    :isUpdatingTask="isUpdatingTask"
                    :account="account"
                    @load:account="loadAccount(true)"
                    @task:updated="(body, path, reload)=>updateTaskInfo(body, path, reload)"
                  ></router-view>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Reset Task Modal -->
      <b-modal
        v-if="!loading && task !== null"
        :has-modal-card="true"
        :active.sync="showResetTaskModal"
        :can-cancel="['x', 'esc']">
        <ResetTaskModal :taskId="task.id" @task:reset="onTaskReset" />
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ResetTaskModal from '@/components/Models/Task/Modals/ResetTaskModal'
export default {
  components: {
    ResetTaskModal
  },
  computed: {
    ...mapGetters(['app', 'auth', 'context']),
    currentPathName () {
      return this.$route.name
    },
    endpoint () {
      return this.context.route + '/assigned-tasks/' + this.$route.params.task + '/'
    },
    loading () {
      return (this.loadingAccount || this.loadingTask)
    }
  },
  data () {
    return {
      loadingAccount: true,
      loadingTask: true,
      isUpdatingTask: false,
      task: null,
      isCompleted: false,
      showResetTaskModal: false,
      account: null,
      setupSteps: [
        {
          id: 'accountInformation',
          label: 'Account',
          path: 'retailer.onboarding.business-information',
          isCompleted: false
        },
        {
          id: 'primaryBusinessAddress',
          label: 'Primary Address',
          path: 'retailer.onboarding.business-address',
          isCompleted: false
        },
        {
          id: 'contact',
          label: 'Contacts',
          path: 'retailer.onboarding.contact-persons',
          isCompleted: false
        },
        {
          id: 'logo',
          label: 'Branding',
          path: 'retailer.onboarding.branding-logo',
          isCompleted: false
        },
        {
          id: 'storeCurrency',
          label: 'Currency',
          path: 'retailer.onboarding.store-currency',
          isCompleted: false
        }
      ]
    }
  },
  methods: {
    updateTaskInfo (taskBody, nextPath, reload) {
      this.isUpdatingTask = true
      this.$http.patch(this.endpoint, taskBody).then(response => {
        this.task = response.data
        this.setupSteps.map(el => {
          if (el.id in this.task.info) {
            el.isCompleted = true
          }
          return el
        })
        if (reload) {
          this.loadAccount(true)
        }
      }).finally(() => {
        this.isUpdatingTask = false
        if (nextPath) {
          this.$router.push({ name: nextPath, params: { task: this.$route.params.task } })
        }
      })
    },
    loadTask (quiet) {
      if (!quiet) this.loadingTask = true
      this.$http.get(this.endpoint).then(response => {
        this.task = response.data
        this.setupSteps.map(el => {
          if (el.id in this.task.info) {
            el.isCompleted = true
          } else if (this.task.completed_at) {
            el.isCompleted = true
          }
          return el
        })
        this.isCompleted = this.task.completed_at !== null
        this.loadingTask = false
      })
    },
    loadAccount (silent = false) {
      if (!silent) this.loadingAccount = true
      const endpoint = this.context.route + '/'
      this.$http.get(endpoint).then(response => {
        this.account = response.data
        this.loadingAccount = false
      })
    },
    onTaskReset () {
      this.$buefy.toast.open({ type: 'is-info', message: 'Task reset successfully.' })
      this.$router.push({ name: 'retailer.onboarding' })
    }
  },
  mounted () {
    this.loadTask()
    this.loadAccount()
  }
}
</script>
