<template>
<div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Add Credits</p>
    </header>
    <section class="modal-card-body">
      Are you sure you want to add the <b>{{this.selectedCredits.length}}</b> selected credits to the payment #{{this.payment.id}}?
    </section>
    <footer class="modal-card-foot space-between">
      <b-button @click="$parent.close()">Close</b-button>
      <b-button
        :disabled="saving"
        type="is-primary"
        @click="save"
      >Save</b-button>
    </footer>
  </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['context'])
  },
  data () {
    return {
      saving: false
    }
  },
  methods: {
    save () {
      this.saving = true
      const json = {
        credit_memo_ids: this.selectedCredits.map(el => {
          return el.id
        })
      }
      const endpoint = this.context.route + '/payments/' + this.$route.params.id + '/add-credits/'
      this.$http.put(endpoint, json).then(response => {
        if (response.data.success_credits.length) {
          this.$emit('reload:payment')
          this.$router.push({ name: 'payment.detail.credits', params: { id: this.payment.id } })
        } else if (response.data.failure_credits.length) {
          this.$buefy.toast.open({ type: 'is-danger', message: 'There was a problem adding credits to the payment. Please try again or contact support.' })
        }
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
  },
  props: ['payment', 'selectedCredits']
}
</script>
