<template>
<div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Adjustment Details</p>
    </header>
    <section class="modal-card-body">
      <b-field label="What kind of adjustment you want to make?">
        <b-select v-model="adjustmentKind" size="is-medium" expanded placeholder="Select an option" required @input="change">
          <!-- add this into the total -->
          <option value="+">Provide a credit to supplier</option>
          <!-- subtract this from the total -->
          <option value="-">Add a fee or charge to supplier</option>
        </b-select>
      </b-field>
      <!-- Amount -->
      <b-field label="Amount">
        <p class="control"><span class="button is-static is-medium">$</span></p>
        <b-input type="number" size="is-medium" v-model="amount" @input="change" expanded></b-input>
      </b-field>
      <!-- Description Value -->
      <b-field label="Description">
        <b-input type="textarea"
          v-model="description"
          maxlength="2048"
          expanded
          @input="change"/>
      </b-field>
      <!-- Delete Value -->
      <b-field v-if="adjustment !== null" class="mt-md">
        <b-checkbox v-model="deleteAdjustment" @input="change">Delete?</b-checkbox>
      </b-field>
    </section>
    <footer class="modal-card-foot space-between">
      <b-button @click="$parent.close()">Close</b-button>
      <b-button
        :disabled="!isReady"
        :loading="saving"
        type="is-primary"
        @click="save"
      >Save</b-button>
    </footer>
  </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/payments/' + this.$route.params.id + '/'
    },
    isReady () {
      return (
        this.isDirty &&
        this.amount !== null &&
        this.adjustmentKind !== null
      )
    }
  },
  data () {
    return {
      amount: null,
      description: null,
      deleteAdjustment: false,
      adjustmentKind: null,
      isDirty: false
    }
  },
  methods: {
    change () {
      this.isDirty = true
    },
    save () {
      const json = {
        amount: this.adjustmentKind === '-' ? this.adjustmentKind + this.amount : this.amount,
        description: this.description
      }
      if (this.deleteAdjustment) {
        this.$emit('delete:adjustment', this.adjustment.id)
      } else {
        this.$emit('update:adjustment', json)
      }
    }
  },
  mounted () {
    if (this.adjustment) {
      this.amount = this.adjustment.amount.includes('-') ? this.adjustment.amount.substring(1) : this.adjustment.amount
      this.description = this.adjustment.description
      this.adjustmentKind = this.adjustment.amount.includes('-') ? '-' : '+'
    }
  },
  props: {
    adjustment: {
      type: Object,
      default: null
    },
    payment: {
      type: Object,
      required: true
    },
    saving: {
      type: Boolean,
      default: false
    }
  }
}
</script>
