<template>
  <div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">
          <span>Integration Configurations</span>
        </p>
      </header>
      <section class="modal-card-body">
       <!-- Accounts Found -->
        <div v-if="!loading && accounts !== null && accounts.length > 0" class="mb-lg">
          <div class="rows">
            <div class="row" v-for="account in accounts" :key="account.id">
              <p class="has-text-primary has-text-weight-bold pb-sm has-border-b has-border-lg">{{account.name}}</p>
              <table class="table is-fullwidth is-striped is-size-7 is-narrow mb-lg">
                <tbody>
                  <tr v-for="credential in account.credentials" :key="credential.id">
                    <th>{{ credential.type }}</th>
                    <td class="has-text-right">{{credential.value}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!-- Accounts Not Found -->
        <div v-else-if="!loading && (results === null || results.length === 0 )">
          <p>Accounts could not be found.</p>
        </div>
        <!-- Accounts Loading -->
        <div v-else class="py-lg">
          <loading />
        </div>
      </section>
      <footer class="modal-card-foot">
        <button class="button" type="button" @click="$parent.close()">Close</button>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/connections/' + this.connection.id + '/platform-accounts/'
    },
    accounts () {
      if (this.results) {
        return this.results.map(result => {
          return {
            name: result.platform.name,
            id: result.platform.id,
            credentials: result.credentials.reduce((creds, credential) => {
              // We pick the first key from Object.keys because the credential object has only one key
              const credentialKey = Object.keys(credential)[0]
              if (credentialKey) { // guard against an empty credential object
                const valueString = credential[credentialKey].values.reduce((valString, current, idx) => {
                  if (credential[credentialKey].datatype === 'boolean') {
                    valString += `${current.value === '1' ? 'Yes' : 'No'}`
                  } else {
                    valString += `${idx === 0 ? '' : ', '}${current.value}`
                  }
                  return valString
                }, '')
                creds.push({
                  type: credential[credentialKey].label,
                  value: valueString,
                  id: credential[credentialKey].id
                })
              }
              return creds
            }, [])
          }
        })
      }

      return null
    }
  },
  data () {
    return {
      loading: false,
      results: null
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint).then(response => {
        if (response && response.data && response.data.results && response.data.results.length) {
          this.results = response.data.results
        }
      })
      // Close the modal in case of error, the error is handled globally by another modal
        .catch(err => {
          console.log(err)
          this.$parent.close()
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  mounted () {
    this.load()
  },
  props: ['connection']
}
</script>
