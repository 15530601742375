<template>
  <div>
    <!-- Intro -->
    <div class="content">
      <p class="mb-md has-text-weight-bold is-size-5 has-text-centered">How did we do?</p>
      <p>
        We hope we've been able to provide you with the support and guidance that you need
        to get onboarded with {{ connection.retailer.name }} via {{ app.productName }}.
      </p>
      <p>
        We're constantly striving to provide the best product and service
        to suppliers on {{ app.productName }}, and your feedback is so valuable.
        Please let us know how we're doing by answering a couple of quick questions.
      </p>
      <p>Click or tap 'Get Started'.</p>
    </div>
    <!-- Navigate -->
    <div class="py-lg">
      <div class="columns is-centered">
        <div class="column">
          <b-button
            type="is-light rounded-lg"
            size="is-medium"
            @click="goBack"
            expanded
            ><b-icon icon="arrow-left"></b-icon>
          </b-button>
        </div>
        <div class="column">
          <b-button
            type="is-primary rounded-lg"
            size="is-medium"
            expanded
            @click="goForward"
          >Get Started</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {

  },
  computed: {
    ...mapGetters(['app'])
  },
  data () {
    return {

    }
  },
  methods: {
    goBack () {
      this.$router.push({ name: 'onboarding.tasks', params: { connection: this.connection.id } })
    },
    goForward () {
      this.$emit('navigate', 'Support')
    }
  },
  props: {
    connection: {
      type: Object,
      required: true
    },
    task: {
      type: Object,
      required: true
    }
  }
}
</script>
