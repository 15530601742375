<template>
  <form @submit.prevent="save">
    <div class="columns">
      <div class="column">
        <div class="has-background-white rounded-xl">
          <!-- Loaded -->
          <div class="pa-lg pb-xl">
            <div class="mb-lg">
              <p class="has-text-weight-bold mb-xs">Packing Slip Template</p>
              <div class="has-text-grey is-size-7">
                <p class="mb-md">
                  Including a {{ context.partner.name }}-branded packing slip
                  is often an important component of the customer experience. The
                  default packing slip will include your logo and core order data
                  including the customer order number. If you have
                  worked with {{ app.platform }} Support to configure a custom
                  packing slip, please provide the template name.
                  </p>
              </div>
            </div>
            <div class="columns">
              <div class="column is-half">
                <p class="has-text-weight-bold mb-md">Default Packing Slip Template</p>
                <b-field>
                  <b-input
                    type="text"
                    size="is-medium"
                    v-model="value"
                    @input="isDirty = 1"
                    placeholder="generic_rcn_packing_slip.html"
                  ></b-input>
                </b-field>
              </div>
            </div>
          </div>
          <div class="border-t">
            <div class="columns is-vcentered is-marginless">
              <div class="column is-hidden-mobile">
                <div class="px-sm has-text-grey-light">
                  <i v-if="isDirty">Click 'Save Preference' to save your changes</i>
                </div>
              </div>
              <div class="column is-narrow">
                <div class="px-sm">
                  <b-button
                    native-type="submit"
                    type="is-primary rounded-lg"
                    :disabled="!ready || saving || !isDirty"
                  >Save Preference</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  computed: {
    ...mapGetters(['app', 'context']),
    endpoint () {
      return this.context.route + '/retailer-preferences/'
    },
    json () {
      return {
        preference: { id: this.preference.id },
        value: this.value
      }
    },
    ready () {
      return (
        this.value !== null
      )
    }
  },
  data () {
    return {
      isDirty: false,
      preference: null,
      value: null,
      errors: [],
      saving: false
    }
  },
  methods: {
    save () {
      this.saving = true
      this.$http.post(this.endpoint, this.json).then(response => {
        this.isDirty = false
        this.$buefy.toast.open({ type: 'is-success', message: 'Preference updated!' })
      }).catch(error => {
        if (error.response && error.response.status === 400) {
          this.errors = error.response.data
        }
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    const preference = this.$utils.find(this.preferences, ['code', 'default_packing_slip_template'])
    if (typeof preference !== 'undefined') {
      this.preference = preference
      if (this.preference.retailer_preference) {
        this.value = preference.retailer_preference.value
      }
    }
  },
  props: ['preferences', 'retailerPreferences']
}
</script>
