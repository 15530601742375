<template>
  <div>
    <div class="columns is-multiline">
      <div class="column is-half">
        <b-field label="UPS Account Number">
          <b-input
            type="text"
            v-model="accountNumber"
            size="is-medium"
            @input="announce"
          ></b-input>
        </b-field>
      </div>
      <div class="column is-half">
        <b-field label="UPS Access License Number">
          <b-input
            type="text"
            v-model="accessLicenseNumber"
            size="is-medium"
            @input="announce"
          ></b-input>
        </b-field>
      </div>
      <div class="column is-half">
        <b-field label="UPS.com Username">
          <b-input
            type="text"
            v-model="upsUsername"
            size="is-medium"
            @input="announce"
          ></b-input>
        </b-field>
      </div>
      <div class="column is-half">
        <b-field label="UPS.com Password">
          <b-input
            type="password"
            v-model="upsPassword"
            size="is-medium"
            password-reveal
            @input="announce"
          ></b-input>
        </b-field>
      </div>
      <div class="column is-half">
        <b-field label="Billing Country">
          <b-select
            v-model="billingCountry"
            size="is-medium"
            @input="announce"
            expanded>
            <option value="US">United States</option>
            <option value="CA">Canada</option>
          </b-select>
        </b-field>
      </div>
      <div class="column is-half">
        <b-field label="Billing Postal Code">
          <b-input
            type="text"
            v-model="billingPostalCode"
            size="is-medium"
            @input="announce"
          ></b-input>
        </b-field>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    endpoint () {
      return this.context.route + '/shippingaccounts/easypost-child-account/'
    },
    valid () {
      return (
        this.accountNumber &&
        this.accessLicenseNumber &&
        this.upsUsername &&
        this.upsPassword &&
        this.billingCountry &&
        this.billingPostalCode
      )
    },
    json () {
      return {
        carrier: {
          code: 'UPSN'
        },
        credentials: {
          account_number: this.accountNumber,
          access_license_number: this.accessLicenseNumber,
          user_id: this.upsUsername,
          password: this.upsPassword,
          billing_country: this.billingCountry,
          billing_postal_code: this.billingPostalCode
        },
        test_credentials: {}
      }
    }
  },
  data () {
    return {
      accountNumber: null,
      accessLicenseNumber: null,
      upsUsername: null,
      upsPassword: null,
      billingCountry: 'US',
      billingPostalCode: null
    }
  },
  methods: {
    announce () {
      if (this.valid) {
        this.$emit('input', this.json)
      }
    }
  }
}
</script>
