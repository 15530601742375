<template>
  <form @submit.prevent="save">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Start Return</p>
      </header>
      <section class="modal-card-body">
        <b-message v-if="'non_field_errors' in errors" type="is-danger">
          {{ errors.non_field_errors[0] }}
        </b-message>
        <b-message v-else type="is-info">Complete the form below to begin a new return.</b-message>
        <!-- Reason -->
        <b-field
          label="Reason"
          :type="('reason_id' in errors) ? 'is-danger' : ''"
          :message="('reason_id' in errors) ? errors['reason_id'][0] : ''"
          expanded>
            <b-select
              v-model="reason"
              :loading="loading"
              placeholder="Select Reason"
              expanded>
              <option v-for="option in reasonOptions" :key="option.id" :value="option.id">{{ option.description }}</option>
            </b-select>
        </b-field>
        <!-- RMA # -->
        <b-field
          label="RMA # (Optional)"
          :type="('rma_number' in errors) ? 'is-danger' : ''"
          :message="('rma_number' in errors) ? errors['rma_number'][0] : ''"
          expanded>
            <b-input
              autocomplete="off"
              type="text"
              v-model="rmaNumber"
            ></b-input>
        </b-field>
        <!-- Memo -->
        <div class="mb-md">
          <b-field
            label="Note"
            :type="('memo' in errors) ? 'is-danger' : ''"
            :message="('memo' in errors) ? errors['memo'][0] : ''"
            expanded>
              <b-input
                autocomplete="off"
                type="textarea"
                v-model="memo"
              ></b-input>
          </b-field>
          <p class="is-size-7 has-text-grey">Add a tracking number or other info.</p>
        </div>
        <!-- Memo -->
        <div>
          <label class="label">Return Items</label>
          <b-table
            class="is-size-7"
            :data="order.order_lines"
            :mobile-cards="false">
            <template>
              <!-- Name -->
              <b-table-column
                label="Item"
                cell-class="nowrap"
                v-slot="props">
                <p>{{ $utils.truncate(props.row.variant.name, { length: 64 }) }}</p>
                <p v-if="context.isMerchant() && props.row.variant.retailer_identifiers.length > 0">
                  <span class="is-size-8 has-text-grey-light">Merchant SKU </span>
                  <span class="has-text-weight-bold">{{ props.row.variant.retailer_identifiers[0].identifier }}</span>
                </p>
                <p v-else>
                  <span class="is-size-8 has-text-grey-light">Supplier SKU </span>
                  <span class="has-text-weight-bold">{{ props.row.variant.brand_identifier }}</span>
                </p>
              </b-table-column>
              <!-- Shipped -->
              <b-table-column
                label="# Eligible"
                cell-class="nowrap"
                width="96"
                centered
                v-slot="props">
                <p>{{ props.row.quantity_shipped - props.row.quantity_canceled - props.row.quantity_returned }}</p>
              </b-table-column>
              <!-- Returning -->
              <b-table-column
                label="# Returning"
                cell-class="nowrap"
                centered
                width="192"
                v-slot="props">
                <b-numberinput
                  min="0"
                  :disabled="props.row.quantity_shipped - props.row.quantity_canceled - props.row.quantity_returned === 0"
                  :max="props.row.quantity_shipped - props.row.quantity_returned"
                  @input="trackLines(props.row, $event)"
                ></b-numberinput>
              </b-table-column>
            </template>
          </b-table>
        </div>
      </section>
      <footer class="modal-card-foot space-between">
        <button class="button" type="button" @click="$parent.close()">Close</button>
        <b-button
          type="is-primary"
          native-type="submit"
          :loading="saving"
          :disabled="!ready || saving">
          Start Return
        </b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  data () {
    return {
      reason: null,
      rmaNumber: null,
      memo: null,
      saving: false,
      generalError: null,
      errors: {},
      returnLines: {},
      reasonOptions: [],
      loading: false
    }
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/rmas/'
    },
    ready () {
      return (
        this.order.id &&
        this.reason &&
        Object.keys(this.returnLines).length > 0
      )
    },
    json () {
      const json = {
        order_id: this.order.id,
        reason_id: this.reason,
        memos: [],
        rma_lines: []
      }
      // optional fields
      if (this.rmaNumber) json.rma_number = this.rmaNumber
      if (this.memo) json.memos.push({ text: this.memo })
      // return lines
      for (const id in this.returnLines) {
        if (this.returnLines[id] > 0) {
          json.rma_lines.push({
            variant: { id: parseInt(id) },
            quantity: this.returnLines[id]
          })
        }
      }
      return json
    }
  },
  methods: {
    save () {
      this.saving = true
      this.$http.post(this.endpoint, this.json).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Return registered!' })
        this.$emit('rma:created', response.data)
        this.$parent.close()
      }).catch(error => {
        this.$buefy.toast.open({ type: 'is-danger', message: 'An error occured! Please check your input and try again!' })
        this.errors = (error.response.data) ? error.response.data : { detail: 'An unexpected error occured!' }
      }).finally(() => {
        this.saving = false
      })
    },
    trackLines (line, value) {
      this.$set(this.returnLines, line.variant.id, value)
    },
    getReturnReasons () {
      this.loading = true
      this.$http.get(this.endpoint + 'reasons/').then(response => {
        this.reasonOptions = response.data
      }).catch(error => {
        if (error.response) {
        // if we get an error, fall back to hardcoded values
          this.reasonOptions = [{ id: '1', description: 'Customer Disposition' },
            { id: '2', description: 'Item did not meet customer expectations' },
            { id: '3', description: 'Item was damaged or defective' },
            { id: '4', description: 'Delivery problem (e.g. customer refused or undeliverable)' }]
        }
      }).finally(() => {
        this.loading = false
      })
    }
  },
  mounted () {
    this.getReturnReasons()
  },
  props: ['order']
}
</script>
