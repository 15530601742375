<template lang="html">
  <div class="has-text-centered has-text-grey-light" :class="padding">
    <p class="py-md"><b-icon icon="loading" custom-class="mdi-spin" :size="size"></b-icon></p>
    <p>{{ message }}</p>
  </div>
</template>

<script>
export default {
  computed: {
    padding () {
      if (this.size === 'is-small') return 'py-sm'
      if (this.size === 'is-medium') return 'py-md'
      return 'py-lg'
    }
  },
  props: {
    message: {
      type: String,
      default: 'Loading...'
    },
    size: {
      type: String,
      default: 'is-large'
    }
  }
}
</script>
