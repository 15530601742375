<template>
  <div>
    <div>
      <div class="columns">
        <div class="column is-one-fifth">
          <p class="has-text-weight-bold is-size-6 mb-sm">Fulfillment Locations</p>
          <p class="has-text-grey is-size-7">Manage the locations you stock inventory & fulfill orders.</p>
        </div>
        <div class="column">
          <div class="has-background-white rounded-xl">
            <div class="px-lg">
              <!-- Loading Billing Locations -->
              <div v-if="loading">
                <div class="pa-md" v-for="index in 1" :key="index">
                  <div class="placeholder placeholder-title"></div>
                  <div class="placeholder placeholder-sentence" style="width:50%"></div>
                </div>
              </div>
              <!-- None Found -->
              <div v-else-if="!loading && locations.length ===0">
                <div class="pa-xxl has-text-centered">
                  <p>No locations found. Click "Add Location" below to get started!</p>
                </div>
              </div>
              <!-- Locations Loaded (or not) -->
              <div v-else>
                <div v-for="(location, index) in locations" :key="location.id" class="py-lg" :class="(index !== 0) ? 'border-t' : ''">
                  <div class="columns is-vcentered">
                    <div class="column">
                      <p class="has-text-weight-bold">{{ location.nickname }}</p>
                      <p class="has-text-grey">
                        {{ location.address.street1 }}
                        {{ location.address.street2 }}
                        {{ location.address.city }},
                        {{ location.address.province }}
                        {{ location.address.postal_code }}
                      </p>
                    </div>
                    <div class="column is-narrow">
                      <a href="#" @click.prevent="edit(location)" >Edit</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="border-t">
              <div class="columns is-vcentered is-marginless">
                <div class="column has-text-right">
                  <div class="px-sm">
                    <b-button native-type="submit" type="is-primary rounded-lg" :disabled="loading" @click="add">Add Location</b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Create/Edit Modal -->
    <b-modal :active.sync="showLocationModal"
      has-modal-card
      @close="location = null">
      <InventoryLocationModal :location="location" @updated="load" />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import InventoryLocationModal from './InventoryLocationModal'
export default {
  components: {
    InventoryLocationModal
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/locations/?type=warehouse&limit=5'
    }
  },
  data () {
    return {
      loading: true,
      showLocationModal: false,
      location: null,
      locations: [],
      isDirty: false,
      inventoryPolicy: null,
      errors: [],
      updating: false
    }
  },
  methods: {
    add () {
      this.location = null
      this.showLocationModal = true
    },
    edit (location) {
      this.location = location
      this.showLocationModal = true
    },
    load () {
      this.loading = true
      this.$http.get(this.endpoint).then(response => {
        this.locations = response.data.results
        this.loading = false
      })
    }
  },
  mounted () {
    this.load()
  }
}
</script>
