<template>
  <div>
    <div v-if="proposal">
      <div class="columns is-vcentered is-mobile">
        <div class="column">
          <p class="has-text-weight-bold has-text-grey">{{ proposal.name }}</p>
          <p class="is-size-7">
            <TypePresenter :type="proposal.proposal_type" />
            &bull;
            <span class="has-text-grey-light">{{ proposal.connection.retailer.name }}</span>
            &bull;
            <span class="has-text-weight-bold">{{ (numActiveItems === 1) ? '1 Item' : numActiveItems + ' Items' }}</span>
            <span class="has-text-grey-light"> ({{ totalItems }} Total Items)</span>
          </p>
        </div>
        <div class="column is-narrow has-text-right">
          <p class="is-size-5"><StatusPresenter :proposal="proposal" /></p>
          <p class="has-text-grey-light">#{{ proposal.id }}</p>
        </div>
      </div>
    </div>
    <!-- Loading -->
    <div v-else>
      <b-skeleton height="25" width="50%" />
      <b-skeleton />
      <b-skeleton width="25%"/>
    </div>
  </div>
</template>

<script>
import StatusPresenter from '@/components/Models/Proposal/Presenters/Status'
import TypePresenter from '@/components/Models/Proposal/Presenters/Type'

export default {
  components: {
    StatusPresenter,
    TypePresenter
  },
  computed: {
    numActiveItems () {
      return parseInt(this.proposal.item_count)
    },
    totalItems () {
      return parseInt(this.proposal.item_count) + parseInt(this.proposal.item_rejected_count)
    }
  },
  props: ['proposal', 'loading']
}
</script>
