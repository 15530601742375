export const dataType = {
  label: 'Data Type',
  key: 'data_type',
  icon: 'pulse',
  tooltip: 'Limit results based on the template data type.',
  options: [{
    value: 'products',
    label: 'Products'
  }, {
    value: 'inventory',
    label: 'Inventory'
  }]
}
