<template>
  <div>
    <slot :record="record" :seed="seed" :loading="loading"></slot>>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  data () {
    return {
      loading: false,
      record: null
    }
  },
  computed: {
    ...mapGetters(['context'])
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint, this.query).then(response => {
        this.record = response.data
        this.loading = false
      })
    }
  },
  mounted () {
    this.loading = (this.loadDetail)
    this.record = (this.loadDetail) ? this.load() : this.seed
  },
  props: {
    seed: {
      default: null
    },
    endpoint: {
      default: null
    },
    query: {
      default: null
    },
    loadDetail: {
      type: Boolean,
      default: false
    }
  }
}
</script>
