<template>
  <div>
    <transition name="fade" mode="out-in">
      <component :is="step" :account="account" @change:step="goto" @complete="complete" ></component>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Locations from '../../components/Inventory/Locations'
import Sync from '../../components/Inventory/Sync'

export default {
  components: {
    Locations,
    Sync
  },
  data () {
    return {
      loading: true,
      step: 'locations',
      errors: {}
    }
  },
  computed: {
    ...mapGetters(['api', 'assets', 'auth', 'context']),
    endpoint () {
      return this.context.route + '/shopify/' + this.account.id + '/shop-config/'
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint).then(response => {
        this.shop = response.data.shop
      }).finally(() => {
        this.loading = false
      })
    },
    goto (step) {
      this.step = step
    },
    complete () {
      this.$router.push({ name: 'shopify.onboard.products' })
    }
  },
  mounted () {
    this.$emit('update:step', 2)
  },
  props: ['account']
}
</script>
