<template lang="html">
  <div>
    <div class="pa-sm">
      <!-- Filter Header -->
      <div class="columns is-mobile is-marginless">
        <div class="column is-narrow">
          <b-icon :icon="filter.icon"></b-icon>
        </div>
        <div class="column">
          <a href="#" @click.prevent="expanded = !expanded" ><p class="has-text-weight-bold mb-sm">{{ filter.label }}</p></a>
        </div>
        <div class="column is-narrow">
          <b-button type="is-primary" size="is-small" outlined @click="expanded = !expanded" v-text="(expanded) ? 'Collapse' : 'Expand'"></b-button>
        </div>
      </div>
      <!-- Selected Options (Tags) -->
      <div v-if="!expanded" class="pb-md px-md">
        <b-field grouped group-multiline>
          <!-- No filters enabled -->
          <div v-if="!selected">
            <p class="has-text-grey-light mb-sm is-size-7">No options applied for this filter (click 'Expand' to select).</p>
          </div>
          <div v-else class="control">
            <b-tag type="is-primary" attached closable @close="remove(selected)">{{ selected.label }}</b-tag>
          </div>
        </b-field>
      </div>
      <!-- Available Options (Checkboxes/Radios) -->
      <div v-else class="pb-md px-md">
        <div v-for="option in filter.options" :key="option.value" class="field">
          <b-radio v-model="selected" :native-value="option" @input="announce">{{ option.label }}</b-radio>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      expanded: false,
      selected: null
    }
  },
  methods: {
    remove (option) {
      this.selected = null
      this.announce()
    },
    announce () {
      if (this.selected !== null) {
        this.$emit('input', [this.selected])
      } else {
        this.$emit('input', [])
      }
    }
  },
  created () {
    if (typeof this.value !== 'undefined' && Array.isArray(this.value) && this.value.length > 0) {
      this.selected = this.value[0]
    }
  },
  props: ['filter', 'value']
}
</script>
