<template>
  <div class="has-background-white pa-xl rounded-lg mb-lg">
    <!-- Intro -->
    <div class="mb-lg">
      <p class="is-size-4 mb-md has-text-weight-bold">Compatibility Results</p>
    </div>
    <!-- Stats -->
    <div class="mb-lg">
      <div class="mb-3">
        <div class="columns">
          <div class="column is-one-quarter">
            <div class="has-background-light rounded-lg has-text-centered pa-lg bg-grey text-center p-4 mb-3">
              <p>Total Items</p>
              <p v-if="loading">Loading...</p>
              <p v-else-if="!loading && vendorRuleset" class="is-size-4 has-text-weight-bold">{{ vendorRuleset.count }}</p>
              <p v-else>--</p>
            </div>
          </div>
          <div class="column is-one-quarter">
            <div class="has-background-light rounded-lg has-text-centered pa-lg bg-grey text-center p-4 mb-3">
              <p>Evaluating</p>
              <p v-if="loading">Loading...</p>
              <p v-else-if="!loading && vendorRuleset" class="is-size-4 has-text-weight-bold">{{ vendorRuleset.processing }}</p>
              <p v-else>--</p>
            </div>
          </div>
          <div class="column is-one-quarter">
            <div class="has-background-light rounded-lg has-text-centered pa-lg bg-grey text-center p-4 mb-3">
              <p>Items Passed</p>
              <p v-if="loading">Loading...</p>
              <p v-else-if="!loading && vendorRuleset" class="is-size-4 has-text-weight-bold has-text-success">{{ vendorRuleset.passed }}</p>
              <p v-else>--</p>
            </div>
          </div>
          <div class="column is-one-quarter">
            <div class="has-background-light rounded-lg has-text-centered pa-lg bg-grey text-center p-4 mb-3">
              <p>Items Failed</p>
              <p v-if="loading">Loading...</p>
              <p v-else-if="!loading && vendorRuleset" class="is-size-4 has-text-weight-bold has-text-danger">{{ vendorRuleset.failed }}</p>
              <p v-else>--</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Results -->
    <div v-if="!loading && vendorRuleset !== null " class="mb-lg">
      <!-- Passing -->
      <div v-if="isPassing">
        <div class="py-xxl has-text-centered has-background-light rounded-lg mb-xxl">
          <h3 class="has-text-success is-size-4">Compatibility Passed!</h3>
          <p class="has-text-grey">All items are passing preliminary compatibility checks. Your proposal is almost ready!</p>
        </div>
      </div>
      <!-- Failing -->
      <div v-else-if="vendorRuleset.failed > 0">
        <FailingAttributes :proposal="proposal" :ruleset="vendorRuleset" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FailingAttributes from '../components/Compatibility/FailingAttributes'

export default {
  components: {
    FailingAttributes
  },
  computed: {
    ...mapGetters(['context']),
    vendorRuleset () {
      if (this.rulesets.length === 0) return null
      const ruleset = this.rulesets.find(ruleset => {
        return ruleset.id === this.proposal.department.brand_ruleset.id
      })
      return (typeof ruleset !== 'undefined') ? ruleset : null
    },
    isPassing () {
      if (this.vendorRuleset) {
        return this.vendorRuleset.failed === 0 && this.vendorRuleset.processing === 0
      }
      return false
    }
  },
  data () {
    return {
      loading: true,
      rulesets: [],
      showImportModal: false
    }
  },
  methods: {
    loadRulesetSummary () {
      this.loading = true
      const endpoint = this.context.route + '/proposals/' + this.proposal.id + '/rulesets-summary/'
      this.$http.get(endpoint).then(response => {
        this.rulesets = response.data
        this.loading = false
      })
    }
  },
  mounted () {
    this.loadRulesetSummary()
  },
  props: ['proposal']
}
</script>
