<template>
  <div>
    <div>
      <p class="has-text-weight-bold mb-sm">Logo</p>
      <p class="has-text-grey mb-md">
        Your organization's logo is often displayed to your partners
        throughout the {{ app.platform }} platform (but most notably included
        on your packing slip). The ideal logo is a 720x240 pixel PNG with a transparent background.
        Please upload your logo below.
      </p>
    </div>
    <!-- Fields -->
    <div class="mt-md mb-xl">
      <!-- Loading account -->
      <div v-if="loading" class="pa-xl">
        <loading message="Loading..."></loading>
      </div>
      <div v-else>
        <LogoUpload v-model="logoUrl" :loading="loading" :logoUrl="(account !== null) ? account.logo_url : null"/>
      </div>
    </div>
    <!-- Navigate -->
    <div class="pt-lg">
      <div class="columns">
        <div class="column">
          <b-button
            type="is-light rounded-lg"
            expanded
            @click="goBack"
            size="is-medium"
            ><b-icon icon="arrow-left"></b-icon>
          </b-button>
        </div>
        <div class="column">
          <b-button
            type="is-primary rounded-lg"
            expanded
            :loading="loading || isUpdating"
            @click="goForward"
            size="is-medium"
          >Save & Continue</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LogoUpload from '@/components/Models/Branding/LogoUpload'
export default {
  components: {
    LogoUpload
  },
  computed: {
    ...mapGetters(['app', 'context'])
  },
  data () {
    return {
      loading: false,
      logoUrl: null,
      saving: false,
      isUpdating: false,
      setupIsCompleted: false,
      errors: {}
    }
  },
  methods: {
    goBack () {
      this.$router.push({ name: 'retailer.onboarding.contact-persons' })
    },
    goForward () {
      this.updateTask()
    },
    updateTask () {
      const taskBody = (typeof this.task.info === 'object') ? { info: this.task.info } : { info: {} }
      // check for logo, is this mandatory step check with shubham!
      taskBody.info.logo = !!this.account.logo_url
      this.$emit('task:updated', taskBody, 'retailer.onboarding.store-currency')
    }
  },
  mounted () {
    if (this.task && this.task.info && this.task.info.logo) {
      this.setupIsCompleted = true
    }
  },
  props: {
    task: {
      type: Object,
      require: true
    },
    account: {
      type: Object,
      required: true
    },
    isUpdatingTask: {
      type: Boolean
    }
  },
  watch: {
    isUpdatingTask (value) {
      this.isUpdating = value
    }
  }
}
</script>
