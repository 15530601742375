<template>
  <span class="has-text-weight-bold" :class="presenter.class">{{ presenter.label }}</span>
</template>

<script>
export default {
  computed: {
    presenter () {
      if (this.proposal.status === 'proposed') {
        return { class: 'has-text-warning', label: 'Proposed' }
      }
      if (this.proposal.status === 'revised') {
        return { class: 'has-text-danger', label: 'Revised' }
      }
      if (this.proposal.status === 'approved') {
        return { class: 'has-text-info', label: 'Approved' }
      }
      if (this.proposal.status === 'pricing_approved') {
        return { class: 'has-text-info', label: 'Pricing Approved' }
      }
      if (this.proposal.status === 'completed' && this.proposal.acknowledged_at === null) {
        return { class: 'has-text-success', label: 'Ready to Publish' }
      }
      if (this.proposal.status === 'completed' && this.proposal.acknowledged_at !== null) {
        return { class: 'has-text-black', label: 'Completed' }
      }
      if (this.proposal.status === 'declined') {
        return { class: 'has-text-grey-light', label: 'Declined' }
      }
      if (this.proposal.status === 'archived') {
        return { class: 'has-text-grey-light', label: 'Archived' }
      }
      // default = 'draft'
      return { class: 'has-text-grey', label: 'Draft' }
    }
  },
  props: {
    proposal: {
      type: Object,
      required: true
    }
  }
}
</script>
