<template>
  <div class="section">
    <div class="container">
      <!-- Page Header -->
      <div class="columns is-vcentered">
        <div class="column is-one-third">
          <breadcrumbs :history="breadcrumbs.history" :current="breadcrumbs.current"  />
        </div>
        <div class="column is-one-third">
          <h1 class="has-text-centered is-size-4">Edit Transformations</h1>
        </div>
      </div>
      <!-- Content -->
      <!-- Edit Attribute Mapping -->
      <div class="pb-lg border-b mb-lg mt-md">
        <div class="columns">
          <div class="column is-one-fifth">
            <p class="has-text-weight-bold is-size-6 mb-sm">Attribute Settings</p>
            <p class="has-text-grey is-size-7">
              Update attribute for this transformations.
            </p>
          </div>
          <div class="column">
            <AttributeUpdate :attributes="attributes"/>
          </div>
        </div>
      </div>
      <!-- Transformer Info -->
      <div class="pb-lg border-b mb-lg">
        <div class="columns">
          <div class="column is-one-fifth">
            <p class="has-text-weight-bold is-size-6 mb-sm">Transformation Settings</p>
            <p class="has-text-grey is-size-7">
              Configure transformations for this attribute.
            </p>
          </div>
          <div class="column">
            <TransformationSettings @on:create="loadTranslations"/>
          </div>
        </div>
      </div>
      <!-- Transformation List -->
      <div class="columns">
        <div class="column is-one-fifth">
          <p class="has-text-weight-bold is-size-6 mb-sm">Transformations</p>
          <p class="has-text-grey is-size-7">
            List of attribute value transformations.
          </p>
        </div>
        <div class="column">
          <TransformationList :translationsList="translations" @on:reload="loadTranslations"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AttributeUpdate from '../components/AttributeDetails/AttributeUpdate'
import TransformationSettings from '../components/AttributeDetails/TransformationSettings'
import TransformationList from '../components/AttributeDetails/TransformationList'
export default {
  components: {
    AttributeUpdate,
    TransformationSettings,
    TransformationList
  },
  computed: {
    ...mapGetters(['app', 'api', 'context']),
    breadcrumbs () {
      return {
        history: [
          { label: 'Settings', name: 'settings' },
          { label: 'Transformers', name: 'transformers' },
          {
            label: this.$route.params.transformer,
            name: 'transformer',
            params: {
              id: this.$route.params.transformer
            }
          }
        ],
        current: 'Edit'
      }
    },
    endpoint () {
      return this.api.baseRoute + '/attributes/'
    }
  },
  data: () => {
    return {
      newLine: false,
      loadingAttributes: false,
      loadingTransactions: false,
      loading: false,
      attributes: [],
      translations: []
    }
  },
  methods: {
    onCreate () {
      this.newLine = true
    },
    loadAllAttributes () {
      this.loadingAttributes = true
      const query = {
        params: { pagination: 0 }
      }
      this.$http.get(this.endpoint, query).then(response => {
        this.attributes = response.data.results
        this.loadingAttributes = false
      })
    },
    // Get list of translations
    loadTranslations () {
      this.loadingTransactions = true
      const query = {
        params: { pagination: 0 }
      }
      const endpoint = this.context.route + `/transformers/${this.$route.params.transformer}/maps/${this.$route.params.id}/transformations/`
      this.$http.get(endpoint, query).then(response => {
        this.translations = response.data.results
        this.loadingTransactions = false
      })
    }
  },
  mounted () {
    this.loadAllAttributes()
    this.loadTranslations()
  },
  props: []
}
</script>
