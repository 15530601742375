<template>
  <form @submit.prevent="update" novalidate autocomplete="off">
    <div class="columns">
      <div class="column is-one-fifth">
        <p class="has-text-weight-bold is-size-6 mb-sm">Digest Notifications</p>
        <p class="has-text-grey is-size-7">
          Subscribe to digest notifications to receive daily, aggregated
          reports.
        </p>
      </div>
      <div class="column">
        <div v-if="!loading">
          <!-- Merchant Notifications -->
          <div v-if="context.isSupplier()">
            <div v-for="notification in supplierNotifications" :key="notification.action" class="mb-md">
              <EmailNotification
                :notifications="notifications"
                :type="notification.type"
                :title="notification.title"
                :text="notification.text"
                :cron="notification.cron"
              />
            </div>
          </div>
          <!-- Supplier Notifications -->
          <div v-else>
            <div v-for="notification in merchantNotifications" :key="notification.action" class="mb-md">
              <EmailNotification
                :notifications="notifications"
                :type="notification.type"
                :title="notification.title"
                :text="notification.text"
                :cron="notification.cron"
              />
            </div>
          </div>
        </div>
        <!-- while loading -->
        <div v-else>
          <div v-for="index in 2" :key="index">
            <div class="has-background-white pa-md rounded-lg mb-sm">
              <div class="mb-md">
                <b-skeleton width="25%" :animated="true"></b-skeleton>
                <b-skeleton width="75%" :animated="true"></b-skeleton>
              </div>
              <b-skeleton width="50%" height="30" :animated="true"></b-skeleton>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import EmailNotification from './EmailNotification'

export default {
  components: {
    EmailNotification
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/notifications/'
    },
    query () {
      return {
        has_recipient_email: 1
      }
    },
    merchantNotifications () {
      const notifications = []
      this.availableNotifications.forEach(notification => {
        if (notification.context.includes('retailer')) {
          notifications.push(notification)
        }
      })
      return notifications
    },
    supplierNotifications () {
      const notifications = []
      this.availableNotifications.forEach(notification => {
        if (notification.context.includes('supplier')) {
          notifications.push(notification)
        }
      })
      return notifications
    }
  },
  data () {
    return {
      availableNotifications: [{
        type: 'Orders Digest',
        title: 'Late Orders Digest',
        text: 'If you have any late purchase orders, the platform will send an email digest to the address or distribution list specified below.',
        cron: '0 2 * * *',
        context: ['supplier', 'retailer']
      },
      {
        type: 'Inventory Digest',
        title: 'Inventory Stockout Digest',
        text: 'If you have products with no inventory, the platform will send an email digest to the address or distribution list specified below.',
        cron: '0 20 * * *',
        context: ['supplier', 'retailer']
      },
      {
        type: 'Discontinued Digest',
        title: 'Items Discontinued Digest',
        text: 'If you have items that were discontinued, the platform will send an email digest to the address or distribution list specified below.',
        cron: '0 20 * * *',
        context: ['supplier', 'retailer']
      },
      {
        type: 'Order Sync Error',
        title: 'Orders Failed To Sync Digest',
        text: 'If you have orders that have errors syncing with platform accounts, the platform will send an email digest to the address or distribution list specified below.',
        cron: '0 20 * * *',
        context: ['retailer']
      },
      {
        type: 'Late Invoice Digest',
        title: 'Late Invoice Digest',
        text: 'If you have orders that have late invoices, the platform will send an email digest to the address or distribution list specified below.',
        cron: '0 20 * * *',
        context: ['supplier', 'retailer']
      }
      ],
      loading: true,
      notifications: []
    }
  },
  methods: {
    load () {
      this.$http.get(this.endpoint, { params: this.query }).then(response => {
        this.notifications = response.data.results
      }).finally(() => {
        this.loading = false
      })
    }
  },
  mounted () {
    this.load()
  },
  props: {}
}
</script>
