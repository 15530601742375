<template>
  <div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Mark as Complete?</p>
      </header>
      <section class="modal-card-body">
        <p class="py-xl has-text-centered">
          Are you sure you are ready to mark this proposal as complete? You can always come back
          and export products later if necessary.
        </p>
      </section>
      <footer class="modal-card-foot space-between" >
        <b-button @click="$parent.close()">Close</b-button>
        <b-button
          type="is-primary"
          :disabled="saving"
          :loading="saving"
          @click="save">Mark as Complete</b-button>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/proposals/' + this.proposal.id + '/acknowledge/'
    }
  },
  data () {
    return {
      saving: false
    }
  },
  methods: {
    save () {
      this.saving = true
      this.$http.put(this.endpoint).then(response => {
        this.$emit('proposal:acknowledged')
        this.$buefy.toast.open({ type: 'is-success', message: 'Proposal Acknowledged!' })
        this.$parent.close()
      }).finally(() => {
        this.loading = false
      })
    }
  },
  props: ['proposal']
}
</script>
