<template>
  <form @submit.prevent>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Accept Invoices</p>
      </header>
      <section class="modal-card-body">
        <div class="pb-md border-b">
          <!-- Reason -->
          <div class="has-background-white-ter pa-md rounded-lg">
            <div class="columns is-vcentered">
              <div class="column">
                <p>Are you sure you want to accept the following invoices?</p>
              </div>
              <div class="column is-narrow">
                <b-button
                  type="is-primary rounded-lg"
                  @click="acceptAll"
                  :loading="busy"
                  :disabled="busy"
                >Accept All Invoices</b-button>
              </div>
            </div>
          </div>
        </div>

        <!-- Results -->
        <div>
          <b-table
            :data="requests"
            :mobile-cards="false"
            hoverable
            class="is-size-7">
            <template>
              <!-- Label -->
              <b-table-column
                label="Invoice #"
                header-class="nowrap"
                cell-class="nowrap"
                width="128"
                v-slot="props">
                {{ props.row.label() }}
              </b-table-column>
              <!-- Status -->
              <b-table-column
                label="Status"
                header-class="nowrap"
                cell-class="nowrap"
                v-slot="props">
                <p v-if="props.row.status === 'Complete'" class="has-text-success">Complete</p>
                <p v-else-if="props.row.status === 'Skip'" class="has-text-warning">
                  Skip: {{ props.row.errorMessage }}
                </p>
                <p v-else-if="props.row.status === 'Error'" class="has-text-danger">
                  Error: {{ props.row.errorMessage }}
                </p>
                <p v-else>{{ props.row.status }}</p>
              </b-table-column>
              <!-- Progress -->
              <b-table-column
                label=""
                header-class="nowrap"
                cell-class="nowrap"
                width="96"
                v-slot="props">
                <b-progress v-if="props.row.status === 'Pending'" show-value></b-progress>
              </b-table-column>
            </template>
          </b-table>
        </div>
      </section>
      <footer class="modal-card-foot space-between">
        <button class="button" type="button" @click="$parent.close()">Close</button>
        <button class="button" type="button" @click="closeAndReload()">Close & Reload</button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import AcknowledgeInvoice from '@/helpers/Requests/AcknowledgeInvoice'
export default {
  components: {

  },
  data () {
    return {
      processing: false,
      requests: [],
      todo: 0,
      skipped: 0,
      success: 0,
      failed: 0,
      reason: null
    }
  },
  computed: {
    ...mapGetters(['context']),
    busy () {
      return this.todo !== this.skipped + this.success + this.failed
    }
  },
  methods: {
    acceptAll () {
      this.reset()
      this.processing = true
      this.requests.forEach((request, index) => {
        this.todo++
        request.prepare()
        setTimeout(() => {
          request.run().then(response => {
            this.success++
          }).catch(() => {
            this.failed++
          })
        }, index * 1000)
      })
    },
    reset () {
      this.todo = this.skipped = this.success = this.failed = 0
      this.processing = false
    },
    closeAndReload () {
      this.$emit('reload')
      this.$parent.close()
    }
  },
  mounted () {
    this.invoices.forEach(invoice => {
      this.requests.push(new AcknowledgeInvoice({
        route: this.context.route,
        invoice: invoice
      }))
    })
  },
  props: ['invoices']
}
</script>
