<template>
  <div>
    <div class="columns is-vcentered is-mobile">
      <div class="column is-narrow is-hidden-mobile">
        <div :class="authorClass" class="has-text-white rounded-full has-text-centered is-size-8" style="width:32px; height:32px; padding-top:6px">
          {{ authorInitials }}
        </div>
      </div>
      <div class="column">
        <p class="has-text-grey is-size-7">
          On {{ $dates.utc(detail.created_at).local().format('MMM D') }},
          {{ authorFullname }} added...
        </p>
        <p class="mb-xs is-size-7">{{ detail.text }}</p>
        <p class="is-size-8 has-text-grey-light" v-if="hasBeenRead">
          <em>Marked as read on {{ $dates.utc(detail.acknowledged_at).local().format('MMM D, YYYY') }}</em>
        </p>
      </div>
      <div class="column is-narrow" v-if="canEdit">
        <b-dropdown position="is-bottom-left" :disabled="busy" :loading="busy">
          <template #trigger="{ active }">
            <b-button
              label="Options"
              type="is-primary"
              size="is-small"
              outlined
              :icon-right="active ? 'menu-up' : 'menu-down'" />
          </template>
          <b-dropdown-item :disabled="!canEdit" @click="edit(memo)">Edit</b-dropdown-item>
          <!-- <b-dropdown-item :disabled="!canDelete" @click="confirmDelete(memo)">Delete</b-dropdown-item> -->
        </b-dropdown>
      </div>
      <div class="column is-narrow" v-if="canMarkAsRead">
        <b-dropdown position="is-bottom-left" :disabled="busy" :loading="busy">
          <template #trigger="{ active }">
            <b-button
              label="Options"
              type="is-primary"
              size="is-small"
              outlined
              :icon-right="active ? 'menu-up' : 'menu-down'" />
          </template>
          <b-dropdown-item :disabled="!canMarkAsRead" @click="markAsRead">Acknowledge</b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  computed: {
    ...mapGetters(['context']),
    busy () {
      return (this.saving || this.refreshing)
    },
    authorInitials () {
      return this.memo.created_by.first_name.charAt(0).toUpperCase() + this.memo.created_by.last_name.charAt(0).toUpperCase()
    },
    authorClass () {
      return (this.memo.created_by_context === 'retailer') ? 'has-background-primary' : 'has-background-success'
    },
    authorFullname () {
      return this.memo.created_by.first_name + ' ' + this.memo.created_by.last_name
    },
    canEdit () {
      return this.context.type === this.memo.created_by_context
    },
    canDelete () {
      return this.context.type === this.memo.created_by_context
    },
    canMarkAsRead () {
      return (this.context.type !== this.memo.created_by_context && this.memo.acknowledged_at === null)
    },
    hasBeenRead () {
      return (this.context.type !== this.memo.created_by_context && this.memo.acknowledged_at !== null)
    },
    readEndpoint () {
      return this.context.route + '/memos/' + this.memo.id + '/acknowledge/'
    }
  },
  data () {
    return {
      saving: false,
      refreshing: false,
      detail: this.memo,
      confirmDelete: false
    }
  },
  methods: {
    edit (memo) {
      this.$emit('edit:memo', memo)
    },
    markAsRead () {
      this.saving = true
      this.$http.put(this.readEndpoint).then(response => {
        this.detail.acknowledged_at = response.data.acknowledged_at
        this.saving = false
      })
    },
    refresh () {
      this.refreshing = true
      this.$http.put(this.readEndpoint).then(response => {

      })
    }
  },
  mounted () {

  },
  props: ['memo']
}
</script>
