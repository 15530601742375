<template>
    <div>
      <!-- Content -->
      <div class="">
        <p class="has-text-weight-bold mb-md">Activate Orders Sync</p>
        <p class="mb-lg">
          We can push orders that you receive from {{ connection.retailer.name }}
          directly into your WooCommerce store. We will tag the order with
          "{{ connection.retailer.name }}" and add core information to the "Notes"
          field (like the packing slip).
        </p>
        <div class="mb-lg">
          <p class="has-text-weight-bold mb-md">Do you want to push orders to your WooCommerce store?</p>
          <div class="columns is-mobile is-vcentered">
            <div class="column is-narrow">
              <div class="field">
                <b-switch v-model="sync" type="is-success" size="is-medium" true-value="Yes" false-value="No">{{ sync }}</b-switch>
              </div>
            </div>
          </div>
          <div class="has-text-grey-light">
            <p v-if="credential && credential.value === '1'" >Note: this integration is currently enabled.</p>
            <p v-else >Note: this integration is currently disabled.</p>
          </div>
        </div>
      </div>
      <!-- Navigate -->
      <div class="py-lg">
        <div class="columns is-centered">
          <div class="column">
            <b-button
              type="is-light rounded-lg"
              size="is-medium"
              @click="goBack"
              expanded
              ><b-icon icon="arrow-left"></b-icon>
            </b-button>
          </div>
          <div class="column">
            <b-button
              type="is-primary rounded-lg"
              size="is-medium"
              expanded
              @click="goForward"
              :disabled="saving"
              :loading="saving"
            >Save &amp; Continue</b-button>
          </div>
        </div>
      </div>
    </div>
  </template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['app', 'context']),
    json () {
      const json = {
        credentials: [{
          credential: {
            code: 'push_order_on_order_create'
          },
          value: (this.sync === 'Yes') ? 1 : 0
        }]
      }
      if (this.credential !== null) {
        json.credentials[0].id = this.credential.id
      }
      return json
    }
  },
  data () {
    return {
      sync: null,
      credential: null,
      saving: false,
      errors: {}
    }
  },
  methods: {
    goBack () {
      this.$emit('navigate', 'Inventory')
    },
    goForward () {
      this.saving = true
      const endpoint = this.context.route + '/platform-accounts/' + this.account.id + '/'
      this.$http.patch(endpoint, this.json).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Setting updated!' })
        this.$emit('platform:saved')
        if (this.sync === 'Yes') {
          this.$emit('navigate', 'Fulfillments')
        } else {
          this.$emit('navigate', 'Confirmation')
        }
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    this.account.credentials.forEach(credential => {
      Object.keys(credential).forEach(key => {
        if (key === 'push_order_on_order_create') {
          this.credential = credential[key].values[0]
          this.sync = (this.credential.value === '1') ? 'Yes' : 'No'
        }
      })
    })
    this.sync = 'Yes'
  },
  props: {
    connection: {
      type: Object,
      required: true
    },
    task: {
      type: Object,
      required: true
    },
    account: {
      type: Object,
      required: true
    }
  }
}
</script>
