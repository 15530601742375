<template>
  <div>
    <div class="mb-sm">
      <p class="mb-xs has-text-centered has-text-weight-bold" :class="status.class">{{ status.label }}</p>
      <p class="mb-xs"><b-progress :value="value" :show-value="true" :size="size" format="percent"></b-progress></p>
    </div>
    <div class="columns has-text-grey-light">
      <div class="column is-one-third">
        <b-icon
          v-if="feed.status !== 'success' && feed.status !== 'failure'"
          icon="loading"
          size="is-small"
          custom-class="mdi-spin has-text-grey-light"
        ></b-icon>
      </div>
      <div class="column is-one-third">
        <p class="has-text-centered is-size-7">
          {{ feed.num_processed }} of {{ feed.num_items }} {{ (feed.num_items === 1) ? 'row' : 'rows' }} processed
        </p>
      </div>
      <!-- <div class="column is-one-third">
        <p v-if="feed.status !== 'success' && feed.status !== 'failure'" class="has-text-right is-size-7">{{ refreshAttempt }} / {{ maxRefreshes }}</p>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    status () {
      // perfect score...
      if (
        this.feed.status === 'success' &&
        this.feed.num_created + this.feed.num_updated === this.feed.num_items &&
        this.feed.num_errors === 0 &&
        this.feed.num_warnings === 0
      ) {
        return {
          class: 'has-text-success',
          label: 'Completed!'
        }
      }
      // something positive...
      if (this.feed.status === 'success' && (this.feed.num_created > 0 || this.feed.num_updated > 0)) {
        if (this.feed.num_errors) return { class: 'has-text-warning', label: 'Completed (with errors)' }
        if (this.feed.num_warnings) return { class: 'has-text-warning', label: 'Completed (with warnings)' }
        return {
          class: 'has-text-success',
          label: 'Completed!'
        }
      }
      // getting close to negative...
      if (this.feed.status === 'success' && this.feed.num_created === 0 && this.feed.num_updated === 0) {
        if (this.feed.num_errors) return { class: 'has-text-danger', label: 'Completed (with errors)' }
        if (this.feed.num_warnings) return { class: 'has-text-danger', label: 'Completed (with warnings)' }
        return {
          class: 'has-text-success',
          label: 'Completed!'
        }
      }
      // getting close to negative...
      if (this.feed.status === 'failure') {
        return {
          class: 'has-text-danger',
          label: 'Completed (with errors!)'
        }
      }
      return { class: 'has-text-grey', label: 'Processing' }
    },
    value () {
      if (this.feed.status === 'success' || this.feed.status === 'failure') return 100
      if (this.feed.status === 'processing' && parseInt(this.feed.num_items) && parseInt(this.feed.num_processed)) {
        return parseFloat(parseFloat(parseInt(this.feed.num_processed) / parseInt(this.feed.num_items) * 100).toFixed(2))
      }
      return undefined
    }
  },
  data () {
    return {
      interval: null,
      refreshAttempt: 1,
      maxRefreshes: 60
    }
  },
  methods: {
    emitRefreshEvent () {
      if (this.refreshAttempt < this.maxRefreshes && (this.feed.status === 'queued' || this.feed.status === 'processing')) {
        this.$emit('refresh')
        this.refreshAttempt++
      } else {
        window.clearInterval(this.interval)
      }
    }
  },
  mounted () {
    if (this.emitRefresh && (this.feed.status === 'queued' || this.feed.status === 'processing')) {
      this.interval = setInterval(() => {
        this.emitRefreshEvent()
      }, 3000)
    }
  },
  unmounted () {
    window.clearInterval(this.interval)
  },
  props: {
    feed: {
      type: Object,
      required: true
    },
    size: {
      type: String,
      default: null
    },
    emitRefresh: {
      type: Boolean,
      default: false
    }
  }
}
</script>
