<template>
  <div>
    <div class="columns">
      <div class="column is-one-fifth">
        <p class="has-text-weight-bold is-size-6 mb-sm">Shipment Notifications</p>
        <p class="has-text-grey is-size-7">Configure whether you would like Shopify to inform your customers when the order is shipped.</p>
      </div>
      <div class="column">
        <div class="has-background-white rounded-lg">
          <div class="pa-lg">
            <!-- Intro Text (Merchant) -->
            <div class="is-size-7 mb-md">
              <p class="">
                Enable this service if you would like to inform your customers through Shopify whenever an order is shipped and tracking information is available.
              </p>
            </div>
            <div class="columns">
              <div class="column is-half">
                <b-field>
                  <b-select
                    v-model="value"
                    expanded
                    placeholder="Select an option"
                    @input="isDirty = 1">
                    <option value="1">Enabled</option>
                    <option value="0">Disabled</option>
                  </b-select>
                </b-field>
              </div>
            </div>
          </div>
          <div class="border-t">
            <div class="columns is-vcentered is-marginless">
              <div class="column is-hidden-mobile">
                <div class="px-sm has-text-grey-light">
                  <i v-if="isDirty">Click 'Save Preference' to save your changes</i>
                </div>
              </div>
              <div class="column is-narrow">
                <div class="px-sm">
                  <b-button
                    native-type="submit"
                    type="is-primary rounded-lg"
                    :disabled="saving || !isDirty"
                    :loading="saving"
                    @click="save"
                  >Save Preference</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['app', 'context']),
    endpoint () {
      return this.context.route + '/platform-accounts/' + this.account.id + '/'
    },
    credential () {
      if (this.account.credentials.length === 0) return null
      let found = null
      this.account.credentials.forEach(credential => {
        Object.keys(credential).forEach(key => {
          if (key === 'notify_customer_on_fulfillment_create') {
            found = credential[key].values[0]
          }
        })
      })
      return found
    },
    json () {
      const json = {
        credentials: [{
          credential: {
            code: 'notify_customer_on_fulfillment_create'
          },
          value: this.value
        }]
      }
      if (this.credential !== null) {
        json.credentials[0].id = this.credential.id
      }
      return json
    }
  },
  data () {
    return {
      saving: false,
      errors: {},
      isDirty: false,
      value: null
    }
  },
  methods: {
    save () {
      this.saving = true
      this.$http.patch(this.endpoint, this.json).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Preference saved!' })
        this.$emit('update:account')
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    this.account.credentials.forEach(credential => {
      Object.keys(credential).forEach(key => {
        if (key === 'notify_customer_on_fulfillment_create' && credential[key].values[0].value) {
          this.value = credential[key].values[0].value
        }
      })
    })
  },
  props: ['account']
}
</script>
