<template>
  <div>
    <div class="has-background-white rounded-lg pa-lg pb-xl">
      <b-field label="Profile Name">
        <b-input
          autocomplete="off"
          type="text"
          v-model="name"
          @input="change"
        ></b-input>
      </b-field>
      <b-field label="Profile Description">
        <b-input
          autocomplete="off"
          type="text"
          v-model="description"
          @input="change"
        ></b-input>
      </b-field>
      <b-field label="Commission Method">
        <b-select v-model="method" placeholder="Select commission method" expanded  @input="change" :disabled="isEdit">
          <option value="flat">Flat commission %</option>
          <option value="category">Commission by category</option>
        </b-select>
      </b-field>
      <!-- Value -->
      <div v-if="method === 'flat'" class="columns">
        <b-field
          class="column"
          label="Commission Value (%)"
          expanded>
          <b-numberinput
            type="text"
            v-model="value"
            :step=".01"
            :min="0"
            :max="100"
            @input="change('value')"
          ></b-numberinput>
        </b-field>
        <b-field label="Start date" class="column">
          <p class="mt-sm">
            {{ $dates.utc(start_at).local().format('MMM DD, YYYY @ h:mm A') }}
          </p>
        </b-field>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {
  },
  computed: {
    ...mapGetters(['app', 'context']),
    minDate () {
      return new Date()
    },
    json () {
      const json = {
        name: this.name,
        description: this.description,
        method: this.method,
        values: [{
          ...this.commissionValue,
          value: this.value / 100,
          start_at: this.isDirty ? new Date().toISOString() : this.start_at
        }]
      }
      return json
    }
  },
  data () {
    return {
      name: null,
      description: null,
      method: 'flat',
      value: null,
      commissionValue: null,
      start_at: new Date(),
      isDirty: false
    }
  },
  methods: {
    change (key) {
      if (key === 'value') {
        this.isDirty = true
        this.start_at = new Date()
      }
      this.$emit('input', this.json)
    }
  },
  mounted () {
    if (this.profileData) {
      const { name, method, description, values } = this.profileData
      this.name = name
      this.method = method
      this.description = description
      this.value = parseFloat(values[0].value) * 100
      this.start_at = new Date(values[0].start_at)
      this.commissionValue = values[0]
    }
  },
  props: {
    profileData: {
      type: Object,
      default: null
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  }
}
</script>
