<template>
  <div>
    <!-- Content -->
    <div class="section">
      <div class="container">
        <!-- Page Header -->
        <div class="mb-md">
          <div class="columns is-vcentered">
            <div class="column is-one-third">
              <breadcrumbs :history="breadcrumbs.history" :current="breadcrumbs.current" />
            </div>
            <div class="column is-one-third">
              <h1 class="has-text-centered is-size-4">Order Detail</h1>
            </div>
          </div>
        </div>
        <!-- Order Loaded -->
        <div v-if="!loading && this.order !== null">
          <!-- Header Loaded -->
          <div class="has-background-white rounded-lg px-lg py-md mb-xxs">
            <div class="columns is-vcentered is-mobile">
              <div class="column">
                <p class="is-size-4">PO #{{ order.purchase_order_number }}</p>
                <p class="has-text-grey">{{ partner.name }}</p>
              </div>
              <div class="column is-narrow has-text-right">
                <p class="is-size-4" :class="status.textClass">{{ status.label }}</p>
                <p :class="status.textClass" v-if="status.message !== null">{{ status.message }}</p>
              </div>
            </div>
          </div>
          <!-- Child Router View -->
          <router-view
            :order="order"
            :platformAccount="platformAccount"
            :connectionPlatformAccount="connectionPlatformAccount"
            :orderRefreshing="loading"
            @order:updated="loadOrder(true)"
            @order:refresh="loadOrder(false)"
            @fulfillment="loadOrder"
            @inspect:variant="inspectVariant"
          />
        </div>
        <!-- Loading Skeleton... -->
        <div v-else>
          <div class="has-background-white pa-lg rounded-lg mb-xs">
            <div class="placeholder placeholder-title" style="width:66%"></div>
            <div class="placeholder placeholder-sentence" style="width:50%"></div>
          </div>
          <div class="has-background-white px-lg py-xxl rounded-lg mb-lg">
            <div class="columns">
              <div class="column">
                <div class="placeholder placeholder-sentence mb-lg"></div>
                <div class="placeholder placeholder-sentence mb-lg"></div>
                <div class="placeholder placeholder-sentence"></div>
              </div>
              <div class="column">
                <div class="placeholder placeholder-sentence mb-lg"></div>
                <div class="placeholder placeholder-sentence mb-lg"></div>
                <div class="placeholder placeholder-sentence"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal: Variant -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showVariantModal"
      :can-cancel="['x']"
      @close="selectedVariant = null"
      ><InventoryModal :selectedVariant="selectedVariant" />
    </b-modal>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import OrderPresenters from '@/mixins/presenters/Order'
import InventoryModal from '@/components/Modals/Variant/InventoryModal'

export default {
  components: {
    InventoryModal
  },
  computed: {
    ...mapGetters(['context']),
    breadcrumbs () {
      return {
        current: (this.loading) ? 'Loading...' : 'Order #' + this.$route.params.id,
        history: [
          { label: 'Orders', name: 'orders.dashboard' },
          { label: 'List', name: 'orders.list' }
        ]
      }
    },
    loading () {
      return (this.loadingOrder || this.loadingPlatformAccounts || this.loadingConnectionPlatformAccounts)
    }
  },
  data () {
    return {
      loadingOrder: true,
      loadingPlatformAccounts: true,
      loadingConnectionPlatformAccounts: false,
      refreshing: false,
      order: null,
      platformAccount: null,
      connectionPlatformAccount: null,
      // modals
      showVariantModal: false,
      // inspections
      selectedVariant: null
    }
  },
  methods: {
    loadOrder (quiet = false) {
      if (!quiet) this.loadingOrder = true
      const endpoint = this.context.route + '/orders/' + this.$route.params.id + '/'
      const query = {
        params: {
          skip_attributes: 1,
          show_shipments: 1
        }
      }
      this.$http.get(endpoint, query).then(response => {
        this.order = response.data
        if (this.context.isMerchant()) {
          this.loadConnectionPlatformAccounts()
        }
        this.loadingOrder = false
      })
    },
    loadPlatformAccounts () {
      this.loadingPlatformAccounts = true
      const endpoint = this.context.route + '/platform-accounts/'
      const query = {
        params: {
          platform: 'shopify'
        }
      }
      this.$http.get(endpoint, query).then(response => {
        if (response.data.count > 0) {
          this.platformAccount = response.data.results[0]
        }
        this.loadingPlatformAccounts = false
      })
    },
    loadConnectionPlatformAccounts () {
      this.loadingConnectionPlatformAccounts = true
      const endpoint = this.context.route + '/connections/' + this.order.connection_id + '/platform-accounts/'
      const query = {
        params: {
          platform: 'shopify'
        }
      }
      this.$http.get(endpoint, query).then(response => {
        if (response.data.count > 0) {
          this.connectionPlatformAccount = response.data.results[0]
        }
        this.loadingConnectionPlatformAccounts = false
      })
    },
    refreshShipments () {
      this.refreshing = true
      const query = {
        params: {
          fields: 'id,shipments,tracking_number',
          skip_attributes: 1
        }
      }
      this.$http.get(this.endpoint, query).then(response => {
        this.$set(this.order, 'shipments', response.data)
        this.refreshing = false
      })
    },
    inspectVariant (variant) {
      this.selectedVariant = variant
      this.showVariantModal = true
    },
    startReturn () {
      this.showEditRmaModal = true
    }
  },
  mixins: [OrderPresenters],
  mounted () {
    this.loadOrder()
    this.loadPlatformAccounts()
  },
  props: []
}
</script>
