<template>
  <div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Apply Transformer</p>
      </header>
      <section class="modal-card-body">
        <b-message type="is-info" class="is-size-7">
          You can select and apply a transformer. All matching attribute values will be modified as per transformer specifications.
        </b-message>
      <div class="mb-lg">
        <b-field label="Transformer">
          <TransformerSelect v-model="transformer" :isMandatory="true"/>
        </b-field>
      </div>
      </section>
      <footer class="modal-card-foot space-between" >
        <b-button @click="$parent.close()">Close</b-button>
        <b-button
          type="is-primary"
          :disabled="loading || transformer === null"
          :loading="loading"
          @click="$emit('apply:transformer', transformer)"
        >Apply</b-button>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TransformerSelect from '../TransformerSelect'

export default {
  components: {
    TransformerSelect
  },
  computed: {
    ...mapGetters(['context'])
  },
  data () {
    return {
      transformer: null
    }
  },
  methods: {

  },
  props: {
    loading: {
      type: Boolean,
      required: true,
      default: false
    }
  }
}
</script>
