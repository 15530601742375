<template>
  <div class="has-background-white rounded-lg">
    <div class="columns pa-lg pb-xl">
      <div class="column is-half">
        <b-field
          label="Attribute Value Match"
          expanded>
          <b-input
            type="text"
            size="is-medium"
            @input="isDirty = 1"
            v-model="match"
          ></b-input>
        </b-field>
      </div>
      <div class="column is-half">
        <b-field
          label="Transformation Output Value"
          expanded>
          <b-input
            type="text"
            size="is-medium"
            @input="isDirty = 1"
            v-model="output"
          ></b-input>
        </b-field>
      </div>
    </div>
    <div class="border-t" />
    <div class="columns is-vcentered is-marginless">
      <div class="column is-hidden-mobile">
        <div class="px-sm has-text-grey-light">
          <i v-if="this.match && !this.output">Output is required to create a translation</i>
          <i v-if="isValidated">Click 'Create' to create new translation</i>
        </div>
      </div>
      <div class="column is-narrow ">
        <div class="px-sm">
          <b-button
            type="is-primary rounded-lg"
            class="is-pulled-right"
            :disabled="saving || !isValidated"
            :loading="saving"
            @click="create"
          >Create
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  computed: {
    ...mapGetters(['app', 'api', 'context']),
    endpoint () {
      return this.context.route + `/transformers/${this.$route.params.transformer}/maps/${this.$route.params.id}/transformations/`
    },
    json () {
      return {
        match: this.match,
        output: this.output
      }
    },
    isValidated () {
      return this.match && this.output && this.isDirty
    }
  },
  data: () => {
    return {
      match: null,
      output: null,
      isDirty: false,
      saving: false,
      errors: {}
    }
  },
  methods: {
    // Create translation
    create () {
      this.saving = true
      this.$http.post(this.endpoint, this.json).then(response => {
        this.errors = {}
        this.$buefy.toast.open({ type: 'is-success', message: 'Translation created!' })
      }).catch(error => {
        this.$buefy.toast.open({ type: 'is-danger', message: 'Error creating translation!' })
        this.errors = error.response.data
      }).finally(() => {
        this.$emit('on:create')
        this.saving = false
        this.isDirty = false
        this.match = null
        this.output = null
      })
    }
  },
  mounted () {
  },
  watch: {

  },
  props: []
}
</script>
