<template>
  <div>
    <div class="section">
      <div class="container">
        <!-- Page Header -->
        <div class="mb-md">
          <div class="columns is-vcentered">
            <div class="column is-one-third">
              <breadcrumbs :history="[{ label: 'Settings', name: 'settings'}]" current="API Clients" />
            </div>
            <div class="column is-one-third">
              <h1 class="has-text-centered is-size-4">API Clients</h1>
            </div>
          </div>
        </div>
        <!-- Filters -->
        <div class="has-background-white rounded-lg pa-md mb-xxs">
          <div class="columns">
            <div class="column">
              <user-filters @filter="filter" :applied-filters="filters" />
            </div>
            <div class="column is-narrow">
              <b-select v-model="orderBy" size="is-medium" expanded @input="sort">
                <option value="-id">Newest</option>
                <option value="id">Oldest</option>
              </b-select>
            </div>
            <div class="column is-narrow">
              <b-button type="is-primary" size="is-medium" @click="showCreateClientModal = true">Add API Client</b-button>
            </div>
          </div>
        </div>
        <!-- Results -->
        <div>
          <!-- Table -->
          <div>
            <b-table
              :data="results"
              :checked-rows.sync="selected"
              :mobile-cards="false"
              :loading="loading"
              hoverable
              checkbox-position="left"
              class="mb-lg">
              <b-table-column
                label="Client Name"
                width="255"
                v-slot="props">
                <router-link class="has-text-weight-bold has-text-info" :to="{ name: 'client', params: { id: props.row.id } }">
                  {{ props.row.name }}
                </router-link>
              </b-table-column>
              <b-table-column
                label="Status"
                numeric
                v-slot="props">
                <p v-if="props.row.status === 'locked'" class="has-text-grey has-text-weight-bold">Inactive</p>
                <p v-else-if="props.row.status === 'active'" class="has-text-success has-text-weight-bold">Active</p>
                <p v-else-if="props.row.status === 'invited'" class="has-text-info has-text-weight-bold">Invited</p>
              </b-table-column>
              <template #empty>
                <div class="has-text-grey has-text-centered py-xxl">
                  {{ (loading) ? "Loading data..." : 'No API clients found.' }}
                </div>
              </template>
            </b-table>
          </div>
          <!-- Pagination Controls -->
          <div class="mt-md">
            <b-pagination
              :total="total"
              :current.sync="currentPage"
              :rounded="true"
              :per-page="perPage"
              @change="paginate">
            </b-pagination>
          </div>
        </div>
        <!-- Create Modal -->
        <b-modal
          :has-modal-card="true"
          :active.sync="showCreateClientModal"
          :can-cancel="['x']"
        ><CreateApiClient @client:created="load" />
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Filterable from '@/mixins/Filterable'
import CreateApiClient from '@/components/Models/User/Modals/CreateApiClient'
import UserFilters from '../components/List/UserFilters'

export default {
  components: {
    CreateApiClient,
    UserFilters
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/users/'
    }
  },
  data () {
    return {
      key: 'clients',
      alwaysAttach: {
        short: 1,
        type: 'api'
      },
      selected: [],
      // modal controls
      focusClient: null,
      showCreateClientModal: false
    }
  },
  methods: {
    inspectClient (payload) {
      this.focusClient = payload
    },
    resetClient () {
      this.focusClient = null
    },
    resetModals () {
      this.focusOrder = null
    },
    getDetails (row) {
      this.$router.push({ name: 'user', params: { id: row.id } })
    }
  },
  mixins: [Filterable],
  mounted () {
    this.load()
  }
}
</script>
