<template>
  <div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Invoice Detail</p>
      </header>
      <section class="modal-card-body">
        <div class="mb-lg">
          <div class="columns">
            <div class="column">
              <table class="table is-fullwidth is-striped is-narrow is-size-7 border">
                <tbody>
                  <tr>
                    <th>Invoice Number</th>
                    <td>
                      <p>{{ invoice.invoice_number }}</p>
                    </td>
                  </tr>
                  <tr>
                    <th>Invoice Date</th>
                    <td>
                      {{ $dates.utc(invoice.invoiced_at).local().format('MMM D, YYYY') }}
                    </td>
                  </tr>
                  <tr>
                    <th>ID</th>
                    <td>{{ invoice.id }}</td>
                  </tr>
                  <tr>
                    <th>Customer Order #</th>
                    <td>{{ invoice.customer_order_number }}</td>
                  </tr>
                  <tr>
                    <th>PO #</th>
                    <td>{{ invoice.purchase_order_number }}</td>
                  </tr>
                  <tr>
                    <th>Status</th>
                    <td>
                      <p :class="status.indicatorClass">
                        <b-tooltip :label="status.tooltip" dashed multilined>
                          {{ status.label }}
                        </b-tooltip>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <th>Acknowledged</th>
                    <td>
                      <span class="has-text-sucess" v-if="invoice.acknowledged_at !== null">{{ $dates.utc(invoice.acknowledged_at).local().format('MMM D, YYYY')}}</span>
                      <span v-else class="has-text-grey">Not Acknowledged</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="column">
              <table class="table is-fullwidth is-size-8 border mb-lg">
                <tbody>
                  <tr>
                    <th>Remit To</th>
                    <td><location :location="invoice.remit_to"></location></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!-- Lines -->
        <div class="mb-lg">
          <p class="has-text-centered is-uppercase has-text-primary is-size-7 has-text-weight-bold pb-sm">Invoice Lines</p>
          <table class="table is-fullwidth is-bordered is-striped is-size-7 is-vcentered">
            <thead>
              <tr>
                <th class="has-background-white-ter">Item</th>
                <th class="has-background-white-ter has-text-centered" style="width:15%">Qty</th>
                <th class="has-background-white-ter has-text-centered" style="width:15%">Unit Cost</th>
                <th class="has-background-white-ter has-text-centered" style="width:15%">Line Total</th>
              </tr>
            </thead>
            <tbody>
              <invoice-line-row v-for="line in invoice.invoice_lines" :key="line.id" :line="line" :currency="invoice.currency" class="border-t pt-md"/>
            </tbody>
          </table>
        </div>
        <!-- Adjustments -->
        <div class="mb-lg" v-if="invoice.charges.length > 0 || invoice.allowances.length > 0">
          <p class="has-text-centered is-uppercase has-text-primary is-size-7 has-text-weight-bold pb-sm">Adjustments</p>
          <table class="table is-striped is-fullwidth has-text-right border is-size-7 mb-lg">
            <tbody>
              <tr v-for="adjustment in invoice.charges" :key="adjustment.id">
                <td>
                  <p>Charge: {{ adjustment.description }}</p>
                  <p class="is-size-8 has-text-grey">{{ adjustment.adjustment_code.description }}</p>
                </td>
                <td class="has-text-right" style="vertical-align:middle"><CurrencyLocale :value="parseFloat(adjustment.locale_price)" :currency="invoice.currency"/></td>
              </tr>
              <tr v-for="adjustment in invoice.allowances" :key="adjustment.id">
                <td>
                  <p>{{ $utils.upperFirst(adjustment.adjustment_code.adjustment_type) }}: {{ adjustment.description }}</p>
                  <p class="is-size-8 has-text-grey">{{ adjustment.adjustment_code.description }}</p>
                </td>
                <td class="has-text-right" style="vertical-align:middle"><CurrencyLocale :value="-parseFloat(adjustment.locale_price)" :currency="invoice.currency" :optionSignDisplay="'negative'"/></td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- Totals -->
        <div class="mb-lg">
          <div class="columns">
            <div class="column ">
              <p class="has-text-centered is-uppercase has-text-primary is-size-7 has-text-weight-bold pb-sm">Totals</p>
              <table class="table is-striped is-size-7 is-fullwidth has-text-right border">
                <tbody>
                  <tr>
                    <th>Subtotal</th>
                    <td class="has-text-right"><CurrencyLocale :value="parseFloat(invoice.locale_subtotal)" :currency="invoice.currency"/></td>
                  </tr>
                  <tr>
                    <th>Adjustments</th>
                    <td class="has-text-right"><CurrencyLocale :value="parseFloat(totalAdjustments)" :currency="invoice.currency" :optionSignDisplay="'negative'"/></td>
                  </tr>
                  <tr>
                    <th>Due Amount</th>
                    <td class="has-text-right has-text-weight-bold"><CurrencyLocale :value="parseFloat(invoice.locale_due_amount)" :currency="invoice.currency"/></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button class="button" type="button" @click="$parent.close()">Close</button>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import InvoicePresenters from '@/mixins/presenters/Invoice'
import Location from '@/components/Location'
import InvoiceLineRow from './InvoiceLineRow'
export default {
  components: {
    Location,
    InvoiceLineRow
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/invoices/' + this.invoice.id + '/'
    },
    totalCharges () {
      let total = 0
      this.invoice.charges.forEach(adjustment => {
        total += parseFloat(adjustment.locale_price)
      })
      return total
    },
    totalAllowances () {
      let total = 0
      this.invoice.allowances.forEach(adjustment => {
        total += parseFloat(adjustment.locale_price)
      })
      return total
    },
    totalAdjustments () {
      return this.totalCharges - this.totalAllowances
    }
  },
  data () {
    return {
      loading: false,
      detail: null
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint).then(response => {
        this.detail = response.data
        this.loading = false
      })
    }
  },
  mixins: [InvoicePresenters],
  mounted () {
    // this.load()
  },
  props: ['invoice']
}
</script>
