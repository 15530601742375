<template>
  <div class="mb-lg">
    <div class="has-background-white rounded-lg pa-sm mb-xxs">
      <table class="table is-bordered is-narrow is-hoverable is-striped is-fullwidth">
        <thead>
          <th>{{shippingAccount.carrier.name}} - {{ shippingAccount.nickname }} -  #{{shippingAccount.bill_third_party_account}}</th>
        </thead>
        <tbody>
          <tr v-for="shippingMethod in shippingAccount.shipping_methods" :key="shippingMethod.id">
            <td>
              <b-field>
                <b-checkbox v-model="selectedShippingMethods" :native-value="shippingMethod.id" @input="$emit('update:selection', selectedShippingMethods)">
                  {{shippingMethod.name}}
                </b-checkbox>
              </b-field>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
export default {
  components: {
  },
  data () {
    return {
      selectedShippingMethods: []
    }
  },
  methods: {

  },
  mounted () {
    this.currentShippingMethods.forEach(id => {
      this.selectedShippingMethods.push(id)
    })
  },
  props: ['shippingAccount', 'currentShippingMethods']
}
</script>
