<template>
  <div>
    <!-- Loading -->
    <div v-if="loading">
      <loading message="Loading..." />
    </div>
    <!-- Loaded (No Profile / Opt-in) -->
    <div v-else-if="!loading && profile === null">
      No profile found.
    </div>
    <!-- Loaded (With Profile) -->
    <div v-else-if="!loading && profile !== null">
      <router-view :profile="profile" @update:profile="load" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/marketplaces/?mine=1'
    }
  },
  data () {
    return {
      loading: true,
      profile: null
    }
  },
  methods: {
    load () {
      this.$http.get(this.endpoint).then(response => {
        if (response.data.results.length > 0) {
          this.profile = response.data.results[0]
          // redirect all non-onboarded partners to network onboarding
          if (this.profile.onboarded_at === null && this.$route.name !== 'network.onboarding') {
            this.$router.push({ name: 'network.onboarding' })
          }
          // if we're loading the marketplace from the onboarding route
          // it's likely onboarding was recently completed, so redirect
          // back to the dashboard
          if (this.profile.onboarded_at !== null && this.$route.name === 'network.onboarding') {
            this.$router.push({ name: 'network.dashboard' })
          }
        } else {
          this.$router.push({ name: 'network.signup' })
        }
      }).finally(() => {
        this.loading = false
      })
    }
  },
  mounted () {
    this.load()
  }
}
</script>
