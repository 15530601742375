<template>
  <div>
    <div class="has-background-light rounded-lg pa-md">
      <div class="columns is-vcentered">
        <div class="column">
          <p class="has-text-weight-bold is-size-7">ShipStation Integration #{{ account.id }}</p>
          <div class="is-size-8">
            <p class="has-text-grey-light">API Key</p>
            <p>{{ apiKey }}</p>
          </div>
        </div>
        <div class="column is-narrow">
          <div class="has-text-right">
            <p>Push Orders</p>
            <p>
              <span v-if='pushOrders' class="has-text-success has-text-weight-bold">Enabled</span>
              <span v-else class="has-text-danger has-text-weight-bold">Disabled</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    apiKey () {
      let value = null
      this.account.credentials.forEach(credential => {
        if ('shipstation_api_key' in credential) {
          value = credential.shipstation_api_key.values[0].value
        }
      })
      return value
    },
    pushOrders () {
      let setting = false
      this.account.credentials.forEach(credential => {
        Object.keys(credential).forEach(key => {
          if (key === 'push_order_on_order_create' && credential[key].values[0].value) {
            setting = (credential[key].values[0].value === '1')
          }
        })
      })
      return setting
    }
  },
  props: ['account']
}
</script>
