<template>
  <form @submit.prevent="save" novalidate autocomplete="off">
    <div class="columns">
      <div class="column is-one-fifth">
        <p class="has-text-weight-bold is-size-6 mb-sm">Manage Accounts</p>
        <p class="has-text-grey is-size-7">
          You can manage access of all accounts you have for fabric Marketplace.
        </p>
      </div>
      <div class="column">
        <!-- Results -->
        <div>
          <!-- Table -->
          <div style="overflow-x:auto; overflow-y:hidden">
            <b-table
              :data="results"
              :mobile-cards="false"
              hoverable
              checkbox-position="left"
              class="mb-lg">
              <b-table-column
                label="Account Id"
                width="150"
                v-slot="props">
                  {{ props.row.sender.brand.id }}
              </b-table-column>
              <b-table-column
                label="Account Name"
                width="255"
                v-slot="props">
                  {{ props.row.sender.brand.name }}
              </b-table-column>
              <b-table-column
                label="Role">
                Brand Admin
              </b-table-column>
              <b-table-column
                label="Status"
                centered
                cell-class=“has-text-weight-bold”
                v-slot="props">
                <p v-if="props.row.status === 'accepted'" class="has-text-success">Accepted</p>
                <p v-else-if="props.row.status === 'rejected'" class="has-text-warning">Rejected</p>
                <p v-else-if="props.row.status === 'pending'" class="has-text-grey">Pending</p>
              </b-table-column>
              <b-table-column
                label="Action"
                centered
                v-slot="props">
                <div v-if="props.row.status==='pending'">
                  <b-button class="mr-sm" type="is-success" size="is-small" @click="accept(props.row.id)">Accept</b-button>
                  <b-button type="is-warning" size="is-small" @click="reject(props.row.id)">Reject</b-button>
                </div>
                <p v-else class="has-text-grey">
                  --
                </p>
              </b-table-column>
              <template #empty>
                <div class="has-text-grey has-text-centered">
                  {{ (loading) ? "Loading data..." : 'No pending invites' }}
                </div>
              </template>
            </b-table>
          </div>
          <!-- Pagination Controls -->
          <div class="mt-md">
            <b-pagination
              :total="total"
              :current.sync="currentPage"
              :rounded="true"
              :per-page="perPage"
              @change="paginate">
            </b-pagination>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import Filterable from '@/mixins/Filterable'

export default {
  components: {
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/invites/?received=1'
    }
  },
  data () {
    return {
      key: 'invites'
    }
  },
  methods: {
    accept (id) {
      const endpoint = this.context.route + '/invites/' + id + '/accept/'
      this.$http.put(endpoint).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Invite Accepted!' })
        this.load()
      }).catch(error => {
        if (error.response && error.response.status === 400) {
          this.errors = error.response.data
        }
        this.$buefy.toast.open({ type: 'is-danger', message: error.response.data })
      })
    },
    reject (id) {
      const endpoint = this.context.route + '/invites/' + id + '/reject/'
      this.$http.put(endpoint).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Invite Rejected!' })
        this.load()
      }).catch(error => {
        if (error.response && error.response.status === 400) {
          this.errors = error.response.data
        }
        this.$buefy.toast.open({ type: 'is-danger', message: error.response.data })
      })
    }
  },
  mixins: [Filterable],
  mounted () {
    this.load()
  }
}
</script>
