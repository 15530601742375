<template>
  <div>
    <div class="mb-md">
      <b-table
        :data="items"
        :mobile-cards="false"
        hoverable
        class="is-size-7">
        <template>
          <!-- Name -->
          <b-table-column
            label="Item"
            cell-class="nowrap"
            v-slot="props">
            <Identifiers
              :variant="props.row.variant"
              :showImage="false"
              nameInteraction="inspect"
              imageInteraction="none"
              @inspect="inspectVariant(props.row.variant)"
            />
          </b-table-column>
          <!-- Supplier SKU -->
          <b-table-column
            label="Supplier SKU"
            cell-class="nowrap"
            width="128"
            centered
            v-slot="props">
            {{props.row.variant.identifier}}
          </b-table-column>
          <!-- Variant ID -->
          <b-table-column
            label="Variant ID"
            cell-class="nowrap"
            width="128"
            centered
            v-slot="props">
            {{props.row.variant.id}}
          </b-table-column>
          <!-- Proposal Variant ID -->
          <b-table-column
            label="Proposal Variant ID"
            cell-class="nowrap"
            width="150"
            centered
            v-slot="props">
            {{props.row.proposal_variant_id}}
          </b-table-column>
        </template>
      </b-table>
    </div>
    <!-- Inspect Variant -->
    <b-modal
      v-if="(inspectProposalVariant !== null)"
      has-modal-card
      :active="(inspectProposalVariant !== null)"
      :can-cancel="['escape', 'x']"
      @close="closeModals"
      ><ProposalVariantModal :proposal="proposal" :selectedVariant="inspectProposalVariant" />
    </b-modal>
  </div>
</template>

<script>
import Identifiers from '@/components/Models/Variant/Identifiers'
import ProposalVariantModal from '@/components/Models/Proposal/Modals/ProposalVariantModal'
export default {
  components: {
    Identifiers,
    ProposalVariantModal
  },
  computed: {
  },
  data: () => {
    return {
      items: [],
      inspectProposalVariant: null,
      proposal: {}
    }
  },
  methods: {
    inspectVariant (variant) {
      this.inspectProposalVariant = variant
    },
    closeModals () {
      this.inspectProposalVariant = null
    }
  },
  mounted () {
    this.items = this.issue.elements
    this.proposal = {
      id: this.$route.params.id
    }
  },
  props: {
    issue: {
      type: Object,
      required: true
    }
  }
}
</script>
