<template>
  <form @submit.prevent="save">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Publish Product</p>
      </header>
      <section class="modal-card-body">
        <b-message type="is-info">
          Please select the platform that you want to receive this product.
        </b-message>
          <PlatformAccountSelect v-model="account" />
        <div class="columns is-vcentered">
          <!-- Template Select -->
          <div class="column">
            <b-field label="Template">
              <b-select
                v-model="template"
                placeholder="Select Template"
                :loading="loadingTemplates"
                :disabled="loadingTemplates || !account"
                expanded>
                <option v-for="template in templates" :value="template.id" :key="template.name">
                  <span v-if="template.name"> {{ template.name }}</span>
                  <span v-else> ID: {{ template.id }}</span>
                </option>
              </b-select>
            </b-field>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot space-between">
        <button class="button" type="button" @click="$parent.close()">Close</button>
        <b-button
          type="is-primary"
          native-type="submit"
          :loading="saving"
          :disabled="saving || !ready"
        >Publish</b-button>
      </footer>
    </div>
  </form>

</template>

<script>
import { mapGetters } from 'vuex'
import PlatformAccountSelect from '@/components/PlatformAccountSelect'

export default {
  components: {
    PlatformAccountSelect
  },
  data () {
    return {
      account: null,
      template: null,
      templates: [],
      saving: false,
      loadingTemplates: false
    }
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      if (this.account !== null) {
        switch (this.account.platform.code) {
          case 'shopify':
            return this.context.route + '/platform-accounts/' + this.account.id + '/push-products/'
          case 'fabric':
            return this.context.route + '/fabric/' + this.account.id + '/pim/push-bulk-products/'
          default:
            return null
        }
      }
      return null
    },
    json () {
      const body = {}
      if (this.account !== null) {
        body.products = [this.product.id]
      }
      if (this.template !== null) {
        body[`${this.account.platform.code}_template_id`] = this.template
      }
      return body
    },
    ready () {
      return this.account !== null && this.endpoint !== null
    }
  },
  methods: {
    save () {
      this.saving = true
      this.$http.post(this.endpoint, this.json).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Product published!' })
        this.$parent.close()
      }).finally(() => {
        this.saving = false
      })
    },
    getTemplates () {
      if (this.account !== null) {
        this.loadingTemplates = true
        const endpoint = `${this.context.route}/${this.account.platform.code}/${this.account.id}/templates/`
        this.$http.get(endpoint).then(response => {
          this.templates = response.data.results
        }).finally(() => {
          this.loadingTemplates = false
        })
      }
    }
  },
  mounted () {

  },
  watch: {
    account () {
      this.getTemplates()
    }
  },
  props: ['product']
}
</script>
