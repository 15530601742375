<template>
    <div>
      <!-- Content -->
      <div class="mb-lg">
        <div class="mb-lg">
          <p class="has-text-weight-bold mb-md">WooCommerce Installation Complete!</p>
          <p>
            Your WooCommerce integration has been installed and configured. If you
            need to make any changes, you can return to this task or access the
            <router-link class="has-text-info" :to="{ name: 'settings.integrations.woocommerce', params: { id: this.account.id }}">WooCommerce settings</router-link>
            page.
          </p>
        </div>
      </div>
      <!-- Navigate -->
      <div class="py-lg">
        <div class="columns is-centered">
          <div class="column">
            <b-button
              type="is-light rounded-lg"
              size="is-medium"
              @click="goBack"
              expanded
              ><b-icon icon="arrow-left"></b-icon>
            </b-button>
          </div>
          <div class="column">
            <b-button
              type="is-primary rounded-lg"
              size="is-medium"
              expanded
              @click="goForward"
              :disabled="loading || saving"
              :loading="saving"
            >Complete Task</b-button>
          </div>
        </div>
      </div>
    </div>
  </template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {

  },
  computed: {
    ...mapGetters(['app', 'context'])
  },
  data () {
    return {
      loading: true,
      saving: false,
      errors: {}
    }
  },
  methods: {
    goBack () {
      this.$emit('navigate', 'Orders')
    },
    async goForward () {
      this.saving = true
      await this.completeTask()
      this.saving = false
    },
    completeTask () {
      const endpoint = this.context.route + '/assigned-tasks/' + this.$route.params.task + '/complete/'
      return this.$http.put(endpoint).then(() => {
        this.$router.push({ name: 'onboarding.tasks', params: { connection: this.connection.id } })
      })
    },
    loadTasks () {
      this.loading = true
      const endpoint = this.context.route + '/onboarding-tasks/?type=conditional'
      this.$http.get(endpoint).then(response => {
        this.tasks = response.data.results
        this.loading = false
      })
    }
  },
  mounted () {
    this.loadTasks()
  },
  props: {
    connection: {
      type: Object,
      required: true
    },
    task: {
      type: Object,
      required: true
    },
    account: {
      type: Object,
      required: true
    }
  }
}
</script>
