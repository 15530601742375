<template>
  <InspectionModal
    :endpoint="endpoint"
    :loadDetail="loadDetail"
    :seed="selected">
    <template v-slot:default="props">
      <form>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Inspect Product</p>
          </header>
          <section class="modal-card-body">
            <!-- Loaded -->
            <div v-if="!props.loading && props.record !== null" class="">

              <!-- Product Info -->
              <div class="columns is-centered">
                <div class="column is-two-fifths">
                  <!-- Image Found -->
                  <div v-if="props.record.primary_media !== null">
                    <figure class="image is-square mb-sm">
                      <img class="rounded-lg" :src="cdnTransform(props.record.primary_media.url, 800, 800, 'fill')" />
                    </figure>
                    <div class="is-size-7 has-text-grey has-text-centered">
                      <p>Native Photo Dimensions</p>
                      <p class="">{{ props.record.primary_media.width }}W x {{ props.record.primary_media.height }}H</p>
                    </div>
                  </div>
                  <!-- No Image Found -->
                  <div v-else>
                    <figure class="image is-square mb-sm">
                      <img class="rounded-lg" :src="cdnTransform(null, 800, 800, 'fill')" />
                    </figure>
                    <div class="is-size-7 has-text-grey has-text-centered">
                      <p>No Photo Found!</p>
                    </div>
                  </div>
                </div>
                <div class="column">
                  <div class="px-md">
                    <div class="mb-sm">
                      <p class="is-size-5">{{ props.record.parent_name }}</p>
                      <p class="has-text-grey">
                        <span class="has-text-grey is-size-8">Product SKU </span>
                        <span class="has-text-weight-bold">{{ props.record.parent_identifier }}</span>
                      </p>
                    </div>
                    <!-- Actions -->
                    <div class="py-md">
                      <p class="is-uppercase has-text-weight-bold mb-sm is-size-7">Description</p>
                      <div class="has-text-grey is-size-7 content" v-html="stripTags(props.record.description)"></div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <!-- Loading -->
            <div v-else>
              <loading message="Loading item..." />
            </div>
          </section>
          <footer class="modal-card-foot">
            <button class="button" type="button" @click="$parent.close()">Close</button>
          </footer>
        </div>
      </form>
    </template>
  </InspectionModal>
</template>

<script>
import InspectionModal from '../InspectionModal'
import CdnTransform from '@/mixins/CdnTransform'
import stripTags from 'locutus/php/strings/strip_tags'
import { mapGetters } from 'vuex'

export default {
  components: {
    InspectionModal
  },
  data () {
    return {
      loadDetail: true
    }
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      if (this.selected !== null) {
        return this.context.route + '/products/' + this.selected.id + '/'
      }
      return null
    },
    description () {
      return this.$utils.truncate(stripTags(this.selected.description, '<p><ul><ol>'), { length: 128 })
    }
  },
  methods: {
    stripTags: stripTags
  },
  mixins: [CdnTransform],
  mounted () {

  },
  props: ['selected']
}
</script>
