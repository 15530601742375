<template>
  <div>
    <PerformanceSummary :start-date="startDate" :end-date="endDate" dimension="variant" :metric="metric"></PerformanceSummary>
  </div>
</template>
<script>
import PerformanceSummary from '@/modules/Performance/views/Summary.vue'
export default {
  data () {
    return {}
  },
  props: {
    startDate: Date,
    endDate: Date,
    metric: String
  },
  components: { PerformanceSummary }
}
</script>
