<template>
  <div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Print Packing Slip</p>
      </header>
      <section class="modal-card-body">
        <b-message :type="status.messageType">{{ status.message }}</b-message>
        <object
          v-if="isReady"
          :data="'data:application/pdf;base64,' + packingSlip.packing_slip"
          type="application/pdf"
          typemustmatch="true"
          style="width:100%; height:500px"
        ></object>
      </section>
      <footer class="modal-card-foot space-between">
        <button class="button" type="button" @click="close">Close</button>
        <a v-if="isReady"
          class="button is-primary"
          :href="url"
          target="_blank">
          Open In New Tab
        </a>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  data () {
    return {
      loading: true,
      packingSlip: null,
      errorMessage: null,
      url: null
    }
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return `${this.context.route}/shipments/${this.shipment.id}/packing_slip/`
    },
    status () {
      const status = {
        message: 'Loading packing slip...',
        messageType: 'is-info'
      }
      if (this.isReady) {
        status.message = 'Packing slip is ready to print.'
      }
      if (this.errorMessage !== null) {
        status.message = this.errorMessage
        status.messageType = 'is-danger'
      }
      return status
    },
    isReady () {
      return !this.loading && this.packingSlip !== null && this.packingSlip.packing_slip !== null
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint).then(response => {
        this.packingSlip = response.data
        // decode base64 string, remove space for IE compatibility
        const decodedData = atob(this.packingSlip.packing_slip.replace(/\s/g, ''))
        // define a fixed-length array buffer
        const buffer = new ArrayBuffer(decodedData.length)
        // define a bytes array of buffer length
        const binArray = new Uint8Array(buffer)
        // get bytes from decodedData
        for (let i = 0; i < decodedData.length; i++) {
          binArray[i] = decodedData.charCodeAt(i)
        }
        // create a new application/pdf blob object
        const blob = new Blob([binArray], { type: 'application/pdf' })
        // create a url for the packing slip so it can be opened in a new tab.
        this.url = window.URL.createObjectURL(blob)
      }).catch(error => {
        if (error.response && error.response.status === 400) {
          this.errorMessage = error.response.data[0]
        } else {
          this.errorMessage = 'An unknown error occurred. Please contact support!'
        }
      }).finally(() => {
        this.loading = false
      })
    },
    close () {
      this.$parent.close()
      this.$emit('close')
    }
  },
  mounted () {
    this.load()
  },
  props: ['shipment']
}
</script>
