<template>
  <span class="has-text-weight-bold" :class="presenter.indicatorClass">{{ presenter.label }}</span>
</template>

<script>
export default {
  computed: {
    presenter () {
      const presenter = {
        label: 'Pending',
        tooltip: 'This return has been registered and is awaiting receipt and approval by the supplier.',
        indicatorClass: 'has-text-grey'
      }
      // if the rma is approved, but not acknowledged
      if (this.rma.status === 'approved' && this.rma.acknowledged_at === null) {
        presenter.label = 'Approved'
        presenter.tooltip = 'Return has been approved, but the merchant has not yet acknowledged receipt.'
        presenter.indicatorClass = 'has-text-warning'
      } else if (this.rma.status === 'approved' && this.rma.acknowledged_at !== null) {
        presenter.label = 'Completed'
        presenter.tooltip = 'The merchant has completed this return.'
        presenter.indicatorClass = 'has-text-success'
      } else if (this.rma.status === 'rejected' && this.rma.acknowledged_at === null) {
        presenter.label = 'Rejected'
        presenter.tooltip = 'Return has been rejected, but the merchant has not yet acknowledged receipt.'
        presenter.indicatorClass = 'has-text-warning'
      } else if (this.rma.status === 'rejected' && this.rma.acknowledged_at !== null) {
        presenter.label = 'Rejected'
        presenter.tooltip = 'The merchant has acknowledged the rejection.'
        presenter.indicatorClass = 'has-text-danger'
      }
      return presenter
    }
  },
  props: ['rma']
}
</script>
