<template>
<div class="has-background-white pa-lg rounded-lg mb-lg">
  <!-- Intro -->
  <div class="mb-lg">
    <p class="is-size-4 mb-sm">Build Proposal</p>
    <p>
      Before you can build your proposal, we need to make sure your import feed
      has been completely processed. The results of your file import are displayed
      below. Please review the import results before continuing.
    </p>
  </div>
  <!-- Results -->
  <div v-if="importFeed !== null" class="mb-xl">
    <p class="has-text-weight-bold border-b pb-sm">Import Results</p>
    <table class="table is-fullwidth is-narrow is-size-7">
      <tbody>
        <tr>
          <th width="20%">Import ID</th>
          <td>{{ importFeed.id }}</td>
        </tr>
        <tr>
          <th>Filename</th>
          <td>
            {{ importFeed.original_filename }}
            <span class="has-text-grey-light">(File ID: {{ tempfileId }})</span>
          </td>
        </tr>
        <tr>
          <th>Status</th>
          <td class="has-text-weight-bold">
            <StatusPresenter :feed="importFeed" />
            <p v-if="!loading && importFeed !== null && importFeed.status === 'queued' || importFeed.status === 'processing'" >
              <a href="#" class="has-text-weight-normal has-text-info" @click.prevent="load"> Refresh</a>
            </p>
          </td>
        </tr>
        <tr>
          <th>Number of Rows</th>
          <td>{{ importFeed.num_items }}</td>
        </tr>
        <tr>
          <th>Number of Rows Processed</th>
          <td>{{ importFeed.num_items }}</td>
        </tr>
        <tr>
          <th>Number of Items Created</th>
          <td>{{ importFeed.num_created }}</td>
        </tr>
        <tr>
          <th>Number of Items Updated</th>
          <td>{{ importFeed.num_updated }}</td>
        </tr>
        <tr>
          <th>Number of Errors</th>
          <td>{{ importFeed.num_errors }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <!-- Schedule Build -->
  <div class="pt-md mb-lg">
    <div class="columns">
      <div class="column is-one-quarter">
        <b-button
          type="is-primary"
          size="is-medium"
          :disabled="!ready || saving"
          :loading="saving"
          expanded
          @click="save"
        >Build Proposal</b-button>
      </div>
      <div class="column is-one-quarter">
        <b-button
          type="is-light"
          size="is-medium"
          :disabled="importFeed === null || importFeed.status === 'success' || importFeed.status === 'failure'"
          :loading="saving"
          expanded
          @click="$emit('feeds:updated')"
        >Refresh Status</b-button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import StatusPresenter from '@/components/Models/FeedQueue/Presenters/Status'

export default {
  components: {
    StatusPresenter
  },
  computed: {
    ...mapGetters(['api', 'context']),
    endpoint () {
      return this.context.route + '/feed-queue/'
    },
    importFeed () {
      if (this.feeds.length === 0) return null
      return this.feeds.find(feed => {
        return feed.info.proposal_feed_purpose === 'import'
      })
    },
    tempfileId () {
      if (this.feeds.length === 0) return null
      const feed = this.feeds.find(feed => {
        return feed.info.proposal_feed_purpose === 'import'
      })
      return ('proposal_tempfile_id' in feed.info)
        ? feed.info.proposal_tempfile_id
        : null
    },
    body () {
      if (!this.tempfileId) return null
      return {
        template: {
          id: this.proposal.department.template.id
        },
        direction: 'import',
        data_type: 'proposals',
        source: 'console',
        behavior: 'update',
        info: {
          proposal_id: this.proposal.id,
          proposal_feed_purpose: 'build',
          rulesets: [{
            id: this.proposal.department.brand_ruleset.id
          }, {
            id: this.proposal.department.retailer_ruleset.id
          }]
        },
        file: {
          tempfile: {
            id: this.tempfileId
          }
        }
      }
    },
    ready () {
      return (
        this.importFeed !== null &&
        this.importFeed.status === 'success' &&
        (this.importFeed.num_created > 0 || this.importFeed.num_updated > 0) &&
        this.tempfileId !== null
      )
    }
  },
  data () {
    return {
      loading: false,
      feed: null,
      saving: false
    }
  },
  methods: {
    save () {
      this.saving = true
      this.$http.post(this.endpoint + 'enqueue/', this.body).then(response => {
        this.feedQueue = response.data
        this.$http.put(this.endpoint + this.feedQueue.id + '/run/').then(response => {
          this.$buefy.toast.open({ type: 'is-success', message: 'Import scheduled!' })
          this.$router.push({ name: 'proposal.tasks.submit', params: { id: this.proposal.id } })
        }).finally(() => {
          this.saving = false
        })
      })
    }
  },
  mounted () {

  },
  props: ['proposal', 'feeds', 'loadingFeeds']
}
</script>
