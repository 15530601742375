<template>
<div>
  <!-- Actions Toolbar -->
  <div class="has-background-white rounded-lg px-md py-md mb-xxs">
    <div class="columns is-vcentered is-mobile">
      <div class="column">
        <b-button  @click.prevent="showImportModal = true" size="is-medium" type="is-light">Import Updated Template</b-button>
      </div>
      <div class="column is-narrow">
        <div class="is-size-7 has-text-grey-light has-text-right">
          <!-- Counts -->
          <p v-if="loading">Loading...</p>
          <p v-else>{{ feeds.length }} {{ (feeds.length === 1) ? 'import' : 'imports' }} found</p>
          <p><a href="#" @click.prevent="load" class="has-text-info">Refresh</a></p>
        </div>
      </div>

    </div>
  </div>
  <!-- Results -->
  <div>
    <div class="mb-md">
      <b-table
        :data="feeds"
        :mobile-cards="false"
        :loading="loading"
        hoverable
        class="is-size-7">
        <template>
          <!-- ID -->
          <b-table-column
            label="ID"
            cell-class="nowrap"
            width="96"
            v-slot="props">
            <p class="has-text-grey-light">{{ props.row.id }}</p>
          </b-table-column>
          <!-- Datatype -->
          <b-table-column
            label="Data"
            cell-class="nowrap"
            width="164"
            v-slot="props">
            <p>
              {{ $utils.upperFirst(props.row.direction) }}
              <Datatype :feed="props.row" />
            </p>
          </b-table-column>
          <!-- File -->
          <b-table-column
            label="File"
            cell-class="nowrap"
            v-slot="props">
            <p>
              <DownloadFileAs :cloudFileName="props.row.cloud_filename" :originalFileName="props.row.original_filename"/>
            </p>
          </b-table-column>
          <!-- Created At -->
          <b-table-column
            label="Queued"
            width="96"
            centered
            v-slot="props">
            <p>{{ $dates.utc(props.row.created_at).local().format('MMM D') }}</p>
            <p class="is-size-8 has-text-grey-light">{{ $dates.utc(props.row.created_at).local().format('h:mm A') }}</p>
          </b-table-column>
          <!-- Processed At -->
          <b-table-column
            label="Processed"
            width="96"
            centered
            v-slot="props">
            <div v-if="props.row.processed_at">
              <p>{{ $dates.utc(props.row.processed_at).local().format('MMM D') }}</p>
              <p class="is-size-8 has-text-grey-light">{{ $dates.utc(props.row.processed_at).local().format('h:mm A') }}</p>
            </div>
            <div v-else>
              <p class="is-size-8 has-text-grey-light">--</p>
            </div>
          </b-table-column>
          <!-- Completed At -->
          <b-table-column
            label="Completed"
            width="96"
            centered
            v-slot="props">
            <div v-if="props.row.completed_at">
              <p>{{ $dates.utc(props.row.completed_at).local().format('MMM D') }}</p>
              <p class="is-size-8 has-text-grey-light">{{ $dates.utc(props.row.completed_at).local().format('h:mm A') }}</p>
            </div>
            <div v-else>
              <p class="is-size-8 has-text-grey-light">--</p>
            </div>
          </b-table-column>
          <!-- Run Time -->
          <b-table-column
            label="Duration"
            width="96"
            centered
            v-slot="props">
            <p class="is-size-8 has-text-grey-light">
              <Runtime :start="props.row.processed_at" :end="props.row.completed_at" />
            </p>
          </b-table-column>
          <!-- Status -->
          <b-table-column
            label="Status"
            cell-class="nowrap has-text-weight-bold"
            centered
            v-slot="props">
            <span v-if="props.row.status === 'success'" class="has-text-success">Success</span>
            <span v-else-if="props.row.status === 'failure'" class="has-text-danger">Failed</span>
            <span v-else-if="props.row.status === 'processing'" class="has-text-danger">Processing</span>
            <span v-else>Queued</span>
          </b-table-column>
        </template>
        <template #empty>
          <div class="py-xxl has-text-grey has-text-centered">
            {{ (loading) ? "Loading data..." : 'No imports found!' }}
          </div>
        </template>
      </b-table>
    </div>
  </div>
  <!-- Import File -->
  <b-modal
    has-modal-card
    :active.sync="showImportModal"
    :can-cancel="['escape', 'x']"
    ><ImportFeedModal :proposal="proposal" />
  </b-modal>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Runtime from '@/components/Models/FeedQueue/Presenters/Runtime'
import Datatype from '@/components/Models/FeedQueue/Presenters/Datatype'
import ImportFeedModal from '@/components/Models/Proposal/Modals/ImportFeed'

export default {
  components: {
    Runtime,
    Datatype,
    ImportFeedModal
  },
  computed: {
    ...mapGetters(['context']),
    loading () {
      return (this.loadingFeeds)
    }
  },
  data () {
    return {
      loadingFeeds: true,
      feeds: [],
      showImportModal: false
    }
  },
  methods: {
    load () {
      this.loadingFeeds = true
      const endpoint = this.context.route + '/proposals/' + this.proposal.id + '/feeds/?order_by=-id'
      this.$http.get(endpoint).then(response => {
        this.feeds = response.data.results
        this.loadingFeeds = false
      })
    }
  },
  mounted () {
    this.load()
  },
  props: {
    proposal: {
      type: Object,
      required: true
    }
  }
}
</script>
