<template>
  <form @submit.prevent="save">
    <div class="columns">
      <div class="column">
        <div class="has-background-white rounded-xl">
          <div class="pa-lg pb-xl">
            <div class="mb-lg">
              <p class="has-text-weight-bold mb-xs">Serial Key</p>
              <div class="has-text-grey is-size-7">
                <p class="mb-md">
                  If Retailer SKU Assignment is enabled, you can choose a seed
                  value for your SKUs.
                </p>
              </div>
            </div>
            <div class="columns">
              <div class="column is-one-third">
                <!-- Prefix is disabled if retailer sku is enabled seed value exists, once we have patch request this will be enabled -->
                <b-field label="Prefix">
                  <b-input
                    type="text"
                    v-model="prefix"
                    size="is-medium"
                    expanded
                    :loading="loading"
                    :disabled="isDisabled"
                    @input="isDirty = 1">
                  </b-input>
                </b-field>
              </div>
              <div class="column is-one-third">
                <!-- Seed value is disabled if retailer sku is enabled seed value exists -->
                <b-field label="Seed Value">
                  <b-input
                    type="number"
                    :controls="false"
                    v-model="seedValue"
                    size="is-medium"
                    expanded
                    :loading="loading"
                    :disabled="isDisabled"
                    @input="isDirty = 1">
                  </b-input>
                </b-field>
              </div>
              <div class="column is-one-third">
                <!-- Next value is for reference only so it is always disabled -->
                <b-field label="Next Value">
                  <b-input
                    type="number"
                    v-model="nextValue"
                    size="is-medium"
                    expanded
                    :loading="loading"
                    :disabled="true">
                  </b-input>
                </b-field>
              </div>
            </div>
          </div>
          <div class="border-t">
            <div class="columns is-vcentered is-marginless">
              <div class="column is-hidden-mobile">
                <div class="px-sm has-text-grey-light">
                  <i v-if="isDirty">Click 'Save Preference' to save your changes</i>
                </div>
              </div>
              <div class="column is-narrow">
                <div class="px-sm">
                  <b-button
                    native-type="submit"
                    type="is-primary rounded-lg"
                    :disabled="!ready || saving || !isDirty"
                  >Save Preference</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['app', 'context']),
    endpoint () {
      return this.context.route + '/serial-keys/'
    },
    json () {
      return {
        target_content_type: 'retailer_identifier',
        seed: this.seedValue,
        prefix: this.prefix
      }
    },
    ready () {
      return (this.seedValue !== null)
    }
  },
  data () {
    return {
      isDirty: false,
      preference: null,
      retailerSkusEnabled: false,
      errors: [],
      saving: false,
      loading: false,
      seedValue: null,
      nextValue: null,
      prefix: null,
      isDisabled: false
    }
  },
  methods: {
    loadSerialKey () {
      this.loading = true
      this.$http.get(this.endpoint).then(response => {
        if (response.data.results.length > 0) {
          this.seedValue = response.data.results[0].seed
          this.nextValue = response.data.results[0].next_key
          this.prefix = response.data.results[0].prefix
          this.isDisabled = this.retailerSkusEnabled && this.seedValue
        }
        this.loading = false
      })
    },
    save () {
      this.saving = true
      // patch to be developed on the backend
      // const method = this.retailerSkusEnabled ? 'patch' : 'post'
      this.$http.post(this.endpoint, this.json).then(response => {
        this.isDirty = false
        this.loadSerialKey()
        this.$emit('preferences:updated')
        this.$buefy.toast.open({ type: 'is-success', message: 'Preference updated!' })
      }).catch(error => {
        if (error.response && error.response.status === 400) {
          this.errors = error.response.data
        }
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    const preference = this.$utils.find(this.preferences, ['code', 'automatically_generate_retailer_skus'])
    if (typeof preference !== 'undefined') {
      this.preference = preference
      if (this.preference.retailer_preference && this.preference.retailer_preference.value === '1') {
        this.retailerSkusEnabled = true
        this.loadSerialKey()
      } else {
        this.isDisabled = true
      }
    }
  },
  props: ['preferences']
}
</script>
