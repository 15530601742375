<template>
  <div class="section">
    <div class="container">
      <!-- Header -->
      <div class="mb-lg has-text-centered">
        <h1 class="is-size-4 has-text-grey-dark">Import From Shopify</h1>
      </div>
      <!-- Step 1: Select Account -->
      <div class="has-background-white pa-lg mb-lg rounded-xl">
        <div class="mb-md">
          <h1 class="has-text-primary is-size-4">Step 1: Select Account</h1>
          <p class="has-text-grey">Select the Shopify account you want to import from...</p>
        </div>
        <div class="columns">
          <div class="column is-half-desktop">
            <b-select v-model="account" placeholder="Select an account..." :disabled="loading" :loading="loading" expanded>
              <option v-for="account in shopifyAccounts" :value="account" :key="account.id">{{ account.label }}</option>
            </b-select>
          </div>
        </div>
      </div>
      <!-- Step 2: Review Settings -->
      <div class="has-background-white pa-lg mb-lg rounded-xl" v-if="account !== null">
        <div class="mb-md">
          <h1 class="has-text-primary is-size-4">Step 2: Review Settings</h1>
          <p class="has-text-grey">Review your current integration settings before import.</p>
        </div>
        <div>
          <schedule-shopify-import v-if="account !== null" :account="account.detail" />
        </div>
      </div>
      <!-- Step 3: Schedule Import -->
      <form @submit.prevent="importProducts">
        <div class="has-background-white pa-lg mb-lg rounded-xl" v-if="account !== null">
          <div class="mb-lg">
            <h1 class="has-text-primary is-size-4">Step 3: Schedule Import</h1>
            <p class="has-text-grey">When ready, click the button below to schedule your import.</p>
          </div>
          <div>
            <b-button type="is-primary" size="is-medium" native-type="submit" :disabled="isDirty || scheduling">Schedule Import</b-button>
          </div>
        </div>
      </form>

    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ScheduleShopifyImport from '../components/ScheduleShopifyImport'
export default {
  components: {
    ScheduleShopifyImport
  },
  computed: {
    ...mapGetters(['context']),
    endpoints () {
      const endpoints = {
        load: this.context.route + '/platform-accounts/?platform=shopify'
      }
      if (this.account !== null) {
        endpoints.import = this.context.route + '/platform-accounts/' + this.account.id + '/pull-products/'
      }
      return endpoints
    },
    shopifyAccounts () {
      if (this.accounts.length === 0) return []
      // in case a vendor has 2 shopify accounts (not sure why, but possible)
      // loop through the accounts and attempt to name them so that the
      // user can differentiate the accounts based on store name?
      const accounts = []
      this.accounts.forEach(account => {
        if (account.platform.code === 'shopify') {
          let store = null
          account.credentials.forEach(credential => {
            if ('shopify_shop_name' in credential) {
              store = credential.shopify_shop_name.values[0].value
            }
          })
          const label = (store !== null)
            ? account.platform.name + ': ' + store + ' (ID: ' + account.id + ')'
            : account.platform.name + ' (ID: ' + account.id + ')'
          accounts.push({
            id: account.id,
            label: label,
            detail: account
          })
        }
      })
      return accounts
    }
  },
  data () {
    return {
      loading: false,
      isDirty: false,
      accounts: [],
      account: null
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoints.load).then(response => {
        this.accounts = response.data.results
        // if only one account (i.e. most people), just select it...
        if (response.data.results.length === 1) {
          this.account = response.data.results[0]
        }
        this.loading = false
      })
    },
    importProducts () {
      this.scheduling = true
      this.$http.get(this.endpoints.import).then(response => {
        this.scheduling = false
      })
    }
  },
  mounted () {
    this.load()
  }
}
</script>
