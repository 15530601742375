<template>
  <InspectionModal
    :endpoint="endpoint"
    :loadDetail="loadDetail"
    :seed="selectedSubscription">
    <template v-slot:default="props">
      <div>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Next invoice on
              {{ $dates.utc(selectedSubscription.expires_at).local().format('MMM D, YYYY')}}
               for {{ $numbers(nextInvoiceAmount).formatCurrency({mantissa: 2, thousandSeparated: true})}}</p>
          </header>
          <section class="modal-card-body">
            <!-- Loaded -->
            <div v-if="!props.loading && props.record !== null" class="">
              <!-- Details -->
              <div class="mb-lg">
                <p class="has-text-weight-bold mb-sm">Usage this period</p>
                <p v-if="props.record.period_usage.length === 0">No usage has been reported during this period yet.</p>
                <table v-else class="table is-fullwidth is-striped is-narrow is-size-7">
                  <thead>
                    <tr>
                      <th class="has-text-centered">ID</th>
                      <th class="has-text-centered">Timestamp</th>
                      <th class="has-text-centered">Total Sales</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="usage in props.record.period_usage" :key="usage.id">
                      <td class="has-text-centered">{{usage.id}}</td>
                      <td class="has-text-centered">{{$dates.utc(usage.timestamp).local().format('MMM D, YYYY @ h:mm A')}}</td>
                      <td class="has-text-right">{{$numbers(usage.quantity).formatCurrency({ thousandSeparated: true })}}</td>
                    </tr>
                  </tbody>
                  <tfoot>
                      <tr>
                          <td class="has-text-centered">Total</td>
                          <td></td>
                          <td class="has-text-right">{{$numbers(periodUsage).formatCurrency({thousandSeparated:true})}}</td>
                      </tr>
                  </tfoot>
                </table>
              </div>
            </div>
            <!-- Loading -->
            <div v-else>
              <loading message="Loading sales history..." />
            </div>
          </section>
          <footer class="modal-card-foot">
            <button class="button" type="button" @click="$parent.close()">Close</button>
          </footer>
        </div>
      </div>
    </template>
  </InspectionModal>
</template>

<script>
import InspectionModal from '../InspectionModal'
import { mapGetters } from 'vuex'

export default {
  components: {
    InspectionModal
  },
  data () {
    return {
      loadDetail: false,
      priceBreaks: {
        10000: 59.99,
        50000: 249.99,
        100000: 399.99,
        500000: 1499.99,
        Infinity: 1999.99
      }
    }
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      const start = this.$dates(this.selectedSubscription.start_at).unix()
      const end = this.$dates(this.selectedSubscription.expires_at).unix()
      return `${this.context.route}/subscriptions/${this.selectedSubscription.id}/usages/?order_by=-id&timestamp_gte=${start}&timestamp_lt=${end}&`
    },
    periodUsage () {
      return this.selectedSubscription.period_usage.reduce((acumulator, currentValue) => acumulator + currentValue.quantity, 0)
    },
    nextInvoiceAmount () {
      const breaks = Object.keys(this.priceBreaks)
      const usage = this.periodUsage
      const amount = this.priceBreaks[breaks[0]]
      for (let i = 0; i < breaks.length; i++) {
        if (usage >= breaks[i]) {
          continue
        } else {
          return this.priceBreaks[breaks[i]]
        }
      }
      return amount
    }
  },
  methods: {
  },
  mounted () {

  },
  props: ['selectedSubscription']
}
</script>
