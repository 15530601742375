import Vue from 'vue'
import VueRouter from 'vue-router'

// import route definitions
import AuthRoutes from '@/modules/Auth/routes.js'
import HomeRoutes from '@/modules/Home/routes.js'
import CatalogRoutes from '@/modules/Catalog/routes.js'
import CategoryRoutes from '@/modules/Categories/routes.js'
import ComplianceRoutes from '@/modules/Compliance/routes.js'
import CommissionProfileRoutes from '@/modules/CommissionProfiles/routes.js'
import ContextRoutes from '@/modules/Context/routes.js'
import ConnectionRoutes from '@/modules/Connections/routes.js'
import DepartmentRoutes from '@/modules/Departments/routes.js'
import DiscoverRoutes from '@/modules/Discover/routes.js'
import ExceptionRoutes from '@/modules/Exceptions/routes.js'
import EdiRoutes from '@/modules/Edi/routes.js'
import FinanceRoutes from '@/modules/Finance/routes.js'
import ImportRoutes from '@/modules/Import/routes.js'
import InstagramRoutes from '@/modules/Instagram/routes.js'
import JobRoutes from '@/modules/Jobs/routes.js'
import NetworkRoutes from '@/modules/Network/routes.js'
import OnboardingRoutes from '@/modules/Onboarding/routes.js'
import OrderRoutes from '@/modules/Orders/routes.js'
import PaymentRoutes from '@/modules/Payments/routes.js'
import ProposalRoutes from '@/modules/Proposals/routes.js'
import RegisterRoutes from '@/modules/Register/routes.js'
import ReportsRoutes from '@/modules/Reports/routes.js'
import SettingsRoutes from '@/modules/Settings/routes.js'
import ShopifyRoutes from '@/modules/Shopify/routes.js'
import SupplierRoutes from '@/modules/Suppliers/routes.js'
import TemplateRoutes from '@/modules/Templates/routes.js'
import TransformerRoutes from '@/modules/Transformers/routes.js'
import UserRoutes from '@/modules/User/routes.js'
import UsersRoutes from '@/modules/Users/routes.js'
import RulesetRoutes from '@/modules/Rulesets/routes.js'
import RetailerOnboardingRoutes from '@/modules/RetailerOnboarding/routes.js'

// import vuex store
import store from '@/store'

// install router
Vue.use(VueRouter)

// compile module routes
const baseRoutes = []
const routes = baseRoutes.concat(
  AuthRoutes,
  HomeRoutes,
  SupplierRoutes,
  CatalogRoutes,
  CategoryRoutes,
  ComplianceRoutes,
  CommissionProfileRoutes,
  ConnectionRoutes,
  ContextRoutes,
  DepartmentRoutes,
  DiscoverRoutes,
  EdiRoutes,
  ExceptionRoutes,
  FinanceRoutes,
  ImportRoutes,
  InstagramRoutes,
  JobRoutes,
  NetworkRoutes,
  OnboardingRoutes,
  OrderRoutes,
  PaymentRoutes,
  ProposalRoutes,
  RegisterRoutes,
  ReportsRoutes,
  RulesetRoutes,
  RetailerOnboardingRoutes,
  SettingsRoutes,
  ShopifyRoutes,
  TemplateRoutes,
  TransformerRoutes,
  UserRoutes,
  UsersRoutes
)

// improve scroll behavior
// https://vuejsdevelopers.com/2017/04/16/vue-js-browser-button-ux/
// consider this:
// https://github.com/vuejs/vue-router/blob/dev/examples/scroll-behavior/app.js
const scrollBehavior = (to, from, savedPosition) => {
  if (savedPosition) {
    return savedPosition
  }
}

// instantiate router
const router = new VueRouter({
  linkActiveClass: 'is-active',
  mode: 'history',
  routes: routes,
  scrollBehavior
})

// Vue Router Navigation Guards

// Clean up and/or fire analytics
// With each route change, we might need to clean up state (e.g. the hamburger
// menu) or maybe fire off analytics
router.beforeEach((to, from, next) => {
  // If unsaved changes then show the confirm modal and set the navigate to path
  if (store.state.unsavedChanges) {
    store.dispatch('setShowUnsavedChangesModal', true)
    store.dispatch('setNavigateToPath', to.name)
    next(false)
  } else {
    store.dispatch('navigate')
    next()
  }
})

// Redirect if Authenticated
// If we detect that the user is authenticated (generally by successfully
// passing the /me/ request) and they are trying to revisit the authentication
// view, just redirect them to the dashboard page view.
// router.beforeEach((to, from, next) => {
//   if (store.getters.authenticated && to.path === '/auth/login') {
//     next({ path: '/orders' })
//     return
//   }
//   next()
// })

// Redirect Guests
// If the route is intended to be a publically accessible page, we can skip
// the auth checks and allow the view to be displayed. All other routes
// are checked for presence of auth markers and redirected if missing. Auth
// markers do not guarantee user is authenticated; of course, that's what
// the api handles anyways.
router.beforeEach((to, from, next) => {
  const localToken = localStorage.getItem('rct')
  if (to.matched.some(record => record.meta.skipAuth) || localToken !== null) {
    next()
  } else {
    store.dispatch('redirectGuest', to.fullPath)
    next(false)
  }
})

// Skip Context Checks
// If the route is intended to be a publically accessible page, or can
// be accessed witout context, skip the context check. Redirect all
// other routes to the context selector.
router.beforeEach((to, from, next) => {
  // if the route allows us to skip context selection...
  if (to.matched.some(record => record.meta.skipContext)) {
    next()
  // or if we have context (or have an unverified context), let user continue...
  } else if (store.state.auth.context !== null || localStorage.getItem('rcc') !== null) {
    next()
  // otherwise, redirect user to context page
  } else {
    next({ name: 'context', query: { intended: (to.path !== '/') ? to.path : null } })
  }
})

// Redirect Forced Password Changes
// If an authenticated user is required to reset their password, redirect them to
// a password change page. Unlike the reset password, the user must be authenticated
// to perform this action.
router.beforeEach((to, from, next) => {
  if (
    store.state.auth.user &&
    store.state.auth.user.requirePasswordChange &&
    to.path !== '/auth/change-password'
  ) {
    next({ name: 'auth.change' })
  } else {
    next()
  }
})

export default router
