<template>
  <div class="section">
    <div class="container">
      <!-- Page Header -->
      <div class="mb-lg">
        <div class="columns is-vcentered">
          <div class="column is-one-third">
            <breadcrumbs :history="[{ label: 'Orders', name: 'orders.dashboard'}]" current="Credits" />
          </div>
          <div class="column is-one-third">
            <h1 class="has-text-centered is-size-4">Credits</h1>
          </div>
        </div>
      </div>
      <!-- Filters Toolbar -->
      <div class="has-background-white rounded-lg pa-md mb-xxs">
        <div class="columns">
          <div class="column">
            <CreditFilters :applied-filters="filters" @filter="filter" />
          </div>
          <div class="column is-narrow">
            <b-select v-model="orderBy" size="is-medium" expanded @input="sort">
              <option value="-id">Most Recent</option>
              <option value="id">Oldest</option>
            </b-select>
          </div>
          <div class="column is-narrow">
            <b-button size="is-medium" class="is-fullwidth" icon-left="download" @click="showExportModal = !showExportModal">Export</b-button>
          </div>
        </div>
      </div>

      <!-- Actions Toolbar -->
      <div class="has-background-white rounded-lg px-md py-sm mb-xxs">
        <div class="columns is-vcentered is-mobile">
          <div class="column is-narrow">
            <b-dropdown :disabled="loading || selected.length === 0">
              <button class="button" slot="trigger" slot-scope="{ active }" :disabled="selected.length === 0">
                <span>Actions</span>
                <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
              </button>
              <template v-if="context.isSupplier()">
                <b-dropdown-item has-link><a href="#" @click.prevent class="has-text-grey-light">No Actions</a></b-dropdown-item>
              </template>
              <template v-if="context.isMerchant()">
                <b-dropdown-item has-link><a href="#" @click.prevent class="has-text-grey-light">No Actions</a></b-dropdown-item>
              </template>
            </b-dropdown>
          </div>
          <div class="column">
            <div class="has-text-right is-size-7">
              <!-- Counts -->
              <p v-if="loading">Loading...</p>
              <p v-else>{{ total }} credits found.</p>
            </div>
          </div>
        </div>
      </div>

      <!-- Results -->
      <div>
        <div class="mb-md">
          <b-table
            :data="results"
            :checked-rows.sync="selected"
            :mobile-cards="false"
            :loading="loading"
            hoverable
            checkable
            checkbox-position="left"
            class="is-size-7">
            <template>
              <!-- RC ID (since RMA Number not always required) -->
              <b-table-column
                label="ID"
                cell-class="nowrap"
                width="128"
                v-slot="props">
                <a href="#"
                  class="has-text-weight-bold has-text-info"
                  @click.prevent="inspectCredit = props.row"
                >{{ props.row.id }}</a>
              </b-table-column>
              <!-- Credit Memo # -->
              <b-table-column
                label="Credit Memo #"
                header-class="nowrap"
                cell-class="nowrap"
                width="196"
                v-slot="props">
                <p v-if="props.row.credit_number">{{ props.row.credit_number }}</p>
                <p v-else class="has-text-grey-light is-italic">None Provided</p>
              </b-table-column>
              <!-- PO # -->
              <b-table-column
                label="PO #"
                cell-class="nowrap"
                v-slot="props">
                {{ props.row.order.purchase_order_number }}
              </b-table-column>
              <!-- Supplier -->
              <b-table-column
                v-if="context.isMerchant()"
                label="Supplier"
                header-class="nowrap"
                cell-class="nowrap"
                v-slot="props">
                {{ props.row.brand.name }}
              </b-table-column>
              <!-- Merchant -->
              <b-table-column
                v-if="context.isSupplier()"
                label="Merchant"
                header-class="nowrap"
                cell-class="nowrap"
                v-slot="props">
                {{ props.row.retailer.name }}
              </b-table-column>
              <!-- Registered -->
              <b-table-column
                label="Registered"
                header-class="nowrap"
                cell-class="nowrap"
                centered
                width="128"
                v-slot="props">
                <span v-if="props.row.rmad_at === null">--</span>
                <span v-else>{{ $dates.utc(props.row.credited_at).local().format('MMM D, YYYY') }}</span>
              </b-table-column>
              <!-- Merchant Status -->
              <b-table-column
                label="Merchant Status"
                header-class="nowrap"
                cell-class="nowrap"
                centered
                width="128"
                v-slot="props">
                <MerchantStatus :credit="props.row" />
              </b-table-column>
            </template>
            <template #empty>
              <div class="py-xxl has-text-grey has-text-centered">
                {{ (loading) ? "Loading data..." : 'No credit memos found!' }}
              </div>
            </template>
          </b-table>
        </div>
        <b-pagination
          :total="total"
          :current.sync="currentPage"
          :rounded="true"
          :per-page="perPage"
          @change="paginate">
        </b-pagination>
      </div>

      <!-- Inspect Credit -->
      <b-modal
        v-if="(inspectCredit !== null)"
        has-modal-card
        :active="(inspectCredit !== null)"
        :can-cancel="['escape', 'x']"
        @close="closeModals"
        ><CreditModal :selectedCredit="inspectCredit" />
      </b-modal>

      <!-- Inspect Order -->
      <b-modal
        has-modal-card
        scroll="keep"
        :active.sync="showOrderLinesModal"
        :can-cancel="['x']"
        @close="closeModals"
        ><OrderLinesModal :selectedOrder="inspectOrder" />
      </b-modal>

      <!-- Bulk Accept -->
      <!-- <b-modal
        v-if="context.isMerchant()"
        has-modal-card
        scroll="keep"
        :active.sync="showAcceptInvoicesModal"
        :can-cancel="['x']"
        ><accept-invoices-modal :invoices="selected" @reload="reload"></accept-invoices-modal>
      </b-modal> -->

      <!-- Modal: Export  -->
      <b-modal
        has-modal-card
        scroll="keep"
        :active.sync="showExportModal"
        :can-cancel="['x']"
        ><div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Export Orders</p>
          </header>
          <section class="modal-card-body">
            <p class="pa-xl has-text-grey has-text-centered">
              Click 'Export' below to have an export delivered to your
              email. Please note that the export will only include
              the items that match your applied filters. Please double check
              your filters before exporting.
            </p>
          </section>
          <footer class="modal-card-foot" style="justify-content:flex-end">
            <b-button type="is-primary" @click="scheduleExport" :loading="exporting" :disabled="exporting">Export</b-button>
          </footer>
        </div>
      </b-modal>

    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Filterable from '@/mixins/Filterable'
import Exportable from '@/mixins/Exportable'
import CreditModal from '@/components/Modals/Credit/CreditModal'
import CreditFilters from '../components/Credits/CreditFilters'
import OrderLinesModal from '@/components/Modals/Order/OrderLinesModal'
import MerchantStatus from '@/components/Presenters/Credit/MerchantStatus'

export default {
  components: {
    CreditFilters,
    CreditModal,
    OrderLinesModal,
    MerchantStatus
  },
  data () {
    return {
      key: 'rmas',
      alwaysAttach: {
        short: 1
      },
      // modals
      showOrderLinesModal: false,
      showCreditModal: false,
      inspectCredit: null,
      inspectOrder: null
    }
  },
  computed: {
    ...mapGetters(['context']),
    view () {
      return (this.context.isSupplier()) ? 'Suppliers' : 'Merchants'
    },
    endpoint () {
      return this.context.route + '/credits/'
    },
    exportEndpoint () {
      return this.endpoint + 'export-email/'
    }
  },
  methods: {
    closeModals () {
      this.inspectCredit = null
      this.inspectOrder = null
    },
    showOrderLines (credit) {
      this.inspectOrder = {
        id: credit.order_id,
        purchase_order_number: credit.order.purchase_order_number
      }
      this.showOrderLinesModal = true
    },
    reload () {
      this.selected = []
      this.load()
    }
  },
  mounted () {
    this.load()
  },
  mixins: [Filterable, Exportable]
}
</script>
