<template>
  <div>
    <div v-for="notification in merchantNotifications" :key="notification.action">
      <PushNotification
        :notifications="notifications"
        :action="notification.action"
        :title="notification.title"
        :text="notification.text"
        notificationType="notifications"/>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PushNotification from './PushNotification'

export default {
  components: {
    PushNotification
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/notifications/'
    },
    merchantNotifications () {
      const notifications = []
      this.availableNotifications.forEach(notification => {
        if (notification.context.includes('retailer')) {
          notifications.push(notification)
        }
      })
      return notifications
    },
    supplierNotifications () {
      const notifications = []
      this.availableNotifications.forEach(notification => {
        if (notification.context.includes('supplier')) {
          notifications.push(notification)
        }
      })
      return notifications
    }
  },
  data () {
    return {
      availableNotifications: [{
        action: 'Orders Digest',
        title: 'Late Orders Digest',
        text: 'If you have any late purchase orders, the platform will send an email digest to the address or distribution list specified below.',
        context: ['retailer']
      }]
    }
  },
  methods: {

  },
  mounted () {

  },
  props: {
    notifications: {
      type: Array,
      required: true
    }
  }
}
</script>
