export default {
  computed: {
    status () {
      const presenter = {
        label: 'Not Finalized',
        tooltip: 'This shipment has been registered, but because the order is not closed, is subject to change.',
        indicatorClass: 'has-text-grey'
      }
      // if the shipment is closed, but not acknowledged
      if (this.shipment.status === 'closed' && this.shipment.acknowledged_at === null) {
        presenter.label = 'Sent to Retailer'
        presenter.tooltip = 'This shipment has been sent to the retailer, but the retailer has not yet acknowledged receipt.'
        presenter.indicatorClass = 'has-text-warning'
      }
      // if the shipment is closed, but acknowledged
      if (this.shipment.status === 'closed' && this.shipment.acknowledged_at !== null) {
        presenter.label = 'Received by Retailer'
        presenter.tooltip = 'The retailer has received this shipment.'
        presenter.indicatorClass = 'has-text-success'
      }
      return presenter
    },
    carrierStatus () {
      const presenter = {
        label: 'Registered',
        tooltip: 'This shipment has been registered and arranged for pickup.',
        textClass: 'has-text-black',
        progress: 0
      }
      // if the shipment is marked as delivered, that's great!
      if (this.shipment.tracking !== null && this.shipment.tracking.delivered_at !== null) {
        presenter.label = 'Delivered'
        presenter.tooltip = 'The carrier has confirmed delivery of the shipment.'
        presenter.textClass = 'has-text-success'
        presenter.progress = 100
      // if the shipment is marked as picked up, that's ok...
      } else if (this.shipment.tracking !== null && this.shipment.tracking.delivered_at !== null) {
        presenter.label = 'Picked Up'
        presenter.tooltip = 'The carrier has confirmed receipt of the shipment.'
        presenter.textClass = 'has-text-success'
        presenter.progress = 50
      }
      return presenter
    }
  }
}
