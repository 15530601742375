<template>
    <div>
      <div class="columns">
        <div class="column is-one-fifth">
          <p class="has-text-weight-bold is-size-6 mb-sm">Orders</p>
          <p class="has-text-grey is-size-7">Configure how your WooCommerce store syncs orders with {{ app.platform }}.</p>
        </div>
        <div class="column">
          <div class="has-background-white rounded-lg">
            <div class="pa-lg">
              <!-- Intro Text (Merchant) -->
              <div class="is-size-7 mb-md">
                <p class="mb-lg">
                  {{ app.platform }} can automatically <b><u>send</u></b> purchase orders
                  from your merchant partners directly to your
                  WooCommerce store. When you fulfill items, tracking numbers
                  will be automatically returned to your merchant partner.
                </p>
                <p>
                  Please note that if you enable this integration, orders from
                  <b><u>ALL</u></b> of your merchant connections on {{ app.platform }} will begin to flow
                  through this integration.
                </p>
              </div>
              <!-- Credential Status -->
              <div class="has-background-white-ter pa-md rounded-lg">
                <div class="columns is-vcentered is-mobile">
                  <div class="column is-narrow">
                    <b-icon v-if="credentialIsEnabled" icon="check" custom-class="has-text-success" />
                    <b-icon v-else icon="close" custom-class="has-text-warning" />
                  </div>
                  <div class="column">
                    <p class="has-text-weight-bold">Push Orders to WooCommerce</p>
                    <p class="has-text-grey-light is-size-8">
                      <span v-if="credentialIsEnabled">This integration is currently enabled.</span>
                      <span v-else>This integration is currently disabled.</span>
                    </p>
                  </div>
                  <div class="column is-narrow">
                    <p class="has-text-weight-bold">
                      <span v-if="credentialIsEnabled" class="has-text-success">Enabled</span>
                      <span v-else class="has-text-warning">Disabled</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="border-t">
              <div class="columns is-vcentered is-marginless">
                <div class="column">
                  <div class="px-sm has-text-grey-light">
                    <i v-if="webhook !== null">Click "Disable Integration" to stop receiving orders.</i>
                    <i v-else>Click "Enable Integration" to begin receiving new orders.</i>
                  </div>
                </div>
                <div class="column is-narrow">
                  <div class="px-sm">
                    <!-- Disable Credental -->
                    <b-button
                      v-if="credentialIsEnabled"
                      type="is-danger rounded-lg"
                      outlined
                      :disabled="saving"
                      :loading="saving"
                      @click="save"
                    >Disable Integration</b-button>
                    <!-- Subscribe to new Webhook -->
                    <b-button
                      v-if="!credentialIsEnabled"
                      type="is-primary rounded-lg"
                      :disabled="saving"
                      :loading="saving"
                      @click="save"
                    >Enable Integration</b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['app', 'context']),
    endpoint () {
      return this.context.route + '/platform-accounts/' + this.account.id + '/'
    },
    credential () {
      if (this.account.credentials.length === 0) return null
      let found = null
      this.account.credentials.forEach(credential => {
        Object.keys(credential).forEach(key => {
          if (key === 'push_order_on_order_create') {
            found = credential[key].values[0]
          }
        })
      })
      return found
    },
    credentialIsEnabled () {
      if (this.credential) {
        return (this.credential.value === '1')
      }
      return false
    },
    json () {
      // if (this.credential === null) return null
      const json = {
        credentials: [{
          credential: {
            code: 'push_order_on_order_create'
          },
          value: (this.credentialIsEnabled) ? 0 : 1
        }]
      }
      if (this.credential !== null) {
        json.credentials[0].id = this.credential.id
      }
      return json
    }
  },
  data () {
    return {
      saving: false,
      webhook: null
    }
  },
  methods: {
    save () {
      this.saving = true
      this.$http.patch(this.endpoint, this.json).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Setting updated!' })
        this.$emit('update:account')
        this.$emit('complete')
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {

  },
  props: ['account']
}
</script>
