<template>
  <svg :width="width" :height="height" :viewBox="viewBox" fill="none" xmlns="http://www.w3.org/2000/svg">
    <ellipse cx="20.062" cy="13.0815" rx="1.19672" ry="1.19672" :fill="color"></ellipse>
    <ellipse cx="20.062" cy="20.062" rx="1.19672" ry="1.19672" :fill="color"></ellipse>
    <ellipse cx="13.0815" cy="20.062" rx="1.19672" ry="1.19672" :fill="color"></ellipse>
    <ellipse cx="13.0814" cy="13.0814" rx="1.99453" ry="1.99453" :fill="color"></ellipse>
    <ellipse cx="6.1" cy="6.1" rx="1.99453" ry="1.99453" :fill="color"></ellipse>
    <ellipse cx="6.1" cy="13.0814" rx="1.99453" ry="1.99453" :fill="color"></ellipse>
    <ellipse cx="13.0814" cy="6.1" rx="1.99453" ry="1.99453" :fill="color"></ellipse>
    <ellipse cx="20.0619" cy="6.1" rx="1.99454" ry="1.99453" :fill="color"></ellipse>
    <ellipse cx="6.1" cy="20.0619" rx="1.99453" ry="1.99453" :fill="color"></ellipse>
    <path d="M40.9106 10.2431V11.9225C40.1339 10.7313 38.8912 9.98926 37.2407 9.98926C34.5417 9.98926 32.3281 12.235 32.3281 15.1252C32.3281 18.0153 34.5417 20.2611 37.2407 20.2611C38.8912 20.2611 40.1339 19.519 40.9106 18.3278V20.0072H42.6V10.2431H40.9106ZM37.4543 18.6207C35.5126 18.6207 34.0174 17.117 34.0174 15.1252C34.0174 13.1333 35.5126 11.6296 37.4543 11.6296C39.4155 11.6296 40.9106 13.1333 40.9106 15.1252C40.9106 17.117 39.4155 18.6207 37.4543 18.6207Z" :fill="color"></path>
    <path d="M49.7547 10.0612C48.1042 10.0612 46.8615 10.8053 46.0848 11.9998V6.39941H44.3955V20.1066H46.0848V18.4225C46.8615 19.617 48.1042 20.3611 49.7547 20.3611C52.4538 20.3611 54.6673 18.1092 54.6673 15.2112C54.6673 12.3131 52.4538 10.0612 49.7547 10.0612ZM49.5411 18.7163C47.58 18.7163 46.0848 17.2085 46.0848 15.2112C46.0848 13.2138 47.58 11.706 49.5411 11.706C51.4829 11.706 52.978 13.2138 52.978 15.2112C52.978 17.2085 51.4829 18.7163 49.5411 18.7163Z" :fill="color"></path>
    <path d="M57.6777 11.911V10.2652H55.9639V20.0615H57.6777V14.8695C57.6777 12.5575 59.2733 11.813 60.7507 11.8718V10.0889C59.5097 10.0889 58.3081 10.5395 57.6777 11.911Z" :fill="color"></path>
    <path d="M63.8417 20.0613H62.2461V10.2881H63.8417V20.0613Z" :fill="color"></path>
    <path d="M70.4428 20.3608C72.3454 20.3608 73.9955 19.3355 74.8109 17.7975L73.3743 16.9496C72.8307 17.975 71.7629 18.6651 70.4428 18.6651C68.5014 18.6651 67.0259 17.1468 67.0259 15.175C67.0259 13.1835 68.5014 11.6653 70.4428 11.6653C71.7435 11.6653 72.8307 12.3554 73.3161 13.4004L74.7333 12.5723C73.9955 11.0146 72.3648 9.98926 70.4428 9.98926C67.5113 9.98926 65.3369 12.2568 65.3369 15.175C65.3369 18.0933 67.5113 20.3608 70.4428 20.3608Z" :fill="color"></path>
    <path d="M31.9298 7.75979V6.11697C29.1157 5.9214 27.6202 7.3882 27.6202 9.98932V10.2827H26.0459V11.9255H27.6202V20.0613H29.3322V11.9255H31.9298V10.2827H29.3322V9.98932C29.3322 8.28783 30.2177 7.62289 31.9298 7.75979Z" :fill="color"></path>
    <ellipse cx="63.0441" cy="7.39668" rx="0.997269" ry="0.997265" :fill="color"></ellipse>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'white'
    },
    width: {
      type: Number,
      default: 160
    },
    height: {
      type: Number,
      default: 52
    },
    viewBox: {
      type: String,
      default: '0 0 80 26'
    }
  }
}
</script>
