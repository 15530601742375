<template>
  <form @submit.prevent>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Apply Transformer</p>
      </header>
      <section class="modal-card-body">
        <b-message type="is-info" class="is-size-7">
          You can select and apply a transformer. All matching attribute values will be modified as per transformer specifications.
        </b-message>
        <p class="mb-lg">Progress: <b>{{processedCount}}/{{products.length}}</b> products are transformed</p>
        <div class="mb-lg">
          <b-field label="Transformer">
            <TransformerSelect v-model="transformer" :isMandatory="true"/>
          </b-field>
        </div>
        <p class="is-size-7 has-text-grey">Note: You cannot close the modal while applying the transformers.</p>
      </section>
      <footer class="modal-card-foot space-between">
        <b-button @click="$parent.close()" :disabled="isCloseDisabled">Close</b-button>
        <b-button
          type="is-primary rounded-lg"
          @click="acceptAll"
          :loading="busy"
          :disabled="!transformer"
        >Apply to All Products</b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import TransformerSelect from '@/components/Models/Transformers/TransformerSelect'
import ApplyTransformer from '@/helpers/Requests/Transformers/ApplyTransformer'
export default {
  components: {
    TransformerSelect
  },
  data () {
    return {
      processing: false,
      requests: [],
      todo: 0,
      skipped: 0,
      success: 0,
      failed: 0,
      transformer: null,
      processedCount: 0
    }
  },
  computed: {
    ...mapGetters(['context']),
    busy () {
      return this.todo !== this.skipped + this.success + this.failed
    },
    isCloseDisabled () {
      return this.processing && (this.processedCount !== this.products.length)
    }
  },
  methods: {
    acceptAll () {
      this.reset()
      this.processing = true
      this.products.forEach(product => {
        this.requests.push(new ApplyTransformer({
          route: this.context.route,
          product: product,
          transformer: this.transformer
        }))
      })
      this.requests.forEach((request, index) => {
        this.todo++
        request.prepare()
        setTimeout(() => {
          request.run().then(response => {
            this.success++
            this.processedCount++
            if (this.processedCount === this.products.length) {
              this.$parent.close()
            }
          }).catch(() => {
            this.failed++
          })
        }, index * 1000)
      })
    },
    reset () {
      this.todo = this.skipped = this.success = this.failed = 0
      this.processing = false
    }
  },
  mounted () {

  },
  props: ['products']
}
</script>
