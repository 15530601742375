<template>
  <form @submit.prevent="update" novalidate autocomplete="off">
    <div class="columns">
      <div class="column is-one-fifth">
        <p class="has-text-weight-bold is-size-6 mb-sm">Inventory Tracking</p>
        <p class="has-text-grey is-size-7">Configure how {{ app.platform }} tracks your inventory.</p>
      </div>
      <div class="column">
        <div class="has-background-white rounded-xl">
          <div class="pa-lg">
            <!-- Loading Billing Locations -->
            <div v-if="loading">
              <div class="pa-md" v-for="index in 1" :key="index">
                <div class="placeholder placeholder-title"></div>
                <div class="placeholder placeholder-sentence" style="width:50%"></div>
              </div>
            </div>
            <!-- Location Loaded (or not) -->
            <div v-else>
              <b-field>
                <b-radio
                  v-model="inventoryPolicy"
                  native-value="managed"
                  @input="isDirty = true">
                  Managed
                </b-radio>
              </b-field>
              <b-field>
                <b-radio
                  v-model="inventoryPolicy"
                  native-value="unmanaged"
                  @input="isDirty = true">
                  Unmanaged
                </b-radio>
              </b-field>
              <b-field>
                <b-radio
                  v-model="inventoryPolicy"
                  native-value="use_variant_policy"
                  @input="isDirty = true">
                  Use Variant Policy
                </b-radio>
              </b-field>
              <div class="has-text-grey">
                <p v-if="inventoryPolicy === 'managed'"><em>{{ app.platform }} will track and share inventory positions with your retail partners.</em></p>
                <p v-else-if="inventoryPolicy === 'unmanaged'"><em>{{ app.platform }} will NOT track inventory for any items and assume ALL items are ALWAYS in stock.</em></p>
                <p v-else-if="inventoryPolicy === 'use_variant_policy'"><em>{{ app.platform }} will check each variant's attribute for the inventory policy. Variants with inventory set to "Unmanaged" will not be tracked.</em></p>
              </div>
            </div>
          </div>
          <div class="border-t">
            <div class="columns is-vcentered is-marginless">
              <div class="column">
                <div class="px-sm has-text-grey-light">
                  <i v-if="isDirty">Click 'Update' to save your changes</i>
                </div>
              </div>
              <div class="column is-narrow">
                <div class="px-sm">
                  <b-button native-type="submit" type="is-primary rounded-lg" :disabled="updating || !isDirty">Update</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['app', 'context']),
    endpoint () {
      return this.context.route + '/'
    },
    json () {
      return {
        inventory_policy: this.inventoryPolicy
      }
    }
  },
  data () {
    return {
      loading: true,
      vendor: null,
      isDirty: false,
      inventoryPolicy: null,
      errors: [],
      updating: false
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint).then(response => {
        this.vendor = response.data
        this.inventoryPolicy = response.data.inventory_policy
        this.loading = false
      })
    },
    update () {
      this.updating = true
      this.$http.patch(this.endpoint, this.json).then(response => {
        this.inventoryPolicy = response.data.inventory_policy
        this.isDirty = false
        this.updating = false
        this.$buefy.toast.open({ type: 'is-success', message: 'Settings updated!' })
      }).catch(error => {
        this.updating = false
        if (error.response && error.response.status === 400) {
          this.errors = error.response.data
        }
      })
    }
  },
  mounted () {
    this.load()
  }
}
</script>
