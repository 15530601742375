<template>
  <div>
    <!-- Content -->
    <div class="section">
      <div class="container">
        <!-- Page Header -->
        <div class="mb-md">
          <div class="columns is-vcentered">
            <div class="column is-one-third">
              <breadcrumbs :history="breadcrumbs.history" :current="breadcrumbs.current" />
            </div>
            <div class="column is-one-third">
              <h1 class="has-text-centered is-size-4">Product Detail</h1>
            </div>
          </div>
        </div>
        <!-- Product Loaded -->
        <div v-if="!loading && this.product !== null">
          <!-- Header Loaded -->
          <div class="has-background-white rounded-lg px-lg py-md mb-xxs">
            <div class="columns is-vcentered is-mobile">
              <div class="column is-narrow">
                <figure class="image is-48x48" v-if="product.primary_media !== null">
                  <img :src="cdnTransform(product.primary_media.url, 48, 48, 'fill')" class="rounded-lg" />
                </figure>
                <figure class="image is-48x48" v-else>
                  <img :src="cdnTransform(null, 48, 48, 'fill')" class="rounded-lg" />
                </figure>
              </div>
              <div class="column">
                <p class="is-size-5">{{ product.parent_name }}</p>
                <p class="has-text-grey">
                  <span class="is-size-8">Product SKU </span>
                  <span class="">{{ product.parent_identifier }}</span>
                </p>
              </div>
            </div>
          </div>
          <!-- Toolbar -->
          <div class="has-background-white rounded-lg px-lg py-md mb-lg">
            <SupplierToolbar
              v-if="context.isSupplier()"
            ></SupplierToolbar>
            <MerchantToolbar
              v-if="context.isMerchant()"
              @action:publish="showPublishModal = true"
              @action:applyTransformer="showTransformerModal = true"
            ></MerchantToolbar>
          </div>
          <!-- Content -->
          <div class="mb-md">
            <div class="columns">
              <div class="column is-flex">
                <!-- Product Info -->
                <div class="has-background-white rounded-lg px-lg py-md mb-xxs">
                  <ProductInfo :product="product" />
                </div>
              </div>
              <div class="column is-flex">
                <!-- Product Description -->
                <div class="has-background-white rounded-lg px-lg py-md mb-xxs is-fullwidth" style="width:100%">
                  <ProductDescription :product="product" />
                </div>
              </div>
            </div>
          </div>
          <!-- Product Variants -->
          <ProductVariants v-if="context.isSupplier()" :product="product" />
          <ProductOffers v-else-if="context.isMerchant()" :product="product" />
        </div>
        <!-- Loading Skeleton... -->
        <div v-else>
          <div class="has-background-white pa-lg rounded-lg mb-xs">
            <div class="placeholder placeholder-title" style="width:66%"></div>
            <div class="placeholder placeholder-sentence" style="width:50%"></div>
          </div>
          <div class="has-background-white px-lg py-xxl rounded-lg mb-lg">
            <div class="columns">
              <div class="column">
                <div class="placeholder placeholder-sentence mb-lg"></div>
                <div class="placeholder placeholder-sentence mb-lg"></div>
                <div class="placeholder placeholder-sentence"></div>
              </div>
              <div class="column">
                <div class="placeholder placeholder-sentence mb-lg"></div>
                <div class="placeholder placeholder-sentence mb-lg"></div>
                <div class="placeholder placeholder-sentence"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Publish Modal -->
    <!-- Product Inspection Modal -->
    <b-modal
      :active.sync="showPublishModal"
      has-modal-card
      :can-cancel="['escape', 'x']">
      <PublishProductModal :product="product" />
    </b-modal>
    <!-- Product Transformation Modal -->
    <b-modal
      :active.sync="showTransformerModal"
      has-modal-card
      :can-cancel="['escape', 'x']">
      <TransformerModal :loading="saving" @apply:transformer="applyTransformer"/>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CdnTransform from '@/mixins/CdnTransform'
import TransformerModal from '@/components/Models/Transformers/Modals/ApplyTransformer'
import MerchantToolbar from '../components/Product/MerchantToolbar'
import SupplierToolbar from '../components/Product/SupplierToolbar'
import ProductInfo from '../components/Product/ProductInfo'
import ProductDescription from '../components/Product/ProductDescription'
// import ProductMedia from '../components/Product/ProductMedia'
import ProductOffers from '../components/Product/ProductOffers'
import ProductVariants from '../components/Product/ProductVariants'
import PublishProductModal from '../components/Product/PublishProductModal'

export default {
  components: {
    MerchantToolbar,
    SupplierToolbar,
    ProductDescription,
    ProductInfo,
    // ProductMedia,
    ProductOffers,
    ProductVariants,
    PublishProductModal,
    TransformerModal
  },
  data () {
    return {
      loading: false,
      product: null,
      showPublishModal: false,
      showTransformerModal: false,
      saving: false
    }
  },
  computed: {
    ...mapGetters(['context']),
    breadcrumbs () {
      return {
        current: (this.loading) ? 'Loading...' : 'Product #' + this.$route.params.id,
        history: [
          { label: 'Catalog', name: this.context.isSupplier() ? 'catalog' : 'catalog.index' },
          { label: 'Products', name: 'catalog.products' }
        ]
      }
    },
    endpoint () {
      return this.context.route + '/products/' + this.$route.params.id + '/'
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint).then(response => {
        this.loading = false
        this.product = response.data
      })
    },
    applyTransformer (transformer) {
      this.saving = true
      const endpoint = this.endpoint + 'apply-transformers/'
      const json = {
        transformer_ids: [transformer.id]
      }
      this.$http.put(endpoint, json).then(() => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Transformer applied!' })
      }).catch(() => {
        this.$buefy.toast.open({ type: 'is-danger', message: 'There was a problem submitting your request, try again' })
      }).finally(() => {
        this.showTransformerModal = false
        this.saving = false
      })
    }
  },
  mixins: [CdnTransform],
  mounted () {
    this.load()
  },
  props: []
}
</script>
