import ContextSelect from './views/ContextSelect'
import CreateAccount from './views/CreateAccount'
export default [
  {
    path: '/context',
    name: 'context',
    component: ContextSelect,
    meta: {
      skipContext: true,
      hideNavigation: true
    }
  },
  {
    path: '/account',
    name: 'account',
    component: CreateAccount,
    meta: {
      skipContext: true,
      hideNavigation: true
    }
  }
]
