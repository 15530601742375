<template>
  <div>
    <p class="has-text-weight-bold mb-md">Import Filter</p>
    <form @submit.prevent="updatePlatformAccount">
      <div class="mb-lg">
        <div class="field">
          <b-radio v-model="behavior" native-value="published" :disabled="locked">Only import products that are currently published to my online store.</b-radio>
        </div>
        <div class="field">
          <b-radio v-model="behavior" native-value="collection" :disabled="locked">Only import products from a specific collection.</b-radio>
        </div>
      </div>
      <!-- Collection ID Input -->
      <div class="mb-lg">
        <div class="columns">
          <div class="column is-half-desktop">
            <b-field label="Shopify Collection ID">
              <b-input v-model="collectionId" :disabled="locked"></b-input>
            </b-field>
          </div>
        </div>
      </div>
      <div class="buttons">
        <b-button type="is-primary" native-type="submit" :disabled="locked">Update</b-button>
        <b-button type="is-light" @click="locked = !locked" :icon-left="(locked) ? 'lock-open-outline' : 'lock'">{{ (locked) ? 'Unlock Settings' : 'Lock Settings' }}</b-button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  computed: {
    ...mapGetters(['context'])
  },
  data () {
    return {
      behavior: 'published',
      locked: true,
      collectionId: null
    }
  },
  methods: {

  },
  mounted () {
    let collectionId = null
    this.account.credentials.forEach(credential => {
      if ('collection_id' in credential) {
        collectionId = credential.collection_id.values[0].value
      }
    })
    if (this.collectionId !== null) {
      this.behavior = 'collection'
      this.collectionId = collectionId
    }
  },
  props: ['account']
}
</script>
