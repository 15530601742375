<template>
<div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Remove Product?</p>
    </header>
    <section class="modal-card-body">
      <div class="mb-lg">
        <b-message v-if="generalError" type="is-danger" class="is-size-7">
          {{ generalError }}
        </b-message>
        <b-message v-else type="is-info" class="is-size-7">
          If you remove this product from this proposal, it cannot be restored (and will have to be added in a different list).
          Are you sure you want to remove this product?
        </b-message>
      </div>
      <b-field label="Select Reason">
        <b-select v-model="reason" size="is-medium" expanded placeholder="Select Reason...">
          <option value="market">Market Fit</option>
          <option value="pricing">Costs, Pricing, &amp; Margin Concerns</option>
          <option value="inventory">Inventory Concerns</option>
        </b-select>
      </b-field>
    </section>
    <footer class="modal-card-foot space-between" >
      <b-button @click="$parent.close()">Close</b-button>
      <b-button
        type="is-primary"
        :disabled="saving"
        :loading="saving"
        @click="save">Remove Product</b-button>
    </footer>
  </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['context'])
  },
  data () {
    return {
      reason: null,
      saving: false,
      generalError: null,
      errors: {}
    }
  },
  methods: {
    save () {
      this.saving = true
      const endpoint = this.context.route + '/proposals/' + this.proposal.id + '/products/' + this.product.id + '/reject/'
      const body = {
        reject_reason: this.reason
      }
      this.$http.put(endpoint, body).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Product removed!' })
        this.$emit('product:removed')
        this.$parent.close()
      }).catch(() => {
        this.generalError = 'This product cannot be removed (likely because of the status of the proposal).'
      }).finally(() => {
        this.saving = false
      })
    }
  },
  props: {
    proposal: {
      type: Object,
      required: true
    },
    product: {
      type: Object
    }
  }
}
</script>
