<template>
  <div>
    <div class="columns is-centered">
      <div class="column is-four-fifths">
        <p><img class="rounded-lg" src="https://res.cloudinary.com/revcascade/image/upload/w_900,h_300/static/placeholder-lg.png" /></p>
        <p>
          <img src="https://res.cloudinary.com/revcascade/image/upload/w_400,h_400,b_black,o_90/static/placeholder-lg.png"
            class="rounded-lg"
            style="margin-top:-128px; margin-left:32px; z-index:100"
            width="128"
          />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  computed: {
    ...mapGetters(['context'])
  },
  data () {
    return {

    }
  },
  methods: {

  },
  mounted () {

  },
  props: []
}
</script>
