<template>
  <div>
    <!-- Content -->
    <div class="has-text-grey has-text-centered">
      <p class="mb-lg">
        Please review your notification settings below:
      </p>
      <table class="table is-fullwidth has-background-light is-size-7 mb-xl">
        <!-- Order Created -->
        <!-- for connection memos -->
        <tr>
          <th class="has-text-left">Order Received</th>
          <td class="has-text-right">
            <span v-if="order && order.recipient_email !== null">{{ order.recipient_email }}</span>
            <span v-else class="has-text-warning has-text-weight-bold">OFF</span>
          </td>
        </tr>
        <tr>
          <th class="has-text-left">Order Change Request</th>
          <td class="has-text-right">
            <span v-if="change && change.recipient_email !== null">{{ change.recipient_email }}</span>
            <span v-else class="has-text-warning has-text-weight-bold">OFF</span>
          </td>
        </tr>
        <tr>
          <th class="has-text-left">Daily Order Digest</th>
          <td class="has-text-right">
            <span v-if="orderDigest && orderDigest.recipient_email !== null">{{ orderDigest.recipient_email }}</span>
            <span v-else class="has-text-warning has-text-weight-bold">OFF</span>
          </td>
        </tr>
      </table>
    </div>
    <!-- Navigate -->
    <div class="py-lg">
      <div class="columns is-centered">
        <div class="column">
          <b-button
            type="is-light rounded-lg"
            size="is-medium"
            @click="goBack"
            expanded
            ><b-icon icon="arrow-left"></b-icon>
          </b-button>
        </div>
        <div class="column">
          <b-button
            type="is-primary rounded-lg"
            size="is-medium"
            expanded
            @click="goForward"
            :disabled="saving"
            :loading="saving"
          >Complete Task</b-button>
        </div>
      </div>
      <div class="pt-lg has-text-centered">
        <p><a href="#" @click.prevent="$emit('navigate', 'Intro')">Start Over</a></p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['app', 'context']),
    order () {
      const notification = this.$utils.find(this.pushNotifications, (notification) => {
        return notification.action.name === 'Order Created'
      })
      return (typeof notification !== 'undefined') ? notification : null
    },
    change () {
      const notification = this.$utils.find(this.pushNotifications, (notification) => {
        return notification.action.name === 'Order Ship To Address Updated'
      })
      return (typeof notification !== 'undefined') ? notification : null
    },
    orderDigest () {
      const notification = this.$utils.find(this.notifications, (notification) => {
        return notification.type.name === 'Orders Digest'
      })
      return (typeof notification !== 'undefined') ? notification : null
    }
  },
  data () {
    return {
      saving: false
    }
  },
  methods: {
    goBack () {
      this.$emit('navigate', 'OrderDigest')
    },
    goForward () {
      this.saving = true
      const endpoint = this.context.route + '/assigned-tasks/' + this.task.id + '/complete/'
      this.$http.put(endpoint).then(() => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Task Completed!' })
        this.$router.push({ name: 'onboarding.tasks', params: { connection: this.connection.id } })
      }).catch(() => {
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again!' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  props: {
    connection: {
      type: Object,
      required: true
    },
    task: {
      type: Object,
      required: true
    },
    pushNotifications: {
      type: Array,
      required: true
    },
    notifications: {
      type: Array,
      required: true
    }
  }
}
</script>
