<template>
  <div class="section">
    <div class="container">
      <!-- Page Header -->
      <div class="mb-lg">
        <div class="columns is-vcentered">
          <div class="column is-one-third">
            <breadcrumbs :history="[{ label: 'Connections', name: 'connections'}]" current="Locations" />
          </div>
          <div class="column is-one-third">
            <h1 class="has-text-centered is-size-4">Connection Locations</h1>
          </div>
        </div>
      </div>
      <!-- Filters Toolbar -->
      <div class="has-background-white rounded-lg pa-md mb-xxs">
        <div class="columns">
          <div class="column">
            <LocationFilters :applied-filters="filters" @filter="filter" />
          </div>
          <div class="column is-narrow">
            <b-select v-model="orderBy" size="is-medium" expanded @input="sort">
              <option value="-id">Most Recent</option>
              <option value="id">Oldest</option>
            </b-select>
          </div>
        </div>
      </div>
      <!-- Actions Toolbar -->
      <div class="has-background-white rounded-lg px-md py-sm mb-xxs">
        <div class="columns is-vcentered is-mobile">
          <div class="column">
            <div class="has-text-right is-size-7">
              <!-- Counts -->
              <p v-if="loading">Loading...</p>
              <p v-else>{{ total }} locations found.</p>
            </div>
          </div>
        </div>
      </div>
      <!-- Results -->
      <div>
        <div class="mb-md">
          <b-table
            :data="results"
            :checked-rows.sync="selected"
            :mobile-cards="false"
            :loading="loading"
            hoverable
            class="is-size-7">
            <template>
              <!-- RC ID (since RMA Number not always required) -->
              <b-table-column
                label="ID"
                cell-class="nowrap has-text-weight-bold"
                width="98"
                v-slot="props">
                {{ props.row.id }}
              </b-table-column>
              <!-- Name-->
              <b-table-column
                label="Supplier"
                cell-class="nowrap"
                v-slot="props">
                {{ props.row.brand.name }}
              </b-table-column>
              <!-- Name-->
              <b-table-column
                label="Type"
                cell-class="nowrap"
                v-slot="props">
                {{ $utils.upperFirst(props.row.type) }}
              </b-table-column>
              <!-- Street-->
              <b-table-column
                label="Address"
                header-class="nowrap"
                cell-class="nowrap"
                v-slot="props">
                {{ props.row.address.street1 }} {{ props.row.address.street2 }}
              </b-table-column>
              <!-- City -->
              <b-table-column
                label="City"
                cell-class="nowrap"
                v-slot="props">
                {{ props.row.address.city }}
              </b-table-column>
              <!-- State -->
              <b-table-column
                label="Province"
                cell-class="nowrap"
                centered
                width="98"
                v-slot="props">
                {{ props.row.address.province }}
              </b-table-column>
              <!-- Country -->
              <b-table-column
                label="Country"
                cell-class="nowrap"
                centered
                width="98"
                v-slot="props">
                {{ props.row.address.country }}
              </b-table-column>
            </template>
            <template #empty>
              <div class="py-xxl has-text-grey has-text-centered">
                {{ (loading) ? "Loading data..." : 'No records found!' }}
              </div>
            </template>
          </b-table>
        </div>
        <b-pagination
          :total="total"
          :current.sync="currentPage"
          :rounded="true"
          :per-page="perPage"
          @change="paginate">
        </b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Filterable from '@/mixins/Filterable'
import LocationFilters from '../components/Locations/LocationFilters'
export default {
  components: {
    LocationFilters
    // LocationModal,
  },
  data () {
    return {
      key: 'connection.locations',
      alwaysAttach: {},
      // modals
      inspectLocation: null
    }
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/connection-locations/'
    }
  },
  methods: {
    closeModals () {
      this.inspectLocation = null
    }
  },
  mounted () {
    this.load()
  },
  mixins: [Filterable]
}
</script>
