<template>
  <div>
    <div class="columns">
      <div class="column is-one-fifth">
        <p class="has-text-weight-bold is-size-6 mb-sm">Notes</p>
        <p class="has-text-grey">
          Add a note for the purchase order
        </p>
      </div>
      <div class="column">
        <div class="has-background-white rounded-lg pa-md mb-xxs">
          <b-field>
            <b-input
              placeholder="Type your message here..."
              size="is-medium"
              v-model="message"
            ></b-input>
          </b-field>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {},
  computed: {
    ...mapGetters(['context'])
  },
  data: () => {
    return {
      message: null
    }
  },
  methods: {},
  mounted () {},
  props: {},
  watch: {
    message (value) {
      this.$emit('input', { message: value })
    }
  }
}
</script>
