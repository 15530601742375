<template>
  <div>
    <div class="columns">
      <div class="column is-narrow">
        <!-- Label Exists -->
        <div v-if="label !== null" class="has-text-centered" >
          <b-icon icon="file-document-outline" size="is-large" custom-class="has-text-grey-light" />
        </div>
        <!-- Checking for Label -->
        <div v-else-if="!exhausted && label === null">
          <b-icon icon="loading" size="is-medium" custom-class="has-text-grey-light mdi-spin" />
        </div>
        <!-- Label Doesn't Exist -->
        <div v-else>
          <b-icon icon="alert" size="is-large" />
        </div>
      </div>
      <div class="column">
        <div class="is-size-7">
          <div class="mb-xs">
            <div v-if="trackingNumber !== null">
              <p>Tracking Number</p>
              <p class="has-text-weight-bold has-text-grey">{{ trackingNumber }} ({{ shipment.shipping_method.name }})</p>
            </div>
            <p v-else><i>Tracking Not Yet Generated</i></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  data () {
    return {
      checking: true,
      loading: false,
      attempts: 0,
      exhausted: false,
      label: null
    }
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/shipments/' + this.shipment.id + '/shipping_label/'
    },
    trackingNumber () {
      if (this.shipment.tracking_number !== null) {
        return this.shipment.tracking_number
      }
      if (this.label !== null && this.label.tracking_number !== null) {
        return this.label.tracking_number
      }
      return null
    }
  },
  methods: {
    checkForLabel () {
      this.checking = true
      const interval = setInterval(() => {
        this.$http.get(this.endpoint).then(response => {
          if (response.data.shipping_label !== null) {
            window.clearInterval(interval)
            this.checking = false
            this.label = response.data
          }
        })
        if (++this.attempts === 3) {
          this.checking = false
          this.exhausted = true
          window.clearInterval(interval)
        }
      }, 3000)
    },
    refreshLabel () {
      this.loading = true
      this.$http.get(this.endpoint).then(response => {
        if (response.data.shipping_label !== null) {
          this.label = response.data
          this.loading = true
        }
      })
    }
  },
  mounted () {
    this.checkForLabel()
  },
  props: ['shipment']
}
</script>
