<template>
  <div>
    <div>
      <div class="mb-lg has-text-centered">
        <p class="has-text-weight-bold">Store Name</p>
        <p>{{ shopName }}</p>
        <p class="has-text-grey-light is-size-7">https://{{ shopName }}.myshopify.com</p>
      </div>
      <div class="mb-lg has-text-centered">
        <p class="has-text-weight-bold">Status</p>
        <div class="has-text-weight-bold">
          <p v-if="hasAccessToken" class="has-text-success">Connected</p>
          <p v-else class="has-text-warning">Not Connected</p>
        </div>
      </div>
    </div>
    <!-- Navigate -->
    <div class="py-lg">
      <div class="columns is-centered">
        <div class="column">
          <b-button
            type="is-light rounded-lg"
            size="is-medium"
            @click="goBack"
            expanded
            ><b-icon icon="arrow-left"></b-icon>
          </b-button>
        </div>
        <div class="column">
          <b-button
            v-if="hasAccessToken"
            type="is-primary rounded-lg"
            size="is-medium"
            expanded
            @click="goForward"
          >Continue</b-button>
          <b-button
            v-else
            type="is-primary rounded-lg"
            size="is-medium"
            expanded
            @click="redirectToShopify"
            :disabled="loading"
            :loading="loading"
          >Install App</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['app', 'api', 'context', 'services']),
    hasAccessToken () {
      let token = false
      this.account.credentials.forEach(credential => {
        if ('shopify_token' in credential) {
          token = true
        }
      })
      return token
    },
    shopName () {
      let shopName = null
      this.account.credentials.forEach(credential => {
        if ('shopify_shop_name' in credential) {
          shopName = credential.shopify_shop_name.values[0].value
        }
      })
      return shopName
    },
    installUrl () {
      return this.api.baseUrl + '/shopify/request-permission/?shop=' + this.shopName + '.myshopify.com'
    }
  },
  data () {
    return {
      nonce: null,
      loading: false,
      errors: {}
    }
  },
  methods: {
    goBack () {
      this.$emit('navigate', 'Intro')
    },
    goForward () {
      this.$emit('navigate', 'Inventory')
    },
    redirectToShopify () {
      this.loading = true
      this.$http.get(this.installUrl).then(response => {
        if (response.data.redirect_to) {
          window.location.href = response.data.redirect_to
        }
        this.loading = false
      })
    }
  },
  props: {
    connection: {
      type: Object,
      required: true
    },
    task: {
      type: Object,
      required: true
    },
    account: {
      type: Object
    }
  }
}
</script>
