<template>
  <form @submit.prevent="save" novalidate autocomplete="off">
    <div class="columns">
      <div class="column is-one-fifth">
        <p class="has-text-weight-bold is-size-6 mb-sm">Default Landing Page</p>
        <p class="has-text-grey is-size-7">
          These settings will be used to configure user default landing page.
        </p>
      </div>
      <div class="column">
        <!-- Loaded -->
        <div v-if="!loading" class="has-background-white rounded-lg">
          <div class="pa-lg pb-xl">
            <div class="mb-lg">
              <b-field expanded label="Landing Page Options">
                <b-select
                  v-model="selectedOption"
                  placeholder="Select page"
                  expanded
                  @input="isDirty = true">
                  <option v-for="option in options" :key="option.id" :value="option">{{ option.name }}</option>
                </b-select>
              </b-field>
            </div>
          </div>
          <div class="border-t">
            <div class="columns is-vcentered is-marginless">
              <div class="column is-hidden-mobile">
                <div class="px-sm has-text-grey-light">
                  <i v-if="isDirty">Click 'Update' to save your changes</i>
                </div>
              </div>
              <div class="column is-narrow">
                <div class="px-sm">
                  <b-button
                    native-type="submit"
                    type="is-primary rounded-lg"
                    :disabled="saving || !isDirty"
                  >Update</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Loading -->
        <div v-else>
          <div class="has-background-white rounded-lg pa-lg mb-lg">
            <b-skeleton width="50%"/>
            <b-skeleton width="30%" />
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  computed: {
    ...mapGetters(['app', 'api', 'auth']),
    endpoint () {
      return this.api.baseUrl + `/v1/users/${this.auth.user.id}/user-preferences/`
    },
    json () {
      const preferences = [{
        preference: { id: this.preferenceId },
        option: { id: this.selectedOption.id }
      }]
      return preferences
    }
  },
  data () {
    return {
      loading: true,
      isDirty: false,
      errors: [],
      saving: false,
      options: [],
      selectedOption: null,
      preferenceId: null,
      pageOptions: null
    }
  },
  methods: {
    load () {
      const endpoint = this.api.baseUrl + '/v1/users/me/'
      this.$http.get(endpoint).then(response => {
        const { preferences } = response && response.data
        this.pageOptions = this.$utils.find(preferences, ['code', 'default_landing_page'])
        // set the default options
        if (this.pageOptions !== null) {
          this.preferenceId = this.pageOptions.id
          this.options = this.pageOptions.options
        }
      }).finally(() => {
        this.loading = false
      })
    },
    save () {
      this.saving = true
      this.$http.post(this.endpoint, this.json).then(response => {
        this.isDirty = false
        const payload = {
          preferences: response.data.map(el => {
            return {
              user_preference: el,
              code: 'default_landing_page'
            }
          })
        }
        // Updating the user preferences object in vue store, after updating the user preferences
        this.$store.dispatch('refreshUserPreferences', payload)
        this.$buefy.toast.open({ type: 'is-success', message: 'Preferences updated!' })
      }).catch(error => {
        if (error.response && error.response.status === 400) {
          this.errors = error.response.data
        }
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    // load preferences
    this.load()
  },
  watch: {
    pageOptions () {
      // set the current value
      const preferences = this.auth.user.preferences
      if (Object.keys(preferences).length > 0) {
        const userPreference = preferences.default_landing_page
        this.selectedOption = this.options.find(el => el.id === userPreference.option_id)
      }
    }
  }
}
</script>
