<template>
  <div class="section">
    <div class="container">
      <!-- Page Header -->
      <div class="mb-md">
        <div class="columns is-vcentered">
          <div class="column is-one-third">
            <breadcrumbs :history="[{ label: 'Settings', name: 'settings'}]" current="Payment Settings" />
          </div>
          <div class="column is-one-third">
            <h1 class="has-text-centered is-size-4">Payment Settings</h1>
          </div>
        </div>
      </div>
      <!-- General Info -->
      <div class="has-background-white rounded-xl has-text-centered pa-xl">
        <b-button @click="openPlaid" type="is-primary">Open Plaid</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  computed: {
    ...mapGetters(['services'])
  },
  data () {
    return {
      plaid: null
    }
  },
  methods: {
    loadPlaidScript (src) {
      return new Promise(function (resolve, reject) {
        if (document.querySelector('script[src="' + src + '"]')) {
          resolve()
          return
        }
        const el = document.createElement('script')
        el.type = 'text/javascript'
        el.async = true
        el.src = src
        el.addEventListener('load', resolve)
        el.addEventListener('error', reject)
        el.addEventListener('abort', reject)
        document.head.appendChild(el)
      })
    },
    onPlaidScriptLoaded () {
      this.plaid = window.Plaid.create({
        clientName: this.services.plaid.clientName,
        env: this.services.plaid.env,
        key: this.services.plaid.key,
        onLoad: this.onPlaidLoaded,
        onSuccess: this.onPlaidSuccess,
        onExit: this.onPlaidExit,
        onEvent: this.onPlaidEvent,
        language: 'en',
        product: ['auth'],
        countryCodes: ['US']
      })
    },
    onPlaidScriptError (error) {
      console.log(error)
    },
    onPlaidLoaded (token, metadata) {
      console.log(token, metadata)
    },
    onPlaidSuccess (token, metadata) {
      console.log(token, metadata)
    },
    onPlaidEvent (event, metadata) {
      console.log(event, metadata)
    },
    onPlaidExit (error, metadata) {
      console.log(error, metadata)
    },
    openPlaid () {
      if (this.plaid !== null) {
        this.plaid.open()
      }
    }
  },
  created () {
    this.loadPlaidScript(this.services.plaid.scriptUrl)
      .then(this.onPlaidScriptLoaded)
      .catch(this.onPlaidScriptError)
  },
  beforeDestroy () {
    // if (this.plaid !== null) {
    //   this.plaid.exit()
    // }
  },
  mounted () {

  }
}
</script>
