<template>
  <form @submit.prevent="changeShipTo" autocomplete="chrome-off" >
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Update Ship To</p>
      </header>
      <section class="modal-card-body">
        <!-- General Errors -->
        <div v-if="'detail' in errors" class="mb-sm">
          <b-message type="is-danger">{{ errors.detail}}</b-message>
        </div>
        <!-- General Errors -->
        <div v-else class="mb-md">
          <b-message type="is-info">Complete the form below to update
          this order's 'Ship To' address. Suppliers will receive an email confirmation
          if they are subscribed to the notification. It is always a good idea to
          follow up with your supplier directly in case fulfillment is already
          underway.
          </b-message>
        </div>
        <!-- Address Form -->
        <div>
          <!-- Name -->
          <div class="mb-lg">
            <b-field
              label="Customer Name"
              :type="('name1' in errors) ? 'is-danger' : ''"
              :message="('name1' in errors) ? errors['name1'][0] : ''">
                <b-input
                  autocomplete="chrome-off"
                  type="text"
                  name="name1"
                  v-model="name1"
                  size="is-medium"
                ></b-input>
            </b-field>
          </div>
          <!-- Street -->
          <div class="mb-lg">
            <b-field
              label="Street Address"
              :type="('street1' in errors) ? 'is-danger' : ''"
              :message="('street1' in errors) ? errors['street1'][0] : ''"
              expanded>
                <b-input
                  autocomplete="chrome-off"
                  type="text"
                  name="street1"
                  v-model="street1"
                  size="is-medium"
                ></b-input>
            </b-field>
            <b-field
              :type="('street2' in errors) ? 'is-danger' : ''"
              :message="('street2' in errors) ? errors['street2'][0] : ''"
              expanded>
                <b-input
                  autocomplete="chrome-off"
                  type="text"
                  name="street2"
                  v-model="street2"
                  placeholder="Apt, Suite, etc (optional)"
                  size="is-medium"
                ></b-input>
            </b-field>
          </div>
          <!-- City -->
          <div class="mb-md">
            <b-field
              label="City"
              :type="('city' in errors) ? 'is-danger' : ''"
              :message="('city' in errors) ? errors['city'][0] : ''"
              expanded>
                <b-input
                  autocomplete="chrome-off"
                  type="text"
                  name="city"
                  v-model="city"
                  size="is-medium"
                ></b-input>
            </b-field>
          </div>
          <!-- Country, Province, Zip -->
          <div class="columns">
            <div class="column is-one-third">
              <b-field
                label="Country"
                :type="('country' in errors) ? 'is-danger' : ''"
                :message="('country' in errors) ? errors['country'][0] : ''"
                  expanded>
                  <b-select v-model="country" name="country" placeholder="Select Country" expanded size="is-medium">
                    <option value="US">United States</option>
                    <option value="CA">Canada</option>
                  </b-select>
              </b-field>
            </div>
            <div class="column is-one-third">
              <b-field
                label="State / Province"
                :type="('province' in errors) ? 'is-danger' : ''"
                :message="('province' in errors) ? errors['province'][0] : ''"
                expanded>
                  <b-input
                    maxlength="2"
                    autocomplete="chrome-off"
                    type="text"
                    name="province"
                    v-model="province"
                    size="is-medium"
                  ></b-input>
              </b-field>
            </div>
            <div class="column is-one-third">
              <b-field
                label="Postal Code"
                :type="('postal_code' in errors) ? 'is-danger' : ''"
                :message="('postal_code' in errors) ? errors['postal_code'][0] : ''"
                expanded>
                  <b-input
                    maxlength="12"
                    autocomplete="chrome-off"
                    type="text"
                    name="postalCode"
                    v-model="postalCode"
                    size="is-medium"
                  ></b-input>
              </b-field>
            </div>
          </div>
          <!-- Phone Number -->
          <div class="mb-md">
            <b-field
              label="Contact Phone Number"
              :type="('phone1' in errors) ? 'is-danger' : ''"
              :message="('phone1' in errors) ? errors['phone1'][0] : ''">
                <b-input
                  autocomplete="chrome-off"
                  type="text"
                  name="phone1"
                  v-model="phone1"
                  size="is-medium"
                ></b-input>
            </b-field>
            <p class="is-italic has-text-grey-light">
              Contact phone numbers are often required by carriers.
            </p>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot space-between">
        <button class="button" type="button" @click="$parent.close()">Close</button>
        <b-button
          native-type="submit"
          type="is-primary"
          :disabled="!isValid || saving"
          :loading="saving"
        >Update Ship To</b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  data () {
    return {
      saving: false,
      errors: {},
      name1: null,
      street1: null,
      street2: null,
      city: null,
      province: null,
      postalCode: null,
      country: null,
      email: null,
      phone1: null
    }
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/orders/' + this.order.id + '/'
    },
    isValid () {
      return (
        this.name1 &&
        this.street1 &&
        this.city &&
        this.province &&
        this.postalCode &&
        this.country
      )
    },
    json () {
      if (!this.isValid) return {}
      return {
        ship_to: {
          name1: this.name1,
          street1: this.street1,
          street2: this.street2,
          city: this.city,
          province: this.province,
          postal_code: this.postalCode,
          country: this.country,
          phone1: this.phone1
        }
      }
    }
  },
  methods: {
    changeShipTo () {
      this.saving = true
      this.$http.patch(this.endpoint, this.json).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Ship to address updated. Vendor will be notified.' })
        this.$emit('shipto:updated')
        this.$parent.close()
        this.errors = {}
      }).catch(error => {
        if (error.response && error.response.status === 400) {
          this.errors = error.response.data
        }
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    this.name1 = this.order.ship_to.name1
    this.street1 = this.order.ship_to.street1
    this.street2 = this.order.ship_to.street2
    this.city = this.order.ship_to.city
    this.province = this.order.ship_to.province
    this.postalCode = this.order.ship_to.postal_code
    this.country = this.order.ship_to.country
    this.email = this.order.ship_to.email
    this.phone1 = this.order.ship_to.phone1
  },
  props: ['order']
}
</script>
