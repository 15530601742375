<template>
<form>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Reset Progress?</p>
    </header>
    <section class="modal-card-body">
      <div class="has-text-centered py-md">
        <p>Are you sure you want to reset this task?</p>
      </div>
    </section>
    <footer class="modal-card-foot space-between">
      <button class="button" type="button" @click="$parent.close()">Cancel</button>
      <b-button
        type="is-danger"
        @click="resetTask"
        :loading="saving"
        :disabled="saving"
      >Reset Task</b-button>
    </footer>
  </div>
</form>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['context'])
  },
  data () {
    return {
      saving: false,
      errors: {}
    }
  },
  methods: {
    async resetTask () {
      this.saving = true
      try {
        if (this.proposal && this.proposal.status === 'draft') {
          await this.archiveProposal()
        }
        await this.resetInfo()
      } catch (error) {
        this.$buefy.toast.open({ type: 'is-danger', message: 'An unexpected error occured.' })
      }
      this.$parent.close()
      this.$emit('task:reset')
      this.$buefy.toast.open({ type: 'is-success', message: 'Task reset!' })
      this.saving = false
    },
    resetInfo () {
      const endpoint = this.context.route + '/assigned-tasks/' + this.task.id + '/'
      const body = {
        info: ''
      }
      return this.$http.patch(endpoint, body)
    },
    archiveProposal () {
      const endpoint = this.context.route + '/proposals/' + this.proposal.id + '/'
      const body = {
        status: 'archived'
      }
      return this.$http.patch(endpoint, body)
    }
  },
  props: {
    task: {
      type: Object,
      required: true
    },
    proposal: {
      type: Object
    }
  }
}
</script>
