<template>
  <div>
    <div class="mt-md">
      <Digest :notifications="notifications" />
    </div>
    <!-- Navigate -->
    <div class="py-lg">
      <div class="columns">
        <div class="column">
          <b-button
            type="is-light rounded-lg"
            expanded
            @click="goBack"
            size="is-medium"
            ><b-icon icon="arrow-left"></b-icon>
          </b-button>
        </div>
        <div class="column">
          <b-button
            type="is-primary rounded-lg"
            expanded
            :loading="saving"
            @click="goForward"
            size="is-medium"
          >Save &amp; Complete</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Digest from '@/components/Models/Notifications/Digest'
export default {
  components: {
    Digest
  },
  computed: {
    ...mapGetters(['app', 'context'])
  },
  data () {
    return {
      setupIsCompleted: false,
      saving: false
    }
  },
  methods: {
    goBack () {
      this.$router.push({ name: 'retailer.onboarding.transactions' })
    },
    goForward () {
      this.taskComplete()
    },
    taskComplete () {
      this.saving = true
      const endpoint = this.context.route + '/assigned-tasks/' + this.$route.params.task + '/complete/'
      const taskBody = (typeof this.task.info === 'object') ? { info: this.task.info } : { info: {} }
      taskBody.info.digest = true
      this.$http.put(endpoint, taskBody).then(() => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Task Completed!' })
        this.$router.push({ name: 'retailer.onboarding' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    if (this.task && this.task.info && this.task.info.digest) {
      this.setupIsCompleted = true
    }
  },
  props: {
    task: {
      type: Object,
      require: true
    },
    pushNotifications: {
      type: Array,
      required: true
    },
    notifications: {
      type: Array,
      required: true
    }
  }
}
</script>
