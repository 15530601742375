<template>
  <div>
    <div class="section">
      <div class="container">
        <div class="mb-md">
          <div class="columns is-vcentered">
            <div class="column is-one-third">
              <breadcrumbs :history="breadcrumbs.history" :current="breadcrumbs.current"/>
            </div>
            <div class="column is-one-third">
              <h1 class="has-text-centered is-size-4">ShipStation Settings</h1>
            </div>
          </div>
        </div>
        <!-- Push Orders (Suppliers) -->
        <div v-if="!loading && account !== null && context.isSupplier()" class="border-b mb-xl pb-xl">
          <PushOrders :account="account" @update:account="load(true)" />
        </div>
        <!-- Pull Shipments (Suppliers) -->
        <div v-if="!loading && account !== null && context.isSupplier()" class="border-b mb-xl pb-xl">
          <PullShipments :account="account" @update:account="load(true)" />
        </div>
        <!-- Tags (Suppliers) -->
        <div v-if="!loading && account !== null && context.isSupplier()" class="mb-xl pb-xl">
          <Tags :account="account" @update:account="load(true)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PushOrders from '../components/Shipstation/PushOrders'
import PullShipments from '../components/Shipstation/PullShipments'
import Tags from '../components/Shipstation/Tags'
export default {
  components: {
    PushOrders,
    PullShipments,
    Tags
  },
  data () {
    return {
      loading: true,
      account: null
    }
  },
  computed: {
    ...mapGetters(['context']),
    breadcrumbs () {
      return {
        current: (this.loading) ? 'Loading...' : 'Shipstation',
        history: [
          { label: 'Settings', name: 'settings' },
          { label: 'Integrations', name: 'settings.integrations' }
        ]
      }
    },
    endpoint () {
      return this.context.route + '/platform-accounts/' + this.$route.params.id + '/'
    }
  },
  methods: {
    load (refresh = false) {
      this.loading = (!refresh)
      this.$http.get(this.endpoint).then(response => {
        this.account = response.data
      }).finally(() => {
        this.loading = false
      })
    }
  },
  mounted () {
    this.load()
  },
  props: []
}
</script>
