<template>
  <div>
    <!-- Header -->
    <div class="columns is-mobile is-vcentered">
      <div class="column"></div>
      <div class="column">
        <p class="has-text-weight-bold has-text-centered">Order Aging</p>
      </div>
      <div class="column">
        <p class="has-text-right pr-md">
          <a href="#"
            @click.prevent="showVendorBreakout = true"
            class="has-text-info is-size-7"
          >By {{ context.isSupplier() ? 'Merchant' : 'Supplier' }}</a>
        </p>
      </div>
    </div>
    <!-- Critical -->
    <router-link :to="{ name: 'orders.list', query: { preset: 'critical'} }">
      <div class="has-background-white px-md rounded-lg mb-xxs">
        <div class="columns is-mobile is-vcentered is-marginless">
          <div class="column"><p class="has-text-weight-bold">Critically Late Orders</p></div>
          <div class="column is-one-fifth">
            <div class="has-background-white-ter pa-xs rounded-lg has-text-weight-bold has-text-centered">
              <span v-if="loading"><b-icon icon="loading" custom-class="mdi-spin" size="is-small" /></span>
              <span v-else-if="criticalOrderCount === 0" class="has-text-success">0</span>
              <span v-else class="has-text-danger">{{ criticalOrderCount }}</span>
            </div>
          </div>
        </div>
      </div>
    </router-link>
    <!-- Late -->
    <router-link :to="{ name: 'orders.list', query: { preset: 'late'} }">
      <div class="has-background-white px-md rounded-lg mb-xxs">
        <div class="columns is-mobile is-vcentered is-marginless">
          <div class="column"><p class="has-text-weight-bold">Late Orders</p></div>
          <div class="column is-one-fifth">
            <div class="has-background-white-ter pa-xs rounded-lg has-text-weight-bold has-text-centered">
              <span v-if="loading"><b-icon icon="loading" custom-class="mdi-spin" size="is-small" /></span>
              <span v-else-if="lateOrderCount === 0" class="has-text-success">0</span>
              <span v-else class="has-text-warning">{{ lateOrderCount }}</span>
            </div>
          </div>
        </div>
      </div>
    </router-link>
    <!-- Current -->
    <router-link :to="{ name: 'orders.list', query: { preset: 'current'} }">
      <div class="has-background-white px-md rounded-lg mb-xxs">
        <div class="columns is-mobile is-vcentered is-marginless">
          <div class="column"><p class="has-text-weight-bold">Current Orders</p></div>
          <div class="column is-one-fifth">
            <div class="has-background-white-ter pa-xs rounded-lg has-text-weight-bold has-text-centered">
              <span v-if="loading"><b-icon icon="loading" custom-class="mdi-spin" size="is-small" /></span>
              <span v-else class="has-text-success">{{ currentOrderCount }}</span>
            </div>
          </div>
        </div>
      </div>
    </router-link>
    <!-- Breakout Modal -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showVendorBreakout"
      :can-cancel="['x']"
      @close="showVendorBreakout = false">
      <div>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Supplier Aging</p>
          </header>
          <section class="modal-card-body is-paddingless">
            <b-table
              :data="vendors"
              :mobile-cards="false"
              :loading="loading"
              hoverable
              class="is-size-7 is-narrow">
              <template>
                <!-- Name -->
                <b-table-column
                  label="Connection"
                  cell-class="nowrap"
                  sortable
                  v-slot="props">
                  <span v-if="context.isSupplier()">{{ props.row.name }}</span>
                  <span v-else>{{ props.row.name }}</span>
                </b-table-column>
                <!-- Current -->
                <b-table-column
                  label="Current"
                  cell-class="nowrap has-text-weight-bold"
                  centered
                  width="96"
                  v-slot="props">
                  <span class="has-text-success">{{ props.row.current }}</span>
                </b-table-column>
                <!-- Late -->
                <b-table-column
                  label="Late"
                  cell-class="nowrap has-text-weight-bold"
                  centered
                  width="96"
                  v-slot="props">
                  <span v-if="props.row.less_than_2_days_late === 0 && props.row.between_2_and_5_days_late === 0"
                    class="has-text-success"
                  >0</span>
                  <span v-else class="has-text-warning">{{ props.row.less_than_2_days_late + props.row.between_2_and_5_days_late }}</span>
                </b-table-column>
                <!-- Critical -->
                <b-table-column
                  label="Critical"
                  cell-class="nowrap has-text-weight-bold"
                  centered
                  width="96"
                  v-slot="props">
                  <span v-if="props.row.greater_than_5_days_late === 0" class="has-text-success">0</span>
                  <span v-else class="has-text-danger">{{ props.row.greater_than_5_days_late }}</span>
                </b-table-column>
              </template>
              <template #empty>
                <div class="py-xxl has-text-grey has-text-centered">
                  {{ (loading) ? "Loading data..." : 'No partners found!' }}
                </div>
              </template>
            </b-table>
          </section>
          <footer class="modal-card-foot">
            <button class="button" type="button" @click="showVendorBreakout = false">Close</button>
          </footer>
        </div>
      </div>
    </b-modal>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  data () {
    return {
      loading: false,
      vendors: [],
      showVendorBreakout: false
    }
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/orders-summary/'
    },
    criticalOrderCount () {
      let count = 0
      if (this.vendors.length > 0) {
        this.vendors.forEach(vendor => {
          count += vendor.greater_than_5_days_late
        })
      }
      return count
    },
    criticalOrderVendors () {
      const vendors = []
      this.vendors.forEach(vendor => {
        if (vendor.greater_than_5_days_late > 0) {
          vendors.push(vendor)
        }
      })
      return this.$utils.orderBy(vendors, ['greater_than_5_days_late'], ['desc'])
    },
    lateOrderCount () {
      let count = 0
      if (this.vendors.length > 0) {
        this.vendors.forEach(vendor => {
          count += vendor.between_2_and_5_days_late
          count += vendor.less_than_2_days_late
        })
      }
      return count
    },
    currentOrderCount () {
      let count = 0
      if (this.vendors.length > 0) {
        this.vendors.forEach(vendor => {
          count += vendor.current
        })
      }
      return count
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint).then(response => {
        this.vendors = this.$utils.sortBy(response.data, ['name'])
        this.loading = false
      })
    }
  },
  mounted () {
    this.load()
  },
  props: []
}
</script>
