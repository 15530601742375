<template>
  <div>
    <!-- Content -->
    <div class="mb-lg">
      <p class="mb-md">
        Please confirm your contacts below.
      </p>
      <div class="mb-md">
        <p class="has-text-weight-bold">Primary Business Contact</p>
        <p>{{ supplier.connections_manager.first_name }} {{ supplier.connections_manager.last_name }}</p>
      </div>
      <div class="mb-md">
        <p class="has-text-weight-bold">Merchandising Contact</p>
        <p>{{ supplier.merchandising_manager.first_name }} {{ supplier.merchandising_manager.last_name }}</p>
      </div>
      <div class="mb-md" v-if="connection.level === 'transactions_and_products'">
        <p class="has-text-weight-bold">Fulfillment Contact</p>
        <p>{{ supplier.shipping_manager.first_name }} {{ supplier.shipping_manager.last_name }}</p>
      </div>
    </div>
    <!-- Navigate -->
    <div class="py-lg">
      <div class="columns is-centered">
        <div class="column">
          <b-button
            type="is-light rounded-lg"
            size="is-medium"
            @click="goBack"
            expanded
            ><b-icon icon="arrow-left"></b-icon>
          </b-button>
        </div>
        <div class="column">
          <b-button
            type="is-primary rounded-lg"
            size="is-medium"
            expanded
            @click="goForward"
            :disabled="saving"
            :loading="saving"
          >Complete Task</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['app', 'context'])
  },
  data () {
    return {
      saving: false
    }
  },
  methods: {
    goBack () {
      this.$emit('navigate', 'FulfillmentContact')
    },
    goForward () {
      this.saving = true
      const endpoint = this.context.route + '/assigned-tasks/' + this.task.id + '/complete/'
      this.$http.put(endpoint).then(() => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Task Completed!' })
        this.$router.push({ name: 'onboarding.tasks', params: { connection: this.connection.id } })
      }).catch(() => {
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again!' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  props: {
    connection: {
      type: Object,
      required: true
    },
    task: {
      type: Object,
      required: true
    },
    supplier: {
      type: Object,
      required: true
    }
  }
}
</script>
