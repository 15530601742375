<template>
<div>
  <div class="has-background-white pa-xl rounded-lg mb-lg">
    <div class="columns is-centered">
      <div class="column">
        <!-- Header -->
        <div class="border-b pb-md">
          <div class="columns">
            <div class="column">
              <router-link :to="{ name: 'proposal.tasks.submit', params: { id: proposal.id } }" class="has-text-info">&lt; Return to Workflow</router-link>
            </div>
            <div class="column">
              <p class="has-text-centered"><b>Proposal Imports</b></p>
            </div>
            <div class="column">
              <p class="has-text-right">
                <a href="#" @click.prevent="$emit('feeds:updated')" class="has-text-info">Refresh</a>
              </p>
            </div>
          </div>
        </div>
        <!-- Feed List -->
        <div>
          <div v-if="!loadingFeeds">
            <div v-for="(feed, index) in feeds" :key="feed.id">
              <div :class="(index !== 0) ? 'border-t' : null" class="pt-md mb-md">
                <ProposalFeed :feed="feed" @inspect:feed="inspectFeed" />
              </div>
            </div>
          </div>
          <div v-else>
            <div v-for="index in 3" :key="index" class="pt-md mb-md" :class="(index !== 0) ? 'border-t' : null">
              <div class="columns">
                <div class="column is-half">
                  <b-skeleton height="20" />
                  <b-skeleton width="50%" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Inspection Modal -->
  <b-modal
    has-modal-card
    :active="(feed !== null)"
    :can-cancel="['escape', 'x']"
    @close="feed = null"
    ><FeedQueueModal :selectedFeed="feed" />
  </b-modal>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import ProposalFeed from '../components/Feeds/ProposalFeed'
import FeedQueueModal from '@/components/Modals/FeedQueue/FeedQueueModal'

export default {
  components: {
    ProposalFeed,
    FeedQueueModal
  },
  computed: {
    ...mapGetters(['context'])
  },
  data () {
    return {
      feed: null
    }
  },
  methods: {
    inspectFeed (feed) {
      this.feed = feed
    }
  },
  props: ['proposal', 'feeds', 'loadingFeeds']
}
</script>
