<template>
  <div>
    <div class="has-background-white rounded-lg">
      <div class="columns is-vcentered">
      <div class="column px-lg">
        <span>Add categories and commission values</span>
        <b-button
          type="is-primary rounded-lg"
          class="is-pulled-right px-md"
          @click="showCategories=true"
        >Add categories
        </b-button>
      </div>
    </div>
    <div class="border-t" />
    <!-- Results -->
    <div>
      <b-table
        :data="retailerCategories"
        :mobile-cards="false"
        hoverable
        class="is-size-7">
        <template>
          <!-- Category -->
          <b-table-column
            label="Category"
            width="270"
            v-slot="props">
            {{ props.row.retailer_category.category === 'All categories' && selectedCategories.length > 1 ? 'All other categories' : props.row.retailer_category.category }}
          </b-table-column>
          <!-- Value -->
          <b-table-column
            label="Value (%)"
            width="270"
            v-slot="props">
            <b-field
              expanded>
              <b-numberinput
                type="text"
                size="is-small"
                v-model="props.row.value"
                :step=".01"
                :min="1"
                :max="100"
                @input="change(props.row)"
              ></b-numberinput>
            </b-field>
          </b-table-column>
          <!-- Start date & time -->
          <b-table-column
            label="Start date & time"
            v-slot="props">
            {{ $dates.utc(props.row.start_at).local().format('MMM DD, YYYY @ h:mm A') }}
          </b-table-column>
          <!-- Delete -->
          <b-table-column
            width="72"
            v-slot="props">
            <b-button v-if="props.row.retailer_category.category !== 'All categories'" type="is-text" size="is-small" @click="inspectRow(props.row, 'showDeleteCategoryModal')">
              <b-icon icon="delete"></b-icon>
            </b-button>
          </b-table-column>
        </template>
        <template #empty>
          <div class="py-xxl has-text-grey has-text-centered">
            {{ (loading) ? "Loading data..." : 'No category added!' }}
          </div>
        </template>
      </b-table>
    </div>
    </div>
    <!-- Select categories modal -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showCategories"
      :can-cancel="['x']"
      ><SelectCategories
        :selectedCategories="selectedCategories"
        @modal:close="showCategories = false"
        @modal:next="addCategories"
        />
    </b-modal>
    <!-- Delete Category Modal -->
    <b-modal
      v-if="inspectCategory !== null"
      has-modal-card
      scroll="keep"
      :active="showDeleteCategoryModal"
      :can-cancel="['x']"
      ><ConfirmationModal
        :label="`Delete commission category: ${inspectCategory.retailer_category.category}`"
        :reason="'Are you sure you want to delete this category commission? This action cannot be reverse and any items of this category will now be charged as per default commission.'"
        :confirmButtonText="'Delete'"
        :confirmButtonType="'is-danger'"
        :saving="deleting"
        @modal:confirm="onDelete"
        @modal:close="onClose"
        />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ConfirmationModal from '@/components/Modals/ConfirmationModal'
import SelectCategories from './SelectCategories'
export default {
  components: {
    ConfirmationModal,
    SelectCategories
  },
  computed: {
    ...mapGetters(['app', 'context']),
    retailerCategories () {
      let categories = []
      categories = this.selectedCategories.map(el => {
        const categoryExists = this.categoryDetails && this.categoryDetails.find(value => value.retailer_category && value.retailer_category.category === el.category)
        if (categoryExists) {
          const startAt = el.isDirty ? new Date().toISOString() : categoryExists.start_at
          return {
            ...categoryExists,
            start_at: startAt
          }
        } else {
          return {
            retailer_category: {
              id: el.id,
              category: el.category
            },
            value: 0,
            start_at: new Date().toISOString()
          }
        }
      })
      return categories
    }
  },
  data () {
    return {
      loading: false,
      showCategories: false,
      errors: {},
      selectedCategories: [{
        category: 'All categories',
        values: 0
      }],
      showDeleteCategoryModal: false,
      inspectCategory: null,
      deleting: false
    }
  },
  methods: {
    addCategories (selected) {
      const categories = this.selectedCategories.concat(selected.filter((sel) => !this.selectedCategories.some((el) => el.category === sel.category)))
      this.selectedCategories = categories
      this.$emit('input', this.retailerCategories)
      this.showCategories = false
    },
    update () {
      this.$emit('input', this.retailerCategories)
      this.inspectCategory = null
    },
    inspectRow (row, key) {
      this.inspectCategory = row
      this[key] = true
    },
    onDelete () {
      if (this.isEdit && this.inspectCategory.id) {
        this.deleting = true
        const endpoint = this.context.route + `/commission-profiles/${this.$route.params.id}/values/${this.inspectCategory.id}/`
        this.$http.delete(endpoint).then(response => {
          this.$buefy.toast.open({ type: 'is-success', message: 'Commission profile updated!' })
          this.deleting = false
          this.$emit('reload')
          this.onClose()
        })
      } else {
        this.selectedCategories = this.selectedCategories.filter(obj => obj.category !== this.inspectCategory.retailer_category.category)
        this.$emit('input', this.retailerCategories)
        this.onClose()
      }
    },
    onClose () {
      this.inspectCategory = null
      this.showDeleteCategoryModal = false
    },
    change (row) {
      const updateIndex = this.selectedCategories.findIndex(el => el.category === row.retailer_category.category)
      if (updateIndex !== -1) {
        this.selectedCategories[updateIndex].isDirty = true
      }
      this.$emit('input', this.retailerCategories)
    }
  },
  mounted () {
    if (this.isEdit) {
      this.selectedCategories = this.selectedCats
    }
  },
  props: {
    profileData: {
      type: Object,
      default: null
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    categoryDetails: {
      type: Array
    },
    selectedCats: {
      type: Array
    }
  },
  watch: {
    selectedCats (newValue) {
      this.selectedCategories = newValue
    }
  }
}
</script>
