<template>
  <div>
    <div class="section">
      <div class="container">
        <!-- Page Header -->
        <div class="columns is-vcentered">
          <div class="column">
            <h1 class="has-text-centered is-size-4">Envelopes</h1>
          </div>
        </div>
        <!-- Filters -->
        <div class="has-background-white rounded-lg pa-md mb-xxs">
          <div class="columns">
            <div class="column">
              <EdiAccountSelect @selected="loadAccountEnvelopes" />
            </div>
            <div class="column">
              <EnvelopeFilters @filter="filter" :applied-filters="filters" />
            </div>
            <div class="column is-narrow">
              <b-field >
                <b-select v-model="orderBy" expanded @input="sort" size="is-medium">
                  <option value="-id">Most Recent</option>
                  <option value="id">Oldest</option>
                </b-select>
              </b-field>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <!-- Toolbar -->
            <div class="has-background-white rounded-lg px-sm mb-xxs">
              <div class="columns is-vcentered is-mobile is-marginless is-paddingless">
                <div class="column">
                  <div class="has-text-right is-size-7">
                    <!-- Counts -->
                    <p v-if="loading">Loading...</p>
                    <p v-else>{{ total }} {{ (total === 1) ? 'envelope' : 'envelopes' }} found.</p>
                  </div>
                </div>
              </div>
            </div>
            <!-- Table -->
            <div>
              <div>
                <b-table
                  :data="results"
                  :mobile-cards="false"
                  :loading="loading"
                  hoverable
                  class="is-size-7">
                  <template>
                    <!-- RC ID  -->
                    <b-table-column
                      label="ID"
                      header-class="nowrap"
                      cell-class="nowrap"
                      width="80"
                      v-slot="props">
                      <a href="#"
                        class="has-text-weight-bold has-text-info"
                        @click.prevent="inspectEnvelope = props.row"
                      >{{ props.row.id }}</a>
                    </b-table-column>
                    <!-- ICN  -->
                    <b-table-column
                      label="Group"
                      cell-class="nowrap"
                      width="128"
                      centered
                      v-slot="props">
                      {{ props.row.functional_identifier }}
                    </b-table-column>
                    <!-- ICN  -->
                    <b-table-column
                      label="ICN"
                      cell-class="nowrap"
                      width="128"
                      v-slot="props">
                      {{ props.row.interchange_control_number }}
                    </b-table-column>
                    <!-- Direction  -->
                    <b-table-column
                      label="Direction"
                      cell-class="nowrap"
                      v-slot="props">
                      <span v-if="props.row.direction === 'outgoing'">Sent to Supplier</span>
                      <span v-else>Received by RC</span>
                    </b-table-column>
                    <!-- Transmitted  -->
                    <b-table-column
                      label="Transmitted"
                      cell-class="nowrap"
                      centered
                      width="200"
                      v-slot="props">
                      <span v-if="props.row.transmitted_at">{{ $dates.utc(props.row.transmitted_at).local().format('MMM DD, YYYY @ h:mm A')}}</span>
                      <span v-else class="has-text-grey-light">--</span>
                    </b-table-column>
                    <!-- Accepted At  -->
                    <b-table-column
                      label="Accepted"
                      cell-class="nowrap"
                      centered
                      width="200"
                      v-slot="props">
                      <span v-if="props.row.functional_identifier === 'FA'" class="has-text-grey-light is-italic">N/A</span>
                      <span v-else-if="props.row.accepted_at === null" class="has-text-grey-light is-italic">Unknown</span>
                      <span v-else>{{ $dates.utc(props.row.accepted_at).local().format('MMM DD, YYYY @ h:mm A') }}</span>
                    </b-table-column>
                  </template>
                  <template #empty>
                    <div class="py-xxl has-text-grey has-text-centered">
                      {{ (loading) ? "Loading data..." : 'No envelopes found!' }}
                    </div>
                  </template>
                </b-table>
              </div>
              <div class="mt-md">
                <b-pagination
                  :total="total"
                  :current.sync="currentPage"
                  :rounded="true"
                  :per-page="perPage"
                  @change="paginate">
                </b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Envelope Inspection Modal -->
    <b-modal
      :active="inspectEnvelope !== null"
      has-modal-card
      :can-cancel="['escape', 'x']"
      @close="inspectEnvelope = null" >
      <EnvelopeModal :selectedEnvelope="inspectEnvelope" />
    </b-modal>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import EdiAccountSelect from '@/components/Models/EdiAccount/EdiAccountSelect'
import CdnTransform from '@/mixins/CdnTransform.js'
import Filterable from '@/mixins/Filterable'
import EnvelopeFilters from '../components/Envelopes/EnvelopeFilters'
import EnvelopeModal from '@/components/Modals/Envelope/EnvelopeModal'

export default {
  components: {
    EdiAccountSelect,
    EnvelopeFilters,
    EnvelopeModal
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      if (this.ediAccount) {
        return this.context.route + '/edi-accounts/' + this.ediAccount.id + '/envelopes/'
      }
      return null
    }
  },
  data () {
    return {
      key: 'envelopes',
      alwaysAttach: {
        exclude_raw_edi: 1,
        short: 1
      },
      inspectEnvelope: null,
      ediAccount: null
    }
  },
  methods: {
    loadAccountEnvelopes (ediAccount) {
      this.ediAccount = ediAccount
      if (this.ediAccount) {
        this.load()
      }
    }
  },
  mixins: [CdnTransform, Filterable],
  mounted () {

  },
  props: []
}
</script>
