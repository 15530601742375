<template>
  <div>
    <form>
      <div class="modal-card">
        <section class="modal-card-body">
          <div v-if="!loading && product !== null">
            <!-- Product Info -->
            <div class="columns is-centered">
              <div class="column is-two-fifths">
                <!-- Image Found -->
                <div v-if="product.primary_media !== null">
                  <figure class="image is-square mb-sm">
                    <img class="rounded-lg" :src="cdnTransform(product.primary_media.url, 800, 800, 'fill')" />
                  </figure>
                  <div class="is-size-7 has-text-grey has-text-centered">
                    <p>Native Photo Dimensions</p>
                    <p class="">{{ product.primary_media.width }}W x {{ product.primary_media.height }}H</p>
                  </div>
                </div>
                <!-- No Image Found -->
                <div v-else>
                  <figure class="image is-square mb-sm">
                    <img class="rounded-lg" :src="cdnTransform(null, 800, 800, 'fill')" />
                  </figure>
                  <div class="is-size-7 has-text-grey has-text-centered">
                    <p>No Photo Found!</p>
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="px-md">
                  <div class="mb-sm">
                    <p class="is-size-5">{{ product.parent_name }}</p>
                    <p class="has-text-grey">
                      <span class="has-text-grey is-size-8">Product SKU </span>
                      <span class="has-text-weight-bold">{{ product.parent_identifier }}</span>
                    </p>
                    <p class="has-text-grey" v-if="product.brand_category !== null">
                      <span class="has-text-grey is-size-8">Category </span>
                      <span class="has-text-weight-bold">{{ product.brand_category }}</span>
                    </p>
                  </div>
                  <div>
                    <p class="has-text-grey" >
                      <span class="has-text-grey is-size-8">Network Status </span>
                      <span class="has-text-weight-bold">
                        <b-tag v-if="product.rcn_status === 'published'" type="is-success">Published</b-tag>
                        <b-tag v-else-if="product.rcn_status === 'failed'" type="is-danger">Needs Data</b-tag>
                        <b-tag v-else-if="product.rcn_status === 'not_published'" type="is-info">Not Published</b-tag>
                        <b-tag v-else type="is-light">Pending</b-tag>
                      </span>
                    </p>
                  </div>
                  <!-- Actions -->
                  <div class="border-t border-b mt-md py-sm">
                    <div class="columns is-vcentered">
                      <div class="column">
                        <b-button
                          v-if="product.rcn_status === 'not_published'"
                          type="is-primary is-fullwidth"
                          :disabled="publishing || loading"
                          :loading="publishing || loading"
                          @click="publish"
                        >Publish</b-button>
                        <b-button
                          v-else-if="product.rcn_status === 'published' || product.rcn_status === 'failed'"
                          type="is-primary is-fullwidth is-outlined"
                          :disabled="publishing || loading"
                          :loading="publishing || loading"
                          @click="unpublish"
                        >Unpublish</b-button>
                        <b-button
                          v-else
                          type="is-primary is-fullwidth"
                          disabled
                        >Publish</b-button>
                      </div>
                      <div class="column">
                        <router-link class="button is-fullwidth has-text-grey" :to="{ name: 'network.product', params: { id: product.id } }">
                          <span>View Details</span>
                          <b-icon icon="chevron-right"></b-icon>
                        </router-link>
                      </div>
                    </div>
                  </div>
                  <div class="py-md">
                    <p class="is-uppercase has-text-weight-bold mb-sm is-size-7">Description</p>
                    <div class="has-text-grey is-size-7 content" v-html="description"></div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Variant View Toolbar -->
            <div class="border-t border-b has-background-white rounded-lg pa-sm">
              <div class="columns is-centered">
                <div class="column is-narrow">
                  <b-field>
                    <b-radio-button v-model="view" native-value="pricing">Pricing</b-radio-button>
                    <b-radio-button v-model="view" native-value="attributes">Attributes</b-radio-button>
                    <b-radio-button v-model="view" native-value="images">Images</b-radio-button>
                  </b-field>
                </div>
              </div>
            </div>

            <!-- Variants Table -->
            <b-table
              v-if="product.variants.length > 0"
              :data="product.variants"
              :mobile-cards="false"
              :loading="loading || product.variants.length === 0"
              class="is-size-7">
              <template>
                <!-- SKU -->
                <b-table-column
                  field="sku"
                  label="SKU"
                  cell-class="nowrap"
                  :visible="columns.includes('sku')"
                  v-slot="props">
                  <p class="has-text-weight-bold">{{ props.row.name }}</p>
                  <p class="is-size-8">{{ props.row.brand_identifier }}</p>
                </b-table-column>
                <!-- Images -->
                <b-table-column
                  label="Images"
                  :visible="columns.includes('images')"
                  style="white-space:nowrap"
                  v-slot="props">
                  <figure class="image is-48x48 is-pulled-left mr-xs" v-for="medium in props.row.media" :key="medium.id">
                    <img class="rounded-lg" :src="cdnTransform(medium.url, 48, 48, 'fill')"  />
                  </figure>
                </b-table-column>
                <b-table-column
                  field="color"
                  label="Color"
                  :visible="columns.includes('color')"
                  v-slot="props">
                  <p v-if="props.row.color === null" class="has-text-grey is-size-7">N/A
                  <p v-else>{{ props.row.color }}</p>
                </b-table-column>
                <b-table-column
                  field="size"
                  label="Size"
                  sortable
                  :visible="columns.includes('size')"
                  v-slot="props">
                  <p v-if="props.row.size === null" class="has-text-grey is-size-7">N/A
                  <p v-else>{{ props.row.size }}</p>
                </b-table-column>
                <b-table-column
                  field="cost"
                  label="Cost"
                  centered
                  :visible="columns.includes('cost')"
                  width="128"
                  v-slot="props">
                  <b-icon icon="alert-outline" class="has-text-danger" v-if="!('cost' in props.row.attributes)" />
                  <p v-else>{{ $numbers(props.row.attributes.cost.values[0].value).formatCurrency({ mantissa: 2 }) }}</p>
                </b-table-column>
                <b-table-column
                  field="map"
                  label="MAP"
                  centered
                  :visible="columns.includes('map')"
                  width="100"
                  v-slot="props">
                  <b-icon icon="alert-outline" v-if="!('map' in props.row.attributes)" />
                  <p v-else>{{ $numbers(props.row.attributes.map.values[0].value).formatCurrency({ mantissa: 2 }) }}</p>
                </b-table-column>
                <b-table-column
                  label="Inventory"
                  centered
                  :visible="columns.includes('inventory')"
                  width="100"
                  v-slot="props">
                  <b-icon icon="alert-outline" v-if="props.row.inventory === null" />
                  <p v-else>{{ props.row.inventory.inventory }}</p>
                </b-table-column>
              </template>
            </b-table>

          </div>
          <div v-else>
            <loading message="Loading product details..." />
          </div>
        </section>
        <footer class="modal-card-foot space-between">
          <router-link class="button" :to="{ name: 'network.product', params: { id: product.id } }">
            Edit Product
          </router-link>
          <button class="button" type="button" @click="$parent.close()">Close</button>
        </footer>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CdnTransform from '@/mixins/CdnTransform'
import stripTags from 'locutus/php/strings/strip_tags'

export default {
  components: {

  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/products/' + this.product.id + '/'
    },
    columns () {
      if (this.view === 'pricing') return ['sku', 'cost', 'shipping', 'map', 'msrp', 'inventory', 'status']
      if (this.view === 'attributes') return ['sku', 'color', 'size', 'status']
      if (this.view === 'images') return ['sku', 'images', 'status']
      return ['sku', 'status']
    },
    description () {
      return this.$utils.truncate(stripTags(this.product.description, '<p><ul><ol>'), { length: 128 })
    }
  },
  data () {
    return {
      loading: true,
      view: 'pricing',
      product: null,
      publishing: false
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint).then(response => {
        this.product = response.data
      }).finally(() => {
        this.loading = false
      })
    },
    refresh () {
      this.$http.get(this.endpoint).then(response => {
        this.product = response.data
      })
    },
    publish () {
      this.publishing = true
      this.$http.post(this.endpoint + 'publish/').then(response => {
        this.refresh()
        this.$emit('update:product', this.product)
        this.$buefy.toast.open({ type: 'is-success', message: 'Product published!' })
      }).finally(() => {
        this.publishing = false
      })
    },
    unpublish () {
      this.publishing = true
      this.$http.post(this.endpoint + 'unpublish/').then(response => {
        this.refresh()
        this.$emit('update:product', this.product)
        this.$buefy.toast.open({ type: 'is-success', message: 'Product removed!' })
      }).finally(() => {
        this.publishing = false
      })
    }
  },
  mixins: [CdnTransform],
  created () {
    this.product = this.preview
  },
  mounted () {
    this.load()
  },
  props: ['preview']
}
</script>
