<template>
  <div class="columns">
    <div class="column is-one-fifth">
      <p class="has-text-weight-bold is-size-6 mb-sm">Order Details</p>
      <p class="has-text-grey">Enter information about your order.</p>
    </div>
    <div class="column">
      <div class="has-background-white rounded-lg pa-md pb-lg">
        <div class="columns">
          <b-field
            class="column is-half"
            label="Purchase Order Number"
            :type="'purchaseOrderNo' in errors ? 'is-danger' : ''"
            :message="'purchaseOrderNo' in errors ? errors['name'][0] : ''"
            expanded
          >
            <b-input
              placeholder="Purchase order number"
              autocomplete="off"
              name="purchaseOrderNo"
              v-model="purchaseOrderNo"
              @input="$emit('input', { purchaseOrderNo: purchaseOrderNo })"
            ></b-input>
          </b-field>
          <b-field label="Order Date" class="column is-half">
            <b-datepicker
              placeholder="Type or select a date..."
              icon="calendar-today"
              :locale="locale"
              v-model="orderDate"
              :icon-right="orderDate ? 'close-circle' : ''"
              icon-right-clickable
              @icon-right-click="clearDate"
              editable
            >
            </b-datepicker>
          </b-field>
        </div>
        <div class="columns">
          <b-field
            class="column is-half"
            label="Retailer Order Number"
            :type="'retailerOrderNo' in errors ? 'is-danger' : ''"
            :message="'retailerOrderNo' in errors ? errors['name'][0] : ''"
            expanded
          >
            <b-input
              placeholder="(optional)"
              autocomplete="off"
              name="retailerOrderNo"
              v-model="retailerOrderNo"
              @input="$emit('input', { retailerOrderNo: retailerOrderNo })"
            ></b-input>
          </b-field>
          <b-field
            class="column is-half"
            label="Customer Order Number"
            :type="'customerOrderNo' in errors ? 'is-danger' : ''"
            :message="'customerOrderNo' in errors ? errors['name'][0] : ''"
            expanded
          >
            <b-input
              placeholder="(optional)"
              autocomplete="off"
              name="customerOrderNo"
              v-model="customerOrderNo"
              @input="$emit('input', { customerOrderNo: customerOrderNo })"
            ></b-input>
          </b-field>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
  },
  computed: {},
  data: () => {
    return {
      customerOrderNo: null,
      purchaseOrderNo: null,
      retailerOrderNo: null,
      orderDate: null,
      locale: undefined
    }
  },
  methods: {
    clearDate () {
      this.orderDate = null
    }
  },
  mounted () {

  },
  props: ['errors'],
  watch: {
    orderDate (value) {
      this.$emit('input', { orderDate: value })
    }
  }
}
</script>
