export default {
  computed: {
    partner () {
      if (this.order === null) return null
      if (this.context.type === 'vendor') {
        return {
          label: 'Merchant',
          id: this.order.retailer.id,
          name: this.order.retailer.name
        }
      } else {
        return {
          label: 'Supplier',
          id: this.order.brand.id,
          name: this.order.brand.name
        }
      }
    },
    status () {
      // set defaults first
      const presenter = {
        label: this.$utils.upperFirst(this.order.status),
        textClass: 'has-text-black',
        message: null
      }
      // if order is partial, rename the label to 'In Progress'
      if (this.order.status === 'partial') presenter.label = 'Open'
      // if the order is open or partial, color the text according to how
      // late the order is (where > 5 is really bad)
      if (this.order.status === 'open' || this.order.status === 'partial') {
        const due = this.$dates.utc().diff(this.$dates(this.order.fulfill_by), 'day')
        if (due > 4) {
          presenter.textClass = 'has-text-danger'
          presenter.message = 'Very Late'
        } else if (due >= 1) {
          presenter.textClass = 'has-text-warning'
          presenter.message = 'Late'
        } else {
          presenter.textClass = 'has-text-success'
          presenter.message = 'On Time'
        }
      }
      return presenter
    }
  }
}
