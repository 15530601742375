<template>
  <div>
    <div class="columns">
      <div class="column is-one-fifth">
        <p class="has-text-weight-bold is-size-6 mb-sm">Webhook History</p>
        <p class="has-text-grey is-size-7">
          Review recent webhook results.
        </p>
      </div>
      <div class="column">
        <!-- Filters Toolbar -->
        <div class="has-background-white rounded-lg pa-md mb-xxs">
          <div class="columns">
            <div class="column">
              <b-select
                v-model="topicFilter"
                size="is-medium"
                expanded
                placeholder="Filter by topic..."
                @input="applyTopicFilter">
                <option value="Variant Inventory Updated">Item Inventory Updated</option>
                <option value="Permit Created">Offer Created</option>
                <option value="Order Created">Order Created</option>
                <option value="Order Closed">Order Closed</option>
                <option value="Shipment Closed">Shipment Closed</option>
                <option value="Order Canceled">Cancel Created</option>
                <option value="Invoice Created">Invoice Created</option>
                <option value="Credit Memo Created">Credit Memo Created</option>
                <option value="Description Attribute Updated">Variant Description Attribute Updated</option>
                <option value="Identifiers Attribute Updated">Variant Identifier Attribute Updated</option>
                <option value="General Attribute Updated">Variant General Attribute Updated</option>
                <option value="Images Attribute Updated">Variant Image Attribute Updated</option>
                <option value="Shipping Attribute Updated">Variant Shipping Attribute Updated</option>
                <option value="Misc Attribute Updated">Variant Misc Attribute Updated</option>
              </b-select>
            </div>
            <div class="column">
              <b-select v-model="orderBy" size="is-medium" expanded @input="sort">
                <option value="-id">Most Recent</option>
                <option value="id">Oldest</option>
              </b-select>
            </div>
          </div>
        </div>
        <div class="has-background-white rounded-lg">
          <div class="px-lg">
            <!-- Webhooks Loaded (or not) -->
            <div v-if="!loading && results.length > 0">
              <div v-for="(webhook, index) in results" :key="webhook.id" class="py-md" :class="(index !== 0) ? 'border-t' : ''">
                <div class="columns is-mobile is-vcentered is-size-7">
                  <div class="column">
                    <p>
                      {{ $utils.upperFirst(webhook.event.content_type) }}
                      ID #{{ webhook.event.object_id }}
                      <span class="has-text-grey-light"> triggered </span>
                      {{ webhook.webhook.action.name }}
                    </p>
                    <p class="has-text-grey-light">
                      <span>History ID: {{ webhook.id }} &bull; </span>
                      <span>Event ID: {{ webhook.event.id }}</span>
                    </p>
                  </div>
                  <div class="column is-narrow">
                    <div class="has-text-weight-bold">
                      <span v-if="webhook.status ==='success'" class="has-text-success">
                        {{ webhook.status_code }}
                      </span>
                      <span v-else class="has-text-warning">
                        {{ webhook.status_code }}
                      </span>
                    </div>
                  </div>
                  <div class="column is-narrow">
                    <Retry :webhook="webhook" />
                  </div>
                </div>
              </div>
            </div>
            <!-- None Found -->
            <div v-else-if="!loading && results.length ===0">
              <div class="pa-xxl has-text-centered has-text-grey">
                <p>No recent webhooks have been triggered.</p>
              </div>
            </div>
            <!-- Loading Webhooks -->
            <div v-else>
              <div class="pa-md" v-for="index in 5" :key="index">
                <div class="placeholder placeholder-title"></div>
                <div class="placeholder placeholder-sentence" style="width:50%"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Filterable from '@/mixins/Filterable'
import Retry from './Retry'
export default {
  components: {
    Retry
  },
  data () {
    return {
      key: 'webhooks',
      topicFilter: null
    }
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/webhooks-history/'
    }
  },
  methods: {
    applyTopicFilter (payload) {
      this.filter({
        preset: 'custom',
        search: {},
        timestamps: {},
        facets: {
          action: [{
            label: this.topicFilter,
            value: this.topicFilter
          }]
        }
      })
    }
  },
  mixins: [Filterable],
  mounted () {
    this.load()
  },
  props: []
}
</script>
