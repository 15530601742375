<template>
  <div>
    <div class="section">
      <div class="container">
        <!-- Page Header -->
        <div class="mb-sm">
          <div class="columns is-centered">
            <div class="column is-one-third">
              <h1 class="has-text-centered is-size-4">
                <span>Payments</span>
              </h1>
            </div>
          </div>
        </div>
        <!-- Content -->
        <div class="mb-lg">
          <div class="columns is-centered">
            <div class="column is-full-tablet is-half-widescreen">
              <!-- Search -->
              <div class="rounded-lg has-background-white mb-lg pa-md">
                <div class="columns">
                  <div class="column">
                    <PaymentSearch />
                  </div>
                  <div v-if="context.isMerchant()" class="column is-one-third is-narrow">
                    <b-button
                      size="is-medium"
                      outlined
                      expanded
                      @click.prevent="showCreatePaymentModal = true">
                      Create Payment
                    </b-button>
                  </div>
                </div>
              </div>
              <!-- Workflow -->
              <div class="columns">
                <div class="column">
                  <div class="mb-md">
                    <p class="has-text-weight-bold has-text-centered mb-md pt-xs">Browse Payments</p>
                    <!-- Draft -->
                    <router-link class="has-text-weight-bold" :to="{ name: 'payments.list', query: { preset: 'draft'} }">
                      <div class="has-background-white px-md rounded-lg mb-xxs">
                        <div class="columns is-mobile is-vcentered is-marginless">
                          <div class="column">
                            Draft
                          </div>
                          <div class="column is-one-fifth">
                            <div class="has-background-white-ter pt-xs rounded-lg has-text-weight-bold has-text-centered">
                              <span><b-icon icon="chevron-right" /></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </router-link>
                    <!-- Completed -->
                    <router-link class="has-text-weight-bold" :to="{ name: 'payments.list', query: { preset: 'paid'} }">
                      <div class="has-background-white px-md rounded-lg mb-xxs">
                        <div class="columns is-mobile is-vcentered is-marginless">
                          <div class="column">
                            Completed
                          </div>
                          <div class="column is-one-fifth">
                            <div class="has-background-white-ter pt-xs rounded-lg has-text-weight-bold has-text-centered">
                              <span><b-icon icon="chevron-right" /></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal: Create -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showCreatePaymentModal"
      :can-cancel="['x']"
      ><CreateModal />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CreateModal from '@/components/Models/Payment/Modals/CreateModal'
import PaymentSearch from '../components/Index/PaymentSearch'

export default {
  components: {
    PaymentSearch,
    CreateModal
  },
  computed: {
    ...mapGetters(['app', 'auth', 'context']),
    loading () {
      return false
    }
  },
  data () {
    return {
      showCreatePaymentModal: false
    }
  },
  methods: {
    redirectToDetails () {
      this.$router.push({ name: 'payment.detail' })
    }
  },
  mounted () {
  }
}
</script>
