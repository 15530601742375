<template>
  <a :href="shipment.tracking_url" target="_blank" :class="presenter.textClass">{{ presenter.label }}</a>
</template>

<script>
export default {
  computed: {
    presenter () {
      const presenter = {
        label: 'Registered',
        tooltip: 'This shipment has been registered and arranged for pickup.',
        textClass: 'has-text-black',
        progress: 0
      }
      // if the shipment is marked as delivered, that's great!
      if (this.shipment.tracking !== null && this.shipment.tracking.delivered_at !== null) {
        presenter.label = 'Delivered'
        presenter.tooltip = 'The carrier has confirmed delivery of the shipment.'
        presenter.textClass = 'has-text-success'
        presenter.progress = 100
      // if the shipment is marked as picked up, that's ok...
      } else if (this.shipment.tracking !== null && this.shipment.tracking.delivered_at !== null) {
        presenter.label = 'Picked Up'
        presenter.tooltip = 'The carrier has confirmed receipt of the shipment.'
        presenter.textClass = 'has-text-success'
        presenter.progress = 50
      }
      return presenter
    }
  },
  props: ['shipment']
}
</script>
