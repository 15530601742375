<template>
  <div>
    <div class="has-background-white pa-lg rounded-lg">
      <div class="columns is-centered">
        <div class="column is-two-fifths">
          <div class=" ">
            <figure class="image is-square mb-sm">
              <img class="rounded-lg" :src="cdnTransform(product.primary_media.url, 800, 800, 'fill')" />
            </figure>
            <div class="is-size-7 has-text-grey has-text-centered">
              <p>Native Photo Dimensions</p>
              <p class="">{{ product.primary_media.width }}W x {{ product.primary_media.height }}H</p>
            </div>
          </div>
        </div>
        <div class="column ">
          <div class="px-md">
            <div>
              <p class="is-size-4">{{ product.parent_name }}</p>
              <p class="has-text-grey">
                <span class="has-text-grey">Product # </span>
                <span class="has-text-weight-bold">{{ product.parent_identifier }}</span>
              </p>
            </div>
            <div class="mt-md">
              <div class="columns is-mobile is-vcentered is-variable is-2">
                <div class="column is-narrow">
                  <span class="is-uppercase is-size-8 has-text-grey">MAP </span>
                  <MapPrice class="has-text-weight-bold has-text-danger is-size-5" :price="product.pricing.map_price" />
                </div>
                <div class="column is-narrow">
                  <span class="is-uppercase is-size-8 has-text-grey">SHIPPED COST </span>
                  <MapPrice class="has-text-weight-bold has-text-primary is-size-5" :price="product.pricing.dropship_cost" />
                </div>
              </div>
            </div>
            <div class="mt-xl">
              <p>
                <b-button
                  type="is-primary rounded-lg"
                  size="is-medium"
                  :disabled="requesting || checking || outstanding.length > 0"
                  :loading="requesting || checking"
                  @click.prevent="request"
                >Request Product</b-button>
              </p>
              <div class="mt-lg" v-if="!checking && outstanding.length > 0">
                <p class="has-text-danger">Your organization has already requested access to this product!</p>
                <p class="is-size-7">
                  <a href="#" class="has-text-grey" @click.prevent="showHistoryModal = true">
                    <u>View Request History</u>
                  </a>
                </p>
              </div>
            </div>
            <div class=" mt-xl border-t py-md">
              <p class="is-uppercase has-text-weight-bold mb-sm is-size-7">Description</p>
              <div class="has-text-grey">{{ product.description }}</div>
            </div>
            <!-- Ask QUestion -->
            <!-- <div class="mt-xl has-text-grey">
              <p>Have a question about this product?</p>
              <p><u>Ask the supplier!</u></p>
            </div> -->
          </div>
        </div>
      </div>

    </div>
    <!-- Modal -->
    <b-modal
      v-if="!checking && outstanding.length > 0"
      :active.sync="showHistoryModal"
      has-modal-card
      trap-focus
      ><outstanding-request-modal :outstanding="outstanding" />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import OutstandingRequestModal from '../components/RequestHistoryModal'
import MapPrice from '@/components/Presenters/Product/MapPrice'
import CdnTransform from '@/mixins/CdnTransform'
export default {
  components: {
    OutstandingRequestModal,
    MapPrice
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/product-access/'
    },
    json () {
      return {
        product_id: this.product.id,
        recipient: {
          brand: {
            id: this.product.brand_id
          }
        }
      }
    }
  },
  data () {
    return {
      requesting: false,
      checking: true,
      outstanding: [],
      showHistoryModal: false,
      error: null
    }
  },
  methods: {
    checkOutstanding () {
      this.checking = true
      this.$http.get(this.endpoint, { params: { product_id: this.product.id, mine: 1 } }).then(response => {
        this.outstanding = response.data.results
      }).finally(() => {
        this.checking = false
      })
    },
    request () {
      this.requesting = true
      this.$http.post(this.endpoint, this.json).then(response => {
        return response
      }).catch(error => {
        if (error.response && error.response.data.detail) {
          this.error = error.response.data.detail
          this.$buefy.toast.open({ type: 'is-danger', message: error.response.data.detail })
        }
      }).finally(() => {
        this.requesting = false
      })
    }
  },
  mounted () {
    this.checkOutstanding()
  },
  mixins: [CdnTransform],
  props: ['product']
}
</script>
