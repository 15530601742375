<template>
  <InspectionModal
    :endpoint="endpoint"
    :loadDetail="loadDetail"
    :seed="selected">
    <template v-slot:default="props">
      <form>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Inspect Offer</p>
          </header>
          <section class="modal-card-body">
            <!-- Loaded -->
            <div v-if="!props.loading && props.record !== null">
              <table class="table is-fullwidth is-striped is-size-7 is-narrow border">
                <tbody>
                  <tr>
                    <th style="width:200px">ID</th>
                    <td>{{ props.record.id }}</td>
                  </tr>
                  <tr>
                    <th>Supplier</th>
                    <td>{{ props.record.variant.brand.name }}</td>
                  </tr>
                  <tr>
                    <th>Merchant</th>
                    <td>{{ props.record.retailer.name }}</td>
                  </tr>
                  <tr>
                    <th>Item ID</th>
                    <td>{{ props.record.variant.id }}</td>
                  </tr>
                  <tr>
                    <th>Supplier SKU</th>
                    <td>{{ props.record.variant.brand_identifier }}</td>
                  </tr>
                  <tr>
                    <th>Supplier Item Name</th>
                    <td>{{ props.record.variant.name }}</td>
                  </tr>
                  <tr v-if="context.isMerchant()">
                    <th>Merchant SKU</th>
                    <td>
                      <span v-if="props.record.variant.retailer_identifiers.length > 0">
                        {{ props.record.variant.retailer_identifiers[0].identifier }}
                      </span>
                      <span v-else class="is-italic">Not Set</span>
                    </td>
                  </tr>
                  <tr v-if="context.isMerchant() && props.record.variant.retailer_identifiers.length > 0">
                    <th>Merchant Item Name</th>
                    <td>
                      <span v-if="props.record.variant.retailer_identifiers[0].name !== null">
                        {{ props.record.variant.retailer_identifiers[0].name }}
                      </span>
                      <span v-else class="is-italic">Not Set</span>
                    </td>
                  </tr>
                  <tr>
                    <th>Merchant Status</th>
                    <td>
                      <b-tooltip
                        v-if="props.record.is_acknowledged"
                        label="Merchant has acknowledged receipt of this offer."
                        dashed>
                        <span class="has-text-weight-bold has-text-success">Acknowledged</span>
                      </b-tooltip>
                      <b-tooltip
                        v-else
                        label="Merchant has access to this offer."
                        dashed>
                        <span class="has-text-weight-bold has-text-info">Sent</span>
                      </b-tooltip>
                    </td>
                  </tr>
                </tbody>
              </table>
              <!-- Pricing -->
              <table class="table is-fullwidth is-striped is-size-7 is-narrow border">
                <tbody>
                  <tr>
                    <th style="width:200px">Item Cost</th>
                    <td class="has-text-weight-bold has-text-warning">
                      <span v-if="props.record.retailer_cost !== null">
                        {{ $numbers(props.record.retailer_cost).formatCurrency({ mantissa: 2 }) }}
                      </span>
                      <span v-else class="">Managed by Merchant</span>
                    </td>
                  </tr>
                  <tr>
                    <th>Effective Date</th>
                    <td>
                      <span v-if="props.record.start_at !== null">{{ $dates(props.record.start_at).format('MMM D, YYYY') }}</span>
                      <span v-else class="has-text-grey-light is-italic">Now</span>                    </td>
                  </tr>
                  <tr>
                    <th>End Date</th>
                    <td>
                      <span v-if="props.record.end_at !== null">{{ $dates(props.record.end_at).format('MMM D, YYYY') }}</span>
                      <span v-else class="has-text-grey-light is-italic">Never</span>
                    </td>
                  </tr>
                </tbody>
              </table>

            </div>
            <!-- Loading -->
            <div v-else>
              <loading message="Loading item..." />
            </div>
          </section>
          <footer class="modal-card-foot">
            <button class="button" type="button" @click="$parent.close()">Close</button>
          </footer>
        </div>
      </form>
    </template>
  </InspectionModal>
</template>

<script>
import InspectionModal from '../InspectionModal'
import CdnTransform from '@/mixins/CdnTransform'
import { mapGetters } from 'vuex'

export default {
  components: {
    InspectionModal
  },
  data () {
    return {
      loadDetail: true
    }
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      if (this.selected !== null) {
        return this.context.route + '/permits/' + this.selected.id + '/'
      }
      return null
    }
  },
  methods: {

  },
  mixins: [CdnTransform],
  mounted () {

  },
  props: ['selected']
}
</script>
