<template>
  <div>
    <div class="columns">
      <div class="column is-one-fifth">
        <p class="has-text-weight-bold is-size-6 mb-sm">Webhooks</p>
        <p class="has-text-grey is-size-7">
          Configure webhooks to listen to key events from the
          {{ app.platform }} platform.
        </p>
      </div>
      <div class="column">
        <div class="has-background-white rounded-lg">
          <div class="px-lg">
            <!-- Webhooks Loaded (or not) -->
            <div v-if="!loading && webhooks.length > 0">
              <div v-for="(webhook, index) in webhooks" :key="webhook.id" class="py-md" :class="(index !== 0) ? 'border-t' : ''">
                <div class="columns is-vcentered is-size-7">
                  <div class="column">
                    <p class="has-text-weight-bold">{{ webhook.action.name }}</p>
                    <p class="has-text-grey">
                      <b v-if="webhook.status === 'enabled'" class="has-text-success">Enabled</b>
                      <b v-else class="has-text-danger">Disabled</b>
                      &bull;
                      <span class="is-uppercase has-text-weight-bold">{{ webhook.target_method }} </span>
                      <span class=""> {{ webhook.target_url }}</span>
                    </p>
                  </div>
                  <div class="column is-narrow">
                    <b-dropdown position="is-bottom-left" >
                      <button class="button is-small" slot="trigger" slot-scope="{ active }">
                        <span>Options</span>
                        <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
                      </button>
                      <b-dropdown-item @click="edit(webhook)">Edit Webhook</b-dropdown-item>
                      <!-- <b-dropdown-item @click="remove(webhook)">Remove Webhook</b-dropdown-item> -->
                    </b-dropdown>
                  </div>
                </div>
              </div>
            </div>
            <!-- None Found -->
            <div v-else-if="!loading && webhooks.length ===0">
              <div class="pa-xxl has-text-centered has-text-grey">
                <p>No webhooks configured. Click "Add Webhook" below to get started!</p>
              </div>
            </div>
            <!-- Loading Webhooks -->
            <div v-else>
              <div class="pa-md" v-for="index in 1" :key="index">
                <div class="placeholder placeholder-title"></div>
                <div class="placeholder placeholder-sentence" style="width:50%"></div>
              </div>
            </div>
          </div>
          <div class="border-t">
            <div class="columns is-vcentered is-marginless">
              <div class="column has-text-right">
                <div class="px-sm">
                  <b-button native-type="submit" type="is-primary rounded-lg" :disabled="loading" @click="add">Add Webhook</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Create/Edit Modal -->
    <b-modal :active.sync="showWebhookModal"
      has-modal-card
      scroll="keep"
      @close="webhook = null">
      <WebhookModal :webhook="webhook" @updated="load" />
    </b-modal>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import WebhookModal from './WebhookModal'

export default {
  components: {
    WebhookModal
  },
  computed: {
    ...mapGetters(['app', 'context']),
    endpoint () {
      return this.context.route + '/webhooks/'
    }
  },
  data () {
    return {
      loading: true,
      showWebhookModal: false,
      webhook: null,
      webhooks: [],
      isDirty: false,
      errors: [],
      updating: false,
      removing: false
    }
  },
  methods: {
    add () {
      this.webhook = null
      this.showWebhookModal = true
    },
    edit (webhook) {
      this.webhook = webhook
      this.showWebhookModal = true
    },
    remove (webhook) {
      this.removing = true
      this.$http.delete(this.endpoint + webhook.id + '/').then(() => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Webhook removed!' })
      }).finally(() => {
        this.removing = false
      })
    },
    load () {
      this.loading = true
      this.$http.get(this.endpoint, { params: { status: 'enabled' } }).then(response => {
        this.webhooks = response.data.results
        this.loading = false
      })
    }
  },
  mounted () {
    this.load()
  }
}
</script>
