<template>
  <div>
      <!-- Name -->
      <div v-if="requireAddressName" class="mb-md">
        <FieldLabel :label="addressNameLabel ? addressNameLabel : 'Name'" />
        <b-field
          :type="('name1' in errors) ? 'is-danger' : ''"
          :message="('name1' in errors) ? errors['name1'][0] : ''"
          expanded>
          <b-input
            type="text"
            name="name"
            v-model="name"
            placeholder="Name"
            @input="announce"
          ></b-input>
        </b-field>
      </div>
      <!-- Nickname -->
      <div v-if="requireNickname" class="border-b pb-lg mb-md">
        <FieldLabel label="Location Nickname" />
        <b-field
          :type="('nickname' in errors) ? 'is-danger' : ''"
          :message="('nickname' in errors) ? errors['nickname'][0] : ''"
          expanded>
          <b-input
            type="text"
            name="nickname"
            v-model="nickname"
            placeholder="e.g. HQ"
            @input="announce"
          ></b-input>
        </b-field>
      </div>
      <!-- Country -->
      <div class="mb-md">
        <FieldLabel label="Country" />
        <b-field
          :type="('country' in errors) ? 'is-danger' : ''"
          :message="('country' in errors) ? errors['country'][0] : ''"
          expanded>
          <b-select
            v-model="country"
            placeholder="Select Country"
            @input="countryChange"
            required
            expanded>
            <option v-for="country in countries" :key="country.id" :value="country.code">
              {{ country.name }}
            </option>
          </b-select>
        </b-field>
      </div>
      <!-- Street  -->
      <div class="mb-md">
        <FieldLabel label="Street Address" />
        <b-field
          :type="('street1' in errors) ? 'is-danger' : ''"
          :message="('street1' in errors) ? errors['street1'][0] : ''"
          expanded>
          <b-input
            type="text"
            name="street1"
            v-model="street1"
            placeholder="Street Line 1"
            @input="announce"
          ></b-input>
        </b-field>
      </div>
      <!-- City -->
      <div class="columns">
        <b-field
          class="column"
          label="Apartment, suite, etc"
          :type="('street2' in errors) ? 'is-danger' : ''"
          :message="('street2' in errors) ? errors['street2'][0] : ''"
          expanded>
          <b-input
            type="text"
            name="street2"
            v-model="street2"
            placeholder="(Optional)"
            @input="announce"
          ></b-input>
        </b-field>
        <div class="column">
          <FieldLabel label="City" />
          <b-field
            :type="('city' in errors) ? 'is-danger' : ''"
            :message="('city' in errors) ? errors['city'][0] : ''"
            expanded>
            <b-input
              type="text"
              name="city"
              v-model="city"
              @input="announce"
              required
            ></b-input>
          </b-field>
        </div>
      </div>
      <!-- Province & Postal Code  -->
      <div>
        <div class="columns">
          <div class="column">
            <FieldLabel :label="provinceLabel" />
            <b-field
              :type="('province' in errors) ? 'is-danger' : ''"
              :message="('province' in errors) ? errors['province'][0] : ''"
              expanded>
              <b-autocomplete
                autocomplete="chrome-off"
                v-model="search"
                :open-on-focus="true"
                :data="filteredCounties"
                :loading="loading"
                field="name"
                name="county"
                :placeholder="provinceLabel"
                clearable
                @select="option => province = (option) ? option.code : null"
                @input="setProvince">
                <template #empty>No results found</template>
              </b-autocomplete>
            </b-field>
          </div>
          <div class="column">
            <FieldLabel :label="postalCodeLabel" />
            <b-field
              :type="('postal_code' in errors) ? 'is-danger' : ''"
              :message="('postal_code' in errors) ? errors['postal_code'][0] : ''"
              expanded>
              <b-input
                maxlength="12"
                type="text"
                name="postalCode"
                :placeholder="postalCodeLabel"
                v-model="postalCode"
                @input="announce"
              ></b-input>
            </b-field>
          </div>
        </div>
      </div>
      <!-- Phone (only if required) -->
      <div class="columns">
        <div v-if="requirePhone" class="column">
          <b-field
            label="Contact Phone"
            :type="('phone' in errors) ? 'is-danger' : ''"
            :message="('phone' in errors) ? errors['phone'][0] : ''"
            expanded>
            <b-input
              type="text"
              name="phone"
              v-model="phone"
              @input="announce"
            ></b-input>
          </b-field>
          <p class="is-italic has-text-grey-light is-size-7">
            Contact phone numbers are often required by carriers.
          </p>
        </div>
      <!-- Email (only if required) -->
        <div v-if="requireEmail" class="column">
          <b-field
            label="Contact Email"
            :type="('email' in errors) ? 'is-danger' : ''"
            :message="('email' in errors) ? errors['email'][0] : ''"
            expanded>
            <b-input
              type="email"
              name="email"
              v-model="email"
              @input="announce"
            ></b-input>
          </b-field>
          <p class="is-italic has-text-grey-light is-size-7">
            Contact email addresses are often required by carriers.
          </p>
        </div>
      </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['api', 'context']),
    json () {
      const json = {
        id: this.id,
        nickname: this.nickname,
        type: this.type,
        address: {
          country: this.country,
          name1: this.name,
          city: this.city,
          province: this.province,
          postal_code: this.postalCode,
          street1: this.street1,
          street2: this.street2,
          phone1: (this.phone && this.phone !== '') ? this.phone : null,
          email: (this.email) ? this.email : null
        }
      }
      return json
    },
    ready () {
      if (this.requireNickname && !this.nickname) return false
      if (this.requireAddressName && !this.name) return false
      if (!this.isPhoneOptional && !this.phone) return false
      if (!this.isEmailOptional && !this.email) return false
      return (
        this.country !== null && this.country !== '' &&
        this.province !== null && this.province !== '' &&
        this.city !== null && this.city !== '' &&
        this.postalCode !== null && this.postalCode !== '' &&
        this.street1 !== null && this.street1 !== ''
      )
    },
    provinceLabel () {
      if (this.country === 'US') return 'State'
      if (this.country === 'GB') return 'County'
      if (this.country === 'IN') return 'State / Union Territory'
      if (this.country === 'RO') return 'Department'
      return 'Province'
    },
    postalCodeLabel () {
      if (this.country === 'US') return 'Zip Code'
      if (this.country === 'IN') return 'Pin Code / Zip Code'
      return 'Postal Code'
    },
    filteredCounties () {
      if (this.provinces.length > 0) {
        const sortedList = this.$utils.sortBy(this.provinces, [function (o) { return o.name }])
        return sortedList.filter((option) => {
          if (this.search) {
            return option.name.toString().toLowerCase().indexOf(this.search.toLowerCase()) >= 0
          } else {
            return option.name.toString().toLowerCase()
          }
        })
      } else return []
    }
  },
  data () {
    return {
      loading: true,
      provinces: [],
      countries: [],
      search: '',
      id: null,
      nickname: null,
      name: null,
      street1: null,
      street2: null,
      country: 'US',
      city: null,
      province: null,
      postalCode: null,
      phone: null,
      email: null,
      countryCodes: null
    }
  },
  methods: {
    loadProvinces (countryCode) {
      const endpoint = this.api.baseUrl + '/v1/provinces/?pagination=0&country_code=' + countryCode
      this.$http.get(endpoint).then(response => {
        this.provinces = response.data.results
        this.loading = false
      })
    },
    loadCountries () {
      const endpoint = this.api.baseUrl + '/v1/countries/'
      this.$http.get(endpoint).then(response => {
        this.countries = response.data.results
        this.loading = false
        this.countryCodes = response.data.results.reduce((acc, country) => {
          acc[country.code] = country.name
          return acc
        }, {})
      })
    },
    countryChange () {
      // reset province, city and postal code
      this.city = null
      this.province = null
      this.search = null
      this.postalCode = null
      this.loadProvinces(this.country)
      this.announce()
    },
    setProvince () {
      const selectedProvince = this.provinces.find(({ name }) => name === this.search)
      this.province = selectedProvince ? selectedProvince.code : this.search
      this.announce()
    },
    announce () {
      if (this.ready) {
        this.$emit('input', this.json)
      }
      this.$emit('is:ready', this.ready)
    }
  },
  mounted () {
    // Load Countries
    this.loadCountries()
    // Load provinces
    this.loadProvinces('US')
    // if a addressForm is passed for an edit, set all fields
    if (this.addressForm && this.addressForm !== null) {
      // map id (to drive POST or PATCH)
      this.id = this.addressForm.id
      this.nickname = this.addressForm.nickname
      // map properties
      this.name = this.addressForm.address.name1
      this.country = this.addressForm.address.country
      this.province = this.addressForm.address.province
      this.city = this.addressForm.address.city
      this.postalCode = this.addressForm.address.postal_code
      this.street1 = this.addressForm.address.street1
      this.street2 = this.addressForm.address.street2
      this.phone = this.addressForm.address.phone1
      this.email = this.addressForm.address.email
      // map for autocomplete
      this.search = this.addressForm.address.province
    } else {
      this.nickname = this.defaultNickname
      this.name = this.defaultAddressName
    }
  },
  props: {
    addressForm: {
      type: Object,
      default: null
    },
    requireNickname: {
      type: Boolean,
      default: false
    },
    defaultNickname: {
      type: String,
      default: null
    },
    requireAddressName: {
      type: Boolean,
      default: true
    },
    addressNameLabel: {
      type: String,
      default: null
    },
    defaultAddressName: {
      type: String,
      default: null
    },
    requireEmail: {
      type: Boolean,
      default: false
    },
    isEmailOptional: {
      type: Boolean,
      default: true
    },
    requirePhone: {
      type: Boolean,
      default: false
    },
    isPhoneOptional: {
      type: Boolean,
      default: true
    },
    errors: {
      type: Object,
      default () {
        return {}
      }
    },
    type: {
      type: String,
      validator: (value) => ['billing', 'warehouse', 'returns'].includes(value)
    }
  }
}
</script>
