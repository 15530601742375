<template>
  <div>
    <div class="rounded-lg has-background-white mb-lg mt-lg pa-md">
      <b-table
        :data="stats"
        sort-icon="arrow-up"
        default-sort="name"
        default-sort-direction="asc">
        <b-table-column
          field="name"
          label="Supplier"
          sortable
          v-slot="props">
          <router-link
            class="has-text-weight-bold has-text-info"
            :to="{ name: 'finance.detail.supplier', params: { year: year, month: month, day: day, id: props.row.id }}">
            {{ props.row.name }}
          </router-link>
        </b-table-column>
        <b-table-column
          field="invoiceSubtotal"
          label="Invoices"
          centered
          sortable
          width="128"
          v-slot="props">
          <p>{{ $numbers(props.row.invoiceSubtotal).formatCurrency({ mantissa: 2, thousandSeparated: true }) }}</p>
          <p class="is-size-7 has-text-grey-light">{{ props.row.invoiceCount }} {{ (props.row.invoiceCount === 1) ? 'Invoice' : 'Invoices' }}</p>
        </b-table-column>
        <b-table-column
          field="invoiceCharges"
          label="Charges"
          centered
          sortable
          width="128"
          v-slot="props">
          <p>{{ $numbers(props.row.invoiceCharges).formatCurrency({ mantissa: 2, thousandSeparated: true }) }}</p>
          <p class="is-size-7 has-text-grey-light">
            <span>{{ $numbers(props.row.invoiceCharges / props.row.invoiceSubtotal).format({ output: 'percent', mantissa: 2 }) }}</span>
          </p>
        </b-table-column>
        <b-table-column
          field="invoiceAllowances"
          label="Allowances"
          centered
          sortable
          width="128"
          v-slot="props">
          <p>{{ $numbers(props.row.invoiceAllowances).formatCurrency({mantissa: 2, thousandSeparated: true}) }}</p>
          <p class="is-size-7 has-text-grey-light">
            <span>{{ $numbers(props.row.invoiceAllowances / props.row.invoiceSubtotal).format({ output: 'percent', mantissa: 2 }) }}</span>
          </p>
        </b-table-column>
        <b-table-column
          field="creditSubtotal"
          label="Credits"
          centered
          sortable
          width="128"
          v-slot="props">
          <p>{{ $numbers(props.row.creditSubtotal).formatCurrency({mantissa: 2, thousandSeparated: true}) }}</p>
          <p class="is-size-7 has-text-grey-light">{{ props.row.creditCount }} {{ (props.row.creditCount === 1) ? 'Credit' : 'Credits' }}</p>
        </b-table-column>
        <template #empty>
          <div class="py-xxl has-text-grey has-text-centered">
            {{ (loading) ? "Loading data..." : 'No invoices found!' }}
          </div>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
export default {
  props: ['stats', 'year', 'month', 'day']
}
</script>
