<template>
  <div class="section">
    <div class="container">
      <!-- Loading Skeleton -->
      <div v-if="loading">
        <loading message="Loading..." />
      </div>
      <!-- Task is loaded -->
      <div v-else>
        <!-- Header -->
        <div class="mb-sm">
          <div class="columns is-vcentered">
            <div class="column is-one-third">
              <breadcrumbs :history="[{ label: 'Onboarding', name: 'retailer.onboarding'}]" current="Integrations" />
            </div>
            <div class="column is-one-third is-size-4 has-text-centered">
              Integrations
            </div>
          </div>
        </div>
        <div class="columns is-centered">
          <div class="column is-three-quarters">
            <div class="columns">
              <div class="column is-one-third">
                <div v-for="(step, index) in setupSteps"
                  :key="step.id"
                  :class="[step.path === currentPathName && 'has-background-grey-lighter', index === 0 && 'rounded-t-lg', index === 1 && 'rounded-b-lg', index !== 1 && 'has-border-b']"
                  class="has-background-white pa-md">
                  <router-link :to="{ name: step.path }" class=" has-text-black">
                    <div class="columns is-vcentered">
                      <div class="column is-narrow">
                        <b-icon v-if="step.isCompleted" icon="check" type="is-success"></b-icon>
                        <b-icon v-else icon="check" custom-class="has-text-grey-lighter"></b-icon>
                      </div>
                      <div class="column">{{step.label}}</div>
                      <div class="column is-narrow">
                        <b-icon icon="chevron-right"></b-icon>
                      </div>
                    </div>
                  </router-link>
                </div>
                <div class="is-size-7 has-text-grey-light mt-lg">
                  <div class="columns">
                    <div class="column">
                      <router-link :to="{ name:'retailer.onboarding' }">&lt; Back to Tasks</router-link>
                    </div>
                    <div class="column">
                      <p class="has-text-right">
                        <a v-if="auth.isStaff && isCompleted" href="#" @click="showResetTaskModal=true">Reset Task </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="column ml-md has-background-white rounded-lg">
                <router-view
                  :task="task"
                  :accounts="accounts"
                  :isUpdatingTask="isUpdatingTask"
                  @task:updated="(body, path)=>updateTaskInfo(body, path)"
                ></router-view>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Reset Task Modal -->
    <b-modal
      v-if="!loading && task !== null"
      :has-modal-card="true"
      :active.sync="showResetTaskModal"
      :can-cancel="['x', 'esc']">
      <ResetTaskModal :taskId="task.id" @task:reset="onTaskReset" />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ResetTaskModal from '@/components/Models/Task/Modals/ResetTaskModal'
export default {
  components: {
    ResetTaskModal
  },
  computed: {
    ...mapGetters(['app', 'auth', 'context']),
    currentPathName () {
      return this.$route.name
    },
    endpoint () {
      return this.context.route + '/assigned-tasks/' + this.$route.params.task + '/'
    },
    loading () {
      return (this.loadingTask || this.loadingAccounts)
    }
  },
  data () {
    return {
      loadingTask: true,
      isUpdatingTask: false,
      loadingAccounts: true,
      task: null,
      isCompleted: false,
      showResetTaskModal: false,
      accounts: [],
      setupSteps: [
        {
          id: 'integrationOptions',
          label: 'Integration Options',
          path: 'retailer.onboarding.integration',
          isCompleted: false
        }
      ]
    }
  },
  methods: {
    updateTaskInfo (taskBody, nextPath) {
      this.isUpdatingTask = true
      this.$http.patch(this.endpoint, taskBody).then(response => {
        this.task = response.data
        this.setupSteps.map(el => {
          if (el.id in this.task.info) {
            el.isCompleted = true
          }
          return el
        })
        // always reset steps if option is updated
        this.setupSteps = this.setupSteps.slice(0, 1)
        // add new tasks based on option selected
        this.loadTask(true)
        this.loadPlatformAccounts(true)
      }).finally(() => {
        this.isUpdatingTask = false
        if (nextPath) {
          this.$router.push({ name: nextPath, params: { task: this.$route.params.task } })
        }
      })
    },
    loadTask (quiet) {
      if (!quiet) this.loadingTask = true
      this.$http.get(this.endpoint).then(response => {
        this.task = response.data
        this.setupSteps.map(el => {
          if (el.id in this.task.info) {
            el.isCompleted = true
          } else if (this.task.completed_at) {
            el.isCompleted = true
          }
          return el
        })
        this.updateSteps()
        this.isCompleted = this.task.completed_at !== null
        this.loadingTask = false
      })
    },
    loadPlatformAccounts (quiet) {
      if (!quiet) this.loadingAccounts = true
      const endpoint = this.context.route + '/platform-accounts/'
      this.$http.get(endpoint).then(response => {
        const validIntegrations = ['fabric', 'bigcommerce', 'shopify']
        if (response.data.results && response.data.count > 0) {
          this.accounts = response.data.results.filter(el => validIntegrations.includes(el.platform.code))
        }
        this.accounts = response.data.results
        this.loadingAccounts = false
      })
    },
    onTaskReset () {
      this.$buefy.toast.open({ type: 'is-info', message: 'Task reset successfully.' })
      this.$router.push({ name: 'retailer.onboarding' })
    },
    updateSteps () {
      if (this.task.info && this.task.info.selectedOption) {
        const option = this.task.info.selectedOption
        const tasks = this.setupSteps
        const taskStatus = this.task.completed_at !== null
        if (option === 'shopify') {
          tasks.push({
            id: 'integrationConfigure',
            label: 'Shopify Installation',
            path: 'retailer.onboarding.configure',
            isCompleted: taskStatus
          })
          this.setupSteps = tasks
        } else if (option === 'api') {
          tasks.push({
            id: 'integrationConfigure',
            label: 'API',
            path: 'retailer.onboarding.configure',
            isCompleted: taskStatus
          })
          this.setupSteps = tasks
        } else if (option === 'fabric') {
          tasks.push({
            id: 'integrationConfigure',
            label: 'fabric',
            path: 'retailer.onboarding.configure',
            isCompleted: taskStatus
          })
          this.setupSteps = tasks
        } else if (option === 'bigcommerce') {
          tasks.push({
            id: 'integrationConfigure',
            label: 'BigCommerce',
            path: 'retailer.onboarding.configure',
            isCompleted: taskStatus
          })
          this.setupSteps = tasks
        }
      }
    }
  },
  mounted () {
    this.loadTask()
    this.loadPlatformAccounts()
  },
  props: []
}
</script>
