<template>
  <span :class="presenter.textClass">{{ presenter.label }}</span>
</template>

<script>
export default {
  computed: {
    presenter () {
      const presenter = {}
      switch (this.connection.status) {
        case 'suspended':
          presenter.label = 'Suspended'
          presenter.textClass = 'has-text-grey-light'
          break
        case 'onboarding':
          presenter.label = 'Onboarding'
          presenter.textClass = 'has-text-primary'
          break
        default:
          presenter.label = 'Active'
          presenter.textClass = 'has-text-success'
      }
      return presenter
    }
  },
  props: {
    connection: {
      type: Object,
      required: true
    }
  }
}
</script>
