<template>
  <div>
    <div class="columns">
      <div class="column is-one-fifth">
        <p class="has-text-weight-bold is-size-6 mb-sm">Orders</p>
        <p class="has-text-grey is-size-7">Configure how your Shopify store syncs orders with {{ app.platform }}.</p>
      </div>
      <div class="column">
        <div class="has-background-white rounded-lg">
          <div class="pa-lg">
            <!-- Intro Text (Merchant) -->
            <div class="is-size-7 mb-md">
              <p class="">
                When you enable a Shopify orders webhook, {{ app.platform }} will
                automatically import customer orders that contain items
                from your approved suppliers and distribute purchase orders to suppliers via
                their preferred integration method (Console, EDI, API, etc). When suppliers ship items, fulfillments and tracking numbers will be
                automatically synced back to the original customer order in your
                Shopify store account.
              </p>
            </div>
            <div v-if="!loading">
              <!-- Webhook Exists -->
              <div v-if="webhook !== null" class="has-background-white-ter  pa-md rounded-lg">
                <div class="columns is-vcentered is-mobile">
                  <div class="column is-narrow">
                    <b-icon icon="check" custom-class="has-text-success" />
                  </div>
                  <div class="column">
                    <p class="has-text-weight-bold">
                      <span v-if="webhook.topic === 'orders/paid'">Order Paid</span>
                      <span v-else-if="webhook.topic === 'orders/create'">Order Create</span>
                    </p>
                    <p class="has-text-grey-light is-size-8">
                      This integration is currently enabled via webhook id {{ webhook.id }}
                      (Shopify ID: {{ webhook.platform_identifier }})
                    </p>
                  </div>
                  <div class="column is-narrow">
                    <p class="has-text-weight-bold has-text-success">Enabled</p>
                  </div>
                </div>
              </div>
              <!-- Select Webhook -->
              <div v-else>
                <p class="has-text-weight-bold mb-sm">Select Webhook</p>
                <div class="mb-md">
                  <b-select v-model="topic" placeholder="Select a topic" expanded>
                    <option v-for="topic in topics" :key="topic.value" :value="topic">{{ topic.label }}</option>
                  </b-select>
                </div>
                <div class="is-size-7">
                  <p v-if="topic !== null">{{ topic.tooltip }}</p>
                  <p v-else>Select a topic to learn more...</p>
                </div>
              </div>
            </div>
            <div v-else class="has-background-white-ter rounded-lg">
              <p class="has-text-grey-light is-size-8 pa-md">Loading...</p>
            </div>
          </div>
          <div class="border-t">
            <div class="columns is-vcentered is-marginless">
              <div class="column">
                <div class="px-sm has-text-grey-light">
                  <i v-if="webhook !== null">Click "Disable Integration" to unsubscribe from the current webhook.</i>
                  <i v-else>Click "Enable Integration" to begin syncing new orders.</i>
                </div>
              </div>
              <div class="column is-narrow">
                <div class="px-sm">
                  <!-- Remove Webhook -->
                  <b-button
                    v-if="webhook !== null"
                    type="is-danger rounded-lg"
                    outlined
                    :disabled="loading || saving"
                    :loading="saving"
                    @click="disable"
                  >Disable Integration</b-button>
                  <!-- Subscribe to new Webhook -->
                  <b-button
                    v-if="webhook === null"
                    type="is-primary rounded-lg"
                    :disabled="loading || saving || topic === null"
                    @click="enable"
                    :loading="saving"
                  >Enable Integration</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['app', 'context']),
    endpoint () {
      return this.context.route + '/shopify/' + this.account.id + '/webhooks/'
    },
    json () {
      return {
        webhook_topic: this.topic.value
      }
    }
  },
  data () {
    return {
      loading: true,
      saving: false,
      errors: {},
      webhook: null,
      topics: [{
        label: 'Order Paid (Recommended)',
        value: 'orders/paid',
        tooltip: 'Push orders as soon as Shopify confirms the order is paid.'
      }, {
        label: 'Order Created',
        value: 'orders/create',
        tooltip: 'Push orders as soon as they are created in Shopify.'
      }],
      topic: null
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint).then(response => {
        // check for paid webhook first...
        const paid = this.$utils.find(response.data.results, ['topic', 'orders/paid'])
        if (paid) {
          this.webhook = paid
          return
        }
        // next, check for the create webhook
        const create = this.$utils.find(response.data.results, ['topic', 'orders/create'])
        if (create) {
          this.webhook = create
        }
      }).finally(() => {
        this.loading = false
      })
    },
    enable () {
      const endpoint = this.context.route + '/shopify/' + this.account.id + '/register-webhook/'
      this.saving = true
      this.$http.put(endpoint, this.json).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Sync enabled!' })
      }).finally(() => {
        this.saving = false
        this.load()
      })
    },
    disable () {
      this.saving = true
      this.$http.delete(this.endpoint + this.webhook.id + '/').then(() => {
        this.webhook = null
        this.$buefy.toast.open({ type: 'is-success', message: 'Sync disabled!' })
      }).finally(() => {
        this.saving = false
        this.load()
      })
    }
  },
  mounted () {
    this.load()
  },
  props: ['account']
}
</script>
