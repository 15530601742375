<template>
  <div class="section">
    <div class="container">
      <!-- Page Header -->
      <div class="mb-lg">
        <div class="columns is-vcentered">
          <div class="column is-one-third">
            <breadcrumbs :history="[{ label: 'Context', name: 'context'}]" current="Browse" />
          </div>
          <div class="column is-one-third">
            <h1 class="has-text-centered is-size-4">Suppliers</h1>
          </div>
        </div>
      </div>
      <!-- Filters Toolbar -->
      <div class="has-background-white rounded-lg pa-md mb-xxs">
        <div class="columns">
          <div class="column">
            <SupplierFilters :applied-filters="filters" @filter="filter" />
          </div>
          <div class="column is-narrow">
            <b-select v-model="orderBy" size="is-medium" expanded @input="sort">
              <option value="-id">Most Recent</option>
              <option value="id">Oldest</option>
            </b-select>
          </div>
        </div>
      </div>
      <!-- Actions Toolbar -->
      <div class="has-background-white rounded-lg px-md py-sm mb-xxs">
        <div class="columns is-vcentered is-mobile">
          <div class="column is-narrow">
            <b-dropdown :disabled="loading || selected.length === 0">
              <button class="button" slot="trigger" slot-scope="{ active }" :disabled="selected.length === 0">
                <span>Actions</span>
                <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
              </button>
              <template v-if="context.isSupplier()">
                <b-dropdown-item has-link><a href="#" @click.prevent class="has-text-grey-light">No Actions</a></b-dropdown-item>
              </template>
              <template v-if="context.isMerchant()">
                <b-dropdown-item has-link><a href="#" @click.prevent class="has-text-grey-light">No Actions</a></b-dropdown-item>
              </template>
            </b-dropdown>
          </div>
          <div class="column">
            <div class="has-text-right is-size-7">
              <!-- Counts -->
              <p v-if="loading">Loading...</p>
              <p v-else>{{ total }} suppliers found.</p>
            </div>
          </div>
        </div>
      </div>
      <!-- Results -->
      <div>
        <div class="mb-md">
          <b-table
            :data="results"
            :checked-rows.sync="selected"
            :mobile-cards="false"
            :loading="loading"
            hoverable
            checkable
            checkbox-position="left"
            class="is-size-7">
            <template>
              <!-- ID  -->
              <b-table-column
                label="ID"
                header-class="nowrap"
                cell-class="nowrap"
                width="96"
                v-slot="props">
                <a href="#"
                  class="has-text-weight-bold has-text-info"
                  @click.prevent="inspectSupplier = props.row"
                >{{ props.row.id }}</a>
              </b-table-column>
              <!-- Name -->
              <b-table-column
                label="Name"
                header-class="nowrap"
                cell-class="nowrap"
                v-slot="props">
                {{ props.row.name }}
              </b-table-column>
              <!-- Created -->
              <b-table-column
                label="Created"
                header-class="nowrap"
                cell-class="nowrap"
                v-slot="props"
                numeric>
                {{ $dates.utc(props.row.joined_at).local().format("MMM D, YYYY") }}
              </b-table-column>
            </template>
            <template #empty>
              <div class="py-xxl has-text-grey has-text-centered">
                {{ (loading) ? "Loading data..." : 'No suppliers found!' }}
              </div>
            </template>
          </b-table>
        </div>
        <b-pagination
          :total="total"
          :current.sync="currentPage"
          :rounded="true"
          :per-page="perPage"
          @change="paginate">
        </b-pagination>
      </div>
      <!-- Inspect -->
      <b-modal
        v-if="(inspectSupplier !== null)"
        has-modal-card
        :active="(inspectSupplier !== null)"
        :can-cancel="['escape', 'x']"
        @close="closeModals"
        ><SupplierModal :selectedSupplier="inspectSupplier" />
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Filterable from '@/mixins/Filterable'
import SupplierModal from '@/components/Modals/Supplier/SupplierModal'
import SupplierFilters from '../components/List/SupplierFilters'

export default {
  components: {
    SupplierFilters,
    SupplierModal
  },
  data () {
    return {
      key: 'suppliers',
      alwaysAttach: {
        short: 1
      },
      orderBy: '-id',
      // modals
      inspectSupplier: null
    }
  },
  computed: {
    ...mapGetters(['api', 'context']),
    endpoint () {
      return this.api.baseUrl + '/v1/brands/'
    }
  },
  methods: {
    closeModals () {
      this.inspectSupplier = null
    },
    reload () {
      this.selected = []
      this.load()
    }
  },
  mounted () {
    this.load()
  },
  mixins: [Filterable]
}
</script>
