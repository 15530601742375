<template>
  <form @submit.prevent>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">{{label}}</p>
      </header>
      <section class="modal-card-body">
        <b-message :type="messageType"> {{reason}} </b-message>
      </section>
      <footer class="modal-card-foot space-between">
        <b-button @click="close">Close</b-button>
        <b-button :type="confirmButtonType" @click="confirm" :loading="saving">{{ confirmButtonText }}</b-button>
      </footer>
    </div>
  </form>
</template>
<script>
export default {
  methods: {
    close () {
      this.$emit('modal:close')
    },
    confirm () {
      this.$emit('modal:confirm')
    }
  },
  props: {
    label: {
      type: String,
      required: true
    },
    reason: {
      type: String,
      required: true
    },
    messageType: {
      type: String,
      default: ''
    },
    saving: {
      type: Boolean,
      default: false
    },
    confirmButtonText: {
      type: String,
      default: 'Confirm'
    },
    confirmButtonType: {
      type: String,
      enum: ['is-primary', 'is-danger'],
      default: 'is-primary'
    }
  }
}
</script>
