<template>
  <form @submit.prevent="save">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ (webhook === null) ? 'Add Webhook' : 'Update Webhook' }}</p>
      </header>
      <section class="modal-card-body">
        <div class="pb-lg">
          <!-- Error -->
          <b-message v-if="error !== null" type="is-danger">
            <span class="has-text-weight-bold">Error! </span>
            <span>{{ error }}</span>
          </b-message>
          <!-- Topic -->
          <b-field
            label="Topic"
            :type="('topic' in errors) ? 'is-danger' : ''"
            :message="('topic' in errors) ? errors['topic'][0] : ''"
            expanded>
            <b-select
              v-model="topic"
              placeholder="Select Topic"
              expanded>
              <option value="Order Canceled">Cancel Created</option>
              <option value="Credit Memo Created">Credit Memo Created</option>
              <option value="Invoice Created">Invoice Created</option>
              <option value="Variant Inventory Updated">Item Inventory Updated</option>
              <option value="Permit Created">Offer Created</option>
              <option value="Order Closed">Order Closed</option>
              <option value="Order Created">Order Created</option>
              <option value="Proposal Approved">Proposal Approved</option>
              <option value="RMA Approved">RMA Approved</option>
              <option value="RMA Rejected">RMA Rejected</option>
              <option value="Shipment Closed">Shipment Closed</option>
              <option value="Description Attribute Updated">Variant Description Attribute Updated</option>
              <option value="General Attribute Updated">Variant General Attribute Updated</option>
              <option value="Identifiers Attribute Updated">Variant Identifier Attribute Updated</option>
              <option value="Images Attribute Updated">Variant Image Attribute Updated</option>
              <option value="Misc Attribute Updated">Variant Misc Attribute Updated</option>
              <option value="Shipping Attribute Updated">Variant Shipping Attribute Updated</option>
            </b-select>
          </b-field>
          <!-- Method & URL -->
          <div class="columns">
            <div class="column is-narrow">
              <b-field
                label="Method"
                :type="('target_method' in errors) ? 'is-danger' : ''"
                :message="('target_method' in errors) ? errors['target_method'][0] : ''"
                expanded>
                <b-select
                  v-model="targetMethod"
                  expanded>
                  <option value="post">POST</option>
                  <option value="put">PUT</option>
                  <option value="patch">PATCH</option>
                </b-select>
              </b-field>
            </div>
            <div class="column">
              <b-field
                label="URL"
                :type="('target_url' in errors) ? 'is-danger' : ''"
                :message="('target_url' in errors) ? errors['target_url'][0] : ''"
                expanded>
                  <b-input
                    autocomplete="off"
                    type="url"
                    v-model="targetUrl"
                  ></b-input>
              </b-field>
            </div>
          </div>
          <!-- Statsu -->
          <b-field
            label="Status"
            :type="('status' in errors) ? 'is-danger' : ''"
            :message="('status' in errors) ? errors['status'][0] : ''"
            expanded>
            <b-select
              v-model="status"
              expanded>
              <option value="enabled">Enabled</option>
              <option value="disabled">Disabled</option>
            </b-select>
          </b-field>
        </div>
      </section>
      <footer class="modal-card-foot space-between">
        <button class="button" type="button" @click="$parent.close()">Close</button>
        <b-button
          native-type="submit"
          class="button is-primary"
          :loading="saving"
          :disabled="!ready || saving"
          v-text="(webhook === null) ? 'Add Webhook' : 'Update Webhook'"
        ></b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      saving: false,
      error: null,
      errors: [],
      isDirty: false,
      topic: null,
      targetMethod: null,
      targetUrl: null,
      status: 'enabled'
    }
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      let route = this.context.route + '/webhooks/'
      if (this.webhook !== null) {
        route += this.webhook.id + '/'
      }
      return route
    },
    method () {
      return (this.webhook === null) ? 'post' : 'patch'
    },
    ready () {
      return (
        this.topic &&
        this.targetMethod &&
        this.targetUrl &&
        this.status
      )
    },
    json () {
      return {
        status: this.status,
        action: {
          name: this.topic
        },
        target_method: this.targetMethod,
        target_url: this.targetUrl
      }
    }
  },
  methods: {
    save () {
      this.saving = true
      this.$http[this.method](this.endpoint, this.json).then(response => {
        this.saving = false
        this.$emit('updated')
        this.$buefy.toast.open({ type: 'is-success', message: 'Location saved!' })
        this.$parent.close()
      }).catch(error => {
        this.saving = false
        if (error.response && error.response.status === 400) {
          this.errors = error.response.data
        }
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      })
    }
  },
  mounted () {
    if (this.webhook !== null) {
      this.topic = this.webhook.action.name
      this.targetMethod = this.webhook.target_method
      this.targetUrl = this.webhook.target_url
      this.status = this.webhook.status
    }
  },
  props: ['webhook']
}
</script>
