<template>
  <div>
    <form @submit.prevent="save">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Update Connection Shipping Methods</p>
        </header>
        <section class="modal-card-body">
          <div class="columns">
            <div class="column">
              <p>Please select the shipping methods that this connection is authorized to use via 3rd
                party billing. If you don't see a shipping account or method in the list below, please
                configure your <a href="/settings/shipping-accounts/" target="_blank">logistics settings</a>.
              </p>
            </div>
          </div>
          <div class="columns">
            <div v-if="loading" class="column">
              <b-loading :is-full-page="false" :active="loading" ></b-loading>
            </div>
            <div v-else class="column">
              <ConnectionShippingAccount
                v-for="shippingAccount in shippingAccounts"
                :key="shippingAccount.id"
                :shipping-account="shippingAccount"
                :current-shipping-methods="currentShippingMethods[shippingAccount.id] || []"
                @update:selection="updateSelection(shippingAccount, $event)"
              ></ConnectionShippingAccount>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot space-between">
          <b-button @click="$parent.close()">Close</b-button>
          <b-button
            type="is-primary"
            native-type="submit"
            :loading="saving"
            :disabled="saving">
            Save Settings
          </b-button>
        </footer>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ConnectionShippingAccount from '@/modules/Connections/components/ConnectionShippingAccount'
export default {
  components: {
    ConnectionShippingAccount
  },
  data () {
    return {
      loading: false,
      saving: false,
      shippingAccounts: [],
      selectedShippingMethods: {},
      currentShippingMethods: {},
      errors: {}
    }
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/shippingaccounts/?limit=250'
    },
    updateEndpoint () {
      return `${this.context.route}/connections/${this.connection.id}/shippingmethod/`
    },
    json () {
      const data = []
      for (const key in this.selectedShippingMethods) {
        if (this.selectedShippingMethods[key].isDirty) {
          data.push({
            shipping_account: {
              id: key,
              shipping_methods: this.selectedShippingMethods[key].shippingMethodIds.map(shippingMethodId => ({ id: shippingMethodId }))
            }
          })
        }
      }
      return data
    }
  },
  methods: {
    save () {
      this.saving = true
      const responses = []
      this.json.forEach(shippingAccount => {
        const promise = this.$http.put(this.updateEndpoint, shippingAccount)
        responses.push(promise)
      })
      Promise.all(responses).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Shipping methods updated!' })
      }).finally(() => {
        this.saving = false
        this.$emit('saved:methods')
        this.$parent.close()
      })
    },
    load () {
      this.loading = true
      this.currentShippingMethods = {}
      this.connection.shipping_accounts.forEach(shippingAccount => {
        this.currentShippingMethods[shippingAccount.id] = []
        this.selectedShippingMethods[shippingAccount.id] = { isDirty: false, shippingMethodIds: [] }
        shippingAccount.shipping_methods.forEach(shippingMethod => {
          this.currentShippingMethods[shippingAccount.id].push(shippingMethod.id)
          this.selectedShippingMethods[shippingAccount.id].shippingMethodIds.push(shippingMethod.id)
        })
      })
      this.$http.get(this.endpoint).then(response => {
        this.loading = false
        this.shippingAccounts = response.data.results
      })
    },
    updateSelection (shippingAccount, value) {
      this.$delete(this.selectedShippingMethods, shippingAccount.id)
      this.selectedShippingMethods[shippingAccount.id] = Object.assign({}, { isDirty: true, shippingMethodIds: value })
    }
  },
  mounted () {
    this.load()
  },
  props: ['connection']
}
</script>
