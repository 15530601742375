<template>
  <div>
    <div class="section">
      <div class="container">
        <div class="mb-md">
          <div class="columns is-vcentered">
            <div class="column is-one-third">
              <breadcrumbs :history="breadcrumbs.history" :current="breadcrumbs.current"/>
            </div>
            <div class="column is-one-third">
              <h1 class="has-text-centered is-size-4">BigCommerce Settings</h1>
            </div>
          </div>
        </div>
        <!-- Info -->
        <div v-if="!loading && account !== null" class="border-b mb-xl pb-xl">
          <StoreDetails :account="account" />
        </div>
        <!-- Push Orders (Suppliers) -->
        <div v-if="!loading && account !== null && context.isSupplier()" class="border-b mb-xl pb-xl">
          <PushOrders :account="account" @update:account="load(true)" />
        </div>
        <!-- Receive Orders (Merchants) -->
        <div v-if="!loading && account !== null && context.isMerchant()" class="border-b mb-xl pb-xl">
          <ReceiveOrders :account="account" />
        </div>
        <!-- Inventory Webhook (Suppliers & Merchants) -->
        <div v-if="!loading && account !== null" class="border-b mb-xl pb-xl">
          <Inventory :account="account" />
        </div>
        <!-- OrderFulfillment Webhook (Suppliers & Merchants) -->
        <div v-if="!loading && account !== null">
          <OrderFulfillment :account="account" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PushOrders from '../components/BigCommerce/PushOrders'
import ReceiveOrders from '../components/BigCommerce/ReceiveOrders'
import Inventory from '../components/BigCommerce/Inventory'
import OrderFulfillment from '../components/BigCommerce/OrderFulfillment'
import StoreDetails from '../components/BigCommerce/StoreDetails'

export default {
  components: {
    PushOrders,
    ReceiveOrders,
    Inventory,
    OrderFulfillment,
    StoreDetails
  },
  data () {
    return {
      loading: true,
      account: null
    }
  },
  computed: {
    ...mapGetters(['context']),
    breadcrumbs () {
      return {
        current: (this.loading) ? 'Loading...' : 'BigCommerce',
        history: [
          { label: 'Settings', name: 'settings' },
          { label: 'Integrations', name: 'settings.integrations' }
        ]
      }
    },
    endpoint () {
      return this.context.route + '/platform-accounts/' + this.$route.params.id + '/'
    }
  },
  methods: {
    load (refresh = false) {
      this.loading = (!refresh)
      this.$http.get(this.endpoint).then(response => {
        this.account = response.data
      }).finally(() => {
        this.loading = false
      })
    }
  },
  mounted () {
    this.load()
  },
  props: []
}
</script>
