<template>
  <div>
    <div class="columns is-mobile is-multiline">
      <!-- New Cancels -->
      <div class="column is-half">
        <div class="has-background-white rounded-lg px-md py-sm">
          <router-link :to="{ name: 'cancels.list', query: { preset: (context.isMerchant() ? 'newBrand' : 'newRetailer' )} }">
            <div class="has-text-centered">
              <p class="is-size-5 has-text-weight-bold" style="margin-bottom:-5px">
                <span v-if="loading"><b-icon icon="loading" custom-class="mdi-spin" size="is-small" /></span>
                <span v-else-if="cancelsCount > 0" class="has-text-danger">{{ cancelsCount }}</span>
                <span v-else class="has-text-success">0</span>
              </p>
              <p class="is-size-7 has-text-grey">New Cancels</p>
            </div>
          </router-link>
        </div>
      </div>
      <!-- New Backorders (Merchants) -->
      <div v-if="context.isMerchant()" class="column is-half">
        <div class="has-background-white rounded-lg px-md py-sm">
          <router-link :to="{ name: 'orders.list', query: { preset: 'newBackorders'} }">
            <div class="has-text-centered">
              <p class="is-size-5 has-text-weight-bold" style="margin-bottom:-5px">
                <span v-if="loading"><b-icon icon="loading" custom-class="mdi-spin" size="is-small" /></span>
                <span v-else class="has-text-success">{{ backordersCount }}</span>
              </p>
              <p class="is-size-7 has-text-grey">New Backorders</p>
            </div>
          </router-link>
        </div>
      </div>
      <!-- New Returns (Suppliers) -->
      <div v-if="context.isSupplier()" class="column is-half">
        <div class="has-background-white rounded-lg px-md py-sm">
          <router-link :to="{ name: 'returns.list', query: { preset: 'pending'} }">
            <div class="has-text-centered">
              <p class="is-size-5 has-text-weight-bold" style="margin-bottom:-5px">
                <span v-if="loading"><b-icon icon="loading" custom-class="mdi-spin" size="is-small" /></span>
                <span v-else class="has-text-success">{{ rmasCount }}</span>
              </p>
              <p class="is-size-7 has-text-grey">New Returns</p>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {
  },
  computed: {
    ...mapGetters(['context']),
    loading () {
      if (this.context.isSupplier()) {
        return (this.loadingCancels || this.loadingReturns)
      }
      return (this.loadingCancels || this.loadingBackorders)
    }
  },
  data () {
    return {
      loadingCancels: true,
      loadingBackorders: true,
      loadingReturns: true,
      cancelsCount: 0,
      backordersCount: 0,
      rmasCount: 0
    }
  },
  methods: {
    loadCancels () {
      this.loadingCancels = true
      const endpoint = this.context.route + '/cancels/'
      const params = {
        params: {
          canceled_by: (this.context.isMerchant()) ? 'brand' : 'retailer',
          is_acknowledged: 0,
          fields: 'id',
          limit: 1
        }
      }
      this.$http.get(endpoint, params).then(response => {
        this.cancelsCount = response.data.count
        this.loadingCancels = false
      })
    },
    loadReturns () {
      this.loadingReturns = true
      const endpoint = this.context.route + '/rmas/'
      const params = {
        params: {
          status: 'pending',
          fields: 'id',
          limit: 1
        }
      }
      this.$http.get(endpoint, params).then(response => {
        this.rmasCount = response.data.count
        this.loadingReturns = false
      })
    },
    loadBackorders () {
      this.loadingBackorders = true
      const endpoint = this.context.route + '/orders/'
      const params = {
        params: {
          status: 'open,partial',
          is_backordered: 1,
          backorder_is_acknowledged: 0,
          fields: 'id',
          limit: 1
        }
      }
      this.$http.get(endpoint, params).then(response => {
        this.backordersCount = response.data.count
        this.loadingBackorders = false
      })
    }
  },
  mounted () {
    if (this.context.isSupplier()) {
      this.loadCancels()
      this.loadReturns()
    } else {
      this.loadCancels()
      this.loadBackorders()
    }
  },
  props: []
}
</script>
https://api-sandbox.revcascade.com/v1/retailers/1161/orders/?status=open,partial&is_backordered=1&backorder_is_acknowledged=0&order_by=-id&page=1&limit=12
