<template>
<div>
  <div class="section">
    <div class="container">
      <!-- Page Header -->
      <div class="columns is-vcentered">
        <div class="column is-one-third">
          <breadcrumbs :history="breadcrumbs.history" :current="breadcrumbs.current"  />
        </div>
        <div class="column is-one-third">
          <h1 class="has-text-centered is-size-4">Template</h1>
        </div>
      </div>
      <!-- Template Basics -->
      <div class="pb-lg border-b mb-lg">
        <div class="columns">
          <div class="column is-one-fifth">
            <p class="has-text-weight-bold is-size-6 mb-sm">Template Settings</p>
            <p class="has-text-grey is-size-7">
              Configure basic settings for this template.
            </p>
          </div>
          <div class="column">
            <div v-if="!loading && template !== null">
              <TemplateInfo :template="template" />
            </div>
            <div v-else>
              <div class="has-background-white rounded-lg pa-lg">
                <loading message="Loading Template..." />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Template Mappings -->
      <div class="columns">
        <div class="column is-one-fifth">
          <p class="has-text-weight-bold is-size-6 mb-sm">Template Mappings</p>
          <p class="has-text-grey is-size-7">
            Review the template columns.
          </p>
        </div>
        <div class="column">
          <div v-if="!loading && template !== null">
            <TemplateMappings :template="template" />
          </div>
          <div v-else>
            <div class="has-background-white rounded-lg pa-lg">
              <loading message="Loading Template..." />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import TemplateInfo from '../components/Template/TemplateInfo'
import TemplateMappings from '../components/Template/TemplateMappings'

export default {
  components: {
    TemplateInfo,
    TemplateMappings
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/templates/' + this.$route.params.id + '/'
    },
    breadcrumbs () {
      return {
        history: [
          { label: 'Settings', name: 'settings' },
          { label: 'Templates', name: 'templates' }
        ],
        current: (this.loading) ? 'Loading...' : '#' + this.template.id
      }
    }
  },
  data () {
    return {
      loading: true,
      template: null
    }
  },
  methods: {
    load () {
      this.$http.get(this.endpoint).then(response => {
        this.template = response.data
        this.loading = false
      })
    }
  },
  mounted () {
    this.load()
  }
}
</script>
