<template>
  <div>
    <!-- Content -->
    <div class="section">
      <div class="container">
        <!-- Page Header -->
        <div class="mb-md">
          <div class="columns is-vcentered">
            <div class="column is-one-third">
              <breadcrumbs :history="breadcrumbs.history" :current="breadcrumbs.current" />
            </div>
            <div class="column is-one-third">
              <h1 class="has-text-centered is-size-4">Import Request</h1>
            </div>
          </div>
        </div>
        <!-- Order Loaded -->
        <div v-if="!loading && this.importRequest !== null">
          <!-- Header -->
          <div class="has-background-white rounded-lg px-lg py-md mb-xxs">
            <div class="columns is-vcentered is-mobile">
              <div class="column">
                <p class="is-size-4">
                  Import Request #{{ (importRequest.reference_number) ? importRequest.reference_number : importRequest.id }}</p>
                <p class="has-text-grey">{{ importRequest.retailer.name }}</p>
              </div>
              <div class="column is-narrow has-text-right">
                <p class="is-size-4 has-text-weight-bold">
                  <span v-if="importRequest.status === 'approved'" class="has-text-success">Approved</span>
                  <span v-else-if="importRequest.status === 'rejected'" class="has-text-grey-light">Declined</span>
                  <span v-else class="has-text-grey">Pending</span>
                </p>
                <p v-if="importRequest.rejected_at !== null" class="has-text-grey">{{ $utils.startCase(importRequest.reject_reason) }}</p>
              </div>
            </div>
          </div>
          <!-- Actions -->
          <div class="has-background-white rounded-lg py-md px-lg mb-lg">
            <div class="columns is-vcentered is-variable is-2">
              <!-- Request Requires Action -->
              <template v-if="context.isSupplier() && importRequest.status === 'pending'">
                <div class="column is-one-quarter">
                  <b-button type="is-primary" size="is-medium" expanded  @click="showApproveModal = true">Approve</b-button>
                </div>
                <div class="column is-one-quarter">
                  <b-button type="is-light" size="is-medium" expanded  @click="showDeclineModal = true">Decline</b-button>
                </div>
              </template>
              <!-- All Other States -->
              <div v-else class="column is-one-quarter">
                <b-button type="is-light" size="is-medium" expanded  @click.prevent :disabled="true">
                  No Actions
                </b-button>
              </div>
            </div>
          </div>
          <!-- Identifiers & Dates -->
          <div class="mb-md">
            <div class="has-background-white rounded-lg pa-lg">
              <div class="columns">
                <!-- Identifiers -->
                <div class="column is-half">
                  <p class="has-text-primary has-text-weight-bold pb-sm has-border-b has-border-lg">Request Info</p>
                  <table class="table is-fullwidth is-striped is-size-7 is-narrow">
                    <tbody>
                      <tr>
                        <th>ID</th>
                        <td class="has-text-right">
                          {{ importRequest.id }}
                        </td>
                      </tr>
                      <tr>
                        <th>Supplier</th>
                        <td class="has-text-right">
                          {{ importRequest.brand.name }}
                        </td>
                      </tr>
                      <tr>
                        <th>Merchant</th>
                        <td class="has-text-right">
                          {{ importRequest.retailer.name }}
                        </td>
                      </tr>
                      <tr>
                        <th>Submitted</th>
                        <td class="has-text-right">
                          {{ $dates.utc(importRequest.created_at).local().format('MMM D, YYYY @ h:mm A') }}
                        </td>
                      </tr>
                      <tr v-if="importRequest.reviewed_by !== null">
                        <th>Reviewed By</th>
                        <td class="has-text-right">
                          {{ importRequest.reviewed_by.first_name }}
                          {{ importRequest.reviewed_by.last_name }}
                        </td>
                      </tr>
                      <tr v-if="importRequest.approved_at !== null">
                        <th>Reviewed At </th>
                        <td class="has-text-right">
                          {{ $dates.utc(importRequest.approved_at).local().format('MMM D, YYYY @ h:mm A') }}
                        </td>
                      </tr>
                      <tr v-if="importRequest.rejected_at !== null">
                        <th>Reviewed At </th>
                        <td class="has-text-right">
                          {{ $dates.utc(importRequest.rejected_at).local().format('MMM D, YYYY @ h:mm A') }}
                        </td>
                      </tr>
                      <tr v-if="importRequest.rejected_at !== null">
                        <th>Reason </th>
                        <td class="has-text-right">
                          {{ importRequest.reject_reason }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- File Info -->
                <div class="column is-half">
                  <p class="has-text-primary has-text-weight-bold pb-sm has-border-b has-border-lg">File Info</p>
                  <table class="table is-fullwidth is-striped is-size-7 is-narrow">
                    <tbody>
                      <tr>
                        <th>File</th>
                        <td class="has-text-right">
                          <a :href="importRequest.feed_file.file"
                            target="_blank"
                            class="has-text-info">{{ importRequest.feed_file.filename }}</a>
                        </td>
                      </tr>
                      <tr>
                        <th>Template</th>
                        <td class="has-text-right">
                          {{ importRequest.template.name }} (ID #{{ importRequest.template.id }})
                        </td>
                      </tr>
                      <tr v-if="importRequest.feed_queue !== null">
                        <th>Queue ID</th>
                        <td class="has-text-right">
                          <a href="#" @click.prevent="inspectFeed = importRequest.feed_queue" class="has-text-info">{{ importRequest.feed_queue.id }}</a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Loading Skeleton... -->
        <div v-else>
          <div class="has-background-white pa-lg rounded-lg mb-xs">
            <div class="placeholder placeholder-title" style="width:66%"></div>
            <div class="placeholder placeholder-sentence" style="width:50%"></div>
          </div>
          <div class="has-background-white px-lg py-xxl rounded-lg mb-lg">
            <div class="columns">
              <div class="column">
                <div class="placeholder placeholder-sentence mb-lg"></div>
                <div class="placeholder placeholder-sentence mb-lg"></div>
                <div class="placeholder placeholder-sentence"></div>
              </div>
              <div class="column">
                <div class="placeholder placeholder-sentence mb-lg"></div>
                <div class="placeholder placeholder-sentence mb-lg"></div>
                <div class="placeholder placeholder-sentence"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Approve Modal -->
    <b-modal
      v-if="context.isSupplier() && importRequest !== null"
      has-modal-card
      :active.sync="showApproveModal"
      :can-cancel="['escape', 'x']"
      >
      <div>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Approve Import Request?</p>
          </header>
          <section class="modal-card-body">
            <b-message type="is-info">
              <p class="mb-lg">
                If you approve this import request, the file submitted by {{ importRequest.retailer.name }}
                will be queued for processing as if you had
                uploaded the file yourself. This may
                add products to your catalog or update products already in your catalog.
              </p>
              <p>
                Please ensure you have reviewed the proposed file and, if applicable,
                reviewed any pricing (such as costs and/or retail). If you notice
                <u>ANY</u> issues, you should not approve this import request.
              </p>
            </b-message>
          </section>
          <footer class="modal-card-foot space-between">
            <button class="button" type="button" @click="showApproveModal = false">Close</button>
            <b-button
              type="is-primary"
              @click="approve"
              :loading="processing"
              :disabled="processing"
            >Approve</b-button>
          </footer>
        </div>
      </div>
    </b-modal>
    <!-- Decline Modal -->
    <b-modal
      v-if="context.isSupplier() && importRequest !== null"
      has-modal-card
      :active.sync="showDeclineModal"
      :can-cancel="['escape', 'x']"
      >
      <div>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Decline Import Request?</p>
          </header>
          <section class="modal-card-body">
            <b-field label="Select Reason">
              <b-select v-model="rejectReason" placeholder="Select a Reason" expanded size="is-medium">
                <option value="Wrong Assortment">Incorrect Assortment</option>
                <option value="Pricing Errors">Pricing Errors</option>
              </b-select>
            </b-field>
          </section>
          <footer class="modal-card-foot space-between">
            <button class="button" type="button" @click="showDeclineModal = false">Close</button>
            <button
              class="button"
              type="button"
              @click="decline"
              :loading="processing"
              :disabled="processing || !canReject"
            >Decline</button>
          </footer>
        </div>
      </div>
    </b-modal>
    <!-- Inspection Modal -->
    <b-modal
      v-if="(inspectFeed !== null)"
      has-modal-card
      :active="(inspectFeed !== null)"
      :can-cancel="['escape', 'x']"
      @close="inspectFeed = null"
      ><FeedQueueModal :selectedFeed="inspectFeed" />
    </b-modal>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FeedQueueModal from '@/components/Modals/FeedQueue/FeedQueueModal'

export default {
  components: {
    FeedQueueModal
  },
  data () {
    return {
      loading: true,
      refreshing: false,
      processing: false,
      importRequest: null,
      rejectReason: null,
      showApproveModal: false,
      showDeclineModal: false,
      inspectFeed: null
    }
  },
  computed: {
    ...mapGetters(['context']),
    breadcrumbs () {
      return {
        current: (this.loading) ? 'Loading...' : '#' + this.$route.params.id,
        history: [
          { label: 'Products', name: (this.context.isSupplier()) ? 'catalog' : 'catalog.index' },
          { label: 'Import Requests', name: 'catalog.import.requests' }
        ]
      }
    },
    endpoint () {
      return this.context.route + '/feed-requests/' + this.$route.params.id + '/'
    },
    canReject () {
      return this.rejectReason !== null
    },
    rejectJson () {
      return {
        reject_reason: this.rejectReason
      }
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint).then(response => {
        this.importRequest = response.data
        this.loading = false
      })
    },
    refresh () {
      this.refreshing = true
      this.$http.get(this.endpoint).then(response => {
        this.importRequest = response.data
        this.refreshing = false
      })
    },
    approve () {
      this.processing = true
      this.$http.put(this.endpoint + 'approve/').then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Import request approved! Please allow up to 30 mins for your feed to process' })
        this.showApproveModal = false
        this.rejectReason = null
        this.refresh()
      }).finally(() => {
        this.processing = false
      })
    },
    decline () {
      this.processing = true
      this.$http.put(this.endpoint + 'reject/', this.rejectJson).then(response => {
        this.showDeclineModal = false
        this.$buefy.toast.open({ type: 'is-warning', message: 'Import request declined!' })
        this.rejectReason = null
        this.refresh()
      }).finally(() => {
        this.processing = false
      })
    }
  },
  mixins: [],
  mounted () {
    this.load()
  },
  props: []
}
</script>
