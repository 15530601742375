<template>
  <div>
    <!-- Content -->
    <div class="">
      <p class="has-text-weight-bold mb-md">Begin Import</p>
      <p class="pb-lg">
        We're now ready to import your products! Please review the settings
        below and click "Begin Import" when ready. Your import will start immediately
        and you do not need to wait for the import to finish. You should see products
        appear in your {{ app.platform }} catalog within 5-15 minutes.
      </p>
    </div>
    <!-- Navigate -->
    <div class="py-lg">
      <div class="columns is-centered">
        <div class="column">
          <b-button
            type="is-light rounded-lg"
            size="is-medium"
            @click="goBack"
            expanded
            ><b-icon icon="arrow-left"></b-icon>
          </b-button>
        </div>
        <div class="column">
          <b-button
            type="is-primary rounded-lg"
            size="is-medium"
            expanded
            :disabled="!ready || saving"
            :loading="saving"
            @click="goForward"
          >Begin Import</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {

  },
  computed: {
    ...mapGetters(['app', 'context']),
    ready () {
      return true
    },
    query () {
      const qs = {}
      if (this.settings.dropshipCostPercent) {
        qs.dropship_cost_percent = this.settings.dropshipCostPercent
      }
      if (this.settings.collection) {
        qs.collection_id = this.settings.collection.id
      }
      return (!this.$utils.isEmpty(qs))
        ? { params: qs }
        : null
    }
  },
  data () {
    return {
      saving: false,
      errors: {}
    }
  },
  methods: {
    goBack () {
      this.$emit('navigate', 'Intro')
    },
    goForward () {
      this.saving = true
      const endpoint = this.context.route + '/platform-accounts/' + this.account.id + '/pull-products/'
      this.$http.get(endpoint, this.query).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Import scheduled!' })
        this.$router.replace({ name: 'onboarding.tasks.import-shopify', query: { job_id: response.data.id } })
        this.$emit('job:queued', response.data)
        this.$emit('navigate', 'Status')
        this.saving = false
      })
    }
  },
  props: {
    connection: {
      type: Object,
      required: true
    },
    task: {
      type: Object,
      required: true
    },
    account: {
      type: Object,
      required: true
    },
    settings: {
      type: Object,
      required: true
    }
  }
}
</script>
