import dates from '@/assets/js/dates'

export const status = {
  label: 'Status',
  key: 'status',
  icon: 'pulse',
  tooltip: 'Filter departments by status',
  options: [{
    value: '1',
    label: 'Active'
  }, {
    value: '0',
    label: 'Inactive'
  }]
}

export const updatedAt = {
  label: 'Updated At',
  key: 'updated_at',
  icon: 'calendar-outline',
  tooltip: 'Filter departments based the date and time they were last updated.',
  options: dates
}
