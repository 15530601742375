<template>
  <div>
    <!-- Context Specific -->
    <div class="mb-lg has-text-centered">
      <component :is="(context.type === 'vendor') ? 'Supplier' : 'Merchant'" />
    </div>
    <!-- Terms of Service -->
    <div class="pb-lg mb-lg">
      <p class="has-text-weight-bold mb-sm">Terms of Service</p>
      <terms-of-service v-model="agreed" />
    </div>
    <!-- Buttons -->
    <div class="mb-xl">
      <div class="columns is-centered">
        <div class="column is-three-quarters-tablet is-two-thirds-widescreen">
          <div class="columns">
            <div class="column">
              <b-button type="is-light rounded-lg" size="is-large" @click="back" expanded>
                <b-icon icon="arrow-left"></b-icon>
              </b-button>
            </div>
            <div class="column">
              <b-button
                type="is-primary rounded-lg"
                size="is-large"
                expanded
                @click="save"
                :loading="saving"
                :disabled="!agreed || saving"
              >Save & Continue</b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TermsOfService from '../../components/TermsOfService'
import Supplier from '../../components/Plans/Supplier'
import Merchant from '../../components/Plans/Merchant'

export default {
  components: {
    Merchant,
    Supplier,
    TermsOfService
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/marketplaces/?mine=1'
    }
  },
  data () {
    return {
      saving: false,
      plan: null,
      agreed: false
    }
  },
  methods: {
    back () {
      this.$router.go(-1)
    },
    save () {
      this.saving = true
      setTimeout(() => {
        this.$emit('update:step', 2)
        if (this.context.type === 'vendor') {
          this.$router.push({ name: 'shopify.onboard.inventory' })
        } else {
          this.$router.push({ name: 'shopify.onboard.integration' })
        }
      }, 1500)
    }
  },
  mounted () {
    this.$emit('update:step', 1)
  },
  props: []
}
</script>
