import Users from './views/List'
import User from './views/Detail'
import Clients from './views/Clients'
import Client from './views/Client'

export default [{
  path: '/settings/team',
  name: 'users',
  component: Users
}, {
  path: '/settings/team/:id',
  name: 'user',
  component: User
}, {
  path: '/settings/api',
  name: 'clients',
  component: Clients
}, {
  path: '/settings/api/:id',
  name: 'client',
  component: Client
}]
