<template>
  <div>
    <div v-if="!loading && returns.length > 0">
      <!-- Create New Return -->
      <div class="border-b">
        <div class="columns is-vcentered is-paddingless is-marginless">
          <div class="column">
            Return Labels
          </div>
          <div class="column is-narrow">
            <div class="has-text-right">
              <b-button type="is-primary" @click.prevent="$emit('label:create')">New Label</b-button>
              &nbsp;
              <b-button type="is-light" @click.prevent="refresh" :loading="refreshing" :disabled="refreshing">
                <b-icon icon="refresh" />
              </b-button>
            </div>
          </div>
        </div>
      </div>
      <table class="table is-size-7 is-fullwidth">
        <tr>
          <th style="width:10%">ID</th>
          <th>Carrier</th>
          <th>Tracking</th>
          <th style="width:15%">Label</th>
        </tr>
        <tr v-for="record in returns" :key="record.id">
          <td>
            <a href="#">{{ record.id }}</a>
          </td>
          <td>
            <p v-if="record.carrier !== null">{{ record.carrier.name }}</p>
            <p v-else class="has-text-grey is-italic">--</p>
          </td>
          <td>
            <p v-if="record.return_tracking_number !== null">{{ record.return_tracking_number }}</p>
            <p v-else class="has-text-grey is-italic">- Not Generated -</p>
          </td>
          <td>
            <a href="#" class="has-text-info" v-if="record.return_tracking_number !== null" @click.prevent="$emit('label:get', record)">Get</a>
            <p v-else class="has-text-grey is-italic">-</p>
          </td>
        </tr>
      </table>
    </div>
    <div v-else-if="!loading && returns.length === 0">
      <div class="has-text-centered pa-xxl">
        <p class="mb-md">No return labels have been generated yet for this RMA yet.</p>
        <p><b-button type="is-primary" size="is-medium" @click="$emit('label:create')">Generate Label</b-button></p>
      </div>
    </div>
    <div v-else>
      <loading message="Checking for labels..." />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  data () {
    return {
      loading: true,
      refreshing: false,
      returns: []
    }
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/rmas/' + this.rma.id + '/returns/'
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint).then(response => {
        this.returns = response.data.results
      }).finally(() => {
        this.loading = false
      })
    },
    refresh () {
      this.refreshing = true
      this.$http.get(this.endpoint).then(response => {
        this.returns = response.data.results
      }).finally(() => {
        this.refreshing = false
      })
    }
  },
  mounted () {
    this.load()
  },
  props: ['rma']
}
</script>
