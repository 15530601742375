<template>
  <div class="columns">
    <div class="column is-one-fifth">
      <p class="has-text-weight-bold is-size-6 mb-sm">Ship To</p>
      <p class="has-text-grey">Add your ship To address.</p>
    </div>
    <div class="column">
      <div class="has-background-white rounded-lg pa-md pb-lg">
        <div v-if="address !== null">
          <p>{{ address.address.name1 }}</p>
          <p>{{ address.address.street1 }}</p>
          <p v-if="address.address.street2">{{ address.address.street2 }}</p>
          <p>
            {{ address.address.city }}, {{ address.address.province }}
            {{ address.address.postal_code }}
          </p>
          <p v-if="address.address.phone1 !== null">
            {{ address.address.phone1 }}
          </p>
          <p v-if="address.address.email !== null">
            {{ address.address.email }}
          </p>
          <p class="is-italic has-text-grey-light">
            Click
            <a class="has-text-info" href="#" @click.prevent="edit">here</a>
            to update the address
          </p>
        </div>
        <div v-else>
          Click
          <a
            class="has-text-info"
            href="#"
            @click.prevent="showAddressModal = true"
            >here</a
          >
          to enter the address
        </div>
      </div>
    </div>
    <b-modal
      has-modal-card
      :active.sync="showAddressModal"
      :can-cancel="['escape', 'x']"
    >
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Add Ship To Address</p>
        </header>
        <section class="modal-card-body">
          <AddressForm
            v-model="address"
            :addressForm="address"
            :requireEmail="true"
            :requirePhone="true"
            @is:ready="isReady"
          />
        </section>
        <footer class="modal-card-foot space-between">
          <b-button @click="showAddressModal = false">Cancel</b-button>
          <div class="space-between">
            <b-button v-if="isEdit" type="is-primary" @click="remove"
              >Remove</b-button
            >
            <b-button type="is-primary" :disabled="!isValidated" @click="save"
              >Save</b-button
            >
          </div>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import AddressForm from '@/components/Global/AddressForm'
export default {
  components: {
    AddressForm
  },
  computed: {},
  data: () => {
    return {
      showAddressModal: false,
      address: null,
      isValidated: false,
      isEdit: false
    }
  },
  methods: {
    edit () {
      this.showAddressModal = true
      this.isEdit = true
    },
    isReady (val) {
      this.isValidated = val
    },
    save () {
      this.$emit('input', { shipTo: this.address })
      this.reset()
    },
    remove () {
      this.address = null
      this.$emit('input', { shipTo: null })
      this.reset()
    },
    reset () {
      this.isEdit = false
      this.showAddressModal = false
    }
  },
  mounted () {},
  props: ['payload'],
  watch: {
    payload (value) {
      if (value !== null && value.shipTo && value.shipTo.address) {
        this.address = value.shipTo.address
      }
    }
  }
}
</script>
