import List from './views/List'
import Index from './views/Index'
import Adjustments from './views/Adjustments'
import Detail from './views/Detail'
import Summary from './views/Summary'
import Invoices from './views/Invoices'
import Credits from './views/Credits'
import InvoicesList from './views/InvoicesList'
import CreditsList from './views/CreditsList'

export default [{
  path: '/payments/list',
  name: 'payments.list',
  component: List
}, {
  path: '/payments',
  name: 'payments',
  component: Index
}, {
  path: '/payment/:id',
  component: Detail,
  children: [{
    path: 'payment.detail'
  }, {
    path: 'invoices',
    name: 'payment.detail.invoices',
    component: Invoices
  }, {
    path: 'credits',
    name: 'payment.detail.credits',
    component: Credits
  }, {
    path: 'adjustments',
    name: 'payment.detail.adjustments',
    component: Adjustments
  }, {
    path: 'invoices/list',
    name: 'payment.detail.invoices.list',
    component: InvoicesList
  }, {
    path: 'credits/list',
    name: 'payment.detail.credits.list',
    component: CreditsList
  }, {
    path: '',
    name: 'payment.detail',
    component: Summary
  }]
}]
