<template>
<div>
  <div class="pa-md">
    <!-- Account is loaded -->
    <div v-if="!loading && account !== null">
      <div class="columns is-centered">
        <div class="column is-one-third">
          <h1 class="has-text-centered is-size-4">Import from Shopify</h1>
        </div>
      </div>
      <div class="columns is-centered">
        <div class="column is-half">
          <div class="box">
            <transition name="fade" mode="out-in">
              <component
                :is="step"
                :account="account"
                :settings="settings"
                :job="job"
                @job:queued="trackJob"
                @change:setting="changeSetting"
                @navigate="navigate"
              ></component>
            </transition>
          </div>
        </div>
      </div>
    </div>
    <!-- Loading Skeleton -->
    <div v-else>
      <div class="mb-md">
        <div class="columns is-centered">
          <div class="column is-one-quarter">
            <b-skeleton />
          </div>
        </div>
      </div>
      <div class="columns is-centered">
        <div class="column is-half">
          <div class="box">
            <loading message="Loading..." />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Intro from '../components/ImportShopify/Intro'
import ScheduleImport from '../components/ImportShopify/ScheduleImport'
import Status from '../components/ImportShopify/Status'

export default {
  components: {
    Intro,
    ScheduleImport,
    Status
  },
  computed: {
    ...mapGetters(['app', 'context']),
    loading () {
      return (this.loadingPlatformAccounts)
    }
  },
  data () {
    return {
      loadingPlatformAccounts: true,
      account: null,
      step: 'Intro',
      settings: {},
      feed: null,
      job: null
    }
  },
  methods: {
    loadPlatformAccount (quiet = false) {
      if (!quiet) this.loadingPlatformAccounts = true
      const endpoint = this.context.route + '/platform-accounts/?platform=shopify'
      this.$http.get(endpoint).then(response => {
        if (response.data.count > 0) {
          this.account = response.data.results[0]
        }
        this.loadingPlatformAccounts = false
      })
    },
    trackJob (job) {
      this.job = {
        id: job.id
      }
    },
    changeSetting (setting) {
      this.$set(this.settings, setting.key, setting.value)
    },
    navigate (step) {
      this.step = step
    }
  },
  mounted () {
    this.loadPlatformAccount()
    if (this.$route.query.job_id) {
      this.job = {
        id: this.$route.query.job_id
      }
      this.step = 'Status'
    }
  }
}
</script>
