<template>
  <form @submit.prevent="save">
    <div class="border pa-md rounded-lg">
      <!-- Inputs -->
      <div class="columns is-mobile is-multiline is-vcentered">
        <!-- Adjustment Type -->
        <div class="column is-half">
          <b-field label="Adjustment Type">
            <b-select
              v-model="type"
              expanded >
              <optgroup label="Charges">
                <option v-for="charge in charges" :key="charge.code" :value="charge">{{ charge.label }}</option>
              </optgroup>
              <optgroup label="Allowance">
                <option v-for="allowance in allowances" :key="allowance.code" :value="allowance">{{ allowance.label }}</option>
              </optgroup>
            </b-select>
          </b-field>
        </div>
        <!-- Calculation -->
        <div class="column is-one-quarter">
          <b-field label="Calculation">
            <b-select
              v-model="calculation"
              expanded>
              <option value="flat">Flat</option>
              <option value="percent">Percent</option>
            </b-select>
          </b-field>
        </div>
        <!-- Amount -->
        <div class="column is-one-quarter">
          <b-field label="Amount">
            <b-numberinput
              expanded
              v-model="amount"
              :controls="false"
              step="0.01"
              :min="minAmount"
              :max="maxAmount"
            />
          </b-field>
        </div>
        <!-- Submit -->
        <div class="column">
          <b-button
            native-type="submit"
            type="is-primary"
            :disabled="!ready"
            expanded>
            Add
          </b-button>
        </div>
      </div>

      <!-- Explanation -->
      <div class="mb-sm">
        <p v-if="type !== null && type.type === 'charge'" class=" has-text-grey is-size-7">
          Adding a <b class="is-italic">charge</b> will
          <b class="is-italic">increase</b> the amount
          your merchant partner owes for this purchase order
        </p>
        <p v-else-if="type !== null && type.type === 'allowance'" class=" has-text-grey is-size-7">
          Adding an <b class="is-italic">allowance</b>
          will <b class="is-italic">descrease</b> the amount
          your merchant partner owes for this purchase order
        </p>
        <p v-else class="has-text-grey is-size-7">
          Select an adjustment type above.
        </p>
      </div>

      <!-- Optional Memo -->
      <div v-if="type !== null && (type.code === 'misc_charge' || type.code === 'misc_allowance')">
        <b-field
          label="Memo (optional)"
          expanded>
            <b-input
              autocomplete="off"
              type="text"
              v-model="description"
            ></b-input>
        </b-field>
      </div>

    </div>
  </form>
</template>

<script>
export default {
  data () {
    return {
      type: null,
      calculation: 'flat',
      amount: null,
      description: null,
      charges: [{
        type: 'charge',
        code: 'service_fee',
        label: 'Service Fee'
      }, {
        type: 'charge',
        code: 'shipping_handling_charge',
        label: 'Shipping Expense'
      }, {
        type: 'charge',
        code: 'misc_charge',
        label: 'Other Charge'
      }],
      allowances: [{
        type: 'allowance',
        code: 'returns_allowance',
        label: 'Returns Allowance'
      }, {
        type: 'allowance',
        code: 'promotional_allowance',
        label: 'Promotion Allowance'
      }, {
        type: 'allowance',
        code: 'misc_allowance',
        label: 'Other Allowance'
      }]
    }
  },
  computed: {
    ready () {
      return this.amount && this.amount > 0 && this.type && this.calculation
    },
    minAmount () {
      return 0
    },
    maxAmount () {
      return (this.calculation === 'percent') ? 100 : null
    },
    json () {
      return {
        source: 'Manual',
        type: this.type.type,
        calculation: this.calculation,
        code: {
          value: this.type.code,
          label: this.type.label
        },
        description: (this.description) ? this.description : this.type.label,
        amount: this.amount
      }
    }
  },
  methods: {
    save () {
      this.$emit('adjustment:create', this.json)
      this.$buefy.toast.open({ type: 'is-success', message: 'Adjustment added' })
      this.reset()
    },
    reset () {
      this.type = null
      this.calculation = 'flat'
      this.amount = null
    }
  },
  props: ['subtotal']
}
</script>
