<template>
  <div style="position: relative;">
    <b-field label="Find a variant">
      <b-autocomplete
        v-model="name"
        :data="data"
        :open-on-focus="true"
        placeholder="Search for variants"
        icon="magnify"
        field="name"
        :loading="loading"
        :disabled="loading"
        @typing="search"
        @select="option => onSelection(option)"
        expanded>
        <template slot-scope="props">
          <div class="media" :class="(selected && selected.id === props.option.id) ? 'has-text-success' : ''">
            <div class="media-content">
              {{ $utils.truncate(props.option.name, { length: 80 }) }}
              <br>
              <small>
                Id: <b>{{ props.option.id }}</b>
              </small>
            </div>
          </div>
        </template>
        <template #empty>
          No variants found.
        </template>
      </b-autocomplete>
    </b-field>
    <span v-if="clearable" @click="clearSelection" style="position: absolute; top: 50%; right: 5%; x-index: 3;">
      <b-icon icon="close" custom-class="pt-sm"/>
    </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import debounce from 'lodash/debounce'
export default {
  components: {},
  computed: {
    ...mapGetters(['app', 'api', 'context']),
    endpoint () {
      return this.context.route + '/variants/'
    },
    query () {
      return {
        order_by: '-id',
        fields: 'id,name',
        search: this.name,
        brand_id: this.brandId
      }
    }
  },
  data: () => {
    return {
      results: [],
      data: [],
      clearable: false,
      selected: null,
      loading: false,
      brandId: null,
      name: ''
    }
  },
  methods: {
    search: debounce(function (name) {
      this.getAllVariants()
    }, 500),
    getAllVariants () {
      this.loading = true
      this.$http.get(this.endpoint, { params: this.query }).then(response => {
        this.results = response.data.results
        this.data = response.data.results
        this.loading = false
      })
    },
    setFilteredData (variantName) {
      this.data = this.results.filter(({ name }) =>
        name.toLowerCase().indexOf(variantName.toLowerCase()) >= 0)
      this.clearable = true
    },
    clearSelection () {
      this.name = ''
      this.clearable = false
      this.data = this.results
      this.selected = null
    },
    onSelection (option) {
      if (option) {
        this.selected = option
        this.name = option.name
        this.setFilteredData(option.name)
        this.$emit('change')
        this.$emit('input', option)
      }
    }
  },
  beforeMount () {
    this.getAllVariants()
  },
  watch: {
    isEdit: {
      deep: true,
      immediate: true,
      handler (value) {
        if (value) {
          this.selected = this.selectedVariant.variant
          this.name = this.selectedVariant.variant.name
          this.setFilteredData(this.selectedVariant.variant.name)
        }
      }
    },
    connectionId: {
      deep: true,
      immediate: true,
      handler (value) {
        this.brandId = value
      }
    }
  },
  props: ['selectedVariant', 'isEdit', 'connectionId']
}
</script>
