<template>
  <div>
    <!-- Content -->
    <div class="">
      <p class="has-text-weight-bold mb-md">Activate Fulfillment Webhook</p>
      <p class="mb-lg">
        When fulfillments are added to your orders from {{ connection.retailer.name }},
        you have the option to automatically return the tracking numbers.
      </p>
      <p class="mb-lg">
        Select "Fulfillment Created" if your Shopify fulfillments
        are created with tracking numbers (which is normally the case if
        you print labels from Shopify). If you use an external app or API that creates fulfillments
        and later updates them with tracking numbers, you should choose "Fulfillment Updated".
      </p>
      <div v-if="!loading && webhook === null" class="mb-lg">
        <p class="has-text-weight-bold mb-md">Select Fulfillment Webhook</p>
        <div class="field">
          <b-select v-model="selectedOption" size="is-medium" expanded placeholder="Select webhook..." @input="isDirty = true">
            <option value="fulfillments/create">Fulfillment Created</option>
            <option value="fulfillments/update">Fulfillment Updated</option>
          </b-select>
        </div>
      </div>
      <div v-else-if="!loading && webhook !== null" class="mb-lg">
        <p class="has-text-weight-bold mb-md">Topic: {{ topicName }}</p>
        <div class="columns is-mobile is-vcentered">
          <div class="column is-narrow">
            <div class="field">
              <b-switch
                v-model="sync"
                type="is-success"
                size="is-medium"
                true-value="Yes"
                false-value="No"
                :disabled="loading"
              >{{ sync }}</b-switch>
            </div>
          </div>
        </div>
        <p v-if="webhook !== null" class="has-text-grey-light">Note: this integration is currently enabled.</p>
      </div>
      <div v-else>Checking current status...</div>
    </div>
    <!-- Navigate -->
    <div class="py-lg">
      <div class="columns is-centered">
        <div class="column">
          <b-button
            type="is-light rounded-lg"
            size="is-medium"
            @click="goBack"
            expanded
            ><b-icon icon="arrow-left"></b-icon>
          </b-button>
        </div>
        <div class="column">
          <b-button
            type="is-primary rounded-lg"
            size="is-medium"
            expanded
            @click="goForward"
            :disabled="saving || !ready"
            :loading="saving"
          >Save &amp; Continue</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['app', 'context']),
    ready () {
      return (this.account || this.storeName)
    },
    endpoint () {
      return this.context.route + '/shopify/' + this.account.id + '/webhooks/'
    },
    json () {
      return {
        webhook_topic: this.webhook
      }
    }
  },
  data () {
    return {
      loading: true,
      webhook: null,
      saving: false,
      sync: 'No',
      topicName: null,
      selectedOption: null
    }
  },
  methods: {
    enable () {
      const endpoint = this.context.route + '/shopify/' + this.account.id + '/register-webhook/'
      const json = {
        webhook_topic: this.selectedOption
      }
      this.saving = true
      this.$http.put(endpoint, json).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Sync enabled!' })
        this.$emit('navigate', 'Cancellations')
      }).finally(() => {
        this.saving = false
      })
    },
    disable () {
      this.saving = true
      this.$http.delete(this.endpoint + this.webhook.id + '/').then(() => {
        this.webhook = null
        this.$buefy.toast.open({ type: 'is-success', message: 'Sync disabled!' })
        this.$emit('navigate', 'Cancellations')
      }).finally(() => {
        this.saving = false
      })
    },
    goBack () {
      this.$emit('navigate', 'Orders')
    },
    goForward () {
      if (this.webhook === null && this.selectedOption !== null) {
        // enable the selection from options
        this.enable()
      } else if (this.sync === 'No' && this.webhook !== null) {
        // disable the selected option
        this.disable()
      } else {
        // don't do anything
        this.$buefy.toast.open({ type: 'is-success', message: 'Settings saved!' })
        this.$emit('navigate', 'Cancellations')
      }
    },
    loadWebhooks () {
      this.loading = true
      const endpoint = this.context.route + '/shopify/' + this.account.id + '/webhooks/'
      this.$http.get(endpoint).then(response => {
        // check for update webhook first...
        const update = this.$utils.find(response.data.results, ['topic', 'fulfillments/update'])
        if (update) {
          this.webhook = update
          this.sync = 'Yes'
          this.topicName = 'Fulfillment Updated'
          return
        }
        // next, check for the create webhook
        const create = this.$utils.find(response.data.results, ['topic', 'fulfillments/create'])
        if (create) {
          this.webhook = create
          this.sync = 'Yes'
          this.topicName = 'Fulfillment Created'
        }
      }).finally(() => {
        this.loading = false
      })
    }
  },
  mounted () {
    this.loadWebhooks()
  },
  props: {
    connection: {
      type: Object,
      required: true
    },
    task: {
      type: Object,
      required: true
    },
    account: {
      type: Object
    }
  }
}
</script>
