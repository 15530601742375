<template>
  <div>
    <div class="section">
      <div class="container">
        <!-- Page Header -->
        <div class="columns is-vcentered">
          <div class="column is-one-third">
            <breadcrumbs :history="breadcrumbs.history" :current="breadcrumbs.current" />
          </div>
          <div class="column is-one-third">
            <h1 class="has-text-centered is-size-4">Create Template</h1>
          </div>
        </div>
        <!-- Content -->
        <!-- Step 1: Select File -->
        <div class="has-background-white pa-lg mb-lg rounded-lg">
          <div class="mb-lg">
            <h1 class="has-text-primary is-size-4">Create Template</h1>
            <p class="has-text-grey">To create a custom import or export template, please complete the steps below.</p>
          </div>
          <!-- Error Messaging -->
          <div v-if="'non_field_errors' in errors" class="mb-lg">
            <b-message type="is-danger">{{ errors.non_field_errors[0] }}</b-message>
          </div>
          <!-- Template Name -->
          <div class="mb-lg">
            <div class="columns">
              <div class="column is-half-desktop">
                <p class="has-text-weight-bold mb-sm"></p>
                <b-field label="Template Name" :type="('name' in errors) ? 'is-danger' : ''"
                  :message="('name' in errors) ? errors['name'][0] : ''" expanded>
                  <b-input type="text" size="is-medium" v-model="templateName"></b-input>
                </b-field>
              </div>
            </div>
          </div>
          <!-- Data Type -->
          <div class="mb-lg">
            <p class="has-text-weight-bold mb-sm">Data Type</p>
            <div class="field">
              <b-radio v-model="dataType" native-value="products">Products</b-radio>
              <p v-if="'data_type' in errors" class="is-size-7 has-text-danger">{{ errors['name'][0] }}</p>
            </div>
          </div>
          <!-- Direction -->
          <div class="mb-lg">
            <p class="has-text-weight-bold mb-sm">Direction</p>
            <div v-if="!isFabricTemplate" class="field">
              <b-radio v-model="direction" native-value="import">Import to {{ app.productName }}</b-radio>
            </div>
            <div class="field">
              <b-radio v-model="direction" native-value="export">Export from {{ app.productName }}</b-radio>
            </div>
          </div>
          <!-- File -->
          <div class="mb-lg">
            <div class="mb-md">
              <p class="has-text-weight-bold">Sample File</p>
              <p>Please drag a sample data file to the box below so that we can check the column headers</p>
            </div>
            <div class="columns">
              <div class="column is-half-desktop">
                <CoreUpload @file:uploaded="updateTempfile" @file:reset="resetHeaders" :askToConfirm='true' />
                <!-- Checking Headers Projects -->
                <div v-if="checkingHeaders" class="mt-lg">
                  <b-progress>Analyzing...</b-progress>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Step 2: Attribute List -->
        <div v-if="headers.length > 0" class="has-background-white pa-lg mb-lg rounded-lg">
          <div class="mb-lg">
            <h1 class="has-text-primary is-size-4">Column Mappings</h1>
            <p class="has-text-grey">For each column in your file, please map it to the closest available attribute.</p>
          </div>
          <div class="mb-lg">
            <table class="table is-vcentered is-fullwidth is-narrow">
              <thead>
                <tr>
                  <th>Column Header</th>
                  <th>Map To</th>
                  <th>Target</th>
                  <th>Priority</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <Mapping v-for="(header, index) in headers" :key="index" :header="header" :index="index"
                  :direction="direction" :attributes="attributes" @announce="setMappings" />
              </tbody>
            </table>
          </div>
          <!-- Save -->
          <div>
            <b-button type="is-primary" size="is-medium" :disabled="saving || !ready" :loading="saving"
              @click="saveTemplate">Save Template</b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CoreUpload from '@/components/CoreUpload'
import Mapping from '../components/CreateTemplate/Mapping'

export default {
  components: {
    CoreUpload,
    Mapping
  },
  computed: {
    ...mapGetters(['app', 'api', 'context']),
    breadcrumbs () {
      return {
        history: [
          { label: 'Settings', name: 'settings' },
          { label: 'Templates', name: 'templates' }
        ],
        current: 'Create'
      }
    },
    isFabricTemplate () {
      return this.$route.name === 'fabric.template.create' && this.$route.params.id
    },
    endpoint () {
      let endpoint = this.context.route + '/templates/'
      if (this.isFabricTemplate) {
        endpoint = `${this.context.route}/fabric/${this.$route.params.id}/templates/`
      }
      return endpoint
    },
    body () {
      const json = {
        name: this.templateName,
        direction: this.direction,
        data_type: this.dataType,
        mappings: []
      }
      for (const mapping in this.mappings) {
        const jsonMapping = {
          attribute: {
            id: this.mappings[mapping].id
          },
          title: this.mappings[mapping].title,
          priority: this.mappings[mapping].priority,
          target: this.mappings[mapping].target
        }

        if (this.direction === 'import') {
          jsonMapping.is_required = (this.mappings[mapping].isRequired) ? 1 : 0
        }
        json.mappings.push(jsonMapping)
      }
      return json
    },
    fabricTemplateBody () {
      const json = {
        name: this.templateName,
        direction: this.direction,
        data_type: this.dataType,
        template_mappings: []
      }
      for (const mapping in this.mappings) {
        const jsonMapping = {
          attribute: {
            code: this.mappings[mapping].code
          },
          title: this.mappings[mapping].title
        }

        if (this.direction === 'import') {
          jsonMapping.is_required = (this.mappings[mapping].isRequired) ? 1 : 0
        }
        json.template_mappings.push(jsonMapping)
      }
      return json
    },
    ready () {
      return (this.templateName && this.direction && this.dataType)
    }
  },
  data () {
    return {
      tempfile: null,
      attributes: [],
      templateName: null,
      dataType: 'products',
      direction: null,
      headers: [],
      checkingHeaders: false,
      mappings: {},
      saving: false,
      errors: {}
    }
  },
  methods: {
    saveTemplate () {
      this.saving = true
      const requestBody = this.isFabricTemplate ? this.fabricTemplateBody : this.body
      this.$http.post(this.endpoint, requestBody).then(response => {
        this.errors = {}
        this.$buefy.toast.open({ type: 'is-success', message: 'Template created!' })
        if (this.isFabricTemplate) {
          this.$router.push({ name: 'fabric.templates' })
        } else {
          this.$router.push({ name: 'templates' })
        }
      }).catch(error => {
        this.$buefy.toast.open({ type: 'is-danger', message: 'Error saving template!' })
        this.errors = error.response.data
      }).finally(() => {
        this.saving = false
      })
    },
    loadAttributes () {
      this.loadingAttributes = true
      const endpoint = this.api.baseRoute + '/attributes/'
      const query = {
        params: { common: 1, pagination: 0 }
      }
      this.$http.get(endpoint, query).then(response => {
        this.attributes = response.data.results
        this.loadingAttributes = false
      })
    },
    analyzeHeaders () {
      this.checkingHeaders = true
      const endpoint = this.context.route + '/templates/analyze-headers/'
      const json = {
        file: {
          tempfile: {
            id: this.tempfile.id
          }
        }
      }
      this.$http.post(endpoint, json).then(response => {
        this.headers = response.data[0].row_array
      }).finally(() => {
        this.checkingHeaders = false
      })
    },
    updateTempfile (file, response) {
      this.tempfile = response
      this.analyzeHeaders()
    },
    resetHeaders () {
      this.tempfile = null
      this.headers = []
    },
    setMappings (mapping) {
      this.$set(this.mappings, this.$utils.snakeCase(mapping.header), {
        id: (mapping.attribute) ? mapping.attribute.id : null,
        code: (mapping.attribute) ? mapping.attribute.code : null,
        title: mapping.header,
        target: mapping.target,
        priority: mapping.priority,
        isRequired: mapping.isRequired
      })
    }
  },
  mounted () {
    this.loadAttributes()
  }
}
</script>
