<template>
  <div v-if="!loading && feedDetail !== null" class="mb-lg">
    <div>
      Ruleset Failure Report:
      <DownloadFileAs label="Download" :cloudFileName="getJobMetadataFromKey(feedDetail, 'ruleset_failure_report', true)"
          :originalFileName="`${feedDetail.id}-error-items`" />
    </div>
    <div>
        Ruleset Error Report:
        <DownloadFileAs label="Download"
            :cloudFileName="getJobMetadataFromKey(feedDetail, 'ruleset_error_report', true)"
            :originalFileName="`${feedDetail.id}-failed-error-details`" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['context'])
  },
  data () {
    return {
      loading: true,
      feedDetail: null
    }
  },
  methods: {
    loadCompatibilityReport () {
      this.loading = true
      const endpoint = this.context.route + '/proposals/' + this.proposal.id + '/compatibility-rulesets-failure-reports/'
      this.$http.get(endpoint).then(response => {
        if (response.data.id) {
          const existingQuery = this.$route.query
          this.$router.replace({ name: 'catalog.submit.new', query: { ...existingQuery, compatibility_id: response.data.id } })
          this.loadJobDetails()
        }
      }).finally(() => {
        this.loading = false
      })
    },
    loadJobDetails () {
      const id = this.$route.query.compatibility_id
      const jobEndpoint = this.context.route + '/jobs/' + id + '/'
      this.$http.get(jobEndpoint).then(response => {
        this.feedDetail = response.data
      }).finally(() => {
        this.loading = false
      })
    },
    getJobMetadataFromKey (row, key, onlyValue) {
      let job = null
      if (row && row.metadata.length) {
        job = row.metadata.find(el => el.name === key)
      }
      if (job && job.value && onlyValue) {
        return job.value
      } else {
        return job !== null && job !== undefined
      }
    }
  },
  mounted () {
    const compatibilityId = this.$route.query.compatibility_id
    // The compatibility-rulesets-failure-reports is supposed to create a new job every time we hit it.
    // The idea is to call it once and then fetch the job id from its response and keep querying with that job id
    if (compatibilityId) {
      this.loadJobDetails()
    } else {
      this.loadCompatibilityReport()
    }
  },
  props: ['proposal', 'ruleset']
}
</script>
