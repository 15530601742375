<template>
  <form @submit.prevent="updateInventory">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Update Item Inventory</p>
      </header>
      <section class="modal-card-body">
        <div v-if="!loading && item !== null">
          <!-- Variant Details -->
          <div >
            <p class="mb-sm"><strong>Item Details</strong></p>
            <table class="table is-fullwidth is-striped is-size-7 is-narrow border mb-xl">
              <tbody>
                <tr>
                  <th>ID</th>
                  <td class="has-text-right">{{ item.id }}</td>
                </tr>
                <tr>
                  <th>Supplier Item SKU</th>
                  <td class="has-text-right">{{ item.brand_identifier }}</td>
                </tr>
                <tr>
                  <th>Supplier Item Name</th>
                  <td class="has-text-right">{{ item.name }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- Discontinued -->
          <div class="mb-xl">
            <b-field label="Status">
              <b-select v-model="inventory.discontinued" expanded>
                <option :value="false">Available</option>
                <option :value="true">Discontinued</option>
              </b-select>
            </b-field>
          </div>
          <!-- Estimated Return -->
          <!-- <b-field
            label="Estimated Return Date"
            :type="('estimated_availability_date' in errors) ? 'is-danger' : ''"
            :message="('estimated_availability_date' in errors) ? errors[''][0] : ''"
            expanded>
            <b-input
              type="date"
              v-model="estimatedAvailabilityDate"/>
            </b-input>
          </b-field> -->
          <!-- Location Inputs -->
          <div class="columns is-mobile">
            <div class="column">
              <p><strong>Location</strong></p>
            </div>
            <div class="column is-one-third-mobile is-half-tablet">
              <p><strong>On Hand</strong></p>
            </div>
          </div>
          <div v-for="location in inventory.locations" :key="location.id" class="mb-xxs">
            <UpdateInventoryLocation :location="location" @inventory:updated="updateLocationInventory"/>
          </div>
          <div class="columns is-mobile">
            <div class="column">
              <p><strong>Total On Hand</strong></p>
            </div>
            <div class="column is-one-third-mobile is-half-tablet">
              <p class=""><strong>{{ totalInventory }}</strong></p>
            </div>
          </div>
        </div>
        <div v-else>
          <loading message="Loading current inventory details..." />
        </div>
      </section>
      <footer class="modal-card-foot space-between">
        <b-button @click="$parent.close()">Close</b-button>
        <b-button
          native-type="submit"
          type="is-primary"
          :disabled="saving || loading"
          :loading="saving"
        >Update Inventory</b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import UpdateInventoryLocation from './UpdateInventoryLocation'
export default {
  components: {
    UpdateInventoryLocation
  },
  data () {
    return {
      loading: true,
      inventory: null,
      locations: {},
      saving: false,
      errors: {}
    }
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/inventory/' + this.item.id + '/'
    },
    totalInventory () {
      let sum = 0
      Object.keys(this.locations).forEach(locationId => {
        sum += this.locations[locationId]
      })
      return sum
    },
    json () {
      const attributes = [{
        attribute: {
          code: 'discontinued'
        },
        value: (this.inventory.discontinued) ? 1 : 0
      }]
      // inventory
      Object.keys(this.locations).forEach(locationId => {
        attributes.push({
          attribute: {
            code: 'inventory_warehouse'
          },
          location: {
            id: locationId
          },
          value: this.locations[locationId]
        })
      })
      return {
        attributes: attributes
      }
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint).then(response => {
        this.inventory = response.data
      }).finally(() => {
        this.loading = false
      })
    },
    updateLocationInventory (value) {
      this.$set(this.locations, value.locationId, value.onHand)
    },
    updateInventory () {
      const url = this.context.route + '/variants/' + this.item.id + '/'
      this.saving = true
      this.$http.patch(url, this.json).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Inventory updated!' })
        this.$emit('inventory:updated')
        this.$parent.close()
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    this.load()
  },
  props: ['item']
}
</script>
