<template>
  <div>
    <component :is="view" :profile="profile" @update:profile="updateProfile"></component>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Merchants from '../components/Onboarding/Merchants'
import Suppliers from '../components/Onboarding/Suppliers'

export default {
  components: {
    Merchants,
    Suppliers
  },
  computed: {
    ...mapGetters(['context']),
    view () {
      return (this.context.type === 'vendor') ? 'Suppliers' : 'Merchants'
    }
  },
  methods: {
    updateProfile () {
      this.$emit('update:profile')
    }
  },
  props: ['profile']
}
</script>
