<template>
  <div>
    <div class="section">
      <div class="container">
        <!-- Page Header -->
        <div class="columns is-vcentered">
          <div class="column is-one-third">
            <breadcrumbs :history="[{ label: 'Settings', name: 'settings'}, { label: 'Templates', name: 'templates'}]" current="BigCommerce" :hideBack="true" />
          </div>
          <div class="column is-one-third">
            <h1 class="has-text-centered is-size-4">BigCommerce Templates</h1>
          </div>
        </div>
        <!-- Table -->
        <div>
          <b-table
            :data="results"
            :checked-rows.sync="selected"
            :mobile-cards="false"
            :loading="loading"
            hoverable
            class="is-size-7">
            <template>
              <!-- ID -->
              <b-table-column
                label="ID"
                width="64"
                v-slot="props">
                <p class="has-text-grey-light">{{ props.row.id }}</p>
              </b-table-column>
              <!-- Template -->
              <b-table-column
                label="Template Name"
                cell-class="nowrap"
                v-slot="props">
                <div>
                  <router-link class="has-text-weight-bold has-text-info" :to="{ name: 'bigcommerce.template', params: { id: bigcommerceId, templateId: props.row.id }}">
                    {{ props.row.name }}
                  </router-link>
                </div>
              </b-table-column>
              <!-- Datatype -->
              <b-table-column
                label="Data Type"
                width="128"
                centered
                v-slot="props">
                <p>{{ $utils.upperFirst(props.row.data_type) }}</p>
              </b-table-column>
              <!-- Direction -->
              <b-table-column
                label="Direction"
                width="128"
                centered
                v-slot="props">
                <p>{{ $utils.upperFirst(props.row.direction) }}</p>
              </b-table-column>
              <!-- Column Mappings -->
              <b-table-column
                label="Mappings"
                width="128"
                centered
                v-slot="props">
                <p>{{ props.row.template_mappings.length }}</p>
              </b-table-column>
            </template>
            <template #empty>
              <div class="py-xxl has-text-grey has-text-centered">
                {{ (loading) ? "Loading data..." : 'No records found!' }}
              </div>
            </template>
          </b-table>
        </div>
        <div class="mt-md">
          <b-pagination
            :total="total"
            :current.sync="currentPage"
            :rounded="true"
            :per-page="perPage"
            @change="paginate">
          </b-pagination>
        </div>
      </div>
    </div>
  </div>
  </template>

<script>
import { mapGetters } from 'vuex'
import Filterable from '@/mixins/Filterable'

export default {
  components: {
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + `/bigcommerce/${this.$route.params.id}/templates/`
    }
  },
  data () {
    return {
      key: 'bigcommerce-templates',
      bigcommerceId: '',
      alwaysAttach: {
      },
      selected: []
    }
  },
  methods: {
  },
  mixins: [Filterable],
  mounted () {
    this.bigcommerceId = this.$route.params.id
    this.load()
  }
}
</script>
