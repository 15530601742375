import List from './views/List'
export default [{
  path: '/suppliers',
  name: 'suppliers',
  component: List,
  meta: {
    skipContext: true,
    hideNavigation: true
  }
}]
