<template>
  <form @submit.prevent="save" autocomplete="off">
    <!-- Intro -->
    <div class="has-text-centered">
      <p class="is-size-4 mb-sm has-text-black">User Setup</p>
      <p class="has-text-centered pb-lg">Next, let's confirm a few settings about you.</p>
    </div>
    <!-- Fields -->
    <div class="pb-lg mb-lg">
      <div class="columns is-centered">
        <div class="column is-two-thirds">
          <b-field
            label="Email"
            :type="('email' in errors) ? 'is-danger' : ''"
            :message="('email' in errors) ? errors['email'][0] : ''"
            expanded>
            <b-input
              size="is-medium"
              autocomplete="off"
              type="text"
              native-type="email"
              v-model="email"
            ></b-input>
          </b-field>
          <div class="columns">
            <div class="column">
              <b-field
                label="First Name"
                :type="('first_name' in errors) ? 'is-danger' : ''"
                :message="('first_name' in errors) ? errors['first_name'][0] : ''"
                expanded>
                <b-input
                  size="is-medium"
                  autocomplete="off"
                  type="text"
                  v-model="firstName"
                ></b-input>
              </b-field>
            </div>
            <div class="column">
              <b-field
                label="Last Name"
                :type="('last_name' in errors) ? 'is-danger' : ''"
                :message="('last_name' in errors) ? errors['last_name'][0] : ''"
                expanded>
                <b-input
                  size="is-medium"
                  autocomplete="off"
                  type="text"
                  v-model="lastName"
                ></b-input>
              </b-field>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Buttons -->
    <div class="mb-xl">
      <div class="columns is-centered">
        <div class="column is-three-quarters-tablet is-two-thirds-widescreen">
          <div class="columns">
            <div class="column">
              <b-button type="is-light rounded-lg" size="is-large" @click="back" expanded>
                <b-icon icon="arrow-left"></b-icon>
              </b-button>
            </div>
            <div class="column">
              <b-button
                native-type="submit"
                type="is-primary rounded-lg"
                size="is-large"
                expanded
                :loading="saving"
                :disabled="!validated || saving"
              >Save & Continue</b-button>
            </div>
          </div>
        </div>
      </div>
      <p v-if="'detail' in errors" class="has-text-danger">{{ error.detail }}</p>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  data () {
    return {
      saving: false,
      errors: {},
      email: null,
      firstName: null,
      lastName: null
    }
  },
  computed: {
    ...mapGetters(['api', 'auth', 'context']),
    endpoint () {
      return this.api.baseUrl + '/' + this.api.version + '/users/' + this.auth.user.id + '/'
    },
    json () {
      const json = {
        first_name: this.firstName,
        last_name: this.lastName
      }
      if (this.email !== this.auth.user.email) {
        json.email = this.email
      }
      return json
    },
    validated () {
      return true
    }
  },
  methods: {
    back () {
      this.$emit('change:step', 'partner')
    },
    save () {
      this.saving = true
      this.$http.patch(this.endpoint, this.json).then(response => {
        this.$store.dispatch('updateUserInfo', {
          firstName: response.data.first_name,
          lastName: response.data.last_name,
          email: response.data.email
        })
        this.$emit('change:step', 'billing')
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    this.email = this.auth.user.email
    this.firstName = this.auth.user.firstName
    this.lastName = this.auth.user.lastName
  },
  props: ['shop']
}
</script>
