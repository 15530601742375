<template>
  <div>
    <div class="columns is-vcentered is-mobile">
      <div class="column is-narrow">
        <div class="pa-sm">
          <b-icon v-if="task.completed_at" icon="check" class="has-text-success" />
          <b-icon v-else icon="check" class="has-text-white-ter" />
        </div>
      </div>
      <div class="column">
        <p class="has-text-weight-bold">{{ task.onboarding_task.name  }}</p>
        <p class="has-text-grey-light is-size-7">{{ task.onboarding_task.description }}</p>
      </div>
      <div class="column is-narrow">
        <div class="py-sm">
          <!-- Task is Completed -->
          <div v-if="task.completed_at" class="has-text-right">
            <p class="has-text-weight-bold has-text-success">Done</p>
            <p class="is-size-7">
              {{ $dates.utc(task.completed_at).local().format('MMM D') }} &bull;
              <router-link :to="route" class="has-text-info">Review</router-link>
            </p>
          </div>
          <div v-else-if="Object.keys(task.info).length">
            <router-link :to="route" class="button is-primary rounded-lg">Continue</router-link>
          </div>
          <div v-else>
            <router-link :to="route" class="button is-primary rounded-lg">Start</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    route () {
      return {
        name: 'onboarding.tasks.' + this.task.onboarding_task.code,
        params: { task: this.task.id }
      }
    }
  },
  props: ['task']
}
</script>
