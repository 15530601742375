<template>
  <div class="has-background-white-ter py-sm px-md rounded-lg mt-xxs">
    <div class="columns is-mobile is-vcentered">
      <div class="column is-narrow">
        <b-icon icon="chevron-right" custom-class="pt-sm" />
      </div>
      <div class="column">
        {{ adjustment.code.label }}
        <span class="is-italic has-text-grey-light" v-if="adjustment.source === 'Automatic'">(Automatic)</span>
      </div>
      <div class="column is-narrow">
        <div class="has-text-right has-text-weight-bold">
          <p v-if="!calculating">
            <span v-if="price >= 0"><CurrencyLocale :value="price" :currency="currency"/></span>
            <span v-else class="has-text-danger"><CurrencyLocale :value="price" :currency="currency"/></span>
          </p>
          <p v-else class="has-text-grey-light is-italic">Calculating...</p>
        </div>
      </div>
      <div class="column is-narrow">
        <a v-if="adjustment.source !== 'Automatic'" href="#" @click.prevent="$emit('adjustment:remove')">
          <b-icon icon="close" custom-class="pt-sm" />
        </a>
        <b-icon v-else icon="close" custom-class="pt-sm has-text-grey-lighter" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  data () {
    return {
      calculating: true,
      price: null
    }
  },
  computed: {
    ...mapGetters(['context']),
    json () {
      const additional = {
        index: this.index,
        price: this.price
      }
      return {
        ...this.adjustment,
        ...additional
      }
    }
  },
  methods: {
    announce () {
      this.$emit('adjustment:updated', this.json)
    },
    calculate () {
      this.calculating = true
      // charges are positive, adjustments are negative...
      const amount = (this.adjustment.type === 'allowance')
        ? parseFloat(this.adjustment.amount) * -1
        : parseFloat(this.adjustment.amount)
      if (this.adjustment.calculation === 'flat') {
        this.price = amount
      }
      if (this.adjustment.calculation === 'percent') {
        this.price = parseFloat(this.subtotal) * amount / 100
      }
      this.announce()
      this.calculating = false
    }
  },
  mounted () {

  },
  props: ['adjustment', 'subtotal', 'index', 'currency'],
  watch: {
    subtotal: {
      immediate: true,
      handler (value) {
        this.calculate()
      }
    }
  }
}
</script>
