<template>
<div>
  <div class="section">
    <div class="container">
      <!-- Page Header -->
      <div class="mb-md">
        <div class="columns is-vcentered">
          <div class="column is-one-third">
            <breadcrumbs :history="breadcrumbs.history" :current="breadcrumbs.current" />
          </div>
          <div class="column is-one-third">
            <h1 class="has-text-centered is-size-4">Complete!</h1>
          </div>
        </div>
      </div>
      <!-- Task -->
      <div class="has-background-white pa-lg rounded-lg">
        <div class="mb-xl">
          <div v-if="!loading && connection !== null">
            <div class="columns">
              <div class="column is-two-thirds">
                <!-- Intro -->
                <div class="mb-lg">
                  <p class="has-text-weight-bold is-size-4 mb-lg">Onboarding Complete!</p>
                  <div class="has-text-grey mb-lg">
                    <div class="mb-md">
                      <p>Thank you for completing the inital setup tasks!</p>
                      <p>
                        {{ app.platform}} has notified <strong>{{ connection.retailer.name }}</strong>
                        you are ready for the next steps.
                      </p>
                    </div>
                    <div v-if="connection.status === 'onboarding'" class="mb-md">
                      <p class="has-text-weight-bold is-size-5 mb-sm">Next Steps</p>
                      <div class="content">
                        <ul>
                          <li>
                            Your connection will remain in <strong class="has-text-primary">onboarding</strong>
                            status until {{ connection.retailer.name }} activates the connection.
                          </li>
                          <li>
                            Your connection will be activated once you confirm with {{ connection.retailer.name }}
                            that your items and inventory are ready to go live and that you
                            are ready to process orders (e.g. After EDI setup is verified)
                          </li>
                          <li>You will not receive any orders until your connection is activated.</li>
                          <li>We encourage you to review your account settings and notifications</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Complete Task -->
            <div class="mb-lg">
              <div class="columns">
                <div class="column is-one-quarter">
                  <router-link
                    :to="{ name: 'home' }"
                    class="button is-primary is-medium"
                    expanded
                  >Return to Dashboard</router-link>
                </div>
              </div>
            </div>
          </div>
          <!-- Loading Connection -->
          <div v-else>
            <loading message="Loading connection..." />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['app', 'context']),
    breadcrumbs () {
      const id = this.$route.params.connection
      return {
        current: 'Complete',
        history: [
          { label: 'Onboarding', name: 'onboarding' },
          { label: 'Tasks', name: 'onboarding.tasks', params: { id: id } }
        ]
      }
    }
  },
  data () {
    return {
      loading: true,
      connection: null
    }
  },
  methods: {
    load () {
      this.loading = true
      const endpoint = this.context.route + '/connections/' + this.$route.params.connection + '/'
      this.$http.get(endpoint).then(response => {
        this.connection = response.data
        this.loading = false
      })
    }
  },
  mounted () {
    this.load()
  }
}
</script>
