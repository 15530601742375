<template>
  <div class="section">
    <div class="container">
      <!-- Component Window -->
      <div class="mb-3xl">
        <div v-if="!loading">
          <transition name="fade" mode="out-in">
            <router-view
              :connections="connections"
              :platformAccounts="accounts"
              :loading="loading"
            ></router-view>
          </transition>
        </div>
        <!-- Skeleton -->
        <div v-else>
          <!-- Page Header -->
          <div class="mb-sm">
            <div class="columns is-centered">
              <div class="column is-one-third">
                <h1 class="has-text-centered is-size-4">Products</h1>
              </div>
            </div>
          </div>
          <div class="mt-xl">
            <div class="columns is-centered">
              <div class="column is-half">
                <div class="columns is-centered">
                  <div class="column is-half">
                    <b-skeleton height="12"/>
                  </div>
                </div>
                <div v-for="index in 3" :key="index" class="mb-xxs">
                  <div class="has-background-white rounded-lg pa-md">
                    <b-skeleton height="18" />
                    <b-skeleton height="12" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['app', 'auth', 'context']),
    loading () {
      return (this.loadingConnections || this.loadingPlatformAccounts)
    }
  },
  data () {
    return {
      loadingConnections: false,
      loadingPlatformAccounts: true,
      connections: [],
      accounts: []
    }
  },
  methods: {
    loadConnections () {
      this.loadingConnections = true
      const endpoint = this.context.route + '/connections/'
      const query = {
        params: {
          status: 'onboarding,active',
          fields: 'id'
        }
      }
      this.$http.get(endpoint, query).then(response => {
        response.data.results.forEach(connection => {
          if (connection.retailer.is_onboarded) {
            this.connections.push(connection)
          }
        })
        this.loadingConnections = false
      })
    },
    loadPlatformAccounts () {
      this.loadingPlatformAccounts = true
      const endpoint = this.context.route + '/platform-accounts/'
      const query = {
        params: {
          platform: 'shopify'
        }
      }
      this.$http.get(endpoint, query).then(response => {
        this.accounts = response.data.results
        this.loadingPlatformAccounts = false
      })
    }
  },
  mounted () {
    // this.loadConnections()
    setTimeout(() => {
      this.loadPlatformAccounts()
    }, 500)
  }
}
</script>
