<template>
  <div>
    <PerformanceChart
      :response-data="responseData"
      :dimension="dimension"
      :metric="metric"
      :loading="isLoading">
    </PerformanceChart>
    <PerformanceTable
      :label="label"
      :report-data="responseData"
      :dimension="dimension"
      :loading="isLoading"
      :hide-connections="hideConnections">
    </PerformanceTable>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import PerformanceChart from '@/modules/Performance/views/PerformanceChart.vue'
import PerformanceTable from '@/modules/Performance/views/PerformanceTable.vue'
import debounce from 'lodash/debounce'
export default {
  components: { PerformanceChart, PerformanceTable },
  data () {
    return {
      responseData: {},
      isLoading: false
    }
  },
  props: {
    dimension: {
      type: String,
      default: 'date'
    },
    metric: {
      type: String,
      default: 'estimated_gmv'
    },
    startDate: {
      type: Date
    },
    endDate: {
      type: Date
    }
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return '/summary/orders/'
    },
    query () {
      const filters = {
        start_date: this.$dates(this.startDate).format('YYYY-MM-DD'),
        end_date: this.$dates(this.endDate).format('YYYY-MM-DD'),
        dimension: this.dimension,
        limit: this.dimension === 'date' ? this.$dates(this.endDate).diff(this.$dates(this.startDate), 'day') : 250
      }
      if (this.context.type === 'vendor') {
        filters.brands = this.context.partner.id
      } else if (this.context.type === 'retailer') {
        filters.retailers = this.context.partner.id
      }
      return filters
    },
    label () {
      switch (this.dimension) {
        case 'date':
          return 'Daily Performance'
        case 'brand':
          return 'Top Brands Performance'
        case 'retailer':
          return 'Top Retailers Performance'
        case 'variant':
          return 'Top Product Performance'
        default:
          return ''
      }
    },
    partnerType () {
      if (this.context.isSupplier()) {
        return 'brand'
      } else if (this.context.isMerchant()) {
        return 'retailer'
      }
      return ''
    },
    hideConnections () {
      return this.dimension !== 'date'
    }
  },
  methods: {
    load () {
      this.isLoading = true
      this.$http.get(this.endpoint, { params: this.query }).then(response => {
        this.responseData = response.data
        this.isLoading = false
      })
    }
  },
  watch: {
    startDate (newVal, oldVal) {
      if (newVal !== null) this.load()
    },
    endDate (newVal, oldVal) {
      if (newVal !== null) this.load()
    },
    dimension (newVal, oldVal) {
      if (newVal !== null && newVal !== oldVal) this.load()
    },
    metric (newVal, oldVal) {
      if (newVal !== null && newVal !== oldVal) this.load()
    }
  },
  mounted () {
    this.load()
  },
  created () {
    this.load = debounce(this.load, 500)
  }
}
</script>
