import Index from './views/Index'
// Business information
import BusinessInformation from './views/BusinessInformation'
import AccountInformation from './views/AccountInformation'
import BusinessAddress from './views/BusinessAddress'
import ContactPersons from './views/ContactPersons'
import BrandingLogo from './views/BrandingLogo'
import StoreCurrency from './views/StoreCurrency'
// Notification preferences
import Notifications from './views/Notifications'
import OnboardingNotifications from './views/OnboardingNotifications'
import TransactionNotifications from './views/TransactionNotifications'
import DigestNotifications from './views/DigestNotifications'
// Integration Preferences
import Integrations from './views/Integrations'
import IntegrationOptions from './views/IntegrationOptions'
import IntegrationConfiguration from './views/IntegrationConfiguration'
// Shipping Accounts
import ShippingAccounts from './views/ShippingAccounts'
// Catalog Management
import CatalogManagement from './views/CatalogManagement'
import CatalogTemplates from './views/CatalogTemplates'
import CatalogRulesets from './views/CatalogRulesets'
import CatalogProposals from './views/CatalogProposals'
// Supplier onboarding Defaults
import SupplierDefaults from './views/SupplierDefaults'
import CostMethod from './views/CostMethod'
import FulfillmentSla from './views/FulfillmentSla'
import PaymentTerms from './views/PaymentTerms'
import CatalogImportMethod from './views/CatalogImportMethod'
import Allowances from './views/Allowances'
import PackingSlipTemplate from './views/PackingSlipTemplate'

export default [{
  path: '/onboarding-tasks',
  name: 'retailer.onboarding',
  component: Index
},
{
  path: '/onboarding-tasks/:task/business-information',
  component: BusinessInformation,
  children: [{
    path: 'account',
    name: 'retailer.onboarding.business-information',
    component: AccountInformation
  }, {
    path: 'address',
    name: 'retailer.onboarding.business-address',
    component: BusinessAddress
  }, {
    path: 'contact',
    name: 'retailer.onboarding.contact-persons',
    component: ContactPersons
  }, {
    path: 'logo',
    name: 'retailer.onboarding.branding-logo',
    component: BrandingLogo
  }, {
    path: 'currency',
    name: 'retailer.onboarding.store-currency',
    component: StoreCurrency
  }]
},
{
  path: '/onboarding-tasks/:task/notifications',
  component: Notifications,
  children: [{
    path: 'onboarding',
    name: 'retailer.onboarding.notifications',
    component: OnboardingNotifications
  }, {
    path: 'transactions',
    name: 'retailer.onboarding.transactions',
    component: TransactionNotifications
  }, {
    path: 'digest',
    name: 'retailer.onboarding.digest',
    component: DigestNotifications
  }]
},
{
  path: '/onboarding-tasks/:task/integration',
  component: Integrations,
  children: [{
    path: 'product-price-info',
    name: 'retailer.onboarding.integration',
    component: IntegrationOptions
  }, {
    path: 'configure',
    name: 'retailer.onboarding.configure',
    component: IntegrationConfiguration
  }]
},
{
  path: '/onboarding-tasks/:task/shipping-accounts',
  name: 'retailer.onboarding.shipping-accounts',
  component: ShippingAccounts
},
{
  path: '/onboarding-tasks/:task/catalog-management',
  component: CatalogManagement,
  children: [{
    path: 'templates',
    name: 'retailer.onboarding.catalog-management',
    component: CatalogTemplates
  }, {
    path: 'rulesets',
    name: 'retailer.onboarding.rulesets',
    component: CatalogRulesets
  }, {
    path: 'proposal-department',
    name: 'retailer.onboarding.proposal-departments',
    component: CatalogProposals
  }]
},
{
  path: '/onboarding-tasks/:task/preferences',
  component: SupplierDefaults,
  children: [{
    path: 'cost-method',
    name: 'retailer.onboarding.supplier-onboarding-defaults',
    component: CostMethod
  }, {
    path: 'fulfillment',
    name: 'retailer.onboarding.fulfillment',
    component: FulfillmentSla
  }, {
    path: 'payment',
    name: 'retailer.onboarding.payment',
    component: PaymentTerms
  }, {
    path: 'import-method',
    name: 'retailer.onboarding.import-method',
    component: CatalogImportMethod
  }, {
    path: 'allowances',
    name: 'retailer.onboarding.allowances',
    component: Allowances
  }, {
    path: 'packing-slip',
    name: 'retailer.onboarding.packing-slip',
    component: PackingSlipTemplate
  }]
}
]
