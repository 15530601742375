<template>
  <div class="has-background-white px-md rounded-lg mb-xxs">
    <router-link :to="{ name: 'proposals.list', query: { preset: 'completed' } }">
      <div class="columns is-mobile is-vcentered is-marginless">
        <div class="column">
          <p class="has-text-weight-bold">Completed</p>
          <p class="has-text-grey-light is-size-7">Proposals that are ready to be published.</p>
        </div>
        <div class="column is-one-fifth">
          <div class="has-background-white-ter pa-xs rounded-lg has-text-weight-bold has-text-centered">
            <span v-if="!loading">{{ count }}</span>
            <span v-else><b-icon icon="loading" custom-class="mdi-spin" size="is-small"></b-icon></span>
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/proposals/'
    },
    query () {
      return {
        params: {
          proposal_type: 'new_product,cost_update',
          status: 'completed',
          is_acknowledged: 1,
          short: 1
        }
      }
    }
  },
  data () {
    return {
      loading: true,
      count: 0
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint, this.query).then(response => {
        this.count = response.data.count
        this.loading = false
      })
    }
  },
  mounted () {
    this.load()
  }
}
</script>
