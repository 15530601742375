<template>
  <div>
    <!-- Product Header -->
    <div class="mb-sm">
      <div class="columns is-mobile is-vcentered">
        <div class="column is-narrow">
          <b-image :src="line.variant.media[0].url | cloudinary(64, 64, 'pad')" ratio="1x1"></b-image>
        </div>
        <div class="column has-text-centered-mobile">
          <div>
            <p>{{ line.variant.name }}</p>
            <div class="is-size-7">
              <p>
                <span v-if="retailerSku !== null" class="has-text-grey">Retailer SKU</span> {{ retailerSku }} &bull;
                <span class="has-text-grey">Supplier SKU</span> {{ line.variant.brand_identifier }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Table -->
    <div class="has-background-white pa-xs rounded-lg">
      <table class="table is-fullwidth is-narrow is-size-7">
        <tbody>
          <tr>
            <th style="width:40%">Qty Ordered</th>
            <td style="width:10%">{{ line.quantity }}</td>
            <th style="width:20%">Line Status</th>
            <td style="width:30%" class="has-text-right">
              <StatusPresenter :line="line" />
            </td>
          </tr>
          <tr>
            <th>Qty Shipped</th>
            <td>{{ line.quantity_shipped }}</td>
            <th>
              <span v-if="line.backordered_until === null">Due Date</span>
              <span v-else>Est. Ship Date</span>
            </th>
            <td class="has-text-right">
              <span v-if="line.backordered_until === null">{{ $dates.utc(line.fulfill_by).format('MMM D, YYYY') }}</span>
              <span v-else>{{ $dates.utc(line.backordered_until).format('MMM D, YYYY') }}</span>
            </td>
          </tr>
          <tr>
            <th>Qty Canceled</th>
            <td>{{ line.quantity_canceled }}</td>
            <th>Cost (ea.)</th>
            <td class="has-text-right">{{ $numbers(line.net_price).formatCurrency({ mantissa: 2 }) }}</td>
          </tr>
          <tr>
            <th>Qty Returned</th>
            <td>{{ line.quantity_shipped }}</td>
            <th>Price (ea.)</th>
            <td class="has-text-right">{{ $numbers(line.price).formatCurrency({ mantissa: 2 }) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import StatusPresenter from '@/components/Presenters/OrderLine/StatusAlt'

export default {
  components: {
    StatusPresenter
  },
  computed: {
    ...mapGetters(['context']),
    retailerSku () {
      if (this.context.isSupplier()) return null
      if (this.line.variant.retailer_identifiers.length > 0) {
        return this.line.variant.retailer_identifiers[0].identifier
      }
      return null
    }
  },
  props: ['line']
}
</script>
