<template>
  <div>
    <!-- Content -->
    <div class="mb-xxl">
      <p class="has-text-weight-bold mb-md">Confirmation</p>
      <p class=" mb-md">
        You ShipStation account has been configured according to your preferences.
        You may revisit this configuration at any time by visiting your
        <router-link :to="{ name: 'settings.integrations.shipstation', params: { id: this.account.id } }" class="has-text-info">ShipStation settings.</router-link>
      </p>
      <div class="has-text-centered pt-xxl">
        <b-icon icon="check" custom-class="mdi-96px has-text-success" />
      </div>
    </div>
    <!-- Navigate -->
    <div class="py-lg">
      <div class="columns is-centered">
        <div class="column">
          <b-button
            type="is-light rounded-lg"
            size="is-medium"
            @click="goBack"
            expanded
            ><b-icon icon="arrow-left"></b-icon>
          </b-button>
        </div>
        <div class="column">
          <b-button
            type="is-primary rounded-lg"
            size="is-medium"
            expanded
            @click="goForward"
            :disabled="saving"
            :loading="saving"
          >Complete Task</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['app', 'context'])
  },
  data () {
    return {
      saving: false,
      errors: {},
      apiKey: null,
      apiSecret: null,
      storeId: null
    }
  },
  methods: {
    goBack () {
      this.$emit('navigate', 'Activate')
    },
    goForward () {
      this.saving = true
      const endpoint = this.context.route + '/assigned-tasks/' + this.task.id + '/complete/'
      this.$http.put(endpoint).then(() => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Task Completed!' })
        this.$router.push({ name: 'onboarding.tasks', params: { connection: this.connection.id } })
      }).catch(() => {
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again!' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    const apiKeyObject = this.account && this.account.credentials.filter(el => el.shipstation_api_key)
    this.apiKey = apiKeyObject[0].shipstation_api_key.values[0].value
    const apiSecretObjet = this.account && this.account.credentials.filter(el => el.shipstation_api_secret)
    this.apiSecret = apiSecretObjet[0].shipstation_api_secret.values[0].value
    const storeIdObject = this.account && this.account.credentials.filter(el => el.shipstation_store_id)
    this.savedAccountId = storeIdObject.length ? storeIdObject[0].shipstation_store_id.values[0].value : null
  },
  props: {
    connection: {
      type: Object,
      required: true
    },
    task: {
      type: Object,
      required: true
    },
    account: {
      type: Object
    }
  }
}
</script>
