<template>
  <p>
    <span :class="`${presenter.tagTextClass} mr-sm`">{{ presenter.tag }}</span>
  </p>
</template>

<script>
export default {
  computed: {
    presenter () {
      const score = Number(this.score)
      const presenter = {
        tag: null,
        tagTextClass: null
      }
      if (score >= 80) {
        presenter.tag = 'Gold'
        presenter.tagTextClass = 'has-text-success'
      } else if (score >= 70) {
        presenter.tag = 'Silver'
        presenter.tagTextClass = 'has-text-grey'
      } else if (score >= 60) {
        presenter.tag = 'Bronze'
        presenter.tagTextClass = 'has-text-warning'
      } else {
        presenter.tag = '--'
        presenter.tagTextClass = 'has-text-grey-light'
      }
      return presenter
    }
  },
  props: ['score']
}
</script>
