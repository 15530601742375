<template>
  <div class="has-text-centered">
    <h4 class="is-size-5 mt-lg has-text-centered">Revenue</h4>
    <div class="columns mt-sm is-mobile is-multiline">
      <!-- Supplier Revenue -->
      <div class="column is-half-tablet">
        <div class="has-background-white pa-md rounded-lg has-text-centered">
            <p class="heading">Supplier Revenue</p>
            <div v-if="this.loading" class="mb-sm"><b-icon icon="loading" custom-class="mdi-spin"></b-icon></div>
            <div v-else class="is-size-4">
              <p>
                ${{ $numbers(thisMonthsRevenue).format({thousandSeparated: true, mantissa: 2}) }}
              </p>
              <RevenueChange :revenueChange="revenueChange"></RevenueChange>
            </div>
        </div>
      </div>
      <!-- Merchant Share -->
      <div class="column is-half-tablet">
        <div class="has-background-white pa-md rounded-lg">
          <p class="heading">Merchant Share Paid</p>
          <div v-if="this.loading" class="mb-sm"><b-icon icon="loading" custom-class="mdi-spin"></b-icon></div>
          <div v-else class="is-size-4">
            <p>
              ${{ $numbers(thisMonthsMerchantShare).format({ thousandSeparated: true, mantissa: 2 }) }}
            </p>
            <RevenueChange :revenueChange="merchantShareChange"></RevenueChange>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import RevenueChange from '../../../components/Presenters/Compliance/RevenueChange.vue'

export default {
  components: {
    RevenueChange
  },
  computed: {
    ...mapGetters(['app']),
    thisMonthsRevenue () {
      return (this.thisMonthsOrderTotals && this.thisMonthsOrderTotals.estimated_gmv) || 0
    },
    revenueChange () {
      const thisMonth = (this.thisMonthsOrderTotals && this.thisMonthsOrderTotals.estimated_gmv) || 0
      const lastMonth = (this.lastMonthsOrderTotals && this.lastMonthsOrderTotals.estimated_gmv) || 0
      return thisMonth - lastMonth
    },
    thisMonthsMerchantShare () {
      const thisMonthsGmv = (this.thisMonthsOrderTotals && this.thisMonthsOrderTotals.estimated_gmv) || 0
      const thisMonthWholeSaleCost = (this.thisMonthsOrderTotals && this.thisMonthsOrderTotals.wholesale_cost) || 0
      return thisMonthsGmv - thisMonthWholeSaleCost
    },
    merchantShareChange () {
      const thisMonthsGmv = (this.thisMonthsOrderTotals && this.thisMonthsOrderTotals.estimated_gmv) || 0
      const thisMonthWholeSaleCost = (this.thisMonthsOrderTotals && this.thisMonthsOrderTotals.wholesale_cost) || 0
      const lastMonthsGmv = (this.lastMonthsOrderTotals && this.lastMonthsOrderTotals.estimated_gmv) || 0
      const lastMonthWholeSaleCost = (this.lastMonthsOrderTotals && this.lastMonthsOrderTotals.wholesale_cost) || 0
      const thisMonthsMerchantShare = thisMonthsGmv - thisMonthWholeSaleCost
      const lastMonthMerchantShare = lastMonthsGmv - lastMonthWholeSaleCost
      return thisMonthsMerchantShare - lastMonthMerchantShare
    }
  },
  data () {
    return {
    }
  },
  methods: {
  },
  props: ['thisMonthsOrderTotals', 'lastMonthsOrderTotals', 'loading']
}
</script>
