<template>
  <span class="has-text-weight-bold" :class="presenter.class">{{ presenter.label }}</span>
</template>

<script>
export default {
  computed: {
    presenter () {
      switch (this.feed.status) {
        case 'success':
          return { class: 'has-text-success', label: 'Completed' }
        default:
          return { class: 'has-text-grey', label: 'Processing' }
      }
    }
  },
  props: ['feed']
}
</script>
