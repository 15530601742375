<template>
  <form @submit.prevent="save" autocomplete="off">
    <!-- Intro -->
    <div class="has-text-centered">
      <p class="is-size-4 mb-sm has-text-black">Import Locations</p>
      <p class="has-text-centered pb-lg px-lg">
        Now let's import any <u>active</u> locations that you use to fulfill orders.
        <br/>You may import
        more than one location if necessary, but you must import at least
        one location to continue.
      </p>
    </div>
    <!-- Fields -->
    <div class="pb-lg mb-lg">
      <!-- Loaded -->
      <div v-if="!loading" class="columns is-centered">
        <div class="column is-two-thirds">
          <import-locations
            :account="account"
            :locations="locations"
            @location:imported="imported"
          />
        </div>
      </div>
      <!-- Loading -->
      <div v-else class="py-lg">
        <loading message="Loading..." />
      </div>
    </div>
    <!-- Buttons -->
    <div class="mb-xl">
      <div class="columns is-centered">
        <div class="column is-three-quarters-tablet is-two-thirds-widescreen">
          <div class="columns">
            <div class="column">
              <b-button type="is-light rounded-lg" size="is-large" @click="back" expanded>
                <b-icon icon="arrow-left"></b-icon>
              </b-button>
            </div>
            <div class="column">
              <b-button
                native-type="submit"
                type="is-primary rounded-lg"
                size="is-large"
                expanded
                :loading="saving"
                :disabled="!validated || saving"
              >Save & Continue</b-button>
            </div>
          </div>
        </div>
      </div>
      <p v-if="'detail' in errors" class="has-text-danger">{{ error.detail }}</p>
    </div>

  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import ImportLocations from '../../components/ImportLocations'

export default {
  components: {
    ImportLocations
  },
  data () {
    return {
      ready: false,
      loading: true,
      locations: [],
      saving: false,
      errors: {}
    }
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/locations/'
    },
    validated () {
      return this.locations.length > 0
    }
  },
  methods: {
    load () {
      this.loading = true
      return this.$http.get(this.endpoint + '?type=warehouse').then(response => {
        this.locations = response.data.results
        this.loading = false
      })
    },
    back () {
      this.$router.go(-1)
    },
    save () {
      this.$emit('change:step', 'sync')
    },
    imported (payload) {
      this.locations.push(payload)
    },
    show () {
      this.ready = true
    }
  },
  mounted () {
    this.load()
  },
  props: ['account']
}
</script>
