<template>
<form @submit.prevent="save">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">{{isAddCarrier ? 'Add' : 'Update'}} Carrier</p>
      </header>
      <!--Section contains the form input/select fields-->
      <section class="modal-card-body">
        <div>
          <p class="has-text-weight-bold mb-sm">Select Shopify Carrier</p>
          <div class="columns is-mobile is-vcentered">
            <div class="column">
              <div class="">
                <SelectShopifyCarrier
                  v-model="shopifyCarrier"
                  :carrierName="carrierMapping && carrierMapping.value"
                  :account="account"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="pt-lg">
          <p class="has-text-weight-bold mb-sm">Select {{ app.productName }} Carrier</p>
          <div class="columns is-mobile is-vcentered">
            <div class="column">
              <SelectMarketplaceCarrier
                v-model="marketplaceCarrier"
                :account="account"
                :carrierId="carrierMapping && carrierMapping.carrier.id"
              />
            </div>
          </div>
        </div>
      </section>
      <!--Footer contains all the ctas-->
      <footer class="modal-card-foot" style="justify-content:space-between" >
        <div>
          <button class="button" type="button" @click="$parent.close()" :disabled="saving">Close</button>
          <b-button
          :type="confirmDelete ? 'is-danger' : ''"
          :disabled="!marketplaceCarrier || !shopifyCarrier || saving"
          v-if="!isAddCarrier"
          @click.prevent="onDelete"
          >{{confirmDelete ? 'Confirm?' : 'Delete'}}</b-button>
        </div>
        <!-- If it is add carrier flow show Add button -->
        <b-button
          native-type="submit"
          type="is-primary"
          v-if="isAddCarrier"
          :disabled="!marketplaceCarrier || !shopifyCarrier || saving"
        >Save</b-button>
        <!-- Else check if it is delete flow, if not show update button-->
        <b-button
          @click.prevent="update"
          type="is-primary"
          v-else-if="!confirmDelete"
          :disabled="!marketplaceCarrier || !shopifyCarrier || saving"
        >Update</b-button>
      </footer>
    </div>
  </form>
</template>
<script>
import { mapGetters } from 'vuex'
import SelectShopifyCarrier from './SelectShopifyCarrier'
import SelectMarketplaceCarrier from '../SelectMarketplaceCarrier'

export default {
  components: {
    SelectShopifyCarrier,
    SelectMarketplaceCarrier
  },
  computed: {
    ...mapGetters(['app', 'context']),
    endpoint () {
      return this.context.route + '/platform-accounts/' + this.account.id + '/carrier-mappings/'
    },
    isAddCarrier () {
      return !this.carrierMapping
    }
  },
  methods: {
    save () {
      this.saving = true
      const payload = {
        value: this.shopifyCarrier,
        carrier_id: this.marketplaceCarrier.id
      }
      this.$http.post(this.endpoint, payload).then(response => {
        this.onSuccess('Carriers mapped successfully!')
      }).catch((error) => {
        const msg = error.response.data.detail || 'Unable to save the carrier mapping'
        this.$buefy.toast.open({ type: 'is-danger', message: msg })
      }).finally(() => { this.saving = false })
    },
    onDelete () {
      if (this.confirmDelete) {
        this.remove()
      } else {
        this.confirmDelete = true
        this.deleteBtnTimerID = setTimeout(() => {
          this.confirmDelete = false
        }, 3000)
      }
    },
    remove () {
      this.saving = true
      clearTimeout(this.deleteBtnTimerID)
      this.$http.delete(this.endpoint + this.carrierMapping.id + '/').then(response => {
        this.onSuccess('Carrier mapping deleted successfully!')
      }).catch((error) => {
        const msg = error.response.data.detail || 'Unable to delete the carrier mapping'
        this.$buefy.toast.open({ type: 'is-danger', message: msg })
      }).finally(() => {
        this.saving = false
        this.confirmDelete = false
      })
    },
    update () {
      this.saving = true
      this.confirmDelete = false
      const payload = {
        value: this.shopifyCarrier,
        carrier_id: this.marketplaceCarrier.id
      }
      this.$http.patch(this.endpoint + this.carrierMapping.id + '/', payload).then(response => {
        this.onSuccess('Carrier mapping updated successfully!')
      }).catch((error) => {
        const msg = error.response.data.detail || 'Unable to update the carrier mapping'
        this.$buefy.toast.open({ type: 'is-danger', message: msg })
      }).finally(() => { this.saving = false })
    },
    onSuccess (msg) {
      this.$buefy.toast.open({ type: 'is-success', message: msg })
      this.$emit('carriers:updated')
      this.$parent.close()
    }
  },
  data () {
    return {
      saving: false,
      shopifyCarrier: null,
      marketplaceCarrier: null,
      mappingName: this.carrierMapping ? this.carrierMapping.nickname : '',
      confirmDelete: false,
      deleteBtnTimerID: 0
    }
  },
  props: {
    account: {
      type: Object,
      required: true
    },
    carrierMapping: {
      type: Object,
      default: null
    }
  }

}
</script>
