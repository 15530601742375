<template>
  <div>
    <p class="has-text-weight-bold mb-sm">Carrier Service</p>
    <!-- Select Service -->
    <div>
      <b-field>
        <b-select
          v-model="method"
          placeholder="Select service..."
          expanded
          :loading="loading"
          :disabled="methods.length === 0 || loading"
          size="is-medium"
          @input="announce">
          <option v-for="method in methods" :key="method.id" :value="method">{{ method.name }}</option>
        </b-select>
      </b-field>
      <div>
        <p v-if="method !== null" class="has-text-grey is-size-7">
          Generic Method: {{ method.name }}, {{method.description}}
        </p>
        <p v-else class="has-text-grey-light is-size-7">
          Select a method above...
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  computed: {
    ...mapGetters(['context', 'api']),
    methods () {
      if (this.methodType && !this.loading) {
        return this.list.filter(el => this.methodType.includes(el.name))
      } else {
        return this.list
      }
    }
  },
  data () {
    return {
      list: [],
      method: null,
      loading: true
    }
  },
  methods: {
    announce () {
      this.$emit('input', this.method)
    },
    loadShippingMethods () {
      const endpoint = this.api.baseUrl + '/v1/genericshippingmethods/'
      const query = {
        params: { order_by: -1, pagination: 0 }
      }
      this.$http.get(endpoint, query).then(response => {
        this.list = response.data.results
        this.loading = false
      })
    }
  },
  mounted () {
    this.loadShippingMethods()
  },
  props: ['methodType']
}
</script>
