<template>
  <div class="section">
    <div class="container">
      <!-- Page Header -->
      <div class="mb-lg">
        <div class="columns is-vcentered is-centered">
          <div class="column is-one-third">
            <h1 class="has-text-centered is-size-4">Job Status</h1>
          </div>
        </div>
      </div>
      <!-- Filters Toolbar -->
      <div class="has-background-white rounded-lg pa-md mb-xxs">
        <div class="columns">
          <div class="column is-narrow">
            <JobFilters :applied-filters="filters" @filter="filter" />
          </div>
          <div class="column ">
            <b-select v-model="orderBy" size="is-medium" expanded @input="sort">
              <option value="-id">Most Recent</option>
              <option value="id">Oldest</option>
            </b-select>
          </div>
          <!-- <div class="column is-narrow">
            <b-button size="is-medium" class="is-fullwidth" icon-left="download" @click="showExportModal = !showExportModal">Export</b-button>
          </div> -->
        </div>
      </div>
      <!-- Actions Toolbar -->
      <div class="has-background-white rounded-lg px-md py-sm mb-xxs">
        <div class="columns is-vcentered is-mobile">
          <div class="column is-narrow">
            <b-dropdown :disabled="loading || selected.length === 0">
              <button class="button" slot="trigger" slot-scope="{ active }" :disabled="selected.length === 0">
                <span>Actions</span>
                <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
              </button>
              <template v-if="context.isSupplier()">
                <b-dropdown-item has-link><a href="#" @click.prevent class="has-text-grey-light">No Actions</a></b-dropdown-item>
              </template>
              <template v-if="context.isMerchant()">
                <b-dropdown-item has-link><a href="#" @click.prevent class="has-text-grey-light">No Actions</a></b-dropdown-item>
              </template>
            </b-dropdown>
          </div>
          <div class="column">
            <div class="has-text-right is-size-7">
              <!-- Counts -->
              <p v-if="loading">Loading...</p>
              <p v-else>{{ total }} {{ (total === 1) ? 'job' : 'jobs' }} found.</p>
            </div>
          </div>
        </div>
      </div>
      <!-- Results -->
      <div>
        <div class="mb-md">
          <b-table
            :data="results"
            :checked-rows.sync="selected"
            :mobile-cards="false"
            :loading="loading"
            hoverable
            checkable
            checkbox-position="left"
            class="is-size-7">
            <template>
              <!-- RC ID (since RMA Number not always required) -->
              <b-table-column
                label="ID"
                header-class="nowrap"
                cell-class="nowrap"
                width="128"
                v-slot="props">
                <a href="#"
                  class="has-text-weight-bold has-text-info"
                  @click.prevent="inspectJob = props.row"
                >{{ props.row.id }}</a>
              </b-table-column>
              <!-- Module Name -->
              <b-table-column
                label="Job"
                header-class="nowrap"
                cell-class="nowrap"
                v-slot="props">
                <ModuleName :module="props.row.module_name" />
              </b-table-column>
              <b-table-column
                label="Started"
                header-class="nowrap"
                cell-class="nowrap"
                width="128"
                v-slot="props">
                <span v-if="props.row.start_time">{{ $dates.utc(props.row.start_time).local().format('MMM D @ h:mm A') }}</span>
                <span v-else class="has-text-grey">--</span>
              </b-table-column>
              <b-table-column
                label="Finished"
                header-class="nowrap"
                cell-class="nowrap"
                width="128"
                v-slot="props">
                <span v-if="props.row.finish_time">{{ $dates.utc(props.row.finish_time).local().format('MMM D @ h:mm A') }}</span>
                <span v-else class="has-text-grey">--</span>
              </b-table-column>
              <b-table-column
                label="Runtime"
                header-class="nowrap"
                cell-class="nowrap"
                centered
                width="96"
                v-slot="props">
                <Runtime :start="props.row.start_time" :finish="props.row.finish_time" />
              </b-table-column>
              <!-- Success -->
              <b-table-column
                label="# Success"
                header-class="nowrap"
                cell-class="nowrap"
                centered
                width="96"
                v-slot="props">
                <span v-if="props.row.total_success !== null">{{ props.row.total_success }}</span>
                <span v-else>--</span>
              </b-table-column>
              <!-- Errors  -->
              <b-table-column
                label="# Errors"
                header-class="nowrap"
                cell-class="nowrap"
                centered
                width="96"
                v-slot="props">
                <span v-if="props.row.error_count !== null">{{ props.row.error_count }}</span>
                <span v-else>--</span>
              </b-table-column>
            </template>
            <template #empty>
              <div class="py-xxl has-text-grey has-text-centered">
                {{ (loading) ? "Loading data..." : 'No jobs found!' }}
              </div>
            </template>
          </b-table>
        </div>
        <b-pagination
          :total="total"
          :current.sync="currentPage"
          :rounded="true"
          :per-page="perPage"
          @change="paginate">
        </b-pagination>
      </div>

      <!-- Inspect Job -->
      <b-modal
        v-if="(inspectJob !== null)"
        has-modal-card
        :active="(inspectJob !== null)"
        :can-cancel="['escape', 'x']"
        @close="closeModals"
        ><JobModal :selectedJob="inspectJob" />
      </b-modal>

    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import presets from '../presets.js'
import Filterable from '@/mixins/Filterable'
import Exportable from '@/mixins/Exportable'
import JobModal from '@/components/Modals/Job/JobModal'
import JobFilters from '../components/List/JobFilters'
import ModuleName from '@/components/Presenters/Job/ModuleName'
import Runtime from '@/components/Presenters/Job/Runtime'

export default {
  components: {
    JobFilters,
    JobModal,
    ModuleName,
    Runtime
  },
  data () {
    return {
      key: 'jobs',
      presets: presets,
      alwaysAttach: {
        short: 1
      },
      // modals
      showJobModal: false,
      inspectJob: null
    }
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/jobs/'
    },
    exportEndpoint () {
      return this.endpoint + 'email-export/'
    }
  },
  methods: {
    closeModals () {
      this.inspectJob = null
    },
    reload () {
      this.selected = []
      this.load()
    }
  },
  mounted () {
    this.load()
  },
  mixins: [Filterable, Exportable]
}
</script>
