<template>
  <span class="has-text-weight-bold" :class="presenter.indicatorClass">
    {{ presenter.label }}
  </span>
</template>

<script>
export default {
  computed: {
    presenter () {
      const presenter = {
        label: 'Sent',
        tooltip: 'This credit has been registered and sent to your merchant partner.',
        indicatorClass: 'has-text-warning'
      }
      // if the shipment is closed, but acknowledged
      if (this.credit.acknowledged_at !== null) {
        presenter.label = 'Received'
        presenter.tooltip = 'The merchant has received this credit memo.'
        presenter.indicatorClass = 'has-text-success'
      }
      return presenter
    }
  },
  props: ['credit']
}
</script>
