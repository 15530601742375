<template>
<div>
  <div class="section">
    <div class="container">
      <!-- Page Header -->
      <div class="mb-md">
        <div class="columns is-vcentered">
          <div class="column is-one-third">
            <breadcrumbs :history="[{ label: 'Settings', name: 'settings'}]" current="Retailer SKU" />
          </div>
          <div class="column is-one-third">
            <h1 class="has-text-centered is-size-4">Retailer SKU Settings</h1>
          </div>
        </div>
      </div>
      <!-- Preferences -->
      <div class="columns">
        <div class="column is-one-fifth">
          <p class="has-text-weight-bold is-size-6 mb-sm">Retailer SKU Settings</p>
          <p class="has-text-grey is-size-7">
            This settings configured on this page will be applied to
            <u class="has-text-weight-bold">new</u> products only; existing products
            and items will NOT be modified. Please read and consider each setting
            carefully.
          </p>
        </div>
        <div class="column">
          <!-- Preferences -->
          <div v-if="!loading">
            <div class="mb-lg">
              <RetailerSkus :preferences="preferences" @preferences:updated="load" />
            </div>
            <div class="mb-lg">
              <SerialKey :preferences="preferences" />
            </div>
          </div>
          <!-- Loading -->
          <div v-else>
            <div class="has-background-white rounded-lg pa-lg mb-lg">
              <b-skeleton />
              <b-skeleton width="50%" />
              <b-skeleton width="25%" />
            </div>
            <div class="has-background-white rounded-lg pa-lg mb-lg">
              <b-skeleton />
              <b-skeleton width="50%" />
              <b-skeleton width="25%" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import RetailerSkus from '../components/Products/RetailerSkus'
import SerialKey from '../components/Products/SerialKey'

export default {
  components: {
    RetailerSkus,
    SerialKey
  },
  computed: {
    ...mapGetters(['api', 'context']),
    endpoint () {
      return this.context.route + '/retailer-preferences/'
    },
    params () {
      return {
        params: {
          grouping: 'misc'
        }
      }
    },
    json () {
      if (Object.keys(this.values).length === 0) {
        return null
      }
      const preferences = []
      for (const preference in this.values) {
        preferences.push(this.values[preference])
      }
      return preferences
    }
  },
  data () {
    return {
      loading: true,
      saving: false,
      preferences: [],
      values: {}
    }
  },
  methods: {
    load () {
      this.loading = true
      const endpoint = this.context.route + '/preferences/'
      this.$http.get(endpoint, this.params).then(response => {
        this.preferences = response.data.results
      }).finally(() => {
        this.loading = false
      })
    },
    trackPreferenceValue (preferenceValue) {
      this.$set(this.values, preferenceValue.key, preferenceValue.payload)
    },
    save () {
      this.saving = true
      this.$http.post(this.endpoint, this.json).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Preferences saved!' })
      }).catch(() => {
        this.$buefy.toast.open({ type: 'is-danger', message: 'Preferences not saved!' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    this.load()
  }
}
</script>
