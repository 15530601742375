<template>
  <div>
    <form @submit.prevent="save">
      <div class="columns">
        <div class="column is-one-fifth">
          <p class="has-text-weight-bold is-size-6 mb-sm">Mission Statement</p>
          <div class="has-text-grey is-size-7">
            <p class="mb-md">
              Summarize your organization's mission in one or two sentences.
            </p>
          </div>
        </div>
        <div class="column">
          <div class="has-background-white rounded-lg">
            <div class="px-lg pt-lg pb-sm">
              <!-- Loading platform account or loading marketplace -->
              <div v-if="loading">
                <loading message="Loading..."></loading>
              </div>
              <!-- Loaded -->
              <div v-else>
                <b-field>
                  <b-input
                    autocomplete="off"
                    type="textarea"
                    name="name"
                    maxlength="140"
                    v-model="mission"
                    @input="isDirty = true"
                    expanded
                  ></b-input>
                </b-field>
              </div>
            </div>
            <div class="border-t">
              <div class="columns is-vcentered is-marginless">
                <div class="column">
                  <div class="px-sm has-text-grey-light">
                    <i v-if="isDirty" class="has-text-danger">Click 'Save' to save your changes before moving on to the next section.</i>
                  </div>
                </div>
                <div class="column is-narrow">
                  <div class="px-sm">
                    <b-button native-type="submit" type="is-primary rounded-lg" :loading="saving" :disabled="saving || !isDirty">Save</b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      if (this.existingAsset !== null) {
        return this.context.route + '/assets/' + this.existingAsset.id + '/'
      }
      return this.context.route + '/marketplaces/' + this.marketplace.id + '/assets/'
    },
    method () {
      return (this.existingAsset !== null) ? 'patch' : 'post'
    },
    existingAsset () {
      if (this.marketplace.assets.length === 0) return null
      const existing = this.$utils.find(this.marketplace.assets, { name: 'mission' })
      if (typeof existing !== 'undefined') {
        return existing
      }
      return null
    },
    json () {
      return {
        name: 'mission',
        description: this.mission.replace(/\r?\n|\r/g, ''),
        type: 'string'
      }
    }
  },
  data () {
    return {
      isDirty: false,
      mission: null,
      errors: [],
      saving: false
    }
  },
  methods: {
    save () {
      this.saving = true
      this.$http[this.method](this.endpoint, this.json).then(response => {
        this.isDirty = false
        this.errors = []
        this.$buefy.toast.open({ type: 'is-success', message: 'Mission statement updated!' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  props: ['account', 'loading', 'marketplace'],
  watch: {
    loading (value) {
      if (value === false && this.existingAsset !== null) {
        this.mission = this.existingAsset.description
      }
    }
  }
}
</script>
