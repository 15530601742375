<template>
  <div>
    <form @submit.prevent="process" autocomplete="off" novalidate>
      <p class="has-text-centered is-size-4">Enter your email to reset your password.</p>
      <!-- Email -->
      <div class="py-xl">
        <div class="mb-lg">
          <b-input
            v-model="email"
            type="text"
            native-type="email"
            size="is-medium"
            placeholder="Email address"
          ></b-input>
        </div>
        <div>
          <b-button
            type="is-primary rounded-lg"
            size="is-medium"
            native-type="submit"
            :disabled="!validated || processing"
            :loading="processing"
            expanded
          >Send Reset Instructions</b-button>
        </div>
        <p v-if="success" class="has-text-warning has-text-centered py-lg">
          If your email is on file with {{ app.platform }}, you should receive an
          email containing reset instructions. Please contact
          <span class="has-text-weight-bold">{{ app.supportEmail }}</span>
          if you need additional assistance.
        </p>
      </div>
    </form>
    <p class="has-text-centered">
      <router-link class="has-text-grey" :to="{ name: 'auth.login' }">Return to Sign In</router-link>
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['app', 'api']),
    validated () {
      return this.email !== null && this.email !== ''
    },
    endpoint () {
      let base = this.api.baseUrl + '/' + this.api.version + '/users/reset-password/'
      base += (this.app.platform === 'fabric')
        ? '?source=fabric'
        : '?source=platform'
      return base
    },
    json () {
      return {
        email: this.email
      }
    }
  },
  data: function () {
    return {
      processing: false,
      email: null,
      success: false
    }
  },
  methods: {
    process () {
      this.processing = true
      this.$http.put(this.endpoint, this.json).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Check your email for further instructions.' })
        this.success = true
        this.email = null
      }).finally(() => {
        this.processing = false
      })
    }
  }
}
</script>
