<template>
  <div>
    <div class="has-background-white rounded-lg pa-lg">
      <div class="mt-md">
        <b-table
          class="is-size-7"
          narrowed
          :data="translations"
          :loading="loading"
          :mobile-cards="false">
          <template>
            <!-- Match -->
            <b-table-column
              label="Match"
              cell-class="nowrap"
              v-slot="props"
              width="200">
              <p>{{ props.row.match}}</p>
            </b-table-column>
            <!-- Output -->
            <b-table-column
              label="Output"
              cell-class="nowrap"
              v-slot="props"
              centered
              width="200">
              <p>
                {{ props.row.output }}
              </p>
            </b-table-column>
            <!-- Updated At -->
            <b-table-column
              label="Updated At"
              cell-class="nowrap"
              v-slot="props"
              centered
              width="100">
              <p>
                {{ $dates.utc(props.row.updated_at).local().format('MMM D, YYYY @ h:mm A')}}
              </p>
              <p></p>
            </b-table-column>
            <!-- Edit -->
            <b-table-column
              label=""
              cell-class="nowrap"
              centered
              v-slot="props"
              width="100">
              <p>
                <a href="#" @click.prevent="editTranslation(props.row)" class="has-text-info">Edit</a>
              </p>
            </b-table-column>
          </template>
          <template #empty>
            <div class="has-text-grey has-text-centered">
              {{ (loading) ? "Loading data..." : 'No transformations found!' }}
            </div>
          </template>
        </b-table>
      </div>
    </div>
    <!-- Edit modal -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showEditModal"
      :can-cancel="['x']"
      >
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Edit Translation</p>
        </header>
        <section class="modal-card-body" style="height: 300px">
          <div class="has-background-white rounded-lg">
            <b-field
              label="Translation Match"
              expanded>
              <b-input
                type="text"
                size="is-medium"
                @input="isDirty = 1"
                v-model="match"
              ></b-input>
            </b-field>
            <b-field
              label="Translation Output"
              expanded>
              <b-input
                type="text"
                size="is-medium"
                @input="isDirty = 1"
                v-model="output"
              ></b-input>
            </b-field>
          </div>
        </section>
        <footer class="modal-card-foot" style="justify-content:space-between">
          <b-button @click="showEditModal = false">Close</b-button>
          <div>
            <b-button type="is-primary" @click="deleteTranslation" :loading="deleting">Remove</b-button>
            <b-button type="is-primary" @click="updateTranslation" :loading="saving" :disabled="!isDirty">Update</b-button>
          </div>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {
  },
  computed: {
    ...mapGetters(['app', 'api', 'context']),
    endpoint () {
      return this.context.route + `/transformers/${this.$route.params.transformer}/maps/${this.$route.params.id}/transformations/`
    }
  },
  data: () => {
    return {
      translations: [],
      loading: false,
      saving: false,
      deleting: false,
      translation: null,
      errors: {},
      showEditModal: false,
      match: '',
      output: '',
      isDirty: false
    }
  },
  methods: {
    editTranslation (row) {
      this.translation = row
      this.showEditModal = true
      this.match = row.match
      this.output = row.output
    },
    // Update existing translation
    updateTranslation () {
      this.saving = true
      const endpoint = this.endpoint + `${this.translation.id}/`
      const json = {
        match: this.match,
        output: this.output
      }
      this.$http.patch(endpoint, json).then(response => {
        this.errors = {}
        this.$buefy.toast.open({ type: 'is-success', message: 'Translation updated!' })
        this.$emit('on:reload')
      }).catch(error => {
        this.$buefy.toast.open({ type: 'is-danger', message: 'Error updating translation!' })
        this.errors = error.response.data
      }).finally(() => {
        this.reset()
      })
    },
    // Delete existing translation
    deleteTranslation () {
      this.deleting = true
      const endpoint = this.endpoint + `${this.translation.id}/`
      this.$http.delete(endpoint).then(response => {
        this.errors = {}
        this.$buefy.toast.open({ type: 'is-success', message: 'Translation deleted!' })
        this.$emit('on:reload')
      }).catch(error => {
        this.$buefy.toast.open({ type: 'is-danger', message: 'Error deleting translation!' })
        this.errors = error.response.data
      }).finally(() => {
        this.reset()
      })
    },
    reset () {
      this.translation = null
      this.showEditModal = false
      this.saving = false
      this.deleting = false
    }
  },
  mounted () {
    this.translations = this.translationsList
  },
  watch: {
    translationsList () {
      this.translations = this.translationsList
    }
  },
  props: ['translationsList']
}
</script>
