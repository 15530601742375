<template>
  <div>
    <div class="columns">
      <div class="column is-one-fifth">
        <p class="has-text-weight-bold is-size-6 mb-sm">Push Inventory</p>
        <p class="has-text-grey is-size-7">Push inventory from {{ app.platform }} to Shopify.</p>
      </div>
      <div class="column">
        <div class="has-background-white rounded-lg">
          <div class="pa-lg">
            <!-- Intro Text (Merchant) -->
            <div class="is-size-7 mb-lg">
              <p class="mb-md">
                Use this utility to push inventory for all synced items from the specified connection.
              </p>
            </div>
            <!-- Form -->
            <form @submit.prevent="syncVariants" class="mb-lg">
              <div class="columns">
                <div class="column">
                  <b-field>
                    <ConnectionSelect v-model="connection" size="is-medium" />
                  </b-field>
                </div>
                <div class="column is-narrow">
                  <b-button
                    type="is-primary"
                    native-type="submit"
                    size="is-medium"
                    :loading="processing"
                    :disabled="processing || !valid"
                  >Push Inventory</b-button>
                </div>
              </div>
            </form>
            <!-- Job Result -->
            <div class="is-size-7">
              <div v-if="processing">
                <p class="has-text-grey-light is-italic">Processing request...</p>
              </div>
              <div v-else-if="errorMessage !== null">
                <p class="has-text-grey-light is-italic">{{ errorMessage }}</p>
              </div>
              <div v-else-if="!processing && job !== null">
                <span class="has-text-weight-bold has-text-success">Retry Scheduled!</span>
                Your input resulted in Job <a href="#" class="has-text-info" @click.prevent="inspectJob = job">#{{ job.id }}</a>.
                Please refer to the job status for more information.
              </div>
              <div v-else>
                <p class="has-text-grey-light is-italic">Click the 'Run Sync' to kickoff the sync process.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Inspect Job -->
    <b-modal
      v-if="(inspectJob !== null)"
      has-modal-card
      :active="(inspectJob !== null)"
      :can-cancel="['escape', 'x']"
      @close="inspectJob = null"
      ><JobModal :selectedJob="inspectJob" />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ConnectionSelect from '@/components/Models/Connection/ConnectionSelect'
import JobModal from '@/components/Modals/Job/JobModal'
export default {
  components: {
    ConnectionSelect,
    JobModal
  },
  computed: {
    ...mapGetters(['app', 'context']),
    endpoint () {
      return this.context.route + '/platform-accounts/' + this.account.id + '/push-inventory/'
    },
    json () {
      return {
        connection_id: parseInt(this.connection.id)
      }
    },
    valid () {
      if (!this.account) return false
      if (!this.connection) return false
      return true
    }
  },
  data () {
    return {
      connection: null,
      processing: false,
      job: null,
      inspectJob: null,
      errorMessage: null
    }
  },
  methods: {
    syncVariants () {
      this.processing = true
      this.job = null
      this.$http.post(this.endpoint, this.json).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Sync scheduled!' })
        this.errorMessage = null
        this.job = response.data
      }).catch(() => {
        this.$buefy.toast.open({ type: 'is-danger', message: 'Sync failed!' })
      }).finally(() => {
        this.processing = false
      })
    }
  },
  mounted () {

  },
  props: ['account']
}
</script>
