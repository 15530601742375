import Request from '../Request'
export default class MarkAsDiscontinued extends Request {
  constructor (config) {
    super()
    this.method = 'patch'
    this.route = config.route
    this.variant = config.variant
    this.validate()
  }

  id () {
    return this.variant.id
  }

  validate () {
    if (this.variant.inventory.discontinued) {
      this.isValid = false
      this.status = 'Skip'
      this.errorMessage = 'Variant has already been marked as discontinued.'
    } else {
      this.isValid = true
    }
  }

  json () {
    return {
      attributes: [{
        attribute: {
          code: 'discontinued'
        },
        value: 1
      }]
    }
  }

  endpoint () {
    return this.route + '/variants/' + this.id() + '/'
  }

  errorHandler (errors) {
    return errors[0]
  }
}
