import dates from '@/assets/js/dates'

export const contentType = {
  label: 'Content Type',
  key: 'content_type',
  icon: 'pulse',
  tooltip: 'Filter rulesets based on the content type.',
  options: [{
    value: 'brand',
    label: 'Suppliers'
  }, {
    value: 'retailers',
    label: 'Retailers'
  }]
}

export const updatedAt = {
  label: 'Updated At',
  key: 'updated_at',
  icon: 'calendar-outline',
  tooltip: 'Filter rulesets based the date and time they were created.',
  options: dates
}
