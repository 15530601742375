<template>
    <div>
      <div>
        <div class="mb-lg has-text-centered">
          <p class="has-text-weight-bold">Store URL</p>
          <p v-if="loading">Loading...</p>
          <p v-else>{{ shopName }}</p>
        </div>
        <div class="mb-lg has-text-centered">
          <p class="has-text-weight-bold">Status</p>
          <div class="has-text-weight-bold">
            <p v-if="hasAccessToken" class="has-text-success">Connected</p>
            <p v-else class="has-text-warning">Not Connected</p>
          </div>
        </div>
      </div>
      <!-- Navigate -->
      <div class="py-lg">
        <div class="columns is-centered">
          <div class="column">
            <b-button
              type="is-light rounded-lg"
              size="is-medium"
              @click="goBack"
              expanded
              ><b-icon icon="arrow-left"></b-icon>
            </b-button>
          </div>
          <div class="column">
            <b-button
              v-if="hasAccessToken"
              type="is-primary rounded-lg"
              size="is-medium"
              expanded
              @click="goForward"
              :disabled="saving"
              :loading="saving"
            >Continue</b-button>
            <a v-else
              :href="installUrl"
              class="button is-primary is-medium rounded-lg is-fullwidth"
            >Install App</a>
          </div>
        </div>
      </div>
    </div>
  </template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['app', 'api', 'context'])
  },
  data () {
    return {
      loading: true,
      saving: false,
      shopName: null,
      installUrl: '',
      hasAccessToken: false,
      installedAccount: null
    }
  },
  methods: {
    goBack () {
      this.$emit('navigate', 'Intro')
    },
    goForward () {
      if (this.account !== null) {
        this.$emit('navigate', 'Inventory')
      }
    },
    completeTask () {
      this.saving = true
      const endpoint = this.context.route + '/assigned-tasks/' + this.$route.params.task + '/complete/'
      this.$http.put(endpoint).then(() => {
        this.$router.push({ name: 'onboarding.tasks', params: { connection: this.connection.id } })
        this.saving = false
      })
    },
    fetchInstallUrl () {
      this.$http.get(this.api.baseUrl + '/v1/brands/' + this.installedAccount.brand.id + '/woocommerce/' + this.installedAccount.id + '/request-permission/').then((res) => {
        this.installUrl = res.data.redirect_url
      })
    },
    setData () {
      if (this.installedAccount && this.installedAccount.credentials) {
        this.installedAccount.credentials.forEach(credential => {
          if ('woocommerce_shop_uri' in credential) {
            this.shopName = credential.woocommerce_shop_uri.values[0].value
          }
          if ('woocommerce_consumer_key' in credential) {
            this.hasAccessToken = true
          }
        })
        if (!this.hasAccessToken) {
          this.fetchInstallUrl()
        }
        this.loading = false
      }
    }
  },
  mounted () {
  },
  watch: {
    account: {
      deep: true,
      immediate: true,
      handler (value) {
        if (value !== null) {
          this.installedAccount = value
          this.setData()
        }
      }
    }
  },
  props: {
    connection: {
      type: Object,
      required: true
    },
    task: {
      type: Object,
      required: true
    },
    account: {
      type: Object
    }
  }
}
</script>
