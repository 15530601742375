<template>
  <div>
    <div class="mb-sm">
      <p class="mb-xs has-text-centered has-text-weight-bold" :class="status.class">{{ status.label }}</p>
      <p class="mb-xs"><b-progress :value="value" :show-value="true" :size="size" format="percent"></b-progress></p>
      <div class="columns has-text-grey-light">
        <div class="column is-one-third">
          <b-icon
            v-if="!job.finish_time"
            icon="loading"
            size="is-small"
            custom-class="mdi-spin has-text-grey-light"
          ></b-icon>
        </div>
        <div class="column is-one-third"></div>
        <div class="column is-one-third">
          <p v-if="!job.finish_time" class="has-text-right is-size-7">{{ refreshAttempt }} / {{ maxRefreshes }}</p>
        </div>
      </div>
      <p v-if="!job.finish_time" class="has-text-centered is-size-7">
        <a href="#" @click.prevent="$emit('refresh')" class="has-text-info">Refresh</a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    status () {
      if (this.job.finish_time) {
        return { class: 'has-text-success', label: 'Completed' }
      }
      if (this.job.start_time) {
        return { class: 'has-text-success', label: 'Processing' }
      }
      return { class: 'has-text-grey', label: 'Queued' }
    },
    value () {
      if (this.job.finish_time) return 100
      return undefined
    }
  },
  data () {
    return {
      interval: null,
      refreshAttempt: 1,
      maxRefreshes: 20
    }
  },
  methods: {
    emitRefreshEvent () {
      if (this.refreshAttempt < this.maxRefreshes && !this.job.finish_time) {
        this.$emit('refresh')
        this.refreshAttempt++
      } else {
        window.clearInterval(this.interval)
      }
    }
  },
  mounted () {
    if (this.emitRefresh && (this.job.finish_time === null)) {
      this.interval = setInterval(() => {
        this.emitRefreshEvent()
      }, 10000)
    }
  },
  unmounted () {
    window.clearInterval(this.interval)
  },
  props: {
    job: {
      type: Object,
      required: true
    },
    size: {
      type: String,
      default: null
    },
    emitRefresh: {
      type: Boolean,
      default: false
    }
  }
}
</script>
