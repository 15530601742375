<template>
  <div>
    <section>
      <b-field>
        <b-taginput
            v-model="tags"
            :before-adding="validation"
            :data="filteredTags"
            :allow-new="true"
            autocomplete
            icon="label"
            placeholder="Add a tag"
            field="tag"
            aria-close-label="Delete this tag"
            @typing="filterTags">
        </b-taginput>
      </b-field>
      <b-message v-if="error" type="is-danger" size="is-small">{{ errorMsg }}</b-message>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import debounce from 'lodash/debounce'

export default {
  components: {},
  computed: {
    ...mapGetters(['context'])
  },
  data () {
    return {
      tags: this.orderTags,
      loading: false,
      errorMsg: '',
      error: false,
      isAdding: false,
      isRemoving: false,
      filteredTags: this.orderTags
    }
  },
  watch: {
    tags (newTags, oldTags) {
      if (this.isAdding || this.isRemoving) {
        return
      }
      if (newTags.length > oldTags.length) {
        const isNew = typeof newTags[newTags.length - 1] === 'string'
        this.addTag({ tag: (isNew) ? newTags[newTags.length - 1] : newTags[newTags.length - 1].tag })
        return
      }
      // remove tag
      const formatNewTags = newTags.map(({ id }) => (id))
      const removeTag = oldTags.filter(({ id }) => !formatNewTags.includes(id))[0]
      if (!removeTag.id) {
        this.$buefy.toast.open({ type: 'is-danger', message: 'Tag is not removed' })
        return
      }
      this.$emit('updateTag', newTags)
      this.removeTag({ id: removeTag.id })
    }
  },
  methods: {
    resetError () {
      this.error = false
      this.errorMsg = ''
    },
    validation (e) {
      this.resetError()
      // if e is string => new tag (no tag id), existing tag is { tag, id }
      const tagName = (typeof e === 'string') ? e : e.tag
      if (this.tags.filter((obj) => obj.tag.toLowerCase() === tagName.toLowerCase()).length > 0) {
        this.error = true
        this.errorMsg = `${tagName} already exists as a tag on this order.`
        return false
      }
      return true
    },
    addTag ({ tag }) {
      // add new or existing tag
      this.isAdding = true
      const endpoint = `${this.context.route}/orders/${this.order.id}/tags/`
      this.$http.post(endpoint, { tag }).then(({ data }) => {
        this.tags.pop()
        this.tags.push(data)
        this.$buefy.toast.open({ type: 'is-success', message: 'Tag added' })
        this.error = false
        this.errorMsg = ''
      }).catch(() => {
        this.$buefy.toast.open({ type: 'is-danger', message: 'Tag is not added' })
      }).finally(() => {
        this.$emit('updateTag', this.tags)
        this.isAdding = false
      })
    },
    removeTag ({ id }) {
      this.isRemoving = true
      const endpoint = `${this.context.route}/tag-items/${id}/`
      this.$http.delete(endpoint).then(({ data }) => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Tag removed' })
        this.error = false
        this.errorMsg = ''
      }).catch(() => {
        this.$buefy.toast.open({ type: 'is-danger', message: 'Tag is not removed' })
      }).finally(() => {
        this.isRemoving = false
      })
    },
    filterTags: debounce(function (e) {
      this.loading = true
      const endpoint = `${this.context.route}/tags/?name_search=${e}`
      this.$http.get(endpoint).then(response => {
        this.filteredTags = response.data.results.map(({ name }) => ({ tag: name }))
      }).finally(() => {
        this.loading = false
      })
    }, 500)
  },
  mounted () {
    this.tags = this.orderTags
  },
  props: {
    orderTags: {
      type: Array,
      required: true
    },
    order: {
      type: Object,
      required: true
    }
  }
}
</script>
