<template>
  <form @submit.prevent="save">
    <b-button
      size="is-small"
      native-type="submit"
      type="is-primary"
      outlined
      :loading="saving"
      :disabled="saving"
    >{{ label }}</b-button>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  data () {
    return {
      saving: false
    }
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      if (this.direction === 'outgoing') {
        return this.context.route + '/edi-accounts/' + this.ediAccountId + '/envelopes/' + this.envelopeId + '/transmit/'
      } else {
        return this.context.route + '/edi-accounts/' + this.ediAccountId + '/documents/' + this.document.id + '/process/'
      }
    },
    label () {
      return (this.direction === 'outgoing') ? 'Retransmit' : 'Reprocess'
    }
  },
  methods: {
    save () {
      this.saving = true
      this.$http.put(this.endpoint).then(() => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Action completed!' })
      }).catch(() => {
        this.$buefy.toast.open({ type: 'is-danger', message: 'Action failed!' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  props: ['ediAccountId', 'envelopeId', 'direction', 'document']
}
</script>
