import dates from '@/assets/js/dates'

export const connection = {
  label: 'Connection',
  key: 'connection_id',
  icon: 'pulse',
  tooltip: 'Limit results to a set of selected partners.'
}

export const status = {
  label: 'Status',
  key: 'acknowledged',
  icon: 'pulse',
  tooltip: 'Limit results based on return status.',
  options: [{
    value: 'approved',
    label: 'Approved'
  }, {
    value: 'pending',
    label: 'Pending'
  }, {
    value: 'declined',
    label: 'Declined'
  }]
}

export const supplierReasons = {
  label: 'Reason',
  key: 'reason_id',
  icon: 'pulse',
  tooltip: 'Limit results based on the cancel reason.',
  options: [{
    value: 1,
    label: 'Customer Disposition'
  }, {
    value: 2,
    label: 'Item did not meet customer expectations'
  }, {
    value: 3,
    label: 'Item was damaged or defective'
  }, {
    value: 4,
    label: 'Delivery problem (e.g. customer refused or undeliverable'
  }]
}

export const merchantReasons = {
  label: 'Return Reason',
  key: 'reason_id',
  icon: 'pulse',
  tooltip: 'Limit results based on the cancel reason.',
  options: [{
    value: 5,
    label: 'Customer Disposition'
  }, {
    value: 6,
    label: 'Item did not meet customer expectations'
  }, {
    value: 7,
    label: 'Item was damaged or defective'
  }, {
    value: 8,
    label: 'Delivery problem (e.g. customer refused or undeliverable'
  }]
}

export const acknowledged = {
  label: 'Acknowledged?',
  key: 'acknowledged',
  icon: 'pulse',
  tooltip: 'Limit results based on acknowledgement.',
  options: [{
    value: 1,
    label: 'Yes'
  }, {
    value: 0,
    label: 'No'
  }]
}

export const createdAt = {
  label: 'Created At',
  key: 'created_at',
  icon: 'pulse',
  tooltip: 'Limit results based on when the return was registered.',
  options: dates
}
