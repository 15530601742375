<template>
  <form @submit.prevent="save">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Add Shipping Account</p>
      </header>
      <section class="modal-card-body">
        <!-- Carriers Loaded -->
        <div v-if="!loadingCarriers && carriers.length > 0">
          <!-- General Errors -->
          <b-message v-if="'general' in errors" type="is-danger">
            {{ errors.general }}
          </b-message>
          <!-- Nickname -->
          <b-field label="Shipping Account Nickname">
            <b-input
              type="text"
              v-model="nickname"
              size="is-medium"
            ></b-input>
          </b-field>
          <!-- Select Carrier -->
          <b-field label="Carrier">
            <b-select
              v-model="carrier"
              size="is-medium"
              placeholder="Select a carrier..."
              expanded>
              <option v-for="carrier in carriers" :key="carrier.id" :value="carrier">{{ carrier.name }}</option>
            </b-select>
          </b-field>
        </div>
        <div v-else>
          <loading message="Loading carriers..." />
        </div>
      </section>
      <footer class="modal-card-foot space-between">
        <button class="button" type="button" @click="$emit('cancel')">Close</button>
        <b-button
          type="is-primary"
          native-type="submit"
          :loading="saving"
          :disabled="saving || !ready">
          Save &amp; Continue
        </b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['api', 'context']),
    endpoint () {
      return this.context.route + '/shippingaccounts/easypost-child-account/'
    },
    ready () {
      return (this.nickname && this.carrier)
    }
  },
  data () {
    return {
      saving: false,
      errors: {},
      loadingCarriers: true,
      carriers: [],
      nickname: null,
      carrier: null
    }
  },
  methods: {
    loadCarriers () {
      this.loadingCarriers = true
      const endpoint = this.api.baseUrl + '/v1/carriers/?is_common=1'
      this.$http.get(endpoint).then(response => {
        this.carriers = response.data.results
      }).finally(() => {
        this.loadingCarriers = false
      })
    },
    save () {
      this.$emit('child:created', {
        carrier: this.carrier,
        nickname: this.nickname,
        apiKeys: ''
      })
      // this.saving = true
      // this.$http.post(this.endpoint).then(response => {
      //   this.$emit('child:created', {
      //     carrier: this.carrier,
      //     nickname: this.nickname,
      //     apiKeys: response.data
      //   })
      // }).catch(error => {
      //   if (error.response && error.response.status === 400) {
      //     if ('error' in error.response.data) {
      //       this.errors.general = 'This action is only available in the production environment.'
      //     }
      //   }
      //   this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      // }).finally(() => {
      //   this.saving = false
      // })
    }
  },
  mounted () {
    this.loadCarriers()
  }
}
</script>
