<template>
  <div>
    <div class="section">
      <div class="container">
        <!-- Page Header -->
        <div class="columns is-vcentered">
          <div class="column is-one-third">
            <breadcrumbs :history="[{ label: 'Settings', name: 'settings'}]" current="Tracking History" :hideBack="true" />
          </div>
          <div class="column is-one-third">
            <h1 class="has-text-centered is-size-4">Tracking History</h1>
          </div>
        </div>
        <!-- List -->
        <div>
          <!-- Results Found -->
          <div v-if="!loading && results.length > 0">
            <b-table
              :data="results"
              :mobile-cards="false"
              :loading="loading"
              class="mb-lg rounded-lg is-size-7">
              <template>
                <b-table-column
                  label="PO #"
                  v-slot="props">
                  <p class="has-text-grey">{{ props.row.shipment.purchase_order_number }}</p>
                </b-table-column>
                <b-table-column
                  label="Shipment ID"
                  v-slot="props">
                  <a href="#"
              class="has-text-weight-bold has-text-info"
              @click.prevent="inspectShipment(props.row.shipment)">
              <p>{{ props.row.shipment.id }}</p>
            </a>
                </b-table-column>
                <b-table-column
                  label="Old Tracking Number"
                  v-slot="props">
                  <p class="has-text-grey">{{ props.row.old_tracking_number }}</p>
                </b-table-column>
                <b-table-column
                  label="New Tracking Number"
                  v-slot="props">
                  <p class="has-text-grey">{{ props.row.new_tracking_number }}</p>
                </b-table-column>
                <b-table-column
                  label="Changed on"
                  v-slot="props">
                  <p class="has-text-grey">{{ $dates.utc(props.row.created_at).local().format('MMM D, YYYY @ h:mm A') }}</p>
                </b-table-column>
                <b-table-column
                  label="Acknowledged"
                  v-slot="props">
                  <p v-if="props.row.acknowledged_at != null">
                  {{ $dates.utc(props.row.acknowledged_at).local().format('MMM D, YYYY @ h:mm A') }}
                </p>
                <p v-else>
                  <b-button
                    :type="type"
                    :loading="loading"
                    :disabled="disabled"
                    @click="handleAcknowledge(props.row.id)"
                    >
                    Acknowledge
                  </b-button>
                </p>
                </b-table-column>
              </template>
            </b-table>
          </div>
          <!-- None Found  -->
          <div v-else-if="!loading && results.length === 0">
            <div class="has-background-white has-text-grey-light has-text-centered py-3xl rounded-xl">
              <p><b-icon icon="magnify-close" size="is-large"></b-icon></p>
              <p>No histories found.</p>
            </div>
          </div>
          <!-- Loading -->
          <div v-else>
            <div class="has-background-white pa-lg mb-xs rounded-lg" v-for="index in 2" :key="index">
              <div class="placeholder placeholder-sentence"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
     <!-- Inspect Modal -->
  <b-modal
      v-if="(focusShipment !== null)"
      has-modal-card
      :active.sync="showInspectShipmentModal"
      :can-cancel="['escape', 'x']"
      @close="closeModals"
      ><ShipmentModal :shipment="focusShipment" />
    </b-modal>
  </div>
  </template>

<script>
import { mapGetters } from 'vuex'
import ShipmentModal from '@/components/Modals/Shipment/ShipmentModal'
export default {
  components: {
    ShipmentModal
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/shipment-tracking-history/?order_by=-id'
    }
  },
  data () {
    return {
      loading: true,
      results: [],
      errors: {},
      showInspectShipmentModal: false,
      focusShipment: null
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint).then(response => {
        this.results = response.data.results
        this.loading = false
      })
    },
    inspectShipment (shipment) {
      this.focusShipment = shipment
      this.showInspectShipmentModal = true
    },
    closeModals () {
      this.focusShipment = null
      this.showInspectShipmentModal = false
    },
    handleAcknowledge (id) {
      this.ackEndpoint = this.context.route + '/shipment-tracking-history/' + id + '/acknowledge/'
      this.$http.put(this.ackEndpoint).then(response => {
        this.results = response.data.results
        this.load()
      })
    }
  },
  mounted () {
    this.load()
  },
  props: []
}
</script>
