import dates from '@/assets/js/dates'

export const verticals = {
  label: 'Verticals',
  key: 'vertical_id',
  icon: 'tag-outline',
  tooltip: 'Filter suppliers by vertical.'
}

export const createdAt = {
  label: 'Created At',
  key: 'created_at',
  icon: 'calendar-outline',
  tooltip: 'Filter suppliers based the date and time they were created.',
  options: dates
}
