<template>
  <div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">
          <span v-if="!loading && connection !== null">{{ partner.name }}</span>
          <span v-else>Loading...</span>
        </p>
      </header>
      <section class="modal-card-body">
        <!-- Connection Found -->
        <div v-if="!loading && connection !== null" class="mb-lg">
          <div class="columns">
            <div class="column">
              <p class="has-text-primary has-text-weight-bold pb-sm has-border-b has-border-lg">Connection Info</p>
              <table class="table is-fullwidth is-striped is-size-7 is-narrow">
                <tbody>
                  <tr>
                    <th>{{ partner.label }} Name</th>
                    <td class="has-text-right">{{ partner.name }}</td>
                  </tr>
                  <tr>
                    <th>{{ partner.label }} ID</th>
                    <td class="has-text-right">{{ partner.id }}</td>
                  </tr>
                  <tr>
                    <th>Connection ID</th>
                    <td class="has-text-right">{{ connection.id }}</td>
                  </tr>
                  <tr>
                    <th>Integration</th>
                    <td class="has-text-right">{{ integration.label }}</td>
                  </tr>
                  <tr>
                    <th>Established</th>
                    <td class="has-text-right">{{ $dates.utc(connection.created_at).local().format('MMM D, YYYY')}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- Connection Contacts -->
          <div class="mb-lg" v-if="connection.connection_contacts.length > 0">
            <p class="has-text-primary has-text-weight-bold pb-sm has-border-b has-border-lg">Connection Contacts</p>
            <table class="table is-fullwidth is-striped is-size-7 is-narrow is-vcentered">
              <tr v-for="contact in connection.connection_contacts" :key="contact.id">
                <td>
                  <p>{{ contact.first_name }} {{ contact.last_name }}</p>
                  <p class="has-text-grey-light">{{ contact.email }}</p>
                </td>
                <td class="has-text-right">{{ $utils.upperFirst(contact.contact_type) }}</td>
              </tr>
            </table>
          </div>
          <!-- Connection Options -->
          <div>
            <p class="has-text-primary has-text-weight-bold pb-sm has-border-b has-border-lg">Connection Options</p>
            <table class="table is-fullwidth is-striped is-size-7 is-narrow">
              <tbody v-if="!$utils.isEmpty(connection.options)">
                <tr v-for="option in connection.options" :key="option.id">
                  <td>&check; {{ option.name }}</td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td class="has-text-grey has-text-centered">
                    <p class="pa-md">No custom configuration options found.</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- Connection Not Found -->
        <div v-else-if="!loading && connection === null">
          <p>Connection could not be found.</p>
        </div>
        <!-- Connection Loading -->
        <div v-else class="py-lg">
          <loading />
        </div>
      </section>
      <footer class="modal-card-foot">
        <button class="button" type="button" @click="$parent.close()">Close</button>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ConnectionPresenters from '@/mixins/presenters/Connection'
export default {
  components: {

  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/connections/' + this.seed.id + '/'
    },
    partner () {
      if (this.connection === null) return null
      if (this.context.type === 'vendor') {
        return {
          label: 'Merchant',
          id: this.connection.retailer.id,
          name: this.connection.retailer.name
        }
      } else {
        return {
          label: 'Supplier',
          id: this.connection.brand.id,
          name: this.connection.brand.name
        }
      }
    }
  },
  data () {
    return {
      loading: false,
      connections: [],
      connection: null
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint).then(response => {
        this.connection = response.data
      }).finally(() => {
        this.loading = false
      })
    }
  },
  mixins: [ConnectionPresenters],
  mounted () {
    this.load()
  },
  props: ['seed']
}
</script>
