<template>
  <div>
    <div class="section">
      <div class="container">
        <div class="has-text-centered mt-xxl mb-xl">
          <!-- Fabric Logo -->
          <p v-if="app.platformCode === 'fabric'">
            <FabricLogo color="black" />
          </p>
          <!-- RC Logo -->
          <p v-else>
            <RevCascadeLogo />
          </p>
        </div>
        <div class="columns is-centered">
          <div class="column is-half">
            <div class="has-background-white rounded-lg pa-xl">
              <transition name="fade" mode="out-in">
                <router-view></router-view>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FabricLogo from '@/components/Logos/Fabric'
import RevCascadeLogo from '@/components/Logos/RevCascade'

export default {
  components: {
    FabricLogo,
    RevCascadeLogo
  },
  computed: {
    ...mapGetters(['app'])
  }
}
</script>
