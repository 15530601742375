<template>
  <div>
    <form @submit.prevent="save">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Activate Connection</p>
        </header>
        <section class="modal-card-body">
          <p class="has-text-centered py-xl">Are you sure you are ready to activate this connection?</p>
        </section>
        <footer class="modal-card-foot space-between">
          <b-button @click="$parent.close()">Close</b-button>
          <b-button
            type="is-primary"
            native-type="submit"
            :loading="saving"
            :disabled="saving">
            Activate Connection
          </b-button>
        </footer>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/connections/' + this.connection.id + '/'
    },
    body () {
      return {
        status: 'active'
      }
    }
  },
  data () {
    return {
      saving: false
    }
  },
  methods: {
    save () {
      this.saving = true
      this.$http.patch(this.endpoint, this.body).then(response => {
        this.$emit('activated:connection')
        this.$buefy.toast.open({ type: 'is-success', message: 'Connection activated!' })
        this.$parent.close()
      }).finally(() => {
        this.saving = false
      })
    }
  },
  props: {
    connection: {
      type: Object,
      required: true
    }
  }
}
</script>
