<template>
  <div class="columns is-centered is-multiline">
    <!-- Review -->
    <div class="column is-half is-one-third-desktop is-one-quarter-widescreen">
      <router-link :to="{ name: 'proposals.dashboard' }">
        <div class="has-background-white rounded-lg" style="height:250px">
          <div class="has-text-centered has-text-grey-lighter pt-xxl">
            <b-icon icon="tag-outline" custom-class="mdi-96px"></b-icon>
          </div>
          <div class="has-text-centered pa-md">
            <p class="has-text-weight-bold mb-sm">Review Proposals</p>
            <p class="has-text-grey-light is-size-7">Review <span class="has-text-info">products</span> and <span class="has-text-info">pricing</span> from
            <br/>
            {{ partner }}</p>.
          </div>
        </div>
      </router-link>
    </div>
    <!-- Browse -->
    <div class="column is-half is-one-third-desktop is-one-quarter-widescreen">
      <router-link :to="{ name: 'catalog.browse' }">
        <div class="has-background-white rounded-lg" style="height:250px">
          <div class="has-text-centered has-text-grey-lighter pt-xxl">
            <b-icon icon="format-list-bulleted-square" custom-class="mdi-96px"></b-icon>
          </div>
          <div class="has-text-centered pa-md">
            <p class="has-text-weight-bold mb-sm">Browse</p>
            <p class="has-text-grey-light is-size-7">Review products &amp; items that have already been imported.</p>
          </div>
        </div>
      </router-link>
    </div>
    <!-- Import -->
    <div class="column is-half is-one-third-desktop is-one-quarter-widescreen">
      <router-link :to="{ name: 'catalog.import' }">
        <div class="has-background-white rounded-lg" style="height:250px">
          <div class="has-text-centered has-text-grey-lighter pt-xxl">
            <b-icon icon="cloud-upload-outline" custom-class="mdi-96px"></b-icon>
          </div>
          <div class="has-text-centered pa-md">
            <p class="has-text-weight-bold mb-sm">Import</p>
            <p class="has-text-grey-light is-size-7">
              Import <span class="has-text-info">products</span> or <span class="has-text-info">inventory</span>
              into {{ app.productName }}.
            </p>
          </div>
        </div>
      </router-link >
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['app']),
    partner () {
      if (this.connections.length === 1) {
        return this.connections[0].brand.name
      }
      return 'your brand partners'
    }
  },
  props: {
    connections: {
      type: Array,
      required: true
    },
    platformAccounts: {
      type: Array,
      required: true
    }
  }
}
</script>
