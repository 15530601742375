<template>
  <div>
    <span>{{ presenter.label }}</span>
  </div>
</template>

<script>
export default {
  computed: {
    presenter () {
      const presenter = {}
      switch (this.connection.connection_fees.payment_terms) {
        case 'net7':
          presenter.label = 'Net 7'
          break
        case 'net15':
          presenter.label = 'Net 15'
          break
        case 'net30':
          presenter.label = 'Net 30'
          break
        case 'net45':
          presenter.label = 'Net 45'
          break
        case 'net60':
          presenter.label = 'Net 60'
          break
        case 'net90':
          presenter.label = 'Net 90'
          break
        default:
          presenter.label = 'Not Set'
      }
      return presenter
    }
  },
  props: {
    connection: {
      type: Object,
      required: true
    },
    showTooltip: {
      type: Boolean,
      default: true
    }
  }
}
</script>
