<template>
  <div class="section">
    <div class="container">
      <!-- Page Header -->
      <div class="mb-md">
        <div class="columns is-vcentered">
          <div class="column is-one-third">
            <breadcrumbs :history="breadcrumbs.history" :current="breadcrumbs.current" />
          </div>
          <div class="column is-one-third">
            <h1 class="has-text-centered is-size-4">Order Create</h1>
          </div>
        </div>
      </div>
      <!-- Error Display -->
      <b-message v-if="!saving && 'non_field_errors' in errors" type="is-danger">
        {{ errors.non_field_errors[0] }}
      </b-message>
      <!-- Form is passed to all individual child components and updates the payload object in this component -->
      <!-- Order info -->
      <div class="border-b pb-lg mb-lg">
        <OrderInfo v-model="form" :errors="errors"/>
      </div>
      <!-- Address -->
      <div class="border-b pb-lg mb-lg">
        <OrderAddress v-model="form" :payload="payload"/>
      </div>
      <!-- Shipping method -->
      <div class="border-b pb-lg mb-lg">
        <OrderShippingMethod v-model="form"/>
      </div>
      <!-- Notes -->
      <div v-if="context.isMerchant()" class="border-b pb-lg mb-lg">
        <Notes v-model="form"/>
      </div>
      <!-- Order Lines -->
      <div class="border-b pb-lg mb-lg">
        <OrderLines v-model="form"/>
      </div>
      <!-- Create button -->
      <div class="columns">
        <div class="column is-one-fifth"></div>
        <div class="column">
          <b-button size="is-medium" type="is-primary" @click="onCreate" :disabled="!isReady" :loading="saving">Create Order</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import OrderInfo from '../components/Create/OrderInfo'
import OrderAddress from '../components/Create/OrderAddress'
import OrderShippingMethod from '../components/Create/OrderShippingMethod'
import Notes from '../components/Create/Notes'
import OrderLines from '../components/Create/OrderLines'

export default {
  components: {
    OrderInfo,
    OrderAddress,
    OrderShippingMethod,
    OrderLines,
    Notes
  },
  computed: {
    ...mapGetters(['context']),
    breadcrumbs () {
      return {
        current: 'Create',
        history: [
          { label: 'Orders', name: 'orders.dashboard' },
          { label: 'List', name: 'orders.list' }
        ]
      }
    },
    endpoint () {
      return this.context.route + '/orders/'
    }
  },
  data: () => {
    return {
      payload: {},
      form: null,
      isReady: false,
      saving: false,
      errors: {}
    }
  },
  methods: {
    async onCreate () {
      this.saving = true
      const json = {
        purchase_order_number: this.payload.purchaseOrderNo,
        customer_order_number: this.payload.customerOrderNo,
        retailer_order_number: this.payload.retailerOrderNo,
        ordered_at: new Date(this.payload.orderDate).toISOString(),
        ship_to: this.payload.shipTo.address,
        requested_shipping_method_id: this.payload.shippingMethod.id,
        order_lines: this.payload.orderLines.map(el => {
          const res = {
            quantity: el.quantity,
            price: el.price,
            order_line_number: el.orderLineNo,
            variant: {
              id: el.variant.id
            }
          }
          if (el.netPrice) {
            res.net_price = el.netPrice
          }
          return res
        }),
        memos: [
          {
            text: this.payload.message
          }
        ]
      }
      this.errors = {}
      try {
        await this.$http.post(this.endpoint, json)
        this.$buefy.toast.open({ type: 'is-success', message: 'Order Created' })
        this.$router.push({ name: 'orders.list' })
      } catch (error) {
        this.errors = (error.response.data) ? error.response.data : { detail: 'An unexpected error occurred!' }
      } finally {
        this.saving = false
      }
    }
  },
  mounted () {},
  props: [],
  watch: {
    form (value) {
      this.payload[Object.keys(value)[0]] = Object.values(value)[0]
      if (this.payload.orderDate &&
        this.payload.purchaseOrderNo &&
        this.payload.shipTo &&
        this.payload.shippingMethod &&
        this.payload.orderLines.length) {
        this.isReady = true
      } else {
        this.isReady = false
      }
    }
  }
}
</script>
