<template>
  <div>
    <!-- Profile Image -->
    <div class="rounded-t-lg">
      <router-link :to="{ name: 'discover.profile', params: {id: marketplace.id, slug: marketplace.brand.code }, query: { source: 'connections'} }">
        <figure class="image is-square">
          <img class="rounded-t-lg" :src="profilePhoto.cdnUrl">
        </figure>
      </router-link>
    </div>
    <!-- Title Box -->
    <div class="has-background-white pa-md rounded-b-lg">
      <p class="has-text-weight-bold">{{ marketplace.brand.name }}</p>
      <p class="has-text-grey is-size-7">{{ verticals }}</p>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MarketplacePresenters from '@/mixins/presenters/Marketplace'
export default {
  components: {

  },
  computed: {
    ...mapGetters(['context'])
  },
  data () {
    return {

    }
  },
  methods: {

  },
  mounted () {

  },
  mixins: [MarketplacePresenters],
  props: ['marketplace']
}
</script>
