<template>
  <div class="section">
    <div class="container">
      <!-- Page Header -->
      <div class="columns is-vcentered">
        <div class="column is-one-third">
          <breadcrumbs :history="[{ label: 'Catalog', name: context.isSupplier() ? 'catalog' : 'catalog.index' }]" current="Utilities" :hideBack="true" />
        </div>
        <div class="column is-one-third">
          <h1 class="has-text-centered is-size-4">Catalog Utilities</h1>
        </div>
      </div>
      <!-- Step 1: Select action -->
      <div class="mb-lg pb-lg has-border-b">
        <div class="columns">
          <div class="column is-one-fifth">
            <p class="has-text-weight-bold is-size-6 mb-sm">Step 1: Select your action</p>
            <p class="has-text-grey">Action you want to perform on your items.</p>
          </div>
          <div class="column">
            <div class="has-background-white rounded-xl pa-xl">
              <div>
                <b-field label="Action">
                  <b-select
                    v-model="selectedAction"
                    placeholder="Select Action"
                    required
                    expanded>
                    <option value="import_offers">Import Offers</option>
                    <option value="revoke_permit">Revoke Permit</option>
                    <option value="delete_skus">Delete SKUs</option>
                  </b-select>
                </b-field>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Step 2: Select Connection, this step is skipped for delete_skus option -->
      <div v-if="selectedAction && selectedAction !== 'delete_skus'" class="mb-lg pb-lg has-border-b">
        <div class="columns">
          <div class="column is-one-fifth">
            <p class="has-text-weight-bold is-size-6 mb-sm">Step 2: Select Merchant</p>
            <p class="has-text-grey">{{ getSelectMerchantSubHeading }}</p>
          </div>
          <div class="column">
            <div class="has-background-white rounded-xl pa-xl">
              <p class="mb-lg">
                {{ getSelectMerchantCardHeading }}
              </p>
              <div class="mb-xl">
                <b-field label="Connection">
                  <ConnectionSelect v-model="connection" />
                </b-field>
              </div>
              <div v-if="connection !== null">
                <b-message type="is-success">
                  This partner allows you to upload offers.
                </b-message>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Step 3: Download Template, update step count for delete_skus -->
      <div v-if="selectedAction" class="mb-lg pb-lg has-border-b">
        <div class="columns">
          <div class="column is-one-fifth">
            <p class="has-text-weight-bold is-size-6 mb-sm">Step {{ selectedAction === 'delete_skus' ? 2 : 3 }}: Download Template</p>
            <p class="has-text-grey">{{ getDownloadTemplateSubHeading }}</p>
          </div>
          <div class="column">
            <div class="has-background-white rounded-xl pa-xl">
              <!-- Instructions -->
              <div class="mb-lg">
                <div class="columns is-vcentered">
                  <div class="column">
                    <p v-if="selectedAction === 'import_offers'">
                      The offer template allows you to specify 5 columns. Fields
                      denoted with an asterisk (<b class="has-text-danger">*</b>) are required.
                    </p>
                    <p v-else>This template allows you to specify only SKU. Fields
                      denoted with an asterisk (<b class="has-text-danger">*</b>) are required.</p>
                  </div>
                  <div class="column is-narrow">
                    <b-button type="is-primary is-fullwidth is-outlined rounded-lg" @click="downloadTemplate">Download Template</b-button>
                  </div>
                </div>
              </div>
              <!-- Fields -->
              <div>
                <!-- Item SKU -->
                <div class="has-background-white-ter pa-md rounded-lg mb-sm">
                  <b-collapse :open="false">
                    <a href="#" @click.prevent slot="trigger">
                      <div class="columns is-vcentered is-mobile">
                        <div class="column">
                          <span class="has-text-weight-bold">Item SKU</span>
                          <b class="has-text-danger"> * </b>
                        </div>
                        <div class="column is-narrow">
                          <p>Learn More</p>
                        </div>
                      </div>
                    </a>
                    <div class="pt-sm">
                      Your unique identifier for the the item.
                    </div>
                  </b-collapse>
                </div>
                <!-- Cost -->
                <div v-if="selectedAction === 'import_offers'" class="has-background-white-ter pa-md rounded-lg mb-sm">
                  <b-collapse :open="false">
                    <a href="#" @click.prevent slot="trigger">
                      <div class="columns is-vcentered is-mobile">
                        <div class="column">
                          <span class="has-text-weight-bold">Merchant Cost</span>
                          <b class="has-text-danger"> * </b>
                        </div>
                        <div class="column is-narrow">
                          <a href="#" >Learn More</a>
                        </div>
                      </div>
                    </a>
                    <div class="pt-sm">
                      In the Commerce Network, suppliers pay upfront
                      for shipping labels, but are later reimbursed for the shipping
                      & handling fee set per item. The "Dropship Cost" represents
                      your "all-in" cost to sell <u>AND</u> ship this item anywhere
                      via domestic ground shipping. This cost Should also include any
                      order processing fees (sometimes added by 3PLs).
                    </div>
                  </b-collapse>
                </div>
                <!-- Retail -->
                <div v-if="selectedAction === 'import_offers'" class="has-background-white-ter pa-md rounded-lg mb-sm">
                  <b-collapse :open="false">
                    <a href="#" @click.prevent slot="trigger">
                      <div class="columns is-vcentered is-mobile">
                        <div class="column">
                          <span class="has-text-weight-bold">Suggested Retail Price</span>
                          <i class="has-text-grey is-size-7"> optional </i>
                        </div>
                        <div class="column is-narrow">
                          <a href="#" >Learn More</a>
                        </div>
                      </div>
                    </a>
                    <div class="pt-sm">
                      The suggested retail price. Merchants may choose to make
                      adjustments to the retail price in accordance with your
                      supplier agreement. {{ app.platform }} does not monitor retail
                      price.
                    </div>
                  </b-collapse>
                </div>
                <!-- Start Date -->
                <div v-if="selectedAction === 'import_offers'" class="has-background-white-ter pa-md rounded-lg mb-sm">
                  <b-collapse :open="false">
                    <a href="#" @click.prevent slot="trigger">
                      <div class="columns is-vcentered is-mobile">
                        <div class="column">
                          <span class="has-text-weight-bold">Start Date</span>
                          <i class="has-text-grey is-size-7"> optional </i>
                        </div>
                        <div class="column is-narrow">
                          <a href="#" >Learn More</a>
                        </div>
                      </div>
                    </a>
                    <div class="pt-sm">
                      The date on which the cost becomes effective. It is recommended
                      to leave this column blank (which will default to make the cost
                      effective immediately)
                    </div>
                  </b-collapse>
                </div>
                <!-- End Date -->
                <div v-if="selectedAction === 'import_offers'" class="has-background-white-ter pa-md rounded-lg">
                  <b-collapse :open="false">
                    <a href="#" @click.prevent slot="trigger">
                      <div class="columns is-vcentered is-mobile">
                        <div class="column">
                          <span class="has-text-weight-bold">End Date</span>
                          <i class="has-text-grey is-size-7"> optional </i>
                        </div>
                        <div class="column is-narrow">
                          <a href="#" >Learn More</a>
                        </div>
                      </div>
                    </a>
                    <div class="pt-sm">
                      The date after which the offer becomes invalid. It is highly
                      recommended to leave this column blank (which will not
                      set an end date) as orders cannot be placed on items
                      with invalid offers.
                    </div>
                  </b-collapse>
                </div>
                <p class="has-text-grey-light is-size-7 mt-md">Note: Exclude the headers before uploading.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Step 4: Upload File, update step count for delete_skus -->
      <div v-if="selectedAction" class="mb-lg pb-lg">
        <div class="columns">
          <div class="column is-one-fifth">
            <p class="has-text-weight-bold is-size-6 mb-sm">Step {{ selectedAction === 'delete_skus' ? 3 : 4 }}: Upload File</p>
            <p class="has-text-grey">{{ getUploadFileSubHeading }}</p>
          </div>
          <div class="column">
            <div class="has-background-white rounded-xl">
              <div class="pa-xl">
                <!-- Upload -->
                <CoreUpload @file:uploaded="updateTempfile" @file:reset="resetTempfile"/>
              </div>
              <div class="has-border-t">
                <div class="columns is-vcentered is-marginless">
                  <div class="column">
                    <div class="px-sm has-text-grey-light">
                      <i v-if="tempfile !== null">Click 'Import File' to upload your file for processing.</i>
                    </div>
                  </div>
                  <div class="column is-narrow">
                    <form @submit.prevent="queueFile">
                      <div class="px-sm">
                        <b-button
                          native-type="submit"
                          type="is-primary"
                          :loading="scheduling"
                          :disabled="!ready || scheduling"
                        >Import File</b-button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CoreUpload from '@/components/CoreUpload'
import ConnectionSelect from '@/components/Models/Connection/ConnectionSelect'
export default {
  components: {
    CoreUpload,
    ConnectionSelect
  },
  computed: {
    ...mapGetters(['app', 'assets', 'context']),
    importOffersEndpoint () {
      return this.context.route + '/permits/bulk/'
    },
    deleteSkusEndpoint () {
      return this.context.route + '/variants/bulk-delete/'
    },
    ready () {
      if (this.selectedAction === 'delete_skus') {
        return this.tempfile !== null
      } else {
        return this.connection !== null && this.tempfile !== null
      }
    },
    json () {
      if (this.selectedAction === 'delete_skus') {
        return {
          tempfile_id: this.tempfile.id
        }
      } else {
        const body = {
          retailers: [this.connection.retailer.id],
          tempfile_id: this.tempfile.id
        }
        if (this.selectedAction === 'import_offers') {
          body.require_cost = 1
        }
        if (this.selectedAction === 'revoke_permit') {
          body.revoke = 1
        }
        return body
      }
    },
    getSelectMerchantSubHeading () {
      let text = 'Offers that you upload will only be applied to the selected merchant partner.'
      if (this.selectedAction === 'revoke_permit') {
        text = 'Items that you import will only be revoked permit from the selected merchant partner'
      }
      return text
    },
    getSelectMerchantCardHeading () {
      let text = `When you upload "offers" to ${this.app.platform}, you are granting permission
                to the selected merchant partner to sell your items at the
                costs you specify in your upload. To begin, please select a merchant partner
                to verify that your partner allows you to update costs.`
      if (this.selectedAction === 'revoke_permit') {
        text = `When you upload "item SKUs" to ${this.app.platform}, you are revoking permission to the selected
        merchant partner to sell your items. To begin, please select a merchant partner to`
      }
      return text
    },
    getDownloadTemplateSubHeading () {
      let text = `Download a pricing template to import your pricing into ${this.app.platform}.`
      if (this.selectedAction === 'revoke_permit') {
        text = 'Download a template to revoke permits from your retailer.'
      } else if (this.selectedAction === 'delete_skus') {
        text = 'Download a template to delete the desired SKUs.'
      }
      return text
    },
    getUploadFileSubHeading () {
      let text = 'Select and upload your pricing updated pricing file.'
      if (this.selectedAction === 'revoke_permit') {
        text = 'Select and upload your item SKU file to revoke permits.'
      } else if (this.selectedAction === 'delete_skus') {
        text = 'Select and upload your item SKU file to to delete them within the system.'
      }
      return text
    }
  },
  data () {
    return {
      connection: null,
      tempfile: null,
      scheduling: false,
      exporting: false,
      selectedAction: null
    }
  },
  methods: {
    queueFile () {
      this.scheduling = true
      const endpoint = ['revoke_permit', 'import_offers'].includes(this.selectedAction) ? this.importOffersEndpoint : this.deleteSkusEndpoint
      this.$http.post(endpoint, this.json).then(response => {
        this.scheduling = false
        this.$buefy.toast.open({ type: 'is-success', message: 'File scheduled!' })
      })
    },
    updateTempfile (file, response) {
      this.tempfile = response
    },
    resetTempfile () {
      this.tempfile = null
    },
    downloadTemplate () {
      const link = document.createElement('a')
      if (this.selectedAction === 'import_offers') {
        link.href = this.assets.templates.import.pricing.sample
        link.download = 'ImportPricingTemplate.xlsx'
      } else {
        const headers = ['Supplier SKU']
        const csvContent = headers.join(',')
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
        link.href = URL.createObjectURL(blob)
        link.download = 'SKUTemplate'
      }
      link.click()
    }
  }
}
</script>
