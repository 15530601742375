<template>
<div>
  <div class="pa-md">
    <!-- Task is loaded -->
    <div v-if="!loading && task !== null">
      <p class="has-text-centered has-text-weight-bold mb-lg is-size-5">ShipStation Integration</p>
      <div class="columns is-centered">
        <div class="column is-half-widescreen">
          <div class="box">
            <transition name="fade" mode="out-in">
              <component
                :is="step"
                :connection="connection"
                :task="task"
                :account="account"
                @platform:added="loadPlatformAccount(true)"
                @platform:updated="loadPlatformAccount(true)"
                @navigate="navigate"
              ></component>
            </transition>
            <!-- Cancel Task Option -->
            <div>
              <p class="has-text-grey-light has-text-centered is-size-7">
                This onboarding task was added based on your preferences. <br/>
                If you no longer need this task, you can remove it
                by <a href="#" @click.prevent="showDeleteTaskModal = true" class="has-text-info">clicking here.</a>
              </p>
            </div>
          </div>
          <!-- Return Link -->
          <div class="has-text-centered">
            <router-link :to="{ name: 'onboarding.tasks', params: { connection: this.$route.params.connection }}" class="is-size-7">
              Back to Tasks
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <!-- Loading Skeleton -->
    <div v-else>
      <div class="mb-md">
        <div class="columns is-centered">
          <div class="column is-one-quarter">
            <b-skeleton />
          </div>
        </div>
      </div>
      <div class="columns is-centered">
        <div class="column is-half">
          <div class="box">
            <loading message="Loading..." />
          </div>
          <!-- Return Link -->
          <div class="has-text-centered">
            <router-link :to="{ name: 'onboarding.tasks', params: { connection: this.$route.params.connection }}" class="is-size-7">
              Back to Tasks
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Delete Task Modal -->
  <b-modal
    v-if="!loading && task !== null"
    :has-modal-card="true"
    :active.sync="showDeleteTaskModal"
    :can-cancel="['x', 'esc']">
    <RemoveTaskModal :taskId="task.id" @task:removed="returnToTasks" />
  </b-modal>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import RemoveTaskModal from '@/components/Models/Task/Modals/RemoveTaskModal'
import Intro from '../components/ShipStation/Intro'
import StoreSetup from '../components/ShipStation/StoreSetup'
import Tags from '../components/ShipStation/Tags'
import Shipments from '../components/ShipStation/Shipments'
import Activate from '../components/ShipStation/Activate'
import Confirmation from '../components/ShipStation/Confirmation'

export default {
  components: {
    RemoveTaskModal,
    Intro,
    StoreSetup,
    Tags,
    Shipments,
    Activate,
    Confirmation
  },
  computed: {
    ...mapGetters(['app', 'context']),
    loading () {
      return (this.loadingTask || this.loadingPlatformAccounts)
    }
  },
  data () {
    return {
      loadingTask: true,
      loadingPlatformAccounts: true,
      task: null,
      account: null,
      step: 'Intro',
      showDeleteTaskModal: false
    }
  },
  methods: {
    loadTask () {
      this.loadingTask = true
      const endpoint = this.context.route + '/assigned-tasks/' + this.$route.params.task + '/'
      this.$http.get(endpoint).then(response => {
        this.task = response.data
        this.loadingTask = false
      })
    },
    loadPlatformAccount (quiet = false) {
      if (!quiet) this.loadingPlatformAccounts = true
      const endpoint = this.context.route + '/platform-accounts/?code=shipstation'
      this.$http.get(endpoint).then(response => {
        if (response.data.count > 0) {
          this.account = response.data.results[0]
        }
        this.loadingPlatformAccounts = false
      })
    },
    returnToTasks () {
      this.$router.push({ name: 'onboarding.tasks', params: { connection: this.$route.params.connection } })
      this.$buefy.toast.open({ type: 'is-info', message: 'Task removed.' })
    },
    navigate (step) {
      this.step = step
    }
  },
  mounted () {
    this.loadTask()
    this.loadPlatformAccount()
  },
  props: ['connection']
}
</script>
