<template>
  <div class="section">
    <div class="container">
      <!-- Page Header -->
      <div class="mb-md">
        <div class="columns is-vcentered">
          <div class="column is-one-third">
            <breadcrumbs :history="[{ label: 'Settings', name: 'settings'}]" current="Webhooks" />
          </div>
          <div class="column is-one-third">
            <h1 class="has-text-centered is-size-4">Webhooks</h1>
          </div>
        </div>
      </div>
      <!-- Webhooks Config -->
      <div class="pb-lg mb-lg border-b">
        <WebhookSetup />
      </div>
      <!-- Webhooks Config -->
      <div class="pb-lg mb-lg">
        <WebhookHistory />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import WebhookSetup from '../components/Webhooks/Setup'
import WebhookHistory from '../components/Webhooks/History'

export default {
  components: {
    WebhookSetup,
    WebhookHistory
  },
  data () {
    return {

    }
  },
  computed: {
    ...mapGetters(['context'])
  },
  methods: {

  },
  mounted () {

  },
  props: []
}
</script>
