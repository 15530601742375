import List from './views/List'
import Items from './views/Items'
import Connections from './views/Connections'

export default [
  {
    path: '/settings/categories',
    name: 'categories',
    component: List
  },
  {
    path: '/settings/categories/:connectionId',
    name: 'categories.connection',
    component: List
  },
  {
    path: '/settings/categories/connections',
    name: 'connection',
    component: Connections
  },
  {
    path: '/settings/categories/items/:id',
    name: 'category.items',
    component: Items
  },
  {
    path: '/settings/categories/:connectionId/items/:id',
    name: 'category.connection.items',
    component: Items
  }
]
