<template>
  <div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Update Rule</p>
      </header>
      <section class="modal-card-body">
        <div v-if="errors && 'non_field_errors' in errors" class="mb-lg">
          <b-message type="is-danger">{{ errors.non_field_errors[0] }}</b-message>
        </div>
        <div class="columns">
          <div class="column">
            <AttributeSelect v-model="attribute" size="is-medium" />
          </div>
          <!-- Target -->
          <div class="column is-narrow">
            <b-field label="Target">
              <b-select v-model="target" size="is-medium" placeholder="Select target">
                <option value="product">Product</option>
                <option value="variant">Variant</option>
              </b-select>
            </b-field>
          </div>
        </div>
        <RulesetForm v-model="json" :showPosition="true" :showIsRequired="true" @is:ready="isReady" :formFields="rule"/>
      </section>
      <footer class="modal-card-foot space-between">
        <b-button @click="$parent.close()">Close</b-button>
        <b-button
          :disabled="saving || !isDirty"
          :loading="saving"
          type="is-primary"
          @click="save"
        >Save</b-button>
      </footer>
    </div>
  </div>
  </template>

<script>
import { mapGetters } from 'vuex'
import AttributeSelect from '@/components/Models/Attributes/AttributeSelect'
import RulesetForm from '@/components/Models/Ruleset/Form'
export default {
  components: {
    AttributeSelect,
    RulesetForm
  },
  computed: {
    ...mapGetters(['api', 'context'])
  },
  data () {
    return {
      saving: false,
      json: null,
      isDirty: false,
      attribute: null,
      target: null,
      errors: {}
    }
  },
  methods: {
    save () {
      this.saving = true
      this.errors = {}
      const data = {
        ...this.json,
        attribute: {
          id: this.attribute.id
        },
        target: this.target
      }
      const endpoint = this.context.route + `/compatibility/rulesets/${this.$route.params.ruleset}/rules/${this.rule.id}/`
      this.$http.put(endpoint, data).then(response => {
        this.$emit('reload:rules')
        this.$buefy.toast.open({ type: 'is-success', message: 'Rule updated successfully' })
        this.$parent.close()
      }).catch(error => {
        if (error.response && error.response.status === 400) {
          this.errors = error.response.data
        } else {
          this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
        }
      }).finally(() => {
        this.saving = false
      })
    },
    isReady (val) {
      this.isDirty = val
    }
  },
  mounted () {
    if (this.rule) {
      this.attribute = this.rule.attribute
      this.json = {
        name: this.rule.name,
        description: this.rule.description,
        position: this.rule.position,
        isRequired: this.rule.is_required
      }
      this.target = this.rule.target || 'variant'
    }
  },
  props: {
    rule: {
      type: Object,
      require: true
    }
  }
}
</script>
