<template>
  <div>
    <div class="section">
      <div class="container">
        <!-- Page Header -->
        <div class="py-sm mb-md">
          <div class="columns is-vcentered">
            <div class="column">
              <breadcrumbs :history="breadcrumbs.history" :current="breadcrumbs.current" />
            </div>
          </div>
        </div>
        <!-- Credentials -->
        <div class="pb-lg mb-lg">
          <div class="columns">
            <div class="column is-one-fifth">
              <p class="has-text-weight-bold is-size-6 mb-sm">Client Credentials</p>
              <p class="has-text-grey is-size-7">API credentials required to access the {{ app.platform }} API.</p>
            </div>
            <div class="column">
              <div class="has-background-white rounded-xl">
                <!-- Loaded -->
                <div v-if="!loading && client !== null" class="pa-lg pb-xl">
                  <div>
                    <div class="mb-md">
                      <p class="has-text-weight-bold">Client Name</p>
                      <p>{{ client.name }}</p>
                    </div>
                    <div class="mb-md">
                      <p class="has-text-weight-bold mb-sm">Authentication URL</p>
                      <p class="mb-sm is-family-monospace has-background-light pa-sm px-lg is-size-8">{{ api.baseUrl }}/auth/token/</p>
                      <div class="has-background-light is-size-8">
                        <p class="has-text-weight-bold pt-sm px-lg is-size-7">Example</p>
                        <pre class="pt-sm">
POST {{ api.baseUrl }}/auth/token/
Content-Type: application/x-www-form-urlencoded
grant_type=client_credentials&client_id=[[ YOUR_CLIENT_ID ]]&client_secret=[[ YOUR_CLIENT_SECRET ]]

HTTP/1.1 200 OK
{
  "access_token": "0oW0r4m1pjIlb5UJujZA5iVuse0XSn",
  "token_type": "Bearer",
  "expires_in": 36000,
  "scope": "read write"
}</pre>
                      </div>
                    </div>
                    <div class="mb-md">
                      <p class="has-text-weight-bold mb-sm">API URL</p>
                      <p class="mb-sm is-family-monospace has-background-light pa-sm px-lg is-size-8">{{ api.baseRoute }}/{{ context.route }}/</p>
                      <div class="has-background-light is-size-8">
                        <p class="has-text-weight-bold pt-sm px-lg is-size-7">Example</p>
                        <pre class="pt-sm">
GET {{ api.baseRoute }}/{{ context.route }}/products/
Authorization: Bearer 0oW0r4m1pjIlb5UJujZA5iVuse0XSn</pre>
                      </div>
                    </div>
                    <div class="mb-md">
                      <p class="has-text-weight-bold mb-sm">{{ (context.isMerchant() ? 'Retailer' : 'Brand' ) }} ID</p>
                      <p class="mb-sm is-family-monospace has-background-light pa-sm px-lg is-size-8">{{ context.partner.id }}</p>
                    </div>
                    <div class="mb-md">
                      <p class="has-text-weight-bold mb-sm">Client ID</p>
                      <p class="mb-sm is-family-monospace has-background-light pa-sm px-lg is-size-8">{{ client.client_id }}</p>
                    </div>
                    <div>
                      <p class="has-text-weight-bold">Client Secret</p>
                      <p v-if="!client.secret_retrieved_at">
                        <a href="#" @click.prevent="showSecretModal = true" class="has-text-info has-text-weight-bold">
                          Get Client Secret
                        </a>
                      </p>
                      <p v-else class="has-text-grey-light">
                        Client secret was retrieved on {{ $dates.utc(client.secret_retrieved_at).local().format('MMM D, YYYY')}}.
                        If you need access to this secret, please please file a
                          <a href="#" @click.prevent="$store.dispatch('supportRequest')" class="has-text-info">support request</a>
                          for assistance.
                      </p>
                    </div>
                  </div>
                </div>
                <!-- Loading -->
                <div v-else>
                  <loading message="Loading client..." />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Secret Modal -->
    <b-modal
      :has-modal-card="true"
      :active.sync="showSecretModal"
      :can-cancel="['x']"
      ><ClientSecretModal :client="client" @secret:retrieved="load(true)"  />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ClientSecretModal from '@/components/Models/User/Modals/ClientSecret'

export default {
  components: {
    ClientSecretModal
  },
  computed: {
    ...mapGetters(['api', 'app', 'context']),
    breadcrumbs () {
      return {
        current: '#' + this.$route.params.id,
        history: [
          { label: 'Settings', name: 'settings' },
          { label: 'API Access', name: 'clients' }
        ]
      }
    },
    endpoint () {
      return this.context.route + '/users/' + this.$route.params.id + '/'
    }
  },
  data () {
    return {
      loading: true,
      client: null,
      showSecretModal: false
    }
  },
  methods: {
    load (silent) {
      if (!silent) this.loading = true
      this.$http.get(this.endpoint).then(response => {
        this.client = response.data
        this.loading = false
      })
    }
  },
  mounted () {
    this.load()
  }
}
</script>
