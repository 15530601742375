<template>
  <form @submit.prevent>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Create Department</p>
      </header>
      <section class="modal-card-body">
        <div class="pb-md">
          <!-- Name -->
          <div class="mb-lg">
            <b-field
              label="Department Name"
              :type="('name' in errors) ? 'is-danger' : ''"
              :message="('name' in errors) ? errors['name'][0] : ''"
              expanded>
              <b-input
                type="text"
                v-model="name"
                size="is-medium"
                placeholder="Name"
              ></b-input>
            </b-field>
          </div>
          <!-- Template -->
          <div class="mb-lg">
            <b-field label="Product Import Template">
              <TemplateSelect direction="import" datatype="products" v-model="template" size="is-medium"/>
            </b-field>
          </div>
          <!-- Supplier Ruleset -->
          <div class="mb-lg">
            <div class="mb-sm">
              <p><label class="label">Supplier Ruleset</label></p>
              <p class="is-size-7 has-text-grey">Select the attribute validations that suppliers must meet.</p>
            </div>
            <b-field>
              <b-select v-model="supplierRuleset" :loading="loading" :disabled="loading" expanded size="is-medium" placeholder="Select Ruleset...">
                <option v-for="ruleset in rulesets" :key="ruleset.id" :value="ruleset">{{ ruleset.name }}</option>
              </b-select>
            </b-field>
          </div>
          <!-- Merchant Ruleset -->
          <div class="mb-lg">
            <div class="mb-sm">
              <p><label class="label">Merchant Ruleset</label></p>
              <p class="is-size-7 has-text-grey">Select the attribute validations that you must meet.</p>
            </div>
            <b-field>
              <b-select v-model="merchantRuleset" :loading="loading" :disabled="loading"  expanded size="is-medium" placeholder="Select Ruleset...">
                <option v-for="ruleset in rulesets" :key="ruleset.id" :value="ruleset" >{{ ruleset.name }}</option>
              </b-select>
            </b-field>
          </div>
          <!-- Department Merchandiser -->
          <div class="mb-lg">
            <b-field label="Department Merchandiser">
              <DepartmentOwnerSelect v-model="departmentOwner" size="is-medium"/>
            </b-field>
          </div>
          <!-- Sample Template URL  -->
          <div class="mb-lg">
            <b-field
              label="Sample Template URL (optional)"
              :type="('sample_template_url' in errors) ? 'is-danger' : ''"
              :message="('sample_template_url' in errors) ? errors['sample_template_url'][0] : ''">
              <b-input
                type="text"
                v-model="sampleTemplateUrl"
                size="is-medium"
                placeholder="Enter URL..."
              ></b-input>
            </b-field>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot space-between">
        <b-button @click.prevent="$parent.close()">Close</b-button>
        <b-button
          type="is-primary"
          :disabled="saving || !ready"
          :loading="saving"
          @click.prevent="save"
          >Save
        </b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import TemplateSelect from '@/components/Models/Template/TemplateSelect'
import DepartmentOwnerSelect from '../DepartmentOwnerSelect'

export default {
  components: {
    TemplateSelect,
    DepartmentOwnerSelect
  },
  computed: {
    ...mapGetters(['app', 'api', 'auth', 'context']),
    loading () {
      return (this.loadingRulesets)
    },
    ready () {
      return true
    }
  },
  data () {
    return {
      loadingRulesets: true,
      rulesets: [],
      name: null,
      template: null,
      supplierRuleset: null,
      merchantRuleset: null,
      sampleTemplateUrl: null,
      saving: false,
      errors: {},
      departmentOwner: null
    }
  },
  methods: {
    loadRulesets () {
      this.loadingRulesets = true
      const endpoint = this.context.route + '/compatibility/rulesets/'
      const query = {
        params: {
          order_by: name
        }
      }
      this.$http.get(endpoint, query).then(response => {
        this.rulesets = response.data.results
        this.loadingRulesets = false
      })
    },
    save () {
      this.saving = true
      const endpoint = this.context.route + '/departments/'
      const body = {
        name: this.name,
        code: this.$utils.kebabCase(this.name),
        owner_user_id: this.departmentOwner,
        template_id: this.template.id,
        brand_ruleset_id: this.supplierRuleset.id,
        retailer_ruleset_id: this.merchantRuleset.id,
        sample_template_url: this.sampleTemplateUrl,
        description: null,
        is_active: 1
      }
      this.$http.post(endpoint, body).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Department created!' })
        this.$emit('reload')
      }).catch(error => {
        if (error.response && error.response.status === 400) {
          this.errors = error.response.data
        }
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    this.loadRulesets()
  }
}
</script>
