<template>
  <div class="pa-md">
    <!-- Error -->
    <div v-if="('general' in errors)" class="mb-md">
      <p class="has-text-danger">{{ errors.general }}</p>
    </div>
    <div>
      <p class="mb-md">
        Which of the following options best describes how you would prefer to integrate to {{ app.productName }}?
      </p>
    </div>
    <!-- Fields -->
    <div class="mt-md">
      <Options v-model="selectedOption" :task="task"/>
    </div>
    <!-- Navigate -->
    <div class="py-lg">
      <div class="columns">
        <div class="column">
          <b-button
            type="is-light rounded-lg"
            expanded
            @click="goBack"
            size="is-medium"
            ><b-icon icon="arrow-left"></b-icon>
          </b-button>
        </div>
        <div class="column">
          <b-button
            type="is-primary rounded-lg"
            expanded
            :disabled="!ready"
            :loading="isUpdating"
            @click="goForward"
            size="is-medium"
          >Save & Continue</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Options from '../components/Integrations/Options'
export default {
  components: {
    Options
  },
  computed: {
    ...mapGetters(['app', 'context']),
    endpoint () {
      return this.context.route + '/platform-accounts/'
    },
    ready () {
      return (
        this.selectedOption !== null
      )
    }
  },
  data () {
    return {
      selectedOption: null,
      isUpdating: false,
      setupIsCompleted: false,
      errors: {}
    }
  },
  methods: {
    goBack () {
      this.$router.push({ name: 'retailer.onboarding' })
    },
    goForward () {
      this.updateTask()
      // check if you have to save this info to backend!!

      // this.saving = true
      // const json = this.selectedOption
      // this.$http.post(this.endpoint, json).then(response => {
      //   this.$buefy.toast.open({ type: 'is-success', message: 'Integration preference updated!' })
      //   this.updateTask()
      // }).catch(error => {
      //   if (error.response && error.response.status === 400) {
      //     this.errors = error.response.data
      //   }
      //   this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      // }).finally(() => {
      //   this.saving = false
      // })
    },
    updateTask () {
      const taskBody = (typeof this.task.info === 'object') ? { info: this.task.info } : { info: {} }
      taskBody.info.integrationOptions = true
      taskBody.info.selectedOption = this.selectedOption
      this.$emit('task:updated', taskBody)
    }
  },
  mounted () {
    if (this.task && this.task.info && this.task.info.integrationOptions) {
      this.setupIsCompleted = true
      this.selectedOption = this.task.info.selectedOption
    }
  },
  props: {
    task: {
      type: Object,
      require: true
    },
    accounts: {
      type: Array,
      required: true
    },
    isUpdatingTask: {
      type: Boolean
    }
  },
  watch: {
    isUpdatingTask (value) {
      this.isUpdating = value
    }
  }
}
</script>
