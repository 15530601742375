import axios from 'axios'
import store from '@/store/'

// set axios defaults
axios.defaults.baseURL = process.env.VUE_APP_API_URL + '/' + process.env.VUE_APP_API_VERSION

// register axios global interceptors
axios.interceptors.response.use(response => {
  return response
}, error => {
  if (error.response) {
    if (error.response.status === 401 && error.request.responseURL.indexOf('/auth/token/') === -1) {
      store.dispatch('redirectGuest')
    } else if (error.response.status === 404 || error.response.status === 403 || error.response.status === 500) {
      store.dispatch('serverError', error.response.status)
    }
  } else {
    store.dispatch('serverError', 504)
  }
  return Promise.reject(error)
})

export default {
  install: function (Vue) {
    Object.defineProperty(Vue.prototype, '$http', { value: axios })
  }
}
