<template>
  <div>
    <div class="columns">
      <div class="column is-one-fifth">
        <p class="has-text-weight-bold is-size-6 mb-sm">Tags</p>
        <p class="has-text-grey is-size-7">Select the tags to set on orders pushed to your Shipstation account.</p>
      </div>
      <div class="column">
        <div class="has-background-white rounded-lg">
          <div class="pa-lg">
            <!-- Intro Text (Vendor) -->
            <div class="is-size-7 mb-md">
              <p class="mb-lg">
                {{ app.platform }} can automatically tag orders
                when they are pushed to your Shipstation account.
              </p>
              <AccountTags v-if="!loading" :account="account" :current-tag-ids="tagIds" @update:tags="updateTags"/>
            </div>
          </div>
          <div class="border-t">
            <div class="columns is-vcentered is-marginless">
              <div class="column">
                <div class="px-sm has-text-grey-light">
                </div>
              </div>
              <div class="column is-narrow">
                <div class="px-sm">
                  <!-- Save tags -->
                  <b-button
                    type="is-primary rounded-lg"
                    :disabled="saving || !isDirty"
                    :loading="saving"
                    @click="save"
                  >Save Tags</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AccountTags from './AccountTags.vue'
export default {
  components: {
    AccountTags
  },
  computed: {
    ...mapGetters(['app', 'context']),
    endpoint () {
      return this.context.route + '/shipstation/' + this.account.id + '/tags/'
    },
    json () {
      return {
        tag_ids: this.tagIds
      }
    }
  },
  data () {
    return {
      loading: true,
      saving: false,
      isDirty: false,
      tagIds: [],
      errors: {}
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint).then(response => {
        this.tagIds = response.data.tag_ids
      }).finally(() => {
        this.loading = false
      })
    },
    save () {
      this.saving = true
      this.$http.put(this.endpoint, this.json).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Tags saved!' })
      }).finally(() => {
        this.saving = false
        this.isDirty = false
      })
    },
    updateTags (tags) {
      this.isDirty = true
      this.tagIds = tags.map(tag => tag.tagId)
    }
  },
  mounted () {
    this.load()
  },
  props: ['account']
}
</script>
