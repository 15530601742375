<template>
  <div class="has-background-white rounded-lg">
    <!-- Loading -->
    <div v-if="loading">
      <loading message="Loading Attribute..." />
    </div>
    <!-- Loaded -->
    <div v-else>
      <div class="columns pa-lg">
        <div class="column is-half">
          <b-field
            label="Current Attribute"
            expanded>
            <b-input
              type="text"
              size="is-medium"
              disabled
              v-model="attributeName"
            />
          </b-field>
        </div>
        <div v-if="isEdit" class="column is-half">
          <AttributeSearch :attributes="attributes" @update:onSelect="onSelect"/>
        </div>
      </div>
      <div class="border-t" />
      <div v-if="isEdit" class="columns is-vcentered is-marginless">
        <div class="column is-narrow">
          <b-button type="is-primary rounded-lg" @click="isEdit=false">Cancel</b-button>
        </div>
        <div class="column px-sm has-text-grey-light">
          <i v-if="isValidated">Click 'Update' to save new attribute</i>
        </div>
        <div class="column">
          <div class="is-pulled-right">
            <b-button type="is-primary rounded-lg" class="mr-md" @click="onDelete">Delete</b-button>
            <b-button type="is-primary rounded-lg" @click="updateAttribute" :loading="saving" :disabled="!isValidated">Update</b-button>
          </div>
        </div>
      </div>
      <div v-else class="columns is-vcentered is-marginless">
        <div class="column px-sm has-text-grey-light">
          <i>Click 'Edit' to update or delete this attribute along with transformations</i>
        </div>
        <div class="column is-narrow">
          <div class="px-sm">
            <b-button type="is-primary rounded-lg" class="is-pulled-right" @click="isEdit=true">Edit</b-button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showConfirmModal">
      <ConfirmModal
        :showModal="showConfirmModal"
        :loading="isDeleting"
        :bodyText="`Are you sure you want to delete attribute: ${attributeName}?`"
        :bodySubText="'This will also delete all the respective transformations in this attribute.'"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ConfirmModal from '@/components/Models/Transformers/Modals/ConfirmModal'
import AttributeSearch from '@/components/Models/Transformers/AttributeSearch'
export default {
  components: {
    ConfirmModal,
    AttributeSearch
  },
  computed: {
    ...mapGetters(['app', 'api', 'context']),
    endpoint () {
      return this.context.route + `/transformers/${this.$route.params.transformer}/maps/${this.$route.params.id}/`
    },
    json () {
      return {
        attribute: {
          code: this.selected.code
        }
      }
    },
    isValidated () {
      return this.attributeName && this.selected !== null
    }
  },
  data: () => {
    return {
      attributeName: null,
      selected: null,
      saving: false,
      errors: {},
      loading: false,
      isEdit: false,
      showConfirmModal: false,
      isDeleting: false
    }
  },
  methods: {
    onSelect (option) {
      this.selected = option
    },
    // Load current map details
    loadAttributeDetails () {
      this.loading = true
      const endpoint = this.context.route + `/transformers/${this.$route.params.transformer}/maps/${this.$route.params.id}/`
      this.$http.get(endpoint).then(response => {
        this.attributeName = response.data.attribute.name
        this.loading = false
      })
    },
    // Update the attribute
    updateAttribute () {
      this.saving = true
      this.$http.patch(this.endpoint, this.json).then(response => {
        this.errors = {}
        this.attributeName = response.data.attribute.name
        this.$buefy.toast.open({ type: 'is-success', message: 'Attribute updated!' })
      }).catch(error => {
        this.$buefy.toast.open({ type: 'is-danger', message: 'Error updating attribute!' })
        this.errors = error.response.data
      }).finally(() => {
        this.saving = false
        this.selected = null
        this.isEdit = false
      })
    },
    // Delete existing attribute
    confirm () {
      this.isDeleting = true
      this.$http.delete(this.endpoint).then(response => {
        this.errors = {}
        this.$buefy.toast.open({ type: 'is-success', message: 'Attribute deleted!' })
        this.$router.push({ name: 'transformer', params: { id: this.$route.params.transformer } })
      }).catch(error => {
        this.$buefy.toast.open({ type: 'is-danger', message: 'Error deleting attribute!' })
        this.errors = error.response.data
      }).finally(() => {
        this.isDeleting = false
      })
    },
    onDelete () {
      this.showConfirmModal = true
    },
    close () {
      this.showConfirmModal = false
    }
  },
  mounted () {
    this.loadAttributeDetails()
  },
  watch: {
  },
  props: ['attributes']
}
</script>
