<template>
  <InspectionModal
    :endpoint="endpoint"
    :loadDetail="loadDetail"
    :seed="selectedFeed">
    <template v-slot:default="props">
      <form>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Inspect Request</p>
          </header>
          <section class="modal-card-body">
            <!-- Loaded -->
            <div v-if="!props.loading && props.record !== null" class="">
              <!-- Feed Details -->
              <div class="mb-lg">
                <p class="has-text-weight-bold mb-sm">Feed Details</p>
                <table class="table is-fullwidth is-striped is-narrow is-size-7 border">
                  <tbody>
                    <tr>
                      <th style="width:164px">ID</th>
                      <td>{{ props.record.id }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- Feed Timestamps -->
              <div class="mb-lg">
                <p class="has-text-weight-bold mb-sm">Feed Timestamps</p>
                <table class="table is-fullwidth is-striped is-narrow is-size-7 border">
                  <tbody>
                    <tr>
                      <th style="width:164px">Created At</th>
                      <td>{{ $dates.utc(props.record.created_at).local().format('MMM D, YYYY @ h:mm A') }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-- Loading -->
            <div v-else>
              <loading message="Loading..." />
            </div>
          </section>
          <footer class="modal-card-foot">
            <button class="button" type="button" @click="$parent.close()">Close</button>
          </footer>
        </div>
      </form>
    </template>
  </InspectionModal>
</template>

<script>
import InspectionModal from '../InspectionModal'
import { mapGetters } from 'vuex'

export default {
  components: {
    InspectionModal
  },
  data () {
    return {
      loadDetail: true
    }
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      if (this.selectedFeed !== null) {
        return this.context.route + '/feed-requests/' + this.selectedRequest.id + '/'
      }
      return null
    }
  },
  methods: {

  },
  mounted () {

  },
  props: ['selectedRequest']
}
</script>
