<template>
  <div>
    <!-- Actions Toolbar -->
    <div class="has-background-white rounded-lg px-md py-sm mb-xxs">
      <div class="columns is-vcentered is-mobile">
        <div class="column is-narrow">
          <b-dropdown :disabled="loading || selected.length === 0">
            <button class="button" slot="trigger" slot-scope="{ active }" :disabled="selected.length === 0">
              <span>Actions</span>
              <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
            </button>
            <b-dropdown-item has-link><a href="#" @click.prevent class="has-text-grey-light">No Actions</a></b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="column">
          <div class="has-text-right is-size-7">
            <!-- Counts -->
            <p v-if="loading">Loading...</p>
            <p v-else>
              {{ cancels.length }}
              {{ (cancels.length === 1) ? 'cancel' : 'cancels' }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- Reults -->
    <div>
      <b-table
        :data="cancels"
        :checked-rows.sync="selected"
        :mobile-cards="false"
        :loading="loading"
        hoverable
        checkable
        checkbox-position="left"
        class="is-size-7">
        <template>
          <!-- ID -->
          <b-table-column
            label="ID"
            header-class="nowrap"
            cell-class="nowrap"
            width="96"
            v-slot="props">
            <a href="#"
              class="has-text-weight-bold has-text-info"
              @click.prevent="inspectCancel = props.row"
            >{{ props.row.id }}</a>
          </b-table-column>
          <!-- Reason -->
          <b-table-column
            label="Reason"
            header-class="nowrap"
            cell-class="nowrap"
            v-slot="props">
            {{ props.row.reason }}
          </b-table-column>
          <!-- Registered -->
          <b-table-column
            label="Registered"
            header-class="nowrap"
            cell-class="nowrap"
            centered
            width="128"
            v-slot="props">
            <span v-if="props.row.canceled_at === null">--</span>
            <span v-else>{{ $dates.utc(props.row.canceled_at).local().format('MMM D, YYYY') }}</span>
          </b-table-column>
          <!-- Acknowledged -->
          <b-table-column
            label="Acknowledged"
            header-class="nowrap"
            cell-class="nowrap"
            centered
            width="128"
            v-slot="props">
            <span v-if="props.row.acknowledged_at === null">--</span>
            <span v-else>{{ $dates.utc(props.row.acknowledged_at).local().format('MMM D, YYYY') }}</span>
          </b-table-column>
        </template>
        <template #empty>
          <div class="py-lg has-text-grey has-text-centered">
            <p>No returns have been registered yet.</p>
          </div>
        </template>
      </b-table>
    </div>
    <!-- Inspect Modal -->
    <b-modal
      v-if="(inspectCancel !== null)"
      has-modal-card
      :active="(inspectCancel !== null)"
      :can-cancel="['escape', 'x']"
      @close="closeModals"
      ><CancelModal :selectedCancel="inspectCancel" @cancel:acknowledged="cancelAcknowledged"/>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CancelModal from '@/components/Modals/Cancel/CancelModal'

export default {
  components: {
    CancelModal
  },
  data () {
    return {
      loading: false,
      selected: [],
      inspectCancel: null
    }
  },
  computed: {
    ...mapGetters(['context'])
  },
  methods: {
    closeModals () {
      this.inspectCancel = null
    },
    cancelAcknowledged () {
      this.$emit('cancel:acknowledged')
    }
  },
  mounted () {

  },
  props: ['orderId', 'cancels']
}
</script>
