<template>
  <div>
    <!-- Actions Toolbar -->
    <div class="has-background-white rounded-lg px-md py-sm mb-xxs">
      <div class="columns is-vcentered is-mobile">
        <div class="column is-narrow">
          <b-dropdown :disabled="loading || selected.length === 0">
            <button class="button" slot="trigger" slot-scope="{ active }" :disabled="selected.length === 0">
              <span>Actions</span>
              <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
            </button>
            <b-dropdown-item><a href="#" @click.prevent="copyCreditNumbers">Copy Credit Numbers</a></b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="column">
          <div class="has-text-right is-size-7">
            <!-- Counts -->
            <p v-if="loading">Loading...</p>
            <p v-else>
              {{ order.credits.length }}
              {{ (order.credits.length === 1) ? 'credit' : 'credits' }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- Reults -->
    <div>
      <b-table
        :data="order.credits"
        :checked-rows.sync="selected"
        :mobile-cards="false"
        :loading="loading"
        hoverable
        checkable
        checkbox-position="left"
        class="is-size-7">
        <template>
          <!-- ID -->
          <b-table-column
            label="ID"
            header-class="nowrap"
            cell-class="nowrap"
            width="128"
            v-slot="props">
            <a href="#"
              class="has-text-weight-bold has-text-info"
              @click.prevent="inspectCredit = props.row"
            >{{ props.row.id }}</a>
          </b-table-column>
          <!-- Credit # -->
          <b-table-column
            label="Credit #"
            header-class="nowrap"
            cell-class="nowrap"
            v-slot="props">
            <p v-if="props.row.credit_number">{{ props.row.credit_number }}</p>
            <p v-else class="has-text-grey-light is-italic">None Provided</p>
          </b-table-column>
          <!-- Submitted -->
          <b-table-column
            label="Registered"
            header-class="nowrap"
            cell-class="nowrap"
            centered
            width="128"
            v-slot="props">
            <span v-if="props.row.credited_at === null">--</span>
            <span v-else>{{ $dates.utc(props.row.credited_at).local().format('MMM D, YYYY') }}</span>
          </b-table-column>
          <!-- Merchant Status -->
          <b-table-column
            label="Merchant Status"
            header-class="nowrap"
            cell-class="nowrap"
            centered
            width="128"
            v-slot="props">
            <MerchantStatus :credit="props.row" />
          </b-table-column>
          <!-- Credit Amount -->
          <b-table-column
            label="Total"
            cell-class="nowrap"
            centered
            width="128"
            v-slot="props">
            {{ $numbers(props.row.locale_credit_amount).formatCurrency({ mantissa: 2 }) }}
          </b-table-column>
        </template>
        <template #empty>
          <div class="py-lg has-text-grey has-text-centered">
            <p>No credits have been registered yet.</p>
          </div>
        </template>
      </b-table>
    </div>
    <!-- Inspect Modal -->
    <b-modal
      v-if="(inspectCredit !== null)"
      has-modal-card
      :active="(inspectCredit !== null)"
      :can-cancel="['escape', 'x']"
      @close="closeModals"
      ><CreditModal :selectedCredit="inspectCredit" />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CreditModal from '@/components/Modals/Credit/CreditModal'
import MerchantStatus from '@/components/Presenters/Credit/MerchantStatus'
export default {
  components: {
    CreditModal,
    MerchantStatus
  },
  data () {
    return {
      loading: false,
      selected: [],
      inspectCredit: null
    }
  },
  computed: {
    ...mapGetters(['context']),
    selectedCreditNumbers () {
      if (this.selected.length === 0) return null
      const creditNumbers = []
      this.selected.forEach(credit => {
        if (credit.credit_number) {
          creditNumbers.push(credit.credit_number)
        }
      })
      if (creditNumbers.length === 0) return null
      if (creditNumbers.length === 1) return creditNumbers.join('')
      return creditNumbers.join(', ')
    }
  },
  methods: {
    copyCreditNumbers () {
      if (this.selectedCreditNumbers !== null) {
        this.$copyText(this.selectedCreditNumbers).then(() => {
          this.$buefy.toast.open({ type: 'is-success', message: 'Credit numbers copied!' })
        })
      } else {
        this.$buefy.toast.open({ type: 'is-danger', message: 'No Credit numbers copied!' })
      }
    },
    closeModals () {
      this.inspectCredit = null
    }
  },
  mounted () {

  },
  props: ['order']
}
</script>
