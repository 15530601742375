<template>
  <div>
    <!-- Actions -->
    <div class="has-background-white rounded-lg py-md px-lg mb-lg">
      <div class="columns is-vcentered is-variable is-2">
        <div class="column is-one-quarter">
          <router-link class="button is-medium is-fullwidth is-outlined" :to="{ name: 'order.summary', params: { id: this.$route.params.id } }">
            Back
          </router-link>
        </div>
        <div class="column">
          <div class="has-text-right" v-if="orderRefreshing">
            <p class="has-text-grey">Refreshing...</p>
          </div>
        </div>
      </div>
    </div>
    <div class="columns is-centered">
      <div class="column is-two-thirds">
        <GenerateLabels
          v-if="$route.params.method === 'create'"
          :order="order"
          :orderRefreshing="orderRefreshing"
          @fulfillment:updated="refresh" />
        <RegisterTracking
          v-else-if="$route.params.method === 'existing'"
          :order="order"
          :orderRefreshing="orderRefreshing"
          @fulfillment:updated="refresh" />
      </div>
      <div class="column is-one-third">
        <div class="has-background-white pa-lg rounded-lg">
          <!-- Existing Shipments -->
          <Shipments
            :orderId="order.id"
            :orderRefreshing="orderRefreshing"
            :shipments="order.shipments"
            @fulfillment:updated="refresh"
           />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import GenerateLabels from '../components/Fulfill/GenerateLabels'
import RegisterTracking from '../components/Fulfill/RegisterTracking'
import Shipments from '../components/Fulfill/Shipments'

export default {
  components: {
    GenerateLabels,
    RegisterTracking,
    Shipments
  },
  computed: {
    ...mapGetters(['context'])
  },
  data () {
    return {
      breadcrumbs: [
        { label: 'Orders', name: 'orders' },
        { label: 'PO #12312', name: 'order' }
      ],
      shipments: []
    }
  },
  methods: {
    refresh () {
      this.$emit('fulfillment')
    }
  },
  mounted () {
    this.shipments = this.$utils.cloneDeep(this.order.shipments)
  },
  props: ['order', 'orderRefreshing']
}
</script>
