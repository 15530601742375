<template>
  <div>
    <!-- Content -->
    <div>
      <div class="has-background-white pa-md rounded-lg mb-xs">
        <div v-if="context.isSupplier()">
          <WorkflowSupplier
            :proposal="proposal"
            @action:propose="showProposeModal = true"
            @action:archive="showArchiveModal = true"
            @action:export="showExportModal = true"
            @action:costUpdate="showCostUpdateModal=true"
          />
        </div>
        <div v-else>
          <WorkflowMerchant
            :proposal="proposal"
            @inspect:compatibility="showRulesetSummaryModal = true"
            @action:owner="showChangeOwnerModal = true"
            @action:revise="showReviseModal = true"
            @action:approve="showApproveModal = true"
            @action:pricing="showPriceApprovalModal = true"
            @action:complete="showCompleteModal = true"
            @action:publish="showPublishModal = true"
            @action:export="showExportModal = true"
            @action:acknowledge="showAcknowledgeModal = true"
            @action:decline="showDeclineModal = true"
            @action:applyTransformer="showTransformerModal = true" />
        </div>
      </div>
      <div class="has-background-white pa-lg rounded-lg mb-lg">
        <ProposalInfo :proposal="proposal" />
      </div>
    </div>
    <!-- Archive Modal -->
    <b-modal
      has-modal-card
      :active.sync="showArchiveModal"
      :can-cancel="['escape', 'x']"
      ><ArchiveModal :type="'proposal'" :proposal="proposal" @proposal:archived="returnToList" />
    </b-modal>
    <!-- Propose Modal -->
    <b-modal
      has-modal-card
      :active.sync="showProposeModal"
      :can-cancel="['escape', 'x']"
      ><ProposeModal :proposal="proposal" @proposal:proposed="refresh"  />
    </b-modal>
    <!-- Revise Modal -->
    <b-modal
      has-modal-card
      :active.sync="showReviseModal"
      :can-cancel="['escape', 'x']"
      ><ReviseModal :proposal="proposal" @proposal:revised="refresh" />
    </b-modal>
    <!-- Approve Modal -->
    <b-modal
      has-modal-card
      :active.sync="showApproveModal"
      :can-cancel="['escape', 'x']"
      ><ApproveModal :proposal="proposal" @proposal:approved="refresh" />
    </b-modal>
    <!-- Pricing Approve Modal -->
    <b-modal
      has-modal-card
      :active.sync="showPriceApprovalModal"
      :can-cancel="['escape', 'x']"
      ><PriceApproveModal :proposal="proposal" @proposal:priced="refresh" />
    </b-modal>
    <!-- Complete Modal -->
    <b-modal
      has-modal-card
      :active.sync="showCompleteModal"
      :can-cancel="['escape', 'x']"
      ><CompleteModal :proposal="proposal" @proposal:completed="refresh" />
    </b-modal>
    <!-- Publish Modal -->
    <b-modal
      has-modal-card
      :active.sync="showPublishModal"
      :can-cancel="['escape', 'x']"
      ><PublishModal :proposal="proposal" @proposal:published="refresh"/>
    </b-modal>
    <!-- Decline Modal -->
    <b-modal
      has-modal-card
      :active.sync="showDeclineModal"
      :can-cancel="['escape', 'x']"
      ><DeclineModal :proposal="proposal" @proposal:declined="refresh" />
    </b-modal>
    <!-- Acknowledge Modal -->
    <b-modal
      has-modal-card
      :active.sync="showAcknowledgeModal"
      :can-cancel="['escape', 'x']"
      ><AcknowledgeModal :proposal="proposal" @proposal:acknowledged="refresh" />
    </b-modal>
    <!-- Change Owner Modal -->
    <b-modal
      has-modal-card
      :active.sync="showChangeOwnerModal"
      :can-cancel="['escape', 'x']"
      ><ChangeOwnerModal :proposal="proposal" @proposal:reassigned="refresh" />
    </b-modal>
    <!-- Export Modal -->
    <b-modal
      has-modal-card
      :active.sync="showExportModal"
      :can-cancel="['escape', 'x']"
      ><ExportModal :proposal="proposal" />
    </b-modal>
    <!-- Cost Update Modal -->
    <b-modal
      has-modal-card
      :active.sync="showCostUpdateModal"
      :can-cancel="['escape', 'x']"
      ><CostUpdateModal :proposal="proposal" />
    </b-modal>
    <!-- Transformers Modal  -->
    <b-modal
      has-modal-card
      :active.sync="showTransformerModal"
      :can-cancel="['escape', 'x']"
      ><TransformerModal @apply:transformer="applyTransformer" :loading="saving"/>
    </b-modal>
    <!-- Ruleset Summary -->
    <!-- <b-modal
      has-modal-card
      :active.sync="showRulesetSummaryModal"
      :can-cancel="['escape', 'x']"
      ><RulesetSummaryModal :proposal="proposal" />
    </b-modal> -->
  </div>
</template>

<script>
import ProposalInfo from '../components/Detail/ProposalInfo'
import ArchiveModal from '@/components/Models/Proposal/Modals/Archive'
import ProposeModal from '@/components/Models/Proposal/Modals/Propose'
import ReviseModal from '@/components/Models/Proposal/Modals/Revise'
import ApproveModal from '@/components/Models/Proposal/Modals/Approve'
import DeclineModal from '@/components/Models/Proposal/Modals/Decline'
import PriceApproveModal from '@/components/Models/Proposal/Modals/PriceApprove'
import CompleteModal from '@/components/Models/Proposal/Modals/Complete'
import PublishModal from '@/components/Models/Proposal/Modals/Publish'
import ExportModal from '@/components/Models/Proposal/Modals/Export'
import AcknowledgeModal from '@/components/Models/Proposal/Modals/Acknowledge'
import ChangeOwnerModal from '@/components/Models/Proposal/Modals/ChangeOwner'
import CostUpdateModal from '@/components/Models/Proposal/Modals/CostUpdate'
import TransformerModal from '@/components/Models/Transformers/Modals/ApplyTransformer'
// import RulesetSummaryModal from '../components/Detail/RulesetSummaryModal'
import WorkflowMerchant from '../components/Detail/WorkflowMerchant'
import WorkflowSupplier from '../components/Detail/WorkflowSupplier'
import { mapGetters } from 'vuex'

export default {
  components: {
    ProposalInfo,
    ArchiveModal,
    ProposeModal,
    ReviseModal,
    ApproveModal,
    DeclineModal,
    PriceApproveModal,
    CompleteModal,
    PublishModal,
    ExportModal,
    AcknowledgeModal,
    ChangeOwnerModal,
    CostUpdateModal,
    // RulesetSummaryModal,
    WorkflowMerchant,
    WorkflowSupplier,
    TransformerModal
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/proposals/' + this.$route.params.id + '/apply-transformer/'
    }
  },
  data () {
    return {
      showArchiveModal: false,
      showProposeModal: false,
      showReviseModal: false,
      showApproveModal: false,
      showPriceApprovalModal: false,
      showCompleteModal: false,
      showAcknowledgeModal: false,
      showPublishModal: false,
      showExportModal: false,
      showChangeOwnerModal: false,
      showRulesetSummaryModal: false,
      showCostUpdateModal: false,
      showTransformerModal: false,
      showDeclineModal: false,
      saving: false
    }
  },
  methods: {
    refresh () {
      this.$emit('proposal:updated')
    },
    returnToList () {
      this.$router.push({ name: 'proposals.list' })
    },
    applyTransformer (transformer) {
      this.saving = true
      const json = {
        transformer_id: transformer.id
      }
      this.$http.post(this.endpoint, json).then(() => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Transformer applied!' })
      }).catch(() => {
        this.$buefy.toast.open({ type: 'is-danger', message: 'There was a problem submitting your request, try again' })
      }).finally(() => {
        this.showTransformerModal = false
        this.saving = false
        this.refresh()
      })
    }
  },
  props: ['proposal']
}
</script>
