<template>
<div class="modal-card">
  <header class="modal-card-head">
    <p class="modal-card-title">Order Line Items</p>
  </header>
  <section class="modal-card-body">
    <!-- List View -->
    <div v-if="order !== null">
      <div v-for="line in order.order_lines" :key="line.id">
        <!-- Item Identifiers -->
        <div class="has-background-light pa-sm rounded-lg mb-xs">
          <OrderLine :line="line" />
        </div>
      </div>
    </div>
  </section>
  <footer class="modal-card-foot">
    <button class="button" type="button" @click="$parent.close()">Close</button>
  </footer>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import OrderLine from './OrderLine'

export default {
  components: {
    OrderLine
  },
  data () {
    return {
      view: 'fulfillment'
    }
  },
  computed: {
    ...mapGetters(['context']),
    columns () {
      if (this.view === 'pricing') return ['item', 'units', 'cost', 'price']
      return ['item', 'units', 'status', 'backorder']
    }
  },
  methods: {
    // loadVariant (id) {
    //   const endpoint = this.context.route + '/variants/' + id + '/'
    //   this.loadingVariant = true
    //   this.$http.get(endpoint).then(response => {
    //     this.variant = response.data
    //     this.loadingVariant = false
    //   })
    // }
  },
  mounted () {

  },
  props: {
    order: {
      type: Object
    }
  }
}
</script>
