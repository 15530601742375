<template>
  <div>
    <!-- Loading -->
    <div v-if="loading">
      <loading message="Loading..." />
    </div>
    <!-- Loaded (No Profile / Opt-in) -->
    <div v-else-if="!loading && profile === null">
      No profile found.
    </div>
    <!-- Loaded (With Profile) -->
    <div v-else-if="!loading && profile !== null">
      <router-view :profile="profile" @update:profile="load" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/marketplaces/?mine=1'
    }
  },
  data () {
    return {
      loading: true,
      profile: null
    }
  },
  methods: {
    load () {
      this.$http.get(this.endpoint).then(response => {
        if (response.data.results.length > 0) {
          this.profile = response.data.results[0]
        }
      }).finally(() => {
        this.loading = false
      })
    }
  },
  mounted () {
    this.load()
  }
}
</script>
