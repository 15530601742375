<template>
  <div>
    <!-- Content -->
    <div class="mb-lg">
      <p class="has-text-weight-bold mb-sm">Order Tags</p>
      <p class="mb-md">
        To assist with fulfillment workflows, {{ app.platform }} can automatically
        apply custom tags to orders when they are pushed to your ShipStation account.
        If you would like us to tag orders, select the tags below. If you need a
        new tag, please create the tag in your ShipStation account (and it will be available here instantly)
      </p>
    </div>
    <div class="has-background-white rounded-lg mb-md">
      <AccountTags :account="account" :current-tag-ids="tagIds" @update:tags="updateTags"/>
    </div>
    <!-- Navigate -->
    <div class="py-lg">
      <div class="columns is-centered">
        <div class="column">
          <b-button
            type="is-light rounded-lg"
            size="is-medium"
            @click="goBack"
            expanded
            ><b-icon icon="arrow-left"></b-icon>
          </b-button>
        </div>
        <div class="column">
          <b-button
            type="is-primary rounded-lg"
            size="is-medium"
            :disabled="saving || loading"
            expanded
            @click="goForward"
          >Continue</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
// Importing the same component from the settings module
import AccountTags from '../../../Settings/components/Shipstation/AccountTags'
export default {
  components: {
    AccountTags
  },
  computed: {
    ...mapGetters(['app', 'context']),
    endpoint () {
      return this.context.route + '/shipstation/' + this.account.id + '/tags/'
    },
    json () {
      return {
        tag_ids: this.tagIds
      }
    }
  },
  data () {
    return {
      loading: true,
      saving: false,
      tagIds: [],
      errors: {}
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint).then(response => {
        this.tagIds = response.data.tag_ids
      }).finally(() => {
        this.loading = false
      })
    },
    goBack () {
      this.$emit('navigate', 'StoreSetup')
    },
    goNext () {
      this.$emit('navigate', 'Shipments')
    },
    goForward () {
      this.saving = true
      this.$http.put(this.endpoint, this.json).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Tags saved!' })
        this.$emit('platform:updated')
        this.$emit('navigate', 'Shipments')
      }).catch(() => {
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    },
    updateTags (tags) {
      this.tagIds = tags.map(tag => tag.tagId)
    }
  },
  mounted () {
    this.load()
  },
  props: {
    connection: {
      type: Object,
      required: true
    },
    task: {
      type: Object,
      required: true
    },
    account: {
      type: Object
    }
  }
}
</script>
