<template>
  <div class="section">
    <div class="container">
      <!-- Page Header -->
      <div class="columns is-vcentered">
        <div class="column is-one-third">
          <breadcrumbs :history="breadcrumbs.history" :current="breadcrumbs.current" />
        </div>
        <div class="column is-one-third">
          <h1 class="has-text-centered is-size-4">Categories</h1>
        </div>
      </div>
      <!-- Filters -->
      <div class="has-background-white rounded-lg pa-md mb-xxs">
        <div class="columns">
          <div class="column">
            <CategoryFilters @filter="filter" :applied-filters="filters" />
          </div>
          <div class="column is-narrow">
            <b-field >
              <b-select v-model="orderBy" expanded @input="sort" size="is-medium">
                <option value="-id">Most Recent</option>
                <option value="id">Oldest</option>
              </b-select>
            </b-field>
          </div>
          <div v-if="context.isMerchant()" class="column is-narrow">
            <b-button type="is-primary" size="is-medium" outlined @click.prevent="showCreateModal=true">Add New Category</b-button>
          </div>
        </div>
      </div>
      <!-- Toolbar -->
      <div class="has-background-white rounded-lg px-sm mb-xxs">
        <div class="columns is-vcentered is-mobile is-marginless is-paddingless">
          <!-- Dropdown -->
          <div class="column">
            <div class="has-text-right is-size-7">
              <!-- Counts -->
              <p v-if="loading">Loading...</p>
              <p v-else>{{ total }} {{ (total === 1) ? 'record' : 'records' }} found.</p>
            </div>
          </div>
        </div>
      </div>
      <!-- Table -->
      <div>
        <b-table
          :data="results"
          :mobile-cards="false"
          :loading="loading"
          hoverable
          class="is-size-7">
          <template>
            <!-- ID -->
            <b-table-column
              label="ID"
              width="64"
              v-slot="props">
              <p class="has-text-grey-light">{{ props.row.id }}</p>
            </b-table-column>
            <!-- Category Name link to view items -->
            <b-table-column
              label="Category Name"
              width="128"
              cell-class="nowrap"
              v-slot="props">
              <router-link class="has-text-weight-bold has-text-info" :to="{ name: 'category.items', params: { id: props.row.id }, query: { name: props.row.category }}">
                {{ props.row.category }}
              </router-link>
            </b-table-column>
            <!-- Number of items -->
            <b-table-column
              label="Number of Items"
              centered
              v-slot="props">
              <p>{{ props.row.item_count }}</p>
            </b-table-column>
            <!-- Last Updated -->
            <b-table-column
              label="Updated At"
              centered
              v-slot="props">
              <p>{{ $dates.utc(props.row.updated_at).local().format('MMM DD, YYYY') }}</p>
            </b-table-column>
            <!-- More options -->
            <!-- retailer more -->
            <b-table-column
              v-if="context.isMerchant()"
              width="128"
              v-slot="props">
              <b-dropdown expanded position="is-bottom-left">
                <div class="is-medium" slot="trigger">
                  <b-icon icon="dots-vertical"></b-icon>
                </div>
                <b-dropdown-item has-link>
                  <a href="#" @click.prevent="openUpdate(props.row)" >Rename</a>
                </b-dropdown-item>
                <b-dropdown-item has-link>
                  <router-link class="button is-white" :to="{ name: 'category.items', params: { id: props.row.id }, query: { name: props.row.category }}">
                    View items
                  </router-link>
                </b-dropdown-item>
                <b-dropdown-item has-link>
                  <a href="#" @click="onDelete" @click.prevent="deleteCategory = props.row">Delete</a>
                </b-dropdown-item>
              </b-dropdown>
            </b-table-column>
            <!-- supplier more -->
            <b-table-column
              v-else
              width="128"
              v-slot="props">
              <b-dropdown expanded position="is-bottom-left">
                <div class="is-medium" slot="trigger">
                  <b-icon icon="dots-vertical"></b-icon>
                </div>
                <b-dropdown-item has-link>
                  <router-link class="has-background-white has-text-grey" :to="{ name: 'category.connection.items', params: { id: props.row.id },  query: { connectionId: $route.params.id, name: props.row.category }}">
                    View items
                  </router-link>
                </b-dropdown-item>
              </b-dropdown>
            </b-table-column>
          </template>
          <template #empty>
            <div class="py-xxl has-text-grey has-text-centered">
              {{ (loading) ? "Loading data..." : 'No records found!' }}
            </div>
          </template>
        </b-table>
      </div>
      <div class="mt-md">
        <b-pagination
          :total="total"
          :current.sync="currentPage"
          :rounded="true"
          :per-page="perPage"
          @change="paginate">
        </b-pagination>
      </div>
    </div>
    <!-- create category -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showCreateModal"
      :can-cancel="['x']"
      >
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Create Category</p>
        </header>
        <section class="modal-card-body">
          <div class="has-background-white rounded-lg">
            <b-field
              label="Category Name"
              expanded>
              <b-input
                type="text"
                size="is-medium"
                v-model="categoryName"
              />
            </b-field>
          </div>
        </section>
        <footer class="modal-card-foot" style="justify-content:space-between">
          <b-button @click="showCreateModal = false">Cancel</b-button>
          <b-button type="is-primary" @click="create" :loading="saving" :disabled="!ready">Save</b-button>
        </footer>
      </div>
    </b-modal>
    <!-- rename category modal-->
    <b-modal
      :active.sync="showRenameModal"
      has-modal-card
      :can-cancel="['escape', 'x']">
      <RenameModal :loading="isUpdating" :category="renameCategory" @update:category="update"/>
    </b-modal>
    <!-- delete category confirmation modal -->
      <div v-if="showConfirmModal">
        <b-modal
          has-modal-card
          scroll="keep"
          :active.sync="showConfirmModal"
          :can-cancel="['x']"
          >
          <ConfirmationModal
              :data="deleteCategory"
              :label="'Are you sure?'"
              :reason="`Are you sure you want to delete category: ${deleteCategory.category}?`"
              @modal:confirm="confirm"
              @modal:close="closeModal"
              />
          </b-modal>
      </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ConfirmationModal from '@/components/Modals/ConfirmationModal'
import Filterable from '@/mixins/Filterable'
import CategoryFilters from '../components/List/Filters'
import RenameModal from '../components/List/Rename'

export default {
  components: {
    CategoryFilters,
    ConfirmationModal,
    RenameModal
  },
  computed: {
    ...mapGetters(['api', 'context']),
    endpoint () {
      let path = `${this.context.route}/categories/`
      if (this.context.isSupplier()) {
        path = `${this.context.route}/connections/${this.$route.params.connectionId}/retailer-categories/`
      }
      return path
    },
    breadcrumbs () {
      const historyArray = (this.context.isSupplier())
        ? [{ label: 'Settings', name: 'settings' }, { label: 'Connections', name: 'connection' }]
        : [{ label: 'Settings', name: 'settings' }]
      return {
        history: historyArray,
        current: 'Categories'
      }
    },
    json () {
      return {
        category: this.categoryName
      }
    },
    ready () {
      return (this.categoryName !== null && this.categoryName.length > 0)
    }
  },
  data () {
    return {
      key: 'categories',
      showCreateModal: false,
      showConfirmModal: false,
      categoryName: null,
      renameCategory: null,
      showRenameModal: false,
      errors: {},
      saving: false,
      isUpdating: false,
      isDeleting: false
    }
  },
  methods: {
    reload () {
      this.showCreateModal = false
      this.showConfirmModal = false
      this.showRenameModal = false
      this.load()
    },
    create () {
      this.saving = true
      this.$http.post(this.endpoint, this.json).then(response => {
        this.errors = {}
        this.$buefy.toast.open({ type: 'is-success', message: 'Category created successfully!' })
        this.reload()
      }).catch(error => {
        this.$buefy.toast.open({ type: 'is-danger', message: 'Error creating category!' })
        this.errors = error.response.data
      }).finally(() => {
        this.saving = false
      })
    },
    openUpdate (categoryData) {
      this.showRenameModal = true
      this.renameCategory = categoryData
    },
    // rename category
    update ({ id, name }) {
      this.isUpdating = true
      const updateEndpoint = `${this.context.route}/categories/${id}/`
      this.$http.patch(updateEndpoint, { category: name }).then(response => {
        this.errors = {}
        this.$buefy.toast.open({ type: 'is-success', message: 'Category updated!' })
        this.reload()
      }).catch(error => {
        this.$buefy.toast.open({ type: 'is-danger', message: 'Error updating category!' })
        this.errors = error.response.data
      }).finally(() => {
        this.isUpdating = false
        this.isDirty = false
      })
    },
    // delete category
    confirm () {
      this.isDeleting = true
      const endpoint = `${this.context.route}/categories/${this.deleteCategory.id}/`
      this.$http.delete(endpoint).then(response => {
        this.errors = {}
        this.$buefy.toast.open({ type: 'is-success', message: 'Category deleted!' })
        this.reload()
      }).catch(error => {
        this.$buefy.toast.open({ type: 'is-danger', message: 'Error deleting Category!' })
        this.errors = error.response.data
      }).finally(() => {
        this.isDeleting = false
      })
    },
    onDelete () {
      this.showConfirmModal = true
    },
    closeModal () {
      this.showConfirmModal = false
    }
  },
  mixins: [Filterable],
  mounted () {
    this.load()
  }
}
</script>
