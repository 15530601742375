export const profileMethods = {
  label: 'Profile method',
  key: 'method',
  icon: 'pulse',
  tooltip: 'Filter profiles based on the method.',
  options: [{
    value: 'flat',
    label: 'Flat'
  }, {
    value: 'category',
    label: 'Category'
  }]
}
