<template>
  <div>
    <form @submit.prevent="upload">
      <div class="columns">
        <div class="column is-one-fifth">
          <p class="has-text-weight-bold is-size-6 mb-sm">Cover Photo</p>
          <div class="has-text-grey is-size-7">
            <p class="mb-md">
              The 'cover photo' is a large, landscape image or photo displayed
              at the top of your profile.
            </p>
            <p>
              Large photos work best (at least 900 pixels wide in a 3:1 ratio).
              If you don't have a photo, consider connecting your Instagram account
              to select a photo from your Instagram feed.
            </p>
          </div>
        </div>
        <div class="column">
          <div class="has-background-white rounded-lg">
            <!-- Loading platform account or loading marketplace -->
            <div v-if="loading" class="pa-xl">
              <loading message="Loading..."></loading>
            </div>
            <!-- Loaded -->
            <div v-else>
              <div class="pa-xl">
                <!-- Cover Photo -->
                <div class="mb-xl">
                  <div class="has-text-centered">
                    <p class="has-text-grey mb-md">Tip: You can zoom and pan the photo below to crop or adjust your photo!</p>
                    <croppa
                      v-model="croppa"
                      canvas-color="#EEF1F9"
                      remove-button-color="black"
                      placeholder="Click (or tap) to select a photo."
                      initial-size="natural"
                      :initial-image="(this.existingAsset !== null) ? this.existingAsset.cloudinaryUrl : null"
                      :placeholder-font-size="16"
                      :auto-sizing="true"
                      :quality="quality"
                      :prevent-white-space="true"
                      :accept="'image/*'"
                      :file-size-limit="5242880"
                      :show-remove-button="false"
                      @init="croppaReady = true"
                      @file-size-exceed="sizeError"
                      @file-type-mismatch="typeError"
                      @new-image="validate"
                      @image-remove="reset"
                      @move="validate"
                      @zoom="validate"
                    ></croppa>
                  </div>
                </div>
                <!-- Instructions -->
                <div>
                  <div class="columns is-centered">
                    <!-- Device Button -->
                    <div class="column is-one-third-desktop">
                      <b-button
                        type="is-light rounded-lg"
                        size="is-medium"
                        icon-left="desktop-mac"
                        @click="croppa.chooseFile()"
                        expanded>
                        Upload From Device
                      </b-button>
                    </div>
                    <!-- Instagram Button -->
                    <div class="column is-one-third-desktop">
                      <b-button
                        v-if="account !== null"
                        type="is-light rounded-lg"
                        size="is-medium"
                        icon-left="instagram"
                        @click.prevent="showInstagramModal = true"
                        expanded>
                        Select from Instagram
                      </b-button>
                      <b-button
                        v-else
                        type="is-light rounded-lg"
                        size="is-medium"
                        icon-left="instagram"
                        @click.prevent="window.location = installUrl"
                        expanded>
                        Connect to Instagram
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="border-t">
                <div class="columns is-vcentered is-marginless">
                  <div class="column">
                    <div class="px-sm has-text-grey-light">
                      <i v-if="inputError !== null" class="has-text-danger">{{ inputError }}</i>
                      <i v-if="isDirty" class="has-text-danger">Click 'Save' to save your changes before moving on to the next section.</i>
                    </div>
                  </div>
                  <div class="column is-narrow">
                    <div class="px-sm">
                      <b-button native-type="submit" type="is-primary rounded-lg" :loading="updating" :disabled="updating || !readyToUpload">Save</b-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>

    <!-- Instagram Modal -->
    <b-modal
      has-modal-card
      :active.sync="showInstagramModal"
      :can-cancel="['x']"
    >
      <instagram-modal :account="account" @use:photo="usePhoto" />
    </b-modal>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CdnTransform from '@/mixins/CdnTransform'
import InstagramModal from './InstagramModal'
export default {
  components: {
    InstagramModal
  },
  computed: {
    ...mapGetters(['api', 'context']),
    endpoint () {
      if (this.existingAsset !== null) {
        return this.context.route + '/assets/' + this.existingAsset.id + '/'
      }
      return this.context.route + '/marketplaces/' + this.marketplace.id + '/assets/'
    },
    method () {
      return (this.existingAsset !== null) ? 'patch' : 'post'
    },
    json () {
      if (this.tempfile === null) return null
      return {
        name: 'cover',
        description: 'Cover Photo',
        type: 'image',
        tempfile: {
          id: this.tempfile.id
        }
      }
    },
    existingAsset () {
      if (this.marketplace.assets.length === 0) return null
      const existing = this.$utils.find(this.marketplace.assets, { name: 'cover' })
      if (typeof existing !== 'undefined') {
        return {
          ...existing,
          cloudinaryUrl: this.cdnTransform(existing.source_url, 900, 300, 'fill')
        }
      }
      return null
    },
    quality () {
      if (this.croppaReady) {
        return (this.croppa.realWidth >= 900) ? 1 : parseFloat((900 / this.croppa.realWidth).toFixed(2))
      }
      return 2
    }
  },
  data () {
    return {
      isDirty: false,
      updating: false,
      showInstagramModal: false,
      showTooltip: false,
      croppaReady: false,
      croppa: {},
      inputError: null,
      readyToUpload: false,
      processing: false,
      tempfile: null,
      errors: [],
      story: null
    }
  },
  methods: {
    sizeError () {
      this.readyToUpload = false
      this.inputError = 'The filesize of your photo is too large. Please try again with a smaller photo.'
    },
    typeError () {
      this.readyToUpload = false
      this.inputError = 'Please upload a photo only.'
    },
    validate () {
      if (this.croppa.naturalWidth < 900 || this.croppa.naturalHeight < 300) {
        this.readyToUpload = false
        this.inputError = 'Please upload a larger landscape photo (at least 900x300 pixels)'
        return
      }
      this.isDirty = true
      this.readyToUpload = true
      this.inputError = null
    },
    reset () {
      this.isDirty = false
      this.readyToUpload = false
      this.inputError = null
      this.errors = []
    },
    usePhoto (payload) {
      this.croppa.initialImage = payload.media_url
      this.croppa.refresh()
    },
    upload () {
      this.updating = true
      this.croppa.generateBlob(blob => {
        const route = this.api.baseUrl + '/v1/tempfiles/upload/'
        const formdata = new FormData()
        formdata.append('file', blob, 'cover.jpg')
        this.$http.post(route, formdata).then(response => {
          this.tempfile = response.data
          this.$http[this.method](this.endpoint, this.json).then(response => {
            this.reset()
            this.$buefy.toast.open({ type: 'is-success', message: 'Cover Photo Updated!' })
            this.$emit('updated:marketplace')
          }).finally(() => {
            this.updating = false
          })
        })
      }, 'image/jpeg', 0.8)
    }
  },
  mixins: [CdnTransform],
  mounted () {

  },
  props: ['account', 'installUrl', 'loading', 'marketplace']
}
</script>

<style scoped>
.croppa-container {
  width: 300px;
  height: 100px;
  background-color: #EEF1F9;
}
@media screen and (min-width: 769px) {
  .croppa-container {
    width: 450px;
    height: 150px;
  }
}
@media screen and (min-width: 1024px) {
  .croppa-container {
    width: 600px;
    height: 200px;
  }
}
@media screen and (min-width: 1024px) {
  .croppa-container {
    width: 750px;
    height: 250px;
  }
}
@media screen and (min-width: 1216px) {
  .croppa-container {
    width: 900px;
    height: 300px;
  }
}
</style>
