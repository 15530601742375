import Auth from './views/Auth'
import Login from './views/Login'
import ForgotPassword from './views/ForgotPassword'
import ResetPassword from './views/ResetPassword'
import ChangePassword from './views/ChangePassword'
import Diagnostics from './views/Diagnostics'

export default [{
  path: '/auth',
  name: 'auth',
  component: Auth,
  children: [{
    path: 'login',
    name: 'auth.login',
    component: Login,
    meta: {
      skipAuth: true,
      skipContext: true,
      hideNavigation: true
    }
  }, {
    path: 'forgot',
    name: 'auth.forgot',
    component: ForgotPassword,
    meta: {
      skipAuth: true,
      skipContext: true,
      hideNavigation: true
    }
  }, {
    path: 'reset',
    name: 'auth.reset',
    component: ResetPassword,
    meta: {
      skipAuth: true,
      skipContext: true,
      hideNavigation: true
    }
  }, {
    path: 'change-password',
    name: 'auth.change',
    component: ChangePassword,
    meta: {
      skipAuth: false,
      skipContext: true,
      hideNavigation: true
    }
  }, {
    path: 'diagnostics',
    name: 'auth.diagnostics',
    component: Diagnostics,
    meta: {
      skipAuth: true,
      skipContext: true,
      hideNavigation: true
    }
  }]
}]
