<template>
  <div>
    <div class="columns">
      <div class="column is-one-fifth">
        <p class="has-text-weight-bold is-size-6 mb-sm">Shopify Order Tags</p>
        <p class="has-text-grey is-size-7">Configure what tags are added to orders when pushed to Shopify.</p>
      </div>
      <div class="column">
        <div class="has-background-white rounded-lg">
          <div class="pa-lg">
            <div class="is-size-7 mb-md">
              <p>
                Orders pushed to your Shopify store will automatically be tagged with 'fabric Marketplace'
                and the name of the retailer that placed the order. You may specify custom tags below.
              </p>
            </div>
            <b-field label="Order Tags">
              <b-taginput v-model="tags" ellipsis maxlength="40" icon="label" placeholder="Add a tag"
                aria-close-label="Delete this tag" :before-adding="validate"></b-taginput>
            </b-field>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['app', 'context']),
    endpoint () {
      return this.context.route + '/shopify/' + this.account.id + '/order-tags/'
    }
  },
  data () {
    return {
      saving: false,
      errors: {},
      tags: [],
      tagsWithId: {},
      selectedTags: []
    }
  },
  watch: {
    tags: {
      deep: true,
      handler (newTags, oldTags) {
        if (newTags.length < oldTags.length) {
          const removedTag = oldTags.find(tag => !newTags.includes(tag))
          this.removeTag(removedTag)
        }
        if (newTags.length > oldTags.length) {
          const addedTag = newTags.find(tag => !oldTags.includes(tag))
          this.createTag(addedTag)
        }
      }
    }
  },
  methods: {
    createTag (tag) {
      this.saving = true
      if (!(tag in this.tagsWithId)) {
        this.$http.post(this.endpoint, { name: tag }).then(response => {
          this.tagsWithId[response.data.name] = response.data.id
        })
      }
    },
    removeTag (tag) {
      this.$http.delete(this.endpoint + this.tagsWithId[tag] + '/').then(response => {
        delete this.tagsWithId[tag]
      })
    },
    getTags () {
      this.$http.get(this.endpoint).then(response => {
        this.tagsWithId = response.data.results.reduce((map, item) => {
          map[item.name] = item.id
          return map
        }, {})
        this.tags = response.data.results.map(tag => tag.name)
      })
    },
    validate (tag) {
      return !(tag in this.tagsWithId)
    }
  },
  mounted () {
    this.getTags()
  },
  props: ['account']
}
</script>
