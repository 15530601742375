<template>
  <span>{{ runtime }}</span>
</template>

<script>
export default {
  computed: {
    runtime () {
      if (!this.start || !this.finish) return '--'
      const start = this.$dates.utc(this.start)
      const finish = this.$dates.utc(this.finish)
      const msDiff = finish.diff(start)
      if (msDiff > 60000) {
        return '~ ' + this.$numbers(finish.diff(start, 'minute')).format({ mantissa: 0 }) + ' minutes'
      } else if (msDiff > 1000) {
        return '~ ' + this.$numbers(finish.diff(start, 'second')).format({ mantissa: 0 }) + ' seconds'
      }
      return this.$numbers(finish.diff(start)).format({ mantissa: 0 }) + ' ms'
    }
  },
  props: ['start', 'finish']
}
</script>
