import Index from './views/Index'
import Notifications from './views/Notifications'
import Preferences from './views/Preferences'

export default [{
  path: '/profile',
  name: 'me',
  component: Index
}, {
  path: '/profile/notifications',
  name: 'me.notifications',
  component: Notifications
}, {
  path: '/profile/preferences',
  name: 'me.preferences',
  component: Preferences
}]
