import Index from './views/Index'
import Connections from './views/Connections'
import Profile from './views/Profile'
import Product from './views/Product'

export default [{
  path: '/discover',
  component: Index,
  children: [{
    path: '',
    name: 'discover.dashboard',
    redirect: { name: 'discover.connections' }
  }, {
    path: 'connections',
    name: 'discover.connections',
    component: Connections
  }, {
    path: 'supplier/:id',
    name: 'discover.profile',
    component: Profile
  }, {
    path: 'supplier/:id/products/:product',
    name: 'discover.product',
    component: Product
  }]
}]
