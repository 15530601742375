<template>
  <div>
    <div class="columns is-multiline">
      <!-- Identifiers -->
      <div class="column is-half">
        <p class="has-text-primary has-text-weight-bold pb-sm has-border-b has-border-lg">Proposal Info</p>
        <table class="table is-fullwidth is-striped is-size-7 is-narrow">
          <tbody>
            <tr>
              <th>
                <span v-if="context.isSupplier()">Owner</span>
                <span v-else>Supplier Merchandiser</span>
              </th>
              <td class="has-text-right">
                <span v-if="proposal.sender.user && proposal.sender.user.first_name">
                  {{ proposal.sender.user.first_name }}
                  {{ proposal.sender.user.last_name }}
                  ({{ proposal.connection.brand.name }})
                </span>
                <span v-else class="has-text-grey-light is-italic">- Not Specified -</span>
              </td>
            </tr>
            <tr v-if="context.isMerchant()">
              <th>Merchant Owner</th>
              <td class="has-text-right">
                <span v-if="proposal.recipient.user && proposal.recipient.user.first_name">
                  {{ proposal.recipient.user.first_name }}
                  {{ proposal.recipient.user.last_name }}
                  ({{ proposal.connection.retailer.name }})
                </span>
                <span v-else class="has-text-grey-light is-italic">- Not Specified -</span>
              </td>
            </tr>
            <tr>
              <th>Proposal Type</th>
              <td class="has-text-right has-text-weight-bold">
                <span v-if="proposal.proposal_type === 'new_product'" class="has-text-success">New Products</span>
                <span v-else-if="proposal.proposal_type === 'cost_update'" class="has-text-grey">Cost Update</span>
                <span v-else-if="proposal.proposal_type === 'image_update'">Image Update</span>
                <span v-else-if="proposal.proposal_type === 'attribute_update'">Attribute Update</span>
                <span v-else class="has-text-grey-light is-italic">Legacy</span>
              </td>
            </tr>
            <tr>
              <th>Category</th>
              <td class="has-text-right">
                <span v-if="proposal.department !== null">{{ proposal.department.name }}</span>
                <span v-else class="has-text-grey-light is-italic">- Not Specified -</span>
              </td>
            </tr>
            <tr v-if="proposal.attachments.length > 0">
              <th>Imported File</th>
              <td class="has-text-right">
                <a :href="proposal.attachments[0].file" :download="proposal.attachments[0].filename">Download</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- Timestamps -->
      <div class="column is-half">
        <p class="has-text-primary has-text-weight-bold pb-sm has-border-b has-border-lg">Dates</p>
        <table class="table is-fullwidth is-striped is-size-7 is-narrow">
          <tbody>
            <!-- Proposed -->
            <tr>
              <th>Proposed</th>
              <td class="has-text-right">
                <span v-if="proposal.proposed_at !== null">{{ $dates.utc(proposal.proposed_at).local().format('MMM D, YYYY @ h:mm A')}}</span>
                <span v-else class="has-text-grey-light">--</span>
              </td>
            </tr>
            <!-- Proposal is Declined -->
            <template v-if="proposal.declined_at !== null">
              <tr>
                <th>Declined</th>
                <td class="has-text-right">
                  <span>{{ $dates.utc(proposal.declined_at).local().format('MMM D, YYYY @ h:mm A')}}</span>
                </td>
              </tr>
            </template>
            <!-- Proposal is still alive... -->
            <template v-else>
              <tr>
                <th>Assortment Approved</th>
                <td class="has-text-right">
                  <span v-if="proposal.approved_at !== null">{{ $dates.utc(proposal.approved_at).local().format('MMM D, YYYY @ h:mm A')}}</span>
                  <span v-else class="has-text-grey-light">--</span>
                </td>
              </tr>
              <tr v-if="'require_pricing_approval_on_proposals' in this.proposal.connection.options">
                <th>Pricing Approved</th>
                <td class="has-text-right">
                  <span v-if="proposal.pricing_approved_at !== null">{{ $dates.utc(proposal.pricing_approved_at).local().format('MMM D, YYYY @ h:mm A')}}</span>
                  <span v-else class="has-text-grey-light">--</span>
                </td>
              </tr>
              <tr>
                <th>Completed</th>
                <td class="has-text-right">
                  <span v-if="proposal.completed_at !== null">{{ $dates.utc(proposal.completed_at).local().format('MMM D, YYYY @ h:mm A')}}</span>
                  <span v-else class="has-text-grey-light">--</span>
                </td>
              </tr>
              <tr>
                <th>Received</th>
                <td class="has-text-right">
                  <span v-if="proposal.acknowledged_at !== null">{{ $dates.utc(proposal.acknowledged_at).local().format('MMM D, YYYY @ h:mm A')}}</span>
                  <span v-else class="has-text-grey-light">--</span>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <!-- Status -->
      <div class="column is-half">
        <p class="has-text-primary has-text-weight-bold pb-sm has-border-b has-border-lg">Status</p>
        <table class="table is-fullwidth is-striped is-size-7 is-narrow">
          <tbody>
            <tr>
              <th>Status</th>
              <td class="has-text-right">
                <Status :proposal="proposal" />
              </td>
            </tr>
            <tr>
              <th>Requires Action?</th>
              <td class="has-text-right">{{ (proposal.action_required) ? 'Yes' : 'No' }}</td>
            </tr>
            <tr>
              <th>Has Price Changes?</th>
              <td class="has-text-right">{{ (proposal.has_price_changes) ? 'Yes' : 'No' }}</td>
            </tr>
            <tr>
              <th>Is Revision?</th>
              <td class="has-text-right">{{ (proposal.has_revision) ? 'Yes' : 'No' }}</td>
            </tr>
            <tr>
              <th>Return Reason</th>
              <td class="has-text-right">
                <span v-if="proposal.return_reason && proposal.return_reason.return_reason !== 'Other'">
                  {{ (proposal.return_reason['return_reason']) }}
                </span>
                <span v-else-if="proposal.return_reason && proposal.return_reason.return_reason === 'Other'">
                  <a href="#" @click.prevent="viewReason" class="has-text-weight-bold has-text-info">View</a>
                </span>
                <span v-else class="has-text-grey-light">--</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- Items -->
      <div class="column is-half">
        <p class="has-text-primary has-text-weight-bold pb-sm has-border-b has-border-lg">Item Counts</p>
        <table class="table is-fullwidth is-striped is-size-7 is-narrow">
          <tbody>
            <tr>
              <th>Items Included</th>
              <td class="has-text-right">
                {{ proposal.item_count }}
              </td>
            </tr>
            <tr>
              <th>Items Rejected</th>
              <td class="has-text-right">
                {{ proposal.item_rejected_count }}
              </td>
            </tr>
            <tr>
              <th>Validations Passed</th>
              <td class="has-text-right">
                {{ proposal.item_ready_count }}
              </td>
            </tr>
            <tr>
              <th>Validations Failing</th>
              <td class="has-text-right">
                {{ proposal.item_failed_count }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
    <b-modal
      has-modal-card
      :active.sync="showViewReturnReasonModal"
      :can-cancel="['escape', 'x']"
      ><ViewReturnReasonModal :proposal="proposal" />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Status from '@/components/Models/Proposal/Presenters/Status'
import ViewReturnReasonModal from '@/components/Models/Proposal/Modals/ViewReturnReason'

export default {
  components: {
    Status,
    ViewReturnReasonModal
  },
  data () {
    return {
      showViewReturnReasonModal: false
    }
  },
  computed: {
    ...mapGetters(['context'])
  },
  methods: {
    viewReason () {
      this.showViewReturnReasonModal = true
    }
  },
  mounted () {

  },
  props: ['proposal', 'return_reason_detail']
}
</script>
