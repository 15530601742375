<template>
  <div>
    <div class="section">
      <div class="container">
        <!-- Page Header -->
        <div class="columns is-vcentered">
          <div class="column is-one-third">
            <breadcrumbs :history="[{ label: 'Network', name: 'network.dashboard'}]" current="Products" :hideBack="true" />
          </div>
          <div class="column is-one-third">
            <h1 class="has-text-centered is-size-4">Product Catalog</h1>
          </div>
        </div>
        <!-- Filters -->
        <div class="has-background-white rounded-lg pa-md mb-xxs">
          <div class="columns">
            <div class="column">
              <product-filters @filter="filter" :applied-filters="filters" :profile="profile" />
            </div>
            <div class="column is-narrow">
              <b-field >
                <b-select v-model="orderBy" expanded @input="sort" size="is-medium">
                  <option value="-id">Most Recently Added</option>
                  <option value="id">Oldest</option>
                  <option value="parent_name">Name (A-Z)</option>
                </b-select>
              </b-field>
            </div>
            <div class="column is-narrow">
              <b-dropdown position="is-bottom-left">
                <button class="button is-medium" slot="trigger">
                  <b-icon icon="dots-vertical"></b-icon>
                  <span>More</span>
                </button>
                <b-dropdown-item has-link><router-link :to="{ name: 'import' }">Import Products</router-link></b-dropdown-item>
                <b-dropdown-item>Export Products</b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column">

            <!-- Toolbar -->
            <div class="has-background-white rounded-lg px-sm mb-xxs">
              <div class="columns is-vcentered is-mobile is-marginless is-paddingless">
                <!-- Dropdown -->
                <div class="column is-narrow">
                  <b-dropdown :disabled="loading || selected.length === 0">
                    <button class="button" slot="trigger" slot-scope="{ active }">
                      <span>Actions</span>
                      <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
                    </button>
                    <b-dropdown-item><a href="#" @click.prevent="publish">Publish Selected Products</a></b-dropdown-item>
                  </b-dropdown>
                </div>
                <div class="column">
                  <div class="has-text-right is-size-7">
                    <!-- Counts -->
                    <p v-if="loading">Loading...</p>
                    <p v-else>{{ total }} products found.</p>
                  </div>
                </div>
              </div>
            </div>

            <!-- Loading -->
            <div v-if="loading">
              <div class="has-background-white rounded-lg pa-md mb-xxs" v-for="index in placeholders" :key="index">
                <div class="placeholder placeholder-title" style="width:66%"></div>
                <div class="placeholder placeholder-sentence" style="width:50%"></div>
              </div>
            </div>
            <!-- Loaded (None)  -->
            <div v-else-if="!loading && results.length === 0">
              <div class="has-background-white rounded-xl has-text-grey-light has-text-centered py-3xl">
                <p><b-icon icon="magnify-close" size="is-large"></b-icon></p>
                <p>No products found.</p>
              </div>
            </div>
            <!-- Loaded -->
            <div v-else>
              <div>
                <b-table
                  :data="results"
                  :checked-rows.sync="selected"
                  :mobile-cards="false"
                  :loading="loading || results.length === 0"
                  checkable
                  hoverable
                  class="is-size-7">
                  <template>
                    <!-- Image -->
                    <b-table-column
                      label=""
                      cell-class="nowrap"
                      hoverable
                      width="64"
                      centered
                      v-slot="props">
                      <figure class="image is-48x48" v-if="props.row.primary_media !== null">
                        <img :src="cdnTransform(props.row.primary_media.url, 48, 48, 'fill')" class="rounded-lg" />
                      </figure>
                      <figure class="image is-48x48" v-else>
                        <img :src="cdnTransform(null, 48, 48, 'fill')" class="rounded-lg" />
                      </figure>
                    </b-table-column>
                    <!-- Name -->
                    <b-table-column
                      field="name"
                      label="Product"
                      cell-class="nowrap"
                      v-slot="props">
                      <p class="has-text-weight-bold">
                        <a href="#" @click.prevent="loadDetail(props.row)">
                          <span class="is-hidden-mobile">{{ props.row.parent_name }}</span>
                          <span class="is-hidden-tablet is-size-7">{{ $utils.truncate(props.row.parent_name, {length: 48}) }}</span>
                        </a>
                      </p>
                      <p class="is-size-8">
                        <span class="is-size-8 has-text-grey">SKU</span>
                        <span class="is-size-7"> {{ props.row.parent_identifier }}</span>
                      </p>
                    </b-table-column>
                    <!-- Category -->
                    <b-table-column
                      field="name"
                      label="Category"
                      header-class="nowrap is-hidden-mobile"
                      cell-class="nowrap is-hidden-mobile"
                      width="200"
                      v-slot="props">
                      <p v-if="props.row.brand_category !== null">{{ props.row.brand_category }}</p>
                      <p v-else class="has-text-grey-light is-size-7"><i>Not Set</i></p>
                    </b-table-column>
                    <!-- Dropship Cost -->
                    <b-table-column
                      label="Dropship Cost"
                      header-class="nowrap is-hidden-mobile"
                      cell-class="nowrap is-hidden-mobile"
                      width="128"
                      centered
                      v-slot="props">
                      <DropshipCost :price="props.row.pricing.dropship_cost" />
                    </b-table-column>
                    <!-- MAP -->
                    <b-table-column
                      label="Min. Retail"
                      header-class="nowrap is-hidden-mobile"
                      cell-class="nowrap is-hidden-mobile"
                      width="128"
                      centered
                      v-slot="props">
                      <MapPrice :price="props.row.pricing.map_price" />
                    </b-table-column>
                    <!-- Status -->
                    <b-table-column
                      field="name"
                      label="Status"
                      centered
                      cell-class="is-tall"
                      width="128"
                      v-slot="props">
                      <p class="has-text-centered">
                        <b-tag v-if="props.row.rcn_status === 'published'" type="is-success">Published</b-tag>
                        <b-tag v-else-if="props.row.rcn_status === 'failed'" type="is-danger">Needs Data</b-tag>
                        <b-tag v-else-if="props.row.rcn_status === 'not_published'" type="is-info">Not Published</b-tag>
                        <b-tag v-else type="is-info">Pending</b-tag>
                      </p>
                    </b-table-column>
                    <!-- Link to Detail -->
                    <b-table-column
                      field="link"
                      label=" "
                      centered
                      class="is-hidden-mobile"
                      width="64"
                      v-slot="props">
                      <p class="has-text-centered">
                        <router-link :to="{ name: 'network.product', params: { id: props.row.id } }">
                          <b-icon icon="chevron-right" />
                        </router-link>
                      </p>
                    </b-table-column>
                  </template>
                </b-table>
              </div>
              <div class="mt-md">
                <b-pagination
                  :total="total"
                  :current.sync="currentPage"
                  :rounded="true"
                  :per-page="perPage"
                  @change="paginate">
                </b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Product Inspection Modal -->
    <b-modal :active="detail !== null" has-modal-card :can-cancel="['escape', 'x']" @close="detail = null" >
      <product-publish-modal :preview="detail" @update:product="load" />
    </b-modal>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CdnTransform from '@/mixins/CdnTransform.js'
import Filterable from '@/mixins/Filterable'
import ProductFilters from '../components/Products/ProductFilters'
import ProductPublishModal from '../components/ProductPublishModal'
import MapPrice from '@/components/Presenters/Product/MapPrice'
import DropshipCost from '@/components/Presenters/Product/DropshipCost'

export default {
  components: {
    ProductFilters,
    ProductPublishModal,
    MapPrice,
    DropshipCost
  },
  computed: {
    ...mapGetters(['context']),
    placeholders () {
      return Math.min(15, this.perPage)
    },
    endpoint () {
      return this.context.route + '/products/'
    },
    json () {
      const ids = []
      this.selected.forEach(product => {
        ids.push(product.id)
      })
      return {
        product_ids: ids
      }
    }
  },
  data () {
    return {
      key: 'products',
      alwaysAttach: {
        short: 1
      },
      selected: [],
      detail: null
    }
  },
  methods: {
    loadDetail (product) {
      this.detail = product
    },
    publish () {
      this.publishing = true
      this.$http.post(this.endpoint + 'bulk-publish/', this.json).then(response => {
        this.selected = []
        this.load()
      }).catch(() => {
        this.$buefy.toast.open({ type: 'is-danger', message: 'Product publishing failed!' })
      }).finally(() => {
        this.publishing = false
      })
    }
  },
  mixins: [CdnTransform, Filterable],
  mounted () {
    this.load()
  },
  props: ['profile']
}
</script>
