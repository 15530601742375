<template>
  <div>
    <!-- Content -->
    <div class="">
      <p class="has-text-weight-bold mb-md">Activate Shipments Intgration</p>
      <p class="mb-lg">
        {{ app.platform }} can automatically pull shipments from your Shipstation account.
        When you ship items on Shipstation, they will be automatically synced to fabric.
        Configure how your Shipstation account syncs shipments with {{ app.platform }}.
      </p>
      <div class="mb-lg">
        <p class="has-text-weight-bold mb-md">Pull Shipments from Shipstation?</p>
        <div class="columns is-mobile is-vcentered">
          <div class="column is-narrow">
            <div class="field">
              <b-switch
                v-model="sync"
                type="is-success"
                size="is-medium"
                true-value="Yes"
                false-value="No"
                :disabled="loading"
              >{{ sync }}</b-switch>
            </div>
          </div>
        </div>
        <div class="has-text-grey-light">
          <p v-if="loading">Checking current status...</p>
          <p v-else-if="webhook !== null">This integration is currently enabled.</p>
          <p v-else>This integration is currently disabled.</p>
        </div>
      </div>
    </div>
    <!-- Navigate -->
    <div class="py-lg">
      <div class="columns is-centered">
        <div class="column">
          <b-button
            type="is-light rounded-lg"
            size="is-medium"
            @click="goBack"
            expanded
            ><b-icon icon="arrow-left"></b-icon>
          </b-button>
        </div>
        <div class="column">
          <b-button
            type="is-primary rounded-lg"
            size="is-medium"
            expanded
            :disabled="loading || saving || !ready"
            @click="goForward"
          >Save &amp; Continue</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['app', 'context']),
    endpoint () {
      return this.context.route + '/shipstation/' + this.account.id + '/webhooks/'
    },
    ready () {
      return (this.account)
    },
    json () {
      return {
        webhook_topic: this.topic
      }
    }
  },
  data () {
    return {
      loading: true,
      saving: false,
      errors: {},
      topic: 'ITEM_SHIP_NOTIFY',
      webhook: null,
      webhooks: [],
      sync: 'Yes'
    }
  },
  methods: {
    loadWebhooks () {
      this.loading = true
      this.$http.get(this.endpoint).then(response => {
        this.webhooks = response.data.results
        if (this.webhooks.length) {
          const hook = this.$utils.find(this.webhooks, ['topic', this.topic])
          if (typeof hook !== 'undefined') {
            this.webhook = hook
          }
        }
        this.loading = false
      })
    },
    enable () {
      const endpoint = this.context.route + '/shipstation/' + this.account.id + '/register-webhook/'
      this.saving = true
      this.$http.put(endpoint, this.json).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Sync enabled!' })
        this.$emit('navigate', 'Activate')
      }).finally(() => {
        this.saving = false
      })
    },
    disable () {
      this.saving = true
      this.$http.delete(this.endpoint + this.webhook.id + '/').then(() => {
        this.webhook = null
        this.$buefy.toast.open({ type: 'is-success', message: 'Sync disabled!' })
        this.$emit('navigate', 'Activate')
      }).finally(() => {
        this.saving = false
      })
    },
    goBack () {
      this.$emit('navigate', 'Tags')
    },
    goForward () {
      if (this.sync === 'Yes' && this.webhook === null) {
        // if brand wants to subscribe and there is no current webhook, then create one...
        this.enable()
      } else if (this.sync === 'No' && this.webhook !== null) {
        // if the brand has a webhook and wants to turn it off, delete the webhook...
        this.disable()
      } else {
        // in all other cases, don't do anything...
        this.$buefy.toast.open({ type: 'is-success', message: 'Settings saved!' })
        this.$emit('navigate', 'Activate')
      }
    }
  },
  mounted () {
    this.loadWebhooks()
  },
  props: {
    connection: {
      type: Object,
      required: true
    },
    task: {
      type: Object,
      required: true
    },
    account: {
      type: Object
    }
  }
}
</script>
