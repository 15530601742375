<template>
  <div>
    <div class="section">
      <div class="container">
        <!-- Page Header -->
        <div class="mb-md">
          <div class="columns is-centered">
            <div class="column is-one-third">
              <h1 class="has-text-centered is-size-4">Billing &amp; Payments</h1>
            </div>
          </div>
        </div>
        <!-- Content -->
        <div class="mb-lg">
          <div class="columns is-multiline">
            <div v-for="month in dates" :key="month.label" class="column is-half">
              <div class="rounded-lg has-background-white pa-lg px-xl">
                <div class="columns is-vcentered is-mobile">
                  <div class="column">
                    <p class="has-text-weight-bold">{{ month.label }}</p>
                  </div>
                  <div class="column">
                    <b-select expanded placeholder="Select Period" @input="selectPeriod">
                      <option v-for="period in month.periods" :key="period.id" :value="period">{{ period.label }}</option>
                    </b-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['auth', 'context']),
    dates () {
      const months = []
      for (let i = 0; i < 12; i++) {
        const month = this.$dates().subtract(i, 'month')
        months.push({
          label: month.format('MMMM YYYY'),
          month: month.format('M'),
          year: month.format('YYYY'),
          periods: [{
            id: 1,
            label: month.startOf('month').format('MMM D') + '-' + month.clone().date(14).format('D'),
            params: {
              year: month.format('YYYY'),
              month: month.format('M'),
              day: month.startOf('month').format('D')
            }
          }, {
            id: 2,
            label: month.clone().date(15).format('MMM D') + '-' + month.clone().endOf('month').format('D'),
            params: {
              year: month.format('YYYY'),
              month: month.format('M'),
              day: month.date(15).format('D')
            }
          }, {
            id: 3,
            label: 'Full Month',
            params: {
              year: month.format('YYYY'),
              month: month.format('M'),
              day: null
            }
          }]
        })
      }
      return months
    }
  },
  methods: {
    selectPeriod (period) {
      this.$router.push({ name: 'finance.detail.summary', params: period.params })
    }
  },
  props: ['year', 'month']
}
</script>
