<template>
  <div>
    <!-- Actions Toolbar -->
    <div class="has-background-white rounded-lg px-md py-sm mb-xxs">
      <div class="columns is-vcentered is-mobile">
        <div class="column is-narrow">
          <b-dropdown :disabled="loading || selected.length === 0">
            <button class="button" slot="trigger" slot-scope="{ active }" :disabled="selected.length === 0">
              <span>Actions</span>
              <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
            </button>
            <b-dropdown-item has-link><a href="#" @click.prevent class="has-text-grey-light">No Actions</a></b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="column">
          <div class="has-text-right is-size-7">
            <!-- Counts -->
            <p v-if="loading">Loading...</p>
            <p v-else>
              {{ envelopes.length }}
              {{ (envelopes.length === 1) ? 'envelope' : 'envelopes' }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- Reults -->
    <div style="overflow-x:auto; overflow-y:hidden">
      <b-table
        :data="envelopes"
        :checked-rows.sync="selected"
        :mobile-cards="false"
        :loading="loading"
        hoverable
        checkable
        checkbox-position="left"
        class="is-size-7">
        <template>
          <!-- ID # -->
          <b-table-column
            label="Envelope ID"
            header-class="nowrap"
            cell-class="nowrap"
            width="96"
            v-slot="props">
            <a href="#"
              class="has-text-weight-bold has-text-info"
              @click.prevent="inspectEnvelope = props.row"
            >{{ props.row.id }}</a>
          </b-table-column>
          <!-- Identifier/Group -->
          <b-table-column
            label="Group"
            header-class="nowrap"
            cell-class="nowrap"
            centered
            width="96"
            v-slot="props">
            {{ props.row.functional_identifier }}
          </b-table-column>
          <!-- ICN -->
          <b-table-column
            label="ICN"
            header-class="nowrap"
            cell-class="nowrap"
            width="128"
            v-slot="props">
            {{ props.row.interchange_control_number }}
          </b-table-column>
          <!-- DICN -->
          <b-table-column
            label="DICN"
            header-class="nowrap"
            cell-class="nowrap"
            v-slot="props">
            {{ props.row.data_interchange_control_number }}
          </b-table-column>
          <!-- Transmitted -->
          <b-table-column
            label="Transmitted"
            header-class="nowrap"
            cell-class="nowrap"
            centered
            width="128"
            v-slot="props">
            <span v-if="props.row.transmitted_at">{{ $dates.utc(props.row.transmitted_at).local().format('MMM DD, YYYY')}}</span>
            <span v-else class="has-text-grey-light">--</span>
          </b-table-column>
          <!-- Accepted -->
          <b-table-column
            label="Accepted"
            header-class="nowrap"
            cell-class="nowrap"
            centered
            width="128"
            v-slot="props">
            <span v-if="props.row.accepted_at !== null">{{ $dates.utc(props.row.accepted_at).local().format('MMM D, YYYY') }}</span>
            <span v-else class="has-text-grey-light">--</span>
          </b-table-column>
          <!-- Num Documents -->
          <b-table-column
            label="# Docs"
            header-class="nowrap"
            cell-class="nowrap"
            centered
            width="96"
            v-slot="props">
            {{ props.row.num_documents }}
          </b-table-column>
        </template>
        <template #empty>
          <div class="py-lg has-text-grey has-text-centered">
            <p>No envelopes have been registered yet.</p>
          </div>
        </template>
      </b-table>
    </div>
    <!-- Inspect Modal -->
    <b-modal
      v-if="(inspectEnvelope !== null)"
      has-modal-card
      :active="(inspectEnvelope !== null)"
      :can-cancel="['escape', 'x']"
      @close="closeModals"
      ><EnvelopeModal :selectedEnvelope="inspectEnvelope" />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import EnvelopeModal from '@/components/Modals/Envelope/EnvelopeModal'

export default {
  components: {
    EnvelopeModal
  },
  data () {
    return {
      loading: false,
      selected: [],
      inspectEnvelope: null
    }
  },
  computed: {
    ...mapGetters(['context'])
  },
  methods: {
    closeModals () {
      this.inspectEnvelope = null
    }
  },
  mounted () {

  },
  props: ['orderId', 'envelopes']
}
</script>
