<template>
    <div>
      <div class="section">
        <div class="container">
          <!-- Page Header -->
          <div class="mb-md">
            <div class="columns is-vcentered">
              <div class="column is-one-third">
                <breadcrumbs :history="breadcrumbs.history" :current="breadcrumbs.current" />
              </div>
              <div class="column is-one-third">
                <h1 class="has-text-centered is-size-4">WooCommerce Settings</h1>
              </div>
            </div>
          </div>
          <!-- Push Orders (Suppliers) -->
          <div v-if="!loading && account !== null && context.isSupplier()" class="border-b mb-xl pb-xl">
            <PushOrders :account="account" @update:account="load(true)" />
          </div>
          <!-- Pull Fulfillments (Suppliers) -->
          <div v-if="!loading && account !== null && context.isSupplier()" class="border-b mb-xl pb-xl">
            <PullFulfillments :account="account" />
          </div>
          <!-- Inventory Webhook (Suppliers & Merchants) -->
          <div v-if="!loading && account !== null">
            <Inventory :account="account" />
          </div>
        </div>
      </div>
    </div>
  </template>

<script>
import { mapGetters } from 'vuex'
import Inventory from '../components/Woocommerce/Inventory'
import PushOrders from '../components/Woocommerce/PushOrders'
import PullFulfillments from '../components/Woocommerce/PullFulfillments'

export default {
  components: {
    Inventory,
    PullFulfillments,
    PushOrders
  },
  data () {
    return {
      loading: true,
      account: null
    }
  },
  computed: {
    ...mapGetters(['context']),
    breadcrumbs () {
      return {
        current: (this.loading) ? 'Loading...' : 'WooCommerce',
        history: [
          { label: 'Settings', name: 'settings' },
          { label: 'Integrations', name: 'settings.integrations' }
        ]
      }
    },
    endpoint () {
      return this.context.route + '/platform-accounts/' + this.$route.params.id + '/'
    }
  },
  methods: {
    load (refresh = false) {
      this.loading = (!refresh)
      this.$http.get(this.endpoint).then(response => {
        this.account = response.data
      }).finally(() => {
        this.loading = false
      })
    }
  },
  mounted () {
    this.load()
  },
  props: []
}
</script>
