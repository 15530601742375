<template>
  <div>
    <!-- Fabric Logo -->
    <p v-if="app.platformCode === 'fabric'">
      <FabricLogo :color="color" />
    </p>
    <!-- RC Logo -->
    <p v-else>
      <RevCascadeLogo />
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FabricLogo from '@/components/Logos/Fabric'
import RevCascadeLogo from '@/components/Logos/RevCascade'

export default {
  components: {
    FabricLogo,
    RevCascadeLogo
  },
  computed: {
    ...mapGetters(['app'])
  },
  props: {
    color: {
      type: String,
      default: 'black'
    }
  }
}
</script>
