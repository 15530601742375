<template>
  <div class="section">
    <div class="container">
      <!-- Page Header -->
      <div class="columns is-vcentered">
        <div class="column is-one-third">
          <breadcrumbs :history="breadcrumbs" current="Import" :hideBack="true" />
        </div>
        <div class="column is-one-third">
          <h1 class="has-text-centered is-size-4">Import Products</h1>
        </div>
      </div>
      <!-- Action Items -->
      <div class="section">
        <div class="container">
          <div class="columns is-centered">
            <div class="column is-two-thirds-tablet">

              <!-- Loading -->
              <div v-if="loading" class=" mb-lg pa-xxl px-xl">
                <loading message="Loading..."></loading>
              </div>
              <!-- Content Ready -->
              <div v-else>
                <!-- Import from File -->
                <div class="rounded-lg has-background-white mb-xs pa-lg px-xl">
                  <div class="columns is-mobile">
                    <div class="column">
                      <div class="">
                        <h4 class="has-text-weight-bold mb-sm">
                          <router-link :to="{ name: 'import.file' }">Import from File</router-link>
                        </h4>
                        <p class="has-text-grey">
                          Upload a template file to add or update product data.
                        </p>
                      </div>
                    </div>
                    <div class="column is-narrow">
                      <router-link class="has-text-info" :to="{ name: 'import.history' }">History</router-link>
                    </div>
                  </div>
                </div>
                <!-- Import from Platform (Vendors Only) -->
                <div v-if="context.type === 'vendor' && accounts.length > 0" class="rounded-lg has-background-white mb-xs pa-lg px-xl">
                  <div class="columns is-mobile">
                    <div class="column">
                      <div class="">
                        <h4 class="has-text-weight-bold mb-sm">
                          <router-link :to="{ name: 'import.shopify' }">Import from Platform</router-link>
                        </h4>
                        <p class="has-text-grey">
                          Import your products from a connected platform (e.g. Shopify)
                        </p>
                      </div>
                    </div>
                    <div class="column is-narrow">
                      <router-link class="has-text-info" :to="{ name: 'import.jobs' }">History</router-link>
                    </div>
                  </div>
                </div>

                <!-- Connect -->
                <div v-if="accounts.length === 0" class="has-text-centered">
                  <p class="has-text-grey mb-sm">Do you use Shopify? Connect your store to import products!</p>
                  <p><a href="#" @click.prevent="showShopifyModal = true">Connect Shopify</a></p>
                </div>
              </div>

            </div>
          </div>

          <!-- Shopify Connect Modal -->
          <b-modal :active.sync="showShopifyModal" has-modal-card :can-cancel="['escape', 'x']">
            <shopify-connect-modal />
          </b-modal>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ShopifyConnectModal from '@/modules/Shopify/components/ConnectModal'
import { mapGetters } from 'vuex'
export default {
  components: {
    ShopifyConnectModal
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/platform-accounts/?platform=shopify'
    },
    breadcrumbs () {
      return [
        { label: 'Network', name: 'network.dashboard' },
        { label: 'Products', name: 'network.products' }
      ]
    }
  },
  data () {
    return {
      loading: true,
      accounts: [],
      showShopifyModal: false
    }
  },
  methods: {
    loadPlatformAccounts () {
      this.loading = true
      this.$http.get(this.endpoint).then(response => {
        this.accounts = response.data.results
        this.loading = false
      })
    }
  },
  mounted () {
    this.loadPlatformAccounts()
  }
}
</script>
