<template>
  <div class="has-text-centered is-size-7 has-text-grey-light py-lg">

    <p class="mb-xs">
      &copy; {{ $dates().format('YYYY') }} {{ app.companyName }}
      &bull;
      <a class="has-text-grey-light" :href="app.privacyUrl" target="_blank">Privacy Policy</a>
      &bull;
      <a class="has-text-grey-light" :href="app.termsOfUseUrl" target="_blank">Terms of Use</a>
    </p>
    <p class="mb-xs">
      App Version {{ app.version }}
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['app'])
  },
  methods: {
    hardReload () {
      window.location.reload()
    }
  }
}
</script>
