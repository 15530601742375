<template>
  <div class="section">
    <div class="container">
      <!-- Page Header -->
      <div class="mb-md">
        <div class="columns is-vcentered is-centered">
          <div class="column is-one-third">
            <h1 class="has-text-centered is-size-4">User Preferences</h1>
          </div>
        </div>
      </div>
      <div>
        <!-- Default Landing Page settings  -->
        <div class="mb-lg">
          <DefaultLandingPage />
        </div>
        <!-- Manage Accounts  -->
        <div class="mb-lg">
          <ManageAccounts />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DefaultLandingPage from '../components/DefaultLandingPage'
import ManageAccounts from '../components/ManageAccounts'

export default {
  components: {
    DefaultLandingPage,
    ManageAccounts
  },
  computed: {
    ...mapGetters(['context'])
  },
  data () {
    return {
      loading: false
    }
  }
}
</script>
