<template>
  <div>
    <div v-if="loading">
      <loading message="Loading issue..."/>
    </div>
    <div v-else>
      <div class="has-background-white rounded-lg px-lg py-md mb-xxs">
        <div class="columns">
          <div class="column">
            <p class="is-size-5">{{issue.title}}</p>
            <p class="is-size-7">Description: {{issue.message}}</p>
            <p class="has-text-grey is-size-7">Added By {{userName}} on {{$dates.utc(issue.created_at).local().format('MMM D, YYYY')}}</p>
          </div>
          <div class="column is-narrow has-text-right">
            <IssueStatus :issue="this.issue" />
          </div>
        </div>
      </div>
      <div class="has-background-white rounded-lg py-md px-lg mb-md">
        <div class="columns is-vcentered is-variable is-2">
          <div class="column is-one-quarter" v-for="(action, index) in actions" :key="index">
            <b-button v-if="action.type === 'primary'" type="is-primary" outlined expanded size="is-medium" @click="action.event">{{ action.label }}</b-button>
            <b-button v-else type="is-light" expanded size="is-medium" :disabled="action.disabled" @click="action.event">{{ action.label }}</b-button>
          </div>
        </div>
      </div>
      <!-- Affected Items -->
      <div class="mb-md">
        <div class="has-background-white rounded-lg pa-lg">
          <div class="has-text-centered">
            <p class="is-size-5">Items</p>
          </div>
          <Variants :issue="issue" />
        </div>
      </div>
      <!-- Notes -->
      <div class="mb-md">
        <div class="has-background-white rounded-lg pa-lg">
          <div class="has-text-centered mb-md">
            <p class="is-size-5">Notes</p>
            <p class="has-text-grey is-size-7">Notes that can be seen by all parties.</p>
          </div>
          <IssueNotes :issue="issue" />
        </div>
      </div>
    </div>
    <!-- Archive Modal -->
    <b-modal
      has-modal-card
      :active.sync="showArchiveIssueModal"
      :can-cancel="['escape', 'x']"
      ><ArchiveModal :type="'issue'" :issue="issue" @issue:archived="returnToList" />
    </b-modal>
    <!-- Close Issue Modal -->
    <b-modal
      has-modal-card
      :active.sync="showCloseIssueModal"
      :can-cancel="['escape', 'x']"
      ><CloseIssueModal :issue="issue" @issue:closed="returnToList" />
    </b-modal>
    <!-- Resolve Issue Modal -->
    <b-modal
      has-modal-card
      :active.sync="showResolveIssueModal"
      :can-cancel="['escape', 'x']"
      ><ResolveIssueModal :issue="issue" @issue:resolved="returnToList" />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ArchiveModal from '@/components/Models/Proposal/Modals/Archive'
import CloseIssueModal from '@/components/Models/ProposalIssue/Modals/Close'
import ResolveIssueModal from '@/components/Models/ProposalIssue/Modals/Resolve'
import Variants from '../components/IssueDetails/Variants'
import IssueNotes from '../components/IssueDetails/Notes'
import IssueStatus from '../components/IssueDetails/IssueStatus'

export default {
  components: {
    ArchiveModal,
    CloseIssueModal,
    ResolveIssueModal,
    Variants,
    IssueNotes,
    IssueStatus
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/proposals/' + this.$route.params.id + '/issues/' + this.$route.params.issueId + '/'
    },
    actions () {
      const actions = []
      // Supplier Actions
      if (this.context.isSupplier()) {
        if (!this.issue.is_closed && !this.issue.is_resolved) {
          actions.push({ label: 'Resolve Issue', event: this.resolveIssue, type: 'primary' })
        } else {
          actions.push({ label: 'No Actions', event: '', type: 'secondary', disabled: true })
        }
      }
      // Merchant Actions
      if (this.context.isMerchant()) {
        if (!this.issue.is_closed) {
          actions.push({ label: 'Close Issue', event: this.closeIssue, type: 'primary' })
        }
        if (this.issue.is_closed && !this.issue.is_archived) {
          actions.push({ label: 'Archive Issue', event: this.archiveIssue, type: 'secondary' })
        }
        if (this.issue.is_archived) {
          actions.push({ label: 'No Actions', event: '', type: 'secondary', disabled: true })
        }
      }
      return actions
    },
    userName () {
      let name = this.issue.created_by.first_name
      if (this.issue.created_by.last_name) {
        name = (`${this.issue.created_by.first_name} ${this.issue.created_by.last_name}`)
      }
      return name
    }
  },
  data: () => {
    return {
      issue: null,
      loading: true,
      showCloseIssueModal: false,
      showArchiveIssueModal: false,
      showResolveIssueModal: false
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint).then(response => {
        this.issue = response.data
        this.loading = false
      })
    },
    closeIssue () {
      this.showCloseIssueModal = true
    },
    archiveIssue () {
      this.showArchiveIssueModal = true
    },
    resolveIssue () {
      this.showResolveIssueModal = true
    },
    returnToList () {
      this.$emit('proposal:updated')
      this.$router.push({ name: 'proposal.detail.issues', params: { id: this.$route.params.id } })
    }
  },
  mounted () {
    this.load()
  },
  props: {
  }
}
</script>
