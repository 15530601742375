<template>
  <div>
    <filter-toolbar
      :hasChanges="hasChanges"
      :appliedFilters="appliedFilters"
      @search="search"
      @update="update"
      @clear="clear">
      <remote-facet-filter
        class="border-b"
        v-model="filters.facets.connection_id"
        response-type="object"
        :resourceLabel="(context.isSupplier()) ? 'retailer.name' : 'brand.name'"
        :filter="filterOptions.connection"
        :endpoint="context.route + '/connections/?short=1'"
        @input="change"
      />
      <tag-filter
        class="border-b"
        v-model="filters.facets.tags"
        :resourceLabel="(context.isSupplier()) ? 'retailer.name' : 'brand.name'"
        :filter="filterOptions.tags"
        :endpoint="context.route + '/tags'"
        @input="change"
      />
      <facet-filter
        class="border-b"
        v-model="filters.facets.status"
        :filter="filterOptions.status"
        @input="change"
      />
      <boolean-filter
        class="border-b"
        v-model="filters.facets.is_acknowledged"
        :filter="filterOptions.acknowledged"
        @input="change"
      />
      <boolean-filter
        class="border-b"
        v-model="filters.facets.is_backordered"
        :filter="filterOptions.backordered"
        @input="change"
      />
      <boolean-filter
        v-if="context.isMerchant()"
        class="border-b"
        v-model="filters.facets.backorder_is_acknowledged"
        :filter="filterOptions.backorderAcknowledged"
        @input="change"
      />
      <boolean-filter
        class="border-b"
        v-model="filters.facets.not_fully_shipped"
        :filter="filterOptions.fulfilled"
        @input="change"
      />
      <boolean-filter
        class="border-b"
        v-model="filters.facets.not_fully_invoiced"
        :filter="filterOptions.invoiced"
        @input="change"
      />
      <boolean-filter
        class="border-b"
        v-model="filters.facets.has_unread_memos"
        :filter="filterOptions.unreadMemos"
        @input="change"
      />
      <text-filter
        class="border-b"
        v-model="filters.facets.variant_identifier"
        :filter="filterOptions.item"
        @input="change"
      />
      <text-filter
        class="border-b"
        v-model="filters.facets.retailer_identifier"
        :filter="filterOptions.retailerSku"
        @input="change"
      />
      <date-filter
        class="border-b"
        v-model="filters.timestamps.fulfill_by"
        :filter="filterOptions.fulfillBy"
        @input="change"
      />
      <date-filter
        class="border-b"
        v-model="filters.timestamps.closed_at"
        :filter="filterOptions.closedAt"
        @input="change"
      />
      <date-filter
        class="border-b"
        v-model="filters.timestamps.backordered_until"
        :filter="filterOptions.backorderedUntil"
        @input="change"
      />
      <date-filter
        v-model="filters.timestamps.created_at"
        :filter="filterOptions.orderedAt"
        @input="change"
      />
    </filter-toolbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import * as filterOptions from '../../filters/orders.js'
import FilterToolbar from '@/components/Filters/FilterToolbar'
import FacetFilter from '@/components/Filters/FacetFilter'
import BooleanFilter from '@/components/Filters/BooleanFilter'
import DateFilter from '@/components/Filters/DateFilter'
import RemoteFacetFilter from '@/components/Filters/RemoteFacetFilter'
import TextFilter from '@/components/Filters/TextFilter'
import TagFilter from '../../components/Tags/TagFilter.vue'

export default {
  components: {
    BooleanFilter,
    DateFilter,
    FacetFilter,
    FilterToolbar,
    RemoteFacetFilter,
    TextFilter,
    TagFilter
  },
  data () {
    return {
      hasChanges: false,
      filters: null,
      // filters
      filterOptions: filterOptions
    }
  },
  computed: {
    ...mapGetters(['context'])
  },
  methods: {
    change () {
      this.hasChanges = true
    },
    search (searchQuery) {
      this.filters.search = {
        parameter: 'search_order_number',
        query: searchQuery
      }
      this.$emit('filter', this.filters)
    },
    update () {
      this.hasChanges = false
      this.filters.preset = 'custom'
      this.$emit('filter', this.filters)
    },
    clear () {
      this.filters = {
        preset: 'all',
        facets: {},
        search: {},
        timestamps: {}
      }
      this.$emit('filter', this.filters)
    }
  },
  mounted () {},
  props: {
    appliedFilters: {
      type: Object,
      required: true
    }
  },
  watch: {
    appliedFilters: {
      deep: true,
      immediate: true,
      handler (filters) {
        this.filters = filters
      }
    }
  }
}
</script>
