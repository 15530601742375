<template>
  <div>
    <!-- Content -->
    <div class="has-background-white rounded-lg pa-lg">
      <div class="columns is-multiline">
        <!-- Identifiers -->
        <div class="column is-half">
          <p class="has-text-primary has-text-weight-bold pb-sm has-border-b has-border-lg" >Order Identifiers</p>
          <table class="table is-fullwidth is-striped is-size-7 is-narrow">
            <tbody>
              <tr>
                <th>{{ partner.label }}</th>
                <td class="has-text-right">
                  <a href="#" @click.prevent="showConnectionModal(order.connection_id)" class="has-text-weight-bold has-text-info">{{ partner.name }}</a>
                </td>
              </tr>
              <tr>
                <th>Customer Order #</th>
                <td class="has-text-right">
                  <span v-if="order.customer_order_number !== null">{{ order.customer_order_number }}</span>
                  <span v-else class="has-text-grey-light">- Not Set -</span>
                </td>
              </tr>
              <tr>
                <th>Purchase Order #</th>
                <td class="has-text-right">{{ order.purchase_order_number }}</td>
              </tr>
              <tr v-if="order.retailer_order_number !== null">
                <th>Retailer Order #</th>
                <td class="has-text-right" >
                  <p v-if="context.isMerchant() && isPlatformOrder && order.retailer_order_number">
                    <a href="#" @click.prevent="showPlatformOrderModal = true"
                      class="has-text-weight-bold has-text-info"
                    >{{ order.retailer_order_number }}</a>
                  </p>
                  <p v-else-if="order.retailer_order_number">{{ order.retailer_order_number }}</p>
                  <p v-else class="has-text-grey-light">- Not Set -</p>
                </td>
              </tr>
              <tr>
                <th>Supplier Order #</th>
                <td class="has-text-right" >
                  <p v-if="context.isSupplier() && isPlatformOrder && order.brand_identifier">
                    <a href="#" @click.prevent="showPlatformOrderModal = true"
                      class="has-text-weight-bold has-text-info"
                    >{{ order.brand_identifier }}</a>
                  </p>
                  <p v-else-if="order.brand_identifier">{{ order.brand_identifier }}</p>
                  <p v-else class="has-text-grey-light">- Not Set -</p>
                </td>
              </tr>
              <tr>
                <th>fabric ID</th>
                <td class="has-text-right">{{ order.id }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- Dates -->
        <div class="column is-half">
          <p class="has-text-primary has-text-weight-bold pb-sm has-border-b has-border-lg">Key Dates</p>
          <table class="table is-fullwidth is-striped is-size-7 is-narrow">
            <tbody>
              <tr>
                <th>Received</th>
                <td class="has-text-right">
                  <p :title="$dates.utc(order.received_at).local().format('ddd MMM D, YYYY h:mm A')">
                    {{ $dates.utc(order.received_at).local().format('MMM D, YYYY') }}
                  </p>
                </td>
              </tr>
              <tr>
                <th>Due</th>
                <td class="has-text-right">
                  {{ $dates.utc(order.fulfill_by).local().format('MMM D, YYYY') }}
                </td>
              </tr>
              <tr v-if="order.backordered_until" >
                <th class="has-text-warning">Backordered Until</th>
                <td class="has-text-right">
                  {{ $dates.utc(order.backordered_until).local().format('MMM D, YYYY') }}
                  <span v-if="order.backorder_acknowledged_at" class="has-text-grey-light is-italic">(Accepted)</span>
                  <span v-else class="has-text-grey-light is-italic">(New)</span>
                </td>
              </tr>
              <tr>
                <th>Accepted</th>
                <td class="has-text-right">
                  <p v-if="order.status === 'canceled'" class="has-text-grey is-italic">
                    Order Canceled
                  </p>
                  <p v-else-if="order.acknowledged_at !== null" :title="$dates.utc(order.acknowledged_at).local().format('ddd MMM D, YYYY h:mm A')">
                    {{ $dates.utc(order.acknowledged_at).local().format('MMM D, YYYY') }}
                  </p>
                  <p v-else class="has-text-grey is-italic">Not Accepted Yet</p>
                </td>
              </tr>
              <tr>
                <th>Closed</th>
                <td class="has-text-right">
                  <p v-if="order.status === 'canceled'" class="has-text-grey is-italic">
                    Order Canceled
                  </p>
                  <p v-else-if="order.closed_at !== null" :title="$dates.utc(order.closed_at).local().format('ddd MMM D, YYYY h:mm A')">
                    {{ $dates.utc(order.closed_at).local().format('MMM D, YYYY') }}
                  </p>
                  <p v-else class="has-text-grey is-italic">Not Closed Yet</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- Shipping -->
        <div class="column is-half">
          <p class="has-text-primary has-text-weight-bold pb-sm has-border-b has-border-lg">Shipping</p>
          <table class="table is-fullwidth is-striped is-size-7 is-narrow">
            <tbody>
              <tr v-if="order.shipping_method !== null && order.shipping_method.name !== null">
                <th>Shipping Method</th>
                <td class="has-text-right">
                  <p>
                    {{ order.shipping_method.name }}
                    <span class="is-size-7 has-text-grey">from</span>
                    {{ order.shipping_method.carrier.name }}
                  </p>
                </td>
              </tr>
              <tr>
                <th>Service Level</th>
                <td class="has-text-right">
                  <p v-if="order.shipping_method !== null && order.shipping_method.name !== null">
                    {{ order.shipping_method.generic_shipping_method.name }}
                  </p>
                  <p v-else>
                    {{ order.requested_shipping_method.name }}
                  </p>
                </td>
              </tr>
              <tr>
                <th>Ship To</th>
                <td class="has-text-right">
                  <customer :customer="order.ship_to" />
                </td>
              </tr>
              <tr v-if="order.sold_to !== null">
                <th>Sold To</th>
                <td class="has-text-right">
                  <customer :customer="order.sold_to" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- Extras -->
        <div class="column is-half">
          <p class="has-text-primary has-text-weight-bold pb-sm has-border-b has-border-lg">Extras</p>
          <table class="table is-fullwidth is-striped is-size-7 is-narrow is-vcentered">
            <tbody>
              <tr v-if="packingSlip !== null">
                <th>Packing Slip</th>
                <td class="has-text-right">
                  <a :href="packingSlip.file" target="_blank">Link</a>
                </td>
              </tr>
              <tr>
                <th>Is Gift?</th>
                <td class="has-text-right">
                  <p :class="(order.is_gift) ? 'has-text-danger' : ''" v-text="(order.is_gift) ? 'Yes' : 'No'"></p>
                </td>
              </tr>
              <tr>
                <th>Gift Message</th>
                <td class="has-text-right">
                  <div v-if="order.gift_message !== null">
                    <p v-for="(line, index) in order.gift_message" :key="index">{{ line }}</p>
                  </div>
                  <p v-else class="has-text-grey is-italic">No Gift Message</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- Order Tags -->
        <div class="column is-half">
          <div class="pb-sm has-border-b has-border-lg">
            <div class="columns">
              <div class="column">
                <p class="has-text-primary has-text-weight-bold">Order Tags</p>
              </div>
            </div>
          </div>
          <div class="py-sm">
            <div class="columns is-vcentered">
              <div class="column">
                <p v-if="tags.length === 0" class="is-size-7 has-text-grey is-italic">No Tags</p>
                <b-taglist class="">
                  <b-tag v-for="tag in tags" :key="tag.id" type="is-info is-light" size="is-medium" attached>{{ tag.tag }}</b-tag>
                </b-taglist>
              </div>
              <div class="column is-narrow">
                <a href="#" @click.prevent="editTags" class="has-text-info has-text-weight-bold is-size-7">
                  <span v-if="!editOrAdd">Add or Edit Tags</span>
                  <span v-else>Finish Editing</span>
                </a>
              </div>
            </div>
          </div>
          <!-- Order Tag Input -->
          <div v-if="editOrAdd" class="pt-lg">
            <p class="label">Edit Tags</p>
            <Tags :order="order" :orderTags="tags" @updateTag="updateTag" class="mb-sm" />
            <p class="is-size-7 has-text-grey-light">Tags are saved as soon as they are added or removed to the input box above.</p>
          </div>
        </div>
      </div>
    </div>

    <!-- Inspect Connection Modal -->
    <b-modal
      v-if="(inspectConnection !== null)"
      has-modal-card
      :active="(inspectConnection !== null)"
      :can-cancel="['escape', 'x']"
      @close="resetModals"
      ><connection-modal :seed="inspectConnection"></connection-modal>
    </b-modal>
    <!-- Inspect Platform Order -->
    <b-modal
      v-if="platformAccount"
      has-modal-card
      :active.sync="showPlatformOrderModal"
      :can-cancel="['escape', 'x']"
      ><PlatformOrderModal :order="order" :platformAccount="platformAccount" />
    </b-modal>
    <!-- display more tags -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showTagsModal"
      :can-cancel="['x']">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Order Tags</p>
        </header>
        <section class="modal-card-body">
          <b-taglist>
            <b-tag v-for="{ tag } in tags" :key="tag"
              style="margin-bottom: 10px;"
              type="is-light"
              attached>
              {{ tag }}
            </b-tag>
          </b-taglist>
        </section>
        <footer class="modal-card-foot">
          <div>
            <b-button @click="showTagsModal=false">Close</b-button>
          </div>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import OrderPresenters from '@/mixins/presenters/Order'
import ConnectionModal from '@/components/Models/Connection/Modals/ConnectionModal'
import PlatformOrderModal from '@/components/Models/PlatformAccount/Modals/PlatformOrder'
import Tags from '../Tags/Tags.vue'
import Customer from './Customer'

export default {
  components: {
    ConnectionModal,
    Customer,
    PlatformOrderModal,
    Tags
  },
  computed: {
    ...mapGetters(['context']),
    isPlatformOrder () {
      return Boolean(
        this.platformAccount &&
        this.platformAccount.platform.code === 'shopify'
      )
    },
    packingSlip () {
      const slip = this.$utils.find(this.order.attachments, ['filename', 'packing_slip.pdf'])
      return (typeof slip !== 'undefined') ? slip : null
    }
  },
  data () {
    return {
      inspectConnection: null,
      showPlatformOrderModal: false,
      showTagsModal: false,
      editOrAdd: false,
      tags: []
    }
  },
  methods: {
    editTags () {
      this.editOrAdd = !this.editOrAdd
    },
    updateTag (data) {
      this.tags = data
      this.$emit('updateTag', data)
    },
    showConnectionModal (payload) {
      this.inspectConnection = {
        id: payload
      }
    },
    resetModals () {
      this.inspectConnection = null
    }
  },
  mounted () {
    this.tags = this.order.tags
  },
  mixins: [OrderPresenters],
  props: {
    order: {
      type: Object,
      required: true
    },
    platformAccount: {
      type: [Object, null]
    }
  }
}
</script>

<style scoped>
  .tagDiv {
    margin-right: 2rem;
    margin-top: 3px;
  }
  .tags .tag {
    margin-bottom: 0;
  }
</style>
