import dates from 'dayjs'
export default {
  new: {
    label: 'New',
    tooltip: 'Orders that have not been accepted.',
    filters () {
      return {
        preset: 'new',
        search: {},
        facets: {
          status: [{
            label: 'Open',
            value: 'open,partial'
          }],
          is_acknowledged: [{
            label: 'No',
            value: '0'
          }]
        },
        timestamps: {}
      }
    }
  },
  open: {
    label: 'Open',
    tooltip: 'Orders that are open.',
    filters () {
      return {
        preset: 'open',
        search: {},
        facets: {
          status: [{
            label: 'Open',
            value: 'open,partial'
          }]
        },
        timestamps: {}
      }
    }
  },
  backordered: {
    label: 'Backordered',
    tooltip: 'Orders that are open, but contain 1 or more backordered lines.',
    filters () {
      return {
        preset: 'backordered',
        search: {},
        facets: {
          status: [{
            label: 'Open',
            value: 'open,partial'
          }],
          is_backordered: [{
            label: 'Yes',
            value: '1'
          }]
        },
        timestamps: {}
      }
    }
  },
  newBackorders: {
    label: 'New Backorders',
    tooltip: 'New backorders from suppliers',
    filters () {
      return {
        preset: 'newBackorders',
        search: {},
        facets: {
          status: [{
            label: 'Open',
            value: 'open,partial'
          }],
          is_backordered: [{
            label: 'Yes',
            value: '1'
          }],
          backorder_is_acknowledged: [{
            label: 'No',
            value: '0'
          }]
        },
        timestamps: {}
      }
    }
  },
  unread: {
    label: 'Messages',
    tooltip: 'Orders that have unread messages from your trading partners.',
    filters () {
      return {
        preset: 'unread',
        search: {},
        facets: {
          status: [{
            label: 'Open',
            value: 'open,partial'
          }],
          has_unread_memos: [{
            label: 'Yes',
            value: '1'
          }]
        },
        timestamps: {}
      }
    }
  },
  notShipped: {
    label: 'Require Fulfillment',
    tooltip: 'Orders that still require fulfillment.',
    filters () {
      const deadline = dates().unix()
      return {
        preset: 'notShipped',
        search: {},
        facets: {
          status: [{
            label: 'Open',
            value: 'open,partial'
          }],
          not_fully_shipped: [{
            label: 'Yes',
            value: '1'
          }]
        },
        timestamps: {
          fulfill_by: [null, deadline]
        }
      }
    }
  },
  notInvoiced: {
    label: 'Require Invoice',
    tooltip: 'Orders that are fulfilled, but still require invoice(s).',
    filters () {
      return {
        preset: 'notShipped',
        search: {},
        facets: {
          status: [{
            label: 'Open',
            value: 'open,partial'
          }],
          not_fully_invoiced: [{
            label: 'Yes',
            value: '1'
          }]
        },
        timestamps: {}
      }
    }
  },
  completed: {
    label: 'Completed',
    tooltip: 'Orders that have been completed.',
    filters () {
      return {
        preset: 'completed',
        facets: {
          status: [{
            label: 'Completed',
            value: 'closed'
          }]
        },
        search: {},
        timestamps: {}
      }
    }
  },
  all: {
    label: 'All',
    tooltip: 'All orders.',
    filters () {
      return {
        preset: 'all',
        facets: {},
        search: {},
        timestamps: {}
      }
    }
  },
  critical: {
    label: 'Critically Late',
    tooltip: 'Orders that are > 5 days past due.',
    filters () {
      const deadline = dates().subtract(5, 'day').unix()
      return {
        preset: 'critical',
        facets: {
          status: [{
            label: 'Open',
            value: 'open,partial'
          }]
        },
        search: {},
        timestamps: {
          fulfill_by: [null, deadline]
        }
      }
    }
  },
  late: {
    label: 'Late',
    tooltip: 'Orders that are between 2 and 5 days past due.',
    filters () {
      const upperBound = dates().unix()
      const lowerBound = dates().subtract(5, 'day').unix()
      return {
        preset: 'late',
        facets: {
          status: [{
            label: 'Open',
            value: 'open,partial'
          }]
        },
        search: {},
        timestamps: {
          fulfill_by: [lowerBound, upperBound]
        }
      }
    }
  },
  current: {
    label: 'Current',
    tooltip: 'Orders that are open and on time.',
    filters () {
      const now = dates().unix()
      return {
        preset: 'current',
        facets: {
          status: [{
            label: 'Open',
            value: 'open,partial'
          }]
        },
        search: {},
        timestamps: {
          fulfill_by: [now, null]
        }
      }
    }
  }
}
