<template>
  <div>
    <div class="columns">
      <div class="column is-one-fifth">
        <p class="has-text-weight-bold is-size-6 mb-sm">Sync Variant</p>
        <p class="has-text-grey is-size-7">
          Attempt to sync variants between Shopify
          and {{ app.platform }} using a {{ app.platform }} Item ID.
        </p>
      </div>
      <div class="column">
        <div class="has-background-white rounded-lg">
          <div class="pa-lg">
            <!-- Intro Text (Merchant) -->
            <div class="is-size-7 mb-md">
              <p>
                This utility uses a {{ app.platform }} Item ID and searches your Shopify s
                tore for any items matching using the Merchant SKU, Vendor SKU,
                and/or UPC.
              </p>
            </div>
            <!-- Form -->
            <form @submit.prevent="syncVariant" class="mb-lg">
              <p class="has-text-weight-bold mb-md">{{ app.platform }} Item ID</p>
              <div class="columns">
                <div class="column">
                  <b-input type="text" v-model="variantId" size="is-medium" ></b-input>
                </div>
                <div class="column is-narrow">
                  <b-button
                    type="is-primary"
                    native-type="submit"
                    size="is-medium"
                    :loading="processing"
                    :disabled="processing || !valid"
                  >Run Sync</b-button>
                </div>
              </div>
            </form>
            <!-- Job Result -->
            <div class="is-size-7">
              <div v-if="processing">
                <p class="has-text-grey-light is-italic">Processing request...</p>
              </div>
              <div v-else-if="errorMessage !== null">
                <p class="has-text-grey-light is-italic">{{ errorMessage }}</p>
              </div>
              <div v-else>
                <p class="has-text-grey-light is-italic">Click the 'Run Sync' to kickoff the sync process.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['app', 'context']),
    endpoint () {
      return this.context.route + '/shopify/' + this.account.id + '/sync-variant/'
    },
    valid () {
      return (this.variantId !== null && parseInt(this.variantId) > 0)
    },
    json () {
      if (!this.variantId) return null
      return {
        variant_id: parseInt(this.variantId)
      }
    }
  },
  data () {
    return {
      variantId: null,
      processing: false,
      errorMessage: null
    }
  },
  methods: {
    syncVariant () {
      this.processing = true
      this.job = null
      this.$http.put(this.endpoint, this.json).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Sync scheduled!' })
        this.variantId = null
        this.errorMessage = null
      }).catch(() => {
        this.$buefy.toast.open({ type: 'is-danger', message: 'Sync failed!' })
      }).finally(() => {
        this.processing = false
      })
    }
  },
  props: ['account']
}
</script>
