<template>
  <span>{{ skuLabel }} <b>{{ skuValue }}</b></span>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['context']),
    hasMerchantSku () {
      return (
        this.context.isMerchant() &&
        this.variant.retailer_identifiers.length > 0 &&
        this.variant.retailer_identifiers[0].identifier
      )
    },
    skuLabel () {
      return (this.hasMerchantSku) ? 'Merchant SKU' : 'Supplier SKU'
    },
    skuValue () {
      return (this.hasMerchantSku) ? this.variant.retailer_identifiers[0].identifier : this.variant.identifier
    }
  },
  props: ['variant']
}
</script>
