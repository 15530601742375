import { cdnTransform } from '@/helpers/cdnTransform'
export default {
  computed: {
    name () {
      if ('brand' in this.marketplace) return this.marketplace.brand.name
      return this.marketplace.retailer.name
    },
    status () {
      const presenter = {
        label: 'Pending Approval',
        tooltip: 'We must approve your profile.',
        indicatorClass: 'has-text-warning'
      }
      if (this.marketplace.status === 'active') {
        presenter.label = 'Active'
        presenter.tooltip = 'Your profile is approved and active.'
        presenter.indicatorClass = 'has-text-success'
      }
      return presenter
    },
    verticals () {
      const verticals = []
      this.marketplace.verticals.forEach(vertical => {
        verticals.push(vertical.name)
      })
      return verticals.join(', ')
    },
    visibility () {
      const presenter = {
        label: 'Private',
        tooltip: 'Your profile is not visibile to RCN Partners.',
        indicatorClass: 'has-text-warning'
      }
      if (this.marketplace.visibility === 'public') {
        presenter.label = 'Visible'
        presenter.tooltip = 'Your profile is visible to your existing connections only.'
        presenter.indicatorClass = 'has-text-success'
      }
      return presenter
    },
    coverPhoto () {
      const placeholder = {
        name: 'cover',
        cdnUrl: 'https://res.cloudinary.com/revcascade/image/upload/w_1200,h_300/static/placeholder-lg.png'
      }
      // if marketplace is null or there are no assets, return default
      if (this.marketplace === null || this.marketplace.assets.length === 0) return placeholder

      // look for co
      const asset = this.$utils.find(this.marketplace.assets, { name: 'cover' })
      if (typeof asset !== 'undefined') {
        return {
          ...asset,
          cdnUrl: cdnTransform(asset.source_url, 900, 300, 'fill')
        }
      }
      return placeholder
    },
    profilePhoto () {
      const placeholder = {
        name: 'profile',
        cdnUrl: 'https://res.cloudinary.com/revcascade/image/upload/w_400,h_400/static/placeholder-lg.png'
      }
      // if marketplace is null or there are no assets, return default
      if (this.marketplace === null || this.marketplace.assets.length === 0) return placeholder

      // look for co
      const asset = this.$utils.find(this.marketplace.assets, { name: 'profile' })
      if (typeof asset !== 'undefined') {
        return {
          ...asset,
          cdnUrl: cdnTransform(asset.source_url, 400, 400, 'fill')
        }
      }
      return placeholder
    },
    missionStatement () {
      // if marketplace is null or there are no assets, return default
      if (this.marketplace === null || this.marketplace.assets.length === 0) return null

      // look for co
      const asset = this.$utils.find(this.marketplace.assets, { name: 'mission' })
      return (asset && asset.description) ? asset.description : null
    }
  }
}
