<template>
  <div>
    <!-- Content -->
    <div class="">
      <p class="has-text-weight-bold mb-md">Activate Product &amp; Inventory Sync</p>
      <p class="mb-lg">
        {{ connection.retailer.name }} requires frequent inventory updates in order
        to reduce cancellations due to stockouts. The {{ app.platform }} Shopify integration
        can sync inventory with {{ app.platform }} automatically. You may turn this integration
        off if you would prefer to update inventory manually each day.
      </p>
      <div class="mb-lg">
        <p class="has-text-weight-bold mb-md">Sync products &amp; inventory automatically? (Highly recommended)</p>
        <div class="columns is-mobile is-vcentered">
          <div class="column is-narrow">
            <div class="field">
              <b-switch v-model="sync" type="is-success" size="is-medium" true-value="Yes" false-value="No">{{ sync }}</b-switch>
            </div>
          </div>
        </div>
        <div class="has-text-grey-light">
          <p v-if="loading">Checking current status...</p>
          <p v-else-if="webhook !== null">Note: this integration is currently enabled.</p>
          <p v-else>Note: this integration is currently disabled.</p>
        </div>
      </div>
    </div>
    <!-- Navigate -->
    <div class="py-lg">
      <div class="columns is-centered">
        <div class="column">
          <b-button
            type="is-light rounded-lg"
            size="is-medium"
            @click="goBack"
            expanded
            ><b-icon icon="arrow-left"></b-icon>
          </b-button>
        </div>
        <div class="column">
          <b-button
            type="is-primary rounded-lg"
            size="is-medium"
            expanded
            @click="goForward"
            :disabled="loading || saving || !ready"
            :loading="saving"
          >Save &amp; Continue</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['app', 'context']),
    loading () {
      return (this.loadingWebhooks)
    },
    ready () {
      return (this.account || this.storeName)
    },
    webhook () {
      if (this.webhooks.length === 0) return null
      const hook = this.$utils.find(this.webhooks, ['topic', 'products/update'])
      return (typeof hook !== 'undefined') ? hook : null
    }
  },
  data () {
    return {
      loadingWebhooks: true,
      sync: 'Yes',
      saving: false,
      errors: {},
      webhooks: []
    }
  },
  methods: {
    goBack () {
      this.$emit('navigate', 'Install')
    },
    goForward () {
      // if brand wants to sync and there is no current webhook, then create one...
      if (this.sync === 'Yes' && this.webhook === null) {
        this.saving = true
        const endpoint = this.context.route + '/shopify/' + this.account.id + '/register-webhook/'
        const body = {
          webhook_topic: 'products/update'
        }
        this.$http.put(endpoint, body).then(response => {
          this.saving = false
          this.$buefy.toast.open({ type: 'is-success', message: 'Sync enabled!' })
          this.$emit('navigate', 'Orders')
        })
      // if the brand has a webhook and wants to turn it off, delete the webhook...
      } else if (this.sync === 'No' && this.webhook !== null) {
        this.saving = true
        const endpoint = this.context.route + '/shopify/' + this.account.id + '/webhooks/' + this.webhook.id + '/'
        this.$http.delete(endpoint).then(() => {
          this.saving = false
          this.$buefy.toast.open({ type: 'is-success', message: 'Sync disabled!' })
          this.$emit('navigate', 'Orders')
        })
      // in all other cases, don't do anything...
      } else {
        this.$buefy.toast.open({ type: 'is-success', message: 'Settings saved!' })
        this.$emit('navigate', 'Orders')
      }
    },
    loadWebhooks () {
      this.loadingWebhooks = true
      const endpoint = this.context.route + '/shopify/' + this.account.id + '/webhooks/'
      this.$http.get(endpoint).then(response => {
        this.webhooks = response.data.results
        this.loadingWebhooks = false
      })
    }
  },
  mounted () {
    this.loadWebhooks()
  },
  props: {
    connection: {
      type: Object,
      required: true
    },
    task: {
      type: Object,
      required: true
    },
    account: {
      type: Object,
      required: true
    }
  }
}
</script>
