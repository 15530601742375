<template>
  <div>
    <!-- Results -->
    <div class="mb-md">
      <b-table
        :data="results"
        :checked-rows.sync="selected"
        :mobile-cards="false"
        :loading="loading"
        hoverable
        class="is-size-7">
        <template>
          <!-- RC ID (since RMA Number not always required) -->
          <b-table-column
            label="ID"
            cell-class="nowrap has-text-weight-bold has-text-grey-light"
            width="98"
            v-slot="props">
            {{ props.row.id }}
          </b-table-column>
          <!-- Name-->
          <b-table-column
            label="Supplier"
            cell-class="nowrap"
            v-slot="props">
            {{ props.row.brand.name }}
          </b-table-column>
          <!-- Name-->
          <b-table-column
            label="Type"
            cell-class="nowrap"
            v-slot="props">
            {{ $utils.upperFirst(props.row.type) }}
          </b-table-column>
          <!-- Street-->
          <b-table-column
            label="Address"
            header-class="nowrap"
            cell-class="nowrap"
            v-slot="props">
            {{ props.row.address.street1 }} {{ props.row.address.street2 }}
          </b-table-column>
          <!-- City -->
          <b-table-column
            label="City"
            cell-class="nowrap"
            v-slot="props">
            {{ props.row.address.city }}
          </b-table-column>
          <!-- State -->
          <b-table-column
            label="Province"
            cell-class="nowrap"
            centered
            width="98"
            v-slot="props">
            {{ props.row.address.province }}
          </b-table-column>
          <!-- Country -->
          <b-table-column
            label="Country"
            cell-class="nowrap"
            centered
            width="98"
            v-slot="props">
            {{ props.row.address.country }}
          </b-table-column>
        </template>
        <template #empty>
          <div class="py-xxl has-text-grey has-text-centered">
            {{ (loading) ? "Loading data..." : 'No records found!' }}
          </div>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Filterable from '@/mixins/Filterable'

export default {
  components: {
    // LocationModal,
  },
  data () {
    return {
      key: 'connection.locations',
      alwaysAttach: {
        connection_id: this.connection.id
      },
      // modals
      inspectLocation: null
    }
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/connection-locations/'
    }
  },
  methods: {
    closeModals () {
      this.inspectLocation = null
    }
  },
  mounted () {
    this.load()
  },
  mixins: [Filterable],
  props: ['connection']
}
</script>
