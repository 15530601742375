<template>
  <p>
    <span class="has-text-weight-bold mr-sm">{{ presenter.score.toFixed(2) }}</span>
    <span :class="presenter.changeTextClass">{{ presenter.scoreChange }}</span>
  </p>
</template>

<script>
export default {
  computed: {
    presenter () {
      const score = Number(this.score)
      const scoreChange = this.scoreChange
      const presenter = {
        score: score,
        scoreChange: scoreChange,
        changeTextClass: null
      }
      if (scoreChange > 0) {
        presenter.changeTextClass = 'has-text-success'
        presenter.scoreChange = `+${this.scoreChange}`
      } else if (scoreChange < 0) {
        presenter.changeTextClass = 'has-text-danger'
      } else {
        presenter.changeTextClass = 'has-text-grey-lighter'
        presenter.scoreChange = '+0'
      }
      return presenter
    }
  },
  props: ['score', 'scoreChange']
}
</script>
