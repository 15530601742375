<template>
  <div>
    <!-- Toolbar -->
    <div class="mb-lg">
      <!-- High Level Stats -->
      <compliance-summary :timestamp="startTimestamp" :daterange="daterange" />
      <!-- Controls -->
      <div class="columns">
        <div class="column">
          <div class="columns" style="align-items:center">
            <div class="column is-narrow">
              <p class="has-text-weight-bold">Sort</p>
            </div>
            <div class="column">
              <div class="field">
                <b-select size="is-medium" v-model="sort" expanded @input="updateSort">
                  <option value="brand__name">Supplier Name (Asc)</option>
                  <option value="-brand__name">Supplier Name (Desc)</option>
                  <option value="-orders_count">Most Orders</option>
                  <option value="orders_count">Fewest Orders</option>
                  <option value="-incidents_count">Most Incidents</option>
                  <option value="incidents_count">Fewest Incidents</option>
                  <option value="ship_time">Ship Speed (Fastest)</option>
                  <option value="-ship_time">Ship Speed (Slowest)</option>
                  <option value="fill_time">Close Speed (Fastest)</option>
                  <option value="-fill_time">Close Speed (Slowest)</option>
                </b-select>
              </div>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="columns" style="align-items:center">
            <div class="column is-narrow">
              <p class="has-text-weight-bold">Search</p>
            </div>
            <div class="column">
              <div class="field is-grouped">
                <p class="control is-expanded">
                  <input
                    v-model="search"
                    class="input is-medium"
                    type="text"
                    placeholder="Search for Vendors..."
                    v-on:keyup.enter="loadComplianceSummary"
                  >
                </p>
                <p class="control">
                  <b-button size="is-medium" icon-right="magnify" :disabled="loading || search === null || search === ''"></b-button>
                  <b-button size="is-medium" icon-right="refresh" :disabled="loading || ! formCanReset" @click="reset"></b-button>
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="column is-narrow">
          <div class="buttons">
            <b-button size="is-medium is-fullwidth" icon-right="export">Export</b-button>
          </div>
        </div> -->
      </div>
    </div>

    <!-- Results Loaded -->
    <div v-if="!loading && vendors.length > 0">
      <div class="mb-md">
        <div class="mb-xs has-background-white pa-md rounded-lg">
          <!-- Results -->
          <div>
            <div class="mb-md">
              <b-table
                class="is-size-7"
                :data="vendors"
                :checked-rows.sync="selected"
                :mobile-cards="false"
                :loading="loading"
                hoverable>
                <template>
                  <!-- Supplier -->
                  <b-table-column
                    label="Supplier"
                    header-class="nowrap"
                    cell-class="nowrap"
                    v-slot="props">
                    <p>{{ props.row.brand.name }}</p>
                  </b-table-column>
                  <!-- # Orders -->
                  <b-table-column
                    label="# Orders"
                    header-class="nowrap"
                    cell-class="nowrap"
                    centered
                    width="128"
                    v-slot="props">
                    <p>
                      {{ props.row.orders_count }}
                    </p>
                  </b-table-column>
                  <!-- # Cancels -->
                  <b-table-column
                    label="# Cancels"
                    header-class="nowrap"
                    cell-class="nowrap"
                    centered
                    width="128"
                    v-slot="props">
                    <p>
                      {{ props.row.cancels_count }}
                    </p>
                  </b-table-column>
                  <!-- Success Rate -->
                  <b-table-column
                    label="Success Rate"
                    header-class="nowrap"
                    cell-class="nowrap has-text-weight-bold"
                    centered
                    width="128"
                    v-slot="props">
                    <SuccessRate :orders="props.row.orders_count" :cancels="props.row.cancels_count" />
                  </b-table-column>
                  <!-- Days To Ship -->
                  <b-table-column
                    label="Fulfill (Days)"
                    header-class="nowrap"
                    cell-class="nowrap has-text-weight-bold"
                    centered
                    width="128"
                    v-slot="props">
                    <DaysToShip :timestat="props.row.ship_time" />
                  </b-table-column>
                  <!-- Days To Close -->
                  <b-table-column
                    label="Invoice (Days)"
                    header-class="nowrap"
                    cell-class="nowrap has-text-weight-bold"
                    centered
                    width="128"
                    v-slot="props">
                    <DaysToComplete :timestat="props.row.fill_time" />
                  </b-table-column>
                </template>
                <template #empty>
                  <div class="py-xxl has-text-grey has-text-centered">
                    {{ (loading) ? "Loading data..." : 'No suppliers found!' }}
                  </div>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
      <b-pagination
        :total="total"
        :current.sync="currentPage"
        :rounded="true"
        :per-page="perPage"
        @change="updatePage">
      </b-pagination>
    </div>
    <!-- Results Loaded (Empty) -->
    <div v-else-if="!loading && vendors.length === 0" class="has-background-white pa-xxl rounded-lg has-text-centered">
      <p class="is-size-5">No results</p>
      <p class="has-text-grey">Please check your filters and try again.</p>
    </div>
    <!-- Results Loading -->
    <div v-else>
      <div v-for="index in 5" :key="index" class="has-background-white px-lg py-md rounded-lg mb-xs">
        <div class="placeholder placeholder-sentence mb-md" style="width:75%"></div>
        <div class="placeholder placeholder-sentence" style="width:50%"></div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ComplianceSummary from '../components/ComplianceSummary'
import DaysToShip from '@/components/Presenters/Compliance/DaysToShip'
import DaysToComplete from '@/components/Presenters/Compliance/DaysToComplete'
import SuccessRate from '@/components/Presenters/Compliance/SuccessRate'

export default {
  components: {
    ComplianceSummary,
    DaysToShip,
    DaysToComplete,
    SuccessRate
  },
  computed: {
    ...mapGetters(['context']),
    formCanReset () {
      return (this.search !== null)
    },
    startTimestamp () {
      return this.daterange.start
    },
    queryString () {
      const qs = {
        params: {
          page: this.currentPage,
          limit: this.perPage,
          order_by: this.sort,
          created_at_gte: this.daterange.start,
          created_at_lte: this.daterange.end,
          exclude_invoicing_incidents: 1
        }
      }
      // attach optional query string
      if (this.search !== null) qs.params.search = this.search
      return qs
    }
  },
  data () {
    return {
      loading: true,
      search: null,
      sort: 'brand__name',
      vendors: [],
      total: null,
      selected: [],
      currentPage: 1,
      perPage: 36
    }
  },
  methods: {
    reset () {
      this.search = null
      this.currentPage = 1
      this.loadComplianceSummary()
    },
    cacheQuery () {
      sessionStorage.setItem('compliance', JSON.stringify(this.queryString))
    },
    loadComplianceSummary () {
      window.scrollTo(0, 0)
      this.loading = true
      this.$http.get(this.context.route + '/compliance/', this.queryString).then(response => {
        this.vendors = response.data.results
        this.total = response.data.count
        this.loading = false
      })
    },
    updateSort () {
      this.currentPage = 1
      this.loadComplianceSummary()
    },
    updatePage (value) {
      this.currentPage = value
      this.loadComplianceSummary()
    }
  },
  mounted () {
    const querySet = JSON.parse(sessionStorage.getItem('compliance'))
    if (querySet !== null) {
      this.filters = querySet.filters
      if (querySet.orderBy !== this.defaults.orderBy) {
        this.orderBy = querySet.orderBy
      }
      if (querySet.currentPage !== this.defaults.currentPage) {
        this.currentPage = querySet.currentPage
      }
    }
    this.loadComplianceSummary()
  },
  props: ['daterange'],
  watch: {
    startTimestamp: function (value) {
      this.loadComplianceSummary()
    }
  }
}
</script>
