<template>
  <form @submit.prevent="update" novalidate autocomplete="off">
    <div class="columns">
      <div class="column is-one-fifth">
        <p class="has-text-weight-bold is-size-6 mb-sm">Profile Visibility</p>
        <p class="has-text-grey is-size-7">
          Control how your organization appears in the {{ app.platform }} Commerce Network.
          <br><br>
          If your profile is visible, you may receive invitations from merchants
          that want to collaborate with you to sell your products.
        </p>
      </div>
      <div class="column">
        <div class="has-background-white rounded-lg">
          <div class="pa-lg pb-xl">
            <!-- Loading Billing Locations -->
            <div v-if="loading">
              <div class="pa-md" v-for="index in 2" :key="index">
                <div class="placeholder placeholder-title"></div>
                <div class="placeholder placeholder-sentence" style="width:50%"></div>
              </div>
            </div>
            <div v-else>
              <!-- Visibility -->
              <div class="mb-xl">
                <p class="has-text-weight-bold mb-md">Profile Visibility</p>
                <!-- <b-field
                  ><b-radio
                    v-model="visibility"
                    native-value="public"
                    @input="isDirty = true">
                    Published
                  </b-radio>
                </b-field> -->
                <div class="field">
                  <b-radio
                    v-model="visibility"
                    native-value="connections-only"
                    @input="isDirty = true">
                    Published (to connections only!)
                  </b-radio>
                </div>
                <div class="field">
                  <b-radio
                    v-model="visibility"
                    native-value="invisible"
                    @input="isDirty = true">
                    Not Published
                  </b-radio>
                </div>
                <!-- Explanation -->
                <div>
                  <em>
                    <span v-if="visibility === null">
                      &nbsp;
                    </span>
                    <span class="has-text-info" v-else-if="visibility === 'public'">
                      Your profile and products are visible to all merchants, and any merchants may request access to sell your products.
                    </span>
                    <span class="has-text-info" v-else-if="visibility === 'connections-only'">
                      Your profile and products are visible to connected merchants only, and only connected merchants may request access to sell your products.
                    </span>
                    <span class="has-text-warning" v-else-if="visibility === 'invisible'">
                      Your profile and products are not visible to any merchants.
                    </span>
                  </em>
                </div>
              </div>

              <!-- Verticals -->
              <div class="mb-xl">
                <p class="has-text-weight-bold mb-md">Product Verticals</p>
                <div class="columns is-mobile">
                  <div class="column is-half-tablet is-one-third-desktop">
                    <div class="field">
                      <b-checkbox v-model="verticals" native-value="10" @input="isDirty = true">Mens Apparel</b-checkbox>
                    </div>
                    <div class="field">
                      <b-checkbox v-model="verticals" native-value="20" @input="isDirty = true">Womens Apparel</b-checkbox>
                    </div>
                    <div class="field">
                      <b-checkbox v-model="verticals" native-value="50" @input="isDirty = true">Handbags & Accessories</b-checkbox>
                    </div>
                    <div class="field">
                      <b-checkbox v-model="verticals" native-value="30" @input="isDirty = true">Baby & Kids</b-checkbox>
                    </div>
                  </div>
                  <div class="column is-half-tablet is-one-third-desktop">
                    <div class="field">
                      <b-checkbox v-model="verticals" native-value="70" @input="isDirty = true">Jewelry</b-checkbox>
                    </div>
                    <div class="field">
                      <b-checkbox v-model="verticals" native-value="40" @input="isDirty = true">Health & Beauty</b-checkbox>
                    </div>
                    <div class="field">
                      <b-checkbox v-model="verticals" native-value="80" @input="isDirty = true">Home Goods</b-checkbox>
                    </div>
                    <div class="field">
                      <b-checkbox v-model="verticals" native-value="60" @input="isDirty = true">Furniture</b-checkbox>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Referral Code -->
              <div>
                <div class="columns">
                  <div class="column is-two-thirds-tablet is-half-desktop">
                    <div class="mb-sm">
                      <p class="has-text-weight-bold mb-xs">Referral Code</p>
                      <p>
                        Use a unique referral code when inviting new merchants to help
                        them connect with you for free!
                      </p>
                    </div>
                    <b-field
                      :type="('referral_code' in errors) ? 'is-danger' : ''"
                      :message="('referral_code' in errors) ? errors['referral_code'][0] : ''"
                      expanded>
                        <b-input
                          autocomplete="off"
                          type="text"
                          v-model="referralCode"
                          maxlength="8"
                          expanded
                          @input="isDirty = true"
                        ></b-input>
                        <p class="control">
                          <b-button
                            type="is-primary"
                            outlined
                            :disabled="!referralCode"
                            @click="testReferral"
                          >Test it Out!</b-button>
                        </p>
                    </b-field>
                  </div>
                </div>
                <div v-if="referralLink">
                  <p class="has-text-weight-bold">Your Referral Link:</p>
                  <p>{{ referralLink }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="border-t">
            <div class="columns is-vcentered is-marginless">
              <div class="column">
                <div class="px-sm has-text-grey-light">
                  <i v-if="isDirty" class="has-text-danger">Click 'Save' to save your changes before moving on to the next section.</i>
                </div>
              </div>
              <div class="column is-narrow">
                <div class="px-sm">
                  <b-button native-type="submit" type="is-primary rounded-lg" :loading="updating" :disabled="updating || !isDirty">Save</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  computed: {
    ...mapGetters(['app', 'context']),
    loading () {
      return this.marketplace === null
    },
    endpoint () {
      if (this.marketplace === null) return null
      return this.context.route + '/marketplaces/' + this.marketplace.id + '/'
    },
    json () {
      return {
        visibility: this.visibility,
        verticals: this.verticals,
        referral_code: this.referralCode.replace(/[^a-zA-Z0-9]/g, '')
      }
    },
    referralLink () {
      const opposingContext = (this.context.isSupplier()) ? 'merchant' : 'supplier'
      if (this.referralCode) {
        return this.app.url + '/register/' + opposingContext + '/plans?code=' + this.referralCode.replace(/[^a-zA-Z0-9]/g, '')
      }
      return null
    }
  },
  data () {
    return {
      isDirty: false,
      inventory: null,
      visibility: null,
      verticals: [],
      referralCode: null,
      errors: [],
      updating: false
    }
  },
  methods: {
    update () {
      this.updating = true
      this.$http.patch(this.endpoint, this.json).then(response => {
        this.isDirty = false
        this.$emit('visibility:updated', this.visibility)
        this.$buefy.toast.open({ type: 'is-success', message: 'Profile updated!' })
      }).catch(() => {
        this.$buefy.toast.open({ type: 'is-danger', message: 'Update failed! Please check your inputs and try again.' })
      }).finally(() => {
        this.updating = false
      })
    },
    testReferral () {
      window.open(this.referralLink, '_blank')
    }
  },
  props: ['marketplace'],
  watch: {
    loading (value) {
      if (value === false && this.marketplace !== null) {
        this.visibility = this.marketplace.visibility
        this.referralCode = this.marketplace.referral_code
        const verticals = []
        this.marketplace.verticals.forEach(vertical => {
          verticals.push(vertical.id)
        })
        this.verticals = verticals
      }
    }
  }
}
</script>
