<template>
  <div>
    <div class="columns is-vcentered">
      <div class="column">
        <p class="has-text-weight-bold">
          Import #<a href="#" @click.prevent="$emit('inspect:feed', feed)" class="has-text-info">{{ feed.id}}</a>
        </p>
        <div class="is-size-7 has-text-grey">
          <p><PurposePresenter :feed="feed" class="is-size-7" /></p>
          <p v-if="feed.completed_at">Completed at {{ $dates.utc(feed.processed_at).local().format('MMM D, YYYY @ h:mm A') }}</p>
          <p v-else-if="feed.processed_at">Started at {{ $dates.utc(feed.processed_at).local().format('MMM D, YYYY @ h:mm A') }}</p>
          <p v-else>Queued at {{ $dates.utc(feed.created_at).local().format('MMM D, YYYY @ h:mm A') }}</p>
        </div>
      </div>
      <div class="column is-2">
        <p><ProgressPresenter :feed="feed"/></p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PurposePresenter from '@/components/Models/FeedQueue/Presenters/Purpose'
import ProgressPresenter from '@/components/Models/FeedQueue/Presenters/Progress'

export default {
  components: {
    ProgressPresenter,
    PurposePresenter
  },
  computed: {
    ...mapGetters(['context'])
  },
  props: ['feed']
}
</script>
